import React from 'react';
import styled, { css } from 'styled-components';
import { Button as AntBtn } from 'antd';
import { FilterIcon } from 'icons/FilterIcon';
import withTheme from './withTheme';

/**
 * @param {object} theme
 * @param {'fixed'|'full'} widthSize
 */
export const Button = withTheme(styled(({ widthSize, theme, ...p }) => <AntBtn {...p} />)`
  ${({ theme, widthSize }) => css`
    display: block;
    padding: ${widthSize === 'fixed'
    ? theme.buttons.paddingFixSize
    : theme.buttons.padding};

  ${() => {
    switch (widthSize) {
      case 'fixed':
        return css`
        min-width: ${theme.buttons.fixWidth};
        max-width: ${theme.buttons.fixWidth};
        width: ${theme.buttons.fixWidth};
        `;
      case 'full':
        return css`
        min-width: ${theme.buttons.minWidth};
        max-width: 100%;
        width: 100%;
        `;
      default:
        return css`
        padding: ${typeof widthSize === 'number'
    ? theme.buttons.paddingFixSize
    : theme.buttons.padding};
        min-width: ${typeof widthSize === 'number' ? `${widthSize}px` : 'max-content'};
        max-width: max-content;
        width: 100%`;
    }
  }};

    height: auto;
    border-width: ${theme.buttons.borderWidth}px;
    border-radius: ${theme.buttons.borderRadius}px;
    font-family: ${theme.fontFamily};
    font-size: ${theme.buttons.textSize}px;
    line-height: ${theme.buttons.lineHeight}px;
    color: ${theme.buttons.main.textColor};
    border-color: ${theme.buttons.main.borderColor};
    box-shadow: none;
    background-color: ${theme.buttons.main.backgroundColor};
    box-sizing: border-box;

    &:hover,
    &:focus {
      border-color: ${theme.buttons.main.borderHoverColor};
      background-color: ${theme.buttons.main.backgroundHoverColor};
      color: ${theme.buttons.main.textColor};
    }

    &:active {
      background-color: ${theme.buttons.main.backgroundColor};
      color: ${theme.buttons.main.textColor};
      border-radius: ${theme.buttons.main.borderRadius}px;
    }

    &:disabled {
      border-color: ${theme.buttons.main.borderDisabledColor};
      background-color: ${theme.buttons.main.backgroundDisabledColor};
      color: ${theme.buttons.main.textDisabledColor};

      &:hover {
        border-color: ${theme.buttons.main.borderDisabledColor};
        background-color: ${theme.buttons.main.backgroundDisabledColor};
        color: ${theme.buttons.main.textDisabledColor};
      }
    }
  `}
`);

/**
 * @param {object} theme
 * @param {'fixed'|'full'} widthSize
 * @return {React.Component}
 */
export const ButtonOutline = withTheme(styled(({ theme, widthSize, ...p }) => <AntBtn {...p} />)`
  ${({ theme, widthSize }) => css`
    display: block;
    padding: ${widthSize === 'fixed' ? theme.buttons.paddingFixSize : theme.buttons.padding};
  ${() => {
    switch (widthSize) {
      case 'fixed':
        return css`
        min-width: ${theme.buttons.fixWidth};
        max-width: ${theme.buttons.fixWidth};
        width: ${theme.buttons.fixWidth};
        `;
      case 'full':
        return css`
        min-width: ${theme.buttons.minWidth};
        max-width: 100%;
        width: 100%;
        `;
      default:
        return css`
        padding: ${typeof widthSize === 'number'
    ? theme.buttons.paddingFixSize
    : theme.buttons.padding};
        min-width: ${typeof widthSize === 'number' ? `${widthSize}px` : 'max-content'};
        max-width: max-content;
        width: 100%`;
    }
  }};

    height: auto;
    border: ${theme.buttons.borderWidth}px solid ${theme.buttons.outline.borderColor};
    border-radius: ${theme.buttons.borderRadius}px;
    font-family: ${theme.fontFamily};
    font-size: ${theme.buttons.textSize}px;
    line-height: ${theme.buttons.lineHeight}px;
    color: ${theme.buttons.outline.textColor};
    text-align: center;
    box-shadow: none;
    background-color: ${theme.buttons.outline.backgroundColor};
    box-sizing: border-box;

    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 1px ${theme.buttons.outline.borderHoverColor};
      border-color: ${theme.buttons.outline.borderHoverColor};
      background-color: ${theme.buttons.outline.backgroundHoverColor};
      color: ${theme.buttons.outline.textHoverColor};
    }

    &:active {
      background-color: ${theme.buttons.outline.backgroundColor};
      color: ${theme.buttons.outline.textColor};
      border-radius: ${theme.buttons.outline.borderRadius}px;
    }

    &:disabled {
      border-color: ${theme.buttons.outline.borderDisabledColor};
      background-color: ${theme.buttons.outline.backgroundDisabledColor};
      color: ${theme.buttons.outline.textDisabledColor};

      &:hover {
        border-color: ${theme.buttons.outline.borderDisabledColor};
        background-color: ${theme.buttons.outline.backgroundDisabledColor};
        color: ${theme.buttons.outline.textDisabledColor};
      }
    }
  `}
`);

export const ButtonText = withTheme(styled(React.forwardRef(
  (
    { theme, ...p },
    ref,
  ) => <AntBtn {...p} ref={ref} />,
))`
  ${({ theme }) => css`
    display: block;
    padding: 0;
    min-width: max-content;
    height: auto;
    border:none;
    box-shadow: none;
    background-color: transparent;
    font-family: ${theme.fontFamily};
    font-size: ${theme.buttons.textSize}px;
    line-height: ${theme.buttons.lineHeight}px;
    color: ${theme.buttons.text.textColor};
    box-sizing: border-box;

    &[ant-click-animating-without-extra-node="true"]::after{
      display: none;
    }

    &:hover,
    &:focus {
      border: ${theme.buttons.text.border};
      color: ${theme.buttons.text.textHoverColor};
      background-color: transparent;
    }

    &:active {
      background-color: ${theme.buttons.text.backgroundColor};
      color: ${theme.buttons.text.textColor};
      border: ${theme.buttons.text.border};
    }
  `}
`);

export const ButtonGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  column-gap: 20px;
  justify-content: ${({ flexPosition }) => flexPosition || 'center'};
  align-items: center;
`;

export const FilterBtnContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  
  &:focus {
    outline: none;
    border: none;
  }
`;

export const ButtonFilter = (props) => (
  <FilterBtnContainer {...props}>
    <FilterIcon />
  </FilterBtnContainer>
);
