import React from 'react';
import styled, { css } from 'styled-components';
import withTheme from 'components/tcm/withTheme';
import { Form } from 'antd';

export const Page = styled.div`
  margin-bottom: 60px;
`;

export const PageTitle = withTheme(styled.h1`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-weight: 600;
  font-size: 44px;
  line-height: 45px;
  letter-spacing: -1.3px;
  color: ${({ theme }) => theme.palette.black};
`);

export const FiltersWrapper = withTheme(styled.div`
  padding: 28px 40px 40px;
  background-color: ${({ theme }) => theme.palette.gray7};
  border-radius: 10px;
`);

export const BlockTitle = withTheme(styled.p`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

export const FormWrapper = styled.div`
  width: 640px;
  ${({ show }) => !show && 'display: none'}
`;

export const FoundedCampaigns = styled.ul`
  margin: 24px 0 55px;
  padding: 0;
  max-height: 221px;
  overflow-y: auto;
`;

export const DatePresets = styled.div`
  margin: 5px 0 33px;
`;

export const ModalText = styled.div`
  margin: 52px auto 0;
  width: 736px;
  font-family: Toyota Type;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;
export const FormItem = styled(Form.Item)`
  margin: 52px auto 50px;
  width: 380px;
  label {
  font-size: 12px;
  }
`;

export const DescriptionItem = styled.div`
  display: grid;
  row-gap: 10px;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 20px;
  
  &:last-child {
    margin-bottom: 47px;
  }
`;

export const ItemTitle = withTheme(styled.p`
  margin-bottom: 0;
  color:  ${({ theme }) => theme.palette.gray3};
`);

export const ItemContent = withTheme(styled.p`
  margin-bottom: 0;
  color:  ${({ theme }) => theme.palette.black};
`);

export const FunnelWrapper = styled.div`
  margin-top: 42px;
`;

export const FunnelTitle = withTheme(styled.p`
  margin-bottom: 26px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
`);

export const Funnel = withTheme(styled.ul`
  display: grid;
  row-gap: 10px;
  padding-left: 0;
  padding-bottom: 60px;
  width: 100%;
  
  li {
    margin: 0;
    list-style: none;
  }
`);

const FunnelItemTpl = withTheme(styled.div`
  padding: ${({ withoutText }) => (withoutText ? '19px 0 16px' : '15px 0 12px')};
  ${({ width }) => (width || width === 0) && css`width: ${width}%`};
  ${({ withoutText }) => withoutText && css`height: 60px`};
  border: 2px solid ${({ theme }) => theme.palette.gray5};
  ${({ withoutText }) => !withoutText && css`box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05)`};
  border-radius: 8px;
  background-color: ${({ theme, isFinal, withoutText }) => (isFinal
    ? theme.palette.successful
    : withoutText
      ? theme.palette.gray5
      : theme.palette.white)
};
  font-family: ${({ theme }) => theme.fontFamily};
  box-sizing: border-box;
`);

const StepValue = withTheme(styled.div`
  padding: 15px 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray2};
`);

const FunnelItemWrapper = withTheme(styled.div`
  display: grid;
  grid-template-columns: 55px 1fr;
  column-gap: 7px;
  align-items: center;
`);

const Count = withTheme(styled.span`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 24px;
  line-height: 25px;
`);

const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ blockWidth }) => blockWidth}% max-content;
  column-gap: 30px;
  align-items: center;
`;
const TextInLine = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
  overflow: visible;
  white-space: nowrap;
  padding-left: 40px;
`;

export const FunnelItem = ({
  total,
  value,
  text,
  isFinal,
  withoutText,
}) => {
  const percentValue = total ? `${Math.round((value * 100) / total)}%` : '0%';
  const proportion = total ? Math.round((value / total) * 100) / 100 : 0;
  const width = proportion * 100;

  return (
    <FunnelItemWrapper>
      <StepValue>
        {percentValue}
      </StepValue>
      {!withoutText ? (
        <FunnelItemTpl isFinal={isFinal} width={width}>
          <TextInLine>
            <Count>{value}</Count>
            {' '}
            {text}
          </TextInLine>
        </FunnelItemTpl>
      ) : (
        <Row blockWidth={width}>
          <FunnelItemTpl isFinal={isFinal} withoutText width={width}>
            <TextInLine>
              <Count>{value}</Count>
              {' '}
              {text}
            </TextInLine>
          </FunnelItemTpl>
        </Row>
      )}
    </FunnelItemWrapper>
  );
};

const Column = styled.div`
  display: grid;
  align-items: center;
`;

const SubFunnelItemTpl = withTheme(styled.p`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 11px;
  align-items: center;
  margin-bottom: 0;
  
  &:first-child {
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 15px; 
  }
  
  &:last-child {
    padding-top: 15px;
  }
`);

export const DoubleFunnelItem = ({
  total,
  firstValue,
  firstText,
  secondaryValue,
  secondaryText,
}) => {
  const firstPercentValue = total ? `${Math.round((firstValue * 100) / total)}%` : '0%';
  const firstProportion = total ? Math.round((firstValue / total) * 100) / 100 : 0;

  const secondaryPercentValue = total ? `${Math.round((secondaryValue * 100) / total)}%` : '0%';
  const secondProportion = total ? Math.round((secondaryValue / total) * 100) / 100 : 0;

  return (
    <FunnelItemWrapper>
      <Column>
        <StepValue>
          {firstPercentValue}
        </StepValue>
        <StepValue>
          {secondaryPercentValue}
        </StepValue>
      </Column>
      <Column>
        <FunnelItemTpl width={Math.max(firstProportion, secondProportion)}>
          <SubFunnelItemTpl>
            <TextInLine>
              <Count>{firstValue}</Count>
              {' '}
              {firstText}
            </TextInLine>
          </SubFunnelItemTpl>
          <SubFunnelItemTpl>
            <TextInLine>
              <Count>{secondaryValue}</Count>
              {' '}
              {secondaryText}
            </TextInLine>
          </SubFunnelItemTpl>
        </FunnelItemTpl>
      </Column>
    </FunnelItemWrapper>
  );
};

export const ConversionBlock = withTheme(styled.div`
  display: grid;
  grid-template-columns: max-content 40px max-content;
  column-gap: 30px;
  align-items: center;
  padding: 60px 0 65px;
  border-top: 1px solid ${({ theme }) => theme.palette.gray5};
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray5};
`);

export const Equally = withTheme(styled.div`
  width: 40px;
  height: 10px;
  border-top: 1px solid ${({ theme }) => theme.palette.gray3};
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray3};
`);

export const ConversionCount = withTheme(styled.div`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
`);

export const DivisionRow = withTheme(styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  
  &:first-child {
    padding-bottom: 11px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.gray3};
  }
  
  &:last-child {
    padding-top: 11px;
  }
`);

export const DivisionCount = withTheme(styled.span`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
`);

export const ReportDescription = styled.div`
  margin-top: 47px;
`;

export const ReportDescriptionTitle = withTheme(styled.p`
  margin-bottom: 31px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
`);

export const ReportDescriptionBody = withTheme(styled.div`
  display: grid;
  grid-template-columns: minmax(max-content, 1fr) 1fr;
  column-gap: 100px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
`);

export const DescriptionBlockTitle = withTheme(styled.p`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const DescriptionText = styled.p`
 margin-bottom: 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 47px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 55px;
`;

export const NoResults = withTheme(styled.p`
  margin-top: 45px;
  margin-bottom: 60px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
  `);
