import styled from 'styled-components';

const OPERATIONS_TYPE_COLORS = {
  Оплата: '#2E82FF',
  Зарегистрирован: '#2E82FF',
  Завершен: '#2E82FF',
  Авторизация: '#2E82FF',
  Возврат: '#E71313',
};

const OPERATIONS_STATUS_COLORS = {
  Подтвержден: '#2E82FF',
  'не определено': '#E71313',
};

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const Header = styled.header`
  font-size: 28px;
  line-height: 38px;
  padding-bottom: 2rem;
`;

export const Status = styled.div`
  color: ${(props) =>
    (OPERATIONS_STATUS_COLORS[props.status]
      ? OPERATIONS_STATUS_COLORS[props.status]
      : '#E71313')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Type = styled.div`
  color: ${(props) =>
    (OPERATIONS_TYPE_COLORS[props.type] ? OPERATIONS_TYPE_COLORS[props.type] : '#E71313')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
