// ugly way of accessing the data - server returns either an object with array inside or just an empty array
export const formatFias = (object, isOrganization = false) =>
  (object.data && object.data.autocompletionList
    ? object.data.autocompletionList
    : []
  ).map((item) => {
    const {
      value,
      additionalValues: { GUID, ...additional },
    } = item;

    return {
      value,
      additionalValues: additional,
      ...(GUID ? { GUID } : {}),
      ...(isOrganization ? { description: `${additional.inn} ${additional.text}` } : {}),
    };
  });

export const formatFiasCompany = (object, isOrganization = false) =>
  (object.data && object.data.autocompletionList
    ? object.data.autocompletionList
    : []
  ).map((item) => {
    const {
      additionalValues: { GUID, ...additional },
    } = item;

    return {
      value: `${additional.opf} ${additional.name}`,
      additionalValues: additional,
      ...(GUID ? { GUID } : {}),
      ...(isOrganization ? { description: `${additional.inn} ${additional.text}` } : {}),
    };
  });
