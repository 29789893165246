import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { formatFilters } from 'hacks';
import { defaultSorting, defaultPageSize } from 'redux/user/reducer';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
}) => {
  const URI = `${API}/admin/user/list`;

  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: defaultPageSize,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchItem = ({ userId, accessToken }) => {
  const URI = `${API}/admin/user/single`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: accessToken || getToken(),
      data: {
        userId: Number(userId),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const createUser = ({
  email,
  login,
  phone,
  fullName,
  isInternal,
  roleId,
  merchantId,
  dealerCenterId,
  userType,
}) => {
  const URI = `${API}/admin/user/create`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        // we explicitly pass both `login`` and `email` keys, but duplicate them if user is internal
        login: isInternal ? login : email,
        email,
        phone,
        fullName,
        roleId,
        type: userType,
        ...(!isInternal
          ? {
            ...(merchantId && { merchantId }),
            ...(dealerCenterId && { dealerCenterId }),
          }
          : {}),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const blockUser = ({ userId }) => {
  const URI = `${API}/admin/user/block`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        userId,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const unblockUser = ({ userId }) => {
  const URI = `${API}/admin/user/unblock`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        userId,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const restoreUserPassword = ({ userId }) => {
  const URI = `${API}/admin/user/restore-password`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        userId,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const updateUser = ({
  id, email, phone, fullName,
}) => {
  const URI = `${API}/admin/user/update`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: Number(id),
        email,
        phone,
        displayName: fullName,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchRoles = ({ userId }) => {
  const URI = `${API}/admin/access/list`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        userId: Number(userId),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchAvailableRoles = ({ userId }) => {
  const URI = `${API}/admin/access/role/current-user-available`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        userId: Number(userId),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const approveRole = ({
  userId, merchantId, dealerCenterId, roleId, isInternal,
}) => {
  const URI = `${API}/admin/user/role/add`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        userId: Number(userId),
        isInternal,
        roleId: Number(roleId),
        ...(dealerCenterId ? { dealerCenterId: Number(dealerCenterId) } : {}),
        ...(merchantId ? { merchantId: Number(merchantId) } : {}),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const disapproveRole = ({ id }) => {
  const URI = `${API}/admin/access/delete`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: Number(id),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const callUserActivity = ({ creditRequestAccessToken }) => {
  const URI = `${API}/ping`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        creditRequestAccessToken,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

export const checkEmail = ({ email, merchantId }) => {
  const URI = `${API}/admin/user/email/validate`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        email,
        merchantId,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

export const downloadLisUsers = ({
  filters,
  sorting,
}) => {
  const URI = `${API}/admin/user/export-for-download`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        filter: {
          ...formatFilters(filters),
          meta: {
            sort: sorting,
          },
        },
        fileFormat: 'xls',
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .catch((error) => console.error(error));
};

export default {
  fetchList,
  fetchItem,
  createUser,
  blockUser,
  unblockUser,
  restoreUserPassword,
  fetchRoles,
  fetchAvailableRoles,
  approveRole,
  updateUser,
  disapproveRole,
  callUserActivity,
  checkEmail,
  downloadLisUsers,
};
