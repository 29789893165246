import { objectDataToArray } from 'utils';

// Here we create an array of objects `{ value: String, label: String }`, since server returns inconsistent data;
// It could be an array, an array-like object, or `value` could be boolean
export const getOptionsList = (array) =>
  (Array.isArray(array) ? array : objectDataToArray(array)).map((e) => ({
    label: String(e.label),
    value: String(e.value),
  }));

// Here we create an array of objects `{ value: String, label: String }`, since server returns inconsistent data;
// It could be an array, an array-like object, or `value` could be boolean
// We also map label as a value (since the server returns label as value):
// i.e. options = { 1: { label: "Advertisment", value: "ads" } } value = "Advertisment" (value should be 'ads')
export const getOptionsListMono = (array) =>
  (Array.isArray(array) ? array : objectDataToArray(array)).map((e) => ({
    label: String(e.label),
    value: String(e.label),
  }));
