import React, { useCallback, useMemo, useState } from 'react';
import { TCM } from 'components/tcm';
import { CallForm } from 'containers/App/TCM/ClientCard/CallForm/CallForm';
import { PageBlock } from 'components/tcm/PageBlock/PageBlock';
import moment from 'moment';
import { CallIcon } from 'icons';
import { getMessageFromResult, getRecallDateText } from 'tcm/offer/format';
import { CallStatusDescriptionsCode, CallStatusesCode } from 'tcm/offer/data';
import { checkPrivilege } from 'helpers/roles';
import { connect } from 'react-redux';

import {
  CallResultContainer,
  CallResultRow,
  CallsContent,
  EmptyContent,
  RecallMessage,
  RecallMessageRow,
  ResultItemDescription,
  ResultItemTitle,
} from 'containers/App/TCM/ClientCard/style';
import { getDeclension } from 'utils/getDeclension';
import { Wrapper } from './style';

/**
 * @param {CallResult} result
 * @returns {JSX.Element}
 * @constructor
 */
const CallResult = ({ result }) => (
  <CallResultContainer>
    <CallResultRow>
      <div>
        <ResultItemTitle>Дата и время</ResultItemTitle>
        <ResultItemDescription>
          { moment(result.date).isValid()
            ? moment(result.date).format('DD.MM.YYYY HH:mm')
            : ''}
        </ResultItemDescription>
      </div>
      <div>
        <ResultItemTitle>Сотрудник</ResultItemTitle>
        <ResultItemDescription>{result.employee ? result.employee.name : ' - '}</ResultItemDescription>
      </div>
    </CallResultRow>
    <div>
      <ResultItemTitle>Результат</ResultItemTitle>
      <ResultItemDescription fullWidth>
        {getMessageFromResult(result.status, result.statusDescriptions)}
      </ResultItemDescription>
    </div>
    <div>
      <ResultItemTitle>Комментарий</ResultItemTitle>
      <ResultItemDescription>{result.text}</ResultItemDescription>
    </div>
  </CallResultContainer>
);

/**
 *
 * @param role
 * @param {CallResult} lastEvent
 * @param {CallResult[]} calls
 * @param {string} clientGuid
 * @param offerStatus
 * @return {JSX.Element}
 * @constructor
 */
const CallResults = ({
  isMyClient,
  role,
  lastEvent,
  calls = [],
  clientGuid,
  setIsCallFormOpened,
}) => {
  const [showCallForm, setShowCallForm] = useState(false);
  const [showMoreResults, setShowMoreResults] = useState(false);

  const onCallBtnClick = useCallback(() => {
    setShowCallForm(true);
    setIsCallFormOpened(true);
  },
  [setIsCallFormOpened]);

  const onCancelCall = useCallback(() => {
    setShowCallForm(false);
    setIsCallFormOpened(false);
  }, [setIsCallFormOpened]);

  const onShowMoreResultClick = useCallback(
    () => setShowMoreResults(!showMoreResults),
    [showMoreResults],
  );

  const callBtn = useMemo(() => (isMyClient ? (
    <TCM.ButtonOutline onClick={onCallBtnClick}>Совершить звонок</TCM.ButtonOutline>
  ) : null),
  [onCallBtnClick, isMyClient]);

  const isRecallStatus = useMemo(() => {
    if (lastEvent) {
      return [
        CallStatusesCode.Recall,
        CallStatusesCode.NegativeRecall,
        CallStatusesCode.NotOwner,
      ].includes(lastEvent.status);
    }
    return false;
  },
  [lastEvent]);

  const isDescriptionRecallStatus = useMemo(() => {
    if (lastEvent?.status?.statusDescriptions) {
      return Boolean(
        lastEvent.status.statusDescriptions
          .find((d) => d.status === CallStatusDescriptionsCode.Date),
      );
    }
    return null;
  },
  [lastEvent]);

  const showRecallMessage = useMemo(() => {
    if (lastEvent) {
      return !showCallForm && (isRecallStatus || isDescriptionRecallStatus);
    }
    return false;
  }, [isDescriptionRecallStatus, isRecallStatus, lastEvent, showCallForm]);

  const recallMessage = useMemo(() => {
    if (showRecallMessage) {
      const statusWithDate = lastEvent.statusDescriptions.find(
        (status) => [
          CallStatusDescriptionsCode.CallbackDate,
          CallStatusDescriptionsCode.Date,
        ].includes(status.key),
      );

      if (!statusWithDate) {
        console.error('recallMessage: no description with "recall" status.');
        return null;
      }
      const recallText = `Перезвонить ${getRecallDateText(statusWithDate.value)}`;

      return (
        <RecallMessage>
          <div><CallIcon /></div>
          <p>{recallText}</p>
        </RecallMessage>
      );
    }
  }, [lastEvent, showRecallMessage]);

  const buttonInCallsHeader = useMemo(() => (
    checkPrivilege(role.TCM.ADD_CALL_TO_OFFER)
    && !showRecallMessage
    && !showCallForm && calls.length > 0
      ? callBtn
      : null
  ), [
    callBtn,
    calls.length,
    role.TCM.ADD_CALL_TO_OFFER,
    showCallForm,
    showRecallMessage,
  ]);

  const showMoreResultsBtnText = useMemo(() => {
    const number = calls.length - 2;
    return (showMoreResults ? 'Скрыть' : `Еще ${number} ${getDeclension(number, 'звонок')}`);
  },
  [calls.length, showMoreResults]);

  const callsResults = useMemo(
    () => (showMoreResults ? calls : calls.slice(0, 2)),
    [calls, showMoreResults],
  );

  return (
    <PageBlock title="Звонки" btnInHeader={buttonInCallsHeader}>
      {callsResults.length > 0
        ? (
          <>
            {showRecallMessage && (
              <RecallMessageRow>
                {recallMessage}
                {
                  checkPrivilege(role.TCM.ADD_CALL_TO_OFFER)
                  && callBtn
                }
              </RecallMessageRow>
            )}

            {showCallForm && (
              <CallForm clientGuid={clientGuid} onCancel={onCancelCall} />
            )}

            <div>
              {callsResults.map(
                (result, index) =>
                  // eslint-disable-next-line react/no-array-index-key
                  <CallResult result={result} key={result.status + index} />,
              )}
            </div>

            {calls.length > 2 && (
              <Wrapper>
                <TCM.ButtonText onClick={onShowMoreResultClick}>
                  {showMoreResultsBtnText}
                </TCM.ButtonText>
              </Wrapper>
            )}
          </>
        )
        : showCallForm
          ? <CallForm clientGuid={clientGuid} onCancel={onCancelCall} />
          : (
            <EmptyContent>
              <CallsContent>
                Пока не было ни одного звонка
              </CallsContent>
              {
                checkPrivilege(role.TCM.ADD_CALL_TO_OFFER)
                && callBtn
              }
            </EmptyContent>
          )}
    </PageBlock>
  );
};

const ConnectWithStore = connect(
  (state) => ({
    role: state.auth.role,
  }),
)(CallResults);

export { ConnectWithStore as CallResults };
