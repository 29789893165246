/* List */
export const OFFER_FETCH_LIST = 'OFFER_FETCH_LIST';
export const OFFER_FETCH_LIST__SUCCESS = 'OFFER_FETCH_LIST__SUCCESS';
export const OFFER_FETCH_LIST__FAILURE = 'OFFER_FETCH_LIST__FAILURE';
export const OFFER_FETCH_LIST__CANCEL_REQUEST = 'OFFER_FETCH_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_LIST__SET_LOADING = 'OFFER_FETCH_LIST__SET_LOADING';
export const OFFER_FETCH_LIST__SET_PAGINATION = 'OFFER_FETCH_LIST__SET_PAGINATION';
export const fetchList = ({
  query,
  filters,
  sorting,
} = {}) => ({
  type: OFFER_FETCH_LIST,
  query,
  filters,
  sorting,
});

export const OFFER_FETCH_COMMON__SET_SEARCH = 'OFFER_FETCH_COMMON__SET_SEARCH';
export const setCommonSearch = ({ search } = {}) => ({ type: OFFER_FETCH_COMMON__SET_SEARCH, search });

export const OFFER_FETCH_UNDERWAY_LIST = 'OFFER_FETCH_UNDERWAY_LIST';
export const OFFER_FETCH_UNDERWAY_LIST__SUCCESS = 'OFFER_FETCH_UNDERWAY_LIST__SUCCESS';
export const OFFER_FETCH_UNDERWAY_LIST__FAILURE = 'OFFER_FETCH_UNDERWAY_LIST__FAILURE';
export const OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST = 'OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_UNDERWAY_LIST__SET_LOADING = 'OFFER_FETCH_UNDERWAY_LIST__SET_LOADING';
export const OFFER_FETCH_UNDERWAY_LIST__SET_SORTING = 'OFFER_FETCH_UNDERWAY_LIST__SET_SORTING';
export const OFFER_FETCH_UNDERWAY_LIST__SET_FILTER = 'OFFER_FETCH_UNDERWAY_LIST__SET_FILTER';
export const OFFER_FETCH_UNDERWAY_LIST__SET_SEARCH = 'OFFER_FETCH_UNDERWAY_LIST__SET_SEARCH';
export const OFFER_FETCH_UNDERWAY_LIST__RESET = 'OFFER_FETCH_UNDERWAY_LIST__RESET';
export const fetchUnderwayList = () => ({
  type: OFFER_FETCH_UNDERWAY_LIST,
});
export const setUnderwayFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_UNDERWAY_LIST__SET_FILTER,
  filters,
});
export const setUnderwaySorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_UNDERWAY_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_WAIT_CONFIRM_LIST = 'OFFER_FETCH_WAIT_CONFIRM_LIST';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__SUCCESS = 'OFFER_FETCH_WAIT_CONFIRM_LIST__SUCCESS';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__FAILURE = 'OFFER_FETCH_WAIT_CONFIRM_LIST__FAILURE';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST = 'OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__SET_LOADING = 'OFFER_FETCH_WAIT_CONFIRM_LIST__SET_LOADING';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING = 'OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER = 'OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SEARCH = 'OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SEARCH';
export const OFFER_FETCH_WAIT_CONFIRM_LIST__RESET = 'OFFER_FETCH_WAIT_CONFIRM_LIST__RESET';
export const fetchWaitConfirmList = () => ({
  type: OFFER_FETCH_WAIT_CONFIRM_LIST,
});
export const setWaitConfirmFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER,
  filters,
});
export const setWaitConfirmSorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_CONFIRMED_LIST = 'OFFER_FETCH_CONFIRMED_LIST';
export const OFFER_FETCH_CONFIRMED_LIST__SUCCESS = 'OFFER_FETCH_CONFIRMED_LIST__SUCCESS';
export const OFFER_FETCH_CONFIRMED_LIST__FAILURE = 'OFFER_FETCH_CONFIRMED_LIST__FAILURE';
export const OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST = 'OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_CONFIRMED_LIST__SET_LOADING = 'OFFER_FETCH_CONFIRMED_LIST__SET_LOADING';
export const OFFER_FETCH_CONFIRMED_LIST__SET_SORTING = 'OFFER_FETCH_CONFIRMED_LIST__SET_SORTING';
export const OFFER_FETCH_CONFIRMED_LIST__SET_FILTER = 'OFFER_FETCH_CONFIRMED_LIST__SET_FILTER';
export const OFFER_FETCH_CONFIRMED_LIST__SET_SEARCH = 'OFFER_FETCH_CONFIRMED_LIST__SET_SEARCH';
export const OFFER_FETCH_CONFIRMED_LIST__RESET = 'OFFER_FETCH_CONFIRMED_LIST__RESET';
export const fetchConfirmedList = () => ({
  type: OFFER_FETCH_CONFIRMED_LIST,
});
export const setConfirmedFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_CONFIRMED_LIST__SET_FILTER,
  filters,
});
export const setConfirmedSorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_CONFIRMED_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_REVISION_LIST = 'OFFER_FETCH_REVISION_LIST';
export const OFFER_FETCH_REVISION_LIST__SUCCESS = 'OFFER_FETCH_REVISION_LIST__SUCCESS';
export const OFFER_FETCH_REVISION_LIST__FAILURE = 'OFFER_FETCH_REVISION_LIST__FAILURE';
export const OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST = 'OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_REVISION_LIST__SET_LOADING = 'OFFER_FETCH_REVISION_LIST__SET_LOADING';
export const OFFER_FETCH_REVISION_LIST__SET_SORTING = 'OFFER_FETCH_REVISION_LIST__SET_SORTING';
export const OFFER_FETCH_REVISION_LIST__SET_FILTER = 'OFFER_FETCH_REVISION_LIST__SET_FILTER';
export const OFFER_FETCH_REVISION_LIST__SET_SEARCH = 'OFFER_FETCH_REVISION_LIST__SET_SEARCH';
export const OFFER_FETCH_REVISION_LIST__RESET = 'OFFER_FETCH_REVISION_LIST__RESET';
export const fetchRevisionList = () => ({
  type: OFFER_FETCH_REVISION_LIST,
});
export const setRevisionFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_REVISION_LIST__SET_FILTER,
  filters,
});
export const setRevisionSorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_REVISION_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_AGREEING_LIST = 'OFFER_FETCH_AGREEING_LIST';
export const OFFER_FETCH_AGREEING_LIST__SUCCESS = 'OFFER_FETCH_AGREEING_LIST__SUCCESS';
export const OFFER_FETCH_AGREEING_LIST__FAILURE = 'OFFER_FETCH_AGREEING_LIST__FAILURE';
export const OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST = 'OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_AGREEING_LIST__SET_LOADING = 'OFFER_FETCH_AGREEING_LIST__SET_LOADING';
export const OFFER_FETCH_AGREEING_LIST__SET_SORTING = 'OFFER_FETCH_AGREEING_LIST__SET_SORTING';
export const OFFER_FETCH_AGREEING_LIST__SET_FILTER = 'OFFER_FETCH_AGREEING_LIST__SET_FILTER';
export const OFFER_FETCH_AGREEING_LIST__SET_SEARCH = 'OFFER_FETCH_AGREEING_LIST__SET_SEARCH';
export const OFFER_FETCH_AGREEING_LIST__RESET = 'OFFER_FETCH_AGREEING_LIST__RESET';
export const fetchAgreeingList = () => ({
  type: OFFER_FETCH_AGREEING_LIST,
});
export const setAgreeingFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_AGREEING_LIST__SET_FILTER,
  filters,
});
export const setAgreeingSorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_AGREEING_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_REFUSED_LIST = 'OFFER_FETCH_REFUSED_LIST';
export const OFFER_FETCH_REFUSED_LIST__SUCCESS = 'OFFER_FETCH_REFUSED_LIST__SUCCESS';
export const OFFER_FETCH_REFUSED_LIST__FAILURE = 'OFFER_FETCH_REFUSED_LIST__FAILURE';
export const OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST = 'OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST';
export const OFFER_FETCH_REFUSED_LIST__SET_LOADING = 'OFFER_FETCH_REFUSED_LIST__SET_LOADING';
export const OFFER_FETCH_REFUSED_LIST__SET_SORTING = 'OFFER_FETCH_REFUSED_LIST__SET_SORTING';
export const OFFER_FETCH_REFUSED_LIST__SET_FILTER = 'OFFER_FETCH_REFUSED_LIST__SET_FILTER';
export const OFFER_FETCH_REFUSED_LIST__SET_SEARCH = 'OFFER_FETCH_REFUSED_LIST__SET_SEARCH';
export const OFFER_FETCH_REFUSED_LIST__RESET = 'OFFER_FETCH_REFUSED_LIST__RESET';
export const fetchRefusedList = () => ({
  type: OFFER_FETCH_REFUSED_LIST,
});
export const setRefusedFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_REFUSED_LIST__SET_FILTER,
  filters,
});
export const setRefusedSorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_REFUSED_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_LIST__SET_SEARCH = 'OFFER_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({ type: OFFER_FETCH_LIST__SET_SEARCH, search });

export const OFFER_FETCH_LIST__SET_PAGE = 'OFFER_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: OFFER_FETCH_LIST__SET_PAGE, page });

export const OFFER_FETCH_LIST__SET_SORTING = 'OFFER_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: OFFER_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const OFFER_FETCH_LIST__SET_FILTER = 'OFFER_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: OFFER_FETCH_LIST__SET_FILTER,
  filters,
});

export const OFFER_FETCH_LIST__RESET = 'OFFER_FETCH_LIST__RESET';
export const reset = () => ({ type: OFFER_FETCH_LIST__RESET });

/* Evaluation set (to draft) */
export const OFFER_EVALUATION_SET = 'OFFER_EVALUATION_SET';
export const OFFER_EVALUATION_SET__SUCCESS = 'OFFER_EVALUATION_SET__SUCCESS';
export const OFFER_EVALUATION_SET__FAILURE = 'OFFER_EVALUATION_SET__FAILURE';
export const OFFER_EVALUATION_SET__RESET = 'OFFER_EVALUATION_SET__RESET';

export const setEvaluation = ({
  evaluations,
}) => ({
  type: OFFER_EVALUATION_SET,
  evaluations,
});

export const resetSetEvaluation = () => ({ type: OFFER_EVALUATION_SET__RESET });

/* Offer (Client info) */
export const OFFER_FETCH = 'OFFER_FETCH';
export const OFFER_FETCH__SUCCESS = 'OFFER_FETCH__SUCCESS';
export const OFFER_FETCH__FAILURE = 'OFFER_FETCH__FAILURE';
export const OFFER_FETCH__RESET = 'OFFER_FETCH__RESET';
export const fetchItem = ({ id }) => ({
  type: OFFER_FETCH,
  id,
});

/* setEmployee */
export const OFFER_EMPLOYEE_SET = 'OFFER_EMPLOYEE_SET';
export const OFFER_EMPLOYEE_SET__SUCCESS = 'OFFER_EMPLOYEE_SET__SUCCESS';

export const OFFER_EMPLOYEE_SET__FAILURE = 'OFFER_EMPLOYEE_SET__FAILURE';
export const setEmployee = ({
  employees,
}) => ({ type: OFFER_EMPLOYEE_SET, employees });

export const OFFER_EMPLOYEE_SET__RESET = 'OFFER_EMPLOYEE_SET__RESET';
export const setEmployeeReset = () => ({ type: OFFER_EMPLOYEE_SET__RESET });

/* exportOffers */
export const OFFER_EXPORT = 'OFFER_EXPORT';
export const OFFER_EXPORT__SUCCESS = 'OFFER_EXPORT__SUCCESS';
export const OFFER_EXPORT__FAILURE = 'OFFER_EXPORT__FAILURE';
export const offerExport = ({ id }) => ({ type: OFFER_EXPORT, id });

/* importOffers */
export const OFFER_IMPORT = 'OFFER_IMPORT';
export const OFFER_IMPORT__SUCCESS = 'OFFER_IMPORT__SUCCESS';
export const OFFER_IMPORT__FAILURE = 'OFFER_IMPORT__FAILURE';
export const offerImport = ({ formData }) => ({ type: OFFER_IMPORT, formData });

export const OFFER_EXPORT_IMPORT__RESET = 'OFFER_EXPORT_IMPORT__RESET';
export const offerExportImportReset = () => ({ type: OFFER_EXPORT_IMPORT__RESET });

/* Offers status set to bank */
export const OFFERS_STATUS_SET = 'OFFERS_STATUS_SET';
export const OFFERS_STATUS_SET_SUCCESS = 'OFFERS_STATUS_SET_SUCCESS';
export const OFFERS_STATUS_SET__FAILURE = 'OFFERS_STATUS_SET__FAILURE';
export const OFFERS_STATUS_SET__RESET = 'OFFERS_STATUS_SET__RESET';
export const resetOffersStatus = () => ({ type: OFFERS_STATUS_SET__RESET });

export const setOffersStatus = ({
  offerIds,
  status,
}) => ({
  type: OFFERS_STATUS_SET, offerIds, status,
});

export const OFFER_ADD_COMMENT = 'OFFER_ADD_COMMENT';
export const OFFER_ADD_COMMENT__SUCCESS = 'OFFER_ADD_COMMENT__SUCCESS';
export const OFFER_ADD_COMMENT__FAILURE = 'OFFER_IMPORT__FAILURE';
export const offerAddComment = ({ text }) => ({ type: OFFER_ADD_COMMENT, text });

export const POST_VISIT_RESULT = 'POST_VISIT_RESULT';
export const POST_VISIT_RESULT__SUCCESS = 'POST_VISIT_RESULT__SUCCESS';
export const POST_VISIT_RESULT__FAILURE = 'POST_VISIT_RESULT__FAILURE';
export const POST_VISIT_RESULT__RESET = 'POST_VISIT_RESULT__RESET';
export const postVisitResult = ({ formData }) => ({ type: POST_VISIT_RESULT, formData });
export const resetVisitResult = () => ({ type: POST_VISIT_RESULT__RESET });

export const OFFER_FETCH_STATUSES = 'OFFER_FETCH_STATUSES';
export const OFFER_FETCH_STATUSES__SUCCESS = 'OFFER_FETCH_STATUSES__SUCCESS';
export const OFFER_FETCH_STATUSES__FAILURE = 'OFFER_FETCH_STATUSES__FAILURE';
export const fetchStatuses = () => ({ type: OFFER_FETCH_STATUSES });

/* exportOffers */
export const OLD_CAR_EXPORT = 'OLD_CAR_EXPORT';
export const OLD_CAR_EXPORT__SUCCESS = 'OLD_CAR_EXPORT__SUCCESS';
export const OLD_CAR_EXPORT__FAILURE = 'OLD_CAR_EXPORT__FAILURE';
export const oldCarExport = ({ id }) => ({ type: OLD_CAR_EXPORT, id });

export const OLD_CAR_EXPORT__RESET = 'OLD_CAR_EXPORT__RESET';
export const oldCarExportReset = () => ({ type: OLD_CAR_EXPORT__RESET });

export const POST_ADDITIONAL_PHONE = 'POST_ADDITIONAL_PHONE';
export const POST_ADDITIONAL_PHONE__SUCCESS = 'POST_ADDITIONAL_PHONE__SUCCESS';
export const POST_ADDITIONAL_PHONE__FAILURE = 'POST_ADDITIONAL_PHONE__FAILURE';
export const postAdditionalPhone = ({
  externalId,
  additionalPhone,
}) => ({
  type: POST_ADDITIONAL_PHONE,
  payload: {
    externalId,
    additionalPhone,
  },
});

export const OFFER_FETCH_PREVIOUS_CAMPAIGN = 'OFFER_FETCH_PREVIOUS_CAMPAIGN';
export const OFFER_FETCH_PREVIOUS_CAMPAIGN__SUCCESS = 'OFFER_FETCH_PREVIOUS_CAMPAIGN__SUCCESS';
export const OFFER_FETCH_PREVIOUS_CAMPAIGN__FAILURE = 'OFFER_FETCH_PREVIOUS_CAMPAIGN__FAILURE';
export const OFFER_FETCH_PREVIOUS_CAMPAIGN__RESET = 'OFFER_FETCH_PREVIOUS_CAMPAIGN__RESET';
export const fetchPreviousCampaign = ({ externalId } = {}) => ({
  type: OFFER_FETCH_PREVIOUS_CAMPAIGN,
  externalId,
});

export const fetchPreviousCampaignReset = ({ externalId } = {}) => ({
  type: OFFER_FETCH_PREVIOUS_CAMPAIGN__RESET,
  externalId,
});
