import styled from 'styled-components';

const Root = styled.div`
  display: grid;
  grid-template-columns: ${({ length }) => (length > 4 ? `repeat(${length}, auto)` : 'repeat(4, auto)')};
  grid-gap: 10px;
  margin-top: 10px;
  align-items: center;
`;

export { Root };
