import API from 'services';

import { extractErrorAndCode, formatForm } from 'hacks';

import {
  all, call, fork, put, select, takeLatest,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import { USER_STATUS__TRY_TO_RESET } from 'redux/userStatus/actions';

import {
  CREDIT__FETCH,
  CREDIT__FETCH_SUCCESS,
  CREDIT__FETCH_FAILURE,
  CREDIT__PATCH,
  CREDIT__PATCH_SUCCESS,
  CREDIT__PATCH_FAILURE,
} from './actions';

const getCreditState = (state) => state.credit;

export function* fetch() {
  yield takeLatest(CREDIT__FETCH, function* (action) {
    try {
      yield put({ type: USER_STATUS__TRY_TO_RESET });
      const response = yield call(API.credit.fetch, { accessToken: action.accessToken });

      if (response.data && response.data.fields && response.data.fields.length > 0) {
        yield put({ type: CREDIT__FETCH_SUCCESS, fields: response.data.fields });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT__FETCH_FAILURE, error: error.message });
    }
  });
}

export function* patch() {
  yield takeLatest(CREDIT__PATCH, function* (action) {
    try {
      const { userInput, exclude = [] } = action;

      const {
        values,
        requestQueue,
        fetch: { id: accessToken },
      } = yield select(getCreditState);

      const latestForm = { ...values, ...requestQueue };
      const dirtyForm = { ...latestForm, ...userInput };

      const cleanedForm = Array.isArray(exclude) && exclude.length > 0
        ? Object.keys(dirtyForm).reduce(
          (o, k) => (exclude.includes(k) ? o : { ...o, [k]: dirtyForm[k] }),
          {},
        )
        : dirtyForm;

      const formatedForm = formatForm(cleanedForm);

      const response = yield call(API.credit.patch, { form: formatedForm, accessToken });
      yield put({ type: USER_STATUS__TRY_TO_RESET });

      if (response.data && response.data.fields && response.data.fields.length > 0) {
        yield put({
          type: CREDIT__PATCH_SUCCESS,
          userInput: action.userInput,
          fields: response.data.fields,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: CREDIT__PATCH_FAILURE,
        userInput: action.userInput,
        error: error.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetch), fork(patch)]);
}
