import React from 'react';
import { HiddenInput } from './style';

/*
Hidden Field component was created to store the value of hidden input in the form
(as opposite of storing it in the redux store)
Main reason - Product Field has 2 dependencies - one of them is stored in hidden field,
another one is a selectWithoutSubmit component
So in order to achieve consistensy, we use getFieldDecorator method from Ant.
Form component to store values in the .form props
And mutate the state of selectWithoutSubmit - we can save it in the form,
but do not pass to the server
*/
function Hidden({
  name,

  form: { getFieldDecorator },

  initialValue,
}) {
  return getFieldDecorator(name, {
    initialValue: initialValue || '',
  })(<HiddenInput name={name} />);
}

export default Hidden;
