export const BREADCRUMBS_SET = 'BREADCRUMBS_SET';
export const setBreadcrumbs = (tree) => ({ type: BREADCRUMBS_SET, tree });

export const BREADCRUMBS_BLOCK_LINKS = 'BREADCRUMBS_BLOCK_LINKS';
export const breadCrumbsBlockLink = () => ({
  type: BREADCRUMBS_BLOCK_LINKS,
});

export const BREADCRUMBS_REDIRECT = 'BREADCRUMBS_REDIRECT';
export const breadCrumbsRedirect = () => ({
  type: BREADCRUMBS_REDIRECT,
});

export const BREADCRUMBS_SHOW_UNBLOCK_MODAL = 'BREADCRUMBS_SHOW_UNBLOCK_MODAL';
export const breadcrumbsShowUnblockModal = () => ({
  type: BREADCRUMBS_SHOW_UNBLOCK_MODAL,
});

export const BREADCRUMBS_HIDE_UNBLOCK_MODAL = 'BREADCRUMBS_HIDE_UNBLOCK_MODAL';
export const breadcrumbsHideUnblockModal = () => ({
  type: BREADCRUMBS_HIDE_UNBLOCK_MODAL,
});

export const BREADCRUMBS_RESET_BLOCK_LINKS = 'BREADCRUMBS_RESET_BLOCK_LINKS';
export const breadcrumbsResetBlockLinks = () => ({
  type: BREADCRUMBS_RESET_BLOCK_LINKS,
});
