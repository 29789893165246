import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logout as logoutAction } from 'redux/auth/actions';
import Campaigns from 'components/tcm/Sidebars/Campagins';
import Clients from 'components/tcm/Sidebars/Clients';
import { getLocalStorageToken } from 'helpers/storage';

import { TCM_MANUAL_URL } from 'settings/api';
import { Tooltip } from 'antd';
import { checkPrivilege } from 'helpers/roles';

import { Link } from 'react-router-dom';
import {
  SecontaryHeader,
  LinkName,
  LinkStyled,
  Nav,
  ProfileRole,
  Title,
  ProfileContainer,
  Header,
  ProfileInfo,
  ProfileName,
  Logout,
  Logo,
} from '../style';

const Wrapper = styled.div`
  min-height: 100vh;
`;

const StyledHeader = styled(Header)`
  border-bottom: 1px solid #e5e5e5;
`;

const SideBar = styled.div`
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 120px;
  max-width: 1440px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 164px 1020px;
  column-gap: 16px;
`;

const Main = styled.main`
  margin-top: 42px;
`;

export const DownloadLink = styled.a`
  margin: 20px 0;
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000;

  &:hover {
    color: #E50000;
  }
`;

const Roles = ({ roles = '' }) => {
  const allRoles = (roles && roles.split(',')) || [];
  const popoverText = allRoles.length > 1 ? roles : '';
  return (
    <Tooltip title={popoverText}>
      <ProfileRole>
        {allRoles[0]}
        {popoverText && '...'}
      </ProfileRole>
    </Tooltip>
  );
};

const Layout = ({ children, logout, role }) => {
  const [isClients, setIsClients] = useState(null);
  const displayRole = getLocalStorageToken('ROLE_NAME');
  const displayName = getLocalStorageToken('DISPLAY_NAME');

  const managementRights = [
    role.MANAGEMENT.VIEW_USER,
    role.MANAGEMENT.VIEW_USER_INTERNAL,
    role.ORGANISATIONS.VIEW__ALL,
    role.ORGANISATIONS.VIEW_POINTS_OF_SALES,
  ];

  const leadsRights = [
    role.LEADS.NAVIGATION,
    role.LEADS.EDIT,
    role.LEADS.READ,
  ];

  const paymentsRights = [
    role.PAYMENTS.VIEW,
    role.PAYMENTS.VIEW_ORGANISATION_BILLS,
    role.PAYMENTS.VIEW_MY_BILLS,
    role.ACQUIRING_AGREEMENT.VIEW,
  ];

  const links = [
    {
      url: '/app/management',
      name: 'управление',
      checkRights: checkPrivilege(managementRights),
    },
    {
      url: '/app/payments',
      name: 'эквайринг',
      checkRights: checkPrivilege(paymentsRights),
    },
    {
      url: '/app/leads',
      name: 'онлайн-заявка',
      checkRights: checkPrivilege(leadsRights),
    },
    {
      url: '/tcm',
      name: 'ТСМ',
      checkRights: true,
    },
  ];

  const filteredLinks = links.filter((item) => item.checkRights);

  useEffect(() => {
    setIsClients(/client/gm.test(window.location.pathname));
  }, [setIsClients]);

  return (
    <>
      <SecontaryHeader>
        <Logout to="/" onClick={logout}>
          Выйти
        </Logout>
      </SecontaryHeader>
      <StyledHeader>
        <Title>
          <Link to="/">
            <Logo />
          </Link>
        </Title>
        <Nav>
          {filteredLinks.length > 0 && filteredLinks.map(({ name, url }) => (
            <LinkStyled to={url} key={url}>
              <LinkName>{name}</LinkName>
            </LinkStyled>
          ))}
        </Nav>
        <ProfileContainer>
          <ProfileInfo>
            <ProfileName>{displayName}</ProfileName>
            <Roles roles={displayRole} />
          </ProfileInfo>
        </ProfileContainer>
      </StyledHeader>
      <Wrapper>
        <Container>
          <Content>
            <SideBar>
              {isClients !== null && (isClients ? <Clients /> : <Campaigns />)}
              {TCM_MANUAL_URL && (
                <DownloadLink href={TCM_MANUAL_URL}>
                  Скачать руководство пользователя
                </DownloadLink>
              )}
            </SideBar>
            <Main>{children}</Main>
          </Content>
        </Container>
      </Wrapper>
    </>
  );
};

export default connect(
  (state) => ({
    role: state.auth.role,
  }),
  { logout: logoutAction },
)(memo(Layout));
