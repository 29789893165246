import styled from 'styled-components';

export const Root = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 2rem 4rem 0;
`;

export const ErrorMessage = styled.div`
  color: red;
`;
