import {
  FILES_FETCH_LIST,
  FILES_FETCH_LIST__SUCCESS,
  FILES_FETCH_LIST__FAILURE,
  FILES_FETCH_LIST__RESET,

  FILE_UPLOAD,
  FILE_UPLOAD__SUCCESS,
  FILE_UPLOAD__FAILURE,
  FILE_UPLOAD__RESET,

  FILE_DOWNLOAD,
  FILE_DOWNLOAD__SUCCESS,
  FILE_DOWNLOAD__FAILURE,
  FILE_DOWNLOAD__RESET,

  FILE_DELETE,
  FILE_DELETE__SUCCESS,
  FILE_DELETE__FAILURE,
} from './actions';

const initState = {
  collection: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  upload: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  download: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  delete: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case FILES_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case FILES_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case FILES_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case FILES_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case FILE_UPLOAD__RESET: {
      return {
        ...state,

        upload: {
          ...initState.upload,
        },
      };
    }

    case FILE_UPLOAD: {
      return {
        ...state,

        upload: {
          ...state.upload,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case FILE_UPLOAD__SUCCESS: {
      return {
        ...state,

        upload: {
          ...state.upload,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case FILE_UPLOAD__FAILURE: {
      return {
        ...state,

        upload: {
          ...state.upload,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case FILE_DOWNLOAD: {
      return {
        ...state,

        download: {
          ...state.download,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case FILE_DOWNLOAD__SUCCESS: {
      return {
        ...state,

        download: {
          ...state.download,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case FILE_DOWNLOAD__FAILURE: {
      return {
        ...state,

        download: {
          ...state.download,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case FILE_DOWNLOAD__RESET: {
      return {
        ...state,

        download: {
          ...initState.download,
        },
      };
    }

    case FILE_DELETE: {
      return {
        ...state,

        delete: {
          ...state.delete,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case FILE_DELETE__SUCCESS: {
      return {
        ...state,

        delete: {
          ...state.delete,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case FILE_DELETE__FAILURE: {
      return {
        ...state,

        delete: {
          ...state.delete,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
