import { DEFAULT_ERROR_MESSAGE } from 'settings';

const formatError = ({ message = '', code = '' } = {}) =>
  message || (code && `Код ошибки #${code}`) || DEFAULT_ERROR_MESSAGE;

/*
  o this function is for formatting the error message
  We need `code` for handling logout
  We need `message` for showing user an error message (string type)
*/
const extractError = (response) => {
  const { errorMessage: message, errorCode: code, data } = response;

  if (message || code) {
    return { message, code };
  }
  if (typeof data === 'object') {
    const { errorMessage: message, errorCode: code, exceptionList } = data;
    if (message || code) {
      return { message, code };
    }
    if (Array.isArray(exceptionList) && exceptionList.length > 0) {
      const { errorMessage: message, errorCode: code } = exceptionList[0];
      return { message, code };
    }
    return { message: null, code: null };
  }
};

export const extractErrorAndCode = (response) => {
  const { message, code } = extractError(response);
  return { error: formatError({ message, code }), code };
};
