import styled from 'styled-components';
import { Typography } from 'antd';

const TextStyled = styled(Typography.Text)`
  // override styles
`;

const TitleStyled = styled(Typography.Title)`
  && {
    h1& {
      font-size: 1.4rem;
      margin: 0.7rem 0 0.35rem;
    }
    h2& {
      font-size: 1.2rem;
      margin: 0.6rem 0 0.3rem;
    }
    h3& {
      font-size: 1rem;
      margin: 0.5rem 0 0.25rem;
    }
    h4& {
      font-size: 0.8rem;
      margin: 0.4rem 0 0.2rem;
    }
  }
`;

const ParagraphStyled = styled(Typography.Paragraph)`
  // override styles
`;

export { TextStyled, TitleStyled, ParagraphStyled };
