import { convertResponseDateToMoment } from 'helpers/date';

export const formatCampaignResponse = (response) => {
  if (
    Array.isArray(response.campaignItemResponseList)
    && Array.isArray(response.topicItemResponseList)
    && Array.isArray(response.subtopicItemResponseList)
  ) {
    return response.campaignItemResponseList.reduce((prev, curr) => {
      const model = response.subtopicItemResponseList
        .find((subtopic) => subtopic.id === curr.subtopicItemResponseList[0].id);
      const brand = response.topicItemResponseList
        .find((topic) => topic.id === model.topicItemResponseList[0].id);

      if (curr.dialingFrom === null || curr.dialingTo === null) {
        return prev;
      }

      return prev.concat({
        id: curr.id,
        brand: brand.name,
        model: model.name,
        dialingFrom: convertResponseDateToMoment(curr.dialingFrom).format('DD.MM'),
        dialingTo: convertResponseDateToMoment(curr.dialingTo).format('DD.MM.YYYY'),
      });
    }, []);
  }

  return [];
};
