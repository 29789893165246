import { buildRightsMatrix } from 'helpers/roles';

import {
  WRITE_AUTH_DATE_FROM_CACHE,
  LOGOUT,
  SIGN_IN_WITH_EMAIL,
  SIGN_IN_WITH_EMAIL__SUCCESS,
  SIGN_IN_WITH_EMAIL__FAILURE,

  REFETCH_PERMISSIONS,
  REFETCH_PERMISSIONS__SUCCESS,
  REFETCH_PERMISSIONS__FAILURE,
} from './actions';

const initState = {
  signInWithEmail: {
    data: {},
    username: '',
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  isLogout: false,

  idToken: null,
  permissionList: [],
  role: buildRightsMatrix([]),
  userId: null,
  expiredAt: null,
  isNeedChangedSoon: false,
  tcmMangerInfo: {
    subsidiaryIds: [],
    legalEntityIds: [],
  },
  isTCMUser: false,
  refetch: {
    isLoaded: false,
    isLoading: false,
    error: null,
  },
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case WRITE_AUTH_DATE_FROM_CACHE: {
      return {
        ...state,
        permissionList: action.permissionList,
        role: buildRightsMatrix(action.permissionList),
        idToken: action.token,
        userId: action.userId,
        tcmMangerInfo: action.tcmMangerInfo,
        isTCMUser: action.isTCMUser,
      };
    }

    case REFETCH_PERMISSIONS: {
      return {
        ...state,
        refetch: {
          isLoaded: true,
          isLoading: false,
          error: null,
        },
      };
    }
    case REFETCH_PERMISSIONS__SUCCESS: {
      return {
        ...state,
        permissionList: action.permissionList,
        role: buildRightsMatrix(action.permissionList),
        userId: action.userId,

        refetch: {
          isLoaded: true,
          isLoading: false,
          error: null,
        },
      };
    }
    case REFETCH_PERMISSIONS__FAILURE: {
      return {
        ...state,
        refetch: {
          isLoaded: false,
          isLoading: false,
          error: action.error,
        },
      };
    }

    case LOGOUT: {
      return {
        ...initState,
        permissionList: [],
        role: buildRightsMatrix([]),
        userId: null,
        idToken: null,
        isLogout: true,
        tcmMangerInfo: {},
        isTCMUser: false,
      };
    }

    case SIGN_IN_WITH_EMAIL: {
      localStorage.setItem('username', action.username);

      return {
        ...state,

        signInWithEmail: {
          data: {},
          username: action.username,
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case SIGN_IN_WITH_EMAIL__SUCCESS: {
      return {
        ...state,

        signInWithEmail: {
          ...state.signInWithEmail,
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
          errors: [],
        },

        permissionList: action.permissionList,
        role: buildRightsMatrix(action.permissionList),
        userId: action.userId,
        idToken: action.token,
        isLogout: false,
        expiredAt: action.expiredAt,
        isNeedChangedSoon: action.isNeedChangedSoon,
        tcmMangerInfo: action.tcmMangerInfo,
        isTCMUser: action.isTCMUser,
      };
    }
    case SIGN_IN_WITH_EMAIL__FAILURE: {
      return {
        ...state,

        signInWithEmail: {
          ...state.signInWithEmail,
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: action.errors,
        },
      };
    }

    default: {
      return state;
    }
  }
}
