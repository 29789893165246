import { CHAR } from 'settings/namedUnicode';

export const CAMPAIGN_STATUSES = {
  CREATION: 'CREATION',
  DRAFT: 'DRAFT',
  VIN_REQUEST: 'VIN_REQUEST',
  REVISION: 'REVISION',
  EVALUATION: 'EVALUATION',
  KP_CALCULATION: 'KP_CALCULATION',
  DISTRIBUTION: 'DISTRIBUTION',
  CALLING: 'CALLING',
  COMPLETE: 'COMPLETE',
  CLOSED: 'CLOSED',
  DELETED: 'DELETED',
  REVISION_KP_CALCULATION: 'REVISION_KP_CALCULATION',
};

export const STATUS_DATA = {
  [CAMPAIGN_STATUSES.CREATION]: {
    title: 'Создание',
    filter: 'create',
  },
  [CAMPAIGN_STATUSES.DRAFT]: {
    title: 'Черновик',
    filter: 'draft',
    positiveAction: {
      type: 'vinRequest',
      text: 'Запросить VIN',
    },
    negativeAction: {
      type: 'deleteCampaign',
      text: 'Удалить',
    },
  },
  [CAMPAIGN_STATUSES.VIN_REQUEST]: {
    title: `Подбор${CHAR.NBSP}VIN`,
    filter: 'vin',
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.REVISION]: {
    title: 'Доработка',
    filter: 'revision',
    positiveAction: {
      type: 'vinRequest',
      text: 'Запросить VIN',
    },
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.REVISION_KP_CALCULATION]: {
    title: 'Доработка',
    filter: 'revision',
    positiveAction: {
      type: 'calculation',
      text: 'Отправить в банк',
    },
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.EVALUATION]: {
    title: 'Оценка',
    filter: 'evaluation',
    positiveAction: {
      type: 'sendToBankEvaluation',
      text: 'Отправить в банк',
    },
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.KP_CALCULATION]: {
    title: `Расчет${CHAR.NBSP}КП`,
    filter: 'calculation',
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.DISTRIBUTION]: {
    title: 'Распределение клиентов',
    filter: 'distribution',
    // positiveAction: {
    //   onClick: () => console.info('revision'),
    //   text: 'Распределить',
    // },
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.CALLING]: {
    title: 'Начало обзвона',
    filter: 'start_call',
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.COMPLETE]: {
    title: 'Обзвон завершен',
    filter: 'end_call',
    negativeAction: {
      type: 'withdrawCampaign',
      text: 'Отозвать кампанию',
    },
  },
  [CAMPAIGN_STATUSES.CLOSED]: {
    title: 'Отозвана',
    // filter: 'end_call',
  },
};

export const CAMPAIGN_TIMESTAMPS = {
  DONE: 'DONE',
  CURRENT: 'CURRENT',
  PENDING: 'PENDING',
};

export const DATE_FORMAT = {
  DD_MM_YYYY: 'dd.mm.yyyy',
  DD_MM: 'dd.mm',
  DD_MM__HH_MM: 'dd.mm hh.mm',
};

export const MOMENT_DATE_FORMAT = {
  DD_MM_YYYY: 'DD.MM.YYYY',
  DD_MM: 'DD.MM',
  DD_MM__HH_MM: 'DD.MM hh.mm',
};
