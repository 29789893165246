export const initialState = {
  collapseAll: false,
  haveOpened: ['1', '2', '3', '4', '5'],
};

export const ACTIONS = {
  SHOW_ALL: 'SHOW_ALL',
  CLOSE_ALL: 'CLOSE_ALL',
  OPEN: 'OPEN',
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SHOW_ALL: {
      return {
        ...state,
        collapseAll: true,
        haveOpened: initialState.haveOpened,
      };
    }
    case ACTIONS.CLOSE_ALL: {
      return {
        ...state,
        collapseAll: false,
        haveOpened: [],
      };
    }
    case ACTIONS.OPEN: {
      let filteredArray;
      if (state.haveOpened.indexOf(action.key) !== -1) {
        filteredArray = state.haveOpened.filter((item) => item !== action.key);
      } else {
        filteredArray = ([...state.haveOpened, action.key]);
      }
      return {
        ...state,
        haveOpened: filteredArray,
      };
    }
    default: {
      return state;
    }
  }
}
