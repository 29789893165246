import styled from 'styled-components';
import { Button, Popover } from 'antd';

const COLOR_STATUS = {
  Возврат: '#E71313',
  Подтвержден: '#7B7B7B',
  'Ожидает оплаты': '#2E82FF',
  Зарезервирован: '#2E82FF',
};

export const Root = styled.div`
  a {
    color: #000;
    font-size: 16px;
  }

`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const Status = styled.div`
  color: ${(props) =>
    (COLOR_STATUS[props.status] ? COLOR_STATUS[props.status] : '#000')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const RepeatContainer = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s linear all;
  &:hover {
    color: #000;
  }
`;

export const RequisitesItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span {
    margin-right: 5px;
  }
`;

export const ItemsContent = styled.div`
  background: #fff;
  margin-top: 5px;
`;

export const ItemsContentContainer = styled(Popover)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Count = styled(Button)`
  background: red;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 10px;
  height: 26px;
  min-width: 26px;
  padding: 0;
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const ItemsContainer = styled.div`
  position: relative;
  color: #000;
`;

export const Inn = styled.div`
 font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #7E7E7E;
`;

export const Company = styled.div`
 font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;

export const Date = styled.div`
 
`;

export const Requisites = styled.div`
 
`;
