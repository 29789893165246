import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  legalEntityId,
}) => {
  const URI = `${API}/admin/document/list/by/legal-entity`;

  const data = {
    legalEntityId,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const uploadFile = ({
  legalEntityId,
  subsidiaryId,
  comment,
  fileName,
}) => {
  const URI = `${API}/admin/document/upload`;

  const data = {
    legalEntityId,
    subsidiaryId,
    comment,
    fileName,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const downloadFile = ({
  id,
}) => {
  const URI = `${API}/admin/document/download`;

  const data = {
    id,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const deleteFile = ({
  id,
}) => {
  const URI = `${API}/admin/document/delete`;

  const data = {
    id,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  uploadFile,
  deleteFile,
  downloadFile,
};
