import moment from 'moment';
import { DATE_FORMAT } from 'tcm/campaigns/data';

/**
 * Convert Date to String
 * @param {Date} date
 * @param {'dd.mm'|'dd.mm.yyyy'|'dd.mm hh.mm'} [format]
 * @returns {string}
 */
export const getStringFromDate = (date, format = DATE_FORMAT.DD_MM_YYYY) => {
  try {
    switch (format) {
      case DATE_FORMAT.DD_MM:
        return date.toLocaleString('ru-RU', { day: '2-digit', month: '2-digit' });
      case DATE_FORMAT.DD_MM__HH_MM:
        return `${date.toLocaleString('ru-RU', { day: '2-digit', month: '2-digit' })} ${date.toLocaleString('ru-RU', { hour: 'numeric', minute: 'numeric' })} `;
      case DATE_FORMAT.DD_MM_YYYY:
        return date.toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' });
      default:
        console.error('Invalid date format');
        return '';
    }
  } catch (e) {
    console.error('Type of "date" parameter must be "Date"!');
    return '';
  }
};

/**
 * @param {string} date in dd.mm.yyyy format
 * @return {string} date in yyyy-mm-dd format
 */
export const revertDate = (date = '') => {
  if (typeof date === 'string') {
    return date.split('.').reverse().join('-');
  }
  return '';
};

const dateIsObject = (date) => {
  if (typeof date === 'object') {
    const dateTemplate = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{4}$/;

    const isDate = typeof date.date === 'string' && dateTemplate.test(date.date);
    const isTimezone = typeof date.timezone_type === 'number';

    return isDate && isTimezone;
  }
  return false;
};

/**
 * @param {any} date
 * @return {moment.Moment}
 */
export const convertResponseDateToMoment = (date) => {
  const ISOTemplate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
  const backendTemplateWithSeconds = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  const backendTemplateWithoutSeconds = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

  if (ISOTemplate.test(date)) {
    return moment(date);
  }

  if (backendTemplateWithSeconds.test(date)) {
    return moment(date, 'YYYY-MM-DD HH:mm:ss');
  }
  if (backendTemplateWithoutSeconds.test(date)) {
    return moment(date, 'YYYY-MM-DD HH:mm');
  }
  if (dateIsObject(date)) {
    const preparedDate = date.date.split(-5);
      // Not sure about the sign change
    const timeZone = -date.timezone_type;
    return moment(preparedDate, 'YYYY-MM-DD HH:mm:ss')
      .startOf('day')
      .add(timeZone, 'h');
  }

  throw new Error(`Another date format is input. What else?
       date: ${date}`);
};

export const isDateExpired = (date) => moment(date, 'DD.MM.YYYY').isSameOrBefore(Date.now());
