import styled from 'styled-components';
import Typography from 'components/Typography';
import { GRID_AREAS } from 'settings';

const { Title: TypographyTitle } = Typography;

const Title = styled(TypographyTitle).attrs({ className: 'FieldsDescription' })`
  h1&.ant-typography,
  h2&.ant-typography,
  h3&.ant-typography,
  h4&.ant-typography {
    margin-top: 1rem;
    color: #8c8c8c;
    font-weight: 200;
  }
  ${({ area }) =>
    typeof area === 'boolean'
    && `
    grid-area: ${area ? GRID_AREAS.LEFT.DESCRIPTION : GRID_AREAS.RIGHT.DESCRIPTION};
  `}
`;

export { Title };
