import React from 'react';
import styled from 'styled-components';
import { GRID_AREAS } from 'settings';
import { Input as InputAntD } from 'antd';

const InputStyled = styled(({ area, length, ...p }) => <InputAntD {...p} />).attrs({ className: 'styledInput' })`
  ${({ area }) => typeof area === 'boolean' && `
    grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
  `}

  & .ant-input-affix-wrapper,
  & .ant-input,
  &.ant-input {
    ${({ area }) => typeof area === 'boolean' && `
      grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
    `}

    ${({ length }) => length && `
      min-width: ${length}ch;
      max-width: ${length}ch;
    `}

    &.ant-input-lg{
      padding: 12px 11px 11px;
    }
  }

  & .ant-input-wrapper,
  & .ant-input-affix-wrapper {
    display: flex;

    .ant-input-group-addon{
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 50;
      default:
        return 32;
    }
  }}px;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  & .ant-select-selection-item {
    text-overflow: unset;
  }

  &.ant-input-affix-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }


  &.ant-input-lg, &.ant-input-affix-wrapper-lg {
    border-radius: 5px;
  }
`;

const SearchStyled = styled(InputAntD.Search).attrs({
  className: 'styledInput',
})`
  && {
    & .ant-input-affix-wrapper
    & .ant-input,
    &.ant-input {
      ${({ area }) => typeof area === 'boolean' && `
        grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
      `}
    }

    & .ant-input-group-addon {
      .ant-btn-primary {
        height: 40px;
        background: ${({ buttonRedColor }) => (buttonRedColor ? '#e50000' : '#9f9f9f')};
        border-color: ${({ buttonRedColor }) => (buttonRedColor ? '#e50000' : '#9f9f9f')};
      }
    }
  }
`;

const PasswordStyled = styled(InputAntD.Password).attrs({
  className: 'styledInput',
})`
  & .ant-input,
  &.ant-input {
    ${({ area }) => typeof area === 'boolean' && `
      grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
    `}

    &.ant-input-lg{
      padding: 12px 11px 11px;
    }
  }

  &.ant-input-affix-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.ant-input-affix-wrapper-lg {
    border-radius: 5px;
  }

`;

const TextAreaStyled = styled(InputAntD.TextArea).attrs({
  className: 'styledInput',
})`

  &.ant-input {
    height: auto;
    border-radius: 5px;
  }

`;

export {
  InputStyled, SearchStyled, PasswordStyled, TextAreaStyled,
};
