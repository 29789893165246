import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const call = ({ accessToken }) => {
  const URI = `${API}/creditrequest/ping/${accessToken}`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
    }),
  };

  return fetch(URI, query)
    .then((response) => response)
    .catch((error) => console.error(error));
};

export default {
  call,
};
