import styled from 'styled-components';
import { Form } from 'antd';

export const Root = styled.div`
    padding-top: 2rem;
    margin-top: 0;
    width: 100%;
`;

export const DeleteButton = styled.button`
    color: red;
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none !important;
    cursor: pointer;
`;

export const AddPointButtonContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 71px;
    
    button{
      width: 306px;
    }
`;

export const AddPointContainer = styled(Form)`
    background: #F2F2F2;
    margin-top: 11px;
    padding: 48px 30px 30px;
    display: grid;
    grid-template-columns: 334px 306px;
    grid-column-gap: 70px;
    align-items: center;
    margin-bottom: 87px;
    
    .ant-row.ant-form-item{
      margin-bottom: 0;
    }
    
    .ant-select-selection-item{
      display: flex;
      align-items: center;
    }
   
    button.ant-btn {
      height: 40px !important;
      padding: 8px 24px !important;
    }
   
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;
