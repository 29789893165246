import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';

import {
  CommentContainer,
  CommentNameDate,
  CommentsContainer,
  CommentText,
  CommentsTitle,
  GlobalStyle,
} from './style';

const Comment = ({ adminName, createdAt, comment }) => (
  <CommentContainer>
    <CommentNameDate>
      <span>{adminName}</span>
      <span>
        {' '}
        {moment(createdAt).isValid() ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : 'Нет данных'}
      </span>
    </CommentNameDate>
    <CommentText>
      {comment}
    </CommentText>
  </CommentContainer>
);

const Comments = (comments) => (
  <CommentsContainer>
    {comments.map((item) => <Comment key={item.id} {...item} />)}
  </CommentsContainer>
);

const CommentsModal = ({
  // passed
  comments,
  isModalVisible,
  hideModal,
}) => (
  <>
    <GlobalStyle />
    <Modal
      width={620}
      title={<CommentsTitle>Комментарии к заявке</CommentsTitle>}
      visible={isModalVisible}
      onCancel={hideModal}
      footer={null}
    >
      {Comments(comments)}
    </Modal>
  </>
);

export default CommentsModal;
