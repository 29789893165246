import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';

import {
  blockUser as blockUserAction,
  blockUserReset as blockUserResetAction,
  unblockUser as unblockUserAction,
  unblockUserReset as unblockUserResetAction,
  restoreUserPassword as restoreUserPasswordAction,
  restoreUserPasswordReset as restoreUserPasswordResetAction,
} from 'redux/user/actions';

import {
  Root, Title, Footer, ErrorMessage, SuccessMessage,
} from './style';

const ACTION = {
  BLOCK: 'block',
  UNBLOCK: 'unblock',
  RESTORE: 'restore',
};

function ConfirmAction({
  // passed
  close,
  visible,
  modalAction,
  userId,

  // connect
  blockIsLoading,
  blockIsLoaded,
  blockError,

  unblockIsLoading,
  unblockIsLoaded,
  unblockError,

  restorePasswordIsLoading,
  restorePasswordIsLoaded,
  restorePasswordError,

  // actions
  blockUser,
  blockUserReset,
  unblockUser,
  unblockUserReset,
  restoreUserPassword,
  restoreUserPasswordReset,
}) {
  const error = blockError || unblockError || restorePasswordError;
  const isLoaded = unblockIsLoaded || restorePasswordIsLoaded || blockIsLoaded;
  const isLoading = blockIsLoading || unblockIsLoading || restorePasswordIsLoading;

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        document.location.reload(true);
      }, 1000);
    }
  }, [isLoaded]);

  function cancel() {
    switch (modalAction) {
      case ACTION.BLOCK:
        blockUserReset();
        break;
      case ACTION.UNBLOCK:
        unblockUserReset();
        break;
      case ACTION.RESTORE:
        restoreUserPasswordReset();
        break;
      default:
        break;
    }
    close();
  }

  function handleConfirm() {
    switch (modalAction) {
      case ACTION.BLOCK:
        blockUser({ userId: Number(userId) });
        break;
      case ACTION.UNBLOCK:
        unblockUser({ userId: Number(userId) });
        break;
      case ACTION.RESTORE:
        restoreUserPassword({ userId: Number(userId) });
        break;
      default:
        break;
    }
  }

  return (
    <Root
      visible={visible}
      onCancel={cancel}
      footer={null}
    >
      <Title cetner> Вы уверены, что хотите выполнить операцию? </Title>
      <Footer>
        <Button
          type="primary"
          shape="square"
          size="small"
          onClick={handleConfirm}
          loading={isLoading}
        >
          Потвердить
        </Button>
        <Button
          type="ghost"
          shape="square"
          size="small"
          onClick={cancel}
          htmlType="button"
        >
          Отменить
        </Button>
      </Footer>
      {error && (
        <ErrorMessage>
          {' '}
          {error}
          {' '}
        </ErrorMessage>
      )}
      {blockIsLoaded && (
        <SuccessMessage>Пользователь успешно заблокирован</SuccessMessage>
      )}
      {unblockIsLoaded && (
        <SuccessMessage>Пользователь успешно разблокирован</SuccessMessage>
      )}
      {restorePasswordIsLoaded && (
        <SuccessMessage>Пароль пользователя успешно сброшен</SuccessMessage>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    // user block
    blockIsLoading: state.user.block.isLoading,
    blockIsLoaded: state.user.block.isLoaded,
    blockError: state.user.block.error,

    // user unblock
    unblockIsLoading: state.user.unblock.isLoading,
    unblockIsLoaded: state.user.unblock.isLoaded,
    unblockError: state.user.unblock.error,

    // restore user password
    restorePasswordIsLoading: state.user.restorePassword.isLoading,
    restorePasswordIsLoaded: state.user.restorePassword.isLoaded,
    restorePasswordError: state.user.restorePassword.error,
  }),
  {
    blockUser: blockUserAction,
    blockUserReset: blockUserResetAction,
    unblockUser: unblockUserAction,
    unblockUserReset: unblockUserResetAction,
    restoreUserPassword: restoreUserPasswordAction,
    restoreUserPasswordReset: restoreUserPasswordResetAction,
  },
)(ConfirmAction);
