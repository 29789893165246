import React from 'react';
import InputSlider from 'components/InputSlider';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { mergeArrays } from 'hacks';

function SliderField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  min,
  max,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue },

  onChangeField,
  rules,
  initialValue,
  size,
}) {
  function handleOnBlur() {
    const value = getFieldValue(name);
    if (initialValue !== value) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  return (
    <>
      <Label label={label} area={area} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: typeof initialValue === 'number' ? initialValue : undefined,
      })(
        <InputSlider
          name={name}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          area={area}
          min={min}
          max={max}
          disabled={disabled}
          size={size}
        />,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default SliderField;
