import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  breadcrumbsShowUnblockModal as breadcrumbsShowUnblockModalAction,
  breadcrumbsResetBlockLinks as breadcrumbsResetBlockLinksAction,
} from 'redux/breadcrumbs/actions';

import { BreadcrumbsContainer, BlockBreadcrumb } from './style';

export function BreadCrumpbs({
    // passed
  inModal,

    // store
  list,
  isLinksBlock,
  isRedirect,

    // actions
  breadcrumbsShowUnblockModal,
  breadcrumbsResetBlockLinks,
}) {
  const [redirectPath, setRedirectPath] = useState('');
  const history = useHistory();

  const showModal = (redirect) => {
    breadcrumbsShowUnblockModal();
    setRedirectPath(redirect);
  };

  useEffect(() => {
    if (isRedirect && redirectPath) {
      history.push(redirectPath);
      breadcrumbsResetBlockLinks();
    }
  }, [isRedirect, history, redirectPath, breadcrumbsResetBlockLinks]);

  return (
    <>
      <BreadcrumbsContainer inModal={inModal}>
        <Breadcrumb separator="→">
          {list.filter((item) => item.name).map((item, i, a) => (
            <Breadcrumb.Item key={item.name}>
              {i === a.length - 1 ? (
                <span>{item.name}</span>
              ) : (
                isLinksBlock ? (
                  <BlockBreadcrumb onClick={() => showModal(item.link)}>
                    {item.name}
                  </BlockBreadcrumb>
                ) : (
                  <Link to={item.link}>{item.name}</Link>
                )
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </BreadcrumbsContainer>
    </>
  );
}

export default connect(
  (state) => ({
    list: state.breadcrumbs.list,
    showModal: state.breadcrumbs.showModal,
    isLinksBlock: state.breadcrumbs.isLinksBlock,
    isRedirect: state.breadcrumbs.isRedirect,
  }),
  {
    breadcrumbsShowUnblockModal: breadcrumbsShowUnblockModalAction,
    breadcrumbsResetBlockLinks: breadcrumbsResetBlockLinksAction,
  },
)(BreadCrumpbs);
