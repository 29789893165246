import React from 'react';

export const RublerIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.02556 11.3558H3.8147V14H1.98403V11.3558H0V9.83654H1.98403V8.51923H0V7.00962H1.98403V0H7.13099C8.6262 0 9.8115 0.38141 10.6869 1.14423C11.5623 1.90705 12 2.9391 12 4.24038C12 5.60577 11.5783 6.66026 10.7348 7.40385C9.89776 8.14103 8.70607 8.51282 7.15974 8.51923H3.8147V9.83654H7.02556V11.3558ZM3.8147 7.00962H7.13099C8.12141 7.00962 8.8754 6.77564 9.39297 6.30769C9.91054 5.83974 10.1693 5.15705 10.1693 4.25962C10.1693 3.44551 9.90096 2.78846 9.36422 2.28846C8.82748 1.78205 8.10224 1.52564 7.1885 1.51923H3.8147V7.00962Z" fill="#A8AAAC" />
  </svg>
);

export const BigRublerIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.92 10.16H2.72V11.648H0.92V13.592H2.72V17H4.952V13.592H14.672V11.648H4.952V10.16H9.248C11.624 10.16 13.28 9.68 14.192 8.696C15.104 7.712 15.56 6.488 15.56 5.048C15.56 2.648 14.048 0.704 11.6 0.344C11.024 0.248 10.184 0.199999 9.056 0.199999H2.72V8.24H0.92V10.16ZM4.952 2.192H9.224C10.256 2.192 10.952 2.24 11.312 2.336C12.488 2.648 13.232 3.704 13.232 5.12C13.232 7.064 12.176 8.192 9.272 8.192H4.952V2.192Z" fill="#A8AAAC" />
  </svg>
);
