/* List */
export const COMPANY_FETCH_LIST = 'COMPANY_FETCH_LIST';
export const COMPANY_FETCH_LIST__SUCCESS = 'COMPANY_FETCH_LIST__SUCCESS';
export const COMPANY_FETCH_LIST__FAILURE = 'COMPANY_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting, withPagination,
} = {}) => ({
  type: COMPANY_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
  withPagination,
});

export const COMPANY_FETCH_LIST__CANCEL_REQUEST = 'COMPANY_FETCH_LIST__CANCEL_REQUEST';
export const COMPANY_FETCH_LIST__SET_LOADING = 'COMPANY_FETCH_LIST__SET_LOADING';
export const COMPANY_FETCH_LIST__SET_PAGINATION = 'COMPANY_FETCH_LIST__SET_PAGINATION';

export const COMPANY_FETCH_LIST__SET_SEARCH = 'COMPANY_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search, withPagination } = {}) => ({
  type: COMPANY_FETCH_LIST__SET_SEARCH,
  search,
  withPagination,
});

export const COMPANY_FETCH_LIST__SET_PAGE = 'COMPANY_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: COMPANY_FETCH_LIST__SET_PAGE, page });

export const COMPANY_FETCH_LIST__SET_SORTING = 'COMPANY_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: COMPANY_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const COMPANY_FETCH_LIST__SET_FILTER = 'COMPANY_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: COMPANY_FETCH_LIST__SET_FILTER,
  filters,
});

export const COMPANY_FETCH_LIST__RESET = 'COMPANY_FETCH_LIST__RESET';
export const reset = () => ({ type: COMPANY_FETCH_LIST__RESET });

/* Item */
export const COMPANY_FETCH_ITEM = 'COMPANY_FETCH_ITEM';
export const COMPANY_FETCH_ITEM__SUCCESS = 'COMPANY_FETCH_ITEM__SUCCESS';
export const COMPANY_FETCH_ITEM__FAILURE = 'COMPANY_FETCH_ITEM__FAILURE';
export const fetchItem = ({ id } = {}) => ({
  type: COMPANY_FETCH_ITEM,
  id,
});

export const COMPANY_FETCH_ITEM__RESET = 'COMPANY_FETCH_ITEM__RESET';
export const fetchItemReset = () => ({ type: COMPANY_FETCH_ITEM__RESET });

export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_CREATE__SUCCESS = 'COMPANY_CREATE__SUCCESS';
export const COMPANY_CREATE__FAILURE = 'COMPANY_CREATE__FAILURE';
export const create = ({
  id = false,
  name,
  fullName,
  inn,
  ogrn,
  kpp,
  dateRegistration,
  okved,
  okpo,
  oktmo,
  individualSpruceOrgan,
  address,
  postAddress,
  phone,
  fax,
  additionalInfo,
} = {}) => ({
  type: COMPANY_CREATE,
  id,
  name,
  fullName,
  inn,
  ogrn,
  kpp,
  dateRegistration,
  okved,
  okpo,
  oktmo,
  individualSpruceOrgan,
  address,
  postAddress,
  phone,
  fax,
  additionalInfo,
});
export const COMPANY_CREATE__RESET = 'COMPANY_CREATE__RESET';
export const createReset = () => ({ type: COMPANY_CREATE__RESET });
