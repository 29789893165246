import React from 'react';

import { Header, Description, Title } from './style';

function ConfirmationCode({ label, text }) {
  return (
    <Header>
      <Description confirmed={text}>{label}</Description>
      <Title>{text}</Title>
    </Header>
  );
}

export default ConfirmationCode;
