import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';

import {
  all,
  call,
  fork,
  put,
  takeLeading,
} from 'redux-saga/effects';

import {
  extractErrorAndCode,
} from 'hacks';

import {
  PERIOD_GET_REPORT,
  PERIOD_GET_REPORT__FAILURE,
  PERIOD_GET_REPORT__SUCCESS,
} from 'redux/tcmPeriod/actions';

// const getState = (state) => state.tcmCampaign;

function* getPeriodReport() {
  yield takeLeading(PERIOD_GET_REPORT, function* ({ startAt, finishAt }) {
    try {
      const response = yield call(API.TCM.period.getPeriodReport, {
        startAt,
        finishAt,
      });

      if (
        response.data
        && !response.errorCode
      ) {
        yield put({
          type: PERIOD_GET_REPORT__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: PERIOD_GET_REPORT__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPeriodReport),
  ]);
}
