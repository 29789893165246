import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyled = styled(Modal)`
  & {
    min-width: 300px;
    max-width: 800px;
  }

  & .ant-modal-content {
    border-radius: 4px;

    & .ant-modal-close {
      width: 60px;
      height: 60px;

      & .ant-modal-close-x {
        width: 100%;
        height: 100%;
      }
    }

    & .ant-modal-header {
      height: 60px;
      padding: 0 40px;
      display: flex;
      align-items: center;

      & .ant-modal-title {
      }
    }

    & .ant-modal-body {
      padding: 15px 40px 40px;
      max-height: 500px;
      overflow: auto;
      margin-top: 0;
      margin-bottom: 40px;
    }

    & .ant-modal-footer {
      border: none;
      border-radius: 0;
      display: flex;
      justify-content: center;
      text-align: left;
      padding: 0 60px 30px;
      margin-top: -60px;

      & button + button {
        margin-left: 30px;
      }
    }
  }
`;
