export const ADMIN_CREATE_MERCHANT = 'ADMIN_CREATE_MERCHANT';
export const ADMIN_CREATE_MERCHANT__SUCCESS = 'ADMIN_CREATE_MERCHANT__SUCCESS';
export const ADMIN_CREATE_MERCHANT__FAILURE = 'ADMIN_CREATE_MERCHANT__FAILURE';
export const createMerchant = ({
  name, inn, admin, users,
} = {}) => ({
  type: ADMIN_CREATE_MERCHANT,
  name,
  inn,
  admin,
  users,
});

export const ADMIN_CREATE_MERCHANT__RESET = 'ADMIN_CREATE_MERCHANT__RESET';
export const createMerchantReset = () => ({ type: ADMIN_CREATE_MERCHANT__RESET });
