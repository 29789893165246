import React from 'react';

export const FilterIcon = () => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M5.75625 0.1999C5.28125 0.1999 4.85938 0.33115 4.49063 0.593649C4.12188 0.85615 3.85 1.1999 3.675 1.6249H0V3.1999H3.6375C3.7875 3.6499 4.05625 4.01552 4.44375 4.29677C4.83125 4.57802 5.26875 4.7249 5.75625 4.7374C6.24375 4.7374 6.68125 4.59365 7.06875 4.30615C7.45625 4.01865 7.725 3.6499 7.875 3.1999H19.2V1.6249H7.81875C7.65625 1.1999 7.3875 0.85615 7.0125 0.593649C6.6375 0.33115 6.21875 0.1999 5.75625 0.1999ZM9.6 7.5124C9.125 7.5124 8.69688 7.6499 8.31563 7.9249C7.93438 8.1999 7.6625 8.5499 7.5 8.9749H0V10.5687H7.5C7.6625 10.9937 7.93438 11.3437 8.31563 11.6187C8.69688 11.8937 9.125 12.0312 9.6 12.0312C10.075 12.0312 10.5031 11.8937 10.8844 11.6187C11.2656 11.3437 11.5375 10.9937 11.7 10.5687H19.2V8.9749H11.7C11.5375 8.5499 11.2656 8.1999 10.8844 7.9249C10.5031 7.6499 10.075 7.5124 9.6 7.5124ZM13.4438 14.8624C12.9688 14.8624 12.5406 15.003 12.1594 15.2843C11.7781 15.5655 11.5063 15.9187 11.3438 16.3437H0V17.9187H11.3438C11.5063 18.3562 11.7781 18.7124 12.1594 18.9874C12.5406 19.2624 12.9688 19.3999 13.4438 19.3999C13.9188 19.3999 14.3469 19.2624 14.7281 18.9874C15.1094 18.7124 15.3813 18.3562 15.5438 17.9187H19.2V16.3437H15.5438C15.3813 15.9062 15.1094 15.553 14.7281 15.2843C14.3469 15.0155 13.9188 14.8749 13.4438 14.8624Z" fill="#E50000" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
