import moment from 'moment';

export const REPORT_FIELD_KEY = {
  REPORT_TYPE: {
    key: 'REPORT_TYPE',
    apiKey: '',
    title: 'Тип отчета',
    rules: [],
  },
  SECTOR_TYPE: {
    key: 'SECTOR_TYPE',
    apiKey: '',
    title: 'Номер сектора',
    rules: [],
  },
  REGISTRY: {
    key: 'REGISTRY',
    apiKey: '',
    title: 'Реестр',
    label: 'Период',
    rules: [
      {
        required: true,
      },
    ],
  },
  SEND_REGISTRY_TO_CLIENT_CHECKBOX: {
    key: 'SEND_REGISTRY_TO_CLIENT_CHECKBOX',
    apiKey: '',
    title: 'отправить реестры клиенту',
    rules: [],
  },
  REGISTRY_NULL_SECTOR_CHECKBOX: {
    key: 'REGISTRY_NULL_SECTOR_CHECKBOX',
    apiKey: '',
    title: 'в т.ч. нулевые реестры',
    rules: [],
  },
  REGISTRY_DOCUMENT_TYPE: {
    key: 'REGISTRY_DOCUMENT_TYPE',
    apiKey: '',
    title: 'Формат выгрузки',
    rules: [],
    options: {
      XLSX: {
        value: 'xls',
        label: 'XLS',
      },
      CSV: {
        value: 'csv',
        label: 'CSV',
      },
    },
  },
  CONSOLIDATED_REPORT: {
    key: 'CONSOLIDATED_REPORT',
    apiKey: '',
    title: 'Сводный отчет за месяц',
    label: 'Месяц',
    rules: [],
  },
  PERIOD_REPORT: {
    key: 'PERIOD_REPORT',
    apiKey: '',
    title: 'Отчет по операциям за период',
    label: 'Период',
    rules: [],
  },
  PROCESSING_REPORT: {
    key: 'PROCESSING_REPORT',
    apiKey: '',
    title: 'Отчет по сверке операций с процессингом',
    label: 'Дата',
    rules: [
      {
        required: true,
      },
    ],
  },
  SYNCHRONIZATION_REPORT: {
    key: 'SYNCHRONIZATION_REPORT',
    apiKey: '',
    title: 'Синхронизация операций с процессингом',
    label: 'Дата',
    rules: [],
  },
};

export const REPORT_TYPE_SELECT = [
  {
    value: REPORT_FIELD_KEY.REGISTRY.key,
    label: REPORT_FIELD_KEY.REGISTRY.title,
  },
  {
    value: REPORT_FIELD_KEY.CONSOLIDATED_REPORT.key,
    label: REPORT_FIELD_KEY.CONSOLIDATED_REPORT.title,
  },
  {
    value: REPORT_FIELD_KEY.PERIOD_REPORT.key,
    label: REPORT_FIELD_KEY.PERIOD_REPORT.title,
  },
  {
    value: REPORT_FIELD_KEY.PROCESSING_REPORT.key,
    label: REPORT_FIELD_KEY.PROCESSING_REPORT.title,
  },
  {
    value: REPORT_FIELD_KEY.SYNCHRONIZATION_REPORT.key,
    label: REPORT_FIELD_KEY.SYNCHRONIZATION_REPORT.title,
  },
];

export const formatToApi = (formValue) => {
  switch (formValue.REPORT_TYPE) {
    // Тип Реестр
    case REPORT_FIELD_KEY.REGISTRY.key:
      return ({
        sectors: Array.isArray(formValue.SECTOR_TYPE)
          && (formValue.SECTOR_TYPE.length > 0) ? formValue.SECTOR_TYPE.map((item) => Number(item)) : [-1],
        dateFrom: moment(formValue.REGISTRY[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment(formValue.REGISTRY[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        fileFormat: formValue.REGISTRY_DOCUMENT_TYPE,
        sendToClient: Boolean(formValue.SEND_REGISTRY_TO_CLIENT_CHECKBOX),
        nullableReports: Boolean(formValue.REGISTRY_NULL_SECTOR_CHECKBOX),
      });
    // Тип Сводный отчет за месяц
    case REPORT_FIELD_KEY.CONSOLIDATED_REPORT.key:
      return ({
        sectors: Array.isArray(formValue.SECTOR_TYPE)
          && (formValue.SECTOR_TYPE.length > 0) ? formValue.SECTOR_TYPE.map((item) => Number(item)) : [-1],
        year: Number(moment(formValue.CONSOLIDATED_REPORT).format('YYYY')),
        month: Number(moment(formValue.CONSOLIDATED_REPORT).format('MM')),
      });
    // Тип отчет по операциям за период
    case REPORT_FIELD_KEY.PERIOD_REPORT.key:
      return ({
        sectors: Array.isArray(formValue.SECTOR_TYPE)
          && (formValue.SECTOR_TYPE.length > 0) ? formValue.SECTOR_TYPE.map((item) => Number(item)) : [-1],
        dateFrom: moment(formValue.PERIOD_REPORT[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment(formValue.PERIOD_REPORT[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      });
    // Тип отчет по сверке операций с процессингом
    case REPORT_FIELD_KEY.PROCESSING_REPORT.key:
      return ({
        sectors: Array.isArray(formValue.SECTOR_TYPE)
          && (formValue.SECTOR_TYPE.length > 0) ? formValue.SECTOR_TYPE.map((item) => Number(item)) : [-1],
        dateRevise: moment(formValue.PROCESSING_REPORT).format('YYYY-MM-DD HH:mm:ss'),
      });
    // Тип синхронизация операций с процессингом
    case REPORT_FIELD_KEY.SYNCHRONIZATION_REPORT.key:
      return ({
        sectors: Array.isArray(formValue.SECTOR_TYPE)
          && (formValue.SECTOR_TYPE.length > 0) ? formValue.SECTOR_TYPE.map((item) => Number(item)) : [-1],
        date: moment(formValue.SYNCHRONIZATION_REPORT).format('YYYY-MM-DD'),
      });
    default:
      return {};
  }
};
