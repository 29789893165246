import styled from 'styled-components';
import { GRID_AREAS } from 'settings';

export const Title = styled.h4.attrs({ className: 'FieldsLabel' })`
  margin-top: 1rem;

  ${({ area }) =>
    typeof area === 'boolean'
    && `
    grid-area: ${area ? GRID_AREAS.LEFT.LABEL : GRID_AREAS.RIGHT.LABEL};
  `}
`;

export const Scrollable = styled.h4`
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
`;
