import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Form } from 'antd';
import { TCM } from 'components/tcm';
import { AddComment } from 'containers/App/TCM/Comments/Comments';
import { FormBtns } from 'containers/App/TCM/ClientCard/CallForm/style';
import Error from 'components/Fields/Error';
import {
  Block,
  BlockTitle,
  CheckboxGroup,
  SubRow,
  SumField,
  CheckBoxText,
} from 'containers/App/TCM/ClientCard/VisitForm/style';
import { Rifm } from 'rifm';
import { Suffix } from 'containers/App/TCM/style';
import { RublerIcon } from 'icons';
import { parseDigitsWithSeparator as parseDigits } from 'utils';
import {
  bank,
  statusDescriptionsCodes,
  FormItems,
  reason,
} from 'containers/App/TCM/ClientCard/VisitForm/data';
import { connect } from 'react-redux';
import {
  postVisitResult as postVisitResultAction,
  resetVisitResult as resetVisitResultAction,
} from 'redux/tcmOffer/actions';

import {
  fetchBrands as fetchBrandsAction,
  fetchModels as fetchModelsAction,
} from 'redux/common/actions';

const VisitForm = ({
  clientGuid,
  onCancel,

  isLoading,
  isLoaded,
  error,

  brandsList,
  brandsIsLoading,
  brandsIsLoaded,
  // brandsError,

  modelsList,
  modelsIsLoading,
  modelsIsLoaded,
  // modelsError,

  // actions
  postVisitResult,
  resetVisitResult,

  fetchBrands,
  fetchModels,

}) => {
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [modelsOptions, setModelsOptions] = useState([]);
  const [modelsTestDriveOptions, setModelsTestDriveOptions] = useState([]);
  const [isTestDrive, setIsTradeIn] = useState([]);
  const [sumValue, setSumValue] = React.useState('');
  const [registrationBank, setRegistrationBank] = useState('');
  const [contractConcludedBank, setContractConcludedBank] = useState('');
  const [form] = Form.useForm();

  const onChangeStatus = (values) => {
    setSelectedStatuses(values);
  };

  const handleValuesChange = (field) => {
    const [key, value] = Object.entries(field)[0];

    if (key === 'TEST_DRIVE_BRAND') {
      form.setFieldsValue({
        [FormItems.statuses.options.TestDrive.children.model.key]: undefined,
      });

      fetchModels({ brand: value });
      setIsTradeIn(true);
    }

    if (key === 'FILING_AN_APPLICATION_BANK') {
      form.setFieldsValue({
        [FormItems.statuses.options.Registration.children.brand.key]: undefined,
        [FormItems.statuses.options.Registration.children.model.key]: undefined,
      });
    }

    if (key === 'FILING_AN_APPLICATION_BRAND') {
      form.setFieldsValue({
        [FormItems.statuses.options.Registration.children.model.key]: undefined,
      });

      fetchModels({ brand: value });
      setIsTradeIn(false);
    }
  };

  const onFinish = useCallback((values) => {
    const arrayValues = Object.entries(values);

    const statusDescriptions = arrayValues
      .filter((item) => statusDescriptionsCodes.includes(item[0]))
      .map(([key, value]) => {
        if (key === 'EVALUATION_TRADE_IN_SUM') {
          return { key, value: parseDigits(value) };
        }
        return { key, value };
      });

    const formData = {
      externalId: clientGuid,
      statuses: values.statuses,
      statusDescriptions,
      text: values.comment,
    };

    postVisitResult({ formData });
  }, [clientGuid, postVisitResult]);

  const formatInteger = (string) => {
    const parsed = parseDigits(string).slice(0, 12);
    const number = Number.parseInt(parsed, 10);

    if (Number.isNaN(number)) {
      return '';
    }
    return number.toLocaleString('ru');
  };

  const onReset = useCallback(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  useEffect(() => {
    if (brandsIsLoaded) {
      setBrandsOptions(brandsList.map((item) => ({ label: item?.value, value: item?.value })));
    }
  }, [brandsIsLoaded, brandsList]);

  useEffect(() => {
    if (modelsIsLoaded) {
      if (!isTestDrive) {
        setModelsOptions(modelsList.map((item) => ({ label: item?.value, value: item?.value })));
      } else {
        setModelsTestDriveOptions(modelsList.map((item) => ({ label: item?.value, value: item?.value })));
      }
    }
  }, [modelsIsLoaded, isTestDrive, modelsList]);

  useEffect(() => {
    if (isLoaded) {
      onCancel();
    }
  }, [isLoaded, onCancel]);

  useEffect(() => () => { resetVisitResult(); }, [resetVisitResult]);

  return (
    <Form
      form={form}
      name="call"
      layout="vertical"
      validateTrigger="onSubmit"
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
    >
      <Block>
        <BlockTitle>Результат визита:</BlockTitle>
        <TCM.Label
          name={FormItems.statuses.key}
          rules={FormItems.statuses.rules}
        >
          <CheckboxGroup onChange={onChangeStatus}>
            <div>
              <TCM.Checkbox
                value={FormItems.statuses.options.TradeIn.value}
              >
                <CheckBoxText>{FormItems.statuses.options.TradeIn.label}</CheckBoxText>
              </TCM.Checkbox>
              {selectedStatuses.includes(FormItems.statuses.options.TradeIn.value) && (
                <SubRow>
                  <Rifm
                    accept={/[\d]/g}
                    format={formatInteger}
                    value={sumValue}
                    onChange={setSumValue}
                  >
                    {({ value = '', onChange }) => (
                      <TCM.Label
                        label={FormItems.statuses.options.TradeIn.children.sum.label}
                        name={FormItems.statuses.options.TradeIn.children.sum.key}
                        rules={FormItems.statuses.options.TradeIn.children.sum.rules}
                        validateTrigger="onBlur"
                      >
                        <SumField>
                          <TCM.Input
                            name={FormItems.statuses.options.TradeIn.children.sum.key}
                            suffix={<Suffix><RublerIcon /></Suffix>}
                            onChange={onChange}
                            value={value}
                          />
                        </SumField>
                      </TCM.Label>
                    )}
                  </Rifm>
                </SubRow>
              )}
            </div>

            <div>
              <TCM.Checkbox
                value={FormItems.statuses.options.TestDrive.value}
              >
                <CheckBoxText>{FormItems.statuses.options.TestDrive.label}</CheckBoxText>
              </TCM.Checkbox>

              {selectedStatuses.includes(FormItems.statuses.options.TestDrive.value) && (
                <SubRow>
                  <TCM.Label
                    label={FormItems.statuses.options.TestDrive.children.brand.label}
                    name={FormItems.statuses.options.TestDrive.children.brand.key}
                    rules={FormItems.statuses.options.TestDrive.children.brand.rules}
                    validateTrigger="onBlur"
                  >
                    <TCM.Select
                      name={FormItems.statuses.options.TestDrive.children.brand.key}
                      options={brandsOptions}
                      placeholder="Выберите марку"
                      loading={brandsIsLoading}
                      disabled={modelsIsLoading}
                    />
                  </TCM.Label>
                  { modelsList.length > 0 && (
                    <TCM.Label
                      label={FormItems.statuses.options.TestDrive.children.model.label}
                      name={FormItems.statuses.options.TestDrive.children.model.key}
                      rules={FormItems.statuses.options.TestDrive.children.model.rules}
                      validateTrigger="onBlur"
                    >
                      <TCM.Select
                        name={FormItems.statuses.options.TestDrive.children.model.key}
                        options={modelsTestDriveOptions}
                        loading={modelsIsLoading}
                        placeholder="Выберите модель"
                        disabled={modelsIsLoading}
                      />
                    </TCM.Label>
                  )}
                </SubRow>
              )}
            </div>
            <div>
              <TCM.Checkbox
                value={FormItems.statuses.options.Registration.value}
              >
                <CheckBoxText>{FormItems.statuses.options.Registration.label}</CheckBoxText>
              </TCM.Checkbox>

              {selectedStatuses.includes(FormItems.statuses.options.Registration.value) && (
                <SubRow>
                  <TCM.Label
                    label={FormItems.statuses.options.Registration.children.bank.label}
                    name={FormItems.statuses.options.Registration.children.bank.key}
                    rules={FormItems.statuses.options.Registration.children.bank.rules}
                    validateTrigger="onBlur"
                  >
                    <TCM.Select
                      name={FormItems.statuses.options.Registration.children.bank.key}
                      options={bank}
                      onChange={setRegistrationBank}
                      placeholder="Выберите банк"
                    />
                  </TCM.Label>

                  {[bank[0].value, bank[1].value, bank[2].value].includes(registrationBank) && (
                    <>
                      <TCM.Label
                        label={FormItems.statuses.options.Registration.children.brand.label}
                        name={FormItems.statuses.options.Registration.children.brand.key}
                        rules={FormItems.statuses.options.Registration.children.brand.rules}
                        validateTrigger="onBlur"
                      >
                        <TCM.Select
                          name={FormItems.statuses.options.Registration.children.brand.key}
                          options={brandsOptions}
                          loading={brandsIsLoading}
                          placeholder="Выберите марку"
                          disabled={modelsIsLoading}
                        />
                      </TCM.Label>

                      { modelsList.length > 0 && (
                        <TCM.Label
                          label={FormItems.statuses.options.Registration.children.model.label}
                          name={FormItems.statuses.options.Registration.children.model.key}
                          rules={FormItems.statuses.options.Registration.children.model.rules}
                          validateTrigger="onBlur"
                        >
                          <TCM.Select
                            name={FormItems.statuses.options.Registration.children.model.key}
                            options={modelsOptions}
                            loading={modelsIsLoading}
                            placeholder="Выберите модель"
                            disabled={modelsIsLoading}
                          />
                        </TCM.Label>
                      )}
                    </>
                  )}
                </SubRow>
              )}
            </div>
            <div>
              <TCM.Checkbox
                value={FormItems.statuses.options.ContractConcluded.value}
              >
                <CheckBoxText>{FormItems.statuses.options.ContractConcluded.label}</CheckBoxText>
              </TCM.Checkbox>

              {selectedStatuses.includes(FormItems.statuses.options.ContractConcluded.value) && (
                <SubRow>
                  <TCM.Label
                    label={FormItems.statuses.options.ContractConcluded.children.bank.label}
                    name={FormItems.statuses.options.ContractConcluded.children.bank.key}
                    rules={FormItems.statuses.options.ContractConcluded.children.bank.rules}
                    validateTrigger="onBlur"
                  >
                    <TCM.Select
                      name={FormItems.statuses.options.ContractConcluded.children.bank.key}
                      options={bank}
                      onChange={setContractConcludedBank}
                      placeholder="Выберите банк"
                    />
                  </TCM.Label>

                  { contractConcludedBank === bank[1].value && (
                    <TCM.Label
                      label={FormItems.statuses.options.ContractConcluded.children.reason.label}
                      name={FormItems.statuses.options.ContractConcluded.children.reason.key}
                      rules={FormItems.statuses.options.ContractConcluded.children.reason.rules}
                      validateTrigger="onBlur"
                    >
                      <TCM.Select
                        name={FormItems.statuses.options.ContractConcluded.children.reason.key}
                        options={reason}
                        placeholder="Выберите причину отказа"
                      />
                    </TCM.Label>
                  )}
                </SubRow>
              )}
            </div>
          </CheckboxGroup>
        </TCM.Label>
      </Block>
      <AddComment
        title="Комментарий к визиту"
        formItemProps={{
          name: FormItems.comment.key,
          rules: FormItems.comment.rules,
        }}
        fieldProps={{
          name: FormItems.comment.key,
          placeholder: FormItems.comment.placeholder,
        }}
      />
      <FormBtns>
        <Form.Item shouldUpdate>
          {() => (
            <TCM.Button
              htmlType="submit"
              loading={isLoading}
              disabled={
                !(
                  !form.getFieldsError().some(({ errors }) => errors.length)
                    && form.getFieldValue('comment'))
              }
            >
              Сохранить результат визита
            </TCM.Button>
          )}
        </Form.Item>
        <Form.Item>
          <TCM.ButtonOutline
            htmlType="button"
            onClick={onReset}
          >
            Отмена
          </TCM.ButtonOutline>
        </Form.Item>
      </FormBtns>
      {Boolean(error) && (
        <Error>{error}</Error>
      ) }
    </Form>
  );
};

const ConnectReduxWithForm = connect(
  (state) => ({
    isLoading: state.tcmOffer.visitResult.isLoading,
    isLoaded: state.tcmOffer.visitResult.isLoaded,
    error: state.tcmOffer.visitResult.error,
    errors: state.tcmOffer.visitResult.errors,

    brandsList: state.common.brands.list,
    brandsIsLoading: state.common.brands.isLoading,
    brandsIsLoaded: state.common.brands.isLoaded,
    brandsError: state.common.brands.error,

    modelsList: state.common.models.list,
    modelsIsLoading: state.common.models.isLoading,
    modelsIsLoaded: state.common.models.isLoaded,
    modelsError: state.common.models.error,
  }),
  {
    postVisitResult: postVisitResultAction,
    resetVisitResult: resetVisitResultAction,
    fetchBrands: fetchBrandsAction,
    fetchModels: fetchModelsAction,
  },
)(VisitForm);

export { ConnectReduxWithForm as VisitForm };
