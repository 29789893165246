import styled from 'styled-components';

const Root = styled.div`
  margin-top: 1rem;

  & {
    .ant-table-thead > tr > th {
      padding: 8px !important;
    }
    .ant-table-tbody > tr > td {
      padding: 8px !important;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
      width: 100%;
    }
    .ant-table-thead > tr > th {
      vertical-align: bottom;
    }
  }
`;

const TableHeader = styled.div``;

const LoadingMessage = styled.div``;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 0.4rem;
`;

const EmptyMessage = styled.div``;

const EmptyResultsMessage = styled.div`
  font-size: 0.9rem;
  padding: 0.8rem;
  text-align: center;
  background: rgb(245, 245, 245);
  border-radius: 6px;
  border: 1px solid #b7b7b7;
  position: relative;
`;

const EmptyResultsClose = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: none;
  background: transparent;
  padding: 0 20px;
  cursor: pointer;
  transition: background ease-in 0.2s;

  &:hover {
    background: #d4d2d269;
  }
`;

export {
  Root,
  TableHeader,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
  EmptyResultsMessage,
  EmptyResultsClose,
};
