import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';

import {
  extractErrorAndCode,
  formatOffer,
  formatOfferList,
  formatOfferPreviousCampaign,
} from 'hacks';

import {
  all,
  takeLatest,
  put,
  fork,
  call,
  select,
  cancel,
  take,
  takeLeading,
} from 'redux-saga/effects';

import { UserRole } from 'hacks/formatOffer';
import {
  OFFER_FETCH_CONFIRMED_LIST,
  OFFER_FETCH_CONFIRMED_LIST__FAILURE,
  OFFER_FETCH_CONFIRMED_LIST__SUCCESS,
  OFFER_FETCH_UNDERWAY_LIST,
  OFFER_FETCH_UNDERWAY_LIST__FAILURE,
  OFFER_FETCH_UNDERWAY_LIST__SUCCESS,
  OFFER_FETCH_WAIT_CONFIRM_LIST,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SUCCESS,
  OFFER_FETCH_WAIT_CONFIRM_LIST__FAILURE,
  POST_VISIT_RESULT,
  POST_VISIT_RESULT__FAILURE,
  POST_VISIT_RESULT__SUCCESS,
  OFFER_FETCH_STATUSES,
  OFFER_FETCH_STATUSES__SUCCESS,
  OFFER_FETCH_STATUSES__FAILURE,
  OFFER_FETCH_UNDERWAY_LIST__SET_LOADING,
  OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SET_LOADING,
  OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST,
  OFFER_FETCH_CONFIRMED_LIST__SET_LOADING,
  OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST,
  OFFER_FETCH_CONFIRMED_LIST__SET_SORTING,
  OFFER_FETCH_CONFIRMED_LIST__SET_FILTER,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER,
  OFFER_FETCH_UNDERWAY_LIST__SET_FILTER,
  OFFER_FETCH_UNDERWAY_LIST__SET_SORTING,
  OFFER_FETCH_COMMON__SET_SEARCH,
  OLD_CAR_EXPORT,
  OLD_CAR_EXPORT__SUCCESS,
  OLD_CAR_EXPORT__FAILURE,
  POST_ADDITIONAL_PHONE,
  POST_ADDITIONAL_PHONE__SUCCESS,
  POST_ADDITIONAL_PHONE__FAILURE,

  OFFER_FETCH_REVISION_LIST,
  OFFER_FETCH_REVISION_LIST__FAILURE,
  OFFER_FETCH_REVISION_LIST__SUCCESS,
  OFFER_FETCH_REVISION_LIST__SET_SORTING,
  OFFER_FETCH_REVISION_LIST__SET_FILTER,
  OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST,
  OFFER_FETCH_REVISION_LIST__SET_LOADING,

  OFFER_FETCH_AGREEING_LIST,
  OFFER_FETCH_AGREEING_LIST__FAILURE,
  OFFER_FETCH_AGREEING_LIST__SUCCESS,
  OFFER_FETCH_AGREEING_LIST__SET_SORTING,
  OFFER_FETCH_AGREEING_LIST__SET_FILTER,
  OFFER_FETCH_AGREEING_LIST__SET_LOADING,
  OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST,

  OFFER_FETCH_REFUSED_LIST,
  OFFER_FETCH_REFUSED_LIST__FAILURE,
  OFFER_FETCH_REFUSED_LIST__SUCCESS,
  OFFER_FETCH_REFUSED_LIST__SET_SORTING,
  OFFER_FETCH_REFUSED_LIST__SET_FILTER,
  OFFER_FETCH_REFUSED_LIST__SET_LOADING,
  OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST,

} from 'redux/tcmOffer/actions';
import { checkPrivilege } from 'helpers/roles';
import { OfferStatus } from 'tcm/offer/data';
import {
  // list
  OFFER_FETCH_LIST,
  OFFER_FETCH_LIST__SUCCESS,
  OFFER_FETCH_LIST__FAILURE,
  OFFER_FETCH_LIST__CANCEL_REQUEST,
  OFFER_FETCH_LIST__SET_LOADING,
  OFFER_FETCH_LIST__SET_PAGINATION,
  OFFER_FETCH_LIST__SET_SEARCH,
  OFFER_FETCH_LIST__SET_PAGE,
  OFFER_FETCH_LIST__SET_SORTING,
  OFFER_FETCH_LIST__SET_FILTER,

  OFFER_EVALUATION_SET,
  OFFER_EVALUATION_SET__SUCCESS,
  OFFER_EVALUATION_SET__FAILURE,

  OFFER_FETCH,
  OFFER_FETCH__SUCCESS,
  OFFER_FETCH__FAILURE,

  OFFER_EMPLOYEE_SET__SUCCESS,
  OFFER_EMPLOYEE_SET,
  OFFER_EMPLOYEE_SET__FAILURE,
  OFFER_EXPORT,
  OFFER_EXPORT__SUCCESS,
  OFFER_EXPORT__FAILURE,
  OFFER_IMPORT,
  OFFER_IMPORT__SUCCESS,
  OFFER_IMPORT__FAILURE,

  OFFERS_STATUS_SET,
  OFFERS_STATUS_SET__FAILURE,
  OFFERS_STATUS_SET_SUCCESS,

  OFFER_ADD_COMMENT,
  OFFER_ADD_COMMENT__SUCCESS,
  OFFER_ADD_COMMENT__FAILURE,

  OFFER_FETCH_PREVIOUS_CAMPAIGN,
  OFFER_FETCH_PREVIOUS_CAMPAIGN__SUCCESS,
  OFFER_FETCH_PREVIOUS_CAMPAIGN__FAILURE,

} from './actions';

const getState = (state) => state.tcmOffer;
const getStateCampaign = (state) => state.tcmCampaign;
const getStateAuth = (state) => state.auth;

const parseExcelErrors = (errors) => {
  if (Array.isArray(errors)) {
    return errors.map((item) => ({
      id: item.fieldName.split('.')[0],
      fieldName: item.fieldName.split('.')[1],
      messages: item.messages.reduce((prev, curr) => `${prev}${curr} `, ''),
    }));
  }
  return [];
};

const formatToFilter = (data = []) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((item) => ({ value: item.id || item.name, label: item.name }));
};

const getApiForOffers = (role) => {
  // из-за того что используется в разных местах один и тот же компонент, было принято решение
  // сделать такой костыль с pathname, чтоб без особых усилий внедрить изменение в пути запроса
  const isManagerOffers = window.location.pathname.includes('campaign/offers/list')
    && checkPrivilege([role.TCM.VIEW_CAMPAIGN]);
  return isManagerOffers ? API.TCM.campaign.fetchCampaignOffersList : API.TCM.offer.fetchList;
};

function* _fetchList() {
  const {
    collection: {
      search, filters, sorting,
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_LIST__SET_LOADING });
    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        suffixTMS: filters.data.suffix ? filters.suffix.map((item) => item.label) : [],
        employees: filters.data.employees ? filters.data.employees.map((item) => item.value) : [],
        campaignId: filters.data.campaignId?.value,
        callStatuses: filters.data.callStatus?.value ? [filters.data.callStatus?.value] : undefined,
        visitStatuses: filters.data.visitStatuses ? filters.data.visitStatuses.map((item) => item.value) : undefined,
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(OFFER_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(OFFER_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

function* _fetchUnderwayList() {
  const {
    collection: {
      search,
      underway: { filters, sorting },
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST__SET_LOADING });

    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        statuses: [OfferStatus.Calling],
        campaignId: filters.data.campaignId?.value,
        callStatuses: filters.data.callStatus?.value ? [filters.data.callStatus?.value] : undefined,
        visitStatuses: filters.data.visitStatuses ? filters.data.visitStatuses.map((item) => item.value) : undefined,
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_UNDERWAY_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST__FAILURE, error: error.message });
  }
}

function* fetchUnderwaySync() {
  const fetchSyncTask = yield fork(_fetchUnderwayList);
  yield take(OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchUnderwayList() {
  yield takeLatest(OFFER_FETCH_UNDERWAY_LIST, function* () {
    yield call(fetchUnderwaySync);
  });
}

export function* fetchUnderwayListOnSearch() {
  yield takeLatest(OFFER_FETCH_COMMON__SET_SEARCH, function* () {
    // yield put({ type: OFFER_FETCH_UNDERWAY_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST });
  });
}

/* For the future. May be need */
// export function* fetchUnderwayListOnPageChange() {
//   yield takeLatest(OFFER_FETCH_UNDERWAY_LIST__SET_PAGE, function* (action) {
//     yield put({ type: OFFER_FETCH_UNDERWAY_LIST__SET_PAGINATION, page: action.page });
//     yield put({ type: OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST });
//     yield put({ type: OFFER_FETCH_UNDERWAY_LIST });
//   });
// }

export function* fetchUnderwayListOnSorting() {
  yield takeLatest(OFFER_FETCH_UNDERWAY_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST });
  });
}

export function* fetchUnderwayListOnFilters() {
  yield takeLatest(OFFER_FETCH_UNDERWAY_LIST__SET_FILTER, function* () {
    // yield put({ type: OFFER_FETCH_UNDERWAY_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_UNDERWAY_LIST });
  });
}

function* _fetchWaitConfirmList() {
  const {
    collection: {
      search,
      waitConfirm: { filters, sorting },
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__SET_LOADING });

    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        statuses: [OfferStatus.WaitConfirm],
        campaignId: filters.data.campaignId?.value,
        callStatuses: filters.data.callStatus?.value ? [filters.data.callStatus?.value] : undefined,
        visitStatuses: filters.data.visitStatuses ? filters.data.visitStatuses.map((item) => item.value) : undefined,
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_WAIT_CONFIRM_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__FAILURE, error: error.message });
  }
}

function* fetchWaitConfirmSync() {
  const fetchSyncTask = yield fork(_fetchWaitConfirmList);
  yield take(OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchWaitConfirmList() {
  yield takeLatest(OFFER_FETCH_WAIT_CONFIRM_LIST, function* () {
    yield call(fetchWaitConfirmSync);
  });
}

export function* fetchWaitConfirmListOnSearch() {
  yield takeLatest(OFFER_FETCH_COMMON__SET_SEARCH, function* () {
    // yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST });
  });
}

/* For the future. May be need */
// export function* fetchWaitConfirmListOnPageChange() {
//   yield takeLatest(OFFER_FETCH_WAIT_CONFIRM_LIST__SET_PAGE, function* (action) {
//     yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__SET_PAGINATION, page: action.page });
//     yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST });
//     yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST });
//   });
// }

export function* fetchWaitConfirmListOnSorting() {
  yield takeLatest(OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST });
  });
}

export function* fetchWaitConfirmListOnFilters() {
  yield takeLatest(OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER, function* () {
    // yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_WAIT_CONFIRM_LIST });
  });
}

function* _fetchConfirmedList() {
  const {
    collection: {
      search,
      confirmed: { filters, sorting },
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST__SET_LOADING });

    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        statuses: [OfferStatus.Confirmed],
        campaignId: filters.data.campaignId?.value,
        callStatuses: filters.data.callStatus?.value ? [filters.data.callStatus?.value] : undefined,
        visitStatuses: filters.data.visitStatuses ? filters.data.visitStatuses.map((item) => item.value) : undefined,
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_CONFIRMED_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST__FAILURE, error: error.message });
  }
}

function* fetchConfirmedSync() {
  const fetchSyncTask = yield fork(_fetchConfirmedList);
  yield take(OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchConfirmedList() {
  yield takeLatest(OFFER_FETCH_CONFIRMED_LIST, function* () {
    yield call(fetchConfirmedSync);
  });
}

export function* fetchConfirmedListOnSearch() {
  yield takeLatest(OFFER_FETCH_COMMON__SET_SEARCH, function* () {
    // yield put({ type: OFFER_FETCH_CONFIRMED_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST });
  });
}

/* For the future. May be need */
// export function* fetchConfirmedListOnPageChange() {
//   yield takeLatest(OFFER_FETCH_CONFIRMED_LIST__SET_PAGE, function* (action) {
//     yield put({ type: OFFER_FETCH_CONFIRMED_LIST__SET_PAGINATION, page: action.page });
//     yield put({ type: OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST });
//     yield put({ type: OFFER_FETCH_CONFIRMED_LIST });
//   });
// }

export function* fetchConfirmedListOnSorting() {
  yield takeLatest(OFFER_FETCH_CONFIRMED_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST });
  });
}

export function* fetchConfirmedListOnFilters() {
  yield takeLatest(OFFER_FETCH_CONFIRMED_LIST__SET_FILTER, function* () {
    // yield put({ type: OFFER_FETCH_CONFIRMED_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_CONFIRMED_LIST });
  });
}
// revision start
function* _fetchRevisionList() {
  const {
    collection: {
      search,
      revision: { filters, sorting },
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_REVISION_LIST__SET_LOADING });

    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        statuses: [OfferStatus.Revision],
        campaignId: filters.data.campaignId?.value,
        callStatuses: filters.data.callStatus?.value ? [filters.data.callStatus?.value] : undefined,
        visitStatuses: filters.data.visitStatuses ? filters.data.visitStatuses.map((item) => item.value) : undefined,
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_REVISION_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_REVISION_LIST__FAILURE, error: error.message });
  }
}

function* fetchRevisionSync() {
  const fetchSyncTask = yield fork(_fetchRevisionList);
  yield take(OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchRevisionList() {
  yield takeLatest(OFFER_FETCH_REVISION_LIST, function* () {
    yield call(fetchRevisionSync);
  });
}

export function* fetchRevisionListOnSearch() {
  yield takeLatest(OFFER_FETCH_COMMON__SET_SEARCH, function* () {
    // yield put({ type: OFFER_FETCH_REVISION_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_REVISION_LIST });
  });
}

/* For the future. May be need */
// export function* fetchRevisionListOnPageChange() {
//   yield takeLatest(OFFER_FETCH_REVISION_LIST__SET_PAGE, function* (action) {
//     yield put({ type: OFFER_FETCH_REVISION_LIST__SET_PAGINATION, page: action.page });
//     yield put({ type: OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST });
//     yield put({ type: OFFER_FETCH_REVISION_LIST });
//   });
// }

export function* fetchRevisionListOnSorting() {
  yield takeLatest(OFFER_FETCH_REVISION_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_REVISION_LIST });
  });
}

export function* fetchRevisionListOnFilters() {
  yield takeLatest(OFFER_FETCH_REVISION_LIST__SET_FILTER, function* () {
    // yield put({ type: OFFER_FETCH_REVISION_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_REVISION_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_REVISION_LIST });
  });
}
// revision end

// agreeing start
function* _fetchAgreeingList() {
  const {
    collection: {
      search,
      agreeing: { filters, sorting },
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_AGREEING_LIST__SET_LOADING });

    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        statuses: undefined,
        campaignId: filters.data.campaignId?.value,
        callStatuses: ['CLIENT_WILL_COME_TO_DC'],
        visitStatuses: ['EVALUATION_TRADE_IN', 'TEST_DRIVE', 'FILING_AN_APPLICATION', 'LOAN_AGREEMENT_EXECUTION'],
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_AGREEING_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_AGREEING_LIST__FAILURE, error: error.message });
  }
}

function* fetchAgreeingSync() {
  const fetchSyncTask = yield fork(_fetchAgreeingList);
  yield take(OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchAgreeingList() {
  yield takeLatest(OFFER_FETCH_AGREEING_LIST, function* () {
    yield call(fetchAgreeingSync);
  });
}

export function* fetchAgreeingListOnSearch() {
  yield takeLatest(OFFER_FETCH_COMMON__SET_SEARCH, function* () {
    // yield put({ type: OFFER_FETCH_AGREEING_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_AGREEING_LIST });
  });
}

/* For the future. May be need */
// export function* fetchAgreeingListOnPageChange() {
//   yield takeLatest(OFFER_FETCH_AGREEING_LIST__SET_PAGE, function* (action) {
//     yield put({ type: OFFER_FETCH_AGREEING_LIST__SET_PAGINATION, page: action.page });
//     yield put({ type: OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST });
//     yield put({ type: OFFER_FETCH_AGREEING_LIST });
//   });
// }

export function* fetchAgreeingListOnSorting() {
  yield takeLatest(OFFER_FETCH_AGREEING_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_AGREEING_LIST });
  });
}

export function* fetchAgreeingListOnFilters() {
  yield takeLatest(OFFER_FETCH_AGREEING_LIST__SET_FILTER, function* () {
    // yield put({ type: OFFER_FETCH_AGREEING_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_AGREEING_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_AGREEING_LIST });
  });
}
// agreeing end

// refused start
function* _fetchRefusedList() {
  const {
    collection: {
      search,
      refused: { filters, sorting },
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);

  const { role } = yield select(getStateAuth);
  const API_METHOD = getApiForOffers(role);

  try {
    yield put({ type: OFFER_FETCH_REFUSED_LIST__SET_LOADING });

    const response = yield call(API_METHOD, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        statuses: undefined,
        campaignId: filters.data.campaignId?.value,
        callStatuses: ['NEGATIVE_CLIENT_NOT_CALL', 'PROPOSAL_IS_NOT_INTERESTING', 'DO_NOT_CALL_BACK'],
        visitStatuses: filters.data.visitStatuses ? filters.data.visitStatuses.map((item) => item.value) : undefined,
      },
      sorting,
      id: data.id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: OFFER_FETCH_REFUSED_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: OFFER_FETCH_REFUSED_LIST__FAILURE, error: error.message });
  }
}

function* fetchRefusedSync() {
  const fetchSyncTask = yield fork(_fetchRefusedList);
  yield take(OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchRefusedList() {
  yield takeLatest(OFFER_FETCH_REFUSED_LIST, function* () {
    yield call(fetchRefusedSync);
  });
}

export function* fetchRefusedListOnSearch() {
  yield takeLatest(OFFER_FETCH_COMMON__SET_SEARCH, function* () {
    // yield put({ type: OFFER_FETCH_REFUSED_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_REFUSED_LIST });
  });
}

/* For the future. May be need */
// export function* fetchRefusedListOnPageChange() {
//   yield takeLatest(OFFER_FETCH_REFUSED_LIST__SET_PAGE, function* (action) {
//     yield put({ type: OFFER_FETCH_REFUSED_LIST__SET_PAGINATION, page: action.page });
//     yield put({ type: OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST });
//     yield put({ type: OFFER_FETCH_REFUSED_LIST });
//   });
// }

export function* fetchRefusedListOnSorting() {
  yield takeLatest(OFFER_FETCH_REFUSED_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_REFUSED_LIST });
  });
}

export function* fetchRefusedListOnFilters() {
  yield takeLatest(OFFER_FETCH_REFUSED_LIST__SET_FILTER, function* () {
    // yield put({ type: OFFER_FETCH_REFUSED_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_REFUSED_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_REFUSED_LIST });
  });
}
// revision end

export function* fetchListOnSearch() {
  yield takeLatest(OFFER_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: OFFER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(OFFER_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: OFFER_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(OFFER_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(OFFER_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: OFFER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: OFFER_FETCH_LIST });
  });
}

function* evaluationSet() {
  yield takeLeading(OFFER_EVALUATION_SET, function* (action) {
    try {
      const {
        evaluations,
      } = action;

      const filterEvaluations = evaluations.reduce((prev, curr) => [...prev, {
        ...curr,
        newCarPrice: (curr.newCarPrice === '0' || curr.newCarPrice === 0)
          ? null : curr.newCarPrice,
        tradeInPrice: (curr.tradeInPrice === '0' || curr.tradeInPrice === 0)
          ? null : curr.tradeInPrice,
        servicesPrice: (curr.servicesPrice === '0' || curr.servicesPrice === 0)
          ? null : curr.servicesPrice,
      }], []);

      const response = yield call(API.TCM.offer.setEvaluation, {
        evaluations: filterEvaluations,
      });

      if (!response.errorCode) {
        yield put({
          type: OFFER_EVALUATION_SET__SUCCESS,
          data: { success: true },
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: OFFER_EVALUATION_SET__FAILURE, error: error.message });
    }
  });
}

function* fetchItem() {
  yield takeLeading(OFFER_FETCH, function* (action) {
    try {
      const {
        id,
      } = action;
      const response = yield call(API.TCM.offer.fetchItem, { id });
      const userRole = checkPrivilege('OFFER_SET_STATUS_WAIT_CONFIRM')
        ? UserRole.Employee
        : UserRole.Manager;
      if (!response.errorCode && typeof response?.data?.id === 'number') {
        yield put({
          type: OFFER_FETCH__SUCCESS,
          data: formatOffer(response.data, userRole),
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: OFFER_FETCH__FAILURE, error: error.message });
    }
  });
}

function* employeeSet() {
  yield takeLeading(OFFER_EMPLOYEE_SET, function* (action) {
    try {
      const { employees } = action;
      const response = yield call(API.TCM.offer.setEmployee, {
        employees,
      });
      if (!response.errorCode && !response.data.length) {
        yield put({
          type: OFFER_EMPLOYEE_SET__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: OFFER_EMPLOYEE_SET__FAILURE, error: error.message });
    }
  });
}

function* offerExport() {
  yield takeLeading(OFFER_EXPORT, function* (action) {
    try {
      const { id } = action;
      const response = yield call(API.TCM.offer.exportOffer, {
        id,
      });
      if (response.size > 0) {
        yield put({
          type: OFFER_EXPORT__SUCCESS,
          data: response,
        });
      } else {
        throw new Error('Ошибка при выгрузке файла');
      }
    } catch (error) {
      yield put({ type: OFFER_EXPORT__FAILURE, error: error.message });
    }
  });
}

function* offerImport() {
  yield takeLeading(OFFER_IMPORT, function* (action) {
    try {
      const { formData, guid } = action;
      const response = yield call(API.TCM.offer.importOffer, { formData, guid });
      const hasError = response.error || response.errorCode || response.errorMessage;
      if (!hasError) {
        yield put({
          type: OFFER_IMPORT__SUCCESS,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          const { validationErrorList } = response.data;
          const errors = parseExcelErrors(validationErrorList);
          yield put({ type: OFFER_IMPORT__FAILURE, error: errors });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: OFFER_IMPORT__FAILURE, error: error.message });
    }
  });
}

function* setOffersStatus() {
  yield takeLeading(OFFERS_STATUS_SET, function* ({ offerIds, status }) {
    try {
      const response = yield call(
        API.TCM.offer.setOfferStatus,
        { offerIds, status },
      );
      if (
        response.data
        && !response.errorCode
      ) {
        yield put({
          type: OFFERS_STATUS_SET_SUCCESS,
          data: response.data,
          status,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: OFFERS_STATUS_SET__FAILURE, error: error.message, status });
    }
  });
}

function* addComment() {
  yield takeLeading(OFFER_ADD_COMMENT, function* (action) {
    const {
      offer: {
        data: {
          externalId,
        },
      },
    } = yield select(getState);
    try {
      const {
        text,
      } = action;
      const response = yield call(API.TCM.offer.addComment, { externalId, text });

      if (!response.error) {
        yield put({
          type: OFFER_ADD_COMMENT__SUCCESS,
        });
      } else {
        throw new Error('Ошибка при добавлении файла');
      }
    } catch (error) {
      yield put({ type: OFFER_ADD_COMMENT__FAILURE, error: error.message });
    }
  });
}

function* postVisitResult() {
  yield takeLatest(POST_VISIT_RESULT, function* ({ formData }) {
    try {
      const response = yield call(API.TCM.offer.postVisitResult, { formData });
      if (
        !response.errorCode && typeof response?.data?.id === 'number'
      ) {
        yield put({
          type: POST_VISIT_RESULT__SUCCESS,
          formData,
        });
        yield put({
          type: OFFER_FETCH__SUCCESS,
          data: formatOffer(response.data),
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: POST_VISIT_RESULT__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: POST_VISIT_RESULT__FAILURE, error: error.message });
    }
  });
}

function* fetchStatuses() {
  yield takeLatest(OFFER_FETCH_STATUSES, function* () {
    try {
      const response = yield call(API.TCM.offer.fetchOfferStatuses);
      if (
        !response.errorCode && Array.isArray(response?.data?.offersStatusCountResponseList)
      ) {
        yield put({
          type: OFFER_FETCH_STATUSES__SUCCESS,
          data: response.data.offersStatusCountResponseList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: OFFER_FETCH_STATUSES__FAILURE, error: error.message });
    }
  });
}

function* oldCarExport() {
  yield takeLeading(OLD_CAR_EXPORT, function* ({ id }) {
    try {
      const response = yield call(API.TCM.offer.exportOldCar, {
        id,
      });
      if (response.size > 0) {
        yield put({
          type: OLD_CAR_EXPORT__SUCCESS,
          data: response,
        });
      } else {
        throw new Error('Ошибка при выгрузке файла');
      }
    } catch (error) {
      yield put({ type: OLD_CAR_EXPORT__FAILURE, error: error.message });
    }
  });
}

function* postAdditionalPhone() {
  yield takeLatest(POST_ADDITIONAL_PHONE, function* ({
    payload: {
      externalId, additionalPhone,
    },
  }) {
    try {
      const response = yield call(API.TCM.offer.postAdditionalPhone, {
        externalId, additionalPhone,
      });
      if (
        !response.errorCode && response?.data
      ) {
        yield put({
          type: POST_ADDITIONAL_PHONE__SUCCESS,
        });
        yield put({
          type: OFFER_FETCH__SUCCESS,
          data: formatOffer(response.data),
        });
      } else {
        throw new Error('Ошибка при сохранении изменения');
      }
    } catch (error) {
      yield put({ type: POST_ADDITIONAL_PHONE__FAILURE, error: error.message });
    }
  });
}

function* getPreviousCampaign() {
  yield takeLatest(OFFER_FETCH_PREVIOUS_CAMPAIGN, function* ({ externalId }) {
    try {
      const response = yield call(API.TCM.offer.getPreviousCampaign, {
        externalId,
      });
      if (response?.data && response?.data.offerItemResponseList) {
        yield put({
          type: OFFER_FETCH_PREVIOUS_CAMPAIGN__SUCCESS,
          data: Array.isArray(response?.data.offerItemResponseList)
            ? formatOfferPreviousCampaign(response?.data) : [],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: OFFER_FETCH_PREVIOUS_CAMPAIGN__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(evaluationSet),
    fork(fetchItem),
    fork(employeeSet),
    fork(offerExport),
    fork(offerImport),
    fork(setOffersStatus),
    fork(addComment),
    fork(postVisitResult),
    fork(getPreviousCampaign),

    fork(fetchUnderwayList),
    fork(fetchUnderwayListOnSearch),
    fork(fetchUnderwayListOnSorting),
    fork(fetchUnderwayListOnFilters),

    fork(fetchWaitConfirmList),
    fork(fetchWaitConfirmListOnSearch),
    fork(fetchWaitConfirmListOnSorting),
    fork(fetchWaitConfirmListOnFilters),

    fork(fetchConfirmedList),
    fork(fetchConfirmedListOnSearch),
    fork(fetchConfirmedListOnSorting),
    fork(fetchConfirmedListOnFilters),

    fork(fetchRevisionList),
    fork(fetchRevisionListOnSearch),
    fork(fetchRevisionListOnSorting),
    fork(fetchRevisionListOnFilters),

    fork(fetchAgreeingList),
    fork(fetchAgreeingListOnSearch),
    fork(fetchAgreeingListOnSorting),
    fork(fetchAgreeingListOnFilters),

    fork(fetchRefusedList),
    fork(fetchRefusedListOnSearch),
    fork(fetchRefusedListOnSorting),
    fork(fetchRefusedListOnFilters),

    fork(fetchStatuses),
    fork(oldCarExport),
    fork(postAdditionalPhone),
  ]);
}
