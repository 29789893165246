import React from 'react';

import { Title } from './style';

function Description({ text, area }) {
  return text ? (
    <Title level={4} area={area}>
      {text}
    </Title>
  ) : null;
}

export default Description;
