import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { useForm } from 'hooks';
import Button from 'components/Button';
import { Search } from 'components/Input';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';
import { initialFilters } from 'redux/user/reducer';
import { Link } from 'react-router-dom';
import { checkPrivilege } from 'helpers/roles';
import { checkIsIternalUser, checkIsOutourceUser } from 'hacks/formatUsers';

import { MVP_3 } from 'settings';

import {
  fetchList as fetchListAction,
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
  downloadListFile as downloadListFileAction,
  downloadListFileReset as downloadListFileResetAction,
} from 'redux/user/actions';

import {
  fetchList as fetchCompanyListAction,
} from 'redux/company/actions';

import {
  fetchList as fetchPointOfSalesListActions,
} from 'redux/dealerCenter/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  STATUS_OPTIONS,
  formatToTable,
  formatToApi,
  STATUS_USER,
} from './data';

import {
  Root,
  Header,
  HeaderTitle,
  HeaderSearch,
  Title,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
  ItemsContainer,
  ItemsContent,
  ItemsContentContainer,
  Count,
  ButtonsContainer,
  DownloadButtonContainer,
} from './style';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 460px)' };
const showSearch = false;

const renderItems = (array) => (
  <ItemsContainer>
    {Array.isArray(array) && array.length === 1 && array[0]}
    {Array.isArray(array) && array.length > 1 && (
      <ItemsContentContainer content={(
        <ItemsContent>
          {array.map((item, index) => index !== 0 && (<div>{item}</div>))}
        </ItemsContent>
      )}
      >
        {array[0]}
        <Count>
          {' '}
          {array.length > 9 ? '9+' : array.length - 1 }
          {' '}
        </Count>
      </ItemsContentContainer>
    )}
  </ItemsContainer>
);

const getColumns = ({ columns = [], role = '' }) => {
  if (Array.isArray(columns) && role) {
    return columns.filter((e) => e.checkRights(role));
  }
  return [];
};

const downloadURL = (fileUrl) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();
};

function List({
  // connect
  role,
  list,
  sorting,
  search,
  isLoading,
  error,
  pagination,
  isLoaded,

  downloadUsersListData,
  downloadUsersListIsLoaded,
  downloadUsersListIsLoading,
  downloadUsersListError,

  companyList,
  companyListIsLoaded,
  companyListIsLoading,

  pointOfSalesList,
  pointOfSalesIsLoaded,
  pointOfSalesIsLoading,

  // actions
  setTablePage,
  setSorting,
  setFilters,
  setSearch,
  resetTable,
  setBreadcrumbs,
  fetchCompanyList,
  fetchPointOfSalesList,
  downloadListFile,
  downloadListFileReset,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [pointOfSalesOptions, setPointOfSalesOptions] = useState([]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Пользователи', link: '/app/management/users/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    const { legalEntityIds, subsidiaryIds } = form;
    setFilters({
      filters: {
        ...form,
        legalEntityIds: formatToApi({ array: legalEntityIds, options: companiesOptions }),
        subsidiaryIds: formatToApi({ array: subsidiaryIds, options: pointOfSalesOptions }),
      },
    });

    return () => resetTable();
      // eslint-disable-next-line
  }, [form, resetTable, setFilters]);

  useEffect(() => {
    if (isLoaded && checkPrivilege(role.ORGANISATIONS.VIEW)) {
      fetchCompanyList({
        withPagination: false,
      });
    }
  }, [isLoaded, fetchCompanyList, role]);

  useEffect(() => {
    if (companyListIsLoaded && (companyList.length > 0)) {
      setCompaniesOptions(formatToTable(companyList));
    }
  }, [companyListIsLoaded, companyList]);

  useEffect(() => {
    if (companyListIsLoaded) {
      fetchPointOfSalesList({
        withPagination: false,
      });
    }
  }, [fetchPointOfSalesList, companyListIsLoaded]);

  useEffect(() => {
    if (pointOfSalesIsLoaded && (pointOfSalesList.length > 0)) {
      setPointOfSalesOptions(formatToTable(pointOfSalesList));
    }
  }, [pointOfSalesIsLoaded, pointOfSalesList]);

  useEffect(() => {
    if (downloadUsersListIsLoaded) {
      const { url } = downloadUsersListData;
      if (url) {
        downloadURL(url);
      }
    }
  }, [downloadUsersListIsLoaded, downloadUsersListData]);

  useEffect(() => () => downloadListFileReset(), [downloadListFileReset]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  function handleOnSearch(v) {
    setSearch({ search: v });
  }

  function handleOnBlur() {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  function downloadUserListFile() {
    downloadListFile();
  }

  const COLUMNS = [
    {
      key: 'displayName',
      checkRights: () => true,
      title: () =>
        TableHeader({
          title: 'ФИО',
          sortBy: 'displayName',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'displayName',
          filterValue: form.displayName,
          filterOnChange: updateForm,
        }),
      render: ({ displayName, id }) => (
        <Link to={`/app/management/users/${id}`}>
          {displayName || `Пользователь ${id}`}
        </Link>
      ),
      width: 240,
    },
    {
      key: 'login',
      checkRights: () => true,
      title: () =>
        TableHeader({
          title: 'Логин/Email',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'login',
          filterValue: form.login,
          filterOnChange: updateForm,
        }),
      render: ({ login }) => <span>{login}</span>,
      width: 240,
    },
    {
      key: 'phone',
      checkRights: () => true,
      title: () =>
        TableHeader({
          title: 'Телефон',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'phone',
          filterValue: form.phone,
          filterOnChange: updateForm,
        }),
      render: ({ phone }) => <span>{phone}</span>,
      width: 240,
    },
    {
      key: 'legalEntityNameList',
      checkRights: (role) => checkPrivilege(role.ORGANISATIONS.VIEW),
      title: () =>
        TableHeader({
          title: 'Организация',
          sortBy: 'legalEntityIds',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterName: 'legalEntityIds',
          filterValue: form.legalEntityIds,
          filterOnChange: updateForm,
          filterOptions: companiesOptions,
          filterIsLoading: companyListIsLoading,
          hasDefault: false,
        }),
      render: ({ legalEntityNameList }) => renderItems(legalEntityNameList),
      width: 240,
    },
    {
      key: 'subsidiaryNameList',
      checkRights: (role) => checkPrivilege(role.ORGANISATIONS.VIEW_POINTS_OF_SALES),
      title: () =>
        TableHeader({
          title: 'Точка продаж',
          sortBy: 'subsidiaryIds',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterName: 'subsidiaryIds',
          filterValue: form.subsidiaryIds,
          filterOnChange: updateForm,
          filterOptions: pointOfSalesOptions,
          filterIsLoading: pointOfSalesIsLoading,
          hasDefault: false,
        }),
      render: ({ subsidiaryNameList }) => renderItems(subsidiaryNameList),
      width: 240,
    },
    {
      key: 'status',
      checkRights: () => true,
      title: () =>
        TableHeader({
          title: 'Статус',
          sortBy: 'status',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'status',
          filterValue: form.status,
          filterOnChange: updateForm,
          filterOptions: STATUS_OPTIONS,
        }),
      render: ({ status }) => <span>{STATUS_OPTIONS.find((item) => item.value === status).label}</span>,
      width: 240,
    },
    {
      key: 'channel',
      checkRights: (role) => checkPrivilege([role.MANAGEMENT.VIEW_USER_INTERNAL]),
      title: () =>
        TableHeader({
          title: 'Авторизация',
          filterType: FILTER_TYPES.SELECT,
          filterName: 'channel',
          filterValue: form.channel,
          filterOnChange: updateForm,
          filterOptions: STATUS_USER,
        }),
      render: ({ userType }) => (
        <span>
          {checkIsIternalUser(userType)
            ? 'Внутренний пользователь'
            : (checkIsOutourceUser(userType)
              ? 'Аутсорс пользователь'
              : 'Внешний пользователь')}
        </span>
      ),
      width: 240,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  const columns = getColumns({ columns: COLUMNS, role });

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>Все пользователи</Title>
          <ButtonsContainer>
            {checkPrivilege([role.MANAGEMENT.ADD_ACTIVE_DIRECTORY_USER, role.MANAGEMENT.ADD_WEB_USER]) && (
              <Button
                size="mini"
                type="link"
                shape="quare"
                href="/app/management/users/new"
              >
                Добавить пользователя
              </Button>
            )}
            {MVP_3 && (
              <DownloadButtonContainer>
                <Button
                  size="mini"
                  type="ghost"
                  shape="quare"
                  loading={downloadUsersListIsLoading}
                  onClick={() => downloadUserListFile()}
                >
                  Выгрузить
                </Button>
                <ErrorMessage>{downloadUsersListError}</ErrorMessage>
              </DownloadButtonContainer>
            )}
          </ButtonsContainer>
        </HeaderTitle>
        {showSearch && (
          <HeaderSearch>
            <Search
              allowClear
              placeholder="Поиск..."
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
              onSearch={handleOnSearch}
              onBlur={handleOnBlur}
            />
          </HeaderSearch>
        )}
      </Header>

      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    list: state.user.collection.list,
    sorting: state.user.collection.sorting,
    filters: state.user.collection.filters,
    search: state.user.collection.search,
    isLoaded: state.user.collection.isLoaded,
    isLoading: state.user.collection.isLoading,
    error: state.user.collection.error,
    pagination: state.user.collection.pagination,

    downloadUsersListData: state.user.downloadUsersList.data,
    downloadUsersListIsLoaded: state.user.downloadUsersList.isLoaded,
    downloadUsersListIsLoading: state.user.downloadUsersList.isLoading,
    downloadUsersListError: state.user.downloadUsersList.error,

    companyList: state.company.collection.list,
    companyListIsLoaded: state.company.collection.isLoaded,
    companyListIsLoading: state.company.collection.isLoading,

    pointOfSalesList: state.dealerCenter.collection.list,
    pointOfSalesIsLoaded: state.dealerCenter.collection.isLoaded,
    pointOfSalesIsLoading: state.dealerCenter.collection.isLoading,
  }),
  {
    fetchList: fetchListAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,
    setBreadcrumbs: setBreadcrumbsAction,
    fetchCompanyList: fetchCompanyListAction,
    fetchPointOfSalesList: fetchPointOfSalesListActions,
    downloadListFile: downloadListFileAction,
    downloadListFileReset: downloadListFileResetAction,
  },
)(List);
