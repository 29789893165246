import React, { useState } from 'react';
import moment from 'moment';
import { TCM } from 'components/tcm/index';
import {
  DateDescription,
  HistoryWrapper,
  ModalTitle,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  CurrentStatus, DateDescriptionWrapper, TableWrapper,
} from 'components/tcm/CampaignInfo/style';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import { LongDownArrow } from 'icons/ArrowIcon';
import { exportStatusHistory } from 'services/tcm/campaign';

const getDescriptionFromStatus = (status) => {
  switch (status) {
    case CAMPAIGN_STATUSES.CREATION:
      return 'Создание кампании';
    case CAMPAIGN_STATUSES.DRAFT:
      return 'Сохранение черновика';
    case CAMPAIGN_STATUSES.VIN_REQUEST:
      return 'Подбор VIN номеров';
    case CAMPAIGN_STATUSES.EVALUATION:
      return 'Начало оценки автомобилей';
    case CAMPAIGN_STATUSES.KP_CALCULATION:
      return 'Расчет КП';
    case CAMPAIGN_STATUSES.DISTRIBUTION:
      return 'Распределение клиентов по сотрудникам';
    case CAMPAIGN_STATUSES.CALLING:
      return 'Начало обзвона клиентов';
    case CAMPAIGN_STATUSES.COMPLETE:
      return 'Конец обзвона клиентов';
    default:
      return '-';
  }
};

export const HistoryModal = ({
  statusHistory, campaignId, visible, onCancel,
}) => {
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showExportError, setShowExportError] = useState(null);

  const historyItems = statusHistory?.map((item, index) => {
    const isPending = ['CURRENT', 'PENDING'].includes(item.timestamp)
      && !(item.timestamp === 'CURRENT' && index === statusHistory.length - 1);
    return (
      <TableRow key={item.createAtRaw}>
        <CurrentStatus>
          {item.timestamp === 'CURRENT' && index !== statusHistory.length - 1
          && (
            <DateDescriptionWrapper>
              <DateDescription>Ожидаемые даты</DateDescription>
              <LongDownArrow />
            </DateDescriptionWrapper>
          )}
          <TableCell isSecondary={isPending}>{moment(item.createAtRaw).format('DD.MM.YYYY')}</TableCell>
        </CurrentStatus>
        <TableCell>{item.author ?? '-'}</TableCell>
        <TableCell>{getDescriptionFromStatus(item.code)}</TableCell>
      </TableRow>
    );
  });

  const onExportClick = () => {
    setIsExportLoading(true);
    exportStatusHistory({ id: campaignId }).then((resp) => {
      setIsExportLoading(false);
      if (resp.size > 0) {
        const url = URL.createObjectURL(resp);
        const shadowLink = document.createElement('a');
        shadowLink.href = url;
        shadowLink.download = `${campaignId}history.xlsx`;
        shadowLink.click();
      } else {
        setShowExportError(true);
      }
    }).catch(() => {
      setIsExportLoading(false);
      setShowExportError(true);
    });
  };

  return (
    <TCM.Modal
      visible={visible}
      onCancel={onCancel}
    >
      <HistoryWrapper>
        <ModalTitle>История действий по кампании</ModalTitle>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableCell>Дата</TableCell>
              <TableCell>Автор</TableCell>
              <TableCell>Изменение</TableCell>
            </TableHeader>
            {historyItems}
          </Table>
        </TableWrapper>
        <TCM.ButtonGroup>
          <TCM.Button onClick={onExportClick} loading={isExportLoading}>
            Выгрузить в Excel
          </TCM.Button>
          <TCM.ErrorMessage>{showExportError}</TCM.ErrorMessage>
          <TCM.ButtonOutline
            onClick={
              () => {
                onCancel();
              }
            }
          >
            Закрыть окно
          </TCM.ButtonOutline>
        </TCM.ButtonGroup>
      </HistoryWrapper>
    </TCM.Modal>
  );
};
