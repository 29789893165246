import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  extractErrorAndCode, formatOffer,
} from 'hacks';
import {
  POST_CALL_RESULT,
  POST_CALL_RESULT__SUCCESS,
  POST_CALL_RESULT__FAILURE,
} from 'redux/tcmCallResult/actions';
import { OFFER_FETCH__SUCCESS } from 'redux/tcmOffer/actions';
import { UserRole } from 'hacks/formatOffer';

function* postCallResult() {
  yield takeLatest(POST_CALL_RESULT, function* ({ formData }) {
    try {
      const response = yield call(API.TCM.offer.postCallResult, { formData });
      if (
        !response.errorCode && typeof response?.data?.id === 'number'
      ) {
        yield put({
          type: POST_CALL_RESULT__SUCCESS,
          formData,
        });
        yield put({
          type: OFFER_FETCH__SUCCESS,
          data: formatOffer(response.data, UserRole.Employee),
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: POST_CALL_RESULT__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: POST_CALL_RESULT__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(postCallResult),
  ]);
}
