import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { Search } from 'components/Input';
import { useForm } from 'hooks';
import Button from 'components/Button';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';
import { initialFilters } from 'redux/dealerCenter/reducer';
import { Link } from 'react-router-dom';
import { checkPrivilege } from 'helpers/roles';

import {
  reset as resetTableAction,
  setFilters as setFiltersAction,
  setPage as setTablePageAction,
  setSearch as setSearchAction,
  setSorting as setSortingAction,
} from 'redux/dealerCenter/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  EmptyMessage,
  ErrorMessage,
  Export,
  Header,
  HeaderSearch,
  HeaderTitle,
  LoadingMessage,
  Root,
  Title,
} from './style';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 460px)' };

function List({
  // connect
  role,

  list,
  companyList,
  sorting,
  search,
  isLoaded,
  isLoading,
  error,
  pagination,

  // actions
  setTablePage,
  setSorting,
  setFilters,
  setSearch,
  resetTable,
  setBreadcrumbs,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState([]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Точки продаж', link: '/app/management/dealerCenters/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    setFilters({ filters: form });
    return () => resetTable();
  }, [form, resetTable, setFilters]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  function handleOnSearch(v) {
    setSearch({ search: v });
  }

  function handleOnBlur() {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  function handleSelectionChange(selectedRowKeys) {
    setSelected(selectedRowKeys);
  }

  const columns = [
    {
      key: 'salePoint',
      title: () =>
        TableHeader({
          title: 'Точка продаж',
          sortBy: 'salePoint',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'salePoint',
          filterValue: form.salePoint,
          filterOnChange: updateForm,
        }),
      render: ({ salePoint, id }) => (
        <Link to={`/app/management/dealerCenters/${id}`}>
          <span>{salePoint}</span>
        </Link>
      ),
      width: 240,
    },
    {
      key: 'organization',
      title: () =>
        TableHeader({
          title: 'Организация',
          filterType: FILTER_TYPES.ORGANISATION_SEARCH,
          filterName: 'organization',
          filterValue: form.organization,
          filterOptions: companyList.map((e) => ({ label: e.name, value: e.name })),
          filterIsLoading: false,
          filterOnChange: updateForm,
        }),
      render: ({ organisation }) => organisation && (
        <Link to={`/app/management/companies/${organisation.id}`}>
          <span>{organisation.name}</span>
        </Link>
      ),
      width: 240,
    },
    {
      key: 'inn',
      title: () =>
        TableHeader({
          title: 'ИНН',
          sortBy: 'inn',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'inn',
          filterValue: form.inn,
          filterOnChange: updateForm,
        }),
      render: ({ inn, id }) => (
        <Link to={`/app/management/dealerCenters/${id}`}>
          <span>{inn}</span>
        </Link>
      ),
      width: 240,
    },
    {
      key: 'krifOrganizationId',
      title: () =>
        TableHeader({
          title: 'Криф ID',
          sortBy: 'krifOrganizationId',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'krifOrganizationId',
          filterValue: form.krifOrganizationId,
          filterOnChange: updateForm,
        }),
      render: ({ krifOrganizationId, id }) => (
        <Link to={`/app/management/dealerCenters/${id}`}>
          <span>{krifOrganizationId}</span>
        </Link>
      ),
      width: 240,
    },
    {
      key: 'dealershipId',
      title: () =>
        TableHeader({
          title: 'Эквайринг ID',
          sortBy: 'dealershipId',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'dealershipId',
          filterValue: form.acquiringID,
          filterOnChange: updateForm,
        }),
      render: ({ dealershipId, id }) => (
        <Link to={`/app/management/dealerCenters/${id}`}>
          <span>{dealershipId}</span>
        </Link>
      ),
      width: 240,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  const rowSelection = {
    columnWidth: 60,
    fixed: true,
    hideDefaultSelections: true,
    selectedRowKeys: selected,
    onChange: handleSelectionChange,
  };

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>Все Точки продаж</Title>
          {checkPrivilege(role.ORGANISATIONS.CREATE) && (
            <Button
              size="mini"
              type="link"
              shape="square"
              href="/app/management/dealerCenters/new"
            >
              Добавить
            </Button>
          )}
        </HeaderTitle>
        <HeaderSearch>
          <Search
            allowClear
            placeholder="Поиск..."
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            onSearch={handleOnSearch}
            onBlur={handleOnBlur}
          />
        </HeaderSearch>
      </Header>

      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        // rowSelection={rowSelection}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />

      {isLoaded && selected.length > 0 && rowSelection.fixed && (
        <Export>
          <Button type="ghost" size="small" shape="square">
            выгрузка
          </Button>
        </Export>
      )}

      {/* {isLoaded && ( */}
      {/*  <Footer> */}
      {/*    <Buttons> */}
      {/*      <Button type="default" size="small" shape="square"> */}
      {/*        загрузка */}
      {/*      </Button> */}
      {/*    </Buttons> */}
      {/*    <Description>выгрузка и загрузка в формате EXL </Description> */}
      {/*  </Footer> */}
      {/* )} */}
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    list: state.dealerCenter.collection.list,
    companyList: state.dealerCenter.collection.companyList,
    sorting: state.dealerCenter.collection.sorting,
    filters: state.dealerCenter.collection.filters,
    search: state.dealerCenter.collection.search,
    isLoaded: state.dealerCenter.collection.isLoaded,
    isLoading: state.dealerCenter.collection.isLoading,
    error: state.dealerCenter.collection.error,
    pagination: state.dealerCenter.collection.pagination || {},
  }),
  {
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
