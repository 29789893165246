import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const Wrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 360px;
`;

export const Table = withTheme(styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  opacity: ${({ hide }) => (hide ? 0.2 : 1)};
  transition: .2s linear opacity;

  th, td {
    padding-right: 16px;

    &:last-child{
      padding-right: 0;
    }
  }

  th {
    padding-bottom: 15px;
    border-bottom: 1px solid  ${({ theme }) => theme.palette.gray4};
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.gray3};
    
    &:nth-child(2){
      color: ${({ theme }) => theme.palette.black};
    }
    
    &:nth-child(5){
      min-width: 189px;
    }
    
    &:nth-child(6) {
      min-width: 130px;
    }
    
    &:nth-child(7) {
      min-width: 126px;
    }
    
    &:nth-child(8){
      min-width: 110px;
    }
  }

  td {
    padding-top: 24px;
    padding-bottom: 23px;
    border-bottom: 1px solid  ${({ theme }) => theme.palette.gray5};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.black};
    
    &:nth-child(3){
      min-width: 150px;
    }
    
    &:nth-child(5), &:nth-child(6), &:nth-child(7){
      width: min-content;
    }
    
    &:nth-child(6), &:nth-child(7), &:nth-child(8){
      font-size: 16px;
    }
  }

  th, td {
    width: min-content;
  }
`);

export const EmployeesTitle = styled.h4`
  margin-bottom: 47px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 46px;
`;

export const EmployeesWrapper = styled.div`
  margin-top: 50px;
`;

export const EmployeesList = styled.fieldset`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 72px;
  
  p {
    margin-bottom: 0;
  }
  .ant-radio-wrapper span:last-child{
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    display: inline-block;
  }
`;

export const FilterBtnContainer = styled.div`
  width: 100%;
  
  button {
    position: absolute;
    top: -25px;
    right: 0;
  }
`;

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 70px;
  
  & > .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 110px;
  }
 `;
