import {
  REPORT_EXPORT,
  REPORT_EXPORT__SUCCESS,
  REPORT_EXPORT__FAILURE,
  REPORT_EXPORT__RESET,

  SAVE_REPORT_TEMPLATE,
  SAVE_REPORT_TEMPLATE__SUCCESS,
  SAVE_REPORT_TEMPLATE__FAILURE,
  SAVE_REPORT_TEMPLATE__RESET,

  GET_TCM_REPORT_SETTINGS,
  GET_TCM_REPORT_SETTINGS__SUCCESS,
  GET_TCM_REPORT_SETTINGS__FAILURE,
  GET_TCM_REPORT_SETTINGS__RESET,

  GET_TCM_REPORT_LIST,
  GET_TCM_REPORT_LIST__SUCCESS,
  GET_TCM_REPORT_LIST__FAILURE,
} from 'redux/tcmReport/actions';

const initState = {
  export: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  reportTemplate: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  settings: {
    data: {
      firstDialingDate: null,
    },
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  reportList: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function tcmReportReducer(state = initState, action) {
  switch (action.type) {
    case REPORT_EXPORT: {
      return {
        ...state,
        export: {
          ...initState.export,
          isLoading: true,
        },
      };
    }
    case REPORT_EXPORT__SUCCESS: {
      return {
        ...state,
        export: {
          ...state.export,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case REPORT_EXPORT__FAILURE: {
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case REPORT_EXPORT__RESET: {
      return {
        ...state,
        export: {
          ...initState.export,
        },
      };
    }

    case SAVE_REPORT_TEMPLATE: {
      return {
        ...state,

        reportTemplate: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }

    case SAVE_REPORT_TEMPLATE__SUCCESS: {
      return {
        ...state,
        reportTemplate: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }

    case SAVE_REPORT_TEMPLATE__FAILURE: {
      return {
        ...state,
        reportTemplate: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case SAVE_REPORT_TEMPLATE__RESET: {
      return {
        ...state,
        reportTemplate: {
          ...initState.reportTemplate,
        },
      };
    }

    case GET_TCM_REPORT_SETTINGS__RESET: {
      return {
        ...state,
        settings: {
          ...initState.settings,
        },
      };
    }
    case GET_TCM_REPORT_SETTINGS: {
      return {
        ...state,
        settings: {
          ...initState.settings,
          isLoading: true,
        },
      };
    }
    case GET_TCM_REPORT_SETTINGS__SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case GET_TCM_REPORT_SETTINGS__FAILURE: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false,
          error: action.error,
        },
      };
    }

    case GET_TCM_REPORT_LIST: {
      return {
        ...state,
        reportList: {
          ...initState.reportList,
          isLoading: true,
        },
      };
    }
    case GET_TCM_REPORT_LIST__SUCCESS: {
      return {
        ...state,
        reportList: {
          ...state.reportList,
          list: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case GET_TCM_REPORT_LIST__FAILURE: {
      return {
        ...state,
        reportList: {
          ...state.reportList,
          isLoading: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
