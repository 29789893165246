import React from 'react';
import { Form } from 'antd';
import { TCM } from 'components/tcm';
import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const CommentLabel = withTheme(styled.p`
  margin-bottom: 21px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

/**
 * Component for adding comment
 *
 * @param {string} title
 * @param {React.TextareaHTMLAttributes} fieldProps
 * @param  {FormItemProps} formItemProps
 * @return {JSX.Element}
 * @constructor
 */
const AddComment = ({ title, formItemProps, fieldProps }) => (
  <>
    <CommentLabel>{title}</CommentLabel>
    <Form.Item {...formItemProps}>
      <TCM.Textarea {...fieldProps} />
    </Form.Item>
  </>
);

export { AddComment };
