import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { DATE_FORMAT_WITH_TIME } from 'settings';
import moment from 'moment';

import {
  LoadingMessage, ErrorMessage, EmptyMessage, Header, Status, Type,
} from './style';

const columns = [
  {
    key: 'operationId',
    title: 'ID Операции',
    render: ({ operationId }) => <span>{operationId}</span>,
    width: 240,
  },
  {
    key: 'data',
    title: 'дата и время операции',
    render: ({ data }) =>
      data
      && typeof data === 'string' && (
        <span>{moment.utc(data).format(DATE_FORMAT_WITH_TIME)}</span>
      ),
    width: 240,
  },
  {
    key: 'sum',
    title: 'Cумма операции',
    render: ({ sum }) => <span>{sum}</span>,
    width: 240,
  },
  {
    key: 'code',
    title: 'Код авторизации',
    render: ({ code }) => <span>{code}</span>,
    width: 240,
  },
  {
    key: 'cartNumber',
    title: 'Номер карты',
    render: ({ cartNumber }) => <span>{cartNumber}</span>,
    width: 240,
  },
  {
    key: 'type',
    title: 'Тип',
    render: ({ type }) => <Type type={type}>{type}</Type>,
    width: 240,
  },
  {
    key: 'status',
    title: 'Статус',
    render: ({ status }) => <Status status={status}>{status}</Status>,
    width: 240,
  },
];

function Operations({
  // connect
  data,
  operations,
  isLoading,
  error,
}) {
  const { orderId } = data;
  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  return (
    <>
      <Header>
        {' '}
        Операции для id заказа
        {' '}
        {orderId}
      </Header>
      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={operations}
        bordered
        loading={isLoading}
        locale={locale}
      />
    </>
  );
}

export default connect(
  (state) => ({
    data: state.transaction.item.data,
    operations: state.transaction.item.operations,
    isLoading: state.transaction.item.isLoading,
    isLoaded: state.transaction.item.isLoaded,
    error: state.transaction.item.error,
  }),
  null,
)(Operations);
