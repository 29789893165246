import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import { CAMPAIGN_TIMESTAMPS, CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import { OfferStatus } from 'tcm/offer/data';
import { isDateExpired } from 'helpers/date';
import {
  AttentionIcon,
  Date, DatesWrapper, ItemWrapper, PopupText, PopUpWrapper, Title, Wrapper,
} from './style';

const dontShowPopUpStatuses = [
  CAMPAIGN_STATUSES.DRAFT,
  CAMPAIGN_STATUSES.CONFIRMED,
  CAMPAIGN_STATUSES.REVISION,
  CAMPAIGN_STATUSES.COMPLETE,
  CAMPAIGN_STATUSES.CLOSED,
  OfferStatus.Confirmed,
];

const canShowPopUp = (status) => status.code && (
  !dontShowPopUpStatuses.find((item) => item === status.code)
);

const PopUp = ({ text, isExpired }) => {
  const [styles, setStyles] = useState({});
  const popupRef = useRef(null);

  useEffect(() => {
    if (popupRef.current) {
      const { current: { offsetParent: parent, offsetWidth } } = popupRef;
      const halfParentWidth = parent.offsetWidth / 2;
      if (offsetWidth / 2 > parent.offsetLeft + halfParentWidth) {
        setStyles({
          width: 'min-content',
          padding: '20px',
          visibility: 'visible',
        });
      } else {
        setStyles({ visibility: 'visible' });
      }
    }
  }, []);

  return (
    <PopUpWrapper ref={popupRef} style={styles}>
      <AttentionIcon color="red" />
      <PopupText isExpired={isExpired}>{text}</PopupText>
    </PopUpWrapper>
  );
};

const Item = ({ status }) => {
  const titleRef = useRef(null);
  const isNext = status.timestamp === CAMPAIGN_TIMESTAMPS.CURRENT
    || status.timestamp === CAMPAIGN_TIMESTAMPS.PENDING;
  const canBeExpired = !(status.code === CAMPAIGN_STATUSES.DRAFT
    || status.code === CAMPAIGN_STATUSES.COMPLETE
    || status.code === CAMPAIGN_STATUSES.CLOSED);
  const isExpired = canBeExpired ? isDateExpired(status.deadline) : false;
  const isComplete = status.timestamp === 'CURRENT' && (status.code === CAMPAIGN_STATUSES.COMPLETE
    || status.code === OfferStatus.Confirmed);

  return (
    <ItemWrapper isNext={isNext}>
      <Title ref={titleRef} timestamp={status.timestamp}>{status.title}</Title>
      <Dates
        datesTo={status.createdAt}
        timestamp={status.timestamp}
        isComplete={isComplete}
      />

      {status.timestamp === CAMPAIGN_TIMESTAMPS.CURRENT && canShowPopUp(status) && (
        <PopUp text={status.popUpText} isExpired={isExpired} />
      )}
    </ItemWrapper>
  );
};

const Dates = ({ datesTo, timestamp, isComplete }) => (
  <DatesWrapper timestamp={timestamp} isComplete={isComplete}>
    <Date>{datesTo[0]}</Date>
    {datesTo.length > 1 && (
      <Date>{datesTo[datesTo.length - 1]}</Date>
    )}
  </DatesWrapper>
);

const Statuses = ({ statuses }) => (
  <>
    {statuses && statuses.map((status) => (
      <Item key={status.code} status={status} />
    ))}
  </>
);

/**
 * @param {
 *   {
 *     statuses: {
 *       title: string | React.Component,
 *       datesTo: string[],
 *       code: string,
 *       timestamp: 'PREV' | 'CURRENT' | 'NEXT',
 *       deadline: string
 *     }[]
 *   }
 * } props
 * @return {React.Component}
 */
export const StatusLine = memo(({ statuses }) => (
  <Wrapper>
    <Statuses statuses={statuses} />
  </Wrapper>
));
