import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { initialFilters } from 'redux/tcmCampaign/reducer';
import {
  setFilters as setFiltersAction,
} from 'redux/tcmCampaign/actions';
import { fetchSubjectList as fetchSubjectListAction } from 'redux/tcmTopics/actions';
import { Modal } from '../Modal';
import { TCM } from '../index';
import { ButtonGroup } from '../Button';
import {
  Wrapper, Title, FormRow, FormFooter,
} from './style';
import { KEYS as K } from './data';

const getSubtopicsByTopic = (subjectSubtopics) => {
  const subtopicsByTopic = new Map();
  subjectSubtopics.forEach((item) => {
    if (subtopicsByTopic.has(item.topicId)) {
      subtopicsByTopic.set(item.topicId, subtopicsByTopic.get(item.topicId).concat([item]));
    } else {
      subtopicsByTopic.set(item.topicId, [item]);
    }
  });

  return subtopicsByTopic;
};

const CampaignsFilter = ({
  // passed
  isVisible,
  onClose,

  // connect
  appliedFilters,

  // actions
  setFilters,
  fetchSubjectList,

  subjectTopics,
  subjectSubtopics,
  subjectIsLoading,
  subjectIsLoaded,
  // subjectError,
}) => {
  const [form] = Form.useForm();
  const [subjectSubtopOptions, setSubjectSubtopOptions] = useState([]);

  const handleValuesChange = (field) => {
    const [key, value] = Object.entries(field)[0];

    if (key === 'THEME') {
      if (value.length > 0) {
        const subtopicsByTopic = getSubtopicsByTopic(subjectSubtopics);

        const options = value.reduce((prev, curr) => prev.concat(subtopicsByTopic.get(curr)), []);

        setSubjectSubtopOptions(options);

        const subtopValues = form.getFieldValue(K.SUB_THEME.key);
        const validValues = Array.isArray(subtopValues) && subtopValues.length > 0
          ? options.filter((item) => subtopValues.includes(item.id))
            .reduce((prev, curr) => [...prev, curr.id], [])
          : [];

        form.setFieldsValue({
          [K.SUB_THEME.key]: validValues,
        });

        const {
          [K.THEME.key]: topics,
          [K.SUB_THEME.key]: subtopics,
          [K.COUNT_FROM.key]: countCarsFrom,
          [K.COUNT_TO.key]: countCarsTo,
          [K.EXECUTION_DATE_FROM.key]: deadlineFrom,
          [K.EXECUTION_DATE_TO.key]: deadlineTo,
          [K.DATE_FROM.key]: dialingFrom,
          [K.DATE_TO.key]: dialingTo,
        } = form.getFieldsValue();

        setFilters({
          filters: {
            topics: topics?.map((topicId) => ({
              value: topicId,
              label: subjectTopics.find((topic) => topic.id === topicId).name,
            })),
            subtopics: subtopics?.map((subtopicId) => ({
              value: subtopicId,
              label: subjectSubtopics.find((subtopic) => subtopic.id === subtopicId).name,
            })),
            countCarsFrom,
            countCarsTo,
            deadlineFrom,
            deadlineTo,
            dialingFrom,
            dialingTo,
          },
        });
      } else {
        setSubjectSubtopOptions(subjectSubtopics);
      }
    }
  };

  const onReset = () => {
    form.resetFields();
    setFilters({ filters: initialFilters });
  };

  useEffect(() => {
    fetchSubjectList();
  }, [fetchSubjectList]);

  useEffect(() => {
    if (subjectIsLoaded) {
      setSubjectSubtopOptions(subjectSubtopics);
    }
  }, [subjectIsLoaded, subjectSubtopics]);

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({
        [K.THEME.key]: appliedFilters.topics?.map((t) => t.value),
        [K.SUB_THEME.key]: appliedFilters.subtopics?.map((t) => t.value),
        [K.COUNT_FROM.key]: appliedFilters.countCarsFrom,
        [K.COUNT_TO.key]: appliedFilters.countCarsTo,
        [K.EXECUTION_DATE_FROM.key]: appliedFilters.deadlineFrom,
        [K.EXECUTION_DATE_TO.key]: appliedFilters.deadlineTo,
        [K.DATE_FROM.key]: appliedFilters.dialingFrom,
        [K.DATE_TO.key]: appliedFilters.dialingTo,
      });
    } else {
      const value = appliedFilters.topics || [];
      if (value.length > 0) {
        const subtopicsByTopic = getSubtopicsByTopic(subjectSubtopics);

        const options = value.reduce((prev, curr) => prev.concat(subtopicsByTopic.get(curr.value)), []);
        setSubjectSubtopOptions(options);
      } else {
        setSubjectSubtopOptions(subjectSubtopics);
      }
    }
  }, [form, appliedFilters, isVisible, subjectSubtopics]);

  const onFinish = (values) => {
    const {
      [K.THEME.key]: topics,
      [K.SUB_THEME.key]: subtopics,
      [K.COUNT_FROM.key]: countCarsFrom,
      [K.COUNT_TO.key]: countCarsTo,
      [K.EXECUTION_DATE_FROM.key]: deadlineFrom,
      [K.EXECUTION_DATE_TO.key]: deadlineTo,
      [K.DATE_FROM.key]: dialingFrom,
      [K.DATE_TO.key]: dialingTo,
    } = values;

    setFilters({
      filters: {
        topics: topics?.map((topicId) => ({
          value: topicId,
          label: subjectTopics.find((topic) => topic.id === topicId).name,
        })),
        subtopics: subtopics?.map((subtopicId) => ({
          value: subtopicId,
          label: subjectSubtopics.find((subtopic) => subtopic.id === subtopicId).name,
        })),
        countCarsFrom,
        countCarsTo,
        deadlineFrom,
        deadlineTo,
        dialingFrom,
        dialingTo,
      },
    });

    onClose();
  };

  if (!subjectIsLoaded) {
    return null;
  }

  return (
    <Modal visible={isVisible} onCancel={onClose}>
      <Wrapper>
        <Title>Фильтр по кампаниям</Title>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onReset={onReset}
          name="campaignsFilter"
          onValuesChange={handleValuesChange}
        >
          <FormRow>
            <TCM.Label
              label={K.THEME.label}
              name={K.THEME.key}
            >
              <TCM.MultipleSelect
                name={K.THEME.key}
                placeholder={K.THEME.placeholder}
                selected={appliedFilters.topics}
                options={subjectTopics}
                loading={subjectIsLoading}
                optionFilterProp="label"
              />
            </TCM.Label>
            <TCM.Label
              label={K.SUB_THEME.label}
              name={K.SUB_THEME.key}
            >
              <TCM.MultipleSelect
                name={K.SUB_THEME.key}
                placeholder={K.SUB_THEME.placeholder}
                selected={appliedFilters.subtopics}
                options={subjectSubtopOptions}
                loading={subjectIsLoading}
                optionFilterProp="label"
              />
            </TCM.Label>
          </FormRow>
          <FormRow>
            <TCM.DateRange
              propsLabelFrom={{
                name: K.EXECUTION_DATE_FROM.key,
                label: K.EXECUTION_DATE_FROM.label,
                validateTrigger: 'onBlur',
              }}
              propsInputFrom={{
                name: K.EXECUTION_DATE_FROM.key,
              }}
              propsLabelTo={{
                name: K.EXECUTION_DATE_TO.key,
                label: K.EXECUTION_DATE_TO.label,
                validateTrigger: 'onBlur',
              }}
              propsInputTo={{
                name: K.EXECUTION_DATE_TO.key,
              }}
            />
            <TCM.InputRange
              propsLabelFrom={{
                name: K.COUNT_FROM.key,
                label: K.COUNT_FROM.label,
                validateTrigger: 'onBlur',
              }}
              propsInputFrom={{
                name: K.COUNT_FROM.key,
                min: K.COUNT_FROM.min,
              }}
              propsLabelTo={{
                name: K.COUNT_TO.key,
                label: K.COUNT_TO.label,
                validateTrigger: 'onBlur',
              }}
              propsInputTo={{
                name: K.COUNT_TO.key,
                min: K.COUNT_TO.min,
              }}
            />
          </FormRow>
          <FormRow>
            <TCM.DateRange
              propsLabelFrom={{
                name: K.DATE_FROM.key,
                label: K.DATE_FROM.label,
                validateTrigger: 'onBlur',
              }}
              propsInputFrom={{
                name: K.DATE_FROM.key,
              }}
              propsLabelTo={{
                name: K.DATE_TO.key,
                label: K.DATE_TO.label,
                validateTrigger: 'onBlur',
              }}
              propsInputTo={{
                name: K.DATE_TO.key,
              }}
            />
          </FormRow>
          <FormFooter>
            <ButtonGroup>
              <TCM.Button htmlType="submit">Показать кампании</TCM.Button>
              <TCM.ButtonOutline htmlType="reset">Сбросить фильтр</TCM.ButtonOutline>
            </ButtonGroup>
          </FormFooter>
        </Form>
      </Wrapper>
    </Modal>
  );
};

const ConnectedWithReduxCampaignsFilter = connect(
  (state) => ({
    appliedFilters: state.tcmCampaign.collection.filters.data,
    subjectTopics: state.tcmTopics.subjects.topics,
    subjectSubtopics: state.tcmTopics.subjects.subtopics,
    subjectIsLoading: state.tcmTopics.subjects.isLoading,
    subjectIsLoaded: state.tcmTopics.subjects.isLoaded,
    subjectError: state.tcmTopics.subjects.error,
  }),
  {
    setFilters: setFiltersAction,
    fetchSubjectList: fetchSubjectListAction,
  },
)(CampaignsFilter);

export { ConnectedWithReduxCampaignsFilter as CampaignsFilter };
