import styled from 'styled-components';
import { palette, size } from 'styled-theme';

export const Header = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr ${size('filterInputHeight')};
  grid-template:
    'title'
    'input';
`;

export const HeaderTitle = styled.div`
  grid-area: title;
  display: flex;
  justify-content: space-between;
`;

export const HeaderSorting = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0;
  width: 21px;
  height: 21px;

  & .anticon {
    font-size: 0.7rem;
  }

  & svg {
    fill: ${palette('primary', 3)};
  }

  &[data-is-active='true'] {
    &[data-sort-type='asc'] {
      .anticon-caret-up {
        & svg {
          fill: ${palette('primary', 0)};
        }
      }
    }

    &[data-sort-type='desc'] {
      .anticon-caret-down {
        & svg {
          fill: ${palette('primary', 0)};
        }
      }
    }
  }
`;

export const HeaderFilter = styled.div.attrs({ className: 'table-header__filter' })`
  grid-area: input;
  min-height: ${size('filterInputHeight')};
  overflow: hidden;
`;
