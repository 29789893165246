export const USER_STATUS__TRY_TO_RESET = 'USER_STATUS__TRY_TO_RESET';
export const USER_STATUS__RESET = 'USER_STATUS__RESET';

export const USER_STATUS__PATCH = 'USER_STATUS__PATCH';
export const USER_STATUS__PATCH_SUCCESS = 'USER_STATUS__PATCH_SUCCESS';
export const USER_STATUS__PATCH_FAILURE = 'USER_STATUS__PATCH_FAILURE';
export const patch = ({ accessToken, status }) => ({
  type: USER_STATUS__PATCH,
  accessToken,
  status,
});
