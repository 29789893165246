import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import Button from 'components/Button';
import { useForm } from 'hooks';
import { Search } from 'components/Input';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';
import { defaultPageSize, initialFilters } from 'redux/transaction/reducer';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_WITH_TIME } from 'settings';
import moment from 'moment';

import {
  fetchList as fetchListAction,
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
} from 'redux/transaction/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

// import { checkPrivilege } from 'helpers/roles';

import {
  Root,
  Header,
  HeaderTitle,
  HeaderSearch,
  Title,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
  Footer,
  Status,
  Method,
} from './style';
import DownloadModal from '../DownloadModal';

const showTotal = () => '';
const scroll = {
  x: false,
  y: 'calc(100vh - 500px)',
};

const METHOD_NAME = {
  arm: 'Счет',
  api: 'Заказ онлайн',
};

const SOURCE = [
  { label: METHOD_NAME.api, value: 'api' },
  { label: METHOD_NAME.arm, value: 'arm' },
];

const STATUS = [
  { label: 'Ожидает оплаты', value: 'Ожидает оплаты' },
  { label: 'Зарезервирован', value: 'Зарезервирован' },
  { label: 'Подтвержден', value: 'Подтвержден' },
  { label: 'Отменен', value: 'Отменен' },
  { label: 'Возврат', value: 'Возврат' },
  { label: 'Частичный возврат', value: 'Частичный возврат' },
  { label: 'Ошибка', value: 'Ошибка' },
  { label: 'Просроченный', value: 'Просроченный' },
  { label: 'Аннулированный', value: 'Аннулированный' },
];

function List({
  // connect
  // role,
  list,
  sorting,
  search,
  isLoading,
  error,
  pagination,

  // actions
  setTablePage,
  setSorting,
  setFilters,
  setSearch,
  resetTable,
  setBreadcrumbs,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const openModal = () => setShowDownloadModal(true);
  const closeModal = () => setShowDownloadModal(false);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Эквайринг', link: '/app/payments' },
        { level: 1, name: 'Платежи', link: '/app/payments/transactions/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    setFilters({ filters: form });

    return () => resetTable();
  }, [form, resetTable, setFilters]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  function handleOnSearch(v) {
    setSearch({ search: v });
  }

  function handleOnBlur() {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  const columns = [
    {
      key: 'number',
      title: () =>
        TableHeader({
          title: 'Номер заказа',
          sortBy: 'number',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'number',
          filterValue: form.number,
          filterOnChange: updateForm,
        }),
      render: ({ paymentId, number }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>{number}</Link>
      ),
      width: 120,
    },
    {
      key: 'data',
      title: () =>
        TableHeader({
          title: 'Дата',
          sortBy: 'data',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.PERIOD,
          filterName: ['dataFrom', 'dataTo'],
          filterValue: [form.dataFrom, form.dataTo],
          filterOnChange: updateForm,
        }),
      render: ({ data, paymentId }) =>
        data
        && typeof data === 'string' && (
          <Link to={`/app/payments/transactions/${paymentId}`}>
            <span>{moment.utc(data).format(DATE_FORMAT_WITH_TIME)}</span>
          </Link>
        ),
      width: 240,
    },
    {
      key: 'registerSum',
      title: () =>
        TableHeader({
          title: 'Сумма',
          sortBy: 'registerSum',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.RANGE,
          filterName: ['registerSumFrom', 'registerSumTo'],
          filterValue: [form.registerSumFrom, form.registerSumTo],
          filterOnChange: updateForm,
          filterFormat: 'currency',
        }),
      render: ({ registerSum, paymentId }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <span>{registerSum}</span>
        </Link>
      ),
      width: 260,
    },
    {
      key: 'actualSum',
      title: () =>
        TableHeader({
          title: 'Сумма актуальная',
          sortBy: 'actualSum',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.RANGE,
          filterName: ['actualSumFrom', 'actualSumTo'],
          filterValue: [form.actualSumFrom, form.actualSumTo],
          filterOnChange: updateForm,
          filterFormat: 'currency',
        }),
      render: ({ actualSum, paymentId }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <span>{actualSum}</span>
        </Link>
      ),
      width: 260,
    },
    {
      key: 'name',
      title: () =>
        TableHeader({
          title: 'ФИО',
          sortBy: 'name',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'name',
          filterValue: form.name,
          filterOnChange: updateForm,
        }),
      render: ({ name, paymentId }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <span>{name}</span>
        </Link>
      ),
      width: 200,
    },
    {
      key: 'phone',
      title: () =>
        TableHeader({
          title: 'Телефон',
          sortBy: 'phone',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'phone',
          filterValue: form.phone,
          filterOnChange: updateForm,
        }),
      render: ({ phone, paymentId }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <span>{phone}</span>
        </Link>
      ),
      width: 200,
    },
    {
      key: 'email',
      title: () =>
        TableHeader({
          title: 'Email',
          sortBy: 'email',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'email',
          filterValue: form.email,
          filterOnChange: updateForm,
        }),
      render: ({ email, paymentId }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <span>{email}</span>
        </Link>
      ),
      width: 200,
    },
    {
      key: 'status',
      title: () =>
        TableHeader({
          title: 'Статус',
          sortBy: 'status',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'status',
          filterValue: form.status,
          filterOptions: STATUS,
          filterIsLoading: false,
          filterOnChange: updateForm,
        }),
      render: ({
        status, paymentId, updatedAt,
      }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <Status status={status}>
            {status}
            <br />
            {updatedAt
              && typeof updatedAt === 'string'
              && moment(updatedAt).format(DATE_FORMAT_WITH_TIME)}
          </Status>
        </Link>
      ),
      width: 200,
    },
    {
      key: 'source',
      title: () =>
        TableHeader({
          title: 'Способ',
          sortBy: 'source',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'source',
          filterValue: form.source,
          filterOptions: SOURCE,
          filterIsLoading: false,
          filterOnChange: updateForm,
        }),
      render: ({ source, paymentId }) => (
        <Link to={`/app/payments/transactions/${paymentId}`}>
          <Method method={source}>{METHOD_NAME[source]}</Method>
        </Link>
      ),
      width: 160,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const paginationObj = {
    current: page,
    pageSize: defaultPageSize,
    total: pagination ? pagination.rowsCount : 0,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>История заказов</Title>
          <Button
            size="mini"
            type="ghost"
            shape="square"
            onClick={openModal}
          >
            Выгрузить
          </Button>
        </HeaderTitle>
        <HeaderSearch>
          <Search
            allowClear
            placeholder="Поиск..."
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            onSearch={handleOnSearch}
            onBlur={handleOnBlur}
          />
        </HeaderSearch>
      </Header>

      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
      <Footer />

      <DownloadModal
        visible={showDownloadModal}
        close={closeModal}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    list: state.transaction.collection.list,
    sorting: state.transaction.collection.sorting,
    filters: state.transaction.collection.filters,
    search: state.transaction.collection.search,
    isLoaded: state.transaction.collection.isLoaded,
    isLoading: state.transaction.collection.isLoading,
    error: state.transaction.collection.error,
    pagination:
      (state.transaction.collection.data
        && state.transaction.collection.data.pagination)
      || {},
  }),
  {
    fetchList: fetchListAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
