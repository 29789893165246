export const FormItems = {
  cars: {
    key: 'cars',
    label: 'Нет автомобилей для реализации',
    rules: [],
  },
  conditions: {
    key: 'conditions',
    label: 'Условия кампании стали неактуальны',
    rules: [],
  },
  other: {
    key: 'other',
    label: 'Другая причина:',
    rules: [],
  },
  comment: {
    key: 'comment',
    apiKey: 'comment',
    placeholder: 'Введите текст',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Комментарий не может быть пустым');
        },
      }),
    ],
  },
};

export const DateRangePresets = {
  allTime: {
    value: 'allTime',
    label: 'Всё время',
  },
  year: {
    value: 'year',
    label: 'Год',
  },
  halfAYear: {
    value: 'halfAYear',
    label: 'Пол года',
  },
  quarter: {
    value: 'quarter',
    label: 'Квартал',
  },
  month: {
    value: 'month',
    label: 'месяц',
  },
};
