import React, { useState, useRef, forwardRef } from 'react';
import { Input as InputAntD } from 'antd';
import styled from 'styled-components';

const defaultProps = {
  allowClear: true,
};

const Input = styled(InputAntD.Search)``;

const Search = forwardRef((props, ref) => (
  <Input ref={ref} {...defaultProps} {...props} />
));

function InputFilter({ name, value: valueFromProps, onChange }) {
  const [value, setValue] = useState(valueFromProps || '');
  const inputEl = useRef(null);

  function handleOnSearch(v) {
    setValue(v);
    onChange({ [name]: v });
  }

  function handleOnPressEnter() {
    onChange({ [name]: value });
    inputEl.current.blur();
  }

  function handleOnChange(event) {
    setValue(event.target.value);
  }

  function handleOnBlur() {
    if (valueFromProps !== value) {
      onChange({ [name]: value });
    }
  }

  return (
    <Search
      ref={inputEl}
      name={name}
      value={value}
      onChange={handleOnChange}
      onSearch={handleOnSearch}
      onBlur={handleOnBlur}
      onPressEnter={handleOnPressEnter}
    />
  );
}

export default InputFilter;
