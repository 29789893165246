import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-modal-close-x{
    color: red;
    font-size: 22px;
  }
`;

const CommentsContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  max-height: 345px;
  overflow-y: auto;
`;

const CommentsTitle = styled.h4`
  font-size: 24px;
  margin-bottom: 0;
  font-family: 'Toyota Type', sans-serif;
`;

const CommentContainer = styled.div`
  padding: .4rem;
`;
const CommentNameDate = styled.span`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 15px 20px;
`;

const CommentText = styled.p`
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 560px;
  color: #000;
`;

export {
  CommentsContainer, CommentContainer, CommentNameDate, CommentText, CommentsTitle, GlobalStyle,
};
