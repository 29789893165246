import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const ActiveLinkClass = 'App_Nav_Active';

export const Root = styled.div`
  padding: 1rem 4rem 0;
  width: 100%;
  margin: 0 auto 25px;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 6px;
`;

export const SubTitle = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #7B7B7B;
  margin-bottom: 25px;
`;

export const Header = styled.header`
  max-width: 1200px;
  padding-top: 4rem;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const Nav = styled.nav`
  height: 2.4rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 47px;
  
    a:first-child {
      border-radius: 30px 0 0 30px;
    }
  
    a:last-child {
      border-radius: 0 30px 30px 0;
      border-right: 1px solid #7B7B7B;
    } 
  
 
`;

export const Link = styled(NavLink).attrs({
  activeClassName: ActiveLinkClass,
})`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  height: 60px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border: 1px solid #7B7B7B;
  border-right: none;
  transition: background 0.2s ease-in, opacity 0.2s ease-in, color 0.2s ease-in;
  &.${ActiveLinkClass} {
    background: #E71313;
    color: #FFFFFF;
  }
  &:hover {
    background: #E71313;
    color: #FFFFFF;
  }
`;
