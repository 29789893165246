import { getToken } from 'helpers/utility';
import { API_URL as API, TOYOTA_URL } from 'settings/api';
import { formatFilters } from 'hacks';
import { defaultPageSize, defaultSorting } from 'redux/dealerCenter/reducer';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
  withPagination = true,
  legalEntityIds = [],
}) => {
  const URI = `${API}/admin/subsidiaries/list`;

  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
    ...(legalEntityIds.length > 0 ? { legalEntityIds } : {}),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: withPagination ? defaultPageSize : 100000000,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchDealerItem = ({ id }) => {
  const URI = `${API}/admin/subsidiaries/get`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const create = ({
  salePoint,
  inn,
  krifId,
  acquiringID,
  onlineId,
  addressactual,
  email,
  phone,
  time,
}) => {
  const URI = `${API}/admin/subsidiaries/create`;

  const data = {
    salePoint,
    inn,
    krifId,
    acquiringID,
    onlineId,
    addressactual,
    email,
    phone,
    time,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const edit = ({
  id,
  salePoint,
  inn,
  krifId,
  acquiringID,
  onlineId,
  addressactual,
  email,
  phone,
  time,
  bindSector,
  unbindSector,
}) => {
  const URI = `${API}/admin/subsidiaries/edit`;

  const data = {
    id,
    salePoint,
    inn,
    krifId,
    acquiringID,
    onlineId,
    addressactual,
    email,
    phone,
    time,
    bindSector,
    unbindSector,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchDealers = () => {
  const URI = `${TOYOTA_URL}/dealer-centers/find`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  fetchDealerItem,
  create,
  edit,
  fetchDealers,
};
