import styled from 'styled-components';

const Root = styled.header`
  min-height: calc(1.4rem * 3 + 1.5rem);
  max-height: calc(1.4rem * 3 + 3rem);
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
`;

const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  padding: 1rem;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
`;

const Message = styled.span`
  padding-left: 0.6rem;
`;

const ErrorMessage = styled.span`
  padding-left: 0.6rem;
  color: red;
`;

export {
  Root, Content, Centered, Error, Message, ErrorMessage,
};
