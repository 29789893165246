import React from 'react';
import { connect } from 'react-redux';
import { TCM } from 'components/tcm';
import { Link } from 'react-router-dom';
import {
  setSearch as setSearchAction,
} from 'redux/tcmCampaign/actions';

import {
  CompanySearch, ContentHeaderWrapper, PageTitle,
} from './style';

const ContentHeader = ({
  // passed
  tableIsEmpty,

  // connect
  search,

  // actions
  setSearch,
}) => {
  function applySearch(value) {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  return (
    <ContentHeaderWrapper>
      <PageTitle>Кампании</PageTitle>
      {!tableIsEmpty && (
        <CompanySearch>
          <TCM.Search onSearch={applySearch} notEmpty={Boolean(search)} />
        </CompanySearch>
      )}
      <TCM.Button as={Link} to="/tcm/campaign/create">Создать кампанию</TCM.Button>
    </ContentHeaderWrapper>
  );
};

export default connect(
  (state) => ({
    search: state.tcmCampaign.collection.search,
  }),
  {
    setSearch: setSearchAction,
  },
)(ContentHeader);
