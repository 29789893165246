import { HISTORY__FETCH, HISTORY__FETCH_SUCCESS, HISTORY__FETCH_FAILURE } from './actions';

const initState = {
  id: null,
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export default function historyReducer(state = initState, action) {
  switch (action.type) {
    case HISTORY__FETCH: {
      return {
        id: action.accessToken,
        list: [],
        isLoading: true,
        isLoaded: false,
        error: null,
      };
    }
    case HISTORY__FETCH_SUCCESS: {
      return {
        ...state,
        list: action.list,
        isLoading: false,
        isLoaded: true,
        error: null,
      };
    }
    case HISTORY__FETCH_FAILURE: {
      return {
        ...state,
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
}
