import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import {
  getTcmReportList as getTcmReportListAction,
} from 'redux/tcmReport/actions';

import {
  ReportListContainer,
  ReportTitle,
  ReportContainer,
  Report,
  ReportError,
} from './style';

function ReportList({
  // passed
  show,
  getReportData,

  // store
  reportList,
  isLoading,
  isLoaded,
  error,

  reportTemplateIsLoaded,

  // actions
  getTcmReportList,
}) {
  useEffect(() => {
    getTcmReportList();
  }, [getTcmReportList]);

  useEffect(() => {
    if (reportTemplateIsLoaded) {
      getTcmReportList();
    }
  }, [reportTemplateIsLoaded, getTcmReportList]);

  if (isLoading) {
    return <LoadingOutlined />;
  }

  if (isLoaded && reportList.length > 0) {
    return (
      <ReportContainer show={show}>
        <ReportTitle>
          Шаблоны отчетов:
        </ReportTitle>
        <ReportListContainer>
          {reportList.map((item) => <Report onClick={() => getReportData(item)} key={item.id}>{item.name}</Report>)}
        </ReportListContainer>
      </ReportContainer>
    );
  }

  if (error) {
    return (
      <ReportError>{error}</ReportError>
    );
  }

  return null;
}

export default connect(
  (state) => ({
    reportList: state.tcmReport.reportList.list,
    isLoading: state.tcmReport.reportList.isLoading,
    isLoaded: state.tcmReport.reportList.isLoaded,
    error: state.tcmReport.reportList.error,

    reportTemplateIsLoaded: state.tcmReport.reportTemplate.isLoaded,
  }),
  {
    getTcmReportList: getTcmReportListAction,
  },
)(ReportList);
