import styled from 'styled-components';
import AutoComplete from 'components/AutoComplete';

export const AutoCompleteStyled = styled(AutoComplete)`
  &&.ant-select-auto-complete {
    font-size: 12px;
    width: 100%;

    & span[aria-live='polite'] {
      position: absolute;
    }

    & .ant-select-selector {
      &:after {
        display: none;
      }
    }

    &.ant-select-focused {
      & .ant-select-selector {
        box-shadow: none;
      }
    }
  }
`;
