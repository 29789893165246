import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { formatFilters } from 'hacks';
import { defaultSorting, defaultPageSize } from 'redux/transaction/reducer';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
}) => {
  const URI = `${API}/payment/search`;

  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: defaultPageSize,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchItem = ({ transactionId }) => {
  const URI = `${API}/payment/get`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(transactionId),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const confirm = ({ transactionId, amount }) => {
  const URI = `${API}/payment/approve`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(transactionId),
        amount: Number(amount),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const cancel = ({ transactionId }) => {
  const URI = `${API}/payment/cancel`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(transactionId),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const refund = ({ transactionId }) => {
  const URI = `${API}/payment/revers`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(transactionId),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const refundPartial = ({ transactionId, amount }) => {
  const URI = `${API}/payment/partial/revers`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(transactionId),
        amount: Number(amount),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const exportFile = ({
  filter,
  fileFormat,
  sorting,
}) => {
  const URI = `${API}/payment/export-for-download`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        filter: {
          ...formatFilters(filter),
          meta: {
            sort: {
              ...sorting,
            },
          },
        },
        fileFormat,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const sendFileToEmail = ({
  filter,
  fileFormat,
  recipients,
  sorting,
}) => {
  const URI = `${API}/payment/export-for-email`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        filter: {
          ...formatFilters(filter),
          meta: {
            sort: {
              ...sorting,
            },
          },
        },
        fileFormat,
        recipients,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  fetchItem,
  confirm,
  cancel,
  refund,
  refundPartial,
  exportFile,
  sendFileToEmail,
};
