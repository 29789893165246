export const formFields = {
  dateFrom: {
    key: 'dateFrom',
    apiKey: 'termFulfillmentFrom',
    label: 'Срок выполнения от',
  },

  dateTo: {
    key: 'dateTo',
    apiKey: 'termFulfillmentTo',
    label: 'Срок выполнения до',
  },

  campaign: {
    key: 'campaign',
    apiKey: 'campaignId',
    label: 'Кампания',
    placeholder: 'Выберите кампанию',
  },

  brand: {
    key: 'brand',
    apiKey: 'brands',
    label: 'Марка',
    placeholder: 'Выберите марку',
  },

  model: {
    key: 'model',
    apiKey: 'models',
    label: 'Модель',
    placeholder: 'Выберите модель',
  },

  vin: {
    key: 'vin',
    apiKey: 'vin',
    label: 'VIN',
    placeholder: 'Укажите VIN',
  },

  callsCount: {
    key: 'callsCount',
    label: 'Звонки',
  },

  visitsCount: {
    key: 'visitsCount',
    label: 'Визиты',
  },

  callStatus: {
    key: 'callStatus',
    apiKey: 'callStatus',
    label: 'Результат последнего звонка',
    placeholder: 'Выберите результат',
  },

  visitStatuses: {
    key: 'visitStatuses',
    apiKey: 'visitStatuses',
    label: 'Цель визита',
    placeholder: 'Выберите результаты',
  },
};
