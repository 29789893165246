import styled, { css } from 'styled-components';
import withTheme from '../withTheme';

export const Wrapper = styled.div`
  margin-bottom: 90px;
`;

export const Title = withTheme(styled.p`
  ${({ theme }) => css`
  margin-top: 18px;
  font-family: ${theme.toyotaFontFamily};
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -1.3px;
  color: ${theme.palette.black};
`}`);

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin-bottom: 28px;
  
  &:last-child {
    margin-bottom: 0;
  }  
`;

export const FormFooter = styled.div`
  margin-top: 80px;
`;
