import styled from 'styled-components';
import { Modal } from 'antd';

export const Root = styled(Modal)`
  & .ant-modal-content {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  & .ant-modal-header {
    padding: 2rem;
  }

  & .ant-modal-body {
    padding: 1rem 2rem 2rem;
  }

  & .ant-form-item {
    margin-bottom: 14px;
  }
`;

export const Title = styled.h1`
  font-family: ToyotaDisplay, sans-serif;
  font-size: 24px;
  line-height: 30px;
  color: #000;
  margin-left: -10px;
`;

export const ModalBody = styled.div`
  margin: 40px;
`;
