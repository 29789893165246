import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const send = ({ login }) => {
  const URI = `${API}/security/otp/send`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        login,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const sendByPhone = ({ phone }) => {
  const URI = `${API}/security/otp/send-by-phone`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        phone,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const check = ({ code, token }) => {
  const URI = `${API}/security/otp/check`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        code,
        token,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const restorePassword = ({ code, token }) => {
  const URI = `${API}/security/restore-password`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        code,
        token,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const resend = ({ token }) => {
  const URI = `${API}/security/otp/resend`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        token,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  send,
  check,
  resend,
  sendByPhone,
  restorePassword,
};
