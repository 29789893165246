import React, { useEffect, useState, useReducer } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import AutoComplete, { Option } from 'components/AutoComplete';
import { useDebounce } from 'hooks';
import { formatFias } from 'hacks';
import API from 'services';

const initialState = {
  search: null,
  isFocused: false,
  isFetching: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FOCUS: {
      return {
        ...state,
        isFocused: true,
      };
    }
    case ACTIONS.BLUR: {
      return {
        ...state,
        isFocused: false,
      };
    }
    case ACTIONS.FETCH: {
      return {
        ...state,
        isFetching: true,
        search: action.search,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function SearchField({
  name, onFocus, onBlur, onChangeCallback,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [passedValue, setPassedValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isCount, setIsCount] = useState(false);

  useEffect(() => {
    if (state.isFocused || isCount) {
      if (debouncedSearchTerm !== state.search) {
        dispatch({ type: ACTIONS.FETCH, search: debouncedSearchTerm });
        API.fias
          .fetchFIAS({
            alias: 'organization',
            value: debouncedSearchTerm,
            dependencies: { ...(isCount ? { count: 1 } : {}) },
          })
          .then((results) => {
            setIsCount(false);
            dispatch({ type: ACTIONS.FETCH_SUCCESS, list: formatFias(results, true) });
          })
          .catch((error) => {
            dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
          });
      }
    }
  }, [state.isFocused, state.search, debouncedSearchTerm, isCount]);

  function handleSelect(v) {
    setIsCount(true);
    onChangeCallback({
      value: v,
      data: state.list.find((e) => e.value === v),
    });
    setPassedValue(v);
  }

  function handleOnFocus() {
    dispatch({ type: ACTIONS.FOCUS });
    onFocus({ target: { name } });
  }

  function handleOnBlur() {
    if (searchTerm !== passedValue) {
      onChangeCallback({
        value: searchTerm,
        data: null,
      });
      setPassedValue(searchTerm);
    }
    dispatch({ type: ACTIONS.BLUR });
    onBlur({ target: { name } });
  }

  return (
    <AutoComplete
      name={name}
      placeholder="Введите ИНН или название компании..."
      onChange={setSearchTerm}
      onSelect={handleSelect}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      notFoundContent={
        state.error ? (
          <span>{state.error}</span>
        ) : state.isFetching ? (
          <LoadingOutlined />
        ) : searchTerm && searchTerm.length < 3 ? (
          <sup>[запрос должен содержать от 3-ех символов]</sup>
        ) : (
          <span>нет данных</span>
        )
      }
    >
      {state.list.map((option) => (
        <Option key={option.value}>{option.value}</Option>
      ))}
    </AutoComplete>
  );
}

export default SearchField;
