import { Radio } from 'antd';
import styled from 'styled-components';

const Row = styled.div`
  display: grid; 
  column-gap: 20px;
  margin-bottom: 28px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FirstRow = styled(Row)`
  grid-template-columns: 320px 440px;
`;

export const SecondRow = styled(Row)`
  grid-template-columns: 200px 200px 340px;
`;

export const ThirdRow = styled(Row)`
  grid-template-columns: 300px 460px;
  margin-bottom: 24px;
`;

export const RowItem = styled.div`
  display: grid;
  row-gap: 24px;
`;

export const RadioGroup = styled(Radio.Group)`
  display: grid;
  grid-template-columns: initial;
  row-gap: 20px;
  margin-bottom: 0;
`;

export const FormFooter = styled.div`
  margin-top: 40px;
`;
