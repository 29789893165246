import moment from 'moment';
import {
  CAMPAIGN_GET_REPORT,
  CAMPAIGN_GET_REPORT__FAILURE, CAMPAIGN_GET_REPORT__RESET,
  CAMPAIGN_GET_REPORT__SUCCESS,
} from 'redux/tcmCampaign/actions';
import {
  CAMPAIGN_FETCH_LIST,
  CAMPAIGN_FETCH_LIST__SUCCESS,
  CAMPAIGN_FETCH_LIST__FAILURE,
  CAMPAIGN_FETCH_LIST__SET_LOADING,
  CAMPAIGN_FETCH_LIST__SET_PAGINATION,
  CAMPAIGN_FETCH_LIST__SET_SEARCH,
  CAMPAIGN_FETCH_LIST__SET_SORTING,
  CAMPAIGN_FETCH_LIST__SET_FILTER,
  CAMPAIGN_FETCH_LIST__RESET,

  CAMPAIGN_CREATE,
  CAMPAIGN_CREATE__SUCCESS,
  CAMPAIGN_CREATE__FAILURE,
  CAMPAIGN_CREATE__RESET_ERROR,
  CAMPAIGN_CREATE__RESET_FORM,
  CAMPAIGN_FETCH,
  CAMPAIGN_FETCH__SUCCESS,
  CAMPAIGN_FETCH__FAILURE,
  CAMPAIGN_FETCH__RESET,

  CAMPAIGN_STATUS_SET,
  CAMPAIGN_STATUS_SET_SUCCESS,
  CAMPAIGN_STATUS_SET__FAILURE,
  CAMPAIGN_STATUS_SET__RESET,

  CAMPAIGN_FETCH_STATUS_LIST,
  CAMPAIGN_FETCH_STATUS_LIST_SUCCESS,
  CAMPAIGN_FETCH_STATUS_LIST__FAILURE,

  CAMPAIGN_FETCH_DIALING_DATES,
  CAMPAIGN_FETCH_DIALING_DATES__SUCCESS,
  CAMPAIGN_FETCH_DIALING_DATES__FAILURE,

  CAMPAIGN_REJECT,
  CAMPAIGN_REJECT__SUCCESS,
  CAMPAIGN_REJECT__FAILURE,
  CAMPAIGN_REJECT__RESET,

  CAMPAIGN_GET_DATE,
  CAMPAIGN_GET_DATE__SUCCESS,
  CAMPAIGN_GET_DATE__FAILURE,
  CAMPAIGN_GET_DATE__RESET,

} from './actions';

const getTagsFromFilter = (filter) => {
  const topics = filter.topics
    ? filter.topics.map((t) => ({
      label: t.label,
      key: 'topics',
    }))
    : [];

  const subtopics = filter.subtopics
    ? filter.subtopics.map((t) => ({
      label: t.label,
      key: 'subtopics',
    }))
    : [];

  const dialingFrom = filter.dialingFrom && {
    label: `Обзвон от ${moment(filter.dialingFrom).format('DD.MM.YYYY')}`,
    key: 'dialingFrom',
  };

  const dialingTo = filter.dialingTo && {
    label: `Обзвон до ${moment(filter.dialingTo).format('DD.MM.YYYY')}`,
    key: 'dialingTo',
  };

  const deadlineFrom = filter.deadlineFrom && {
    label: `Выполнить от ${moment(filter.deadlineFrom).format('DD.MM.YYYY')}`,
    key: 'deadlineFrom',
  };

  const deadlineTo = filter.deadlineTo && {
    label: `Выполнить до ${moment(filter.deadlineTo).format('DD.MM.YYYY')}`,
    key: 'deadlineTo',
  };

  const countCarsFrom = filter.countCarsFrom && {
    label: `От ${filter.countCarsFrom} автомобилей`,
    key: 'countCarsFrom',
  };

  const countCarsTo = filter.countCarsTo && {
    label: `До ${filter.countCarsTo} автомобилей`,
    key: 'countCarsTo',
  };

  return [
    ...topics,
    ...subtopics,
    dialingFrom,
    dialingTo,
    deadlineFrom,
    deadlineTo,
    countCarsFrom,
    countCarsTo,
  ].filter((tag) => (tag !== undefined && tag !== null));
};

/**
 * Fields which used in campaigns filter.
 * dialingFrom, dialingTo, deadlineFrom, deadlineTo values
 * have string type in ISO 8601 Extended format ('2019-11-14T00:00:00+03:00')
 *
 * @type {{
 * status: CAMPAIGN_STATUSES | undefined
 * dialingFrom: string | undefined,
 * dialingTo: string | undefined,
 * deadlineFrom: string | undefined,
 * deadlineTo: string | undefined,
 * topics: number[] | undefined,
 * subtopics: number[] | undefined,
 * countCarsFrom: number | undefined,
 * countCarsTo: number | undefined,
 * }}
 */
export const initialFilters = {
  status: null,
  topics: undefined,
  subtopics: undefined,
  dialingFrom: undefined,
  dialingTo: undefined,
  countCarsFrom: undefined,
  countCarsTo: undefined,
  deadlineFrom: undefined,
  deadlineTo: undefined,
};

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

const changeSorting = (currentValue, sortBy) => {
  if (currentValue.sortBy !== sortBy) {
    return {
      sortBy,
      order: ORDER.DESC,
    };
  } if (currentValue.order === ORDER.ASC) {
    return defaultSorting;
  }
  return {
    sortBy: currentValue.sortBy,
    order: ORDER.ASC,
  };
};

export const defaultPageSize = 25;

const initState = {
  collection: {
    data: {},
    list: [],
    pagination: {},
    sorting: defaultSorting,
    search: '',
    filters: {
      data: initialFilters,
      tags: [],
    },
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  dialingDates: {
    data: {
      dialingFrom: '',
      dialingTo: '',
    },
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  create: {
    data: {},
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  campaign: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  campaignStatuses: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  campaignsSet: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  statuses: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  campaignReject: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  calendar: {
    data: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  report: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function campaignsReducer(state = initState, action) {
  switch (action.type) {
    case CAMPAIGN_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case CAMPAIGN_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case CAMPAIGN_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case CAMPAIGN_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case CAMPAIGN_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: changeSorting(state.collection.sorting, action.sortBy),
        },
      };
    }

    case CAMPAIGN_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: {
            data: action.filters,
            tags: getTagsFromFilter(action.filters),
          },
        },
      };
    }

    case CAMPAIGN_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          pagination: action.pagination || {},
          page: (action.pagination && action.pagination.page) || 1,
          list: action.list,
          dealerCenterList: action.dealerCenterList,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          list: [],
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN_FETCH: {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: true,
          error: null,
        },
      };
    }
    case CAMPAIGN_FETCH__SUCCESS: {
      return {
        ...state,
        campaign: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CAMPAIGN_FETCH__FAILURE: {
      return {
        ...state,
        campaign: {
          ...state.campaign,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN_FETCH__RESET: {
      return {
        ...state,
        campaign: {
          ...initState.campaign,
        },
      };
    }

    case CAMPAIGN_CREATE: {
      return {
        ...state,

        create: {
          data: {},
          form: {
            status: action.status,
            subtopicId: action.subtopicId,
            countCarsInStock: action.countCarsInStock,
            dialingFrom: action.dialingFrom,
            dialingTo: action.dialingTo,
            comment: action.comment,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case CAMPAIGN_CREATE__SUCCESS: {
      return {
        ...state,

        create: {
          ...state.create,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_CREATE__FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }
    case CAMPAIGN_CREATE__RESET_ERROR: {
      return {
        ...state,

        create: {
          ...state.create,
          error: action.error,
        },
      };
    }
    case CAMPAIGN_CREATE__RESET_FORM: {
      return {
        ...state,

        create: {
          ...initState.create,
        },
      };
    }

    case CAMPAIGN_STATUS_SET: {
      return {
        ...state,

        campaignsSet: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_STATUS_SET_SUCCESS: {
      return {
        ...state,

        campaignsSet: {
          ...state.campaignsSet,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_STATUS_SET__FAILURE: {
      return {
        ...state,

        campaignsSet: {
          ...state.campaignsSet,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN_STATUS_SET__RESET: {
      return {
        ...state,

        campaignsSet: {
          ...initState.campaignsSet,
        },
      };
    }

    case CAMPAIGN_FETCH_STATUS_LIST: {
      return {
        ...state,

        statuses: {
          list: [],
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_FETCH_STATUS_LIST_SUCCESS: {
      return {
        ...state,

        statuses: {
          ...state.statuses,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_FETCH_STATUS_LIST__FAILURE: {
      return {
        ...state,

        statuses: {
          ...state.statuses,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN_FETCH_DIALING_DATES: {
      return {
        ...state,

        dialingDates: {
          ...state.dialingDates,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_FETCH_DIALING_DATES__SUCCESS: {
      return {
        ...state,

        dialingDates: {
          ...state.dialingDates,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_FETCH_DIALING_DATES__FAILURE: {
      return {
        ...state,

        dialingDates: {
          ...state.dialingDates,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN_REJECT: {
      return {
        ...state,

        campaignReject: {
          ...state.campaignReject,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_REJECT__SUCCESS: {
      return {
        ...state,

        campaignReject: {
          ...state.campaignReject,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_REJECT__FAILURE: {
      return {
        ...state,

        campaignReject: {
          ...state.campaignReject,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CAMPAIGN_REJECT__RESET: {
      return {
        ...state,
        campaignReject: {
          ...initState.campaignReject,
        },
      };
    }

    case CAMPAIGN_GET_DATE: {
      return {
        ...state,

        calendar: {
          ...state.calendar,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_GET_DATE__SUCCESS: {
      return {
        ...state,

        calendar: {
          ...state.calendar,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_GET_DATE__FAILURE: {
      return {
        ...state,

        calendar: {
          ...state.calendar,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CAMPAIGN_GET_DATE__RESET: {
      return {
        ...state,
        calendar: {
          ...initState.calendar,
        },
      };
    }

    case CAMPAIGN_GET_REPORT: {
      return {
        ...state,

        report: {
          ...state.report,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_GET_REPORT__SUCCESS: {
      return {
        ...state,

        report: {
          ...state.report,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CAMPAIGN_GET_REPORT__FAILURE: {
      return {
        ...state,

        report: {
          ...state.report,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CAMPAIGN_GET_REPORT__RESET: {
      return {
        ...state,
        report: {
          ...initState.report,
        },
      };
    }

    default: {
      return state;
    }
  }
}
