import styled from 'styled-components';

export const Root = styled.div`
  font-size: 14px;
  width: 244px;
  position: relative;
  top: -10px;
  
  h4 {
   margin: 0;
  }

  .ant-select{
   width: 100%;
  }
`;
