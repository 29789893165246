import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const fetchData = ({ accessToken }) => {
  const URI = `${API}/creditrequest/credit-data/fields`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,
      data: {},
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const patch = ({ form, accessToken }) => {
  const URI = `${API}/creditrequest/credit-data/fields`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,

      data: {
        ...form,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetch: fetchData,
  patch,
};
