import styled from 'styled-components';
import withTheme from '../../../components/tcm/withTheme';

export const ContentHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const CompanySearch = styled.div`
  margin-left: 48px;
  margin-right: 34px;
  width: 100%;
`;

export const PageTitle = styled.h1`
  margin-bottom: 0;
  font-family: 'Toyota Type', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 45px;
  letter-spacing: -1.3px;
  color: #000000;
`;

export const ModalTitle = styled.h2`
  margin-bottom: 0;
  font-family: 'Toyota Type', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -1.3px;
  color: #000000;
`;

export const ModalDescription = styled.p`
  margin-top: 24px;
  margin-bottom: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  width: 100%;
  color: #000;
  
`;

export const BackButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 12px max-content;
  column-gap: 12px;
  align-items: center;
  width: max-content;
  background-color: transparent;
`;

export const Suffix = styled.span`
  color: #A8AAAC;
  font-size: 18px;
`;

export const TableSortingTitle = withTheme(styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, isActive }) => (
    isActive
      ? theme.palette.black
      : theme.palette.gray3)};
  
  p {
    margin-bottom: 0;
    color: inherit;
  }
`);

export const PageContainer = styled.div`
  margin-bottom: 60px;
`;
