import styled from 'styled-components';
import { GRID_AREAS } from 'settings';

const Root = styled.div`
  display: flex;
  align-items: center;

  ${({ area }) =>
    typeof area === 'boolean'
    && `
    grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
  `}
`;

const LinkStyled = styled.a`
  margin-top: 1rem;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export { Root, LinkStyled };
