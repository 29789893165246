import {
  PAYMENT_FETCH_LIST,
  PAYMENT_FETCH_LIST__SUCCESS,
  PAYMENT_FETCH_LIST__FAILURE,
  PAYMENT_FETCH_LIST__SET_LOADING,
  PAYMENT_FETCH_LIST__SET_PAGINATION,
  PAYMENT_FETCH_LIST__SET_SEARCH,
  PAYMENT_FETCH_LIST__SET_SORTING,
  PAYMENT_FETCH_LIST__SET_FILTER,
  PAYMENT_FETCH_LIST__RESET,
  PAYMENT_FETCH_ITEM,
  PAYMENT_FETCH_ITEM__SUCCESS,
  PAYMENT_FETCH_ITEM__FAILIRE,
  PAYMENT_CREATE,
  PAYMENT_CREATE__SUCCESS,
  PAYMENT_CREATE__FAILIRE,
  PAYMENT_CREATE__RESET,
  PAYMENT_ANNUL,
  PAYMENT_ANNUL__SUCCESS,
  PAYMENT_ANNUL__FAILIRE,
  PAYMENT_SECTOR_FETCH_LIST,
  PAYMENT_SECTOR_FETCH_LIST__SUCCESS,
  PAYMENT_SECTOR_FETCH_LIST__FAILURE,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {
  number: '',
  dataFrom: '',
  dataTo: '',
  registerSumFrom: '',
  registerSumTo: '',
  actualSumFrom: '',
  actualSumTo: '',
  name: '',
  status: '',
  requisites: '',
};

const initState = {
  collection: {
    data: {},
    list: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    pagination: {},
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  item: {
    id: null,
    data: {},
    operations: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  create: {
    form: {},
    paymentId: null,
    formUrl: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  annul: {
    id: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  sectors: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function paymentReducer(state = initState, action) {
  switch (action.type) {
    case PAYMENT_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case PAYMENT_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case PAYMENT_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case PAYMENT_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case PAYMENT_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case PAYMENT_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case PAYMENT_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case PAYMENT_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          pagination: action.pagination || {},
          page: (action.pagination && action.pagination.page) || 1,
          data: action.data,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case PAYMENT_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case PAYMENT_FETCH_ITEM: {
      return {
        ...state,

        item: {
          ...state.item,
          id: action.id,
          data: {},
          operations: [],
          isLoading: true,
          isLoaded: false,
          error: null,
        },

        create: {
          ...initState.create,
        },

        annul: {
          ...initState.annul,
        },
      };
    }
    case PAYMENT_FETCH_ITEM__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,
          data: action.data,
          operations: Array.isArray(action.operations) ? action.operations : [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case PAYMENT_FETCH_ITEM__FAILIRE: {
      return {
        ...state,

        item: {
          ...state.item,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case PAYMENT_CREATE: {
      return {
        ...state,

        create: {
          ...state.create,
          form: {
            additionalInformation: action.additionalInformation,
            amount: action.amount,
            number: action.number,
            channel: action.channel,
            dealerId: action.dealerId,
            description: action.description,
            email: action.email,
            expiredAt: action.expiredAt,
            fullName: action.fullName,
            phone: action.phone,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case PAYMENT_CREATE__SUCCESS: {
      return {
        ...state,

        create: {
          ...state.create,
          paymentId: action.paymentId,
          formUrl: action.formUrl,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case PAYMENT_CREATE__FAILIRE: {
      return {
        ...state,

        create: {
          ...state.create,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case PAYMENT_CREATE__RESET: {
      return {
        ...state,

        create: {
          ...initState.create,
        },
      };
    }

    case PAYMENT_ANNUL: {
      return {
        ...state,

        annul: {
          id: action.id,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case PAYMENT_ANNUL__SUCCESS: {
      return {
        ...state,

        annul: {
          ...state.annul,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case PAYMENT_ANNUL__FAILIRE: {
      return {
        ...state,

        annul: {
          ...state.annul,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case PAYMENT_SECTOR_FETCH_LIST: {
      return {
        ...state,

        sectors: {
          ...state.sectors,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case PAYMENT_SECTOR_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        sectors: {
          ...state.sectors,

          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case PAYMENT_SECTOR_FETCH_LIST__FAILURE: {
      return {
        ...state,

        sectors: {
          ...state.sectors,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
