import React from 'react';

import { Form, Input } from 'antd';

import { checkPrivilege } from 'helpers/roles';

import Select from 'components/Select';
import {
  KEYS as K,
  WRITE_OF_OPTIONS,
  WRITE_OF_OPTIONS_API,
  SETTINGS_DATE_OF_BILL_OPTIONS,
  BOOKER_TYPES_OPTIONS,
} from '../data';

import {
  FormItemsContainer,
  FormItemsRow,
} from '../style';

function SettingsItems({
    // passed
  handleFocus,
  handleBlur,
  isEdit,
  isNew,
  role,
}) {
  const canEdit = checkPrivilege([
    role.ACQUIRING_AGREEMENT.EDIT,
  ]);

  const isReadOnly = canEdit && (isEdit || isNew);
  return (
    <>
      <FormItemsContainer>
        <FormItemsRow>
          <Form.Item
            name={K.SETTINGS_WRITE_OFF_SITE.key}
            label={K.SETTINGS_WRITE_OFF_SITE.title}
            rules={K.SETTINGS_WRITE_OFF_SITE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Select
              name={K.SETTINGS_WRITE_OFF_SITE.key}
              onFocus={(e) => handleFocus(e, K.SETTINGS_WRITE_OFF_SITE.key)}
              onBlur={(e) => handleBlur(e, K.SETTINGS_WRITE_OFF_SITE.key)}
              filterOption={false}
              list={WRITE_OF_OPTIONS_API}
              hasDefault={false}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.SETTINGS_WRITE_OFF_BILL.key}
            label={K.SETTINGS_WRITE_OFF_BILL.title}
            rules={K.SETTINGS_WRITE_OFF_BILL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Select
              name={K.SETTINGS_WRITE_OFF_BILL.key}
              onFocus={(e) => handleFocus(e, K.SETTINGS_WRITE_OFF_BILL.key)}
              onBlur={(e) => handleBlur(e, K.SETTINGS_WRITE_OFF_BILL.key)}
              filterOption={false}
              list={WRITE_OF_OPTIONS}
              hasDefault={false}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.SETTINGS_URL_SITE.key}
            label={K.SETTINGS_URL_SITE.title}
            rules={K.SETTINGS_URL_SITE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.SETTINGS_URL_SITE.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.SETTINGS_DATE_OF_BILL.key}
            label={K.SETTINGS_DATE_OF_BILL.title}
            rules={K.SETTINGS_DATE_OF_BILL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Select
              name={K.SETTINGS_DATE_OF_BILL.key}
              onFocus={(e) => handleFocus(e, K.SETTINGS_DATE_OF_BILL.key)}
              onBlur={(e) => handleBlur(e, K.SETTINGS_DATE_OF_BILL.key)}
              filterOption={false}
              list={SETTINGS_DATE_OF_BILL_OPTIONS}
              hasDefault={false}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.SETTINGS_BOOKER_URL.key}
            label={K.SETTINGS_BOOKER_URL.title}
            rules={K.SETTINGS_BOOKER_URL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.SETTINGS_BOOKER_URL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.SETTINGS_BOOKER_TYPES.key}
            label={K.SETTINGS_BOOKER_TYPES.title}
            rules={K.SETTINGS_BOOKER_TYPES.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Select
              name={K.SETTINGS_BOOKER_TYPES.key}
              onFocus={(e) => handleFocus(e, K.SETTINGS_BOOKER_TYPES.key)}
              onBlur={(e) => handleBlur(e, K.SETTINGS_BOOKER_TYPES.key)}
              filterOption={false}
              list={BOOKER_TYPES_OPTIONS}
              hasDefault={false}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
    </>
  );
}

export default SettingsItems;
