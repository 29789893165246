import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { RestrictedRoute } from 'router';
import moment from 'moment';
import { getLocalStorageToken } from 'helpers/storage';

import Modal from 'components/Modal';

import {
  Link, Route, Redirect, Switch,
} from 'react-router-dom';

import { logout as logoutAction } from 'redux/auth/actions';
import { checkPrivilege } from 'helpers/roles';
import { DATE_FORMAT_WITH_TIME } from 'settings';

import Leads from './Leads';
import Management from './Management';
import Payments from './Payments';
import TCM from './TCM';
import Settings from './Settings';

import {
  Root,
  Header,
  SecontaryHeader,
  Title,
  Nav,
  LinkStyled,
  LinkName,
  ProfileContainer,
  ProfileInfo,
  ProfileName,
  ProfileRole,
  Logo,
  Logout,
} from './style';

const Roles = ({ roles = '' }) => {
  const allRoles = (roles && roles.split(',')) || [];
  const popoverText = allRoles.length > 1 ? roles : '';
  return (
    <Tooltip title={popoverText}>
      <ProfileRole>
        {allRoles[0]}
        {popoverText && '...'}
      </ProfileRole>
    </Tooltip>
  );
};

function App({
  // connect
  role,
  expiredAt,
  isNeedChangedSoon,

  // actions
  logout,
}) {
  const [showExpiredPasswordModal, setShowExpiredPasswordModal] = useState(false);

  const displayName = getLocalStorageToken('DISPLAY_NAME');
  const displayRole = getLocalStorageToken('ROLE_NAME');

  const managementRights = [
    role.MANAGEMENT.VIEW_USER,
    role.MANAGEMENT.VIEW_USER_INTERNAL,
    role.ORGANISATIONS.VIEW__ALL,
    role.ORGANISATIONS.VIEW_POINTS_OF_SALES,
  ];
  const checkManagementRightsRights = (role) => checkPrivilege([
    role.MANAGEMENT.VIEW_USER,
    role.MANAGEMENT.VIEW_USER_INTERNAL,
    role.ORGANISATIONS.VIEW__ALL,
    role.ORGANISATIONS.VIEW_POINTS_OF_SALES,
  ]);

  const leadsRights = [
    role.LEADS.NAVIGATION,
    role.LEADS.EDIT,
    role.LEADS.READ,
  ];

  const paymentsRights = [
    role.PAYMENTS.VIEW,
    role.PAYMENTS.VIEW_ORGANISATION_BILLS,
    role.PAYMENTS.VIEW_MY_BILLS,
    role.ACQUIRING_AGREEMENT.VIEW,
  ];
  const checkPaymentsRights = (role) => checkPrivilege([
    role.PAYMENTS.VIEW,
    role.PAYMENTS.VIEW_ORGANISATION_BILLS,
    role.PAYMENTS.VIEW_MY_BILLS,
    role.ACQUIRING_AGREEMENT.VIEW,
  ]);

  const TCMRights = [
    role.TCM.VIEW_CAMPAIGN,
    role.TCM.VIEW_OFFER,
  ];
  const checkTCMRights = (role) => checkPrivilege([
    role.TCM.VIEW_CAMPAIGN,
    role.TCM.VIEW_OFFER,
  ]);

  const checkSettingsRights = (role) => checkPrivilege(role.SETTINGS.NAVIGATION);

  useEffect(() => {
    if (isNeedChangedSoon) {
      setShowExpiredPasswordModal(true);
    }
  }, [isNeedChangedSoon]);

  return (
    <Root>
      <SecontaryHeader>
        <Logout to="/" onClick={logout}>
          Выйти
        </Logout>
      </SecontaryHeader>
      <Header>
        <Title>
          <Link to="/">
            <Logo />
          </Link>
        </Title>
        <Nav>
          {checkPrivilege(managementRights) && (
            <LinkStyled to="/app/management">
              <LinkName>управление</LinkName>
            </LinkStyled>
          )}
          {checkPrivilege(paymentsRights) && (
            <LinkStyled to="/app/payments">
              <LinkName>эквайринг</LinkName>
            </LinkStyled>
          )}
          {checkPrivilege(leadsRights) && (
            <>
              <LinkStyled to="/app/leads">
                <LinkName>онлайн-заявка</LinkName>
              </LinkStyled>
            </>
          )}
          {checkPrivilege(TCMRights) && (
            <LinkStyled to="/tcm">
              <LinkName>ТСМ</LinkName>
            </LinkStyled>
          )}
          {checkPrivilege(role.SETTINGS.NAVIGATION) && (
            <LinkStyled to="/app/settings">
              <LinkName>настройки</LinkName>
            </LinkStyled>
          )}
        </Nav>

        <ProfileContainer>
          <ProfileInfo>
            <ProfileName>{displayName}</ProfileName>
            <Roles roles={displayRole} />
          </ProfileInfo>
        </ProfileContainer>
      </Header>

      {/* <Modals /> */}

      <Modal
        title="Внимание"
        visible={showExpiredPasswordModal}
        onCancel={() => setShowExpiredPasswordModal(false)}
      >
        Срок действия вашего пароля истекает, пожалуйста смените его до
        {' '}
        {expiredAt && (
          moment(expiredAt).format(DATE_FORMAT_WITH_TIME)
        )}
      </Modal>

      <Switch>
        <Route exact path="/app">
          <Redirect to="/app/leads" />
        </Route>
        <Route path="/app/leads" component={Leads} />
        <RestrictedRoute path="/app/management" component={Management} checkRights={checkManagementRightsRights} />
        <RestrictedRoute path="/app/payments" component={Payments} checkRights={checkPaymentsRights} />
        <RestrictedRoute path="/tcm" component={TCM} checkRights={checkTCMRights} />
        <RestrictedRoute path="/app/settings" component={Settings} checkRights={checkSettingsRights} />
      </Switch>

    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    isTCMUser: state.auth.isTCMUser,
    expiredAt: state.auth.expiredAt,
    isNeedChangedSoon: state.auth.isNeedChangedSoon,
  }),
  {
    logout: logoutAction,
  },
)(App);
