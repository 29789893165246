import styled from 'styled-components';
import { Checkbox } from 'antd';

const Root = styled.div`
  position: relative;
  transition: background 0.2s ease-in;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const CheckboxStyled = styled(Checkbox)`
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 8px;
  padding: 8px 8px 40px 8px;
  font-size: 14px;
  line-height: 1;

  & .ant-checkbox {
    align-self: center;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0.7rem;
  padding: 0 8px 0 40px;
  line-height: 1;
`;

export { Root, CheckboxStyled, ErrorMessage };
