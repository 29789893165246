import React from 'react';
import { Input as InputAntD } from 'antd';

import {
  InputStyled, SearchStyled, PasswordStyled, TextAreaStyled,
} from './style';

const defaultProps = {};

export const Input = React.forwardRef((props, ref) => (
  <InputStyled {...defaultProps} {...props} ref={ref} />
));
export const Search = React.forwardRef((props, ref) => (
  <SearchStyled {...defaultProps} {...props} ref={ref} />
));
export const Password = React.forwardRef((props, ref) => (
  <PasswordStyled {...defaultProps} {...props} ref={ref} />
));
export const TextArea = React.forwardRef((props, ref) => (
  <TextAreaStyled {...defaultProps} {...props} ref={ref} />
));
export const { Group } = InputAntD;
export default Input;
