import { parseDigitsWithSeparator as parseDigits } from 'utils';

const COUNTRY_CODE = '+7';

const SEPARATORS = {
  0: '(',
  3: ') ',
  6: '-',
  8: '-',
};

const preformatedPhone = (string) => (
  typeof string === 'string' ? string.replace(/^\+7/, '') : ''
);

const format = (string) => {
  const digits = parseDigits(typeof string === 'string' ? string.replace(/^\+7/, '') : string);
  return Array.from(digits)
    .reduce((p, c, i) => `${p}${SEPARATORS[i] || ''}${c}`, '')
    .substring(0, 15);
};

const replace = (string) => {
  const digits = parseDigits(string);
  const [area, prefix, linear1, linear2] = [
    digits.slice(0, 3).padEnd(3, '_'),
    digits.slice(3, 6).padEnd(3, '_'),
    digits.slice(6, 8).padEnd(2, '_'),
    digits.slice(8, 10).padEnd(2, '_'),
  ];

  return `${SEPARATORS[0]}${area}${SEPARATORS[3]}${prefix}${SEPARATORS[6]}${linear1}${SEPARATORS[8]}${linear2}`;
};

const validateIfNotEmpty = () => ({
  validator(rule, value) {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject('Номер телефона не может быть пустым');
  },
});

const validateIfNotFilled = () => ({
  validator(rule, value) {
    const digits = parseDigits(value);
    if (
      (
        typeof value === 'string' && (
          digits.length === 0
          || digits.length === 10
        )
      ) || (
        typeof value !== 'string'
      )
    ) {
      return Promise.resolve();
    }
    return Promise.reject('Номер телефона должен содержать 10 цифр');
  },
});

export default {
  preformatedPhone,
  prefix: COUNTRY_CODE,
  format,
  replace,
  validators: {
    validateIfNotEmpty,
    validateIfNotFilled,
  },
};
