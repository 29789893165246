import {
  all, takeLatest, put, fork, call, select, cancel, take,
} from 'redux-saga/effects';

import { extractErrorAndCode, formatWithComments } from 'hacks';

import API from 'services';

import { ON_ERROR } from 'redux/auth/actions';

import actions from './actions';

const getTableFromState = (state) => state.table;

function* _fetchData() {
  const {
    search, page, filters, sorting,
  } = yield select(getTableFromState);

  try {
    yield put({ type: actions.TABLE_SET_LOADING });
    const response = yield call(API.table.fetchData, {
      search,
      page,
      filters,
      sorting,
    });

    if (
      (Array.isArray(response.data) && response.data.length === 0)
      || response.data.creditRequestWithCommentList
    ) {
      const listWithComments = formatWithComments(response.data);

      yield put({
        type: actions.TABLE_FETCH_DATA__SUCCESS,
        data: { ...response.data, pagination: response.pagination },
        list: listWithComments,
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: actions.TABLE_FETCH_DATA__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchData);
  yield take(actions.TABLE_CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchData() {
  yield takeLatest(actions.TABLE_FETCH_DATA, function* () {
    yield call(fetchSync);
  });
}

export function* fetchDataOnSearch() {
  yield takeLatest(actions.TABLE_SET_SEARCH, function* () {
    yield put({ type: actions.TABLE_SET_PAGINATION, page: 1 });
    yield put({ type: actions.TABLE_CANCEL_REQUEST });
    yield put({ type: actions.TABLE_FETCH_DATA });
  });
}

export function* fetchDataOnPageChange() {
  yield takeLatest(actions.TABLE_SET_PAGE, function* (action) {
    yield put({ type: actions.TABLE_SET_PAGINATION, page: action.page });
    yield put({ type: actions.TABLE_CANCEL_REQUEST });
    yield put({ type: actions.TABLE_FETCH_DATA });
  });
}

export function* fetchDataOnSorting() {
  yield takeLatest(actions.TABLE_SET_SORTING, function* () {
    yield put({ type: actions.TABLE_CANCEL_REQUEST });
    yield put({ type: actions.TABLE_FETCH_DATA });
  });
}

export function* fetchDataOnFilters() {
  yield takeLatest(actions.TABLE_SET_FILTER, function* () {
    yield put({ type: actions.TABLE_SET_PAGINATION, page: 1 });
    yield put({ type: actions.TABLE_CANCEL_REQUEST });
    yield put({ type: actions.TABLE_FETCH_DATA });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchData),
    fork(fetchDataOnSearch),
    fork(fetchDataOnPageChange),
    fork(fetchDataOnSorting),
    fork(fetchDataOnFilters),
  ]);
}
