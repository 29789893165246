import { v1 as getId } from 'uuid';
import { getToken } from 'helpers/utility';
import { API_URL as API } from '../../settings/api';

export const findCreditPrograms = ({
  dealerCenter,
  brand,
  model,
}) => {
  const URI = `${API}/creditrequest/product/find`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        subsidiaries: {
          krifOrganizationName: dealerCenter,
        },
        brand,
        model,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};
