export const REQUEST__FETCH = 'REQUEST__FETCH';
export const REQUEST__FETCH_SUCCESS = 'REQUEST__FETCH_SUCCESS';
export const REQUEST__FETCH_FAILURE = 'REQUEST__FETCH_FAILURE';
export const fetch = ({ accessToken }) => ({ type: REQUEST__FETCH, accessToken });

export const REQUEST__PATCH = 'REQUEST__PATCH';
export const REQUEST__PATCH_PENDING_UPDATE = 'REQUEST__PATCH_PENDING_UPDATE';
export const REQUEST__PATCH_PENDING_RESET = 'REQUEST__PATCH_PENDING_RESET';
export const REQUEST__PATCH_LOADING_ENABLE = 'REQUEST__PATCH_LOADING_ENABLE';
export const REQUEST__PATCH_LOADING_DISABLE = 'REQUEST__PATCH_LOADING_DISABLE';
export const REQUEST__PATCH_SUCCESS = 'REQUEST__PATCH_SUCCESS';
export const REQUEST__PATCH_FAILURE = 'REQUEST__PATCH_FAILURE';
export const patch = ({ userInput, exclude }) => ({
  type: REQUEST__PATCH,
  userInput,
  exclude,
});

export const REQUEST__SAVE = 'REQUEST__SAVE';
export const REQUEST__SAVE_SUCCESS = 'REQUEST__SAVE_SUCCESS';
export const REQUEST__SAVE_FAILURE = 'REQUEST__SAVE_FAILURE';
export const REQUEST__SAVE_RESET = 'REQUEST__SAVE_RESET';
export const save = () => ({ type: REQUEST__SAVE });
export const saveReset = () => ({ type: REQUEST__SAVE_RESET });

export const REQUEST__FETCH_FILE = 'REQUEST__FETCH_FILE';
export const REQUEST__FETCH_FILE_SUCCESS = 'REQUEST__FETCH_FILE_SUCCESS';
export const REQUEST__FETCH_FILE_FAILURE = 'REQUEST__FETCH_FILE_FAILURE';
export const fetchFile = ({ name }) => ({ type: REQUEST__FETCH_FILE, name });
export const REQUEST__REMOVE_PREVIEW = 'REQUEST__REMOVE_PREVIEW';
export const removePreview = ({ name }) => ({ type: REQUEST__REMOVE_PREVIEW, name });
export const REQUEST__UPLOAD_FILES = 'REQUEST__UPLOAD_FILES';

// we use it to autoscroll to the tab/block/input after uploading the file
export const REQUEST__UPLOAD_FILES_SET_LAST_FILE = 'REQUEST__UPLOAD_FILES_SET_LAST_FILE';
export const uploadFiles = ({ fileList, documentType }) => ({
  type: REQUEST__UPLOAD_FILES,
  fileList,
  documentType,
});
export const REQUEST__UPLOAD_FILE = 'REQUEST__UPLOAD_FILE';
export const REQUEST__UPLOAD_FILE_UPLOADED = 'REQUEST__UPLOAD_FILE_UPLOADED';
export const REQUEST__UPLOAD_FILE_RECOGNIZED = 'REQUEST__UPLOAD_FILE_RECOGNIZED';
export const REQUEST__UPLOAD_FILE_CONFIRMED = 'REQUEST__UPLOAD_FILE_CONFIRMED';
export const REQUEST__UPLOAD_FILE_ERROR = 'REQUEST__UPLOAD_FILE_ERROR';

export const REQUEST__DELETE_FILE = 'REQUEST__DELETE_FILE';
export const REQUEST__DELETE_FILE_SUCCESS = 'REQUEST__DELETE_FILE_SUCCESS';
export const REQUEST__DELETE_FILE_FAILURE = 'REQUEST__DELETE_FILE_FAILURE';
export const deleteFile = ({ fieldName }) => ({ type: REQUEST__DELETE_FILE, fieldName });

export const REQUEST_GET_ANSWER_DETAILS = 'REQUEST_GET_ANSWER_DETAILS';
export const REQUEST_GET_ANSWER_DETAILS__SUCCESS = 'REQUEST_GET_ANSWER_DETAILS__SUCCESS';
export const REQUEST_GET_ANSWER_DETAILS__FAILURE = 'REQUEST_GET_ANSWER_DETAILS__FAILURE';
export const REQUEST_GET_ANSWER_DETAILS__RESET = 'REQUEST_GET_ANSWER_DETAILS__RESET';
export const getDetails = ({ accessToken }) => ({ type: REQUEST_GET_ANSWER_DETAILS, accessToken });
export const getDetailsReset = () => ({ type: REQUEST_GET_ANSWER_DETAILS__RESET });

export const REQUEST_REVISION__FETCH = 'REQUEST_REVISION__FETCH';
export const REQUEST_REVISION__FETCH_SUCCESS = 'REQUEST_REVISION__FETCH_SUCCESS';
export const REQUEST_REVISION__FETCH_FAILURE = 'REQUEST_REVISION__FETCH_FAILURE';
export const REQUEST_REVISION__FETCH_RESET = 'REQUEST_REVISION__FETCH_RESET';
export const revision = ({ message, accessToken }) => ({ type: REQUEST_REVISION__FETCH, accessToken, message });
export const revisionReset = () => ({ type: REQUEST_REVISION__FETCH_RESET });
