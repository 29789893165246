import React from 'react';
import { Form } from 'antd';
import { KEYS } from 'containers/App/TCM/ClientCard/CallForm/data';
import { RadioGroup } from 'containers/App/TCM/ClientCard/CallForm/style';
import { NegativeRecallSubField } from 'containers/App/TCM/ClientCard/CallForm/NegativeRecallSubField';
import { TCM } from 'components/tcm';

export const NegativeResults = ({ result, setResult }) => (
  <Form.Item
    name={KEYS.STATUS.key}
    rules={KEYS.STATUS.rules}
    validateTrigger={['onChange', 'onBlur']}
  >
    <RadioGroup value={result} onChange={(e) => setResult(e.target.value)}>
      <div>
        <TCM.Radio value={KEYS.STATUS.options.negativeRecall.value}>
          {KEYS.STATUS.options.negativeRecall.label}
        </TCM.Radio>
        {
          result === KEYS.STATUS.options.negativeRecall.value && (
            <NegativeRecallSubField />
          )
        }
      </div>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.notRecall.value}>
          {KEYS.STATUS.options.notRecall.label}
        </TCM.Radio>
      </div>

    </RadioGroup>
  </Form.Item>
);
