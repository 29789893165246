import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { TCM } from 'components/tcm';

import {
  setFilters as setFiltersAction,
} from 'redux/tcmCampaignOffer/actions';

import { initialFilters } from 'redux/tcmOffer/reducer';
import {
  Wrapper, Title, FormRow, FormFooter,
} from './style';
import { KEYS as K } from './data';

const withoutEmployees = { value: 0, label: 'Без сотрудников' };

const getRerender = (value) => (Array.isArray(value) ? [...value] : value);

const formatToFilter = (data = []) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((item) => ({ value: item, label: item }));
};

const filterModels = (brandList = [], brands, selectedModelList) => {
  if (brands.length === 0) {
    const modelList = brandList.reduce((acc, el) => [...acc, ...el.models], []);
    return { modelList: formatToFilter(modelList), selectedModelList };
  }
  const filteredModelList = brandList
    .filter((item) => brands.includes(item.name))
    .reduce((acc, el) => [...acc, ...el.models], []);
  const filteredSelectedModelList = selectedModelList.filter((item) => filteredModelList.includes(item.value));
  return { modelList: formatToFilter(filteredModelList), selectedModelList: filteredSelectedModelList };
};

const Filters = ({
  // passed
  isVisible,
  onClose,

  // connect
  // filters data
  filtersList,
  // filters,
  filters,
  // employees
  employeesList,
  employeesIsLoading,
  employeesIsLoaded,
  employeesError,

  // actions
  setFilters,
}) => {
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [models, setModels] = useState([]);
  const { brands, suffixTMS, brandList } = filtersList;

  useEffect(() => {
    if (brandList) {
      const modelList = brandList.reduce((acc, el) => [...acc, ...el.models], []);
      setModels(formatToFilter(modelList));
    }
  }, [brandList, setModels]);

  useEffect(() => {
    if (isVisible) {
      setSelectedBrands(filters.brands);
      const brands = filters.brands.map((item) => item.value);
      const { modelList, selectedModelList } = filterModels(brandList, brands, filters.models);
      setModels(modelList);
      setSelectedModels(selectedModelList);
    }
  }, [isVisible, setSelectedBrands, setSelectedModels, filters, brandList]);

  useEffect(() => {
    if (employeesIsLoaded) {
      setEmployees([
        ...employeesList.map((item) => ({ value: item.id, label: item.displayName })),
        withoutEmployees,
      ]);
    }
  }, [employeesIsLoaded, employeesList]);

  useEffect(() => {
    if (employeesError) {
      form.setFields([{ name: K.EMPLOYEES.key, error: employeesError }]);
    }
  }, [employeesError, form]);

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({
        [K.PRICE_TRADE_IN_FROM.key]: filters.priceTradeInFrom,
        [K.PRICE_TRADE_IN_TO.key]: filters.priceTradeInTo,
        [K.PRICE_NEW_CAR_FROM.key]: filters.priceNewCarFrom,
        [K.PRICE_NEW_CAR_TO.key]: filters.priceNewCarTo,
      });
    }
  }, [form, filters, isVisible]);

  const onReset = () => {
    form.resetFields();
    setFilters({ filters: { ...initialFilters, statuses: ['DISTRIBUTION'] } });
  };

  const handleOnFinish = (values) => {
    const {
      [K.EMPLOYEES.key]: employees,
      [K.PRICE_NEW_CAR_FROM.key]: priceNewCarFrom,
      [K.PRICE_NEW_CAR_TO.key]: priceNewCarTo,
      [K.PRICE_TRADE_IN_FROM.key]: priceTradeInFrom,
      [K.PRICE_TRADE_IN_TO.key]: priceTradeInTo,
      [K.SUFFIX.key]: suffix,
    } = values;

    const filters = {
      brands: selectedBrands,
      models: selectedModels,
      suffixTMS: suffix?.map((suffixId) => ({
        value: suffixId,
        label: suffixTMS.find((topic) => topic.value === suffixId).label,
      })),
      employees: employees?.map((employeeId) => ({
        value: employeeId,
        label: employeeId !== withoutEmployees.value
          ? employeesList.find((topic) => topic.id === employeeId).displayName
          : withoutEmployees.label,
      })),
      priceNewCarFrom,
      priceNewCarTo,
      priceTradeInFrom,
      priceTradeInTo,
    };

    setFilters({ filters });
    onClose();
  };

  const onChangeBrandHandler = (brands) => {
    setSelectedBrands(formatToFilter(brands));
    const { modelList, selectedModelList } = filterModels(brandList, brands, selectedModels);
    setModels(modelList);
    setSelectedModels(selectedModelList);
  };

  return (
    <TCM.Modal visible={isVisible} onCancel={onClose}>
      <Wrapper>
        <Title>Фильтр по клиентам</Title>
        <Form
          form={form}
          onFinish={handleOnFinish}
          layout="vertical"
          onReset={onReset}
        >
          <FormRow>
            <TCM.Label
              label={K.BRANDS.label}
              name={K.BRANDS.key}
            >
              <TCM.MultipleSelect
                name={K.BRANDS.key}
                placeholder={K.BRANDS.placeholder}
                selected={selectedBrands}
                options={brands}
                onChange={onChangeBrandHandler}
              />
            </TCM.Label>
            <TCM.Label
              label={K.MODELS.label}
              name={K.MODELS.key}
            >
              <TCM.MultipleSelect
                name={K.MODELS.key}
                placeholder={K.MODELS.placeholder}
                selected={selectedModels}
                options={models}
                onChange={(v) => { setSelectedModels(formatToFilter(v)); }}
              />
            </TCM.Label>
          </FormRow>
          <FormRow>
            <TCM.Label
              label={K.SUFFIX.label}
              name={K.SUFFIX.key}
            >
              <TCM.MultipleSelect
                name={K.SUFFIX.key}
                placeholder={K.SUFFIX.placeholder}
                selected={getRerender(filters.suffixTMS)}
                options={suffixTMS}
              />
            </TCM.Label>
            <TCM.Label
              label={K.EMPLOYEES.label}
              name={K.EMPLOYEES.key}
            >
              <TCM.MultipleSelect
                name={K.EMPLOYEES.key}
                placeholder={K.EMPLOYEES.placeholder}
                selected={getRerender(filters.employees)}
                options={employees}
                loading={employeesIsLoading}
              />
            </TCM.Label>
          </FormRow>
          <FormRow>
            <TCM.InputRange
              propsLabelFrom={{
                name: K.PRICE_TRADE_IN_FROM.key,
                label: K.PRICE_TRADE_IN_FROM.label,
                validateTrigger: 'onBlur',
              }}
              propsInputFrom={{
                name: K.PRICE_TRADE_IN_FROM.key,
              }}
              propsLabelTo={{
                name: K.PRICE_TRADE_IN_TO.key,
                label: K.PRICE_TRADE_IN_TO.label,
                validateTrigger: 'onBlur',
              }}
              propsInputTo={{
                name: K.PRICE_TRADE_IN_TO.key,
              }}
            />
            <TCM.InputRange
              propsLabelFrom={{
                name: K.PRICE_NEW_CAR_FROM.key,
                label: K.PRICE_NEW_CAR_FROM.label,
                validateTrigger: 'onBlur',
              }}
              propsInputFrom={{
                name: K.PRICE_NEW_CAR_FROM.key,
              }}
              propsLabelTo={{
                name: K.PRICE_NEW_CAR_TO.key,
                label: K.PRICE_NEW_CAR_TO.label,
                validateTrigger: 'onBlur',
              }}
              propsInputTo={{
                name: K.PRICE_NEW_CAR_TO.key,
              }}
            />
          </FormRow>

          <FormFooter>
            <TCM.ButtonGroup>
              <TCM.Button htmlType="submit">Показать клиентов</TCM.Button>
              <TCM.ButtonOutline htmlType="reset">Сбросить фильтр</TCM.ButtonOutline>
            </TCM.ButtonGroup>
          </FormFooter>
        </Form>
      </Wrapper>
    </TCM.Modal>
  );
};

export default connect(
  (state) => ({
    list: state.tcmCampaignOffer.collection.list,
    isLoaded: state.tcmCampaignOffer.collection.isLoaded,
    filters: state.tcmCampaignOffer.collection.filters.data,

    filtersList: state.tcmCampaignOffer.filters,

    // employees
    employeesList: state.tcmEmployee.collection.list,
    employeesIsLoading: state.tcmEmployee.collection.isLoading,
    employeesIsLoaded: state.tcmEmployee.collection.isLoaded,
    employeesError: state.tcmEmployee.collection.error,
  }),
  {
    setFilters: setFiltersAction,
  },
)(Filters);
