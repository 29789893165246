export const CREDIT_CALCULATOR_RE_CALC = 'CREDIT_CALCULATOR_RE_CALC';
export const CREDIT_CALCULATOR_RE_CALC__SUCCESS = 'CREDIT_CALCULATOR_RE_CALC__SUCCESS';
export const CREDIT_CALCULATOR_RE_CALC__FAILURE = 'CREDIT_CALCULATOR_RE_CALC__FAILURE';
export const CREDIT_CALCULATOR_RE_CALC__RESET = 'CREDIT_CALCULATOR_RE_CALC__RESET';
export const reCalc = (data) => ({
  type: CREDIT_CALCULATOR_RE_CALC,
  data,
});
export const reCalcReset = () => ({
  type: CREDIT_CALCULATOR_RE_CALC__RESET,
});

export const CREDIT_CALCULATOR_SAVE = 'CREDIT_CALCULATOR_SAVE';
export const CREDIT_CALCULATOR_SAVE__SUCCESS = 'CREDIT_CALCULATOR_SAVE__SUCCESS';
export const CREDIT_CALCULATOR_SAVE__FAILURE = 'CREDIT_CALCULATOR_SAVE__FAILURE';
export const CREDIT_CALCULATOR_SAVE__RESET = 'CREDIT_CALCULATOR_SAVE__RESET';
export const saveResult = (data) => ({
  type: CREDIT_CALCULATOR_SAVE,
  data,
});
export const resetResult = () => ({ type: CREDIT_CALCULATOR_SAVE__RESET });
