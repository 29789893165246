import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';

import {
  extractErrorAndCode,
} from 'hacks';

import {
  all,
  put,
  fork,
  call,
  takeLeading,
} from 'redux-saga/effects';

import {
  FETCH_LEXUS_MODELS,
  FETCH_LEXUS_MODELS__FAILURE,
  FETCH_LEXUS_MODELS__SUCCESS,

  FETCH_TOYOTA_MODELS,
  FETCH_TOYOTA_MODELS__FAILURE,
  FETCH_TOYOTA_MODELS__SUCCESS,

  FETCH_BRANDS,
  FETCH_BRANDS__FAILURE,
  FETCH_BRANDS__SUCCESS,

  FETCH_MODELS,
  FETCH_MODELS__FAILURE,
  FETCH_MODELS__SUCCESS,
} from 'redux/common/actions';

function* fetchToyotaModels() {
  yield takeLeading(FETCH_TOYOTA_MODELS, function* () {
    try {
      const response = yield call(
        API.car.find,
        { alias: 'model', dependencies: { brand: 'Toyota' } },
      );
      if (!response.errorCode && response?.data?.valueList.length > 0) {
        yield put({
          type: FETCH_TOYOTA_MODELS__SUCCESS,
          list: response.data.valueList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FETCH_TOYOTA_MODELS__FAILURE, error: error.message });
    }
  });
}

function* fetchLexusModels() {
  yield takeLeading(FETCH_LEXUS_MODELS, function* () {
    try {
      const response = yield call(
        API.car.find,
        { alias: 'model', dependencies: { brand: 'Lexus' } },
      );
      if (!response.errorCode && response?.data?.valueList.length > 0) {
        yield put({
          type: FETCH_LEXUS_MODELS__SUCCESS,
          list: response.data.valueList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FETCH_LEXUS_MODELS__FAILURE, error: error.message });
    }
  });
}

function* fetchBrands() {
  yield takeLeading(FETCH_BRANDS, function* () {
    try {
      const response = yield call(
        API.car.find,
        { alias: 'brand', dependencies: {} },
      );
      if (!response.errorCode && response?.data?.valueList.length > 0) {
        yield put({
          type: FETCH_BRANDS__SUCCESS,
          list: response.data.valueList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FETCH_BRANDS__FAILURE, error: error.message });
    }
  });
}

function* fetchModels() {
  yield takeLeading(FETCH_MODELS, function* ({ brand }) {
    try {
      const response = yield call(
        API.car.find,
        { alias: 'model', dependencies: { brand } },
      );
      if (!response.errorCode && response?.data?.valueList.length > 0) {
        yield put({
          type: FETCH_MODELS__SUCCESS,
          list: response.data.valueList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FETCH_MODELS__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchToyotaModels),
    fork(fetchLexusModels),
    fork(fetchBrands),
    fork(fetchModels),
  ]);
}
