import {
  SALES_POINTS_CHANGE_ORGANISATION,
  SALES_POINTS_CHANGE_ORGANISATION__SUCCESS,
  SALES_POINTS_CHANGE_ORGANISATION__FAILIRE,
} from './actions';

const initState = {
  change: {
    form: {},
    bindedOrganization: true,
    roleChangeOrganization: true,
    isLoading: false,
    error: null,
    errors: [],
  },
};

export default function paymentReducer(state = initState, action) {
  switch (action.type) {
    case SALES_POINTS_CHANGE_ORGANISATION: {
      return {
        ...state,

        change: {
          ...state.change,
          form: {},
          isLoading: true,
          error: null,
        },
      };
    }
    case SALES_POINTS_CHANGE_ORGANISATION__SUCCESS: {
      return {
        ...state,

        change: {
          ...state.change,

          isLoading: false,
        },
      };
    }
    case SALES_POINTS_CHANGE_ORGANISATION__FAILIRE: {
      return {
        ...state,

        change: {
          ...state.change,
          isLoading: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    default: {
      return state;
    }
  }
}
