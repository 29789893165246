import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';
import { getToken } from 'helpers/utility';

export const find = ({
  dealerCenterName = '',
  group = '',
  brand = '', model = '',
  accessTokenCreditRequest = '',
}) => {
  const URI = `${API}/creditrequest/product/find`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        subsidiaries: {
          name: dealerCenterName,
        },
        accessTokenCreditRequest,
        group: Number(group),
        brand,
        model,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  find,
};
