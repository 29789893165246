import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { defaultSorting, defaultPageSize } from 'redux/payment/reducer';
import { formatFilters } from 'hacks';
import { v1 as getId } from 'uuid';

const fetchList = ({
  search = '', page = 1, filters = {}, sorting = defaultSorting,
}) => {
  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
  };

  const URI = `${API}/account/search`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: defaultPageSize,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

const fetchSectors = () => {
  const URI = `${API}/account/sector/list`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {},
    }),
  };
  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

const fetchItem = ({ id }) => {
  const URI = `${API}/account/get`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(id),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

const cloneItem = ({ id }) => {
  const URI = `${API}/account/clone`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(id),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

const create = ({
  additionalInformation,
  amount,
  number,
  channel,
  dealerId,
  description,
  email,
  expiredAt,
  fullName,
  phone,
  legalEntityId,
  sector,
}) => {
  const URI = `${API}/account/create-and-send`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),

      data: {
        additionalInformation,
        amount,
        number,
        channel,
        dealerId,
        description,
        email,
        expiredAt,
        fullName,
        phone,
        legalEntityId,
        sector,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

const annul = ({ id }) => {
  const URI = `${API}/payment/void`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        paymentId: Number(id),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  cloneItem,
  fetchItem,
  create,
  annul,
  fetchSectors,
};
