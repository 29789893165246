import React from 'react';

import {
  Title,
  Grid,
  GridRow,
  GridItem,
  Root,
} from '../style';

function DeclineWithRecommend({
  // passed
  data,
}) {
  const {
    decisionList,
  } = data;
  const decision = (Array.isArray(decisionList) && decisionList[0]) || {};

  return (
    <Root>
      <Title>Отказ с рекомендацией</Title>
      <Grid>
        <GridRow>
          <GridItem label>
            Продукт:
          </GridItem>
          <GridItem>
            {decision?.productName}
          </GridItem>
        </GridRow>
        <GridRow borderNone />
        <GridRow>
          <GridItem label>
            Марка:
          </GridItem>
          <GridItem>
            {decision?.carBrand}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Модель:
          </GridItem>
          <GridItem>
            {decision?.carModel}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Стоимость автомобиля в валюте кредита:
          </GridItem>
          <GridItem>
            {decision?.carPrice}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Год выпуска автомобиля:
          </GridItem>
          <GridItem>
            {decision?.carYear}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Первоначальный взнос в валюте кредита:
          </GridItem>
          <GridItem>
            {decision?.downPayment}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Первоначальный взнос, %:
          </GridItem>
          <GridItem>
            {decision?.downPaymentPercent}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Сумма кредита:
          </GridItem>
          <GridItem>
            {decision?.loanAmount}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Валюта кредита:
          </GridItem>
          <GridItem>
            {decision?.loanCurrency}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Срок кредита:
          </GridItem>
          <GridItem>
            {decision?.loanTerm}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Процентная ставка:
          </GridItem>
          <GridItem>
            {decision?.interestRate}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Ежемесячный платеж:
          </GridItem>
          <GridItem>
            {decision?.monthlyPayment}
          </GridItem>
        </GridRow>
      </Grid>
      <br />
      <Grid>
        <GridRow>
          <GridItem label>
            Решение:
          </GridItem>
          <GridItem>
            Отказать с рекомендацией повторного обращения
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Рекомендация:
          </GridItem>
          <GridItem>
            {decision?.refusalRecommendation}
          </GridItem>
        </GridRow>
      </Grid>

    </Root>
  );
}

export default DeclineWithRecommend;
