import styled from 'styled-components';
import { TCM } from 'components/tcm';
import { Link } from 'react-router-dom';
import withTheme from 'components/tcm/withTheme';

export const Container = styled.div``;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'Open Sans', sans-serif;
  opacity: ${({ hide }) => (hide ? 0.2 : 1)};
  transition: .2s linear opacity;

  th, td {
    padding: 0 26px 0 0;
  
    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  th {
    padding-bottom: 15px;
    border-bottom: 1px solid #cecece;
    vertical-align: top;
    
    &:last-child {
      padding-right: 0;
    }
  }

  td {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #F0F0F0;
    
    &[data-width='200']{
      width: 200px;
    }
    
    &[data-width='72']{
      width: 72px;
    }
    
    &[data-width='100']{
      width: 100px;
    }
  }

  th, td {
    width: min-content;
  }
  tbody{
   position: relative;
  }
  
  tbody tr {
    transition: .25s all linear;
    position: relative;
    z-index: 1;
    
    &:hover{
      background: #F0F0F0;
    }
    
    &:nth-child(4){
      width: 94px;
    }
   
    td:first-child{
      padding-left: 20px;
    }
    
     td:last-child{
      padding-right: 20px;      
      
      button:hover{
        background: transparent;
      }
    }
    
    button{
      background: transparent;
    }
  }
`;

export const TableTitleStroke = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isTheme }) => (isTheme ? 16 : 14)}px;
  line-height: 20px;
  color: #000000;
`;

export const TableCell = styled.div`
  width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  box-sizing: border-box;
`;

export const TableTitleSecondaryStroke = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #A8AAAC;
`;

export const TableTitleSortingStroke = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;

export const SortingIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-top: auto;
  padding: 0;
  background-color: transparent;
  border: none;
`;

export const SortingTableHeader = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const ActionTextBtn = styled(TCM.ButtonText)`
  min-width: min-content;
  width: min-content;
  white-space: normal;
`;

export const CampaignName = styled.span`
  color: #000;
  transition: .1s linear all;
`;

export const SubName = styled.span`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #A8AAAC;
  transition: .2s linear all;
`;

export const LinkTable = styled(Link)`  
  &:hover {
     ${CampaignName}, ${SubName} {
        color: #e50000;
     } 
  }
`;

export const FilterBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  
  &:focus {
    outline: none;
    border: none;
  }
`;

export const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 940px 50px;
  column-gap: 30px;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  top: -10px;
`;

export const FilterTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
  
  & > div {
    margin-bottom: 1em;
  }
`;

export const NoResults = withTheme(styled.div`
  display: grid;
  row-gap: 30px;
  margin-top: 45px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
  
    p {
      margin-bottom: 0;
    }
  `);

export const TableContainer = styled.div`
  position: relative;
  
  & > div {
    position: absolute;
    width: 100%;
    height: 200px;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 200px;
  }
  `;

export const Td = withTheme(styled.td`
      & p{
        color: ${({ theme, isExpired }) => (isExpired ? theme.palette.accent : theme.palette.black)};
      }
    `);
