import React from 'react';
import { AutoComplete as AutoCompleteAntD } from 'antd';

import { AutoCompleteStyled } from './style';

const defaultProps = {
  getPopupContainer: (trigger) => trigger.parentNode,
};

export const AutoComplete = React.forwardRef((props, ref) => (
  <AutoCompleteStyled {...defaultProps} {...props} ref={ref} />
));
export const { Option } = AutoCompleteAntD;
export default AutoComplete;
