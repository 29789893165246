import React from 'react';
import { connect } from 'react-redux';
import { TCM } from 'components/tcm';
import { Link } from 'react-router-dom';

import {
  ContentHeaderWrapper, PageTitle,
} from '../style';

const ContentHeader = () => (
  <ContentHeaderWrapper>
    <PageTitle>Пользователи</PageTitle>
    <TCM.Button as={Link} to="/tcm/users/new">Добавить сотрудника</TCM.Button>
  </ContentHeaderWrapper>
);

export default connect(
  null,
  null,
)(ContentHeader);
