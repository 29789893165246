import {
  COUNTRIES__FETCH,
  COUNTRIES__FETCH_SUCCESS,
  COUNTRIES__FETCH_FAILURE,
} from './actions';

const initState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export default function countriesReducer(state = initState, action) {
  switch (action.type) {
    case COUNTRIES__FETCH: {
      return {
        ...initState,
        isLoading: true,
      };
    }
    case COUNTRIES__FETCH_SUCCESS: {
      return {
        list: action.list,
        isLoading: false,
        isLoaded: true,
        error: null,
      };
    }
    case COUNTRIES__FETCH_FAILURE: {
      return {
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
}
