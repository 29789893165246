import API from 'services';

import { extractErrorAndCode } from 'hacks';

import {
  all, call, fork, put, takeLatest, takeEvery, select,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import { MODALS__MERGE } from 'redux/modals/actions';

import {
  USER_STATUS__TRY_TO_RESET,
  USER_STATUS__RESET,
  USER_STATUS__PATCH,
  USER_STATUS__PATCH_SUCCESS,
  USER_STATUS__PATCH_FAILURE,
} from './actions';

const getRequestState = (state) => state.request;
const getUserStatusState = (state) => state.userStatus;

export function* patch() {
  yield takeLatest(USER_STATUS__PATCH, function* (action) {
    try {
      const {
        fetch: { id: accessToken },
      } = yield select(getRequestState);

      const response = yield call(API.userStatus.patch, { accessToken, status: action.status });
      const { data: { modals = [] } } = response;

      if (response.data && response.data.status === 'success') {
        const successMessage = (
          Array.isArray(modals) && modals.length > 0
            ? modals[0].description
            : ''
        );

        yield put({ type: USER_STATUS__PATCH_SUCCESS, successMessage });
      } else {
        if (Array.isArray(modals) && modals.length > 0) {
          yield put({ type: MODALS__MERGE, modals });
        }

        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        // we set error to '' (empty string), so we rely on server sending the error message in the .modals
        throw new Error(modals.length > 0 ? '' : error);
      }
    } catch (error) {
      yield put({ type: USER_STATUS__PATCH_FAILURE, error: error.message });
    }
  });
}

export function* reset() {
  yield takeEvery(USER_STATUS__TRY_TO_RESET, function* () {
    const { id } = yield select(getUserStatusState);

    if (id !== null) {
      yield put({ type: USER_STATUS__RESET });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(patch), fork(reset)]);
}
