import React, {
  useEffect, useState, useRef,
} from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { Rifm } from 'rifm';

import { checkPrivilege } from 'helpers/roles';

import Button from 'components/Button';

import {
  formatPhone,
  parseDigitsWithSeparator as parseDigits,
  cursorToLastPhoneValuePosition,
} from 'utils';

import {
  create as createCompanyAction,
  createReset as createCompanyResetAction,
} from 'redux/company/actions';

import { KEYS as K } from './data';
import AddressField from './AddressField';

import {
  Main,
  Row,
  Footer,
  ErrorMessage,
} from './style';

const format = (s) => parseDigits(s);
const formatNumeric = (s) => (s || '').replace(/[^\d.]/gi, '');
const formatOKVED = (s) => (s || '')
  .replace(/\s{2,}/gm, '')
  .replace(/[^\d\sA-Za-zА-Я-а-я.,;]/gi, '');
const validPhoneNumber = (number) => (/[\d]/gm.test(number) ? `+7${number}` : '');

function Company({
  history,
  itemData,
  itemIsLoaded,
  organisation = {},

  // connect
  role,

  data,
  error,
  errors,
  isLoading,
  isLoaded,

  // actions
  createCompany,
  createCompanyReset,
}) {
  const [form] = Form.useForm();
  const [isValidated, setIsValidated] = useState(true);
  const phoneField = useRef();
  const faxField = useRef();
  const { companyId } = useParams();
  const location = useLocation();
  const isNew = location.pathname.includes('/new');
  const isEdit = location.pathname.includes('/edit');
  const isFormDisabled = !isNew && !isEdit;
  const canEdit = checkPrivilege([role.ORGANISATIONS.EDIT_WITHOUT_RESTRICTIONS]);

  useEffect(() => () => createCompanyReset(), [createCompanyReset]);

  useEffect(() => {
    if (isLoaded) {
      history.push(`/app/management/companies/${data.id}`);
    }
  }, [isLoaded, history, data.id]);

  function handleChange({ name, value }) {
    form.setFields([{ name, value }]);
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  useEffect(() => {
    if (itemIsLoaded) {
      const {
        name = '',
        fullName = '',
        ogrn = '',
        inn = '',
        kpp = '',
        okved = '',
        okpo = '',
        oktmo = '',
        individualSpruceOrgan = '',
        address = '',
        postAddress = '',
        phone = '',
        fax = '',
        additionalInfo = '',
      } = itemData;
      form.setFields([
        ...(name ? [{ name: 'NAME', value: name }] : []),
        ...(fullName ? [{ name: 'NAME_FULL', value: fullName }] : []),
        ...(ogrn ? [{ name: 'OGRN', value: ogrn }] : []),
        ...(inn ? [{ name: 'INN', value: inn }] : []),
        ...(kpp ? [{ name: 'KPP', value: kpp }] : []),
        ...(okved ? [{ name: 'OKVED', value: okved }] : []),
        ...(okpo ? [{ name: 'OKPO', value: okpo }] : []),
        ...(oktmo ? [{ name: 'OKTMO', value: oktmo }] : []),
        ...(individualSpruceOrgan ? [{ name: 'EXECUTIVE', value: individualSpruceOrgan }] : []),
        ...(address ? [{ name: 'ADDRESS', value: address }] : []),
        ...(postAddress ? [{ name: 'ADDRESS_POSTAL', value: postAddress }] : []),
        ...(phone ? [{ name: 'PHONE', value: formatPhone.preformatedPhone(phone) }] : []),
        ...(fax ? [{ name: 'FAX', value: formatPhone.preformatedPhone(fax) }] : []),
        ...(additionalInfo ? [{ name: 'DESCRIPTION', value: additionalInfo }] : []),
      ]);
    }
  }, [itemIsLoaded, itemData, form]);

  useEffect(() => {
    if (organisation && organisation.inn) {
      if (/^(\d{10}|\d{12})$/.test(organisation.inn)) {
        const {
          name = '',
          fullName = '',
          ogrn = '',
          inn = '',
          kpp = '',
          okved = '',
          okpo = '',
          oktmo = '',
          individualSpruceOrgan = '',
          address = '',
          postAddress = '',
          phone = '',
          fax = '',
          additionalInfo = '',
          opf = '',
        } = organisation.additionalValues;

        form.setFields([
          ...(name ? [{ name: 'NAME', value: `${opf} ${name}` }] : []),
          ...(fullName ? [{ name: 'NAME_FULL', value: fullName }] : []),
          ...(ogrn ? [{ name: 'OGRN', value: ogrn }] : []),
          ...(inn ? [{ name: 'INN', value: inn }] : []),
          ...(kpp ? [{ name: 'KPP', value: kpp }] : []),
          ...(okved ? [{ name: 'OKVED', value: okved }] : []),
          ...(okpo ? [{ name: 'OKPO', value: okpo }] : []),
          ...(oktmo ? [{ name: 'OKTMO', value: oktmo }] : []),
          ...(individualSpruceOrgan ? [{ name: 'EXECUTIVE', value: individualSpruceOrgan }] : []),
          ...(address ? [{ name: 'ADDRESS', value: address }] : []),
          ...(postAddress ? [{ name: 'ADDRESS_POSTAL', value: postAddress }] : []),
          ...(phone ? [{ name: 'PHONE', value: phone }] : []),
          ...(fax ? [{ name: 'FAX', value: fax }] : []),
          ...(additionalInfo ? [{ name: 'DESCRIPTION', value: additionalInfo }] : []),
        ]);
      }
    }
  }, [organisation, form]);

  useEffect(() => {
    if (!isValidated && Array.isArray(errors) && errors.length > 0) {
      const errorsFlat = errors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.NAME.apiKey]: name,
        [K.NAME_FULL.apiKey]: fullName,
        [K.OGRN.apiKey]: ogrn,
        [K.INN.apiKey]: inn,
        [K.KPP.apiKey]: kpp,
        [K.OKVED.apiKey]: okved,
        [K.OKPO.apiKey]: okpo,
        [K.OKTMO.apiKey]: oktmo,
        [K.EXECUTIVE.apiKey]: individualSpruceOrgan,
        [K.ADDRESS.apiKey]: address,
        [K.ADDRESS_POSTAL.apiKey]: postAddress,
        [K.PHONE.apiKey]: phone,
        [K.FAX.apiKey]: fax,
        [K.DESCRIPTION.apiKey]: additionalInfo,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(name ? [{ name: 'NAME', errors: [name] }] : []),
        ...(fullName ? [{ name: 'NAME_FULL', errors: [fullName] }] : []),
        ...(ogrn ? [{ name: 'OGRN', errors: [ogrn] }] : []),
        ...(inn ? [{ name: 'INN', errors: [inn] }] : []),
        ...(kpp ? [{ name: 'KPP', errors: [kpp] }] : []),
        ...(okved ? [{ name: 'OKVED', errors: [okved] }] : []),
        ...(okpo ? [{ name: 'OKPO', errors: [okpo] }] : []),
        ...(oktmo ? [{ name: 'OKTMO', errors: [oktmo] }] : []),
        ...(individualSpruceOrgan ? [{ name: 'EXECUTIVE', errors: [individualSpruceOrgan] }] : []),
        ...(address ? [{ name: 'ADDRESS', errors: [address] }] : []),
        ...(postAddress ? [{ name: 'ADDRESS_POSTAL', errors: [postAddress] }] : []),
        ...(phone ? [{ name: 'PHONE', errors: [phone] }] : []),
        ...(fax ? [{ name: 'FAX', errors: [fax] }] : []),
        ...(additionalInfo ? [{ name: 'DESCRIPTION', errors: [additionalInfo] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [errors, form, isValidated, setIsValidated]);

  function handleFinish(values) {
    const {
      [K.NAME.key]: name,
      [K.NAME_FULL.key]: fullName,
      [K.OGRN.key]: ogrn,
      [K.INN.key]: inn,
      [K.KPP.key]: kpp,
      [K.OKVED.key]: okved,
      [K.OKPO.key]: okpo,
      [K.OKTMO.key]: oktmo,
      [K.EXECUTIVE.key]: individualSpruceOrgan,
      [K.ADDRESS.key]: address,
      [K.ADDRESS_POSTAL.key]: postAddress,
      [K.PHONE.key]: phone,
      [K.FAX.key]: fax,
      [K.DESCRIPTION.key]: additionalInfo,
    } = values;

    if (companyId) {
      createCompany({
        id: companyId,
        name,
        fullName,
        ogrn,
        inn,
        kpp,
        okved,
        okpo,
        oktmo,
        individualSpruceOrgan,
        address,
        postAddress,
        phone: validPhoneNumber(phone),
        fax: validPhoneNumber(fax),
        additionalInfo,
      });
    } else {
      createCompany({
        name,
        fullName,
        ogrn,
        inn,
        kpp,
        okved,
        okpo,
        oktmo,
        individualSpruceOrgan,
        address,
        postAddress,
        phone,
        fax,
        additionalInfo,
      });
    }
    setIsValidated(false);
  }

  return (
    <Main
      name="user"
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <Row>
        <Form.Item
          name={K.NAME.key}
          label={K.NAME.title}
          rules={K.NAME.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Input
            name={K.NAME.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.NAME_FULL.key}
          label={K.NAME_FULL.title}
          rules={K.NAME_FULL.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Input
            name={K.NAME_FULL.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.OGRN.key}
          label={K.OGRN.title}
          rules={K.OGRN.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/[\d]/g}
            format={format}
          >
            {({ value, onChange }) => (
              <Input
                name={K.OGRN.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>

        <Form.Item
          name={K.INN.key}
          label={K.INN.title}
          rules={K.INN.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Input
            name={K.INN.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isFormDisabled || organisation.inn || itemData.inn}
          />
        </Form.Item>

        <Form.Item
          name={K.KPP.key}
          label={K.KPP.title}
          rules={K.KPP.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/[\d]/g}
            format={format}
          >
            {({ value, onChange }) => (
              <Input
                name={K.KPP.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.OKVED.key}
          label={K.OKVED.title}
          rules={K.OKVED.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/./g}
            format={formatOKVED}
          >
            {({ value, onChange }) => (
              <Input
                name={K.OKVED.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>

        <Form.Item
          name={K.OKPO.key}
          label={K.OKPO.title}
          rules={K.OKPO.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/[\d.]/g}
            format={formatNumeric}
          >
            {({ value, onChange }) => (
              <Input
                name={K.OKPO.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>

        <Form.Item
          name={K.OKTMO.key}
          label={K.OKTMO.title}
          rules={K.OKTMO.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/[\d.]/g}
            format={formatNumeric}
          >
            {({ value, onChange }) => (
              <Input
                name={K.OKTMO.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.EXECUTIVE.key}
          label={K.EXECUTIVE.title}
          rules={K.EXECUTIVE.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Input
            name={K.EXECUTIVE.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.ADDRESS.key}
          label={K.ADDRESS.title}
          rules={K.ADDRESS.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <AddressField
            name={K.ADDRESS.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.ADDRESS_POSTAL.key}
          label={K.ADDRESS_POSTAL.title}
          rules={K.ADDRESS_POSTAL.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <AddressField
            name={K.ADDRESS_POSTAL.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.PHONE.key}
          label={K.PHONE.title}
          rules={K.PHONE.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/[\d]/g}
            mask
            replace={formatPhone.replace}
            format={formatPhone.format}
            onClick={() => cursorToLastPhoneValuePosition(phoneField)}
          >
            {({ value, onChange }) => (
              <Input
                name={K.PHONE.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                addonBefore={formatPhone.prefix}
                ref={phoneField}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>

        <Form.Item
          name={K.FAX.key}
          label={K.FAX.title}
          rules={K.FAX.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Rifm
            accept={/[\d]/g}
            mask
            replace={formatPhone.replace}
            format={formatPhone.format}
            onClick={() => cursorToLastPhoneValuePosition(faxField)}
          >
            {({ value, onChange }) => (
              <Input
                name={K.FAX.key}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={() => cursorToLastPhoneValuePosition(faxField)}
                addonBefore={formatPhone.prefix}
                ref={faxField}
                disabled={isFormDisabled}
              />
            )}
          </Rifm>
        </Form.Item>
      </Row>

      <Row>
        <Form.Item
          name={K.DESCRIPTION.key}
          label={K.DESCRIPTION.title}
          rules={K.DESCRIPTION.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Input.TextArea
            name={K.DESCRIPTION.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Row>

      {error && (
        <ErrorMessage>{error}</ErrorMessage>
      )}

      {isNew && (
        <Footer>
          <Button
            size="mini"
            type="link"
            shape="square"
            href="/app/management/companies/list"
          >
            Отмена
          </Button>

          <Button
            htmlType="submit"
            type="ghost"
            size="mini"
            shape="square"
            loading={isLoading}
          >
            Создать компанию
          </Button>
        </Footer>
      )}

      {isEdit && (
        <Footer>
          <Button
            size="mini"
            type="link"
            shape="square"
            href={`/app/management/companies/${companyId}/details`}
          >
            Отмена
          </Button>

          <Button
            htmlType="submit"
            type="ghost"
            size="mini"
            shape="square"
            loading={isLoading}
          >
            Сохранить
          </Button>
        </Footer>
      )}

      {!(isNew || isEdit) && canEdit && (
        <Footer>
          <Button
            size="mini"
            type="link"
            shape="square"
            href={`/app/management/companies/${companyId}/edit`}
          >
            Редактировать
          </Button>
        </Footer>
      )}
    </Main>
  );
}

export default withRouter(connect(
  (state) => ({
    role: state.auth.role,

    itemData: state.company.item.data,
    itemIsLoaded: state.company.item.isLoaded,

    data: state.company.create.data,
    error: state.company.create.error,
    errors: state.company.create.errors,
    isLoading: state.company.create.isLoading,
    isLoaded: state.company.create.isLoaded,
  }),
  {
    createCompany: createCompanyAction,
    createCompanyReset: createCompanyResetAction,
  },
)(Company));
