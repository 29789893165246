import styled, { css } from 'styled-components';
import { Radio as AntRadio } from 'antd';
import React from 'react';
import withTheme from './withTheme';

export const Radio = withTheme(styled(AntRadio)`
  ${({ theme }) => css`
    &,
    & .ant-radio-checked,
    &:hover {
      .ant-radio-inner {
        width: ${theme.radio.width};
        height: ${theme.radio.height};
        border: ${theme.radio.border};
        border-radius: 50%;
        box-sizing: border-box;
      }
    }

    & .ant-radio-inner::after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      margin-top: 0;
      margin-left: 0;
      transform: translate(-50%, -50%);
      background-color: ${theme.radio.iconCheckedColor};
    }

    & .ant-radio-checked::after {
      border-color: ${theme.radio.iconCheckedColor};
    }
    
    &.ant-radio-wrapper  {
      display: flex;
      align-items: center;
      margin-right: 0;
      white-space: normal;
      overflow-wrap: anywhere;
    }
    
    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
    
    span:last-child {
      padding-right: 0;
      font-family: ${theme.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: ${theme.palette.black};
    }
  `}
`);

export const RadioGroup = withTheme(styled(AntRadio.Group).attrs({ className: 'radio-group' })`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 44px;
  width: 100%;
  
  .ant-radio-button-wrapper {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: ${({ theme }) => theme.palette.gray6};
    
    &:hover {
      color: ${({ theme }) => theme.palette.accent};
    }
    
    &:focus-within {
      box-shadow: none;
    }
    
    &:first-child {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
    
    &:last-child {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: ${({ theme }) => theme.palette.accent};
    border-color: ${({ theme }) => theme.palette.accent};
    color: ${({ theme }) => theme.palette.white};
  }
  
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-color: ${({ theme }) => theme.palette.accent};
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    content: none;
  }
`);

export const RadioTextGroup = withTheme(styled(AntRadio.Group)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 17px;
`);

export const RadioBtnText = withTheme(styled(React.forwardRef((
  { theme, ...p },
  ref,
) => <AntRadio.Button {...p} ref={ref} />))`
  ${({ theme }) => css`
  &.ant-radio-button-wrapper {
    display: block;
    padding: 0;
    min-width: max-content;
    height: auto;
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-family: ${theme.fontFamily};
    font-size: ${theme.buttons.textSize}px;
    line-height: ${theme.buttons.lineHeight}px;
    color: ${theme.buttons.text.textColor};
    box-sizing: border-box;
    
    &:before {
      content: none;
    }

    &[ant-click-animating-without-extra-node="true"]::after{
      display: none;
    }

    &:hover,
    &:focus {
      border: ${theme.buttons.text.border};
      color: ${theme.buttons.text.textHoverColor};
      background-color: transparent;
    }

    &:active {
      background-color: ${theme.buttons.text.backgroundColor};
      color: ${theme.buttons.text.textColor};
      border: ${theme.buttons.text.border};
    }
    
    &.ant-radio-button-wrapper-checked {
            color: ${theme.palette.gray3};
    }
    
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: none;
    }
  }

`}
`);
