import React, { useState } from 'react';
import { Search } from 'components/Input';

import { Root } from './style';

function SearchPanel({ onSearch }) {
  const [search, setSearch] = useState('');

  function handleOnSearch(v) {
    setSearch(v);
    onSearch(v);
  }

  return (
    <Root>
      <Search
        allowClear
        placeholder="Введите номер заявки, ФИО или номер телефона клиента"
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
        onSearch={handleOnSearch}
      />
    </Root>
  );
}

export default SearchPanel;
