// ugly way of accessing the data - server returns either an object with array inside or just an empty array
export const formatProduct = (object) =>
  (object.data && object.data.productResponseList
    ? object.data.productResponseList
    : []
  ).map((item) => {
    const {
      id,
      fullInternalName: name,
      group,
      P_Loan_Rate_Min: loanRate,
      P_Loan_Downpayment_Min: downPaymentMin,
      P_Loan_Downpayment_Max: downPaymentMax,
    } = item;

    return ({
      value: id,
      label: [
        name,
        [
          ...(loanRate ? [`${loanRate}% годовых`] : []),
          ...((downPaymentMin && downPaymentMax) ? [`ПВ:${downPaymentMin}-${downPaymentMax}%`] : []),
        ].join`, `,
      ].join` `,
      group,
      loanRate,
    });
  });
