import styled from 'styled-components';
import { Collapse } from 'antd';

import {
  LoadingOutlined,
} from '@ant-design/icons';

const Root = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const CollapseStyled = styled(Collapse)`
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

const PanelStyled = styled(Collapse.Panel)``;

const PreviewRemoveButton = styled.button`
  padding: 0 10px;
  margin: 0;
  border-radius: 2px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 1px solid red;
  color: white;
  background: #f727274f;
  cursor:pointer;
  transition: background 0.2s ease-in;
  width: max-content;
  max-width: 40px;
  
  &:focus, &:active {
    outline: none;
  }

  &:hover {
    background: #f727278f;
  }
`;

const ImageLoaderIcon = styled(LoadingOutlined)``;

const Image = styled.img`
  width: 100%;
`;

const ErrorMessage = styled.span`
  color: red;
`;

const RotateLeftButton = styled(PreviewRemoveButton)`
  left: 1rem;
`;

const RotateRightButton = styled(PreviewRemoveButton)`
  left: 4rem;
`;

const DownloadButton = styled(PreviewRemoveButton)`
  right: 4rem;
`;

const ImagePreviewContainer = styled.div`
    transition: .2s linear all;
    ${({ rotate }) => `transform: rotate(${rotate}deg)`}
`;

export {
  Root,
  ImageContainer,
  CollapseStyled,
  PanelStyled,
  PreviewRemoveButton,
  ImageLoaderIcon,
  Image,
  ErrorMessage,
  RotateLeftButton,
  RotateRightButton,
  DownloadButton,
  ImagePreviewContainer,
};
