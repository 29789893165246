import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import { DATE_FORMAT_WITH_TIME as DATE_FORMAT } from 'settings';
import moment from 'moment';

import { ReloadOutlined } from '@ant-design/icons';

import { fetch as fetchHistory } from 'redux/history/actions';

import {
  Root,
  CollapseStyled,
  PanelStyled,
  RefetchButton,
  Header,
  Title,
  TimeBlock,
  TimeTitle,
  TimeDuration,
  TimePeriod,
  LogContent,
  LogText,
  LogTime,
} from './style';

const PanelHeader = ({
  title, dateFrom = '', dateTo = '', duration = 0,
}) => (
  <Header>
    <Title>{title}</Title>
    <TimeBlock>
      <TimeTitle>Находился в сессии: </TimeTitle>

      <TimePeriod>
        {moment(dateFrom).isValid() && (
          <>
            {moment(dateFrom).format(DATE_FORMAT)}
            {moment(dateTo).isValid() && (
              <>
                {' - '}
                {moment(dateTo).format(DATE_FORMAT)}
              </>
            )}
          </>
        )}
      </TimePeriod>

      {duration && duration > 0 ? (
        <>
          {' - '}
          <TimeDuration>{moment.duration(duration, 'seconds').humanize()}</TimeDuration>
        </>
      ) : null}
    </TimeBlock>
  </Header>
);

function History({
  // passed
  id,

  // connect
  historyId,
  list,
  isLoading,
  isLoaded,
  error,

  // actions
  fetchHistory,
}) {
  useEffect(() => {
    if (id !== historyId) {
      fetchHistory({ accessToken: id });
    }
  }, [fetchHistory, id, historyId]);

  function refetchHistory() {
    fetchHistory({ accessToken: id });
  }

  return (
    <Root>
      <RefetchButton onClick={refetchHistory}>
        <ReloadOutlined />
      </RefetchButton>
      {isLoading && <Loader text="Загрузка истории изменений" />}
      {error && <span>{error}</span>}
      {isLoaded && (
        <CollapseStyled>
          {list.map((e) => (
            <PanelStyled
              key={String(e.id)}
              header={(
                <PanelHeader
                  title={e.userFullName}
                  dateFrom={e.dateFrom}
                  dateTo={e.dateTo}
                  duration={e.durationLogin}
                />
              )}
            >
              {e.logList.map((log) => (
                <LogContent key={log.id}>
                  <LogText>{log.name}</LogText>
                  <LogTime>{moment(log.date).format(DATE_FORMAT)}</LogTime>
                </LogContent>
              ))}
            </PanelStyled>
          ))}
        </CollapseStyled>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    historyId: state.history.id,
    list: state.history.list,
    isLoading: state.history.isLoading,
    isLoaded: state.history.isLoaded,
    error: state.history.error,
  }),
  {
    fetchHistory,
  },
)(History);
