import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ARM_COMMENTS, ARM_ANSWER } from 'settings';
import RequestPage from './RequestPage';

function Index() {
  return (
    <Switch>
      <Route exact path="/app/leads/request/:id" component={RequestPage} />
      <Route path="/app/leads/request/:id/details" component={RequestPage} />
      <Route path="/app/leads/request/:id/history" component={RequestPage} />
      <Route path="/app/leads/request/:id/credit" component={RequestPage} />
      {ARM_COMMENTS && <Route path="/app/leads/request/:id/comments" component={RequestPage} />}
      {ARM_ANSWER && <Route path="/app/leads/request/:id/answer" component={RequestPage} />}
    </Switch>
  );
}

export default connect(null, null)(Index);
