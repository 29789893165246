import React, { Fragment } from 'react';
import Typography from 'components/Typography';
import Button from 'components/Button';
import Select from 'components/Fields/Select';
import SelectWithoutSubmit from 'components/Fields/SelectWithoutSubmit';
import Product from 'components/Fields/Product';
import Radio from 'components/Fields/Radio';
import Input from 'components/Fields/Input';
import InputMasked from 'components/Fields/InputMasked';
import DateField from 'components/Fields/Date';
import Currency from 'components/Fields/Currency';
import Phone from 'components/Fields/Phone';
import Checkbox from 'components/Fields/Checkbox';
import Textarea from 'components/Fields/Textarea';
import AutoComplete from 'components/Fields/AutoComplete';
import Country from 'components/Fields/Country';
import Address from 'components/Fields/Address';
import CarAutoSuggest from 'components/Fields/CarAutoSuggest';
import Codebook from 'components/Fields/Codebook';
import Email from 'components/Fields/Email';
import Slider from 'components/Fields/Slider';
import DealerCenter from 'components/Fields/DealerCenter';
import Label from 'components/Fields/Label';
import Collapse from 'components/Collapse';
import Tabs from 'components/Fields/Tabs';
import Crif from 'components/Fields/Crif';
import Link from 'components/Fields/Link';
import Moratorium from 'components/Fields/Moratorium';
import MoratoriumButton from 'components/Fields/MoratoriumButton';
import Hidden from 'components/Fields/Hidden';
import { TabPane } from 'components/Tabs';
import ConfirmationCode from 'components/ConfirmationCode';
import { TYPES } from 'settings';
import { flattenFields } from 'utils';

import {
  Block,
  ButtonContainer,
  Column,
  KaskoContainer,
  KaskoRemove,
  KaskoRoot,
  NestedContainer,
  NestedRoot,
  Row,
  RowAsymmetric,
  RowPayments,
} from '../style';

const { Title } = Typography;

const Nested = ({ children }) => <NestedRoot>{children}</NestedRoot>;

function Switch({ field, props = {}, area = false } = {}) {
  const { formIsDisabled } = props;

  switch (field.type) {
    // User Input types
    case TYPES.SELECT: {
      return (
        <Select
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          options={field.options}
          label={field.label}
          initialValue={field.value}
          error={field.error}
          disabled={formIsDisabled || field.disabled}
          area={area}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.SELECT_WITHOUT_SUBMIT: {
      return (
        <SelectWithoutSubmit
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          options={field.options}
          label={field.label}
          initialValue={field.value}
          error={field.error}
          disabled={formIsDisabled || field.disabled}
          area={area}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.PRODUCT: {
      return (
        <Product
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          options={field.options}
          label={field.label}
          initialValue={field.value}
          error={field.error}
          disabled={formIsDisabled || field.disabled}
          dependencies={field.dependencies}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          area={area}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.RADIO: {
      return (
        <Radio
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          options={field.options}
          label={field.label}
          initialValue={field.value}
          description={field.help}
          error={field.error}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          area={area}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.CHECKBOX: {
      // temporary solution - should be a different type
      const subComponent = field.name === TYPES.CRM_RESULTS && (
        <Crif
          key={field.name}
          name={field.name}
          initialValue={field.value}
          disabled={formIsDisabled || field.disabled}
          dataSource={field.dataSource}
          {...props}
        />
      );

      return (
        <>
          <Nested key={field.name}>
            <Checkbox
              {...props}
              name={field.name}
              type={field.type}
              label={field.label}
              initialValue={field.value}
              error={field.error}
              disabled={formIsDisabled || field.disabled}
              dependents={field.dependents}
              ifChangeClearFields={field.ifChangeClearFields}
              area={area}
              dataSource={field.dataSource}
              warning={field.warning}
            />
            {Array.isArray(field.data) && field.data.length > 0 && (
              <NestedContainer>{field.data.map((e) => Switch({ field: e, props, area }))}</NestedContainer>
            )}
          </Nested>
          {subComponent}
        </>
      );
    }
    case TYPES.MORATORIUM: {
      if (typeof field.link === 'string') {
        return (
          <Fragment key={field.name}>
            <Row>
              <Link href={field.link} target="_blank" area>
                {field.label}
              </Link>
              <MoratoriumButton area={false} />
            </Row>
            <Moratorium />
          </Fragment>
        );
      }
      return (
        <Input
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          capitalize={field.firstCharToUpperCase}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.TEXT: {
      if (typeof field.link === 'string') {
        return (
          <Fragment key={field.name}>
            <Row>
              <Link href={field.link} target="_blank" area>
                {field.label}
              </Link>
            </Row>
          </Fragment>
        );
      }
      if (typeof field.mask === 'string') {
        return (
          <InputMasked
            key={field.name}
            {...props}
            name={field.name}
            type={field.type}
            initialValue={field.value}
            label={field.label}
            error={field.error}
            placeholder={field.label}
            area={area}
            mask={field.mask}
            disabled={formIsDisabled || field.disabled}
            dependents={field.dependents}
            ifChangeClearFields={field.ifChangeClearFields}
            warning={field.warning}
            dataSource={field.dataSource}
            size="large"
          />
        );
      }
      return (
        <Input
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          capitalize={field.firstCharToUpperCase}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.NUMBER: {
      return (
        <Input
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          capitalize={field.firstCharToUpperCase}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.EMAIL: {
      return (
        <Email
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          options={field.mailServers}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.DATE: {
      return (
        <DateField
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.MONEY: {
      return (
        <Currency
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          range={field.range}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.PHONE: {
      return (
        <Phone
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          phoneIsVerified={field.phoneIsVerified}
          postfix={field.postfix}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.TEXTAREA: {
      return (
        <Textarea
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.ADDRESS: {
      if (field.alias === 'country') {
        return (
          <Country
            key={field.name}
            {...props}
            name={field.name}
            type={field.type}
            alias={field.alias}
            dependencies={field.dependencies}
            initialValue={field.value}
            label={field.label}
            error={field.error}
            placeholder={field.label}
            area={area}
            disabled={formIsDisabled || field.disabled}
            dependents={field.dependents}
            ifChangeClearFields={field.ifChangeClearFields}
            warning={field.warning}
            dataSource={field.dataSource}
            size="large"
          />
        );
      }
      return (
        <Address
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          alias={field.alias}
          dependencies={field.dependencies}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          timeZone={field.timeZone}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          valueOnlyFromOptions={field.valueOnlyFromOptions}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.CAR_AUTOSUGGEST:
    case TYPES.CAR_AUTOSUGGEST_TRADEIN: {
      return (
        <CarAutoSuggest
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          alias={field.alias}
          dependencies={field.dependencies}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
          isTradeIn={field.type === TYPES.CAR_AUTOSUGGEST_TRADEIN}
        />
      );
    }
    case TYPES.CODEBOOK: {
      return (
        <Codebook
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          alias={field.alias}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.SELECT_WITH_FILTER: {
      return (
        <AutoComplete
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          options={field.options}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.SELECT_WITH_SEARCH: {
      return (
        <Address
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          alias={field.alias}
          dependencies={field.dependencies}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          area={area}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          valueOnlyFromOptions={field.valueOnlyFromOptions}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.RANGE: {
      return (
        <Slider
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          initialValue={field.value}
          label={field.label}
          error={field.error}
          placeholder={field.label}
          min={Array.isArray(field.range) ? field.range[0] : 0}
          max={Array.isArray(field.range) ? field.range[1] : 100}
          disabled={formIsDisabled || field.disabled}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.DEALER_CENTER: {
      return (
        <DealerCenter
          key={field.name}
          {...props}
          name={field.name}
          type={field.type}
          options={field.options}
          label={field.label}
          initialValue={field.value}
          error={field.error}
          disabled={formIsDisabled || field.disabled}
          area={area}
          dependents={field.dependents}
          ifChangeClearFields={field.ifChangeClearFields}
          warning={field.warning}
          dataSource={field.dataSource}
          size="large"
        />
      );
    }
    case TYPES.KASKO: {
      const driversCount = (props.fields.filter((e) => e.type === 'borderBlock') || []).length;

      return (
        <KaskoRoot key={field.name}>
          {Array.isArray(field.data) && field.data.length > 0 && (
            <KaskoContainer>
              {driversCount > 1 && (
                <KaskoRemove
                  onClick={() =>
                    props.onChangeField(
                      {},
                      flattenFields(field.data)
                        .filter((e) => e.value)
                        .map((e) => e.name),
                    )}
                >
                  ✕
                </KaskoRemove>
              )}
              {field.data.map((e) => Switch({ field: e, props }))}
            </KaskoContainer>
          )}
        </KaskoRoot>
      );
    }
    case TYPES.BUTTON: {
      return (
        <ButtonContainer>
          <Button type="primary" size="small" onClick={() => props.onChangeField({ [field.name]: !field.value })}>
            {field.label}
          </Button>
        </ButtonContainer>
      );
    }
    case TYPES.HIDDEN: {
      // temporary solution - should be a different type
      if (field.name === TYPES.CRM_RESULTS) {
        return (
          <Crif
            key={field.name}
            name={field.name}
            initialValue={field.value}
            disabled={formIsDisabled || field.disabled}
            {...props}
          />
        );
      }
      return <Hidden key={field.name} {...props} name={field.name} initialValue={field.value} />;
    }

    // Text types
    case TYPES.TITLE: {
      return (
        <Title key={field.name} level={2}>
          {field.label}
        </Title>
      );
    }
    case TYPES.ACCEPTED: {
      return (
        <Title key={field.name} level={4}>
          {field.label}
        </Title>
      );
    }
    case TYPES.SMS: {
      return (
        <Title key={field.name} level={4}>
          {field.label}
        </Title>
      );
    }
    case TYPES.SMS_CONFIRMATION: {
      return <ConfirmationCode key={field.name} name={field.name} label={field.label} text={field.text} />;
    }
    case TYPES.TEXT_NOTIFICATION: {
      return (
        <Title key={field.name} level={4} type="danger">
          {field.label || field.text}
        </Title>
      );
    }

    // Block types
    case TYPES.ROW: {
      return (
        <RowAsymmetric key={field.name}>
          {field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}
        </RowAsymmetric>
      );
    }
    case TYPES.HALF: {
      return (
        <div key={field.name}>
          <Label label={field.label} />
          <Row>{field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}</Row>
        </div>
      );
    }
    case TYPES.ROW__0:
    case TYPES.ROW__1:
    case TYPES.ROW__2:
    case TYPES.ROW__3: {
      if (field.name === 'rowMonthlyPayments') {
        return (
          <RowPayments key={field.name}>
            {field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}
          </RowPayments>
        );
      }
      return <Row key={field.name}>{field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}</Row>;
    }
    case TYPES.COLUMN: {
      return (
        <Column key={field.name}>{field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}</Column>
      );
    }
    case TYPES.BLOCK__2:
    case TYPES.BLOCK__3: {
      return <Block key={field.name}>{field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}</Block>;
    }
    case TYPES.COLLAPSE: {
      return (
        <Collapse key={field.name} title={field.title}>
          {field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}
        </Collapse>
      );
    }
    case TYPES.TABS: {
      return (
        <Block key={field.name}>
          <Tabs name={field.name} fields={field.data} {...props}>
            {field.data.map((e) => Switch({ field: e, props }))}
          </Tabs>
        </Block>
      );
    }
    case TYPES.TAB: {
      return (
        <TabPane key={field.name} tab={field.label}>
          {field.data.map((e, i) => Switch({ field: e, props, area: i % 2 === 0 }))}
        </TabPane>
      );
    }

    default: {
      // return <span key={field.name}>{field.name}</span>;
      return null;
    }
  }
}

export default Switch;
