import styled from 'styled-components';
import nextStatusIcon from 'assets/icons/nextStatusIcon.svg';
import nextStatusIconGray from 'assets/icons/nextStatusIconGray.svg';
import { AttentionIcon } from 'icons';
import { CAMPAIGN_TIMESTAMPS } from 'tcm/campaigns/data';
import withTheme from '../withTheme';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const ItemWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  width: min-content;
  align-items: baseline;
  text-align: center;
  
  &:not(:last-child) {
    .status-title {
      display: flex;
    
      &:after {
        margin-left: 10px;
        width: 6px;
        height: 15px;
        content: url(${({ isNext }) => (isNext ? nextStatusIconGray : nextStatusIcon)});
      }
    }
    .status-dates{
      padding-right: 16px;
    }
  }
`;

export const Title = withTheme(styled.p.attrs(() => ({ className: 'status-title' }))`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ timestamp, theme }) => {
    switch (timestamp) {
      case CAMPAIGN_TIMESTAMPS.PENDING:
        return theme.palette.gray3;
      default:
        return theme.palette.black;
    }
  }};
`);

const getColor = ({ timestamp, isComplete, theme }) => {
  if (isComplete) {
    return theme.palette.black;
  }
  switch (timestamp) {
    case CAMPAIGN_TIMESTAMPS.CURRENT:
    case CAMPAIGN_TIMESTAMPS.PENDING:
      return theme.palette.gray3;
    default:
      return theme.palette.black;
  }
};

export const DatesWrapper = withTheme(styled.div.attrs(() => ({ className: 'status-dates' }))`
  color: ${({ timestamp, isComplete, theme }) => getColor({ timestamp, isComplete, theme })};
`);

export const Date = withTheme(styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  
  &:not(:last-child){
    &:after {
      margin-top: -.5em;
      display: block;
      content: '...';
      line-height: 1;
    }
  }
  
  &:last-child {
    padding-right: 0;
  }
`);

export const PopUpWrapper = withTheme(styled.div`
  position: absolute;
  top: calc(100% + 25px);
  left: calc(50% - 8px);
  display: grid;
  grid-template-columns: 18px auto;
  column-gap: 8px;
  padding: 30px 107px 33px;
  max-width: 980px;
  min-width: min-content;
  width: max-content;
  background-color: ${({ theme }) => theme.palette.white};
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
  transform: translate(-50%, 0%);
  filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.08));
  box-sizing: border-box;
  visibility: hidden;
  
  &:after {
    background-color: ${({ theme }) => theme.palette.white};
    position: absolute;
    left: 50%;
    top: -5px;
    width: 48px;
    height: 48px;
    transform:  translate(-50%, 0%) rotate(45deg);
    content: '';
  }
`);

export const PopupText = withTheme(styled.p`
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  color: ${({ theme, isExpired }) => (isExpired ? theme.palette.accent : theme.palette.black)};
`);

const AttentionIconWrapper = styled(AttentionIcon)`
  margin-top: 6px;
`;

export { AttentionIconWrapper as AttentionIcon };
