import React from 'react';
import styled from 'styled-components';
import { GRID_AREAS } from 'settings';
import { InputNumber as InputNumberAntD } from 'antd';

const InputStyled = styled(
  ({ area, ...p }) => <InputNumberAntD {...p} />,
).attrs({ className: 'styledInput' })`
  border-radius: 5px;

   &.ant-input-number {
      ${({ area }) => typeof area === 'boolean' && `
        grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
        min-width: max-content;
      `}
      
      &.ant-input-number-lg input {
        height: 50px;
      }
  }
`;

export { InputStyled };
