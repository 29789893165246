import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';

import {
  all,
  call,
  fork,
  put,
  takeLeading,
} from 'redux-saga/effects';

import {
  extractErrorAndCode,
} from 'hacks';
import {
  FETCH_SUBJECT_LIST,
  FETCH_SUBJECT_LIST__FAILURE,
  FETCH_SUBJECT_LIST__SUCCESS,
} from 'redux/tcmTopics/actions';

function* fetchSubjectList() {
  yield takeLeading(FETCH_SUBJECT_LIST, function* () {
    try {
      const response = yield call(API.TCM.campaign.fetchTopics);
      if (
        response.data
        && Array.isArray(response.data.topicItemResponseList)
        && Array.isArray(response.data.subtopicItemResponseList)
      ) {
        yield put({
          type: FETCH_SUBJECT_LIST__SUCCESS,
          topics: response.data.topicItemResponseList.map(
            (t) => ({ ...t, value: t.id, label: t.name }),
          ),
          subtopics: response.data.subtopicItemResponseList.map(
            (t) => ({
              ...t,
              value: t.id,
              label: t.name,
              topicId: (
                Array.isArray(t.topicItemResponseList)
                    && t.topicItemResponseList.length > 0
                    && t.topicItemResponseList[0].id
              ) ? t.topicItemResponseList[0].id : null,
            }),

          ),
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FETCH_SUBJECT_LIST__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchSubjectList),
  ]);
}
