import styled from 'styled-components';
import { Form, Collapse } from 'antd';

const { Panel } = Collapse;

export const Root = styled.div`
    padding: 2rem 4rem 0;
    margin-top: 0;
    width: 100%;
    
    .ant-picker {
      width: 100%;
    }
    
    .ant-select-single, .ant-select-selector{
      height: 40px !important;
    }
    
    .ant-collapse-header{
      padding: 0 !important;
    }
    
    .ant-select-selection-item{
      display: flex;
      align-items: center;
    }
`;

export const Loading = styled.div`
    padding: 2rem 4rem 0;
    margin-top: 0;
    width: 100%;
    text-align: center;
    height: 82vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eeeeee;
`;

export const CollapseAll = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: right;
    color: #E71313;
    margin-bottom: 28px;
    cursor: pointer;
`;

export const CollapseHeader = styled.div`
    padding: 12px 16px;
    padding-right: 40px;
    width: 100%;
    z-index: 10;
`;

export const Footer = styled.div` 
   display: grid;
   grid-template-columns: 192px 303px;
   grid-column-gap: 33px;
   justify-content: flex-end;
   margin-top: 61px;
`;

export const Main = styled(Form)`
  padding: 2rem 0 3rem;

  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: auto;
  }

  & textarea.ant-input {
    margin-bottom: 0px;
  }

  & .ant-btn {
    min-width: 160px !important;
  }
`;

export const Header = styled.div`
    margin-top: 51px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

export const ButtonsContainer = styled.div`
  a.ant-btn, button.ant-btn {
    padding-top: 0.01px !important;
    padding-bottom: 0.01px !important;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  ${({ isEdit }) => !isEdit && `
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 32px;
  `}
`;

export const CollapseContainer = styled(Collapse)`
  border: none;
  background: #fff;
`;

export const FormItemsContainer = styled.div`
  padding-top: 36px;
  border-top: 1px solid #E0E0E0;
  
  label {
    color: #7E7E7E;
  }
`;

export const FormItemsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ items }) => items || 3},
    ${({ width }) => width || '1fr'}
  );
  grid-column-gap: 21px;
`;

export const FormItemsTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 18px;
`;

export const TextArea = styled.div`
  margin-top: 20px;
`;

export const CollapseItem = styled(Panel)`
  background: #F2F2F2;
  margin-bottom: 21px;
  padding-top: 0;
  
  .ant-collapse-content {
    border-top: none;
  }
  
  &.ant-collapse-item-active {
   .ant-collapse-arrow{
      transform: translateY(-50%) rotate(180deg) !important;
    }
  }
  
  .ant-collapse-header{
    height: 77px;
    display: flex;
    align-items: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
  }
  
  .ant-collapse-content{
    background: #F2F2F2;
  }
  
  svg{
    transition: .2s linear all;
    z-index: 1;
  }
 
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: right;
`;

export const SuccessMessage = styled.div`
  color: green;
  text-align: right;
  margin-top: 20px;
  font-size: 14px;
  font-family: "Toyota Type",sans-serif;
`;
