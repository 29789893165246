import {
  FETCH_EMPLOYEES_LIST,
  FETCH_EMPLOYEES_LIST__SUCCESS,
  FETCH_EMPLOYEES_LIST__FAILURE,

  EMPLOYEE_GET_REPORT,
  EMPLOYEE_GET_REPORT__FAILURE,
  EMPLOYEE_GET_REPORT__RESET,
  EMPLOYEE_GET_REPORT__SUCCESS,
} from './actions';

const initState = {
  collection: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  report: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function employeeReducer(state = initState, action) {
  switch (action.type) {
    case FETCH_EMPLOYEES_LIST: {
      return {
        ...state,
        collection: {
          ...state.collection,
          isLoading: true,
        },
      };
    }
    case FETCH_EMPLOYEES_LIST__SUCCESS: {
      return {
        ...state,
        collection: {
          ...state.collection,
          list: action.value,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case FETCH_EMPLOYEES_LIST__FAILURE: {
      return {
        ...state,
        collection: {
          ...state.collection,
          list: [],
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case EMPLOYEE_GET_REPORT: {
      return {
        ...state,

        report: {
          ...state.report,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case EMPLOYEE_GET_REPORT__SUCCESS: {
      return {
        ...state,

        report: {
          ...state.report,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case EMPLOYEE_GET_REPORT__FAILURE: {
      return {
        ...state,

        report: {
          ...state.report,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case EMPLOYEE_GET_REPORT__RESET: {
      return {
        ...state,
        report: {
          ...initState.report,
        },
      };
    }

    default: {
      return state;
    }
  }
}
