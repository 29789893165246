import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SignIn from './SignIn';
import AcceptInvite from './AcceptInvite';
import RestorePassword from './RestorePassword';
import RestoreExternalPassword from './RestoreExternalPassword';

import { Root } from './style';

function Auth() {
  return (
    <Root>
      <Switch>
        <Route exact path="/auth" component={SignIn} />
        <Route path="/auth/invite/:token" component={AcceptInvite} />
        <Route path="/auth/restore-password/:token" component={RestorePassword} />
        <Route exact path="/auth/restore" component={RestoreExternalPassword} />
      </Switch>
    </Root>
  );
}

export default Auth;
