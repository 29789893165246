import { CallStatusDescriptionsCode, CallStatusesCode } from 'tcm/offer/data';

export const MainFieldsCodes = {
  Date: 'CREATE_DATE',
  Time: 'CREATE_TIME',
  Status: 'STATUS',
  Comment: 'COMMENT',
};

export const NotInterestedOptions = {
  SatisfiedCurrent: 'Устраивает текущий автомобиль',
  InterestedAnotherToyota: 'Интересует другая модель Toyota',
  InterestedAnotherLexus: 'Интересует другая модель Lexus',
  Late: 'Будет актуально позже',
  NotWantNewLoan: 'Не хочет новый кредит',
  NotSatisfiedWithAssessment: 'Не устраивает оценка',
  NotSatisfiedWithPayment: 'Не устраивает платеж',
  InterestedAnotherBrand: 'Интересует другой бренд',
  PurchasedAnother: 'Уже приобретен другой авто',
  Difficulties: 'Финансовые трудности',
  Other: 'Другая причина',
};

const causeOptions = Object
  .values(NotInterestedOptions)
  .map((value) => ({
    label: value,
    value,
  }));

const whyOptions = [
  'Мой автомобиль еще новый',
  'Маленький пробег',
  'Другая причина',
].map((value) => ({ value, label: value }));

const resultValues = new Map(Object.values(CallStatusesCode)
  .map(
    (value) => [value, value],
  ));

const StatusOptions = {
  recall: {
    value: CallStatusesCode.Recall,
    label: 'Предложение интересно, перезвонить позднее',
    children: {
      recallDate: {
        key: 'RECALL_DATE',
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === CallStatusesCode.Recall
                && (!value || value.format('DD.MM.YYYY').length === 0)
              ) {
                return Promise.reject('Дата не может быть пустой');
              }
              return Promise.resolve();
            },
          }),
        ],
      },

      recallTime: {
        key: 'RECALL_TIME',
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === StatusOptions.recall.value
                && (!value || value.length === 0)
              ) {
                return Promise.reject('Время не может быть пустым');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
  },
  visitToDc: {
    value: CallStatusesCode.VisitToDc,
    label: 'Предложение интересно, клиент приедет в ДЦ',
  },
  notOwner: {
    value: CallStatusesCode.NotOwner,
    label: 'Перезвонить другому человеку',
    children: {
      newName: {
        key: CallStatusDescriptionsCode.AnotherName,
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === StatusOptions.notOwner.value
                && (!value || value.length === 0)
              ) {
                return Promise.reject('Имя не может быть пустым');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      newPhone: {
        key: CallStatusDescriptionsCode.AnotherPhone,
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === StatusOptions.notOwner.value
                && (!value || value.length === 0)
              ) {
                return Promise.reject('Номер телефона не может быть пустым');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      callLater: {
        key: 'RECALL_LATER',
        label: 'Позвонить позже',
      },
      callDate: {
        key: 'RECALL_DATE',
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue('CALL_LATER');
              if (
                result
                && (!value || value.format('DD.MM.YYYY').length === 0)
              ) {
                return Promise.reject('Дата не может быть пустой');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      callTime: {
        key: 'RECALL_TIME',
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue('RECALL_LATER');
              if (
                result
                && (!value || value.length === 0)
              ) {
                return Promise.reject('Время не может быть пустым');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
  },
  notClient: {
    value: CallStatusesCode.NotClient,
    label: 'Ответил не клиент',
  },
  notInterested: {
    key: CallStatusesCode.NotInterested,
    value: CallStatusesCode.NotInterested,
    label: 'Предложение неинтересно',
    children: {
      cause: {
        key: CallStatusDescriptionsCode.Reason,
        apiKey: CallStatusDescriptionsCode.Reason,
        label: 'Причина',
        placeholder: 'Выберите причину',
        options: causeOptions,
        children: {
          why: {
            key: CallStatusDescriptionsCode.Why,
            apiKey: CallStatusDescriptionsCode.Why,
            label: 'Почему?',
            options: whyOptions,
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue(CallStatusDescriptionsCode.Reason);
                  if (
                    result
                    && (!value || value.length === 0)
                  ) {
                    return Promise.reject('Выберите причину из списка');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          model: {
            key: CallStatusDescriptionsCode.Model,
            apiKey: CallStatusDescriptionsCode.Model,
            label: 'Модель автомобиля',
            placeholder: 'Выберите модель',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue(CallStatusDescriptionsCode.Reason);
                  if (
                    result
                    && (!value || value.length === 0)
                  ) {
                    return Promise.reject('Выберите модель из списка');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          date: {
            key: CallStatusDescriptionsCode.Date,
            apiKey: CallStatusDescriptionsCode.Date,
            label: 'Дата',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue(CallStatusDescriptionsCode.Reason);
                  if (
                    result
                    && (!value || value.toISOString().length === 0)
                  ) {
                    return Promise.reject('Выберите дату');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        },
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === CallStatusesCode.NotInterested
                && (!value || value.length === 0)
              ) {
                return Promise.reject('Выберите причину из списка');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
  },
  notCall: {
    value: CallStatusesCode.NotCall,
    label: 'Негатив клиента, не звонить',
  },

  negativeRecall: {
    value: CallStatusesCode.NegativeRecall,
    label: 'Перезвонить',
    children: {
      recallDate: {
        key: 'RECALL_DATE',
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === CallStatusesCode.NegativeRecall
                && (!value || value.toISOString().length === 0)
              ) {
                return Promise.reject('Дата не может быть пустой');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      recallTime: {
        key: 'RECALL_TIME',
        rules: [
          (formInstance) => ({
            validator(rule, value) {
              const result = formInstance.getFieldValue(MainFieldsCodes.Status);
              if (
                result === CallStatusesCode.NegativeRecall
                && (!value || value.length === 0)
              ) {
                return Promise.reject('Время не может быть пустым');
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
  },
  notRecall: {
    value: CallStatusesCode.NotRecall,
    label: 'Не перезванивать',
  },
};

export const KEYS = {
  [MainFieldsCodes.Date]: {
    key: MainFieldsCodes.Date,
    label: 'Дата',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.format('DD.MM.YYYY').length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Дата начала не может быть пустой');
        },
      }),
    ],
  },

  [MainFieldsCodes.Time]: {
    key: MainFieldsCodes.Time,
    label: 'Время',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Дата начала не может быть пустой');
        },
      }),
    ],
  },

  [MainFieldsCodes.Status]: {
    key: MainFieldsCodes.Status,
    apiKey: 'status',
    options: StatusOptions,
    rules: [
      () => ({
        validator(rule, value) {
          if (resultValues.has(value)) {
            return Promise.resolve();
          }
          return Promise.reject('Выберите результат обзвона из предложенных вариантов');
        },
      }),
    ],
  },

  [MainFieldsCodes.Comment]: {
    key: MainFieldsCodes.Comment,
    apiKey: 'text',
    placeholder: 'Введите текст',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Комментарий не может быть пустым');
        },
      }),
    ],
  },
};
