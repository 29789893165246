import { getToken } from 'helpers/utility';
import { API_URL as API, API_BUTLER_URL } from 'settings/api';
import { v1 as getId } from 'uuid';

export const signInWithEmail = ({ username, password }) => {
  const URI = `${API}${process.env.REACT_APP_LOGIN_METHOD}`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        login: username,
        password,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const logout = ({ accessToken }) => {
  const URI = `${API}/security/auth/logout`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken,
      data: {
        token: accessToken,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const signInWithActiveDirectory = () => {
  const URI = `${API_BUTLER_URL}/security/auth/wa`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({}),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const refreshToken = ({ token }) => {
  const URI = `${API}/token/refresh`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        refreshToken: token,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const register = ({ token, password }) => {
  const URI = `${API}/security/restore-password/or/register/finish`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        token,
        password,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const restorePassword = ({ token, password }) => {
  const URI = `${API}/security/restore-password/or/register/finish`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        token,
        password,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  signInWithEmail,
  signInWithActiveDirectory,
  refreshToken,
  logout,
  register,
  restorePassword,
};
