import { API_TCM_URL as API } from 'settings/api';
import { getToken } from 'helpers/utility';

export const getPeriodReport = ({
  startAt,
  finishAt,
}) => {
  const URI = `${API}/tcm/report/period`;
  const data = {
    startAt,
    finishAt,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};
