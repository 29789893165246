import React, { useEffect, useReducer, useState } from 'react';
import Button from 'components/Button';
import API from 'services';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router';

import { checkPrivilege } from 'helpers/roles';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import OrganisationField from './OrganisationField';
import Form from '../Form';

import { Header, OrganisationLink, Root } from './style';

const initialState = {
  inn: null,
  isRequestPending: false,
  isRequestSucceeded: false,
  error: null,
  company: {
    id: null,
    name: '',
  },
  displayForm: false,
};

const ACTIONS = {
  RESET: 'RESET',
  CHECK: 'CHECK',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAILURE: 'CHECK_FAILURE',
  CHECK_SUCCEED: 'CHECK_SUCCEED',
  RENDER_FORM: 'RENDER_FORM',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.RESET: {
      return {
        ...initialState,
      };
    }
    case ACTIONS.CHECK: {
      return {
        ...state,
        inn: action.inn,
        isRequestPending: true,
      };
    }
    case ACTIONS.CHECK_SUCCESS: {
      return {
        ...state,
        isRequestPending: false,
        isRequestSucceeded: true,
        error: null,
        company: {
          id: action.id,
          name: action.name,
        },
        displayForm: false,
      };
    }
    case ACTIONS.CHECK_FAILURE: {
      return {
        ...state,
        isRequestPending: false,
        isRequestSucceeded: false,
        error: action.error,
        company: {
          id: null,
          name: '',
        },
        displayForm: false,
      };
    }
    case ACTIONS.CHECK_SUCCEED: {
      return {
        ...state,
        isRequestPending: false,
        isRequestSucceeded: true,
        error: null,
        company: {
          id: null,
          name: '',
        },
        displayForm: true,
      };
    }
    case ACTIONS.RENDER_FORM: {
      return {
        ...state,
        displayForm: true,
      };
    }
    default: {
      return state;
    }
  }
}

function Company({
  // connect
  role,

  // actions
  setBreadcrumbs,
}) {
  const location = useLocation();
  const { inn } = queryString.parse(location.search);
  const [organisation, setOrganisation] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isMounted, setIsMounted] = useState(false);
  const canCreateCompanyWithLiquidatedStatus = checkPrivilege([
    role.ORGANISATIONS.CREATE_WITH_LIQUIDATED_STATUS,
  ]);
  const canCreateCompanyByHand = checkPrivilege([
    role.ORGANISATIONS.CREATE_FALLBACK,
  ]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Организации', link: '/app/management/companies/list' },
        {
          level: 2,
          name: 'Добавить новую организацию',
          link: '/app/management/companies/new',
        },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  function checkOrganisation() {
    dispatch({ type: ACTIONS.CHECK, inn: organisation.inn });

    API.company
      .fetchList({
        filters: {
          inn: organisation.inn || '',
        },
      })
      .then((results) => {
        if (
          results
          && (
            (
              Array.isArray(results.data)
              && results.data.length === 0
            ) || (
              results.data
              && Array.isArray(results.data.legalEntityResponseList)
              && results.data.legalEntityResponseList.length === 0
            )
          )
        ) {
          dispatch({ type: ACTIONS.CHECK_SUCCEED });
        } else if (
          results.data
          && Array.isArray(results.data.legalEntityResponseList)
          && results.data.legalEntityResponseList.length > 0
        ) {
          const company = results.data.legalEntityResponseList[0];
          if (company.inn && company.name) {
            dispatch({ type: ACTIONS.CHECK_SUCCESS, id: company.id, name: company.name });
          } else {
            throw new Error('Что-то пошло не так');
          }
        } else {
          throw new Error('Что-то пошло не так');
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.CHECK_FAILURE, error: error.message });
      });
  }

  function createOrganisation() {
    dispatch({ type: ACTIONS.RENDER_FORM });
  }

  function handleOnChange(e) {
    setOrganisation({
      name: e.value,
      inn: e.additionalValues?.inn || '',
      additionalValues: e.additionalValues,
    });

    if (state.inn !== e.additionalValues?.inn) {
      dispatch({ type: ACTIONS.RESET });
    } else {
      dispatch({ type: ACTIONS.RENDER_FORM });
    }
  }

  return (
    <Root>
      <Header>
        <OrganisationField
          name="search"
          onChange={handleOnChange}
          allowDisabledOptions={canCreateCompanyWithLiquidatedStatus}
          value={inn}
          isUnique={false}
        />

        {state.error && (
          <span>{state.error}</span>
        )}
        {state.company.id && (
          <div>
            Организация с таким ИНН уже зарегистрирована -
            <OrganisationLink href={`${state.company.id}`}>{state.company.name}</OrganisationLink>
          </div>
        )}
        {(!canCreateCompanyByHand && !state.error && !state.company.id) ? (
          <Button
            htmlType="button"
            type="primary"
            size="mini"
            shape="square"
            onClick={checkOrganisation}
            disabled={!organisation.inn || state.inn === organisation.inn || state.isRequestSucceeded}
            loading={state.isRequestPending}
          >
            Проверить наличие организации
          </Button>
        ) : (!state.company.id && (
          <Button
            htmlType="button"
            type="primary"
            size="mini"
            shape="square"
            onClick={createOrganisation}
            disabled={state.displayForm}
          >
            Создать организацию
          </Button>
        ))}
      </Header>

      {state.displayForm && (
        <Form organisation={organisation} />
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    itemData: state.company.item.data,
    itemIsLoaded: state.company.item.isLoaded,
  }),
  {
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(Company);
