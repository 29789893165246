import React from 'react';
import {
  Route,
  Switch,
  useParams,
  Redirect,
} from 'react-router-dom';

import List from './List';
import User from './User';

function Users() {
  const { companyId } = useParams();
  return (
    <Switch>
      <Route exact path="/app/management/companies/:companyId/users">
        <Redirect to={`/app/management/companies/${companyId}/users/list`} />
      </Route>
      <Route path="/app/management/companies/:companyId/users/list" component={List} />
      <Route path="/app/management/companies/:companyId/users/new" component={User} />
      <Route path="/app/management/companies/:companyId/users/:userId" component={User} />
      <Route path="/app/management/companies/:companyId/users/:userId/edit" component={User} />
    </Switch>
  );
}
export default Users;
