import React from 'react';
import { Select as SelectAntD } from 'antd';

import { SelectStyled } from './style';

const defaultProps = {
  getPopupContainer: (trigger) => trigger.parentNode,
  hasDefault: true,
};

export const Select = React.forwardRef((props, ref) => (
  <SelectStyled {...defaultProps} {...props} ref={ref} />
));
export default Select;
export const { Option } = SelectAntD;
