import {
  PRODUCT__FIND_LOADING,
  PRODUCT__FIND_SUCCESS,
  PRODUCT__FIND_FAILURE,
} from './actions';

const initState = {
  params: {},
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export default function productReducer(state = initState, action) {
  switch (action.type) {
    case PRODUCT__FIND_LOADING: {
      return {
        params: {
          dealerCenterName: action.dealerCenterName,
          brand: action.brand,
          group: action.group,
          model: action.model,
        },
        list: [],
        isLoading: true,
        isLoaded: false,
        error: null,
      };
    }
    case PRODUCT__FIND_SUCCESS: {
      return {
        ...state,
        list: action.list,
        isLoading: false,
        isLoaded: true,
        error: null,
      };
    }
    case PRODUCT__FIND_FAILURE: {
      return {
        ...state,
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
}
