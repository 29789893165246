import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  Upload,
} from 'antd';

import Button from 'components/Button';

import { KEYS } from './data';

import {
  Root,
  FormStyled,
  Footer,
  ErrorMessage,
  DraggerContainer,
  ErrorsContainer,
  RedirectContainer,
  RedirectButtonsContainer,
  RedirectTitle,
  TextItem,
} from './style';

const {
  FILES,
} = KEYS;

const { Dragger } = Upload;

const fileMaxSizeMb = 25;

function AddFile({
  // passed
  close,
  visible,
  handleAddFile,
  isLoading,
  isLoaded,
  resetAddFile,
  error,
  sector,

  // store
  redirect,
  newCompany,
}) {
  const [form] = Form.useForm();
  const [isMounted, setIsMounted] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [sizeError, setSizeError] = useState('');
  const { id: redirectId, sector: redirectSector, inn } = redirect;
  const { inn: companyInn } = newCompany;

  const handleUpload = (file) => {
    const fileSizeMb = file.size * 0.000001;

    setSizeError('');

    if (fileSizeMb <= fileMaxSizeMb) {
      setFileList([file]);
    } else {
      setSizeError('Размер загружаемого файла не может превышать 25МБ');
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,

    beforeUpload: (file) => {
      handleUpload(file);
      return false;
    },

    onRemove: () => {
      setFileList([]);
      resetAddFile();
    },
  };

  function handleFinish() {
    if (fileList.length !== 0) {
      const reader = new FileReader();

      reader.onload = function load(event) {
        handleAddFile({
          file: event.target.result,
          ...(sector ? { sector } : {}),
        });
      };
      reader.readAsText(fileList[0]);
    }
  }

  function reset() {
    form.resetFields();
  }

  function cancel() {
    if (isLoaded) {
      reset();
    }
    setSizeError('');
    close();
  }

  useEffect(() => {
    if (visible) {
      setIsMounted(true);
    } else {
      setFileList([]);
      setSizeError('');
      form.resetFields();
      resetAddFile();
    }
  }, [visible, form, setIsMounted, resetAddFile]);

  useEffect(() => {
    if (isMounted && isLoaded) {
      setFileList([]);
      close();
      form.resetFields();
    }
  }, [isMounted, isLoaded, form, close]);

  return (
    <Root
      visible={visible}
      onCancel={cancel}
      footer={null}
      width={768}
    >
      <FormStyled
        name="AddRole"
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={handleFinish}
      >
        <DraggerContainer
          hide={fileList.length > 0}
        >
          <Form.Item
            name={FILES.key}
            label={FILES.title}
            rules={FILES.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Dragger {...props}>
              <p>Переместить сюда</p>
              <p>или</p>
              <Button>
                + Выберите файл
              </Button>
            </Dragger>
          </Form.Item>
        </DraggerContainer>

        {(sizeError || error) && !redirectId && !companyInn && (
          <ErrorsContainer>
            {sizeError && <ErrorMessage>{sizeError}</ErrorMessage>}
            {error && !isLoading && <ErrorMessage>{error}</ErrorMessage>}
          </ErrorsContainer>
        )}

        {redirectId && (
          <RedirectContainer>
            <RedirectTitle>
              <TextItem>
                Договор с таким ИНН
                {' '}
                {inn}
                {' '}
                и сектором
                {' '}
                {redirectSector}
                {' '}
                уже существует
              </TextItem>
              <TextItem>
                Хотите перейти на этот договор?
              </TextItem>
            </RedirectTitle>
            <RedirectButtonsContainer>
              <Button
                type="ghost"
                shape="square"
                size="small"
                onClick={() => close()}
              >
                Отмена
              </Button>
              <Button
                type="primary"
                shape="square"
                size="small"
                href={`/app/payments/contracts/${redirectId}`}
              >
                Перейти
              </Button>
            </RedirectButtonsContainer>
          </RedirectContainer>
        )}

        {companyInn && (
          <RedirectContainer>
            <RedirectTitle>
              <TextItem>
                В системе нет организации с ИНН
                {' '}
                {companyInn}
                , перед добавлением договора, необходимо зарегистрировать организацию
              </TextItem>
            </RedirectTitle>
            <RedirectButtonsContainer>
              <Button
                type="ghost"
                shape="square"
                size="small"
                onClick={() => close()}
              >
                Отмена
              </Button>
              <Button
                type="primary"
                shape="square"
                size="small"
                href={`/app/management/companies/new?inn=${companyInn}`}
              >
                Добавить организацию
              </Button>
            </RedirectButtonsContainer>
          </RedirectContainer>
        )}

        {!redirectId && !companyInn && (
          <Footer>
            <Button
              type="primary"
              shape="square"
              size="small"
              htmlType="submit"
              loading={isLoading}
              disabled={!(fileList.length > 0 || redirectId)}
            >
              Загрузить
            </Button>
          </Footer>
        )}

      </FormStyled>
    </Root>
  );
}

export default connect(
  (state) => ({
    redirect: state.contracts.redirect,
    newCompany: state.contracts.newCompany,
  }),
  null,
)(AddFile);
