export const formatWithComments = ({
  commentItemList = [],
  creditRequestWithCommentList = [],
}) => {
  if (Array.isArray(commentItemList) && Array.isArray(creditRequestWithCommentList)) {
    const commentsMap = new Map();
    commentItemList.forEach((item) => commentsMap.set(item.id, item));
    return creditRequestWithCommentList.reduce((prev, curr) => [
      ...prev,
      {
        ...curr,
        comments:
            Array.isArray(curr.commentItemList)
            && curr.commentItemList.map((item) => commentsMap.get(item.id)),
      }],
    []);
  }
  return [];
};
