import React from 'react';
import { Table } from 'antd';

import {
  Title,
  Grid,
  GridRow,
  GridItem,
  Root,
} from '../style';

const scroll = { y: 'calc(100vh - 460px)' };

function ApprovedInformationExtra({
  // passed
  data,
}) {
  const {
    approvedAdditionalConditionList,
    approvedServiceList,
    decisionList,
  } = data;
  const decision = (Array.isArray(decisionList) && decisionList[0]) || {};
  const approvedServices = (Array.isArray(approvedServiceList) && approvedServiceList) || [];
  const approvedAdditionalCondition = (Array.isArray(approvedAdditionalConditionList)
    && approvedAdditionalConditionList) || [];
  const columnsApprovedServices = [
    {
      key: 'name',
      title: 'Наименование доп.сервиса',
      render: ({ name }) => (
        <span>
          {name}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'amount',
      checkRights: () => true,
      title: 'Стоимость доп.сервиса, включенная в кредит',
      render: ({ amount }) => (
        <span>
          {amount}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'isIncluded',
      title: 'Включение в сумму кредита (Решение Банка)',
      render: ({ isIncluded }) => (
        <span>
          {isIncluded ? 'Да' : 'Нет'}
        </span>
      ),
      width: 100,
      align: 'center',
    },
  ];
  const columnsAdditionalCondition = [
    {
      key: 'confirmed',
      title: 'Выполнено',
      render: ({ confirmed }) => (
        <span>
          {confirmed ? 'Да' : 'Нет'}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'name',
      title: 'Наименование доп.условия',
      render: ({ name }) => (
        <span>
          {name}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'details',
      title: 'Детали',
      render: ({ details }) => (
        <span>
          {details}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'comment',
      title: 'Комментарий по дополнительному условию',
      render: ({ comment }) => (
        <span>
          {comment}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'bankCommentConfirmed',
      title: 'Комментарий банка по выполнению',
      render: ({ bankCommentConfirmed }) => (
        <span>
          {bankCommentConfirmed}
        </span>
      ),
      width: 100,
      align: 'center',
    },
  ];

  return (
    <Root>
      <Title>Одобрено с дополнительными условиями</Title>
      <Grid>
        <GridRow>

          <GridItem label>
            Продукт:
          </GridItem>
          <GridItem>
            {decision?.productName}
          </GridItem>
        </GridRow>
        <GridRow borderNone />
        <GridRow>
          <GridItem label>
            Марка:
          </GridItem>
          <GridItem>
            {decision?.carBrand}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Модель:
          </GridItem>
          <GridItem>
            {decision?.carModel}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Стоимость автомобиля в валюте, в руб.:
          </GridItem>
          <GridItem>
            {decision?.carPrice}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Год выпуска автомобиля:
          </GridItem>
          <GridItem>
            {decision?.carYear}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Первоначальный взнос:
          </GridItem>
          <GridItem>
            {decision?.downPayment}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Валюта первоначального взноса:
          </GridItem>
          <GridItem>
            {decision?.loanCurrency}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Первоначальный взнос, %:
          </GridItem>
          <GridItem>
            {decision?.downPaymentPercent}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            LTV, %:
          </GridItem>
          <GridItem>
            {decision?.ltv}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Сумма кредита:
          </GridItem>
          <GridItem>
            {decision?.loanAmount}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Валюта кредита:
          </GridItem>
          <GridItem>
            {decision?.loanCurrency}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Срок:
          </GridItem>
          <GridItem>
            {decision?.loanTerm}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Ставка:
          </GridItem>
          <GridItem>
            {decision?.interestRate}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Ежемесячный платеж:
          </GridItem>
          <GridItem>
            {decision?.monthlyPayment}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Остаточный платеж:
          </GridItem>
          <GridItem>
            {decision?.restPayment || 'Нет данных'}
          </GridItem>
        </GridRow>
      </Grid>

      {approvedServices.length > 0 && (
        <>
          <Title>Информация по доп.сервисам</Title>
          <Table
            rowKey={({ id }) => id}
            columns={columnsApprovedServices}
            dataSource={approvedServices}
            bordered
            scroll={scroll}
            pagination={false}
          />
        </>
      )}

      {approvedAdditionalCondition.length > 0 && (
        <>
          <Title>Информация по доп.условиям</Title>
          <Table
            rowKey={({ id }) => id}
            columns={columnsAdditionalCondition}
            dataSource={approvedAdditionalCondition}
            bordered
            scroll={scroll}
            pagination={false}
          />
        </>
      )}
    </Root>
  );
}

export default ApprovedInformationExtra;
