import React from 'react';
import moment from 'moment';
import { convertResponseDateToMoment } from 'helpers/date';
import { separateNumberByRank } from 'helpers/numbers';
import { CHAR } from 'settings/namedUnicode';
import { InfoIcon, RublerIcon } from 'icons';
import {
  CallStatusDescriptionsCode,
  CallStatusesCode, EventStatusDescriptionsCode, EventStatusesCode, VisitStatusCode,
} from 'tcm/offer/data';
import { InfoIconContainer } from 'containers/App/TCM/ManagerClientsTable/style';

/**
 * @typedef StatusDescriptions
 * @type {object}
 * @property {EventStatusDescriptionsCode} key
 * @property {string} value
 */

/**
 * @typedef Employee
 * @type {object}
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 */

/**
 * @typedef CallResult
 * @type {object}
 * @property {string} date - date in ISO format.
 * @property {EventStatusesCode} status - Call statuses.
 * @property {EventStatusDescriptionsCode[]} statusDescriptions - Array of status subfields.
 * @property {string} text - text of comment.
 * @property {Employee} employee
 */

/**
 *  @param {EventStatusesCode} status
 *  @return {string}
 */
const getTextFromEventStatus = (status) => {
  switch (status) {
    case EventStatusesCode.Recall:
      return 'Предложение интересно, перезвонить позднее.';
    case EventStatusesCode.VisitToDc:
      return 'Предложение интересно, клиент приедет в ДЦ.';
    case EventStatusesCode.NotOwner:
      return 'Перезвонить другому человеку.';
    case EventStatusesCode.NotClient:
      return 'Ответил не клиент.';
    case EventStatusesCode.NotInterested:
      return 'Предложение неинтересно.';
    case EventStatusesCode.NotCall:
      return 'Негатив клиента, не звонить.';
    case EventStatusesCode.NegativeRecall:
      return 'Не дозвонился. Перезвонить позже.';
    case EventStatusesCode.NotRecall:
      return 'Не перезванивать.';
    case EventStatusesCode.TradeIn:
      return 'Оценка Trade-in.';
    case EventStatusesCode.TestDrive:
      return 'Тест-драйв.';
    case EventStatusesCode.Registration:
      return 'Подача заявки.';
    case EventStatusesCode.ContractConcluded:
      return 'Оформление кредитного договора.';
    default:
      console.error('getMessageFromResult: status code is not in StatusesCode');
      return '';
  }
};

/**
 * @param {string} date
 * @return {string}
 */
export const getRecallDateText = (date) => {
  if (
    convertResponseDateToMoment(date)
      .startOf('day')
      .isSame(moment()
        .startOf('day'))
  ) {
    return `сегодня в ${moment(date)
      .format('HH:mm')}`;
  }
  if (
    convertResponseDateToMoment(date)
      .startOf('day')
      .isSame(moment()
        .startOf('day')
        .add(1, 'd'))
  ) {
    return `завтра в ${moment(date)
      .format('HH:mm')}`;
  }
  return `${convertResponseDateToMoment(date).format('DD.MM.YYYY')} 
  в ${convertResponseDateToMoment(date).format('HH:mm')}`;
};

/**
 * @param {{key: EventStatusDescriptionsCode, value: string | number}} option
 * @return {string | JSX.Element}
 */
export const getContentFromDescription = (option) => {
  switch (option.key) {
    case EventStatusDescriptionsCode.CallbackDate:
      return `Перезвонить ${getRecallDateText(option.value)}`;
    case EventStatusDescriptionsCode.AnotherName:
      return `Имя: ${option.value}.`;
    case EventStatusDescriptionsCode.AnotherPhone:
      return `Новый номер: ${option.value}.`;
    case EventStatusDescriptionsCode.Reason:
      return `Причина: ${option.value}.`;
    case EventStatusDescriptionsCode.Why:
      return `Почему: ${option.value}.`;
    case EventStatusDescriptionsCode.Model:
      return `Модель: ${option.value}.`;
    case EventStatusDescriptionsCode.Date:
      return `Перезвонить ${moment(option.value)
        .format('DD.MM.YYYY')}.`;

    case EventStatusDescriptionsCode.Sum:
      return (
        <span>
          Оценка Trade-in:
          {CHAR.SP}
          {separateNumberByRank(option.value)}
          {CHAR.NBSP}
          <RublerIcon />
        </span>
      );
    case EventStatusDescriptionsCode.TestDriveBrand:
      return `Тест драйв: ${option.value} `;
    case EventStatusDescriptionsCode.TestDriveModel:
      return `${option.value}.`;
    case EventStatusDescriptionsCode.RegistrationBank:
      return `Подача заявки: ${option.value}${option.value === 'Тойота Банк' ? ',' : '.'}`;
    case EventStatusDescriptionsCode.RegistrationBrand:
      return ` ${option.value}`;
    case EventStatusDescriptionsCode.RegistrationModel:
      return ` ${option.value}.`;
    case EventStatusDescriptionsCode.ContractConcludedBank:
      return `Оформление кредитного договора: ${option.value}${option.value !== 'Другой банк' && '.'}`;
    case EventStatusDescriptionsCode.ContractConcludedReason:
      return ` ${option.value}`;

    default:
      console.error('descriptionText: descriptions code not in EventStatusDescriptionsCode');
      return '';
  }
};

/**
 * @param {EventStatusesCode} status
 * @param {StatusDescriptions[]} descriptions
 * @return {string}
 */
export const getMessageFromResult = (status, descriptions) => {
  const mainText = getTextFromEventStatus(status);

  const descriptionText = descriptions
    .map((status, index) => {
      const value = getContentFromDescription(status);
      return typeof value === 'string' && index !== descriptions.length - 1
        ? `${value} `
        : value;
    });

  return [mainText, ...descriptionText].join(' ');
};

/**
 *
 * @param {VisitStatusCode[]} statuses
 */
const generateVisitResultText = (statuses) => {
  const description = statuses.map(
    (status) => {
      const text = getTextFromEventStatus(status).slice(0, -1);
      if (!text) {
        return text;
      }
      return text[0].toLowerCase() + text.slice(1);
    },
  ).join(', ');

  return `Визит: ${description}.`;
};

const generateRecallText = (status, descriptions) => {
  const statusText = getTextFromEventStatus(status);
  const date = descriptions.find((description) =>
    description.key === CallStatusDescriptionsCode.CallbackDate)?.value;

  if (date) {
    const today = moment();
    return (
      <>
        {`Перезвонить ${getRecallDateText(date)}`}
        { today.isAfter(date) && (
          <InfoIconContainer>
            <InfoIcon />
          </InfoIconContainer>
        )}
      </>
    );
  }
  return statusText;
};

export const getEventText = (event) => {
  const isVisit = Object.values(VisitStatusCode).includes(event.status);
  const isRecallWithDate = [
    CallStatusesCode.Recall,
    CallStatusesCode.NegativeRecall,
    CallStatusesCode.NotOwner,
  ].includes(event.status);

  if (isVisit) {
    return generateVisitResultText(event.statuses);
  }
  if (isRecallWithDate) {
    return generateRecallText(event.status, event.statusDescriptions);
  }
  return getTextFromEventStatus(event.status);
};
