export const formFields = {
  dateFrom: {
    key: 'dateFrom',
    apiKey: 'dateFrom',
    label: 'Дата действия кампании от',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.isValid) {
            return Promise.resolve();
          }
          return Promise.reject('Дата не может быть пустой');
        },
      }),
    ],
  },
  dateTo: {
    key: 'dateTo',
    apiKey: 'dateTo',
    label: 'до',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.isValid) {
            return Promise.resolve();
          }
          return Promise.reject('Дата не может быть пустой');
        },
      }),
    ],
  },
  dateRange: {
    key: 'dateRange',
    apiKey: 'dateRange',
  },
  employees: {
    key: 'employees',
    apiKey: 'employees',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Выберите хотя бы однго сотрудника');
        },
      }),
    ],
  },
  campaigns: {
    key: 'campaigns',
    apiKey: 'campaigns',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Выберите хотя бы одну кампанию');
        },
      }),
    ],
  },
};

export const DateRangePresets = {
  allTime: {
    value: 'allTime',
    label: 'Всё время',
  },
  year: {
    value: 'year',
    label: 'Год',
  },
  halfAYear: {
    value: 'halfAYear',
    label: 'Пол года',
  },
  quarter: {
    value: 'quarter',
    label: 'Квартал',
  },
  month: {
    value: 'month',
    label: 'Месяц',
  },
};
