import styled from 'styled-components';

import Loader from 'components/Loader';
import {
  Form,
  Modal,
  Radio,
  Tag,
} from 'antd';

export const Root = styled(Modal)`
  & .ant-modal-content {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  & .ant-modal-header {
    padding: 2rem;
  }

  & .ant-modal-body {
    padding: 1rem 2rem 2rem;
  }

  & .ant-form-item {
    margin-bottom: 14px;
  }
  
 .ant-radio-wrapper span{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #535353;
 }
  
  label{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 24px !important;
  }
`;

export const FormStyled = styled(Form)``;

export const EmailTag = styled(Tag)`
    margin-bottom: 8px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #000;
  text-align: left;
  margin: 0 0 40px;
`;
export const SubTitle = styled.h2`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #7E7E7E;
  text-align: left;
  margin: 0 0 20px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  
  button{
    max-width: 200px;
  }

`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const SuccessMessage = styled.p`
  color: green;
`;

export const LoadingMessage = styled.div`
  color: #000;
  
  svg{
    color: red;
    font-size: 18px;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  display: grid;
  grid-template-columns: repeat(3, 100px);
  column-gap: 30px;
  margin-bottom: 40px;
`;

export const InputContainer = styled.div`
  max-width: 540px;
  margin-bottom: 30px;
  
  input{
    height: 58px;
  }
`;

export const WrongEmail = styled.p`
    font-family: Open Sans;
    color: red;
    font-size: 15px;
`;

export const InputLabel = styled.h2`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #7E7E7E;
  text-align: left;
  margin-bottom: 5px;
`;

export const LoadingLoader = styled(Loader)`

`;

export const ButtonsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, max-content);
  justify-content: flex-end;
  grid-gap: 15px;
  margin-bottom: 10px;
`;
