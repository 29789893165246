import {
  all, call, fork, put, takeLatest,
} from 'redux-saga/effects';
import API from 'services';
import { extractErrorAndCode, formatOffer } from 'hacks';
import {
  CREDIT_CALCULATOR_RE_CALC,
  CREDIT_CALCULATOR_RE_CALC__FAILURE,
  CREDIT_CALCULATOR_RE_CALC__SUCCESS,
  CREDIT_CALCULATOR_SAVE,
  CREDIT_CALCULATOR_SAVE__FAILURE,
  CREDIT_CALCULATOR_SAVE__SUCCESS,
} from 'redux/tcmCreditCalculator/actions';
import { ON_ERROR } from 'redux/auth/actions';
import { UserRole } from 'hacks/formatOffer';
import { OFFER_FETCH__SUCCESS } from 'redux/tcmOffer/actions';

export function* reCalc() {
  yield takeLatest(CREDIT_CALCULATOR_RE_CALC, function* ({
    data,
  }) {
    try {
      const {
        oldData,
        newData,
        dealerCenter,
      } = data;
      const response = yield call(API.TCM.creditCalculator.reCalc, {
        oldData,
        newData,
        dealerCenter,
      });

      if (
        response
        && response.data
        && response.data.creditData
      ) {
        yield put({
          type: CREDIT_CALCULATOR_RE_CALC__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT_CALCULATOR_RE_CALC__FAILURE, error: error.message });
    }
  });
}

export function* saveResult() {
  yield takeLatest(CREDIT_CALCULATOR_SAVE, function* ({
    data,
  }) {
    try {
      const response = yield call(API.TCM.creditCalculator.saveResult, {
        data,
      });

      if (
        response?.data?.externalId
      ) {
        yield put({
          type: CREDIT_CALCULATOR_SAVE__SUCCESS,
        });
        yield put({
          type: OFFER_FETCH__SUCCESS,
          data: formatOffer(response.data, UserRole.Employee),
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT_CALCULATOR_SAVE__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(reCalc),
    fork(saveResult),
  ]);
}
