import styled from 'styled-components';
import Typography from 'components/Typography';
import { GRID_AREAS } from 'settings';

const { Paragraph } = Typography;

const ErrorMessage = styled(Paragraph)`
  ${({ area }) =>
    typeof area === 'boolean'
    && `
    grid-area: ${area ? GRID_AREAS.LEFT.ERROR : GRID_AREAS.RIGHT.ERROR};
  `}
`;

export { ErrorMessage };
