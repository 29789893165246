import React from 'react';
import { connect } from 'react-redux';
import { FIXBLOCK_TYPES as TYPES } from 'settings';
import { patch as patchRequest } from 'redux/request/actions';
import Checkbox from './Checkbox';

import { Root } from './style';

function FileConfirmation({
  // connect
  requestTree,
  requestIsLoaded,
  disabled,

  // actions
  patchRequest,
}) {
  const fixblock = requestTree.find((e) => e.type === 'fixblock');
  const fixBlockFooter = requestIsLoaded
    ? fixblock.data.find((e) => e.name === 'zeroFormBlockFooter')
    : { data: [] };
  const checkboxes = requestIsLoaded
    ? fixBlockFooter.data.filter((e) => e.type === TYPES.CHECKBOX)
    : [];

  function handleOnChange(userInput) {
    patchRequest({ userInput });
  }

  return (
    <Root>
      {checkboxes.map((checkbox) => (
        <Checkbox
          key={checkbox.name}
          name={checkbox.name}
          initialValue={checkbox.value === true}
          onChange={handleOnChange}
          label={checkbox.label}
          isRequired={checkbox.isRequired}
          error={checkbox.error}
          disabled={disabled || checkbox.disabled}
        />
      ))}
    </Root>
  );
}

export default connect(
  (state) => ({
    requestTree: state.request.fieldsFlat,
    requestIsLoaded: state.request.fetch.isLoaded,
    disabled: state.request.formIsDisabled,
  }),
  {
    patchRequest,
  },
)(FileConfirmation);
