import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'components/Select';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { defineDependencies, flattenDependencies, mergeArrays } from 'hacks';
import { SCROLL_DURATION } from 'settings';
import { find as findProduct } from 'redux/product/actions';
import { useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';

function Product({
  name,
  type,
  label,
  placeholder,
  area,
  dependencies,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  values,
  form,
  form: {
    getFieldDecorator, getFieldValue, setFieldsValue, resetFields,
  },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,

  // connect
  params,
  list,
  isLoading,
  isLoaded,
  error,

  // actions
  findProduct,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();
  const flatDependencies = flattenDependencies(dependencies);
  const { id } = useParams();
  const {
    dealerCenter: dealerCenterName,
    group,
    brand,
    model,
  } = defineDependencies(
    flatDependencies,
    form.getFieldsValue(Object.values(flatDependencies)),
  );

  function handleOnSelect() {
    const value = getFieldValue(name);
    const dependenciesToValues = defineDependencies(
      flatDependencies,
      form.getFieldsValue(Object.values(flatDependencies)),
    );

    if (
      !(
        values[name] === value
        && Object.entries(flatDependencies).every(
          ([k, v]) => values[v] === dependenciesToValues[k],
        )
      )
    ) {
      const dependenciesUpdate = Object.entries(flatDependencies).reduce(
        (p, [k, v]) => ({ ...p, [v]: dependenciesToValues[k] }),
        {},
      );

      const mutatedForm = {
        [name]: value,
        ...dependenciesUpdate,
      };

      console.warn('mutatedForm', mutatedForm);
      onChangeField(mutatedForm, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  useEffect(() => {
    if (
      params.dealerCenterName !== dealerCenterName
      || params.group !== group
      || params.brand !== brand
      || params.model !== model
    ) {
      findProduct({
        dealerCenterName,
        group,
        brand,
        model,
        accessTokenCreditRequest: id,
      });
      resetFields([name]);
    }
  }, [params, dealerCenterName, group, brand, model, name, id, findProduct, resetFields]);

  useEffect(() => {
    if (isLoaded) {
      setFieldsValue({
        [name]: (list.find((e) => e.value === initialValue) || {}).value || '',
      });
    }
  }, [isLoaded, list, initialValue, setFieldsValue, name]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue:
          (list.find((e) => e.value === initialValue) || {}).value || initialValue || '',
      })(
        <Select
          name={name}
          placeholder={placeholder}
          area={area}
          onSelect={handleOnSelect}
          disabled={disabled}
          loading={isLoading}
          notFoundContent={
            error ? (
              <span>{error}</span>
            ) : isLoading ? (
              <LoadingOutlined />
            ) : (
              <span>нет данных</span>
            )
          }
          ref={focusableNode}
          size={size}
          list={list}
        />,
      )}
      <Warning text={warning} area={area} />
    </>
  );
}

export default connect(
  (state) => ({
    params: state.product.params,
    list: state.product.list,
    isLoading: state.product.isLoading,
    isLoaded: state.product.isLoaded,
    error: state.product.error,
  }),
  {
    findProduct,
  },
)(Product);
