import API from 'services';
import { eventChannel } from 'redux-saga';
import { IS_ACTIVE_DIRECTORY } from 'settings/api';
import { push } from 'react-router-redux';

import {
  all, takeEvery, takeLatest, put, fork, call, select,
} from 'redux-saga/effects';

import {
  saveLocalStorageToken,
  removeLocalStorageToken,
} from 'helpers/storage';

import {
  getToken, getTokens, setTokens, removeTokens,
  getUser, setUser, removeUser,
  getPermissionList, setPermissionList,
  removePermissionList, getUserId, getTCMManger, getTCMUser, removeTCMInfo,
} from 'helpers/utility';

import {
  CHECK_AUTHORIZATION,
  WRITE_AUTH_DATE_FROM_CACHE,
  ON_ERROR,
  LOGOUT,
  SIGN_IN_WITH_EMAIL,
  SIGN_IN_WITH_EMAIL__SUCCESS,
  SIGN_IN_WITH_EMAIL__FAILURE,

  REFETCH_PERMISSIONS,
  REFETCH_PERMISSIONS__SUCCESS,
  REFETCH_PERMISSIONS__FAILURE,
} from './actions';

const getState = (state) => state.auth;

// 10 minutes
const REFRESH_RATE = 10 * 60 * 1000;

const refreshTokenTimer = (timeout) =>
  eventChannel((emitter) => {
    let i = 0;
    const iv = setInterval(() => emitter(++i), timeout);
    return () => clearInterval(iv);
  });

const getAllPermissions = (roles = []) => {
  const uniquePermissions = new Map();
  roles.forEach((roleList) => {
    if (roleList.role && roleList.role.permissionList) {
      roleList.role.permissionList.forEach((permission) => {
        if (!uniquePermissions.has(permission.alias)) {
          uniquePermissions.set(permission.alias, permission);
        }
      });
    }
  });
  return Array.from(uniquePermissions.values());
};

function* _signIn({ response, rememberMe = false }) {
  if (!response.data?.skip) {
    if (
      response.data
        && Array.isArray(response.data.authResponseList)
        && response.data.authResponseList[0].isLoggedIn
    ) {
      const {
        data: {
          authResponseList,
        },
      } = response;

      const [user] = authResponseList;
      const {
        expiredAt,
        isNeedChangedSoon,
      } = Array.isArray(response.data.passwordList) ? response.data.passwordList[0] : {};

      const {
        accessToken,
        refreshToken,
        userId,
        roles,
      } = user;

      if (Array.isArray(roles) && roles.length > 0) {
        const roleNames = roles.map((item) => item.role.name).sort().join(', ');
        if (roleNames) {
          saveLocalStorageToken('ROLE_NAME', roleNames);
        } else {
          removeLocalStorageToken('ROLE_NAME');
        }
      }

      let tcmMangerInfo = {};
      let isTCMUser = false;

      if (userId) {
        const answer = yield call(API.user.fetchItem, { accessToken, userId });

        if (answer.data && answer.data.userList) {
          const { displayName, accessList } = answer.data.userList[0];
          const hasRoleTCM = accessList.find((item) => item?.role && (
            (item.role?.name === 'ТСМ Менеджер ДЦ') || (item.role?.name === 'ТСМ Сотрудник ДЦ')
          ));

          if (hasRoleTCM) {
            const TCM_MANAGER_ROLES = accessList.filter((item) => item?.role && item.role?.name === 'ТСМ Менеджер ДЦ') || [];
            saveLocalStorageToken('TCM_USER', true);
            isTCMUser = true;
            if (TCM_MANAGER_ROLES.length > 0) {
              const subsidiaryIds = TCM_MANAGER_ROLES.filter((item) => item.type === 'dealer_center').map((item) => item.entityId);
              const legalEntityIds = TCM_MANAGER_ROLES.filter((item) => item.type !== 'dealer_center').map((item) => item.entityId);
              tcmMangerInfo = {
                subsidiaryIds,
                legalEntityIds,
              };
              saveLocalStorageToken('TCM_MANAGER', JSON.stringify(tcmMangerInfo));
            } else {
              removeLocalStorageToken('TCM_MANAGER');
            }
          } else {
            removeLocalStorageToken('TCM_USER', false);
          }

          saveLocalStorageToken('DISPLAY_NAME', displayName);
          saveLocalStorageToken('USER_ID', userId);
        } else {
          removeLocalStorageToken('DISPLAY_NAME');
          removeLocalStorageToken('USER_ID');
        }
      }

      const permissionList = (
        response.data
          && Array.isArray(response.data.authResponseList)
          && response.data.authResponseList[0]
          && Array.isArray(response.data.authResponseList[0].roles)
          && response.data.authResponseList[0].roles[0]
          && response.data.authResponseList[0].roles[0].role
          && Array.isArray(response.data.authResponseList[0].roles[0].role.permissionList)
          ? getAllPermissions(response.data.authResponseList[0].roles)
          : []
      );

      yield put({
        type: SIGN_IN_WITH_EMAIL__SUCCESS,
        data: response.data,
        userId,
        token: accessToken,
        permissionList,
        expiredAt,
        isNeedChangedSoon,
        tcmMangerInfo,
        isTCMUser,
      });

      setPermissionList(permissionList);
      setTokens(rememberMe, accessToken, refreshToken);
      setUser(user);
    } else if (response.data && response.data.validationErrorList) {
      yield put({
        type: SIGN_IN_WITH_EMAIL__FAILURE,
        errors: response.data.validationErrorList,
      });
    } else {
      const error = response.errorMessage || `Ошибка: ${response.errorCode}`;
      yield put({ type: SIGN_IN_WITH_EMAIL__FAILURE, error });
    }
  }
}

export function* onError() {
  yield takeEvery(ON_ERROR, function* (action) {
    if (action.errorCode === 2002) { // token is expired
      if (IS_ACTIVE_DIRECTORY) {
        const response = yield call(API.auth.signInWithActiveDirectory);
        yield call(_signIn, { response, rememberMe: true });
      } else {
        yield put({ type: LOGOUT });
      }
    }
    if (action.errorCode === 401) { // unauthorized
      yield put({ type: LOGOUT });
    }
    if (action.errorCode === 403) { // access denied
      if (IS_ACTIVE_DIRECTORY) {
        const response = yield call(API.auth.signInWithActiveDirectory);
        yield call(_signIn, { response, rememberMe: true });
      } else {
        yield put({ type: REFETCH_PERMISSIONS });
      }
    }
  });
}

export function* logout() {
  yield takeEvery(LOGOUT, function* () {
    const accessToken = getToken();
    yield call(API.auth.logout, { accessToken });
    removeTokens();
    removePermissionList();
    removeUser();
    removeTCMInfo();
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(CHECK_AUTHORIZATION, function* () {
    const token = getToken();
    if (token) {
      yield put({
        type: WRITE_AUTH_DATE_FROM_CACHE,
        token,
        permissionList: getPermissionList(),
        userId: getUserId(),
        tcmMangerInfo: getTCMManger(),
        isTCMUser: getTCMUser(),
      });
    } else if (IS_ACTIVE_DIRECTORY) {
      const response = yield call(API.auth.signInWithActiveDirectory);
      yield call(_signIn, { response, rememberMe: true });
    }

    const channel = yield call(refreshTokenTimer, REFRESH_RATE);
    yield takeEvery(channel, function* () {
      const [, refreshToken] = getTokens();
      if (refreshToken) {
        const response = yield call(API.auth.refreshToken, { token: refreshToken });

        if (response.data && response.data.sessionList) {
          const {
            data: { sessionList },
          } = response;

          const {
            accessToken: accessTokenNew,
            refreshToken: refreshTokenNew,
          } = sessionList[0];

          setTokens(true, accessTokenNew, refreshTokenNew);
        }
      }
    });
  });
}

export function* signInWithEmail() {
  yield takeEvery(SIGN_IN_WITH_EMAIL, function* (action) {
    try {
      const response = yield call(API.auth.signInWithEmail, {
        username: action.username,
        password: action.password,
      });
      yield call(_signIn, { response, rememberMe: action.rememberMe });
    } catch (error) {
      yield put({ type: SIGN_IN_WITH_EMAIL__FAILURE, error: error.message });
    }
  });
}

function* refetchPermissions() {
  yield takeLatest(REFETCH_PERMISSIONS, function* () {
    const {
      refetch: {
        isLoading,
      },
    } = yield select(getState);

    if (!isLoading) {
      try {
        const user = getUser();

        if (user && user.userId) {
          const userRoles = yield call(API.user.fetchRoles, { userId: user.userId });
          if (
            userRoles
            && userRoles.data
            && Array.isArray(userRoles.data.accessItemResponseList)
          ) {
            const globalRoles = yield call(API.role.fetchList);

            if (
              globalRoles
              && globalRoles.data
              && globalRoles.data.roleList
              && Array.isArray(globalRoles.data.roleList)
              && Array.isArray(globalRoles.data.permissionList)
            ) {
              const roleIds = userRoles.data.accessItemResponseList.map(({ roleId }) => roleId);
              const uniquePermissions = new Set();

              roleIds.forEach((roleId) => {
                const role = globalRoles.data.roleList.find((e) => e.id === roleId);
                if (role && Array.isArray(role.permissionList)) {
                  role.permissionList.forEach(({ id }) => {
                    if (!uniquePermissions.has(id)) {
                      uniquePermissions.add(id);
                    }
                  });
                }
              });

              const permissionList = Array.from(uniquePermissions.values()).map((e) => {
                const permission = globalRoles.data.permissionList.find((perm) => perm.id === e);
                if (permission) {
                  return permission;
                }
                return null;
              }).filter(Boolean);

              if (permissionList.length > 0) {
                yield put({
                  type: REFETCH_PERMISSIONS__SUCCESS,
                  permissionList,
                  userId: user.userId,
                });
                setPermissionList(permissionList);
              } else {
                throw new Error('У пользователя нет никаких прав');
              }
            } else {
              throw new Error('Не удалось получить список прав');
            }
          } else {
            throw new Error('Не удалось получить список ролей пользователя');
          }
        } else {
          throw new Error('Пользователь не найден');
        }
      } catch (error) {
        yield put({ type: REFETCH_PERMISSIONS__FAILURE, error: error.message });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onError),
    fork(logout),
    fork(checkAuthorization),
    fork(signInWithEmail),
    fork(refetchPermissions),
  ]);
}
