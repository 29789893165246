import React, { useRef } from 'react';
import { Rifm } from 'rifm';
import { Form, Input } from 'antd';

import { cursorToLastPhoneValuePosition, formatPhone } from 'utils';
import { checkPrivilege } from 'helpers/roles';

import {
  FormItemsContainer,
  FormItemsRow,
  FormItemsTitle,
} from '../style';

import { KEYS as K } from '../data';

function ContactItems({
    // passed
  handleFocus,
  handleBlur,
  isEdit,
  isNew,
  role,
}) {
  const curatorPhoneField = useRef();
  const signatoryPhoneField = useRef();

  const canEdit = checkPrivilege([
    role.ACQUIRING_AGREEMENT.EDIT,
  ]);

  const isReadOnly = canEdit && (isEdit || isNew);

  return (
    <>
      <FormItemsContainer>
        <FormItemsTitle>
          Подписант
        </FormItemsTitle>
        <FormItemsRow items={2} width="536px">
          <Form.Item
            name={K.CONTACTS_SIGNATORY_NAME.key}
            label={K.CONTACTS_SIGNATORY_NAME.title}
            rules={K.CONTACTS_SIGNATORY_NAME.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_SIGNATORY_NAME.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.CONTACTS_SIGNATORY_PHONE.key}
            label={K.CONTACTS_SIGNATORY_PHONE.title}
            rules={K.CONTACTS_SIGNATORY_PHONE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm
              accept={/[\d]/g}
              mask
              replace={formatPhone.replace}
              format={formatPhone.format}
              onClick={() => cursorToLastPhoneValuePosition(signatoryPhoneField)}
            >
              {({ value, onChange }) => (
                <Input
                  name={K.CONTACTS_SIGNATORY_PHONE.key}
                  value={value}
                  onChange={onChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onClick={() => cursorToLastPhoneValuePosition(signatoryPhoneField)}
                  addonBefore={formatPhone.prefix}
                  ref={signatoryPhoneField}
                  disabled={!(isReadOnly)}
                />
              )}
            </Rifm>
          </Form.Item>
          <Form.Item
            name={K.CONTACTS_SIGNATORY_EMAIL.key}
            label={K.CONTACTS_SIGNATORY_EMAIL.title}
            rules={K.CONTACTS_SIGNATORY_EMAIL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_SIGNATORY_EMAIL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
      <FormItemsContainer>
        <FormItemsTitle>
          Куратор
        </FormItemsTitle>
        <FormItemsRow items={2} width="536px">
          <Form.Item
            name={K.CONTACTS_CURATOR_NAME.key}
            label={K.CONTACTS_CURATOR_NAME.title}
            rules={K.CONTACTS_CURATOR_NAME.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_CURATOR_NAME.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.CONTACTS_CURATOR_POSITION.key}
            label={K.CONTACTS_CURATOR_POSITION.title}
            rules={K.CONTACTS_CURATOR_POSITION.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_CURATOR_POSITION.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.CONTACTS_CURATOR_PHONE.key}
            label={K.CONTACTS_CURATOR_PHONE.title}
            rules={K.CONTACTS_CURATOR_PHONE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm
              accept={/[\d]/g}
              mask
              replace={formatPhone.replace}
              format={formatPhone.format}
              onClick={() => cursorToLastPhoneValuePosition(curatorPhoneField)}
            >
              {({ value, onChange }) => (
                <Input
                  name={K.CONTACTS_CURATOR_PHONE.key}
                  value={value}
                  onChange={onChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onClick={() => cursorToLastPhoneValuePosition(curatorPhoneField)}
                  addonBefore={formatPhone.prefix}
                  ref={curatorPhoneField}
                  disabled
                />
              )}
            </Rifm>
          </Form.Item>
          <Form.Item
            name={K.CONTACTS_CURATOR_EMAIL.key}
            label={K.CONTACTS_CURATOR_EMAIL.title}
            rules={K.CONTACTS_CURATOR_EMAIL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_CURATOR_EMAIL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
      <FormItemsContainer>
        <FormItemsTitle>
          Уведомления
        </FormItemsTitle>
        <FormItemsRow items={2} width="536px">
          <Form.Item
            name={K.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.key}
            label={K.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.title}
            rules={K.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow items={2} width="536px">
          <Form.Item
            name={K.CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL.key}
            label={K.CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL.title}
            rules={K.CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow items={2} width="536px">
          <Form.Item
            name={K.CONTACTS_NOTIFICATIONS_ADDRESS.key}
            label={K.CONTACTS_NOTIFICATIONS_ADDRESS.title}
            rules={K.CONTACTS_NOTIFICATIONS_ADDRESS.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CONTACTS_NOTIFICATIONS_ADDRESS.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
    </>
  );
}

export default ContactItems;
