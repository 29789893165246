import moment from 'moment';

export const KEYS = {
  // ПАРАМЕТРЫ
  NUMBER: {
    key: 'NUMBER',
    apiKey: 'contractNumber',
    title: 'Номер договора',
    rules: [],
  },

  DATE: {
    key: 'DATE',
    apiKey: 'contractDate',
    title: 'Дата договора',
    rules: [],
  },

  STATUS: {
    key: 'STATUS',
    apiKey: 'status',
    title: 'Статус',
    rules: [
      {
        required: true,
        message: 'Статус не может быть пустым',
      },
    ],
  },

  SECTOR: {
    key: 'SECTOR',
    apiKey: 'sector',
    title: 'Сектор',
    rules: [
      {
        required: true,
        message: 'Сектор не может быть пустой',
      },
    ],
  },

  PASSWORD: {
    key: 'PASSWORD',
    apiKey: 'maskPassword',
    title: 'Пароль',
    rules: [],
  },

  API_KEY: {
    key: 'API_KEY',
    apiKey: 'keyApi',
    title: 'API KEY',
    rules: [],
  },

  OCCUPATION: {
    key: 'OCCUPATION',
    apiKey: 'occupation',
    title: 'Род деятельности',
    rules: [],
  },

  SITE: {
    key: 'SITE',
    apiKey: 'site',
    title: 'Сайт',
    rules: [],
  },

  BRAND: {
    key: 'BRAND',
    apiKey: 'brand',
    title: 'Бренд',
    rules: [],
  },

  CURRENT_TARIFF: {
    key: 'CURRENT_TARIFF',
    apiKey: 'currentTariff',
    title: 'Текущий тариф',
    rules: [],
  },

  NEW_TARIFF: {
    key: 'NEW_TARIFF',
    apiKey: 'newTariff',
    title: 'Новый тариф',
    rules: [],
  },

  DATE_TARIFF: {
    key: 'DATE_TARIFF',
    apiKey: 'dateTariff',
    title: 'Начало действия (для нового тарифа)',
    rules: [],
  },

  // КОНТАКТЫ

  CONTACTS_SIGNATORY_NAME: {
    key: 'CONTACTS_SIGNATORY_NAME',
    apiKey: 'signatory',
    title: 'ФИО и должность руководителя',
    rules: [],
  },

  CONTACTS_SIGNATORY_PHONE: {
    key: 'CONTACTS_SIGNATORY_PHONE',
    apiKey: 'signatoryPhone',
    title: 'Телефон',
    rules: [],
  },

  CONTACTS_SIGNATORY_EMAIL: {
    key: 'CONTACTS_SIGNATORY_EMAIL',
    apiKey: 'signatoryEmail',
    title: 'Email',
    rules: [
      () => ({
        validator(rule, value) {
          if (value && value.length > 0) {
            if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Невалидный емайл');
          }
          return Promise.resolve();
        },
      }),
    ],
  },

  CONTACTS_CURATOR_NAME: {
    key: 'CONTACTS_CURATOR_NAME',
    apiKey: 'curatorFio',
    title: 'ФИО',
    rules: [],
  },

  CONTACTS_CURATOR_POSITION: {
    key: 'CONTACTS_CURATOR_POSITION',
    apiKey: 'curatorPosition',
    title: 'Должность',
    rules: [],
  },

  CONTACTS_CURATOR_PHONE: {
    key: 'CONTACTS_CURATOR_PHONE',
    apiKey: 'curatorPhone',
    title: 'Телефон',
    rules: [],
  },

  CONTACTS_CURATOR_EMAIL: {
    key: 'CONTACTS_CURATOR_EMAIL',
    apiKey: 'curatorEmail',
    title: 'Email',
    rules: [
      () => ({
        validator(rule, value) {
          if (value && value.length > 0) {
            if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Невалидный емайл');
          }
          return Promise.resolve();
        },
      }),
    ],
  },

  CONTACTS_NOTIFICATIONS_OPERATION_EMAIL: {
    key: 'CONTACTS_NOTIFICATIONS_OPERATION_EMAIL',
    apiKey: 'emailForOperations',
    title: 'Email для операций',
    rules: [
      () => ({
        validator(rule, value) {
          if (value && value.length > 0) {
            if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Невалидный емайл');
          }
          return Promise.resolve();
        },
      }),
    ],
  },

  CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL: {
    key: 'CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL',
    apiKey: 'emailForRegistries',
    title: 'Email для реестров',
    rules: [
      () => ({
        validator(rule, value) {
          if (value && value.length > 0) {
            if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Невалидный емайл');
          }
          return Promise.resolve();
        },
      }),
    ],
  },

  CONTACTS_NOTIFICATIONS_ADDRESS: {
    key: 'CONTACTS_NOTIFICATIONS_ADDRESS',
    apiKey: 'postAdress',
    title: 'Адрес для корреспонденции',
    rules: [],
  },

  // РЕКВЕЗИТЫ
  REQUISITES_BANK: {
    key: 'REQUISITES_BANK',
    apiKey: 'bank',
    title: 'Банк',
    rules: [],
  },

  REQUISITES_CORRESPONDENT_BILL: {
    key: 'REQUISITES_CORRESPONDENT_BILL',
    apiKey: 'correspondentAccount',
    title: 'Корреспондентский счет',
    rules: [],
  },

  REQUISITES_CALCULATED_BILL: {
    key: 'REQUISITES_CALCULATED_BILL',
    apiKey: 'checkingAccount',
    title: 'Расчетный счет',
    rules: [],
  },

  // ЧЕК
  BILL_SENDER: {
    key: 'BILL_SENDER',
    apiKey: 'emailSendPP',
    title: 'Чек (кто отправитель)',
    rules: [],
  },

  BILL_TRADE_NAME: {
    key: 'BILL_TRADE_NAME',
    apiKey: 'merchantNameCheck',
    title: 'Торговое наименование',
    rules: [],
  },

  BILL_WEB_ADDRESS: {
    key: 'BILL_WEB_ADDRESS',
    apiKey: 'webAddressCheck',
    title: 'WEB адрес',
    rules: [],
  },

  BILL_EMAIL: {
    key: 'BILL_EMAIL',
    apiKey: 'emailCheck',
    title: 'Email',
    rules: [
      () => ({
        validator(rule, value) {
          if (value && value.length > 0) {
            if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Невалидный емайл');
          }
          return Promise.resolve();
        },
      }),
    ],
  },

  BILL_PHONE: {
    key: 'BILL_PHONE',
    apiKey: 'phoneCheck',
    title: 'Телефон',
    rules: [],
  },

  // Настройки
  SETTINGS_WRITE_OFF_SITE: {
    key: 'SETTINGS_WRITE_OFF_SITE',
    apiKey: 'paymentTypeApi',
    title: 'Порядок списания (сайт)',
    rules: [
      {
        required: true,
        message: 'Порядок списания не может быть пустым',
      },
    ],
  },

  SETTINGS_WRITE_OFF_BILL: {
    key: 'SETTINGS_WRITE_OFF_BILL',
    apiKey: 'paymentTypeArm',
    title: 'Порядок списания (счет)',
    rules: [
      {
        required: true,
        message: 'Порядок списания не может быть пустым',
      },
    ],
  },

  SETTINGS_URL_SITE: {
    key: 'SETTINGS_URL_SITE',
    apiKey: 'urlCallback',
    title: 'URL для колбэка (сайт)',
    rules: [],
  },

  SETTINGS_DATE_OF_BILL: {
    key: 'SETTINGS_DATE_OF_BILL',
    apiKey: 'accountLifetime',
    title: 'Срок действия ссылки (счет)',
    rules: [],
  },

  SETTINGS_BOOKER_URL: {
    key: 'SETTINGS_BOOKER_URL',
    apiKey: 'ascbUrl',
    title: 'URL бухгалтерской системы',
    rules: [],
  },

  SETTINGS_BOOKER_TYPES: {
    key: 'SETTINGS_BOOKER_TYPES',
    apiKey: 'ascbType',
    title: 'Тип запроса к бухгалтерской системе',
    rules: [],
  },

  COMMENT: {
    key: 'COMMENT',
    apiKey: 'comment',
    title: 'Комментарии',
    rules: [
      () => ({
        validator(rule, value) {
          if (value && value.length > 0) {
            if (value.length > 255) {
              return Promise.reject('Поле комментарий не должно превышать 255 символов');
            }
            return Promise.resolve();
          }
          return Promise.resolve();
        },
      }),
    ],
  },
};

const formatCommission = (array = []) => {
  if (Array.isArray(array)) {
    if (array.length > 1) {
      return {
        startDate: array[1].startDate ? moment(array[1].startDate) : '',
        currentTariff: array[0].commission || '',
        newTariff: array[1].commission || '',
      };
    } if (array.length === 1) {
      return {
        startDate: '',
        currentTariff: array[0].commission || '',
        newTariff: '',
      };
    }
    return {
      startDate: '',
      currentTariff: '',
      newTariff: '',
    };
  }
  return {
    startDate: '',
    currentTariff: '',
    newTariff: '',
  };
};

export const setDataToFields = (item, form) => {
  const {
    accountLifetime,
    bank,
    brand,
    checkingAccount,
    comment,
    commission,
    contractDate,
    contractNumber,
    correspondentAccount,
    curatorEmail,
    curatorFio,
    curatorPhone,
    curatorPosition,
    emailCheck,
    // emailForNotification,
    emailForOperations,
    emailForRegistries,
    emailSendPP,
    keyApi,
    maskPassword,
    merchantNameCheck,
    occupation,
    paymentTypeApi,
    paymentTypeArm,
    phoneCheck,
    postAdress,
    sector,
    signatory,
    signatoryEmail,
    signatoryPhone,
    site,
    status,
    urlCallback,
    webAddressCheck,
    ascbUrl,
    ascbType,
  } = item;

  const {
    startDate,
    currentTariff,
    newTariff,
  } = formatCommission(commission);

  const [numbers] = accountLifetime ? accountLifetime.split(/\s/) : [''];

  form.setFields([
    // PARAMS
    ...(contractNumber ? [{ name: KEYS.NUMBER.key, value: contractNumber }] : []),
    ...(contractDate ? [{ name: KEYS.DATE.key, value: moment(contractDate) }] : []),
    ...(brand ? [{ name: KEYS.BRAND.key, value: brand }] : []),
    ...(status ? [{ name: KEYS.STATUS.key, value: status }] : []),
    ...(keyApi ? [{ name: KEYS.API_KEY.key, value: keyApi }] : []),
    ...(maskPassword ? [{ name: KEYS.PASSWORD.key, value: maskPassword }] : []),
    ...(sector ? [{ name: KEYS.SECTOR.key, value: sector }] : []),
    ...(site ? [{ name: KEYS.SITE.key, value: site }] : []),
    ...(occupation ? [{ name: KEYS.OCCUPATION.key, value: occupation }] : []),

    // TARIFF // apiKey commission[{},{}]
    ...(currentTariff ? [{ name: KEYS.CURRENT_TARIFF.key, value: currentTariff }] : []),
    ...(newTariff ? [{ name: KEYS.NEW_TARIFF.key, value: newTariff }] : []),
    ...(startDate ? [{ name: KEYS.DATE_TARIFF.key, value: moment(startDate) }] : []),

    // SIGNATORY
    ...(signatory ? [{ name: KEYS.CONTACTS_SIGNATORY_NAME.key, value: signatory }] : []),
    ...(signatoryEmail ? [{ name: KEYS.CONTACTS_SIGNATORY_EMAIL.key, value: signatoryEmail }] : []),
    ...(signatoryPhone ? [{ name: KEYS.CONTACTS_SIGNATORY_PHONE.key, value: signatoryPhone }] : []),

    // CURATOR
    ...(curatorPhone ? [{ name: KEYS.CONTACTS_CURATOR_PHONE.key, value: curatorPhone }] : []),
    ...(curatorEmail ? [{ name: KEYS.CONTACTS_CURATOR_EMAIL.key, value: curatorEmail }] : []),
    ...(curatorFio ? [{ name: KEYS.CONTACTS_CURATOR_NAME.key, value: curatorFio }] : []),
    ...(curatorPosition ? [{
      name: KEYS.CONTACTS_CURATOR_POSITION.key, value: curatorPosition,
    }] : []),

    // NOTIFICATIONS
    ...(emailForOperations
      ? [{ name: KEYS.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.key, value: emailForOperations }]
      : []),
    ...(emailForRegistries
      ? [{ name: KEYS.CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL.key, value: emailForRegistries }]
      : []),
    ...(postAdress
      ? [{ name: KEYS.CONTACTS_NOTIFICATIONS_ADDRESS.key, value: postAdress }]
      : []),
    // emailForNotification нет поля в макете, но его надо передавать беку
    // ...(emailForNotification
    //   ? [{ name: KEYS.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.key, value: emailForNotification }]
    //   : []),

    // REQUISITES
    ...(bank ? [{ name: KEYS.REQUISITES_BANK.key, value: bank }] : []),
    ...(checkingAccount ? [{ name: KEYS.REQUISITES_CALCULATED_BILL.key, value: checkingAccount }] : []),
    ...(correspondentAccount ? [{ name: KEYS.REQUISITES_CORRESPONDENT_BILL.key, value: correspondentAccount }] : []),

    // BILL
    ...(emailSendPP ? [{ name: KEYS.BILL_SENDER.key, value: emailSendPP }] : []),
    ...(emailCheck ? [{ name: KEYS.BILL_EMAIL.key, value: emailCheck }] : []),
    ...(webAddressCheck ? [{ name: KEYS.BILL_WEB_ADDRESS.key, value: webAddressCheck }] : []),
    ...(merchantNameCheck ? [{ name: KEYS.BILL_TRADE_NAME.key, value: merchantNameCheck }] : []),
    ...(phoneCheck ? [{ name: KEYS.BILL_PHONE.key, value: phoneCheck }] : []),

    // SETTINGS
    ...(paymentTypeArm ? [{ name: KEYS.SETTINGS_WRITE_OFF_BILL.key, value: paymentTypeArm }] : []),
    ...(paymentTypeApi ? [{ name: KEYS.SETTINGS_WRITE_OFF_SITE.key, value: paymentTypeApi }] : []),
    ...(urlCallback ? [{ name: KEYS.SETTINGS_URL_SITE.key, value: urlCallback }] : []),
    { name: KEYS.SETTINGS_DATE_OF_BILL.key, value: Number(numbers) || null },
    ...(ascbUrl ? [{ name: KEYS.SETTINGS_BOOKER_URL.key, value: ascbUrl }] : []),
    ...(ascbType ? [{ name: KEYS.SETTINGS_BOOKER_TYPES.key, value: ascbType }] : []),

    // COMMENT
    ...(comment ? [{ name: KEYS.COMMENT.key, value: comment }] : []),
  ]);
};

export const STATUS_OPTIONS = [
  {
    value: 'new',
    label: 'Новый',
    disabled: false,
  },
  {
    value: 'off_line',
    label: 'Отключен',
    disabled: false,
  },
  {
    value: 'active',
    label: 'Активный',
    disabled: true,
  },
];

export const WRITE_OF_OPTIONS = [
  {
    value: '0',
    label: '1 стадийное',
  },
  {
    value: '1',
    label: '2 стадийное',
  },
];

export const WRITE_OF_OPTIONS_API = [
  {
    value: '0',
    label: '1 стадийное',
  },
  {
    value: '1',
    label: '2 стадийное',
  },
  {
    value: '4',
    label: '1 стадийное / 2 стадийное',
  },
];

export const SETTINGS_DATE_OF_BILL_OPTIONS = [
  {
    value: 24,
    label: '24 часа',
  },
  {
    value: null,
    label: 'Бессрочно',
  },
];

export const BOOKER_TYPES_OPTIONS = [
  {
    value: '0',
    label: 'Стандартный запрос',
  },
  {
    value: '1',
    label: 'Расширенный запрос',
  },
];
// header, id, inn,  надо добавить
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const dateFormatWithZeroTime = 'YYYY-MM-DD 00:00:00';

export const formatValuesForCreateContract = (values, item) => {
  const {
    commission,
    id,
    inn,
    header,
    redirectTimer,
    emailForNotification,
  } = item;

  const { startDate } = Array.isArray(commission) && commission[0] ? commission[0] : {};

  const {
    [KEYS.API_KEY.key]: keyApi,
    [KEYS.BRAND.key]: brand,
    [KEYS.COMMENT.key]: comment,
    [KEYS.DATE.key]: contractDate,
    [KEYS.NUMBER.key]: contractNumber,
    [KEYS.OCCUPATION.key]: occupation,
    [KEYS.PASSWORD.key]: maskPassword,
    [KEYS.SECTOR.key]: sector,
    [KEYS.SITE.key]: site,
    [KEYS.STATUS.key]: status,

    [KEYS.BILL_EMAIL.key]: emailCheck,
    [KEYS.BILL_PHONE.key]: phoneCheck,
    [KEYS.BILL_SENDER.key]: emailSendPP,
    [KEYS.BILL_TRADE_NAME.key]: merchantNameCheck,
    [KEYS.BILL_WEB_ADDRESS.key]: webAddressCheck,

    [KEYS.CONTACTS_CURATOR_EMAIL.key]: curatorEmail,
    [KEYS.CONTACTS_CURATOR_NAME.key]: curatorFio,
    [KEYS.CONTACTS_CURATOR_PHONE.key]: curatorPhone,
    [KEYS.CONTACTS_CURATOR_POSITION.key]: curatorPosition,

    [KEYS.CONTACTS_NOTIFICATIONS_ADDRESS.key]: postAdress,
    [KEYS.CONTACTS_NOTIFICATIONS_OPERATION_EMAIL.key]: emailForOperations,
    [KEYS.CONTACTS_NOTIFICATIONS_REGISTERED_EMAIL.key]: emailForRegistries,

    [KEYS.CONTACTS_SIGNATORY_EMAIL.key]: signatoryEmail,
    [KEYS.CONTACTS_SIGNATORY_NAME.key]: signatory,
    [KEYS.CONTACTS_SIGNATORY_PHONE.key]: signatoryPhone,

    [KEYS.CURRENT_TARIFF.key]: currentTariff,
    [KEYS.DATE_TARIFF.key]: dateTariff,
    [KEYS.NEW_TARIFF.key]: newTariff,

    [KEYS.REQUISITES_BANK.key]: bank,
    [KEYS.REQUISITES_CALCULATED_BILL.key]: checkingAccount,
    [KEYS.REQUISITES_CORRESPONDENT_BILL.key]: correspondentAccount,

    [KEYS.SETTINGS_DATE_OF_BILL.key]: accountLifetime,
    [KEYS.SETTINGS_URL_SITE.key]: urlCallback,
    [KEYS.SETTINGS_WRITE_OFF_BILL.key]: paymentTypeArm,
    [KEYS.SETTINGS_WRITE_OFF_SITE.key]: paymentTypeApi,
    [KEYS.SETTINGS_BOOKER_URL.key]: ascbUrl,
    [KEYS.SETTINGS_BOOKER_TYPES.key]: ascbType,
  } = values;

  return {
    id,
    inn,
    header,
    emailSendPP,
    keyApi,
    sector: Number(sector),
    contractNumber: contractNumber ? String(contractNumber) : null,
    contractDate: contractDate ? moment(contractDate).format(dateFormatWithZeroTime) : null,
    occupation,
    site,
    brand,
    signatory,
    curatorFio,
    curatorPosition,
    curatorPhone,
    curatorEmail,
    postAdress,
    bank,
    correspondentAccount,
    checkingAccount,
    paymentTypeApi: Number(paymentTypeApi),
    paymentTypeArm: Number(paymentTypeArm),
    accountLifetime: accountLifetime === 24 ? '24 hours' : null,
    status,
    commission: newTariff ? [
      ...((currentTariff && startDate) ? [{
        commission: currentTariff,
        startDate,
      }] : []),
      {
        commission: newTariff,
        ...(dateFormat ? {
          startDate: moment(dateTariff).format(dateFormat),
        } : {}),
      },
    ] : (currentTariff && startDate) ? [
      {
        commission: currentTariff,
        startDate,
      },
    ] : null,
    redirectTimer,
    maskPassword,
    comment,
    urlCallback,
    emailForOperations,
    emailForRegistries,
    signatoryPhone,
    signatoryEmail,
    emailForNotification,
    emailCheck,
    phoneCheck,
    merchantNameCheck,
    webAddressCheck,
    ascbUrl,
    ascbType,
  };
};
