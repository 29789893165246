export const formatCurrency = (v) => {
  const currencyRegex = /^(?<int>\d*)(?<separator>[.,]{0,1})(?<float>[0-9]{0,2})(?:.*)$/;
  if (typeof v === 'string' || typeof v === 'number') {
    const [, int, separator, float] = String(v).replace(/\s/g, '').match(currencyRegex);

    return int.length > 0
      ? separator
        ? `${int}.${float}`
        : int
      : '';
  }
  return '';
};
