import API from 'services';

import { extractErrorAndCode, formatForm } from 'hacks';

import {
  all, call, delay, fork, put, putResolve, select, takeEvery, takeLatest, takeLeading,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';
import { CRIF_CHECK_MORATORIUM } from 'redux/crif/actions';
import { USER_STATUS__TRY_TO_RESET } from 'redux/userStatus/actions';

import {
  REQUEST__DELETE_FILE,
  REQUEST__DELETE_FILE_FAILURE,
  REQUEST__DELETE_FILE_SUCCESS,
  REQUEST__FETCH,
  REQUEST__FETCH_FAILURE,
  REQUEST__FETCH_FILE,
  REQUEST__FETCH_FILE_FAILURE,
  REQUEST__FETCH_FILE_SUCCESS,
  REQUEST__FETCH_SUCCESS,
  REQUEST__PATCH,
  REQUEST__PATCH_FAILURE,
  REQUEST__PATCH_LOADING_DISABLE,
  REQUEST__PATCH_LOADING_ENABLE,
  REQUEST__PATCH_PENDING_RESET,
  REQUEST__PATCH_PENDING_UPDATE,
  REQUEST__PATCH_SUCCESS,
  REQUEST__REMOVE_PREVIEW,
  REQUEST__SAVE,
  REQUEST__SAVE_FAILURE,
  REQUEST__SAVE_SUCCESS,
  REQUEST__UPLOAD_FILE,
  REQUEST__UPLOAD_FILE_CONFIRMED,
  REQUEST__UPLOAD_FILE_ERROR,
  REQUEST__UPLOAD_FILE_RECOGNIZED,
  REQUEST__UPLOAD_FILE_UPLOADED,
  REQUEST__UPLOAD_FILES,
  REQUEST__UPLOAD_FILES_SET_LAST_FILE,
  REQUEST_GET_ANSWER_DETAILS,
  REQUEST_GET_ANSWER_DETAILS__FAILURE,
  REQUEST_GET_ANSWER_DETAILS__SUCCESS,
  REQUEST_REVISION__FETCH,
  REQUEST_REVISION__FETCH_FAILURE,
  REQUEST_REVISION__FETCH_SUCCESS,
} from './actions';

const getState = (state) => state.request;
const getRequestState = (state) => state.request;
const getCrifState = (state) => state.crif;

function* checkMoratorium() {
  const { isLoaded, hasMoratorium } = yield select(getCrifState);

  if (isLoaded && !hasMoratorium) {
    yield put({
      type: CRIF_CHECK_MORATORIUM,
    });
  }
}

export function* fetch() {
  yield takeLatest(REQUEST__FETCH, function* (action) {
    try {
      yield put({ type: USER_STATUS__TRY_TO_RESET });

      const response = yield call(API.request.fetch, { accessToken: action.accessToken });

      if (response.data && response.data.fields && response.data.fields.length > 0) {
        yield put({
          type: REQUEST__FETCH_SUCCESS,
          fields: response.data.fields,
          popups: response.data.popups,
          next: Boolean(response.data.next),
        });
        yield call(checkMoratorium);
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: REQUEST__FETCH_FAILURE, error: error.message });
    }
  });
}

function* delayedPatch() {
  const {
    patch: { pending },
  } = yield select(getState);

  if (Object.keys(pending).length) {
    yield put({ type: REQUEST__PATCH_PENDING_RESET });
    yield put({ type: REQUEST__PATCH, userInput: pending, exclude: [] });
  }
}

export function* patch() {
  yield takeEvery(REQUEST__PATCH, function* ({ userInput, exclude = [] }) {
    const {
      values,
      fetch: { id: accessToken },
      patch: { isLoading, pending },
    } = yield select(getState);

    if (isLoading) {
      // здесь нет условия, когда значение удаляется
      // по факту если `userInput` пустой, а `exclude` - непустой массив, этот кейс должен сработать, но он не сработает
      yield put({
        type: REQUEST__PATCH_PENDING_UPDATE,
        pending: { ...pending, ...userInput },
      });
    } else {
      yield put({ type: REQUEST__PATCH_LOADING_ENABLE });

      try {
        const filteredForm = Object.keys(values).reduce(
          (o, k) => (exclude.includes(k) ? o : { ...o, [k]: values[k] }),
          {},
        );
        const dirtyForm = { ...filteredForm, ...userInput };

        const cleanedForm = Array.isArray(exclude) && exclude.length > 0
          ? Object.keys(dirtyForm).reduce(
            (o, k) => (exclude.includes(k) ? o : { ...o, [k]: dirtyForm[k] }),
            {},
          )
          : dirtyForm;

        const formatedForm = formatForm(cleanedForm);
        const response = yield call(API.request.patch, { form: formatedForm, accessToken });

        if (response.data && response.data.fields && response.data.fields.length > 0) {
          yield put({
            type: REQUEST__PATCH_SUCCESS,
            fields: response.data.fields,
            popups: response.data.popups,
            next: Boolean(response.data.next),
          });
          yield call(checkMoratorium);
        } else {
          const { error, code } = extractErrorAndCode(response);
          yield put({ type: ON_ERROR, errorCode: code });
          throw new Error(error);
        }
      } catch (error) {
        yield put({ type: REQUEST__PATCH_FAILURE, error: error.message });
      }

      yield put({ type: REQUEST__PATCH_LOADING_DISABLE });
      yield call(delayedPatch);
    }
  });
}

export function* save() {
  yield takeLatest(REQUEST__SAVE, function* () {
    try {
      const {
        values,
        fetch: { id: accessToken },
      } = yield select(getRequestState);

      const response = yield call(API.request.save, { form: values, accessToken });

      yield put({ type: USER_STATUS__TRY_TO_RESET });

      if (response.data && response.data.fields && response.data.fields.length > 0) {
        yield put({
          type: REQUEST__SAVE_SUCCESS,
          fields: response.data.fields,
          popups: response.data.popups,
          next: Boolean(response.data.next),
        });
        yield call(checkMoratorium);
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: REQUEST__SAVE_FAILURE, error: error.message });
    }
  });
}

export function* fetchFile() {
  yield takeEvery(REQUEST__FETCH_FILE, function* (action) {
    try {
      const {
        fetch: { id: accessToken },
      } = yield select(getRequestState);

      const response = yield call(API.file.fetch, { accessToken, name: action.name });

      if (response.data && response.data.content) {
        yield put({
          type: REQUEST__FETCH_FILE_SUCCESS,
          name: action.name,
          file: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: REQUEST__FETCH_FILE_FAILURE,
        name: action.name,
        error: error.message,
      });
    }
  });
}

function* checkFileWithIntervals({
  accessToken,

  // https://selfteam.atlassian.net/browse/TB-4998
  // Я не согласен с решением, и какой-то разумный лимит на кол-во попыток должен быть
  // Но раз просят - то поставлю Infinity
  tries = Infinity,

  interval = 1,
}) {
  for (let i = 0; i < tries; i++) {
    try {
      const response = yield call(API.file.check, { accessToken });

      if (response && response.data && response.data.isProcessed === true) {
        return true;
      }
    } catch (error) {
      if (i < tries - 1) {
        yield delay(1000 * interval);
      }
    }
  }
  return false;
}

function* uploadFile({ fieldName, file, documentType }) {
  try {
    yield put({ type: REQUEST__UPLOAD_FILE, fieldName, fileName: file.name });

    if (documentType) {
      const { values } = yield select(getState);

      if (values.typeSecondDocument !== documentType) {
                // explicitly pass typeSecondDocument to override form data
        yield put({
          type: REQUEST__PATCH,
          userInput: { typeSecondDocument: documentType },
          exclude: [],
        });
      }
    }

    const {
      values,
      fetch: { id: accessToken },
    } = yield select(getState);

    const response = yield call(API.file.upload, { accessToken, fieldName, file });

    if (response && response.data && response.data.status === true) {
      yield putResolve({ type: REQUEST__UPLOAD_FILE_UPLOADED, fieldName });
      const recognizedFile = yield call(checkFileWithIntervals, { accessToken });

      if (recognizedFile) {
        yield putResolve({ type: REQUEST__UPLOAD_FILE_RECOGNIZED, fieldName });
        const fileConfirmationResponse = yield call(API.file.confirm, {
          accessToken,
          fieldName,
          form: values,
        });

        if (
          fileConfirmationResponse
                    && fileConfirmationResponse.data
                    && fileConfirmationResponse.data.fields
        ) {
          yield putResolve({
            type: REQUEST__UPLOAD_FILE_CONFIRMED,
            fieldName,
            fields: fileConfirmationResponse.data.fields,
            popups: fileConfirmationResponse.data.popups,
          });
          yield call(checkMoratorium);
        } else {
          throw new Error('Ошибка применения изменений');
        }
      } else {
        throw new Error('Ошибка распознавания файла');
      }
    } else {
      throw new Error('Ошибка загрузки файла');
    }
  } catch (error) {
    yield putResolve({ type: REQUEST__UPLOAD_FILE_ERROR, fieldName, error: error.message });
  }
}

export function* uploadFiles() {
  yield takeEvery(REQUEST__UPLOAD_FILES, function* (action) {
    yield put({ type: REQUEST__PATCH_LOADING_ENABLE });

        // eslint-disable-next-line no-unused-vars
    for (const item of action.fileList) {
      yield call(uploadFile, {
        file: item.file,
        fieldName: item.fieldName,
        documentType: action.documentType,
      });
    }

    if (action.fileList.length > 0) {
      yield put({
        type: REQUEST__UPLOAD_FILES_SET_LAST_FILE,
        name: action.fileList.slice(-1)[0].fieldName,
      });
    }

    yield put({ type: REQUEST__PATCH_LOADING_DISABLE });
    yield call(delayedPatch);
  });
}

export function* deleteFile() {
  yield takeEvery(REQUEST__DELETE_FILE, function* (action) {
    yield put({ type: REQUEST__REMOVE_PREVIEW, name: action.fieldName });
    yield put({ type: REQUEST__PATCH_LOADING_ENABLE });

    try {
      const {
        values,
        patch: { pending },
        fetch: { id: accessToken },
        files,
      } = yield select(getRequestState);

      const dirtyForm = { ...values, ...pending };
      const exclude = [
        action.fieldName,
        ...Object.keys(files).filter((key) => files[key].isDeleting),
      ];

      // we exclude from the 'form' every file is deleting right now
      // api queries are pending, not finished, so we need to read data from the store
      // and act like those files do not exist
      const cleanedForm = Array.isArray(exclude) && exclude.length > 0
        ? Object.keys(dirtyForm).reduce(
          (o, k) => (exclude.includes(k) ? o : { ...o, [k]: dirtyForm[k] }),
          {},
        )
        : dirtyForm;

      const response = yield call(API.file.delete, {
        accessToken,
        fieldName: action.fieldName,
        form: cleanedForm,
      });

      if (response.data && response.data.fields && response.data.fields.length > 0) {
        yield put({ type: REQUEST__DELETE_FILE_SUCCESS, fieldName: action.fieldName });

        // update form data (removing the file will return new updated form)
        yield put({
          type: REQUEST__FETCH_SUCCESS,
          fields: response.data.fields,
          popups: response.data.popups,
          next: Boolean(response.data.next),
        });
        yield call(checkMoratorium);
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: REQUEST__DELETE_FILE_FAILURE,
        fieldName: action.fieldName,
        error: error.message,
      });
    }

    yield put({ type: REQUEST__PATCH_LOADING_DISABLE });
    yield call(delayedPatch);
  });
}

function* getDetails() {
  yield takeLeading(REQUEST_GET_ANSWER_DETAILS, function* (action) {
    try {
      const response = yield call(API.request.getDetails, { accessToken: action.accessToken });

      if (!response.error && response.data && !response.errorCode) {
        yield put({
          type: REQUEST_GET_ANSWER_DETAILS__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: REQUEST_GET_ANSWER_DETAILS__FAILURE, error: error.message });
    }
  });
}

export function* revision() {
  yield takeLatest(REQUEST_REVISION__FETCH, function* (action) {
    try {
      const response = yield call(
        API.request.sendToRevision,
        { accessTokenCreditRequest: action.accessToken, message: action.message },
      );

      if (!response.errorCode) {
        yield put({ type: REQUEST_REVISION__FETCH_SUCCESS });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: REQUEST_REVISION__FETCH_FAILURE, error: error.message });
      yield put({ type: ON_ERROR, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetch),
    fork(patch),
    fork(save),
    fork(fetchFile),
    fork(uploadFiles),
    fork(deleteFile),
    fork(getDetails),
    fork(revision),
  ]);
}
