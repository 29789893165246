import React, { useState } from 'react';
import styled from 'styled-components';
import {
  FormStaticDate,
  RecallDate,
  RecallDateLabel,
  SecondaryField,
} from 'containers/App/TCM/ClientCard/CallForm/style';
import { TCM } from 'components/tcm';
import { KEYS } from 'containers/App/TCM/ClientCard/CallForm/data';

const Group = styled.div`
  display: grid;
  grid-template-columns: 410px 280px;
  column-gap: 20px;
  margin-bottom: 24px;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 24px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RecallOtherSubfield = () => {
  const [isChecked, setIsChecked] = useState(false);
  const onCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <SecondaryField>
      <Group>
        <TCM.Label
          label="Имя"
          validateTrigger="onBlur"
          rules={KEYS.STATUS.options.notOwner.children.newName.rules}
          name={KEYS.STATUS.options.notOwner.children.newName.key}
        >
          <TCM.Input
            name={KEYS.STATUS.options.notOwner.children.newName.key}
            placeholder="Укажите имя"
          />
        </TCM.Label>
        <TCM.Label
          label="Новый номер"
          validateTrigger="onBlur"
          rules={KEYS.STATUS.options.notOwner.children.newPhone.rules}
          name={KEYS.STATUS.options.notOwner.children.newPhone.key}
        >
          <TCM.Input
            name={KEYS.STATUS.options.notOwner.children.newPhone.key}
            type="tel"
            placeholder="Укажите телефон"
          />
        </TCM.Label>
      </Group>
      <CheckboxWrapper>
        <TCM.Label
          name={KEYS.STATUS.options.notOwner.children.callLater.key}
          valuePropName="checked"
        >
          <TCM.Checkbox
            onChange={onCheck}
          >
            Позвонить позже
          </TCM.Checkbox>
        </TCM.Label>
      </CheckboxWrapper>
      {isChecked && (
        <>
          <RecallDateLabel>Дата и время повторного звонка</RecallDateLabel>
          <RecallDate>
            <TCM.Label
              name={KEYS.STATUS.options.notOwner.children.callDate.key}
              rules={KEYS.STATUS.options.notOwner.children.callDate.rules}
              validateTrigger="onBlur"
            >
              <FormStaticDate
                name={KEYS.STATUS.options.notOwner.children.callDate.key}
                allowClear={false}
              />
            </TCM.Label>
            <TCM.Label
              name={KEYS.STATUS.options.notOwner.children.callTime.key}
              rules={KEYS.STATUS.options.notOwner.children.callTime.rules}
              validateTrigger="onBlur"
            >
              <TCM.TimePicker
                name={KEYS.STATUS.options.notOwner.children.callTime.key}
              />
            </TCM.Label>
          </RecallDate>
        </>
      )}
    </SecondaryField>
  );
};
