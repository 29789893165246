import {
  getLocalStorageToken,
  saveLocalStorageToken,
  removeLocalStorageToken,
  getSessionStorageToken,
  saveSessionStorageToken,
  removeSessionStorageToken,
} from 'helpers/storage';

const AUTH_KEY = 'AUTH_KEY';
const REFRESH_KEY = 'REFRESH_KEY';
const DISPLAY_NAME = 'DISPLAY_NAME';
const ROLE_NAME = 'ROLE_NAME';
const PERMISSION_LIST = 'PERMISSION_LIST';
const USER = 'USER';
const USER_ID = 'USER_ID';
const TCM_MANAGER = 'TCM_MANAGER';
const TCM_USER = 'TCM_USER';

export const getToken = () =>
  getSessionStorageToken(AUTH_KEY) || getLocalStorageToken(AUTH_KEY);
export const getRefreshToken = () =>
  getSessionStorageToken(REFRESH_KEY) || getLocalStorageToken(REFRESH_KEY);
export const getTokens = () => [getToken(), getRefreshToken()];

export const setRefreshToken = (refreshKey) => {
  saveLocalStorageToken(REFRESH_KEY, refreshKey);
  saveSessionStorageToken(REFRESH_KEY, refreshKey);
};

export const setTokens = (shouldBeSavedInLocalStorage = false, authKey, refreshKey) => {
  if (shouldBeSavedInLocalStorage) {
    saveLocalStorageToken(AUTH_KEY, authKey);
    saveLocalStorageToken(REFRESH_KEY, refreshKey);
  }
  saveSessionStorageToken(AUTH_KEY, authKey);
  saveSessionStorageToken(REFRESH_KEY, refreshKey);
};

export const removeTokens = () => {
  removeSessionStorageToken(AUTH_KEY);
  removeSessionStorageToken(REFRESH_KEY);
  removeLocalStorageToken(AUTH_KEY);
  removeLocalStorageToken(REFRESH_KEY);
  removeLocalStorageToken(DISPLAY_NAME);
  removeLocalStorageToken(ROLE_NAME);
  removeLocalStorageToken(PERMISSION_LIST);
};

export const filterObjectKeys = (object) =>
  Object.keys(object).reduce(
    (o, k) => ({ ...o, ...(object[k] ? { [k]: object[k] } : {}) }),
    {},
  );

export const getPermissionList = () => {
  const list = (
    JSON.parse(getSessionStorageToken(PERMISSION_LIST))
    || JSON.parse(getLocalStorageToken(PERMISSION_LIST))
  );
  return Array.isArray(list) ? list : [];
};

export const getUserId = () => {
  const userId = (
    JSON.parse(getSessionStorageToken(USER_ID))
    || JSON.parse(getLocalStorageToken(USER_ID))
  );
  return userId || null;
};

export const getTCMManger = () => {
  const userId = (
    JSON.parse(getSessionStorageToken(TCM_MANAGER))
    || JSON.parse(getLocalStorageToken(TCM_MANAGER))
  );
  return userId || null;
};

export const getTCMUser = () => {
  const userId = (
    JSON.parse(getSessionStorageToken(TCM_USER))
    || JSON.parse(getLocalStorageToken(TCM_USER))
  );
  return userId || null;
};

export const setPermissionList = (list) => {
  const l = Array.isArray(list) ? list.map(({ alias }) => ({ alias })) : [];

  saveLocalStorageToken(PERMISSION_LIST, JSON.stringify(l));
  saveSessionStorageToken(PERMISSION_LIST, JSON.stringify(l));
};

export const removePermissionList = () => {
  removeSessionStorageToken(PERMISSION_LIST);
  removeLocalStorageToken(PERMISSION_LIST);
};

export const getUser = () => {
  const object = (
    JSON.parse(getSessionStorageToken(USER))
    || JSON.parse(getLocalStorageToken(USER))
  );
  return object && Object.keys(object).length > 0 ? object : {};
};

export const setUser = (object) => {
  saveLocalStorageToken(USER, JSON.stringify(object));
  saveSessionStorageToken(USER, JSON.stringify(object));
};

export const removeUser = () => {
  removeSessionStorageToken(USER);
  removeLocalStorageToken(USER);
};

export const removeTCMInfo = () => {
  removeSessionStorageToken(TCM_USER);
  removeLocalStorageToken(TCM_USER);
  removeSessionStorageToken(TCM_MANAGER);
  removeLocalStorageToken(TCM_MANAGER);
};
