import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Title,
  List,
} from 'components/tcm/Sidebars/style';

const NavItem = ({
  // passed
  title,
}) => (
  <List>
    <Title>
      <Link to="/tcm/users/list">{title}</Link>
    </Title>
  </List>
);

const Users = () => (
  <NavItem
    title="Пользователи"
  />
);

export default connect(
  null, null,
)(Users);
