export const INVITE__RESET = 'INVITE__RESET';
export const reset = () => ({ type: INVITE__RESET });

export const INVITE_CONFIRM_EMAIL = 'INVITE_CONFIRM_EMAIL';
export const INVITE_CONFIRM_EMAIL__SUCCESS = 'INVITE_CONFIRM_EMAIL__SUCCESS';
export const INVITE_CONFIRM_EMAIL__FAILURE = 'INVITE_CONFIRM_EMAIL__FAILURE';
export const confirmEmail = ({ token, email }) => ({
  type: INVITE_CONFIRM_EMAIL,
  token,
  email,
});

export const INVITE_SEND_OTP = 'INVITE_SEND_OTP';
export const INVITE_SEND_OTP__SUCCESS = 'INVITE_SEND_OTP__SUCCESS';
export const INVITE_SEND_OTP__FAILURE = 'INVITE_SEND_OTP__FAILURE';
export const sendOtp = ({ login }) => ({ type: INVITE_SEND_OTP, login });

export const INVITE_CHECK_OTP = 'INVITE_CHECK_OTP';
export const INVITE_CHECK_OTP__SUCCESS = 'INVITE_CHECK_OTP__SUCCESS';
export const INVITE_CHECK_OTP__FAILURE = 'INVITE_CHECK_OTP__FAILURE';
export const checkOtp = ({ code, token }) => ({
  type: INVITE_CHECK_OTP,
  code,
  token,
});

export const INVITE_FINISH = 'INVITE_FINISH';
export const INVITE_FINISH__SUCCESS = 'INVITE_FINISH__SUCCESS';
export const INVITE_FINISH__FAILURE = 'INVITE_FINISH__FAILURE';
export const finishRegister = ({ password, token }) => ({
  type: INVITE_FINISH,
  password,
  token,
});
