import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';

import { Root, Canvas, PaginationStyled } from './style';

function PDFPreview({ base64 }) {
  const canvasRef = useRef(null);
  const [page, setPage] = useState(1);

  function pageOnChange(n) {
    setPage(n);
  }

  const { pdfDocument } = usePdf({
    file: `data:application/pdf;base64,${base64}`,
    page,
    canvasRef,
  });

  return (
    <Root>
      <Canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <PaginationStyled
          defaultCurrent={page}
          total={pdfDocument.numPages}
          pageSize={1}
          onChange={pageOnChange}
          hideOnSinglePage
          showSizeChanger={false}
        />
      )}
    </Root>
  );
}

export default PDFPreview;
