import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { Rifm } from 'rifm';

import {
  sendOtpByPhone as sendOtpByPhoneAction,
  checkOtp as checkOtpAction,
  reset as resetAction,
} from 'redux/restore/actions';

import {
  formatPhone,
  cursorToLastPhoneValuePosition,
  parseDigits,
} from 'utils';

import { useInterval } from 'hooks';

import { Search } from 'components/Input';
import Button from 'components/Button';

import { KEYS as K } from './data';

import {
  Root,
  FormError,
  Footer,
} from './style';

const rulesCode = [
  {
    required: true,
    message: 'Смс код не может быть пустым',
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && parseDigits(value).length === 4
        ? Promise.resolve()
        : Promise.reject('Смс код должен содержать 4 цифры');
    },
  },
];
const addMask = (string) => {
  const digits = parseDigits(string);
  return digits.slice(0, 4).padEnd(4, '_');
};
const numberFormat = (string) => {
  const digits = parseDigits(string);

  return Array.from(digits)
    .reduce((p, c) => `${p}${c}`, '')
    .substring(0, 4);
};
const TIMEOUT = 60;

function Restore({
  // passed
  location,
  history,

  // connect
  idToken,
  otpRecipient,
  otpIsLoading,
  otpIsLoaded,
  otpLogin,
  otpError,

  confirmationIsLoaded,
  confirmationError,

  // actions
  sendOtpByPhone,
  checkOtp,
  reset,
}) {
  const [form] = Form.useForm();
  const phoneField = useRef();
  const [isRunning, setIsRunning] = useState(false);
  const [count, setCount] = useState(0);
  const [error, setError] = useState('');
  const [showCode, setShowCode] = useState(false);

  useInterval(
    () => {
      if (count === 0) {
        setIsRunning(false);
      } else {
        setCount(count - 1);
      }
    },
    isRunning ? 1000 : null,
  );

  useEffect(() => {
    if (confirmationError) {
      setError(confirmationError);
    }
  }, [confirmationError, setError]);

  useEffect(() => {
    if (otpIsLoaded) {
      setShowCode(true);
    }
  }, [otpIsLoaded]);

  useEffect(() => {
    if (idToken) {
      const path = (
        (
          location.state
          && location.state.from
          && location.state.from.pathname
        ) || '/'
      );

      history.push(path);
    }
  }, [history, idToken, location]);

  useEffect(() => {
    if (otpIsLoaded) {
      setCount(TIMEOUT);
      setIsRunning(true);
    }
  }, [otpIsLoaded]);

  useEffect(() => () => reset(), [reset]);

  const handleSearch = useCallback(() => {
    if (count === 0) {
      form.setFields([{ name: 'code', value: '', errors: [] }]);

      sendOtpByPhone({ phone: form.getFieldValue('PHONE') });
    }
  }, [count, form, sendOtpByPhone]);

  function handleChange({ name, value }) {
    form.setFields([{ name, value }]);
    setError('');

    if (otpIsLoaded
        && (name === 'code')
        && (/[\d]{4}/gm.test(value))
    ) {
      checkOtp({ code: value, password: true });
    }
  }

  const handleBlur = useCallback(({ target: { name } }) => {
    form.validateFields([name]);
  }, [form]);

  const handleFocus = useCallback(({ target: { name } }) => {
    form.setFields([{ name, errors: [] }]);
  }, [form]);

  const handleFinish = useCallback((values) => {
    const { PHONE: phone } = values;
    sendOtpByPhone({ phone });
  }, [sendOtpByPhone]);

  return (
    <Root>
      {!confirmationIsLoaded ? (
        <Form
          form={form}
          layout="vertical"
          name="RestorePassword"
          size="large"
          hideRequiredMark
          onFinish={handleFinish}
        >
          <Form.Item
            name={K.PHONE.key}
            label={K.PHONE.title}
            rules={K.PHONE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm
              accept={/[\d]/g}
              mask
              replace={formatPhone.replace}
              format={formatPhone.format}
              onClick={() => cursorToLastPhoneValuePosition(phoneField)}
            >
              {({ value, onChange }) => (
                <Input
                  name={K.PHONE.key}
                  value={value}
                  onChange={onChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                  addonBefore={formatPhone.prefix}
                  ref={phoneField}
                  disabled={otpIsLoaded}
                />
              )}
            </Rifm>
          </Form.Item>
          {showCode && (
            <>
              <Form.Item
                name="code"
                label={(
                  <span>
                    SMS код
                    {otpRecipient && (
                      <sup>
                        [сообщение было выслано на
                        <strong>{otpRecipient}</strong>
                        ]
                      </sup>
                    )}
                  </span>
                )}
                rules={rulesCode}
                validateFirst
                validateTrigger="onBlur"
              >
                <Rifm
                  onChange={(v) => handleChange({ name: 'code', value: v })}
                  accept={/[\d]/g}
                  mask
                  replace={addMask}
                  format={numberFormat}
                >
                  {({ value, onChange }) => (
                    <Search
                      name="code"
                      value={value}
                      onSearch={handleSearch}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      buttonRedColor={!((count > 0) || otpIsLoading)}
                      enterButton={!confirmationIsLoaded
                        ? (
                          <div>
                            {count > 0 ? (
                              <span>
                                {count}
                                с
                              </span>
                            ) : (
                              <span>
                                {otpLogin ? 'Выслать код повторно' : 'Выслать код'}
                              </span>
                            )}
                          </div>
                        ) : <></>}
                      onChange={onChange}
                      disabled={confirmationIsLoaded}
                    />
                  )}
                </Rifm>
              </Form.Item>
            </>
          )}
          {(error || otpError) && <FormError>{(error || otpError)}</FormError>}
          {!otpIsLoaded && (
            <Footer>
              <Button type="primary" block htmlType="submit" loading={otpIsLoading}>
                Продолжить
              </Button>
            </Footer>
          )}
        </Form>
      ) : (
        <>На Вашу почту отправлена инструкция по восстановлению пароля. </>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    otpRecipient: state.restore.otp.recipient,
    otpIsLoaded: state.restore.otp.isLoaded,

    otpLogin: state.restore.otp.login,
    otpIsLoading: state.restore.otp.isLoading,
    otpError: state.restore.otp.error,

    confirmationIsLoading: state.restore.confirmation.isLoading,
    confirmationIsLoaded: state.restore.confirmation.isLoaded,
    confirmationError: state.restore.confirmation.error,
    confirmationErrors: state.restore.confirmation.errors,

    idToken: state.auth.idToken,
  }),
  {
    sendOtpByPhone: sendOtpByPhoneAction,
    checkOtp: checkOtpAction,
    reset: resetAction,
  },
)(Restore);
