import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';
import { defaultPageSize, defaultSorting } from 'redux/report/reducer';
import { formatFilters } from 'hacks';

export const exportReportFile = ({
  filter,
}) => {
  const URI = `${API}/admin/report/generate/registry`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        ...filter,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getReportSectors = () => {
  const URI = `${API}/admin/report/sector/list`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {},
    }),
  };
  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchList = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
}) => {
  const URI = `${API}/admin/report/list`;

  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
    nullableReports: filters.nullableReports,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: defaultPageSize,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const reportsSync = ({
  sectors = [],
  date = '',
}) => {
  const URI = `${API}/admin/report/operations/sync`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        sectors,
        date,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const monthlySummaryOperations = ({
  sectors = [],
  year = 0,
  month = 0,
}) => {
  const URI = `${API}/admin/report/generate/monthly-summary-operations`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        sectors,
        year,
        month,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const periodOperations = ({
  sectors = [],
  dateTo = '',
  dateFrom = '',
}) => {
  const URI = `${API}/admin/report/generate/report`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        sectors,
        dateFrom,
        dateTo,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const reportReconciliation = ({
  sectors = [],
  dateRevise = '',
}) => {
  const URI = `${API}/admin/report/generate/report-reconciliation-of-discrepancies`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        sectors,
        dateRevise,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getFileUrl = ({
  id = '',
}) => {
  const URI = `${API}/admin/report/get/public/url`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  exportReportFile,
  getReportSectors,
  reportsSync,
  monthlySummaryOperations,
  periodOperations,
  reportReconciliation,
  getFileUrl,
};
