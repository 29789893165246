import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useParams,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { connect } from 'react-redux';

import {
  setBreadcrumbs as setBreadcrumbsAction,
} from 'redux/breadcrumbs/actions';

import {
  fetchItem as fetchItemAction,
  fetchItemReset as fetchItemResetAction,
} from 'redux/company/actions';

import Loader from 'components/Loader';

import Files from './Files';
import Users from './Users';
import DealerCenters from './DealerCenters';
import Contracts from './Сontracts';
import Form from '../Form';

import {
  Root,
  Header,
  Title,
  SubTitle,
  Nav,
  Link,
} from './style';

function Company({
   // store
  data,
  isLoaded,
  isLoading,
  role,
  error,

  // action
  setBreadcrumbs,
  fetchItem,
  fetchItemReset,
}) {
  const { companyId } = useParams();
  const [isMounted, setIsMounted] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    title: 'Организация',
    inn: '',
  });

  const history = useHistory();

  const routes = [
    {
      path: `/app/management/companies/${companyId}/details`,
      title: 'Организация',
      component: Form,
      checkRights: () => true,
    },
    {
      path: `/app/management/companies/${companyId}/dealerCenters`,
      title: 'Точки продажи',
      component: DealerCenters,
      checkRights: () => true,
    },
    {
      path: `/app/management/companies/${companyId}/users`,
      title: 'Пользователи',
      component: Users,
      checkRights: () => true,
    },
    {
      path: `/app/management/companies/${companyId}/contracts`,
      title: 'Договоры',
      component: Contracts,
      checkRights: () => true,
    },
    {
      path: `/app/management/companies/${companyId}/files`,
      title: 'Файлы',
      component: Files,
      checkRights: () => true,
    },
  ];

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (companyId) {
      fetchItem({ id: companyId });
    }
  }, [fetchItem, companyId]);

  useEffect(() => {
    if (isLoaded && data.name) {
      setCompanyInfo({
        title: data.name,
        inn: data?.inn,
      });
    }
  }, [isLoaded, data]);

  useEffect(() => {
    if ((isLoaded || error) && !data.name) {
      history.push('/app/management/companies/list');
    }
  }, [error, isLoaded, data, history]);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Организации', link: '/app/management/companies/list' },
        {
          level: 2,
          name: data?.name ? data.name : `${companyId}`,
          link: `/app/management/companies/list/${companyId}`,
        },
      ]);
    }
  }, [isMounted, setBreadcrumbs, companyId, data]);

  useEffect(() => () => fetchItemReset(), [fetchItemReset]);

  return (
    <Root>
      {isLoading ? (
        <Loader text="Загрузка данных организации..." height="100vh" />
      ) : (
        <>
          <Header>
            <Title>
              {companyInfo.title}
            </Title>
            <SubTitle>
              ИНН
              {' '}
              {companyInfo.inn}
            </SubTitle>
            <Nav>
              {routes
                .filter((route) => route.checkRights(role))
                .map((route) => (
                  <Link key={route.path} to={route.path}>
                    {route.title}
                  </Link>
                ))}
            </Nav>
          </Header>
          <Switch>
            <Route exact path={`/app/management/companies/${companyId}`}>
              <Redirect to={`/app/management/companies/${companyId}/details`} />
            </Route>

            <Route path="/app/management/companies/:companyId/edit" component={Form} />
            <Route path="/app/management/companies/:companyId/details" component={Form} />
            <Route path="/app/management/companies/:companyId/files" component={Files} />
            <Route path="/app/management/companies/:companyId/users" component={Users} />
            <Route path="/app/management/companies/:companyId/dealerCenters" component={DealerCenters} />
            <Route path="/app/management/companies/:companyId/contracts" component={Contracts} />
          </Switch>
        </>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    data: state.company.item.data,
    isLoaded: state.company.item.isLoaded,
    isLoading: state.company.item.isLoading,
    error: state.company.item.error,
  }),
  {
    setBreadcrumbs: setBreadcrumbsAction,
    fetchItem: fetchItemAction,
    fetchItemReset: fetchItemResetAction,
  },
)(Company);
