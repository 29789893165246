import styled from 'styled-components';

export const Root = styled.div.attrs({ className: 'grid' })`
  height: calc(100vh - 5.6rem);
  overflow: hidden;
  padding: 0 2rem 0;

  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  align-content: start;
`;
