import React, { useCallback, useEffect, useRef } from 'react';
import Select from 'components/Select';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { getOptionsList } from 'hacks';
import { SCROLL_DURATION } from 'settings';

function SelectField({
  name,
  type,
  label,
  options = [],
  error: errorMessage,
  area,
  disabled,
  warning,

  form: { getFieldDecorator },

  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();
  const list = getOptionsList(options);

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue:
          (list.find((e) => e.value === String(initialValue)) || {}).value
          || initialValue
          || '',
      })(
        <Select
          name={name}
          area={area}
          disabled={disabled}
          ref={focusableNode}
          size={size}
          list={list}
        />,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default SelectField;
