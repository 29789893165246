import React, { useCallback, useMemo, useState } from 'react';
import { PageBlock } from 'components/tcm/PageBlock/PageBlock';
import { VisitForm } from 'containers/App/TCM/ClientCard/VisitForm/VisitForm';
import { TCM } from 'components/tcm';
import {
  ResultItemDescription,
  ResultItemTitle,
  Visit,
  VisitRow,
  Wrapper,
  NoVisitsText,
  VisitsOptionsContainer,
  EmptyContent,
  VisitGrid,
} from 'containers/App/TCM/ClientCard/style';
import { checkPrivilege } from 'helpers/roles';
import { connect } from 'react-redux';
import { VisitDescriptionCode } from 'tcm/offer/data';
import moment from 'moment';
import { RublerIcon } from 'icons';
import { connectStringNumber, separateNumberByRank } from 'helpers/numbers';
import { getDeclension } from 'utils/getDeclension';

const Visits = ({
  isMyClient,
  role,
  clientGuid,
  visits = [],
  canAddVisit,
}) => {
  const [showVisitForm, setShowVisitForm] = useState(false);
  const [showMoreResults, setShowMoreResults] = useState(false);

  const onCallBtnClick = useCallback(() => {
    setShowVisitForm(true);
  },
  []);

  const callBtn = useMemo(() => (isMyClient && canAddVisit ? (
    <TCM.ButtonOutline onClick={onCallBtnClick}>
      Добавить визит клиента
    </TCM.ButtonOutline>
  ) : null),
  [onCallBtnClick, isMyClient, canAddVisit]);

  const onCancelVisit = useCallback(() => {
    setShowVisitForm(false);
  }, []);

  const onShowMoreResultClick = useCallback(
    () => setShowMoreResults(!showMoreResults),
    [showMoreResults], [],
  );

  const showMoreResultsBtnText = useMemo(() => {
    const number = visits.length - 2;
    return (showMoreResults ? 'Скрыть' : `Еще ${number} ${getDeclension(number, 'визит')}`);
  },
  [visits.length, showMoreResults]);

  const visitsResults = useMemo(
    () => (showMoreResults ? visits : visits.slice(0, 2)),
    [visits, showMoreResults],
  );

  const buttonInCallsHeader = useMemo(() => (
    checkPrivilege(role.TCM.ADD_VISIT_TO_OFFER)
    && !showVisitForm
    && visitsResults.length !== 0
      ? callBtn
      : null
  ), [callBtn, role.TCM.ADD_VISIT_TO_OFFER, showVisitForm, visitsResults.length]);

  return (
    <PageBlock title="Визиты" btnInHeader={buttonInCallsHeader}>

      {!showVisitForm && visitsResults.length === 0 && (
        <EmptyContent>
          <NoVisitsText>Пока не было ни одного визита </NoVisitsText>
          {
            checkPrivilege(role.TCM.ADD_VISIT_TO_OFFER)
          && callBtn
          }
        </EmptyContent>

      )}

      {checkPrivilege(role.TCM.ADD_VISIT_TO_OFFER) && showVisitForm && (
        <VisitForm clientGuid={clientGuid} onCancel={onCancelVisit} />
      )}

      <VisitsOptionsContainer>
        {visitsResults.map((visit, index) => {
          const tradeInSum = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.Sum,
          )?.value;

          const formattedTradeInSum = tradeInSum && separateNumberByRank(connectStringNumber(tradeInSum));

          const brand = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.TestDriveBrand,
          )?.value;

          const model = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.TestDriveModel,
          )?.value;

          const testDriveBrandAndModel = brand && model
            ? `${brand} ${model}`
            : undefined;

          const registrationValue = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.RegistrationBank,
          )?.value;

          const registrationBrand = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.RegistrationBrand,
          )?.value;

          const registrationModel = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.RegistrationModel,
          )?.value;

          const registrationBrandAndModel = registrationBrand && registrationModel
            ? `${registrationBrand} ${registrationModel}`
            : undefined;

          const contractExecutionValue = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.ContractConcludedBank,
          )?.value;

          const contractExecutionReason = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.ContractConcludedReason,
          )?.value;

          const executionOtherDC = visit.statusDescriptions.find(
            (desc) => desc.key === VisitDescriptionCode.LoanAgreementExecutionOtherDC,
          )?.value;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Visit key={(visit.employee && visit.employee.id ? visit.employee.id : ' ') + index}>
              <VisitRow>
                <div>
                  <ResultItemTitle>Дата визита</ResultItemTitle>
                  <ResultItemDescription>{moment(visit.date).format('DD.MM.YYYY')}</ResultItemDescription>
                </div>
              </VisitRow>

              <VisitGrid>
                { Boolean(formattedTradeInSum || testDriveBrandAndModel) && (
                  <>
                    { Boolean(formattedTradeInSum) && (
                      <div>
                        <ResultItemTitle>Оценка Trade-in</ResultItemTitle>
                        <ResultItemDescription>
                          {formattedTradeInSum}
                          {' '}
                          <RublerIcon />
                        </ResultItemDescription>
                      </div>
                    )}
                    { Boolean(testDriveBrandAndModel) && (
                      <div>
                        <ResultItemTitle>Тест-драйв</ResultItemTitle>
                        <ResultItemDescription>{testDriveBrandAndModel}</ResultItemDescription>
                      </div>
                    )}
                  </>
                )}

                { Boolean(registrationValue || registrationBrandAndModel) && (
                  <>
                    { Boolean(registrationValue) && (
                      <div>
                        <ResultItemTitle>Подача заявки</ResultItemTitle>
                        <ResultItemDescription>{registrationValue}</ResultItemDescription>
                      </div>
                    )}
                    { Boolean(registrationBrandAndModel) && (
                      <div>
                        <ResultItemTitle>Модель</ResultItemTitle>
                        <ResultItemDescription>{registrationBrandAndModel}</ResultItemDescription>
                      </div>
                    )}
                  </>
                )}

                { Boolean(contractExecutionValue || contractExecutionReason) && (
                  <>
                    { Boolean(contractExecutionValue) && (
                      <div>
                        <ResultItemTitle>Оформление кредитного договора</ResultItemTitle>
                        <ResultItemDescription>{contractExecutionValue}</ResultItemDescription>
                      </div>
                    )}
                    { Boolean(contractExecutionReason) && (
                      <div>
                        <ResultItemTitle>Причина отказа</ResultItemTitle>
                        <ResultItemDescription>{contractExecutionReason}</ResultItemDescription>
                      </div>
                    )}
                  </>
                )}
                {Boolean(executionOtherDC) && (
                  <div>
                    <ResultItemTitle>Оформил в другом ДЦ</ResultItemTitle>
                    <ResultItemDescription>{executionOtherDC}</ResultItemDescription>
                  </div>
                )}
              </VisitGrid>

              <div>
                <ResultItemTitle>Комментарий к визиту</ResultItemTitle>
                <ResultItemDescription fullWidth>{visit.text}</ResultItemDescription>
              </div>
            </Visit>

          );
        })}
      </VisitsOptionsContainer>
      {visits.length > 2 && (
        <Wrapper>
          <TCM.ButtonText onClick={onShowMoreResultClick}>
            {showMoreResultsBtnText}
          </TCM.ButtonText>
        </Wrapper>
      )}
    </PageBlock>
  );
};

const ConnectWithStore = connect(
  (state) => ({
    role: state.auth.role,
  }),
)(Visits);

export { ConnectWithStore as Visits };
