import styled from 'styled-components';
import { Popover, Button } from 'antd';
import withTheme from 'components/tcm/withTheme';

export const Container = styled.div`
  .ant-table-thead > tr > th{
     background: transparent;
     padding-bottom: 35px;
     font-family: "Open Sans", sans-serif;
     font-size: 14px;
     color: #A8AAAC;
     
     &:first-child {
        color: #000;
     }
  }
  .ant-table-tbody > tr > td{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    ${({ isLoading }) => isLoading && 'border: none'}
  }
  .ant-pagination-item-active{
    border-color: #e50000;
    a {
      color: #e50000;
    }
  }
  .ant-pagination-item:focus, .ant-pagination-item:hover {
   transition: all 0.3s;
   border-color: #e50000;
      a {
        color: #e50000;
      } 
   }
   
   .ant-spin{
    color: inherit;
   }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'Open Sans', sans-serif;
  opacity: ${({ hide }) => (hide ? 0.2 : 1)};
  transition: .2s linear opacity;

  th, td {
    padding: 0 26px 0 0;
  
    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  th {
    padding-bottom: 15px;
    border-bottom: 1px solid #cecece;
    vertical-align: top;
    color: #000;
    font-weight: 500;
    
    &:last-child {
      padding-right: 0;
    }
    &:not(:first-of-type) {
      color: #A8AAAC
    }
  }

  td {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #F0F0F0;
    
    &[data-width='200']{
      width: 200px;
    }
    
    &[data-width='72']{
      width: 72px;
    }
    
    &[data-width='100']{
      width: 100px;
    }
  }

  th, td {
    width: min-content;
  }
  tbody{
   position: relative;
  }
  
  tbody tr {
    transition: .25s all linear;
    position: relative;
    z-index: 1;
    
    &:hover{
      background: #F0F0F0;
    }
    
    &:nth-child(4){
      width: 94px;
    }
   
    td:first-child{
      padding-left: 20px;
    }
    
     td:last-child{
      padding-right: 20px;      
      
      button:hover{
        background: transparent;
      }
    }
    
    button{
      background: transparent;
    }
  }
`;

export const TableTitleStroke = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isTheme }) => (isTheme ? 16 : 14)}px;
  line-height: 20px;
  color: #000000;
`;

export const TableTitleSecondaryStroke = styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #A8AAAC;
`;
export const TableContainer = styled.div`
  position: relative;
  
  & > div {
    position: absolute;
    width: 100%;
    height: 200px;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 200px;
  }
  `;

export const Td = withTheme(styled.td`
      & p{
        color: ${({ secondary }) => (secondary ? '#A8AAAC' : '#000000')};
      }
    `);

export const Root = styled.div`
  padding: 0rem 4rem 0;
  
  .ant-select-selection-item {
    color: rgba(0,0,0, .8);
  }
`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const ItemsContainer = styled.div`
  position: relative;
`;

export const ItemsContent = styled.div`
  background: #fff;
  margin-top: 5px;
`;

export const ItemsContentContainer = styled(Popover)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Count = styled(Button)`
  background: red;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 10px;
  height: 26px;
  min-width: 26px;
  padding: 0;
`;

export const ButtonsContainer = styled.div`
 display: grid;
 grid-auto-flow: column;
 grid-column-gap: 20px;
 align-items: center;
`;

export const DownloadButtonContainer = styled.div`
 display: grid;
 position: relative;
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
