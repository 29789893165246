import React, { useEffect, useState } from 'react';

import {
  OptWrapper,
  CheckBox,
  CheckBoxInput,
  MultipleSelectStyled,
  MultipleOption,
} from './style';

const MultipleSelect = ({ options, selected, ...p }) => {
  const [newSelected, setNewSelected] = useState([]);

  useEffect(() => {
    if (selected) {
      setNewSelected(selected.map((opt) => opt.value));
    }
  },
  [selected]);

  const onChange = (val) => {
    p.onChange(val);
    setNewSelected(val);
  };

  return (
    <MultipleSelectStyled
      {...p}
      value={newSelected}
      optionLabelProp="label"
      onChange={onChange}
    >
      { Array.isArray(options) && options.map((opt) => (
        <MultipleOption
          key={opt.value}
          value={opt.value}
          label={opt.label}
        >
          <OptWrapper>
            <CheckBox>
              <CheckBoxInput
                name={opt.value}
                checked={newSelected.includes(opt.value)}
              />
              <div />
            </CheckBox>
            {opt.label}
          </OptWrapper>
        </MultipleOption>
      ))}
    </MultipleSelectStyled>
  );
};

export { MultipleSelect, MultipleOption };
