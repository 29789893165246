const palette = {
  accent: '#e50000',
  accentDarker: '#CE0000',
  successful: '#4CAF50',
  white: '#fff',
  black: '#000',
  gray1: '#595e68',
  gray2: '#6c7073',
  gray3: '#a8aaaC',
  gray4: '#cecece',
  gray5: '#f0f0f0',
  gray6: '#f5f5f5',
  gray7: '#f7f7f7',
};

const buttons = {
  minWidth: '140px',
  fixWidth: '200px',
  padding: '14px 85px',
  paddingFixSize: '14px 1em',
  borderWidth: 1,
  borderRadius: 40,
  textSize: 14,
  lineHeight: 20,
  main: {
    borderColor: palette.accent,
    borderDisabledColor: palette.gray6,
    borderHoverColor: palette.accentDarker,
    backgroundColor: palette.accent,
    backgroundHoverColor: palette.accentDarker,
    backgroundDisabledColor: palette.gray5,
    textColor: palette.white,
    textDisabledColor: palette.gray2,
  },
  outline: {
    borderColor: palette.accent,
    borderDisabledColor: palette.gray4,
    borderHoverColor: palette.accentDarker,
    backgroundColor: palette.white,
    backgroundHoverColor: palette.white,
    backgroundDisabledColor: palette.white,
    textHoverColor: palette.accentDarker,
    textColor: palette.accent,
    textDisabledColor: palette.gray4,
  },
  text: {
    border: 'none',
    backgroundColor: 'transparent',
    backgroundHoverColor: 'transparent',
    textHoverColor: palette.accentDarker,
    textColor: palette.accent,
  },
};

const input = {
  padding: '14px 20px 14px',
  borderRadius: 5,
  borderColor: palette.gray4,
  borderInvalidColor: palette.accent,
  fontWeight: 400,
  fontWeightPlaceholder: 300,
  fontSize: 16,
  lineHeight: 20,
  textColor: palette.black,
  textPlaceholderColor: palette.gray3,
};

const inputNumber = {
  padding: '9px 20px 9px',
};

const select = {
  padding: '12px 44px 4px 20px',
  borderRadius: 5,
  borderOpenRadius: '5px 5px 0px 0px',
  borderColor: palette.gray4,
  fontWeight: 400,
  fontWeightPlaceholder: 300,
  fontSize: '16px',
  lineHeight: '20px',
  textColor: palette.black,
  textPlaceholderColor: palette.gray3,
  icons: {
    arrowColor: palette.accent,
  },
};

const multipleSelect = {
  padding: '9px 44px 9px 20px',
};

const checkbox = {
  width: '20px',
  height: '20px',
  border: `2px solid ${palette.gray4}`,
  borderColor: palette.gray4,
  borderRadius: '4px',
  backgroundColor: palette.white,
  iconCheckedColor: palette.accent,
};

const radio = {
  width: '20px',
  height: '20px',
  border: `2px solid ${palette.gray4}`,
  iconCheckedColor: palette.accent,
};

const label = {
  marginBottom: '7px',
  textColor: palette.black,
  lineHeight: '15px',
  fontWeight: 300,
  fontSize: '12px',
};

export default {
  fontFamily: "'Open Sans', sans-serif",
  toyotaFontFamily: "'Toyota Type', sans-serif",
  palette,
  buttons,
  input,
  inputNumber,
  select,
  multipleSelect,
  checkbox,
  radio,
  label,
};
