import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { TCM } from 'components/tcm';
import { PageTitle } from 'containers/App/TCM/style';
import {
  offerAddComment as offerAddCommentAction,
  postAdditionalPhone as postAdditionalPhoneAction,
  setEmployee as setEmployeeAction,
  setEmployeeReset as setEmployeeResetAction,
  fetchItem as fetchItemAction,
} from 'redux/tcmOffer/actions';
import {
  fetchEmployeesList as fetchEmployeesListAction,
} from 'redux/tcmEmployee/actions';
import { SHOWING_COMMENTS } from 'settings';
import { OfferStatus } from 'tcm/offer/data';

import { OkIcon } from 'icons';
import { SuccessfulField } from 'components/tcm/ClientInfo/style';
import { checkPrivilege } from 'helpers/roles';
import { getDeclension } from 'utils/getDeclension';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import { useParams } from 'react-router';
import { PhoneInput } from '../Input';

import {
  Content,
  FieldTitle,
  FieldValue,
  Main,
  MainContent,
  StatusWrapper,
  Title,
  Header,
  EmployeeContainer,
  Employee,
  Name,
  ClientSex,
  BirthDay,
  CityOfResidence,
  Phone,
  PhoneShowFull,
  CommentsContainer,
  CommentsContainerTitle, NewCommentLabel, NewCommentContainer,
  CommentContainer,
  Comment,
  CommentTitle,
  CommentText,
  CommentDate,
  ModalText,
  ModalWithChildren,
} from './style';
import { ErrorMessage } from '../ErrorMessage';

// https://habr.com/ru/post/493598/
function hideNumber(string, replaceTo = '*', elemsHide = 5, sliceFromback = 2) {
  const result = string.match(/^(\(?\+?\d{1,2}\)? ?\(?\d{1,3}\)? ?\d+-? ?\d+-? ?\d+)$/);
  if (result !== null) {
    // тут мы выдергиваем n элементов после среза x
    const regex = new RegExp(`((\\(?\\ ?\\-?\\d\\ ?\\-?\\)?){${elemsHide}})((\\ ?\\-?\\d\\ ?\\-?){${sliceFromback}}$)`, 'gm');

    let m;
    // eslint-disable-next-line no-cond-assign
    while ((m = regex.exec(string)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      const forRex = m[1];
      const str = m[1].replace(/(\d)/gm, replaceTo);
      const lasts = m[3];
      const full = string;
      const noBack = full.slice(0, -lasts.length).slice(0, -forRex.length);
      const out = `${noBack}${str}${lasts}`;
      return out;
    }
    return string;
  }
  return string;
}

const ConfirmedContent = ({ text }) => (
  <SuccessfulField as="div">
    <OkIcon />
    <p>{text}</p>
  </SuccessfulField>
);

function ClientInfo({
  // passed
  client,
  role,
  externalId,
  isMyClient,
  // status,
  isLoaded,
  employee,

  // store
  commentIsLoading,
  isAdditionalPhoneLoading,
  // isAdditionalPhoneLoaded,
  additionalPhoneError,
  offer,

  employeesList,
  // employeesIsLoading,
  // employeesIsLoaded,
  // employeesError,

  employeeIsLoading,
  employeeIsLoaded,
  employeeError,

  // actions
  offerAddComment,
  postAdditionalPhone,
  fetchEmployeesList,
  setEmployee,
  setEmployeeReset,
  fetchItem,
}) {
  const [showMoreComments, setShowMoreComments] = useState(false);
  const [showFullPhone, setShowFullPhone] = useState(false);
  const [showFullAdditionalPhone, setShowFullAdditionalPhone] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [showModalSelectEmployee, setModalSelectEmployee] = useState(false);
  const [selectedEmployee, selectEmployee] = useState(offer?.employee?.id);

  useEffect(() => {
    fetchEmployeesList();
  }, [fetchEmployeesList]);

  const id = externalId ?? useParams().id;
  useEffect(() => {
    if (employeeIsLoaded) {
      setEmployeeReset();
      setModalSelectEmployee(false);
      fetchItem({ id });
    }
  }, [fetchItem, setEmployeeReset, setModalSelectEmployee, id, employeeIsLoaded]);

  const isManager = /campaign\/offers|tcm\/client/.test(window.location.pathname)
    && checkPrivilege([role.TCM.VIEW_CAMPAIGN]);

  // старая логика, попросили поменять, чтоб был доступ к замене сотрудника и у клиента
  // в статусе Обзвон
  // const canChangeEmployee = offer?.campaign?.status === CAMPAIGN_STATUSES.DISTRIBUTION
  //   && (offer?.status === OfferStatus.Calling || offer?.status === OfferStatus.Distribution);

  const canChangeEmployee = offer?.status === OfferStatus.Calling || (
    offer?.campaign?.status === CAMPAIGN_STATUSES.DISTRIBUTION
    && offer?.status === OfferStatus.Distribution
  );

  const { comments = [], statusName } = offer;

  const showMoreText = useMemo(() => {
    const number = comments.length - SHOWING_COMMENTS;
    return (!showMoreComments && comments.length > SHOWING_COMMENTS
      ? `Еще ${number}  ${getDeclension(number, 'комментарий')}`
      : 'Скрыть');
  },
  [comments.length, showMoreComments]);

  const preparedComments = useMemo(() => (
    showMoreComments
      ? comments
      : comments.slice(0, 2)),
  [comments, showMoreComments]);

  const handleSendNewComment = () => {
    offerAddComment({
      text: newComment,
    });
  };

  const [additionalPhone, setAdditionalPhone] = useState('7');
  const deleteAdditionalPhone = () => {
    postAdditionalPhone({
      externalId,
      additionalPhone: '',
    });
    setAdditionalPhone('7');
  };
  const saveAdditionalPhone = (e) => {
    e.preventDefault();
    postAdditionalPhone({
      externalId,
      additionalPhone,
    });
  };
  const isComments = comments.length > 0;

  const canAddComment = (offer.status === OfferStatus.WaitConfirm
    || offer.status === OfferStatus.Calling || offer.status === OfferStatus.Revision);

  const assignEmployee = () => {
    const employees = [{
      externalId,
      employeeId: selectedEmployee,
    }];
    setEmployee({ employees });
  };

  const showAdditionalPhoneBlock = !((isManager || offer.status === OfferStatus.Confirmed) && !client.additionalPhone);

  const showAdditionalPhoneBlockIsMyClient = (isMyClient && !(offer.status === OfferStatus.Confirmed));

  return (
    <>
      <Main>
        <Header>
          <Title>
            <PageTitle>Карточка клиента</PageTitle>
          </Title>
          {isManager
            && (
              <EmployeeContainer>
                {employee?.name ? (
                  <>
                    <Employee>
                      Сотрудник:
                      {' '}
                      {employee.name}
                    </Employee>
                    {canChangeEmployee
                      && (
                        <TCM.ButtonText widthSize="fixed" onClick={() => setModalSelectEmployee(true)}>
                          Изменить
                        </TCM.ButtonText>
                      )}
                  </>
                ) : (
                  <>
                    <Employee>Сотрудник не назначен</Employee>
                    {canChangeEmployee
                        && (
                          <TCM.Button widthSize="fixed" onClick={() => setModalSelectEmployee(true)}>
                            Выбрать сотрудника
                          </TCM.Button>
                        )}
                  </>
                )}
                <ModalWithChildren
                  visible={showModalSelectEmployee}
                  onOk={assignEmployee}
                  onClose={() => setModalSelectEmployee(false)}
                  onCancel={() => setModalSelectEmployee(false)}
                  title="Выбор сотрудника"
                  btnTextOk="Назначить"
                  btnTextCancel="Закрыть окно"
                  onOkLoading={employeeIsLoading}
                  onOkError={employeeError}
                >
                  <ModalText>Выберите сотрудника, которому надо назначить клиента:</ModalText>
                  <TCM.RadioGroup
                    name="reason"
                    value={selectedEmployee}
                    onChange={(e) => selectEmployee(e.target.value)}
                  >
                    {employeesList.map((employee) => (
                      <TCM.Radio
                        key={employee.id}
                        value={employee.id}
                      >
                        {employee.displayName}
                      </TCM.Radio>
                    ))}
                  </TCM.RadioGroup>
                </ModalWithChildren>
              </EmployeeContainer>
            )}
        </Header>
        {isLoaded && (
          <Content>
            <MainContent>
              {/* <IdWrapper> */}
              {/*  /!* оказалось не нужно *!/ */}
              {/*  /!* <FieldTitle>ID</FieldTitle> *!/ */}
              {/*  /!* <FieldValue>{externalId || 'Нет данных'}</FieldValue> *!/ */}
              {/* </IdWrapper> */}
              <Name>
                <FieldTitle>ФИО</FieldTitle>
                <FieldValue>{client.name || 'Нет данных'}</FieldValue>
              </Name>

              <StatusWrapper>
                <FieldTitle>Статус</FieldTitle>
                {offer.status === OfferStatus.Confirmed
                  ? <ConfirmedContent text={statusName} />
                  : <FieldValue>{statusName}</FieldValue>}
              </StatusWrapper>

              <Phone>
                <FieldTitle>Телефон</FieldTitle>
                {client.phone ? (
                  <FieldValue>
                    {showFullPhone
                      ? client.phone
                      : hideNumber(client.phone)}
                    {!showFullPhone && (
                      <PhoneShowFull onClick={() => setShowFullPhone(true)}>
                        Показать телефон
                      </PhoneShowFull>
                    )}
                  </FieldValue>
                ) : (
                  <FieldValue>
                    Нет данных
                  </FieldValue>
                )}
              </Phone>

              <ClientSex>
                <FieldTitle>Пол</FieldTitle>
                <FieldValue>{client.sex || 'Нет данных'}</FieldValue>
              </ClientSex>

              {!(showAdditionalPhoneBlock || showAdditionalPhoneBlockIsMyClient)
                ? (
                  <CityOfResidence>
                    <FieldTitle>Город проживания</FieldTitle>
                    <FieldValue>{client.cityOfResidence || 'Нет данных'}</FieldValue>
                  </CityOfResidence>
                )
                : (
                  <Phone>
                    <FieldTitle>Другой телефон</FieldTitle>
                    {client.additionalPhone ? (
                      <FieldValue>
                        {showFullAdditionalPhone
                          ? (
                            <>
                              {client.additionalPhone}
                              {offer.status !== OfferStatus.Confirmed
                              && (
                                (!isManager || isMyClient) && (
                                  <PhoneShowFull onClick={deleteAdditionalPhone} loading={isAdditionalPhoneLoading}>
                                    {isAdditionalPhoneLoading ? 'Удаление...' : 'Удалить'}
                                  </PhoneShowFull>
                                )
                              )}
                            </>
                          )
                          : (
                            <>
                              {hideNumber(client.additionalPhone)}

                              <PhoneShowFull onClick={() => setShowFullAdditionalPhone(true)}>
                                Показать телефон
                              </PhoneShowFull>
                            </>
                          )}

                      </FieldValue>
                    ) : (
                      <>
                        {offer.status !== OfferStatus.Confirmed
                        && (
                          <PhoneInput
                            mask="+7 (111) 111-11-11"
                            value={additionalPhone}
                            onChange={(e) => setAdditionalPhone(e.target.value)}
                            onSubmit={saveAdditionalPhone}
                            loading={isAdditionalPhoneLoading}
                          />
                        )}
                      </>
                    )}
                    {Boolean(additionalPhoneError) && <ErrorMessage>{additionalPhoneError}</ErrorMessage>}
                  </Phone>
                )}

              <BirthDay>
                <FieldTitle>Дата рождения</FieldTitle>
                {client.dateOfBirth ? (
                  <FieldValue>{moment(client.dateOfBirth).format('DD.MM.yy')}</FieldValue>
                ) : (
                  <FieldValue>Нет</FieldValue>
                )}
              </BirthDay>

              {(showAdditionalPhoneBlock || showAdditionalPhoneBlockIsMyClient)
              && (
                <CityOfResidence>
                  <FieldTitle>Город проживания</FieldTitle>
                  <FieldValue>{client.cityOfResidence || 'Нет данных'}</FieldValue>
                </CityOfResidence>
              )}
            </MainContent>
            {employee?.name && (isComments || canAddComment) && (
              <CommentsContainer>
                <CommentsContainerTitle>Комментарии</CommentsContainerTitle>
                {preparedComments.map((item) => (
                  <CommentContainer key={item.id}>
                    <CommentTitle>
                      {item.author?.name || 'Банк'}
                    </CommentTitle>
                    <Comment>
                      <CommentText>
                        {item?.text || ''}
                      </CommentText>
                      <CommentDate>
                        {moment(item.createdAt).isValid()
                          ? moment(item.createdAt).format('DD.MM')
                          : ''}
                        {' '}
                        {moment(item.createdAt).isValid()
                          ? moment(item.createdAt).format('HH:mm')
                          : ''}
                      </CommentDate>
                    </Comment>
                  </CommentContainer>
                ))}

                {comments.length > SHOWING_COMMENTS && (
                  <p>
                    <TCM.ButtonText onClick={() => setShowMoreComments(!showMoreComments)}>
                      {showMoreText}
                    </TCM.ButtonText>
                  </p>
                )}

                {canAddComment && (
                  <NewCommentContainer>
                    <NewCommentLabel>
                      Новый комментарий:
                    </NewCommentLabel>
                    <TCM.Textarea
                      placeholder="Введите текст"
                      onChange={({ target: { value } }) => setNewComment(value)}
                      value={newComment}
                    />
                    <TCM.ButtonOutline
                      disabled={!(newComment)}
                      onClick={handleSendNewComment}
                      loading={commentIsLoading}
                    >
                      Добавить
                    </TCM.ButtonOutline>
                  </NewCommentContainer>
                )}
              </CommentsContainer>
            )}
          </Content>
        )}
      </Main>
    </>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    // offer
    offer: state.tcmOffer.offer.data,
    commentIsLoaded: state.tcmOffer.comment.isLoaded,
    commentIsLoading: state.tcmOffer.comment.isLoading,
    isAdditionalPhoneLoading: state.tcmOffer.additionalPhone.isLoading,
    isAdditionalPhoneLoaded: state.tcmOffer.additionalPhone.isLoaded,
    additionalPhoneError: state.tcmOffer.additionalPhone.error,

    // employees
    employeesList: state.tcmEmployee.collection.list,
    employeesIsLoading: state.tcmEmployee.collection.isLoading,
    employeesIsLoaded: state.tcmEmployee.collection.isLoaded,
    employeesError: state.tcmEmployee.collection.error,

    // employee
    // employee: state.tcmOffer.employee.data,
    employeeIsLoading: state.tcmOffer.employee.isLoading,
    employeeIsLoaded: state.tcmOffer.employee.isLoaded,
    employeeError: state.tcmOffer.employee.error,
  }),
  {
    offerAddComment: offerAddCommentAction,
    postAdditionalPhone: postAdditionalPhoneAction,
    fetchEmployeesList: fetchEmployeesListAction,
    setEmployee: setEmployeeAction,
    setEmployeeReset: setEmployeeResetAction,
    fetchItem: fetchItemAction,
  },
)(ClientInfo);
