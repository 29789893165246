import { ErrorBoundary } from 'containers/App/TCM/ErrorBoundary';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { checkPrivilege } from 'helpers/roles';
import { RestrictedRoute, checkRedirectRoute } from 'router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';

import { DATE_FORMAT_WITH_TIME, TCM_REPORTS_EMPLOYEES, TCM_REPORTS_PERIOD } from 'settings';
import moment from 'moment';
import Page404 from 'components/tcm/Page404';
import CampaignsReport from './Reports/Campaings/CampagnsReport';
import EmployeesReport from './Reports/Employees/EmployeesReport';
import PeriodReport from './Reports/Period/PeriodReport';
import Layout from './Layout';
import MainTable from './MainTable/MainTable';
import CreateCampaignForm from './CreateCampaignForm/CreateCampaignForm';
import { ShowCampaign } from './ShowCampaign/ShowCampaign';
import Offer from './Оffer';
import Users from './Users';
import ClientCard from './ClientCard';
import { ClientsTable } from './ClientsTable';
import { ClientsTable as ManagerClientsTable } from './ManagerClientsTable';
import Modal from '../../../components/Modal';

const routes = [
  {
    path: '/tcm/campaign/list',
    component: MainTable,
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_CAMPAIGN,
      role.TCM.VIEW_CAMPAIGNS_COUNT,
    ]),
  },
  {
    path: '/tcm/campaign/offers/list',
    component: ManagerClientsTable,
    checkRights: (role) => {
      const showOffers = checkPrivilege([role.TCM.VIEW_MANAGER_OFFERS_COUNT]);
      const showCampaigns = checkPrivilege([role.TCM.VIEW_CAMPAIGN]);
      return showOffers && showCampaigns;
    },
  },
  {
    path: '/tcm/campaign/offers/:id',
    component: ClientCard,
    checkRights: (role) => {
      const showOffers = checkPrivilege([role.TCM.VIEW_MANAGER_OFFERS_COUNT]);
      const showCampaigns = checkPrivilege([role.TCM.VIEW_CAMPAIGN]);
      return showOffers && showCampaigns;
    },
  },
  {
    path: '/tcm/campaign/users/list',
    component: ClientsTable,
    checkRights: (role) => {
      const showOffers = checkPrivilege([role.TCM.VIEW_OFFERS_COUNT]);
      const showCampaigns = checkPrivilege([role.TCM.VIEW_CAMPAIGN]);
      return showOffers && showCampaigns;
    },
  },
  {
    path: '/tcm/campaign/users/:id',
    component: ClientCard,
    checkRights: (role) => {
      const showOffers = checkPrivilege([role.TCM.VIEW_OFFERS_COUNT]);
      const showCampaigns = checkPrivilege([role.TCM.VIEW_CAMPAIGN]);
      return showOffers && showCampaigns;
    },
  },
  {
    path: '/tcm/campaign/create',
    component: CreateCampaignForm,
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_CAMPAIGN,
    ]),
  },
  {
    path: '/tcm/campaign/:id',
    component: ShowCampaign,
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_CAMPAIGN,
    ]),
  },
  {
    path: '/tcm/client/list',
    component: ClientsTable,
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_OFFERS_COUNT,
    ]),
  },
  {
    path: '/tcm/client/:id',
    component: ClientCard,
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_OFFERS_COUNT,
    ]),
  },
  {
    path: '/tcm/offer/:id',
    component: Offer,
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_OFFER,
    ]),
  },
  {
    path: '/tcm/users',
    component: Users,
    checkRights: ((role) => checkPrivilege([
      role.TCM.VIEW_USERS,
    ])),
  },
  {
    path: '/tcm/reports/campaigns',
    component: CampaignsReport,
    checkRights: () => true,
  },
  ...(TCM_REPORTS_EMPLOYEES ? [
    {
      path: '/tcm/reports/employees',
      component: EmployeesReport,
      checkRights: () => true,
    },
  ] : []),
  ...(TCM_REPORTS_PERIOD ? [
    {
      path: '/tcm/reports/period',
      component: PeriodReport,
      checkRights: () => true,
    },
  ] : []),
  {
    path: '*',
    component: Page404,
    checkRights: () => true,
  },
];

export function Index({
  role,
  expiredAt,
  isNeedChangedSoon,
}) {
  const [showExpiredPasswordModal, setShowExpiredPasswordModal] = useState(false);
  // const api is need for contextHolder
  // eslint-disable-next-line no-unused-vars
  const [api, contextHolder] = message.useMessage();
  message.config({
    duration: 5,
    maxCount: 3,
    prefixCls: 'system-notice',
  });

  useEffect(() => {
    if (isNeedChangedSoon) {
      setShowExpiredPasswordModal(true);
    }
  }, [isNeedChangedSoon]);

  return (
    <ErrorBoundary>
      <ConfigProvider>
        {contextHolder}
        <Layout>
          <Switch>
            <Route exact path="/tcm">
              <Redirect to={checkRedirectRoute({ role, routes })} />
            </Route>

            {routes.map((route) => (
              <RestrictedRoute
                key={route.path}
                path={route.path}
                component={route.component}
                checkRights={route.checkRights}
              />
            ))}
          </Switch>
          <Modal
            title="Внимание"
            visible={showExpiredPasswordModal}
            onCancel={() => setShowExpiredPasswordModal(false)}
          >
            Срок действия вашего пароля истекает, пожалуйста смените его до
            {' '}
            {expiredAt && (
              moment(expiredAt).format(DATE_FORMAT_WITH_TIME)
            )}
          </Modal>
        </Layout>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    expiredAt: state.auth.expiredAt,
    isNeedChangedSoon: state.auth.isNeedChangedSoon,
  }),
  null,
)(Index);
