import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'components/Fields/Select';
import { fetchDealers as fetchDealerCenter } from 'redux/dealerCenter/actions';

function DealerCenter(props) {
  const {
    dealerCenterList,
    dealerCenterIsLoaded,
    dealerCenterIsLoading,
    dealerCenterError,
    fetchDealerCenter,
    ...passedProps
  } = props;

  useEffect(() => {
    if (!dealerCenterIsLoaded && !dealerCenterIsLoading && !dealerCenterError) {
      fetchDealerCenter();
    }
  }, [dealerCenterIsLoaded, dealerCenterIsLoading, dealerCenterError, fetchDealerCenter]);

  return <Select {...passedProps} options={dealerCenterList} />;
}

export default connect(
  (state) => ({
    dealerCenterList: state.dealerCenter.dealers.list,
    dealerCenterIsLoaded: state.dealerCenter.dealers.isLoaded,
    dealerCenterIsLoading: state.dealerCenter.dealers.isLoading,
    dealerCenterError: state.dealerCenter.dealers.error,
  }),
  {
    fetchDealerCenter,
  },
)(DealerCenter);
