import {
  fetchTopics,
  fetchList as campaignFetchList,
  create,
  update,
  fetchCampaign,
  setCampaignStatus,
  fetchListStatuses,
  fetchDialingDates,
  fetchCampaignOfferList,
  fetchFilterOptions,
  campaignReject,
  fetchCampaignOfferStatuses,
  fetchCampaignOffersList,
  getCalendarDate,
  getCampaignReport,
} from './campaign';

import {
  fetchList as offerFetchList,
  setEvaluation,
  fetchItem,
  setEmployee,
  exportOffer,
  importOffer,
  postCallResult,
  setOfferStatus,
  addComment,
  postVisitResult,
  fetchOfferStatuses,
  exportOldCar,
  postAdditionalPhone,
  getPreviousCampaign,
} from './offer';

import { getPeriodReport } from './period';

import { fetchEmployeesList, getEmployeeReport } from './employee';

import { reCalc, saveResult } from './creditCalculator';

import {
  exportReport,
  saveReportTemplate,
  getTcmReportSettings,
  getTcmReportList,
} from './report';

export default {
  campaign: {
    fetchList: campaignFetchList,
    fetchCampaignOfferList,
    fetchFilterOptions,
    fetchTopics,
    create,
    update,
    fetchCampaign,
    setCampaignStatus,
    fetchListStatuses,
    fetchDialingDates,
    campaignReject,
    fetchCampaignOfferStatuses,
    fetchCampaignOffersList,
    getCalendarDate,
    getCampaignReport,
  },
  offer: {
    fetchList: offerFetchList,
    fetchFilterOptions,
    setEvaluation,
    fetchItem,
    setEmployee,
    exportOffer,
    importOffer,
    postCallResult,
    setOfferStatus,
    addComment,
    postVisitResult,
    fetchOfferStatuses,
    exportOldCar,
    postAdditionalPhone,
    getPreviousCampaign,
  },
  period: { getPeriodReport },
  employee: {
    fetchList: fetchEmployeesList,
    getEmployeeReport,
  },
  creditCalculator: {
    reCalc,
    saveResult,
  },
  report: {
    exportReport,
    saveReportTemplate,
    getTcmReportSettings,
    getTcmReportList,
  },
};
