import React from 'react';
import { connect } from 'react-redux';

import {
  Root,
} from './style';
import ApprovedInformation from './ApprovedInformation';
import ApprovedInformationExtra from './ApprovedInformationExtra';
import DeclineWithRecommend from './DeclineWithRecommend';
import Decline from './Decline';
import NotAccepted from './NotAccepted';

const renderAnswer = ({ decision, data }) => {
  switch (decision) {
    case 'APPROVED':
      return <ApprovedInformation data={data} />;
    case 'APPROVED_ADD_COND':
      return <ApprovedInformationExtra data={data} />;
    case 'DEC_WITH_RECOMMEND':
      return <DeclineWithRecommend data={data} />;
    case 'DECLINE':
      return <Decline data={data} />;
    case 'NOT_ACCEPTED':
      return <NotAccepted data={data} />;
    default:
      return null;
  }
};

function Answer({
  // store
  data,
}) {
  const {
    decisionList,
  } = data;
  const decision = Array(decisionList) && decisionList[0] && decisionList[0].decision;
  return (
    <Root>
      {renderAnswer({ decision, data })}
    </Root>
  );
}

export default connect(
  (state) => ({
    data: state.request.details.data,
    isLoading: state.request.details.isLoading,
    error: state.request.details.error,
  }),
  null,
)(Answer);
