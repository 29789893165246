import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TCM } from 'components/tcm';
import { SuccessfulIcon, ErrorIcon } from 'icons';
import { ModalDescription, ModalTitle } from 'containers/App/TCM/style';
import { Form } from 'antd';
import { ErrorMessageAbsolute } from '../ErrorMessage';

const StyledIcon = styled(SuccessfulIcon)`
  margin-bottom: 29px;
`;

const SingleBtnWrapper = styled.div`
  margin: 32px 0 70px;
`;

const WithButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 71px;
  
  button {
    margin-top: 40px;
  }
`;

const WithoutButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 91px;
`;

const WithoutIcon = styled.div`
  display: grid;
  row-gap: 71px;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 90px;
`;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 90px;
  .ant-btn{
    min-width: 300px;
  }
  & .ant-form-item-label {
    padding:  0 4px 4px 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size:12px;
  }
`;

const ButtonWrapper = styled.div`
  position:relative;
  width: max-content;
`;

export const CreateSuccessfulModal = ({
  expectedDate, title, description, ...p
}) => (
  <TCM.Modal {...p}>
    <StyledIcon />
    <ModalTitle>{title}</ModalTitle>
    <ModalDescription>
      {expectedDate ? `${description} ${expectedDate}` : description}
    </ModalDescription>
    <SingleBtnWrapper>
      <TCM.ButtonOutline htmlType="button" widthSize="fixed" onClick={p.onCancel}>Закрыть</TCM.ButtonOutline>
    </SingleBtnWrapper>
  </TCM.Modal>
);

export const CreateErrorModal = ({
  title, description, btnTextCancel, left, ...p
}) => (
  <TCM.Modal {...p}>
    <StyledIcon as={ErrorIcon} />
    <ModalTitle>{title}</ModalTitle>
    <ModalDescription left={left}>
      {description}
    </ModalDescription>
    <SingleBtnWrapper>
      <TCM.ButtonOutline htmlType="button" widthSize="fixed" onClick={p.onCancel}>{btnTextCancel}</TCM.ButtonOutline>
    </SingleBtnWrapper>
  </TCM.Modal>
);

export const CreateDraftSuccessfulModal = ({ title, ...p }) => (
  <TCM.Modal {...p} closable={false}>
    <WithoutButton>
      <StyledIcon />
      <ModalTitle>{title}</ModalTitle>
    </WithoutButton>
  </TCM.Modal>
);

export const SaveFormModal = ({
  title,
  btnTextOk,
  btnTextCancel,
  onClose,
  onOkLoading,
  onOkError,
  ...p
}) => {
  const closeHandler = useMemo(
    () => onClose || p.onCancel,
    [onClose, p.onCancel],
  );

  return (
    <TCM.Modal {...p} onCancel={closeHandler}>
      <WithoutIcon>
        <ModalTitle>{title}</ModalTitle>
        <TCM.ButtonGroup>
          <ButtonWrapper>
            <TCM.Button onClick={p.onOk} loading={onOkLoading}>{btnTextOk}</TCM.Button>
            {onOkError && <ErrorMessageAbsolute>{onOkError}</ErrorMessageAbsolute>}
          </ButtonWrapper>
          <TCM.ButtonOutline onClick={p.onCancel}>{btnTextCancel}</TCM.ButtonOutline>
        </TCM.ButtonGroup>
      </WithoutIcon>
    </TCM.Modal>
  );
};

export const SaveFormModalOk = ({
  title,
  description,
  btnTextOk,
  btnTextCancel,
  onClose,
  ...p
}) => {
  const closeHandler = useMemo(
    () => onClose || p.onCancel,
    [onClose, p.onCancel],
  );

  return (
    <TCM.Modal {...p} onCancel={closeHandler}>
      <WithButton>
        <StyledIcon />
        <ModalTitle>{title}</ModalTitle>
        <ModalDescription>
          {description}
        </ModalDescription>
        <TCM.ButtonGroup>
          <TCM.Button onClick={p.onOk}>{btnTextOk}</TCM.Button>
          {btnTextCancel && <TCM.ButtonOutline onClick={p.onCancel}>{btnTextCancel}</TCM.ButtonOutline>}
        </TCM.ButtonGroup>
      </WithButton>
    </TCM.Modal>
  );
};

export const ModalWithChildren = ({
  title,
  btnTextOk,
  btnTextCancel,
  onClose,
  onOk,
  disabledOk,
  onOkLoading,
  onOkError,
  children,
  ...p
}) => {
  const closeHandler = useMemo(
    () => onClose || p.onCancel,
    [onClose, p.onCancel],
  );

  return (
    <TCM.Modal {...p} onCancel={closeHandler}>

      <Wrapper>
        <ModalTitle>{title}</ModalTitle>
        <Form layout="vertical">

          {children}

          <TCM.ButtonGroup>
            <ButtonWrapper>
              <TCM.Button onClick={onOk} loading={onOkLoading} disabled={disabledOk}>{btnTextOk}</TCM.Button>
              {onOkError && <ErrorMessageAbsolute>{onOkError}</ErrorMessageAbsolute>}
            </ButtonWrapper>
            <TCM.ButtonOutline onClick={p.onCancel}>{btnTextCancel}</TCM.ButtonOutline>
          </TCM.ButtonGroup>
        </Form>
      </Wrapper>
    </TCM.Modal>
  );
};
