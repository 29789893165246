import React, { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import API from 'services';
import { useDebounce } from 'hooks';
import { getListFromResponse } from 'hacks';

// eslint-disable-next-line import/named
import { AutoCompleteStyled, Option } from './style';

const initialState = {
  search: null,
  isFocused: false,
  isFetching: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FOCUS: {
      return {
        ...state,
        isFocused: true,
      };
    }
    case ACTIONS.BLUR: {
      return {
        ...state,
        isFocused: false,
      };
    }
    case ACTIONS.FETCH: {
      return {
        ...state,
        isFetching: true,
        search: action.search,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

export function OrganisationSearch({
  // passed
  name,
  value: initialValue,
  placeholder = 'Выберите организацию из списка',
  onChange,
  // store
  dealerCenter,
  dealerCenterIsLoaded,
}) {
  const [searchTerm, setSearchTerm] = useState(
    typeof initialValue === 'string' ? initialValue : undefined,
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (dealerCenterIsLoaded && dealerCenter) {
      const { legalEntityName } = dealerCenter;
      setSearchTerm(legalEntityName);
    }
  }, [dealerCenterIsLoaded, dealerCenter]);

  useEffect(() => {
    if (initialValue && typeof initialValue === 'string' && state.list.length > 0) {
      const organization = state.list.find((item) => item.inn === initialValue);
      if (organization) {
        setSearchTerm(organization.name);
      }
    }
  }, [setSearchTerm, initialValue, state]);

  useEffect(() => {
    if (debouncedSearchTerm !== state.search) {
      dispatch({ type: ACTIONS.FETCH, search: debouncedSearchTerm });
      API.company
        .fetchList({
          search: debouncedSearchTerm,
        })
        .then((results) => {
          const { list, hasError } = getListFromResponse({
            response: results,
            magicKey: 'legalEntityResponseList',
          });

          if (!hasError) {
            dispatch({ type: ACTIONS.FETCH_SUCCESS, list });
          } else {
            throw new Error('Возникла ошибка при получении списка организаций');
          }
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
        });
    }
  }, [state.search, debouncedSearchTerm]);

  function handleChange(v) {
    setSearchTerm(v);
    if (initialValue) {
      onChange({ [name]: v || '' });
    }
  }

  function handleSelect(v) {
    // setSearchTerm(v);
    if (!initialValue) {
      onChange({ [name]: v || '' });
    }
  }
  return (
    <AutoCompleteStyled
      name={name}
      value={searchTerm}
      placeholder={placeholder}
      onChange={handleChange}
      onSelect={handleSelect}
      loading={state.isFetching}
      notFoundContent={
        state.error ? (
          <span>{state.error}</span>
        ) : state.isFetching ? (
          <LoadingOutlined />
        ) : searchTerm && searchTerm.length < 3 ? (
          <sup>[запрос должен содержать от 3-ех символов]</sup>
        ) : (
          <span>нет данных</span>
        )
      }
    >
      {state.list.map((v) => (
        <Option key={v.id} value={v.name}>
          {v.name}
        </Option>
      ))}
    </AutoCompleteStyled>
  );
}

export default connect(
  (state) => ({
    dealerCenter: state.dealerCenter.item.data,
    dealerCenterIsLoaded: state.dealerCenter.item.isLoaded,
  }),
  null,
)(OrganisationSearch);
