import React from 'react';
import styled, { css } from 'styled-components';
import { Input as AntInput } from 'antd';
import withTheme from './withTheme';
import { Label } from './Label';

export const Textarea = withTheme(styled(({ theme, ...p }) => <AntInput.TextArea {...p} />)`
  ${({ theme }) => css`
    padding: ${theme.input.padding};
    border-color: ${theme.input.borderColor};
    border-radius: ${theme.input.borderRadius}px;
    font-family: ${theme.fontFamily};
    font-weight: ${theme.input.fontWeight};
    font-size: ${theme.input.fontSize}px;
    line-height: ${theme.input.lineHeight}px;
    color: ${theme.input.textColor};
    box-sizing: border-box;

    &::placeholder {
      font-weight: ${theme.input.fontWeightPlaceholder};
      font-size: ${theme.input.fontSize}px;
      line-height: ${theme.input.lineHeight}px;
      color: ${theme.input.textPlaceholderColor};
      opacity: 1;
    }

    &.invalid {
      border-color ${theme.input.borderInvalidColor};
    }
  `}
`);

export const LabeledTextarea = ({ label, ...p }) => (
  <Label label={label}>
    <Textarea {...p} />
  </Label>
);
