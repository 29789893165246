import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { SecondaryField } from 'containers/App/TCM/ClientCard/CallForm/style';
import { TCM } from 'components/tcm';
import { KEYS, NotInterestedOptions } from 'containers/App/TCM/ClientCard/CallForm/data';
import styled from 'styled-components';
import API from 'services';

const Group = styled.div`
  display: grid;
  grid-template-columns: 410px ${({ withDate }) => (withDate ? 180 : 380)}px;
  grid-gap: 20px;
`;

const ModelSubField = ({ options, loading }) => (
  <TCM.Label
    label={KEYS.STATUS.options.notInterested.children.cause.children.model.label}
    name={KEYS.STATUS.options.notInterested.children.cause.children.model.key}
    rules={KEYS.STATUS.options.notInterested.children.cause.children.model.rules}
    validateTrigger="onBlur"
  >
    <TCM.Select
      name={KEYS.STATUS.options.notInterested.children.cause.children.model.key}
      placeholder={KEYS.STATUS.options.notInterested.children.cause.children.model.placeholder}
      options={options}
      loading={loading}
    />
  </TCM.Label>
);

const initialState = {
  toyota: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: '',
  },
  lexus: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetchToyota':
      return {
        ...state,
        toyota: {
          ...state.toyota,
          isLoading: true,
        },
      };
    case 'fetchToyotaSuccess':
      return {
        ...state,
        toyota: {
          ...state.toyota,
          isLoading: false,
          isLoaded: true,
          data: action.data,
        },
      };
    case 'fetchToyotaFail':
      return {
        ...state,
        toyota: {
          ...state.toyota,
          isLoading: false,
          error: action.error,
        },
      };

    case 'fetchLexus':
      return {
        ...state,
        lexus: {
          ...state.toyota,
          isLoading: true,
        },
      };
    case 'fetchLexusSuccess':
      return {
        ...state,
        lexus: {
          ...state.toyota,
          isLoading: false,
          isLoaded: true,
          data: action.data,
        },
      };
    case 'fetchLexusFail':
      return {
        ...state,
        lexus: {
          ...state.toyota,
          isLoading: false,
          error: action.error,
        },
      };
    default:
      console.error('NotInterestedSubFields: invalid action type!');
  }
};

export const NotInterestedSubFields = () => {
  const [cause, setCause] = useState(undefined);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'fetchLexus' });
    API.car.find({ alias: 'model', dependencies: { brand: 'Lexus' } })
      .then((values) => {
        const preparedValues = values.data.valueList.map((value) => ({
          value: value.value,
          key: value.value,
        }));
        dispatch({ type: 'fetchLexusSuccess', data: preparedValues });
      });
  }, []);

  useEffect(() => {
    dispatch({ type: 'fetchToyota' });
    API.car.find({ alias: 'model', dependencies: { brand: 'Toyota' } })
      .then((values) => {
        const preparedValues = values.data.valueList.map((value) => ({
          value: value.value,
          key: value.value,
        }));
        dispatch({ type: 'fetchToyotaSuccess', data: preparedValues });
      });
  }, []);

  const groupWithDate = useMemo(() => cause === NotInterestedOptions.Late,
    [cause]);

  const onMainSelect = useCallback((cause) => {
    setCause(cause);
  }, []);

  const renderSubfield = useMemo(() => {
    switch (cause) {
      case NotInterestedOptions.SatisfiedCurrent:
        return (
          <TCM.Label
            label={KEYS.STATUS.options.notInterested.children.cause.children.why.label}
            name={KEYS.STATUS.options.notInterested.children.cause.children.why.key}
            rules={KEYS.STATUS.options.notInterested.children.cause.children.why.rules}
            validateTrigger="onBlur"
          >
            <TCM.Select
              name={KEYS.STATUS.options.notInterested.children.cause.children.why.key}
              options={KEYS.STATUS.options.notInterested.children.cause.children.why.options}
            />
          </TCM.Label>
        );
      case NotInterestedOptions.InterestedAnotherToyota:
        return (
          <ModelSubField options={state.toyota.data} loading={state.toyota.isLoading} />
        );
      case NotInterestedOptions.InterestedAnotherLexus:
        return (
          <ModelSubField options={state.lexus.data} loading={state.lexus.isLoading} />
        );
      case NotInterestedOptions.Late:
        return (
          <TCM.Label
            label={KEYS.STATUS.options.notInterested.children.cause.children.date.label}
            name={KEYS.STATUS.options.notInterested.children.cause.children.date.key}
            rules={KEYS.STATUS.options.notInterested.children.cause.children.date.rules}
            validateTrigger="onBlur"
          >
            <TCM.DatePicker
              name={KEYS.STATUS.options.notInterested.children.cause.children.why.key}
              rules={KEYS.STATUS.options.notInterested.children.cause.children.why.rules}
              allowClear={false}
            />
          </TCM.Label>
        );
      default:
        return null;
    }
  }, [
    cause,
    state.lexus.data,
    state.lexus.isLoading,
    state.toyota.data,
    state.toyota.isLoading,
  ]);

  return (
    <SecondaryField>
      <Group withDate={groupWithDate}>
        <TCM.Label
          name={KEYS.STATUS.options.notInterested.children.cause.key}
          label={KEYS.STATUS.options.notInterested.children.cause.label}
          rules={KEYS.STATUS.options.notInterested.children.cause.rules}
          validateTrigger="onBlur"
        >
          <TCM.Select
            name={KEYS.STATUS.options.notInterested.children.cause.key}
            placeholder={KEYS.STATUS.options.notInterested.children.cause.placeholder}
            options={KEYS.STATUS.options.notInterested.children.cause.options}
            onSelect={onMainSelect}
          />
        </TCM.Label>
        {renderSubfield}
      </Group>
    </SecondaryField>
  );
};
