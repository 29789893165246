export const PRODUCT__FIND = 'PRODUCT__FIND';
export const PRODUCT__FIND_LOADING = 'PRODUCT__FIND_LOADING';
export const PRODUCT__FIND_SUCCESS = 'PRODUCT__FIND_SUCCESS';
export const PRODUCT__FIND_FAILURE = 'PRODUCT__FIND_FAILURE';
export const find = ({
  dealerCenterName, group, brand, model, accessTokenCreditRequest,
}) => ({
  type: PRODUCT__FIND,
  dealerCenterName,
  group,
  brand,
  model,
  accessTokenCreditRequest,
});
