import styled from 'styled-components';

const Root = styled.div`
  font-size: 1.4rem;

  &:first-of-type {
    padding: 0.5rem;
    display: grid;
  }

  &:last-of-type {
    padding: 0.5rem;
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
  }

  @media (min-width: 1200px) {
  }
`;

export { Root };
