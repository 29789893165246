import moment from 'moment';
import {
  OFFER_FETCH_UNDERWAY_LIST,
  OFFER_FETCH_UNDERWAY_LIST__FAILURE,
  OFFER_FETCH_UNDERWAY_LIST__SUCCESS,
  OFFER_FETCH_UNDERWAY_LIST__SET_SORTING,
  OFFER_FETCH_UNDERWAY_LIST__SET_FILTER,

  OFFER_FETCH_WAIT_CONFIRM_LIST,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SUCCESS,
  OFFER_FETCH_WAIT_CONFIRM_LIST__FAILURE,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER,

  OFFER_FETCH_CONFIRMED_LIST,
  OFFER_FETCH_CONFIRMED_LIST__FAILURE,
  OFFER_FETCH_CONFIRMED_LIST__SUCCESS,
  OFFER_FETCH_CONFIRMED_LIST__SET_SORTING,
  OFFER_FETCH_CONFIRMED_LIST__SET_FILTER,

  OFFER_FETCH_REVISION_LIST,
  OFFER_FETCH_REVISION_LIST__FAILURE,
  OFFER_FETCH_REVISION_LIST__SUCCESS,
  OFFER_FETCH_REVISION_LIST__SET_SORTING,
  OFFER_FETCH_REVISION_LIST__SET_FILTER,

  OFFER_FETCH_AGREEING_LIST,
  OFFER_FETCH_AGREEING_LIST__FAILURE,
  OFFER_FETCH_AGREEING_LIST__SUCCESS,
  OFFER_FETCH_AGREEING_LIST__SET_SORTING,
  OFFER_FETCH_AGREEING_LIST__SET_FILTER,
  OFFER_FETCH_AGREEING_LIST__SET_LOADING,

  OFFER_FETCH_REFUSED_LIST,
  OFFER_FETCH_REFUSED_LIST__FAILURE,
  OFFER_FETCH_REFUSED_LIST__SUCCESS,
  OFFER_FETCH_REFUSED_LIST__SET_SORTING,
  OFFER_FETCH_REFUSED_LIST__SET_FILTER,
  OFFER_FETCH_REFUSED_LIST__SET_LOADING,

  OFFERS_STATUS_SET,
  OFFERS_STATUS_SET__FAILURE,
  OFFERS_STATUS_SET__RESET,
  OFFERS_STATUS_SET_SUCCESS,
  POST_VISIT_RESULT,
  POST_VISIT_RESULT__FAILURE,
  POST_VISIT_RESULT__RESET,
  POST_VISIT_RESULT__SUCCESS,
  OFFER_FETCH_STATUSES,
  OFFER_FETCH_STATUSES__SUCCESS,
  OFFER_FETCH_STATUSES__FAILURE,
  OFFER_FETCH_UNDERWAY_LIST__SET_LOADING,
  OFFER_FETCH_WAIT_CONFIRM_LIST__SET_LOADING,
  OFFER_FETCH_CONFIRMED_LIST__SET_LOADING,
  OFFER_FETCH_REVISION_LIST__SET_LOADING,
  OFFER_FETCH_COMMON__SET_SEARCH,
  OLD_CAR_EXPORT,
  OLD_CAR_EXPORT__SUCCESS,
  OLD_CAR_EXPORT__FAILURE,
  OLD_CAR_EXPORT__RESET,
  OFFER_FETCH__RESET,
  POST_ADDITIONAL_PHONE,
  POST_ADDITIONAL_PHONE__SUCCESS,
  POST_ADDITIONAL_PHONE__FAILURE,
} from 'redux/tcmOffer/actions';

import {
  OFFER_FETCH_LIST,
  OFFER_FETCH_LIST__SUCCESS,
  OFFER_FETCH_LIST__FAILURE,
  OFFER_FETCH_LIST__SET_LOADING,
  OFFER_FETCH_LIST__SET_PAGINATION,
  OFFER_FETCH_LIST__SET_SEARCH,
  OFFER_FETCH_LIST__SET_SORTING,
  OFFER_FETCH_LIST__SET_FILTER,
  OFFER_FETCH_LIST__RESET,

  OFFER_EVALUATION_SET,
  OFFER_EVALUATION_SET__SUCCESS,
  OFFER_EVALUATION_SET__FAILURE,
  OFFER_EVALUATION_SET__RESET,

  OFFER_FETCH,
  OFFER_FETCH__SUCCESS,
  OFFER_FETCH__FAILURE,
  OFFER_EMPLOYEE_SET,
  OFFER_EMPLOYEE_SET__SUCCESS,
  OFFER_EMPLOYEE_SET__FAILURE,
  OFFER_EMPLOYEE_SET__RESET,
  OFFER_EXPORT,
  OFFER_EXPORT__SUCCESS,
  OFFER_EXPORT__FAILURE,
  OFFER_IMPORT,
  OFFER_IMPORT__SUCCESS,
  OFFER_IMPORT__FAILURE,

  OFFER_EXPORT_IMPORT__RESET,

  OFFER_ADD_COMMENT,
  OFFER_ADD_COMMENT__SUCCESS,
  OFFER_ADD_COMMENT__FAILURE,

  OFFER_FETCH_PREVIOUS_CAMPAIGN,
  OFFER_FETCH_PREVIOUS_CAMPAIGN__SUCCESS,
  OFFER_FETCH_PREVIOUS_CAMPAIGN__FAILURE,
  OFFER_FETCH_PREVIOUS_CAMPAIGN__RESET,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultSortingUnderwayList = {
  sortBy: 'status.in_work',
  order: ORDER.ASC,
};

const changeSorting = (currentValue, sortBy) => {
  if (currentValue.sortBy !== sortBy) {
    return {
      sortBy,
      order: ORDER.DESC,
    };
  } if (currentValue.order === ORDER.ASC) {
    return defaultSorting;
  }
  return {
    sortBy: currentValue.sortBy,
    order: ORDER.ASC,
  };
};

const getTagsFromFilter = (filter) => {
  const models = filter.models
    ? filter.models?.map((t) => ({
      label: t.label,
      key: 'models',
    }))
    : [];

  const brands = filter.brands
    ? filter.brands?.map((t) => ({
      label: t.label,
      key: 'brands',
    }))
    : [];

  const suffixTMS = filter.suffixTMS
    ? filter.suffixTMS?.map((t) => ({
      label: t.label,
      key: 'suffixTMS',
    }))
    : [];

  const employees = filter.employees
    ? filter.employees?.map((t) => ({
      label: t.label,
      key: 'employees',
    }))
    : [];

  const priceTradeInFrom = filter.priceTradeInFrom && {
    label: `Цена Trade-In от ${filter.priceTradeInFrom}`,
    key: 'priceTradeInFrom',
  };

  const priceTradeInTo = filter.priceTradeInTo && {
    label: `Цена Trade-In до ${filter.priceTradeInTo}`,
    key: 'priceTradeInTo',
  };

  const priceNewCarFrom = filter.priceNewCarFrom && {
    label: `Цена нового автомобиля от ${filter.priceNewCarFrom}`,
    key: 'priceNewCarFrom',
  };

  const priceNewCarTo = filter.priceNewCarTo && {
    label: `Цена нового автомобиля до ${filter.priceNewCarTo}`,
    key: 'priceNewCarTo',
  };

  const termFulfillmentFrom = filter.termFulfillmentFrom && {
    label: `Срок выполнения от ${moment(filter.termFulfillmentFrom).format('DD.MM.YYYY')}`,
    key: 'termFulfillmentFrom',
  };

  const termFulfillmentTo = filter.termFulfillmentTo && {
    label: `Срок выполнения до ${moment(filter.termFulfillmentTo).format('DD.MM.YYYY')}`,
    key: 'termFulfillmentTo',
  };

  const campaignId = filter.campaignId && {
    label: filter.campaignId.label,
    key: 'campaignId',
  };

  const callCountMustBeLess = filter.callCountMustBeLess === 0 && {
    label: 'Звонков не было',
    key: 'callCountMustBeLess',
  };

  const callCountMustBeMore = filter.callCountMustBeMore === 1 && {
    label: '1 и более звонков',
    key: 'callCountMustBeMore',
  };

  const visitCountMustBeLess = filter.visitCountMustBeLess === 0 && {
    label: 'Визитов не было',
    key: 'visitCountMustBeLess',
  };

  const visitCountMustBeMore = filter.visitCountMustBeMore === 1 && {
    label: 'Клиент приезжал в ДЦ',
    key: 'visitCountMustBeMore',
  };

  const callStatus = filter.callStatus && {
    label: filter.callStatus.label,
    key: 'callStatus',
  };

  const vin = filter.vin && {
    label: filter.vin,
    key: 'vin',
  };

  const visitStatuses = filter.visitStatuses?.length > 0
    ? filter.visitStatuses.map((t) => ({
      label: t.label,
      key: 'visitStatuses',
    }))
    : [];

  return [
    ...models,
    ...brands,
    ...suffixTMS,
    ...employees,
    priceTradeInFrom,
    priceTradeInTo,
    priceNewCarFrom,
    priceNewCarTo,
    termFulfillmentFrom,
    termFulfillmentTo,
    campaignId,
    callCountMustBeLess,
    callCountMustBeMore,
    visitCountMustBeLess,
    visitCountMustBeMore,
    callStatus,
    ...visitStatuses,
    vin,
  ].filter((tag) => Boolean(tag));
};

export const defaultPageSize = 50;

/**
 * @type {{
 *  models: string[],
 *  brands: string[],
 *  suffixTMS: string[],
 *  employees: string[],
 *  priceTradeInFrom: number | undefined,
 *  priceTradeInTo: number | undefined
 *  priceNewCarFrom: number | undefined,
 *  priceNewCarTo: number | undefined,
 * }}
 */
export const initialFilters = {
  brands: [],
  models: [],
  suffixTMS: [],
  employees: [],
  statuses: [],
  tradeInFrom: undefined,
  tradeInTo: undefined,
  priceNewCarFrom: undefined,
  priceNewCarTo: undefined,
  termFulfillmentFrom: undefined,
  termFulfillmentTo: undefined,
  campaignId: undefined,
  vin: undefined,
  callsCount: undefined,
  visitsCount: undefined,
  callStatus: undefined,
  visitStatuses: undefined,
};

const initState = {
  statuses: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  collection: {
    data: {},
    list: [],
    pagination: {},
    sorting: defaultSorting,
    search: '',
    filters: {
      data: initialFilters,
      tags: [],
    },
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,

    underway: {
      list: [],
      sorting: defaultSortingUnderwayList,
      search: '',
      filters: {
        data: initialFilters,
        tags: [],
      },
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    waitConfirm: {
      list: [],
      sorting: defaultSorting,
      search: '',
      filters: {
        data: initialFilters,
        tags: [],
      },
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    confirmed: {
      list: [],
      sorting: defaultSorting,
      search: '',
      filters: {
        data: initialFilters,
        tags: [],
      },
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    revision: {
      list: [],
      sorting: defaultSorting,
      search: '',
      filters: {
        data: initialFilters,
        tags: [],
      },
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    agreeing: {
      list: [],
      sorting: defaultSorting,
      search: '',
      filters: {
        data: initialFilters,
        tags: [],
      },
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    refused: {
      list: [],
      sorting: defaultSorting,
      search: '',
      filters: {
        data: initialFilters,
        tags: [],
      },
      isLoading: false,
      isLoaded: false,
      error: null,
    },
  },

  filters: {
    brands: [],
    models: [],
    suffixTMS: [],
  },

  evaluation: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  offer: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  employee: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  export: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  import: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  statusSet: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,

    CONFIRMED: {
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    REVISION: {
      isLoading: false,
      isLoaded: false,
      error: null,
    },
  },

  loadedClientsNumber: null,

  comment: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  visitResult: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  additionalPhone: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  previousCampaign: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function offerReducer(state = initState, action) {
  switch (action.type) {
    case OFFER_FETCH_STATUSES: {
      const isOldDataLoaded = Boolean(state.statuses.list.length);
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: !isOldDataLoaded,
          isLoaded: isOldDataLoaded,
          error: null,
        },
      };
    }
    case OFFER_FETCH_STATUSES__SUCCESS: {
      return {
        ...state,
        statuses: {
          list: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case OFFER_FETCH_STATUSES__FAILURE: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case OFFER_FETCH_COMMON__SET_SEARCH:
    case OFFER_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }
    case OFFER_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }
    case OFFER_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: changeSorting(state.collection.sorting, action.sortBy),
        },
      };
    }
    case OFFER_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: {
            data: action.filters,
            tags: getTagsFromFilter(action.filters),
          },
        },
      };
    }

    case OFFER_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
        filters: initState.filters,
      };
    }
    case OFFER_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          pagination: action.pagination || {},
          page: (action.pagination && action.pagination.page) || 1,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
        filters: action.filters,
      };
    }
    case OFFER_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case OFFER_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }
    case OFFER_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case OFFER_FETCH_UNDERWAY_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,
          underway: {
            ...state.collection.underway,
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_UNDERWAY_LIST__SUCCESS: {
      return {
        ...state,
        collection: {
          ...state.collection,
          underway: {
            ...state.collection.underway,
            list: action.list,
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_UNDERWAY_LIST__FAILURE: {
      return {
        ...state,
        collection: {
          ...state.collection,
          underway: {
            ...state.collection.underway,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }
    case OFFER_FETCH_UNDERWAY_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,
          underway: {
            ...state.collection.underway,
            filters: {
              data: action.filters,
              tags: getTagsFromFilter(action.filters),
            },
          },
        },
      };
    }
    case OFFER_FETCH_UNDERWAY_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          underway: {
            ...state.collection.underway,
            sorting: changeSorting(state.collection.underway.sorting, action.sortBy),
          },
        },
      };
    }
    case OFFER_FETCH_UNDERWAY_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          underway: {
            ...state.collection.underway,
            isLoading: true,
          },
        },
      };
    }

    case OFFER_FETCH_WAIT_CONFIRM_LIST: {
      return {
        ...state,
        collection: {
          ...state.collection,
          waitConfirm: {
            ...state.collection.waitConfirm,
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_WAIT_CONFIRM_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,
          waitConfirm: {
            ...state.collection.waitConfirm,

            list: action.list,
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_WAIT_CONFIRM_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,
          waitConfirm: {
            ...state.collection.waitConfirm,

            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }
    case OFFER_FETCH_WAIT_CONFIRM_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,
          waitConfirm: {
            ...state.collection.waitConfirm,
            filters: {
              data: action.filters,
              tags: getTagsFromFilter(action.filters),
            },
          },
        },
      };
    }
    case OFFER_FETCH_WAIT_CONFIRM_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          waitConfirm: {
            ...state.collection.waitConfirm,
            sorting: changeSorting(state.collection.waitConfirm.sorting, action.sortBy),
          },
        },
      };
    }
    case OFFER_FETCH_WAIT_CONFIRM_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          waitConfirm: {
            ...state.collection.waitConfirm,
            isLoading: true,
          },
        },
      };
    }

    case OFFER_FETCH_CONFIRMED_LIST: {
      return {
        ...state,
        collection: {
          ...state.collection,
          confirmed: {
            ...state.collection.confirmed,
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_CONFIRMED_LIST__SUCCESS: {
      return {
        ...state,
        collection: {
          ...state.collection,
          confirmed: {
            ...state.collection.confirmed,
            list: action.list,
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_CONFIRMED_LIST__FAILURE: {
      return {
        ...state,
        collection: {
          ...state.collection,
          confirmed: {
            ...state.collection.confirmed,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }
    case OFFER_FETCH_CONFIRMED_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,
          confirmed: {
            ...state.collection.confirmed,
            filters: {
              data: action.filters,
              tags: getTagsFromFilter(action.filters),
            },
          },
        },
      };
    }
    case OFFER_FETCH_CONFIRMED_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          confirmed: {
            ...state.collection.confirmed,
            sorting: changeSorting(state.collection.confirmed.sorting, action.sortBy),
          },
        },
      };
    }
    case OFFER_FETCH_CONFIRMED_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          confirmed: {
            ...state.collection.confirmed,
            isLoading: true,
          },
        },
      };
    }

    case OFFER_FETCH_REVISION_LIST: {
      return {
        ...state,
        collection: {
          ...state.collection,
          revision: {
            ...state.collection.revision,
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_REVISION_LIST__SUCCESS: {
      return {
        ...state,
        collection: {
          ...state.collection,
          revision: {
            ...state.collection.revision,
            list: action.list,
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_REVISION_LIST__FAILURE: {
      return {
        ...state,
        collection: {
          ...state.collection,
          revision: {
            ...state.collection.revision,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }
    case OFFER_FETCH_REVISION_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,
          revision: {
            ...state.collection.revision,
            filters: {
              data: action.filters,
              tags: getTagsFromFilter(action.filters),
            },
          },
        },
      };
    }
    case OFFER_FETCH_REVISION_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          revision: {
            ...state.collection.revision,
            sorting: changeSorting(state.collection.confirmed.sorting, action.sortBy),
          },
        },
      };
    }
    case OFFER_FETCH_REVISION_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          revision: {
            ...state.collection.revision,
            isLoading: true,
          },
        },
      };
    }

    case OFFER_FETCH_AGREEING_LIST: {
      return {
        ...state,
        collection: {
          ...state.collection,
          agreeing: {
            ...state.collection.agreeing,
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_AGREEING_LIST__SUCCESS: {
      return {
        ...state,
        collection: {
          ...state.collection,
          agreeing: {
            ...state.collection.agreeing,
            list: action.list,
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_AGREEING_LIST__FAILURE: {
      return {
        ...state,
        collection: {
          ...state.collection,
          agreeing: {
            ...state.collection.agreeing,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }
    case OFFER_FETCH_AGREEING_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,
          agreeing: {
            ...state.collection.agreeing,
            filters: {
              data: action.filters,
              tags: getTagsFromFilter(action.filters),
            },
          },
        },
      };
    }
    case OFFER_FETCH_AGREEING_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          agreeing: {
            ...state.collection.agreeing,
            sorting: changeSorting(state.collection.confirmed.sorting, action.sortBy),
          },
        },
      };
    }
    case OFFER_FETCH_AGREEING_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          agreeing: {
            ...state.collection.agreeing,
            isLoading: true,
          },
        },
      };
    }

    case OFFER_FETCH_REFUSED_LIST: {
      return {
        ...state,
        collection: {
          ...state.collection,
          refused: {
            ...state.collection.refused,
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_REFUSED_LIST__SUCCESS: {
      return {
        ...state,
        collection: {
          ...state.collection,
          refused: {
            ...state.collection.refused,
            list: action.list,
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFER_FETCH_REFUSED_LIST__FAILURE: {
      return {
        ...state,
        collection: {
          ...state.collection,
          refused: {
            ...state.collection.refused,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }
    case OFFER_FETCH_REFUSED_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,
          refused: {
            ...state.collection.refused,
            filters: {
              data: action.filters,
              tags: getTagsFromFilter(action.filters),
            },
          },
        },
      };
    }
    case OFFER_FETCH_REFUSED_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          refused: {
            ...state.collection.refused,
            sorting: changeSorting(state.collection.confirmed.sorting, action.sortBy),
          },
        },
      };
    }
    case OFFER_FETCH_REFUSED_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          refused: {
            ...state.collection.refused,
            isLoading: true,
          },
        },
      };
    }

    case OFFER_EVALUATION_SET: {
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          isLoading: true,
        },
      };
    }
    case OFFER_EVALUATION_SET__SUCCESS: {
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case OFFER_EVALUATION_SET__FAILURE: {
      return {
        ...state,
        evaluation: {
          ...state.evaluation,
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case OFFER_EVALUATION_SET__RESET: {
      return {
        ...state,

        evaluation: {
          ...initState.evaluation,
        },
      };
    }

    case OFFER_FETCH: {
      return {
        ...state,
        offer: {
          ...state.offer,
          isLoading: true,
          error: null,
        },
      };
    }
    case OFFER_FETCH__SUCCESS: {
      return {
        ...state,
        offer: {
          ...state.offer,
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
        comment: {
          ...initState.comment,
        },
      };
    }
    case OFFER_FETCH__FAILURE: {
      return {
        ...state,
        offer: {
          ...state.offer,
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case OFFER_FETCH__RESET: {
      return {
        ...state,
        offer: {
          ...initState.offer,
        },
      };
    }

    case OFFER_EMPLOYEE_SET: {
      return {
        ...state,
        employee: {
          ...initState.employee,
          isLoading: true,
        },
      };
    }
    case OFFER_EMPLOYEE_SET__SUCCESS: {
      return {
        ...state,
        employee: {
          ...state.employee,
          data: action.value,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case OFFER_EMPLOYEE_SET__FAILURE: {
      return {
        ...state,
        employee: {
          ...state.employee,
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case OFFER_EMPLOYEE_SET__RESET: {
      return {
        ...state,
        employee: {
          ...initState.employee,
        },
      };
    }

    case OFFER_EXPORT: {
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: true,
        },
      };
    }
    case OFFER_EXPORT__SUCCESS: {
      return {
        ...state,
        export: {
          ...state.export,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case OFFER_EXPORT__FAILURE: {
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: false,
          error: action.error,
        },
      };
    }

    case OFFER_IMPORT: {
      return {
        ...state,
        import: {
          isLoaded: false,
          isLoading: true,
          error: null,
        },
      };
    }
    case OFFER_IMPORT__SUCCESS: {
      return {
        ...state,
        import: {
          ...state.import,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case OFFER_IMPORT__FAILURE: {
      return {
        ...state,
        import: {
          isLoaded: false,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case OFFER_EXPORT_IMPORT__RESET: {
      return {
        ...state,
        import: {
          ...initState.import,
        },
        export: {
          ...initState.export,
        },
      };
    }

    case OFFERS_STATUS_SET: {
      return {
        ...state,

        statusSet: {
          ...state.statusSet,
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
          [action.status]: {
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
        loadedClientsNumber: action.offerIds.length,
      };
    }
    case OFFERS_STATUS_SET_SUCCESS: {
      return {
        ...state,

        statusSet: {
          ...state.statusSet,
          data: action.data,
          isLoading: false,
          isLoaded: true,
          [action.status]: {
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case OFFERS_STATUS_SET__FAILURE: {
      return {
        ...state,

        statusSet: {
          ...state.statusSet,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          [action.status]: {
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }

    case OFFERS_STATUS_SET__RESET: {
      return {
        ...state,

        statusSet: {
          ...initState.statusSet,
        },
      };
    }

    case OFFER_ADD_COMMENT: {
      return {
        ...state,
        comment: {
          isLoaded: false,
          isLoading: true,
          error: null,
        },
      };
    }
    case OFFER_ADD_COMMENT__SUCCESS: {
      return {
        ...state,
        comment: {
          ...state.comment,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case OFFER_ADD_COMMENT__FAILURE: {
      return {
        ...state,
        comment: {
          isLoaded: false,
          isLoading: false,
          error: action.error,
        },
      };
    }

    case POST_VISIT_RESULT:
    {
      return {
        ...state,
        visitResult: {
          isLoaded: false,
          isLoading: true,
          error: null,
        },
      };
    }
    case POST_VISIT_RESULT__SUCCESS: {
      return {
        ...state,
        visitResult: {
          ...state.visitResult,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case POST_VISIT_RESULT__FAILURE: {
      return {
        ...state,
        visitResult: {
          isLoaded: false,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case POST_VISIT_RESULT__RESET: {
      return {
        ...state,
        visitResult: initState.visitResult,
      };
    }
    case OLD_CAR_EXPORT: {
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: true,
        },
      };
    }
    case OLD_CAR_EXPORT__SUCCESS: {
      return {
        ...state,
        export: {
          ...state.export,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case OLD_CAR_EXPORT__FAILURE: {
      return {
        ...state,
        export: {
          ...state.export,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case OLD_CAR_EXPORT__RESET: {
      return {
        ...state,
        export: {
          ...initState.export,
        },
      };
    }

    case POST_ADDITIONAL_PHONE: {
      return {
        ...state,
        additionalPhone: {
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case POST_ADDITIONAL_PHONE__SUCCESS: {
      return {
        ...state,
        additionalPhone: {
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case POST_ADDITIONAL_PHONE__FAILURE: {
      return {
        ...state,
        additionalPhone: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case OFFER_FETCH_PREVIOUS_CAMPAIGN__RESET: {
      return {
        ...state,
        previousCampaign: {
          ...initState.previousCampaign,
        },
      };
    }
    case OFFER_FETCH_PREVIOUS_CAMPAIGN: {
      return {
        ...state,
        previousCampaign: {
          list: [],
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case OFFER_FETCH_PREVIOUS_CAMPAIGN__SUCCESS: {
      return {
        ...state,
        previousCampaign: {
          list: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case OFFER_FETCH_PREVIOUS_CAMPAIGN__FAILURE: {
      return {
        ...state,
        previousCampaign: {
          list: [],
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
