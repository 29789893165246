import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import {
  Alert, Select, Form, Input,
} from 'antd';
import { Rifm } from 'rifm';
import merge from 'merge';
import { formatPhone, parseDigits, cursorToLastPhoneValuePosition } from 'utils';

import {
  createMerchant as createMerchantAction,
  createMerchantReset as createMerchantResetAction,
} from 'redux/admin/actions';

import {
  fetchAvailableRoles as fetchAvailableRolesAction,
} from 'redux/user/actions';

import SearchField from './SearchField';

import {
  Root,
  Main,
  MainTitle,
  MainSubtitle,
  MainDivider,
  Aside,
  Header,
  AsideTitle,
  UserList,
  User,
  UserHeader,
  UserTitle,
  UserDelete,
  Footer,
  ErrorMessage,
  Result,
  ResultTitle,
} from './style';

const rulesCompany = [
  {
    required: true,
    message: 'Выберите компанию или введите ИНН',
  },
];

const rulesInn = [
  {
    required: true,
    message: 'ИНН не может быть пустым',
  },
];

const rulesName = [
  {
    required: true,
    message: 'Имя не может быть пустым',
  },
];

const rulesPhone = [
  formatPhone.validators.validateIfNotEmpty,
  formatPhone.validators.validateIfNotFilled,
];

const rulesEmail = [
  {
    required: true,
    message: 'Емайл не может быть пустым',
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)
        ? Promise.resolve()
        : Promise.reject('Невалидный емайл');
    },
  },
];

const rulesRoles = [
  {
    required: true,
    message: 'Выберите одну из ролей',
  },
];

const K = {
  COMPANY: {
    key: 'COMPANY',
    apiKey: 'name',
    title: 'Компания',
    rules: rulesCompany,
  },
  INN: {
    key: 'INN',
    apiKey: 'inn',
    title: 'ИНН',
    rules: rulesInn,
  },
  ADMIN_NAME: {
    key: 'ADMIN_NAME',
    apiKey: 'fullName',
    title: 'ФИО',
    rules: rulesName,
  },
  ADMIN_PHONE: {
    key: 'ADMIN_PHONE',
    apiKey: 'phone',
    title: 'Телефон',
    rules: rulesPhone,
  },
  ADMIN_EMAIL: {
    key: 'ADMIN_EMAIL',
    apiKey: 'email',
    title: 'Е-майл',
    rules: rulesEmail,
  },
  ADMIN_TYPES: {
    fullName: 'ADMIN_NAME',
    phone: 'ADMIN_PHONE',
    email: 'ADMIN_EMAIL',
  },
  USERS: {
    key: 'USERS',
  },
  USER_ROLE: {
    key: 'USER_ROLE',
    apiKey: 'roles',
    title: 'Роли',
    rules: rulesRoles,
  },
  USER_NAME: {
    key: 'USER_NAME',
    apiKey: 'fullName',
    title: 'ФИО',
    rules: rulesName,
  },
  USER_PHONE: {
    key: 'USER_PHONE',
    apiKey: 'phone',
    title: 'Телефон',
    rules: rulesPhone,
  },
  USER_EMAIL: {
    key: 'USER_EMAIL',
    apiKey: 'email',
    title: 'Е-майл',
    rules: rulesEmail,
  },
};

function CreateUser({
  // connect
  error,
  errors,
  isLoading,
  isLoaded,
  roleList,
  roleIsLoading,
  roleError,

  // actions
  createMerchant,
  createMerchantReset,
  fetchAvailableRoles,
}) {
  const [form] = Form.useForm();
  const phoneField = useRef();
  const adminPhoneField = useRef();
  const [isValidated, setIsValidated] = useState(true);
  const [nestedErrors, setNestedErrors] = useState({});

  function renderNestedFieldError({ id, name }) {
    return {
      ...(nestedErrors[id] && nestedErrors[id][name]
        ? {
          validateStatus: 'error',
          help: nestedErrors[id][name],
        }
        : {}),
    };
  }

  useEffect(() => {
    fetchAvailableRoles();
  }, [fetchAvailableRoles]);

  useEffect(
    () => () => {
      createMerchantReset();
    },
    [createMerchantReset],
  );

  useEffect(() => {
    if (!isValidated && Array.isArray(errors) && errors.length > 0) {
      const errorsFlat = errors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));
      const errorsNested = merge.recursive(true, ...errorsFlat);
      const {
        name: company, inn, adminUser, users,
      } = errorsNested;

      setNestedErrors(users || {});

      form.setFields([
        ...(company ? [{ name: 'COMPANY', errors: [company] }] : []),
        ...(inn ? [{ name: 'INN', errors: [inn] }] : []),
        ...(adminUser
          ? Object.keys(adminUser).map((key) => ({
            name: [K.ADMIN_TYPES[key]],
            errors: [adminUser[key]],
          }))
          : []),
      ]);
      setIsValidated(true);
    }
  }, [errors, form, isValidated, setIsValidated]);

  function handleFinish(values) {
    const {
      COMPANY: company,
      INN: inn,
      ADMIN_NAME: adminName,
      ADMIN_PHONE: adminPhone,
      ADMIN_EMAIL: adminEmail,
      USERS: users,
    } = values;

    createMerchant({
      name: company,
      inn,
      admin: {
        fullName: adminName,
        phone: formatPhone.prefix + parseDigits(adminPhone),
        email: adminEmail,
      },
      ...(Array.isArray(users) && users.length > 0
        ? {
          users: users.map((e) => ({
            fullName: e.USER_NAME,
            phone: formatPhone.prefix + parseDigits(e.USER_PHONE),
            email: e.USER_EMAIL,
            roles: [e.USER_ROLE], // explicitly pass an array
          })),
        }
        : {}),
    });
    setIsValidated(false);
  }

  function onChangeCallback({ name, value, data }) {
    if (
      data
      && data.data
      && data.data.additionalValues
      && data.data.additionalValues.inn
    ) {
      form.setFields([
        { name, value },
        { name: K.INN.key, value: data.data.additionalValues.inn },
      ]);
    } else {
      form.setFields([
        { name, value },
        { name: K.INN.key, value: '' },
      ]);
    }
  }

  function removeErrorFromNestedField(name) {
    const [id, field] = name.split(',');

    const filteredNestedFieldErrors = Object.keys(nestedErrors).reduce((p, c) => {
      if (c === id) {
        return {
          ...p,
          [c]: Object.entries(nestedErrors[c]).reduce(
            (p, [k, v]) => ({ ...p, ...(k === K[field].apiKey ? {} : { [k]: v }) }),
            {},
          ),
        };
      }
      return { ...p, c };
    }, {});

    setNestedErrors(filteredNestedFieldErrors);
  }

  function onRoleChange(v, name) {
    removeErrorFromNestedField(name.join`,`);
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    // Nested Form.Items have `{Number},{STRING_NAME}` as a name
    if (name.includes(',')) {
      removeErrorFromNestedField(name);
    } else {
      form.setFields([{ name, errors: [] }]);
    }
  }

  function reset() {
    setIsValidated(true);
    createMerchantReset();
    form.resetFields();
  }

  return (
    <Root
      name="SignUp"
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={handleFinish}
    >
      {isLoaded ? (
        <>
          <Result>
            <ResultTitle>Пользователь был успешно создан</ResultTitle>
          </Result>
          <Footer>
            <Button type="primary" onClick={reset}>
              Создать нового пользователя
            </Button>
          </Footer>
        </>
      ) : (
        <>
          <Main>
            <MainTitle>Сведения и юридическом лице</MainTitle>
            <Form.Item
              name={K.COMPANY.key}
              label={K.COMPANY.title}
              rules={K.COMPANY.rules}
              validateFirst
              validateTrigger="onBlur"
            >
              <SearchField
                name={K.COMPANY.key}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChangeCallback={(data) =>
                  onChangeCallback({ name: K.COMPANY.key, value: data.value, data })}
              />
            </Form.Item>
            <Form.Item
              name={K.INN.key}
              label={K.INN.title}
              rules={K.INN.rules}
              validateFirst
              validateTrigger="onBlur"
            >
              <Input name={K.INN.key} onFocus={handleFocus} onBlur={handleBlur} />
            </Form.Item>

            <MainDivider />

            <MainSubtitle>Администратор ДЦ</MainSubtitle>
            <Form.Item
              name={K.ADMIN_NAME.key}
              label={K.ADMIN_NAME.title}
              rules={K.ADMIN_NAME.rules}
              validateFirst
              validateTrigger="onBlur"
            >
              <Input name={K.ADMIN_NAME.key} onFocus={handleFocus} onBlur={handleBlur} />
            </Form.Item>
            <Form.Item
              name={K.ADMIN_PHONE.key}
              label={K.ADMIN_PHONE.title}
              rules={K.ADMIN_PHONE.rules}
              validateFirst
              validateTrigger="onBlur"
            >
              <Rifm
                accept={/[\d]/g}
                mask
                replace={formatPhone.replace}
                format={formatPhone.format}
                onClick={() => cursorToLastPhoneValuePosition(adminPhoneField)}
              >
                {({ value, onChange }) => (
                  <Input
                    name={K.ADMIN_PHONE.key}
                    value={value}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    addonBefore={formatPhone.prefix}
                    onClick={() => cursorToLastPhoneValuePosition(adminPhoneField)}
                    ref={adminPhoneField}
                  />
                )}
              </Rifm>
            </Form.Item>
            <Form.Item
              name={K.ADMIN_EMAIL.key}
              label={K.ADMIN_EMAIL.title}
              rules={K.ADMIN_EMAIL.rules}
              validateFirst
              validateTrigger="onBlur"
            >
              <Input name={K.ADMIN_EMAIL.key} onFocus={handleFocus} onBlur={handleBlur} />
            </Form.Item>
          </Main>

          <Form.List name={K.USERS.key}>
            {(fields, { add, remove }) => (
              <Aside>
                <Header>
                  <AsideTitle>Пользователи</AsideTitle>
                  <Button type="ghost" size="small" onClick={() => add()}>
                    Добавить пользователя
                  </Button>
                </Header>

                {fields.length > 0 && (
                  <UserList>
                    {fields.map((field, id) => (
                      <User key={field.key}>
                        <UserHeader>
                          <UserTitle>
                            Пользователь #
                            {id + 1}
                          </UserTitle>
                          <UserDelete onClick={() => remove(field.name)}>
                            удалить
                          </UserDelete>
                        </UserHeader>
                        <Form.Item
                          name={[field.name, K.USER_ROLE.key]}
                          label={K.USER_ROLE.title}
                          rules={K.USER_ROLE.rules}
                          validateFirst
                          validateTrigger={['onBlur', 'onChange']}
                          {...renderNestedFieldError({ id, name: K.USER_ROLE.apiKey })}
                        >
                          <Select
                            name={[field.name, K.USER_ROLE.key]}
                            onChange={(v) =>
                              onRoleChange(v, [field.name, K.USER_ROLE.key])}
                            loading={roleIsLoading}
                            notFoundContent={
                              roleError ? (
                                <span>{roleError}</span>
                              ) : (
                                <span>нет данных</span>
                              )
                            }
                          >
                            {roleList.map((e) => (
                              <Select.Option key={e.id} value={e.id}>
                                {e.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, K.USER_NAME.key]}
                          label={K.USER_NAME.title}
                          rules={K.USER_NAME.rules}
                          validateFirst
                          validateTrigger="onBlur"
                          {...renderNestedFieldError({ id, name: K.USER_NAME.apiKey })}
                        >
                          <Input
                            name={[field.name, K.USER_NAME.key]}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, K.USER_PHONE.key]}
                          label={K.USER_PHONE.title}
                          rules={K.USER_PHONE.rules}
                          validateFirst
                          validateTrigger="onBlur"
                          {...renderNestedFieldError({ id, name: K.USER_PHONE.apiKey })}
                        >
                          <Rifm
                            accept={/[\d]/g}
                            mask
                            replace={formatPhone.replace}
                            format={formatPhone.format}
                            onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                          >
                            {({ value, onChange }) => (
                              <Input
                                name={[field.name, K.USER_PHONE.key]}
                                value={value}
                                onChange={onChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                addonBefore={formatPhone.prefix}
                                onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                                ref={phoneField}
                              />
                            )}
                          </Rifm>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, K.USER_EMAIL.key]}
                          label={K.USER_EMAIL.title}
                          rules={K.USER_EMAIL.rules}
                          validateFirst
                          validateTrigger="onBlur"
                          {...renderNestedFieldError({ id, name: K.USER_EMAIL.apiKey })}
                        >
                          <Input
                            name={[field.name, K.USER_EMAIL.key]}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </Form.Item>
                      </User>
                    ))}
                  </UserList>
                )}
              </Aside>
            )}
          </Form.List>

          <Footer>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Продолжить
            </Button>
            {error && (
              <ErrorMessage>
                <Alert message={error} type="error" closable />
              </ErrorMessage>
            )}
          </Footer>
        </>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    error: state.admin.create.error,
    errors: state.admin.create.errors,
    isLoading: state.admin.create.isLoading,
    isLoaded: state.admin.create.isLoaded,

    roleList: state.user.availableRoles.list,
    roleIsLoading: state.user.availableRoles.isLoading,
    roleIsLoaded: state.user.availableRoles.isLoaded,
    roleError: state.user.availableRoles.error,
  }),
  {
    createMerchant: createMerchantAction,
    createMerchantReset: createMerchantResetAction,
    fetchAvailableRoles: fetchAvailableRolesAction,
  },
)(CreateUser);
