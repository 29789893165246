import {
  INVITE__RESET,
  INVITE_CONFIRM_EMAIL,
  INVITE_CONFIRM_EMAIL__SUCCESS,
  INVITE_CONFIRM_EMAIL__FAILURE,
  INVITE_SEND_OTP,
  INVITE_SEND_OTP__SUCCESS,
  INVITE_SEND_OTP__FAILURE,
  INVITE_CHECK_OTP,
  INVITE_CHECK_OTP__SUCCESS,
  INVITE_CHECK_OTP__FAILURE,

  INVITE_FINISH,
  INVITE_FINISH__SUCCESS,
  INVITE_FINISH__FAILURE,
} from './actions';

const initState = {
  email: {
    token: null,
    value: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  otp: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  confirmation: {
    token: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
  register: {
    token: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
};

export default function inviteReducer(state = initState, action) {
  switch (action.type) {
    case INVITE__RESET: {
      return initState;
    }

    case INVITE_CONFIRM_EMAIL: {
      return {
        ...state,

        email: {
          token: action.token,
          value: action.email,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case INVITE_CONFIRM_EMAIL__SUCCESS: {
      return {
        ...state,

        email: {
          ...state.email,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case INVITE_CONFIRM_EMAIL__FAILURE: {
      return {
        ...state,

        email: {
          ...state.email,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case INVITE_SEND_OTP: {
      return {
        ...state,

        otp: {
          ...state.otp,
          login: action.login,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case INVITE_SEND_OTP__SUCCESS: {
      return {
        ...state,

        otp: {
          ...state.otp,
          token: action.token,
          recipient: action.recipient,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case INVITE_SEND_OTP__FAILURE: {
      return {
        ...state,

        otp: {
          ...state.otp,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case INVITE_CHECK_OTP: {
      return {
        ...state,

        confirmation: {
          ...state.confirmation,
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case INVITE_CHECK_OTP__SUCCESS: {
      return {
        ...state,

        confirmation: {
          ...state.confirmation,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case INVITE_CHECK_OTP__FAILURE: {
      return {
        ...state,

        confirmation: {
          ...state.confirmation,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: action.errors,
        },
      };
    }

    case INVITE_FINISH: {
      return {
        ...state,

        register: {
          ...state.register,
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case INVITE_FINISH__SUCCESS: {
      return {
        ...state,

        register: {
          ...state.register,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case INVITE_FINISH__FAILURE: {
      return {
        ...state,

        register: {
          ...state.register,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: action.errors,
        },
      };
    }
    default: {
      return state;
    }
  }
}
