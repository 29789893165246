import styled from 'styled-components';
import { palette, size } from 'styled-theme';

const WithStyle = (ComponentName) => styled(ComponentName).attrs({ className: 'styledInput' })`
  & {
    width: 100%;
  }

  & .ant-input {
    height: ${size('filterInputHeight')};
    border-radius: 4px;
    font-size: 12px;
    padding: 7px 6px 9px 16px;
    background: ${palette('form', 0)};
    border-color:  ${palette('form', 2)};
    color: ${palette('form', 5)};
    caret-color: ${palette('form', 5)};
    &:hover {
      background: ${palette('form', 1)};
      border-color:  ${palette('form', 3)};
    }
    &:focus {
      background: ${palette('form', 1)};
      border-color:  ${palette('form', 3)};
      box-shadow: 0 0 0 2px ${palette('form', 4)};
    }
  }
`;

export {
  WithStyle,
};
