export const FETCH_TOYOTA_MODELS = 'FETCH_TOYOTA_MODELS';
export const FETCH_TOYOTA_MODELS__SUCCESS = 'FETCH_TOYOTA_MODELS__SUCCESS';
export const FETCH_TOYOTA_MODELS__FAILURE = 'FETCH_TOYOTA_MODELS__FAILURE';
/**
 * Fetch models of Toyota
 * @return {{type: string}}
 */
export const fetchToyotaModels = () => ({ type: FETCH_TOYOTA_MODELS });

export const FETCH_LEXUS_MODELS = 'FETCH_LEXUS_MODELS';
export const FETCH_LEXUS_MODELS__SUCCESS = 'FETCH_LEXUS_MODELS__SUCCESS';
export const FETCH_LEXUS_MODELS__FAILURE = 'FETCH_LEXUS_MODELS__FAILURE';
/**
 * Fetch models of Lexus
 * @return {{type: string}}
 */
export const fetchLexusModels = () => ({ type: FETCH_LEXUS_MODELS });

export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_BRANDS__SUCCESS = 'FETCH_BRANDS__SUCCESS';
export const FETCH_BRANDS__FAILURE = 'FETCH_BRANDS__FAILURE';
/**
 * Fetch brands
 * @return {{type: string}}
 */
export const fetchBrands = () => ({ type: FETCH_BRANDS });

export const FETCH_MODELS = 'FETCH_MODELS';
export const FETCH_MODELS__SUCCESS = 'FETCH_MODELS__SUCCESS';
export const FETCH_MODELS__FAILURE = 'FETCH_MODELS__FAILURE';
/**
 * Fetch models
 * @return {{type: string}}
 */
export const fetchModels = ({ brand }) => ({ type: FETCH_MODELS, brand });
