import withTheme from 'components/tcm/withTheme';
import styled from 'styled-components';
import React from 'react';
import { ButtonText } from 'components/tcm/Button';
import { ModalWithChildren as Modal } from '../Modals';

export const Content = withTheme(styled.div`
  padding: 33px 40px 37px;
  background-color: ${({ theme }) => theme.palette.gray6};
  border-radius: 10px;
`);

export const MainContent = withTheme(styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
`);

export const FieldTitle = withTheme(styled.div`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);
export const FieldValue = withTheme(styled.p`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
`);

export const SuccessfulField = withTheme(styled(FieldValue)`
  display: grid;
  grid-template-columns: 26px max-content;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.successful};
  font-family: ${({ theme }) => theme.fontFamily};
  
  p {
    margin-bottom: 0;
  }
`);

export const IdWrapper = styled.div`
 
`;

export const StatusWrapper = styled.div`

`;

export const Name = styled.div`

`;

export const BirthDay = styled.div`

`;
export const CityOfResidence = styled.div`

`;
export const Phone = styled.div`

`;

export const PhoneShowFull = withTheme(styled(({ theme, ...p }) => <ButtonText {...p} />)`
      display: inline;
      font-family: ${({ theme }) => theme.fontFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.accent};
      margin-left: 27px;
      cursor: pointer;

      &:before{
        background: transparent;
      }
`);

export const ClientSex = styled.div`
   p {
    text-transform: uppercase
   };

`;

export const Main = styled.div`
  margin-top: 160px;
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 33px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  
  p {
    margin-bottom: 0;
    
    &:last-child {
      margin-left: .5em;
      margin-bottom: 5px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmployeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Employee = styled.div`
   font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-right: 20px;
`;

export const CommentsContainer = withTheme(styled.div`
   border-top: 1px solid ${({ theme }) => theme.palette.gray4};
   margin-top: 28px;
   padding-top: 23px;
`);

export const CommentsContainerTitle = styled.div`
  margin-bottom: 25px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000;
`;

export const NewCommentContainer = withTheme(styled.div`
    textarea {
     background: ${({ theme }) => theme.palette.white};
     
      &::placeholder {
          font-weight: 300;
          font-size: 16px;
          line-height: 20px;
          color: ${({ theme }) => theme.palette.gray3};
      }
    }
    
    button {
      margin-left: auto;
      background: transparent;
      margin-top: 20px;
      
      &:disabled {
        background: transparent;
      }
    }
 `);

export const NewCommentLabel = withTheme(styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    font-family: ${({ theme }) => theme.fontFamily};
    margin-bottom: 7px;
 `);

export const CommentContainer = styled.div`
 &:not(:last-child){
    margin-bottom: 20px;
  }
`;
export const Comment = withTheme(styled.div`
  display: flex;
`);

export const CommentTitle = withTheme(styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const CommentDate = withTheme(styled.div`
  flex-shrink: 0;
  display: flex;
  margin-bottom: 0;
  margin-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const CommentText = withTheme(styled.p`
  margin-bottom: 0;  
  max-width: 775px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
  overflow-wrap: break-word;
`);

export const ModalText = withTheme(styled.div`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  color: ${({ theme }) => theme.palette.black};
  font-size: 18px;
  margin: 35px auto 0 auto;
  width:530px;
`);

export const ModalWithChildren = withTheme(styled(({ theme, ...p }) => <Modal {...p} />)`
  width: 980px;

  .ant-radio-group{
    margin: 27px auto 46px auto;
    gap: 20px;
    width:530px;
  }
`);
