import React from 'react';
import {
  Root,
  Title,
  ModalBody,
} from './style';
import ReportForm from './Form';

export default function FormReportModal({
  close,
  visible,
}) {
  return (
    <Root
      visible={visible}
      onCancel={close}
      footer={null}
      width={814}
    >
      <ModalBody>
        <Title>Отчеты</Title>
        <ReportForm visible={visible} />
      </ModalBody>
    </Root>
  );
}
