import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from 'settings';

import {
  Input as InputAntD,
  DatePicker as DatePickerAntD,
} from 'antd';

import {
  WithStyle,
} from './style';

const defaultProps = {
  allowClear: true,
  separator: '',
};

const RangePickerStyled = WithStyle((props) => <DatePickerAntD.RangePicker {...defaultProps} {...props} />);

function DateFilter({
  name: [startName, endName],
  onChange: onChangeField,
  value: [startValue, endValue],
}) {
  const [start, setStart] = useState(startValue || '');
  const [end, setEnd] = useState(endValue || '');

  useEffect(() => {
    setStart(startValue);
  }, [startValue]);

  useEffect(() => {
    setEnd(endValue);
  }, [endValue]);

  function handleOnChange(dates, dateStrings) {
    onChangeField({
      [startName]: dateStrings[0],
      [endName]: dateStrings[1],
    });
  }

  return (
    <InputAntD.Group compact>
      <RangePickerStyled
        value={
          (
            moment(start, DATE_FORMAT).isValid()
            && moment(end, DATE_FORMAT).isValid()
          )
            ? [moment(start, DATE_FORMAT), moment(end, DATE_FORMAT)]
            : []
        }
        allowEmpty={[true, true]}
        format={DATE_FORMAT}
        onChange={handleOnChange}
      />
    </InputAntD.Group>
  );
}

export default DateFilter;
