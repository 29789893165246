import styled from 'styled-components';
import { key } from 'styled-theme';
import { GRID_AREAS } from 'settings';
import { DatePicker } from 'antd';

const DatePickerStyled = styled(DatePicker).attrs({ className: 'styledInput' })`
  && {
    &.ant-calendar-picker {
      ${({ area }) =>
    typeof area === 'boolean'
        && `
        grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
      `}
    }

    & .ant-input {
      ${key('defaultInput')}
    }
  }
`;

export { DatePickerStyled };
