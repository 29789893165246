import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  padding: ${(props) => (props.inModal ? '0' : '2rem 4rem 0')};
  margin-top: ${(props) => (props.inModal ? '20px' : '0')};
  width: 100%;

  .ant-breadcrumb a {
    font-size: 14px;
    color: #000;
  }
  
  .ant-breadcrumb span {
    font-size: 14px;
    color: #000;
  }

  .ant-breadcrumb-separator,
  .ant-breadcrumb > span:last-child a {
    color: #000;
  }
`;
export const BreadcrumbsModalContainer = styled.div`
  margin-top: 20px;

  .ant-breadcrumb a {
    font-size: 14px;
    color: #000;
  }
  .ant-breadcrumb-separator,
  .ant-breadcrumb > span:last-child a {
    color: #000;
  }
`;

export const BlockBreadcrumb = styled.span`
  font-size: 14px;
  color: #000;
  cursor: pointer;
`;
