import { useEffect, useState } from 'react';
import { notification } from 'antd';

function Notification({
  name, title, description, close,
}) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);

      notification.open({
        message: title,
        description,
        duration: 0,
        onClose: () => close({ name }),
      });
    }
  }, [name, title, description, close, isMounted, setIsMounted]);

  return null;
}

export default Notification;
