import styled from 'styled-components';
import { AutoComplete } from 'antd';

export const Description = styled.div`
  height: 2rem;
  font-size: 0.8rem;
  color: #696969;
  margin-top: 4px;
  margin-bottom: -20px;
`;

export const { Option } = AutoComplete;

export const AutoCompleteStyled = styled(AutoComplete).attrs({
  className: 'styledInput',
})`
    &.ant-select-auto-complete {
      width: 100%;
    &.ant-select-single.ant-select-lg {
      .ant-select-selector {
        height: 50px;
        border-radius: 5px;
        
        .ant-select-selection-search-input {
          height: 50px;
        }
        
        .ant-select-selection-placeholder {
          line-height: 50px;
        }
        
        .ant-select-selection-item {
          line-height: 50px;
        }
      }
    }
`;
