import React from 'react';

import { Root } from './style';

function Label({ title, label }) {
  return (
    <Root>
      {title && (
        <strong>
          {title}
          :
          {' '}
        </strong>
      )}
      <span>{label}</span>
    </Root>
  );
}

export default Label;
