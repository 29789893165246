import {
  // list
  DEALER_CENTER_FETCH_LIST,
  DEALER_CENTER_FETCH_LIST__SUCCESS,
  DEALER_CENTER_FETCH_LIST__FAILURE,
  DEALER_CENTER_FETCH_LIST__SET_LOADING,
  DEALER_CENTER_FETCH_LIST__SET_PAGINATION,
  DEALER_CENTER_FETCH_LIST__SET_SEARCH,
  DEALER_CENTER_FETCH_LIST__SET_SORTING,
  DEALER_CENTER_FETCH_LIST__SET_FILTER,
  DEALER_CENTER_FETCH_LIST__RESET,

  // item
  DEALER_CENTER__FETCH_ITEM,
  DEALER_CENTER__FETCH_ITEM_SUCCESS,
  DEALER_CENTER__FETCH_ITEM_FAILURE,
  DEALER_CENTER__FETCH_ITEM_RESET,
  DEALER_CENTER__CREATE,
  DEALER_CENTER__CREATE_SUCCESS,
  DEALER_CENTER__CREATE_FAILURE,
  DEALER_CENTER__EDIT,
  DEALER_CENTER__EDIT_SUCCESS,
  DEALER_CENTER__EDIT_FAILURE,

  // ARM
  DEALER_CENTER__FETCH_DEALERS,
  DEALER_CENTER__FETCH_DEALERS_SUCCESS,
  DEALER_CENTER__FETCH_DEALERS_FAILURE,

  DEALER_CENTER__RESET,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {};

const initState = {
  collection: {
    data: {},
    pagination: {},
    list: [],
    companyList: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  item: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  create: {
    data: {},
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  edit: {
    data: {},
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  // arm
  dealers: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function dealerCenterReducer(state = initState, action) {
  switch (action.type) {
    case DEALER_CENTER_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case DEALER_CENTER_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case DEALER_CENTER_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case DEALER_CENTER_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case DEALER_CENTER_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case DEALER_CENTER_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case DEALER_CENTER_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case DEALER_CENTER_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          data: action.data,
          pagination: action.pagination || {},
          page: (action.pagination && action.pagination.page) || 1,
          list: action.list,
          companyList: action.companyList,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case DEALER_CENTER_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case DEALER_CENTER__FETCH_ITEM: {
      return {
        ...state,

        item: {
          ...state.item,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case DEALER_CENTER__FETCH_ITEM_SUCCESS: {
      return {
        ...state,
        item: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case DEALER_CENTER__FETCH_ITEM_FAILURE: {
      return {
        ...state,
        item: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case DEALER_CENTER__FETCH_ITEM_RESET: {
      return {
        ...state,
        item: {
          ...initState.item,
        },
      };
    }

    case DEALER_CENTER__CREATE: {
      return {
        ...state,

        create: {
          data: {},
          form: {
            salePoint: action.salePoint,
            inn: action.inn,
            krifId: action.krifId,
            acquiringID: action.acquiringID,
            onlineId: action.onlineId,
            addressactual: action.addressactual,
            phone: action.phone,
            detail: action.detail,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case DEALER_CENTER__CREATE_SUCCESS: {
      return {
        ...state,

        create: {
          ...state.create,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case DEALER_CENTER__CREATE_FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case DEALER_CENTER__EDIT: {
      return {
        ...state,

        edit: {
          data: {},
          form: {
            salePoint: action.salePoint,
            inn: action.inn,
            krifId: action.krifId,
            acquiringID: action.acquiringID,
            onlineId: action.onlineId,
            addressactual: action.addressactual,
            phone: action.phone,
            detail: action.detail,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case DEALER_CENTER__EDIT_SUCCESS: {
      return {
        ...state,

        edit: {
          ...state.edit,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case DEALER_CENTER__EDIT_FAILURE: {
      return {
        ...state,

        edit: {
          ...state.edit,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    // ARM
    case DEALER_CENTER__FETCH_DEALERS: {
      return {
        ...state,

        dealers: {
          ...state.dealers,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case DEALER_CENTER__FETCH_DEALERS_SUCCESS: {
      return {
        ...state,

        dealers: {
          ...state.dealers,
          list: action.list.map((e) => ({ label: e.name, value: e.name })),
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case DEALER_CENTER__FETCH_DEALERS_FAILURE: {
      return {
        ...state,

        dealers: {
          ...state.dealers,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case DEALER_CENTER__RESET: {
      const { page } = action;
      return {
        ...state,
        create: {
          ...initState.create,
        },
        ...(page === 'new' ? (
          {
            edit: {
              ...initState.edit,
            },
            item: {
              ...initState.item,
            },
          }
        ) : (
          {
            edit: {
              ...state.edit,
              isLoading: false,
              isLoaded: false,
              error: null,
              errors: [],
            },
            item: {
              ...state.item,
              error: null,
              errors: [],
            },
          }
        )),
      };
    }

    default: {
      return state;
    }
  }
}
