/* List */
export const DEALER_CENTER_FETCH_LIST = 'DEALER_CENTER_FETCH_LIST';
export const DEALER_CENTER_FETCH_LIST__SUCCESS = 'DEALER_CENTER_FETCH_LIST__SUCCESS';
export const DEALER_CENTER_FETCH_LIST__FAILURE = 'DEALER_CENTER_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting, withPagination, legalEntityIds,
} = {}) => ({
  type: DEALER_CENTER_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
  withPagination,
  legalEntityIds,
});

export const DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST = 'DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST';
export const DEALER_CENTER_FETCH_LIST__SET_LOADING = 'DEALER_CENTER_FETCH_LIST__SET_LOADING';
export const DEALER_CENTER_FETCH_LIST__SET_PAGINATION = 'DEALER_CENTER_FETCH_LIST__SET_PAGINATION';

export const DEALER_CENTER_FETCH_LIST__SET_SEARCH = 'DEALER_CENTER_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({
  type: DEALER_CENTER_FETCH_LIST__SET_SEARCH,
  search,
});

export const DEALER_CENTER_FETCH_LIST__SET_PAGE = 'DEALER_CENTER_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({
  type: DEALER_CENTER_FETCH_LIST__SET_PAGE,
  page,
});

export const DEALER_CENTER_FETCH_LIST__SET_SORTING = 'DEALER_CENTER_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: DEALER_CENTER_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const DEALER_CENTER_FETCH_LIST__SET_FILTER = 'DEALER_CENTER_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters, withPagination } = {}) => ({
  type: DEALER_CENTER_FETCH_LIST__SET_FILTER,
  filters,
  withPagination,
});

export const DEALER_CENTER_FETCH_LIST__RESET = 'DEALER_CENTER_FETCH_LIST__RESET';
export const reset = () => ({ type: DEALER_CENTER_FETCH_LIST__RESET });

/* Item */
export const DEALER_CENTER__FETCH_ITEM = 'DEALER_CENTER__FETCH_ITEM';
export const DEALER_CENTER__FETCH_ITEM_SUCCESS = 'DEALER_CENTER__FETCH_ITEM_SUCCESS';
export const DEALER_CENTER__FETCH_ITEM_FAILURE = 'DEALER_CENTER__FETCH_ITEM_FAILURE';
export const DEALER_CENTER__FETCH_ITEM_RESET = 'DEALER_CENTER__FETCH_ITEM_RESET';
export const fetchDealerCenter = ({ id = '' } = {}) => ({
  type: DEALER_CENTER__FETCH_ITEM,
  id,
});
export const fetchDealerCenterReset = () => ({
  type: DEALER_CENTER__FETCH_ITEM_RESET,
});

export const DEALER_CENTER__CREATE = 'DEALER_CENTER__CREATE';
export const DEALER_CENTER__CREATE_SUCCESS = 'DEALER_CENTER__CREATE_SUCCESS';
export const DEALER_CENTER__CREATE_FAILURE = 'DEALER_CENTER__CREATE_FAILURE';
export const create = ({
  salePoint,
  inn,
  krifId,
  acquiringID,
  onlineId,
  addressactual,
  phone,
  time,
  email,
} = {}) => ({
  type: DEALER_CENTER__CREATE,
  salePoint,
  inn,
  krifId,
  acquiringID,
  onlineId,
  addressactual,
  phone,
  time,
  email,
});
export const DEALER_CENTER__RESET = 'DEALER_CENTER__RESET';
export const resetForm = (page) => ({ type: DEALER_CENTER__RESET, page });

export const DEALER_CENTER__EDIT = 'DEALER_CENTER__EDIT';
export const DEALER_CENTER__EDIT_SUCCESS = 'DEALER_CENTER__EDIT_SUCCESS';
export const DEALER_CENTER__EDIT_FAILURE = 'DEALER_CENTER__EDIT_FAILURE';
export const edit = ({
  id,
  salePoint,
  inn,
  krifId,
  acquiringID,
  onlineId,
  addressactual,
  phone,
  time,
  detail,
  bindSector,
  unbindSector,
  email,
} = {}) => ({
  type: DEALER_CENTER__EDIT,
  id,
  salePoint,
  inn,
  krifId,
  acquiringID,
  onlineId,
  addressactual,
  phone,
  time,
  detail,
  bindSector,
  unbindSector,
  email,
});

/* Old Dealer Centers (ARM) */
export const DEALER_CENTER__FETCH_DEALERS = 'DEALER_CENTER__FETCH_DEALERS';
export const DEALER_CENTER__FETCH_DEALERS_SUCCESS = 'DEALER_CENTER__FETCH_DEALERS_SUCCESS';
export const DEALER_CENTER__FETCH_DEALERS_FAILURE = 'DEALER_CENTER__FETCH_DEALERS_FAILURE';
export const fetchDealers = () => ({ type: DEALER_CENTER__FETCH_DEALERS });
