import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const Wrapper = withTheme(styled.div`
  padding-bottom: 40px;
  
  .ant-radio-group{
    display: block;
  }
`);

export const Title = withTheme(styled.h2`
  margin-bottom: 33px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: ${({ theme }) => theme.palette.black};
  text-align: center;
`);

export const SubTitle = withTheme(styled.h4`
  margin-bottom: 26px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
    font-size: 18px;
    line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
  text-align: center;
`);

export const RadioTitle = withTheme(styled.h4`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
  text-align: left;
`);

export const CheckBoxText = styled.span`
  font-size: 16px;
`;

export const Row = styled.div`
  margin-bottom: 20px;
`;
