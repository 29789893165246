import {
  RESTORE__RESET,
  RESTORE_CONFIRM_PASSWORD,
  RESTORE_CONFIRM_PASSWORD__SUCCESS,
  RESTORE_CONFIRM_PASSWORD__FAILURE,

  RESTORE_SEND_OTP,
  RESTORE_SEND_OTP__SUCCESS,
  RESTORE_SEND_OTP__FAILURE,

  RESTORE_SEND_OTP_PHONE,
  RESTORE_SEND_OTP_PHONE__SUCCESS,
  RESTORE_SEND_OTP_PHONE__FAILURE,

  RESTORE_CHECK_OTP,
  RESTORE_CHECK_OTP__SUCCESS,
  RESTORE_CHECK_OTP__FAILURE,

  RESTORE_FINISH,
  RESTORE_FINISH__SUCCESS,
  RESTORE_FINISH__FAILURE,
} from './actions';

const initState = {
  password: {
    token: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  otp: {
    login: null,
    token: null,
    recipient: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  confirmation: {
    token: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  restore: {
    token: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
};

export default function restoreReducer(state = initState, action) {
  switch (action.type) {
    case RESTORE__RESET: {
      return {
        ...initState,
      };
    }
    case RESTORE_CONFIRM_PASSWORD: {
      return {
        ...state,

        password: {
          token: action.token,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case RESTORE_CONFIRM_PASSWORD__SUCCESS: {
      return {
        ...state,

        password: {
          ...state.password,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case RESTORE_CONFIRM_PASSWORD__FAILURE: {
      return {
        ...state,

        password: {
          ...state.password,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case RESTORE_SEND_OTP: {
      return {
        ...state,

        otp: {
          ...state.otp,
          login: action.login,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case RESTORE_SEND_OTP__SUCCESS: {
      return {
        ...state,

        otp: {
          ...state.otp,
          token: action.token,
          recipient: action.recipient,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case RESTORE_SEND_OTP__FAILURE: {
      return {
        ...state,

        otp: {
          ...state.otp,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case RESTORE_SEND_OTP_PHONE: {
      return {
        ...state,
        confirmation: { ...initState.confirmation },
        otp: {
          ...state.otp,
          login: action.login,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case RESTORE_SEND_OTP_PHONE__SUCCESS: {
      return {
        ...state,

        otp: {
          ...state.otp,
          token: action.token,
          recipient: action.recipient,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case RESTORE_SEND_OTP_PHONE__FAILURE: {
      return {
        ...state,

        otp: {
          ...state.otp,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case RESTORE_CHECK_OTP: {
      return {
        ...state,

        confirmation: {
          ...state.confirmation,
          token: action.token,
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case RESTORE_CHECK_OTP__SUCCESS: {
      return {
        ...state,

        confirmation: {
          ...state.confirmation,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case RESTORE_CHECK_OTP__FAILURE: {
      return {
        ...state,

        confirmation: {
          ...state.confirmation,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case RESTORE_FINISH: {
      return {
        ...state,

        restore: {
          ...state.restore,
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case RESTORE_FINISH__SUCCESS: {
      return {
        ...state,

        restore: {
          ...state.restore,
          isLoading: false,
          isLoaded: true,
          error: null,
          errors: [],
        },
      };
    }
    case RESTORE_FINISH__FAILURE: {
      return {
        ...state,

        restore: {
          ...state.restore,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: action.errors,
        },
      };
    }

    default: {
      return state;
    }
  }
}
