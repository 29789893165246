import styled from 'styled-components';
import { Form } from 'antd';
import withTheme from 'components/tcm/withTheme';

const tdPaddingTop = '30px';

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'Open Sans';
  opacity: ${({ hide }) => (hide ? 0.2 : 1)};
  th, td {
    padding-right: 14px;

    &:last-child{
      padding-right: 0;
    }
  }

  th {
    padding-bottom: 25px;
    border-bottom: 1px solid #cecece;
    vertical-align: top;
  }

  td {
    padding-top: ${tdPaddingTop};
    padding-bottom: 25px;
    border-bottom: 1px solid #F0F0F0;
    vertical-align: top;
    
    &[data-width='200']{
      width: 200px;
    }
    
    &[data-width='72']{
      width: 72px;
    }
    
    &[data-width='100']{
      width: 100px;
    }
  }

  th, td {
    width: min-content;
  }
`;

export const Hidden = styled.div`
  position: absolute;
  visibility: hidden;
`;

export const TableTitleStroke = styled.div`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isTheme }) => (isTheme ? 16 : 14)}px;
  line-height: 20px;
  color: #000000;
`;

export const Cell = styled(TableTitleStroke)`
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  color: ${({ second }) => (second ? '#A8AAAC' : '#000')};
`;

export const Price = styled(TableTitleStroke)`
  text-align: center;
  color: #000;
  display: flex;
  font-size: 16px;
  min-width: 100px;
  
  div{
    font-size: 20px;
  }
  
  span{
      margin-left: 4px;
  }
`;

export const Vin = styled(TableTitleStroke)`
   grid-area: vin;
`;

export const Brand = styled(TableTitleStroke)`
   grid-area: brand;
`;

export const Model = styled(TableTitleStroke)`
   grid-area: model;
`;

export const CampaignInfoContainer = styled.div`
  margin-bottom: 50px;
`;

export const ErrorRow = styled.div`
  color: #000;
  text-align: left;
  margin-bottom: 20px;
`;

export const ErrorField = styled.span`
  color: #6c7073;
`;

export const TableVinTitleContainer = styled.div`
  display: grid;
  min-width: 202px;
  grid-template-columns: repeat(2, 75px);
  grid-auto-rows: minmax(auto, 20px);
  grid-template-areas:
   'vin   .'
   'brand model'; 
`;

export const TableVinBodyContainer = styled.div`
  position: relative;
  display: grid;
  min-width: 183px;
  grid-gap: 10px 10px;
  grid-template-columns: repeat(1, 62px);
  grid-template-areas:
   'vin vin'
   'brand model'; 
`;

export const TableBodyEngineContainer = styled.div`
  display: grid;
  grid-gap: 10px 0;
  justify-content: flex-start;
  align-items: flex-start;
  grid-template-areas:
   'engine'
   'volume'; 
   
  p {
    text-align: left;
  }
`;

export const TableBodyColorsContainer = styled.div`
  display: grid;
  width: max-content;
  min-width: 90px;
  grid-gap: 10px 6px;
  justify-content: flex-start;
  align-items: center;
  grid-template-areas:
   'color colorName'
   'color colorName'; 
   
   div {
    text-align: left;
   }
`;

export const Color = styled.div`
  background: ${({ color }) => color || '#000'};
  border: 1px solid #000;
  border-radius: 50%;
  height: 14px;
  width: 14px;
`;

export const TableTitleSecondaryStroke = styled.div`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #A8AAAC;
`;

export const TableContainer = styled.div`
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  min-height: 70px;
  
  & > .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 70px;
  }
  
  .ant-input-affix-wrapper{
    padding: 11px 12px 12px 20px;
    min-width: 130px;
  }
  
  input {
    font-size: 16px;
  }
  
  ${({ isEdit }) => !isEdit && `
    tbody tr td:nth-of-type(6), tbody tr td:nth-of-type(7), tbody tr td:nth-of-type(8) {
    vertical-align: middle;
  }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${({ isEdit }) => (isEdit ? 'space-between' : 'flex-end')};
  align-content: center;
  padding-bottom: 70px;
`;

export const ExcelButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 50px;
  font-size: 18px;
  color: #000000;
  font-family: Toyota Display;
`;

export const ResultCell = styled.div`
  display: grid;
  row-gap: 10px;
  
   p {
    margin-bottom: 0;
   }
`;

export const CellWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CallingWrapper = styled.div`
  margin-top: 60px;
`;

export const CallingTitle = withTheme(styled.div`
  margin-bottom: 48px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

export const CallingTable = withTheme(styled.table`
  margin-bottom: 70px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  opacity: ${({ hide }) => (hide ? 0.2 : 1)};
  transition: .2s linear opacity;

  th, td {
    padding-right: 16px;

    &:last-child{
      padding-right: 0;
    }
  }

  th {
    padding-bottom: 15px;
    border-bottom: 1px solid  ${({ theme }) => theme.palette.gray4};
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.gray3};
    
    &:nth-child(2){
      color: ${({ theme }) => theme.palette.black};
    }
    
    &:nth-child(3){
      min-width: 240px;
    }
    
    &:nth-child(3){
      min-width: 276px;
    }
    
    &:nth-child(5){
      min-width: 130px;
    }
  }

  td {
    padding-top: 24px;
    padding-bottom: 23px;
    border-bottom: 1px solid  ${({ theme }) => theme.palette.gray5};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.black};
    
    &:nth-child(3){
      min-width: 150px;
    }
    
    &:nth-child(5), &:nth-child(6), &:nth-child(7){
      width: min-content;
    }
    
    &:nth-child(6), &:nth-child(7), &:nth-child(8){
      font-size: 16px;
    }
  }

  th, td {
    width: min-content;
  }
`);

export const CallingForm = styled(Form)`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 55px;
  justify-content: space-between;
  margin-bottom: 60px;
`;
