import React, {
  useCallback, useEffect, useReducer, useRef, useState,
} from 'react';
import AutoComplete, { Option } from 'components/AutoComplete';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { useDebounce } from 'hooks';
import { defineDependencies, formatFias, mergeArrays } from 'hacks';
import API from 'services';
import moment from 'moment';
import { SCROLL_DURATION } from 'settings';

import { LoadingOutlined } from '@ant-design/icons';

import {
  InputContainer, Suffix, TimeBlock, TimeLocal, TimeZone, WithSuffix,
} from './style';

// RegExp doesnt support naming capture groups in firefox. https://bugzilla.mozilla.org/show_bug.cgi?id=1362154
const regexZone = /(?:UTC\s?)([+|-]\d{1,})/;

const renderLocalTime = (timeZone) => {
  if (typeof timeZone === 'string') {
    const [, offset = ''] = regexZone.exec(timeZone) || [];
    return moment()
      .add(-moment().utcOffset() / 60 + Number(offset), 'h')
      .format('HH:mm');
  }
  return '';
};

const initialState = {
  search: null,
  isFocused: false,
  isLoaded: false,
  isFetching: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
  SELECT: 'SELECT',
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FOCUS: {
      return {
        ...state,
        isFocused: true,
        isLoaded: false,
      };
    }
    case ACTIONS.BLUR: {
      return {
        ...state,
        isFocused: false,
        isLoaded: false,
      };
    }
    case ACTIONS.SELECT: {
      return {
        ...state,
        isFocused: false,
        isLoaded: false,
      };
    }
    case ACTIONS.FETCH: {
      return {
        ...state,
        isFetching: true,
        isLoaded: false,
        search: action.search,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isLoaded: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

const FIELDS_WITH_GUID = [
  'region',
  'area',
  'city',
  'street',
  'settlement',
  'country',
  'planStructure',
  'addressRegistration',
  'addressLive',
  'addressWork',
];

// https://selfteam.atlassian.net/browse/TB-3762
// special case - we ignore `text` in the `name` if the name end with `text`

const getName = (s) => (s.endsWith('text') ? s.substring(0, s.length - 4) : s);

const getAddressFilledFields = (data, name) =>
  Object.keys(data).reduce((o, k) => (data[k] ? { ...o, [name + k]: data[k] } : o), {});
const getAddressEmptyFields = (data, name) =>
  Object.keys(data).reduce((o, k) => [...o, ...(data[k] ? [] : [name + k])], []);

function AddressField({
  type,
  name,
  alias,
  dependencies,
  label,
  error: errorMessage,
  placeholder,
  timeZone,
  area,
  disabled,
  dependents,
  valueOnlyFromOptions,
  ifChangeClearFields,
  warning,
  dataSource,

  values = {},
  queue = {},
  form: { getFieldDecorator, getFieldValue, setFieldsValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const [searchTerm, setSearchTerm] = useState(initialValue || '');
  const [isCount, setIsCount] = useState(false);
  const [searchGuid, setSearchGuid] = useState('');

  useEffect(() => {
    setSearchTerm(initialValue || '');
  }, [initialValue, setSearchTerm]);

  const debouncedSearchTerm = useDebounce(searchTerm, 750);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.isFocused || isCount) {
      const isCanSearch = (debouncedSearchTerm.length >= 3) || (debouncedSearchTerm.length === 0);
      if (debouncedSearchTerm !== state.search && isCanSearch) {
        dispatch({ type: ACTIONS.FETCH, search: debouncedSearchTerm });
        API.fias
          .fetchFIAS({
            alias,
            value: debouncedSearchTerm,
            dependencies: { ...defineDependencies(dependencies, values), ...(isCount ? { count: 1 } : {}) },
          })
          .then((results) => {
            setIsCount(false);
            dispatch({
              type: ACTIONS.FETCH_SUCCESS,
              list: formatFias(results, alias === 'organization'),
            });
          })
          .catch((error) => {
            dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
          });
      }
    }
  }, [state.isFocused, state.search, debouncedSearchTerm, alias, dependencies, values, isCount]);

  useEffect(() => {
    // if user selected option and server response data
    if (searchGuid && state.isLoaded) {
      if (state.list.length > 0) {
        const option = state.list[0];

        if (option) {
          const { additionalValues, GUID, value } = option;
          const _name = getName(name);

          const userInput = {
            // split address field values
            ...getAddressFilledFields(additionalValues, _name),

            // write GUID value of field in the hidden input
            ...(FIELDS_WITH_GUID.some((s) => _name.includes(s))
              ? { [`${_name}GUID`]: GUID }
              : {}),

            [_name]: value,
          };

          setSearchTerm(value);

          onChangeField(
            userInput,
            mergeArrays(
              dependents,
              ifChangeClearFields,
              getAddressEmptyFields(additionalValues, _name),
            ),
          );
        }
      }
      setSearchGuid('');
    }
  }, [
    state,
    searchGuid,
    debouncedSearchTerm,
    ifChangeClearFields,
    onChangeField,
    dependents,
    name,
  ]);

  function handleSelect(v) {
    const option = state.list.find((e) => e.value === v);
    if (option) {
      const { additionalValues, GUID } = option;
      const _name = getName(name);

      const userInput = {
        // split address field values
        ...getAddressFilledFields(additionalValues, _name),

        // write GUID value of field in the hidden input
        ...(FIELDS_WITH_GUID.some((s) => _name.includes(s))
          ? { [`${_name}GUID`]: GUID }
          : {}),

        [_name]: v,
      };

      setIsCount(true);
      setSearchGuid(GUID);
      focusableNode.current.blur();
      dispatch({ type: ACTIONS.SELECT });

      onChangeField(
        userInput,
        mergeArrays(
          dependents,
          ifChangeClearFields,
          getAddressEmptyFields(additionalValues, _name),
        ),
      );
    }
  }

  function handleOnBlur() {
    if (
      searchTerm !== values[name] //   form already has that value
      && queue[name] !== searchTerm // request is already pending
    ) {
      if (!valueOnlyFromOptions) {
        const _name = getName(name);

        const userInput = {
          [_name]: searchTerm,
        };

        const exludeInput = [
          ...mergeArrays(dependents, ifChangeClearFields),

          // reset `address` and `organization` field values
          ...Object.keys(values).filter((e) => e.startsWith(_name)),

          // reset GUID value of field in the hidden input
          ...(FIELDS_WITH_GUID.some((s) => _name.includes(s)) ? [`${_name}GUID`] : []),
        ];

        onChangeField(userInput, exludeInput);
      }
    }
    dispatch({ type: ACTIONS.BLUR });
  }

  const setFieldValueToForm = useCallback((value) => {
    setFieldsValue({ [name]: value });
  }, [name, setFieldsValue]);

  const getValueFromEvent = useCallback((e) => (e && e.target && e.target.value) || (typeof e === 'string' && e) || '', []);

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  const curValue = getFieldValue(name) || '';
  const suffix = ((initialValue === curValue) || !curValue) && (dataSource === 'digital_profile') ? 'DP' : null;

  // Жесткий костыль который приводит к тому,
  // что мы заменяем значение поля в случае,
  // если не работаем с этим полем в данный момент и приоритет отдаем значения с бэка.
  useEffect(() => {
    if (debouncedSearchTerm !== initialValue && state.isLoaded && !state.isFocused) {
      setFieldValueToForm(initialValue);
    }
  }, [debouncedSearchTerm, initialValue, state.isLoaded, state.isFocused, setFieldValueToForm]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      <WithSuffix suffix={suffix} area={area}>
        <InputContainer>
          {getFieldDecorator(name, {
            rules,
            initialValue: initialValue || '',
            getValueFromEvent,
          })(
            <AutoComplete
              id={name}
              name={name}
              placeholder={placeholder}
              onSearch={setSearchTerm}
              onSelect={handleSelect}
              onFocus={() => dispatch({ type: ACTIONS.FOCUS })}
              onBlur={handleOnBlur}
              disabled={disabled}
              notFoundContent={
                state.error ? (
                  <span>{state.error}</span>
                ) : state.isFetching ? (
                  <LoadingOutlined />
                ) : searchTerm && searchTerm.length < 3 ? (
                  <sup>[запрос должен содержать от 3-ех символов]</sup>
                ) : (
                  <span>нет данных</span>
                )
              }
              ref={focusableNode}
              size={size}
            >
              {state.list.map((option) => (
                <Option key={option.value}>{option.value}</Option>
              ))}
            </AutoComplete>,
          )}
          {timeZone && (
            <TimeBlock>
              <TimeZone>{timeZone}</TimeZone>
              <TimeLocal>{renderLocalTime(timeZone)}</TimeLocal>
            </TimeBlock>
          )}
        </InputContainer>
        {suffix && <Suffix>{suffix}</Suffix>}
      </WithSuffix>
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default React.memo(
  AddressField,
  (prev, next) => (prev.initialValue === next.initialValue) && (prev.error === next.error),
);
