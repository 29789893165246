import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'components/Button';
import {
  Form, Input, Checkbox, Menu, Dropdown,
} from 'antd';
import Select from 'components/Select';
import { Rifm } from 'rifm';
import { formatPhone, cursorToLastPhoneValuePosition } from 'utils';
import { checkPrivilege } from 'helpers/roles';
import { checkIsIternalUser, checkIsOutourceUser } from 'hacks/formatUsers';
import { CaretDownOutlined, SettingOutlined } from '@ant-design/icons';

import { DEBOUNCE_SEARCH_TIME } from 'settings';

import { useDebounce } from 'hooks';

import {
  createUser as createUserAction,
  createUserReset as createUserResetAction,
  updateUser as updateUserAction,
  fetchAvailableRoles as fetchAvailableRolesAction,
  resetUserStore as resetUserStoreAction,
  checkUserEmail as checkUserEmailAction,
  checkUserEmailReset as checkUserEmailResetAction,
} from 'redux/user/actions';

import {
  fetchList as fetchCompanyListAction,
  setSearch as setSearchAction,
} from 'redux/company/actions';

import {
  ErrorMessage,
  SuccessMessage,
} from 'components/Messages';

import ConfirmModal from './ConfirmModal';

import { KEYS as K } from './data';

import {
  Root,
  Header,
  Title,
  Main,
  Rows,
  Row,
  Footer,
  SettingsContainer,
  UserType,
  UserDropDown,
  HiddenContainer,
} from './style';

const USER_STATUS = {
  active: 'Активный',
  new: 'Новый',
  blocked_by_bank: 'Заблокирован банком',
  blocked_change_password: 'Должен сменить пароль',
};

const USER_TYPE_LIST = [
  { label: 'Внутренний пользователь', value: 'internal' },
  { label: 'Аутсорс пользователь', value: 'outsource' },
];

const getAvailableRoles = ({ list = [], isUserInternal }) =>
  list
    .filter(({ forExternal }) => forExternal === !isUserInternal)
    .map((elem) => ({ id: elem.id, name: elem.name }));

function UserForm({
  // connect
  history,
  role,

  userData,
  userIsLoaded,

  checkEmailIsLoading,
  checkEmailIsLoaded,
  checkEmailError,

  createData,
  createIsLoading,
  createIsLoaded,
  createError,
  createErrors,

  updateIsLoading,
  updateIsLoaded,
  updateError,
  updateErrors,

  availableRoles,
  availableRolesIsLoading,
  availableRolesIsLoaded,
  availableRolesError,

  companyList,
  companyListIsLoading,
  companyListIsLoaded,
  companyListError,

  // actions
  createUser,
  createUserReset,
  updateUser,
  fetchAvailableRoles,
  fetchCompanyList,
  resetUserStore,
  setSearch,
  checkUserEmail,
  checkUserEmailReset,
}) {
  const [form] = Form.useForm();
  const { companyId } = useParams();
  const phoneField = useRef();
  const location = useLocation();
  const { userId } = useParams();
  const [dealerCentersByCompany, setDealerCentersByCompany] = useState([]);
  const [isValidated, setIsValidated] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [userStatus, setUserStatus] = useState('new');
  const [availableRolesList, setAvailableRolesList] = useState([]);
  const [isRoleTCM, setIsRoleTCM] = useState(false);
  const [canEditForm, setCanEditForm] = useState(false);
  const needToCheckEmail = checkPrivilege([role.MANAGEMENT.VALIDATE_EMAIL_USER]);
  const [isOutsourceUser, setIsOutsourceUser] = useState(checkIsOutourceUser(userData));
  const [createOutsourceUser, setCreateOutsourceUser] = useState(false);
  const canChangeUserType = checkPrivilege([role.MANAGEMENT.CAN_SELECT_INNER_USER_TYPE]);

  const [isUserInternal, setIsUserInternal] = useState(checkPrivilege([
    role.MANAGEMENT.ADD_ACTIVE_DIRECTORY_USER,
  ]));

  const isNew = location.pathname.endsWith('/new');
  const isEdit = location.pathname.endsWith('/edit');
  const isReadOnly = (userIsLoaded && !isEdit) || createIsLoaded;
  const initialValues = {
    [K.IS_INTERNAL.key]: isUserInternal,
  };
  const canRestorePassword = checkPrivilege([
    role.MANAGEMENT.RECOVER_USER_PASSWORD,
    role.MANAGEMENT.RECOVER_USER_PASSWORD_EXTERNAL,
  ]);

  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_SEARCH_TIME);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  function handleShowModal(action) {
    openModal();
    setModalAction(action);
  }

  const menuItems = [
    {
      checkRights: (role) => checkPrivilege([
        role.MANAGEMENT.RECOVER_USER_PASSWORD,
        role.MANAGEMENT.RECOVER_USER_PASSWORD_EXTERNAL,
      ]),
      userStatus: ['new', 'active', 'blocked_change_password'],
      component: (
        (isUserInternal && checkPrivilege([role.MANAGEMENT.RECOVER_USER_PASSWORD]))
          || (!isUserInternal && checkPrivilege([role.MANAGEMENT.RECOVER_USER_PASSWORD_EXTERNAL]))
      ) && (
        <Menu.Item key="3" onClick={() => handleShowModal('restore')}>
          Сбросить пароль
        </Menu.Item>
      ),
    },
  ];

  const menu = (
    <Menu>
      {menuItems
        .filter((e) => e.checkRights(role))
        .filter((item) => item.userStatus.includes(userStatus))
        .map((item) => item.component)}
    </Menu>
  );

  // function handleChange({ target: { checked: v } }) {
  //   if (checkPrivilege(role.MANAGEMENT.ADD_WEB_USER)) {
  //     setIsUserInternal(v);
  //   }
  // }

  function handleSearchCompany(value) {
    setSearchTerm(value);
  }

  function handleSearchDealerCenter(value) {
    const companyId = form.getFieldValue(K.COMPANY.key);
    const selectedCompany = companyList.find((e) => e.id === companyId);
    const { subsidiariesList } = selectedCompany;

    if (Array.isArray(subsidiariesList) && subsidiariesList.length > 0) {
      setDealerCentersByCompany(
        subsidiariesList
          .map((item) => ({ ...item, search: item.name ? item.name.toLowerCase() : '' }))
          .filter((item) => item.search.includes(value.toLowerCase())),
      );
    } else {
      setDealerCentersByCompany([]);
    }
  }

  const handleCompanyChange = useCallback((companyId) => {
    form.setFieldsValue({
      [K.DEALER_CENTER.key]: '',
    });

    const selectedCompany = companyList.find((e) => e.id === companyId);

    if (selectedCompany) {
      if (Array.isArray(selectedCompany.subsidiariesList)) {
        if (isRoleTCM) {
          setDealerCentersByCompany(
            selectedCompany.subsidiariesList.filter((item) => item.krifOrganizationName),
          );
        } else {
          setDealerCentersByCompany(selectedCompany.subsidiariesList);
        }
      }
    }
  }, [companyList, form, isRoleTCM]);

  function handleValuesChange(value) {
    const { ROLE, USER_TYPE } = value;

    if (ROLE) {
      const isRoleTCM = availableRolesList.find((item) => (item.id === ROLE)
          && ((item.name === 'ТСМ Менеджер ДЦ') || (item.name === 'ТСМ Сотрудник ДЦ')));

      if (isRoleTCM && isRoleTCM.id) {
        setIsRoleTCM(true);
      } else {
        setIsRoleTCM(false);
      }
    }

    if (USER_TYPE) {
      setCreateOutsourceUser(USER_TYPE === 'outsource');
    }
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleFinish(values) {
    const {
      [K.EMAIL.key]: email,
      [K.LOGIN.key]: login,
      [K.PHONE.key]: phone,
      [K.NAME.key]: fullName,
      [K.IS_INTERNAL.key]: isInternal,
      [K.ROLE.key]: roleId,
      [K.COMPANY.key]: merchantId,
      [K.DEALER_CENTER.key]: dealerCenterId,
      [K.USER_TYPE.key]: type,
    } = values;

    // дополнительная проверка на то, чтобы не слать маску на бэк
    const validPhone = phone && phone.search('_') !== -1 ? null : phone;

    if (isNew) {
      if (needToCheckEmail) {
        if (email && merchantId) {
          checkUserEmail({ merchantId, email });
        } else {
          form.setFields([
            ...(!email ? [{ name: K.EMAIL.key, errors: ['Заполните поле email'] }] : []),
            ...(!merchantId ? [{ name: K.COMPANY.key, errors: ['Выбирте организацию'] }] : []),
          ]);
        }
      } else {
        createUser({
          email,
          login: createOutsourceUser ? email : login,
          phone: validPhone,
          fullName,
          // Если есть разрешение на изменение типа значит от может поменять на внутренний или аутсорс тип пользователя
          // оба этих типа относяться в внутренним,
          isInternal: canChangeUserType || isInternal,
          // Если есть разраешение на смену типа,
          // тогда мы должны выбором типа выбрать пользователя если нет то огда от начального состояния
          userType: canChangeUserType ? type : isInternal ? 'internal' : 'external',
          roleId,
          ...(!isUserInternal
            ? {
              merchantId,
              dealerCenterId,
            }
            : {}),
        });
      }
    }

    if (isEdit) {
      updateUser({ email, phone: validPhone, fullName });
    }

    setIsValidated(false);
  }

  useEffect(() => () => resetUserStore(), [resetUserStore]);

  useEffect(() => {
    createUserReset();
  }, [createUserReset]);

  useEffect(() => {
    if (isNew) {
      fetchAvailableRoles();
    }
  }, [fetchAvailableRoles, isNew]);

  useEffect(() => {
    if (availableRolesIsLoaded) {
      setAvailableRolesList(getAvailableRoles({
        list: availableRoles,
        isUserInternal: isOutsourceUser || isUserInternal,
      }));
    }
  }, [availableRolesIsLoaded, availableRoles, isUserInternal, isOutsourceUser]);

  useEffect(() => {
    if (!isUserInternal && availableRolesIsLoaded) {
      fetchCompanyList({
        withPagination: false,
      });
    }
  }, [isUserInternal, fetchCompanyList, availableRolesIsLoaded]);

  useEffect(() => {
    if ((debouncedSearchTerm === searchTerm) && (searchTerm !== null)) {
      setSearch({ search: searchTerm });
      form.setFieldsValue({
        [K.DEALER_CENTER.key]: '',
      });
    }
  }, [debouncedSearchTerm, searchTerm, setSearch, form]);

  useEffect(() => {
    if (createIsLoaded) {
      createUserReset();
      checkUserEmailReset();
      history.push(`/app/management/companies/${companyId}/users/${createData.id}`);
    }
  }, [
    createIsLoaded,
    createData.id,
    companyId,
    history,
    checkUserEmailReset,
    createUserReset,
  ]);

  useEffect(() => {
    if (companyListIsLoaded && companyId) {
      form.setFields([
        ...([{ name: K.COMPANY.key, value: Number(companyId) }]),
      ]);
      handleCompanyChange(Number(companyId));
    }
  }, [companyListIsLoaded, companyId, form, handleCompanyChange]);

  useEffect(() => {
    if (userIsLoaded) {
      const {
        displayName,
        email,
        phone,
        status,
        userType,
        login,
        roleId,
        merchantId,
        dealerCenterId,
      } = userData;
      const isInternal = checkIsIternalUser(userType);
      const isOutsource = checkIsOutourceUser(userType);
      setIsUserInternal(isInternal || isOutsource);
      setCreateOutsourceUser(isOutsource);
      setIsOutsourceUser(isOutsource);

      form.setFields([
        ...(displayName ? [{ name: K.NAME.key, value: displayName }] : []),
        ...(email ? [{ name: K.EMAIL.key, value: email }] : []),
        ...(phone ? [{ name: K.PHONE.key, value: phone.replace(/\+7/, '') }] : []),
        ...(login ? [{ name: K.LOGIN.key, value: login }] : []),
        ...(status ? [{ name: K.STATUS.key, value: USER_STATUS[status] }] : []),
        ...(isInternal ? [{ name: K.IS_INTERNAL.key, value: isInternal }] : []),
        ...(roleId ? [{ name: K.ROLE.key, value: roleId }] : []),
        ...(merchantId ? [{ name: K.COMPANY.key, value: merchantId }] : []),
        ...(dealerCenterId
          ? [{ dealerCenterId: K.DEALER_CENTER.key, value: dealerCenterId }]
          : []),
        ...(canChangeUserType ? [{ name: K.USER_TYPE.key, value: isOutsource ? 'outsource' : 'internal' }] : []),
      ]);
      setUserStatus(status);
    }
  }, [userIsLoaded, canChangeUserType, userData, form]);

  useEffect(() => {
    if (
      !isValidated
      && !createIsLoading
      && Array.isArray(createErrors)
      && createErrors.length > 0
    ) {
      const errorsFlat = createErrors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.EMAIL.apiKey]: email,
        [K.LOGIN.apiKey]: login,
        [K.PHONE.apiKey]: phone,
        [K.NAME.apiKey]: name,
        [K.IS_INTERNAL.apiKey]: isInternal,
        [K.ROLE.apiKey]: roleId,
        [K.COMPANY.apiKey]: merchantId,
        [K.DEALER_CENTER.apiKey]: dealerCenterId,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(email ? [{ name: K.EMAIL.key, errors: [email] }] : []),
        ...(login ? [{ name: K.LOGIN.key, errors: [login] }] : []),
        ...(phone ? [{ name: K.PHONE.key, errors: [phone] }] : []),
        ...(name ? [{ name: K.NAME.key, errors: [name] }] : []),
        ...(isInternal ? [{ name: K.IS_INTERNAL.key, errors: [isInternal] }] : []),
        ...(roleId ? [{ name: K.ROLE.key, errors: [roleId] }] : []),
        ...(merchantId ? [{ name: K.COMPANY.key, errors: [merchantId] }] : []),
        ...(dealerCenterId
          ? [{ dealerCenterId: K.DEALER_CENTER.key, value: dealerCenterId }]
          : []),
      ]);
      setIsValidated(true);
    }
  }, [createErrors, createIsLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if (
      !isValidated
      && !updateIsLoading
      && Array.isArray(updateErrors)
      && updateErrors.length > 0
    ) {
      const errorsFlat = updateErrors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.EMAIL.apiKey]: email,
        [K.PHONE.apiKey]: phone,
        [K.NAME.apiKey]: name,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(email ? [{ name: K.EMAIL.key, errors: [email] }] : []),
        ...(phone ? [{ name: K.PHONE.key, errors: [phone] }] : []),
        ...(name ? [{ name: K.NAME.key, errors: [name] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [updateErrors, updateIsLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if (checkEmailError) {
      form.setFields([
        { name: K.EMAIL.key, errors: [checkEmailError] },
      ]);
    }
  }, [checkEmailError, form]);

  useEffect(() => {
    if (checkEmailIsLoaded && isNew && needToCheckEmail) {
      const {
        [K.EMAIL.key]: email,
        [K.LOGIN.key]: login,
        [K.PHONE.key]: phone,
        [K.NAME.key]: fullName,
        [K.IS_INTERNAL.key]: isInternal,
        [K.ROLE.key]: roleId,
        [K.COMPANY.key]: merchantId,
        [K.DEALER_CENTER.key]: dealerCenterId,
        [K.USER_TYPE.key]: type,
      } = form.getFieldsValue();

      // дополнительная проверка на то, чтобы не слать маску на бэк
      const validPhone = phone && phone.search('_') !== -1 ? null : phone;

      createUser({
        email,
        login: createOutsourceUser ? email : login,
        phone: validPhone,
        fullName,
        // Если есть разрешение на изменение типа значит от может поменять на внутренний или аутсорс тип пользователя
        // оба этих типа относяться в внутренним,
        isInternal: canChangeUserType || isInternal,
        // Если есть разраешение на смену типа,
        // тогда мы должны выбором типа выбрать пользователя если нет то огда от начального состояния
        userType: canChangeUserType ? type : isInternal ? 'internal' : 'external',
        roleId,
        ...(!isUserInternal
          ? {
            merchantId,
            dealerCenterId,
          }
          : {}),
      });
    }
  }, [
    checkEmailIsLoaded,
    form,
    createUser,
    isNew,
    needToCheckEmail,
    isUserInternal,
    canChangeUserType,
    createOutsourceUser,
  ]);

  useEffect(() => {
    const companyId = form.getFieldValue(K.COMPANY.key);
    if (companyId) {
      handleCompanyChange(companyId, isRoleTCM);
    }
  }, [form, isRoleTCM, handleCompanyChange]);

  useEffect(() => {
    if (isUserInternal) {
      setCanEditForm(checkPrivilege([
        role.MANAGEMENT.EDIT_ALL_ACTIVE_DIRECTORY_USERS,
        role.MANAGEMENT.EDIT_ACTIVE_DIRECTORY_USERS,
      ]));
    } else {
      setCanEditForm(checkPrivilege([
        role.MANAGEMENT.EDIT_ALL_WEB_USERS,
        role.MANAGEMENT.EDIT_WEB_USERS,
      ]));
    }
  }, [role, isUserInternal]);

  useEffect(() => {
    if (availableRolesList.length > 0) {
      // это было сделано потому что нужно для валидации точки продажи проверять на наличия роли ТСМ
      // и проверку по id мы не можем сделать так как id на разных контурах разное.
      form.setFields([
        { name: 'HIDDEN_ROLES', value: availableRolesList.map((item) => `${item.id}_${item.name}`) },
      ]);
    }
  }, [availableRolesList, form]);

  return (
    <Root>
      <Header>
        <Title>{userId ? 'Пользователь' : 'Создать нового пользователя'}</Title>
        <SettingsContainer>
          {userIsLoaded && canRestorePassword && (
            <Dropdown overlay={menu}>
              <UserDropDown>
                <SettingOutlined />
                <CaretDownOutlined />
              </UserDropDown>
            </Dropdown>
          )}
          {userIsLoaded && !isEdit && canEditForm && (
            <Button
              size="small"
              type="link"
              shape="square"
              href={`/app/management/companies/${companyId}/users/${userId}/edit`}
            >
              Редактировать
            </Button>
          )}
        </SettingsContainer>
      </Header>
      {((userId && userIsLoaded) || !userId) && (
        <Main
          name="user"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleFinish}
          autoComplete="off"
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
        >
          <Rows>
            <HiddenContainer>
              <Form.Item
                name="HIDDEN_ROLES"
                validateFirst
                validateTrigger="onBlur"
              >
                <Select
                  name="HIDDEN_ROLES"
                  mode="multiple"
                  list={availableRolesList}
                  hasDefault={false}
                />
              </Form.Item>
            </HiddenContainer>
            <Row>
              {checkPrivilege(role.MANAGEMENT.ADD_ACTIVE_DIRECTORY_USER) && isNew ? (
                <Form.Item name={K.IS_INTERNAL.key} valuePropName="checked">
                  <Checkbox
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled
                  >
                    {K.IS_INTERNAL.title}
                  </Checkbox>
                </Form.Item>
              ) : !isNew && (
                <UserType>
                  {isOutsourceUser ? 'Аутсорс пользователь' : isUserInternal ? 'Внутренний пользователь' : 'Внешний пользователь'}
                </UserType>
              )}
              {canChangeUserType && (
                <Form.Item
                  name={K.USER_TYPE.key}
                  label={K.USER_TYPE.title}
                  rules={K.USER_TYPE.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Select
                    name={K.USER_TYPE.key}
                    onFocus={(e) => handleFocus(e, K.USER_TYPE.key)}
                    onBlur={(e) => handleBlur(e, K.USER_TYPE.key)}
                    list={USER_TYPE_LIST}
                    hasDefault={false}
                    disabled={!isNew}
                  />
                </Form.Item>
              )}
              <Form.Item
                name={K.NAME.key}
                label={K.NAME.title}
                rules={K.NAME.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input
                  name={K.NAME.key}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={isReadOnly}
                />
              </Form.Item>

              {isUserInternal && !createOutsourceUser && (
                <Form.Item
                  name={K.LOGIN.key}
                  label={K.LOGIN.title}
                  rules={K.LOGIN.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.LOGIN.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={!isNew}
                  />
                </Form.Item>
              )}

              <Form.Item
                name={K.EMAIL.key}
                label={K.EMAIL.title}
                rules={K.EMAIL.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input
                  name={K.EMAIL.key}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={isReadOnly ? true : !isUserInternal && isEdit}
                />
              </Form.Item>

              {(!isUserInternal || createOutsourceUser) && (
                <Form.Item
                  name={K.PHONE.key}
                  label={K.PHONE.title}
                  rules={!isUserInternal ? [] : K.PHONE.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Rifm
                    accept={/[\d]/g}
                    mask
                    replace={formatPhone.replace}
                    format={formatPhone.format}
                    onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                  >
                    {({ value, onChange }) => (
                      <Input
                        name={K.PHONE.key}
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                        addonBefore={formatPhone.prefix}
                        disabled={isReadOnly}
                        ref={phoneField}
                      />
                    )}
                  </Rifm>
                </Form.Item>
              )}
              { isNew && (
                <>
                  {!isUserInternal && (
                    <>
                      <Form.Item
                        name={K.COMPANY.key}
                        label={K.COMPANY.title}
                        rules={K.COMPANY.rules}
                        validateFirst
                        validateTrigger="onBlur"
                      >
                        <Select
                          name={K.COMPANY.key}
                          showSearch
                          onSearch={handleSearchCompany}
                          onFocus={(e) => handleFocus(e, K.COMPANY.key)}
                          onBlur={(e) => handleBlur(e, K.COMPANY.key)}
                          onChange={handleCompanyChange}
                          loading={companyListIsLoading}
                          disabled
                          filterOption={false}
                          list={companyList}
                          hasDefault={false}
                        />
                      </Form.Item>
                      {companyListError && <ErrorMessage>{companyListError}</ErrorMessage>}
                      <Form.Item
                        name={K.DEALER_CENTER.key}
                        label={K.DEALER_CENTER.title}
                        rules={K.DEALER_CENTER.rules}
                        validateFirst
                        validateTrigger="onBlur"
                      >
                        <Select
                          showSearch
                          onSearch={handleSearchDealerCenter}
                          name={K.DEALER_CENTER.key}
                          onFocus={(e) => handleFocus(e, K.DEALER_CENTER.key)}
                          onBlur={(e) => handleBlur(e, K.DEALER_CENTER.key)}
                          loading={companyListIsLoading}
                          disabled={companyListIsLoading || isReadOnly}
                          filterOption={false}
                          list={dealerCentersByCompany}
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    name={K.ROLE.key}
                    label={K.ROLE.title}
                    rules={K.ROLE.rules}
                    validateFirst
                    validateTrigger="onBlur"
                  >
                    <Select
                      name={K.ROLE.key}
                      onFocus={(e) => handleFocus(e, K.ROLE.key)}
                      onBlur={(e) => handleBlur(e, K.ROLE.key)}
                      loading={availableRolesIsLoading}
                      disabled={availableRolesIsLoading || isReadOnly}
                      list={availableRolesList}
                      hasDefault={false}
                    />
                  </Form.Item>
                  {availableRolesError && (
                    <ErrorMessage>{availableRolesError}</ErrorMessage>
                  )}
                </>
              )}

              {!isNew && (
                <Form.Item
                  name={K.STATUS.key}
                  label={K.STATUS.title}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input name={K.STATUS.key} disabled />
                </Form.Item>
              )}

              {createError && <ErrorMessage>{createError}</ErrorMessage>}

              {isNew && (
                <Footer>
                  {createIsLoaded ? (
                    <SuccessMessage>Пользователь успешно создан</SuccessMessage>
                  ) : (
                    <Button
                      block
                      type="primary"
                      shape="square"
                      htmlType="submit"
                      disabled={checkEmailIsLoading}
                      loading={createIsLoading || checkEmailIsLoading}
                    >
                      Создать пользователя
                    </Button>
                  )}
                </Footer>
              )}
              {isEdit && (
                <>
                  <Footer>
                    <Button
                      block
                      type="primary"
                      shape="square"
                      htmlType="submit"
                      loading={updateIsLoading}
                    >
                      Сохранить изменения
                    </Button>
                  </Footer>
                  {updateError && <ErrorMessage>{updateError}</ErrorMessage>}
                  {updateIsLoaded && (
                    <SuccessMessage>
                      Пользовательские данные были обновлены
                    </SuccessMessage>
                  )}
                </>
              )}
            </Row>
          </Rows>
        </Main>
      )}
      <ConfirmModal
        visible={isModalOpen}
        close={closeModal}
        modalAction={modalAction}
        userId={userId}
      />
    </Root>
  );
}

export default withRouter(
  connect(
    (state) => ({
      role: state.auth.role,

      userData: state.user.item.data,
      userIsLoading: state.user.item.isLoading,
      userIsLoaded: state.user.item.isLoaded,
      userError: state.user.item.error,

      checkEmailIsLoading: state.user.checkEmail.isLoading,
      checkEmailIsLoaded: state.user.checkEmail.isLoaded,
      checkEmailError: state.user.checkEmail.error,

      createData: state.user.create.data,
      createIsLoading: state.user.create.isLoading,
      createIsLoaded: state.user.create.isLoaded,
      createError: state.user.create.error,
      createErrors: state.user.create.errors,

      updateData: state.user.update.data,
      updateIsLoading: state.user.update.isLoading,
      updateIsLoaded: state.user.update.isLoaded,
      updateError: state.user.update.error,
      updateErrors: state.user.update.errors,

      availableRoles: state.user.availableRoles.list,
      availableRolesIsLoading: state.user.availableRoles.isLoading,
      availableRolesIsLoaded: state.user.availableRoles.isLoaded,
      availableRolesError: state.user.availableRoles.error,

      companyList: state.company.collection.list,
      companyListIsLoading: state.company.collection.isLoading,
      companyListIsLoaded: state.company.collection.isLoaded,
      companyListError: state.company.collection.error,
    }),
    {
      createUser: createUserAction,
      createUserReset: createUserResetAction,
      updateUser: updateUserAction,
      fetchAvailableRoles: fetchAvailableRolesAction,
      fetchCompanyList: fetchCompanyListAction,
      resetUserStore: resetUserStoreAction,
      setSearch: setSearchAction,
      checkUserEmail: checkUserEmailAction,
      checkUserEmailReset: checkUserEmailResetAction,
    },
  )(UserForm),
);
