import { b64toBlob, flattenFields } from 'utils';
import { FIXBLOCK_TYPES, STATUS_LIST, TYPES } from 'settings';

import {
  REQUEST__DELETE_FILE,
  REQUEST__DELETE_FILE_FAILURE,
  REQUEST__DELETE_FILE_SUCCESS,
  REQUEST__FETCH,
  REQUEST__FETCH_FAILURE,
  REQUEST__FETCH_FILE,
  REQUEST__FETCH_FILE_FAILURE,
  REQUEST__FETCH_FILE_SUCCESS,
  REQUEST__FETCH_SUCCESS,
  REQUEST__PATCH,
  REQUEST__PATCH_FAILURE,
  REQUEST__PATCH_LOADING_DISABLE,
  REQUEST__PATCH_LOADING_ENABLE,
  REQUEST__PATCH_PENDING_RESET,
  REQUEST__PATCH_PENDING_UPDATE,
  REQUEST__PATCH_SUCCESS,
  REQUEST__REMOVE_PREVIEW,
  REQUEST__SAVE,
  REQUEST__SAVE_FAILURE,
  REQUEST__SAVE_RESET,
  REQUEST__SAVE_SUCCESS,
  REQUEST__UPLOAD_FILE,
  REQUEST__UPLOAD_FILE_CONFIRMED,
  REQUEST__UPLOAD_FILE_ERROR,
  REQUEST__UPLOAD_FILE_RECOGNIZED,
  REQUEST__UPLOAD_FILE_UPLOADED,
  REQUEST__UPLOAD_FILES,
  REQUEST__UPLOAD_FILES_SET_LAST_FILE,
  REQUEST_GET_ANSWER_DETAILS,
  REQUEST_GET_ANSWER_DETAILS__FAILURE,
  REQUEST_GET_ANSWER_DETAILS__RESET,
  REQUEST_GET_ANSWER_DETAILS__SUCCESS,
  REQUEST_REVISION__FETCH,
  REQUEST_REVISION__FETCH_FAILURE,
  REQUEST_REVISION__FETCH_RESET,
  REQUEST_REVISION__FETCH_SUCCESS,
} from './actions';

const STATUS_KEY = 'zeroStatus';
const STATUS_READ_ONLY = String(STATUS_LIST[4].value);

const filePreview = {
  file: null,
  isLoading: false,
  isLoaded: false,
  error: null,
};

const fileDraft = {
  value: '',
  label: '',
  error: '',
  status: '',
  disabled: false,
  isLoaded: false, // true if we recieved data from the server
  isLoading: false, // final state of upload (either failed with error or succesfully
  // uploaded -> recognised -> saved in the form)
  isDeleting: false, // true if delete request is in progress
  isUploaded: false, // true if upload request is in progress
  isRecognized: false, // true if file has been uploaded successfully and currently in recognising state
  isConfirmed: false, // true if file successfully had been recognised
};

const initState = {
  fields: [],
  fieldsFlat: [],
  values: {},
  popups: [],

  // we check response.data.next - status should be locked till next !== true
  // in order to not allow user to change status of a request to Submit/5
  // (just check the settings - there should be status list)
  statusIsLocked: false,

  formIsDisabled: false,

  fetch: {
    id: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  patch: {
    pending: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  save: {
    id: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  // files
  previews: {},
  fileToProccess: null, // store the fieldname of the file for autoscroll
  files: {},
  details: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  revision: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },

};

const getFiles = (fields, state) => {
  const fixblock = fields.find((e) => e.type === TYPES.MAIN);
  const fixBlockBody = fixblock
    ? fixblock.data.find((e) => e.name === 'zeroFormBlockBody')
    : { data: [] };
  const fileList = flattenFields(fixBlockBody.data).filter(
    (e) => e.type === FIXBLOCK_TYPES.DOCUMENT,
  );
  const files = fileList.reduce((o, file) => {
    const status = state.files[file.name]
      ? state.files[file.name].status
      : file.value
        ? 'Файл загружен с сервера'
        : '';

    return {
      ...o,
      [file.name]: {
        ...fileDraft,
        ...state.files[file.name],
        value: file.value,
        label: file.label,
        error: file.error,
        status,
        disabled: Boolean(file.disabled),
        isLoaded: Boolean(file.value),
      },
    };
  }, {});

  return files;
};

export default function requestReducer(state = initState, action) {
  switch (action.type) {
    case REQUEST__FETCH: {
      return {
        ...initState,

        fetch: {
          ...initState.fetch,
          id: action.accessToken,
          isLoading: true,
        },
      };
    }
    case REQUEST__FETCH_SUCCESS: {
      const fieldsFlat = flattenFields(action.fields);
      const values = fieldsFlat
        .filter((e) => e.value !== null)
        .reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
      const formIsDisabled = fieldsFlat.find((e) => e.name === STATUS_KEY).value === STATUS_READ_ONLY;

      return {
        ...state,
        fields: action.fields,
        fieldsFlat,
        values,
        popups: action.popups,
        statusIsLocked: !action.next,
        formIsDisabled,

        fetch: {
          id: state.fetch.id,
          isLoading: false,
          isLoaded: true,
          error: null,
        },

        files: getFiles(fieldsFlat, state),
      };
    }
    case REQUEST__FETCH_FAILURE: {
      return {
        ...state,

        fetch: {
          id: state.fetch.id,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REQUEST__PATCH: {
      return {
        ...state,

        queue: action.userInput,

        patch: {
          ...state.patch,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REQUEST__PATCH_PENDING_UPDATE: {
      return {
        ...state,

        patch: {
          ...state.patch,
          pending: action.pending,
        },
      };
    }
    case REQUEST__PATCH_PENDING_RESET: {
      return {
        ...state,

        patch: {
          ...state.patch,
          pending: {},
        },
      };
    }
    case REQUEST__PATCH_LOADING_ENABLE: {
      return {
        ...state,

        patch: {
          ...state.patch,
          isLoading: true,
        },
      };
    }
    case REQUEST__PATCH_LOADING_DISABLE: {
      return {
        ...state,

        patch: {
          ...state.patch,
          isLoading: false,
        },
      };
    }
    case REQUEST__PATCH_SUCCESS: {
      const fieldsFlat = flattenFields(action.fields);
      const values = fieldsFlat
        .filter((e) => e.value !== null)
        .reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
      const formIsDisabled = fieldsFlat.find((e) => e.name === STATUS_KEY).value === STATUS_READ_ONLY;

      return {
        ...state,
        fields: action.fields,
        fieldsFlat,
        values,
        popups: action.popups,
        statusIsLocked: !action.next,
        formIsDisabled,

        patch: {
          ...state.patch,
          isLoaded: true,
        },

        files: getFiles(fieldsFlat, state),
      };
    }
    case REQUEST__PATCH_FAILURE: {
      return {
        ...state,

        patch: {
          ...state.patch,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case REQUEST__SAVE_RESET: {
      return {
        ...state,

        save: {
          ...initState.save,
        },
      };
    }
    case REQUEST__SAVE: {
      return {
        ...state,

        save: {
          id: state.fetch.id,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REQUEST__SAVE_SUCCESS: {
      const fieldsFlat = flattenFields(action.fields);
      const values = fieldsFlat
        .filter((e) => e.value !== null)
        .reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
      const formIsDisabled = fieldsFlat.find((e) => e.name === STATUS_KEY).value === STATUS_READ_ONLY;

      return {
        ...state,
        fields: action.fields,
        fieldsFlat,
        values,
        popups: action.popups,
        statusIsLocked: !action.next,
        formIsDisabled,

        requestQueue: {},
        isQueueEmpty: true,

        save: {
          ...state.save,
          isLoading: false,
          isLoaded: true,
          error: null,
        },

        files: getFiles(fieldsFlat, state),
      };
    }
    case REQUEST__SAVE_FAILURE: {
      return {
        ...state,

        save: {
          ...state.save,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REQUEST__FETCH_FILE: {
      return {
        ...state,

        previews: {
          ...state.previews,

          [action.name]: {
            ...filePreview,
            isLoading: true,
          },
        },
      };
    }
    case REQUEST__FETCH_FILE_SUCCESS: {
      return {
        ...state,

        previews: {
          ...state.previews,

          [action.name]: {
            ...filePreview,
            file: URL.createObjectURL(b64toBlob(action.file.content, action.file.mime)),
            base64: action.file.content,
            mime: action.file.mime,
            isLoading: false,
            isLoaded: true,
          },
        },
      };
    }
    case REQUEST__FETCH_FILE_FAILURE: {
      return {
        ...state,

        previews: {
          ...state.previews,

          [action.name]: {
            ...filePreview,
            isLoading: false,
            error: action.error,
          },
        },
      };
    }
    case REQUEST__REMOVE_PREVIEW: {
      if (state.previews[action.name]) {
        // manually remove file from the browser memory
        URL.revokeObjectURL(state.previews[action.name].file);
      }

      return {
        ...state,

        previews: {
          ...state.previews,

          [action.name]: {
            ...state.previews[action.name],
            file: null,
            isLoading: false,
            isLoaded: false,
            error: null,
          },
        },
      };
    }

    case REQUEST__DELETE_FILE: {
      return {
        ...state,
        fileToProccess: null,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            isLoaded: false,
            isLoading: true,
            isDeleting: true,
            isUploaded: false,
            isRecognized: false,
            isConfirmed: false,
            status: 'Файл удаляется...',
          },
        },
      };
    }
    case REQUEST__DELETE_FILE_SUCCESS: {
      return {
        ...state,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            value: null,
            isLoaded: true,
            isLoading: false,
            isDeleting: true,
            isUploaded: false,
            isRecognized: false,
            isConfirmed: false,
            status: null,
          },
        },
      };
    }
    case REQUEST__DELETE_FILE_FAILURE: {
      return {
        ...state,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            isLoaded: false,
            isLoading: false,
            status: action.error,
          },
        },
      };
    }

    case REQUEST__UPLOAD_FILES: {
      const fieldNames = action.fileList.map(({ fieldName }) => fieldName);

      return {
        ...state,
        fileToProccess: null,

        files: fieldNames.reduce(
          (p, fieldName) => ({
            ...p,
            [fieldName]: {
              ...p[fieldName],
              isLoaded: false,
              isLoading: true,
              isDeleting: false,
              isUploaded: false,
              isRecognized: false,
              isConfirmed: false,
              status: 'Файл в очереди...',
            },
          }),
          state.files,
        ),
      };
    }

    case REQUEST__UPLOAD_FILES_SET_LAST_FILE: {
      return {
        ...state,
        fileToProccess: action.name,
      };
    }

    case REQUEST__UPLOAD_FILE: {
      return {
        ...state,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            isLoaded: false,
            isLoading: true,
            isDeleting: false,
            isUploaded: false,
            isRecognized: false,
            isConfirmed: false,
            value: action.fileName,
            label: action.fileName,
            status: 'Файл загружается...',
            error: '',
          },
        },
      };
    }
    case REQUEST__UPLOAD_FILE_UPLOADED: {
      return {
        ...state,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            isUploaded: true,
            status: 'Файл распознается...',
          },
        },
      };
    }
    case REQUEST__UPLOAD_FILE_RECOGNIZED: {
      return {
        ...state,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            isRecognized: true,
            status: 'Изменения применяются...',
          },
        },
      };
    }
    case REQUEST__UPLOAD_FILE_CONFIRMED: {
      const fieldsFlat = flattenFields(action.fields);
      const values = fieldsFlat
        .filter((e) => e.value !== null)
        .reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
      const formIsDisabled = fieldsFlat.find((e) => e.name === STATUS_KEY).value === STATUS_READ_ONLY;

      return {
        ...state,
        fields: action.fields,
        fieldsFlat,
        values,
        popups: action.popups,
        statusIsLocked: !action.next,
        formIsDisabled,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            value: values[action.fieldName],
            label:
              (fieldsFlat.find((e) => e.name === action.fieldName) || {}).label
              || values[action.fieldName],
            isConfirmed: true,
            isLoaded: true,
            isLoading: false,
            status: 'Изменения сохранены.',
          },
        },
      };
    }
    case REQUEST__UPLOAD_FILE_ERROR: {
      return {
        ...state,
        fileToProccess: null,

        files: {
          ...state.files,

          [action.fieldName]: {
            ...state.files[action.fieldName],
            isLoaded: false,
            isLoading: false,
            status: action.error,
            error: action.error,
          },
        },
      };
    }

    case REQUEST_GET_ANSWER_DETAILS: {
      return {
        ...state,

        details: {
          ...initState.details,
          isLoading: true,
        },
      };
    }
    case REQUEST_GET_ANSWER_DETAILS__SUCCESS: {
      return {
        ...state,

        details: {
          ...state.details,
          isLoading: false,
          data: action.data,
          isLoaded: true,
        },
      };
    }
    case REQUEST_GET_ANSWER_DETAILS__FAILURE: {
      return {
        ...state,

        details: {
          ...state.details,
          isLoading: false,
          error: action.error,
          isLoaded: false,
        },
      };
    }
    case REQUEST_GET_ANSWER_DETAILS__RESET: {
      return {
        ...state,

        details: {
          ...initState.details,
        },
      };
    }

    case REQUEST_REVISION__FETCH: {
      return {
        ...state,

        revision: {
          ...initState.revision,
          isLoading: true,
        },
      };
    }

    case REQUEST_REVISION__FETCH_SUCCESS: {
      return {
        ...state,

        revision: {
          ...initState.revision,
          isLoaded: true,
        },
      };
    }

    case REQUEST_REVISION__FETCH_FAILURE: {
      return {
        ...state,

        revision: {
          ...initState.revision,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case REQUEST_REVISION__FETCH_RESET: {
      return {
        ...state,

        revision: {
          ...initState.revision,
        },
      };
    }

    default: {
      return state;
    }
  }
}
