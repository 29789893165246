import styled from 'styled-components';
import { Checkbox } from 'antd';
import withTheme from 'components/tcm/withTheme';

const CheckboxGroup = styled(Checkbox.Group)`
  display: grid;
  row-gap: 30px;
  
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const Block = withTheme(styled.div`
  margin: 26px 0 31px;
  padding-bottom: 27px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray4};
`);

const BlockTitle = withTheme(styled.p`
  margin-top: 27px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

const SubRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 280px;
  column-gap: 20px;
  margin-top: 14px;
  margin-left: 28px;
`;

const SumField = styled.div`
  width: 160px;
`;

const CheckBoxText = styled.span`
  font-size: 16px;
`;

export {
  SubRow,
  Block,
  CheckboxGroup,
  BlockTitle,
  SumField,
  CheckBoxText,
};
