import React, {
  useEffect,
  useState,
  useReducer,
} from 'react';
import { connect } from 'react-redux';
import { Form, Radio } from 'antd';

import API from 'services';
import { useMount } from 'hooks';

import {
  exportFile as exportFileAction,
  reportsSync as reportsSyncAction,
  reportReset as reportResetAction,
  reportMonthlySummaryOperations as reportMonthlySummaryOperationsAction,
  reportPeriodOperations as reportPeriodOperationsAction,
  reportReconciliation as reportReconciliationAction,
} from 'redux/report/actions';

import SelectMultiple from 'components/SelectMultiple';
import Checkbox from 'components/Checkbox';
import {
  formatToApi,
  REPORT_TYPE_SELECT,
  REPORT_FIELD_KEY as K,
} from './data';

import {
  Main,
  ReportSelect,
  Footer,
  FormButton,
  DatePickerStyled,
  RangePickerStyled,
  CheckBoxContainer,
  FormContainer,
  RedRadio,
  ErrorMessage,
  SuccessMessage,
} from './style';

const checkDisableButton = (form) => {
  const reportType = form ? form.getFieldValue(K.REPORT_TYPE.key) : 'disable';
  switch (reportType) {
    case K.PROCESSING_REPORT.key:
      return !form.getFieldValue(K.PROCESSING_REPORT.key);
    default:
      return !reportType;
  }
};

const FormType = ({ type }) => {
  switch (type) {
    case (K.REGISTRY.key): {
      return (
        <>
          <CheckBoxContainer>
            <Form.Item
              name={K.SEND_REGISTRY_TO_CLIENT_CHECKBOX.key}
              rules={K.SEND_REGISTRY_TO_CLIENT_CHECKBOX.rules}
              valuePropName="checked"
            >
              <Checkbox
                name={K.SEND_REGISTRY_TO_CLIENT_CHECKBOX.key}
              >
                {K.SEND_REGISTRY_TO_CLIENT_CHECKBOX.title}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={K.REGISTRY_NULL_SECTOR_CHECKBOX.key}
              rules={K.REGISTRY_NULL_SECTOR_CHECKBOX.rules}
              valuePropName="checked"
            >
              <Checkbox
                name={K.REGISTRY_NULL_SECTOR_CHECKBOX.key}
              >
                {K.REGISTRY_NULL_SECTOR_CHECKBOX.title}
              </Checkbox>
            </Form.Item>
          </CheckBoxContainer>
          <Form.Item
            name={K.REGISTRY.key}
            label={K.REGISTRY.label}
            rules={K.REGISTRY.rules}
          >
            <RangePickerStyled
              name={K.REGISTRY.key}
            />
          </Form.Item>
          <Form.Item
            name={K.REGISTRY_DOCUMENT_TYPE.key}
            rules={K.REGISTRY_DOCUMENT_TYPE.rules}
            label={K.REGISTRY_DOCUMENT_TYPE.title}
          >
            <Radio.Group
              name={K.REGISTRY_DOCUMENT_TYPE.key}
            >
              <RedRadio
                value={K.REGISTRY_DOCUMENT_TYPE.options.XLSX.value}
              >
                {K.REGISTRY_DOCUMENT_TYPE.options.XLSX.label}
              </RedRadio>
              <RedRadio
                value={K.REGISTRY_DOCUMENT_TYPE.options.CSV.value}
              >
                {K.REGISTRY_DOCUMENT_TYPE.options.CSV.label}
              </RedRadio>
            </Radio.Group>
          </Form.Item>
        </>
      );
    }
    case (K.CONSOLIDATED_REPORT.key):
      return (
        <Form.Item
          name={K.CONSOLIDATED_REPORT.key}
          label={K.CONSOLIDATED_REPORT.label}
          rules={K.CONSOLIDATED_REPORT.rules}
        >
          <DatePickerStyled
            picker="month"
            name={K.CONSOLIDATED_REPORT.key}
          />
        </Form.Item>
      );
    case (K.PERIOD_REPORT.key):
      return (
        <Form.Item
          name={K.PERIOD_REPORT.key}
          label={K.PERIOD_REPORT.label}
          rules={K.PERIOD_REPORT.rules}
        >
          <RangePickerStyled
            name={K.PERIOD_REPORT.key}
          />
        </Form.Item>
      );
    case (K.PROCESSING_REPORT.key):
      return (
        <Form.Item
          name={K.PROCESSING_REPORT.key}
          label={K.PROCESSING_REPORT.label}
          rules={K.PROCESSING_REPORT.rules}
        >
          <DatePickerStyled
            name={K.PROCESSING_REPORT.key}
          />
        </Form.Item>
      );
    case (K.SYNCHRONIZATION_REPORT.key):
      return (
        <Form.Item
          name={K.SYNCHRONIZATION_REPORT.key}
          label={K.SYNCHRONIZATION_REPORT.label}
          rules={K.SYNCHRONIZATION_REPORT.rules}
        >
          <DatePickerStyled
            name={K.SYNCHRONIZATION_REPORT.key}
          />
        </Form.Item>
      );
    default:
      return (
        <></>
      );
  }
};

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

const ACTIONS = {
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function ReportForm({
  // passed
  visible,

  // store
  exportFileIsLoading,
  exportFileError,
  exportFileIsLoaded,

  syncReportsIsLoading,
  syncReportsIsLoaded,
  syncReportsError,

  monthlySummaryOperationsIsLoading,
  monthlySummaryOperationsIsLoaded,
  monthlySummaryOperationsError,

  periodOperationsIsLoading,
  periodOperationsIsLoaded,
  periodOperationsError,

  reportReconciliationIsLoading,
  reportReconciliationIsLoaded,
  reportReconciliationError,

  // actions
  exportReportFile,
  reportsSync,
  reportReset,
  reportMonthlySummaryOperations,
  reportPeriodOperations,
  reportReconciliation,
}) {
  const [form] = Form.useForm();
  const [reportType, setReportType] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [disableButton, setDisableButton] = useState(true);
  const isMounted = useMount();

  const handleReportTypeChange = (e) => {
    setReportType(e);
  };

  const handleExportReportFile = (values) => {
    const filter = formatToApi(values);

    switch (reportType) {
      case K.SYNCHRONIZATION_REPORT.key:
        reportsSync({ ...filter });
        return true;

      case K.REGISTRY.key:
        exportReportFile({
          filter,
        });
        return true;

      case K.CONSOLIDATED_REPORT.key:
        reportMonthlySummaryOperations({
          ...filter,
        });
        return true;

      case K.PERIOD_REPORT.key:
        reportPeriodOperations({
          ...filter,
        });
        return true;

      case K.PROCESSING_REPORT.key:
        reportReconciliation({
          ...filter,
        });
        return true;

      default:
        return true;
    }
  };

  const handleChangeValue = (value) => {
    const { REPORT_TYPE } = value;
    if (REPORT_TYPE) {
      reportReset();
    }

    setDisableButton(checkDisableButton(form));
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH });
    API.report
      .getReportSectors()
      .then((results) => {
        const { data: { sectorList } } = results;

        if (Array.isArray(sectorList) && (sectorList.length > 0)) {
          const formatSectorList = sectorList.map((item) => (
            { value: String(item.sector), label: item.name }));
          dispatch({ type: ACTIONS.FETCH_SUCCESS, list: formatSectorList });
        } else {
          throw new Error('Возникла ошибка при получении списка секторов');
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
      });
  }, []);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      reportReset();
      setReportType(null);
    }
  }, [form, visible, reportReset]);

  useEffect(() => {
    if (isMounted) {
      form.setFieldsValue({
        [K.REGISTRY_DOCUMENT_TYPE.key]: K.REGISTRY_DOCUMENT_TYPE.options.XLSX.value,
      });
    }
  }, [isMounted, form]);

  const isLoading = syncReportsIsLoading
    || exportFileIsLoading
    || monthlySummaryOperationsIsLoading
    || periodOperationsIsLoading
    || reportReconciliationIsLoading;
  const error = syncReportsError
    || exportFileError
    || monthlySummaryOperationsError
    || periodOperationsError
    || reportReconciliationError;

  return (
    <Main
      name="report"
      layout="vertical"
      form={form}
      size="large"
      onFinish={handleExportReportFile}
      hideRequiredMark
      onValuesChange={handleChangeValue}
    >
      <FormContainer>
        <Form.Item
          name={K.REPORT_TYPE.key}
          label={K.REPORT_TYPE.title}
          rules={K.REPORT_TYPE.rules}
        >
          <ReportSelect
            hasDefault={false}
            name={K.REPORT_TYPE.key}
            options={REPORT_TYPE_SELECT}
            placeholder="Выберите отчет"
            onChange={handleReportTypeChange}
          />
        </Form.Item>
        <Form.Item
          name={K.SECTOR_TYPE.key}
          label={K.SECTOR_TYPE.title}
          rules={K.SECTOR_TYPE.rules}
        >
          <SelectMultiple
            name={K.SECTOR_TYPE.key}
            hasDefault={false}
            withTooltip
            isLoading={state.isLoading}
            disabled={state.isLoading}
            options={state.list}
            placeholder="Выберите сектор"
            optionFilterProp="label"
          />
        </Form.Item>
        <ErrorMessage>{state.error}</ErrorMessage>
        <FormType type={reportType} form={form} />
      </FormContainer>
      <Footer>
        <FormButton
          size="mini"
          shape="square"
          type="primary"
          htmlType="link"
          disabled={disableButton}
          loading={isLoading}
        >
          {reportType === K.SYNCHRONIZATION_REPORT.key ? 'Синхронизировать' : 'Сформировать'}
        </FormButton>
        <ErrorMessage>{error}</ErrorMessage>
        {syncReportsIsLoaded && <SuccessMessage>Синхронизация запущена </SuccessMessage>}

        {exportFileIsLoaded && <SuccessMessage>Формирование отчета запущено</SuccessMessage>}
        {monthlySummaryOperationsIsLoaded && (
          <SuccessMessage>Формирование отчета запущено</SuccessMessage>
        )}
        {periodOperationsIsLoaded && (
          <SuccessMessage>Формирование отчета запущено</SuccessMessage>
        )}
        {reportReconciliationIsLoaded && (
          <SuccessMessage>Формирование отчета запущено</SuccessMessage>
        )}
      </Footer>
    </Main>
  );
}

export default connect(
  (state) => ({
    exportFileData: state.report.exportFile.data,
    exportFileIsLoading: state.report.exportFile.isLoading,
    exportFileIsLoaded: state.report.exportFile.isLoaded,
    exportFileError: state.report.exportFile.error,

    syncReportsData: state.report.syncReports.data,
    syncReportsIsLoading: state.report.syncReports.isLoading,
    syncReportsIsLoaded: state.report.syncReports.isLoaded,
    syncReportsError: state.report.syncReports.error,

    monthlySummaryOperationsData: state.report.monthlySummaryOperations.data,
    monthlySummaryOperationsIsLoading: state.report.monthlySummaryOperations.isLoading,
    monthlySummaryOperationsIsLoaded: state.report.monthlySummaryOperations.isLoaded,
    monthlySummaryOperationsError: state.report.monthlySummaryOperations.error,

    periodOperationsData: state.report.periodOperations.data,
    periodOperationsIsLoading: state.report.periodOperations.isLoading,
    periodOperationsIsLoaded: state.report.periodOperations.isLoaded,
    periodOperationsError: state.report.periodOperations.error,

    reportReconciliationData: state.report.reportReconciliation.data,
    reportReconciliationIsLoading: state.report.reportReconciliation.isLoading,
    reportReconciliationIsLoaded: state.report.reportReconciliation.isLoaded,
    reportReconciliationError: state.report.reportReconciliation.error,
  }),
  {
    exportReportFile: exportFileAction,
    reportsSync: reportsSyncAction,
    reportReset: reportResetAction,
    reportMonthlySummaryOperations: reportMonthlySummaryOperationsAction,
    reportPeriodOperations: reportPeriodOperationsAction,
    reportReconciliation: reportReconciliationAction,
  },
)(ReportForm);
