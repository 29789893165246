import { flattenFields, isObjectEmpty } from 'utils';

import {
  CREDIT__FETCH,
  CREDIT__FETCH_SUCCESS,
  CREDIT__FETCH_FAILURE,
  CREDIT__PATCH,
  CREDIT__PATCH_SUCCESS,
  CREDIT__PATCH_FAILURE,
} from './actions';

const initState = {
  fields: [],
  fieldsFlat: [],
  values: {},

  requestQueue: {},
  isQueueEmpty: true,

  fetch: {
    id: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  patch: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

const mutateFields = (fields) => {
  fields.forEach((e, i, a) => {
    const { dependencies: d, name } = e;

    if (d) {
      Object.keys(d).forEach((k) => {
        const field = a.find((f) => f.name === d[k]);

        if (field) {
          if (Array.isArray(field.dependents)) {
            if (!field.dependents.includes(name)) {
              field.dependents.push(name);
            }
          } else {
            field.dependents = [name];
          }
        }
      });
    }
  });
};

export default function creditReducer(state = initState, action) {
  switch (action.type) {
    case CREDIT__FETCH: {
      return {
        ...state,
        fields: [],
        fieldsFlat: [],
        values: {},

        requestQueue: {},
        isQueueEmpty: true,

        fetch: {
          id: action.accessToken,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CREDIT__FETCH_SUCCESS: {
      const fieldsFlat = flattenFields(action.fields);
      mutateFields(fieldsFlat);
      const values = fieldsFlat
        .filter((e) => e.value !== null)
        .reduce((p, c) => ({ ...p, [c.name]: c.value }), {});

      return {
        ...state,
        fields: action.fields,
        fieldsFlat,
        values,

        fetch: {
          id: state.fetch.id,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CREDIT__FETCH_FAILURE: {
      return {
        ...state,

        fetch: {
          id: state.fetch.id,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CREDIT__PATCH: {
      return {
        ...state,

        requestQueue: { ...state.requestQueue, ...action.userInput },
        isQueueEmpty: false,

        patch: {
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CREDIT__PATCH_SUCCESS: {
      const fieldsFlat = flattenFields(action.fields);
      mutateFields(fieldsFlat);
      const values = fieldsFlat
        .filter((e) => e.value !== null)
        .reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
      const requestQueue = Object.keys(state.requestQueue).reduce(
        (p, c) => ({ ...p, ...(action.userInput[c] ? {} : { [c]: state.requestQueue[c] }) }),
        {},
      );

      return {
        ...state,
        fields: action.fields,
        fieldsFlat,
        values,
        requestQueue,
        isQueueEmpty: isObjectEmpty(requestQueue),

        patch: {
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CREDIT__PATCH_FAILURE: {
      const requestQueue = Object.keys(state.requestQueue).reduce(
        (p, c) => ({ ...p, ...(action.userInput[c] ? {} : { [c]: state.requestQueue[c] }) }),
        {},
      );
      return {
        ...state,
        requestQueue,
        isQueueEmpty: isObjectEmpty(requestQueue),

        patch: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
