/* List */
export const PAYMENT_FETCH_LIST = 'PAYMENT_FETCH_LIST';
export const PAYMENT_FETCH_LIST__SUCCESS = 'PAYMENT_FETCH_LIST__SUCCESS';
export const PAYMENT_FETCH_LIST__FAILURE = 'PAYMENT_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting,
} = {}) => ({
  type: PAYMENT_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
});

export const PAYMENT_FETCH_LIST__CANCEL_REQUEST = 'PAYMENT_FETCH_LIST__CANCEL_REQUEST';
export const PAYMENT_FETCH_LIST__SET_LOADING = 'PAYMENT_FETCH_LIST__SET_LOADING';
export const PAYMENT_FETCH_LIST__SET_PAGINATION = 'PAYMENT_FETCH_LIST__SET_PAGINATION';

export const PAYMENT_FETCH_LIST__SET_SEARCH = 'PAYMENT_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({
  type: PAYMENT_FETCH_LIST__SET_SEARCH,
  search,
});

export const PAYMENT_FETCH_LIST__SET_PAGE = 'PAYMENT_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: PAYMENT_FETCH_LIST__SET_PAGE, page });

export const PAYMENT_FETCH_LIST__SET_SORTING = 'PAYMENT_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: PAYMENT_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const PAYMENT_FETCH_LIST__SET_FILTER = 'PAYMENT_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: PAYMENT_FETCH_LIST__SET_FILTER,
  filters,
});

export const PAYMENT_FETCH_LIST__RESET = 'PAYMENT_FETCH_LIST__RESET';
export const reset = () => ({ type: PAYMENT_FETCH_LIST__RESET });

/* Item */
export const PAYMENT_FETCH_ITEM = 'PAYMENT_FETCH_ITEM';
export const PAYMENT_FETCH_ITEM__SUCCESS = 'PAYMENT_FETCH_ITEM__SUCCESS';
export const PAYMENT_FETCH_ITEM__FAILIRE = 'PAYMENT_FETCH_ITEM__FAILIRE';
export const fetchItem = ({ id, isRepeat = false } = {}) => ({ type: PAYMENT_FETCH_ITEM, id, isRepeat });

export const PAYMENT_CREATE = 'PAYMENT_CREATE';
export const PAYMENT_CREATE__SUCCESS = 'PAYMENT_CREATE__SUCCESS';
export const PAYMENT_CREATE__FAILIRE = 'PAYMENT_CREATE__FAILIRE';
export const create = ({
  additionalInformation,
  amount,
  number,
  channel,
  dealerId,
  description,
  email,
  expiredAt,
  fullName,
  phone,
  legalEntityId,
  sector,
} = {}) => ({
  type: PAYMENT_CREATE,
  additionalInformation,
  amount,
  number,
  channel,
  dealerId,
  description,
  email,
  expiredAt,
  fullName,
  phone,
  legalEntityId,
  sector,
});
export const PAYMENT_CREATE__RESET = 'PAYMENT_CREATE__RESET';
export const resetCreateForm = () => ({ type: PAYMENT_CREATE__RESET });

export const PAYMENT_ANNUL = 'PAYMENT_ANNUL';
export const PAYMENT_ANNUL__SUCCESS = 'PAYMENT_ANNUL__SUCCESS';
export const PAYMENT_ANNUL__FAILIRE = 'PAYMENT_ANNUL__FAILIRE';
export const annul = ({ id } = {}) => ({ type: PAYMENT_ANNUL, id });

/* List */
export const PAYMENT_SECTOR_FETCH_LIST = 'PAYMENT_SECTOR_FETCH_LIST';
export const PAYMENT_SECTOR_FETCH_LIST__SUCCESS = 'PAYMENT_SECTOR_FETCH_LIST__SUCCESS';
export const PAYMENT_SECTOR_FETCH_LIST__FAILURE = 'PAYMENT_SECTOR_FETCH_LIST__FAILURE';
export const fetchSectors = () => ({ type: PAYMENT_SECTOR_FETCH_LIST });
