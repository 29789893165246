import actions from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {
  createdAtFrom: '',
  createdAtTo: '',
  updatedAtFrom: '',
  updatedAtTo: '',
  dealerCenter: '',
  name: '',
  number: '',
  phone: '',
  status: '',
  nameCS: '',
  personalOfferStatus: '',
  personalOfferdDealerCenter: '',
  personalOfferNumber: '',
  personalOfferCreatedAtFrom: '',
  personalOfferCreatedAtTo: '',
  personalOfferSendAt: '',
  personalOfferROP: '',
  personalOfferFAndI: '',
};

const initState = {
  data: {},
  list: [],
  sorting: defaultSorting,
  search: '',
  filters: initialFilters,
  page: 1,
  isLoading: false,
  isLoaded: false,
  error: null,
};

export default function tableReducer(state = initState, action) {
  switch (action.type) {
    case actions.TABLE_RESET: {
      return {
        ...initState,
      };
    }

    case actions.TABLE_SET_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.TABLE_SET_SEARCH: {
      return {
        ...state,
        search: action.search,
      };
    }

    case actions.TABLE_SET_PAGINATION: {
      return {
        ...state,
        page: action.page,
      };
    }

    case actions.TABLE_SET_SORTING: {
      return {
        ...state,
        sorting: {
          sortBy: action.sortBy,
          order:
            state.sorting.sortBy === action.sortBy
              ? state.sorting.order === ORDER.ASC
                ? ORDER.DESC
                : ORDER.ASC
              : ORDER.ASC,
        },
      };
    }

    case actions.TABLE_SET_FILTER: {
      return {
        ...state,
        filters: action.filters,
      };
    }

    case actions.TABLE_FETCH_DATA: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null,
      };
    }
    case actions.TABLE_FETCH_DATA__SUCCESS: {
      return {
        ...state,
        data: action.data,
        list: action.list,
        isLoading: false,
        isLoaded: true,
        error: null,
      };
    }
    case actions.TABLE_FETCH_DATA__FAILURE: {
      return {
        ...state,
        data: {},
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
}
