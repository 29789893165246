import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/Loader';

import { useLocation, useParams } from 'react-router-dom';

import { fetchItem as fetchItemAction } from 'redux/user/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import Form from './Form';
import Roles from './Roles';

import { Root, ErrorMessage } from './style';

function User({
  // connect
  userIsLoading,
  userIsLoaded,
  userError,

  createData,
  createIsLoaded,

  // actions
  fetchItem,
}) {
  const { userId } = useParams();
  const location = useLocation();
  const isNew = location.pathname.endsWith('/new');

  // проверка на id или в url параметрах или при возвращении данных от сервера о новом пользователе.
  const rolesUserId = userId || createData.id;

  useEffect(() => {
    if (userId) {
      fetchItem({ userId });
    }
  }, [fetchItem, userId]);

  return (
    <Root>
      {userId && userError && <ErrorMessage>{userError}</ErrorMessage>}

      {userId && userIsLoading && (
        <Loader text="Загружаются данные пользователя..." height={400} />
      )}

      {(isNew || (userId && userIsLoaded)) && <Form />}

      {((userId && userIsLoaded) || (createIsLoaded && createData.id)) && (
        <Roles userId={rolesUserId} />
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    userData: state.user.item.data,
    userIsLoading: state.user.item.isLoading,
    userIsLoaded: state.user.item.isLoaded,
    userError: state.user.item.error,
    createData: state.user.create.data,
    createIsLoaded: state.user.create.isLoaded,
  }),
  {
    fetchItem: fetchItemAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(User);
