import API from 'services';

import { extractErrorAndCode } from 'hacks';

import {
  all, fork, put, takeLeading, call,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  ROLE_FETCH_LIST,
  ROLE_FETCH_LIST__SUCCESS,
  ROLE_FETCH_LIST__FAILURE,
} from './actions';

export function* fetchList() {
  yield takeLeading(ROLE_FETCH_LIST, function* () {
    try {
      const response = yield call(API.role.fetchList);

      if (
        response
        && response.data
        && response.data.roleList
        && Array.isArray(response.data.roleList)
      ) {
        yield put({ type: ROLE_FETCH_LIST__SUCCESS, list: response.data.roleList });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: ROLE_FETCH_LIST__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchList)]);
}
