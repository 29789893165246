export const mergeArrays = (...args) =>
  args.reduce((p, c) => {
    if (Array.isArray(c)) {
      return [...p, ...c];
    }
    if (typeof c === 'string') {
      const subArray = c.split(',');
      return [...p, ...subArray];
    }
    if (typeof c === 'number') {
      return [...p, c];
    }
    return p;
  }, []);
