import API from 'services';

import {
  extractErrorAndCode,
} from 'hacks';

import {
  all, takeLatest, put, fork, call, select, take, cancel,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';
import { defaultPageSize } from 'redux/contracts/reducer';

import {
  // list
  CONTRACT_FETCH_LIST,
  CONTRACT_FETCH_LIST__SUCCESS,
  CONTRACT_FETCH_LIST__FAILURE,
  CONTRACT_FETCH_LIST__CANCEL_REQUEST,
  CONTRACT_FETCH_LIST__SET_LOADING,
  CONTRACT_FETCH_LIST__SET_PAGINATION,
  CONTRACT_FETCH_LIST__SET_SEARCH,
  CONTRACT_FETCH_LIST__SET_PAGE,
  CONTRACT_FETCH_LIST__SET_SORTING,
  CONTRACT_FETCH_LIST__SET_FILTER,

  CONTRACT_FETCH,
  CONTRACT_FETCH__SUCCESS,
  CONTRACT_FETCH__FAILURE,

  CONTRACT_UPDATE,
  CONTRACT_UPDATE__FAILURE,
  CONTRACT_UPDATE__SUCCESS,

  CONTRACT_CREATE,
  CONTRACT_CREATE__SUCCESS,
  CONTRACT_CREATE__FAILURE,

  CONTRACT_DELETE,
  CONTRACT_DELETE__SUCCESS,
  CONTRACT_DELETE__FAILURE,

  CONTRACT_REDIRECT,

  CONTRACT_REDIRECT_TO_NEW_COMPANY,

  CONTRACT_FETCH_POINT_OF_SALES,
  CONTRACT_FETCH_POINT_OF_SALES__SUCCESS,
  CONTRACT_FETCH_POINT_OF_SALES__FAILURE,

  CONTRACT_SAVE,
  CONTRACT_SAVE__SUCCESS,
  CONTRACT_SAVE__FAILURE,

  CONTRACT_UNBIND_POINTS_OF_SALE,
  CONTRACT_UNBIND_POINTS_OF_SALE__SUCCESS,
  CONTRACT_UNBIND_POINTS_OF_SALE__FAILURE,

  CONTRACT_BIND_POINTS_OF_SALE,
  CONTRACT_BIND_POINTS_OF_SALE__SUCCESS,
  CONTRACT_BIND_POINTS_OF_SALE__FAILURE,

} from './actions';

/* List */
const getState = (state) => state.contracts;

const ERROR_CODE_EXIST_CONTRACT = 16003;
const ERROR_CODE_COMPANY_NOT_EXIST = 7000;

const formatContractPointOfSales = (array = []) => array.map((item) => (
  { ...item, name: item.salePoint || item.name }
));

function* _fetchList() {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);

  try {
    yield put({ type: CONTRACT_FETCH_LIST__SET_LOADING });
    const response = yield call(API.contracts.fetchList, {
      search,
      page,
      filters: {
        ...filters,
        ...(filters.sectors ? { sectors: [Number(filters.sectors)] } : {}),
      },
      sorting,
    });

    if (
      Array.isArray(response.data)
        || (response.data && Array.isArray(response.data.acquiringAgreementResponseItemList))
    ) {
      yield put({
        type: CONTRACT_FETCH_LIST__SUCCESS,
        list: response.data.acquiringAgreementResponseItemList || [],
        pagination: response.pagination || {
          page: 1,
          pageSize: defaultPageSize,
          rowsCount: 0,
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: CONTRACT_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(CONTRACT_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(CONTRACT_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(CONTRACT_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: CONTRACT_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: CONTRACT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CONTRACT_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(CONTRACT_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: CONTRACT_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: CONTRACT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CONTRACT_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(CONTRACT_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: CONTRACT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CONTRACT_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(CONTRACT_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: CONTRACT_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: CONTRACT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CONTRACT_FETCH_LIST });
  });
}

export function* updateContract() {
  yield takeLatest(CONTRACT_UPDATE, function* ({
    file,
    sector,
  }) {
    try {
      const response = yield call(API.contracts.updateContract, {
        file,
        sector,
      });
      if (
        response
          && Array.isArray(response.data.acquiringAgreementResponseItemList)
          && response.data.acquiringAgreementResponseItemList[0]
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_UPDATE__SUCCESS,
          data: response.data.acquiringAgreementResponseItemList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_UPDATE__FAILURE, error: error.message });
    }
  });
}

export function* createContract() {
  yield takeLatest(CONTRACT_CREATE, function* ({
    file,
  }) {
    try {
      const response = yield call(API.contracts.createContract, {
        file,
      });
      if (
        response
          && response.data
          && response.data.acquiringAgreementResponseItemList
          && Array.isArray(response.data.acquiringAgreementResponseItemList)
          && response.data.acquiringAgreementResponseItemList[0]
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_CREATE__SUCCESS,
          data: response.data.acquiringAgreementResponseItemList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);

        if (response.errorCode === ERROR_CODE_EXIST_CONTRACT) {
          if (response.data.exceptionList && response.data.exceptionList[0]) {
            const { context } = response.data.exceptionList[0];
            const id = Array.isArray(context) && context[0] ? context[0] : null;
            const sector = Array.isArray(context) && context[1] ? context[1] : null;
            const inn = Array.isArray(context) && context[2] ? context[2] : null;

            yield put({
              type: CONTRACT_REDIRECT,
              data: {
                id, error, sector, inn,
              },
            });
          }
        }

        if (response.errorCode === ERROR_CODE_COMPANY_NOT_EXIST) {
          if (response.data.exceptionList && response.data.exceptionList[0]) {
            const { context } = response.data.exceptionList[0];
            const inn = Array.isArray(context) && context[0] ? context[0] : null;
            yield put({
              type: CONTRACT_REDIRECT_TO_NEW_COMPANY,
              data: {
                inn, error,
              },
            });
          }
        }

        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_CREATE__FAILURE, error: error.message });
    }
  });
}

export function* fetchItem() {
  yield takeLatest(CONTRACT_FETCH, function* ({
    id,
    sector,
  }) {
    try {
      const response = yield call(API.contracts.fetchItem, {
        id,
        sector,
      });
      if (
        response
          && response.data
          && Array.isArray(response.data.acquiringAgreementResponseItemList)
          && response.data.acquiringAgreementResponseItemList[0]
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_FETCH__SUCCESS,
          data: response.data.acquiringAgreementResponseItemList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_FETCH__FAILURE, error: error.message });
    }
  });
}

export function* fetchPointSales() {
  yield takeLatest(CONTRACT_FETCH_POINT_OF_SALES, function* ({
    inn,
    sector,
  }) {
    try {
      const bindResponse = yield call(API.contracts.fetchPointOfSalesList, {
        sector,
      });

      if (
        bindResponse && (
          Array.isArray(bindResponse.data)
          || (bindResponse.data && Array.isArray(bindResponse.data.subsidiariesResponseList))
        )
      ) {
        const bindArray = new Map();
        if (bindResponse.data.subsidiariesResponseList) {
          bindResponse.data.subsidiariesResponseList.forEach((item) => {
            if (!bindArray.has(item.id)) {
              bindArray.set(item.id, item.salePoint || item.name);
            }
          });
        }

        const unbindResponse = yield call(API.contracts.fetchPointOfSalesList, {
          inn,
        });

        if (unbindResponse && (
          Array.isArray(unbindResponse.data)
            || (unbindResponse.data && Array.isArray(unbindResponse.data.subsidiariesResponseList)))
        ) {
          const unbind = !Array.isArray(unbindResponse.data)
            ? unbindResponse.data.subsidiariesResponseList.filter((item) => !bindArray.has(item.id))
            : [];

          yield put({
            type: CONTRACT_FETCH_POINT_OF_SALES__SUCCESS,
            data: {
              unbindList: formatContractPointOfSales(unbind),
              bindList: formatContractPointOfSales(bindResponse.data.subsidiariesResponseList),
            },
          });
        } else {
          const { error, code } = extractErrorAndCode(unbindResponse);
          yield put({ type: ON_ERROR, errorCode: code });
          throw new Error(error);
        }
      } else {
        const { error, code } = extractErrorAndCode(bindResponse);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_FETCH_POINT_OF_SALES__FAILURE, error: error.message });
    }
  });
}

export function* deleteContract() {
  yield takeLatest(CONTRACT_DELETE, function* ({ id }) {
    try {
      const response = yield call(API.contracts.deleteContract, { id });
      if (
        response
          && response.data
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_DELETE__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_DELETE__FAILURE, error: error.message });
    }
  });
}

export function* unbindPointOfSales() {
  yield takeLatest(CONTRACT_UNBIND_POINTS_OF_SALE, function* ({ sector, subsidiariesIds }) {
    try {
      const response = yield call(API.contracts.unbindPointsOfSale, { sector, subsidiariesIds });
      if (
        response
          && response.data
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_UNBIND_POINTS_OF_SALE__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_UNBIND_POINTS_OF_SALE__FAILURE, error: error.message });
    }
  });
}

export function* bindPointOfSales() {
  yield takeLatest(CONTRACT_BIND_POINTS_OF_SALE, function* ({ sector, subsidiariesIds }) {
    try {
      const response = yield call(API.contracts.bindPointsOfSale, { sector, subsidiariesIds });
      if (
        response
          && response.data
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_BIND_POINTS_OF_SALE__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_BIND_POINTS_OF_SALE__FAILURE, error: error.message });
    }
  });
}

export function* saveContract() {
  yield takeLatest(CONTRACT_SAVE, function* ({ data }) {
    try {
      const response = yield call(API.contracts.saveContract, data);
      if (
        response
          && response.data
          && response.data.acquiringAgreementResponseItemList
          && Array.isArray(response.data.acquiringAgreementResponseItemList)
          && response.data.acquiringAgreementResponseItemList[0]
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: CONTRACT_SAVE__SUCCESS,
          data: response.data.acquiringAgreementResponseItemList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CONTRACT_SAVE__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(updateContract),
    fork(createContract),
    fork(deleteContract),
    fork(fetchPointSales),
    fork(saveContract),
    fork(fetchItem),
    fork(bindPointOfSales),
    fork(unbindPointOfSales),
  ]);
}
