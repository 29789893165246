import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Input, { Password, Search } from 'components/Input';
import Button from 'components/Button';
import { Rifm } from 'rifm';
import { parseDigits } from 'utils';
import { useInterval } from 'hooks';

import { useLocation, useParams } from 'react-router-dom';

import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { baseURL } from 'settings/api';

import {
  confirmEmail as confirmEmailAction,
  sendOtp as sendOtpAction,
  checkOtp as checkOtpAction,
  reset as resetInviteAction,
  finishRegister as finishRegisterAction,
} from 'redux/invite/actions';

import {
  Root,
  Title,
  ErrorMessage,
  PasswordRulesContainer,
  PasswordRuleslTitle,
  PasswordRule,
} from './style';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const numberFormat = (string) => {
  const digits = parseDigits(string);

  return Array.from(digits)
    .reduce((p, c) => `${p}${c}`, '')
    .substring(0, 4);
};

const addMask = (string) => {
  const digits = parseDigits(string);
  return digits.slice(0, 4).padEnd(4, '_');
};

const TIMEOUT = 60;

const rulesEmail = [
  {
    required: true,
    message: 'Email не может быть пустым',
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)
        ? Promise.resolve()
        : Promise.reject('Невалидный емайл');
    },
  },
];

const rulesCode = [
  {
    required: true,
    message: 'Смс код не может быть пустым',
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && parseDigits(value).length === 4
        ? Promise.resolve()
        : Promise.reject('Смс код должен содержать 4 цифры');
    },
  },
];

const rulesPassword = [
  {
    required: true,
    message: 'Пароль не может быть пустым',
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && value.length >= 8
        ? Promise.resolve()
        : Promise.reject('Минимальная длина пароля - 8 символов');
    },
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && /[0-9]{1,}/g.test(value)
        ? Promise.resolve()
        : Promise.reject('Пароль должен содержать хотя бы одну цифру');
    },
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && /[a-zа-яё]{1,}/g.test(value)
        ? Promise.resolve()
        : Promise.reject('Пароль должен содержать хотя бы одну букву в нижнем регистре');
    },
  },
  {
    validator(rule, value) {
      return typeof value === 'string' && /[A-ZА-ЯЁ]{1,}/g.test(value)
        ? Promise.resolve()
        : Promise.reject('Пароль должен содержать хотя бы одну букву в верхнем регистре');
    },
  },
  {
    validator(rule, value) {
      return typeof value === 'string'
        // eslint-disable-next-line no-useless-escape
        && (/[!"№;:?*()+=<>,.\[\]{}\/]{1,}/g.test(value) || value.match(/\//))
        ? Promise.resolve()
        : Promise.reject(
          String.raw`Пароль должен содержать хотя бы один спецсимвол (!"№;:?*()+=<>,./\[]{})`,
        );
    },
  },
];

const renderPasswordRules = () => (
  <>
    <PasswordRuleslTitle>Пароль должен содержать:</PasswordRuleslTitle>
    <PasswordRulesContainer>
      <PasswordRule>Минимум 8 символов</PasswordRule>
      <PasswordRule>Прописные и строчные буквы</PasswordRule>
      <PasswordRule>Цифры</PasswordRule>
      <PasswordRule>{'Спецсимвол (!"№;:?*()+=<>,./[]{})'}</PasswordRule>
    </PasswordRulesContainer>
  </>
);

function AcceptInvite({
  // connect
  emailIsLoading,
  emailIsLoaded,
  emailError,

  otpRecipient,
  otpLogin,
  otpIsLoading,
  otpIsLoaded,
  otpError,

  confirmationIsLoading,
  confirmationIsLoaded,
  confirmationError,
  confirmationErrors,

  registerIsLoaded,

  // actions
  confirmEmail,
  sendOtp,
  checkOtp,
  resetInvite,
  finishRegister,
}) {
  const query = useQuery();
  const { token } = useParams();
  const [form] = Form.useForm();
  const [error, setError] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [code, setCode] = useState('');
  const [count, setCount] = useState(0);
  const [isValidated, setIsValidated] = useState(true);
  const email = query.get('email');

  useInterval(
    () => {
      if (count === 0) {
        setIsRunning(false);
      } else {
        setCount(count - 1);
      }
    },
    isRunning ? 1000 : null,
  );

  useEffect(() => {
    if (email) {
      form.setFields([{ name: 'email', value: email }]);
    }

    return () => {
      resetInvite();
    };
  }, [resetInvite, email, form]);

  useEffect(() => {
    if (otpIsLoaded) {
      setCount(TIMEOUT);
      setIsRunning(true);
    }
  }, [otpIsLoaded]);

  useEffect(() => {
    setError(emailError || confirmationError);
  }, [emailError, confirmationError, setError]);

  useEffect(() => {
    if (
      !isValidated
      && Array.isArray(confirmationErrors)
      && confirmationErrors.length > 0
    ) {
      const errorsFlat = confirmationErrors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));
      const { code, password } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(code ? [{ name: 'code', errors: [code] }] : []),
        ...(password ? [{ name: 'password', errors: [password] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [confirmationErrors, form, isValidated, setIsValidated]);

  function handleFinish(values) {
    if (confirmationIsLoaded) {
      const { password } = values;
      finishRegister({ password });
      setIsValidated(false);
    } else {
      const { email } = values;
      confirmEmail({ token, email });
      setIsValidated(false);
    }
  }

  function handleSearch() {
    if (count === 0) {
      form.setFields([{ name: 'code', value: '', errors: [] }]);

      sendOtp({ login: form.getFieldValue('email') });
    }
  }

  function handleChange({ name, value }) {
    form.setFields([{ name, value }]);
    setError('');

    if (otpIsLoaded
        && (name === 'code')
        && (/[\d]{4}/gm.test(value))
    ) {
      checkOtp({ code: value });
      setCode(value);
    }
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  return (
    <Root>
      {registerIsLoaded ? (
        <>
          <Title>Регистрация прошла успешно</Title>
          <a href={`${baseURL}/app`}>Перейти на главную</a>
        </>
      ) : (
        <>
          <Title>Добро пожаловать в личный кабинет</Title>
          <Form
            form={form}
            layout="vertical"
            name="AcceptInvite"
            size="large"
            hideRequiredMark
            onFinish={handleFinish}
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={rulesEmail}
              validateFirst
              validateTrigger="onBlur"
              {...(emailIsLoaded ? { hasFeedback: true, validateStatus: 'success' } : {})}
            >
              <Input
                name="email"
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Введите емайл"
                disabled={emailIsLoaded}
                onChange={
                  ({ target: { value: v } }) => handleChange({
                    name: 'email',
                    value: v,
                  })
                }
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </Form.Item>

            {error && (
              <ErrorMessage>
                Ошибка:
                {'\u0020'}
                {error}
              </ErrorMessage>
            )}

            {!emailIsLoaded && (
              <Button
                type="primary"
                block
                htmlType="submit"
                loading={emailIsLoading}
              >
                Продолжить
              </Button>
            )}

            {emailIsLoaded && !confirmationIsLoaded && (
              <>
                <Form.Item
                  name="code"
                  label={(
                    <span>
                      SMS код
                      {otpRecipient && (
                        <sup>
                          [сообщение было выслано на
                          <strong>{otpRecipient}</strong>
                          ]
                        </sup>
                      )}
                    </span>
                  )}
                  rules={rulesCode}
                  validateFirst
                  validateTrigger="onBlur"
                  {...(confirmationIsLoaded
                    ? { hasFeedback: true, validateStatus: 'success' }
                    : {})}
                >
                  <Rifm
                    onChange={(v) => handleChange({ name: 'code', value: v })}
                    accept={/[\d]/g}
                    mask
                    replace={addMask}
                    format={numberFormat}
                  >
                    {({ value, onChange }) => (
                      <Search
                        name="code"
                        value={value}
                        loading={otpIsLoading}
                        onSearch={handleSearch}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        buttonRedColor={!((count > 0) || otpIsLoading)}
                        enterButton={
                          confirmationIsLoaded ? (
                            <div />
                          ) : (
                            <div>
                              {count > 0 ? (
                                <span>
                                  {count}
                                  с
                                </span>
                              ) : (
                                <span>
                                  {otpLogin ? 'Выслать код повторно' : 'Выслать код'}
                                </span>
                              )}
                            </div>
                          )
                        }
                        onChange={onChange}
                        disabled={confirmationIsLoaded}
                      />
                    )}
                  </Rifm>
                </Form.Item>

                {otpError && (
                  <ErrorMessage>
                    Ошибка подтверждения:
                    {'\u0020'}
                    {otpError}
                  </ErrorMessage>
                )}
              </>
            )}

            {emailIsLoaded && code && confirmationIsLoaded && (
              <>
                <Form.Item
                  name="password"
                  label="Установите пароль"
                  rules={rulesPassword}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Password
                    name="password"
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Минимальная длина пароля - 8 символов"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </Form.Item>

                {renderPasswordRules()}

                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={confirmationIsLoading}
                >
                  Завершить регистрацию
                </Button>
              </>
            )}
          </Form>
        </>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    emailValue: state.invite.email.value,
    emailIsLoading: state.invite.email.isLoading,
    emailIsLoaded: state.invite.email.isLoaded,
    emailError: state.invite.email.error,

    otpRecipient: state.invite.otp.recipient,
    otpLogin: state.invite.otp.login,
    otpIsLoading: state.invite.otp.isLoading,
    otpIsLoaded: state.invite.otp.isLoaded,
    otpError: state.invite.otp.error,

    confirmationIsLoading: state.invite.confirmation.isLoading,
    confirmationIsLoaded: state.invite.confirmation.isLoaded,
    confirmationError: state.invite.confirmation.error,
    confirmationErrors: state.invite.confirmation.errors,

    registerIsLoading: state.invite.register.isLoading,
    registerIsLoaded: state.invite.register.isLoaded,
    registerError: state.invite.register.error,
  }),
  {
    confirmEmail: confirmEmailAction,
    sendOtp: sendOtpAction,
    checkOtp: checkOtpAction,
    resetInvite: resetInviteAction,
    finishRegister: finishRegisterAction,
  },
)(AcceptInvite);
