import { LoadingOutlined } from '@ant-design/icons';
import { FilterTag } from 'components/tcm/MultipleSelect/style';
import { ClientsFilter } from 'containers/App/TCM/ClientsTable/ClientsFilter/ClientsFilter';
import {
  ClientTableWrapper,
  DeadlineHeader,
  NoResults,
  TableTitle,
} from 'containers/App/TCM/ClientsTable/style';
import { FilterIcon } from 'icons/FilterIcon';
import queryString from 'query-string';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import { ArrowSortingIcon } from 'icons';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { useRowSelect, useTable, useBlockLayout } from 'react-table';
import moment from 'moment';

import {
  fetchList as fetchListAction,

  fetchUnderwayList as fetchUnderwayListAction,
  setUnderwaySorting as setUnderwaySortingAction,
  setUnderwayFilters as setUnderwayFiltersAction,

  fetchWaitConfirmList as fetchWaitConfirmListAction,
  setWaitConfirmSorting as setWaitConfirmSortingAction,
  setWaitConfirmFilters as setWaitConfirmFiltersAction,

  fetchConfirmedList as fetchConfirmedListAction,
  setConfirmedSorting as setConfirmedSortingAction,
  setConfirmedFilters as setConfirmedFiltersAction,

  fetchRevisionList as fetchRevisionListAction,
  setRevisionSorting as setRevisionSortingAction,
  setRevisionFilters as setRevisionFiltersAction,

  fetchAgreeingList as fetchAgreeingListAction,
  setAgreeingSorting as setAgreeingSortingAction,
  setAgreeingFilters as setAgreeingFiltersAction,

  fetchRefusedList as fetchRefusedListAction,
  setRefusedSorting as setRefusedSortingAction,
  setRefusedFilters as setRefusedFiltersAction,

  reset as resetAction,
  setFilters as setFiltersAction,
  setSorting as setSortingAction,
  setOffersStatus as setOffersStatusAction,
  resetOffersStatus as resetOffersStatusAction,
} from 'redux/tcmOffer/actions';

import { TCM } from 'components/tcm';
import {
  OfferStatus,
  FILTER_LIST_TYPE,
  EMPLOYEE_SHOW_CHECK_BOXES,
} from 'tcm/offer/data';
import { SaveFormModalOk } from 'components/tcm/Modals';
import { getDeclension } from 'utils/getDeclension';
import { checkPrivilege } from 'helpers/roles';
import ContentHeader from './ContentHeader';
import { TableSortingTitle } from '../style';
import {
  TableContainer,
  Table,
  TableVinTitleContainer,
  FilterBtnContainer,
  Brand,
  Model,
  TableTitleStroke,
  Vin,
  TableVinBodyContainer,
  Cell,
  ContainerBtn,
  DeadlineContainer,
  DeadLineTime,
  DeadlineIcon,
  NoCalls,
  ButtonContainer,
  ErrorContainer,
} from './style';

import { CLIENTS_STATUS_MAP, CLIENTS_STATUSES_MAP } from './data';

import {
  FilterBtn,
  FilterTags,
  FilterWrapper,
  SortingIconWrapper,
  SortingTableHeader,
} from '../MainTable/style';

const getPeriod = (termFulfillmentFrom, termFulfillmentTo) => {
  const timeFrom = termFulfillmentFrom && moment(termFulfillmentFrom).isValid()
    ? moment(termFulfillmentFrom).format('DD.MM')
    : '';
  const timeTo = termFulfillmentTo && moment(termFulfillmentTo).isValid()
    ? moment(termFulfillmentTo).format('DD.MM.YYYY')
    : '';
  return `${timeFrom}—${timeTo}`;
};

const renderFilteredTable = (filter, role) => {
  switch (filter) {
    case 'WAIT_CONFIRM':
      return <ConnectedWaitConfirmTable role={role} noTitle />;
    case 'CONFIRMED':
      return <ConnectedConfirmedTable role={role} noTitle />;
    case 'CALLING':
      return <ConnectedUnderwayTable role={role} noTitle />;
    case 'REVISION':
      return <ConnectedRevisionTable role={role} noTitle />;
    case 'AGREEING':
      return <ConnectedAgreeingTable role={role} noTitle />;
    case 'REFUSED':
      return <ConnectedRefusedTable role={role} noTitle />;
    default:
      return <div>Нет подходятщей таблицы</div>;
  }
};

const UnderwayTable = ({
  role,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  filterTags,
  appliedFilters,
  statusSetLoaded,
  statusSetIsLoading,
  statusSetError,
  loadedClientsNumber,
  noTitle,

  setOffersStatus,
  resetOffersStatus,
  fetchUnderwayList,
  setUnderwaySorting,
  setUnderwayFilters,
}) => {
  useEffect(() => {
    fetchUnderwayList({ filters: appliedFilters });
  }, [appliedFilters, fetchUnderwayList]);

  useEffect(() => {
    if (statusSetLoaded) {
      fetchUnderwayList({ filters: appliedFilters });
    }
  }, [appliedFilters, fetchUnderwayList, statusSetLoaded]);

  return (
    <StatusTable
      title={noTitle ? '' : 'В работе'}
      type={FILTER_LIST_TYPE.CALLING}
      list={list}
      error={error}
      filterTags={filterTags}
      isLoaded={isLoaded}
      isLoading={isLoading}
      sorting={sorting}
      setOffersStatus={setOffersStatus}
      resetOffersStatus={resetOffersStatus}
      statusSetLoaded={statusSetLoaded}
      statusSetIsLoading={statusSetIsLoading}
      loadedClientsNumber={loadedClientsNumber}
      statusSetError={statusSetError}
      onFilter={setUnderwayFilters}
      onSort={setUnderwaySorting}
      appliedFilters={appliedFilters}
      withCheckBox
      role={role}
    />
  );
};

const ConnectedUnderwayTable = connect(
  (state) => ({
      // offers
    list: state.tcmOffer.collection.underway.list,
    sorting: state.tcmOffer.collection.underway.sorting,
    isLoading: state.tcmOffer.collection.underway.isLoading,
    isLoaded: state.tcmOffer.collection.underway.isLoaded,
    error: state.tcmOffer.collection.underway.error,
    filterTags: state.tcmOffer.collection.underway.filters.tags,
    appliedFilters: state.tcmOffer.collection.underway.filters.data,
    loadedClientsNumber: state.tcmOffer.loadedClientsNumber,

      // statusSet
    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetError: state.tcmOffer.statusSet.error,
  }),
  {
    fetchUnderwayList: fetchUnderwayListAction,
    setUnderwayFilters: setUnderwayFiltersAction,
    setUnderwaySorting: setUnderwaySortingAction,
    setOffersStatus: setOffersStatusAction,
    resetOffersStatus: resetOffersStatusAction,
  },
)(UnderwayTable);

const WaitConfirmTable = ({
  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  filterTags,
  appliedFilters,
  statusSetLoaded,
  statusSetIsLoading,

  fetchWaitConfirmList,
  setWaitConfirmSorting,
  setWaitConfirmFilters,
  setOffersStatus,
  resetOffersStatus,

  role,
  noTitle,
}) => {
  useEffect(() => {
    fetchWaitConfirmList({ filters: appliedFilters });
  }, [appliedFilters, fetchWaitConfirmList]);

  useEffect(() => {
    if (statusSetLoaded) {
      fetchWaitConfirmList({ filters: appliedFilters });
    }
  }, [appliedFilters, fetchWaitConfirmList, statusSetLoaded]);

  return (
    <>
      <StatusTable
        title={noTitle ? '' : 'Ожидают подтверждения менеджера'}
        list={list}
        error={error}
        filterTags={filterTags}
        isLoaded={isLoaded}
        isLoading={isLoading}
        sorting={sorting}
        onFilter={setWaitConfirmFilters}
        onSort={setWaitConfirmSorting}
        appliedFilters={appliedFilters}
        statusSetLoaded={statusSetLoaded}
        statusSetIsLoading={statusSetIsLoading}
        role={role}
        setOffersStatus={setOffersStatus}
        resetOffersStatus={resetOffersStatus}
        type={FILTER_LIST_TYPE.WAIT_CONFIRM}
      />
    </>
  );
};

const ConnectedWaitConfirmTable = connect(
  (state) => ({
    // offers
    list: state.tcmOffer.collection.waitConfirm.list,
    sorting: state.tcmOffer.collection.waitConfirm.sorting,
    isLoading: state.tcmOffer.collection.waitConfirm.isLoading,
    isLoaded: state.tcmOffer.collection.waitConfirm.isLoaded,
    error: state.tcmOffer.collection.waitConfirm.error,
    filterTags: state.tcmOffer.collection.waitConfirm.filters.tags,
    appliedFilters: state.tcmOffer.collection.waitConfirm.filters.data,

    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetError: state.tcmOffer.statusSet.error,
  }),
  {
    fetchWaitConfirmList: fetchWaitConfirmListAction,
    setWaitConfirmSorting: setWaitConfirmSortingAction,
    setWaitConfirmFilters: setWaitConfirmFiltersAction,
    setOffersStatus: setOffersStatusAction,
    resetOffersStatus: resetOffersStatusAction,
  },
)(WaitConfirmTable);

const ConfirmedTable = ({
  role,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  filterTags,
  appliedFilters,
  statusSetLoaded,
  statusSetIsLoading,

  fetchConfirmedList,
  setConfirmedSorting,
  setConfirmedFilters,
  noTitle,
}) => {
  useEffect(() => {
    fetchConfirmedList({ filters: appliedFilters });
  }, [appliedFilters, fetchConfirmedList]);
  return (
    <StatusTable
      title={noTitle ? '' : 'Подтвержденные клиенты'}
      list={list}
      error={error}
      filterTags={filterTags}
      isLoaded={isLoaded}
      isLoading={isLoading}
      sorting={sorting}
      onSort={setConfirmedSorting}
      onFilter={setConfirmedFilters}
      appliedFilters={appliedFilters}
      role={role}
      type={FILTER_LIST_TYPE.CONFIRMED}
      statusSetLoaded={statusSetLoaded}
      statusSetIsLoading={statusSetIsLoading}
    />
  );
};

const ConnectedConfirmedTable = connect(
  (state) => ({
    // offers
    list: state.tcmOffer.collection.confirmed.list,
    sorting: state.tcmOffer.collection.confirmed.sorting,
    isLoading: state.tcmOffer.collection.confirmed.isLoading,
    isLoaded: state.tcmOffer.collection.confirmed.isLoaded,
    error: state.tcmOffer.collection.confirmed.error,
    filterTags: state.tcmOffer.collection.confirmed.filters.tags,
    appliedFilters: state.tcmOffer.collection.confirmed.filters.data,

    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetError: state.tcmOffer.statusSet.error,
  }),
  {
    setConfirmedFilters: setConfirmedFiltersAction,
    setConfirmedSorting: setConfirmedSortingAction,
    fetchConfirmedList: fetchConfirmedListAction,
  },
)(ConfirmedTable);

const RevisionTable = ({
  role,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  filterTags,
  appliedFilters,
  statusSetLoaded,
  statusSetIsLoading,

  fetchRevisionList,
  setRevisionSorting,
  setRevisionFilters,

  noTitle,
}) => {
  useEffect(() => {
    fetchRevisionList({ filters: appliedFilters });
  }, [appliedFilters, fetchRevisionList]);

  return (
    <StatusTable
      title={noTitle ? '' : 'Доработка'}
      list={list}
      error={error}
      filterTags={filterTags}
      isLoaded={isLoaded}
      isLoading={isLoading}
      sorting={sorting}
      onSort={setRevisionSorting}
      onFilter={setRevisionFilters}
      appliedFilters={appliedFilters}
      role={role}
      type={FILTER_LIST_TYPE.REVISION}
      statusSetLoaded={statusSetLoaded}
      statusSetIsLoading={statusSetIsLoading}
    />
  );
};

const ConnectedRevisionTable = connect(
  (state) => ({
    // offers
    list: state.tcmOffer.collection.revision.list,
    sorting: state.tcmOffer.collection.revision.sorting,
    isLoading: state.tcmOffer.collection.revision.isLoading,
    isLoaded: state.tcmOffer.collection.revision.isLoaded,
    error: state.tcmOffer.collection.revision.error,
    filterTags: state.tcmOffer.collection.revision.filters.tags,
    appliedFilters: state.tcmOffer.collection.revision.filters.data,

    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetError: state.tcmOffer.statusSet.error,
  }),
  {
    setRevisionFilters: setRevisionFiltersAction,
    setRevisionSorting: setRevisionSortingAction,
    fetchRevisionList: fetchRevisionListAction,
  },
)(RevisionTable);

const AgreeingTable = ({
  role,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  filterTags,
  appliedFilters,

  fetchAgreeingList,
  setAgreeingSorting,
  setAgreeingFilters,
  noTitle,
}) => {
  useEffect(() => {
    fetchAgreeingList({ filters: appliedFilters });
  }, [appliedFilters, fetchAgreeingList]);

  return (
    <StatusTable
      title={noTitle ? '' : 'Согласившиеся'}
      list={list}
      error={error}
      filterTags={filterTags}
      isLoaded={isLoaded}
      isLoading={isLoading}
      sorting={sorting}
      onSort={setAgreeingSorting}
      onFilter={setAgreeingFilters}
      appliedFilters={appliedFilters}
      role={role}
      type={FILTER_LIST_TYPE.AGREEING}
    />
  );
};

const ConnectedAgreeingTable = connect(
  (state) => ({
    // offers
    list: state.tcmOffer.collection.agreeing.list,
    sorting: state.tcmOffer.collection.agreeing.sorting,
    isLoading: state.tcmOffer.collection.agreeing.isLoading,
    isLoaded: state.tcmOffer.collection.agreeing.isLoaded,
    error: state.tcmOffer.collection.agreeing.error,
    filterTags: state.tcmOffer.collection.agreeing.filters.tags,
    appliedFilters: state.tcmOffer.collection.agreeing.filters.data,
  }),
  {
    setAgreeingFilters: setAgreeingFiltersAction,
    setAgreeingSorting: setAgreeingSortingAction,
    fetchAgreeingList: fetchAgreeingListAction,
  },
)(AgreeingTable);

const RefusedTable = ({
  role,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  filterTags,
  appliedFilters,

  fetchRefusedList,
  setRefusedSorting,
  setRefusedFilters,
  noTitle,
}) => {
  useEffect(() => {
    fetchRefusedList({ filters: appliedFilters });
  }, [appliedFilters, fetchRefusedList]);

  return (
    <StatusTable
      title={noTitle ? '' : 'Отказавшиеся'}
      list={list}
      error={error}
      filterTags={filterTags}
      isLoaded={isLoaded}
      isLoading={isLoading}
      sorting={sorting}
      onSort={setRefusedSorting}
      onFilter={setRefusedFilters}
      appliedFilters={appliedFilters}
      role={role}
      type={FILTER_LIST_TYPE.REFUSED}
    />
  );
};

const ConnectedRefusedTable = connect(
  (state) => ({
    // offers
    list: state.tcmOffer.collection.refused.list,
    sorting: state.tcmOffer.collection.refused.sorting,
    isLoading: state.tcmOffer.collection.refused.isLoading,
    isLoaded: state.tcmOffer.collection.refused.isLoaded,
    error: state.tcmOffer.collection.refused.error,
    filterTags: state.tcmOffer.collection.refused.filters.tags,
    appliedFilters: state.tcmOffer.collection.refused.filters.data,
  }),
  {
    setRefusedFilters: setRefusedFiltersAction,
    setRefusedSorting: setRefusedSortingAction,
    fetchRefusedList: fetchRefusedListAction,
  },
)(RefusedTable);
/**
 *
 * @param role
 * @param type
 * @param title
 * @param list
 * @param filterTags
 * @param isLoaded
 * @param isLoading
 * @param sorting
 * @param setOffersStatus
 * @param statusSetIsLoading
 * @param statusSetError
 * @param appliedFilters
 * @param onFilter
 * @param onSort
 * @param withCheckBox
 * @param showCheckBox
 * @returns {JSX.Element}
 */
const StatusTable = ({
  role,
  error,

  title,
  type,
  list = [],
  filterTags,
  isLoaded,
  isLoading,
  sorting,
  setOffersStatus,
  resetOffersStatus,
  statusSetIsLoading,
  statusSetLoaded,
  loadedClientsNumber,
  statusSetError,
  appliedFilters,
  onFilter,
  onSort,
  withCheckBox,
}) => {
  const [calls, setCalls] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [campaigns, setCampaigns] = useState(false);
  const listMap = useMemo(() => new Map(), []);
  const [showModal, setShowModal] = useState(false);

  const isManager = window.location.pathname.includes('campaign/users')
    && checkPrivilege([role.TCM.VIEW_CAMPAIGN]);
  const pathToClient = isManager ? '/tcm/campaign/users/' : '/tcm/client/';

  useEffect(() => {
    if (type === FILTER_LIST_TYPE.CALLING) {
      resetOffersStatus();
    }
  }, [resetOffersStatus, type]);

  const getDataFromList = useCallback((list) => list.filter((item) => item.car && item.client)
    .map((offer) => {
      const { status, statusDescriptions, statuses } = offer.lastEvent || {};

      const {
        text = () => (
          <NoCalls>
            Нет звонков
          </NoCalls>
        ),
        icon,
      } = (statuses ? CLIENTS_STATUSES_MAP(statuses) : status
        ? CLIENTS_STATUS_MAP[status] || {}
        : {}
      );

      EMPLOYEE_SHOW_CHECK_BOXES[FILTER_LIST_TYPE.CALLING] = (offer?.status === OfferStatus.Calling && status);

      const canShowCheckBoxes = EMPLOYEE_SHOW_CHECK_BOXES[type];

      return ({
        __status: offer?.status,
        __lastCallStatus: status,
        __id: offer.id,
        __hasCheck: canShowCheckBoxes,
        termFulfillmentTo: (
          <DeadlineContainer>
            <p>{getPeriod(offer.termFulfillmentFrom, offer.termFulfillmentTo)}</p>
            <DeadlineIcon>
              {' '}
              {icon}
              {' '}
            </DeadlineIcon>
          </DeadlineContainer>
        ),
        status: (
          <Cell>
            {statusDescriptions ? text(statusDescriptions) : (
              <NoCalls>
                Нет звонков
              </NoCalls>
            )}
          </Cell>
        ),
        campaign: offer.subtopic?.name || '-',
        vin: (
          <TableVinBodyContainer>
            <Vin>
              <TableTitleStroke>{offer.car?.vin || '-'}</TableTitleStroke>
            </Vin>
            <Brand>
              <TableTitleStroke>{offer.car?.brand || '-'}</TableTitleStroke>
            </Brand>
            <Model>
              <TableTitleStroke>{offer.car?.model || '-'}</TableTitleStroke>
            </Model>
          </TableVinBodyContainer>
        ),
        action: (
          <ContainerBtn>
            <TCM.ButtonText as={Link} to={`${pathToClient}${offer.externalId}`}>
              Открыть
            </TCM.ButtonText>
          </ContainerBtn>
        ),
      });
    }), [type, pathToClient]);

  const tableData = useMemo(() => getDataFromList(list),
    [getDataFromList, list]);

  const CarTitle = useMemo(() => (
    <>
      <TableVinTitleContainer>
        <Vin>
          <p>VIN</p>
        </Vin>
        <Brand>
          <p>Марка</p>
        </Brand>
        <Model>
          <p>Модель</p>
        </Model>
      </TableVinTitleContainer>
    </>
  ), []);

  const columns = useMemo(() => [
    {
      Header: (
        <DeadlineHeader>
          <DeadLineTime>Срок выполнения</DeadLineTime>
          <DeadlineIcon />
        </DeadlineHeader>
      ),
      accessor: 'termFulfillmentTo',
      sortKey: 'termFulfillmentTo',
      minWidth: withCheckBox ? 230 : 280,
      width: withCheckBox ? 230 : 280,
    },
    {
      Header: 'Результат события',
      accessor: 'status',
      sortKey: 'lastEvent.status',
      minWidth: 300,
      width: 300,
    },
    {
      Header: 'Кампания',
      accessor: 'campaign',
      sortKey: 'campaign',
      minWidth: 150,
      width: 150,
    },
    {
      Header: CarTitle,
      accessor: 'vin',
      sortKey: 'car.model',
      minWidth: 180,
      width: 180,
    },
    {
      Header: !filterTags.length && (
        <FilterBtnContainer>
          <TCM.ButtonFilter
            onClick={() => {
              setShowFilter(true);
            }}
          />
        </FilterBtnContainer>
      ),
      accessor: 'action',
      minWidth: 110,
      width: 110,
    },
  ],
  [filterTags.length, CarTitle, withCheckBox]);

  const selectHook = useCallback((hooks) => {
    if (withCheckBox) {
      return hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <Form.Item noStyle>
                <TCM.Checkbox {...getToggleAllRowsSelectedProps()} />
              </Form.Item>
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {(row.original.__hasCheck)
          && (
            <Form.Item noStyle>
              <TCM.Checkbox {...row.getToggleRowSelectedProps()} />
            </Form.Item>
          )}
            </div>
          ),
          width: 50,
        },
        ...columns,
      ]);
    }
  }, [withCheckBox]);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
    },
    useRowSelect,
    selectHook,
    useBlockLayout,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const handleSendToManager = () => {
    const offerIds = selectedFlatRows
      .filter((item) => item.original.__hasCheck)
      .map((item) => listMap.get(item.original.__id).id);

    if (offerIds.length > 0) {
      setOffersStatus({
        offerIds,
        status: 'WAIT_CONFIRM',
      });
      setShowModal(true);
    }
  };

  const handleResetModal = () => {
    setShowModal(false);
    resetOffersStatus();
  };

  const deleteTagHandler = (tag) => {
    if (['brands', 'models', 'visitStatuses'].includes(tag.key)) {
      const currentTagIndex = appliedFilters[tag.key].findIndex((t) => t.label === tag.label);
      if (currentTagIndex !== -1) {
        const prevCurrentTypeTags = appliedFilters[tag.key].slice(0, currentTagIndex);
        const nextCurrentTypeTags = appliedFilters[tag.key].slice(currentTagIndex + 1);

        onFilter({
          filters: {
            ...appliedFilters,
            [tag.key]: [...prevCurrentTypeTags, ...nextCurrentTypeTags],
          },
        });
      }
    } else {
      onFilter({
        filters: {
          ...appliedFilters,
          [tag.key]: undefined,
        },
      });
    }
  };

  useEffect(() => {
    if (!campaigns && isLoaded) {
      const campaignsList = list.map((offer) => offer.campaign).reduce((acc, curr) => {
        if (!acc.some((option) => option.value === curr.id)) {
          return acc.concat({
            label: curr.name,
            value: curr.id,
          });
        }
        return acc;
      }, []);

      setCampaigns(campaignsList);
    }
  }, [campaigns, isLoaded, list]);

  useEffect(() => {
    if (isLoaded && Array.isArray(list) && list.length > 0) {
      setCalls(list);
      list.map((item) => listMap.set(item.id, item));
    }
  }, [isLoaded, list, listMap, setCalls]);

  return (
    <>
      <TableContainer>

        {title ? (
          <TableTitle>
            {title}
            {isLoading && (
              <>
                {' '}
                <LoadingOutlined />
              </>
            )}
          </TableTitle>
        ) : (
          <>
            {isLoading && <TCM.Loader className="loader" text="Идет загрузка списка клиентов..." />}
          </>
        )}

        {Boolean(filterTags.length) && (
          <FilterWrapper>
            <FilterTags>
              {filterTags.map((tag) => (
                <FilterTag
                  key={tag.label}
                  label={tag.label}
                  onClose={() => deleteTagHandler(tag)}
                />
              ))}
            </FilterTags>
            <FilterBtn onClick={() => setShowFilter(!showFilter)}>
              <FilterIcon />
            </FilterBtn>
          </FilterWrapper>
        )}

        {list.length > 0 && (
          <Table hide={isLoading} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map((column) => {
                      if (!['action'].includes(column.id)) {
                        return (
                          <th
                            {...column.getHeaderProps()}
                            onClick={() =>
                              column.sortKey && onSort({ sortBy: column.sortKey })}
                          >
                            <SortingTableHeader>
                              <TableSortingTitle isActive={sorting?.sortBy === column.sortKey}>
                                {column.render('Header')}
                              </TableSortingTitle>
                              <SortingIconWrapper>
                                { sorting?.sortBy === column.sortKey
                              && (
                                <>
                                  {
                                    sorting.order === 'desc'
                                      ? <ArrowSortingIcon />
                                      : <ArrowSortingIcon up />
                                  }
                                </>
                              )}
                              </SortingIconWrapper>
                            </SortingTableHeader>
                          </th>
                        );
                      }
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      );
                    })
                  }
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}

        {list.length === 0 && isLoaded && (
          <NoResults>
            <p>Подходящих клиентов нет</p>
          </NoResults>
        )}

        {error && (
          <NoResults error>
            <p>{error}</p>
          </NoResults>
        )}

        {type === FILTER_LIST_TYPE.CALLING && isLoaded && (
          <>
            <ButtonContainer>
              <TCM.Button
                disabled={!(calls.length > 0
            && selectedFlatRows.filter((item) => item.original.__hasCheck).length > 0)}
                onClick={handleSendToManager}
                loading={statusSetIsLoading}
              >
                Отправить менеджеру на согласование
              </TCM.Button>
              <ErrorContainer>{statusSetError}</ErrorContainer>
            </ButtonContainer>

            <SaveFormModalOk
              visible={showModal && statusSetLoaded}
              title={`Вы отправили на согласование 
              ${loadedClientsNumber === 1 ? 'одного' : loadedClientsNumber} ${getDeclension(loadedClientsNumber, 'клиент')}`}
              description={`Ожидаемая дата получения ответа от менеджера: ${moment().add(3, 'd').format('DD.MM.YYYY')}`}
              onOk={() => handleResetModal()}
              btnTextOk="ОК"
            />
          </>
        )}
      </TableContainer>

      <ClientsFilter
        visible={showFilter}
        onClose={() => setShowFilter(false)}
        onFilter={onFilter}
        campaigns={campaigns}
        appliedFilters={appliedFilters}
      />
    </>
  );
};

const ClientsTable = ({
  role,

  fetchList,

  statusSetLoaded,
  reset,
}) => {
  const location = useLocation();
  const { filter, userFilter } = queryString.parse(location.search);
  const filterClients = filter || userFilter;

  useEffect(() => {
    if (statusSetLoaded) {
      fetchList();
    }
  }, [fetchList, statusSetLoaded]);

  useEffect(() => () => {
    reset();
  }, [reset]);

  return useMemo(() => (
    <ClientTableWrapper>
      <ContentHeader withCommonSearch={!filterClients} />

      {filterClients
        ? (
          renderFilteredTable(filterClients, role)
        ) : (
          <>
            <ConnectedUnderwayTable
              role={role}
            />

            <ConnectedWaitConfirmTable
              role={role}
            />

            <ConnectedConfirmedTable
              role={role}
            />
          </>
        )}
    </ClientTableWrapper>
  ), [filterClients, role]);
};

const ConnectedWithRedux = connect(
  (state) => ({
    role: state.auth.role,

    // offers

    list: state.tcmOffer.collection.list,
    sorting: state.tcmOffer.collection.sorting,
    isLoading: state.tcmOffer.collection.isLoading,
    isLoaded: state.tcmOffer.collection.isLoaded,
    error: state.tcmOffer.collection.error,
    filterTags: state.tcmOffer.collection.filters.tags,
    appliedFilters: state.tcmOffer.collection.filters.data,

    // statusSet
    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetError: state.tcmOffer.statusSet.error,
  }),
  {
    fetchList: fetchListAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    reset: resetAction,
    setOffersStatus: setOffersStatusAction,
    resetOffersStatus: resetOffersStatusAction,
  },
)(ClientsTable);

export { ConnectedWithRedux as ClientsTable };
