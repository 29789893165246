import React from 'react';
import styled, { css } from 'styled-components';
import { TCM } from 'components/tcm';
import { CHAR } from 'settings/namedUnicode';
import withTheme from './withTheme';

const RangeRow = styled.div`
  display: grid;
  grid-template-rows: 22px minmax(50px, max-content);
  grid-template-columns: 1fr 20px 1fr;
  grid-template-areas: 
  "leftInput     .     rightInput"
  "leftInput separator rightInput";
  align-items: flex-start;
`;

const LabelFrom = styled.div`
  grid-area: leftInput;
`;

const LabelTo = styled.div`
  grid-area: rightInput;
`;

const SeparatorWrapper = withTheme(styled.div`
  ${({ theme }) => css`
  grid-area: separator;
  margin-top: 15px;
  font-family: ${theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: ${theme.palette.black};
  `}
`);

export const InputRange = ({
  propsLabelFrom, propsInputFrom, propsLabelTo, propsInputTo,
}) => (
  <RangeRow>
    <LabelFrom>
      <TCM.Label {...propsLabelFrom}>
        <TCM.InputNumber placeholder="Введите кол-во" {...propsInputFrom} />
      </TCM.Label>
    </LabelFrom>
    <SeparatorWrapper>{CHAR.MDASH}</SeparatorWrapper>
    <LabelTo>
      <TCM.Label {...propsLabelTo}>
        <TCM.InputNumber placeholder="Введите кол-во" {...propsInputTo} />
      </TCM.Label>
    </LabelTo>
  </RangeRow>
);
