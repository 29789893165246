import React, { useMemo } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { TableContainer } from 'containers/App/TCM/Disribution/style';
import { TCM } from 'components/tcm';
import { Link } from 'react-router-dom';
import {
  Brand,
  CallingTable,
  CallingTitle,
  CallingWrapper,
  CellWithIcon,
  Model,
  ResultCell,
  TableTitleSecondaryStroke,
  TableTitleStroke,
  TableVinBodyContainer,
  TableVinTitleContainer,
  Vin,
} from 'containers/App/TCM/Оffer/style';
import { getEventText } from 'tcm/offer/format';
import { connect } from 'react-redux';
import { setOffersStatus as setOffersStatusAction } from 'redux/tcmOffer/actions';
import {
  getIconFromOffer,
  getTextFromOfferStatus,
} from 'tcm/offer/data';

const CompleteStatusTable = ({
  list, isLoading,
}) => {
  const columns = useMemo(() => [
    {
      Header: (
        <TableVinTitleContainer>
          <Vin>
            <TableTitleSecondaryStroke>VIN</TableTitleSecondaryStroke>
          </Vin>
          <Brand>
            <TableTitleStroke>Марка</TableTitleStroke>
          </Brand>
          <Model>
            <TableTitleSecondaryStroke>Модель</TableTitleSecondaryStroke>
          </Model>
        </TableVinTitleContainer>
      ),
      accessor: 'vin', // accessor is the "key" in the data
    },
    {
      Header: 'Сотрудник',
      accessor: 'employee',
      sortKey: 'employee.name',
    },
    {
      Header: 'Статус',
      accessor: 'status',
      sortKey: 'status',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ], []);

  const data = useMemo(() => list
    .filter((item) => item.status && item.car)
    .map((offer) => ({
      statusValue: offer.status,
      vin: (
        <TableVinBodyContainer>
          <Vin>{offer.car?.vin || ''}</Vin>
          <Brand>{offer.car?.brand || ''}</Brand>
          <Model>{offer.car?.model || ''}</Model>
        </TableVinBodyContainer>
      ),
      brand: offer.car.brand,
      model: offer.car.model,
      employee: (
        <CellWithIcon>
          <div>{offer.employee ? offer.employee.name : '-'}</div>
          {getIconFromOffer(offer)}
        </CellWithIcon>
      ),
      status: (
        <ResultCell>
          {Boolean(offer.status) && (
            <p>{getTextFromOfferStatus(offer.status)}</p>
          )}

          {Boolean(offer.lastEvent) && (
            <p>
              {getEventText(offer.lastEvent)}
            </p>
          )}
        </ResultCell>
      ),
      action: (
        <TCM.ButtonText as={Link} to={`/tcm/campaign/offers/${offer.externalId}`}>
          Просмотр клиента
        </TCM.ButtonText>
      ),
    })), [list]);

  const tableInstance = useTable(
    { columns, data },
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // selectedFlatRows,
  } = tableInstance;

  return (
    <CallingWrapper>
      <CallingTitle>Клиенты:</CallingTitle>
      <TableContainer>
        {isLoading && <TCM.Loader text="Идет загрузка списка клиентов..." />}

        <CallingTable hide={isLoading} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </CallingTable>

      </TableContainer>
    </CallingWrapper>
  );
};

const ConnectedWithRedux = connect(
  (state) => ({
    // statusSet
    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetError: state.tcmOffer.statusSet.error,
  }),
  {
    setOffersStatus: setOffersStatusAction,
  },
)(CompleteStatusTable);

export { ConnectedWithRedux as CompleteStatusTable };
