import { parseDigitsWithSeparator as parseDigits } from 'utils';

export const KEYS = {
  IS_INTERNAL: {
    key: 'IS_INTERNAL',
    apiKey: '',
    title: 'Внутренний пользователь',
    rules: [],
  },

  NAME: {
    key: 'NAME',
    title: 'ФИО',
    apiKey: 'fullName',
    placeholder: 'Укажите ФИО',
    rules: [
      {
        required: true,
        message: 'Имя не может быть пустым',
      },
    ],
  },

  LOGIN: {
    key: 'LOGIN',
    apiKey: 'login',
    title: 'Логин',
    rules: [
      {
        required: true,
        message: 'Логин не может быть пустым',
      },
    ],
  },

  EMAIL: {
    key: 'EMAIL',
    apiKey: 'email',
    title: 'Email',
    placeholder: 'Укажите email',
    rules: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          const isUserInternal = getFieldValue('IS_INTERNAL');
          if (isUserInternal) {
            return Promise.resolve();
          }
          if (typeof value === 'string' && value.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Email не может быть пустым');
        },
      }),
      ({ getFieldValue }) => ({
        validator(rule, value) {
          const isUserInternal = getFieldValue('IS_INTERNAL');
          if (isUserInternal) {
            return Promise.resolve();
          }
          if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
            return Promise.resolve();
          }
          return Promise.reject('Невалидный емайл');
        },
      }),
    ],
  },

  PHONE: {
    key: 'PHONE',
    apiKey: 'phone',
    title: 'Телефон',
    rules: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          const isUserInternal = getFieldValue('IS_INTERNAL');
          if (isUserInternal) {
            return Promise.resolve();
          }
          if (value) {
            return Promise.resolve();
          }
          return Promise.reject('Номер телефона не может быть пустым');
        },
      }),
      ({ getFieldValue }) => ({
        validator(rule, value) {
          const isUserInternal = getFieldValue('IS_INTERNAL');
          if (isUserInternal) {
            return Promise.resolve();
          }
          if (typeof value === 'string' && parseDigits(value).length === 10) {
            return Promise.resolve();
          }
          return Promise.reject('Номер телефона должен содержать 10 цифр');
        },
      }),
    ],
  },

  STATUS: {
    key: 'STATUS',
    apiKey: 'status',
    title: 'Статус',
    rules: [],
  },

  ROLE: {
    key: 'ROLE',
    apiKey: 'roleId',
    title: 'Роль',
    placeholder: 'Выберите роли',
    rules: [
      {
        required: true,
        message: 'Выберите существующую роль',
      },
    ],
  },

  COMPANY: {
    key: 'COMPANY',
    apiKey: 'merchantId',
    title: 'Организация',
    placeholder: 'Выберите организацию',
    rules: [
      {
        required: true,
        message: 'Выберите существующую организацию',
      },
    ],
  },

  DEALER_CENTER: {
    key: 'DEALER_CENTER',
    apiKey: 'dealerCenterId',
    title: 'Точка продаж',
    placeholder: 'Выберите точку продаж',
    rules: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          const roles = getFieldValue('HIDDEN_ROLES');
          const role = getFieldValue('ROLE');

          const availableRolesList = roles.map((item) => {
            const [id, name] = item.split('_');
            return ({ id: Number(id), name });
          });

          const isRoleTCM = availableRolesList.find((item) => (item.id === role)
              && ((item.name === 'ТСМ Менеджер ДЦ') || (item.name === 'ТСМ Сотрудник ДЦ')));

          if ((isRoleTCM && isRoleTCM.id)) {
            if (value) {
              return Promise.resolve();
            }

            return Promise.reject('Выберите точку продажи');
          }
          return Promise.resolve();
        },
      }),
    ],
  },
};
