import React, { useCallback, useEffect, useRef } from 'react';
import Input from 'components/InputNumber';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { SCROLL_DURATION } from 'settings';
import { parseDigits } from 'utils';
import { mergeArrays } from 'hacks';

const MIN = 0;
const MAX = 999999999;

const format = (string, desc = '') => {
  const valueFormatted = `${string} ₽ ${desc}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return string === '' ? '' : parseDigits(string).length > 0 ? valueFormatted : '';
};

const parse = (e) => Number(parseDigits(e));

function CurrencyField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  range,
  warning,

  form: { getFieldDecorator, getFieldValue, setFieldsValue },
  fields,

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  function handleOnBlur() {
    const current = getFieldValue(name);
    const d = Number(parseDigits(current));
    const [min = MIN, max = MAX] = range || [];
    const v = d < min ? min : d > max ? max : d;

    if (current === '') {
      if (initialValue !== null) {
        setFieldsValue({ [name]: undefined });
        onChangeField({}, [...mergeArrays(dependents, ifChangeClearFields), name]);
      }
    } else if (v !== Number(parseDigits(initialValue))) {
      setFieldsValue({ [name]: v });
      onChangeField({ [name]: v }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  let desc = '';
  const paymentFields = fields && (fields.find((v) => v.name === 'rowMonthlyPayments') || {}).data;
  if (name === 'creditOptionsMonthlyPaymentMin') {
    const period1 = +(paymentFields.find((v) => v.name === 'creditOptionsPeriodMonthlyMin') || {}).value;
    desc = period1 === 0 ? '1 месяц' : `с 1 по ${period1 + 1} месяц`;
  } else if (name === 'creditOptionsMonthlyPaymentMid') {
    const period1 = +(paymentFields.find((v) => v.name === 'creditOptionsPeriodMonthlyMin') || {}).value;
    const period2 = +(paymentFields.find((v) => v.name === 'creditOptionsPeriodMonthlyMid') || {}).value;
    desc = (period1 + 1 === period1 + period2) ? `${period1 + 1} месяц` : `с ${period1 + 1} по ${period1 + period2} месяц`;
  } else if (name === 'creditOptionsMonthlyPaymentAmount') {
    const period1 = +(paymentFields.find((v) => v.name === 'creditOptionsPeriodMonthlyMin') || {}).value;
    const period2 = +(paymentFields.find((v) => v.name === 'creditOptionsPeriodMonthlyMid') || {}).value;
    const period3 = +(paymentFields.find((v) => v.name === 'creditOptionsPeriodMonthlyMax') || {}).value;

    if (period1 || period2) {
      const p1 = period1 + period2 + 1;
      const p2 = period1 + period2 + period3;
      desc = p1 === p2 ? `${p1} месяц` : `с ${p1} по ${p2} месяц`;
    }
  }

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: Number.isInteger(initialValue)
          ? initialValue
          : typeof initialValue === 'string'
            ? Number(parseDigits(initialValue))
            : undefined,
      })(
        <Input
          min={MIN}
          max={MAX}
          name={name}
          formatter={(value) => format(value, desc)}
          parser={parse}
          placeholder={placeholder}
          area={area}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={focusableNode}
          size={size}
        />,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default CurrencyField;
