import React from 'react';

import Button from 'components/Button';

import {
  Root,
  RedirectContainer,
  RedirectButtonsContainer,
  RedirectTitle,
  TextItem,
} from './style';

const statusChangeToDefault = true;

function StatusModal({
  // passed
  close,
  visible,
  handleSave,

}) {
  const handleFinish = () => {
    handleSave();
    close();
  };

  return (
    <Root
      visible={visible}
      onCancel={close}
      footer={null}
      width={768}
    >
      <RedirectContainer>
        <RedirectTitle>
          <TextItem> При сохранении произойдет смена статуса договора. Продолжить?</TextItem>
        </RedirectTitle>
        <RedirectButtonsContainer>
          <Button
            type="ghost"
            shape="square"
            size="small"
            onClick={() => close(statusChangeToDefault)}
          >
            Отмена
          </Button>
          <Button
            type="primary"
            shape="square"
            size="small"
            onClick={handleFinish}
          >
            Сохранить
          </Button>
        </RedirectButtonsContainer>
      </RedirectContainer>
    </Root>
  );
}

export default StatusModal;
