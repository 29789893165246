import React from 'react';
import { RestrictedRoute } from 'router';
import { Route, Redirect, Switch } from 'react-router-dom';

import List from './List';
import Item from './Item';

const checkRights = () => true;

function Index() {
  return (
    <Switch>
      <Route exact path="/app/payments/contracts">
        <Redirect to="/app/payments/contracts/list" />
      </Route>
      <RestrictedRoute
        path="/app/payments/contracts/list"
        component={List}
        checkRights={checkRights}
      />
      <RestrictedRoute
        path="/app/payments/contracts/:sectorId/new"
        component={Item}
        checkRights={checkRights}
      />
      <RestrictedRoute
        path="/app/payments/contracts/:contractId"
        component={Item}
        checkRights={checkRights}
      />
    </Switch>
  );
}

export default Index;
