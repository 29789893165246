import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import StartPage from 'containers';
import App from 'containers/App';
import Auth from 'containers/Auth';
import AccessRestricted from 'containers/AccessRestricted';
import TCM from './containers/App/TCM';

export const RestrictedRoute = connect((state) => ({
  role: state.auth.role,
}))(({
  component: Component, role, checkRights, ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      (checkRights(role) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/accessRestricted',
            state: { from: props.location },
          }}
        />
      ))}
  />
));

const AuthRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      (isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth',
            state: { from: props.location },
          }}
        />
      ))}
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => (
  <ConnectedRouter history={history}>
    <AuthRoute exact path="/" component={StartPage} isLoggedIn={isLoggedIn} />
    <Route path="/auth" component={Auth} />
    <Route path="/accessRestricted" component={AccessRestricted} />
    <AuthRoute path="/tcm" component={TCM} isLoggedIn={isLoggedIn} />
    <AuthRoute path="/app" component={App} isLoggedIn={isLoggedIn} />
  </ConnectedRouter>
);

export const checkRedirectRoute = ({ role, routes }) => (
  (routes.find((e) => e.checkRights(role)) || {}).path
  || '/accessRestricted'
);

export default connect((state) => ({
  isLoggedIn: state.auth.idToken !== null,
  role: state.auth.role,
}))(PublicRoutes);
