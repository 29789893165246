import React from 'react';

export const CloseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L19 19" stroke="#7A7A7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 1L1 19" stroke="#7A7A7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TagCloseIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L1 9M1 1L9 9" stroke="#6C7073" strokeWidth="2" />
  </svg>
);
