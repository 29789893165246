import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import Loader from 'components/Loader';
import { CODEBOOK, DATE_FORMAT, TYPES } from 'settings';
import moment from 'moment';

import {
  reset as resetCrif,
  setPage as setCrifPage,
  setSorting as setCrifSorting,
  unlockSearch as unlockCrifSearch,
} from 'redux/crif/actions';

import { patch as patchRequest } from 'redux/request/actions';

import { CloseOutlined } from '@ant-design/icons';

import {
  EmptyMessage,
  EmptyResultsClose,
  EmptyResultsMessage,
  ErrorMessage,
  LoadingMessage,
  Root,
  TableHeader,
} from './style';

const formatAddress = ({
  country = '',
  region = '',
  regionType = '',
  city = '',
  cityType = '',
  area = '',
  areaType = '',
  street = '',
  streetType = '',
  settlement = '',
  settlementType = '',
  house = '',
}) => {
  const _country = country && CODEBOOK.countries[country];
  const _region = region && CODEBOOK.regionTypes[regionType]
    ? `${region} ${CODEBOOK.regionTypes[regionType]}`
    : null;
  const _city = city && CODEBOOK.cityTypes[cityType]
    ? `${CODEBOOK.cityTypes[cityType]} ${city}`
    : null;
  const _area = area && CODEBOOK.areaTypes[areaType]
    ? `${CODEBOOK.areaTypes[areaType]} ${area}`
    : null;
  const _street = street && CODEBOOK.streetTypes[streetType]
    ? `${CODEBOOK.streetTypes[streetType]} ${street}`
    : null;
  const _settlement = settlement && CODEBOOK.settlementTypes[settlementType]
    ? `${CODEBOOK.settlementTypes[settlementType]} ${settlement}`
    : null;

  return (
    <div>
      <span>
        {[_country, _region, _city, _area, _street, _settlement, house]
          .filter(Boolean)
          .join(', ')}
      </span>
    </div>
  );
};

function CrifField({
  // passed
  disabled,
  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,

  // connect
  fieldsFlat,
  list,
  isLoading,
  isLoaded,
  error,
  resultIsLocked,
  hasActiveMoratorium,

  // actions
  resetCrif,
  unlockCrifSearch,
  patchRequest,
}) {
  const scrollableNode = useRef();

  useEffect(() => () => resetCrif(), [resetCrif]);

  function applySelection(id = '') {
    const item = list.find((e) => e.crmId === id) || {};
    if (item) {
      patchRequest({
        userInput: {
          [TYPES.CRM_SELECT]: String(id),
          crmSubjectID5NT: item.fiveNtId,
          crmGroupID: item.groupId,
        },
      });
    }
  }

  const columns = [
    {
      dataIndex: 'data',
      title: () => <TableHeader>ФИО</TableHeader>,
      render: ({ surname = '', name = '', patronymic = '' }) => (
        <div>
          <div>{surname}</div>
          <div>{name}</div>
          <div>{patronymic}</div>
        </div>
      ),
      width: 100,
    },
    {
      dataIndex: 'document',
      title: () => <TableHeader>Дата рождения</TableHeader>,

      // in order to address and issue with timezone
      // we decided to just ignore it
      // https://selfteam.atlassian.net/browse/TB-3228
      // well, the solution should be provided from the server-side, I guess
      render: ({ birthdate }) =>
        birthdate
        && typeof birthdate === 'string' && (
          <span>{moment.utc(birthdate.split('T')[0]).format(DATE_FORMAT)}</span>
        ),
      width: 100,
    },
    {
      dataIndex: 'document',
      title: () => <TableHeader>Место рождения</TableHeader>,
      render: ({ birthplace }) => <span>{birthplace}</span>,
      width: 100,
    },
    {
      dataIndex: ['document', 'passport'],
      title: () => <TableHeader>Паспортные данные</TableHeader>,
      render: ({
        series = '',
        number = '',
        issueDate = '',
        issuer = '',
        issuerCode = '',
      }) => (
        <div>
          <div>
            <b>Серия</b>
            {' '}
            {series}
            {' '}
            <b>номер</b>
            {' '}
            {number}
          </div>
          <div>
            <b>Выдан</b>
            {' '}
            {issuer}
            {' '}
            {issueDate}
          </div>
          <div>
            <b>Код подразделения</b>
            {' '}
            {issuerCode}
          </div>
        </div>
      ),
      width: 300,
    },
    {
      dataIndex: ['document', 'oldPassport'],
      title: () => <TableHeader>Предыдущий паспорт</TableHeader>,
      render: ({ series = '', number = '' }) =>
        series.length > 0
        && number.length > 0 && (
          <div>
            <b>Серия</b>
            {' '}
            {series}
            {' '}
            <b>номер</b>
            {' '}
            {number}
          </div>
        ),
      width: 100,
    },
    /* {
      dataIndex: 'document.oldPassport',
      title: () => TableHeader({
        title: 'Адрес регистрации',
      }),
      render: ({
        series = '',
        number = '',
      }) => series.length > 0 && number.length > 0 && (
        <div><b>Серия</b> {series} <b>номер</b> {number}</div>
      ),
      width: 100,
    }, */
    {
      dataIndex: 'address',
      title: () => <TableHeader>Адрес фактического проживания</TableHeader>,
      render: (address) => formatAddress(address),
      width: 100,
    },
    {
      dataIndex: 'contact',
      title: () => <TableHeader>Номер мобильного телефона</TableHeader>,
      render: ({ value }) => value && <div>{value}</div>,
      width: 100,
    },
    {
      key: 'actions',
      dataIndex: 'crmId',
      title: '',
      render: (crmId) => (
        <Button disabled={disabled} onClick={() => applySelection(crmId)}>
          Выбрать
        </Button>
      ),
      width: 80,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { error: errorCRM } = fieldsFlat.find((e) => e.name === TYPES.CRM_SELECT) || {};

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }
    }
  }, [scrollableNode, onScrollCallback]);

  useEffect(() => {
    if ([TYPES.CRM_RESULTS, TYPES.CRM_SELECT].includes(fieldToScrollName)) {
      scrollTo();
    }
  }, [fieldToScrollName, scrollTo]);

  return (
    !resultIsLocked && (
      <Root ref={scrollableNode}>
        {isLoading && <Loader text="Выполняется поиск..." height={100} />}
        {isLoaded && !hasActiveMoratorium && (
          <>
            {list.length === 0 ? (
              <EmptyResultsMessage>
                <span>Совпадения не найдены</span>
                <EmptyResultsClose disabled={disabled} onClick={unlockCrifSearch}>
                  <CloseOutlined />
                </EmptyResultsClose>
              </EmptyResultsMessage>
            ) : (
              <>
                <Table
                  rowKey={(record) => record.number}
                  columns={columns}
                  dataSource={list}
                  loading={isLoading}
                  pagination={false}
                  locale={locale}
                  bordered
                  size="small"
                />
                {errorCRM && <ErrorMessage>Необходимо выбрать клиента</ErrorMessage>}
              </>
            )}
          </>
        )}
        {error && (
          <ErrorMessage>
            Результаты поиска:
            {error}
          </ErrorMessage>
        )}
      </Root>
    )
  );
}

export { CrifField };

export default connect(
  (state) => ({
    fetchId: state.request.fetch.id,
    fieldsFlat: state.request.fieldsFlat,
    id: state.crif.id,
    list: state.crif.list,
    isLoading: state.crif.isLoading,
    isLoaded: state.crif.isLoaded,
    error: state.crif.error,
    sorting: state.crif.sorting,
    resultIsLocked: state.crif.resultIsLocked,
    buttonIsLocked: state.crif.buttonIsLocked,
    hasActiveMoratorium: state.crif.hasActiveMoratorium,
  }),
  {
    resetCrif,
    setCrifPage,
    setCrifSorting,
    unlockCrifSearch,
    patchRequest,
  },
)(CrifField);
