import { KEYS } from 'containers/App/TCM/ClientCard/CallForm/data';
import { RadioGroup } from 'containers/App/TCM/ClientCard/CallForm/style';
import { RecallSubField } from 'containers/App/TCM/ClientCard/CallForm/RecallSubField';
import { Form } from 'antd';
import React from 'react';
import { RecallOtherSubfield } from 'containers/App/TCM/ClientCard/CallForm/RecallOtherSubfield';
import { NotInterestedSubFields } from 'containers/App/TCM/ClientCard/CallForm/NotIntrestedSubfields';
import { TCM } from 'components/tcm';

export const PositiveResults = ({ result, setResult }) => (
  <Form.Item
    name={KEYS.STATUS.key}
    rules={KEYS.STATUS.rules}
    validateTrigger={['onChange', 'onBlur']}
  >
    <RadioGroup onChange={(e) => setResult(e.target.value)}>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.recall.value}>
          {KEYS.STATUS.options.recall.label}
        </TCM.Radio>
        {
          result === KEYS.STATUS.options.recall.value && (
            <RecallSubField />
          )
        }
      </div>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.visitToDc.value}>
          {KEYS.STATUS.options.visitToDc.label}
        </TCM.Radio>
      </div>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.notOwner.value}>
          {KEYS.STATUS.options.notOwner.label}
        </TCM.Radio>
        {
          result === KEYS.STATUS.options.notOwner.value && (
            <RecallOtherSubfield />
          )
        }
      </div>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.notClient.value}>
          {KEYS.STATUS.options.notClient.label}
        </TCM.Radio>
      </div>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.notInterested.value}>
          {KEYS.STATUS.options.notInterested.label}
        </TCM.Radio>
        {result === KEYS.STATUS.options.notInterested.value && (
          <NotInterestedSubFields />
        )}
      </div>

      <div>
        <TCM.Radio value={KEYS.STATUS.options.notCall.value}>
          {KEYS.STATUS.options.notCall.label}
        </TCM.Radio>
      </div>

    </RadioGroup>
  </Form.Item>
);
