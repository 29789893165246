import API from 'services';

import {
  all, call, fork, put, takeLeading,
} from 'redux-saga/effects';

import { extractErrorAndCode } from 'hacks';

import {
  COUNTRIES__FETCH,
  COUNTRIES__FETCH_SUCCESS,
  COUNTRIES__FETCH_FAILURE,
} from './actions';

export function* fetch() {
  yield takeLeading(COUNTRIES__FETCH, function* () {
    try {
      const response = yield call(API.countries.fetch);

      if (
        response.data
        && response.data.dictionaryValueList
        && response.data.dictionaryValueList.length > 0
      ) {
        yield put({
          type: COUNTRIES__FETCH_SUCCESS,
          list: response.data.dictionaryValueList.map((e) => ({
            value: e.description,
            label: e.description,
          })),
        });
      } else {
        throw new Error(extractErrorAndCode(response).error);
      }
    } catch (error) {
      yield put({ type: COUNTRIES__FETCH_FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetch)]);
}
