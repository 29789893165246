import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import Table from './Table';

function List({
  setBreadcrumbs,
}) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Онлайн заявка', link: '/app/leads/request/list' },
        { level: 1, name: 'Обмен документами', link: '/app/leads/credit-request/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  return (<Table />);
}

export default connect(
  null,
  {
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
