const KEY = 'data';

function iterate({ collection = [], array = [] }) {
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    collection.push(item);
    if (item[KEY] && item[KEY].length > 0) {
      iterate({ collection, array: item[KEY] });
    }
  }
  return collection;
}

export const flattenFields = (array) => iterate({ array });
