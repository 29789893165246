import React from 'react';

import { Root, Description } from './style';

function AccessRestricted() {
  return (
    <Root>
      <Description>
        Не достаточно прав для просмотра данного раздела, обратитесь к вашему администратору.
      </Description>
    </Root>
  );
}

export default AccessRestricted;
