import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  pagination: { page, pageSize },
  filters,
  sorting: sort,
}) => {
  const URI = `${API}/creditrequest/signing/list`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        fullName: filters.fullName || undefined,
        crifId: filters.crifId || undefined,
      },
      meta: {
        pagination: { page, pageSize },
        sort,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getDetails = ({ creditToken }) => {
  const URI = `${API}/creditrequest/signing/single`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { creditToken },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getDocuments = ({ creditToken }) => {
  const URI = `${API}/credit-client-cabinet/file/signing/list`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { creditToken, andSigned: true },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const saveDocument = ({ data }) => {
  const URI = `${API}/credit-client-cabinet/file/signing/save`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const removeDocument = ({ creditToken, id }) => {
  const URI = `${API}/credit-client-cabinet/file/signing/delete`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { creditToken, id },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const sendSMS = ({ creditToken }) => {
  const URI = `${API}/credit-client-cabinet/sms/application-signing`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { creditToken },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  getDetails,
  getDocuments,
  saveDocument,
  removeDocument,
  sendSMS,
};
