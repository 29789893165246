import API from 'services';

import { extractErrorAndCode, formatUserWithRole } from 'hacks';

import {
  all,
  takeLatest,
  put,
  fork,
  call,
  select,
  cancel,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  // list
  USER_FETCH_LIST,
  USER_FETCH_LIST__SUCCESS,
  USER_FETCH_LIST__FAILURE,
  USER_FETCH_LIST__CANCEL_REQUEST,
  USER_FETCH_LIST__SET_LOADING,
  USER_FETCH_LIST__SET_PAGINATION,
  USER_FETCH_LIST__SET_SEARCH,
  USER_FETCH_LIST__SET_PAGE,
  USER_FETCH_LIST__SET_SORTING,
  USER_FETCH_LIST__SET_FILTER,

  // item
  USER_FETCH_ITEM,
  USER_FETCH_ITEM__SUCCESS,
  USER_FETCH_ITEM__FAILURE,
  USER_CREATE_USER,
  USER_CREATE_USER__SUCCESS,
  USER_CREATE_USER__FAILURE,
  USER_PATCH,
  USER_PATCH__SUCCESS,
  USER_PATCH__FAILURE,
  USER_BLOCK_USER,
  USER_BLOCK_USER__SUCCESS,
  USER_BLOCK_USER__FAILURE,
  USER_UNBLOCK_USER,
  USER_UNBLOCK_USER__SUCCESS,
  USER_UNBLOCK_USER__FAILURE,
  USER_RESTORE_PASSWORD_USER,
  USER_RESTORE_PASSWORD__SUCCESS,
  USER_RESTORE_PASSWORD__FAILURE,

  // roles
  USER_FETCH_ROLES,
  USER_FETCH_ROLES__SUCCESS,
  USER_FETCH_ROLES__FAILURE,
  USER_FETCH_AVAILABLE_ROLES,
  USER_FETCH_AVAILABLE_ROLES__SUCCESS,
  USER_FETCH_AVAILABLE_ROLES__FAILURE,
  USER_APPROVE_ROLE,
  USER_APPROVE_ROLE__SUCCESS,
  USER_APPROVE_ROLE__FAILURE,
  USER_DISAPPROVE_ROLE,
  USER_DISAPPROVE_ROLE__SUCCESS,
  USER_DISAPPROVE_ROLE__FAILURE,

  // activity
  USER_ACTIVITY,
  USER_ACTIVITY__SUCCESS,
  USER_ACTIVITY__FAILURE,

  USER_CHECK_EMAIL,
  USER_CHECK_EMAIL__SUCCESS,
  USER_CHECK_EMAIL__FAILURE,

  USER_DOWNLOAD_LIST_FILE,
  USER_DOWNLOAD_LIST_FILE__SUCCESS,
  USER_DOWNLOAD_LIST_FILE__FAILURE,
} from './actions';

const getState = (state) => state.user;

function* _fetchList() {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);

  try {
    yield put({ type: USER_FETCH_LIST__SET_LOADING });
    const response = yield call(API.user.fetchList, {
      search,
      page,
      filters,
      sorting,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.userList))
    ) {
      yield put({
        type: USER_FETCH_LIST__SUCCESS,
        list: formatUserWithRole(response.data.userList),
        pagination: response.pagination,
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: USER_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(USER_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(USER_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(USER_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: USER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: USER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: USER_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(USER_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: USER_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: USER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: USER_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(USER_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: USER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: USER_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(USER_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: USER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: USER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: USER_FETCH_LIST });
  });
}

export function* createUser() {
  yield takeLatest(USER_CREATE_USER, function* ({
    email,
    login,
    phone,
    fullName,
    isInternal,
    roleId,
    merchantId,
    dealerCenterId,
    userType,
  }) {
    try {
      const response = yield call(API.user.createUser, {
        email,
        login,
        phone,
        fullName,
        isInternal,
        roleId,
        merchantId,
        dealerCenterId,
        userType,
      });

      if (
        response
        && response.data
        && response.data.userList
        && Array.isArray(response.data.userList)
        && response.data.userList.length === 1
      ) {
        yield put({ type: USER_CREATE_USER__SUCCESS, data: response.data.userList[0] });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: USER_CREATE_USER__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: USER_CREATE_USER__FAILURE, error: error.message });
    }
  });
}

export function* updateUser() {
  yield takeLatest(USER_PATCH, function* ({ fullName, email, phone }) {
    const {
      item: { data },
    } = yield select(getState);

    const { id } = data;
    try {
      const response = yield call(API.user.updateUser, {
        id,
        fullName,
        email,
        phone,
      });

      if (
        response
        && response.data
        && response.data.userList
        && Array.isArray(response.data.userList)
        && response.data.userList.length === 1
      ) {
        yield put({
          type: USER_PATCH__SUCCESS,
          data: response.data.userList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: USER_PATCH__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: USER_PATCH__FAILURE, error: error.message });
    }
  });
}

export function* fetchItem() {
  yield takeLatest(USER_FETCH_ITEM, function* ({ userId }) {
    try {
      const response = yield call(API.user.fetchItem, { userId });

      if (
        response
        && response.data
        && response.data.userList
        && Array.isArray(response.data.userList)
        && response.data.userList.length === 1
      ) {
        yield put({ type: USER_FETCH_ITEM__SUCCESS, data: response.data.userList[0] });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_FETCH_ITEM__FAILURE, error: error.message });
    }
  });
}

export function* fetchRoles() {
  yield takeLatest(USER_FETCH_ROLES, function* ({ userId }) {
    try {
      const response = yield call(API.user.fetchRoles, { userId });

      if (
        Array.isArray(response.data)
        || (response.data && Array.isArray(response.data.accessItemResponseList))
      ) {
        yield put({
          type: USER_FETCH_ROLES__SUCCESS,
          list: response.data.accessItemResponseList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_FETCH_ROLES__FAILURE, error: error.message });
    }
  });
}

export function* fetchAvailableRoles() {
  yield takeLatest(USER_FETCH_AVAILABLE_ROLES, function* ({ userId }) {
    try {
      const response = yield call(API.user.fetchAvailableRoles, { userId });

      if (
        Array.isArray(response.data)
        || (response.data && Array.isArray(response.data.roleList))
      ) {
        yield put({
          type: USER_FETCH_AVAILABLE_ROLES__SUCCESS,
          list: response.data.roleList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_FETCH_AVAILABLE_ROLES__FAILURE, error: error.message });
    }
  });
}

export function* approveRole() {
  yield takeLatest(USER_APPROVE_ROLE, function* ({
    userId,
    merchantId,
    dealerCenterId,
    roleId,
    isInternal,
  }) {
    try {
      const response = yield call(API.user.approveRole, {
        userId,
        merchantId,
        dealerCenterId,
        roleId,
        isInternal,
      });

      if (
        response
        && ((Array.isArray(response.data) && response.data.length === 0)
          || (response.data && response.data.id))
      ) {
        yield put({
          type: USER_APPROVE_ROLE__SUCCESS,
          data: { merchantId, dealerCenterId, roleId },
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: USER_APPROVE_ROLE__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: USER_APPROVE_ROLE__FAILURE, error: error.message });
    }
  });
}

export function* disapproveRole() {
  yield takeEvery(USER_DISAPPROVE_ROLE, function* ({ id }) {
    try {
      const response = yield call(API.user.disapproveRole, { id });

      if (response && response.data && response.data.status === 'ok') {
        yield put({ type: USER_DISAPPROVE_ROLE__SUCCESS, id });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_DISAPPROVE_ROLE__FAILURE, id, error: error.message });
    }
  });
}

export function* blockUser() {
  yield takeLatest(USER_BLOCK_USER, function* ({ userId }) {
    try {
      const response = yield call(API.user.blockUser, { userId });

      if (response && response.data && !response.errorMessage) {
        yield put({ type: USER_BLOCK_USER__SUCCESS, data: { succes: true } });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (response && response.data && response.error) {
          yield put({ type: USER_BLOCK_USER__FAILURE, error });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: USER_BLOCK_USER__FAILURE, error: error.message });
    }
  });
}

export function* unblockUser() {
  yield takeLatest(USER_UNBLOCK_USER, function* ({ userId }) {
    try {
      const response = yield call(API.user.unblockUser, { userId });

      if (response && response.data && !response.errorMessage) {
        yield put({ type: USER_UNBLOCK_USER__SUCCESS, data: { succes: true } });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (response && response.data && response.error) {
          yield put({ type: USER_UNBLOCK_USER__FAILURE, error });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: USER_UNBLOCK_USER__FAILURE, error: error.message });
    }
  });
}

export function* restoreUserPassword() {
  yield takeLatest(USER_RESTORE_PASSWORD_USER, function* ({ userId }) {
    try {
      const response = yield call(API.user.restoreUserPassword, { userId });

      if (response && response.data && !response.errorMessage) {
        yield put({ type: USER_RESTORE_PASSWORD__SUCCESS, data: { succes: true } });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (response && response.data && response.error) {
          yield put({ type: USER_RESTORE_PASSWORD__FAILURE, error });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: USER_RESTORE_PASSWORD__FAILURE, error: error.message });
    }
  });
}

export function* sendUserActivity() {
  yield takeLatest(USER_ACTIVITY, function* () {
    const {
      activity: { creditRequestAccessToken },
    } = yield select(getState);

    try {
      const response = yield call(API.user.callUserActivity, { creditRequestAccessToken });

      if (response && response.data && !response.errorMessage) {
        yield put({ type: USER_ACTIVITY__SUCCESS, data: { success: true } });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_ACTIVITY__FAILURE, error: error.message });
    }
  });
}

export function* checkUserEmail() {
  yield takeLatest(USER_CHECK_EMAIL, function* ({ email, merchantId }) {
    try {
      const response = yield call(API.user.checkEmail, { email, merchantId });

      if (response && response.data && !response.errorMessage) {
        yield put({ type: USER_CHECK_EMAIL__SUCCESS, data: { success: true } });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_CHECK_EMAIL__FAILURE, error: error.message });
    }
  });
}

export function* downloadListFile() {
  yield takeLatest(USER_DOWNLOAD_LIST_FILE, function* () {
    const {
      collection: {
        page,
        filters,
        sorting,
      },
    } = yield select(getState);
    try {
      const response = yield call(API.user.downloadLisUsers, {
        filters,
        page,
        sorting,
      });

      if (
        response
        && response.data
        && !response.errorMessage
        && Array.isArray(response.data.userListExportResponseList)
      ) {
        yield put({
          type: USER_DOWNLOAD_LIST_FILE__SUCCESS,
          data: response.data.userListExportResponseList[0] || {},
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: USER_DOWNLOAD_LIST_FILE__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(createUser),
    fork(updateUser),
    fork(fetchItem),
    fork(fetchRoles),
    fork(approveRole),
    fork(disapproveRole),
    fork(blockUser),
    fork(unblockUser),
    fork(restoreUserPassword),
    fork(fetchAvailableRoles),
    fork(sendUserActivity),
    fork(checkUserEmail),
    fork(downloadListFile),
  ]);
}
