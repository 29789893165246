import React from 'react';
import styled from 'styled-components';

const TextAreaWithSuffix = styled(({ suffix, ...rest }) => <div {...rest} />)`
  position: relative;

  & .styledInput {
    ${({ suffix }) => suffix && `
      padding-right: 40px;
    `}
  }
`;

const TextAreaSuffix = styled.div`
  position: absolute;
  display: inline-block;
  right: 12px;
  font-size: 16px;
`;

export { TextAreaWithSuffix, TextAreaSuffix };
