import styled from 'styled-components';
import { Button } from 'antd';

const Root = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 4rem;
`;

const Content = styled.div`
  padding: 1rem;
  height: 100%;
  overflow: auto;
`;

const Footer = styled.footer`
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadButton = styled(Button)``;

export {
  Root, Content, Footer, UploadButton,
};
