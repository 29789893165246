export const baseURL = process.env.PUBLIC_URL || '';

const postfix = '/v1';

export const IS_ACTIVE_DIRECTORY = process.env.REACT_APP_IS_ACTIVE_DIRECTORY === 'true';
const URL = `${window.location.protocol}//${window.location.host}`;

const DEVELOPER_MODE = true;
const REACT_APP_API_URL = 'REACT_APP_API_URL';
const REACT_APP_API_BUTLER_URL = 'REACT_APP_API_BUTLER_URL';
const REACT_APP_TOYOTA_URL = 'REACT_APP_TOYOTA_URL';
const REACT_APP_TCM_API = 'REACT_APP_TCM_API';

export const TCM_MANUAL_URL = process.env.REACT_APP_TCM_MANUAL_URL || false;

export const TOYOTA_URL = (
  (DEVELOPER_MODE && localStorage.getItem(REACT_APP_TOYOTA_URL))
  || `${process.env.REACT_APP_TOYOTA_URL || URL}${postfix}`
);

export const API_URL = (
  (DEVELOPER_MODE && localStorage.getItem(REACT_APP_API_URL))
  || `${process.env.REACT_APP_API_URL || URL}${postfix}`
);

export const API_BUTLER_URL = (
  (DEVELOPER_MODE && localStorage.getItem(REACT_APP_API_BUTLER_URL))
  || `${process.env.REACT_APP_API_BUTLER_URL || URL}${postfix}`
);

export const API_TCM_URL = (
  (DEVELOPER_MODE && localStorage.getItem(REACT_APP_TCM_API))
    || `${process.env.REACT_APP_TCM_API || URL}${postfix}`
);
