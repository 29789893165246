import React from 'react';
import Slider from 'components/Slider';
import InputNumber from 'components/InputNumber';

function InputSlider({
  name,
  placeholder,
  value,
  min,
  max,
  disabled,
  onBlur,
  onChange,
  size,
}) {
  function handleChange(v) {
    onChange(v < min ? min : v > max ? max : v);
  }

  return (
    <>
      <InputNumber
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        size={size}
      />
      <Slider
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        disabled={disabled}
        onBlur={onBlur}
      />
    </>
  );
}

export default InputSlider;
