import styled from 'styled-components';

const Root = styled.div`
  font-size: 1rem;
  padding: 1rem;
  background: #f5f5f5;
  border: 1px solid #333;
  border-radius: 6px;
  margin-top: 1rem;
`;

export { Root };
