import { getToken } from 'helpers/utility';
import { defaultSorting, defaultPageSize } from 'redux/table/reducer';
import { API_URL as API } from 'settings/api';
import { formatFilters } from 'hacks';
import { v1 as getId } from 'uuid';

export const fetchData = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
}) => {
  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
  };

  const URI = `${API}/creditrequest/user/search`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: defaultPageSize,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchData,
};
