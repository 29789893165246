import styled from 'styled-components';
import { Button } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

export const Root = styled(Button)`
  margin: 0;
  font-size: 0.8rem;
  border: none;
  background: #c9c9c9;
  padding: 0.4rem 1rem;

  &:hover {
    background: #8c8c8c;
    color: rgba(0, 0, 0, 0.65);
  }
  &:focus {
    background: #8c8c8c;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const ModalBody = styled.div``;

export const ModalTitle = styled.h1`
  font-size: 20px;
`;

export const ModalContent = styled.p`
  color: gray;
 
  textarea {
    resize: none;
  }
`;

export const ModalLink = styled.a``;

export const Loader = styled(LoadingOutlined)`
  font-size: 1.2rem;
  padding-right: 10px;
`;

export const Error = styled.p``;

export const ErrorMessage = styled.span`
  color: red;
`;
