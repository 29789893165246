import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const Wrapper = withTheme(styled.div`
  margin: 45px 0;
`);

export const Title = withTheme(styled.div`
  margin-bottom: 40px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
`);

export const Container = withTheme(styled.div`
  padding: 40px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.gray7};
  margin-bottom: 40px;
  
  .radio-group {
     grid-template-columns: ${({ countButton }) => (countButton ? `repeat(${countButton}, 1fr)` : 'repeat(2, 1fr)')};
     .ant-radio-button-wrapper {
        overflow: hidden;
        height: auto;
     }
  }
`);

export const Row = styled.div`
  padding: 37px 0 30px 0;
  border-top: 1px solid #E3E3E3;
  display: grid;
  column-gap: 30px;
  grid-template-columns: 1fr 1.2fr 1fr 1fr;
`;

export const RowSliderBlocks = styled.div`
  margin-top: 30px;
  margin-bottom: 52px;
  display: grid;
  column-gap: 30px;
  grid-template-columns: 1fr 1.2fr 1fr 1fr;
`;

export const ChangeCar = styled.div`
  padding: 8px 0 60px 0;
  display: grid;
  column-gap: 30px;
  grid-template-columns: 1fr 1.2fr 1fr 1fr;
  height: 145px
`;

export const CurrentCar = styled.div`
  height: 145px
`;

export const CarTitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: baseline;
  column-gap: 10px;
  margin-bottom: 21px;
`;

export const Brand = withTheme(styled.div`
font-family: ${({ theme }) => theme.toyotaFontFamily};
font-size: 24px;
line-height: 25px;
color: ${({ theme }) => theme.palette.black};
`);

export const ModelSuffix = withTheme(styled.span`
font-family: ${({ theme }) => theme.fontFamily};
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color:  ${({ theme }) => theme.palette.black};
`);

export const FieldTitle = withTheme(styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const FieldValue = withTheme(styled.div`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
`);

export const Suffix = withTheme(styled.span`
  color: ${({ theme }) => theme.palette.gray3};
  font-size: 18px;
`);
