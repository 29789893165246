import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { Table, Popconfirm } from 'antd';
import { useLocation } from 'react-router-dom';
import { checkPrivilege } from 'helpers/roles';
import { checkIsIternalUser } from 'hacks/formatUsers';

import {
  DeleteOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import {
  fetchList as fetchCompanyListAction,
  reset as resetAction,
} from 'redux/company/actions';

import {
  fetchList as fetchRoleListAction,
} from 'redux/role/actions';

import {
  fetchRoles as fetchRolesAction,
  fetchAvailableRoles as fetchAvailableRolesAction,
  disapproveRole as disapproveRoleAction,
} from 'redux/user/actions';

import { TCM } from 'components/tcm';
import AddRole from './AddRole';

import {
  Root,
  Header,
  Title,
  Action,
  ButtonDelete,
  ErrorMessage,
  EmptyMessage,
} from './style';

const getColumns = ({ columns = [], role = '' }) => {
  if (Array.isArray(columns) && role) {
    return columns.filter((e) => e.checkRights(role));
  }
  return [];
};

function Roles({
  // passed
  userId,

  // connect
  role,
  roles,
  rolesIsLoading,
  rolesIsLoaded,
  rolesError,

  availableRoles,
  availableRolesIsLoading,
  availableRolesIsLoaded,
  availableRolesError,

  companyList,
  dealerCenterList,
  companyListIsLoading,
  companyListIsLoaded,
  companyListError,

  roleListError,

  userData,
  userIsLoaded,

  approveRoleIsLoaded,

  disapproveRoleIsLoaded,

  // action
  fetchRoles,
  fetchAvailableRoles,
  disapproveRole,
  fetchCompanyList,
  resetCompanyList,
  fetchRoleList,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const isEdit = location.pathname.endsWith('/edit');
  const [isUserInternal, setIsUserInternal] = useState(
    checkPrivilege(role.MANAGEMENT.ADD_ACTIVE_DIRECTORY_USER),
  );
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (userIsLoaded) {
      const { userType } = userData;
      const isInternal = checkIsIternalUser(userType);
      setIsUserInternal(isInternal);
    }
  }, [userIsLoaded, userData]);

  /* поочередно загружаем три списка: роли пользвоателя, компании и дилерские центры, общий список ролей */
  useEffect(() => {
    if (userId) {
      fetchRoles({ userId });
    }
  }, [fetchRoles, userId]);

  useEffect(() => {
    if (disapproveRoleIsLoaded) {
      fetchRoles({ userId });
    }
  }, [fetchRoles, userId, disapproveRoleIsLoaded]);

  useEffect(() => {
    if (approveRoleIsLoaded) {
      fetchRoles({ userId });
    }
  }, [fetchRoles, approveRoleIsLoaded, userId]);

  useEffect(() => {
    fetchRoleList();
  }, [fetchRoleList]);
  /* end of sequential loading */

  useEffect(() => {
    if (rolesIsLoaded) {
      fetchAvailableRoles({ userId });
    }
  }, [rolesIsLoaded, fetchAvailableRoles, userId]);

  function removeRole(id) {
    disapproveRole({ id });
  }

  const locale = {
    emptyText: rolesIsLoading ? (
      <TCM.Loader text="Идет загрузка списка ролей сотрудников..." />
    ) : rolesError ? (
      <ErrorMessage>{rolesError}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const COLUMNS = [
    {
      key: 'merchantName',
      title: 'Организация',
      dataIndex: '',
      checkRights: () => true,
      render: ({ merchantName, dealerCenterId }) => (
        <span>
          {
            merchantName
            || (
              ((dealerCenterList.find((e) => e.id === dealerCenterId) || {}).organisation || {}).name
            )
            || '-'
          }
        </span>
      ),
      width: 200,
    },
    {
      key: 'dealerCenterName',
      title: 'Точка продаж',
      dataIndex: 'dealerCenterName',
      checkRights: () => true,
      render: (dealerCenterName) => (
        <span>
          {dealerCenterName || '-'}
        </span>
      ),
      width: 200,
    },
    {
      key: 'roleName',
      title: 'Роль',
      dataIndex: 'roleName',
      checkRights: () => true,
      render: (roleName) => (
        <span>{(roleName || '-')}</span>
      ),
      width: 200,
    },
    {
      key: 'id',
      title: '',
      dataIndex: '',
      // ЭТО БЫЛО СДЕЛАНО ПОТОМУ, ЧТО УДАЛЯТЬ РОЛИ МОЖЕТ ТОЛЬКО ТОТ КТО ИХ НАЗНАЧАЕТ (ТАК СКАЗАЛИ НА БЭКЕ)
      checkRights: (role) => checkPrivilege((isUserInternal
        ? role.MANAGEMENT.ASSIGN_ROLE_TO_ACTIVE_DIRECTORY_USERS
        : role.MANAGEMENT.ASSIGN_ROLE_TO_WEB_USERS)) && isEdit,
      render: ({ id, isLoading, error }) =>
        id && (
          <Action>
            <Popconfirm
              title="Вы уверены, что хотите выполнить удаление?"
              icon={<WarningOutlined />}
              onConfirm={() => removeRole(id)}
              onCancel={null}
              okButtonProps={{ danger: true }}
              okText="Да, удалить"
              cancelText="Нет"
            >
              <ButtonDelete>
                {isLoading ? <LoadingOutlined /> : <DeleteOutlined />}
                удалить
              </ButtonDelete>
            </Popconfirm>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Action>
        ),
      width: 140,
    },
  ];

  const columns = getColumns({ columns: COLUMNS, role });
  const nestedListsError = companyListError || roleListError;

  const canShowAddRoleButton = userIsLoaded
    && ((isUserInternal
      && checkPrivilege(role.MANAGEMENT.ASSIGN_ROLE_TO_ACTIVE_DIRECTORY_USERS))
      || (!isUserInternal && checkPrivilege(role.MANAGEMENT.ASSIGN_ROLE_TO_WEB_USERS)));

  return (
    <Root>
      <Header>
        <Title>Роли</Title>
        {canShowAddRoleButton && isEdit && (
          <Button onClick={openModal} size="mini" type="link" shape="square">
            Добавить роль
          </Button>
        )}
      </Header>

      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={roles}
        locale={locale}
        pagination={false}
      />

      {nestedListsError && <ErrorMessage>{nestedListsError}</ErrorMessage>}

      <AddRole
        role={role}
        close={closeModal}
        resetCompanyList={resetCompanyList}
        fetchCompanyList={fetchCompanyList}
        companyList={companyList}
        companyListIsLoading={companyListIsLoading}
        companyListIsLoaded={companyListIsLoaded}
        companyListError={companyListError}
        roleList={availableRoles}
        roleListIsLoading={availableRolesIsLoading}
        roleListIsLoaded={availableRolesIsLoaded}
        roleListError={availableRolesError}
        userId={userId}
        visible={isModalOpen}
        isUserInternal={isUserInternal}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    roles: state.user.roles.list,
    rolesIsLoading: state.user.roles.isLoading,
    rolesIsLoaded: state.user.roles.isLoaded,
    rolesError: state.user.roles.error,

    availableRoles: state.user.availableRoles.list,
    availableRolesIsLoading: state.user.availableRoles.isLoading,
    availableRolesIsLoaded: state.user.availableRoles.isLoaded,
    availableRolesError: state.user.availableRoles.error,

    companyList: state.company.collection.list,
    dealerCenterList: state.company.collection.dealerCenterList,
    companyListIsLoading: state.company.collection.isLoading,
    companyListIsLoaded: state.company.collection.isLoaded,
    companyListError: state.company.collection.error,

    roleList: state.role.list,
    roleListIsLoading: state.role.isLoading,
    roleListIsLoaded: state.role.isLoaded,
    roleListError: state.role.error,

    userData: state.user.item.data,
    userIsLoading: state.user.item.isLoading,
    userIsLoaded: state.user.item.isLoaded,

    approveRoleIsLoaded: state.user.approveRole.isLoaded,

    disapproveRoleIsLoaded: state.user.disapproveRole.isLoaded,
  }),
  {
    // user
    fetchRoles: fetchRolesAction,
    fetchAvailableRoles: fetchAvailableRolesAction,
    disapproveRole: disapproveRoleAction,

    fetchCompanyList: fetchCompanyListAction,
    resetCompanyList: resetAction,
    fetchRoleList: fetchRoleListAction,
  },
)(memo(Roles));
