import { parseDigitsWithSeparator as parseDigits } from 'utils';

export const KEYS = {
  PHONE: {
    key: 'PHONE',
    apiKey: 'phone',
    title: 'Телефон',
    rules: [
      () => ({
        validator(rule, value) {
          if (value) {
            return Promise.resolve();
          }
          return Promise.reject('Номер телефона не может быть пустым');
        },
      }),
      () => ({
        validator(rule, value) {
          if (typeof value === 'string' && parseDigits(value).length === 10) {
            return Promise.resolve();
          }
          return Promise.reject('Номер телефона должен содержать 10 цифр');
        },
      }),
    ],
  },
};
