import React from 'react';

import { Route, Switch } from 'react-router-dom';

import StartPage from './StartPage';
import App from './App';

function Root() {
  return (
    <Switch>
      <Route exact path="/" component={StartPage} />
      <Route path="/app" component={App} />
    </Switch>
  );
}

export default Root;
