import {
  // list
  USER_FETCH_LIST,
  USER_FETCH_LIST__SUCCESS,
  USER_FETCH_LIST__FAILURE,
  USER_FETCH_LIST__SET_LOADING,
  USER_FETCH_LIST__SET_PAGINATION,
  USER_FETCH_LIST__SET_SEARCH,
  USER_FETCH_LIST__SET_SORTING,
  USER_FETCH_LIST__SET_FILTER,
  USER_FETCH_LIST__RESET,

  // item
  USER_FETCH_ITEM,
  USER_FETCH_ITEM__SUCCESS,
  USER_FETCH_ITEM__FAILURE,
  USER_CREATE_USER,
  USER_CREATE_USER__SUCCESS,
  USER_CREATE_USER__FAILURE,
  USER_CREATE_USER__RESET,
  USER_PATCH,
  USER_PATCH__SUCCESS,
  USER_PATCH__FAILURE,
  USER_BLOCK_USER,
  USER_BLOCK_USER__SUCCESS,
  USER_BLOCK_USER__FAILURE,
  USER_BLOCK_USER__RESET,
  USER_UNBLOCK_USER,
  USER_UNBLOCK_USER__SUCCESS,
  USER_UNBLOCK_USER__FAILURE,
  USER_UNBLOCK_USER__RESET,
  USER_RESTORE_PASSWORD_USER,
  USER_RESTORE_PASSWORD__SUCCESS,
  USER_RESTORE_PASSWORD__FAILURE,
  USER_RESTORE_PASSWORD_USER__RESET,

  // roles
  USER_FETCH_ROLES,
  USER_FETCH_ROLES__SUCCESS,
  USER_FETCH_ROLES__FAILURE,
  USER_FETCH_AVAILABLE_ROLES,
  USER_FETCH_AVAILABLE_ROLES__SUCCESS,
  USER_FETCH_AVAILABLE_ROLES__FAILURE,
  USER_APPROVE_ROLE,
  USER_APPROVE_ROLE__SUCCESS,
  USER_APPROVE_ROLE__FAILURE,
  USER_APPROVE_ROLE__RESET,
  USER_DISAPPROVE_ROLE,
  USER_DISAPPROVE_ROLE__SUCCESS,
  USER_DISAPPROVE_ROLE__FAILURE,

  // activity
  USER_ACTIVITY,
  USER_ACTIVITY__SUCCESS,
  USER_ACTIVITY__FAILURE,
  USER_ACTIVITY_SET_CREDIT_TOKEN,
  USER_ACTIVITY_REMOVE_CREDIT_TOKEN,

  USER_CHECK_EMAIL,
  USER_CHECK_EMAIL__SUCCESS,
  USER_CHECK_EMAIL__FAILURE,
  USER_CHECK_EMAIL__RESET,

  // reset
  USER_RESET_STORE,

  USER_DOWNLOAD_LIST_FILE,
  USER_DOWNLOAD_LIST_FILE__SUCCESS,
  USER_DOWNLOAD_LIST_FILE__FAILURE,
  USER_DOWNLOAD_LIST_FILE__RESET, USER_PATCH__RESET,

} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'displayName',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {};

const roleDraft = {
  isLoading: false,
  error: null,
};

const formatRoles = (list) => {
  if (Array.isArray(list)) {
    return list.map((e) => ({ ...e, ...roleDraft }));
  }
  return [];
};

const initState = {
  collection: {
    data: {},
    list: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
    pagination: {
      page: 1,
      pageSize: defaultPageSize,
      rowsCount: 0,
    },
  },

  item: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  create: {
    form: {},
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  update: {
    form: {},
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  block: {
    form: {},
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  unblock: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  restorePassword: {
    form: {},
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  roles: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  availableRoles: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  approveRole: {
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  activity: {
    data: {},
    creditRequestAccessToken: '',
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  disapproveRole: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  checkEmail: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  downloadUsersList: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case USER_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case USER_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case USER_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case USER_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case USER_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case USER_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case USER_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case USER_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,
          pagination: action.pagination,
          data: action.data,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case USER_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case USER_CREATE_USER: {
      return {
        ...state,

        create: {
          ...state.create,

          form: {
            email: action.email,
            login: action.login,
            phone: action.phone,
            fullName: action.fullName,
            isInternal: action.isInternal,
          },
          isLoading: true,
        },
      };
    }
    case USER_CREATE_USER__SUCCESS: {
      return {
        ...state,

        create: {
          ...state.create,

          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case USER_CREATE_USER__FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,

          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }
    case USER_CREATE_USER__RESET: {
      return {
        ...state,

        create: {
          ...initState.create,
        },
      };
    }

    case USER_PATCH__RESET: {
      return {
        ...state,

        update: {
          ...state.create,
          ...initState.update,
        },
      };
    }
    case USER_PATCH: {
      return {
        ...state,

        update: {
          ...state.create,

          form: {
            email: action.email,
            phone: action.phone,
            fullName: action.fullName,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case USER_PATCH__SUCCESS: {
      return {
        ...state,

        update: {
          ...state.create,

          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case USER_PATCH__FAILURE: {
      return {
        ...state,

        update: {
          ...state.create,

          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case USER_BLOCK_USER: {
      return {
        ...state,

        block: {
          ...initState.block,
          isLoading: true,
        },
      };
    }
    case USER_BLOCK_USER__SUCCESS: {
      return {
        ...state,

        block: {
          ...state.block,

          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case USER_BLOCK_USER__FAILURE: {
      return {
        ...state,

        block: {
          ...state.block,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case USER_BLOCK_USER__RESET: {
      return {
        ...state,

        block: {
          ...initState.block,
        },
      };
    }

    case USER_UNBLOCK_USER: {
      return {
        ...state,

        unblock: {
          ...initState.unblock,
          isLoading: true,
        },
      };
    }
    case USER_UNBLOCK_USER__SUCCESS: {
      return {
        ...state,

        unblock: {
          ...state.unblock,

          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case USER_UNBLOCK_USER__FAILURE: {
      return {
        ...state,

        unblock: {
          ...state.unblock,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case USER_UNBLOCK_USER__RESET: {
      return {
        ...state,

        unblock: {
          ...initState.unblock,
        },
      };
    }

    case USER_RESTORE_PASSWORD_USER: {
      return {
        ...state,

        restorePassword: {
          ...initState.restorePassword,
          isLoading: true,
        },
      };
    }
    case USER_RESTORE_PASSWORD__SUCCESS: {
      return {
        ...state,

        restorePassword: {
          ...state.restorePassword,

          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case USER_RESTORE_PASSWORD__FAILURE: {
      return {
        ...state,

        restorePassword: {
          ...state.restorePassword,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case USER_RESTORE_PASSWORD_USER__RESET: {
      return {
        ...state,

        restorePassword: {
          ...initState.restorePassword,
        },
      };
    }

    case USER_FETCH_ITEM: {
      return {
        ...state,

        item: {
          ...initState.item,
          isLoading: true,
        },
      };
    }
    case USER_FETCH_ITEM__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case USER_FETCH_ITEM__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case USER_FETCH_ROLES: {
      return {
        ...state,

        roles: {
          ...initState.roles,

          isLoading: true,
        },
      };
    }

    case USER_FETCH_ROLES__SUCCESS: {
      return {
        ...state,

        roles: {
          ...state.roles,

          list: formatRoles(action.list),
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case USER_FETCH_ROLES__FAILURE: {
      return {
        ...state,

        roles: {
          ...state.roles,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case USER_FETCH_AVAILABLE_ROLES: {
      return {
        ...state,

        availableRoles: {
          ...initState.availableRoles,

          isLoading: true,
        },
      };
    }
    case USER_FETCH_AVAILABLE_ROLES__SUCCESS: {
      return {
        ...state,

        availableRoles: {
          ...state.availableRoles,

          list: formatRoles(action.list),
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case USER_FETCH_AVAILABLE_ROLES__FAILURE: {
      return {
        ...state,

        availableRoles: {
          ...state.availableRoles,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case USER_APPROVE_ROLE: {
      return {
        ...state,

        approveRole: {
          ...state.initState,

          form: {
            userId: action.userId,
            merchantId: action.merchantId,
            dealerCenterId: action.dealerCenterId,
            roleId: action.roleId,
          },
          isLoading: true,
        },
      };
    }
    case USER_APPROVE_ROLE__SUCCESS: {
      return {
        ...state,

        approveRole: {
          ...state.approveRole,

          isLoading: false,
          isLoaded: true,
        },

        // save new role to the `roles`` list
        roles: {
          ...state.roles,

          list: [
            ...state.roles.list,
            {
              ...action.data,
              ...roleDraft,
            },
          ],
        },
      };
    }
    case USER_APPROVE_ROLE__FAILURE: {
      return {
        ...state,

        approveRole: {
          ...state.approveRole,

          isLoading: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }
    case USER_APPROVE_ROLE__RESET: {
      return {
        ...state,

        approveRole: {
          ...initState.approveRole,
        },
      };
    }

    case USER_DISAPPROVE_ROLE: {
      return {
        ...state,

        roles: {
          ...state.roles,

          list: state.roles.list.map((e) =>
            (e.id === action.id
              ? {
                ...roleDraft,
                ...e,
                isLoading: true,
                error: null,
              }
              : e)),
        },
        disapproveRole: {
          ...initState.disapproveRole,
          isLoading: true,
        },
      };
    }
    case USER_DISAPPROVE_ROLE__SUCCESS: {
      return {
        ...state,

        roles: {
          ...state.roles,

          list: state.roles.list.filter((e) => e.id !== action.id),
        },

        disapproveRole: {
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case USER_DISAPPROVE_ROLE__FAILURE: {
      return {
        ...state,

        roles: {
          ...state.roles,

          list: state.roles.list.map((e) =>
            (e.id === action.id ? { ...e, isLoading: false, error: action.error } : e)),
        },

        disapproveRole: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case USER_ACTIVITY: {
      return {
        ...state,

        activity: {
          ...initState.activity,
          creditRequestAccessToken: state.activity.creditRequestAccessToken,
          isLoading: true,
        },
      };
    }
    case USER_ACTIVITY__SUCCESS: {
      return {
        ...state,

        activity: {
          ...state.activity,
          data: action.data,
          isLoaded: true,
          isLoading: false,
        },
      };
    }
    case USER_ACTIVITY__FAILURE: {
      return {
        ...state,

        activity: {
          ...state.activity,
          error: action.error,
          isLoading: false,
        },
      };
    }

    case USER_ACTIVITY_SET_CREDIT_TOKEN: {
      return {
        ...state,

        activity: {
          ...state.activity,
          creditRequestAccessToken: action.creditRequestAccessToken,
        },
      };
    }

    case USER_ACTIVITY_REMOVE_CREDIT_TOKEN: {
      return {
        ...state,

        activity: {
          ...state.activity,
          creditRequestAccessToken: '',
        },
      };
    }

    case USER_RESET_STORE: {
      return {
        ...state,
        item: {
          ...initState.item,
        },
        update: {
          ...initState.update,
        },
        create: {
          ...initState.create,
        },
        block: {
          ...initState.block,
        },
        unblock: {
          ...initState.unblock,
        },
        approveRole: {
          ...initState.approveRole,
        },
        roles: {
          ...initState.roles,
        },
      };
    }

    case USER_CHECK_EMAIL: {
      return {
        ...state,

        checkEmail: {
          ...initState.checkEmail,
          isLoading: true,
        },
      };
    }
    case USER_CHECK_EMAIL__SUCCESS: {
      return {
        ...state,

        checkEmail: {
          ...state.checkEmail,
          isLoaded: true,
          isLoading: false,
        },
      };
    }
    case USER_CHECK_EMAIL__FAILURE: {
      return {
        ...state,

        checkEmail: {
          ...state.checkEmail,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case USER_CHECK_EMAIL__RESET: {
      return {
        ...state,

        checkEmail: {
          ...initState.checkEmail,
        },
      };
    }

    case USER_DOWNLOAD_LIST_FILE: {
      return {
        ...state,

        downloadUsersList: {
          ...initState.downloadUsersList,
          isLoading: true,
        },
      };
    }
    case USER_DOWNLOAD_LIST_FILE__SUCCESS: {
      return {
        ...state,

        downloadUsersList: {
          ...state.downloadUsersList,
          data: action.data,
          isLoaded: true,
          isLoading: false,
        },
      };
    }
    case USER_DOWNLOAD_LIST_FILE__FAILURE: {
      return {
        ...state,

        downloadUsersList: {
          ...state.downloadUsersList,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case USER_DOWNLOAD_LIST_FILE__RESET: {
      return {
        ...state,

        downloadUsersList: {
          ...initState.downloadUsersList,
        },
      };
    }

    default: {
      return state;
    }
  }
}
