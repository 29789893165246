export const formFields = {
  dateFrom: {
    key: 'dateFrom',
    apiKey: 'dateFrom',
    label: 'Период обзвона с',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.isValid) {
            return Promise.resolve();
          }
          return Promise.reject('Дата не может быть пустой');
        },
      }),
    ],
  },
  dateTo: {
    key: 'dateTo',
    apiKey: 'dateTo',
    label: 'до',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.isValid) {
            return Promise.resolve();
          }
          return Promise.reject('Дата не может быть пустой');
        },
      }),
    ],
  },
  dateRange: {
    key: 'dateRange',
    apiKey: 'dateRange',
  },
};

export const DateRangePresets = {
  allTime: {
    value: 'allTime',
    label: 'Всё время',
  },
  year: {
    value: 'year',
    label: 'Год',
  },
  halfAYear: {
    value: 'halfAYear',
    label: 'Пол года',
  },
  quarter: {
    value: 'quarter',
    label: 'Квартал',
  },
  month: {
    value: 'month',
    label: 'Месяц',
  },
};
