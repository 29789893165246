import React from 'react';

import { Form, Input } from 'antd';

import {
  FormItemsContainer,
  FormItemsRow,
} from '../style';

import { KEYS as K } from '../data';

function RequisitesItems({
    // passed
  handleFocus,
  handleBlur,
}) {
  return (
    <>
      <FormItemsContainer>
        <FormItemsRow>
          <Form.Item
            name={K.REQUISITES_BANK.key}
            label={K.REQUISITES_BANK.title}
            rules={K.REQUISITES_BANK.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.REQUISITES_BANK.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.REQUISITES_CORRESPONDENT_BILL.key}
            label={K.REQUISITES_CORRESPONDENT_BILL.title}
            rules={K.REQUISITES_CORRESPONDENT_BILL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.REQUISITES_CORRESPONDENT_BILL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.REQUISITES_CALCULATED_BILL.key}
            label={K.REQUISITES_CALCULATED_BILL.title}
            rules={K.REQUISITES_CALCULATED_BILL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.REQUISITES_CALCULATED_BILL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
    </>
  );
}

export default RequisitesItems;
