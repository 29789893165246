import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Popconfirm, Table } from 'antd';
import { useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import {
  fetchList as fetchListAction,
  fileDelete as fileDeleteAction,
  fileDownload as fileDownloadAction,
  resetDownload as resetDownloadAction,
} from 'redux/files/actions';

import {
  fetchList as fetchCompanyListAction,
} from 'redux/company/actions';

import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import AddFile from './AddFile';
import {
  EmptyMessage,
  ErrorMessage,
  LoadingMessage,
  ActionContainers,
  ButtonDownload,
  ButtonDelete,
  HeaderTitle,
  Title,
  Row,
  RowContainer,
  ButtonDeleteContainer,
} from './style';

const scroll = { y: 'calc(100vh - 460px)' };
const showTotal = () => '';
const pageSize = 15;

const downloadURL = (fileUrl, fileName) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();
};

function Files({
  // stores
  list,
  isLoading,
  error,

  companyList,
  companyListIsLoading,
  companyListIsLoaded,
  companyListError,

  uploadIsLoaded,

  deleteIsLoading,
  deleteIsLoaded,
  deleteError,

  downloadData,
  downloadIsLoading,
  downloadIsLoaded,

  // actions
  fetchList,
  fetchCompanyList,
  fileDelete,
  fileDownload,
  resetDownload,
}) {
  const { companyId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [downloadId, setDownLoadId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  function goToPage(value) {
    setPage(value);
  }

  function handleDownloadFile(id, fileName) {
    fileDownload({ id, fileName });
    setDownLoadId(id);
  }
  function handleDeleteFile(id) {
    fileDelete({ id });
    setDeleteId(id);
  }

  const paginationObj = {
    current: page,
    pageSize,
    total: list.length,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };
  const COLUMNS = [
    {
      key: 'fileName',
      title: () =>
        TableHeader({
          title: 'Название',
        }),
      render: ({ fileName, createAt }) => (
        <RowContainer>
          <Row>
            {fileName}
          </Row>
          <Row>
            {moment(createAt).isValid() && moment(createAt).format('DD.MM.YYYY')}
          </Row>
        </RowContainer>
      ),
      width: 240,
    },
    {
      key: 'user',
      title: () =>
        TableHeader({
          title: 'Пользователь',
        }),
      render: ({ creatorName, subsidiary }) => (
        <RowContainer>
          <Row>
            {creatorName}
          </Row>
          <Row>
            {subsidiary}
          </Row>
        </RowContainer>
      ),
      width: 200,
    },
    {
      key: 'comment',
      title: () =>
        TableHeader({
          title: 'Комментарий',
        }),
      render: ({ comment }) => <Row>{comment}</Row>,
      width: 540,
    },
    {
      key: 'action',
      render: ({ id, fileName }) => (
        <ActionContainers>
          <ButtonDownload
            onClick={() => handleDownloadFile(id, fileName)}
            loading={downloadId === id && downloadIsLoading}
            disable={
              (downloadId !== id && downloadIsLoading)
                || (deleteIsLoading)
            }
          >
            Скачать
          </ButtonDownload>
          <Popconfirm
            title="Вы уверены, что хотите выполнить удаление?"
            icon={<WarningOutlined />}
            onConfirm={() => handleDeleteFile(id)}
            onCancel={null}
            okButtonProps={{ danger: true }}
            okText="Подтвердить"
            cancelText="Отмена"
          >
            <ButtonDeleteContainer>
              <ButtonDelete
                disable={
                  (downloadIsLoading)
                      || (deleteId !== id && deleteIsLoading)
                }
              >
                {deleteId === id && deleteIsLoading ? <LoadingOutlined /> : <DeleteOutlined />}
                Удалить
              </ButtonDelete>
              {deleteId === id && deleteError && <ErrorMessage>{deleteError}</ErrorMessage>}
            </ButtonDeleteContainer>
          </Popconfirm>

        </ActionContainers>
      ),
      width: 140,
    },
  ];

  useEffect(() => {
    if (companyId) {
      fetchList({ legalEntityId: Number(companyId) });
    }
  }, [fetchList, companyId]);

  useEffect(() => {
    if ((uploadIsLoaded || deleteIsLoaded) && companyId) {
      fetchList({ legalEntityId: Number(companyId) });

      if (deleteIsLoaded) {
        setDeleteId(null);
      }
    }
  }, [uploadIsLoaded, deleteIsLoaded, companyId, fetchList]);

  useEffect(() => {
    fetchCompanyList({ withPagination: false });
  }, [fetchCompanyList]);

  useEffect(() => {
    if (downloadIsLoaded) {
      setDownLoadId(null);
      const { documentFileList, fileName } = downloadData;
      const file = Array.isArray(documentFileList) && documentFileList[0]
        ? documentFileList[0]
        : {};
      downloadURL(file?.url, fileName);
    }
  }, [downloadData, downloadIsLoaded]);

  useEffect(() => () => resetDownload(), [resetDownload]);

  return (
    <>
      <HeaderTitle>
        <Title>
          Файлы
        </Title>
        <Button
          onClick={openModal}
          htmlType="button"
          type="primary"
          size="mini"
          shape="square"
        >
          Прикрепить файл
        </Button>
      </HeaderTitle>
      <Table
        rowKey={({ id }) => id}
        columns={COLUMNS}
        dataSource={list}
        bordered
        pagination={list.length > pageSize ? paginationObj : false}
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />

      <AddFile
        close={closeModal}
        companyList={companyList}
        companyListIsLoading={companyListIsLoading}
        companyListIsLoaded={companyListIsLoaded}
        companyListError={companyListError}
        visible={isModalOpen}
      />
    </>
  );
}

export default connect(
  (state) => ({
    list: state.files.collection.list,
    isLoaded: state.files.collection.isLoaded,
    isLoading: state.files.collection.isLoading,
    error: state.files.collection.error,

    uploadIsLoaded: state.files.upload.isLoaded,

    deleteIsLoading: state.files.delete.isLoading,
    deleteIsLoaded: state.files.delete.isLoaded,
    deleteError: state.files.delete.error,

    downloadData: state.files.download.data,
    downloadIsLoading: state.files.download.isLoading,
    downloadIsLoaded: state.files.download.isLoaded,

    companyList: state.company.collection.list,
    dealerCenterList: state.company.collection.dealerCenterList,
    companyListIsLoading: state.company.collection.isLoading,
    companyListIsLoaded: state.company.collection.isLoaded,
    companyListError: state.company.collection.error,
  }),
  {
    fetchList: fetchListAction,
    fetchCompanyList: fetchCompanyListAction,
    fileDelete: fileDeleteAction,
    fileDownload: fileDownloadAction,
    resetDownload: resetDownloadAction,
  },
)(Files);
