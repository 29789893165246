import React from 'react';
import { formatPhone } from 'utils';
import moment from 'moment';

import { DATE_FORMAT_WITH_TIME } from 'settings';

import { Status, Type } from './style';

export const CHANNEL = {
  PHONE: 'sms',
  EMAIL: 'email',
  LINK: 'link',
};

export const KEYS = {
  USER_NAME: {
    key: 'USER_NAME',
    apiKey: 'fullName',
    title: 'ФИО',
    isOptional: false,
    rules: [
      {
        required: true,
        message: 'Имя не может быть пустым',
      },
    ],
  },
  USER_POINT: {
    key: 'USER_POINT',
    apiKey: 'dealerId',
    title: 'Точка продаж',
    isOptional: false,
    rules: [],
  },
  USER_ORDER_SUM: {
    key: 'USER_ORDER_SUM',
    apiKey: 'amount',
    title: 'Сумма заказа',
    isOptional: false,
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },
  USER_ORDER: {
    key: 'USER_ORDER',
    apiKey: 'number',
    title: 'Номер заказа',
    isOptional: false,
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },
  USER_EMAIL: {
    key: 'USER_EMAIL',
    apiKey: 'email',
    title: 'Email',
    isOptional: false,
    rules: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (getFieldValue('channel') === CHANNEL.EMAIL) {
            if (value) {
              return Promise.resolve();
            }
            return Promise.reject('Емайл не может быть пустым');
          }
          return Promise.resolve();
        },
      }),
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (getFieldValue('channel') === CHANNEL.EMAIL) {
            if (typeof value === 'string' && /[a-z0-9]{1,}@[a-z0-9]{1,}/gi.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject('Введите существующий емайл');
          }
          return Promise.resolve();
        },
      }),
    ],
  },
  USER_PHONE: {
    key: 'USER_PHONE',
    apiKey: 'phone',
    title: 'Номер Телефона',
    isOptional: false,
    rules: [
      formatPhone.validators.validateIfNotEmpty,
      formatPhone.validators.validateIfNotFilled,
    ],
  },
  USER_INFO: {
    key: 'USER_INFO',
    apiKey: 'additionalInformation',
    title: 'Дополнительная информация',
    isOptional: true,
    rules: [],
  },
  USER_DESCRIPTION: {
    key: 'USER_DESCRIPTION',
    apiKey: 'description',
    title: 'Описание заказа',
    isOptional: false,
    rules: [
      {
        required: true,
        message: 'Описание не может быть пустым',
      },
    ],
  },
  USER_DATE: {
    key: 'USER_DATE',
    apiKey: 'expiredAt',
    title: 'Срок действия счета',
    isOptional: false,
    rules: [
      {
        required: true,
        message: 'Дата не может быть пустой',
      },
    ],
  },
  USER_TIME: {
    key: 'USER_TIME',
    apiKey: 'expiredAt__time',
    title: 'Время до (включительно)',
    isOptional: false,
    rules: [
      {
        required: true,
        message: 'Укажите время',
      },
      () => ({
        validator(rule, value) {
          if (typeof value === 'string' && value.includes(':')) {
            const [h, m] = value.split(':').map(Number);
            if (h >= 0 && h <= 23 && m >= 0 && m <= 59) {
              return Promise.resolve();
            }
            return Promise.reject('Введите корректное время');
          }
          return Promise.reject('Введите корректное время');
        },
      }),
    ],
  },
  USER_REF: {
    key: 'USER_REF',
    apiKey: 'ref',
    title: 'Скопируйте ссылку и отправьте любым способом',
  },
};

export const COLUMNS = [
  {
    key: 'operationId',
    title: 'ID Операции',
    render: ({ operationId }) => <span>{operationId}</span>,
    width: 240,
  },
  {
    key: 'data',
    title: 'дата и время операции',
    render: ({ data }) =>
      data
        && typeof data === 'string' && (
        <span>{moment.utc(data).format(DATE_FORMAT_WITH_TIME)}</span>
      ),
    width: 240,
  },
  {
    key: 'sum',
    title: 'Cумма операции',
    render: ({ sum }) => <span>{sum}</span>,
    width: 240,
  },
  {
    key: 'code',
    title: 'Код авторизации',
    render: ({ code }) => <span>{code}</span>,
    width: 240,
  },
  {
    key: 'cartNumber',
    title: 'Номер карты',
    render: ({ cartNumber }) => <span>{cartNumber}</span>,
    width: 240,
  },
  {
    key: 'type',
    title: 'Тип',
    render: ({ type }) => <Type type={type}>{type}</Type>,
    width: 240,
  },
  {
    key: 'status',
    title: 'Статус',
    render: ({ status }) => <Status status={status}>{status}</Status>,
    width: 240,
  },
];
