import styled from 'styled-components';
import { palette } from 'styled-theme';
import { Tabs } from 'antd';

const TabsStyled = styled(Tabs)`
  && {
    &.ant-tabs {

      .ant-tabs-bar {
        border-color: ${palette('form', 4)};
      }
      .ant-tabs-nav {
        .ant-tabs-ink-bar {
          background-color: ${palette('form', 3)};
        }
        .ant-tabs-tab {
          color: ${palette('form', 5)};
          margin-left: 20px;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }
      .ant-tabs-top-content {
        min-height: 400px;
      }
    }
    .ant-tabs-nav-container-scrolling {
      padding-right: 0;
      padding-left: 0;
    }
  }
`;

export { TabsStyled };
