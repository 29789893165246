import {
  PERIOD_GET_REPORT,
  PERIOD_GET_REPORT__FAILURE,
  PERIOD_GET_REPORT__SUCCESS,
  PERIOD_GET_REPORT__RESET,
} from 'redux/tcmPeriod/actions';

// export const defaultPageSize = 25;

const initState = {
  report: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function campaignsReducer(state = initState, action) {
  switch (action.type) {
    case PERIOD_GET_REPORT: {
      return {
        ...state,

        report: {
          ...state.report,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case PERIOD_GET_REPORT__SUCCESS: {
      return {
        ...state,

        report: {
          ...state.report,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case PERIOD_GET_REPORT__FAILURE: {
      return {
        ...state,

        report: {
          ...state.report,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case PERIOD_GET_REPORT__RESET: {
      return {
        ...state,
        report: {
          ...initState.report,
        },
      };
    }

    default: {
      return state;
    }
  }
}
