import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import SelectMultiple from 'components/SelectMultiple';
import {
  useParams, withRouter,
} from 'react-router-dom';
import { Form, Input } from 'antd';
import { Rifm } from 'rifm';
import { formatPhone, cursorToLastPhoneValuePosition } from 'utils';

import {
  fetchDealerCenter as fetchDealerCenterAction,
  fetchDealerCenterReset as fetchDealerCenterResetAction,
} from 'redux/dealerCenter/actions';

import {
  ErrorMessage,
} from 'components/Messages';
import { KEYS as K } from './data';

import AddressField from './AddressField';

import {
  Root,
  Header,
  Title,
  Main,
  Rows,
  Row,
  RowTop,
  RowContainer,
  LoadingContainer,
} from './style';

const initialValues = {
  [K.NAME.key]: '',
  [K.INN.key]: '',
  [K.KRIF_ID.key]: '',
  [K.ACQUIRING_ID.key]: '',
  [K.ONLINE_ID.key]: '',
  [K.ADDRESS.key]: '',
  [K.PHONE.key]: '',
  [K.DESCRIPTION.key]: '',
  [K.SECTORS.key]: [],
};

const getOptions = (array = []) => {
  if (!Array.isArray(array)) {
    return [];
  }
  return array.length > 0
    ? array.map((item) => ({ value: item, label: item }))
    : [];
};

function Item({
  // connect
  history,
  data,
  isLoaded,
  dealerCenter,
  dealerCenterIsLoading,
  dealerCenterIsLoaded,
  dealerCenterError,

  // actions
  fetchDealerCenter,
}) {
  const [form] = Form.useForm();
  const phoneField = useRef();
  const [salePointTitle, setSalePointTitle] = useState('Точка продаж');
  const { dealerCenterId } = useParams();
  const isReadOnly = true;
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    if (dealerCenterId) {
      fetchDealerCenter({
        id: Number(dealerCenterId),
      });
    }
  }, [dealerCenterId, fetchDealerCenter]);

  const handleSetValue = useCallback(() => {
    const {
      inn,
      krifId,
      acquiringID,
      onlineId,
      addressactual,
      phone,
      detail,
      salePoint,
      sectorList,
      bindSector,
    } = dealerCenter;

    const validPhone = phone && phone.replace(/(\+7)/gm, '');

    if (Array.isArray(sectorList) && sectorList.length > 0) {
      const options = getOptions(sectorList);
      setSectors(options);
    }

    form.setFields([
      ...(salePoint ? [{ name: K.NAME.key, value: salePoint }] : []),
      ...(bindSector && bindSector.length > 0
        ? [{ name: K.SECTORS.key, value: bindSector }]
        : []),
      ...(inn ? [{ name: K.INN.key, value: inn }] : []),
      ...(krifId ? [{ name: K.KRIF_ID.key, value: krifId }] : []),
      ...(acquiringID ? [{ name: K.ACQUIRING_ID.key, value: acquiringID }] : []),
      ...(onlineId ? [{ name: K.ONLINE_ID.key, value: onlineId }] : []),
      ...(addressactual ? [{ name: K.ADDRESS.key, value: addressactual }] : []),
      ...(phone ? [{ name: K.PHONE.key, value: validPhone }] : []),
      ...(detail ? [{ name: K.DESCRIPTION.key, value: detail }] : []),
    ]);

    setSalePointTitle(salePoint);
  }, [form, dealerCenter]);

  useEffect(() => {
    if (dealerCenterIsLoaded && dealerCenter) {
      handleSetValue();
    }
  }, [dealerCenterIsLoaded, dealerCenter, handleSetValue]);

  useEffect(() => {
    if (isLoaded) {
      form.setFields([{ name: K.INN.key, value: '' }]);
      history.push(`/app/management/dealerCenters/${data.id}`);
    }
  }, [isLoaded, history, data.id, form]);

  return (
    <Root>
      {(dealerCenterIsLoaded) && (
        <Header>
          <Title>{salePointTitle}</Title>
        </Header>
      )}

      {dealerCenterIsLoading && (
        <LoadingContainer>
          <Loader text="Загрузка данных дилерского центра" />
        </LoadingContainer>
      )}

      {dealerCenterError && <ErrorMessage>{dealerCenterError}</ErrorMessage>}

      {(dealerCenterIsLoaded) && (
        <Main
          name="pointOfSales"
          layout="vertical"
          hideRequiredMark
          form={form}
          initialValues={initialValues}
          autoComplete="off"
        >
          <Rows>
            <RowContainer>
              <RowTop>
                <Form.Item
                  name={K.NAME.key}
                  label={K.NAME.title}
                  rules={K.NAME.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.NAME.key}
                    disabled={isReadOnly}
                  />
                </Form.Item>
                <Form.Item
                  name={K.KRIF_ID.key}
                  label={K.KRIF_ID.title}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.KRIF_ID.key}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.ACQUIRING_ID.key}
                  label={K.ACQUIRING_ID.title}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.ACQUIRING_ID.key}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.ONLINE_ID.key}
                  label={K.ONLINE_ID.title}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.ONLINE_ID.key}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.ADDRESS.key}
                  label={K.ADDRESS.title}
                  rules={K.ADDRESS.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <AddressField
                    name={K.ADDRESS.key}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.PHONE.key}
                  label={K.PHONE.title}
                  rules={K.PHONE.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Rifm
                    accept={/[\d]/g}
                    mask
                    replace={formatPhone.replace}
                    format={formatPhone.format}
                    onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                  >
                    {({ value, onChange }) => (
                      <Input
                        name={K.PHONE.key}
                        value={value}
                        onChange={onChange}
                        onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                        addonBefore={formatPhone.prefix}
                        disabled={isReadOnly}
                        ref={phoneField}
                      />
                    )}
                  </Rifm>
                </Form.Item>
                <Form.Item
                  name={K.SECTORS.key}
                  label={K.SECTORS.title}
                  rules={K.SECTORS.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <SelectMultiple
                    name={K.SECTORS.key}
                    disabled={isReadOnly}
                    options={sectors}
                  />
                </Form.Item>
              </RowTop>
            </RowContainer>

            <Row>
              <Form.Item
                name={K.DESCRIPTION.key}
                label={K.DESCRIPTION.title}
                rules={K.DESCRIPTION.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input.TextArea
                  name={K.DESCRIPTION.key}
                  rows={5}
                  disabled={isReadOnly}
                />
              </Form.Item>
            </Row>
          </Rows>
        </Main>
      )}
    </Root>
  );
}

export default withRouter(
  connect(
    (state) => ({
      data: state.dealerCenter.create.data,
      isLoaded: state.dealerCenter.create.isLoaded,

      dealerCenter: state.dealerCenter.item.data,
      dealerCenterIsLoading: state.dealerCenter.item.isLoading,
      dealerCenterIsLoaded: state.dealerCenter.item.isLoaded,
      dealerCenterError: state.dealerCenter.item.error,
    }),
    {
      fetchDealerCenter: fetchDealerCenterAction,

      fetchDealerCenterReset: fetchDealerCenterResetAction,
    },
  )(Item),
);
