import React, { useEffect, useState } from 'react';
import { Option } from 'components/AutoComplete';
import { AutoCompleteStyled } from './style';

const getPopupContainer = () => document.body;

function AutoCompleteFilter({
  name,
  value: valueFromProps,
  onChange,
  options = [],
  isLoading,
}) {
  const [value, setValue] = useState(valueFromProps || '');
  const [list, setList] = useState(options);

  useEffect(() => {
    if (value === '' && list.length !== options.length) {
      setList(options);
    }
  }, [options, list, value]);

  function handleOnSearch(v) {
    const filteredList = v && v.length > 0
      ? options
        .filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
        .sort(
          (x, y) =>
            x.label.indexOf(v.toLowerCase()) - y.label.indexOf(v.toLowerCase()),
        )
      : options;

    setList(filteredList);
    setValue(v);
    if (v === '') {
      onChange({ [name]: '' });
    }
  }

  function handleOnSelect(v) {
    const filteredList = value.length > 0
      ? options
        .filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
        .sort(
          (x, y) =>
            x.label.indexOf(v.toLowerCase()) - y.label.indexOf(v.toLowerCase()),
        )
      : options;

    setList(filteredList);
    setValue(v);
    onChange({ [name]: v || '' });
  }

  return (
    <AutoCompleteStyled
      allowClear
      getPopupContainer={getPopupContainer}
      name={name}
      value={value}
      onSearch={handleOnSearch}
      onSelect={handleOnSelect}
      loading={isLoading}
    >
      {list.map((option, i) => (
        /* eslint-disable-next-line */
        <Option key={option.value + i} value={option.value}>
          {option.label}
        </Option>
      ))}
    </AutoCompleteStyled>
  );
}

export default AutoCompleteFilter;
