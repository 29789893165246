import React from 'react';

import { Root, LinkStyled } from './style';

function Link({
  href, target, children, area,
}) {
  return (
    <Root area={area}>
      <LinkStyled href={href} target={target}>
        {children}
      </LinkStyled>
    </Root>
  );
}

export default Link;
