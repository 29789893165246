import React from 'react';

export const CallIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#A8AAAC" />
    <mask id="path-2-inside-1" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 12.2321C6.44772 11.2755 6.77547 10.0523 7.73205 9.5L11.1962 7.5C11.6744 7.22386 12.286 7.38773 12.5622 7.86603L14.0622 10.4641C14.3383 10.9424 14.1744 11.554 13.6962 11.8301L12.8301 12.3301C12.3518 12.6063 12.188 13.2179 12.4641 13.6962L14.4641 17.1603C14.7402 17.6385 15.3518 17.8024 15.8301 17.5263L16.6962 17.0263C17.1744 16.7501 17.786 16.914 18.0622 17.3923L19.5622 19.9904C19.8383 20.4687 19.6744 21.0803 19.1962 21.3564L15.7321 23.3564C14.7755 23.9087 13.5523 23.5809 13 22.6244L7 12.2321Z" />
    </mask>
    <path d="M7 12.2321L7.86603 11.7321L7 12.2321ZM13 22.6244L12.134 23.1244L13 22.6244ZM14.4641 17.1603L13.5981 17.6603L14.4641 17.1603ZM7.23205 8.63397C5.79717 9.4624 5.30555 11.2972 6.13397 12.7321L7.86603 11.7321C7.58988 11.2538 7.75376 10.6422 8.23205 10.366L7.23205 8.63397ZM10.6962 6.63397L7.23205 8.63397L8.23205 10.366L11.6962 8.36603L10.6962 6.63397ZM14.9282 9.9641L13.4282 7.36603L11.6962 8.36603L13.1962 10.9641L14.9282 9.9641ZM13.3301 13.1962L14.1962 12.6962L13.1962 10.9641L12.3301 11.4641L13.3301 13.1962ZM15.3301 16.6603L13.3301 13.1962L11.5981 14.1962L13.5981 17.6603L15.3301 16.6603ZM16.1962 16.1603L15.3301 16.6603L16.3301 18.3923L17.1962 17.8923L16.1962 16.1603ZM20.4282 19.4904L18.9282 16.8923L17.1962 17.8923L18.6962 20.4904L20.4282 19.4904ZM16.2321 24.2224L19.6962 22.2224L18.6962 20.4904L15.2321 22.4904L16.2321 24.2224ZM12.134 23.1244C12.9624 24.5592 14.7972 25.0509 16.2321 24.2224L15.2321 22.4904C14.7538 22.7665 14.1422 22.6026 13.866 22.1244L12.134 23.1244ZM6.13397 12.7321L12.134 23.1244L13.866 22.1244L7.86603 11.7321L6.13397 12.7321ZM18.6962 20.4904L19.6962 22.2224C20.6527 21.6701 20.9805 20.447 20.4282 19.4904L18.6962 20.4904ZM17.1962 17.8923L18.9282 16.8923C18.3759 15.9357 17.1527 15.608 16.1962 16.1603L17.1962 17.8923ZM13.5981 17.6603C14.1504 18.6168 15.3735 18.9446 16.3301 18.3923L15.3301 16.6603L13.5981 17.6603ZM12.3301 11.4641C11.3735 12.0164 11.0458 13.2396 11.5981 14.1962L13.3301 13.1962L12.3301 11.4641ZM13.1962 10.9641L14.1962 12.6962C15.1527 12.1439 15.4805 10.9207 14.9282 9.9641L13.1962 10.9641ZM11.6962 8.36603L11.6962 8.36603L13.4282 7.36603C12.8759 6.40944 11.6527 6.08169 10.6962 6.63397L11.6962 8.36603Z" fill="#A8AAAC" mask="url(#path-2-inside-1)" />
    <line x1="10.433" y1="8.75" x2="12.433" y2="12.2141" stroke="#A8AAAC" />
    <line x1="15.433" y1="17.75" x2="17.433" y2="21.2141" stroke="#A8AAAC" />
    <path d="M20.6082 14.7623C21.1422 12.7695 19.9596 10.7212 17.9668 10.1873" stroke="#A8AAAC" strokeLinecap="round" />
    <path d="M22.7954 15.3485C23.653 12.1477 21.7535 8.85765 18.5527 8" stroke="#A8AAAC" strokeLinecap="round" />
  </svg>
);

export const NoCallIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#E50000" />
    <mask id="path-2-inside-1" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 12.2321C6.44772 11.2755 6.77547 10.0523 7.73205 9.5L11.1962 7.5C11.6744 7.22386 12.286 7.38773 12.5622 7.86603L14.0622 10.4641C14.3383 10.9424 14.1744 11.554 13.6962 11.8301L12.8301 12.3301C12.3518 12.6063 12.188 13.2179 12.4641 13.6962L14.4641 17.1603C14.7402 17.6385 15.3518 17.8024 15.8301 17.5263L16.6962 17.0263C17.1744 16.7501 17.786 16.914 18.0622 17.3923L19.5622 19.9904C19.8383 20.4687 19.6744 21.0803 19.1962 21.3564L15.7321 23.3564C14.7755 23.9087 13.5523 23.5809 13 22.6244L7 12.2321Z" />
    </mask>
    <path d="M7.23205 8.63397C5.79717 9.4624 5.30555 11.2972 6.13397 12.7321L7.86603 11.7321C7.58988 11.2538 7.75376 10.6422 8.23205 10.366L7.23205 8.63397ZM10.6962 6.63397L7.23205 8.63397L8.23205 10.366L11.6962 8.36603L10.6962 6.63397ZM14.9282 9.9641L13.4282 7.36603L11.6962 8.36603L13.1962 10.9641L14.9282 9.9641ZM13.3301 13.1962L14.1962 12.6962L13.1962 10.9641L12.3301 11.4641L13.3301 13.1962ZM15.3301 16.6603L13.3301 13.1962L11.5981 14.1962L13.5981 17.6603L15.3301 16.6603ZM16.1962 16.1603L15.3301 16.6603L16.3301 18.3923L17.1962 17.8923L16.1962 16.1603ZM20.4282 19.4904L18.9282 16.8923L17.1962 17.8923L18.6962 20.4904L20.4282 19.4904ZM16.2321 24.2224L19.6962 22.2224L18.6962 20.4904L15.2321 22.4904L16.2321 24.2224ZM12.134 23.1244C12.9624 24.5592 14.7972 25.0509 16.2321 24.2224L15.2321 22.4904C14.7538 22.7665 14.1422 22.6026 13.866 22.1244L12.134 23.1244ZM6.13397 12.7321L12.134 23.1244L13.866 22.1244L7.86603 11.7321L6.13397 12.7321ZM18.6962 20.4904L19.6962 22.2224C20.6527 21.6701 20.9805 20.447 20.4282 19.4904L18.6962 20.4904ZM17.1962 17.8923L18.9282 16.8923C18.3759 15.9357 17.1527 15.608 16.1962 16.1603L17.1962 17.8923ZM13.5981 17.6603C14.1504 18.6168 15.3735 18.9446 16.3301 18.3923L15.3301 16.6603L13.5981 17.6603ZM12.3301 11.4641C11.3735 12.0164 11.0458 13.2396 11.5981 14.1962L13.3301 13.1962L12.3301 11.4641ZM13.1962 10.9641L14.1962 12.6962C15.1527 12.1439 15.4805 10.9207 14.9282 9.9641L13.1962 10.9641ZM11.6962 8.36603L11.6962 8.36603L13.4282 7.36603C12.8759 6.40944 11.6527 6.08169 10.6962 6.63397L11.6962 8.36603Z" fill="#E50000" mask="url(#path-2-inside-1)" />
    <line x1="10.433" y1="8.75" x2="12.433" y2="12.2141" stroke="#E50000" />
    <line x1="15.433" y1="17.75" x2="17.433" y2="21.2141" stroke="#E50000" />
    <path d="M20.6082 14.7623C21.1422 12.7695 19.9596 10.7212 17.9668 10.1872" stroke="#E50000" strokeLinecap="round" />
    <path d="M22.7954 15.3485C23.653 12.1477 21.7535 8.85765 18.5527 8" stroke="#E50000" strokeLinecap="round" />
    <rect x="25.2357" y="7.31699" width="2" height="25" transform="rotate(60 25.2357 7.31699)" fill="#E50000" stroke="white" />
  </svg>
);

export const RepeatIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#A8AAAC" />
    <path d="M8.5 14C8.5 14.2761 8.72386 14.5 9 14.5C9.27614 14.5 9.5 14.2761 9.5 14H8.5ZM20.3536 11.3536C20.5488 11.1583 20.5488 10.8417 20.3536 10.6464L17.1716 7.46447C16.9763 7.2692 16.6597 7.2692 16.4645 7.46447C16.2692 7.65973 16.2692 7.97631 16.4645 8.17157L19.2929 11L16.4645 13.8284C16.2692 14.0237 16.2692 14.3403 16.4645 14.5355C16.6597 14.7308 16.9763 14.7308 17.1716 14.5355L20.3536 11.3536ZM9.5 14V12H8.5V14H9.5ZM10 11.5H20V10.5H10V11.5ZM9.5 12C9.5 11.7239 9.72386 11.5 10 11.5V10.5C9.17157 10.5 8.5 11.1716 8.5 12H9.5Z" fill="#A8AAAC" />
    <path d="M20.5 16C20.5 15.7239 20.2761 15.5 20 15.5C19.7239 15.5 19.5 15.7239 19.5 16H20.5ZM8.64645 18.6464C8.45118 18.8417 8.45118 19.1583 8.64645 19.3536L11.8284 22.5355C12.0237 22.7308 12.3403 22.7308 12.5355 22.5355C12.7308 22.3403 12.7308 22.0237 12.5355 21.8284L9.70711 19L12.5355 16.1716C12.7308 15.9763 12.7308 15.6597 12.5355 15.4645C12.3403 15.2692 12.0237 15.2692 11.8284 15.4645L8.64645 18.6464ZM19.5 16V18H20.5V16H19.5ZM19 18.5H9V19.5H19V18.5ZM19.5 18C19.5 18.2761 19.2761 18.5 19 18.5V19.5C19.8284 19.5 20.5 18.8284 20.5 18H19.5Z" fill="#A8AAAC" />
  </svg>
);

export const CarIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#4CAF50" />
    <rect x="7.5" y="14.5" width="15" height="5" stroke="#4CAF50" strokeLinecap="round" />
    <path d="M23 15L20.5195 9.21216C20.2043 8.47679 19.4813 8 18.6812 8H11.3188C10.5187 8 9.79566 8.47679 9.4805 9.21216L7 15" stroke="#4CAF50" strokeLinecap="round" />
    <circle cx="10" cy="17" r="1" fill="#4CAF50" />
    <circle cx="20" cy="17" r="1" fill="#4CAF50" />
    <rect x="7.5" y="19.5" width="1" height="2" rx="0.5" stroke="#4CAF50" strokeLinecap="round" />
    <rect x="21.5" y="19.5" width="1" height="2" rx="0.5" stroke="#4CAF50" strokeLinecap="round" />
    <rect x="22.5" y="15.5" width="1" height="1" rx="0.5" transform="rotate(-90 22.5 15.5)" stroke="#4CAF50" strokeLinecap="round" />
    <rect x="6.5" y="15.5" width="1" height="1" rx="0.5" transform="rotate(-90 6.5 15.5)" stroke="#4CAF50" strokeLinecap="round" />
  </svg>
);

export const NewIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#A8AAAC" />
    <path d="M15 6.12978L17.4906 11.1762L17.6069 11.4119L17.867 11.4497L23.4361 12.2589L19.4063 16.187L19.218 16.3705L19.2625 16.6296L20.2138 22.1762L15.2327 19.5574L15 19.4351L14.7673 19.5574L9.78621 22.1762L10.7375 16.6296L10.782 16.3705L10.5937 16.187L6.56392 12.2589L12.133 11.4497L12.3931 11.4119L12.5094 11.1762L15 6.12978Z" stroke="#A8AAAC" />
  </svg>
);

export const VisitIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#4CAF50" />
    <circle cx="12" cy="13" r="2.5" stroke="#4CAF50" />
    <path d="M14 17V17C15.1046 17 16 17.8954 16 19V23H8V19C8 17.8954 8.89543 17 10 17V17" stroke="#4CAF50" strokeLinecap="round" />
    <path d="M18.5 23L18.5 13.5L18.5 6.5L23.5 9L18.5 12" stroke="#4CAF50" strokeLinecap="round" />
  </svg>
);
