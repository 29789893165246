import styled, { css } from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const ClientInfoCardContainer = styled.div`
  margin-bottom: 45px;
`;

export const Title = withTheme(styled.h3`
    font-family: ${({ theme }) => theme.toyotaFontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 25px;
    color: ${({ theme }) => theme.palette.black};
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`);

export const CallsContainer = styled.div`
  margin-bottom: 65px;
`;

export const EmptyContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CallsContent = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-right: 20px;
`;

export const CampaignContainer = styled.div`
  & > div {
   margin-top: 0;
  }
  margin-bottom: 45px;
`;

export const CarInfoContainer = styled.div`
  margin-bottom: 45px;
`;

export const InfoContent = styled.div`
  background: #F7F7F7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  padding: 33px 40px 37px;
`;

export const Row = styled.div`
`;

export const BlockRow = withTheme(styled.div`
  display: grid;
  grid-template-columns: 170px 160px 190px 175px 185px;
  column-gap: 14px;
  padding: 22px 0 26px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray4};
  
  &:last-child {
    border-bottom: none;
  }
`);

export const RowSeparate = styled.div`
  width: 100%;
  height: 1px;
  background: #E3E3E3;
`;

export const FieldTitle = withTheme(styled.div`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
  white-space: nowrap;
`);

export const FieldValue = withTheme(styled.div`
  margin-bottom: 0;
  font-family: ${({ theme, bold }) => (bold ? theme.toyotaFontFamily : theme.fontFamily)};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
`);

export const Color = styled.div`
  background: ${({ color }) => color || '#000'};
  border: 1px solid #000;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  margin-right: 4px;
`;

export const ColorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CalculationContainer = styled.div`
  margin-bottom: 40px;
`;

export const CalculationInfoContent = withTheme(styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.gray7};
  border-radius: 10px;
  padding: 33px 40px 37px;
`);

export const PreviousCampaignContainer = CalculationContainer;

export const PreviousCampaignContent = withTheme(styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.gray7};
  border-radius: 10px;
  padding: 33px 40px 37px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  row-gap: 20px;
`);

export const SubTopic = withTheme(styled.span`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
`);

export const SuffixCampaign = withTheme(styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const BackBtnWrapper = styled.div`
  margin-bottom: 40px;
  margin-top: 22px;
`;

// Call results
export const CallResultContainer = withTheme(styled.div`
  display: grid;
  row-gap: 20px;
  padding-bottom: 37px;
  padding-top: 33px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray4};
  
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.palette.gray4};
  }
  
  ${({ withMore }) => !withMore && css` &:last-child {
    border-bottom: none;
  }`}
`);

export const CallResultRow = styled.div`
  display: grid;
  grid-template-columns: 340px 1fr;
  column-gap: 20px;
`;

export const ResultItemTitle = withTheme(styled.p`
  margin-bottom: 10px;
  width: max-content;
  max-width: 407px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color:  ${({ theme }) => theme.palette.gray3};
`);

export const ResultItemDescription = withTheme(styled.p`
  margin-bottom: 0;
  width: auto;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '407px')};
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color:  ${({ theme }) => theme.palette.black};
  overflow-wrap: break-word;
`);

export const RecallMessageRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const RecallMessage = withTheme(styled.div`
  display: grid;
  grid-template-columns: 30px max-content;
  column-gap: 8px;
  align-items: center;
  
  p {
    margin-bottom: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.black};
  }
`);

export const Wrapper = styled.div`
  button {
    background: transparent;
    &:hover{
      background: transparent;
    }
    &:focus{
      background: transparent;
    }
  }
`;

export const Visit = withTheme(styled.div`
  display: grid;
  row-gap: 20px;
  padding: 33px 0 37px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray4};
  
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.palette.gray4};
  }
  
  ${({ withMore }) => !withMore && css` &:last-child {
    border-bottom: none;
  }`}
`);

export const VisitGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  row-gap: 20px;
  column-gap: 86px;
`;

export const VisitRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 86px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const NoVisitsText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

export const VisitsOptionsContainer = styled.div``;

export const ButtonWrapper = styled.div`
  position:relative;
  width: max-content;
`;
