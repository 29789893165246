import moment from 'moment';
import { CHAR } from 'settings/namedUnicode';

export const formatOfferList = (response) => {
  if (response) {
    const { offerItemResponseList } = response;

    if (Array.isArray(offerItemResponseList)) {
      const {
        carItemResponseList,
        campaignItemResponseList,
        subtopicItemResponseList,
        topicItemResponseList,
      } = response;

      if (
        Array.isArray(carItemResponseList)
        && Array.isArray(campaignItemResponseList)
        && Array.isArray(subtopicItemResponseList)
        && Array.isArray(topicItemResponseList)
      ) {
        const campaigns = new Map();
        campaignItemResponseList.forEach((campaign) => campaigns.set(campaign.id, campaign));

        const subtopics = new Map();
        subtopicItemResponseList.forEach((subtopic) => subtopics.set(subtopic.id, subtopic));

        return offerItemResponseList.map((offer) => {
          const campaign = (
            Array.isArray(offer.campaignItemResponseList)
              && offer.campaignItemResponseList.length > 0
              && offer.campaignItemResponseList[0].id
              ? campaigns.get(offer.campaignItemResponseList[0].id)
              : {}
          );

          const car = (
            Array.isArray(offer.carItemResponseList)
            && offer.carItemResponseList.length > 0
              ? offer.carItemResponseList[0]
              : {}
          );

          const subtopic = (
            Array.isArray(campaign.subtopicItemResponseList)
              && campaign.subtopicItemResponseList.length > 0
              && campaign.subtopicItemResponseList[0].id
              ? subtopics.get(campaign.subtopicItemResponseList[0].id)
              : {}
          );

          const carItem = Array.isArray(carItemResponseList)
            ? carItemResponseList.find((item) => car.vin === item.vin)
            : car;

          const dialingFrom = (campaign.dialingFrom && moment(campaign.dialingFrom).isValid())
            ? moment(campaign.dialingFrom).format('DD.MM')
            : CHAR.MDASH;
          const dialingTo = (campaign.dialingTo && moment(campaign.dialingTo).isValid())
            ? moment(campaign.dialingTo).format('DD.MM.YYYY')
            : CHAR.MDASH;
          const name = `${carItem?.brand ?? ''} ${carItem?.model ?? ''}
          ${(carItem?.workingVolume || carItem?.engineType) ? ' / ' : ''}
          ${(carItem?.workingVolume ?? 0) / 1000 || ''} ${carItem?.engineType ?? ''} (${dialingFrom}${CHAR.MDASH}${dialingTo})`;

          return {
            calculation: offer.calculation,
            calculations: offer.calculations,
            client: offer.client,
            deadline: offer.deadline,
            employee: offer.employee,
            evaluation: offer.evaluation,
            externalId: offer.externalId,
            id: offer.externalId,
            unId: offer.id,
            lastEvent: offer.lastEvent,
            status: offer.status,

            // calculated
            campaign: {
              ...campaign,
              name,
            },
            car: carItem,
            subtopic,
            termFulfillmentFrom: offer.termFulfillmentFrom,
            termFulfillmentTo: offer.termFulfillmentTo,
          };
        });
      }
      console.error(`Сервер не прислал полные данные.
Проверьте корректность данных полей offerItemResponseList / carItemResponseList /campaignItemResponseList метода /v1/tcm/offers`);
    }
  }
  return [];
};

export const formatOfferPreviousCampaign = (response = []) => {
  if (response) {
    const { offerItemResponseList } = response;

    if (Array.isArray(offerItemResponseList)) {
      const {
        carItemResponseList,
        campaignItemResponseList,
        subtopicItemResponseList,
        topicItemResponseList,
      } = response;

      if (
        Array.isArray(carItemResponseList)
        && Array.isArray(campaignItemResponseList)
        && Array.isArray(subtopicItemResponseList)
        && Array.isArray(topicItemResponseList)
      ) {
        const campaigns = new Map();
        campaignItemResponseList.forEach((campaign) => campaigns.set(campaign.id, campaign));

        const subtopics = new Map();
        subtopicItemResponseList.forEach((subtopic) => subtopics.set(subtopic.id, subtopic));

        return offerItemResponseList.map((offer) => {
          const campaign = (
            Array.isArray(offer.campaignItemResponseList)
            && offer.campaignItemResponseList.length > 0
            && offer.campaignItemResponseList[0].id
              ? campaigns.get(offer.campaignItemResponseList[0].id)
              : {}
          );

          const car = (
            Array.isArray(offer.carItemResponseList)
            && offer.carItemResponseList.length > 0
              ? offer.carItemResponseList[0]
              : {}
          );

          const carItem = Array.isArray(carItemResponseList)
            ? carItemResponseList.find((item) => car.vin === item.vin)
            : car;

          const subtopic = (
            Array.isArray(campaign.subtopicItemResponseList)
            && campaign.subtopicItemResponseList.length > 0
            && campaign.subtopicItemResponseList[0].id
              ? subtopics.get(campaign.subtopicItemResponseList[0].id)
              : {}
          );
          const dialingFrom = (campaign.dialingFrom && moment(campaign.dialingFrom).isValid())
            ? moment(campaign.dialingFrom).format('DD.MM')
            : CHAR.MDASH;
          const dialingTo = (campaign.dialingTo && moment(campaign.dialingTo).isValid())
            ? moment(campaign.dialingTo).format('DD.MM.YYYY')
            : CHAR.MDASH;

          return {
            dialingFrom,
            dialingTo,
            id: campaign.id,
            lastEvent: offer.lastEvent,
            status: offer.status,
            subtopicName: subtopic.name,
            suffixName: Array.isArray(carItem.suffixTMSList) && carItem.suffixTMSList[0].name
              ? carItem.suffixTMSList[0].name : '-',

            // calculated
            campaign: {
              ...campaign,
            },
          };
        });
      }
      console.error(`Сервер не прислал полные данные.
Проверьте корректность данных полей offerItemResponseList / carItemResponseList /campaignItemResponseList метода /v1/tcm/offers`);
    }
  }
  return [];
};
