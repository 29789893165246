const actions = {
  TABLE_CANCEL_REQUEST: 'TABLE_CANCEL_REQUEST',

  TABLE_RESET: 'TABLE_RESET',
  reset: () => ({ type: actions.TABLE_RESET }),

  TABLE_SET_LOADING: 'TABLE_SET_LOADING',
  TABLE_SET_PAGINATION: 'TABLE_SET_PAGINATION',

  TABLE_SET_SEARCH: 'TABLE_SET_SEARCH',
  setSearch: ({ search }) => ({ type: actions.TABLE_SET_SEARCH, search }),

  TABLE_SET_PAGE: 'TABLE_SET_PAGE',
  setPage: ({ page }) => ({ type: actions.TABLE_SET_PAGE, page }),

  TABLE_SET_SORTING: 'TABLE_SET_SORTING',
  setSorting: ({ sortBy }) => ({ type: actions.TABLE_SET_SORTING, sortBy }),

  TABLE_SET_FILTER: 'TABLE_SET_FILTER',
  setFilters: ({ filters }) => ({ type: actions.TABLE_SET_FILTER, filters }),

  TABLE_FETCH_DATA: 'TABLE_FETCH_DATA',
  TABLE_FETCH_DATA__SUCCESS: 'TABLE_FETCH_DATA__SUCCESS',
  TABLE_FETCH_DATA__FAILURE: 'TABLE_FETCH_DATA__FAILURE',
  fetchData: () => ({ type: actions.TABLE_FETCH_DATA }),
};

export default actions;
