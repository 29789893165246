import React from 'react';
import { connect } from 'react-redux';
import { checkPrivilege } from 'helpers/roles';
import { RestrictedRoute, checkRedirectRoute } from 'router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Nav, Link } from 'components/Navigation';
import BreadCrumpbs from 'components/Breadcrumb';

import { REPORTS } from 'settings';
import Bills from './Bills';
import Contracts from './Contracts';
import FAQ from './FAQ';
import Reports from './Reports';
import Transactions from './Transactions';

import { Root, Content } from './style';

const routes = [
  {
    path: '/app/payments/transactions',
    title: 'Платежи',
    component: Transactions,
    checkRights: (role) => checkPrivilege([
      role.PAYMENTS.VIEW,
    ]),
  },
  {
    path: '/app/payments/bills',
    title: 'Счета',
    component: Bills,
    checkRights: (role) => checkPrivilege([
      role.PAYMENTS.CREATE_BILLS,
      role.PAYMENTS.VIEW_ORGANISATION_BILLS,
      role.PAYMENTS.VIEW_MY_BILLS,
    ]),
  },
  ...(REPORTS ? [{
    path: '/app/payments/reports',
    title: 'Отчеты',
    component: Reports,
    checkRights: (role) => checkPrivilege([
      role.PAYMENTS.GENERATE_REPORTS,
      role.PAYMENTS.DOWNLOAD_REPORTS,
    ]),
  }] : []),
  {
    path: '/app/payments/contracts',
    title: 'Договоры',
    component: Contracts,
    checkRights: (role) => checkPrivilege([
      role.ACQUIRING_AGREEMENT.VIEW,
    ]),
  },
  {
    path: '/app/payments/faq',
    title: 'FAQ',
    component: FAQ,
    checkRights: (role) => checkPrivilege([
      role.AGREEMENTS.VIEW_FAQ,
    ]),
  },
];

function Index({ role }) {
  return (
    <Root>
      <Nav>
        {routes
          .filter((route) => route.checkRights && route.checkRights(role))
          .map((route) => (
            <Link key={route.path} to={route.path}>
              {route.title}
            </Link>
          ))}
      </Nav>
      <BreadCrumpbs />
      <Content>
        <Switch>
          <Route exact path="/app/payments">
            <Redirect to={checkRedirectRoute({ role, routes })} />
          </Route>

          {routes.map((route) => (
            <RestrictedRoute
              key={route.path}
              path={route.path}
              component={route.component}
              checkRights={route.checkRights}
            />
          ))}
        </Switch>
      </Content>
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
  }),
  null,
)(Index);
