import React, {
  useState, useEffect, useCallback,
} from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import {
  fetchItem as fetchItemAction,
  setOffersStatus as setOffersStatusAction,
  oldCarExport as oldCarExportAction,
  oldCarExportReset as oldCarExportResetAction,
  resetOffersStatus as resetOffersStatusAction,
  fetchPreviousCampaign as fetchPreviousCampaignAction,
  fetchPreviousCampaignReset as fetchPreviousCampaignResetAction,
} from 'redux/tcmOffer/actions';
import ClientInfo from 'components/tcm/ClientInfo';
import CampaignInfo from 'components/tcm/CampaignInfo';
import { StatusLine } from 'components/tcm/StatusLine/StatusLine';
import { STATUS_DATA, CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import { TCM } from 'components/tcm';
import { parseDigitsWithSeparator as parseDigits } from 'utils';
import { ArrowIcon, RublerIcon } from 'icons';
import { CallResults } from 'containers/App/TCM/ClientCard/CallResults';
import { CreditForm } from 'containers/App/TCM/Credit';
import Error from 'components/Fields/Error';
import { PageContainer } from 'containers/App/TCM/style';
import { Visits } from 'containers/App/TCM/ClientCard/Visits';
import { checkPrivilege } from 'helpers/roles';
import { Actions } from 'containers/App/TCM/ClientCard/style';
import { CreateErrorModal } from 'components/tcm/Modals';
import { OfferStatus } from 'tcm/offer/data';
import { ErrorMessageAbsolute } from 'components/tcm/ErrorMessage';
import Page404 from 'components/tcm/Page404';
import { Calculation } from './Calculation';
import { COLOR_MAP } from './data';
import { CLIENTS_STATUS_MAP, CLIENTS_STATUSES_MAP } from '../ClientsTable/data';
import {
  ClientInfoCardContainer,
  Title,
  CallsContainer,
  CampaignContainer,
  CarInfoContainer,
  InfoContent,
  Row,
  RowSeparate,
  FieldTitle,
  FieldValue,
  Color,
  ColorContainer,
  CalculationContainer,
  PreviousCampaignContainer,
  PreviousCampaignContent,
  BackBtnWrapper,
  SubTopic,
  SuffixCampaign,
  ButtonWrapper,
} from './style';
import { BackButtonContainer, Suffix } from '../style';

const formatInteger = (string) => {
  const parsed = parseDigits(string);
  const number = Number.parseInt(parsed, 10);

  if (Number.isNaN(number)) {
    return '';
  }
  return number.toLocaleString('ru');
};

const PreviousCampaignRows = ({ list }) => list.map((item, i) => {
  const { status, statusDescriptions, statuses } = item.lastEvent || {};
  const {
    text = () => null,
  } = (statuses ? CLIENTS_STATUSES_MAP(statuses) : status
    ? CLIENTS_STATUS_MAP[status] || {}
    : {}
  );
  return (
    <>
      <Row>
        <FieldTitle>ID</FieldTitle>
        {item.id ? (
          <FieldValue>
            {item.id}
          </FieldValue>
        ) : (
          <FieldValue>Нет данных</FieldValue>
        )}
      </Row>
      <Row>
        <FieldTitle>Кампания</FieldTitle>
        {(item.subtopicName && item.suffixName) ? (
          <FieldValue>
            <SubTopic>{item.subtopicName}</SubTopic>
            {' '}
            <SuffixCampaign>{item.suffixName}</SuffixCampaign>
          </FieldValue>
        ) : (
          <FieldValue>Нет данных</FieldValue>
        )}
      </Row>
      <Row>
        <FieldTitle>Период обзвона</FieldTitle>
        {(item.dialingFrom && item.dialingTo) ? (
          <FieldValue>
            {`${item.dialingFrom} - ${item.dialingTo}`}
          </FieldValue>
        ) : (
          <FieldValue>Нет данных</FieldValue>
        )}
      </Row>
      <Row>
        <FieldTitle>Результат</FieldTitle>
        {item.lastEvent ? (
          <FieldValue>
            {statusDescriptions ? text(statusDescriptions) : 'Нет данных'}
          </FieldValue>
        ) : (
          <FieldValue>Нет данных</FieldValue>
        )}
      </Row>
      {(list.length - 1) > i && (
        <>
          <RowSeparate />
          <RowSeparate />
          <RowSeparate />
          <RowSeparate />
        </>
      )}
    </>
  );
});

function ClientCard({
  role,
  userId,
  // store
  offer,
  isLoaded,
  isLoading,
  error,

  // statusSet
  statusIsLoaded,
  statusIsLoading,
  statusError,

  previousCampaignList,

  commentIsLoaded,

  oldCarError,
  oldCarIsLoading,
  oldCarIsLoaded,
  oldCarData,

  // actions
  fetchItem,
  setOffersStatus,
  oldCarExport,
  oldCarExportReset,
  resetOffersStatus,
  fetchPreviousCampaign,
  fetchPreviousCampaignReset,
}) {
  const [campaign, setCampaign] = useState({});
  const [loadingBtn, setLoadingBtn] = useState('');
  const [showExportModal, setShowExportModal] = useState(false);
  const [isMyClient, setIsMyClient] = useState(false);
  const [isCallFormOpened, setIsCallFormOpened] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const {
    car, client, evaluation, employee, statusHistory, externalId,
  } = offer;
  const isOfferConfirmed = offer.status === OfferStatus.Confirmed;

  const onConfirmed = () => {
    setLoadingBtn('confirmed');
    setOffersStatus({
      offerIds: [offer.externalId],
      status: OfferStatus.Confirmed,
    });
  };

  const onRevision = () => {
    setLoadingBtn('revision');
    setOffersStatus({
      offerIds: [offer.externalId],
      status: OfferStatus.Revision,
    });
  };

  const showCreditForm = checkPrivilege([
    role.TCM.OFFER_SET_CALCULATION,
  ]) && isMyClient && (Boolean(offer?.lastEvent) || isCallFormOpened);

  useEffect(() => {
    if (statusIsLoaded) {
      setLoadingBtn('');
    }
  }, [statusIsLoaded]);

  useEffect(() => {
    if (employee && employee.id && userId) {
      setIsMyClient(employee.id === userId);
    }
  }, [employee, userId]);

  useEffect(() => {
    fetchItem({ id });
  }, [fetchItem, id]);

  useEffect(() => {
    if (commentIsLoaded) {
      fetchItem({ id });
    }
  }, [fetchItem, id, commentIsLoaded]);

  useEffect(() => {
    if (isLoaded && offer.campaign) {
      setCampaign({
        comments: Array.isArray(offer.campaign.comments)
          ? offer.campaign.comments.filter((item) => item.text)
            .map((item) => ({
              ...item,
              createdAt: moment(item.createdAt)
                .format('DD.MM hh:mm'),
            }))
          : [],
        topic: offer.campaign.subtopic.topic.name,
        subtopic: offer.campaign.subtopic.name,
        id: offer.campaign.id,
        status: STATUS_DATA[offer.campaign.status].title,
        dialingFrom: moment(offer.campaign.dialingFrom)
          .format('DD.MM'),
        dialingTo: moment(offer.campaign.dialingTo)
          .format('DD.MM.yyyy'),
        countCarsInStock: offer.campaign.countCarsInStock,
      });
    }

    if (isLoaded && offer?.externalId) {
      fetchPreviousCampaign({ externalId: offer.externalId });
    }
  }, [
    isLoaded,
    offer.campaign,
    offer.externalId,
    fetchPreviousCampaign,
  ]);

  useEffect(() => {
    if (statusIsLoaded) {
      fetchItem({ id });
    }
  }, [fetchItem, id, statusIsLoaded]);

  const onExportClick = useCallback(() => {
    oldCarExport({ id });
  }, [id, oldCarExport]);

  useEffect(() => {
    if (oldCarIsLoaded) {
      const url = URL.createObjectURL(oldCarData);
      const shadowLink = document.createElement('a');
      shadowLink.href = url;
      shadowLink.download = `${offer.client.name}_${offer.car.brand}_${offer.car.model}.xlsx`;
      shadowLink.click();
      oldCarExportReset();
    }
  }, [offer, oldCarData, oldCarIsLoaded, oldCarExportReset]);

  useEffect(() => {
    if (oldCarError) {
      setShowExportModal(true);
    }
  }, [oldCarError]);

  useEffect(() => () => {
    resetOffersStatus();
    fetchPreviousCampaignReset();
  }, [resetOffersStatus, fetchPreviousCampaignReset]);

  if (error) {
    return <Page404 text="Клиент не найден" />;
  }

  return (
    <PageContainer>
      <BackBtnWrapper>
        <TCM.ButtonText onClick={() => history.goBack()}>
          <BackButtonContainer>
            <ArrowIcon left />
            Назад
          </BackButtonContainer>
        </TCM.ButtonText>
      </BackBtnWrapper>
      {statusHistory?.length > 0 && !isLoading && (
        <StatusLine statuses={statusHistory} />
      )}

      {isLoading ? (
        <TCM.Loader text="Загрузка данных клиента ..." />
      ) : (
        <>
          <ClientInfoCardContainer>
            <ClientInfo
              client={client || {}}
              employee={employee}
              isLoaded={isLoaded}
              externalId={externalId}
              isMyClient={isMyClient}
            />
          </ClientInfoCardContainer>
          <CallsContainer>
            <Title>
              События
            </Title>
            <CallResults
              lastEvent={offer.lastEvent}
              calls={offer.calls}
              clientGuid={offer.externalId}
              isMyClient={isMyClient}
              setIsCallFormOpened={setIsCallFormOpened}
              isOfferConfirmed={isOfferConfirmed}
            />
            {offer?.calls?.length > 0 && (
              <Visits
                clientGuid={offer.externalId}
                visits={offer.visits}
                isMyClient={isMyClient}
                isOfferConfirmed={isOfferConfirmed}
                canAddVisit={offer.canAddVisit}
              />
            )}
          </CallsContainer>
          {campaign.id
              && (
                <CampaignContainer>
                  <CampaignInfo campaign={campaign || {}} />
                </CampaignContainer>
              )}

          {previousCampaignList.length > 0 && (
            <PreviousCampaignContainer>
              <Title>
                Участие клиента в предыдущих кампаниях
              </Title>
              <PreviousCampaignContent>
                <PreviousCampaignRows list={previousCampaignList} />
              </PreviousCampaignContent>
            </PreviousCampaignContainer>
          )}
          <CarInfoContainer>
            <Title>
              Информация об автомобиле клиента
              <TCM.ButtonOutline
                widthSize="fixed"
                onClick={onExportClick}
                loading={oldCarIsLoading}
              >
                Выгрузить в Excel
              </TCM.ButtonOutline>
            </Title>
            <InfoContent>
              <Row>
                <FieldTitle>{(car?.brand || 'Нет данных')}</FieldTitle>
                <FieldValue>{(car?.model || 'Нет данных')}</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Год выпуска</FieldTitle>
                <FieldValue>
                  {(moment(car?.dateOfIssue)
                    .format('yyyy') || 'Нет данных')}
                </FieldValue>
              </Row>
              <Row>
                <FieldTitle>Дата покупки у дилера</FieldTitle>
                <FieldValue>
                  {(moment(car?.loanIssueDate)
                    .format('DD.MM.yyyy') || 'Нет данных')}
                </FieldValue>
              </Row>
              <Row>
                <FieldTitle>Автомобиль куплен</FieldTitle>
                <FieldValue>{(car?.purchaseCondition || 'Нет данных')}</FieldValue>
              </Row>
              {/* TODO: refactoring. Dirty hack to emulate row border. */}
              <RowSeparate />
              <RowSeparate />
              <RowSeparate />
              <RowSeparate />
              <Row>
                <FieldTitle>Трансмиссия</FieldTitle>
                <FieldValue>{(car?.transmission || 'Нет данных')}</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Привод</FieldTitle>
                <FieldValue>{car?.driveUnit || 'Нет данных'}</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Тип двигателя</FieldTitle>
                <FieldValue>{car?.engineType || 'Нет данных'}</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Рабочий объем</FieldTitle>
                <FieldValue>
                  {car?.workingVolume ? `${car.workingVolume / 1000} см3` : '-'}
                </FieldValue>
              </Row>
              <Row>
                <FieldTitle>Суффикс</FieldTitle>
                <FieldValue>{(car?.suffixTMS || 'Нет данных')}</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Комплектация</FieldTitle>
                <FieldValue>{car?.bundlingCode || 'Нет данных' }</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Цвет кузова</FieldTitle>
                <FieldValue>
                  <ColorContainer>
                    {car?.bodyColor && <Color color={COLOR_MAP[car.bodyColor]} />}
                    {' '}
                    {(car?.bodyColor || 'Нет данных')}
                  </ColorContainer>
                </FieldValue>
              </Row>
              <Row>
                <FieldTitle>Салон</FieldTitle>
                <FieldValue>
                  <ColorContainer>
                    {car?.interiorColor && (
                      <>
                        <Color color={COLOR_MAP[car?.interiorColor]} />
                        {' '}
                        {(car?.interiorColor || 'Нет данных')}
                      </>
                    )}
                    {' '}
                    /
                    {' '}
                    {car?.upholsteryType}
                  </ColorContainer>
                </FieldValue>
              </Row>
              {/* TODO: refactoring. Dirty hack to emulate row border. */}
              <RowSeparate />
              <RowSeparate />
              <RowSeparate />
              <RowSeparate />
              <Row>
                <FieldTitle>VIN</FieldTitle>
                <FieldValue>{(car?.vin || 'Нет данных')}</FieldValue>
              </Row>
              <Row>
                <FieldTitle>Цена Trade-in</FieldTitle>
                {evaluation?.tradeInPrice ? (
                  <FieldValue>
                    {formatInteger(evaluation.tradeInPrice)}
                    {' '}
                    <Suffix><RublerIcon /></Suffix>
                  </FieldValue>
                ) : (
                  <FieldValue>Нет данных</FieldValue>
                )}
              </Row>
            </InfoContent>
          </CarInfoContainer>

          {isLoaded && offer.calculations?.length && (
            <CalculationContainer>
              <Title>
                Расчет кредитного предложения от банка
              </Title>
              <Calculation
                calculations={offer.calculations}
                evaluation={evaluation}
                carName={`${offer.car.brand} ${offer.car.model}`}
              />
            </CalculationContainer>
          )}

          {offer.calculation && (
            <CalculationContainer>
              <Title>
                Новый расчет кредитного предложения
              </Title>
              <Calculation
                calculations={[offer.calculation]}
                evaluation={evaluation}
              />
            </CalculationContainer>
          )}

          {showCreditForm && (
            <CreditForm
              offer={offer}
            />
          )}

          {(offer.status === OfferStatus.Calling || offer.status === OfferStatus.Revision)
            && isMyClient && (
            <Actions>
              <ButtonWrapper>
                <TCM.Button
                  onClick={() => setOffersStatus({
                    offerIds: [offer.externalId],
                    status: OfferStatus.WaitConfirm,
                  })}
                  disabled={!offer.calls?.length}
                  loading={statusIsLoading}
                >
                  Отправить менеджеру на согласование
                </TCM.Button>
                {Boolean(statusError)
                    && <ErrorMessageAbsolute>{statusError}</ErrorMessageAbsolute>}
              </ButtonWrapper>
              <TCM.ButtonText
                onClick={() => history.goBack()}
              >
                Закрыть
              </TCM.ButtonText>
            </Actions>
          )}

          {
            checkPrivilege([
              role.TCM.OFFER_SET_STATUS_CONFIRMED,
              role.TCM.OFFER_SET_STATUS_REVISION,
            ]) && offer.status === OfferStatus.WaitConfirm && (
              <Actions>
                <TCM.ButtonGroup>
                  <TCM.Button
                    onClick={onConfirmed}
                    loading={loadingBtn === 'confirmed'}
                    disabled={offer.campaign?.status === CAMPAIGN_STATUSES.DISTRIBUTION}
                  >
                    Подтвердить результат
                  </TCM.Button>

                  <TCM.ButtonOutline
                    onClick={onRevision}
                    loading={loadingBtn === 'revision'}
                    disabled={offer.campaign?.status === CAMPAIGN_STATUSES.DISTRIBUTION}
                  >
                    Отправить на доработку
                  </TCM.ButtonOutline>
                </TCM.ButtonGroup>
                <TCM.ButtonText
                  onClick={() => history.goBack()}
                >
                  Закрыть
                </TCM.ButtonText>
              </Actions>
            )
          }
          {Boolean(statusError) && <Error>{statusError}</Error>}
        </>
      )}
      {oldCarError && (
        <CreateErrorModal
          onCancel={() => setShowExportModal(false)}
          visible={showExportModal}
          title="Ошибка"
          description="Ошибка сервера выгрузка excel-файла невозможна, попробуйте позже"
        />
      )}
    </PageContainer>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    userId: state.auth.userId,
    // offer
    offer: state.tcmOffer.offer.data,
    isLoaded: state.tcmOffer.offer.isLoaded,
    isLoading: state.tcmOffer.offer.isLoading,
    error: state.tcmOffer.offer.error,

    // statusSet
    statusIsLoaded: state.tcmOffer.statusSet.isLoaded,
    statusIsLoading: state.tcmOffer.statusSet.isLoading,
    statusError: state.tcmOffer.statusSet.error,

    previousCampaignList: state.tcmOffer.previousCampaign.list,
    previousCampaignIsLoaded: state.tcmOffer.previousCampaign.isLoaded,
    previousCampaignIsLoading: state.tcmOffer.previousCampaign.isLoading,
    previousCampaignError: state.tcmOffer.previousCampaign.error,

    commentIsLoaded: state.tcmOffer.comment.isLoaded,

    oldCarError: state.tcmOffer.export.error,
    oldCarIsLoading: state.tcmOffer.export.isLoading,
    oldCarIsLoaded: state.tcmOffer.export.isLoaded,
    oldCarData: state.tcmOffer.export.data,
  }),
  {
    fetchItem: fetchItemAction,
    setOffersStatus: setOffersStatusAction,
    oldCarExport: oldCarExportAction,
    oldCarExportReset: oldCarExportResetAction,
    resetOffersStatus: resetOffersStatusAction,
    fetchPreviousCampaign: fetchPreviousCampaignAction,
    fetchPreviousCampaignReset: fetchPreviousCampaignResetAction,
  },
)(ClientCard);
