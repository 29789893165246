import styled from 'styled-components';

export const Root = styled.div`
 margin-bottom: 30px;
`;

export const Description = styled.div`
  height: 2rem;
  font-size: 0.8rem;
  color: #696969;
  margin-top: 4px;
  margin-bottom: -20px;
`;

export const Content = styled.div`
`;

export const Active = styled.div`
  font-weight: bold;
`;

export const NonActive = styled.div`
  text-decoration: line-through;
`;

export const Inn = styled.div`
`;

export const Address = styled.div`
`;
