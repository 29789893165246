import {
  CREDIT_CALCULATOR_RE_CALC,
  CREDIT_CALCULATOR_RE_CALC__FAILURE, CREDIT_CALCULATOR_RE_CALC__RESET,
  CREDIT_CALCULATOR_RE_CALC__SUCCESS,
  CREDIT_CALCULATOR_SAVE,
  CREDIT_CALCULATOR_SAVE__FAILURE, CREDIT_CALCULATOR_SAVE__RESET,
  CREDIT_CALCULATOR_SAVE__SUCCESS,
} from 'redux/tcmCreditCalculator/actions';

const initState = {
  reCalc: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  saveResult: {
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function tcmCreditCalculator(state = initState, action) {
  switch (action.type) {
    case CREDIT_CALCULATOR_RE_CALC:
      return {
        ...state,
        reCalc: {
          ...state.reCalc,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    case CREDIT_CALCULATOR_RE_CALC__SUCCESS:
      return {
        ...state,
        reCalc: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    case CREDIT_CALCULATOR_RE_CALC__FAILURE:
      return {
        ...state,
        reCalc: {
          ...state.reCalc,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    case CREDIT_CALCULATOR_RE_CALC__RESET:
      return {
        ...state,
        reCalc: initState.reCalc,
      };

    case CREDIT_CALCULATOR_SAVE:
      return {
        ...state,
        saveResult: {
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    case CREDIT_CALCULATOR_SAVE__SUCCESS:
      return {
        ...state,
        saveResult: {
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    case CREDIT_CALCULATOR_SAVE__FAILURE:
      return {
        ...state,
        saveResult: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    case CREDIT_CALCULATOR_SAVE__RESET:
      return {
        ...state,
        saveResult: initState.saveResult,
      };

    default: {
      return state;
    }
  }
}
