import styled from 'styled-components';

const Root = styled.div`
  height: calc(100vh - 23rem);
  position: relative;
  overflow: auto;
`;

const ScrollView = styled.div`
  padding: 1rem;
  width: 50%;
`;

const FixedView = styled.div`
  position: absolute;
  width: calc(50% - 1rem);
  right: 1rem;
  top: 60px;
  height: 100%;
  overflow: auto;
  pointer-events: none;

  & img {
    opacity: 0.4;
    width: 100%;
  }
`;

export { Root, ScrollView, FixedView };
