import { API_TCM_URL as API } from 'settings/api';
import { getToken } from 'helpers/utility';
import { v4 as uuidv4 } from 'uuid';

export const exportReport = (
  { startAt, finishAt, campaignIds = [] },
) => {
  const URI = `${API}/tcm/report/campaign/export`;
  const data = {
    startAt, finishAt, campaignIds,
  };
  const query = {
    'X-Request-Id': uuidv4(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.blob())
    .then((blob) => blob);
};

export const saveReportTemplate = ({
  reportType, name, params,
}) => {
  const URI = `${API}/tcm/report/template/create`;
  const data = {
    type: reportType, name, params,
  };
  const query = {
    'X-Request-Id': uuidv4(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getTcmReportSettings = () => {
  const URI = `${API}/tcm/report/settings`;
  const query = {
    'X-Request-Id': uuidv4(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getTcmReportList = () => {
  const URI = `${API}/tcm/report/template/list`;
  const query = {
    'X-Request-Id': uuidv4(),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        type: 'campaign',
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};
