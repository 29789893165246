import React from 'react';
import { connect } from 'react-redux';
import { TCM } from 'components/tcm';

import {
  setSearch as setSearchAction,
  setCommonSearch as setCommonSearchAction,
} from 'redux/tcmOffer/actions';

import {
  CompanySearch, ContentHeaderWrapper, PageTitle,
} from './style';

const ContentHeader = ({
  // passed
  tableIsEmpty,
  withCommonSearch,

  // connect
  search,

  // actions
  setSearch,
  setCommonSearch,
}) => {
  function applySearch(value) {
    if (search !== value) {
      if (withCommonSearch) {
        setCommonSearch({ search: value });
      } else {
        setSearch({ search: value });
      }
    }
  }

  return (
    <ContentHeaderWrapper>
      <PageTitle>Клиенты</PageTitle>
      {!tableIsEmpty && (
        <CompanySearch>
          <TCM.Search onSearch={applySearch} value={search} notEmpty={Boolean(search)} />
        </CompanySearch>
      )}
    </ContentHeaderWrapper>
  );
};

export default connect(
  (state) => ({
    search: state.tcmOffer.collection.search,
  }),
  {
    setSearch: setSearchAction,
    setCommonSearch: setCommonSearchAction,
  },
)(ContentHeader);
