import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { TCM } from 'components/tcm';
import moment from 'moment';
import { ArrowSortingIcon, ClickHereIcon } from 'icons';
import {
  setFilters as setFiltersAction,
  setSorting as setSortingAction,
  resetFetchList as resetFetchListAction,
  fetch as fetchAction,
  fetchList as fetchListAction,
  setCampaignStatus as setCampaignStatusAction,
  resetSetCampaignStatus as resetSetCampaignStatusAction,
} from 'redux/tcmCampaign/actions';
import { CHAR } from 'settings/namedUnicode';
import { CAMPAIGN_STATUSES, MOMENT_DATE_FORMAT } from 'tcm/campaigns/data';
import { FilterIcon } from 'icons/FilterIcon';
import { CampaignsFilter } from 'components/tcm/CampaignsFilter/CampaignsFilter';
import { FilterTag } from 'components/tcm/MultipleSelect/style';
import { isDateExpired } from 'helpers/date';
import { SaveFormModal } from 'components/tcm/Modals';
import RejectCampaign from '../RejectCampaign';
import {
  ActionTextBtn,
  CampaignName,
  FilterBtn,
  Table,
  TableCell,
  TableTitleSecondaryStroke,
  TableTitleStroke,
  FilterTags, FilterWrapper,
  Container,
  SubName,
  SortingIconWrapper,
  SortingTableHeader,
  NoResults,
  LinkTable,
  TableContainer,
  Td,
} from './style';
import ContentHeader from '../ContentHeader';
import { EmptyTableContent } from '../CreateCampaignForm/style';
import { TableSortingTitle } from '../style';

const defaultSelectedCampaign = {
  showModal: false,
  id: null,
  nextStatus: null,
  title: '',
  btnTextOk: '',
  btnTextCancel: '',
};

const MainTable = ({
  // store
  list,
  filterTags,
  appliedFilters,
  sorting,
  searchQuery,
  isLoading,
  isLoaded,
  error,

  statusesList,
  setCampaignStatusIsLoading,
  setCampaignStatusIsLoaded,
  setCampaignStatusError,

  // actions
  setFilters,
  setSorting,
  resetFetchList,
  fetchList,
  fetchCampaign,
  setCampaignStatus,
  resetSetCampaignStatus,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showAddFirstCampaign, setShowAddFirstCampaign] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedCampaign, setSelectedCampaignModal] = useState(defaultSelectedCampaign);
  const location = useLocation();
  const { filter } = queryString.parse(location.search);

  useEffect(() => () => resetFetchList(), [resetFetchList]);

  useEffect(() => {
    const status = statusesList.find((item) => item.name === 'ALL');
    setShowAddFirstCampaign(status?.count === 0);
  }, [statusesList]);

  useEffect(() => {
    if (setCampaignStatusIsLoaded) {
      fetchList();
      resetSetCampaignStatus();
      setSelectedCampaignModal(defaultSelectedCampaign);
      setShowRejectModal(false);
    }
  }, [setCampaignStatusIsLoaded, resetSetCampaignStatus, setSelectedCampaignModal, setShowRejectModal, fetchList]);

  useEffect(() => {
    if (appliedFilters.status !== filter) {
      setFilters({
        filters: {
          ...appliedFilters,
          status: typeof filter === 'string' ? String(filter).toUpperCase() : undefined,
        },
      });
    }
  }, [setFilters, appliedFilters, filter]);

  const data = useMemo(() => list.map((item) => {
    const showNegativeAction = (item.negativeAction
      && (item.negativeAction.type !== 'withdrawCampaign'
        || (item.negativeAction.type === 'withdrawCampaign' && item.canClosed === true)));

    const negativeActionHandler = () => {
      switch (item.negativeAction.type) {
        case 'withdrawCampaign':
          // TODO сейчас в модалку данные приходят из redux, при оптимизации надо пересмотреть
          fetchCampaign(item.id);
          setShowRejectModal(true);
          break;
        case 'deleteCampaign':
          setSelectedCampaignModal({
            showModal: true,
            id: item.id,
            nextStatus: CAMPAIGN_STATUSES.DELETED,
            title: 'Удалить черновик кампании?',
            btnTextOk: 'Да, удалить',
            btnTextCancel: 'Нет, не удалять',
          });
          break;

        default:
          break;
      }
    };

    const positiveActionHandler = () => {
      switch (item.canSetNextStatus) {
        case CAMPAIGN_STATUSES.VIN_REQUEST:
          setSelectedCampaignModal({
            showModal: true,
            id: item.id,
            nextStatus: CAMPAIGN_STATUSES.VIN_REQUEST,
            title: "Перевести в статус 'Подбор VIN'?",
            btnTextOk: 'Да, перевести',
            btnTextCancel: 'Нет, не переводить',
          });
          break;
        case CAMPAIGN_STATUSES.KP_CALCULATION:
          setSelectedCampaignModal({
            showModal: true,
            id: item.id,
            nextStatus: CAMPAIGN_STATUSES.KP_CALCULATION,
            title: 'Отправить в банк?',
            btnTextOk: 'Да, отправить',
            btnTextCancel: 'Нет, не отправлять',
          });
          break;
        default:
          break;
      }
    };

    return {
      statusCode: item.statusCode,
      theme: (
        <TableCell maxWidth={144}>
          <LinkTable to={`/tcm/campaign/${item.id}`}>
            <TableTitleStroke isTheme>
              <CampaignName>{item.name}</CampaignName>
            </TableTitleStroke>
            <SubName>{item.subName}</SubName>
          </LinkTable>
        </TableCell>
      ),
      status: <TableTitleSecondaryStroke>{item.status}</TableTitleSecondaryStroke>,
      deadline: (
        <TableTitleStroke>
          {item.deadline ? (
            moment(item.deadline).isValid() ? moment(item.deadline).format(MOMENT_DATE_FORMAT.DD_MM_YYYY) : ''
          ) : ''}
        </TableTitleStroke>
      ),
      count: <TableTitleStroke>{item.countCarsInStock}</TableTitleStroke>,
      calling: (
        <TableCell maxWidth={122}>
          <TableTitleStroke>
            {item.dialingFrom ? moment(item.dialingFrom).format(MOMENT_DATE_FORMAT.DD_MM) : ''}
            {CHAR.NBSP}
            —
            {CHAR.NBSP}
            {item.dialingTo ? moment(item.dialingTo).format(MOMENT_DATE_FORMAT.DD_MM_YYYY) : ''}
          </TableTitleStroke>
        </TableCell>
      ),
      actionPositive: (item.canSetNextStatus && (
        <TCM.ButtonOutline widthSize="fixed" onClick={positiveActionHandler}>
          {item.positiveAction?.text}
        </TCM.ButtonOutline>
      )),
      actionNegative: (showNegativeAction && (
        <ActionTextBtn onClick={negativeActionHandler}>
          {item.negativeAction.text}
        </ActionTextBtn>
      )),
    };
  }), [list, setShowRejectModal, setSelectedCampaignModal, fetchCampaign]);

  const themeTitle = useMemo(() => (
    <>
      <p>Тема</p>
      <p>Подтема</p>
    </>
  ), []);

  const columns = useMemo(() => [
    {
      Header: themeTitle,
      accessor: 'theme', // accessor is the "key" in the data
      sortKey: 'subtopic',
    },
    {
      Header: 'Статус',
      accessor: 'status',
      sortKey: 'status',
    },
    {
      Header: 'Срок до',
      accessor: 'deadline',
      sortKey: 'deadline',
    },
    {
      Header: (
        <>
          Количество
          <br />
          автомобилей
        </>
      ),
      accessor: 'count',
      sortKey: 'countCarsInStock',
    },
    {
      Header: 'Обзвон',
      accessor: 'calling',
      sortKey: 'dialingFrom',
    },
    {
      Header: '',
      accessor: 'actionPositive',
    },
    {
      Header: !filterTags.length && (
        <FilterBtn onClick={() => setShowFilter(!showFilter)}>
          <FilterIcon />
        </FilterBtn>
      ),
      accessor: 'actionNegative',
      minWidth: 72,
      width: 72,
    },
  ], [filterTags.length, showFilter, themeTitle]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { columns, data },
    useSortBy,
  );

  const deleteTagHandler = (tag) => {
    if (['topics', 'subtopics'].includes(tag.key)) {
      const currentTagIndex = appliedFilters[tag.key].findIndex((t) => t.label === tag.label);
      if (currentTagIndex !== -1) {
        const prevCurrentTypeTags = appliedFilters[tag.key].slice(0, currentTagIndex);
        const nextCurrentTypeTags = appliedFilters[tag.key].slice(currentTagIndex + 1);

        setFilters({
          filters: {
            ...appliedFilters,
            [tag.key]: [...prevCurrentTypeTags, ...nextCurrentTypeTags],
          },
        });
      }
    } else {
      setFilters({
        filters: {
          ...appliedFilters,
          [tag.key]: undefined,
        },
      });
    }
  };

  const isStatusEmpty = useMemo(() => !filterTags.length && !list.length && filter && isLoaded && !searchQuery,
    [filter, isLoaded, list.length, searchQuery, filterTags]);

  const isNoCampaigns = useMemo(
    () => !list.length
      && !filterTags.length
      && !searchQuery
      && isLoaded
      && showAddFirstCampaign,
    [searchQuery, filterTags.length, isLoaded, list.length, showAddFirstCampaign],
  );

  const isSearchNoResults = useMemo(() => searchQuery && !list.length && isLoaded,
    [searchQuery, isLoaded, list.length]);

  const isFilteredTableEmpty = useMemo(
    () =>
      !list.length
      && (Boolean(filterTags.length)
        && !searchQuery)
      && isLoaded,
    [list.length, filterTags.length, searchQuery, isLoaded],
  );

  const displayTable = useMemo(() =>
    !isNoCampaigns
    && !isFilteredTableEmpty
    && !isSearchNoResults, [isFilteredTableEmpty, isSearchNoResults, isNoCampaigns]);

  const handleClose = () => {
    setSelectedCampaignModal(defaultSelectedCampaign);
    setShowRejectModal(false);
    resetSetCampaignStatus();
  };

  const handleRejectClose = () => {
    setShowRejectModal(false);
  };

  return (
    <Container>
      <ContentHeader
        tableIsEmpty={isNoCampaigns}
      />

      {Boolean(filterTags.length) && (
        <FilterWrapper>
          <FilterTags>
            {filterTags.map((tag) => (
              <FilterTag
                key={tag.label}
                label={tag.label}
                onClose={() => deleteTagHandler(tag)}
              />
            ))}
          </FilterTags>
          <FilterBtn onClick={() => setShowFilter(!showFilter)}>
            <FilterIcon />
          </FilterBtn>
        </FilterWrapper>
      )}
      <TableContainer className="container">
        {isLoading && (
          <TCM.Loader text="Идет загрузка списка кампаний..." />
        )}
        {displayTable && (
          <Table hide={isLoading} {...getTableProps()}>
            <thead>
              {
                headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      headerGroup.headers.map((column) => {
                        if (!['actionPositive', 'actionNegative'].includes(column.id)) {
                          return (
                            <th
                              {...column.getHeaderProps()}
                              onClick={() =>
                                column.sortKey && setSorting({ sortBy: column.sortKey })}
                            >
                              <SortingTableHeader>
                                <TableSortingTitle isActive={sorting?.sortBy === column.sortKey}>
                                  {column.render('Header')}
                                </TableSortingTitle>
                                <SortingIconWrapper>
                                  {sorting?.sortBy === column.sortKey
                                    && (
                                      <>
                                        {
                                          sorting.order === 'desc'
                                            ? <ArrowSortingIcon />
                                            : <ArrowSortingIcon up />
                                        }
                                      </>
                                    )}
                                </SortingIconWrapper>
                              </SortingTableHeader>
                            </th>
                          );
                        }
                        return (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                        );
                      })
                    }
                  </tr>
                ))
              }
            </thead>

            <tbody {...getTableBodyProps()}>
              {
                rows.map((row) => {
                  const deadline = row.original.deadline.props.children;
                  const calling = row.original.calling.props.children.props.children[4];
                  const { statusCode } = row.original;
                  const canBeExpired = !(statusCode === CAMPAIGN_STATUSES.DRAFT
                    || statusCode === CAMPAIGN_STATUSES.COMPLETE
                    || statusCode === CAMPAIGN_STATUSES.CLOSED);
                  const expiredDeadline = canBeExpired ? isDateExpired(deadline) : false;
                  const expiredCalling = canBeExpired ? isDateExpired(calling) : false;
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {
                        row.cells.map((cell) => {
                          if (cell.column.id === 'actionPositive') {
                            return (
                              <Td {...cell.getCellProps()} data-width="200">
                                {cell.render('Cell')}
                              </Td>
                            );
                          }
                          if (cell.column.id === 'actionNegative') {
                            return (
                              <Td {...cell.getCellProps()} data-width="72">
                                {cell.render('Cell')}
                              </Td>
                            );
                          }
                          if (cell.column.id === 'count') {
                            return (
                              <Td {...cell.getCellProps()} data-width="100">
                                {cell.render('Cell')}
                              </Td>
                            );
                          }
                          if (cell.column.id === 'deadline') {
                            return (
                              <Td {...cell.getCellProps()} isExpired={expiredDeadline}>
                                {cell.render('Cell')}
                              </Td>
                            );
                          }
                          if (cell.column.id === 'calling') {
                            return (
                              <Td {...cell.getCellProps()} isExpired={expiredCalling}>
                                {cell.render('Cell')}
                              </Td>
                            );
                          }
                          return (
                            <Td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </Td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        )}
      </TableContainer>

      {isNoCampaigns && (
        <EmptyTableContent>
          <p>
            Здесь появится список кампаний.
          </p>
          <p>
            Создайте первую кампанию!
          </p>
          <ClickHereIcon />
        </EmptyTableContent>
      )}

      {isFilteredTableEmpty && (
        <NoResults>
          <p>Кампаний с такими параметрами не найдено. </p>
          <TCM.ButtonOutline
            onClick={() => setShowFilter(true)}
          >
            Изменить параметры фильтра
          </TCM.ButtonOutline>
        </NoResults>
      )}

      {isSearchNoResults && (
        <NoResults>
          <p>Ничего не найдено. </p>
        </NoResults>
      )}

      {isStatusEmpty && (
        <NoResults>
          <p>Кампаний с таким статусом пока нет.</p>
        </NoResults>
      )}

      {error && (
        <p>
          {error}
        </p>
      )}

      <CampaignsFilter
        isVisible={showFilter}
        onClose={() => setShowFilter(false)}
      />

      <RejectCampaign
        visible={showRejectModal}
        close={handleRejectClose}
        blockGoBack
        fetchList={fetchList}
      />

      <SaveFormModal
        visible={selectedCampaign.showModal}
        onCancel={handleClose}
        onOk={() => setCampaignStatus({ id: selectedCampaign.id, status: selectedCampaign.nextStatus })}
        onOkLoading={setCampaignStatusIsLoading}
        onOkError={setCampaignStatusError && 'Ошибка сети'}
        onClose={handleClose}
        title={selectedCampaign.title}
        btnTextOk={selectedCampaign.btnTextOk}
        btnTextCancel={selectedCampaign.btnTextCancel}
      />

    </Container>
  );
};

export default connect(
  (state) => ({
    list: state.tcmCampaign.collection.list,
    filterTags: state.tcmCampaign.collection.filters.tags,
    appliedFilters: state.tcmCampaign.collection.filters.data,
    sorting: state.tcmCampaign.collection.sorting,
    searchQuery: state.tcmCampaign.collection.search,
    isLoading: state.tcmCampaign.collection.isLoading,
    isLoaded: state.tcmCampaign.collection.isLoaded,
    error: state.tcmCampaign.collection.error,
    statusesList: state.tcmCampaign.statuses.list,

    setCampaignStatusIsLoading: state.tcmCampaign.campaignsSet.isLoading,
    setCampaignStatusIsLoaded: state.tcmCampaign.campaignsSet.isLoaded,
    setCampaignStatusError: state.tcmCampaign.campaignsSet.error,
  }),
  {
    setFilters: setFiltersAction,
    setSorting: setSortingAction,
    resetFetchList: resetFetchListAction,
    fetchList: fetchListAction,
    fetchCampaign: fetchAction,
    setCampaignStatus: setCampaignStatusAction,
    resetSetCampaignStatus: resetSetCampaignStatusAction,
  },
)(MainTable);
