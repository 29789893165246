import { CODEBOOK } from 'settings';

const PHONE_INDEXES = Object.keys(CODEBOOK.phoneTypes);

export const formatCrifResponse = (response) => {
  if (Array.isArray(response)) {
    return response.map((e) => {
      const documents = (Array.isArray(e.personDocument) ? e.personDocument : []).filter(
        (d) => d.crmId === e.crmId,
      );

      return {
        crmId: e.crmId,
        fiveNtId: e.fiveNtId,
        groupId: e.groupId,
        moratorium: e.moratorium,
        moratoriumDateEnd: e.moratoriumDateEnd,
        data: Array.isArray(e.personData) ? e.personData[0] : {},
        address:
          Array.isArray(e.personAddress) && e.personAddress.length > 0
            ? e.personAddress[0]
            : {},

        contact:
          Array.isArray(e.personContact) && e.personContact.length > 0
            ? e.personContact.sort(
              (x, y) =>
                PHONE_INDEXES.indexOf(x.contactType)
                  - PHONE_INDEXES.indexOf(y.contactType),
            )[0]
            : {},

        document: {
          birthdate: e.birthdate,
          birthplace: e.birthplace,
          passport: documents.find((e) => e.docTypeId === 1) || {},
          oldPassport: documents.find((e) => e.docTypeId === 2) || {},
        },
      };
    });
  }
  return [];
};
