import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { imageToBase64 } from 'utils';
import { v1 as getId } from 'uuid';

export const fetchFile = ({ accessToken, name }) => {
  const URI = `${API}/creditrequest/file/content`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        fieldName: name,
        accessToken,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const uploadFile = ({ accessToken, file, fieldName }) => {
  const URI = `${API}/creditrequest/file/upload`;

  return imageToBase64(file).then((base64) => {
    const query = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Request-Id': getId(),
      },
      body: JSON.stringify({
        accessToken: getToken(),
        data: {
          accessToken,
          fileName: file.name,
          fileType: file.type,
          fieldName,
          content: base64,
        },
      }),
    };

    return fetch(URI, query)
      .then((response) => response.json())
      .then((json) => json);
  });
};

export const checkFile = ({ accessToken }) => {
  const URI = `${API}/creditrequest/file/check`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        accessToken,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const confirmFile = ({ accessToken, fieldName, form }) => {
  const URI = `${API}/creditrequest/file/update-form-data`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,
      fieldName,
      data: form,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const deleteFile = ({ accessToken, form, fieldName }) => {
  const URI = `${API}/creditrequest/file/delete`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,
      data: form,
      fieldName,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetch: fetchFile,
  upload: uploadFile,
  check: checkFile,
  confirm: confirmFile,
  delete: deleteFile,
};
