import React from 'react';

import { ErrorMessage } from './style';

function Error({ error, area }) {
  return error ? (
    <ErrorMessage type="danger" area={area}>
      {error}
    </ErrorMessage>
  ) : null;
}

export default Error;
