import React from 'react';
import styled from 'styled-components';
import { key } from 'styled-theme';
import { GRID_AREAS } from 'settings';
import { Select } from 'antd';

const SelectStyled = styled(({ area, ...p }) => (
  <Select {...p} allowClear>
    {p.hasDefault && <Select.Option value="" key="">Не выбрано</Select.Option>}
    {p.list && p.list.map((e) => (
      <Select.Option disabled={e.disabled} key={(e.id || e.value)} value={(e.id || e.value)}>
        {(e.name || e.label)}
      </Select.Option>
    ))}
  </Select>
))`

    &.ant-select {
      ${({ area }) => typeof area === 'boolean' && `
        width: 100%;
        grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
      `};
      
    }

    :not(.ant-input-group-addon) > &.ant-select {
      width: 100%;
      max-width: 400px;
      outline: none;

      ${({ area }) => typeof area === 'boolean' && `
        width: 100%;
        grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
      `}
    }

    .ant-select-selection--single {
      display: flex;
      align-items: center;
      ${key('defaultInput')}
    }

    :not(.ant-select-disabled) > .ant-select-focused .ant-select-selection,
    :not(.ant-select-disabled) > .ant-select-open .ant-select-selection,
    :not(.ant-select-disabled) > .ant-select-selection:focus,
    :not(.ant-select-disabled) > .ant-select-selection:active {
      ${key('input.focus')}
    }

    :not(.ant-select-disabled) > .ant-select-selection:hover {
      ${key('input.hover')}
    }

    .ant-select-selection-selected-value {
      color: ${key('input.color')};
    }
    
    &.ant-select-single.ant-select-lg {
      .ant-select-selector {
        height: 50px;
        border-radius: 5px;
        
        .ant-select-selection-search-input {
          height: 50px;
        }
        
        .ant-select-selection-item {
          line-height: 50px;
        }
      }
    }
`;

export { SelectStyled };
