import React from 'react';
import { connect } from 'react-redux';
import { TCM } from 'components/tcm';

import {
  setSearch as setSearchAction,
} from 'redux/tcmCampaignOffer/actions';

import {
  Root,
  Title,
  SearchContainer,
} from './style';

const Header = ({
  search,
  setSearch,
}) => {
  function applySearch(value) {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  return (
    <Root>
      <Title>
        Выберите клиентов, которых надо распределить:
      </Title>
      <SearchContainer>
        <TCM.Search
          onSearch={applySearch}
          notEmpty={Boolean(search)}
        />
      </SearchContainer>
    </Root>
  );
};

export default connect(
  (state) => ({
    search: state.tcmCampaignOffer.collection.search,
  }),
  {
    setSearch: setSearchAction,
  },
)(Header);
