import React, { useEffect, useReducer, useState } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { connect } from 'react-redux';
import { Form as LegacyForm } from '@ant-design/compatible';

import {
  Button, message, Popconfirm, Result,
} from 'antd';
import FileConfirmation from 'components/FileConfirmation';
import Upload from 'components/Upload';
import Loader from 'components/Loader';
import { flattenFields } from 'utils';

import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import { TYPES, FILE_SLOTS } from 'settings';

import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

import { patch as patchRequest, save as saveRequest } from 'redux/request/actions';

import { fetchList as fetchCrifList } from 'redux/crif/actions';

import {
  ContentContainer,
  ContentView,
  AsideCollapse,
  Confirmation,
  ErrorMessage,
} from './style';

import Switch from '../../Switch';

const getBlockWithError = (fields) => {
  const tabs = fields.filter((e) => e.type === TYPES.TABS);
  const fieldsInTabs = flattenFields(tabs).filter(
    (e) => e.error && e.type !== TYPES.HIDDEN,
  );
  return fieldsInTabs.length > 0 ? tabs[0].name : null;
};

const getFieldWithError = (fields) => {
  const fieldsWithError = flattenFields(
    fields.filter((e) => e.type !== TYPES.MAIN),
  ).filter((e) => e.name === TYPES.CRM_SELECT || (e.error && e.type !== TYPES.HIDDEN));
  return fieldsWithError.length > 0 ? fieldsWithError[0].name : null;
};

const initialState = {
  isScrolling: false,
  scrollUnit: null,
  scrollTab: null,
};

const ACTIONS = {
  SCROLL_START: 'SCROLL_START',
  SCROLL_TO_BLOCK: 'SCROLL_TO_BLOCK',
  SCROLL_TO_FIELD: 'SCROLL_TO_FIELD',
  SCROLL_TO_TAB: 'SCROLL_TO_TAB',
  SCROLL_FINISH: 'SCROLL_FINISH',
};

const containerStyle = {
  height: 'calc(100vh - 23rem)',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SCROLL_START: {
      return {
        ...state,
        isScrolling: true,
      };
    }
    case ACTIONS.SCROLL_TO_BLOCK: {
      return {
        ...state,
        scrollUnit: action.unit,
      };
    }
    case ACTIONS.SCROLL_TO_FIELD: {
      return {
        ...state,
        scrollUnit: action.unit,
      };
    }
    case ACTIONS.SCROLL_TO_TAB: {
      return {
        ...state,
        isScrolling: true,
        scrollUnit: action.unit,
        scrollTab: action.tab,
      };
    }
    case ACTIONS.SCROLL_FINISH: {
      return {
        ...state,
        scrollUnit: null,
        isScrolling: false,
      };
    }
    default: {
      return state;
    }
  }
}

function FormComponent({
  // passed
  id,
  form,
  isActive,

  // connect
  fields,
  values,
  formIsDisabled,
  queue,
  popups,
  fetchIsLoading,
  fetchIsLoaded,
  fetchError,
  patchIsLoading,
  patchError,
  saveId,
  saveIsLoading,
  saveIsLoaded,
  saveError,
  fileToProccess,
  files,
  // crifId,
  // crifIsLoading,
  // crifIsLoaded,
  // crifError,

  // actions
  patchRequest,
  saveRequest,
  // fetchCrifList,
}) {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isReady, setReady] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isAccessForbidden = popups.find((e) => e.type === TYPES.ACCESS);

  function onScrollCallback(fieldWithError = null) {
    if (fieldWithError) {
      dispatch({ type: ACTIONS.SCROLL_TO_FIELD, unit: fieldWithError });
    } else {
      dispatch({ type: ACTIONS.SCROLL_FINISH });
    }
  }

  // useEffect(() => {
  //   if (fetchIsLoaded && crifId !== id && !crifIsLoading && !crifIsLoaded && !crifError) {
  //     fetchCrifList({ accessToken: id });
  //   }
  // }, [fetchCrifList, fetchIsLoaded, crifIsLoading, crifIsLoaded, crifError, crifId, id]);

  useEffect(() => {
    const file = files[fileToProccess];
    if (fileToProccess && file && !file.isLoading) {
      if (FILE_SLOTS.first.fields.includes(fileToProccess)) {
        dispatch({
          type: ACTIONS.SCROLL_TO_TAB,
          unit: FILE_SLOTS.first.parent,
          tab: FILE_SLOTS.first.tab,
        });
      } else if (FILE_SLOTS.second.fields.includes(fileToProccess)) {
        dispatch({
          type: ACTIONS.SCROLL_TO_TAB,
          unit: FILE_SLOTS.second.parent,
          tab: FILE_SLOTS.second.tab,
        });
      }
    }
    // eslint-disable-next-line
  }, [fileToProccess]);

  useEffect(() => {
    if (
      state.isScrolling
      && state.scrollUnit === null
      && !saveIsLoading
      && saveIsLoaded
    ) {
      const blockWithError = getBlockWithError(fields);
      if (blockWithError) {
        dispatch({ type: ACTIONS.SCROLL_TO_BLOCK, unit: blockWithError });
      } else {
        const fieldWithError = getFieldWithError(fields);
        if (fieldWithError) {
          dispatch({ type: ACTIONS.SCROLL_TO_FIELD, unit: fieldWithError });
        }
      }
    }
  }, [state.scrollUnit, state.isScrolling, saveIsLoading, saveIsLoaded, fields]);

  useEffect(() => {
    if (formIsDirty && !saveIsLoading && saveIsLoaded) {
      message.success('Данные были успешно сохранены', 7);
      setFormIsDirty(false);
    }
  }, [saveIsLoading, saveIsLoaded, formIsDirty]);

  useEffect(() => {
    if (!isReady && isActive && fetchIsLoaded) {
      setReady(true);
    } else if (isReady && !fetchIsLoaded) {
      setReady(false);
    }
  }, [isActive, fetchIsLoaded, isReady]);

  function onChangeField(userInput, exclude) {
    patchRequest({
      userInput,
      ...(Array.isArray(exclude) ? { exclude } : {}),
    });
  }

  function onSubmit(e) {
    e.preventDefault();
  }

  function handleFormSubmit() {
    saveRequest();
    setFormIsDirty(true);
    dispatch({ type: ACTIONS.SCROLL_START });
  }

  function handleOnBeforeUnload(e) {
    if (form.isFieldsTouched()) {
      e.preventDefault();
      return 'Несохраненные данные будут потеряны!';
    }
  }

  const saveButtonIsDisabled = formIsDisabled || patchIsLoading;
  const isLoading = (saveId === id && saveIsLoading) || patchIsLoading;
  const error = (saveId === id && saveError) || patchError;

  return (
    <Beforeunload onBeforeunload={handleOnBeforeUnload}>
      {popups.map((field) => Switch({ field }))}
      {fetchIsLoading && <Loader text="Загрузка данных кредита" />}
      {fetchError && <span>{fetchError}</span>}
      {isReady && (
        <>
          {!isAccessForbidden ? (
            <ReflexContainer
              windowResizeAware
              style={containerStyle}
              orientation="vertical"
            >
              <ReflexElement>
                <AsideCollapse onClick={() => setCollapsed(!collapsed)}>
                  {collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                </AsideCollapse>
                <ContentView>
                  <ContentContainer>
                    <LegacyForm onSubmit={onSubmit}>
                      {fields.map((field) =>
                        Switch({
                          field,
                          props: {
                            form,
                            values,
                            formIsDisabled,
                            queue,
                            onChangeField,
                            fieldToScroll: {
                              name: state.scrollUnit,
                              tab: state.scrollTab,
                            },
                            onScrollCallback,
                          },
                        }))}
                    </LegacyForm>
                    <Confirmation>
                      <Popconfirm
                        title="Вы хотите сохранить изменения?"
                        onConfirm={handleFormSubmit}
                        onCancel={() => null}
                        okText="Да, сохранить"
                        cancelText="Нет"
                        disabled={saveButtonIsDisabled}
                      >
                        <Button
                          type="primary"
                          size="large"
                          loading={isLoading}
                          disabled={saveButtonIsDisabled}
                        >
                          {patchIsLoading ? 'Заявка обновляется...' : 'Сохранить'}
                        </Button>
                      </Popconfirm>
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Confirmation>
                  </ContentContainer>
                </ContentView>
              </ReflexElement>

              <ReflexSplitter />

              {!collapsed && (
                <ReflexElement minSize={600} maxSize={1200}>
                  <Upload id={id} />
                </ReflexElement>
              )}

              <ReflexElement minSize={180} maxSize={180}>
                <FileConfirmation />
              </ReflexElement>
            </ReflexContainer>
          ) : (
            <Result status="warning" title={isAccessForbidden.text} />
          )}
        </>
      )}
    </Beforeunload>
  );
}

export default connect(
  (state) => ({
    fields: state.request.fields,
    fieldsFlat: state.request.fieldsFlat,
    values: state.request.values,
    formIsDisabled: state.request.formIsDisabled,
    queue: state.request.requestQueue,
    popups: state.request.popups,
    fetchId: state.request.fetch.id,
    fetchIsLoading: state.request.fetch.isLoading,
    fetchIsLoaded: state.request.fetch.isLoaded,
    fetchError: state.request.fetch.error,
    patchIsLoading: state.request.patch.isLoading,
    patchIsLoaded: state.request.patch.isLoaded,
    patchError: state.request.patch.error,
    saveId: state.request.save.id,
    saveIsLoading: state.request.save.isLoading,
    saveIsLoaded: state.request.save.isLoaded,
    saveError: state.request.save.error,
    fileToProccess: state.request.fileToProccess,
    files: state.request.files,
    crifId: state.crif.id,
    crifIsLoading: state.crif.isLoading,
    crifIsLoaded: state.crif.isLoaded,
    crifError: state.crif.error,
    crifButtonIsLocked: state.crif.buttonIsLocked,
  }),
  {
    patchRequest,
    saveRequest,
    fetchCrifList,
  },
)(FormComponent);
