import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { useForm } from 'hooks';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';
import { initialFilters } from 'redux/dealerCenter/reducer';
import { Link, useParams } from 'react-router-dom';

import {
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
} from 'redux/dealerCenter/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  Root,
  Header,
  HeaderTitle,
  Title,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
} from './style';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 460px)' };

function List({
  // connect
  data,
  list,
  sorting,
  isLoading,
  error,
  pagination,

  // actions
  setTablePage,
  setSorting,
  setFilters,
  resetTable,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);

  const { companyId } = useParams();

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    resetTable();
    setFilters({
      filters: {
        ...form,
        organization: data.name,
      },
    });
    return () => resetTable();
  }, [form, resetTable, setFilters, data]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  const columns = [
    {
      key: 'salePoint',
      title: () =>
        TableHeader({
          title: 'Название',
          sortBy: 'salePoint',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'salePoint',
          filterValue: form.salePoint,
          filterOnChange: updateForm,
        }),
      render: ({ salePoint, id }) => (
        <Link to={`/app/management/companies/${companyId}/dealerCenters/${id}`}>
          <span>{ salePoint }</span>
        </Link>
      ),
      width: 240,
    },
    {
      key: 'address',
      title: () =>
        TableHeader({
          title: 'Адрес',
          sortBy: 'address',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'address',
          filterValue: form.address,
          filterOnChange: updateForm,
        }),
      render: ({ address, id }) => (
        <Link to={`/app/management/companies/${companyId}/dealerCenters/${id}`}>
          <span>{address}</span>
        </Link>
      ),
      width: 240,
    },
    {
      key: 'phone',
      title: () =>
        TableHeader({
          title: 'Телефон',
          sortBy: 'phone',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'phone',
          filterValue: form.phone,
          filterOnChange: updateForm,
        }),
      render: ({ phone, id }) => (
        <Link to={`/app/management/companies/${companyId}/dealerCenters/${id}`}>
          <span>{phone}</span>
        </Link>
      ),
      width: 240,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>Все Точки продаж</Title>
        </HeaderTitle>
      </Header>

      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    data: state.company.item.data,
    list: state.dealerCenter.collection.list,
    companyList: state.dealerCenter.collection.companyList,
    sorting: state.dealerCenter.collection.sorting,
    filters: state.dealerCenter.collection.filters,
    search: state.dealerCenter.collection.search,
    isLoaded: state.dealerCenter.collection.isLoaded,
    isLoading: state.dealerCenter.collection.isLoading,
    error: state.dealerCenter.collection.error,
    pagination: state.dealerCenter.collection.pagination || {},
  }),
  {
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
