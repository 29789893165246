import { brand as brandData } from 'containers/App/TCM/ClientCard/VisitForm/data';
import {
  FirstRow, SecondRow, ThirdRow, RadioGroup, FormFooter, RowItem,
} from 'containers/App/TCM/ClientsTable/ClientsFilter/style';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';

import { TCM } from 'components/tcm';
import withTheme from 'components/tcm/withTheme';
import { connect } from 'react-redux';
import {
  fetchLexusModels as fetchLexusModelsAction,
  fetchToyotaModels as fetchToyotaModelsAction,
} from 'redux/common/actions';
import { initialFilters } from 'redux/tcmOffer/reducer';

import styled, { css } from 'styled-components';
import { CallStatusesCode, VisitStatusCode } from 'tcm/offer/data';
import { formFields } from './data';

export const Wrapper = styled.div`
  margin-bottom: 90px;
`;

export const Title = withTheme(styled.p`
  ${({ theme }) => css`
  margin-top: 18px;
  font-family: ${theme.toyotaFontFamily};
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -1.3px;
  color: ${theme.palette.black};
`}`);

const EventState = {
  NotNecessary: 'notNecessary',
  Were: 'were',
  WereNot: 'wereNot',
};

const getCallsCountFromForm = (callsCount) => {
  switch (callsCount) {
    case EventState.WereNot:
      return {
        callCountMustBeLess: 0,
      };
    case EventState.Were:
      return {
        callCountMustBeMore: 1,
      };
    default:
      return {};
  }
};

const getVisitsCountFromForm = (visitsCount) => {
  switch (visitsCount) {
    case EventState.WereNot:
      return {
        visitCountMustBeLess: 0,
      };
    case EventState.Were:
      return {
        visitCountMustBeMore: 1,
      };
    default:
      return {};
  }
};

const getCallLabelFromCode = (callCode) => {
  switch (callCode) {
    case CallStatusesCode.Recall:
      return 'Предложение интересно, перезвонить позднее';
    case CallStatusesCode.NotOwner:
      return 'Перезвонить другому человеку';
    case CallStatusesCode.VisitToDc:
      return 'Предложение интересно, клиент приедет в ДЦ';
    case CallStatusesCode.NotClient:
      return 'Ответил не клиент';
    case CallStatusesCode.NotInterested:
      return 'Предложение неинтересно';
    case CallStatusesCode.NotCall:
      return 'Негатив клиента, не звонить';
    case CallStatusesCode.NegativeRecall:
      return 'Перезвонить';
    case CallStatusesCode.NotRecall:
      return 'Не перезванивать';
    default:
      console.error('getCallLabelFromCode. callCode parameter must be in CallStatusesCode');
      return '';
  }
};

const getVisitLabelFromCode = (visitCode) => {
  switch (visitCode) {
    case VisitStatusCode.TradeIn:
      return 'Оценка Trade-in';
    case VisitStatusCode.TestDrive:
      return 'Тест драйв';
    case VisitStatusCode.Registration:
      return 'Подача заявки';
    case VisitStatusCode.ContractConcluded:
      return 'Оформление кредитного договора';
    default:
      console.error('getVisitLabelFromCode. visitCode parameter must be in VisitStatusesCode');
      return '';
  }
};

const callStatusOptions = [
  {
    value: CallStatusesCode.Recall,
    label: 'Предложение интересно, перезвонить позднее',
  },
  {
    value: CallStatusesCode.NotOwner,
    label: 'Перезвонить другому человеку',
  },
  {
    value: CallStatusesCode.VisitToDc,
    label: 'Предложение интересно, клиент приедет в ДЦ',
  },
  {
    value: CallStatusesCode.NotClient,
    label: 'Ответил не клиент',
  },
  {
    value: CallStatusesCode.NotInterested,
    label: 'Предложение неинтересно',
  },
  {
    value: CallStatusesCode.NotCall,
    label: 'Негатив клиента, не звонить',
  },
  {
    value: CallStatusesCode.NegativeRecall,
    label: 'Перезвонить',
  },
  {
    value: CallStatusesCode.NotRecall,
    label: 'Не перезванивать',
  },
];

const visitStatusOptions = [
  {
    value: VisitStatusCode.TradeIn,
    label: 'Оценка Trade-in',
  },
  {
    value: VisitStatusCode.TestDrive,
    label: 'Тест драйв',
  },
  {
    value: VisitStatusCode.Registration,
    label: 'Подача заявки',
  },
  {
    value: VisitStatusCode.ContractConcluded,
    label: 'Оформление кредитного договора',
  },
];

const formInitialState = {
  [formFields.callsCount.key]: EventState.NotNecessary,
  [formFields.visitsCount.key]: EventState.NotNecessary,
};

const ClientsFilter = ({
  visible,
  onClose,
  onFilter,
  campaigns,
  appliedFilters,

  toyotaModels,
  isToyotaModelsLoading,
  isToyotaModelsLoaded,

  lexusModels,
  isLexusModelsLoading,
  isLexusModelsLoaded,

  fetchToyotaModels,
  fetchLexusModels,
}) => {
  const [callsState, setCallsState] = useState();
  const [visitsState, setVisitsState] = useState();
  const [brand, setBrand] = useState('');

  const [form] = Form.useForm();

  const testDriveModels = useMemo(() => {
    switch (brand) {
      case 'Toyota':
        return isToyotaModelsLoaded ? toyotaModels : [];
      case 'Lexus':
        return isLexusModelsLoaded ? lexusModels : [];
      default:
        return [];
    }
  }, [
    brand,
    isToyotaModelsLoaded,
    toyotaModels,
    isLexusModelsLoaded,
    lexusModels,
  ]);

  const isModelLoading = isToyotaModelsLoading || isLexusModelsLoading;

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        [formFields.dateFrom.key]: appliedFilters.termFulfillmentFrom
          ? moment(appliedFilters.termFulfillmentFrom)
          : undefined,
        [formFields.dateTo.key]: appliedFilters.termFulfillmentTo
          ? moment(appliedFilters.termFulfillmentTo)
          : undefined,
        [formFields.campaign.key]: appliedFilters.campaignId?.value,
        [formFields.brand.key]: appliedFilters.brands[0]?.value,
        [formFields.model.key]: appliedFilters.models[0]?.value,
        [formFields.vin.key]: appliedFilters.vin,
        [formFields.callsCount.key]: appliedFilters.callCountMustBeMore === 1
          ? EventState.Were
          : appliedFilters.callCountMustBeLess === 0
            ? EventState.WereNot
            : EventState.NotNecessary,
        [formFields.visitsCount.key]: appliedFilters.visitCountMustBeMore === 1
          ? EventState.Were
          : appliedFilters.visitCountMustBeLess === 0
            ? EventState.WereNot
            : EventState.NotNecessary,
        [formFields.callStatus.key]: appliedFilters.callStatus?.value,
        [formFields.visitStatuses.key]: appliedFilters.visitStatuses?.map((visit) => visit.value),
      });
    }
  }, [form, appliedFilters, visible]);

  const onFinish = (values) => {
    const {
      [formFields.dateFrom.key]: termFulfillmentFrom,
      [formFields.dateTo.key]: termFulfillmentTo,
      [formFields.campaign.key]: campaignId,
      [formFields.brand.key]: brands,
      [formFields.model.key]: models,
      [formFields.vin.key]: vin,
      [formFields.callsCount.key]: callsCount,
      [formFields.visitsCount.key]: visitsCount,
      [formFields.callStatus.key]: callStatus,
      [formFields.visitStatuses.key]: visitStatuses,
    } = values;

    const filters = {
      termFulfillmentFrom: termFulfillmentFrom?.isValid()
        ? termFulfillmentFrom.toISOString()
        : undefined,
      termFulfillmentTo: termFulfillmentTo?.isValid()
        ? termFulfillmentTo.toISOString()
        : undefined,
      campaignId: campaignId
        ? {
          value: campaignId,
          label: campaigns.find((campaign) => campaign.value === campaignId)?.label,
        }
        : undefined,
      brands: brands ? [{
        value: brands,
        label: brands,
      }] : [],
      models: models ? [{
        value: models,
        label: models,
      }] : [],
      callStatus: callStatus ? {
        value: callStatus,
        label: getCallLabelFromCode(callStatus),
      } : undefined,
      visitStatuses: visitStatuses?.map((visit) => ({
        value: visit,
        label: getVisitLabelFromCode(visit),
      })),
      vin,
      ...getCallsCountFromForm(callsCount),
      ...getVisitsCountFromForm(visitsCount),
    };

    onFilter({ filters });
    onClose();
  };

  const onReset = () => {
    form.resetFields();
    onFilter({ filters: initialFilters });
  };

  const onCallsStatusChange = (event) => {
    setCallsState(event.target.value);
  };
  const onVisitsStatusChange = (event) => {
    setVisitsState(event.target.value);
  };

  function removeUnnecessarySpaces({ target: { name, value } }) {
    form.setFields([{ name, value: value.trim() }]);
  }

  useEffect(() => {
    fetchToyotaModels();
    fetchLexusModels();
  }, [fetchLexusModels, fetchToyotaModels]);

  return (
    <TCM.Modal
      visible={visible}
      onCancel={onClose}
    >
      <Wrapper>
        <Title>Фильтр по клиентам</Title>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          name="clientFilter"
          initialValues={formInitialState}
        >
          <div className="fields-wrapper">
            <FirstRow>
              <TCM.DateRange
                propsLabelFrom={{
                  name: formFields.dateFrom.key,
                  label: formFields.dateFrom.label,
                  validateTrigger: 'onBlur',
                }}
                propsInputFrom={{
                  name: formFields.dateFrom.key,
                }}
                propsLabelTo={{
                  name: formFields.dateTo.key,
                  label: formFields.dateTo.label,
                  validateTrigger: 'onBlur',
                }}
                propsInputTo={{
                  name: formFields.dateTo.key,
                }}
              />
              <TCM.Label
                name={formFields.campaign.key}
                label={formFields.campaign.label}
                validateTrigger="onBlur"
              >
                <TCM.Select
                  name={formFields.campaign.key}
                  placeholder={formFields.campaign.placeholder}
                  options={campaigns}
                  allowClear
                />
              </TCM.Label>
            </FirstRow>

            <SecondRow>
              <TCM.Label
                name={formFields.brand.key}
                label={formFields.brand.label}
                validateTrigger="onBlur"
              >
                <TCM.Select
                  name={formFields.brand.key}
                  placeholder={formFields.brand.placeholder}
                  options={brandData}
                  onChange={setBrand}
                  allowClear
                />
              </TCM.Label>

              <TCM.Label
                name={formFields.model.key}
                label={formFields.model.label}
                validateTrigger="onBlur"
              >
                <TCM.Select
                  name={formFields.model.key}
                  placeholder={formFields.model.placeholder}
                  options={testDriveModels}
                  loading={isModelLoading}
                  allowClear
                />
              </TCM.Label>

              <TCM.Label
                name={formFields.vin.key}
                label={formFields.vin.label}
                validateTrigger="onBlur"
              >
                <TCM.Input
                  name={formFields.vin.key}
                  placeholder={formFields.vin.placeholder}
                  onBlur={removeUnnecessarySpaces}
                />
              </TCM.Label>
            </SecondRow>

            <ThirdRow>
              <RowItem>
                <TCM.Label
                  name={formFields.callsCount.key}
                  label={formFields.callsCount.label}
                  validateTrigger="onBlur"
                >
                  <RadioGroup onChange={onCallsStatusChange}>
                    <TCM.Radio value={EventState.NotNecessary}>Не важно</TCM.Radio>
                    <TCM.Radio value={EventState.WereNot}>Звонков не было</TCM.Radio>
                    <TCM.Radio value={EventState.Were}>1 или более звонков</TCM.Radio>
                  </RadioGroup>
                </TCM.Label>

                {callsState === EventState.Were && (
                  <div style={{ width: 300 }}>
                    <TCM.Label
                      name={formFields.callStatus.key}
                      label={formFields.callStatus.label}
                      validateTrigger="onBlur"
                    >
                      <TCM.Select
                        name={formFields.callStatus.key}
                        placeholder={formFields.callStatus.placeholder}
                        options={callStatusOptions}
                      />
                    </TCM.Label>
                  </div>
                )}
              </RowItem>

              <RowItem>
                <TCM.Label
                  name={formFields.visitsCount.key}
                  label={formFields.visitsCount.label}
                  validateTrigger="onBlur"
                >
                  <RadioGroup onChange={onVisitsStatusChange}>
                    <TCM.Radio value={EventState.NotNecessary}>Не важно</TCM.Radio>
                    <TCM.Radio value={EventState.WereNot}>Визитов не было</TCM.Radio>
                    <TCM.Radio value={EventState.Were}>Клиент приезжал в ДЦ</TCM.Radio>
                  </RadioGroup>
                </TCM.Label>

                {visitsState === EventState.Were && (
                  <TCM.Label
                    name={formFields.visitStatuses.key}
                    label={formFields.visitStatuses.label}
                    validateTrigger="onBlur"
                  >
                    <TCM.MultipleSelect
                      name={formFields.visitStatuses.key}
                      placeholder={formFields.visitStatuses.placeholder}
                      options={visitStatusOptions}
                      selected={appliedFilters.visitStatuses}
                    />
                  </TCM.Label>
                )}
              </RowItem>
            </ThirdRow>
          </div>

          <FormFooter>
            <TCM.ButtonGroup>
              <TCM.Button htmlType="submit">Показать клиентов</TCM.Button>
              <TCM.ButtonOutline onClick={onReset}>Сбросить фильтр</TCM.ButtonOutline>
            </TCM.ButtonGroup>
          </FormFooter>
        </Form>
      </Wrapper>
    </TCM.Modal>
  );
};

const ConnectReduxWithForm = connect(
  (state) => ({
    toyotaModels: state.common.toyotaModels.list,
    isToyotaModelsLoading: state.common.toyotaModels.isLoading,
    isToyotaModelsLoaded: state.common.toyotaModels.isLoaded,
    toyotaModelsError: state.common.toyotaModels.error,

    lexusModels: state.common.lexusModels.list,
    isLexusModelsLoading: state.common.lexusModels.isLoading,
    isLexusModelsLoaded: state.common.lexusModels.isLoaded,
    lexusModelsError: state.common.lexusModels.error,
  }),
  {
    fetchToyotaModels: fetchToyotaModelsAction,
    fetchLexusModels: fetchLexusModelsAction,
  },
)(ClientsFilter);

export { ConnectReduxWithForm as ClientsFilter };
