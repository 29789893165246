import React, {
  useCallback,
  useEffect, useMemo, useReducer, useRef, useState,
} from 'react';
import { Form } from 'antd';
import moment from 'moment';
import { TCM } from 'components/tcm';
import { CHAR } from 'settings/namedUnicode';
import { connect } from 'react-redux';
import { fetchList } from 'services/tcm/campaign';
import { fetchEmployeesList } from 'services/tcm/employee';
import {
  tcmReportExport as tcmReportExportAction,
  tcmReportExportReset as tcmReportExportResetAction,
  saveReportTemplate as saveReportTemplateAction,
  saveReportTemplateReset as saveReportTemplateResetAction,
  getTcmReportSettings as getTcmReportSettingsAction,
  getTcmReportSettingsReset as getTcmReportSettingsResetAction,
} from 'redux/tcmReport/actions';
import {
  employeeGetReport as employeeGetReportAction,
  employeeResetReport as employeeResetReportAction,
} from 'redux/tcmEmployee/actions';

import { CreateErrorModal, CreateDraftSuccessfulModal } from 'components/tcm/Modals';

import { ModalWithChildren } from 'components/tcm/ClientInfo/style';
import { debounce } from 'underscore';
import { formatCampaignResponse } from 'tcm/campaigns/format';
import {
  ConversionBlock,
  ConversionCount, DescriptionBlockTitle, DescriptionText,
  DivisionCount,
  DivisionRow,
  DoubleFunnelItem,
  Equally,
  FunnelItem,
  Page,
  ReportDescription,
  ReportDescriptionBody,
  ReportDescriptionTitle,
  ModalText,
  FormItem,
  FiltersWrapper,
  PageTitle,
  BlockTitle,
  FormWrapper,
  FoundedCampaigns,
  DatePresets,
  DescriptionItem,
  ItemTitle,
  ItemContent,
  FunnelWrapper,
  FunnelTitle,
  Funnel,
  Header,
  ButtonsWrapper,
  NoResults,
} from './style';

import { formFields, DateRangePresets } from './data';
import { initialState, reducer, ACTIONS } from './reducer';

const initialValues = {
  [formFields.dateRange.key]: 'allTime',
  [formFields.dateFrom.key]: undefined,
  [formFields.dateTo.key]: moment(),
};

const searchCampaign = debounce(fetchList, 300, true);
const searchEmployee = debounce(fetchEmployeesList, 300, true);

const halfAYearDate = moment().quarter() >= 3
  ? moment().startOf('year').add(6, 'M')
  : moment().startOf('year');

const checkValidCampaigns = (campaigns = []) => Array.isArray(campaigns) && campaigns.length > 0;

const EmployeesReport = ({
  // report
  reportData,
  reportIsLoading,
  reportIsLoaded,
  // reportError,

  // redux
  exportReportData,
  exportReportIsLoading,
  exportReportIsLoaded,
  exportReportError,

  // reportTemplateData,
  reportTemplateIsLoading,
  reportTemplateIsLoaded,
  reportTemplateError,

  reportSettingsData,
  // reportSettingsIsLoading,
  reportSettingsIsLoaded,
  // reportSettingsError,

  // actions
  tcmReportExport,
  tcmReportExportReset,

  saveReportTemplate,
  saveReportTemplateReset,

  employeeGetReport,
  employeeResetReport,

  getTcmReportSettings,
  getTcmReportSettingsReset,
}) => {
  const [templateName, setTemplateName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [requestReportData, setRequestReportData] = useState(null);
  const [showReportsForm, setReportsShowForm] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { campaigns, employees } = state;
  const isLoading = campaigns.isLoading || employees.isLoading;

  const searchResultsElement = useRef(null);

  const [form] = Form.useForm();

  const { funnelSaleList, conversionList } = reportData;

  // const campaignList = formatCampaignResponse(reportData).map((campaign) => ({
  //   campaignId: campaign.id,
  //   label: `${campaign.brand} ${campaign.model} (${campaign.dialingFrom} - ${campaign.dialingTo})`,
  // }));

  const campaignList = ((campaigns.isLoaded && requestReportData?.campaignIds) || []).map((campaignId) => {
    const campaign = campaigns.list.find(({ value }) => value === campaignId);
    return ({
      campaignId,
      label: campaign.label,
    });
  });

  const employeeList = ((employees.isLoaded && requestReportData?.employeeIds) || []).map((employeeId) => {
    const employee = employees.list.find(({ value }) => value === employeeId);
    return ({
      employeeId,
      label: employee.label,
    });
  });

  const totalCount = funnelSaleList ? funnelSaleList[0]?.items[0].count : null;

  const firstDialingDate = useMemo(() => (
    moment(reportSettingsData.firstDialingDate).isValid()
      ? moment(reportSettingsData.firstDialingDate)
      : undefined
  ), [reportSettingsData]);

  const funnel = useMemo(() => {
    if (funnelSaleList) {
      return (
        funnelSaleList.map((step, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            {step.items.length === 1 && (
              <FunnelItem
                total={totalCount}
                value={step.items[0].count}
                text={step.items[0].label}
                isFinal={step.level === 8}
                withoutText={[6, 7, 8].includes(step.level)}
              />
            )}

            {step.items.length === 2 && (
              <DoubleFunnelItem
                total={totalCount}
                firstValue={step.items[0].count}
                firstText={step.items[0].label}
                secondaryText={step.items[1].label}
                secondaryValue={step.items[1].count}
              />
            )}
          </li>
        ))
      );
    }

    return [];
  }, [funnelSaleList, totalCount]);

  const clearSearch = (event) => {
    if (event && !event.path.some((el) => el === searchResultsElement.current)) {
      dispatch({ type: ACTIONS.FETCH_EMPLOYEES_RESET });
      dispatch({ type: ACTIONS.FETCH_CAMPAIGNS_RESET });
    }
  };

  const onSubmit = (values) => {
    const {
      dateFrom,
      dateTo,
      campaigns,
      employees,
    } = values;

    const data = {
      startAt: dateFrom.format('YYYY-MM-DD'),
      finishAt: dateTo.format('YYYY-MM-DD'),
      campaignIds: campaigns,
      employeeIds: employees,
    };
    setRequestReportData(data);
    employeeGetReport(data);
    setReportsShowForm(false);
    clearSearch();
  };

  const onReset = () => {
    form.setFieldsValue({
      [formFields.dateFrom.key]: undefined,
      [formFields.dateTo.key]: undefined,
      [formFields.dateRange.key]: '',
      [formFields.campaigns.key]: undefined,
    });
  };

  const onDateRangeChange = (event) => {
    switch (event.target.value) {
      case DateRangePresets.month.value: {
        form.setFieldsValue({
          [formFields.dateFrom.key]: moment().startOf('month'),
          [formFields.dateTo.key]: moment(),
        });
        break;
      }
      case DateRangePresets.quarter.value: {
        form.setFieldsValue({
          [formFields.dateFrom.key]: moment().startOf('quarter'),
          [formFields.dateTo.key]: moment(),
        });
        break;
      }
      case DateRangePresets.halfAYear.value: {
        form.setFieldsValue({
          [formFields.dateFrom.key]: halfAYearDate,
          [formFields.dateTo.key]: moment(),
        });
        break;
      }
      case DateRangePresets.year.value: {
        form.setFieldsValue({
          [formFields.dateFrom.key]: moment().startOf('year'),
          [formFields.dateTo.key]: moment(),
        });
        break;
      }
      case DateRangePresets.allTime.value: {
        form.setFieldsValue({
          [formFields.dateFrom.key]: firstDialingDate,
          [formFields.dateTo.key]: moment(),
        });
        break;
      }

      default:
        console.error('onDateRangeChange: Incorrect value of date range: ', event.target.value);
    }
  };

  const clearCurrentDateRangePresets = () => {
    form.setFieldsValue({
      [formFields.dateRange.key]: '',
    });
  };

  const getEmployees = useCallback((event) => {
    dispatch({ type: ACTIONS.FETCH_EMPLOYEES, query: event.target.value });
    searchEmployee({ search: event.target.value }).then((resp) => {
      const searchResult = resp.data.userList.map((employee) => ({
        value: employee.id,
        label: `${employee.displayName}`,
      }));
      dispatch({ type: ACTIONS.FETCH_EMPLOYEES_SUCCESS, list: searchResult });
    }).catch((e) => {
      dispatch({ type: ACTIONS.FETCH_EMPLOYEES_FAIL, error: e });
    });

    form.setFields([
      {
        name: formFields.employees.key,
        value: [],
      },
    ]);
  }, [form]);

  const onSearchChangeEmployees = (event) => {
    getEmployees(event);
  };

  const onSearchChangeEmployeesAll = (event) => {
    if (event.target.value === '' && !employees.list.length) {
      getEmployees(event);
    }
  };

  const getCampaigns = useCallback((event) => {
    dispatch({ type: ACTIONS.FETCH_CAMPAIGNS, query: event.target.value });
    searchCampaign({ search: event.target.value }).then((resp) => {
      const searchResult = formatCampaignResponse(resp.data).map((campaign) => ({
        value: campaign.id,
        label: `${campaign.brand} ${campaign.model} (${campaign.dialingFrom} - ${campaign.dialingTo})`,
      }));
      dispatch({ type: ACTIONS.FETCH_CAMPAIGNS_SUCCESS, list: searchResult });
    }).catch((e) => {
      dispatch({ type: ACTIONS.FETCH_CAMPAIGNS_FAIL, error: e });
    });
    form.setFields([
      {
        name: formFields.campaigns.key,
        value: [],
      },
    ]);
  }, [form]);

  const onSearchChange = (event) => {
    getCampaigns(event);
  };
  const onSearchChangeAll = (event) => {
    if (event.target.value === '' && !campaigns.list.length) {
      getCampaigns(event);
    }
  };

  const conversionData = conversionList?.[0] || {};
  const conversionDividend = conversionData.params?.[0].value;
  const conversionDivider = conversionData.params?.[1].value;
  const { conversion } = conversionData;

  useEffect(() => {
    if (exportReportIsLoaded) {
      const url = URL.createObjectURL(exportReportData);
      const shadowLink = document.createElement('a');
      shadowLink.href = url;
      shadowLink.download = 'Report.xlsx';
      shadowLink.click();
      tcmReportExportReset();
    }
  }, [exportReportData, exportReportIsLoaded, tcmReportExportReset]);

  useEffect(() => {
    if (reportTemplateIsLoaded) {
      setShowModal(false);
    }
  }, [reportTemplateIsLoaded]);

  useEffect(() => {
    if (!reportSettingsIsLoaded) {
      getTcmReportSettings();
    } else {
      form.setFieldsValue({
        [formFields.dateFrom.key]: firstDialingDate,
      });
    }
  }, [
    reportSettingsIsLoaded,
    form,
    reportSettingsData,
    getTcmReportSettings,
    firstDialingDate,
  ]);

  useEffect(() => () => {
    employeeResetReport();
    getTcmReportSettingsReset();
  }, [employeeResetReport, getTcmReportSettingsReset]);

  if (reportIsLoading) {
    return (
      <TCM.Loader text="Идет построение отчета..." />
    );
  }

  return (
    <Page>
      <Header>
        <PageTitle>Отчет</PageTitle>
        {requestReportData && !showReportsForm && (
          <TCM.ButtonOutline
            widthSize="fixed"
            onClick={() => tcmReportExport(requestReportData)}
            loading={exportReportIsLoading}
          >
            Выгрузить в Excel
          </TCM.ButtonOutline>
        )}
      </Header>

      <FiltersWrapper>
        <BlockTitle>По сотрудникам</BlockTitle>
        <FormWrapper show={showReportsForm}>
          <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            name="clientFilter"
            initialValues={initialValues}
            validateTrigger="onSubmit"
          >

            <TCM.Label
              label="Сотрудник"
            >
              <TCM.InputSearch
                placeholder="Поиск"
                value={employees.query}
                onChange={onSearchChangeEmployees}
                onFocus={onSearchChangeEmployeesAll}
                loading={employees.isLoading}
              />
            </TCM.Label>

            {Boolean(employees.list.length) && (
              <FoundedCampaigns ref={searchResultsElement}>
                <TCM.Label
                  noStyle
                  name={formFields.employees.key}
                >
                  <TCM.CheckBoxGroup
                    options={employees.list}
                  />
                </TCM.Label>
              </FoundedCampaigns>
            )}

            <TCM.Label
              label="Кампания"
            >
              <TCM.InputSearch
                placeholder="Поиск"
                value={campaigns.query}
                onChange={onSearchChange}
                onFocus={onSearchChangeAll}
                loading={campaigns.isLoading}
              />
            </TCM.Label>

            {Boolean(campaigns.list.length) && (
              <FoundedCampaigns ref={searchResultsElement}>
                <TCM.Label
                  noStyle
                  name={formFields.campaigns.key}
                >
                  <TCM.CheckBoxGroup
                    options={campaigns.list}
                  />
                </TCM.Label>
              </FoundedCampaigns>
            )}

            <TCM.DateRange
              propsLabelFrom={{
                name: formFields.dateFrom.key,
                label: formFields.dateFrom.label,
                validateTrigger: 'onBlur',
                rules: formFields.dateFrom.rules,
              }}
              propsInputFrom={{
                onChange: clearCurrentDateRangePresets,
                name: formFields.dateFrom.key,
              }}
              propsLabelTo={{
                name: formFields.dateTo.key,
                label: formFields.dateTo.label,
                validateTrigger: 'onBlur',
                rules: formFields.dateTo.rules,
              }}
              propsInputTo={{
                name: formFields.dateTo.key,
                onChange: clearCurrentDateRangePresets,
              }}
            />

            <DatePresets>
              <TCM.Label noStyle name={formFields.dateRange.key}>
                <TCM.RadioTextGroup onChange={onDateRangeChange}>
                  <TCM.RadioBtnText value={DateRangePresets.allTime.value}>
                    {DateRangePresets.allTime.label}
                  </TCM.RadioBtnText>
                  <TCM.RadioBtnText value={DateRangePresets.year.value}>
                    {DateRangePresets.year.label}
                  </TCM.RadioBtnText>
                  <TCM.RadioBtnText value={DateRangePresets.halfAYear.value}>
                    {DateRangePresets.halfAYear.label}
                  </TCM.RadioBtnText>
                  <TCM.RadioBtnText value={DateRangePresets.quarter.value}>
                    {DateRangePresets.quarter.label}
                  </TCM.RadioBtnText>
                  <TCM.RadioBtnText value={DateRangePresets.month.value}>
                    {DateRangePresets.month.label}
                  </TCM.RadioBtnText>
                </TCM.RadioTextGroup>
              </TCM.Label>
            </DatePresets>

            <ButtonsWrapper>
              <TCM.ButtonGroup flexPosition="flex-start">

                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <TCM.Button
                      htmlType="submit"
                      loading={reportIsLoading}
                      disabled={!(
                        form.getFieldValue(formFields.dateFrom.key)
                          && form.getFieldValue(formFields.dateTo.key)
                          && checkValidCampaigns(form.getFieldValue(formFields.campaigns.key))
                          && checkValidCampaigns(form.getFieldValue(formFields.employees.key))
                      ) || (isLoading)}
                    >
                      Построить отчет
                    </TCM.Button>
                  )}
                </Form.Item>

                <Form.Item noStyle>
                  <TCM.ButtonOutline onClick={onReset}>
                    Сбросить фильтры
                  </TCM.ButtonOutline>
                </Form.Item>
              </TCM.ButtonGroup>
            </ButtonsWrapper>
          </Form>
        </FormWrapper>

        {!showReportsForm && (
          <div>
            {false && (
              <DescriptionItem>
                <ItemTitle>Действие кампании:</ItemTitle>
                <ItemContent>
                  {moment(requestReportData.startAt).format('DD.MM')}
                  {' — '}
                  {moment(requestReportData.finishAt).format('DD.MM.YYYY')}
                </ItemContent>
              </DescriptionItem>
            )}

            <DescriptionItem>
              <ItemTitle>Cотрудники:</ItemTitle>
              <div>
                {Boolean(employeeList.length) && reportIsLoaded
                 && employeeList.map(({ label, employeeId }) =>
                   <ItemContent key={employeeId}>{label}</ItemContent>)}
              </div>
            </DescriptionItem>

            {false && (
              <DescriptionItem>
                <ItemTitle>Кампания:</ItemTitle>
                <div>
                  {Boolean(campaignList.length) && reportIsLoaded
                && campaignList.map(({ label, campaignId }) =>
                  <ItemContent key={campaignId}>{label}</ItemContent>)}
                </div>
              </DescriptionItem>
            )}

            <TCM.ButtonGroup flexPosition="flex-start">
              <TCM.Button onClick={() => setReportsShowForm(true)}>Изменить</TCM.Button>
              <TCM.ButtonOutline
                onClick={() => setShowModal(true)}
              >
                Сохранить шаблон
              </TCM.ButtonOutline>
            </TCM.ButtonGroup>
          </div>
        )}
      </FiltersWrapper>

      {
        reportIsLoaded && !showReportsForm && (
          funnel.length > 0 ? (
            <>
              <FunnelWrapper>
                <FunnelTitle>Воронка продаж</FunnelTitle>

                <Funnel>
                  {funnel}
                </Funnel>

                {Boolean(conversion) && (
                  <ConversionBlock>
                    <ConversionCount>
                      Конверсия
                      {CHAR.NBSP}
                      {`${Math.round(conversion * 100) / 100} %`}
                    </ConversionCount>
                    <Equally />
                    <div>
                      <DivisionRow>
                        <DivisionCount>{conversionDividend}</DivisionCount>
                        {CHAR.NBSP}
                        Клиентов взяли кредит
                      </DivisionRow>
                      <DivisionRow>
                        <DivisionCount>{conversionDivider}</DivisionCount>
                        {CHAR.NBSP}
                        Согласованных результатов звонка
                      </DivisionRow>
                    </div>
                  </ConversionBlock>
                )}

              </FunnelWrapper>

              <ReportDescription>
                <ReportDescriptionTitle>В воронку продаж включены:</ReportDescriptionTitle>
                <ReportDescriptionBody>
                  <div>
                    <DescriptionBlockTitle>Кампании:</DescriptionBlockTitle>
                    <div>
                      {formatCampaignResponse(reportData)?.map((campaign) => (
                        <DescriptionText>
                          {`${campaign.brand} ${campaign.model} (${campaign.dialingFrom} - ${campaign.dialingTo})`}
                        </DescriptionText>
                      ))}
                    </div>
                  </div>

                  <div>
                    <DescriptionBlockTitle>Сотрудники:</DescriptionBlockTitle>
                    <div>
                      {reportData.userList
                      && Array.isArray(reportData.userList)
                      && (reportData.userList.length > 0)
                        ? reportData.userList.map((item) => (
                          <DescriptionText>
                            {item.name}
                          </DescriptionText>
                        )) : (
                          <DescriptionText>&mdash;</DescriptionText>
                        )}
                    </div>
                  </div>

                  <div>
                    <DescriptionBlockTitle>Период обзвона:</DescriptionBlockTitle>
                    <div>
                      <DescriptionText>
                        {moment(requestReportData.startAt).format('DD.MM')}
                        {' — '}
                        {moment(requestReportData.finishAt).format('DD.MM.YYYY')}
                      </DescriptionText>
                    </div>
                  </div>

                </ReportDescriptionBody>
              </ReportDescription>
            </>
          )
            : (
              <NoResults>
                Информация не найдена. Попробуйте изменить данные для построения отчета
              </NoResults>
            )
        )

      }

      <ModalWithChildren
        visible={showModal}
        onOk={() => saveReportTemplate({
          reportType: 'campaign',
          name: templateName,
          params: { ...requestReportData, query: campaigns.query },
        })}
        onClose={() => {
          setShowModal(false);
          setTemplateName('');
          saveReportTemplateReset();
        }}
        onCancel={
          () => {
            setShowModal(false);
            setTemplateName('');
            saveReportTemplateReset();
          }
        }
        title="Шаблон"
        btnTextOk="Сохранить"
        disabledOk={!templateName}
        onOkLoading={reportTemplateIsLoading}
        onOkError={reportTemplateError}
        btnTextCancel="Не сохранять"
      >
        <ModalText>
          Шаблон упрощает работу с отчетами.
          <br />
          Все параметры отчета будут сохранены, и вам не придется заново их выбирать.
        </ModalText>

        <FormItem label="Название шаблона">
          <TCM.Input
            placeholder="Укажите название"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </FormItem>

      </ModalWithChildren>

      <CreateErrorModal
        onCancel={() => {
          tcmReportExportReset();
        }}
        visible={Boolean(exportReportError)}
        title="Ошибка"
        description="Ошибка сервера выгрузка excel-файла невозможна, попробуйте позже"
      />

      <CreateDraftSuccessfulModal
        visible={reportTemplateIsLoaded}
        onCancel={() => {
          saveReportTemplateReset();
        }}
        title="Шаблон успешно сохранен"
      />
    </Page>
  );
};

export default connect(
  (state) => ({
    exportReportData: state.tcmReport.export.data,
    exportReportIsLoading: state.tcmReport.export.isLoading,
    exportReportIsLoaded: state.tcmReport.export.isLoaded,
    exportReportError: state.tcmReport.export.error,

    reportTemplateData: state.tcmReport.reportTemplate.data,
    reportTemplateIsLoading: state.tcmReport.reportTemplate.isLoading,
    reportTemplateIsLoaded: state.tcmReport.reportTemplate.isLoaded,
    reportTemplateError: state.tcmReport.reportTemplate.error,

    reportSettingsData: state.tcmReport.settings.data,
    reportSettingsIsLoading: state.tcmReport.settings.isLoading,
    reportSettingsIsLoaded: state.tcmReport.settings.isLoaded,
    reportSettingsError: state.tcmReport.settings.error,

    reportData: state.tcmEmployee.report.data,
    reportIsLoading: state.tcmEmployee.report.isLoading,
    reportIsLoaded: state.tcmEmployee.report.isLoaded,
    reportError: state.tcmEmployee.report.error,
  }),
  {
    tcmReportExport: tcmReportExportAction,
    tcmReportExportReset: tcmReportExportResetAction,
    saveReportTemplate: saveReportTemplateAction,
    saveReportTemplateReset: saveReportTemplateResetAction,
    getTcmReportSettings: getTcmReportSettingsAction,
    getTcmReportSettingsReset: getTcmReportSettingsResetAction,

    employeeGetReport: employeeGetReportAction,
    employeeResetReport: employeeResetReportAction,
  },
)(EmployeesReport);
