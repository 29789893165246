import {
  FETCH_SUBJECT_LIST,
  FETCH_SUBJECT_LIST__FAILURE,
  FETCH_SUBJECT_LIST__SUCCESS,
} from 'redux/tcmTopics/actions';

const initState = {
  subjects: {
    topics: [],
    subtopics: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function campaignsReducer(state = initState, action) {
  switch (action.type) {
    case FETCH_SUBJECT_LIST: {
      return {
        ...state,
        subjects: {
          ...state.subjects,
          isLoading: true,
        },
      };
    }
    case FETCH_SUBJECT_LIST__SUCCESS: {
      return {
        ...state,
        subjects: {
          ...state.subjects,
          topics: action.topics,
          subtopics: action.subtopics,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case FETCH_SUBJECT_LIST__FAILURE: {
      return {
        ...state,
        subjects: {
          ...state.subjects,
          list: [],
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
