import styled from 'styled-components';
import { Form } from 'antd';

export const Root = styled(Form)`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4rem;

  /* Semi-global styles */
  & .ant-form-item {
    margin-bottom: 6px;
  }

  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & .ant-checkbox-group {
    display: flex;
    flex-flow: column nowrap;
  }

  & .ant-input-number {
    min-width: 160px;
  }
`;

export const Main = styled.main``;

export const Aside = styled.aside`
  display: flex;
  flex-flow: column nowrap;
`;

export const Footer = styled.footer`
  background: rgba(0, 0, 0, 0.02);
  height: 100%;
  padding: 2rem;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

export const CarInfoContainer = styled.div`
  margin-bottom: -10px;
  margin-top: 25px;
`;

export const SwitchRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  & > * {
    opacity: 0.8;
  }
`;

export const ActionRow = styled.div`
  padding: 1rem 0;

  & + & {
    border-top: 2px solid #d9d9d9;
  }
`;

export const ControlGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SuccessMessage = styled.div`
  color: green;
`;
