import React from 'react';
import { connect } from 'react-redux';
import Select from 'components/Fields/Select';

import {
  Root,
} from './style';

function SelectDocument({
  // passed
  field,
  props,
}) {
  return (
    <Root>
      <Select
        key={field.name}
        {...props}
        onChangeField={field.onChangeField}
        form={field.form}
        name={field.name}
        type={field.type}
        options={field.options}
        label={field.label}
        initialValue={field.value}
        error={field.error}
        disabled={field.disabled}
        dependents={field.dependents}
        ifChangeClearFields={field.ifChangeClearFields}
        warning={field.warning}
        size="middle"
      />
    </Root>
  );
}

export default connect(null, null)(SelectDocument);
