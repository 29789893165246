import API from 'services';
import { extractErrorAndCode } from 'hacks';

import {
  setTokens,
  setPermissionList,
} from 'helpers/utility';

import {
  all, fork, put, takeLatest, call, select,
} from 'redux-saga/effects';

import { WRITE_AUTH_DATE_FROM_CACHE } from 'redux/auth/actions';

import {
  INVITE_CONFIRM_EMAIL,
  INVITE_CONFIRM_EMAIL__SUCCESS,
  INVITE_CONFIRM_EMAIL__FAILURE,
  INVITE_SEND_OTP,
  INVITE_SEND_OTP__SUCCESS,
  INVITE_SEND_OTP__FAILURE,
  INVITE_CHECK_OTP,
  INVITE_CHECK_OTP__SUCCESS,
  INVITE_CHECK_OTP__FAILURE,

  INVITE_FINISH,
  INVITE_FINISH__SUCCESS,
  INVITE_FINISH__FAILURE,
} from './actions';

const getState = (state) => state.invite;

const getAllPermissions = (roles = []) => {
  const uniquePermissions = new Map();
  roles.forEach((roleList) => {
    if (roleList.role && roleList.role.permissionList) {
      roleList.role.permissionList.forEach((permission) => {
        if (!uniquePermissions.has(permission.alias)) {
          uniquePermissions.set(permission.alias, permission);
        }
      });
    }
  });
  return Array.from(uniquePermissions.values());
};

export function* confirmEmail() {
  yield takeLatest(INVITE_CONFIRM_EMAIL, function* ({ token, email }) {
    try {
      const response = yield call(API.email.confirm, { token, email });

      if (
        response
        && response.data
        && Array.isArray(response.data)
        && response.data.length === 0
      ) {
        yield put({ type: INVITE_CONFIRM_EMAIL__SUCCESS });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: INVITE_CONFIRM_EMAIL__FAILURE, error: error.message });
    }
  });
}

export function* sendOtp() {
  yield takeLatest(INVITE_SEND_OTP, function* ({ login }) {
    try {
      const {
        otp: { token },
      } = yield select(getState);
      let response;

      if (token) {
        response = yield call(API.otp.resend, { token });
      } else {
        response = yield call(API.otp.send, { login });
      }

      if (response && response.data && Array.isArray(response.data.otpList)) {
        const { recipient, token } = response.data.otpList[0];
        const maskedRecipient = typeof recipient === 'string'
          ? recipient.replace(/\d/g, (v, i) => (i > 4 && i < 10 ? '*' : v))
          : '';
        yield put({ type: INVITE_SEND_OTP__SUCCESS, recipient: maskedRecipient, token });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: INVITE_SEND_OTP__FAILURE, error: error.message });
    }
  });
}

export function* checkOtp() {
  yield takeLatest(INVITE_CHECK_OTP, function* ({ code }) {
    try {
      const {
        otp: { token },
      } = yield select(getState);
      const response = yield call(API.otp.check, { code, token });

      if (response && response.data && Array.isArray(response.data.otpList)) {
        yield put({ type: INVITE_CHECK_OTP__SUCCESS });
      } else {
        const { error } = extractErrorAndCode(response);

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: INVITE_CHECK_OTP__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: INVITE_CHECK_OTP__FAILURE, error: error.message });
    }
  });
}

export function* register() {
  yield takeLatest(INVITE_FINISH, function* ({ password }) {
    try {
      const {
        otp: { token },
      } = yield select(getState);
      const response = yield call(API.auth.register, { password, token });

      if (response.data && Array.isArray(response.data.sessionList)) {
        yield put({ type: INVITE_FINISH__SUCCESS });
        const {
          data: { sessionList },
        } = response;

        const { accessToken, refreshToken } = sessionList[0];

        const permissionList = (
          response.data
              && Array.isArray(response.data.authResponseList)
              && response.data.authResponseList[0]
              && Array.isArray(response.data.authResponseList[0].roles)
              && response.data.authResponseList[0].roles[0]
              && response.data.authResponseList[0].roles[0].role
              && Array.isArray(response.data.authResponseList[0].roles[0].role.permissionList)
            ? getAllPermissions(response.data.authResponseList[0].roles)
            : []
        );

        yield put({
          type: WRITE_AUTH_DATE_FROM_CACHE,
          token: accessToken,
          permissionList,
        });

        setPermissionList(permissionList);
        setTokens(/* rememberMe */ false, accessToken, refreshToken);
      } else {
        const { error } = extractErrorAndCode(response);

        if (
          response
            && response.data
            && response.data.validationErrorList
            && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: INVITE_CHECK_OTP__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: INVITE_FINISH__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(confirmEmail),
    fork(sendOtp),
    fork(checkOtp),
    fork(register),
  ]);
}
