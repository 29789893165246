import styled from 'styled-components';
import { key } from 'styled-theme';
import { Radio as RadioAntD } from 'antd';
import { GRID_AREAS } from 'settings';

const RadioStyled = styled(RadioAntD)`
  && {
    font-size: ${key('radio.fontSize')};
    margin-bottom: 4px;
  }
`;

const GroupStyled = styled(RadioAntD.Group)`
  && {
    ${({ area }) =>
    typeof area === 'boolean'
      && `
      grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
    `}

    display: flex;
    flex-direction: column;
  }
`;

export { RadioStyled, GroupStyled };
