export const STATUS_OPTIONS = [
  {
    value: 'new',
    label: 'Новый',
  },
  {
    value: 'active',
    label: 'Активный',
  },
  {
    value: 'blocked_by_client',
    label: 'Заблокирован клиентом',
  },
  {
    value: 'blocked_by_bank',
    label: 'Заблокирован банком',
  },
  {
    value: 'blocked_change_password',
    label: 'Заблокирован смена пароля',
  },
];

export const REPORTS_TYPES = [
  {
    value: 'registy',
    label: 'Реестр',
  },
  // {
  //   value: 'period_registy',
  //   label: 'Реестр по периоду',
  // },
  // {
  //   value: 'nullable_reports',
  //   label: 'Нулевые отчеты',
  // },
  {
    value: 'daily_report',
    label: 'Отчет по операциям за период',
  },
  {
    value: 'monthly_report',
    label: 'Сводный отчет за месяц',
  },
  // {
  //   value: 'period_report',
  //   label: 'Отчет за период',
  // },
  {
    value: 'reconciliation_report',
    label: 'Отчет по сверке операций с процессингом',
  },
];
export const REPORTS_TYPES_TABLE = {
  registy: 'Реестр',
  // period_registy: 'Реестр по периоду',
  // nullable_reports: 'Нулевые отчеты',
  daily_report: 'Отчет по операциям за период',
  monthly_report: 'Сводный отчет за месяц',
  // period_report: 'Отчет за период',
  reconciliation_report: 'Отчет по сверке операций с процессингом',
};

export const formatToTable = (array = []) => array.map((item) => ({ value: item.name, label: item.name }));

export const formatToApi = ({ array = [], options }) => (array
  ? options.filter((e) => array.includes(e.label)).map((item) => item.value)
  : []);

export const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const ACTIONS = {
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}
