export const PERIOD_GET_REPORT = 'PERIOD_GET_REPORT';
export const PERIOD_GET_REPORT__SUCCESS = 'PERIOD_GET_REPORT__SUCCESS';
export const PERIOD_GET_REPORT__FAILURE = 'PERIOD_GET_REPORT__FAILURE';
export const PERIOD_GET_REPORT__RESET = 'PERIOD_GET_REPORT__RESET';
export const periodGetReport = ({ startAt, finishAt }) => ({
  type: PERIOD_GET_REPORT,
  startAt,
  finishAt,
});
export const periodResetReport = () => ({
  type: PERIOD_GET_REPORT__RESET,
});
