import { formatPhone } from 'utils';

export const KEYS = {
  NAME: {
    key: 'NAME',
    apiKey: 'name',
    title: 'Наименование',
    rules: [
      {
        required: true,
        message: 'Укажите наименование компании',
      },
    ],
  },

  NAME_FULL: {
    key: 'NAME_FULL',
    apiKey: 'fullName',
    title: 'Полное наименование',
    rules: [
      {
        required: true,
        message: 'Укажите полное наименование компании',
      },
    ],
  },

  OGRN: {
    key: 'OGRN',
    apiKey: 'ogrn',
    title: 'ОГРН/ОГРНИП',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  INN: {
    key: 'INN',
    apiKey: 'inn',
    title: 'ИНН',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  KPP: {
    key: 'KPP',
    apiKey: 'kpp',
    title: 'КПП',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  OKVED: {
    key: 'OKVED',
    apiKey: 'okved',
    title: 'ОКВЭД',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  OKPO: {
    key: 'OKPO',
    apiKey: 'okpo',
    title: 'ОКПО',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  OKTMO: {
    key: 'OKTMO',
    apiKey: 'oktmo',
    title: 'ОКТМО',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  EXECUTIVE: {
    key: 'EXECUTIVE',
    apiKey: 'individualSpruceOrgan', // Это вообще что такое? Почему исполнительный орган называется елью?
    title: 'Единоличный исполнительный орган',
    rules: [],
  },

  ADDRESS: {
    key: 'ADDRESS',
    apiKey: 'address',
    title: 'Адрес регистрации',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  ADDRESS_POSTAL: {
    key: 'ADDRESS_POSTAL',
    apiKey: 'postAddress',
    title: 'Адрес почтовый (если отличается от адреса регистрации)',
    rules: [],
  },

  PHONE: {
    key: 'PHONE',
    apiKey: 'phone',
    title: 'Номер Телефона',
    rules: [
      formatPhone.validators.validateIfNotFilled,
    ],
  },

  FAX: {
    key: 'FAX',
    apiKey: 'fax',
    title: 'Факс',
    rules: [
      formatPhone.validators.validateIfNotFilled,
    ],
  },

  DESCRIPTION: {
    key: 'DESCRIPTION',
    apiKey: 'additionalInfo',
    title: 'Дополнительная информация',
    rules: [],
  },
};
