import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Root = styled.div`
  max-width: 34rem;
  width: 100%;
  margin: 120px auto 0;
  background: #fff;
  padding: 4rem;
  border-radius: 5px;
  border: 1px solid #cecece;

  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & .ant-checkbox-wrapper {
    color: rgba(0, 0, 0, 0.85);
  }

  & .ant-form-item {
    margin-bottom: 7px;
  }

  & .ant-form-item-control {
    padding-bottom: 1.6rem;
  }

  & .ant-form-item-explain {
    position: absolute;
    bottom: 0;
    height: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-family: ToyotaDisplay;
  font-size: 24px;
  line-height: 30px;
  color: #000;
  margin-bottom: 2rem;
`;

export const FormBlock = styled.div`
  margin: 0;
`;

export const FormError = styled.div`
  margin: 1.6rem 0;
  color: #e50000;
`;

export const Footer = styled.div`
  margin-top: 1.6rem;
`;

export const LinkButton = styled(Link)`
  color: #E71313;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 30px;
  display: block;
  text-align: center;
  
  &:hover{
    cursor: pointer;
    color: #E71313;
  }
`;
