import styled from 'styled-components';
import { palette, size } from 'styled-theme';

import { Input } from 'antd';

export const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const InputStyled = styled(Input)`
  &.ant-input {
    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    &:last-of-type {
      border-left: 1px solid transparent;
      border-radius: 0 5px 5px 0;
    }

    height: ${size('filterInputHeight')};
    font-size: 12px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    color: ${palette('form', 5)};
    caret-color: ${palette('form', 5)};
    text-align: center;

    &:hover {
      background: ${palette('form', 1)};
      border-color: ${palette('form', 3)};
    }
    &:focus {
      background: ${palette('form', 1)};
      border-color: ${palette('form', 3)};
    }
  }
`;
