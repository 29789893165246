import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { useForm } from 'hooks';
import Button from 'components/Button';
import { Search } from 'components/Input';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';
import { initialFilters } from 'redux/company/reducer';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from 'settings';
import moment from 'moment';

import {
  checkPrivilege,
} from 'helpers/roles';

import {
  setBreadcrumbs as setBreadcrumbsAction,
} from 'redux/breadcrumbs/actions';

import {
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
} from 'redux/company/actions';

import {
  Root,
  Header,
  HeaderTitle,
  HeaderSearch,
  Title,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
} from './style';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 460px)' };

const HAS_SERVICES = false;

export const STATUS_OPTIONS = [
  {
    value: 'tcm',
    label: 'ТСМ',
  },
  {
    value: 'billing_page',
    label: 'Платежная страница',
  },
  {
    value: 'credit_request',
    label: 'Заявка',
  },
];

function List({
  // connect
  role,

  list,
  sorting,
  search,
  isLoading,
  error,
  pagination,

  // actions
  setTablePage,
  setSorting,
  setFilters,
  setSearch,
  resetTable,
  setBreadcrumbs,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Организации', link: '/app/management/companies/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    resetTable();
    // в этом методе в саге используется fetchList
    setFilters({ filters: form });
    return () => resetTable();
  }, [form, resetTable, setFilters]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  function handleOnSearch(v) {
    setSearch({ search: v });
  }

  function handleOnBlur() {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  const columns = [
    {
      key: 'name',
      title: () =>
        TableHeader({
          title: 'Организация',
          sortBy: 'name',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'string',
          filterValue: form.name,
          filterIsLoading: false,
          filterOnChange: updateForm,
        }),
      render: ({ id, name }) => <Link to={`/app/management/companies/${id}`}>{name}</Link>,
      width: 240,
    },
    {
      key: 'inn',
      title: () =>
        TableHeader({
          title: 'ИНН',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'inn',
          filterValue: form.inn,
          filterOnChange: updateForm,
        }),
      render: ({ inn, id }) => <Link to={`/app/management/companies/${id}`}>{inn}</Link>,
      width: 240,
    },
    {
      key: 'email',
      title: () =>
        TableHeader({
          title: 'Сервисы',
          ...(HAS_SERVICES ? {
            filterType: FILTER_TYPES.SELECT,
            filterName: 'email',
            filterValue: form.email,
            filterOnChange: updateForm,
            filterOptions: STATUS_OPTIONS,
          } : {}),
        }),
      render: ({ id }) => <Link to={`/app/management/companies/${id}`} />,
      width: 240,
    },
    {
      key: 'created',
      title: () =>
        TableHeader({
          title: 'Дата заведения',
          sortBy: 'createdAt',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.PERIOD,
          filterName: ['createFrom', 'createTo'],
          filterValue: [form.createFrom, form.createTo],
          filterOnChange: updateForm,
        }),
      render: ({ createdAt }) => (
        <span>{createdAt && moment.utc(createdAt).format(DATE_FORMAT)}</span>
      ),
      width: 240,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>Все организации</Title>
          {checkPrivilege([role.ORGANISATIONS.CREATE, role.ORGANISATIONS.CREATE_FALLBACK]) && (
            <Button
              htmlType="button"
              type="primary"
              size="mini"
              shape="square"
              href="/app/management/companies/new"
            >
              Добавить
            </Button>
          )}
        </HeaderTitle>
        <HeaderSearch>
          <Search
            allowClear
            placeholder="Поиск..."
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            onSearch={handleOnSearch}
            onBlur={handleOnBlur}
          />
        </HeaderSearch>
      </Header>

      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    list: state.company.collection.list,
    sorting: state.company.collection.sorting,
    filters: state.company.collection.filters,
    search: state.company.collection.search,
    isLoaded: state.company.collection.isLoaded,
    isLoading: state.company.collection.isLoading,
    error: state.company.collection.error,
    pagination: state.company.collection.pagination,
  }),
  {
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
