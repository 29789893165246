import React, { useCallback, useEffect, useRef } from 'react';
import Radio, { Group } from 'components/Radio';
import Error from 'components/Fields/Error';
import Description from 'components/Fields/Description';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { getOptionsList, mergeArrays } from 'hacks';
import { SCROLL_DURATION } from 'settings';

function RadioField({
  name,
  type,
  label,
  options,
  error: errorMessage,
  description,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const list = getOptionsList(options);

  function handleChange({ target: { value } }) {
    if (String(initialValue) !== value) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: typeof initialValue === 'string' ? initialValue : undefined,
      })(
        <Group key={name} area={area} onChange={handleChange} disabled={disabled}>
          {list.map((option, i) => (
            <Radio
              key={option.value}
              value={option.value}
              {...(i === 0 ? { ref: focusableNode } : {})}
            >
              {option.label}
            </Radio>
          ))}
        </Group>,
      )}
      <Description text={description} area={area} />
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default RadioField;
