import React from 'react';
import { TYPES } from 'settings';
import ModalError from './ModalError';
import Notification from './Notification';

function Switch({ field, closeModal }) {
  switch (field.type) {
    case TYPES.ERROR_MODAL:
    case TYPES.MODAL_ERROR: {
      return (
        <ModalError
          key={field.name}
          name={field.name}
          title={field.title}
          description={field.description}
          close={closeModal}
        />
      );
    }
    case TYPES.NOTIFICATION: {
      return (
        <Notification
          key={field.name}
          name={field.name}
          title={field.title}
          description={field.description}
          close={closeModal}
        />
      );
    }

    default: {
      return null;
    }
  }
}

export default Switch;
