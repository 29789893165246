import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { withRouter, useLocation } from 'react-router-dom';
import SearchPanel from 'components/SearchPanel';
import DocumentPanel from 'components/DocumentPanel';
import Modals from 'components/Modals';
import QS from 'query-string';
import { baseURL } from 'settings/api';

import {
  getDetails,
  getDetailsReset,
  patch as patchRequest,
} from 'redux/request/actions';

import { ARM_COMMENTS, ARM_ANSWER } from 'settings';

import {
  setCreditToken as setCreditTokenAction,
  removeCreditToken as removeCreditTokenAction,
} from 'redux/user/actions';
import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import { Form as LegacyForm } from '@ant-design/compatible';
import CreditForm from './Form/Credit';
import RequestForm from './Form/Request';
import History from './Form/History';
import Comments from './Form/Comments';
import Answer from './Form/Answer';

import { TabsStyled } from './style';

const TABS = {
  HISTORY: {
    path: 'history',
    title: 'История изменений',
  },
  CREDIT: {
    path: 'credit',
    title: 'Кредит',
  },
  REQUEST: {
    path: 'details',
    title: 'Заявка',
  },
  COMMENTS: {
    path: 'comments',
    title: 'Комментарии',
  },
  ANSWER: {
    path: 'answer',
    title: 'Ответ',
  },
};

const getTab = (URLpath) =>
  TABS[Object.keys(TABS).find((e) => URLpath.endsWith(TABS[e].path)) || 'REQUEST'].path;

function Item({
  history,
  match,
  form,
  // passed
  requestTree,

  // store
  isLoadedAnswer,

  // actions
  patchRequest,
  setCreditToken,
  removeCreditToken,
  setBreadcrumbs,
  getDetailsReset,
  getDetails,
}) {
  const {
    params: { id },
    path,
  } = match;

  const [tab, setTab] = useState(getTab(path));
  const location = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const { text } = requestTree.find((item) => item.name === 'zeroName') || {};

  useEffect(() => {
    if (id) {
      getDetails({ accessToken: id });
    }
  }, [getDetails, id]);

  useEffect(() => () => getDetailsReset(), [getDetailsReset]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Онлайн заявка', link: '/app/leads' },
        { level: 1, name: 'Все заявки', link: '/app/leads/request/list' },
        { level: 2, name: text ? `${text}` : 'Клиент', link: location.pathname },
      ]);
    }
  }, [isMounted, setBreadcrumbs, text, location]);

  useEffect(() => {
    setTab(getTab(match.path));
  }, [match.path]);

  useEffect(() => {
    if (id) {
      setCreditToken({ creditRequestAccessToken: id });
    }
    return () => {
      removeCreditToken();
    };
  }, [id, setCreditToken, removeCreditToken]);

  function handleTabChange(k) {
    history.push(`/app/leads/request/${id}/${k}`);

    if (k === TABS.REQUEST.path) {
      patchRequest({
        userInput: {},
      });
    }
  }

  function onChangeField(userInput, exclude) {
    patchRequest({
      userInput,
      ...(Array.isArray(exclude) ? { exclude } : {}),
    });
  }

  function handleSearch(v) {
    const { protocol, host } = window.location;
    window.open(
      `${protocol}//${host}${baseURL}/app/leads?${QS.stringify({ search: v })}`,
      '_blank',
    );
  }

  return (
    <>
      <SearchPanel onSearch={handleSearch} />
      <DocumentPanel
        id={id}
        form={form}
        onChangeField={onChangeField}
      />
      <TabsStyled
        defaultActiveKey={TABS.REQUEST.path}
        activeKey={tab}
        type="card"
        animated={false}
        onChange={handleTabChange}
      >
        <Tabs.TabPane tab={TABS.CREDIT.title} key={TABS.CREDIT.path}>
          <CreditForm id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={TABS.REQUEST.title} key={TABS.REQUEST.path}>
          <RequestForm id={id} form={form} isActive={tab === TABS.REQUEST.path} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={TABS.HISTORY.title} key={TABS.HISTORY.path}>
          <History id={id} />
        </Tabs.TabPane>
        {ARM_COMMENTS && (
          <Tabs.TabPane tab={TABS.COMMENTS.title} key={TABS.COMMENTS.path}>
            <Comments id={id} />
          </Tabs.TabPane>
        )}
        {ARM_ANSWER && isLoadedAnswer && (
          <Tabs.TabPane tab={TABS.ANSWER.title} key={TABS.ANSWER.path}>
            <Answer id={id} />
          </Tabs.TabPane>
        )}
      </TabsStyled>
      <Modals />
    </>
  );
}

const withForm = LegacyForm.create({ name: 'request' })(Item);

export default connect(
  (state) => ({
    crif: state.crif.form,
    requestTree: state.request.fieldsFlat,
    isLoadedAnswer: state.request.details.isLoaded,
  }),
  {
    patchRequest,
    setCreditToken: setCreditTokenAction,
    removeCreditToken: removeCreditTokenAction,
    setBreadcrumbs: setBreadcrumbsAction,
    getDetailsReset,
    getDetails,
  },
)(withRouter(withForm));
