import {
  // list
  CREDIT_REQUEST_FETCH_LIST,
  CREDIT_REQUEST_FETCH_LIST__SUCCESS,
  CREDIT_REQUEST_FETCH_LIST__FAILURE,
  CREDIT_REQUEST_FETCH_LIST__SET_LOADING,
  CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION,
  CREDIT_REQUEST_FETCH_LIST__SET_SEARCH,
  CREDIT_REQUEST_FETCH_LIST__SET_SORTING,
  CREDIT_REQUEST_FETCH_LIST__SET_FILTER,
  CREDIT_REQUEST_FETCH_LIST__RESET,

  // item
  CREDIT_REQUEST__FETCH_ITEM,
  CREDIT_REQUEST__FETCH_ITEM_SUCCESS,
  CREDIT_REQUEST__FETCH_ITEM_FAILURE,
  CREDIT_REQUEST__FETCH_ITEM_RESET,
  CREDIT_REQUEST__CREATE,
  CREDIT_REQUEST__CREATE_SUCCESS,
  CREDIT_REQUEST__CREATE_FAILURE,
  CREDIT_REQUEST__EDIT,
  CREDIT_REQUEST__EDIT_SUCCESS,
  CREDIT_REQUEST__EDIT_FAILURE,

  /* load document */
  CREDIT_REQUEST__SAVE_DOCUMENT,
  CREDIT_REQUEST__SAVE_DOCUMENT_SUCCESS,
  CREDIT_REQUEST__SAVE_DOCUMENT_FAILURE,
  /* remove document */
  CREDIT_REQUEST__REMOVE_DOCUMENT,
  CREDIT_REQUEST__REMOVE_DOCUMENT_SUCCESS,
  CREDIT_REQUEST__REMOVE_DOCUMENT_FAILURE,
  /* send SMS */
  CREDIT_REQUEST__SEND_SMS,
  CREDIT_REQUEST__SEND_SMS_SUCCESS,
  CREDIT_REQUEST__SEND_SMS_FAILURE,

  CREDIT_REQUEST__RESET,
} from './actions';

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {};

export const defaultPageSize = 25;

export const initialFilters = {};

const initState = {
  collection: {
    data: {},
    pagination: {
      page: 1,
      pageSize: defaultPageSize,
    },
    list: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  item: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    isSMSLoading: false,
  },

  create: {
    data: {},
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },

  edit: {
    data: {},
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
};

export default function creditRequestReducer(state = initState, action) {
  switch (action.type) {
    case CREDIT_REQUEST_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case CREDIT_REQUEST_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case CREDIT_REQUEST_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          pagination: {
            ...state.collection.pagination,
            ...(action.pagination || {}),
          },
        },
      };
    }

    case CREDIT_REQUEST_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case CREDIT_REQUEST_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case CREDIT_REQUEST_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          data: action.data,
          pagination: {
            ...state.collection.pagination,
            ...action.pagination,
            pageSize: state.collection.pagination.pageSize,
          },
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CREDIT_REQUEST_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CREDIT_REQUEST__FETCH_ITEM: {
      return {
        ...state,

        item: {
          ...state.item,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__FETCH_ITEM_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__FETCH_ITEM_FAILURE: {
      return {
        ...state,
        item: {
          ...state.item,
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CREDIT_REQUEST__FETCH_ITEM_RESET: {
      return {
        ...state,
        item: {
          ...initState.item,
        },
      };
    }

    /* load document */
    case CREDIT_REQUEST__SAVE_DOCUMENT: {
      return {
        ...state,

        item: {
          ...state.item,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__SAVE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          data: {
            ...state.item.data,
            documents: Array.isArray(action.documents)
              ? action.documents
              : state.item.data.documents || [],
          },
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__SAVE_DOCUMENT_FAILURE: {
      return {
        ...state,
        item: {
          ...state.item,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    /* remove document */
    case CREDIT_REQUEST__REMOVE_DOCUMENT: {
      return {
        ...state,

        item: {
          ...state.item,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__REMOVE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          data: {
            ...state.item.data,
            documents: Array.isArray(action.documents)
              ? action.documents
              : state.item.data.documents || [],
          },
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__REMOVE_DOCUMENT_FAILURE: {
      return {
        ...state,
        item: {
          ...state.item,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    /* sendSMS */
    case CREDIT_REQUEST__SEND_SMS: {
      return {
        ...state,

        item: {
          ...state.item,
          isSMSLoading: true,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__SEND_SMS_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          isSMSLoading: false,
          error: null,
        },
      };
    }
    case CREDIT_REQUEST__SEND_SMS_FAILURE: {
      return {
        ...state,
        item: {
          ...state.item,
          isSMSLoading: false,
          error: action.error,
        },
      };
    }

    case CREDIT_REQUEST__CREATE: {
      return {
        ...state,

        create: {
          data: {},
          form: {
            salePoint: action.salePoint,
            inn: action.inn,
            krifId: action.krifId,
            acquiringID: action.acquiringID,
            onlineId: action.onlineId,
            addressactual: action.addressactual,
            phone: action.phone,
            detail: action.detail,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case CREDIT_REQUEST__CREATE_SUCCESS: {
      return {
        ...state,

        create: {
          ...state.create,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CREDIT_REQUEST__CREATE_FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case CREDIT_REQUEST__EDIT: {
      return {
        ...state,

        edit: {
          data: {},
          form: {
            salePoint: action.salePoint,
            inn: action.inn,
            krifId: action.krifId,
            acquiringID: action.acquiringID,
            onlineId: action.onlineId,
            addressactual: action.addressactual,
            phone: action.phone,
            detail: action.detail,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case CREDIT_REQUEST__EDIT_SUCCESS: {
      return {
        ...state,

        edit: {
          ...state.edit,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CREDIT_REQUEST__EDIT_FAILURE: {
      return {
        ...state,

        edit: {
          ...state.edit,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case CREDIT_REQUEST__RESET: {
      const { page } = action;
      return {
        ...state,
        create: {
          ...initState.create,
        },
        ...(page === 'new' ? (
          {
            edit: {
              ...initState.edit,
            },
            item: {
              ...initState.item,
            },
          }
        ) : (
          {
            edit: {
              ...state.edit,
              isLoading: false,
              isLoaded: false,
              error: null,
              errors: [],
            },
            item: {
              ...state.item,
              error: null,
              errors: [],
            },
          }
        )),
      };
    }

    default: {
      return state;
    }
  }
}
