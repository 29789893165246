import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import List from './List';
import Transaction from './Transaction';

function Index() {
  return (
    <Switch>
      <Route exact path="/app/payments/transactions">
        <Redirect to="/app/payments/transactions/list" />
      </Route>

      <Route path="/app/payments/transactions/list" component={List} />
      <Route path="/app/payments/transactions/:transactionId" component={Transaction} />
    </Switch>
  );
}

export default Index;
