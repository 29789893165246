import React from 'react';
import Modal from 'components/Modal';

import { ErrorMessage } from './style';

const errorProps = {
  visible: true,
  closable: true,
  footer: null,
};

function ModalError(props) {
  const {
    name, description, close, ...passedProps
  } = props;

  function onCancel() {
    close({ name });
  }

  return (
    <Modal {...errorProps} {...passedProps} onCancel={onCancel}>
      <ErrorMessage>{description}</ErrorMessage>
    </Modal>
  );
}

export default ModalError;
