import React from 'react';
import { Tabs } from 'antd';

import { TabsStyled } from './style';

const { TabPane } = Tabs;

const defaultProps = {
  animated: {
    inkBar: true,
    tabPane: false,
  },
};

export { TabPane };
export default React.forwardRef((props, ref) => (
  <TabsStyled {...defaultProps} {...props} ref={ref} />
));
