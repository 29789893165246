import React from 'react';
import SearchFilter from 'components/TableFilters/Input';
import DateFilter from 'components/TableFilters/Date';
import PeriodFilter from 'components/TableFilters/Period';
import SelectFilter from 'components/TableFilters/Select';
import SelectMultiple from 'components/TableFilters/SelectMultiple';
import RangeFilter from 'components/TableFilters/Range';
import AutoCompleteFilter from 'components/TableFilters/AutoComplete';
import OrganisationSearch from 'components/TableFilters/OrganisationSearch';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import {
  Header, HeaderTitle, HeaderFilter, HeaderSorting,
} from './style';

export const FILTER_TYPES = {
  INPUT: 'input',
  SELECT: 'select',
  SELECT_MULTIPLE: 'select multiple',
  SELECT_WITH_FILTER: 'autocomplete',
  DATE: 'date',
  PERIOD: 'period',
  RANGE: 'range',
  ORGANISATION_SEARCH: 'organisation_search',
};

function Filter({
  type,
  name,
  value,
  options,
  isLoading,
  onChange,
  format,
  hasDefault = true,
  filterOptionValue = 'label',
}) {
  switch (type) {
    case FILTER_TYPES.INPUT: {
      return <SearchFilter name={name} value={value} onChange={onChange} />;
    }
    case FILTER_TYPES.SELECT: {
      return (
        <SelectFilter
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          isLoading={isLoading}
          hasDefault={hasDefault}
        />
      );
    }
    case FILTER_TYPES.ORGANISATION_SEARCH: {
      return (
        <OrganisationSearch
          name={name}
          value={value}
          onChange={onChange}
        />
      );
    }
    case FILTER_TYPES.SELECT_MULTIPLE: {
      return (
        <SelectMultiple
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          isLoading={isLoading}
          hasDefault={hasDefault}
          optionValue={filterOptionValue}
        />
      );
    }
    case FILTER_TYPES.SELECT_WITH_FILTER: {
      return (
        <AutoCompleteFilter
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          isLoading={isLoading}
          hasDefault={hasDefault}
        />
      );
    }

    case FILTER_TYPES.DATE: {
      return <DateFilter name={name} value={value} onChange={onChange} />;
    }
    case FILTER_TYPES.PERIOD: {
      return <PeriodFilter name={name} value={value} onChange={onChange} />;
    }
    case FILTER_TYPES.RANGE: {
      return <RangeFilter name={name} value={value} onChange={onChange} formatType={format} />;
    }
    default: {
      return null;
    }
  }
}

function TableHeader({
  title,
  sortBy,
  sorting,
  onClick,
  filterType,
  filterName,
  filterValue,
  filterOptions,
  filterIsLoading,
  filterOnChange,
  filterFormat,
  hasDefault,
  filterOptionValue,
}) {
  function sortOnClick() {
    onClick({ sortBy });
  }

  return (
    <Header>
      <HeaderTitle {...(sortBy ? { onClick: sortOnClick } : {})}>
        {title}
        {sortBy && (
          <HeaderSorting
            data-is-active={sorting.sortBy === sortBy}
            data-sort-type={sorting.order}
          >
            <CaretUpOutlined />
            <CaretDownOutlined />
          </HeaderSorting>
        )}
      </HeaderTitle>
      {filterType && (
        <HeaderFilter>
          {Filter({
            type: filterType,
            name: filterName,
            value: filterValue,
            options: filterOptions,
            isLoading: filterIsLoading,
            onChange: filterOnChange,
            format: filterFormat,
            filterOptionValue,
            hasDefault,
          })}
        </HeaderFilter>
      )}
    </Header>
  );
}

export default TableHeader;
