import styled from 'styled-components';

export const Root = styled.div`
  margin: 0 auto;
  padding: 2rem 4rem 0;
`;

export const Header = styled.header`
  font-size: 28px;
  line-height: 38px;
  padding-bottom: 2rem;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;
