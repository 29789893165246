const RIGHTS = {
  ALL: {
    value: Symbol('ALL'),
    alias: 'all',
  },

  LEADS: {
    // synthetic property - allows to hide nav element
    NAVIGATION: {
      value: Symbol('NAVIGATION'),
      alias: 'application_full',
    },

    // Редактирование онлайн заявки
    EDIT: {
      value: Symbol('EDIT'),
      alias: 'edit_credit_request',
    },

    // Чтение онлайн заявки
    READ: {
      value: Symbol('READ'),
      alias: 'view_credit_request',
    },
  },

  SETTINGS: {
    // Редактирование системных настроек
    EDIT: {
      value: Symbol('EDIT'),
      alias: 'edit_system_setting',
    },
  },

  MANAGEMENT: {
    // Право Добавить внутреннего пользователя
    ADD_ACTIVE_DIRECTORY_USER: {
      value: Symbol('ADD_ACTIVE_DIRECTORY_USER'),
      alias: 'create_internal_user',
    },

    // Право Добавить внешнего пользователя
    ADD_WEB_USER: {
      value: Symbol('ADD_WEB_USER'),
      alias: 'create_external_user',
    },

        /* update */
    // Добавлять[/Редактировать] роли
    ADD_ROLE_TO_WEB_USER: {
      value: Symbol('ADD_ROLE_TO_WEB_USER'),
      alias: 'add_role_to_external_user',
    },

        /* update */
    // Добавлять[/Редактировать] роли
    ADD_ROLE_TO_DIRECTORY_USER: {
      value: Symbol('ADD_ROLE_TO_DIRECTORY_USER'),
      alias: 'add_role_to_internal_user',
    },

    // [Добавлять/]Редактировать роли
    EDIT_ROLE: {
      value: Symbol('EDIT_ROLE'),
      alias: 'edit_role',
    },

    // Назначать роли внутренним пользователям
    ASSIGN_ROLE_TO_ACTIVE_DIRECTORY_USERS: {
      value: Symbol('ASSIGN_ROLE_TO_ACTIVE_DIRECTORY_USERS'),
      alias: 'add_role_to_internal_user',
    },

    // Назначать роли внешним пользователям
    ASSIGN_ROLE_TO_WEB_USERS: {
      value: Symbol('ASSIGN_ROLE_TO_WEB_USERS'),
      alias: 'add_role_to_external_user',
    },

    // Редактирования всех внутренних пользователей
    EDIT_ALL_ACTIVE_DIRECTORY_USERS: {
      value: Symbol('EDIT_ALL_ACTIVE_DIRECTORY_USERS'),
      alias: 'edit_all_internal_user',
    },

    // Редактирования внутреннего пользователя
    EDIT_ACTIVE_DIRECTORY_USERS: {
      value: Symbol('EDIT_ACTIVE_DIRECTORY_USERS'),
      alias: 'edit_internal_user',
    },

    // Редактирования всех внешних пользователей
    EDIT_ALL_WEB_USERS: {
      value: Symbol('EDIT_ALL_WEB_USERS'),
      alias: 'edit_all_external_user',
    },

    // Редактирования внешнего пользователя
    EDIT_WEB_USERS: {
      value: Symbol('EDIT_WEB_USERS'),
      alias: 'edit_external_user',
    },

    /* update */
    // Право Просмотр  пользователя
    VIEW_USER: {
      value: Symbol('VIEW_USER'),
      alias: 'view_external_user',
    },

    /* update */
    // Право Просмотр  пользователя
    VIEW_USER_INTERNAL: {
      value: Symbol('VIEW_USER'),
      alias: 'view_internal_user',
    },

    // Право Редактировать пользователя
    EDIT_USER: {
      value: Symbol('EDIT_USER'),
      alias: 'edit_user',
    },

    /* update */
    // Право Восстановить пароль
    RECOVER_USER_PASSWORD: {
      value: Symbol('RECOVER_USER_PASSWORD'),
      alias: 'reset_password_internal_user',
    },

    /* update */
    // Право Восстановить пароль
    RECOVER_USER_PASSWORD_EXTERNAL: {
      value: Symbol('RECOVER_USER_PASSWORD'),
      alias: 'reset_password_external_user',
    },

    /* update */
    // Право Заблокировать пользователя
    BLOCK_USER: {
      value: Symbol('BLOCK_USER'),
      alias: 'block_internal_user',
    },

    /* update */
    // Право Заблокировать пользователя
    BLOCK_USER_EXTERNAL: {
      value: Symbol('BLOCK_USER'),
      alias: 'block_external_user',
    },

    /* update */
    // Право Разблокировать пользователя
    UNBLOCK_USER: {
      value: Symbol('UNBLOCK_USER'),
      alias: 'unblock_internal_user',
    },

    /* update */
    // Право Разблокировать пользователя
    UNBLOCK_USER_EXTERNAL: {
      value: Symbol('UNBLOCK_USER'),
      alias: 'unblock_external_user',
    },

    // Право назначать роль Куратора
    ASSIGN_CURATOR_ROLE: {
      value: Symbol('ASSIGN_CURATOR_ROLE'),
      alias: 'can_assign_role_curator',
    },

    // Право назначать роль RC
    ASSIGN_RC_ROLE: {
      value: Symbol('ASSIGN_RC_ROLE'),
      alias: 'can_assign_role_rc',
    },

    // Право назначать роль CS
    ASSIGN_CS_ROLE: {
      value: Symbol('ASSIGN_CS_ROLE'),
      alias: 'can_assign_role_cs',
    },

    // Право назначать роль Администратор ЮЛ
    ASSIGN_ORGANISATION_ADMIN_ROLE: {
      value: Symbol('ASSIGN_ORGANISATION_ADMIN_ROLE'),
      alias: 'can_assign_role_merchant_admin',
    },

    // Право назначать роль Контролер
    ASSIGN_CONTROLLER_ROLE: {
      value: Symbol('ASSIGN_CONTROLLER_ROLE'),
      alias: 'can_assign_role_controller',
    },

    // Право назначать роль Оператор
    ASSIGN_OPERATOR_ROLE: {
      value: Symbol('ASSIGN_OPERATOR_ROLE'),
      alias: 'can_assign_role_operator',
    },

    // Право на проверку email-a при создании/редактировании пользователя
    VALIDATE_EMAIL_USER: {
      value: Symbol('VALIDATE_EMAIL_USER'),
      alias: 'validate_email_user',
    },

    // Право на выбор тип пользователя при создании пользователя
    CAN_SELECT_INNER_USER_TYPE: {
      value: Symbol('CAN_SELECT_INNER_USER_TYPE'),
      alias: 'can_select_inner_user_type',
    },
  },

  ORGANISATIONS: {
    // Право Добавлять[/Загружать] организации
    CREATE: {
      value: Symbol('CREATE'),
      alias: 'create_merchant',
    },

    // Право Добавлять[/Загружать] организации
    CREATE_FALLBACK: {
      value: Symbol('CREATE_FALLBACK'),
      alias: 'create_merchant_by_hand',
    },

    // Право Добавлять[/Загружать] организации
    CREATE_WITH_LIQUIDATED_STATUS: {
      value: Symbol('CREATE_WITH_LIQUIDATED_STATUS'),
      alias: 'create_merchant_with_liquidated_status',
    },

    // Право [Добавлять/]Загружать организации
    UPLOAD: {
      value: Symbol('UPLOAD'),
      alias: 'load_merchant',
    },

    // Право Просматривать Организацию
    VIEW: {
      value: Symbol('VIEW'),
      alias: 'view_merchant',
    },

    /* add */
    // Право Просматривать Все Организации
    VIEW__ALL: {
      value: Symbol('VIEW__ALL'),
      alias: 'list_merchant',
    },

    // Право редактировать Все поля Организации
    EDIT_WITHOUT_RESTRICTIONS: {
      value: Symbol('EDIT_WITHOUT_RESTRICTIONS'),
      alias: 'edit_merchant',
    },

    // Право редактировать ограниченные поля Организации
    EDIT_WITH_RESTRICTIONS: {
      value: Symbol('EDIT_WITH_RESTRICTIONS'),
      alias: 'edit_restricted_fields_merchant',
    },

    // Право добавлять[/загружать] Торговые точки
    CREATE_POINTS_OF_SALES: {
      value: Symbol('CREATE_POINTS_OF_SALES'),
      alias: 'create_dealer_center',
    },

    // Право [добавлять/]загружать Торговые точки
    UPLOAD_POINTS_OF_SALES: {
      value: Symbol('UPLOAD_POINTS_OF_SALES'),
      alias: 'load_dealer_center',
    },

    // Право просматривать Торговые точки
    VIEW_POINTS_OF_SALES: {
      value: Symbol('VIEW_POINTS_OF_SALES'),
      alias: 'view_dealer_center',
    },

    // Право редактировать Торговые точки
    EDIT_POINTS_OF_SALES: {
      value: Symbol('EDIT_POINTS_OF_SALES'),
      alias: 'edit_dealer_center',
    },

    VIEW_USERS: {
      value: Symbol('VIEW_USERS'), // Право на просмотр страницы Пользователи
      alias: null,
    },
  },

  AGREEMENTS: {
    // synthetic property - allows to hide nav element
    NAVIGATION: {
      value: Symbol('NAVIGATION'),
      alias: null,
    },
  },
  REPORTS: {

    // Формировать отчеты
    GENERATE_REPORTS: {
      value: Symbol('GENERATE_REPORTS'),
      alias: 'generate_reports',
    },

    // Выгружать отчеты
    UPLOAD_REPORTS: {
      value: Symbol('UPLOAD_REPORTS'),
      alias: 'upload_reports',
    },
  },

  ACQUIRING_AGREEMENT: {
    // просматривать договор эквайринга
    VIEW: {
      value: Symbol('VIEW'),
      alias: 'view_acquiring_agreement',
    },

    // создавать договор эквайринга
    CREATE: {
      value: Symbol('CREATE'),
      alias: 'create_acquiring_agreement',
    },

    // редактировать договор эквайринга
    EDIT: {
      value: Symbol('EDIT'),
      alias: 'edit_acquiring_agreement',
    },

    // подтверждать договор
    CONFIRM: {
      value: Symbol('CONFIRM'),
      alias: 'confirm_agreement',
    },

    // менять статус договора
    CHANGE_STATUS: {
      value: Symbol('CHANGE_STATUS'),
      alias: 'change_agreement_status',
    },

    CHANGE_ONLY_STATUS: {
      value: Symbol('CHANGE_ONLY_STATUS'),
      alias: 'edit_acquiring_agreement_only_status_edit',
    },

    // блокировать договор
    BLOCK: {
      value: Symbol('BLOCK'),
      alias: 'block_agreement',
    },

  },

  PAYMENTS: {
    // Просматривать платежи организации
    VIEW: {
      value: Symbol('VIEW'),
      alias: 'payment_list',
    },

    /* add */
    // Создавать счета
    CREATE: {
      value: Symbol('CREATE_BILLS'),
      alias: 'payment_create',
    },

    // Подтверждать платежи (2фазные)
    CONFIRM: {
      value: Symbol('CONFIRM'),
      alias: 'approve_payment',
    },

    /* add */
    // Реверс платежей
    REVERSE: {
      value: Symbol('REVERSE'),
      alias: 'revers_payment',
    },

    // Делать возврат
    RETURN: {
      value: Symbol('RETURN'),
      alias: 'cancel_payment',
    },

    // Создавать счета
    CREATE_BILLS: {
      value: Symbol('CREATE_BILLS'),
      alias: 'create_account',
    },

    // Просматривать счета организации
    VIEW_ORGANISATION_BILLS: {
      value: Symbol('VIEW_ORGANISATION_BILLS'),
      alias: 'account_list',
    },

    // Просматривать свои счета
    VIEW_MY_BILLS: {
      value: Symbol('VIEW_MY_BILLS'),
      alias: 'account_list_own',
    },

    // Отклонять свои счета
    DECLINE_MY_BILLS: {
      value: Symbol('DECLINE_MY_BILLS'),
      alias: 'reject_own_account',
    },

    // Отклонять все счета организации
    DECLINE_ORGANISATION_BILLS: {
      value: Symbol('DECLINE_ORGANISATION_BILLS'),
      alias: 'reject_account',
    },

    // Выгружать отчеты
    DOWNLOAD_REPORTS: {
      value: Symbol('DOWNLOAD_REPORTS'),
      alias: 'upload_reports',
    },

    // Формировать отчеты
    GENERATE_REPORTS: {
      value: Symbol('GENERATE_REPORTS'),
      alias: 'generate_reports',
    },
  },

  TCM: {
    VIEW_CAMPAIGN: {
      value: Symbol('VIEW_CAMPAIGN'),
      alias: 'tcm_view_campaign',
    },

    CREATE_CAMPAIGN: {
      value: Symbol('CREATE_CAMPAIGN'),
      alias: 'tcm_create_campaign',
    },

    VIEW_OFFER: {
      value: Symbol('VIEW_OFFER'),
      alias: 'tcm_view_offer',
    },

    CAMPAIGN_SET_EVALUATION: {
      value: Symbol('CAMPAIGN_SET_EVALUATION'),
      alias: 'tcm_set_evaluation',
    },

    SET_DISTRIBUTION: {
      value: Symbol('SET_DISTRIBUTION'),
      alias: 'tcm_set_distribution',
    },

    SET_CAMPAIGN_STATUS: {
      value: Symbol('SET_CAMPAIGN_STATUS'),
      alias: 'tcm_set_campaign_status',
    },

    EXPORT_OFFERS: {
      value: Symbol('EXPORT_OFFERS'),
      alias: 'tcm_export_offers',
    },

    IMPORT_OFFERS: {
      value: Symbol('IMPORT_OFFERS'),
      alias: 'tcm_import_offers',
    },

    ADD_COMMENT_TO_OFFER: {
      value: Symbol('ADD_COMMENT_TO_OFFER'),
      alias: 'tcm_add_comment_to_odder',
    },

    ADD_CALL_TO_OFFER: {
      value: Symbol('ADD_CALL_TO_OFFER'),
      alias: 'tcm_add_call_to_offer',
    },

    ADD_VISIT_TO_OFFER: {
      value: Symbol('ADD_VISIT_TO_OFFER'),
      alias: 'tcm_add_visit_to_offer',
    },

    OFFER_SET_STATUS_WAIT_CONFIRM: {
      value: Symbol('OFFER_SET_STATUS_WAIT_CONFIRM'),
      alias: 'tcm_offer_set_status_wait_confirm',
    },

    OFFER_SET_STATUS_CONFIRMED: {
      value: Symbol('OFFER_SET_STATUS_CONFIRMED'),
      alias: 'tcm_offer_set_status_confirmed',
    },

    OFFER_SET_STATUS_REVISION: {
      value: Symbol('OFFER_SET_STATUS_REVISION'),
      alias: 'tcm_offer_set_status_revision',
    },

    OFFER_SET_CALCULATION: {
      value: Symbol('OFFER_SET_CALCULATION'),
      alias: 'tcm_offer_set_calculation',
    },

    VIEW_CAMPAIGNS_COUNT: {
      value: Symbol('VIEW_CAMPAIGNS_COUNT'),
      alias: 'tcm_view_campaigns_count',
    },

    VIEW_MANAGER_OFFERS_COUNT: {
      value: Symbol('VIEW_MANAGER_OFFERS_COUNT'),
      alias: 'tcm_view_offers_count',
    },

    VIEW_OFFERS_COUNT: {
      value: Symbol('VIEW_OFFERS_COUNT'),
      alias: 'tcm_view_self_offers_count',
    },
    VIEW_USERS: {
      value: Symbol('VIEW_USERS'),
      alias: 'view_external_user_without_viewing_sections',
    },
    VIEW_COMPANIES: {
      value: Symbol('VIEW_COMPANIES'),
      alias: 'list_merchant_without_viewing_sections',
    },
    VIEW_POINTS_OF_SALES: {
      value: Symbol('VIEW_POINTS_OF_SALES'),
      alias: 'view_dealer_center_without_viewing_sections',
    },
  },
};

export const buildRightsMatrix = (array) => Object.entries(RIGHTS).reduce(
  (p, [group, priviledges]) => ({
    ...p,
    [group]: Object.entries(priviledges).reduce(
      (p, [priviledge, { alias }]) => ({
        ...p,
        [priviledge]: Boolean(array.find((e) => e.alias === alias)),
      }),
      {},
    ),
  }),
  {},
);

export const checkPrivilege = (rights = []) => {
  if (Array.isArray(rights)) {
    return rights.some((right) => right === true);
  }

  return rights === true;
};
