import { getStringFromDate } from 'helpers/date';
import moment from 'moment';
import {
  STATUS_DATA, CAMPAIGN_STATUSES, CAMPAIGN_TIMESTAMPS, DATE_FORMAT,
} from 'tcm/campaigns/data';
import { CHAR } from 'settings/namedUnicode';

const getCampaignMessageFromCode = (statusCode, deadline) => {
  const formattedDeadline = moment(deadline).format('DD.MM.YYYY');
  switch (statusCode) {
    case CAMPAIGN_STATUSES.VIN_REQUEST:
      return `Ожидаемая${CHAR.NBSP}дата${CHAR.NBSP}получения VIN${CHAR.NBSP}номеров:${CHAR.NBSP}${formattedDeadline}`;
    case CAMPAIGN_STATUSES.EVALUATION:
      return `Оцените${CHAR.NBSP}автомобили до${CHAR.NBSP}${formattedDeadline}`;
    case CAMPAIGN_STATUSES.KP_CALCULATION:
      return `Ожидаемая${CHAR.NBSP}дата${CHAR.NBSP}получения${CHAR.NBSP}расчета${CHAR.NBSP}КП от${CHAR.NBSP}банка:${CHAR.NBSP}${formattedDeadline}`;
    case CAMPAIGN_STATUSES.DISTRIBUTION:
      return `Распределите${CHAR.NBSP}клиентов${CHAR.NBSP}до${CHAR.NBSP}${formattedDeadline}`;
    case CAMPAIGN_STATUSES.CALLING:
      return `Ожидаемая${CHAR.NBSP}дата${CHAR.NBSP}окончания обзвона${CHAR.NBSP}клиентов:${CHAR.NBSP}${formattedDeadline}`;
    case CAMPAIGN_STATUSES.CONFIRMED:
      return `Подтвердите результат обзвона до ${formattedDeadline}`;
    case CAMPAIGN_STATUSES.COMPLETE:
    case CAMPAIGN_STATUSES.REVISION:
    case CAMPAIGN_STATUSES.DRAFT:
      return '';
    default:
      console.error('Wrong code of message status!');
      return '';
  }
};

const getFormattedStatus = (status, deadline) => ({
  code: status.status,
  title: STATUS_DATA[status.status].title,
  createdAt: status.createdAt,
  createAtRaw: status.createdAt,
  timestamp: status.isReal ? CAMPAIGN_TIMESTAMPS.DONE : CAMPAIGN_TIMESTAMPS.PENDING,
  popUpText: getCampaignMessageFromCode(status.status, deadline),
  author: status?.user?.name,
});

const modifiedStatus = (status, campaign) => {
  const modifiedCurrent = getFormattedStatus(status, campaign.deadline);

  modifiedCurrent.timestamp = modifiedCurrent.code === campaign.status
    ? CAMPAIGN_TIMESTAMPS.CURRENT
    : modifiedCurrent.timestamp;

  modifiedCurrent.deadline = modifiedCurrent.code === campaign.status
    && getStringFromDate(new Date(campaign.deadline));

  modifiedCurrent.createdAt = [getStringFromDate(new Date(modifiedCurrent.createdAt), DATE_FORMAT.DD_MM)];

  return modifiedCurrent;
};

export const formatCampaign = (rawData) => {
  if (typeof rawData === 'object') {
    try {
      return {
        id: rawData.id,
        statusCode: rawData.status,
        status: STATUS_DATA[rawData.status].title,
        deadline: getStringFromDate(new Date(rawData.deadline)),
        dialingFrom: getStringFromDate(new Date(rawData.dialingFrom), DATE_FORMAT.DD_MM),
        dialingFromRaw: rawData.dialingFrom,
        dialingTo: getStringFromDate(new Date(rawData.dialingTo)),
        dialingToRaw: rawData.dialingTo,
        calculatedFrom: moment(rawData.calculatedFrom),
        calculatedTo: moment(rawData.calculatedTo),
        countCarsInStock: rawData.countCarsInStock,
        topic: rawData.subtopic.topic.name,
        topicId: rawData.subtopic.topic.id,
        subtopic: rawData.subtopic.name,
        subtopicId: rawData.subtopic.id,
        canClosed: rawData.canClosed,
        canSetNextStatus: rawData.canSetNextStatus,
        comments: rawData.comments.filter((comment) => comment.text.trim().length).map(
          (comment) => ({
            ...comment,
            createdAt: getStringFromDate(new Date(comment.createdAt), DATE_FORMAT.DD_MM__HH_MM),
          }),
        ),
        statusHistory: rawData.statusHistory.reduce((prev, curr) => {
          const modifiedCurrent = modifiedStatus(curr, rawData);

          const repeatedIndex = prev.findIndex((status) => status.code === modifiedCurrent.code);
          if (repeatedIndex !== -1) {
            const repeated = prev[repeatedIndex];
            const leftOther = prev.slice(0, repeatedIndex);
            const rightOther = prev.slice(repeatedIndex + 1);
            const modifiedRepeated = [repeated, modifiedCurrent].reduce((prev, curr) => ({
              ...prev,
              createdAt: prev.createdAt.concat([curr.createdAt]),
            }));
            return leftOther.concat(modifiedRepeated).concat(rightOther);
          }

          if (prev[prev.length - 1].code === CAMPAIGN_STATUSES.VIN_REQUEST
            && prev[prev.length - 1]?.timestamp === CAMPAIGN_TIMESTAMPS.CURRENT
            && modifiedCurrent.code === CAMPAIGN_STATUSES.REVISION
          ) {
            return [...prev.slice(0, -1), modifiedCurrent, ...prev.slice(-1)];
          }

          return [...prev, modifiedCurrent];
        }, [{
          code: CAMPAIGN_STATUSES.CREATION,
          title: STATUS_DATA[CAMPAIGN_STATUSES.CREATION].title,
          createdAt: [moment(rawData.createdAt).format('DD.MM.YYYY')],
          timestamp: CAMPAIGN_STATUSES.DONE,
          author: rawData.creator.name,
        }]),

      };
    } catch (e) {
      console.error(`formatCampaign: invalid data. ${e.name}: ${e.message}`);
      return {};
    }
  }
  console.error('formatCampaign: type of "data" parameter must be "Object"!');
  return {};
};
