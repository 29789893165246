export const KEYS = {
  BRANDS: {
    key: 'BRANDS',
    apiKey: 'brands',
    label: 'Марка',
    placeholder: 'Выберите марку автомобиля',
  },

  MODELS: {
    key: 'MODELS',
    apiKey: 'models',
    label: 'Модели',
    placeholder: 'Выберите модель',
  },

  SUFFIX: {
    key: 'SUFFIX',
    apiKey: 'suffixTMS',
    label: 'Суффикс',
    placeholder: 'Выберите суффикс',
  },

  EMPLOYEES: {
    key: 'EMPLOYEES',
    apiKey: 'employees',
    label: 'Сотрудник',
    placeholder: 'Выберите сотрудника',
  },

  PRICE_TRADE_IN_FROM: {
    key: 'PRICE_TRADE_IN_FROM',
    apiKey: 'tradeInFrom',
    label: 'Цена Trade-in от',
  },

  PRICE_TRADE_IN_TO: {
    key: 'PRICE_TRADE_IN_TO',
    apiKey: 'tradeInTo',
    label: 'Цена Trade-in до',
  },

  PRICE_NEW_CAR_FROM: {
    key: 'PRICE_NEW_CAR_FROM',
    apiKey: 'priceNewCarFrom',
    label: 'Цена нового автомобиля от',
  },

  PRICE_NEW_CAR_TO: {
    key: 'PRICE_NEW_CAR_TO',
    apiKey: 'priceNewCarTo',
    label: 'Цена нового автомобиля до',
  },

};
