/* List */
export const CAMPAIGN_FETCH_LIST = 'CAMPAIGN_FETCH_LIST';
export const CAMPAIGN_FETCH_LIST__SUCCESS = 'CAMPAIGN_FETCH_LIST__SUCCESS';
export const CAMPAIGN_FETCH_LIST__FAILURE = 'CAMPAIGN_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filter = {}, sorting,
} = {}) => ({
  type: CAMPAIGN_FETCH_LIST,
  search,
  page,
  filter,
  sorting,
});

export const CAMPAIGN_FETCH_LIST__CANCEL_REQUEST = 'CAMPAIGN_FETCH_LIST__CANCEL_REQUEST';
export const CAMPAIGN_FETCH_LIST__SET_LOADING = 'CAMPAIGN_FETCH_LIST__SET_LOADING';
export const CAMPAIGN_FETCH_LIST__SET_PAGINATION = 'CAMPAIGN_FETCH_LIST__SET_PAGINATION';

export const CAMPAIGN_FETCH_LIST__SET_SEARCH = 'CAMPAIGN_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({
  type: CAMPAIGN_FETCH_LIST__SET_SEARCH,
  search,
});

export const CAMPAIGN_FETCH_LIST__SET_PAGE = 'CAMPAIGN_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: CAMPAIGN_FETCH_LIST__SET_PAGE, page });

export const CAMPAIGN_FETCH_LIST__SET_SORTING = 'CAMPAIGN_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: CAMPAIGN_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const CAMPAIGN_FETCH_LIST__SET_FILTER = 'CAMPAIGN_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters = {} } = {}) => ({
  type: CAMPAIGN_FETCH_LIST__SET_FILTER,
  filters,
});

export const CAMPAIGN_FETCH_LIST__RESET = 'CAMPAIGN_FETCH_LIST__RESET';
export const resetFetchList = () => ({ type: CAMPAIGN_FETCH_LIST__RESET });

/* Item */
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE';
export const CAMPAIGN_CREATE__SUCCESS = 'CAMPAIGN_CREATE__SUCCESS';
export const CAMPAIGN_CREATE__FAILURE = 'CAMPAIGN_CREATE__FAILURE';
export const CAMPAIGN_CREATE__RESET_ERROR = 'CAMPAIGN_CREATE__RESET_ERROR';
export const CAMPAIGN_CREATE__RESET_FORM = 'CAMPAIGN_CREATE__RESET_FORM';

export const CAMPAIGN_FETCH = 'CAMPAIGN_FETCH';
export const CAMPAIGN_FETCH__SUCCESS = 'CAMPAIGN_FETCH__SUCCESS';
export const CAMPAIGN_FETCH__FAILURE = 'CAMPAIGN_FETCH__FAILURE';
export const create = ({
  status = '',
  subtopicId = '',
  countCarsInStock = '',
  dialingFrom = '',
  dialingTo = '',
  comment = '',
} = {}) => ({
  type: CAMPAIGN_CREATE,
  status,
  subtopicId,
  countCarsInStock,
  dialingFrom,
  dialingTo,
  comment,
});
export const CAMPAIGN_FETCH__RESET = 'CAMPAIGN_FETCH__RESET';

export const resetCampaign = () => ({
  type: CAMPAIGN_FETCH__RESET,
});

export const resetError = () => ({ type: CAMPAIGN_CREATE__RESET_ERROR });
export const resetForm = () => ({ type: CAMPAIGN_CREATE__RESET_FORM });

/**
 *
 * @param {number} id
 * @return {{id: string, type: string}}
 */
export const fetch = (id) => ({ type: CAMPAIGN_FETCH, id });

/* Campaign status set to bank */
export const CAMPAIGN_STATUS_SET = 'CAMPAIGN_STATUS_SET';
export const CAMPAIGN_STATUS_SET_SUCCESS = 'CAMPAIGN_STATUS_SET_SUCCESS';
export const CAMPAIGN_STATUS_SET__FAILURE = 'CAMPAIGN_STATUS_SET__FAILURE';
export const CAMPAIGN_STATUS_SET__RESET = 'CAMPAIGN_STATUS_SET__RESET';

export const setCampaignStatus = ({
  id,
  status,
  comment,
}) => ({
  type: CAMPAIGN_STATUS_SET, id, status, comment,
});

export const resetSetCampaignStatus = (() => ({ type: CAMPAIGN_STATUS_SET__RESET }));

// STATUS LIST
export const CAMPAIGN_FETCH_STATUS_LIST = 'CAMPAIGN_FETCH_STATUS_LIST';
export const CAMPAIGN_FETCH_STATUS_LIST_SUCCESS = 'CAMPAIGN_FETCH_STATUS_LIST_SUCCESS';
export const CAMPAIGN_FETCH_STATUS_LIST__FAILURE = 'CAMPAIGN_FETCH_STATUS_LIST__FAILURE';

export const fetchCampaignStatusList = () => ({ type: CAMPAIGN_FETCH_STATUS_LIST });

// DIALING-DATES
export const CAMPAIGN_FETCH_DIALING_DATES = 'CAMPAIGN_FETCH_DIALING_DATES';
export const CAMPAIGN_FETCH_DIALING_DATES__SUCCESS = 'CAMPAIGN_FETCH_DIALING_DATES__SUCCESS';
export const CAMPAIGN_FETCH_DIALING_DATES__FAILURE = 'CAMPAIGN_FETCH_DIALING_DATES__FAILURE';
export const fetchDialingDates = ({
  dialingFrom,
} = {}) => ({
  type: CAMPAIGN_FETCH_DIALING_DATES,
  dialingFrom,
});

export const CAMPAIGN_REJECT = 'CAMPAIGN_REJECT';
export const CAMPAIGN_REJECT__SUCCESS = 'CAMPAIGN_REJECT__SUCCESS';
export const CAMPAIGN_REJECT__FAILURE = 'CAMPAIGN_REJECT__FAILURE';
export const CAMPAIGN_REJECT__RESET = 'CAMPAIGN_REJECT__RESET';
export const campaignReject = ({ reason }) => ({ type: CAMPAIGN_REJECT, reason });
export const campaignRejectReset = () => ({ type: CAMPAIGN_REJECT__RESET });

export const CAMPAIGN_GET_DATE = 'CAMPAIGN_GET_DATE';
export const CAMPAIGN_GET_DATE__SUCCESS = 'CAMPAIGN_GET_DATE__SUCCESS';
export const CAMPAIGN_GET_DATE__FAILURE = 'CAMPAIGN_GET_DATE__FAILURE';
export const CAMPAIGN_GET_DATE__RESET = 'CAMPAIGN_GET_DATE__RESET';
export const campaignGetDate = ({ dateFrom, dateTo }) => ({
  type: CAMPAIGN_GET_DATE,
  dateFrom,
  dateTo,
});

export const CAMPAIGN_GET_REPORT = 'CAMPAIGN_GET_REPORT';
export const CAMPAIGN_GET_REPORT__SUCCESS = 'CAMPAIGN_GET_REPORT__SUCCESS';
export const CAMPAIGN_GET_REPORT__FAILURE = 'CAMPAIGN_GET_REPORT__FAILURE';
export const CAMPAIGN_GET_REPORT__RESET = 'CAMPAIGN_GET_REPORT__RESET';
export const campaignGetReport = ({ campaignIds, startAt, finishAt }) => ({
  type: CAMPAIGN_GET_REPORT,
  campaignIds,
  startAt,
  finishAt,
});
export const campaignResetReport = () => ({
  type: CAMPAIGN_GET_REPORT__RESET,
});
