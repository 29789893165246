import API from 'services';

import { extractErrorAndCode, formatProduct } from 'hacks';

import {
  all, fork, put, takeLatest, call, select,
} from 'redux-saga/effects';

import {
  PRODUCT__FIND,
  PRODUCT__FIND_LOADING,
  PRODUCT__FIND_SUCCESS,
  PRODUCT__FIND_FAILURE,
} from './actions';

const getProductState = (state) => state.product;

export function* find() {
  yield takeLatest(PRODUCT__FIND, function* (action) {
    const {
      params: {
        dealerCenterName, group, brand, model,
      },
    } = yield select(getProductState);

    if (
      !(
        dealerCenterName === action.dealerCenterName
        && group === action.group
        && brand === action.brand
        && model === action.model
      )
    ) {
      yield put({
        type: PRODUCT__FIND_LOADING,
        dealerCenterName: action.dealerCenterName,
        group: action.group,
        brand: action.brand,
        model: action.model,
      });

      try {
        const response = yield call(API.product.find, {
          dealerCenterName: action.dealerCenterName,
          group: action.group,
          brand: action.brand,
          model: action.model,
          accessTokenCreditRequest: action.accessTokenCreditRequest,
        });

        if (response && response.data) {
          const list = formatProduct(response);
          yield put({ type: PRODUCT__FIND_SUCCESS, list });
        } else {
          const { error } = extractErrorAndCode(response);
          throw new Error(error);
        }
      } catch (error) {
        yield put({ type: PRODUCT__FIND_FAILURE, error: error.message });
      }
    }
  });
}

export default function* rootSaga() {
  yield all([fork(find)]);
}
