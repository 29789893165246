import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import tableActions from 'redux/table/actions';
import { withRouter } from 'react-router-dom';
import { useForm } from 'hooks';
import { defaultPageSize, initialFilters } from 'redux/table/reducer';
import {
  DATE_FORMAT_WITH_TIME as DATE_FORMAT,
  STATUS_LIST,
  ARM_COMMENTS,
} from 'settings';
import moment from 'moment';
import { fetchDealers as fetchDealerCenter } from 'redux/dealerCenter/actions';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';

import CommentsModal from '../Comments';

import {
  Root,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
  LinkStyled,
  ErrorMessageStatus,
  CommentsBtnContainer,
  CommentsBtn,
} from './style';

const {
  reset: resetTableAction,
  fetchData: fetchDataAction,
  setPage: setTablePageAction,
  setSorting: setSortingAction,
  setFilters: setFiltersAction,
} = tableActions;

const scroll = { y: 'calc(100vh - 380px)' };
const showTotal = (total, range) => `${range[0]}-${range[1]} из ${total}`;

const renderStatus = (status) => {
  const statusSplit = status.split(' - ');
  if (statusSplit.length > 1) {
    return (
      <>
        <span>{statusSplit[0]}</span>
        {' - '}
        <ErrorMessageStatus>{statusSplit[1]}</ErrorMessageStatus>
      </>
    );
  }
  return (
    <span>{statusSplit[0]}</span>
  );
};

function TableComponent({
  // connect
  list,
  sorting,
  isLoading,
  error,
  pagination,
  dealerCenterList,
  dealerCenterIsLoaded,
  dealerCenterIsLoading,
  dealerCenterError,

  // actions
  resetTable,
  setTablePage,
  setSorting,
  setFilters,
  fetchDealerCenter,
}) {
  useEffect(() => {
    if (!dealerCenterIsLoaded && !dealerCenterIsLoading && !dealerCenterError) {
      fetchDealerCenter();
    }
  }, [dealerCenterIsLoaded, dealerCenterIsLoading, dealerCenterError, fetchDealerCenter]);

  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);

  const handleShowModal = (comments) => {
    setComments(comments);
    setShowComments((prevState) => !prevState);
  };

  const handleHideModal = () => {
    setComments([]);
    setShowComments((prevState) => !prevState);
  };

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  useEffect(() => {
    setFilters({ filters: form });
    return () => resetTable();
  }, [form, resetTable, setFilters]);

  // I do not agree with current way of retrieving the data from the sever
  // We use `acccessToken` and `accessCode` to get the specific data entry
  // Which leads us to the problem - since the only way to get both `accessToken` and
  // `accessCode` is to load the list of data first,
  // we are forced to use `accessToken` as an unique id instead of number - and generate links with it
  // So for now we are swapping `number` and `accessToken`
  // UPD: there is no need to `accessCode` anymore

  const columns = [
    {
      key: 'dealerCenter',
      title: () =>
        TableHeader({
          title: 'Наименование ДЦ',
          sortBy: 'dealerCenter',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_WITH_FILTER,
          filterName: 'dealerCenter',
          filterValue: form.dealerCenter,
          filterOptions: dealerCenterList,
          filterIsLoading: dealerCenterIsLoading,
          filterOnChange: updateForm,
        }),
      render: ({ dealerCenter, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>{dealerCenter}</LinkStyled>
      ),
      width: 240,
    },
    {
      key: 'number',
      title: () =>
        TableHeader({
          title: '№ заявки',
          sortBy: 'number',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'number',
          filterValue: form.number,
          filterOnChange: updateForm,
        }),
      render: ({ number, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>{number}</LinkStyled>
      ),
      width: 120,
    },
    {
      key: 'status',
      title: () =>
        TableHeader({
          title: 'Статус заявки',
          sortBy: 'status',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'status',
          filterValue: form.status,
          filterOptions: STATUS_LIST,
          filterIsLoading: false,
          filterOnChange: updateForm,
        }),
      render: ({ status, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>
          {
            renderStatus(status)
          }
        </LinkStyled>
      ),
      width: 220,
    },
    {
      key: 'createdAt',
      title: () =>
        TableHeader({
          title: 'Дата создания заявки',
          sortBy: 'createdAt',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.DATE,
          filterName: ['createdAtFrom', 'createdAtTo'],
          filterValue: [form.createdAtFrom, form.createdAtTo],
          filterOnChange: updateForm,
        }),
      render: ({ createdAt, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>
          {moment(createdAt).format(DATE_FORMAT)}
        </LinkStyled>
      ),
      width: 220,
    },
    {
      key: 'updatedAt',
      title: () =>
        TableHeader({
          title: 'Последнее действие клиента',
          sortBy: 'updatedAt',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.DATE,
          filterName: ['updatedAtFrom', 'updatedAtTo'],
          filterValue: [form.updatedAtFrom, form.updatedAtTo],
          filterOnChange: updateForm,
        }),
      render: ({ updatedAt, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>
          {moment(updatedAt).format(DATE_FORMAT)}
        </LinkStyled>
      ),
      width: 240,
    },
    {
      key: 'name',
      title: () =>
        TableHeader({
          title: 'ФИО клиента',
          sortBy: 'name',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'name',
          filterValue: form.name,
          filterOnChange: updateForm,
        }),
      render: ({ name, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>{name}</LinkStyled>
      ),
      width: 220,
    },
    {
      key: 'phone',
      title: () =>
        TableHeader({
          title: 'Телефон клиента',
          sortBy: 'phone',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'phone',
          filterValue: form.phone,
          filterOnChange: updateForm,
        }),
      render: ({ phone, accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}`}>{phone}</LinkStyled>
      ),
      width: 200,
    },
    ...(ARM_COMMENTS ? [{
      key: 'comments',
      title: () =>
        TableHeader({
          title: '',
        }),
      render: ({ comments }) => Array.isArray(comments)
        && comments.length > 0 && (
        <CommentsBtnContainer>
          <CommentsBtn onClick={() => handleShowModal(comments)}>
            Комментарии
          </CommentsBtn>
        </CommentsBtnContainer>
      ),
      width: 166,
    }] : [{
      key: 'history',
      title: () =>
        TableHeader({
          title: '',
        }),
      render: ({ accessToken }) => (
        <LinkStyled to={`/app/leads/request/${accessToken}/history`}>
          История действий
        </LinkStyled>
      ),
      width: 166,
    }]),
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const paginationObj = {
    current: page,
    pageSize: defaultPageSize,
    total: pagination ? pagination.rowsCount : 0,
    showTotal,
    onChange: goToPage,
  };

  return (
    <Root>
      <Table
        rowKey={(record) => record.number}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
      <CommentsModal
        isModalVisible={showComments}
        comments={comments}
        hideModal={handleHideModal}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    list: state.table.list,
    sorting: state.table.sorting,
    filters: state.table.filters,
    isLoaded: state.table.isLoaded,
    isLoading: state.table.isLoading,
    error: state.table.error,
    pagination: state.table.data.pagination || {},
    dealerCenterList: state.dealerCenter.dealers.list,
    dealerCenterIsLoaded: state.dealerCenter.dealers.isLoaded,
    dealerCenterIsLoading: state.dealerCenter.dealers.isLoading,
    dealerCenterError: state.dealerCenter.dealers.error,
  }),
  {
    resetTable: resetTableAction,
    fetchData: fetchDataAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    fetchDealerCenter,
  },
)(withRouter(TableComponent));
