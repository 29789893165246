import React from 'react';

import { RadioStyled, GroupStyled } from './style';

const defaultProps = {};

export const Radio = React.forwardRef((props, ref) => (
  <RadioStyled {...defaultProps} {...props} ref={ref} />
));
export const Group = React.forwardRef((props, ref) => (
  <GroupStyled {...defaultProps} {...props} ref={ref} />
));
export default Radio;
