import styled from 'styled-components';
import { Form } from 'antd';

export const Root = styled.div`
  overflow: auto;
  height: 100%;
  margin: 0;
`;

export const OrganizationRow = styled.div`
  background: rgba(0, 0, 0, 0.03);
  height: 100%;
  padding: 0 1rem 1rem;
  margin: 0 0 1.5rem;
  margin-left: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: max-content;
  margin-top: -10px;
  padding-top: 10px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2rem 4rem;
`;

export const Title = styled.h3`
  font-family: "Open Sans";
  font-size: 24px;
  line-height: 30px;
  color: #000;
`;

export const Main = styled(Form)`
  padding: 2rem 4rem;
  margin: 0 auto;
  border-top: 1px solid #e6e6e6;

  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & textarea.ant-input {
    margin-bottom: 0px;
  }
`;

export const Rows = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const RowTop = styled.div`
  display: flex;
  width: 50%;
  flex-flow: column nowrap;
`;

export const RowContainer = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FooterAction = styled.footer`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: flex-end;
  padding-bottom: 1rem;
  
  a{
    margin-right: 20px;
  }
`;

export const FormActionGroup = styled.div``;

export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const FormSubmit = styled.div`
  padding-left: 1rem;
  margin-top: calc(1.2rem + 3px); // height of label + padding of label
`;

export const TimeHelper = styled.div`
  margin-top: -20px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SmallSuccessMessage = styled.div`
  color: green;
  padding-top: 1rem;
`;

export const Status = styled.div`
  color: #096dd9;
`;
