export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const checkAuthorization = () => ({ type: CHECK_AUTHORIZATION });

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const WRITE_AUTH_DATE_FROM_CACHE = 'WRITE_AUTH_DATE_FROM_CACHE';
export const ON_ERROR = 'ON_ERROR';

export const LOGOUT = 'LOGOUT';
export const logout = () => ({ type: LOGOUT });

export const SIGN_IN_WITH_EMAIL = 'SIGN_IN_WITH_EMAIL';
export const SIGN_IN_WITH_EMAIL__SUCCESS = 'SIGN_IN_WITH_EMAIL__SUCCESS';
export const SIGN_IN_WITH_EMAIL__FAILURE = 'SIGN_IN_WITH_EMAIL__FAILURE';
export const signInWithEmail = ({ username, password, rememberMe = false }) => ({
  type: SIGN_IN_WITH_EMAIL,
  username,
  password,
  rememberMe,
});

export const REFETCH_PERMISSIONS = 'REFETCH_PERMISSIONS';
export const REFETCH_PERMISSIONS__SUCCESS = 'REFETCH_PERMISSIONS__SUCCESS';
export const REFETCH_PERMISSIONS__FAILURE = 'REFETCH_PERMISSIONS__FAILURE';
