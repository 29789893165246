import React from 'react';

export const AttentionIcon = ({ color, ...p }) => {
  const colorCode = (() => {
    switch (color) {
      case 'red':
        return '#E50000';
      case 'gray':
        return '#A8AAAC';
      default:
        return '#000000';
    }
  })();
  return (
    <svg {...p} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke={colorCode} strokeWidth="2" />
      <path d="M9 13.8C8.4 13.8 8 13.4 8 12.8V8.80005C8 8.20005 8.4 7.80005 9 7.80005C9.6 7.80005 10 8.20005 10 8.80005V12.8C10 13.4 9.6 13.8 9 13.8Z" fill={colorCode} />
      <path d="M9 7C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6C8 6.55228 8.44772 7 9 7Z" fill={colorCode} />
    </svg>
  );
};
