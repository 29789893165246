import React from 'react';
import { connect } from 'react-redux';

import { isSearchAvailable } from 'settings';

import { fetchList as fetchCrifList } from 'redux/crif/actions';

import { Root, ButtonStyled } from './style';

function MoratoriumButton({
  // passed
  area,

  // connect
  values,
  fetchId,
  isLoading,
  hasActiveMoratorium,
  buttonIsLocked,

  // actions
  fetchCrifList,
}) {
  function fetchList() {
    fetchCrifList({ accessToken: fetchId });
  }

  const isDisabled = !isSearchAvailable(values) || hasActiveMoratorium || buttonIsLocked;

  return (
    <Root area={area}>
      <ButtonStyled
        shape="round"
        type="primary"
        size="large"
        ghost
        disabled={isDisabled}
        loading={isLoading}
        onClick={fetchList}
      >
        Поиск клиента
      </ButtonStyled>
    </Root>
  );
}

export default connect(
  (state) => ({
    values: state.request.values,
    fetchId: state.request.fetch.id,
    isLoading: state.crif.isLoading,
    hasActiveMoratorium: state.crif.hasActiveMoratorium,
    buttonIsLocked: state.crif.buttonIsLocked,
  }),
  {
    fetchCrifList,
  },
)(MoratoriumButton);
