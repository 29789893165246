import React from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT } from 'settings';
import moment from 'moment';

import { Root } from './style';

function Moratorium({
  // connect
  hasMoratorium,
  activeMoratorium,
}) {
  const { moratorium, moratoriumDateEnd } = activeMoratorium;
  const date = moment(moratoriumDateEnd).isValid() && moment(moratoriumDateEnd).format(DATE_FORMAT);

  return hasMoratorium && moratorium ? (
    <Root>
      Из-за недавнего отказа для заявителя действует мораторий на подачу новых заявок до
      {' '}
      <b>{date || '_# Дата Моратория не указана #_'}</b>
      {' '}
      включительно.
    </Root>
  ) : null;
}

export default connect(
  (state) => ({
    id: state.crif.id,
    hasMoratorium: state.crif.hasMoratorium,
    activeMoratorium: state.crif.moratorium,
    isLoading: state.crif.isLoading,
    isLoaded: state.crif.isLoaded,
    error: state.crif.error,
  }),
  null,
)(Moratorium);
