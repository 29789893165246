import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Table } from 'antd';
import moment from 'moment';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  contractCreate as contractCreateAction,
  contractCreateReset as contractCreateResetAction,

  fetchList as fetchListAction,
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
} from 'redux/contracts/actions';

import {
  fetchList as fetchPointOfSalesListActions,
} from 'redux/dealerCenter/actions';

import {
  fetchList as fetchCompanyListAction,
} from 'redux/company/actions';

import { checkPrivilege } from 'helpers/roles';
import { useForm } from 'hooks';
import { initialFilters } from 'redux/contracts/reducer';

import Button from 'components/Button';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';

import {
  STATUS_OPTIONS,
  STATUS_VALUES,
  formatToApi,
  formatToTable,
} from './data';

import AddFile from '../AddFile';

import {
  Header,
  HeaderTitle,
  Root,
  Title,
  Count,
  ItemsContainer,
  ItemsContent,
  ItemsContentContainer,
  Company,
  Inn,
  Date,
  Requisites,
  EmptyMessage,
  ErrorMessage,
  LoadingMessage,
} from './style';

const scroll = { y: 'calc(100vh - 460px)' };
const showTotal = () => '';

const renderItems = (array) => (
  <ItemsContainer>
    {Array.isArray(array) && array.length === 1 && array[0]}
    {Array.isArray(array) && array.length > 1 && (
      <ItemsContentContainer content={(
        <ItemsContent>
          {array.map((item, index) => index !== 0 && (<div>{item}</div>))}
        </ItemsContent>
      )}
      >
        {array[0]}
        <Count>
          {' '}
          {array.length > 9 ? '9+' : array.length - 1 }
          {' '}
        </Count>
      </ItemsContentContainer>
    )}
  </ItemsContainer>
);

function List({
  // passed
  role,

  // store
  item,

  createIsLoaded,
  createIsLoading,
  createError,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  pagination,

  pointOfSalesList,
  pointOfSalesIsLoaded,
  pointOfSalesIsLoading,

  companyList,
  companyListIsLoaded,
  companyListIsLoading,

 // actions
  setTablePage,
  setBreadcrumbs,
  contractCreate,
  contractCreateReset,
  setSorting,
  setFilters,
  resetTable,

  fetchCompanyList,

  fetchPointOfSalesList,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pointOfSalesOptions, setPointOfSalesOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [page, setPage] = useState(1);
  const { form, updateForm } = useForm(initialFilters);
  const history = useHistory();

  const permissionAddNew = checkPrivilege([
    role.ACQUIRING_AGREEMENT.CREATE,
  ]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };
  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  const COLUMNS = [
    {
      key: 'legalEntityNameList',
      title: () =>
        TableHeader({
          title: 'Организация',
          // sortBy: 'legalEntityIds',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterName: 'legalEntityIds',
          filterValue: form.legalEntityIds,
          filterOnChange: updateForm,
          filterOptions: companiesOptions,
          filterIsLoading: companyListIsLoading,
          hasDefault: false,
        }),

      render: ({ id, legalEntityName, inn }) => (
        <Link to={`/app/payments/contracts/${id}`}>
          <Company>{legalEntityName}</Company>
          <Inn>
            ИНН
            {' '}
            {inn}
          </Inn>
        </Link>
      ),
      width: 300,
    },
    {
      key: 'subsidiariesIds',
      title: () =>
        TableHeader({
          title: 'Точка продаж',
          // sortBy: 'subsidiariesIds',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterName: 'subsidiariesIds',
          filterValue: form.subsidiariesIds,
          filterOnChange: updateForm,
          filterOptions: pointOfSalesOptions,
          hasDefault: false,
          filterIsLoading: pointOfSalesIsLoading,
          disabled: pointOfSalesIsLoading,
        }),
      render: ({ subsidiariesNameArray = [] }) => renderItems(subsidiariesNameArray),
      width: 180,
      align: 'left',
    },
    {
      key: 'sectors',
      title: () =>
        TableHeader({
          title: 'Сектор',
          // sortBy: 'sector',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'sectors',
          filterValue: form.sectors,
          filterOnChange: updateForm,
        }),
      render: ({ id, sector }) => (
        <Link to={`/app/payments/contracts/${id}`}>
          {sector}
        </Link>
      ),
      width: 200,
      align: 'left',
    },
    {
      key: 'requisites',
      title: () =>
        TableHeader({
          title: 'Реквизиты',
          // sortBy: 'searchStr',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'searchStr',
          filterValue: form.requisites,
          filterOnChange: updateForm,
        }),
      render: ({ id, contractNumber, contractDate }) => (
        <Link to={`/app/payments/contracts/${id}`}>
          <Requisites>{contractNumber || ''}</Requisites>
          <Date>
            {contractDate
              ? moment(contractDate).isValid()
                ? moment(contractDate).format('DD.MM.YYYY')
                : ''
              : ''}
          </Date>
        </Link>
      ),
      width: 260,
      align: 'left',
    },
    {
      key: 'status',
      title: () =>
        TableHeader({
          title: 'Статус',
          sortBy: 'status',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'status',
          filterValue: form.status,
          filterOnChange: updateForm,
          filterOptions: STATUS_OPTIONS,
        }),
      render: ({ contractId = 1, status = 'статус' }) => (
        <Link to={`/app/payments/contracts/${contractId}`}>
          { STATUS_VALUES[status] || '' }
        </Link>
      ),
      width: 140,
      align: 'left',
    },
  ];

  useEffect(() => {
    const { subsidiariesIds, sector, legalEntityIds } = form;

    setFilters({
      filters: {
        ...form,
        subsidiariesIds: formatToApi({ array: subsidiariesIds, options: pointOfSalesOptions }),
        legalEntityIds: formatToApi({ array: legalEntityIds, options: companiesOptions }),
        ...(sector ? { sector: [Number(sector)] } : {}),
      },
    });

    return () => resetTable();
        // eslint-disable-next-line
    }, [setFilters, resetTable, form]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    if (isLoaded) {
      fetchCompanyList({
        withPagination: false,
      });
    }
  }, [isLoaded, fetchCompanyList, role]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (createIsLoaded) {
      const { sector = null } = item;
      if (sector) {
        history.push(`/app/payments/contracts/${sector}/new`);
      }
    }
  }, [createIsLoaded, history, item]);

  useEffect(() => {
    if (createIsLoaded) {
      contractCreateReset();
    }
  }, [createIsLoaded, contractCreateReset]);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Эквайринг', link: '/app/payments' },
        { level: 1, name: 'Договоры', link: '/app/payments/contracts/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    fetchPointOfSalesList({
      withPagination: false,
    });
  }, [fetchPointOfSalesList]);

  useEffect(() => {
    if (pointOfSalesIsLoaded && (pointOfSalesList.length > 0)) {
      setPointOfSalesOptions(formatToTable(pointOfSalesList));
    }
  }, [pointOfSalesIsLoaded, pointOfSalesList]);

  useEffect(() => {
    if (companyListIsLoaded && (companyList.length > 0)) {
      setCompaniesOptions(formatToTable(companyList));
    }
  }, [companyListIsLoaded, companyList]);

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>Все договоры</Title>
          {permissionAddNew && (
            <Button
              size="mini"
              type="link"
              shape="square"
              onClick={() => openModal()}
            >
              Добавить
            </Button>
          )}
        </HeaderTitle>
      </Header>
      <Table
        rowKey={({ id }) => id}
        columns={COLUMNS}
        dataSource={list}
        loading={isLoading}
        bordered
        pagination={paginationObj}
        locale={locale}
        scroll={scroll}
      />
      <AddFile
        close={closeModal}
        visible={isModalOpen}
        handleAddFile={contractCreate}
        isLoaded={createIsLoaded}
        isLoading={createIsLoading}
        error={createError}
        resetAddFile={contractCreateReset}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    item: state.contracts.item.data,
    createIsLoaded: state.contracts.create.isLoaded,
    createIsLoading: state.contracts.create.isLoading,
    createError: state.contracts.create.error,

    list: state.contracts.collection.list,
    sorting: state.contracts.collection.sorting,
    filters: state.contracts.collection.filters,
    isLoaded: state.contracts.collection.isLoaded,
    isLoading: state.contracts.collection.isLoading,
    error: state.contracts.collection.error,
    pagination: state.contracts.collection.pagination,

    pointOfSalesList: state.dealerCenter.collection.list,
    pointOfSalesIsLoaded: state.dealerCenter.collection.isLoaded,
    pointOfSalesIsLoading: state.dealerCenter.collection.isLoading,

    companyList: state.company.collection.list,
    companyListIsLoaded: state.company.collection.isLoaded,
    companyListIsLoading: state.company.collection.isLoading,
  }),
  {
    setBreadcrumbs: setBreadcrumbsAction,
    contractCreate: contractCreateAction,
    contractCreateReset: contractCreateResetAction,

    fetchList: fetchListAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,

    fetchPointOfSalesList: fetchPointOfSalesListActions,

    fetchCompanyList: fetchCompanyListAction,
  },
)(List);
