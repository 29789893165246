import React from 'react';
import styled from 'styled-components';
import { GRID_AREAS } from 'settings';
import { size } from 'styled-theme';

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const TimeBlock = styled.div`
  height: ${size('inputHeight')};
  display: grid;
  grid-template-rows: 50% 50%;
`;

const TimeZone = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0 0.5rem;
`;

const TimeLocal = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0 0.5rem;
`;

const WithSuffix = styled(({ suffix, ...rest }) => <div {...rest} />)`
  ${({ area }) =>
    typeof area === 'boolean'
    && `
    grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
  `}
  position: relative;

  & .ant-select-selector {
    ${({ suffix }) => suffix && `
      padding-right: 40px;
    `}
  }
`;

const Suffix = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export {
  InputContainer,
  TimeBlock,
  TimeZone,
  TimeLocal,
  WithSuffix,
  Suffix,
};
