import React, { useEffect, useState } from 'react';
import { parseDigits, formatCurrency } from 'utils';

import { Root, InputStyled } from './style';

const format = (string) => {
  const valueFormatted = `${string} ₽`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return string === '' ? '' : parseDigits(string).length > 0 ? valueFormatted : '';
};

const parse = (e) => Number(parseDigits(e));

const getFormatValue = (formatType, value) => (formatType === 'currency' ? formatCurrency(value) : value);

function RangeFilter({
  name: [startName, endName],
  onChange: onChangeField,
  value: [startValue, endValue],
  formatType,
}) {
  const [min, setMin] = useState(startValue || 0);
  const [max, setMax] = useState(endValue || 0);

  useEffect(() => {
    setMin(startValue);
  }, [startValue, setMin]);

  useEffect(() => {
    setMax(endValue);
  }, [endValue, setMax]);

  function handleOnBlur() {
    onChangeField({
      [startName]: min,
      [endName]: max,
    });
  }

  return (
    <Root>
      <InputStyled
        name={startName}
        placeholder="От..."
        value={min}
        formatter={format}
        parser={parse}
        onChange={({ target: { value } }) => setMin(getFormatValue(formatType, value))}
        onBlur={handleOnBlur}
      />
      <InputStyled
        name={endName}
        placeholder="...До"
        value={max}
        formatter={format}
        parser={parse}
        onChange={({ target: { value } }) => setMax(getFormatValue(formatType, value))}
        onBlur={handleOnBlur}
      />
    </Root>
  );
}

export default RangeFilter;
