import API from 'services';

import {
  all,
  takeLatest,
  fork,
  put,
  call, select, take, cancel,
} from 'redux-saga/effects';

import { extractErrorAndCode } from 'hacks';

import {
  REPORT_FETCH_LIST,
  REPORT_FETCH_LIST__CANCEL_REQUEST,
  REPORT_FETCH_LIST__FAILURE, REPORT_FETCH_LIST__SET_FILTER,
  REPORT_FETCH_LIST__SET_LOADING,
  REPORT_FETCH_LIST__SET_PAGE,
  REPORT_FETCH_LIST__SET_PAGINATION,
  REPORT_FETCH_LIST__SET_SEARCH,
  REPORT_FETCH_LIST__SET_SORTING,
  REPORT_FETCH_LIST__SUCCESS,

  REPORT_EXPORT_FILE,
  REPORT_EXPORT_FILE__SUCCESS,
  REPORT_EXPORT_FILE__FAILURE,

  REPORTS_SYNC,
  REPORTS_SYNC__SUCCESS,
  REPORTS_SYNC__FAILURE,

  REPORT_MONTHLY_SUMMARY_OPERATIONS,
  REPORT_MONTHLY_SUMMARY_OPERATIONS__SUCCESS,
  REPORT_MONTHLY_SUMMARY_OPERATIONS__FAILURE,

  REPORT_PERIOD_OPERATIONS,
  REPORT_PERIOD_OPERATIONS__SUCCESS,
  REPORT_PERIOD_OPERATIONS__FAILURE,

  REPORT_RECONCILIATION,
  REPORT_RECONCILIATION__SUCCESS,
  REPORT_RECONCILIATION__FAILURE,

  REPORT_GET_FILE_URL,
  REPORT_GET_FILE_URL__SUCCESS,
  REPORT_GET_FILE_URL__FAILURE,
} from './actions';

import { ON_ERROR } from '../auth/actions';

const getState = (state) => state.report;
const toCoins = (value) => parseFloat(value) * 100;

function* _fetchList() {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);

  try {
    yield put({ type: REPORT_FETCH_LIST__SET_LOADING });
    const response = yield call(API.report.fetchList, {
      search,
      page,
      filters: {
        ...filters,
        ...(filters.legalEntityName ? { legalEntityName: filters.legalEntityName } : {}),
        ...(filters.subsidisryName ? { subsidisryName: filters.subsidisryName } : {}),
        amountToBeTransferredFrom: filters.amountToBeTransferredFrom ? toCoins(filters.amountToBeTransferredFrom) : '',
        amountToBeTransferredTo: filters.amountToBeTransferredTo ? toCoins(filters.amountToBeTransferredTo) : '',
      },
      sorting,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.filesReportList))
    ) {
      yield put({
        type: REPORT_FETCH_LIST__SUCCESS,
        list: response.data.filesReportList || [],
        pagination: response.pagination || {},
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: REPORT_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(REPORT_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(REPORT_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(REPORT_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: REPORT_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: REPORT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: REPORT_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(REPORT_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: REPORT_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: REPORT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: REPORT_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(REPORT_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: REPORT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: REPORT_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(REPORT_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: REPORT_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: REPORT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: REPORT_FETCH_LIST });
  });
}

export function* exportReport() {
  yield takeLatest(REPORT_EXPORT_FILE, function* ({ filter }) {
    try {
      const response = yield call(API.report.exportReportFile, {
        filter,
      });

      if (
        response
        && response.data
        && !response.error
        && !response.errorCode
        && !response.message
      ) {
        yield put({
          type: REPORT_EXPORT_FILE__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: REPORT_EXPORT_FILE__FAILURE, error: error.message });
    }
  });
}

export function* reportsSync() {
  yield takeLatest(REPORTS_SYNC, function* ({ sectors, date }) {
    try {
      const response = yield call(API.report.reportsSync, {
        sectors,
        date,
      });

      if (
        response
        && response.data
        && !response.error
        && !response.errorCode
        && !response.message
      ) {
        yield put({
          type: REPORTS_SYNC__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: REPORTS_SYNC__FAILURE, error: error.message });
    }
  });
}

export function* monthlySummaryOperations() {
  yield takeLatest(REPORT_MONTHLY_SUMMARY_OPERATIONS, function* ({ sectors, year, month }) {
    try {
      const response = yield call(API.report.monthlySummaryOperations, {
        sectors,
        year,
        month,
      });

      if (
        response
        && response.data
        && !response.error
        && !response.errorCode
        && !response.message
      ) {
        yield put({
          type: REPORT_MONTHLY_SUMMARY_OPERATIONS__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: REPORT_MONTHLY_SUMMARY_OPERATIONS__FAILURE,
        error: error.message,
      });
    }
  });
}

export function* periodOperations() {
  yield takeLatest(REPORT_PERIOD_OPERATIONS, function* ({
    sectors,
    dateTo,
    dateFrom,
  }) {
    try {
      const response = yield call(API.report.periodOperations, {
        sectors,
        dateTo,
        dateFrom,
      });

      if (
        response
        && response.data
        && !response.error
        && !response.errorCode
        && !response.message
      ) {
        yield put({
          type: REPORT_PERIOD_OPERATIONS__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: REPORT_PERIOD_OPERATIONS__FAILURE,
        error: error.message,
      });
    }
  });
}

export function* reportReconciliation() {
  yield takeLatest(REPORT_RECONCILIATION, function* ({
    sectors,
    dateRevise,
  }) {
    try {
      const response = yield call(API.report.reportReconciliation, {
        sectors,
        dateRevise,
      });

      if (
        response
        && response.data
        && !response.error
        && !response.errorCode
        && !response.message
      ) {
        yield put({
          type: REPORT_RECONCILIATION__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: REPORT_RECONCILIATION__FAILURE,
        error: error.message,
      });
    }
  });
}

export function* getFileUrl() {
  yield takeLatest(REPORT_GET_FILE_URL, function* ({
    id,
  }) {
    try {
      const response = yield call(API.report.getFileUrl, {
        id,
      });

      if (
        response
        && response.data
        && !response.error
        && !response.errorCode
        && !response.message
      ) {
        yield put({
          type: REPORT_GET_FILE_URL__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({
        type: REPORT_GET_FILE_URL__FAILURE,
        error: error.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(exportReport),
    fork(reportsSync),
    fork(monthlySummaryOperations),
    fork(periodOperations),
    fork(reportReconciliation),
    fork(getFileUrl),
  ]);
}
