import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import withTheme from 'components/tcm/withTheme';
import { Rifm } from 'rifm';
import { connectStringNumber, separateNumberByRank } from 'helpers/numbers';
import { EditableWrapper, ExtremeValue } from 'components/tcm/Slider/style';
import {
  Slider,
  BlockContainer,
  Suffix,
  BlockTitle,
  Value,
  SecondaryValue,
  ValueContainer,
  SecondaryValueContainer,
  SliderContainer,
} from './style';

const Input = withTheme(styled.input`
  font: inherit;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.button};
`);

export const SliderBlock = ({
  defaultValue,
  disabled,
  onChange,
  onAfterChange,
  value,
  title,
  suffix,
  min,
  max,
  valueText,
  format = (v) => v,
  secondaryText,
  secondaryValue,
  gridColumn,
  step = 1,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [rifmValue, setRifmValue] = useState('');

  const inputRef = useRef(null);

  const isSecondLine = !!(secondaryValue || secondaryText);

  const onValueClick = (e) => {
    if (isEditable && inputRef.current && e.target !== inputRef.current) {
      setIsEditable(false);
    }

    if (!isEditable && min && max) {
      setIsEditable(true);
    }
  };

  const onInputBlur = (e) => {
    setIsEditable(false);

    const { value } = e.target;
    const newValue = connectStringNumber(value);
    if (newValue >= min || newValue <= max) {
      onChange(newValue);
    } else if (newValue < min) {
      onChange(min);
    } else if (newValue > max) {
      onChange(max);
    }
  };

  const onInputKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const onRifmChange = (v) => {
    setRifmValue(v);
  };

  useEffect(() => {
    if (isEditable && value) {
      setRifmValue(value);
    }
  }, [isEditable, value]);

  return (
    <BlockContainer gridColumn={gridColumn}>
      <BlockTitle>{title}</BlockTitle>
      <ValueContainer isSecondLine={isSecondLine}>

        <Value
          tabIndex={-1}
          onClick={onValueClick}
        >
          {isEditable
            ? (

              <Rifm
                accept={/[\d]/g}
                format={format}
                value={rifmValue}
                onChange={onRifmChange}
              >
                {({ value, onChange }) => (
                  <Input
                    onBlur={onInputBlur}
                    onKeyDown={onInputKeyDown}
                    onChange={onChange}
                    value={value}
                    ref={inputRef}
                  />
                )}
              </Rifm>
            )
            : (
              <span>
                {format && value !== 0 ? format(value) : value}
              </span>
            )}
          {' '}
          {valueText}
        </Value>
        <Suffix>{suffix}</Suffix>
      </ValueContainer>
      {isSecondLine
        && (
          <SecondaryValueContainer>
            <SecondaryValue>
              {secondaryValue}
              {' '}
              {secondaryText}
            </SecondaryValue>
          </SecondaryValueContainer>
        )}

      <SliderContainer>
        <Slider
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChange}
          value={value}
          onAfterChange={onAfterChange}
          min={min}
          max={max}
          step={step}
        />
      </SliderContainer>
      {isEditable && (
        <EditableWrapper>
          <ExtremeValue>{separateNumberByRank(min)}</ExtremeValue>
          <ExtremeValue>{separateNumberByRank(max)}</ExtremeValue>
        </EditableWrapper>
      )}
    </BlockContainer>
  );
};
