import styled from 'styled-components';
import { palette, size } from 'styled-theme';

const WithStyle = (ComponentName) => styled(ComponentName)`
  &.ant-select {
    min-height: ${size('filterInputHeight')};
    font-size: 12px;
    width: 100%;
    max-width: 400px;
    outline: none;
  }
  .ant-select-selection--single {
    min-height: ${size('filterInputHeight')};
    border-radius: 3px;
    display: flex;
    align-items: center;
    background: ${palette('form', 0)};
    border-color: ${palette('form', 2)};
    color: ${palette('form', 5)};
    &:focus {
      background: ${palette('form', 1)};
      border-color: ${palette('form', 3)};
      box-shadow: 0 0 0 2px ${palette('form', 4)};
    }
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-open .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    background: ${palette('form', 1)};
    border-color: ${palette('form', 3)};
    box-shadow: 0 0 0 2px ${palette('form', 4)};
  }
  .ant-select-selection:hover {
    background: ${palette('form', 1)};
    border-color: ${palette('form', 3)};
  }
  .ant-select-selection-selected-value {
    color: ${palette('form', 5)};
  }
`;

export { WithStyle };
