import API from 'services';

import {
  extractErrorAndCode,
} from 'hacks';

import {
  all, takeLatest, put, fork, call,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  FILES_FETCH_LIST,
  FILES_FETCH_LIST__SUCCESS,
  FILES_FETCH_LIST__FAILURE,

  FILE_UPLOAD,
  FILE_UPLOAD__FAILURE,
  FILE_UPLOAD__SUCCESS,

  FILE_DELETE,
  FILE_DELETE__SUCCESS,
  FILE_DELETE__FAILURE,

  FILE_DOWNLOAD,
  FILE_DOWNLOAD__SUCCESS,
  FILE_DOWNLOAD__FAILURE,

} from './actions';

/* List */
export function* fetchList() {
  yield takeLatest(FILES_FETCH_LIST, function* ({ legalEntityId }) {
    try {
      const response = yield call(API.files.fetchList, { legalEntityId });
      if (
        response
          && response.data
          && (Array.isArray(response.data.documentList) || Array.isArray(response.data))
      ) {
        yield put({
          type: FILES_FETCH_LIST__SUCCESS,
          list: response.data.documentList || [],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FILES_FETCH_LIST__FAILURE, error: error.message });
    }
  });
}

export function* uploadFiles() {
  yield takeLatest(FILE_UPLOAD, function* ({
    legalEntityId,
    subsidiaryId,
    comment,
    fileName,
  }) {
    try {
      const response = yield call(API.files.uploadFile, {
        legalEntityId,
        subsidiaryId,
        comment,
        fileName,
      });
      if (
        response
          && response.data
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: FILE_UPLOAD__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FILE_UPLOAD__FAILURE, error: error.message });
    }
  });
}

export function* deleteFile() {
  yield takeLatest(FILE_DELETE, function* ({ id }) {
    try {
      const response = yield call(API.files.deleteFile, { id });
      if (
        response
          && response.data
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: FILE_DELETE__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FILE_DELETE__FAILURE, error: error.message });
    }
  });
}

export function* downloadFile() {
  yield takeLatest(FILE_DOWNLOAD, function* ({ id, fileName }) {
    try {
      const response = yield call(API.files.downloadFile, { id });
      if (
        response
          && response.data
          && !response.errorMessage
          && !response.errorCode
      ) {
        yield put({
          type: FILE_DOWNLOAD__SUCCESS,
          data: { ...response.data, fileName },
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FILE_DOWNLOAD__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(uploadFiles),
    fork(deleteFile),
    fork(downloadFile),
  ]);
}
