import moment from 'moment';

export const KEYS = {
  THEME: {
    key: 'THEME',
    apiKey: '',
    label: 'Тема',
    rules: [
      () => ({
        validator(rule, value) {
          if (typeof value === 'number') {
            return Promise.resolve();
          }
          return Promise.reject('Тема не может быть пустой');
        },
      }),
    ],
  },

  SUB_THEME: {
    key: 'SUB_THEME',
    label: 'Подтема',
    apiKey: 'subtopicId',
    rules: [
      () => ({
        validator(rule, value) {
          if (typeof value === 'number') {
            return Promise.resolve();
          }
          return Promise.reject('Подтема не может быть пустой');
        },
      }),
    ],
  },

  COUNT: {
    key: 'COUNT',
    apiKey: 'countCarsInStock',
    label: 'Количество доступных для реализации автомобилей',
    rules: [
      () => ({
        validator(rule, value) {
          if ((typeof value === 'number') && value > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Количество не может быть пустым или меньше 1');
        },
      }),
    ],
  },

  DATE_FROM: {
    key: 'DATE_FROM',
    apiKey: 'dialingFrom',
    label: 'Дата начала обзвона',
    rules: [
      () => ({
        validator(rule, value) {
          if (!value) {
            return Promise.reject('Дата начала не может быть пустой');
          }
          if (moment(value).isValid()) {
            return Promise.resolve();
          }
        },
      }),
    ],
  },

  DATE_TO: {
    key: 'DATE_TO',
    apiKey: 'dialingTo',
    label: 'Дата окончания обзвона',
    rules: [
      () => ({
        validator(rule, value) {
          if (!value) {
            return Promise.reject('Дата окончания не может быть пустой');
          }
          if (moment(value).isValid()) {
            return Promise.resolve();
          }
        },
      }),
    ],
  },

  COMMENT: {
    key: 'COMMENT',
    apiKey: 'comment',
    label: 'Комментарий',
    rules: [],
  },
};
