import React from 'react';

export const VisitIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="#4CAF50" />
    <circle cx="12" cy="13" r="2.5" stroke="#4CAF50" />
    <path d="M14 17V17C15.1046 17 16 17.8954 16 19V23H8V19C8 17.8954 8.89543 17 10 17V17" stroke="#4CAF50" strokeLinecap="round" />
    <path d="M18.5 23L18.5 13.5L18.5 6.5L23.5 9L18.5 12" stroke="#4CAF50" strokeLinecap="round" />
  </svg>
);
