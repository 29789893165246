const library = {
  комментарий: ['комментарий', 'комментария', 'комментариeв'],
  звонок: ['звонок', 'звонка', 'звонков'],
  визит: ['визит', 'визита', 'визитов'],
  клиент: ['клиента', 'клиента', 'клиентов'], // одного клиента, 3 клиента, 5 клиентов
};

export function getDeclension(number, word) {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;
  const wordForms = library[word];
  if (!wordForms) {
    console.error(`Добавьте слово "${word}" в библиотеку`);
    return word;
  }
  if (n > 10 && n < 20) { return wordForms[2]; }
  if (n1 > 1 && n1 < 5) { return wordForms[1]; }
  if (n1 === 1) { return wordForms[0]; }
  return wordForms[2];
}
