import styled from 'styled-components';
import { Radio } from 'antd';
import { TCM } from 'components/tcm';
import withTheme from 'components/tcm/withTheme';

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  width: 340px;
`;

export const RadioFieldset = withTheme(styled.div`
  padding-bottom: 36px;
`);

export const Container = styled.div`
  width: 450px;
`;

export const Block = withTheme(styled.div`
  padding-top: 31px;
  border-top: 1px solid ${({ theme }) => theme.palette.gray4};
`);

const FieldLabel = withTheme(styled.p`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

export const ResultsLabel = styled(FieldLabel)`
  margin-top: 31px;
  margin-bottom: 32px;
`;

export const RadioGroup = styled(Radio.Group)`
  display: grid;
  row-gap: 30px;
`;

export const FormBtns = withTheme(styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
`);

export const FormDate = withTheme(styled(TCM.DatePicker)`
  
  .ant-form-item {
    margin-bottom: 0;
  }
`);

export const FormStaticDate = withTheme(styled(TCM.StaticDatePicker)`
  
  .ant-form-item {
    margin-bottom: 0;
  }
`);

export const RecallDateLabel = withTheme(styled.p`
  margin-bottom: 7px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.black};
`);

export const RecallDate = styled.div`
  display: grid;
  grid-template-columns: 160px 130px;
  column-gap: 20px;
`;

export const SecondaryField = styled.div`
  margin-left: 30px;
  margin-top: 24px;
  margin-bottom: 4px;
`;
