import API from 'services';
import { formatLogs, extractErrorAndCode } from 'hacks';

import {
  all, fork, put, takeLatest, call,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import { HISTORY__FETCH, HISTORY__FETCH_SUCCESS, HISTORY__FETCH_FAILURE } from './actions';

export function* fetch() {
  yield takeLatest(HISTORY__FETCH, function* (action) {
    try {
      const response = yield call(API.history.fetch, { accessToken: action.accessToken });

      if (response && response.data) {
        yield put({ type: HISTORY__FETCH_SUCCESS, list: formatLogs(response.data) });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: HISTORY__FETCH_FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetch)]);
}
