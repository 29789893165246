import React from 'react';

import { CHECK, SHOW_FILE_TYPE } from 'helpers/feature';

import { Title, Scrollable } from './style';

const IS_DEBUG = CHECK(SHOW_FILE_TYPE);

function Label({
  label, type, name, area, forwardedRef, onClick,
}) {
  return label ? (
    <Title area={area} ref={forwardedRef} onClick={onClick}>
      {label}
      {IS_DEBUG && (
        <sup title={name}>
          [
          {type}
          -
          {name}
          ]
        </sup>
      )}
    </Title>
  ) : (
    <Scrollable area={area} ref={forwardedRef} />
  );
}

export default React.forwardRef((props, ref) => <Label {...props} forwardedRef={ref} />);
