import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form } from 'antd';

export const Root = styled.div`
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const HiddenContainer = styled.header`
  position: absolute;
  z-index: -999;
  opacity: 0;
`;

export const Title = styled.h3`
  font-family: "Toyota Type",sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 45px;
  /* identical to box height, or 102% */

  display: flex;
  align-items: center;
  letter-spacing: -1.3px;

  /* Black */

  color: #000000;
`;
export const GoBackButton = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #E50000;
  &:hover{
    color: #E50000;
  }
`;

export const BackButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 12px max-content;
  column-gap: 12px;
  align-items: center;
  width: max-content;
  background-color: transparent;
  margin-bottom: 44px;
`;

export const Main = styled(Form)`
  padding-top: 2rem;

  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & .ant-checkbox-wrapper {
    color: rgba(0, 0, 0, 0.85);
  }

  & .ant-form-item {
    margin-bottom: 7px;
  }
  
  .ant-input-group-wrapper{
   padding-left: 0;
   padding-right: 0;
  }
  
  .ant-input-wrapper.ant-input-group{
    height: 50px;
    input {
      height: 50px;
    }
  }
`;

export const Rows = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(320px, 400px));
  grid-column-gap: 20px;
  
`;

export const Footer = styled.footer`
  margin: 1rem 0 0;
  max-width: 240px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SuccessMessage = styled.div`
  color: #8c8c8c;
  margin: 0 0 10px;
`;

export const UserType = styled.div`
  color: #000;
  margin: 0 0 10px;
`;

export const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserDropDown = styled.div`
  background: #e71313;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 40px;
  width: 48px;
  border-radius: 5px;
  margin-right: 10px;

  .ant-icon {
    font-size: 12px;
  }
`;
