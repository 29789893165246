import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Form } from 'antd';

import Select from 'components/Select';
import Button from 'components/Button';

import {
  approveRole as approveRoleAction,
  approveRoleReset as approveRoleResetAction,
} from 'redux/user/actions';

import {
  setSearch as setSearchAction,
} from 'redux/company/actions';

import { BreadCrumpbs } from 'components/Breadcrumb';
import { KEYS } from './data';

import {
  Root, Header, Title, FormStyled, Footer, ErrorMessage,
} from './style';

const getAvailableRoles = ({ list = [], isUserInternal }) =>
  list
    .filter(({ forExternal }) => forExternal === !isUserInternal)
    .map((elem) => ({ id: elem.id, name: elem.name }));

const ModalHeader = ({ location }) => {
  const { companyId } = useParams();
  const breadCrumbRoleList = [
    { level: 0, name: 'Организация', link: `/app/management/companies/${companyId}/users` },
    { level: 1, name: 'Пользователи', link: `/app/management/companies/${companyId}/users` },
    { level: 2, name: 'роль', link: location.pathname },
  ];
  return (
    <Header>
      <Title>Добавить новую роль</Title>
      <BreadCrumpbs list={breadCrumbRoleList} inModal />
    </Header>
  );
};

const { COMPANY, DEALER_CENTER, ROLE } = KEYS;

function AddRole({
  // passed
  close,

  companyList,
  companyListIsLoading,
  companyListIsLoaded,
  companyListError,
  resetCompanyList,
  fetchCompanyList,

  roleList,
  roleListIsLoading,
  roleListIsLoaded,
  roleListError,

  userId,
  visible,
  isUserInternal,

  // connect
  userRole,
  isLoading,
  isLoaded,
  error,
  errors,

  // actions
  approveRole,
  approveRoleReset,
  setSearch,
}) {
  const [form] = Form.useForm();
  const { companyId } = useParams();
  const [isMounted, setIsMounted] = useState(false);
  const [dealerCentersByCompany, setDealerCentersByCompany] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [availableRolesList, setAvailableRolesList] = useState(
    getAvailableRoles({ list: roleList, role: userRole, isUserInternal }),
  );
  const [isValidated, setIsValidated] = useState(true);
  const location = useLocation();
  const [isRoleTCM, setIsRoleTCM] = useState(false);

  const handleCompanyChange = useCallback((companyId) => {
    form.setFieldsValue({
      [DEALER_CENTER.key]: '',
    });

    const selectedCompany = companyList.find((e) => e.id === companyId);

    if (selectedCompany) {
      if (Array.isArray(selectedCompany.subsidiariesList)) {
        if (isRoleTCM) {
          setDealerCentersByCompany(
            selectedCompany.subsidiariesList.filter((item) => item.krifOrganizationName),
          );
        } else {
          setDealerCentersByCompany(selectedCompany.subsidiariesList);
        }
      }
    }
  }, [form, companyList, isRoleTCM]);

  function handleSearchCompany(value) {
    setSearch({ search: value });

    form.setFieldsValue({
      [DEALER_CENTER.key]: '',
    });
    setIsSearched(true);
  }

  function handleSearchDealerCenter(value) {
    const companyId = form.getFieldValue(COMPANY.key);
    const selectedCompany = companyList.find((e) => e.id === companyId);
    const { subsidiariesList } = selectedCompany;

    if (Array.isArray(subsidiariesList) && subsidiariesList.length > 0) {
      setDealerCentersByCompany(
        subsidiariesList
          .map((item) => ({ ...item, search: item.name ? item.name.toLowerCase() : '' }))
          .filter((item) => item.search.includes(value.toLowerCase())),
      );
    } else {
      setDealerCentersByCompany([]);
    }
  }

  function handleValuesChange(value) {
    const { ROLE, COMPANY } = value;

    if (ROLE) {
      const isRoleTCM = availableRolesList.find((item) => (item.id === ROLE)
          && ((item.name === 'ТСМ Менеджер ДЦ') || (item.name === 'ТСМ Сотрудник ДЦ')));

      if (isRoleTCM && isRoleTCM.id) {
        setIsRoleTCM(true);
      } else {
        setIsRoleTCM(false);
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, 'COMPANY')
      && !COMPANY
      && isSearched
    ) {
      setSearch({ search: '' });
      setIsSearched(false);
    }
  }

  function handleBlur(_e, name) {
    form.validateFields([name]);
  }

  function handleFocus(_e, name) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleFinish(values) {
    const { [COMPANY.key]: company, [DEALER_CENTER.key]: point, [ROLE.key]: role } = values;

    approveRole({
      userId,
      [COMPANY.apiKey]: company,
      [DEALER_CENTER.apiKey]: point,
      [ROLE.apiKey]: role,
      isInternal: isUserInternal,
    });
    setIsValidated(false);
  }

  function reset() {
    setIsValidated(true);
    form.resetFields();
  }

  function cancel() {
    if (isLoaded) {
      reset();
    }
    close();
  }

  useEffect(() => {
    if (visible) {
      fetchCompanyList();
    }
  }, [
    fetchCompanyList,
    visible,
  ]);

  useEffect(() => {
    // we do not have access to `form` hook till the <form> component is rendered
    if (visible) {
      setIsMounted(true);
      setIsRoleTCM(false);
      approveRoleReset();
      form.setFields([
        { name: ROLE.key, value: '', errors: [] },
        { name: DEALER_CENTER.key, value: '', errors: [] },
        ...[(Array.isArray(companyList) && companyList.length > 1 ? { name: COMPANY.key, value: '', errors: [] } : {})],
      ]);
    } else {
      form.resetFields();
      setIsSearched(false);
      resetCompanyList();
    }
  }, [
    visible,
    setIsMounted,
    approveRoleReset,
    form,
    companyList,
    resetCompanyList,
  ]);
  /* start: write fetch errors
  if we failed to fetch one of the lists - get an error message and put it under the input
 */
  useEffect(() => {
    if (isMounted && companyListError) {
      form.setFields([{ name: COMPANY.key, errors: [companyListError] }]);
    }
  }, [isMounted, companyListError, form]);

  useEffect(() => {
    if (isMounted && roleListError) {
      form.setFields([{ name: ROLE.key, errors: [roleListError] }]);
    }
  }, [isMounted, roleListError, form]);
  /* end: write fetch errors */

  useEffect(() => {
    if (isMounted && isLoaded) {
      close();
      setIsValidated(true);
      form.resetFields();
      approveRoleReset();
    }
  }, [isMounted, isLoaded, form, close, approveRoleReset]);

  useEffect(() => {
    if (!isValidated && !isLoading && Array.isArray(errors) && errors.length > 0) {
      const errorsFlat = errors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));
      const {
        [COMPANY.apiKey]: company,
        [DEALER_CENTER.apiKey]: point,
        [ROLE.apiKey]: role,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(company ? [{ name: COMPANY.key, errors: [company] }] : []),
        ...(point ? [{ name: DEALER_CENTER.key, errors: [point] }] : []),
        ...(role ? [{ name: ROLE.key, errors: [role] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [errors, isLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if (roleListIsLoaded) {
      setAvailableRolesList(getAvailableRoles({ list: roleList, isUserInternal }));
    }
  }, [roleList, roleListIsLoaded, isUserInternal]);

  useEffect(() => {
    if (companyListIsLoaded && companyId) {
      form.setFields([
        ...([{ name: COMPANY.key, value: Number(companyId) }]),
      ]);
      handleCompanyChange(Number(companyId));
    }
  }, [companyListIsLoaded, companyId, form, handleCompanyChange]);

  useEffect(() => {
    const companyId = form.getFieldValue(COMPANY.key);
    if (companyId) {
      handleCompanyChange(companyId, isRoleTCM);
    }
  }, [form, isRoleTCM, handleCompanyChange]);

  return (
    <Root
      visible={visible}
      title={<ModalHeader location={location} />}
      onCancel={cancel}
      footer={null}
    >
      <FormStyled
        name="AddRole"
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name={COMPANY.key}
          label={COMPANY.title}
          rules={COMPANY.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Select
            name={COMPANY.key}
            showSearch
            onSearch={handleSearchCompany}
            onFocus={(e) => handleFocus(e, COMPANY.key)}
            onBlur={(e) => handleBlur(e, COMPANY.key)}
            onChange={handleCompanyChange}
            loading={companyListIsLoading}
            disabled
            filterOption={false}
            list={companyList}
            hasDefault={false}
          />
        </Form.Item>

        <Form.Item
          name={DEALER_CENTER.key}
          label={DEALER_CENTER.title}
          // так как нет нормальной возможности завести проверку на внешнего пользователя в data.jsx
          // для внешнего пользователя необязательное поле
          rules={(isRoleTCM || isUserInternal) ? DEALER_CENTER.rules : []}
          validateFirst
          validateTrigger="onBlur"
        >
          <Select
            showSearch
            onSearch={handleSearchDealerCenter}
            name={DEALER_CENTER.key}
            onFocus={(e) => handleFocus(e, DEALER_CENTER.key)}
            onBlur={(e) => handleBlur(e, DEALER_CENTER.key)}
            loading={companyListIsLoading}
            disabled={companyListIsLoading}
            filterOption={false}
            list={dealerCentersByCompany}
          />
        </Form.Item>
        <Form.Item
          name={ROLE.key}
          label={ROLE.title}
          rules={ROLE.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Select
            name={ROLE.key}
            onFocus={(e) => handleFocus(e, ROLE.key)}
            onBlur={(e) => handleBlur(e, ROLE.key)}
            loading={roleListIsLoading}
            disabled={roleListIsLoading}
            list={availableRolesList}
            hasDefault={false}
          />
        </Form.Item>

        {error && !isLoading && <ErrorMessage>{error}</ErrorMessage>}

        <Footer>
          <Button
            type="ghost"
            shape="square"
            size="small"
            onClick={cancel}
            htmlType="button"
          >
            Отменить
          </Button>
          <Button
            type="primary"
            shape="square"
            size="small"
            htmlType="submit"
            loading={isLoading}
          >
            Сохранить
          </Button>
        </Footer>
      </FormStyled>
    </Root>
  );
}

export default connect(
  (state) => ({
    userRole: state.auth.role,
    isLoading: state.user.approveRole.isLoading,
    isLoaded: state.user.approveRole.isLoaded,
    error: state.user.approveRole.error,
    errors: state.user.approveRole.errors,
  }),
  {
    approveRole: approveRoleAction,
    approveRoleReset: approveRoleResetAction,
    setSearch: setSearchAction,
  },
)(AddRole);
