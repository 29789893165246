export const CREDIT__FETCH = 'CREDIT__FETCH';
export const CREDIT__FETCH_SUCCESS = 'CREDIT__FETCH_SUCCESS';
export const CREDIT__FETCH_FAILURE = 'CREDIT__FETCH_FAILURE';
export const fetch = ({ accessToken }) => ({ type: CREDIT__FETCH, accessToken });

export const CREDIT__PATCH = 'CREDIT__PATCH';
export const CREDIT__PATCH_SUCCESS = 'CREDIT__PATCH_SUCCESS';
export const CREDIT__PATCH_FAILURE = 'CREDIT__PATCH_FAILURE';
export const patch = ({ userInput, exclude }) => ({
  type: CREDIT__PATCH,
  userInput,
  exclude,
});
