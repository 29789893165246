import {
  ROLE_FETCH_LIST,
  ROLE_FETCH_LIST__SUCCESS,
  ROLE_FETCH_LIST__FAILURE,
} from './actions';

const initState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export default function roleReducer(state = initState, action) {
  switch (action.type) {
    case ROLE_FETCH_LIST: {
      return {
        ...initState,

        isLoading: true,
      };
    }
    case ROLE_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        list: action.list,
        isLoading: false,
        isLoaded: true,
      };
    }
    case ROLE_FETCH_LIST__FAILURE: {
      return {
        ...state,

        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
}
