import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Rifm } from 'rifm';
import { Link, useHistory } from 'react-router-dom';
import { useRowSelect, useTable } from 'react-table';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { TCM } from 'components/tcm';
import { ArrowSortingIcon, RublerIcon } from 'icons';
import { SaveFormModalOk } from 'components/tcm/Modals';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import { Form, Radio, Popover } from 'antd';
import {
  parseDigitsWithSeparator as parseDigits,
} from 'utils';
import {
  fetchEmployeesList as fetchEmployeesListAction,
} from 'redux/tcmEmployee/actions';
import {
  setCampaignStatus as setCampaignStatusAction,
  resetCampaign as resetCampaignAction,
  fetch as fetchCampaignAction,
} from 'redux/tcmCampaign/actions';
import {
  setEmployee as setEmployeeAction,
  setEmployeeReset as setEmployeeResetAction,
} from 'redux/tcmOffer/actions';
import {
  fetchList as fetchListAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  reset as resetAction,
  resetFilters as resetFiltersAction,
} from 'redux/tcmCampaignOffer/actions';

import { checkPrivilege } from 'helpers/roles';

import { FilterTag } from 'components/tcm/MultipleSelect/style';
import { FilterIcon } from 'icons/FilterIcon';
import { OfferStatus } from 'tcm/offer/data';
import moment from 'moment';
import { ErrorMessage } from 'components/tcm/ErrorMessage';
import RejectCampaign from '../RejectCampaign';
import Header from './Header';
import Filters from './Filters';

import {
  Table,
  BtnWrapper,
  EmployeesList,
  EmployeesTitle,
  EmployeesWrapper,
  Wrapper,
  FilterBtnContainer,
  TableContainer,
} from './style';

import { Suffix, TableSortingTitle } from '../style';
import {
  FilterBtn,
  FilterTags,
  FilterWrapper,
  SortingIconWrapper,
  SortingTableHeader,
  NoResults,
} from '../MainTable/style';

const formatInteger = (string) => {
  const parsed = parseDigits(string);
  const number = Number.parseInt(parsed, 10);

  if (Number.isNaN(number)) {
    return '';
  }
  return number.toLocaleString('ru');
};

const Distribution = ({
  // passed
  campaignId,
  setIsDataChanged,
  setDraftSaveHandler,

  // store
  role,
  listId,
  list,
  sorting,
  filterTags,
  appliedFilters,
  isLoading,
  isLoaded,
  error,
  employeesList,
  // employeesIsLoading,
  employeesIsLoaded,
  // employeesError,

  search,

  employeesOfferIsLoaded,
  employeesOfferIsLoading,

  // actions
  fetchList,
  fetchEmployeesList,
  setEmployeeList,
  setCampaignStatus,
  setSorting,
  setFilters,
  reset,
  resetCampaign,
  fetchCampaign,
  setEmployeeReset,
  // resetFilters,
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [showAllAssignedModal, setShowAllAssignedModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showRejectCampaignButton, setShowRejectCampaignButton] = useState(true);
  const history = useHistory();
  const canViewManagerClient = checkPrivilege([role.TCM.VIEW_MANAGER_OFFERS_COUNT]);

  const filteredList = list.filter((item) => item.evaluation && item.car && item.client);

  const noClientsError = !filterTags.length && !filteredList.length;
  const dataError = filteredList.length !== list.length;

  const columns = useMemo(() => [
    {
      Header: 'Марка',
      accessor: 'brand',
      sortKey: 'car.brand',
    },
    {
      Header: 'Модель',
      accessor: 'model',
      sortKey: 'car.model',
    },
    {
      Header: 'Суфф.',
      accessor: 'suffix',
      sortKey: 'car.suffixTMS',
    },
    {
      Header: 'Сотрудник',
      accessor: 'employee',
      sortKey: 'employee.name',
    },
    {
      Header: 'Цена Trade-In',
      accessor: 'tradeInPrice',
      sortKey: 'evaluation.tradeInPrice',
    },
    {
      Header: 'Цена нового автомобиля',
      accessor: 'newPrice',
      sortKey: 'evaluation.newCarPrice',
    },
    {
      Header: 'Доп. сервисы',
      accessor: 'additionalServices',
      sortKey: 'evaluation.servicesPrice',
    },
    {
      Header: !filterTags.length && (
        <FilterBtnContainer>
          <TCM.ButtonFilter
            onClick={(e) => {
              e.stopPropagation();
              setShowFilter(!showFilter);
            }}
          />
        </FilterBtnContainer>
      ),
      accessor: 'action',
      minWidth: 100,
      width: 100,
    },
  ], [filterTags.length, showFilter]);

  const data = useMemo(() => list
    .filter((item) => item.evaluation && item.car && item.client)
    .map((offer) => ({
      offerStatus: offer.status,
      externalId: offer.externalId,
      brand: offer.car.brand,
      model: offer.car.model,
      suffix: Array.isArray(offer.car.suffixTMSList)
        && offer.car.suffixTMSList.length > 0
        && offer.car.suffixTMSList[0]
        ? offer.car.suffixTMSList[0]?.name : '',

      employee: offer.employee?.name || '-',
      tradeInPrice: (
        <Rifm
          value={(offer.evaluation.tradeInPrice || '')}
          format={formatInteger}
        >
          {({ value }) => (
            <>
              {value}
              <Suffix>
                {' '}
                {value.length ? <RublerIcon /> : null}
              </Suffix>
            </>
          )}
        </Rifm>
      ),
      newPrice: (
        <Rifm
          value={(offer.evaluation.newCarPrice || '')}
          format={formatInteger}
        >
          {({ value }) => (
            <>
              {value}
              <Suffix>
                {' '}
                {value.length ? <RublerIcon /> : null}
              </Suffix>
            </>
          )}
        </Rifm>
      ),
      additionalServices: (
        <Rifm
          value={(offer.evaluation.servicesPrice || '')}
          format={formatInteger}
        >
          {({ value }) => (
            <>
              {value}
              <Suffix>
                {' '}
                {value.length ? <RublerIcon /> : null}
              </Suffix>
            </>
          )}
        </Rifm>
      ),
      action: (
        offer.client.externalId && canViewManagerClient
          ? <TCM.ButtonText as={Link} to={`/tcm/campaign/offers/${offer.externalId}`}>Просмотр клиента</TCM.ButtonText>
          : ''
      ),
    })), [list, canViewManagerClient]);

  const selectHook = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <Form.Item noStyle>
              <TCM.Checkbox {...getToggleAllRowsSelectedProps()} />
            </Form.Item>
          </div>
        ),
        Cell: ({ row }) => {
          const { offerStatus } = row.original;
          const hideCheckbox = offerStatus === OfferStatus.WaitConfirm;
          return (
            <div>
              <Form.Item noStyle>
                {!hideCheckbox
                  && <TCM.Checkbox {...row.getToggleRowSelectedProps()} />}
              </Form.Item>
            </div>
          );
        },
      },
      ...columns,
    ]);
  }, []);

  const tableInstance = useTable(
    { columns, data },
    useRowSelect,
    selectHook,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const saveDraft = useCallback(() => {
    const { employee } = form.getFieldsValue();

    const employees = selectedFlatRows.filter((item) => item.original.offerStatus !== OfferStatus.WaitConfirm)
      .map((offer) => ({
        externalId: offer.original.externalId,
        employeeId: employee,
      }));
    setEmployeeList({ employees });
  }, [form, selectedFlatRows, setEmployeeList]);

  const onFormSubmit = useCallback(() => {
    saveDraft();
    form.setFieldsValue({ offer: [] });
  }, [
    form,
    saveDraft,
  ]);

  const modalOkHandler = () => {
    history.push('/tcm');
  };

  const modalCancelHandler = () => {
    setShowAllAssignedModal(false);
    setIsStatusChanged(true);
  };

  const deleteTagHandler = (tag) => {
    if (['models', 'brands', 'suffixTMS', 'employees'].includes(tag.key)) {
      const currentTagIndex = appliedFilters[tag.key].findIndex((t) => t.label === tag.label);
      if (currentTagIndex !== -1) {
        const prevCurrentTypeTags = appliedFilters[tag.key].slice(0, currentTagIndex);
        const nextCurrentTypeTags = appliedFilters[tag.key].slice(currentTagIndex + 1);

        setFilters({
          filters: {
            ...appliedFilters,
            [tag.key]: [...prevCurrentTypeTags, ...nextCurrentTypeTags],
          },
        });
      }
    } else {
      setFilters({
        filters: {
          ...appliedFilters,
          [tag.key]: undefined,
        },
      });
    }
  };

  useEffect(() => () => setEmployeeReset(), [setEmployeeReset]);

  useEffect(() => {
    if (isLoaded && list.length > 0) {
      const filtered = list.filter((item) =>
        item.evaluation && item.car && item.client && item.employee && item.employee?.name);
      setShowRejectCampaignButton(filtered.length === 0);

      const isSyncCampaign = String(listId) === id;

      if (isSyncCampaign && (filterTags.length === 0) && (search.length === 0)) {
        const filteredList = list.filter((item) => item.evaluation && item.car && item.client);
        const hasEmployees = filteredList.filter((item) => item.employee && item.employee.id);

        if (filteredList.length > 0 && (filteredList.length === hasEmployees.length)) {
          setCampaignStatus({
            id,
            status: CAMPAIGN_STATUSES.CALLING,
            comment: '',
          });
          setShowAllAssignedModal(true);
        }
      }
    }
  }, [
    isLoaded,
    list,
    setCampaignStatus,
    id,
    listId,
    search,
    filterTags,
  ]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    if (employeesOfferIsLoaded) {
      fetchList();
    }
  }, [fetchList, employeesOfferIsLoaded]);

  useEffect(() => {
    fetchEmployeesList();
  }, [fetchEmployeesList]);

  useEffect(() => {
    setIsDataChanged(
      Boolean(form.getFieldsValue().offer?.length),
    );
  });

  useEffect(() => {
    setDraftSaveHandler(saveDraft);
  }, [saveDraft, setDraftSaveHandler]);

  useEffect(() => {
    if (employeesIsLoaded && employeesList[0]?.id) {
      form.setFieldsValue({ employee: employeesList[0].id });
    }
  }, [employeesIsLoaded, employeesList, form]);

  useEffect(() => () => {
    reset();
    if (isStatusChanged) {
      fetchCampaign(campaignId);
    } else {
      resetCampaign();
    }
  }, [
    campaignId,
    fetchCampaign,
    isStatusChanged,
    reset,
    resetCampaign,
  ]);

  return (
    <Wrapper>
      {!noClientsError && <Header />}
      {Boolean(filterTags.length) && (
        <FilterWrapper>
          <FilterTags>
            {filterTags.map((tag) => (
              <FilterTag
                key={tag.label}
                label={tag.label}
                onClose={() => deleteTagHandler(tag)}
              />
            ))}
          </FilterTags>
          <FilterBtn onClick={() => setShowFilter(!showFilter)}>
            <FilterIcon />
          </FilterBtn>
        </FilterWrapper>
      )}

      <TableContainer>
        {isLoading && <TCM.Loader text="Идет загрузка списка клиентов..." />}
        {Boolean(filteredList.length && !noClientsError)
        && (
          <>
            <Table hide={isLoading} {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        onClick={() =>
                          column.sortKey && setSorting({ sortBy: column.sortKey })}
                      >
                        <SortingTableHeader>
                          <TableSortingTitle isActive={sorting?.sortBy === column.sortKey}>
                            {column.render('Header')}
                          </TableSortingTitle>
                          <SortingIconWrapper>
                            {sorting?.sortBy === column.sortKey
                                && (
                                  <>
                                    {
                                      sorting.order === 'desc'
                                        ? <ArrowSortingIcon />
                                        : <ArrowSortingIcon up />
                                    }
                                  </>
                                )}
                          </SortingIconWrapper>
                        </SortingTableHeader>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Form form={form} onFinish={onFormSubmit} name="offerForm">

              <EmployeesWrapper>
                <EmployeesTitle>Выберите сотрудника, которому надо назначить выбранных клиентов:</EmployeesTitle>
                <Form.Item name="employee" noStyle>
                  <EmployeesList as={Radio.Group}>
                    {employeesList.map((employee) => (
                      <TCM.Radio
                        key={employee.id}
                        value={employee.id}
                      >
                        <Popover content={employee.displayName}>
                          {employee.displayName}
                        </Popover>
                      </TCM.Radio>
                    ))}
                  </EmployeesList>
                </Form.Item>
              </EmployeesWrapper>

              <BtnWrapper>
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <TCM.Button
                      htmlType="submit"
                      disabled={!selectedFlatRows.length || !form.getFieldsValue().employee}
                      loading={employeesOfferIsLoading}
                    >
                      Назначить клиентов сотруднику
                    </TCM.Button>
                  )}
                </Form.Item>
                {showRejectCampaignButton && (
                  <TCM.ButtonText
                    onClick={() => setShowRejectModal(true)}
                  >
                    Отозвать кампанию
                  </TCM.ButtonText>
                )}
              </BtnWrapper>
            </Form>
          </>
        )}
      </TableContainer>

      {Boolean(!filteredList.length && !noClientsError)
        && (
          <NoResults>
            <p>Клиентов с такими параметрами не найдено. </p>
            <TCM.ButtonOutline
              onClick={() => setShowFilter(true)}
            >
              Изменить параметры фильтра
            </TCM.ButtonOutline>
          </NoResults>
        )}

      {error && !isLoading && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}

      {noClientsError && !isLoading && (
        <ErrorMessage>
          Кампания не содержит клиентов.
        </ErrorMessage>
      )}

      {dataError && !isLoading && (
        <ErrorMessage>
          Клиенты с некорректными данными скрыты.
        </ErrorMessage>
      )}

      <SaveFormModalOk
        visible={showAllAssignedModal}
        btnTextCancel="Закрыть окно"
        title="Вы распределили всех клиентов!"
        description={`Теперь сотрудники начинают обзвон клиентов. 
         Ожидаемая дата окончания обзвона: ${moment().add(3, 'd').format('DD.MM.YYYY')}`}
        btnTextOk="К списку всех кампаний"
        onCancel={modalCancelHandler}
        onOk={modalOkHandler}
      />

      <Filters
        isVisible={showFilter}
        onClose={() => setShowFilter(false)}
      />

      <RejectCampaign
        close={() => setShowRejectModal(false)}
        visible={showRejectModal}
      />
    </Wrapper>
  );
};

const ConnectedWithRedux = connect(
  (state) => ({
    role: state.auth.role,

    // offers
    listId: state.tcmCampaignOffer.collection.id,
    list: state.tcmCampaignOffer.collection.list,
    sorting: state.tcmCampaignOffer.collection.sorting,
    isLoading: state.tcmCampaignOffer.collection.isLoading,
    isLoaded: state.tcmCampaignOffer.collection.isLoaded,
    error: state.tcmCampaignOffer.collection.error,
    filterTags: state.tcmCampaignOffer.collection.filters.tags,
    appliedFilters: state.tcmCampaignOffer.collection.filters.data,

    search: state.tcmCampaignOffer.collection.search,
    // employees
    employeesList: state.tcmEmployee.collection.list,
    employeesIsLoading: state.tcmEmployee.collection.isLoading,
    employeesIsLoaded: state.tcmEmployee.collection.isLoaded,
    employeesError: state.tcmEmployee.collection.error,

    employeesOfferIsLoaded: state.tcmOffer.employee.isLoaded,
    employeesOfferIsLoading: state.tcmOffer.employee.isLoading,
  }),
  {
    fetchList: fetchListAction,
    fetchEmployeesList: fetchEmployeesListAction,
    setEmployeeList: setEmployeeAction,
    setCampaignStatus: setCampaignStatusAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    reset: resetAction,
    resetFilters: resetFiltersAction,
    fetchCampaign: fetchCampaignAction,
    resetCampaign: resetCampaignAction,
    setEmployeeReset: setEmployeeResetAction,
  },
)(Distribution);

export { ConnectedWithRedux as Distribution };
