import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { useForm } from 'hooks';
import { initialFilters } from 'redux/user/reducer';
import { Link } from 'react-router-dom';
import { TCM } from 'components/tcm';

import {
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  reset as resetTableAction,
} from 'redux/user/actions';

import {
  STATUS_OPTIONS,
} from './data';

import ContentHeader from '../ContentHeader';

import {
  ErrorMessage,
  EmptyMessage,
  TableTitleStroke,
  TableTitleSecondaryStroke,
  Container,
  Count,
  ItemsContainer,
  ItemsContent,
  ItemsContentContainer,
} from './style';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 400px)' };

const getColumns = ({ columns = [], role = '' }) => {
  if (Array.isArray(columns) && role) {
    return columns.filter((e) => e.checkRights(role));
  }
  return [];
};

const renderItems = (array) => (
  <ItemsContainer>
    {Array.isArray(array) && array.length === 1 && array[0]}
    {Array.isArray(array) && array.length > 1 && (
      <ItemsContentContainer content={(
        <ItemsContent>
          {array.map((item, index) => index !== 0 && (<div>{item}</div>))}
        </ItemsContent>
      )}
      >
        {array[0]}
        <Count>
          {' '}
          {array.length > 9 ? '9+' : array.length - 1 }
          {' '}
        </Count>
      </ItemsContentContainer>
    )}
  </ItemsContainer>
);

function List({
  // connect
  role,
  tcmMangerInfo,

  list,
  isLoading,
  error,
  pagination,

  // actions
  setTablePage,
  setFilters,
  resetTable,
}) {
  const { form } = useForm(initialFilters);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    setFilters({
      filters: {
        ...form,
        subsidiaryIds: tcmMangerInfo.subsidiaryIds,
        legalEntityIds: tcmMangerInfo.legalEntityIds,
      },
    });

    return () => resetTable();
    // eslint-disable-next-line
  }, [form, resetTable, setFilters]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  const COLUMNS = [
    {
      key: 'displayName',
      checkRights: () => true,
      title: () => 'ФИО',
      render: ({ displayName, id }) => (
        <TableTitleStroke>
          {displayName || `Пользователь ${id}`}
        </TableTitleStroke>
      ),
      width: 200,
    },
    {
      key: 'role',
      checkRights: () => true,
      title: () => 'Роль',
      render: ({ roleNames }) => <TableTitleSecondaryStroke>{renderItems(roleNames)}</TableTitleSecondaryStroke>,
    },
    {
      key: 'phone',
      checkRights: () => true,
      title: () => 'Телефон',
      render: ({ phone }) => <TableTitleStroke>{phone}</TableTitleStroke>,
    },
    {
      key: 'email',
      checkRights: () => true,
      title: () => 'Email',
      render: ({ email }) => <TableTitleStroke>{email}</TableTitleStroke>,
    },
    {
      key: 'status',
      checkRights: () => true,
      title: () => 'Статус',
      render: ({ status }) => (
        <TableTitleStroke>
          {STATUS_OPTIONS.find((item) => item.value === status).label}
        </TableTitleStroke>
      ),
      width: 150,
    },
    {
      key: 'action',
      checkRights: () => true,
      title: () => '',
      render: ({ id }) => (
        <TCM.ButtonText as={Link} to={`/tcm/users/${id}`}>
          Просмотр сотрудника
        </TCM.ButtonText>
      ),
      width: 150,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <TCM.Loader text="Идет загрузка списка сотрудников..." />
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const tableLoading = {
    spinning: isLoading && list.length > 0,
    indicator: <TCM.Loader text="Идет загрузка списка сотрудников..." />,
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  const columns = getColumns({ columns: COLUMNS, role });

  return (
    <Container isLoading={isLoading}>
      <ContentHeader />
      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        loading={tableLoading}
        locale={locale}
        scroll={scroll}
      />
    </Container>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    tcmMangerInfo: state.auth.tcmMangerInfo,

    list: state.user.collection.list,
    sorting: state.user.collection.sorting,
    filters: state.user.collection.filters,
    search: state.user.collection.search,
    isLoaded: state.user.collection.isLoaded,
    isLoading: state.user.collection.isLoading,
    error: state.user.collection.error,
    pagination: state.user.collection.pagination,
  }),
  {
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    resetTable: resetTableAction,
  },
)(List);
