import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import LogoImage from './logo.png';

export const Root = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  overflow-y: auto;
  align-items: center;
  & > * {
    // css grid overflow workaround
    min-width: 0;
  }
`;

export const RootBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 3vw;
  margin: 2rem 0;

  @media (min-width: 500px) {
    padding: 0 10vw;
  }
`;

export const Nav = styled.nav`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  margin: 0 1rem;
  grid-gap: 2rem;

  @media (min-width: 500px) {
    margin: 0 10vw;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;
    justify-content: space-evenly;
  }

  & > * {
    // css grid overflow workaround
    min-width: 0;
  }
`;

export const Logo = styled.img.attrs({
  src: LogoImage,
})`
  height: 100%;
`;

export const LinkStyled = styled(NavLink).attrs()`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  font-family: Open Sans, serif;
  font-size: 2rem;
  color: #4e3434;
  border: 1px solid #333;
  border-radius: 5px;

  transition: background 0.2s ease-in, opacity 0.2s ease-in, color 0.2s ease-in;

  &:hover {
    background: #ed3434;
    opacity: 0.5;
    color: #fff;
  }
  @media (max-width: 1024px) {
    height: 200px;
  }
  @media (max-width: 500px) {
    height: 150px;
  }
`;

export const LinkName = styled.span`
  text-transform: capitalize;
  text-align: center;
`;
