import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useRowSelect, useTable } from 'react-table';
import { Form } from 'antd';
import { TableContainer } from 'containers/App/TCM/Disribution/style';
import { TCM } from 'components/tcm';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import {
  Brand,
  CallingForm,
  CallingTable,
  CallingTitle,
  CallingWrapper,
  CellWithIcon,
  Model,
  ResultCell,
  TableTitleSecondaryStroke,
  TableTitleStroke,
  TableVinBodyContainer,
  TableVinTitleContainer,
  Vin,
} from 'containers/App/TCM/Оffer/style';
import { getEventText } from 'tcm/offer/format';
import { connect } from 'react-redux';

import {
  reset as resetAction,

  setOffersStatus as setOffersStatusAction,
  resetOffersStatus as resetOffersStatusAction,
} from 'redux/tcmOffer/actions';

import {
  getIconFromOffer,
  getTextFromOfferStatus,
  OfferStatus,
} from 'tcm/offer/data';
import { SaveFormModalOk, CreateErrorModal } from 'components/tcm/Modals';
import { getDeclension } from 'utils/getDeclension';

const CallingStatusTable = ({
  // passed
  list,
  isLoading,
  isLoaded,
  fetchList,

  // store
  setOffersStatus,
  resetOffersStatus,
  statusSetLoaded,
  listId,

  statusSetConfirmed,
  statusSetRevision,
  resetCampaignOffers,
}) => {
  const listMap = useMemo(() => new Map(), []);
  const [sentOffers, setSentOffers] = useState(0);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const columns = useMemo(() => [
    {
      Header: (
        <TableVinTitleContainer>
          <Vin>
            <TableTitleSecondaryStroke>VIN</TableTitleSecondaryStroke>
          </Vin>
          <Brand>
            <TableTitleStroke>Марка</TableTitleStroke>
          </Brand>
          <Model>
            <TableTitleSecondaryStroke>Модель</TableTitleSecondaryStroke>
          </Model>
        </TableVinTitleContainer>
      ),
      accessor: 'vin', // accessor is the "key" in the data
    },
    {
      Header: 'Сотрудник',
      accessor: 'employee',
      sortKey: 'employee.name',
    },
    {
      Header: 'Статус',
      accessor: 'status',
      sortKey: 'status',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ], []);

  const data = useMemo(() => list
    .filter((item) => item.status && item.employee && item.car)
    .map((offer) => ({
      __id: offer.id,
      statusValue: offer.status,
      vin: (
        <TableVinBodyContainer>
          <Vin>{offer.car?.vin || ''}</Vin>
          <Brand>{offer.car?.brand || ''}</Brand>
          <Model>{offer.car?.model || ''}</Model>
        </TableVinBodyContainer>
      ),
      brand: offer.car.brand,
      model: offer.car.model,
      employee: (
        <CellWithIcon>
          <div>{offer.employee.name}</div>
          {getIconFromOffer(offer)}
        </CellWithIcon>
      ),
      status: (
        <ResultCell>
          {Boolean(offer.status) && (
            <p>{getTextFromOfferStatus(offer.status)}</p>
          )}

          {Boolean(offer.lastEvent) && (
            <p>
              {getEventText(offer.lastEvent)}
            </p>
          )}
        </ResultCell>
      ),
      action: (
        <TCM.ButtonText as={Link} to={`/tcm/campaign/offers/${offer.externalId}`}>
          Просмотр клиента
        </TCM.ButtonText>
      ),
    })), [list]);

  const selectHook = useCallback((hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps, rows }) => {
          if (rows.some((row) => row.original.statusValue === OfferStatus.WaitConfirm)) {
            return (
              <div>
                <Form.Item noStyle>
                  <TCM.Checkbox {...getToggleAllRowsSelectedProps()} />
                </Form.Item>
              </div>
            );
          }
          return null;
        },
        Cell: ({ row }) => {
          if (row.original.statusValue === OfferStatus.WaitConfirm) {
            return (
              <div>
                <Form.Item noStyle>
                  <TCM.Checkbox {...row.getToggleRowSelectedProps()} />
                </Form.Item>
              </div>
            );
          }
          return null;
        },
      },
      ...columns,
    ]);
  }, []);

  const tableInstance = useTable(
    { columns, data },
    useRowSelect,
    selectHook,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows: allSelectedFlatRows,
  } = tableInstance;

  const selectedFlatRows = allSelectedFlatRows.filter((i) => i.original.statusValue === OfferStatus.WaitConfirm);

  const [form] = Form.useForm();

  const handleSendConfirmed = () => {
    const offerIds = selectedFlatRows
      .map((item) => listMap.get(item.original.__id).id);

    if (offerIds.length > 0) {
      setOffersStatus({
        offerIds,
        status: 'CONFIRMED',
      });
      setSentOffers(offerIds.length);
    }
  };

  const handleSendRevision = () => {
    const offerIds = selectedFlatRows
      .map((item) => listMap.get(item.original.__id).id);

    if (offerIds.length > 0) {
      setOffersStatus({
        offerIds,
        status: 'REVISION',
      });
      setSentOffers(offerIds.length);
    }
  };

  const handleRerender = () => {
    history.go(0);
  };

  const modalOkHandler = () => {
    resetOffersStatus();
    resetCampaignOffers();
    history.push('/tcm');
  };

  const modalCancelHandler = () => {
    resetOffersStatus();
    if (isAllSelected) {
      resetCampaignOffers();
      handleRerender();
    }
  };

  useEffect(() => {
    if (statusSetLoaded) {
      fetchList();
    }
  }, [statusSetLoaded, fetchList]);

  useEffect(() => {
    if (isLoaded && Array.isArray(list) && list.length > 0) {
      list.map((item) => listMap.set(item.id, item));
    }
  }, [isLoaded, list, listMap]);

  useEffect(() => {
    if (isLoaded && list.length > 0) {
      const isSyncCampaign = String(listId) === id;

      if (isSyncCampaign) {
        const filteredList = list.filter((item) => item.status && item.employee && item.car);
        const confirmedItems = filteredList.filter((item) => item.status === OfferStatus.Confirmed);

        if (filteredList.length > 0 && (filteredList.length === confirmedItems.length)) {
          setIsAllSelected(true);
        } else {
          setIsAllSelected(false);
        }
      }
    }
  }, [
    isLoaded,
    list,
    id,
    listId,
  ]);

  useEffect(() => () => {
    resetOffersStatus();
    resetCampaignOffers();
    setIsAllSelected(false);
  }, [
    resetOffersStatus,
    resetCampaignOffers,
    setIsAllSelected,
  ]);

  return (
    <CallingWrapper>
      <CallingTitle>Клиенты:</CallingTitle>
      <TableContainer>
        {isLoading && <TCM.Loader text="Идет загрузка загрузка списка клиентов..." />}

        <CallingTable hide={isLoading} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </CallingTable>

        <CallingForm form={form} onFinish={handleSendConfirmed} name="callingStatusForm">
          <TCM.ButtonGroup>
            <TCM.Button
              widthSize={280}
              disabled={!(list.length > 0
                && selectedFlatRows.length > 0) || statusSetRevision.isLoading}
              loading={statusSetConfirmed.isLoading}
              htmlType="submit"
            >
              Подтвердить результат
            </TCM.Button>
            <TCM.ButtonOutline
              widthSize={280}
              disabled={!(list.length > 0
                && selectedFlatRows.length > 0) || statusSetConfirmed.isLoading}
              onClick={handleSendRevision}
              loading={statusSetRevision.isLoading}
            >
              Отправить на доработку
            </TCM.ButtonOutline>
          </TCM.ButtonGroup>
        </CallingForm>

      </TableContainer>

      <SaveFormModalOk
        visible={isAllSelected || statusSetConfirmed.isLoaded || statusSetRevision.isLoaded}
        title={isAllSelected
          ? 'Вы отправили на доработку (подтвердили) всех клиентов'
          : `Вы ${statusSetConfirmed.isLoaded ? 'подтвердили' : 'отправили на доработку'} 
            ${sentOffers === 1 ? 'одного' : sentOffers} ${getDeclension(sentOffers, 'клиент')}`}
        onCancel={modalCancelHandler}
        onOk={modalOkHandler}
        btnTextOk="К списку всех кампаний"
        btnTextCancel="Закрыть окно"
      />

      <CreateErrorModal
        visible={statusSetConfirmed.error || statusSetRevision.error}
        title="Ошибка"
        onCancel={resetOffersStatus}
        description="Ошибка сервера, попробуйте позже"
      />

    </CallingWrapper>
  );
};

const ConnectedWithRedux = connect(
  (state) => ({
    // collection
    error: state.tcmCampaignOffer.collection.error,

    // statusSet
    statusSetIsLoading: state.tcmOffer.statusSet.isLoading,
    statusSetLoaded: state.tcmOffer.statusSet.isLoaded,
    statusSetError: state.tcmOffer.statusSet.error,

    listId: state.tcmCampaignOffer.collection.id,

    // statusSetConfirmed
    statusSetConfirmed: state.tcmOffer.statusSet.CONFIRMED,
    // statusSetRevision
    statusSetRevision: state.tcmOffer.statusSet.REVISION,

  }),
  {
    setOffersStatus: setOffersStatusAction,
    resetOffersStatus: resetOffersStatusAction,
    resetCampaignOffers: resetAction,
  },
)(CallingStatusTable);

export { ConnectedWithRedux as CallingStatusTable };
