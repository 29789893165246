import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, Input, Switch, InputNumber,
} from 'antd';
import { DATE_FORMAT } from 'settings';
import moment from 'moment';
import Button from 'components/Button';
import { parseDigitsWithSeparator as parseInteger, formatCurrency } from 'utils';

import {
  confirm as confirmPayment,
  cancel as cancelPayment,
  refund as refundPayment,
  refundPartial as refundPartialPayment,
} from 'redux/transaction/actions';

import { checkPrivilege } from 'helpers/roles';

import {
  ErrorMessage,
  SuccessMessage,
} from 'components/Messages';

import {
  Header,
} from '../style';

import {
  Root,
  Main,
  Aside,
  Footer,
  Row,
  SwitchRow,
  ActionRow,
  ControlGroup,
  CarInfoContainer,
} from './style';

const format = (string) => {
  const valueFormatted = parseInteger(string).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return string === '' ? null : parseInteger(string).length > 0 ? valueFormatted : '';
};

const formatToSum = (string = '') => {
  const regex = /[.]/gm;
  let stringSum = string;

  if (regex.test(string)) {
    stringSum = string.replace(',', '');
  }
  return parseFloat(stringSum) || 0;
};

const METHOD_NAME = {
  arm: 'Счет',
  api: 'Заказ онлайн',
};

const getForm = (data) => [
  ...(data.number ? [{ name: 'number', value: data.number || '' }] : []),
  ...(data.data
    ? [
      {
        name: 'data',
        value:
            (data.data
              && typeof data.data === 'string'
              && moment.utc(data.data).format(DATE_FORMAT))
            || '',
      },
    ]
    : []),
  ...(data.source ? [{ name: 'site', value: METHOD_NAME[data.source] }] : []),
  ...(data.description ? [{ name: 'description', value: data.description || '' }] : []),
  ...(data.name ? [{ name: 'name', value: data.name || '' }] : []),
  ...(data.phone ? [{ name: 'phone', value: data.phone || '' }] : []),
  ...(data.email ? [{ name: 'email', value: data.email || '' }] : []),
  ...(data.sectorName ? [{ name: 'sectorName', value: data.sectorName || '' }] : []),
  ...(data.actualSum ? [{ name: 'actualSum', value: data.actualSum || 0 }] : []),
  ...(data.registerSum ? [{ name: 'registerSum', value: data.registerSum || '' }] : []),
  ...(data.status ? [{ name: 'status', value: data.status || '' }] : []),
  ...(data.brand ? [{ name: 'brand', value: data.brand || '' }] : []),
  ...(data.model ? [{ name: 'model', value: data.model || '' }] : []),
  ...(data.vin ? [{ name: 'vin', value: data.vin || '' }] : []),
  ...(data.carYear ? [{ name: 'carYear', value: data.carYear || '' }] : []),
  ...(data.equipmentCode ? [{ name: 'equipmentCode', value: data.equipmentCode || '' }] : []),
];

const initialValues = {
  number: '',
  data: '',
  site: '',
  description: '',
  name: '',
  phone: '',
  email: '',
  sum: '',
  status: '',
  refundFull: true,
  confirmFull: true,
  actualSum: '',
  sector: '',
  model: '',
  brand: '',
  vin: '',
  carYear: '',
  equipmentCode: '',
};

const S = {
  REGISTERED: 'Ожидает оплаты',
  AUTHORIZED: 'Зарезервирован',
  COMPLETED: 'Подтвержден',
  CANCELED: 'Отменен',
  CANCELED_REVERSE: 'Возврат',
  PARTIAL_REVERSE: 'Частичный возврат',
  BLOCKED: 'Ошибка',
};

const STATUS = new Map([
  [
    S.REGISTERED,
    {
      refund: false,
      cancel: false,
      confirm: false,
      partial: false,
    },
  ],
  [
    S.AUTHORIZED,
    {
      refund: false,
      cancel: true,
      confirm: true,
      partial: false,
    },
  ],
  [
    S.COMPLETED,
    {
      refund: true,
      cancel: false,
      confirm: false,
      partial: false,
    },
  ],
  [
    S.CANCELED,
    {
      refund: false,
      cancel: false,
      confirm: false,
      partial: false,
    },
  ],
  [
    S.CANCELED_REVERSE,
    {
      refund: false,
      cancel: false,
      confirm: false,
      partial: false,
    },
  ],
  [
    S.PARTIAL_REVERSE,
    {
      refund: false,
      cancel: false,
      confirm: false,
      partial: true,
    },
  ],
  [
    S.BLOCKED,
    {
      refund: false,
      cancel: false,
      confirm: false,
      partial: false,
    },
  ],
]);

const getAccess = (status) =>
  (STATUS.has(status)
    ? STATUS.get(status)
    : {
      refund: false, cancel: false, confirm: false, partial: false,
    });

function TransactionForm({
  // connect
  role,
  data,
  isLoaded,

  confirmIsLoading,
  confirmIsLoaded,
  confirmError,

  cancelIsLoading,
  cancelIsLoaded,
  cancelError,

  refundIsLoading,
  refundIsLoaded,
  refundError,

  refundPartialIsLoading,
  refundPartialError,

  // actions
  confirm,
  cancel,
  refund,
  refundPartial,
}) {
  const [form] = Form.useForm();
  const [isMounted, setIsMounted] = useState(false);
  const [isRefundFull, setIsRefundFull] = useState(initialValues.refundFull);
  const [isConfirmFull, setIsConfirmFull] = useState(initialValues.confirmFull);

  const {
    refund: hasRefund, cancel: hasCancel, confirm: hasConfirm, partial: hasPartial,
  } = getAccess(
    data.status,
  );

  // логика просчета необходимой суммы такова:
  // - если платеж  в статусе "Подтвержден", необходимо выводить  возврата частичного или полного
  // - если платеж в статусе "Зарезервирован", необходимо вывводить  подтверждения полной или частиной оплаты
  // - сумма возврата платежа берется из свойства actualSum в объекте paymentResponseList
  // - сумма потверждения платежа рассчитывается по формуле registerSum - actualSum = availableSum

  const { actualSum } = data;
  const totalSum = formatToSum(actualSum);

  useEffect(() => {
    setIsMounted(true);
  }, [setIsMounted]);

  useEffect(() => {
    if (hasPartial) {
      setIsRefundFull(false);
    }
  }, [hasPartial]);

  useEffect(() => {
    if (isMounted && isLoaded) {
      form.setFields([
        ...getForm(data),
        { name: 'refundAmount', value: totalSum },
        { name: 'confirmAmount', value: totalSum },
        { name: 'cancelAmount', value: totalSum },
        { name: 'actualSum', value: actualSum },
        { name: 'refundFull', value: !hasPartial },
      ]);
    }
  }, [isMounted, isLoaded, form, data, totalSum, actualSum, hasPartial]);

  useEffect(() => {
    if (cancelIsLoaded) {
      form.setFields([{ name: 'status', value: S.CANCELED }]);
    }
  }, [cancelIsLoaded, form]);

  function handleChangeRefund(value) {
    setIsRefundFull(value);

    if (value) {
      form.setFields([{ name: 'refundAmount', value: totalSum }]);
    }
  }

  function handleChangeConfirm(value) {
    setIsConfirmFull(value);

    if (value) {
      form.setFields([{ name: 'confirmAmount', value: totalSum }]);
    }
  }

  function handleBlur({ target: { name, value } }) {
    const d = Number(parseInteger(value));
    const max = Number(parseInteger(data.sum));

    if (d > max) {
      form.setFields([{ name, value: format(String(max)) }]);
    }
  }

  function handleRefund() {
    if (isRefundFull) {
      refund();
    } else {
      const v = form.getFieldValue('refundAmount');

      if (v > 0) {
        refundPartial({
          amount: v,
        });
      } else {
        form.setFields([
          { name: 'refundAmount', errors: ['Значение не может быть меньше нуля'] },
        ]);
      }
    }
  }

  function handleCancel() {
    const v = form.getFieldValue('cancelAmount');

    if (v > 0) {
      // amount можно не передавать. Так как отмета идет от полной суммы
      cancel();
    } else {
      form.setFields([
        { name: 'cancelAmount', errors: ['Значение не может быть меньше нуля'] },
      ]);
    }
  }

  function handleConfirm() {
    const v = form.getFieldValue('confirmAmount');

    if (v > 0) {
      confirm({
        amount: v,
      });
    } else {
      form.setFields([
        { name: 'confirmAmount', errors: ['Значение не может быть меньше нуля'] },
      ]);
    }
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleFinish() {}

  return (
    <Root
      name="transactionForm"
      layout="vertical"
      hideRequiredMark
      form={form}
      onFinish={handleFinish}
      initialValues={initialValues}
    >
      <Main>
        <Row>
          <Form.Item name="number" label="Номер заказа">
            <Input name="number" readOnly />
          </Form.Item>
          <Form.Item name="data" label="Дата заказа">
            <Input name="data" readOnly />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item name="site" label="Способ">
            <Input name="site" readOnly />
          </Form.Item>
          <Form.Item name="sectorName" label="Точка продаж">
            <Input name="sectorName" readOnly />
          </Form.Item>
        </Row>
        <Form.Item name="name" label="ФИО">
          <Input name="name" readOnly />
        </Form.Item>
        <Row>
          <Form.Item name="phone" label="Телефон">
            <Input name="phone" readOnly />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input name="email" readOnly />
          </Form.Item>
        </Row>
        <Form.Item name="description" label="Описание">
          <Input.TextArea name="description" readOnly />
        </Form.Item>
        <CarInfoContainer>
          <Header>
            Информация по авто
          </Header>
          <Row>
            <Form.Item name="brand" label="Марка">
              <Input name="brand" readOnly />
            </Form.Item>
            <Form.Item name="model" label="Модель">
              <Input name="model" readOnly />
            </Form.Item>
            {/* <Form.Item name="carYear" label="Год выпуска"> */}
            {/*  <Input name="carYear" readOnly /> */}
            {/* </Form.Item> */}
          </Row>
          <Row>
            <Form.Item name="equipmentCode" label="Код комплектации">
              <Input name="equipmentCode" readOnly />
            </Form.Item>
            <Form.Item name="vin" label="Vin">
              <Input name="vin" readOnly />
            </Form.Item>
          </Row>
        </CarInfoContainer>
      </Main>

      <Aside>
        <Form.Item name="registerSum" label="Сумма заказа">
          <Input name="registerSum" readOnly />
        </Form.Item>
        <Form.Item name="actualSum" label="Cумма актуальная">
          <Input name="actualSum" readOnly />
        </Form.Item>
        <Form.Item name="status" label="Статус заказа">
          <Input name="status" readOnly />
        </Form.Item>

        {(hasRefund || hasCancel || hasConfirm || hasPartial) && (
          <Footer>
            {(hasRefund
              || (hasPartial && (totalSum !== 0))
            ) && checkPrivilege(role.PAYMENTS.RETURN) && (
              <ActionRow>
                <Form.Item name="refundAmount" label="Сумма возврата">
                  <InputNumber
                    name="refundAmount"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isRefundFull}
                    formatter={formatCurrency}
                    precision={2}
                    decimalSeparator=","
                  />
                </Form.Item>
                <SwitchRow>
                  <Form.Item
                    name="refundFull"
                    label="Возврат (полный)"
                    valuePropName="checked"
                  >
                    <Switch
                      name="refundFull"
                      onChange={handleChangeRefund}
                      disabled={hasPartial}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    size="mini"
                    shape="square"
                    onClick={handleRefund}
                    loading={refundIsLoading || refundPartialIsLoading}
                  >
                    {isRefundFull ? 'Полный возврат' : 'Частичный возврат'}
                  </Button>
                </SwitchRow>
                {(refundError || refundPartialError) && (
                  <ErrorMessage>{refundError || refundPartialError}</ErrorMessage>
                )}
                {refundIsLoaded && (
                  <SuccessMessage>Возврат был произведен</SuccessMessage>
                )}
              </ActionRow>
            )}

            {hasCancel && checkPrivilege(role.PAYMENTS.RETURN) && (
              <ActionRow>
                <Form.Item name="cancelAmount" label="Сумма отмены">
                  <InputNumber
                    name="cancelAmount"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled
                    formatter={formatCurrency}
                    precision={2}
                    decimalSeparator=","
                  />
                </Form.Item>
                <ControlGroup>
                  <Button
                    type="ghost"
                    size="mini"
                    shape="square"
                    onClick={handleCancel}
                    loading={cancelIsLoading}
                    disabled={cancelIsLoaded}
                  >
                    {cancelIsLoaded ? 'Отменен' : 'Отмена платежа'}
                  </Button>
                </ControlGroup>
                {cancelError && <ErrorMessage>{cancelError}</ErrorMessage>}
                {cancelIsLoaded && (
                  <SuccessMessage>Отмена была произведена</SuccessMessage>
                )}
              </ActionRow>
            )}

            {hasConfirm && checkPrivilege(role.PAYMENTS.CONFIRM) && (
              <ActionRow>
                <Form.Item name="confirmAmount" label="Сумма подтверждения">
                  <InputNumber
                    name="confirmAmount"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isConfirmFull}
                    precision={2}
                    decimalSeparator=","
                    formatter={formatCurrency}
                  />
                </Form.Item>
                <SwitchRow>
                  <Form.Item
                    name="confirmFull"
                    label="Сумма подтверждения (полная)"
                    valuePropName="checked"
                  >
                    <Switch name="confirmFull" onChange={handleChangeConfirm} />
                  </Form.Item>
                  <Button
                    type="primary"
                    size="mini"
                    shape="square"
                    onClick={handleConfirm}
                    loading={confirmIsLoading}
                    disabled={confirmIsLoaded}
                  >
                    {isConfirmFull ? 'Полное подтверждение' : 'Частичное подтверждение'}
                  </Button>
                </SwitchRow>
                {confirmError && <ErrorMessage>{confirmError}</ErrorMessage>}
                {confirmIsLoaded && (
                  <SuccessMessage>Оплата была произведена</SuccessMessage>
                )}
              </ActionRow>
            )}
          </Footer>
        )}
      </Aside>
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    data: state.transaction.item.data,
    isLoading: state.transaction.item.isLoading,
    isLoaded: state.transaction.item.isLoaded,
    error: state.transaction.item.error,

    confirmIsLoading: state.transaction.item.confirm.isLoading,
    confirmIsLoaded: state.transaction.item.confirm.isLoaded,
    confirmError: state.transaction.item.confirm.error,

    cancelIsLoading: state.transaction.item.cancel.isLoading,
    cancelIsLoaded: state.transaction.item.cancel.isLoaded,
    cancelError: state.transaction.item.cancel.error,

    refundIsLoading: state.transaction.item.refund.isLoading,
    refundIsLoaded: state.transaction.item.refund.isLoaded,
    refundError: state.transaction.item.refund.error,

    refundPartialIsLoading: state.transaction.item.refundPartial.isLoading,
    refundPartialIsLoaded: state.transaction.item.refundPartial.isLoaded,
    refundPartialError: state.transaction.item.refundPartial.error,
  }),
  {
    confirm: confirmPayment,
    cancel: cancelPayment,
    refund: refundPayment,
    refundPartial: refundPartialPayment,
  },
)(TransactionForm);
