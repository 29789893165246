import React from 'react';
import { Table } from 'antd';

import {
  Title,
  Grid,
  GridRow,
  GridItem,
  Root,
} from '../style';

const scroll = { y: 'calc(100vh - 460px)' };

function ApprovedInformation({
  // passed
  data,
}) {
  const {
    approvedServiceList,
    decisionList,
  } = data;
  const decision = (Array.isArray(decisionList) && decisionList[0]) || {};
  const approvedServices = (Array.isArray(approvedServiceList) && approvedServiceList) || [];
  const columnsApprovedServices = [
    {
      key: 'name',
      title: 'Наименование доп.сервиса',
      render: ({ name }) => (
        <span>
          {name}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'amount',
      checkRights: () => true,
      title: 'Стоимость доп.сервиса, включенная в кредит',
      render: ({ amount }) => (
        <span>
          {amount}
        </span>
      ),
      width: 100,
      align: 'center',
    },
    {
      key: 'isIncluded',
      title: 'Включение в сумму кредита (Решение Банка)',
      render: ({ isIncluded }) => (
        <span>
          {isIncluded ? 'Да' : 'Нет'}
        </span>
      ),
      width: 100,
      align: 'center',
    },
  ];

  let payments = [{ label: 'Ежемесячный платеж:', value: decision?.monthlyPayment }];

  if (decision?.monthlyPaymentSub1 || decision?.monthlyPaymentSub2) {
    payments = [];
    let lastPeriod = 0;
    if (decision.monthlyPaymentSub1) {
      const desc = decision.periodSub1 === 1 ? '1 месяц' : `с 1 по ${decision.periodSub1 + 1} месяц`;
      payments.push({ label: 'Ежемесячный платеж:', value: decision.monthlyPaymentSub1, desc });
      lastPeriod = decision.periodSub1;
    }
    if (decision.monthlyPaymentSub2) {
      const desc = decision.periodSub2 === 1 ? `${lastPeriod + 1} месяц` : `с ${lastPeriod + 1} по ${decision.periodSub2 + 1} месяц`;
      payments.push({ label: 'Ежемесячный платеж:', value: decision.monthlyPaymentSub2, desc });
      lastPeriod += decision?.periodSub2;
    }
    const desc = `с ${lastPeriod + 1} по ${decision.loanTerm} месяц`;
    payments.push({ label: 'Ежемесячный платеж:', value: decision.monthlyPayment, desc });
  }

  return (
    <Root>
      <Title>Одобрено</Title>
      <Grid>
        <GridRow>
          <GridItem label>
            Продукт:
          </GridItem>
          <GridItem>
            {decision?.productName}
          </GridItem>
        </GridRow>
        <GridRow borderNone />
        <GridRow>
          <GridItem label>
            Марка:
          </GridItem>
          <GridItem>
            {decision?.carBrand}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Модель:
          </GridItem>
          <GridItem>
            {decision?.carModel}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Стоимость автомобиля в валюте кредита:
          </GridItem>
          <GridItem>
            {decision?.carPrice}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Год выпуска автомобиля:
          </GridItem>
          <GridItem>
            {decision?.carYear}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Первоначальный взнос в валюте кредита:
          </GridItem>
          <GridItem>
            {decision?.downPayment}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Первоначальный взнос, %:
          </GridItem>
          <GridItem>
            {decision?.downPaymentPercent}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Сумма кредита:
          </GridItem>
          <GridItem>
            {decision?.loanAmount}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Валюта кредита:
          </GridItem>
          <GridItem>
            {decision?.loanCurrency}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Срок кредита:
          </GridItem>
          <GridItem>
            {decision?.loanTerm}
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem label>
            Процентная ставка:
          </GridItem>
          <GridItem>
            {decision?.interestRate}
          </GridItem>
        </GridRow>
        {(payments || []).map((item) => (
          <GridRow>
            <GridItem label>
              {item.label}
            </GridItem>
            <GridItem>
              {item.value}
              {' '}
              {item.desc ? `(${item.desc})` : ''}
            </GridItem>
          </GridRow>
        ))}
      </Grid>
      {approvedServices.length > 0 && (
        <>
          <Title>Информация по доп.сервисам</Title>
          <Table
            rowKey={({ id }) => id}
            columns={columnsApprovedServices}
            dataSource={approvedServices}
            bordered
            scroll={scroll}
            pagination={false}
          />
        </>
      )}
    </Root>
  );
}

export default ApprovedInformation;
