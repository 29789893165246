import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const confirm = ({ token }) => {
  const URI = `${API}/confirm/email`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      data: {
        token,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  confirm,
};
