import styled, { css } from 'styled-components';
import React, {
  useEffect, useCallback, useState, useReducer,
} from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'services';

import { CalendarIcon } from 'icons/CalendarIcon';

import withTheme from './withTheme';

export const DatePickerStyled = withTheme(styled(AntDatePicker).attrs(() => ({
  suffixIcon: <CalendarIcon />,
  format: 'DD.MM.YYYY',
  showToday: false,
  dropdownClassName: 'tcm-date-picker',
}))`
  ${({ theme }) => css`
    padding: ${theme.input.padding};
    width: 100%;
    border-color: ${theme.input.borderColor};
    border-radius: ${theme.input.borderRadius}px;
    box-sizing: border-box;
    
    input {
    font-family: ${theme.fontFamily};
    font-weight: ${theme.input.fontWeight};
    font-size: ${theme.input.fontSize}px;
    line-height: ${theme.input.lineHeight}px;
    color: ${theme.input.textColor};
    
      &::placeholder {
        font-weight: ${theme.input.fontWeightPlaceholder};
        font-size: ${theme.input.fontSize}px;
        line-height: ${theme.input.lineHeight}px;
        color: ${theme.input.textPlaceholderColor};
        opacity: 1;
      }
    }
`}
`);

export const TimePicker = withTheme(styled.input.attrs(() => ({ type: 'time' }))`
  padding: 0 20px 0;
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.input.fontWeight};
  font-size: ${({ theme }) => theme.input.fontSize}px;
  line-height: 46px;
  color: ${({ theme }) => theme.input.textColor};
  
  &:hover {
    border-color: #40a9ff;
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);

  };
`);

const DATE_FORMAT = 'YYYY-MM-DD';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

const ACTIONS = {
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        list: action.data,
        isLoaded: true,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function StaticDatePickerContainer(props) {
  const {
    allowClear,
    className,
    id,
    name,
    onBlur,
    onChange,
    theme,
    value,
    placeholder,
    disabledDate,
  } = props;

  const getDisabledDates = useCallback((currentDate) => {
    if (moment(currentDate).isValid()) {
      return disabledDate?.(currentDate);
    }
  },
  [disabledDate]);

  return (
    <DatePickerStyled
      allowClear={allowClear}
      className={className}
      id={id}
      name={name}
      onBlur={(e) => { onBlur(e); }}
      onChange={onChange}
      theme={theme}
      value={value}
      placeholder={placeholder}
      disabledDate={getDisabledDates}
    />
  );
}

function DatePickerContainer(props) {
  const {
    allowClear,
    className,
    id,
    name,
    onBlur,
    onChange,
    theme,
    value,
    placeholder,
    disabledDate,
  } = props;

  const [cacheDisabledDates, setCacheDisabledDates] = useState(new Set());
  const [state, dispatch] = useReducer(reducer, initialState);

  const [dateTo, setDateTo] = useState(
    moment().endOf('year').format('YYYY-MM-DD'),
  );
  const [dateFrom, setDateFrom] = useState(
    moment().format('YYYY-MM-DD'),
  );

  const getDisabledDates = useCallback((currentDate) => {
    if (moment(currentDate).isValid()) {
      return disabledDate?.(currentDate)
      || (cacheDisabledDates.has(currentDate.format(DATE_FORMAT)));
    }
  },
  [cacheDisabledDates, disabledDate]);

  const checkYear = useCallback((value) => {
    const year = value.format('YYYY');

    if (year !== moment(dateTo).format('YYYY')) {
      const endDate = moment(year).endOf('year').format('YYYY-MM-DD');
      const startDate = moment(year).startOf('year').format('YYYY-MM-DD');

      setDateTo(endDate);
      setDateFrom(startDate);
    }
  }, [dateTo]);

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH });
    API.TCM.campaign.getCalendarDate({
      dateFrom: moment(dateFrom).subtract(1, 'M').format('YYYY-MM-DD'),
      dateTo: moment(dateTo).add(1, 'M').format('YYYY-MM-DD'),
    }).then(({ data, errorCode }) => {
      if (
        data
          && data.holidays
          && !errorCode
      ) {
        const holidays = data.holidays.map((item) => moment(item.split('T')[0]));
        dispatch({ type: ACTIONS.FETCH_SUCCESS, data: [...holidays] });
      } else {
        throw new Error('Возникла ошибка при получении списка недоступных дат');
      }
    })
      .catch((error) => {
        dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
      });
  }, [dateFrom, dateTo]);

  useEffect(() => {
    const newCacheDisabledDates = new Set([...cacheDisabledDates,
                                           ...state.list.map((item) => moment(item).format(DATE_FORMAT))]);
    if (state.isLoaded && cacheDisabledDates.size !== newCacheDisabledDates.size) {
      setCacheDisabledDates(newCacheDisabledDates);
      if (newCacheDisabledDates.has(moment(value).format(DATE_FORMAT))) {
        dispatch({ type: ACTIONS.FETCH_FAILURE, error: 'Не рабочий день' });
        onChange('');
      }
    }
  }, [state.isLoaded, state.list, cacheDisabledDates, onChange, value]);

  const onBlurValidation = (e) => {
    const { value } = e.target;
    if (moment(value).isValid()) {
      checkYear(moment(value));
    }
  };
  return (
    <DatePickerStyled
      allowClear={allowClear}
      className={className}
      error={state.error}
      id={id}
      name={name}
      onBlur={(e) => { onBlurValidation(e); onBlur(e); }}
      onChange={onChange}
      theme={theme}
      value={value}
      loading={state.isLoading}
      disabledDate={getDisabledDates}
      placeholder={placeholder}
      onPanelChange={checkYear}
    />
  );
}

export const DatePicker = connect(
  null, null,
)(DatePickerContainer);

export const StaticDatePicker = StaticDatePickerContainer;
