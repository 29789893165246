import {
  POST_CALL_RESULT,
  POST_CALL_RESULT__SUCCESS,
  POST_CALL_RESULT__FAILURE,
  POST_CALL_RESULT__RESET,
} from 'redux/tcmCallResult/actions';

const initState = {
  form: {
    formData: {
      externalId: '',
      status: '',
      statusDescriptions: [],
      date: '',
      text: '',
    },
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
};

export default function callResultReducer(state = initState, action) {
  switch (action.type) {
    case POST_CALL_RESULT: {
      return {
        ...state,
        form: {
          ...state.form,
          formData: action.formData,
          isLoading: true,
        },
      };
    }
    case POST_CALL_RESULT__SUCCESS: {
      return {
        ...state,
        form: {
          ...state.subjects,
          formData: action.formData,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case POST_CALL_RESULT__FAILURE: {
      return {
        ...state,
        form: {
          ...state.form,
          isLoading: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }
    case POST_CALL_RESULT__RESET: {
      return {
        ...state,
        form: initState.form,
      };
    }

    default: {
      return state;
    }
  }
}
