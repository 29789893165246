import styled from 'styled-components';
import { GRID_AREAS } from 'settings';
import { Button } from 'antd';

const Root = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;

  ${({ area }) =>
    typeof area === 'boolean'
    && `
    grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
  `}
`;

const ButtonStyled = styled(Button)``;

export { Root, ButtonStyled };
