import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import List from './List';
import User from './User';

function Index() {
  return (
    <Switch>
      <Route exact path="/tcm/users">
        <Redirect to="/tcm/users/list" />
      </Route>

      <Route path="/tcm/users/list" component={List} />
      <Route path="/tcm/users/new" component={User} />
      <Route path="/tcm/users/:userId" component={User} />
      <Route path="/tcm/users/:userId/edit" component={User} />
    </Switch>
  );
}

export default Index;
