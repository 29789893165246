import API from 'services';

import {
  extractErrorAndCode,
  formatCompaniesAndDealerCenters,
} from 'hacks';

import {
  all, takeLatest, put, fork, call, select, cancel, take,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import { formatCompany } from './format';

import {
  COMPANY_FETCH_LIST,
  COMPANY_FETCH_LIST__SUCCESS,
  COMPANY_FETCH_LIST__FAILURE,
  COMPANY_FETCH_LIST__CANCEL_REQUEST,
  COMPANY_FETCH_LIST__SET_LOADING,
  COMPANY_FETCH_LIST__SET_PAGINATION,
  COMPANY_FETCH_LIST__SET_SEARCH,
  COMPANY_FETCH_LIST__SET_PAGE,
  COMPANY_FETCH_LIST__SET_SORTING,
  COMPANY_FETCH_LIST__SET_FILTER,
  COMPANY_FETCH_LIST__RESET,

  COMPANY_FETCH_ITEM,
  COMPANY_FETCH_ITEM__SUCCESS,
  COMPANY_FETCH_ITEM__FAILURE,

  COMPANY_CREATE,
  COMPANY_CREATE__SUCCESS,
  COMPANY_CREATE__FAILURE,
} from './actions';

const getState = (state) => state.company;

function* _fetchList({ withPagination }) {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);
  try {
    yield put({ type: COMPANY_FETCH_LIST__SET_LOADING });
    const response = yield call(API.company.fetchList, {
      search, page, filters, sorting, withPagination,
    });
    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.legalEntityResponseList))
    ) {
      const { companies, dealerCenters } = formatCompaniesAndDealerCenters(response.data);

      yield put({
        pagination: response.pagination,
        type: COMPANY_FETCH_LIST__SUCCESS,
        list: companies,
        dealerCenterList: dealerCenters,
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: COMPANY_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync({ withPagination }) {
  const fetchSyncTask = yield fork(_fetchList, { withPagination });
  yield take(COMPANY_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(COMPANY_FETCH_LIST, function* ({ withPagination }) {
    yield call(fetchSync, { withPagination });
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(COMPANY_FETCH_LIST__SET_SEARCH, function* ({ withPagination }) {
    yield put({ type: COMPANY_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: COMPANY_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: COMPANY_FETCH_LIST, withPagination });
  });
}

export function* fetchListReset() {
  yield takeLatest(COMPANY_FETCH_LIST__RESET, function* () {
    yield put({ type: COMPANY_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: COMPANY_FETCH_LIST__CANCEL_REQUEST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(COMPANY_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: COMPANY_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: COMPANY_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: COMPANY_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(COMPANY_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: COMPANY_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: COMPANY_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(COMPANY_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: COMPANY_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: COMPANY_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: COMPANY_FETCH_LIST });
  });
}

/* Item */
export function* fetchItem() {
  yield takeLatest(COMPANY_FETCH_ITEM, function* ({ id }) {
    try {
      const response = yield call(API.company.fetchItem, { id });
      if (
        response
        && response.data
        && Array.isArray(response.data.legalEntityResponseList)
      ) {
        yield put({
          type: COMPANY_FETCH_ITEM__SUCCESS,
          data: formatCompany(response.data),

          // List of operations
          subsidiaries: response.data.subsidiariesResponseList
              && Array.isArray(response.data.subsidiariesResponseList)
              && response.data.subsidiariesResponseList.length > 0
            ? response.data.subsidiariesResponseList[0] : {},
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: COMPANY_FETCH_ITEM__FAILURE, error: error.message });
    }
  });
}

function* create() {
  yield takeLatest(COMPANY_CREATE, function* (action) {
    const {
      id,
      name,
      fullName,
      inn,
      ogrn,
      kpp,
      dateRegistration,
      okved,
      okpo,
      oktmo,
      individualSpruceOrgan,
      address,
      postAddress,
      phone,
      fax,
      additionalInfo,
    } = action;

    try {
      const method = id ? API.company.update : API.company.create;

      const response = yield call(method, {
        id,
        name,
        fullName,
        inn,
        ogrn,
        kpp,
        dateRegistration,
        okved,
        okpo,
        oktmo,
        individualSpruceOrgan,
        address,
        postAddress,
        phone,
        fax,
        additionalInfo,
      });

      if (response
        && response.data
        && Array.isArray(response.data.legalEntityResponseList)
        && response.data.legalEntityResponseList[0].id) {
        yield put({
          type: COMPANY_CREATE__SUCCESS,
          data: formatCompany(response.data),

          // List of operations
          subsidiaries: response.data.subsidiariesResponseList
              && Array.isArray(response.data.subsidiariesResponseList)
              && response.data.subsidiariesResponseList.length > 0
            ? response.data.subsidiariesResponseList[0] : {},
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: COMPANY_CREATE__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: COMPANY_CREATE__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(fetchItem),
    fork(fetchListReset),
    fork(create),
  ]);
}
