import styled from 'styled-components';

const Message = styled.div`
  margin: 20px 0;
  font-size: 14px;
  font-family: "Toyota Type",sans-serif;
`;

export const SuccessMessage = styled(Message)`
  color: green;
`;

export const ErrorMessage = styled(Message)`
  color: red;
`;
