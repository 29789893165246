import { MODALS__MERGE, MODALS__REMOVE } from './actions';

const initState = [];

export default function modalsReducer(state = initState, action) {
  switch (action.type) {
    case MODALS__MERGE: {
      return [...state, ...action.modals];
    }
    case MODALS__REMOVE: {
      return state.filter((e) => e.name !== action.name);
    }

    default: {
      return state;
    }
  }
}
