import moment from 'moment';
import { DATE_FORMAT } from 'settings';

const formatValue = (value, key) =>
  (key.endsWith('To') || key.endsWith('From')
    ? /^\d+$/g.test(value)
      ? Number(value)
      : key.endsWith('To')
        ? moment(value, DATE_FORMAT).endOf('day').format()
        : moment(value, DATE_FORMAT).startOf('day').format()
    : value);

export const formatFilters = (object) =>
  Object.keys(object).reduce(
    (p, c) => ({ ...p, ...(object[c] ? { [c]: formatValue(object[c], c) } : {}) }),
    {},
  );
