import moment from 'moment';

import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';

import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';

import {
  extractErrorAndCode,
  formatCampaign,
  formatCampaignList,
} from 'hacks';

import {
  CAMPAIGN_GET_REPORT,
  CAMPAIGN_GET_REPORT__FAILURE,
  CAMPAIGN_GET_REPORT__SUCCESS,
} from 'redux/tcmCampaign/actions';
import {
  CAMPAIGN_FETCH_LIST,
  CAMPAIGN_FETCH_LIST__SUCCESS,
  CAMPAIGN_FETCH_LIST__FAILURE,
  CAMPAIGN_FETCH_LIST__CANCEL_REQUEST,
  CAMPAIGN_FETCH_LIST__SET_LOADING,
  CAMPAIGN_FETCH_LIST__SET_PAGINATION,
  CAMPAIGN_FETCH_LIST__SET_SEARCH,
  CAMPAIGN_FETCH_LIST__SET_PAGE,
  CAMPAIGN_FETCH_LIST__SET_SORTING,
  CAMPAIGN_FETCH_LIST__SET_FILTER,

  CAMPAIGN_CREATE,
  CAMPAIGN_CREATE__SUCCESS,
  CAMPAIGN_CREATE__FAILURE,
  CAMPAIGN_FETCH,
  CAMPAIGN_FETCH__SUCCESS,
  CAMPAIGN_FETCH__FAILURE,
  CAMPAIGN_STATUS_SET,
  CAMPAIGN_STATUS_SET_SUCCESS,
  CAMPAIGN_STATUS_SET__FAILURE,

  CAMPAIGN_FETCH_STATUS_LIST,
  CAMPAIGN_FETCH_STATUS_LIST_SUCCESS,
  CAMPAIGN_FETCH_STATUS_LIST__FAILURE,

  CAMPAIGN_FETCH_DIALING_DATES,
  CAMPAIGN_FETCH_DIALING_DATES__SUCCESS,
  CAMPAIGN_FETCH_DIALING_DATES__FAILURE,

  CAMPAIGN_REJECT,
  CAMPAIGN_REJECT__SUCCESS,
  CAMPAIGN_REJECT__FAILURE,

  CAMPAIGN_GET_DATE,
  CAMPAIGN_GET_DATE__SUCCESS,
  CAMPAIGN_GET_DATE__FAILURE,

} from './actions';

const getState = (state) => state.tcmCampaign;

function* _fetchList() {
  const {
    collection: {
      search, page, sorting,
      filters: {
        data: {
          topics = [],
          subtopics = [],
          ...appliedFilters
        },
      },
    },
  } = yield select(getState);

  const filters = {
    ...(Array.isArray(topics) && topics.length > 0 ? { topics: topics.map((t) => t.value) } : {}),
    ...(Array.isArray(subtopics) && subtopics.length > 0 ? { subtopics: subtopics.map((t) => t.value) } : {}),
    ...appliedFilters,
  };

  try {
    yield put({ type: CAMPAIGN_FETCH_LIST__SET_LOADING });
    const response = yield call(API.TCM.campaign.fetchList, {
      search, page, filters, sorting,
    });

    if (
      response.data
      && ((Array.isArray(response.data) && !response.data.length)
      || (Array.isArray(response.data.campaignItemResponseList)
          && Array.isArray(response.data.topicItemResponseList)
          && Array.isArray(response.data.subtopicItemResponseList)))
    ) {
      yield put({
        type: CAMPAIGN_FETCH_LIST__SUCCESS,
        list: formatCampaignList(response.data),
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: CAMPAIGN_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(CAMPAIGN_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(CAMPAIGN_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(CAMPAIGN_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: CAMPAIGN_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: CAMPAIGN_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(CAMPAIGN_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: CAMPAIGN_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: CAMPAIGN_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(CAMPAIGN_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: CAMPAIGN_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(CAMPAIGN_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: CAMPAIGN_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: CAMPAIGN_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_FETCH_LIST });
  });
}

function* fetchListStatuses() {
  yield takeLeading(CAMPAIGN_FETCH_STATUS_LIST, function* () {
    try {
      const response = yield call(API.TCM.campaign.fetchListStatuses);
      if (
        response.data
          && Array.isArray(response.data.campaignsStatusList)
      ) {
        yield put({
          type: CAMPAIGN_FETCH_STATUS_LIST_SUCCESS,
          list: response.data.campaignsStatusList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_FETCH_STATUS_LIST__FAILURE, error: error.message });
    }
  });
}

function* create() {
  yield takeLatest(CAMPAIGN_CREATE, function* ({
    status,
    subtopicId,
    countCarsInStock,
    dialingFrom,
    dialingTo,
    comment,
  }) {
    try {
      const {
        campaign: {
          data: {
            id = null,
          },
        },
      } = yield select(getState);

      const method = id ? API.TCM.campaign.update : API.TCM.campaign.create;
      const response = yield call(method, {
        status,
        subtopicId,
        countCarsInStock,
        dialingFrom,
        dialingTo,
        comment,
        id,
      });

      if (response && response.data && response.data.id) {
        yield put({ type: CAMPAIGN_CREATE__SUCCESS, data: response.data });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: CAMPAIGN_CREATE__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_CREATE__FAILURE, error: error.message });
    }
  });
}

function* fetchCampaign() {
  yield takeLeading(CAMPAIGN_FETCH, function* ({ id }) {
    try {
      const response = yield call(API.TCM.campaign.fetchCampaign, { id });
      if (
        response.data && !response.errorCode
      ) {
        yield put({
          type: CAMPAIGN_FETCH__SUCCESS,
          data: formatCampaign(response.data),
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_FETCH__FAILURE, error: error.message });
    }
  });
}

function* setCampaignStatus() {
  yield takeLeading(CAMPAIGN_STATUS_SET, function* ({ id, status, comment }) {
    try {
      const response = yield call(API.TCM.campaign.setCampaignStatus, { id, status, comment });
      if (
        response.data
        && !response.errorCode
      ) {
        yield put({
          type: CAMPAIGN_STATUS_SET_SUCCESS,
          data: response.data.deadline ? response.data : {},
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_STATUS_SET__FAILURE, error: error.message });
    }
  });
}

function* campaignReject() {
  yield takeLeading(CAMPAIGN_REJECT, function* ({ reason }) {
    try {
      const {
        campaign: {
          data: {
            id = null,
          },
        },
      } = yield select(getState);

      const response = yield call(API.TCM.campaign.campaignReject, { id, status: 'CLOSED', reason });
      if (
        response.data
          && !response.errorCode
      ) {
        yield put({
          type: CAMPAIGN_REJECT__SUCCESS,
          data: response.data.deadline ? response.data : {},
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_REJECT__FAILURE, error: error.message });
    }
  });
}

function* fetchDialingDates() {
  yield takeLeading(CAMPAIGN_FETCH_DIALING_DATES, function* ({
    dialingFrom,
  }) {
    try {
      const response = yield call(API.TCM.campaign.fetchDialingDates, { dialingFrom });
      if (
        response.data
        && response.data.dialingFrom
        && response.data.dialingTo
      ) {
        yield put({
          type: CAMPAIGN_FETCH_DIALING_DATES__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_FETCH_DIALING_DATES__FAILURE, error: error.message });
    }
  });
}

function* getCalendarDate() {
  yield takeLeading(CAMPAIGN_GET_DATE, function* ({ dateFrom, dateTo }) {
    try {
      const response = yield call(API.TCM.campaign.getCalendarDate, {
        dateFrom, dateTo,
      });

      if (
        response.data
        && response.data.holidays
        && !response.errorCode
      ) {
        const holidays = response.data.holidays.map((item) => moment(item.split('T')[0]));
        yield put({
          type: CAMPAIGN_GET_DATE__SUCCESS,
          data: [...holidays],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_GET_DATE__FAILURE, error: error.message });
    }
  });
}

function* getCampaignReport() {
  yield takeLeading(CAMPAIGN_GET_REPORT, function* ({ campaignIds, startAt, finishAt }) {
    try {
      const response = yield call(API.TCM.campaign.getCampaignReport, {
        campaignIds,
        startAt,
        finishAt,
      });

      if (
        response.data
        && !response.errorCode
      ) {
        yield put({
          type: CAMPAIGN_GET_REPORT__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_GET_REPORT__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(create),
    fork(fetchCampaign),
    fork(setCampaignStatus),
    fork(fetchListStatuses),
    fork(fetchDialingDates),
    fork(campaignReject),
    fork(getCalendarDate),
    fork(getCampaignReport),
  ]);
}
