/* List */
export const FETCH_EMPLOYEES_LIST = 'EMPLOYEES_LIST';
export const FETCH_EMPLOYEES_LIST__SUCCESS = 'EMPLOYEES_LIST__SUCCESS';
export const FETCH_EMPLOYEES_LIST__FAILURE = 'EMPLOYEES_LIST__FAILURE';

export const fetchEmployeesList = () => ({ type: FETCH_EMPLOYEES_LIST });

export const EMPLOYEE_GET_REPORT = 'EMPLOYEE_GET_REPORT';
export const EMPLOYEE_GET_REPORT__SUCCESS = 'EMPLOYEE_GET_REPORT__SUCCESS';
export const EMPLOYEE_GET_REPORT__FAILURE = 'EMPLOYEE_GET_REPORT__FAILURE';
export const EMPLOYEE_GET_REPORT__RESET = 'EMPLOYEE_GET_REPORT__RESET';

export const employeeGetReport = ({
  campaignIds, employeeIds, startAt, finishAt,
}) => ({
  type: EMPLOYEE_GET_REPORT,
  campaignIds,
  employeeIds,
  startAt,
  finishAt,
});
export const employeeResetReport = () => ({
  type: EMPLOYEE_GET_REPORT__RESET,
});
