import React, { useCallback, useEffect, useRef } from 'react';
import Input from 'components/Input';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { Rifm } from 'rifm';
import { SCROLL_DURATION } from 'settings';
import { parseDigits } from 'utils';
import { mergeArrays } from 'hacks';

const SEPARATORS = {
  2: '.',
  4: '.',
};

const format = (string) => {
  const digits = parseDigits(string);

  return Array.from(digits)
    .reduce((p, c, i) => `${p}${SEPARATORS[i] || ''}${c}`, '')
    .substring(0, 11);
};

const replace = (string) => {
  const digits = parseDigits(string);

  const [d, m, y] = [
    digits.slice(0, 2).padEnd(2, '_'),
    digits.slice(2, 4).padEnd(2, '_'),
    digits.slice(4, 8).padEnd(4, '_'),
  ];

  return `${d}${SEPARATORS[2]}${m}${SEPARATORS[4]}${y}`;
};

function DateField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  dataSource,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  function handleOnBlur() {
    const value = getFieldValue(name);
    if ((initialValue || '') !== value) {
      const onlyDigits = parseDigits(value);
      if (onlyDigits.length === 0) {
        onChangeField({}, [...mergeArrays(dependents, ifChangeClearFields), name]);
      }
      if (onlyDigits.length > 0) {
        onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
      }
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: initialValue || '',
      })(
        <Rifm accept={/[\d]/g} mask replace={replace} format={format}>
          {({ value, onChange }) => (
            <Input
              className="ds-digital-profile"
              name={name}
              value={value}
              onBlur={handleOnBlur}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
              ref={focusableNode}
              area={area}
              length={16}
              size={size}
              suffix={(initialValue === value) && (dataSource === 'digital_profile') ? 'DP' : ' '}
            />
          )}
        </Rifm>,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default DateField;
