import React from 'react';

import { Root, LoaderText, LoaderStyled } from './style';

function Loader({ text, height = 400, ...p }) {
  return (
    <Root {...p} height={height} className="loader">
      <LoaderStyled />
      <LoaderText>{text}</LoaderText>
    </Root>
  );
}

export default Loader;
