import React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import List from './List';

function Index() {
  return (
    <Switch>
      <Route exact path="/app/payments/reports">
        <Redirect to="/app/payments/reports/list" />
      </Route>
      <Route path="/app/payments/reports/list" component={List} />
    </Switch>
  );
}

export default Index;
