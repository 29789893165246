import withTheme from 'components/tcm/withTheme';
import styled from 'styled-components';

export const Wrapper = styled.div`
 margin-top: 22px;
`;

export const Content = withTheme(styled.div`
  padding: 33px 40px 37px;
  background-color: ${({ theme }) => theme.palette.gray6};
  border-radius: 10px;
`);

export const WithDraw = styled.div`
  margin: 55px 0 55px auto;
  width: min-content;
`;
