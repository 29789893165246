export const STATUS_OPTIONS = [
  {
    value: 'new',
    label: 'Новый',
  },
  {
    value: 'active',
    label: 'Активный',
  },
  {
    value: 'off_line',
    label: 'Отключен',
  },
];

export const STATUS_VALUES = {
  new: 'Новый',
  active: 'Активный',
  only_account: 'Только Счет',
  off_line: 'Отключен',
};

export const formatToTable = (array = []) => array.map((item) => ({ value: item.id, label: item.name }));

export const formatToApi = ({ array = [], options }) => {
  if (Array.isArray(array)) {
    return (array
      ? options.filter((e) => array.includes(e.label)).map((item) => item.value)
      : []);
  }
  return [array];
};
