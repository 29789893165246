import React from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'antd';
import withTheme from './withTheme';

export const Wrapper = withTheme(styled(({ gridColumn, theme, ...p }) => <Form.Item {...p} />)`
  ${({ theme }) => css`
    
    margin-bottom: 0;
    ${({ gridColumn }) => gridColumn && css`
      grid-column: ${gridColumn}
    `};

    & .ant-form-item-label {
    padding: 0;
    
      label {
        margin-bottom: ${theme.label.marginBottom};
        font-family: ${theme.fontFamily};
        font-style: normal;
        font-weight:  ${theme.label.fontWeight};
        font-size:  ${theme.label.fontSize};
        line-height: ${theme.label.lineHeight};
        color: ${theme.label.textColor};
        height: unset;
      }
    }
  `}
`);

export const Label = ({ children, ...p }) => (
  <Wrapper {...p}>
    {children}
  </Wrapper>
);
