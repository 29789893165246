export const RESTORE__RESET = 'RESTORE__RESET';
export const reset = () => ({ type: RESTORE__RESET });

export const RESTORE_CONFIRM_PASSWORD = 'RESTORE_CONFIRM_PASSWORD';
export const RESTORE_CONFIRM_PASSWORD__SUCCESS = 'RESTORE_CONFIRM_PASSWORD__SUCCESS';
export const RESTORE_CONFIRM_PASSWORD__FAILURE = 'RESTORE_CONFIRM_PASSWORD__FAILURE';
export const confirmPassword = ({ token }) => ({ type: RESTORE_CONFIRM_PASSWORD, token });

export const RESTORE_SEND_OTP = 'RESTORE_SEND_OTP';
export const RESTORE_SEND_OTP__SUCCESS = 'RESTORE_SEND_OTP__SUCCESS';
export const RESTORE_SEND_OTP__FAILURE = 'RESTORE_SEND_OTP__FAILURE';
export const sendOtp = ({ login }) => ({ type: RESTORE_SEND_OTP, login });

export const RESTORE_SEND_OTP_PHONE = 'RESTORE_SEND_OTP_PHONE';
export const RESTORE_SEND_OTP_PHONE__SUCCESS = 'RESTORE_SEND_OTP_PHONE__SUCCESS';
export const RESTORE_SEND_OTP_PHONE__FAILURE = 'RESTORE_SEND_OTP_PHONE__FAILURE';
export const sendOtpByPhone = ({ phone }) => ({ type: RESTORE_SEND_OTP_PHONE, phone });

export const RESTORE_CHECK_OTP = 'RESTORE_CHECK_OTP';
export const RESTORE_CHECK_OTP__SUCCESS = 'RESTORE_CHECK_OTP__SUCCESS';
export const RESTORE_CHECK_OTP__FAILURE = 'RESTORE_CHECK_OTP__FAILURE';
export const checkOtp = ({ code, token, password }) => ({
  type: RESTORE_CHECK_OTP,
  code,
  token,
  password,
});

export const RESTORE_FINISH = 'RESTORE_FINISH';
export const RESTORE_FINISH__SUCCESS = 'RESTORE_FINISH__SUCCESS';
export const RESTORE_FINISH__FAILURE = 'RESTORE_FINISH__FAILURE';
export const finishRestore = ({ password, token }) => ({
  type: RESTORE_FINISH,
  password,
  token,
});
