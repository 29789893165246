import React from 'react';

import { TextStyled, TitleStyled, ParagraphStyled } from './style';

const defaultProps = {};

export const Text = (props) => <TextStyled {...defaultProps} {...props} />;
export const Title = TitleStyled;
export const Paragraph = (props) => <ParagraphStyled {...defaultProps} {...props} />;

export default {
  Text,
  Title,
  Paragraph,
};
