export const formatCrif = ({
  surname = '',
  name = '',
  middleName = '',
  dateOfBirth = '',
  placeOfBirth = '',
  passportSeriesAndNumber = '',
  oldPassportSeriesAndNumber = '',
}) => {
  const [series, number] = passportSeriesAndNumber.split('-').map(Number);
  const [seriesOld, numberOld] = oldPassportSeriesAndNumber.split('-').map(Number);

  return {
    ...(surname ? { surname } : {}),
    ...(name ? { name } : {}),
    ...(middleName ? { patronymic: middleName } : {}),
    ...(dateOfBirth ? { birthdate: dateOfBirth } : {}),
    ...(placeOfBirth ? { birthplace: placeOfBirth } : {}),

    documents: [
      {
        series,
        number,
        docTypeId: 1, // hard-coded value
      },
      {
        series: seriesOld,
        number: numberOld,
        docTypeId: 2, // hard-coded value
      },
    ].filter((e) => e.series || e.number),
  };
};
