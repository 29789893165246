import React from 'react';
import { DatePicker as DatePickerAntD } from 'antd';

import { DATE_FORMAT } from 'settings';

import { DatePickerStyled } from './style';

const defaultProps = {
  getPopupContainer: (trigger) => trigger.parentNode,
  format: DATE_FORMAT,
};

export const DatePicker = (props) => <DatePickerAntD {...defaultProps} {...props} />;
export default React.forwardRef((props, ref) => (
  <DatePickerStyled {...defaultProps} {...props} ref={ref} />
));
