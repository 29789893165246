import withTheme from 'components/tcm/withTheme';
import styled, { css } from 'styled-components';

export const Content = withTheme(styled.div`
  padding: 33px 40px 37px;
  background-color: ${({ theme }) => theme.palette.gray6};
  border-radius: 10px;
`);

export const MainContent = withTheme(styled.div`
  display: grid;
  grid-template-areas: '     id           status        .  '
                       'plannedPeriod estimatedPeriod count';
  grid-auto-columns: max-content;
  column-gap: 30px;
  row-gap: 20px;
  
  &:not(:last-child){
    margin-bottom: 28px;
  }
`);

export const FieldTitle = withTheme(styled.p`
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);
export const FieldValue = withTheme(styled.p`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
`);

export const IdWrapper = styled.div`
  grid-area: id;
`;

export const StatusWrapper = styled.div`
  grid-area: status;
`;

export const PlannedPeriodWrapper = styled.div`
  grid-area: plannedPeriod;
`;

export const EstimatedPeriodWrapper = styled.div`
  grid-area: estimatedPeriod;
`;

export const CountWrapper = styled.div`
  grid-area: count;
`;

export const CommentsWrapper = withTheme(styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  padding-top: 22px;
  border-top: 1px solid ${({ theme }) => theme.palette.gray4};
  
  & > p {
    color: ${({ theme }) => theme.palette.black};
    font-size: 16px;
  }
`);

export const Comment = withTheme(styled.div`
  display: flex;
  align-items: flex-start;
  
  &:not(:last-child){
    margin-bottom: 20px;
  }
`);

export const CommentTitle = withTheme(styled.p`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const CommentDate = withTheme(styled.div`
  flex-shrink: 0;
  display: flex;
  margin-bottom: 0;
  margin-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const CommentRow = styled.div`
  display: flex;
`;

export const CommentText = withTheme(styled.p`
  margin-bottom: 0;
  max-width: 775px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
  overflow-wrap: break-word;
`);

export const Main = styled.div`
  margin-top: 160px;
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 33px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  
  p {
    margin-bottom: 0;
    
    &:last-child {
      margin-left: .5em;
      margin-bottom: 5px;
    }
  }
`;

export const SubTitle = withTheme(styled.p`
  margin-bottom: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
`);

export const BackBtnWrapper = styled.div`
  margin-bottom: 40px;
`;

export const StatusLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StatusHistory = styled.button`
  display: block;
  width: min-content;
  min-width: min-content;
  margin-left: .25em;
  background-color: transparent;
`;

export const ModalTitle = withTheme(styled.p`
${({ theme }) => css`
  margin-top: 18px;
  font-family: ${theme.toyotaFontFamily};
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -1.3px;
  color: ${theme.palette.black};
`}`);

export const HistoryWrapper = styled.div`
  margin-bottom: 90px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  margin-bottom: 40px;
  padding-left: 125px;
  width: 100%;
  overflow: auto;
  height: min-content;
  max-height: 506px;
`;

export const Table = withTheme(styled.div`
${({ theme }) => css`
  width: 580px;
  font-family: ${theme.fontFamily};
  color: ${theme.palette.black};
  font-size: 14px;
  line-height: 20px;
`}
`);

export const TableRow = withTheme(styled.div`
  display: grid;
  grid-template-columns: 72px 162px 1fr;
  column-gap: 28px;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray5};
`);

export const TableHeader = withTheme(styled(TableRow)`
  border-bottom-color: ${({ theme }) => theme.palette.gray4};
`);

export const TableCell = withTheme(styled.div`
  ${({ isSecondary }) => isSecondary && css`
    color: ${({ theme }) => theme.palette.gray4}
  `}
`);

export const CurrentStatus = styled.div`
  position: relative;
`;

export const DateDescriptionWrapper = styled.div`
  position: absolute;
  right: calc(100% + 17px);
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
`;

export const DateDescription = withTheme(styled.div`
  text-align: right;
  color: ${({ theme }) => theme.palette.gray4}
`);
