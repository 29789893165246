import { all } from 'redux-saga/effects';
import tcmCreditCalculatorSagas from 'redux/tcmCreditCalculator/saga';
import adminSagas from './admin/saga';
import authSagas from './auth/saga';
import companySagas from './company/saga';
import countriesSagas from './countries/saga';
import creditSagas from './credit/saga';
import crifSagas from './crif/saga';
import creditRequestSagas from './creditRequest/saga';
import dealerCenterSagas from './dealerCenter/saga';
import historySagas from './history/saga';
import inviteSagas from './invite/saga';
import restoreSagas from './restore/saga';
import paymentSagas from './payment/saga';
import productSagas from './product/saga';
import requestSagas from './request/saga';
import roleSagas from './role/saga';
import tableSagas from './table/saga';
import filesSagas from './files/saga';
import tcmCampaignSagas from './tcmCampaign/saga';
import tcmCampaignOfferSagas from './tcmCampaignOffer/saga';
import tcmOfferSagas from './tcmOffer/saga';
import tcmPeriodSagas from './tcmPeriod/saga';
import transactionSagas from './transaction/saga';
import userStatusSagas from './userStatus/saga';
import userSagas from './user/saga';
import employeeSagas from './tcmEmployee/saga';
import topicsSagas from './tcmTopics/saga';
import callResultsSagas from './tcmCallResult/saga';
import commonSagas from './common/saga';
import contractsSagas from './contracts/saga';
import reportSagas from './report/saga';
import tcmReportSagas from './tcmReport/saga';

export default function* rootSaga() {
  yield all([
    adminSagas(),
    authSagas(),
    companySagas(),
    countriesSagas(),
    creditSagas(),
    crifSagas(),
    creditRequestSagas(),
    dealerCenterSagas(),
    historySagas(),
    inviteSagas(),
    paymentSagas(),
    productSagas(),
    requestSagas(),
    roleSagas(),
    tableSagas(),
    tcmCampaignSagas(),
    tcmCampaignOfferSagas(),
    tcmOfferSagas(),
    tcmPeriodSagas(),
    transactionSagas(),
    userStatusSagas(),
    userSagas(),
    filesSagas(),
    restoreSagas(),
    employeeSagas(),
    topicsSagas(),
    callResultsSagas(),
    commonSagas(),
    contractsSagas(),
    tcmCreditCalculatorSagas(),
    reportSagas(),
    tcmReportSagas(),
  ]);
}
