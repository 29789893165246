// check moratorium if form/fields contains [TYPES.CRM_SELECT] field
export const CRIF_CHECK_MORATORIUM = 'CRIF_CHECK_MORATORIUM';
export const CRIF_SET_MORATORIUM = 'CRIF_SET_MORATORIUM';

// in case if user edited some data in the form - we need to unlock MoratoriumButton
export const CRIF_BUTTON_UNLOCK = 'CRIF_BUTTON_UNLOCK';
export const CRIF_RESULT_UNLOCK = 'CRIF_RESULT_UNLOCK';
export const CRIF_SEARCH_UNLOCK = 'CRIF_SEARCH_UNLOCK';
export const unlockSearch = () => ({ type: CRIF_BUTTON_UNLOCK });

export const CRIF_CANCEL_REQUEST = 'CRIF_CANCEL_REQUEST';
export const CRIF_SET_LOADING = 'CRIF_SET_LOADING';
export const CRIF_SET_PAGINATION = 'CRIF_SET_PAGINATION';

export const CRIF_RESET = 'CRIF_RESET';
export const reset = () => ({ type: CRIF_RESET });

export const CRIF_SET_PAGE = 'CRIF_SET_PAGE';
export const setPage = ({ page }) => ({ type: CRIF_SET_PAGE, page });

export const CRIF_SET_SORTING = 'CRIF_SET_SORTING';
export const setSorting = ({ sortBy }) => ({ type: CRIF_SET_SORTING, sortBy });

export const CRIF_FETCH_LIST = 'CRIF_FETCH_LIST';
export const CRIF_FETCH_LIST__SUCCESS = 'CRIF_FETCH_LIST__SUCCESS';
export const CRIF_FETCH_LIST__FAILURE = 'CRIF_FETCH_LIST__FAILURE';
export const fetchList = ({ accessToken }) => ({ type: CRIF_FETCH_LIST, accessToken });
