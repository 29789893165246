import styled from 'styled-components';

const Root = styled.div`
  padding: 1rem;
  display: flex;

  & + & {
    border-top: 1px solid #d9d9d9;
  }
`;

export { Root };
