import React from 'react';
import styled, { css } from 'styled-components';

const ArrowDownIcon = (props) => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 1L6 6L11 1" stroke="#E50000" strokeWidth="2" />
  </svg>
);

export const ArrowIcon = styled(({
  up,
  left,
  right,
  down,
  ...p
}) => <ArrowDownIcon {...p} />)`
  transform-origin: 50% 50%;
  transform: rotate(${({
    up,
    left,
    right,
    down,
  }) => {
    if (up) return '180';
    if (left) return '90';
    if (right) return '-90';
    if (down) return '0';
    throw new Error('Required "up"|"left"|"right"|"down" props are missing');
  }}deg);
`;

export const LongDownArrow = () => (
  <svg width="8" height="29" viewBox="0 0 8 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.64645 28.3536C3.84171 28.5488 4.15829 28.5488 4.35355 28.3536L7.53554 25.1716C7.7308 24.9763 7.7308 24.6597 7.53553 24.4645C7.34027 24.2692 7.02369 24.2692 6.82843 24.4645L4 27.2929L1.17157 24.4645C0.976312 24.2692 0.659729 24.2692 0.464467 24.4645C0.269205 24.6597 0.269205 24.9763 0.464467 25.1716L3.64645 28.3536ZM3.5 2.18557e-08L3.5 28L4.5 28L4.5 -2.18557e-08L3.5 2.18557e-08Z" fill="#A8AAAC" />
  </svg>
);

const ArrowSortingIcon = (props) => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.5 6L8.39711 0.75H0.602886L4.5 6Z" fill="#6C7073" />
  </svg>
);

const ArrowSortingIconWrapper = styled(ArrowSortingIcon)`
  ${({ up }) => up && css`
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  `}
`;

const ArrowCollapseIcon = (props) => (
  <svg width="48" height="29" viewBox="0 0 48 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7 6L25 24L43 6" stroke="#E71313" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const ArrowCollapseIconWrapper = styled(ArrowCollapseIcon)`
  ${({ up }) => up && css`
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  `}
`;

export {
  ArrowSortingIconWrapper as ArrowSortingIcon,
  ArrowCollapseIconWrapper as ArrowCollapseIcon,
};
