import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  accessToken,
  surname = '',
  name = '',
  patronymic = '',
  birthdate = '',
  birthplace = '',
  documents = [],
}) => {
  const URI = `${API}/person/get/list/${accessToken}`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        surname,
        name,
        patronymic,
        birthdate,
        birthplace,
        documents,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
};
