import {
  getLocalStorageToken,
  saveLocalStorageToken,
  removeLocalStorageToken,
  getSessionStorageToken,
  saveSessionStorageToken,
  removeSessionStorageToken,
} from 'helpers/storage';

export const REMOVE_STATUS_LOCK = 'REMOVE_STATUS_LOCK';
export const SHOW_FILE_TYPE = 'SHOW_FILE_TYPE';

export const SET_REDIRECT_FROM_RETURN_STATUS_BUTTON = 'SET_REDIRECT_FROM_RETURN_STATUS_BUTTON';
export const SET_REDIRECT_FROM_SUBMIT_STATUS_BUTTON = 'SET_REDIRECT_FROM_SUBMIT_STATUS_BUTTON';
export const SET_REDIRECT_FROM_FINAL_STATUS_BUTTON = 'SET_REDIRECT_FROM_FINAL_STATUS_BUTTON';

const KEYS = new Set([
  REMOVE_STATUS_LOCK,
  SHOW_FILE_TYPE,
  SET_REDIRECT_FROM_RETURN_STATUS_BUTTON,
  SET_REDIRECT_FROM_SUBMIT_STATUS_BUTTON,
  SET_REDIRECT_FROM_FINAL_STATUS_BUTTON,
]);

export const CHECK = (feature, defaultValue = false) => {
  if (KEYS.has(feature)) {
    const value = getLocalStorageToken(feature) || getSessionStorageToken(feature);
    let parsedValue = null;

    try {
      parsedValue = JSON.parse(value);
    } catch (er) {
      console.error(er);
    }

    return parsedValue !== null ? parsedValue : defaultValue;
  }
  console.error(
    `Feature ${feature} does not exist, check is you typed it correctly or run FEATURE.
    GET_KEYS() to get list of available features`,
  );
  return defaultValue;
};

export const UNLOCK = (feature, value = true, useSession = true) => {
  if (KEYS.has(feature)) {
    if (useSession) {
      console.info(
        `Feature ${feature} has been enabled! After closing the tab the settings will be reset!
         Reload the page so the effect will be applied.`,
      );
      saveSessionStorageToken(feature, value);
    } else {
      console.info(
        `Feature ${feature} has been enabled! The settings are stored in localStorage.getItem('${feature}').
        Reload the page so the effect will be applied.`,
      );
      saveLocalStorageToken(feature, value);
    }
  } else {
    console.error(
      `Feature ${feature} does not exist, check is you typed it correctly or run FEATURE.
      GET_KEYS() to get list of available features`,
    );
  }
};

export const RESET = (feature) => {
  if (KEYS.has(feature)) {
    removeLocalStorageToken(feature);
    removeSessionStorageToken(feature);
    console.error(
      `Feature ${feature} has been reset. Run FEATURE.GET_KEYS() to get list of available features.
      Reload the page so the effect will be applied.`,
    );
  } else {
    console.error(
      `Feature ${feature} does not exist, check is you typed it correctly or run FEATURE
      GET_KEYS() to get list of available features`,
    );
  }
};

export const GET_KEYS = () => [...KEYS.values()];

const FEATURE = {
  CHECK,
  UNLOCK,
  RESET,
  GET_KEYS,
};

window.FEATURE = FEATURE;

export default FEATURE;
