import styled from 'styled-components';
import {
  DatePicker,
  Form,
  Select,
  Radio,
} from 'antd';
import Button from 'components/Button';

const { RangePicker } = DatePicker;

export const Main = styled(Form)`
`;

export const FormContainer = styled.div`
  border: 1px solid #7B7B7B;
  width: 640px;
  padding: 25px;
  border-radius: 7px;
  
  label {
    color: #7E7E7E;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
  }
  
 `;

export const SelectMultipleWrapper = styled.div`
  .ant-select-multiple .ant-select-selector{
    max-height: 96px;
    overflow-y: auto;
    width: 547px;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
`;

export const RangePickerStyled = styled(RangePicker)`
  width: 100%;
`;

export const ReportSelect = styled(Select)`
  
`;

export const LabelSelect = styled.label`

`;

export const SelectContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const FormButton = styled(Button)`
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RedRadio = styled(Radio).attrs({ className: 'SOMETHING' })`
  .ant-radio-inner::after {
    background: #E71313;
  }
  
  .ant-radio-checked .ant-radio-inner {
    border-color: #E71313;
    background: #E71313;
  }
  
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
    border-color: #E71313;
  }
`;

export const ErrorMessage = styled.p`
  align-self: flex-start;
  color: red;
`;

export const SuccessMessage = styled.p`
  align-self: flex-end;
  color: green;
`;
