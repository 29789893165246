import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  campaignReject as campaignRejectAction,
  campaignRejectReset as campaignRejectResetAction,
} from 'redux/tcmCampaign/actions';

import { TCM } from 'components/tcm';

import {
  CheckBoxText,
  Title,
  Row,
  Wrapper,
  RadioTitle,
  SubTitle,
} from './style';

import { FormItems } from './data';

const MIN_TEXT_LENGTH = 1;

function RejectCampaign({
   // passed
  visible,
  close,
  blockGoBack,
  fetchList,

  // store
  isLoading,
  isLoaded,
  data,

  // actions
  campaignReject,
  campaignRejectReset,
}) {
  const [form] = Form.useForm();
  const [showComments, setShowComments] = useState(false);
  const [canFinish, setCanFinish] = useState(false);
  const history = useHistory();

  const handleFinish = (values) => {
    const { reason: reasonValue, comment } = values;

    if (comment) {
      campaignReject({ reason: `${reasonValue} ${comment}` });
    } else {
      campaignReject({ reason: reasonValue });
    }
  };

  const handleValuesChange = (val) => {
    if (val.reason) {
      setShowComments(val.reason === FormItems.other.label);
      setCanFinish(val.reason !== FormItems.other.label);
    }

    if (Object.prototype.hasOwnProperty.call(val, 'comment')) {
      setCanFinish(val.comment.length >= MIN_TEXT_LENGTH);
    }
  };

  useEffect(() => {
    if (isLoaded && !blockGoBack) {
      close();

      history.goBack();
    }
  }, [isLoaded, close, history, blockGoBack]);

  useEffect(() => {
    if (isLoaded && blockGoBack && fetchList) {
      fetchList();
      close();
      campaignRejectReset();
    }
  }, [blockGoBack, campaignRejectReset, close, fetchList, isLoaded]);

  useEffect(() => () => campaignRejectReset(), [campaignRejectReset]);

  return (
    <TCM.Modal
      visible={visible && data.canClosed}
      onCancel={close}
      footer={null}
    >
      <Wrapper>
        <Title>Отзыв кампании</Title>
        <SubTitle>После отзыва кампании работа по ней со стороны банка прекратится.</SubTitle>
        <RadioTitle>Выберите причину:</RadioTitle>
        <Form
          form={form}
          name="reject"
          layout="vertical"
          validateTrigger="onSubmit"
          onFinish={handleFinish}
          onValuesChange={handleValuesChange}
        >
          <TCM.Label
            name="reason"
            validateTrigger="onBlur"
          >
            <TCM.RadioGroup name="reason">
              <Row>
                <TCM.Radio
                  value={FormItems.cars.label}
                >
                  <CheckBoxText>{FormItems.cars.label}</CheckBoxText>
                </TCM.Radio>
              </Row>
              <Row>
                <TCM.Radio
                  value={FormItems.conditions.label}
                >
                  <CheckBoxText>{FormItems.conditions.label}</CheckBoxText>
                </TCM.Radio>
              </Row>
              <Row>
                <TCM.Radio
                  value={FormItems.other.label}
                >
                  <CheckBoxText>{FormItems.other.label}</CheckBoxText>
                </TCM.Radio>
              </Row>

            </TCM.RadioGroup>
          </TCM.Label>
          {showComments && (
            <Row>
              <TCM.Label name={FormItems.comment.key}>
                <TCM.Textarea
                  placeholder="Введите текст"
                  name={FormItems.comment.key}
                />
              </TCM.Label>
            </Row>
          )}

          <TCM.ButtonGroup>
            <TCM.Button
              htmlType="submit"
              loading={isLoading}
              disabled={!(canFinish)}
            >
              Отозвать кампанию
            </TCM.Button>
            <TCM.ButtonOutline onClick={close}>
              Закрыть окно
            </TCM.ButtonOutline>
          </TCM.ButtonGroup>
        </Form>
      </Wrapper>
    </TCM.Modal>
  );
}

export default connect(
  (state) => ({
    data: state.tcmCampaign.campaign.data,
    isLoading: state.tcmCampaign.campaignReject.isLoading,
    isLoaded: state.tcmCampaign.campaignReject.isLoaded,
  }),
  {
    campaignReject: campaignRejectAction,
    campaignRejectReset: campaignRejectResetAction,
  },
)(RejectCampaign);
