import React, { useState } from 'react';
import { Select as SelectAntD } from 'antd';

import { WithStyle } from './style';

const defaultProps = {
  allowClear: true,
  hasDefault: true,
};

const Select = WithStyle((props) => <SelectAntD {...defaultProps} {...props} />);

function SelectFilter({
  name, value: valueFromProps, onChange, options = [], isLoading, hasDefault,
}) {
  const [value, setValue] = useState(valueFromProps || '');

  function handleOnChange(v) {
    setValue(v);
    onChange({ [name]: v || '' });
  }

  return (
    <Select
      allowClear
      name={name}
      value={value}
      onChange={handleOnChange}
      loading={isLoading}
    >
      { hasDefault && (
        <SelectAntD.Option key="" value="">
          Не выбрано
        </SelectAntD.Option>
      )}
      {options.map((option, i) => (
        /* eslint-disable-next-line */
        <SelectAntD.Option title={option.label} key={option.value + i} value={option.value}>
          {option.label}
        </SelectAntD.Option>
      ))}
    </Select>
  );
}

export default SelectFilter;
