import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTable } from 'react-table';
import { Form } from 'antd';
import { Rifm } from 'rifm';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import {
  setEvaluation as setEvaluationAction,
  resetSetEvaluation as resetSetEvaluationAction,
  offerExport as offerExportAction,
  offerImport as offerImportAction,
  offerExportImportReset as offerExportImportResetAction,
} from 'redux/tcmOffer/actions';

import {
  fetchList as fetchListAction,
} from 'redux/tcmCampaignOffer/actions';

import {
  setCampaignStatus as setCampaignStatusAction,
  resetSetCampaignStatus as resetSetCampaignStatusAction,
  resetCampaign as resetCampaignAction,
} from 'redux/tcmCampaign/actions';
import { parseDigitsWithSeparator as parseDigits } from 'utils';
import { TCM } from 'components/tcm';
import {
  CreateSuccessfulModal,
  SaveFormModal,
  CreateErrorModal,
  SaveFormModalOk,
} from 'components/tcm/Modals';

import { RublerIcon } from 'icons';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import isEqual from 'react-fast-compare';
import { getToken } from 'helpers/utility';
import RejectCampaign from '../RejectCampaign';
import { Suffix } from '../style';
import { KEYS as K, COLOR_MAP } from './data';

import { CallingStatusTable } from './CallingStatusTable';
import { CompleteStatusTable } from './CompleteStatusTable';

import {
  TableContainer,
  TableVinTitleContainer,
  TableVinBodyContainer,
  TableBodyEngineContainer,
  TableBodyColorsContainer,
  Table,
  TableTitleSecondaryStroke,
  TableTitleStroke,
  Brand,
  Model,
  Vin,
  Cell,
  Color,
  ButtonsContainer,
  Price,
  ExcelButtonsContainer,
  Hidden,
  ErrorRow,
  ErrorField,
} from './style';

const getValueAndRowNumber = (string = '') => (
  string ? string.match(/(?<row>\d*)(?<value>.*)/).groups
    : {
      row: '1',
      value: '',
    }
);

const groupFieldsByRow = (o) => Object.entries(o).reduce((prev, [k, value]) => {
  const { row, value: field } = getValueAndRowNumber(k);
  return {
    ...prev,
    [row]: {
      ...(prev[row] ? prev[row] : []),
      [K[field].apiKey]: K[field] !== K.EXTERNAL_ID ? parseDigits(value) : value,
    },
  };
}, {});

const getFields = (o) => Object.entries(o).reduce((p, [, v]) => [...p, v], []);

const getErrorsRow = (errors = []) => (Array.isArray(errors)
  ? errors.map((error) => (
    <ErrorRow>
      <ErrorField>
        ID:
        {' '}
        <strong>{error.id}</strong>
        {' '}
        Поле:
        {' '}
        <strong>{error.fieldName}</strong>
      </ErrorField>
      {' '}
      -
      {' '}
      {error.messages}
    </ErrorRow>
  ))
  : errors);

const REQUIRED_FIELDS_NAMES = ['PRICE_TRADE_IN', 'PRICE_NEW_CAR'];
const ALL_FIELDS_NAMES = ['PRICE_TRADE_IN', 'PRICE_NEW_CAR', 'PRICE_EXTRA_SERVICES'];

function Offer({
  // store
  list,
  isLoading,
  isLoaded,
  isStatusRevision,
  // error,

  evaluationIsLoading,
  evaluationIsLoaded,
  // evaluationError,
  setIsDataChanged,
  setDraftSaveHandler,

  campaign,
  campaignsSet,
  campaignsSetIsLoading,
  campaignsSetIsLoaded,

  // actions
  fetchList,
  setEvaluation,
  setCampaignStatus,

  resetSetEvaluation,
  resetSetCampaignStatus,

  exportOfferData,
  exportOfferIsLoading,
  exportOfferIsLoaded,
  exportOfferError,

  importOfferIsLoading,
  importOfferIsLoaded,
  importOfferError,

  offerExport,
  offerImport,
  offerExportImportReset,
  resetCampaign,
}) {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [isDraft, setIsDraft] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [fieldsIsValid, setFieldsIsValid] = useState(true);
  const [showSaved, setShowSaved] = useState(false);
  const [showFillWarning, setShowFillWarning] = useState(false);
  const [fillWarningText, setFillWarningText] = useState('');
  const [kpCalculationTime, setKpCalculationTime] = useState('');
  const [initialValue, setInitialValue] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const inputFile = useRef(null);
  const [showDraftModalConfirmation, setShowDraftConfirmation] = useState(false);
  /// fixed after demo to normal logic
  const [isSendToBank, setIsSendToBank] = useState(false);
  // import export excel files
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  // reject campaign
  const [showRejectModal, setShowRejectModal] = useState(false);

  const isEdit = campaign?.statusCode === CAMPAIGN_STATUSES.EVALUATION || isStatusRevision;

  const formatInteger = (string) => {
    const parsed = parseDigits(string);
    const number = Number.parseInt(parsed, 10);

    if (Number.isNaN(number)) {
      return '';
    }
    return number.toLocaleString('ru');
  };

  const handleSetDraft = useCallback(() => {
    const values = form.getFieldsValue();
    const evaluations = getFields(groupFieldsByRow(values)).map((item) => ({
      externalId: item.externalId,
      tradeInPrice: typeof item.tradeInPrice === 'number' ? item.tradeInPrice : Number(item.tradeInPrice),
      newCarPrice: typeof item.newCarPrice === 'number' ? item.newCarPrice : Number(item.newCarPrice),
      servicesPrice: typeof item.servicesPrice === 'number' ? item.servicesPrice : Number(item.servicesPrice),
    }));
    setIsDraft(true);
    setEvaluation({ evaluations });
  }, [form, setEvaluation]);

  const handleSetCampaignStatus = () => {
    setIsDraft(false);
    setIsSendToBank(true);

    const values = form.getFieldsValue();
    const evaluations = getFields(groupFieldsByRow(values));
    const filledRows = evaluations.filter((field) => field[K.PRICE_TRADE_IN.apiKey] && field[K.PRICE_NEW_CAR.apiKey]);

    if (evaluations.length === filledRows.length) {
      setEvaluation({ evaluations });
    } else {
      setFillWarningText(
        (`У вас на оценке ${evaluations.length} автомобилей.
Вы внесли данные по ${filledRows.length}, все равно отправить?`),
        setShowFillWarning(true),
      );
    }
  };

  const handleCloseModal = () => {
    setShowSaved(false);
    setShowFillWarning(false);

    if (campaignsSetIsLoaded) {
      history.push('/tcm');
    }
  };

  const handleSetCampaignStatusWithUnfilledRows = () => {
    const values = form.getFieldsValue();
    const evaluations = getFields(groupFieldsByRow(values)).map((item) => ({
      externalId: item.externalId,
      tradeInPrice: typeof item.tradeInPrice === 'number' ? item.tradeInPrice : Number(item.tradeInPrice),
      newCarPrice: typeof item.newCarPrice === 'number' ? item.newCarPrice : Number(item.newCarPrice),
      servicesPrice: typeof item.servicesPrice === 'number' ? item.servicesPrice : Number(item.servicesPrice),
    }));
    const filledRows = evaluations.filter((field) => field[K.PRICE_TRADE_IN.apiKey] && field[K.PRICE_NEW_CAR.apiKey]);
    setEvaluation({ evaluations: filledRows });
  };

  const onSuccessfulModalClose = useCallback(() => {
    history.push('/tcm');
  }, [history]);

  const onModalDraftConfirmationClose = useCallback(() => {
    setShowDraftConfirmation(false);
  }, []);

  const onModalDraftConfirmationSuccess = useCallback(() => {
    setShowDraftConfirmation(false);
    handleSetDraft();
  }, [handleSetDraft]);

  const validateTable = useCallback(() => {
    const getValuesByRows = (values) => Object.entries(values)
      .reduce((p, [k, v]) => {
        const { row, value } = getValueAndRowNumber(k);
        return {
          ...p,
          [row]: {
            ...(p[row] ? p[row] : {}),
            ...(
              ALL_FIELDS_NAMES.includes(value) ? { [value]: v } : {}),
          },
        };
      }, {});

    const isRowValid = (row) => {
      const isRowFull = REQUIRED_FIELDS_NAMES.every((e) => row[e]);
      if (isRowFull) {
        return 'rowFull';
      }
      const isRowEmpty = ALL_FIELDS_NAMES.every((e) => !row[e]);
      if (isRowEmpty) {
        return 'rowEmpty';
      }
      return 'rowInvalid';
    };

    const values = form.getFieldsValue();
    const valuesByRows = getValuesByRows(values);
    const validateRows = Object.keys(valuesByRows).map((rowName) => isRowValid(valuesByRows[rowName]));
    const isRowsInvalid = validateRows.some((el) => el === 'rowInvalid');
    const isFullRow = validateRows.some((el) => el === 'rowFull');

    setDisableButton(isRowsInvalid || !isFullRow);
  }, [form]);

  const handleBlur = useCallback(() => {
    setFieldsIsValid(true);
    form.validateFields()
      .catch(() => { setFieldsIsValid(false); });

    validateTable();
  }, [form, validateTable]);

  const handleFocus = useCallback(({ target: { name } }) => {
    form.setFields([{ name }]);
  }, [form]);

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <TableVinTitleContainer>
            <Vin>
              <TableTitleSecondaryStroke>VIN</TableTitleSecondaryStroke>
            </Vin>
            <Brand>
              <TableTitleStroke>Марка</TableTitleStroke>
            </Brand>
            <Model>
              <TableTitleSecondaryStroke>Модель</TableTitleSecondaryStroke>
            </Model>
          </TableVinTitleContainer>
        ),
        accessor: 'vin', // accessor is the "key" in the data
      },
      {
        Header: <TableTitleSecondaryStroke>Год выпуска</TableTitleSecondaryStroke>,
        accessor: 'yearOfIssue',
      },
      {
        Header: <TableTitleSecondaryStroke>Суффикс</TableTitleSecondaryStroke>,
        accessor: 'suffix',
      },
      {
        Header: <TableTitleSecondaryStroke> Двигатель, Объем</TableTitleSecondaryStroke>,
        accessor: 'engine',
      },
      {
        Header: <TableTitleSecondaryStroke>Цвет кузова, салона</TableTitleSecondaryStroke>,
        accessor: 'colors',
      },
      {
        Header: <TableTitleSecondaryStroke>Цена Trade-In</TableTitleSecondaryStroke>,
        accessor: 'priceTradeIn',
      }, {
        Header: <TableTitleSecondaryStroke>Цена нового автомобиля</TableTitleSecondaryStroke>,
        accessor: 'priceNewCar',
      }, {
        Header: <TableTitleSecondaryStroke>Доп.сервисы</TableTitleSecondaryStroke>,
        accessor: 'priceExtraServices',
      },
    ],
    [],
  );

  const data = React.useMemo(
    () => list.map((item) => {
      const { car, evaluation, unId } = item;
      const { tradeInPrice = '', newCarPrice = '', servicesPrice = '' } = evaluation || {};
      const { suffixTMSList } = car;
      const suffix = Array.isArray(suffixTMSList) && suffixTMSList.length > 0 && suffixTMSList[0]
        ? suffixTMSList[0]?.name : '';

      return ({
        vin: (
          <TableVinBodyContainer>
            <Vin>{car?.vin || ''}</Vin>
            <Brand>{car?.brand || ''}</Brand>
            <Model>{car?.model || ''}</Model>
            <Hidden>
              <TCM.Label
                name={`${unId}${K.EXTERNAL_ID.key}`}
                rules={K.EXTERNAL_ID.rules}
              >
                <TCM.Input
                  name={`${unId}${K.EXTERNAL_ID.key}`}
                />
              </TCM.Label>
            </Hidden>
          </TableVinBodyContainer>
        ),
        yearOfIssue: <Cell left>{car.dateOfIssue ? moment(car.dateOfIssue).format('YYYY') : '-'}</Cell>,
        suffix: <Cell left>{suffix}</Cell>,
        engine: (
          <TableBodyEngineContainer>
            <Cell>{car?.engineType || ''}</Cell>
            <Cell second>
              {car?.workingVolume ? `${car.workingVolume / 1000} л` : '-'}
            </Cell>
          </TableBodyEngineContainer>
        ),
        colors: (
          <TableBodyColorsContainer>
            {car?.bodyColor && COLOR_MAP[car?.bodyColor]
              ? <Color color={COLOR_MAP[car?.bodyColor]} />
              : <span />}
            <Cell>
              {
                (car?.bodyColor && COLOR_MAP[car?.bodyColor])
                  ? car?.bodyColor : 'Нет данных'
              }
            </Cell>

            {car?.interiorColor && COLOR_MAP[car?.interiorColor]
              ? <Color color={COLOR_MAP[car?.interiorColor]} />
              : <span />}
            <Cell>
              {
                (car?.interiorColor && COLOR_MAP[car?.interiorColor])
                  ? car?.interiorColor
                  : 'Нет данных'
              }
            </Cell>
          </TableBodyColorsContainer>
        ),
        priceTradeIn: isEdit ? (
          <TCM.Label
            name={`${unId}${K.PRICE_TRADE_IN.key}`}
            rules={K.PRICE_TRADE_IN.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm accept={/[\d]/g} format={formatInteger}>
              {({ value, onChange }) => (
                <TCM.Input
                  name={`${unId}${K.PRICE_TRADE_IN.key}`}
                  suffix={<Suffix><RublerIcon /></Suffix>}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={value ?? ''}
                />
              )}
            </Rifm>
          </TCM.Label>
        ) : (
          tradeInPrice ? (
            <Price>
              {formatInteger((tradeInPrice))}
              <Suffix><RublerIcon /></Suffix>
            </Price>
          ) : ''
        ),
        priceNewCar: isEdit ? (
          <TCM.Label
            name={`${unId}${K.PRICE_NEW_CAR.key}`}
            rules={K.PRICE_NEW_CAR.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm accept={/[\d]/g} format={formatInteger}>
              {({ value, onChange }) => (
                <TCM.Input
                  name={`${unId}${K.PRICE_NEW_CAR.key}`}
                  suffix={<Suffix><RublerIcon /></Suffix>}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={value ?? ''}
                />
              )}
            </Rifm>
          </TCM.Label>
        ) : (
          newCarPrice ? (
            <Price>
              {formatInteger((newCarPrice))}
              <Suffix><RublerIcon /></Suffix>
            </Price>
          ) : ''
        ),
        priceExtraServices: isEdit ? (
          <TCM.Label
            name={`${unId}${K.PRICE_EXTRA_SERVICES.key}`}
            rules={K.PRICE_EXTRA_SERVICES.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm accept={/[\d]/g} format={formatInteger}>
              {({ value, onChange }) => (
                <TCM.Input
                  name={`${unId}${K.PRICE_EXTRA_SERVICES.key}`}
                  suffix={<Suffix><RublerIcon /></Suffix>}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={onChange}
                  value={value ?? ''}
                />
              )}
            </Rifm>
          </TCM.Label>
        ) : (
          servicesPrice ? (
            <Price>
              {formatInteger((servicesPrice))}
              <Suffix><RublerIcon /></Suffix>
            </Price>
          ) : ''
        ),
      });
    }), [handleBlur, handleFocus, isEdit, list],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const onFormChange = useCallback(() => {
    setIsFormChanged(!isEqual(initialValue, form.getFieldsValue()));
  },
  [form, initialValue]);

  const onExportClick = useCallback(() => {
    offerExport({ id: campaign.id });
  }, [campaign.id, offerExport]);

  const onImportChange = ((event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('data[id]', campaign.id);
      formData.append('data[file]', file);
      formData.append('accessToken', getToken());
      offerImport({ formData });
    }
  });

  useEffect(() => {
    fetchList({ id });
  }, [fetchList, id]);

  useEffect(() => {
    if (isLoaded && (list.length > 0)) {
      list.forEach((item) => {
        const {
          unId,
          externalId,
          evaluation,
        } = item;

        const { newCarPrice = '', tradeInPrice = '', servicesPrice = '' } = evaluation || {};

        form.setFields([
          { name: `${unId}${K.PRICE_NEW_CAR.key}`, value: newCarPrice },
          { name: `${unId}${K.PRICE_TRADE_IN.key}`, value: tradeInPrice },
          { name: `${unId}${K.PRICE_EXTRA_SERVICES.key}`, value: servicesPrice },
          ...(externalId ? [{ name: `${unId}${K.EXTERNAL_ID.key}`, value: externalId }] : []),
        ]);
      });
    }
  }, [isLoaded, list, form]);

  useEffect(() => {
    if (isLoaded && (list.length > 0)) {
      validateTable();
    }
  }, [validateTable, isLoaded, list]);

  useEffect(() => {
    if (isDraft && evaluationIsLoaded) {
      history.push('/tcm');
    }
    if (!isDraft && evaluationIsLoaded && isSendToBank) {
      setCampaignStatus({
        id,
        status: CAMPAIGN_STATUSES.KP_CALCULATION,
        comment: '',
      });
    }
  }, [evaluationIsLoaded, history, isDraft, id, setCampaignStatus, isSendToBank]);

  useEffect(() => {
    if (campaignsSetIsLoaded) {
      setShowSaved(true);
    }
  }, [campaignsSetIsLoaded]);

  useEffect(() => () => {
    resetSetEvaluation();
    resetSetCampaignStatus();
  }, [resetSetEvaluation, resetSetCampaignStatus]);

  useEffect(() => {
    if (campaignsSetIsLoaded) {
      const date = campaignsSet.deadline
        ? moment(campaignsSet.deadline).format('DD.MM.YYYY')
        : moment().add(3, 'd').format('DD.MM.YYYY');
      setKpCalculationTime(date);
    }
  }, [campaignsSetIsLoaded, campaignsSet]);

  useEffect(() => {
    if (isLoaded) {
      setInitialValue(form.getFieldsValue());
    }
  }, [form, form.getFieldsValue, isLoaded]);

  useEffect(() => {
    if (isEdit) {
      setIsDataChanged(!isEqual(initialValue, form.getFieldsValue()));
    }
  });

  useEffect(() => {
    if (importOfferError || importOfferIsLoaded) {
      setShowImportModal(true);
    }
  }, [importOfferError, importOfferIsLoaded]);

  useEffect(() => {
    if (exportOfferError) {
      setShowExportModal(true);
    }
  }, [exportOfferError]);

  useEffect(() => {
    if (importOfferIsLoaded) {
      fetchList({ id });
      // reset value in input file
      inputFile.current.value = '';
    }
  }, [importOfferIsLoaded, fetchList, id]);

  useEffect(() => {
    if (importOfferError) {
      // reset value in input file
      inputFile.current.value = '';
    }
  }, [importOfferError]);

  useEffect(() => {
    if (isEdit) {
      setDraftSaveHandler(handleSetDraft);
    }
  }, [handleSetDraft, isEdit, setDraftSaveHandler]);

  useEffect(() => {
    if (exportOfferIsLoaded) {
      const url = URL.createObjectURL(exportOfferData);
      const shadowLink = document.createElement('a');
      shadowLink.href = url;
      shadowLink.download = `${campaign.topic}_${campaign.subtopic}_${campaign.id}.xlsx`;
      shadowLink.click();
    }
  }, [campaign, exportOfferData, exportOfferIsLoaded]);

  useEffect(() => () => {
    offerExportImportReset();
    resetCampaign();
  }, [offerExportImportReset, resetCampaign]);

  // TODO: exportOfferError will have to be in snackbar

  if (campaign?.statusCode === CAMPAIGN_STATUSES.CALLING) {
    return (
      <CallingStatusTable
        list={list}
        isLoading={isLoading}
        isLoaded={isLoaded}
        fetchList={fetchList}
      />
    );
  }

  if (campaign?.statusCode === CAMPAIGN_STATUSES.COMPLETE) {
    return (
      <CompleteStatusTable
        list={list}
        isLoading={isLoading}
        isLoaded={isLoaded}
      />
    );
  }

  return (
    <>
      <ExcelButtonsContainer>
        Автомобили на оценку:
        <TCM.ButtonGroup>
          <TCM.ButtonOutline
            widthSize="fixed"
            onClick={onExportClick}
            loading={exportOfferIsLoading}
          >
            Выгрузить в Excel
          </TCM.ButtonOutline>
          {isEdit && (
            <TCM.ButtonOutline widthSize="fixed" as="label" loading={importOfferIsLoading}>
              Загрузить из Excel
              <input
                ref={inputFile}
                type="file"
                accept=".xlsx"
                style={{ display: 'none' }}
                onChange={onImportChange}
              />
            </TCM.ButtonOutline>
          )}
        </TCM.ButtonGroup>
      </ExcelButtonsContainer>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onFormChange}
      >
        <TableContainer isEdit={isEdit}>
          {isLoading && <TCM.Loader text="Идет загрузка списка автомобилей..." />}

          <Table hide={isLoading} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} key={column.id}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} key={cell.column.id}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
        <ButtonsContainer isEdit={isEdit}>
          {isEdit && (
            <TCM.ButtonGroup>
              <TCM.Button
                disabled={disableButton || !fieldsIsValid}
                onClick={handleSetCampaignStatus}
                loading={!isDraft && campaignsSetIsLoading}
              >
                Отправить в банк
              </TCM.Button>
              <TCM.ButtonOutline
                disabled={!isFormChanged || !fieldsIsValid}
                // если нужно показывать модальное окно
                // onClick={() => setShowDraftConfirmation(true)}
                onClick={() => onModalDraftConfirmationSuccess()}
                loading={isDraft && evaluationIsLoading}
              >
                Сохранить и закрыть
              </TCM.ButtonOutline>
            </TCM.ButtonGroup>
          )}
          <TCM.ButtonText onClick={() => setShowRejectModal(true)}>Отозвать кампанию</TCM.ButtonText>
        </ButtonsContainer>
      </Form>

      <RejectCampaign
        close={() => setShowRejectModal(false)}
        visible={showRejectModal}
      />

      <SaveFormModal
        title={<span style={{ whiteSpace: 'break-spaces' }}>{fillWarningText}</span>}
        visible={showFillWarning}
        btnTextOk="Да, отправить в банк"
        btnTextCancel="Нет, не отправлять"
        onCancel={handleCloseModal}
        onOk={handleSetCampaignStatusWithUnfilledRows}
      />

      <SaveFormModal
        visible={showDraftModalConfirmation}
        onCancel={onSuccessfulModalClose}
        onOk={onModalDraftConfirmationSuccess}
        onClose={onModalDraftConfirmationClose}
        title="Сохранить кампанию перед закрытием?"
        btnTextOk="Да, сохранить"
        btnTextCancel="Нет, закрыть без сохранения"
      />

      <CreateSuccessfulModal
        visible={showSaved}
        title="Автомобили с оценкой отправлены в банк"
        description={`Ожидаемая дата получения расчета КП: ${kpCalculationTime}`}
        onCancel={handleCloseModal}
      />

      {exportOfferError && (
        <CreateErrorModal
          onCancel={() => setShowExportModal(false)}
          visible={showExportModal}
          title="Ошибка"
          description="Ошибка сервера выгрузка excel-файла невозможна, попробуйте позже"
        />
      )}

      {importOfferError && (
        <CreateErrorModal
          onCancel={() => setShowImportModal(false)}
          visible={showImportModal}
          title="Ошибка"
          left
          description={getErrorsRow(importOfferError)}
        />
      )}

      {importOfferIsLoaded && (
        <SaveFormModalOk
          visible={showImportModal}
          title="Успешно"
          description="Данные успешно загружены из Excel-файла"
          onCancel={() => setShowImportModal(false)}
          onOk={() => setShowImportModal(false)}
          btnTextOk="ОК"
        />
      )}
    </>
  );
}

export default connect(
  (state) => ({
    // offers
    list: state.tcmCampaignOffer.collection.list,
    isLoading: state.tcmCampaignOffer.collection.isLoading,
    isLoaded: state.tcmCampaignOffer.collection.isLoaded,
    error: state.tcmCampaignOffer.collection.error,

    evaluationData: state.tcmOffer.evaluation.data,
    evaluationIsLoading: state.tcmOffer.evaluation.isLoading,
    evaluationIsLoaded: state.tcmOffer.evaluation.isLoaded,
    evaluationError: state.tcmOffer.evaluation.error,
    // campaign

    campaign: state.tcmCampaign.campaign.data,
    campaignsSet: state.tcmCampaign.campaignsSet.data,
    campaignsSetIsLoading: state.tcmCampaign.campaignsSet.isLoading,
    campaignsSetIsLoaded: state.tcmCampaign.campaignsSet.isLoaded,
    campaignsSetError: state.tcmCampaign.campaignsSet.error,

    exportOfferData: state.tcmOffer.export.data,
    exportOfferIsLoading: state.tcmOffer.export.isLoading,
    exportOfferIsLoaded: state.tcmOffer.export.isLoaded,
    exportOfferError: state.tcmOffer.export.error,

    importOfferIsLoading: state.tcmOffer.import.isLoading,
    importOfferIsLoaded: state.tcmOffer.import.isLoaded,
    importOfferError: state.tcmOffer.import.error,
  }),
  {
    fetchList: fetchListAction,
    setEvaluation: setEvaluationAction,
    setCampaignStatus: setCampaignStatusAction,
    resetSetEvaluation: resetSetEvaluationAction,
    resetSetCampaignStatus: resetSetCampaignStatusAction,
    offerExport: offerExportAction,
    offerImport: offerImportAction,
    offerExportImportReset: offerExportImportResetAction,
    resetCampaign: resetCampaignAction,
  },
)(Offer);
