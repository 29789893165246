export const formFields = {
  creditProgram: {
    key: 'creditProgram',
    apiKey: 'creditProgramId',
    label: 'Программа',
    placeholder: 'Выберите программу',
  },

  // slider
  termOfCredit: {
    key: 'termOfCredit',
    apiKey: 'termOfCredit',
    label: 'Срок кредита ',
  },
  firstPaymentAmount: {
    key: 'firstPaymentAmount',
    apiKey: 'firstPaymentAmount',
    label: 'Первоначальный взнос',
  },
  monthlyPaymentAmount: {
    key: 'monthlyPaymentAmount',
    apiKey: 'monthlyPaymentAmount',
    label: 'Ежемесячный платеж',
  },
  deferredPaymentAmount: {
    key: 'deferredPaymentAmount',
    apiKey: 'deferredPaymentAmount',
    label: 'Остаточный платеж',
  },

  brand: {
    key: 'brand',
    apiKey: 'brands',
    label: 'Марка',
    placeholder: 'Выберите марку',
  },
  model: {
    key: 'model',
    apiKey: 'models',
    label: 'Модель',
    placeholder: 'Выберите модель',
  },

  creditAmount: {
    key: 'creditAmount',
    apiKey: 'creditAmount',
    label: 'Сумма кредита',
  },
  vehiclePrice: {
    key: 'vehiclePrice',
    apiKey: 'vehiclePrice',
    label: 'Цена нового автомобиля',
  },
  additionalServicesAmount: {
    key: 'additionalServicesAmount',
    apiKey: 'additionalServicesAmount',
    label: 'Доп. сервисы',
  },
};
