import styled from 'styled-components';

export const Root = styled.div`
  height: 100%;
  margin: auto ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Description = styled.p`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0;
  padding: 2rem;
  font-weight: bold;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
