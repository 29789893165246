import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import List from './List';
import Company from './Company';
import Create from './Create';

function Index() {
  return (
    <Switch>
      <Route exact path="/app/management/companies">
        <Redirect to="/app/management/companies/list" />
      </Route>
      <Route path="/app/management/companies/list" component={List} />
      <Route path="/app/management/companies/new" component={Create} />
      <Route path="/app/management/companies/:companyId" component={Company} />
    </Switch>
  );
}

export default Index;
