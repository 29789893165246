import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FIXBLOCK_TYPES, FIXBLOCK_TYPES as TYPES } from 'settings';

import { LoadingOutlined } from '@ant-design/icons';

import { fetch as fetchRequest } from 'redux/request/actions';

import Switch from './Switch';

import {
  Centered, Content, Error, ErrorMessage, Message, Root,
} from './style';

function DocumentPanel({
  // passed
  id,
  form,
  onChangeField,

  // connect
  requestTree,
  requestIsLoading,
  requestIsLoaded,
  requestError,

  patchIsLoading,
  saveIsLoading,

  // actions
  fetchRequest,
}) {
  useEffect(() => {
    fetchRequest({ accessToken: id });
  }, [fetchRequest, id]);

  const fixblock = requestTree.find((e) => e.type === 'fixblock');
  const isFromDealership = requestTree.find((e) => e.name === 'isFromDealership');

  const fixBlockHeader = requestIsLoaded
    ? fixblock.data.find((e) => e.name === 'zeroFormBlockHeader')
    : {};

  const disabled = patchIsLoading || saveIsLoading || requestIsLoading;

  const list = requestIsLoaded
    ? [
      {
        name: 'firstRow',
        type: 'row',
        data: [
          fixBlockHeader.data.find((e) => e.name === 'zeroName'),
          fixBlockHeader.data.find((e) => e.name === 'zeroPhone'),
          fixBlockHeader.data.find((e) => e.name === 'zeroNumber'),
        ],
      },
      {
        name: 'secondRow',
        type: 'row',
        data: [
          fixBlockHeader.data.find((e) => e.name === 'zeroStatus'),
          {
            name: 'buttonGroup',
            type: TYPES.BUTTON_GROUP,
            data: [
              {
                ...fixBlockHeader.data.find((e) => e.name === 'toStatus4'),
                accessToken: id,
                confirm: true,
              },
              {
                ...fixBlockHeader.data.find((e) => e.name === 'toStatus5'),
                accessToken: id,
              },
              {
                ...fixBlockHeader.data.find((e) => e.name === 'status6CausesForRefusalList'),
                accessToken: id,
                form,
                onChangeField,
                ...(disabled ? { disabled } : {}),
              },
              {
                ...fixBlockHeader.data.find((e) => e.name === 'toStatus6'),
                accessToken: id,
                confirm: false,
                form,
                ...(disabled ? { disabled } : {}),
              },
              ...([isFromDealership && isFromDealership.value ? {
                ...isFromDealership,
                name: 'isFromDealership',
                type: FIXBLOCK_TYPES.REVISION,
                accessToken: id,
              } : {}]),
            ],
          },
        ],
      },
    ]
    : [];

  return (
    <Root>
      {requestIsLoading && (
        <Centered>
          <LoadingOutlined />
          {' '}
          <Message>Данные заявки загружаются...</Message>
        </Centered>
      )}
      {requestIsLoaded && <Content>{list.map((field) => Switch({ field }))}</Content>}
      {requestError && (
        <Error>
          Ошибка:
          <ErrorMessage>{requestError}</ErrorMessage>
        </Error>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    requestTree: state.request.fieldsFlat,
    requestIsLoading: state.request.fetch.isLoading,
    requestIsLoaded: state.request.fetch.isLoaded,
    requestError: state.request.fetch.error,
    requestPopups: state.request.popups,

    patchIsLoading: state.request.patch.isLoading,
    saveIsLoading: state.request.save.isLoading,
  }),
  {
    fetchRequest,
  },
)(DocumentPanel);
