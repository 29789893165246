import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Popover } from 'antd';
import { Search } from 'components/Input';
import { useForm } from 'hooks';
import Button from 'components/Button';
import TableHeader, { FILTER_TYPES } from 'components/TableHeader';
import { initialFilters } from 'redux/payment/reducer';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_WITH_TIME } from 'settings';
import moment from 'moment';
import { MailOutlined, PhoneOutlined, LinkOutlined } from '@ant-design/icons';

import {
  fetchList as fetchListAction,
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
} from 'redux/payment/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import { checkPrivilege } from 'helpers/roles';

import {
  Root,
  Header,
  HeaderTitle,
  HeaderSearch,
  Title,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
  RepeatContainer,
  Status,
  RequisitesItem,
} from './style';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 460px)' };

const STATUS = [
  { label: 'Ожидает оплаты', value: 'Ожидает оплаты' },
  { label: 'Зарезервирован', value: 'Зарезервирован' },
  { label: 'Подтвержден', value: 'Подтвержден' },
  { label: 'Отменен', value: 'Отменен' },
  { label: 'Возврат', value: 'Возврат' },
  { label: 'Частичный возврат', value: 'Частичный возврат' },
  { label: 'Ошибка', value: 'Ошибка' },
  { label: 'Просроченный', value: 'Просроченный' },
  { label: 'Аннулированный', value: 'Аннулированный' },
];

const RETURN_STATUS = 'Возврат';
const AWAITING_PAIMANT_STATUS = 'Ожидает оплаты';

const renderRequisites = ({
  firstSendType, email, phone, url,
}) => {
  switch (firstSendType) {
    case 'email':
      return (
        <RequisitesItem>
          <MailOutlined />
          {' '}
          {email}
        </RequisitesItem>
      );

    case 'sms':
      return (
        <RequisitesItem>
          <PhoneOutlined />
          {' '}
          {phone}
        </RequisitesItem>
      );

    case 'link':
      return (
        <RequisitesItem>
          <LinkOutlined />
          {' '}
          <Popover content={url}>
            Ссылка
          </Popover>
        </RequisitesItem>
      );

    default:
      return (
        <RequisitesItem>
          <MailOutlined />
          {' '}
          {email}
        </RequisitesItem>
      );
  }
};

function List({
  // connect
  role,

  list,
  search,
  isLoading,
  error,
  pagination,
  sorting,

  // actions
  setTablePage,
  setSorting,
  setFilters,
  setSearch,
  resetTable,
  setBreadcrumbs,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Эквайринг', link: '/app/payments' },
        { level: 1, name: 'Счета', link: '/app/payments/bills/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination.page]);

  useEffect(() => {
    setFilters({ filters: form });
    return () => resetTable();
  }, [form, resetTable, setFilters]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  function handleOnSearch(v) {
    setSearch({ search: v });
  }

  function handleOnBlur() {
    if (search !== value) {
      setSearch({ search: value });
    }
  }

  const COLUMNS = [
    {
      key: 'requisites',
      title: () =>
        TableHeader({
          title: 'Реквизиты',
        }),
      render: ({
        firstSendType, email, phone, url, paymentId,
      }) => (
        <Link to={`/app/payments/bills/${paymentId}`}>
          {renderRequisites({
            firstSendType, email, phone, url,
          })}
        </Link>
      ),
      width: 200,
    },
    {
      key: 'number',
      title: () =>
        TableHeader({
          title: 'Номер заказа',
          sortBy: 'number',
          sorting: {},
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'number',
          filterValue: form.number,
          filterOnChange: updateForm,
        }),
      render: ({ paymentId, number }) => (
        <Link to={`/app/payments/bills/${paymentId}`}>{number}</Link>
      ),
      width: 180,
    },
    {
      key: 'data',
      title: () =>
        TableHeader({
          title: 'Дата',
          sortBy: 'data',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.PERIOD,
          filterName: ['dataFrom', 'dataTo'],
          filterValue: [form.dataFrom, form.dataTo],
          filterOnChange: updateForm,
        }),
      render: ({ data, paymentId }) =>
        data
        && typeof data === 'string' && (
          <Link to={`/app/payments/bills/${paymentId}`}>
            {moment.utc(data).format(DATE_FORMAT_WITH_TIME)}
          </Link>
        ),
      width: 200,
      align: 'right',
    },
    {
      key: 'registerSum',
      title: () =>
        TableHeader({
          title: 'Сумма',
          sortBy: 'registerSum',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.RANGE,
          filterName: ['registerSumFrom', 'registerSumTo'],
          filterValue: [form.registerSumFrom, form.registerSumTo],
          filterOnChange: updateForm,
          filterFormat: 'currency',
        }),
      render: ({ registerSum, paymentId }) => (
        <Link to={`/app/payments/bills/${paymentId}`}>
          <span>{registerSum}</span>
        </Link>
      ),
      width: 260,
    },
    {
      key: 'actualSum',
      title: () =>
        TableHeader({
          title: 'Сумма актуальная',
          sortBy: 'actualSum',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.RANGE,
          filterName: ['actualSumFrom', 'actualSumTo'],
          filterValue: [form.actualSumFrom, form.actualSumTo],
          filterOnChange: updateForm,
          filterFormat: 'currency',
        }),
      render: ({ actualSum, paymentId }) => (
        <Link to={`/app/payments/bills/${paymentId}`}>
          <span>{actualSum}</span>
        </Link>
      ),
      width: 260,
    },
    {
      key: 'name',
      title: () =>
        TableHeader({
          title: 'ФИО',
          sortBy: 'name',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'name',
          filterValue: form.name,
          filterOnChange: updateForm,
        }),
      render: ({ name, paymentId }) => (
        <Link to={`/app/payments/bills/${paymentId}`}>{name}</Link>
      ),
      width: 240,
    },
    {
      key: 'status',
      title: () =>
        TableHeader({
          title: 'Статус',
          sortBy: 'status',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'status',
          filterValue: form.status,
          filterOptions: STATUS,
          filterIsLoading: false,
          filterOnChange: updateForm,
        }),
      render: ({
        status, paymentId, expiredAt,
      }) => (
        <Link to={`/app/payments/bills/${paymentId}`}>
          <Status status={status}>
            {AWAITING_PAIMANT_STATUS !== status ? (
              status
            ) : (
              <>
                <div>
                  {status}
                  {' '}
                  до
                </div>
                {expiredAt
                      && typeof expiredAt === 'string'
                      && moment(expiredAt).format(DATE_FORMAT_WITH_TIME)}

              </>
            )}
          </Status>
        </Link>
      ),
      width: 220,
    },
    {
      key: 'repeat',
      title: () =>
        TableHeader({
          title: '',
        }),
      render: ({ paymentId, status }) =>
        status !== RETURN_STATUS && (
          <Link to={`/app/payments/bills/${paymentId}/repeat`}>
            <RepeatContainer>
              <Button
                type="ghost"
                size="mini"
                shape="square"
              >
                Повторить
              </Button>
            </RepeatContainer>
          </Link>
        ),
      width: 170,
    },
  ];

  const columns = checkPrivilege(role.PAYMENTS.CREATE_BILLS)
    ? COLUMNS
    : COLUMNS.filter((e) => e.key !== 'repeat');

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>История счетов</Title>
          {checkPrivilege(role.PAYMENTS.CREATE_BILLS) && (
            <Button size="mini" type="link" shape="square" href="/app/payments/bills/new">
              Создать счет
            </Button>
          )}
        </HeaderTitle>
        <HeaderSearch>
          <Search
            allowClear
            placeholder="Поиск..."
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            onSearch={handleOnSearch}
            onBlur={handleOnBlur}
          />
        </HeaderSearch>
      </Header>

      <Table
        rowKey={({ id }) => id}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,

    list: state.payment.collection.list,
    sorting: state.payment.collection.sorting,
    filters: state.payment.collection.filters,
    search: state.payment.collection.search,
    isLoaded: state.payment.collection.isLoaded,
    isLoading: state.payment.collection.isLoading,
    error: state.payment.collection.error,
    pagination: state.payment.collection.pagination || {},
  }),
  {
    fetchList: fetchListAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
