export const KEYS = {
  COMPANY: {
    key: 'COMPANY',
    apiKey: 'merchantId',
    title: 'Организация',
    rules: [
      {
        required: true,
        message: 'Выберите существующую организацию',
      },
    ],
  },
  DEALER_CENTER: {
    key: 'DEALER_CENTER',
    apiKey: 'dealerCenterId',
    title: 'Точка продаж',
    rules: [
      {
        required: true,
        message: 'Выберите точку продаж из списка',
      },
    ],
  },
  ROLE: {
    key: 'ROLE',
    apiKey: 'roleId',
    title: 'Роль',
    rules: [
      {
        required: true,
        message: 'Выберите существующую роль',
      },
    ],
  },
};
