import React from 'react';

import {
  Route,
  Redirect,
  Switch,
  useParams,
} from 'react-router-dom';

import List from './List';
import Item from './Item';

function DealerCenters() {
  const { companyId } = useParams();

  return (
    <Switch>
      <Route exact path="/app/management/companies/:companyId/dealerCenters">
        <Redirect to={`/app/management/companies/${companyId}/dealerCenters/list`} />
      </Route>

      <Route path="/app/management/companies/:companyId/dealerCenters/list" component={List} />
      {/* <Route path="/app/management/dealerCenters/new" component={Item} /> */}
      <Route path="/app/management/companies/:companyId/dealerCenters/:dealerCenterId" component={Item} />
      {/* <Route path="/app/management/dealerCenters/:dealerCenterId/edit" component={Item} /> */}
    </Switch>
  );
}

export default DealerCenters;
