export const KEYS = {
  COMMENT: {
    key: 'COMMENT',
    apiKey: 'comment',
    title: 'Комментарий',
    rules: [
      {
        required: true,
        message: 'Комментарий не может быть пустым',
      },
    ],
  },
};
