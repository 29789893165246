/*eslint-disable*/
export const initialState = {
  campaigns: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
		  query: '',
  },
  employees: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
		  query: '',
  },
};

export const ACTIONS = {
  FETCH_CAMPAIGNS: 'FETCH_CAMPAIGNS',
  FETCH_CAMPAIGNS_SUCCESS: 'FETCH_CAMPAIGNS_SUCCESS',
  FETCH_CAMPAIGNS_FAIL: 'FETCH_CAMPAIGNS_FAIL',
  FETCH_CAMPAIGNS_RESET: 'FETCH_CAMPAIGNS_RESET',

  FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
  FETCH_EMPLOYEES_SUCCESS: 'FETCH_EMPLOYEES_SUCCESS',
  FETCH_EMPLOYEES_FAIL: 'FETCH_EMPLOYEES_FAIL',
  FETCH_EMPLOYEES_RESET: 'FETCH_EMPLOYEES_RESET',
};

export function reducer(state, action) {
  switch (action.type) {
		  case ACTIONS.FETCH_CAMPAIGNS_RESET: {
				  return {
						  ...state,
						  campaigns: {
								  ...initialState.campaigns,
						  },
				  };
		  }
    case ACTIONS.FETCH_CAMPAIGNS: {
      return {
        ...state,
        campaigns: {
        		...initialState.campaigns,
		        isLoading: true,
		        query: action.query,
        },
      };
    }
    case ACTIONS.FETCH_CAMPAIGNS_SUCCESS: {
				  return {
						  ...state,
						  campaigns: {
								  ...state.campaigns,
								  list: action.list,
								  isLoading: false,
								  isLoaded: true,
						  },
				  };
		  }
		  case ACTIONS.FETCH_CAMPAIGNS_FAIL: {
				  return {
						  ...state,
						  campaigns: {
								  ...state.campaigns,
								  isLoading: false,
								  error: action.error,
						  },
				  };
		  }

		  case ACTIONS.FETCH_EMPLOYEES_RESET: {
				  return {
						  ...state,
						  employees: {
								  ...initialState.employees,
						  },
				  };
		  }
		  case ACTIONS.FETCH_EMPLOYEES: {
				  return {
						  ...state,
						  employees: {
								  ...initialState.employees,
								  isLoading: true,
								  query: action.query,
						  },
				  };
		  }
		  case ACTIONS.FETCH_EMPLOYEES_SUCCESS: {
				  return {
						  ...state,
						  employees: {
								  ...state.employees,
								  list: action.list,
								  isLoading: false,
								  isLoaded: true,
						  },
				  };
		  }
		  case ACTIONS.FETCH_EMPLOYEES_FAIL: {
				  return {
						  ...state,
						  employees: {
								  ...state.employees,
								  isLoading: false,
								  error: action.error,
						  },
				  };
		  }
    default: {
      return state;
    }
  }
}
