import React, { useCallback, useEffect, useState } from 'react';
import { TCM } from 'components/tcm';
import { Radio, Form } from 'antd';
import moment from 'moment';
import { MainFieldsCodes, KEYS } from 'containers/App/TCM/ClientCard/CallForm/data';
import { CallStatusDescriptionsCode, getCallStatusDescriptions } from 'tcm/offer/data';
import { PositiveResults } from 'containers/App/TCM/ClientCard/CallForm/PositiveResults';
import {
  RadioFieldset,
  FormRow,
  Container,
  Block,
  FormBtns,
  FormStaticDate,
  ResultsLabel,
} from 'containers/App/TCM/ClientCard/CallForm/style';
import { NegativeResults } from 'containers/App/TCM/ClientCard/CallForm/NegativeResults';
import { connect } from 'react-redux';
import {
  postCallResult as postCallResultAction,
  resetCallResult as resetCallResultAction,
} from 'redux/tcmCallResult/actions';
import Error from 'components/Fields/Error';
import { AddComment } from 'containers/App/TCM/Comments/Comments';

import { fetchStatuses as fetchStatusesAction } from 'redux/tcmOffer/actions';

const ResultType = {
  Positive: 1,
  Negative: 0,
};

const OutDateFormats = {
  Date: 'YYYY-MM-DD',
  Time: 'HH:mm:ss',
};

const initialValues = {
  [KEYS.CREATE_DATE.key]: moment(),
  [KEYS.CREATE_TIME.key]: moment().format('HH:mm'),
  [KEYS.STATUS.options.recall.children.recallDate.key]: moment(),
  [KEYS.STATUS.options.recall.children.recallTime.key]: moment().format('HH:mm'),
  [KEYS.STATUS.options.notOwner.children.callDate.key]: moment(),
  [KEYS.STATUS.options.notOwner.children.callTime.key]: moment().format('HH:mm'),
  [KEYS.STATUS.options.notInterested.children.cause.children.why.key]:
    KEYS.STATUS.options.notInterested.children.cause.children.why.options[0].value,
  [KEYS.STATUS.options.notInterested.children.cause.children.date.key]: moment(),

  [KEYS.STATUS.options.negativeRecall.children.recallDate.key]: moment(),
  [KEYS.STATUS.options.negativeRecall.children.recallTime.key]: moment().format('HH:mm'),
};

const CallForm = ({
  clientGuid,
  onCancel,

  isLoading,
  isLoaded,
  error,
  // errors,

  postCallResult,
  resetCallResult,
  fetchStatuses,
}) => {
  const [resultType, setResultType] = useState(ResultType.Positive);
  const [result, setResult] = useState(undefined);
  const [isCommentValid, setIsCommentValid] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);

  const [form] = Form.useForm();

  const onFinish = useCallback((values) => {
    const statusDescriptions = getCallStatusDescriptions(values, OutDateFormats.Date);

    const date = [
      values[MainFieldsCodes.Date].format(OutDateFormats.Date),
      values[MainFieldsCodes.Time],
    ].join(' ');

    const formData = {
      externalId: clientGuid,
      status: values[MainFieldsCodes.Status],
      statusDescriptions,
      date,
      text: values[MainFieldsCodes.Comment],
    };
    postCallResult({ formData });
  }, [clientGuid, postCallResult]);

  const onReset = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleValuesChange = (field) => {
    const [key] = Object.entries(field)[0];
    if (key === 'REASON') {
      form.setFieldsValue({
        [CallStatusDescriptionsCode.Model]: undefined,
      });
    }
  };

  useEffect(() => {
    if (isLoaded) {
      onCancel();
      fetchStatuses();
    }
  }, [isLoaded, onCancel, fetchStatuses]);

  useEffect(() => () => resetCallResult(), [resetCallResult]);

  useEffect(() => {
    setIsSubmitDisable(!(result && isCommentValid));
  }, [isCommentValid, result]);

  return (
    <Form
      form={form}
      name="call"
      layout="vertical"
      initialValues={initialValues}
      validateTrigger="onSubmit"
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
    >
      <FormRow>
        <TCM.Label
          label={KEYS.CREATE_DATE.label}
          name={KEYS.CREATE_DATE.key}
          rules={KEYS.CREATE_DATE.rules}
          validateTrigger="onBlur"
        >
          <FormStaticDate name={KEYS.CREATE_DATE.key} allowClear={false} />
        </TCM.Label>
        <TCM.Label
          label={KEYS.CREATE_TIME.label}
          name={KEYS.CREATE_TIME.key}
          rules={KEYS.CREATE_TIME.rules}
          validateTrigger="onBlur"
        >
          <TCM.TimePicker name={KEYS.CREATE_TIME.key} />
        </TCM.Label>
      </FormRow>
      <Container>
        <ResultsLabel>Результат звонка:</ResultsLabel>
        <TCM.RadioGroup
          value={resultType}
          onChange={(e) => {
            form.resetFields(['STATUS']);
            setResult(undefined);
            setResultType(e.target.value);
          }}
        >
          <Radio.Button value={ResultType.Positive}>Дозвонился</Radio.Button>
          <Radio.Button value={ResultType.Negative}>Не дозвонился</Radio.Button>
        </TCM.RadioGroup>
      </Container>
      <RadioFieldset>
        { resultType === ResultType.Positive && (
          <PositiveResults result={result} setResult={setResult} />
        )}
        { resultType === ResultType.Negative && (
          <NegativeResults result={result} setResult={setResult} />
        )}
      </RadioFieldset>

      <Block>
        <AddComment
          title="Комментарий к звонку:"
          formItemProps={{
            name: KEYS.COMMENT.key,
            rules: KEYS.COMMENT.rules,
            validateTrigger: 'onBlur',
          }}
          fieldProps={{
            name: KEYS.COMMENT.key,
            placeholder: KEYS.COMMENT.placeholder,
            onChange: (event) => {
              setIsCommentValid(Boolean(event.currentTarget.value.length));
            },
          }}
        />
        <FormBtns>
          <Form.Item shouldUpdate>
            {() => (
              <TCM.Button
                htmlType="submit"
                loading={isLoading}
                disabled={isSubmitDisable}
              >
                Сохранить результат звонка
              </TCM.Button>
            )}
          </Form.Item>
          <Form.Item>
            <TCM.ButtonOutline
              htmlType="button"
              onClick={onReset}
            >
              Отмена
            </TCM.ButtonOutline>
          </Form.Item>
        </FormBtns>
        {Boolean(error) && (
          <Error>{error}</Error>
        ) }
      </Block>
    </Form>
  );
};

const ConnectReduxWithForm = connect(
  (state) => ({
    isLoading: state.tcmCallResults.form.isLoading,
    isLoaded: state.tcmCallResults.form.isLoaded,
    error: state.tcmCallResults.form.error,
    errors: state.tcmCallResults.form.errors,
  }),
  {
    postCallResult: postCallResultAction,
    resetCallResult: resetCallResultAction,
    fetchStatuses: fetchStatusesAction,
  },
)(CallForm);

export { ConnectReduxWithForm as CallForm };
