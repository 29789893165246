import styled from 'styled-components';
import { Form } from 'antd';

export const Main = styled(Form)`
  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & .ant-checkbox-wrapper {
    color: rgba(0, 0, 0, 0.85);
  }

  & .ant-form-item {
    margin-bottom: 7px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  grid-auto-columns: 1fr;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SuccessMessage = styled.div`
  color: #8c8c8c;
  margin: 0 0 10px;
`;
