import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import MaskedInput from 'antd-mask-input';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { SCROLL_DURATION } from 'settings';
import { parseDigits } from 'utils';
import { mergeArrays } from 'hacks';

const formatCharacters = {
  A: {
    validate: (char) => /[\da-zа-яё]/i.test(char),
  },
};

function InputField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder: placeholderValue = '',
  area,
  mask: maskValue = '',
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, setFieldsValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
  dataSource,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();
  const [value, setValue] = useState(String(initialValue || ''));
  const mask = String(maskValue);
  const isMaskContainNonEditableParts = /^[1\s]{1,}\s[^\d]{1,}$/g.test(mask);
  // const isMaskContainOnlyDigits = /^[0-9]*$/gi.test(mask);

  const placeholder = (
    isMaskContainNonEditableParts
      ? (
        value.replace(/[^\d]/g, '').length + 1 === mask.replace(/[^\d]/g, '').length
          ? mask
          : mask.slice(1)
      )
      : mask.replace(/[1A]/gi, '_')
  ) || placeholderValue || '';

  useEffect(() => {
    const value = typeof initialValue === 'string'
      ? initialValue
      : typeof initialValue === 'number'
        ? String(initialValue)
        : '';
    setValue(value);
    setFieldsValue({ [name]: value });
  }, [initialValue, name, setValue, setFieldsValue]);

  function handleOnFocus() {
    const caret = isMaskContainNonEditableParts
      ? value.lastIndexOf((value.match(/\d/g) || []).slice(-1)[0]) + 1
      : value.indexOf('_') === -1 ? value.length : value.indexOf('_');
    const focusNode = focusableNode.current;
    if (focusNode) {
      const inputNode = focusableNode.current && focusableNode.current.input;
      if (inputNode) {
        setTimeout(() => {
          inputNode.setSelectionRange(caret, caret);
          inputNode.focus();
        }, 10);
      }
    }
  }

  function handleOnBlur() {
    if ((initialValue || '') !== value) {
      if (isMaskContainNonEditableParts) {
        const onlyDigits = parseDigits(value);
        if (onlyDigits.length === 0) {
          onChangeField({}, [...mergeArrays(dependents, ifChangeClearFields), name]);
        } else {
          onChangeField(
            { [name]: Number(onlyDigits) },
            mergeArrays(dependents, ifChangeClearFields),
          );
        }
      } else {
        const formattedValue = String(value).replace(/_/g, '');

        if (formattedValue.length === 0) {
          onChangeField({}, [...mergeArrays(dependents, ifChangeClearFields), name]);
        } else {
          onChangeField(
            { [name]: formattedValue },
            mergeArrays(dependents, ifChangeClearFields),
          );
        }
      }
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  function getValueFromEvent(e) {
    const v = (e && e.target && e.target.value) || '';
    setValue(v);
    return v;
  }

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: value,
        getValueFromEvent,
      })(
        <MaskedInput
          name={name}
          placeholder={placeholder}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={focusableNode}
          area={area}
          size={size}
          mask={mask}
          formatCharacters={formatCharacters}
          suffix={(initialValue === value) && (dataSource === 'digital_profile') ? 'DP' : ' '}
        />,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default InputField;
