import styled from 'styled-components';

const COLOR_STATUS = {
  Возврат: '#E71313',
  Просроченный: '#E71313',
  'Частичный возврат': '#E71313',
  Подтвержден: '#7B7B7B',
  'Ожидает оплаты': '#2E82FF',
  Зарезервирован: '#2E82FF',
};

const COLOR_METHOD = {
  arm: '#2E82FF',
  api: '#7B7B7B',
};

export const Root = styled.div`
  padding: 0 4rem 0;

  a {
    color: #7b7b7b;
    font-size: 16px;
  }
`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const Status = styled.div`
  color: ${(props) =>
    (COLOR_STATUS[props.status] ? COLOR_STATUS[props.status] : '#7B7B7B')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Method = styled.div`
  color: ${(props) =>
    (COLOR_METHOD[props.method] ? COLOR_METHOD[props.method] : '#7B7B7B')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const Footer = styled.footer`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 1rem;
  padding: 1rem 0;
  position: absolute;
  bottom: 0
`;
