import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const createMerchant = ({
  name, inn, admin, users = [],
}) => {
  const URI = `${API}/admin/legal-entity/create`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        name,
        inn,
        adminUser: admin,
        ...(Array.isArray(users) ? { users } : {}),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  createMerchant,
};
