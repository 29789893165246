import {
  CAMPAIGN_OFFER_FETCH_LIST,
  CAMPAIGN_OFFER_FETCH_LIST__SUCCESS,
  CAMPAIGN_OFFER_FETCH_LIST__FAILURE,
  CAMPAIGN_OFFER_FETCH_LIST__SET_LOADING,
  CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION,
  CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH,
  CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING,
  CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER,
  CAMPAIGN_OFFER_FETCH_LIST__RESET,
  CAMPAIGN__OFFER_FETCH_LIST__RESET_FILTER,

  // filter options
  CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS,
  CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__SUCCESS,
  CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__FAILURE,

  CAMPAIGN_OFFER_FETCH_STATUSES,
  CAMPAIGN_OFFER_FETCH_STATUSES__SUCCESS,
  CAMPAIGN_OFFER_FETCH_STATUSES__FAILURE,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

const changeSorting = (currentValue, sortBy) => {
  if (currentValue.sortBy !== sortBy) {
    return {
      sortBy,
      order: ORDER.DESC,
    };
  } if (currentValue.order === ORDER.ASC) {
    return defaultSorting;
  }
  return {
    sortBy: currentValue.sortBy,
    order: ORDER.ASC,
  };
};

const getTagsFromFilter = (filter) => {
  const models = filter.models
    ? filter.models?.map((t) => ({
      label: t.label,
      key: 'models',
    }))
    : [];

  const brands = filter.brands
    ? filter.brands?.map((t) => ({
      label: t.label,
      key: 'brands',
    }))
    : [];

  const suffixTMS = filter.suffixTMS
    ? filter.suffixTMS?.map((t) => ({
      label: t.label,
      key: 'suffixTMS',
    }))
    : [];

  const suffix = filter.suffix
    ? filter.suffix?.map((t) => ({
      label: t.label,
      key: 'suffix',
    }))
    : [];

  const employees = filter.employees
    ? filter.employees?.map((t) => ({
      label: t.label,
      key: 'employees',
    }))
    : [];

  const priceTradeInFrom = (filter.priceTradeInFrom || filter.priceTradeInFrom === 0)
    ? {
      label: `Цена Trade-In от ${filter.priceTradeInFrom}`,
      key: 'priceTradeInFrom',
    }
    : null;

  const priceTradeInTo = (filter.priceTradeInTo || filter.priceTradeInTo === 0)
    ? {
      label: `Цена Trade-In до ${filter.priceTradeInTo}`,
      key: 'priceTradeInTo',
    }
    : null;

  const priceNewCarFrom = (filter.priceNewCarFrom || filter.priceNewCarFrom === 0)
    ? {
      label: `Цена нового автомобиля от ${filter.priceNewCarFrom}`,
      key: 'priceNewCarFrom',
    }
    : null;

  const priceNewCarTo = (filter.priceNewCarTo || filter.priceNewCarTo === 0)
    ? {
      label: `Цена нового автомобиля до ${filter.priceNewCarTo}`,
      key: 'priceNewCarTo',
    }
    : null;

  return [
    ...models,
    ...brands,
    ...suffixTMS,
    ...suffix,
    ...employees,
    priceTradeInFrom,
    priceTradeInTo,
    priceNewCarFrom,
    priceNewCarTo,
  ].filter((tag) => (tag !== undefined && tag !== null));
};

export const defaultPageSize = 25;

/**
 * @type {{
 *  models: string[],
 *  brands: string[],
 *  suffixTMS: string[],
 *  employees: string[],
 *  priceTradeInFrom: number | undefined,
 *  priceTradeInTo: number | undefined
 *  priceNewCarFrom: number | undefined,
 *  priceNewCarTo: number | undefined,
 * }}
 */
export const initialFilters = {
  brands: [],
  models: [],
  suffixTMS: [],
  employees: [],
  tradeInFrom: undefined,
  tradeInTo: undefined,
  priceNewCarFrom: undefined,
  priceNewCarTo: undefined,
};

const initState = {
  collection: {
    id: null,
    data: {},
    list: [],
    pagination: {},
    sorting: defaultSorting,
    search: '',
    filters: {
      data: initialFilters,
      tags: [],
    },
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  statuses: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  filters: {
    brands: [],
    brandList: [{ name: null, models: [] }],
    models: [],
    suffixTMS: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function tcmCampaignOffer(state = initState, action) {
  switch (action.type) {
    case CAMPAIGN_OFFER_FETCH_STATUSES: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_OFFER_FETCH_STATUSES__SUCCESS: {
      return {
        ...state,
        statuses: {
          list: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CAMPAIGN_OFFER_FETCH_STATUSES__FAILURE: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },

        filters: {
          ...initState.filters,
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,
          id: action.id,
          isLoading: true,
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: changeSorting(state.collection.sorting, action.sortBy),
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: {
            data: action.filters,
            tags: getTagsFromFilter(action.filters),
          },
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_OFFER_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          pagination: action.pagination || {},
          page: (action.pagination && action.pagination.page) || 1,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
        filters: action.filters,
      };
    }
    case CAMPAIGN_OFFER_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CAMPAIGN__OFFER_FETCH_LIST__RESET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: {
            data: initialFilters,
            tags: [],
          },
        },
      };
    }

    case CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS: {
      return {
        ...state,

        filters: {
          ...initState.filters,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__SUCCESS: {
      const {
        brands = [],
        models = [],
        suffixTMS = [],
        brandList = [],
      } = action.filters;

      return {
        ...state,

        filters: {
          brands,
          models,
          suffixTMS,
          brandList,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__FAILURE: {
      return {
        ...state,

        filters: {
          ...state.filters,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
