import API from 'services';

import {
  extractErrorAndCode,
} from 'hacks';

import {
  all, takeLatest, put, fork, call, select, cancel, take,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  // list
  CREDIT_REQUEST_FETCH_LIST,
  CREDIT_REQUEST_FETCH_LIST__SUCCESS,
  CREDIT_REQUEST_FETCH_LIST__FAILURE,
  CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST,
  CREDIT_REQUEST_FETCH_LIST__SET_LOADING,
  CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION,
  CREDIT_REQUEST_FETCH_LIST__SET_SEARCH,
  CREDIT_REQUEST_FETCH_LIST__SET_PAGE,
  CREDIT_REQUEST_FETCH_LIST__SET_SORTING,
  CREDIT_REQUEST_FETCH_LIST__SET_FILTER,

  // item
  CREDIT_REQUEST__FETCH_ITEM,
  CREDIT_REQUEST__FETCH_ITEM_SUCCESS,
  CREDIT_REQUEST__FETCH_ITEM_FAILURE,

  // save document
  CREDIT_REQUEST__SAVE_DOCUMENT,
  CREDIT_REQUEST__SAVE_DOCUMENT_SUCCESS,
  CREDIT_REQUEST__SAVE_DOCUMENT_FAILURE,

  // remove document
  CREDIT_REQUEST__REMOVE_DOCUMENT,
  CREDIT_REQUEST__REMOVE_DOCUMENT_SUCCESS,
  CREDIT_REQUEST__REMOVE_DOCUMENT_FAILURE,

  // send SMS
  CREDIT_REQUEST__SEND_SMS,
  CREDIT_REQUEST__SEND_SMS_SUCCESS,
  CREDIT_REQUEST__SEND_SMS_FAILURE,

  // CREDIT_REQUEST__CREATE,
  // CREDIT_REQUEST__CREATE_SUCCESS,
  // CREDIT_REQUEST__CREATE_FAILURE,
  // CREDIT_REQUEST__EDIT,
  // CREDIT_REQUEST__EDIT_SUCCESS,
  // CREDIT_REQUEST__EDIT_FAILURE,

} from './actions';

const getState = (state) => state.creditRequest;

function* _fetchList({ withPagination }) {
  const {
    collection: {
      search, pagination, filters, sorting,
    },
  } = yield select(getState);

  try {
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__SET_LOADING });
    const response = yield call(API.creditRequest.fetchList, {
      search,
      pagination,
      filters,
      sorting,
      withPagination,
    });

    if (response.data && Array.isArray(response.data.signingApplicationListItemList)) {
      yield put({
        type: CREDIT_REQUEST_FETCH_LIST__SUCCESS,
        list: response.data.signingApplicationListItemList,
        pagination: response.pagination,
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync({ withPagination }) {
  const fetchSyncTask = yield fork(_fetchList, { withPagination });
  yield take(CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(CREDIT_REQUEST_FETCH_LIST, function* ({ withPagination }) {
    yield call(fetchSync, { withPagination });
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(CREDIT_REQUEST_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION, pagination: { page: 1 } });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(CREDIT_REQUEST_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION, pagination: action.pagination });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(CREDIT_REQUEST_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(CREDIT_REQUEST_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION, pagination: { page: 1 } });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CREDIT_REQUEST_FETCH_LIST });
  });
}

/* Item */
export function* fetchDetails() {
  yield takeLatest(CREDIT_REQUEST__FETCH_ITEM, function* (action) {
    try {
      const [details, documents] = yield all([
        call(API.creditRequest.getDetails, { creditToken: action.id }),
        call(API.creditRequest.getDocuments, { creditToken: action.id }),
      ]);

      if (!details.error && details.data && !details.errorCode
        && !documents.error && documents.data && !documents.errorCode) {
        yield put({
          type: CREDIT_REQUEST__FETCH_ITEM_SUCCESS,
          data: {
            ...details.data,
            documents: Array.isArray(documents.data?.fileSigningListItemList)
              ? documents.data.fileSigningListItemList
              : [],
          },
        });
      } else {
        const { error, code } = details.errorCode ? extractErrorAndCode(details) : extractErrorAndCode(documents);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT_REQUEST__FETCH_ITEM_FAILURE, error: error.message });
    }
  });
}

export function* saveDocument() {
  yield takeLatest(CREDIT_REQUEST__SAVE_DOCUMENT, function* (action) {
    try {
      const data = action.document;
      const response = yield call(API.creditRequest.saveDocument, { data });
      const documents = yield call(API.creditRequest.getDocuments, { creditToken: action.document.creditToken });

      if (!response.error && response.data && !response.errorCode
        && !documents.error && documents.data && !documents.errorCode) {
        yield put({
          type: CREDIT_REQUEST__SAVE_DOCUMENT_SUCCESS,
          documents: Array.isArray(documents.data?.fileSigningListItemList)
            ? documents.data.fileSigningListItemList
            : [],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT_REQUEST__SAVE_DOCUMENT_FAILURE, error: error.message });
    }
  });
}

export function* removeDocument() {
  yield takeLatest(CREDIT_REQUEST__REMOVE_DOCUMENT, function* (action) {
    try {
      const data = action.document;
      const response = yield call(API.creditRequest.removeDocument, {
        creditToken: data.creditToken,
        id: data.id,
      });
      const documents = yield call(API.creditRequest.getDocuments, { creditToken: action.document.creditToken });

      if (!response.error && response.data && !response.errorCode
        && !documents.error && documents.data && !documents.errorCode) {
        yield put({
          type: CREDIT_REQUEST__REMOVE_DOCUMENT_SUCCESS,
          documents: Array.isArray(documents.data?.fileSigningListItemList)
            ? documents.data.fileSigningListItemList
            : [],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT_REQUEST__REMOVE_DOCUMENT_FAILURE, error: error.message });
    }
  });
}

export function* sendSMS() {
  yield takeLatest(CREDIT_REQUEST__SEND_SMS, function* (action) {
    try {
      const { creditToken, resolve } = action.payload;
      const response = yield call(API.creditRequest.sendSMS, { creditToken });

      if (!response.error && response.data && !response.errorCode) {
        yield put({ type: CREDIT_REQUEST__SEND_SMS_SUCCESS });
        if (typeof resolve === 'function') resolve();
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CREDIT_REQUEST__SEND_SMS_FAILURE, error: error.message });
    }
  });
}

// function* create() {
//   yield takeLatest(CREDIT_REQUEST__CREATE, function* (action) {
//     const {
//       salePoint,
//       inn,
//       krifId,
//       acquiringID,
//       onlineId,
//       addressactual,
//       phone,
//       detail,
//     } = action;

//     try {
//       const response = yield call(API.dealerCenter.create, {
//         salePoint,
//         inn,
//         krifId,
//         acquiringID,
//         onlineId,
//         addressactual,
//         phone,
//         detail,
//       });

//       if (response
//         && response.data
//         && response.data.id) {
//         yield put({ type: CREDIT_REQUEST__CREATE_SUCCESS, data: response.data });
//       } else {
//         const { error, code } = extractErrorAndCode(response);
//         yield put({ type: ON_ERROR, errorCode: code });

//         if (
//           response
//           && response.data
//           && response.data.validationErrorList
//           && Array.isArray(response.data.validationErrorList)
//         ) {
//           yield put({
//             type: CREDIT_REQUEST__CREATE_FAILURE,
//             error,
//             errors: response.data.validationErrorList,
//           });
//         } else {
//           throw new Error(error);
//         }
//       }
//     } catch (error) {
//       yield put({ type: CREDIT_REQUEST__CREATE_FAILURE, error: error.message });
//     }
//   });
// }

// function* edit() {
//   yield takeLatest(CREDIT_REQUEST__EDIT, function* (action) {
//     const {
//       id,
//       salePoint,
//       inn,
//       krifId,
//       acquiringID,
//       onlineId,
//       addressactual,
//       phone,
//       detail,
//       bindSector,
//       unbindSector,
//     } = action;

//     try {
//       const response = yield call(API.dealerCenter.edit, {
//         id,
//         salePoint,
//         inn,
//         krifId,
//         acquiringID,
//         onlineId,
//         addressactual,
//         phone,
//         detail,
//         bindSector,
//         unbindSector,
//       });

//       if (response
//         && response.data
//         && response.data.id) {
//         yield put({
//           type: CREDIT_REQUEST__EDIT_SUCCESS,
//           data: response.data,
//         });
//       } else {
//         const { error, code } = extractErrorAndCode(response);
//         yield put({ type: ON_ERROR, errorCode: code });

//         if (
//           response
//           && response.data
//           && response.data.validationErrorList
//           && Array.isArray(response.data.validationErrorList)
//         ) {
//           yield put({
//             type: CREDIT_REQUEST__EDIT_FAILURE,
//             error,
//             errors: response.data.validationErrorList,
//           });
//         } else {
//           throw new Error(error);
//         }
//       }
//     } catch (error) {
//       yield put({ type: CREDIT_REQUEST__EDIT_FAILURE, error: error.message });
//     }
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(fetchDetails),
    fork(saveDocument),
    fork(removeDocument),
    fork(sendSMS),
    // fork(create),
    // fork(edit),
  ]);
}
