import React from 'react';
import { connect } from 'react-redux';
import { checkPrivilege } from 'helpers/roles';
import { RestrictedRoute, checkRedirectRoute } from 'router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Nav, Link } from 'components/Navigation';
import BreadCrumpbs from 'components/Breadcrumb';

import Companies from './Companies';
import PointsOfSale from './PointsOfSale';
import Users from './Users';

import { Root, Content } from './style';

const routes = [
  {
    path: '/app/management/companies',
    title: 'Организации',
    component: Companies,
    checkRights: (role) => checkPrivilege([
      role.ORGANISATIONS.VIEW__ALL,
    ]),
  },
  {
    path: '/app/management/dealerCenters',
    title: 'Точки продаж',
    component: PointsOfSale,
    checkRights: (role) => checkPrivilege(role.ORGANISATIONS.VIEW_POINTS_OF_SALES),
  },
  {
    path: '/app/management/users',
    title: 'Пользователи',
    component: Users,
    checkRights: (role) => checkPrivilege([
      role.MANAGEMENT.VIEW_USER,
      role.MANAGEMENT.VIEW_USER_INTERNAL,
    ]),
  },
];

function Index({
  // connect
  role,
}) {
  return (
    <Root>
      <Nav>
        {routes
          .filter((route) => route.checkRights(role))
          .map((route) => (
            <Link key={route.path} to={route.path}>
              {route.title}
            </Link>
          ))}
      </Nav>
      <BreadCrumpbs />
      <Content>
        <Switch>
          <Route exact path="/app/management">
            <Redirect to={checkRedirectRoute({ role, routes })} />
          </Route>

          {routes.map((route) => (
            <RestrictedRoute
              key={route.path}
              path={route.path}
              component={route.component}
              checkRights={route.checkRights}
            />
          ))}
        </Switch>
      </Content>
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
  }),
  null,
)(Index);
