import React, {
  useEffect, useState, forwardRef, useRef,
} from 'react';
import {
  Select as SelectAntD,
  Tag,
  Tooltip as ToolTipAntD,
} from 'antd';

import { SelectContainer } from './style';

const defaultProps = {
  allowClear: true,
  hasDefault: true,
};

const tagRender = (value, label, onClose, withTooltip) =>
  (
    withTooltip ? (
      <ToolTipAntD title={`${value}`}>
        {}
        <Tag closable onClose={onClose}>
          {label}
        </Tag>
      </ToolTipAntD>
    ) : (
      <Tag closable onClose={onClose}>
        {label}
      </Tag>
    )
  );

const Select = forwardRef((props, ref) => <SelectAntD ref={ref} {...defaultProps} {...props} />);

function SelectMultiple({
  name, value: valueFromProps, onChange, options = [], isLoading, hasDefault, optionValue,

}) {
  const [value, setValue] = useState(valueFromProps || []);
  const [list, setList] = useState(options);
  const selectEl = useRef(null);

  useEffect(() => {
    if (value === [] && list.length !== options.length) {
      setList(options);
    }
  }, [options, list, value]);

  function handleOnChange(v) {
    setValue(v);
    onChange({ [name]: v || [] });
  }

  return (
    <SelectContainer>
      <Select
        allowClear
        name={name}
        value={value}
        onChange={handleOnChange}
        onSelect={() => selectEl.current.blur()}
        loading={isLoading}
        mode="multiple"
        maxTagTextLength={10}
        ref={selectEl}
        optionFilterProp="label"
        tagRender={({ value, label, onClose }) =>
          tagRender(value, label, onClose, true)}
      >
        { hasDefault && (
          <SelectAntD.Option key="" value="">
            Не выбрано
          </SelectAntD.Option>
        )}
        {options.map((option) => (
          <SelectAntD.Option
            key={option.value}
            value={optionValue === 'label' ? option.label : option.value}
            label={option.label}
          >
            {option.label}
          </SelectAntD.Option>
        ))}
      </Select>
    </SelectContainer>
  );
}

export default SelectMultiple;
