import React from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';

export const Root = styled.div`
  position: relative;
`;

const CanvasStyled = styled.canvas`
  width: 100% !important;
  height: auto !important;
`;

export const Canvas = React.forwardRef((props, ref) => (
  <CanvasStyled {...props} ref={ref} />
));

export const PaginationStyled = styled(Pagination)`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  display: flex;
  justify-content: center;
`;
