import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import TabsStyled from 'components/Tabs';
import { SCROLL_DURATION, TYPES } from 'settings';
import { flattenFields } from 'utils';

const getFirstTab = (fields) => {
  const tabsWithError = fields.filter(
    (tab) => flattenFields(tab.data).filter((e) => e.error).length > 0,
  );
  return tabsWithError.length > 0 ? tabsWithError[0] : fields[0];
};

const getFirstField = (fields) => {
  const tab = getFirstTab(fields);
  const flattenedFields = flattenFields(tab.data).filter((e) => e.type !== TYPES.HIDDEN);
  const fieldsWithError = flattenedFields.filter((e) => e.error);

  return fieldsWithError.length > 0 ? fieldsWithError[0].name : '';
};

function Tabs({
  name,
  fields,
  children,

  fieldToScroll: { name: fieldToScrollName, tab: fieldToScrollTab } = {},
  onScrollCallback,
}) {
  const scrollableNode = useRef();
  const [tab, setTab] = useState(fields[0].name);

  function handleTabChange(t) {
    setTab(t);
  }

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setTimeout(() => {
        setTab(fieldToScrollTab || getFirstTab(fields).name);
        onScrollCallback(getFirstField(fields));
      }, SCROLL_DURATION * 3);
    }
  }, [scrollableNode, onScrollCallback, fields, fieldToScrollTab]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <div ref={scrollableNode}>
      <TabsStyled activeKey={tab} onChange={handleTabChange}>
        {children}
      </TabsStyled>
    </div>
  );
}

export default Tabs;
