import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import withTheme from 'components/tcm/withTheme';

const Title = withTheme(styled.h3`
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
  ${({ withBtn }) => !withBtn && css`margin-bottom: 36px`};
`);

const Header = withTheme(styled.div`
  display: flex;
  justify-content: space-between;
  ${({ withBtn }) => withBtn && css`margin-bottom: 30px`};
`);

const Container = withTheme(styled.section`
  margin-bottom: 20px;
  padding: ${({ withTitle }) => (withTitle ? 33 : 30)}px 40px 40px;
  background: ${({ theme }) => theme.palette.gray7};
  border-radius: 10px;
  
  &:last-child {
    margin-bottom: 0;
  }
`);

export const PageBlock = ({ title, btnInHeader, children }) => {
  const withTitle = useMemo(() => Boolean(title), [title]);
  const withBtn = useMemo(() => Boolean(btnInHeader), [btnInHeader]);
  return (
    <Container withTitle={withTitle}>
      {title && btnInHeader
        ? (
          <Header withBtn={withBtn}>
            <Title withBtn>{title}</Title>
            {btnInHeader}
          </Header>
        )
        : title
          ? <Title withBtn={false}>{title}</Title>
          : null}
      {children}
    </Container>
  );
};
