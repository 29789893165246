import {
  CRIF_SET_MORATORIUM,
  CRIF_BUTTON_UNLOCK,
  CRIF_RESULT_UNLOCK,
  CRIF_SEARCH_UNLOCK,
  CRIF_SET_LOADING,
  CRIF_RESET,
  CRIF_SET_PAGINATION,
  CRIF_SET_SORTING,
  CRIF_FETCH_LIST,
  CRIF_FETCH_LIST__SUCCESS,
  CRIF_FETCH_LIST__FAILURE,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'updateDate',
  order: ORDER.DESC,
};

export const defaultPageSize = 3;

const LOCK_STATES = {
  DEFAULT: {
    resultIsLocked: false,
    buttonIsLocked: false,
    statusIsLocked: false,
  },
  HAS_MORATORIUM: {
    resultIsLocked: true,
    buttonIsLocked: true,
    statusIsLocked: true,
  },
  SEARCH_RESULTS_EMPTY: {
    resultIsLocked: false,
    buttonIsLocked: true,
    statusIsLocked: false,
  },
  USER_WITHOUT_MORATORIUM: {
    resultIsLocked: false,
    buttonIsLocked: true,
    statusIsLocked: false,
  },
  SEARCH_RESULTS_SUCCESS: {
    resultIsLocked: false,
    buttonIsLocked: true,
    statusIsLocked: true,
  },
};

const getLockState = (list, currentUser, moratorium) => {
  if (moratorium) {
    return LOCK_STATES.HAS_MORATORIUM;
  }
  if (list.length === 0) {
    return LOCK_STATES.SEARCH_RESULTS_EMPTY;
  }
  if (currentUser && currentUser.crmId) {
    return LOCK_STATES.USER_WITHOUT_MORATORIUM;
  }
  return LOCK_STATES.SEARCH_RESULTS_SUCCESS;
};

const initState = {
  id: null,
  form: {},
  list: [],
  hasMoratorium: false,
  moratorium: {},
  ...LOCK_STATES.DEFAULT,
  isLoading: false,
  isLoaded: false,
  error: null,
  sorting: defaultSorting,
  page: 1,
};

export default function crifReducer(state = initState, action) {
  switch (action.type) {
    case CRIF_RESET: {
      return {
        ...initState,
      };
    }

    case CRIF_SET_MORATORIUM: {
      return {
        ...state,
        moratorium: action.currentUser || {},
        hasMoratorium: Boolean(action.moratorium),
        statusIsLocked: Boolean(action.moratorium),
        resultIsLocked:
          Boolean(action.moratorium)
          || action.dataHasChanged
          || action.currentUser.crmId
          || state.resultIsLocked,
        buttonIsLocked: !(action.dataHasChanged && action.searchAvailable),
      };
    }

    case CRIF_RESULT_UNLOCK: {
      return {
        ...state,
        resultIsLocked: false,
      };
    }
    case CRIF_BUTTON_UNLOCK: {
      return {
        ...state,
        buttonIsLocked: false,
        resultIsLocked: true,
      };
    }
    case CRIF_SEARCH_UNLOCK: {
      return {
        ...state,
        ...LOCK_STATES.DEFAULT,
      };
    }

    case CRIF_SET_PAGINATION: {
      return {
        ...state,
        page: action.page,
      };
    }
    case CRIF_SET_SORTING: {
      return {
        ...state,
        sorting: {
          sortBy: action.sortBy,
          order:
            state.sorting.sortBy === action.sortBy
              ? state.sorting.order === ORDER.ASC
                ? ORDER.DESC
                : ORDER.ASC
              : ORDER.ASC,
        },
      };
    }

    case CRIF_FETCH_LIST: {
      return {
        ...state,
        id: action.accessToken,
        list: [],
        moratorium: {},
        hasMoratorium: false,
        isLoading: true,
        isLoaded: false,
        error: null,
      };
    }
    case CRIF_SET_LOADING: {
      return {
        ...state,
        isLoading: true,
        form: action.form,
      };
    }
    case CRIF_FETCH_LIST__SUCCESS: {
      return {
        ...state,
        list: action.list,
        moratorium: action.currentUser || {},
        hasMoratorium: Boolean(action.moratorium),
        ...getLockState(action.list, action.currentUser, Boolean(action.moratorium)),
        isLoading: false,
        isLoaded: true,
        error: null,
      };
    }
    case CRIF_FETCH_LIST__FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
}
