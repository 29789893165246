import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const ActiveLinkClass = 'App_Nav_Active';

export const Nav = styled.nav`
  height: 2.4rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  padding: 0 100px;
`;

export const Link = styled(NavLink).attrs({
  activeClassName: ActiveLinkClass,
})`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-family: Open Sans;
  font-size: 22px;
  color: #7b7b7b;

  transition: background 0.2s ease-in, opacity 0.2s ease-in, color 0.2s ease-in;
  &.${ActiveLinkClass} {
    color: #000000;
  }
  &:hover {
    color: #000000;
  }
`;
