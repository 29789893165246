import styled from 'styled-components';
import { Collapse, Button, Form } from 'antd';

const { Panel } = Collapse;

const Root = styled.div`
  height: calc(100vh - 23rem);
  position: relative;
  overflow: auto;
  
  textarea{
    resize: none;
  }
`;

const CollapseStyled = styled(Collapse)`
  margin-right: 4rem;
  max-width: 1200px;
`;

const PanelStyled = styled(Panel)``;

const RefetchButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 700;
`;

const TimeBlock = styled.div``;

const TimeTitle = styled.strong`
  font-size: 0.8rem;
  color: #797979;
  font-weight: 500;
`;

const TimeDuration = styled.span`
  font-weight: bold;
`;

const TimePeriod = styled.time`
  font-weight: bold;
`;

const LogContent = styled.p`
  margin: 0;
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    border-top: 1px solid #dbdbdb;
  }
`;

const LogText = styled.span``;

const LogTime = styled.time`
  background: #ccc;
  padding: 0.1rem 1rem;
  border-radius: 11px;
  font-size: 0.8rem;
`;

const Container = styled.div`
  display: grid;
  align-items: flex-start;
  width: 100%;
  grid-template-columns: 1.2fr .8fr;
  grid-column-gap: 20px;
`;

const CommentsContainer = styled.div`
  display: grid;
  align-items: flex-start;
  width: 100%;
  grid-template-columns: 1fr;
  max-height: 52vh;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid rgba(0,0,0, .2);
  
  ${({ full }) => full && 'min-height: 100%;'}
`;

const Main = styled(Form)`
  height: 100%;
  
  button{
    max-width: 200px;
  }
  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & .ant-checkbox-wrapper {
    color: rgba(0, 0, 0, 0.85);
  }

  & .ant-form-item {
    margin-bottom: 7px;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SuccessMessage = styled.div`
  color: #8c8c8c;
  margin: 0 0 10px;
`;

export {
  Root,
  CollapseStyled,
  PanelStyled,
  RefetchButton,
  Header,
  Title,
  TimeBlock,
  TimeTitle,
  TimeDuration,
  TimePeriod,
  LogContent,
  LogText,
  LogTime,
  CommentsContainer,
  Container,
  Main,
};
