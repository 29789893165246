import React, { useEffect, useState } from 'react';
import { Input, message as notification, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from '../../Modal';
import {
  ErrorMessage, ModalBody, ModalContent, ModalTitle, Root,
} from './style';
import { revision as revisionRequest, revisionReset as revisionResetValue } from '../../../redux/request/actions';
import Button from '../../Button';

const { TextArea } = Input;

function RevisionButton({
  // passed
  accessToken,
  title,

  // connect
  formIsDisabled,
  fetchIsLoading,
  isLoading,
  isLoaded,
  error,

  // actions
  revisionRequest,
  revisionResetValue,
}) {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const history = useHistory();

  function handleClose() {
    setShowModal(false);
    setMessage('');
  }

  function handleOnClick(e) {
    e.stopPropagation();
    setShowModal(true);
  }

  function onChange(e) {
    setMessage(e.target.value);
    setMessageError('');
  }

  function sendRevision() {
    revisionRequest({ message, accessToken });
  }

  const isButtonDisabled = message.length === 0;

  useEffect(() => {
    if (isLoaded && !isLoading) {
      notification.success('Ваше уведомление успешно отправлено в ДЦ', 3);
      setTimeout(() => {
        history.push('/app');
      }, 3000);
      revisionResetValue();
      handleClose();
      setMessage('');
      setMessageError('');
    }
  }, [history, isLoading, isLoaded, revisionResetValue]);

  useEffect(() => {
    setMessageError(error);
  }, [error]);

  return (
    <Skeleton active loading={fetchIsLoading}>
      <Modal
        visible={showModal}
        closable
        onCancel={handleClose}
        title="Отправить на доработку"
      >
        <ModalBody>
          <ModalTitle>
            Отправка уведомления в ДЦ
          </ModalTitle>
          <ModalContent>
            <TextArea
              showCount
              placeholder="Введите уведомление в ДЦ"
              maxLength={400}
              style={{
                height: 240,
                resize: 'none',
              }}
              onChange={onChange}
              value={message}
              {...(messageError ? { status: 'error' } : {})}

            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}

            <Button
              block
              type="primary"
              shape="square"
              htmlType="submit"
              loading={isLoading}
              onClick={sendRevision}
              disabled={isButtonDisabled || isLoading}
              style={{ marginTop: '20px' }}
            >
              Отправить
            </Button>
          </ModalContent>
        </ModalBody>
      </Modal>

      <Root
        onClick={handleOnClick}
        loading={isLoading}
        title={title}
        disabled={formIsDisabled}
      >
        Отправить на доработку
      </Root>
    </Skeleton>
  );
}

export default connect(
  (state) => ({
    formIsDisabled: state.request.formIsDisabled,
    requestStatusIsLocked: state.request.statusIsLocked,
    fetchIsLoading: state.request.fetch.isLoading,
    isLoading: state.request.revision.isLoading,
    isLoaded: state.request.revision.isLoaded,
    error: state.request.revision.error,

  }),
  {
    revisionRequest,
    revisionResetValue,
  },
)(RevisionButton);
