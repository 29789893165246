import styled from 'styled-components';
import { Collapse, Button } from 'antd';

const { Panel } = Collapse;

const Root = styled.div`
  height: calc(100vh - 23rem);
  position: relative;
  overflow: auto;
`;

const CollapseStyled = styled(Collapse)`
  margin-right: 4rem;
  max-width: 1200px;
`;

const PanelStyled = styled(Panel)``;

const RefetchButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h6`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 700;
`;

const TimeBlock = styled.div``;

const TimeTitle = styled.strong`
  font-size: 0.8rem;
  color: #797979;
  font-weight: 500;
`;

const TimeDuration = styled.span`
  font-weight: bold;
`;

const TimePeriod = styled.time`
  font-weight: bold;
`;

const LogContent = styled.p`
  margin: 0;
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    border-top: 1px solid #dbdbdb;
  }
`;

const LogText = styled.span``;

const LogTime = styled.time`
  background: #ccc;
  padding: 0.1rem 1rem;
  border-radius: 11px;
  font-size: 0.8rem;
`;

export {
  Root,
  CollapseStyled,
  PanelStyled,
  RefetchButton,
  Header,
  Title,
  TimeBlock,
  TimeTitle,
  TimeDuration,
  TimePeriod,
  LogContent,
  LogText,
  LogTime,
};
