import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import Loader from 'components/Loader';
import SelectMultiple from 'components/SelectMultiple';
import {
  Link, useParams, useLocation, withRouter,
} from 'react-router-dom';
import { Form, Input } from 'antd';
import { Rifm } from 'rifm';
import { checkPrivilege } from 'helpers/roles';
import { formatPhone, cursorToLastPhoneValuePosition } from 'utils';
import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  create as createAction,
  resetForm as resetFormAction,
  edit as editAction,
  fetchDealerCenter as fetchDealerCenterAction,
  fetchDealerCenterReset as fetchDealerCenterResetAction,
} from 'redux/dealerCenter/actions';

import {
  ErrorMessage,
  SuccessMessage,
} from 'components/Messages';
import { KEYS as K } from './data';

import OrganisationField from './OrganisationField';
import AddressField from './AddressField';

import {
  Root,
  Header,
  Title,
  Main,
  Rows,
  Row,
  RowTop,
  FooterAction,
  OrganizationRow,
  RowContainer,
  ButtonContainer,
  LoadingContainer,
  TimeHelper,
} from './style';

const initialValues = {
  [K.NAME.key]: '',
  [K.INN.key]: '',
  [K.KRIF_ID.key]: '',
  [K.ACQUIRING_ID.key]: '',
  [K.ONLINE_ID.key]: '',
  [K.ADDRESS.key]: '',
  [K.PHONE.key]: '',
  [K.TIME.key]: '',
  [K.SECTORS.key]: [],
  [K.EMAIL.key]: '',
};

const getOptions = (array = []) => {
  if (!Array.isArray(array)) {
    return [];
  }
  return array.length > 0
    ? array.map((item) => ({ value: item, label: item }))
    : [];
};

const formatToInt = (array = []) => array.map((item) => Number(item));

function Item({
  // connect
  history,
  role,

  data,
  isLoading,
  isLoaded,
  error,
  errors,

  dataEdit,
  editIsLoading,
  editIsLoaded,
  editError,
  editErrors,

  dealerCenter,
  dealerCenterIsLoading,
  dealerCenterIsLoaded,
  dealerCenterError,

  // actions
  create,
  resetForm,
  edit,
  fetchDealerCenter,
  setBreadcrumbs,
  fetchDealerCenterReset,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [form] = Form.useForm();
  const phoneField = useRef();
  const [isValidated, setIsValidated] = useState(true);
  const [salePointTitle, setSalePointTitle] = useState('Точка продаж');
  const [hasInn, setHasInn] = useState(false);
  const { dealerCenterId } = useParams();
  const location = useLocation();
  const isNew = location.pathname.endsWith('/new');
  const isEdit = location.pathname.endsWith('/edit');
  const canChangeOrganization = checkPrivilege(role.ORGANISATIONS.CREATE) && dealerCenterId;
  const isReadOnly = isLoaded || (dealerCenterId && !isEdit);
  const [organisationIsEditable, setOrganisationIsEditable] = useState(
    dealerCenterId || isReadOnly,
  );
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    setIsMounted(true);
  }, [setIsMounted]);

  useEffect(() => {
    // так как страница у нас одна и у нее должна меняться логика в зависимости от пути
    // нам необходимо дополнительно проверять pathname и делать сброс данных в сторе
    if (location.pathname) {
      if (isNew) {
        resetForm('new');
      } else {
        resetForm();
      }
    }
  }, [location, resetForm, isNew, form]);

  useEffect(() => {
    if (isMounted) {
      const mixin = isNew
        ? [{ level: 2, name: 'Новая точка', link: '/app/management/dealerCenters/new' }]
        : [
          {
            level: 2,
            name: salePointTitle || 'Точка продаж',
            link: `/app/management/dealerCenters/${dealerCenterId}`,
          },
          ...(isEdit
            ? [
              {
                level: 3,
                name: 'редактирование',
                link: `/app/management/dealerCenters/${dealerCenterId}/edit`,
              },
            ]
            : []),
        ];

      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Точки продаж', link: '/app/management/dealerCenters/list' },
        ...mixin,
      ]);
    }
  }, [isMounted, setBreadcrumbs, isNew, isEdit, dealerCenterId, salePointTitle]);

  useEffect(() => {
    if (dealerCenterId) {
      fetchDealerCenter({
        id: Number(dealerCenterId),
      });
    }
  }, [dealerCenterId, fetchDealerCenter]);

  useEffect(() => {
    if (editIsLoaded) {
      setOrganisationIsEditable(true);
    }
  }, [editIsLoaded, setOrganisationIsEditable]);

  useEffect(() => {
    if (editIsLoaded) {
      const {
        sectorList,
      } = dataEdit;

      if (Array.isArray(sectorList)) {
        const options = getOptions(sectorList);
        setSectors(options);
      }
    }
  }, [editIsLoaded, dataEdit]);

  useEffect(() => {
    if (!isEdit && canChangeOrganization && dealerCenterId) {
      setOrganisationIsEditable(true);
    }
  }, [isEdit, canChangeOrganization, dealerCenterId]);

  const handleSetValue = useCallback(() => {
    const {
      inn,
      krifId,
      acquiringID,
      onlineId,
      addressactual,
      phone,
      time,
      salePoint,
      sectorList,
      bindSector,
      email,
    } = dealerCenter;

    const validPhone = phone && phone.replace(/(\+7)/gm, '');

    if (Array.isArray(sectorList) && sectorList.length > 0) {
      const options = getOptions(sectorList);
      setSectors(options);
    }

    form.setFields([
      ...(salePoint ? [{ name: K.NAME.key, value: salePoint }] : []),
      ...(bindSector && bindSector.length > 0
        ? [{ name: K.SECTORS.key, value: bindSector }]
        : []),
      ...(inn ? [{ name: K.INN.key, value: inn }] : []),
      ...(krifId ? [{ name: K.KRIF_ID.key, value: krifId }] : []),
      ...(acquiringID ? [{ name: K.ACQUIRING_ID.key, value: acquiringID }] : []),
      ...(onlineId ? [{ name: K.ONLINE_ID.key, value: onlineId }] : []),
      ...(addressactual ? [{ name: K.ADDRESS.key, value: addressactual }] : []),
      ...(email ? [{ name: K.EMAIL.key, value: email }] : []),
      ...(phone ? [{ name: K.PHONE.key, value: validPhone }] : []),
      ...(time ? [{ name: K.TIME.key, value: time }] : []),
    ]);

    setSalePointTitle(salePoint);
    setHasInn(inn);
      // проверка привязана организация к Точке пролаже и есть ли доступ к изменению точки продажи
      // если есть доступ и нет организации то будет доступана измение организации и без кнопки изменить организацию
    if (isEdit && !inn && canChangeOrganization) {
      setOrganisationIsEditable(false);
    }
  }, [form, dealerCenter, isEdit, canChangeOrganization]);

  const handleResetValue = useCallback(() => {
    form.setFields([
      { name: K.NAME.key, value: '' },
      { name: K.INN.key, value: '' },
      { name: K.KRIF_ID.key, value: '' },
      { name: K.ACQUIRING_ID.key, value: '' },
      { name: K.ONLINE_ID.key, value: '' },
      { name: K.ADDRESS.key, value: '' },
      { name: K.EMAIL.key, value: '' },
      { name: K.PHONE.key, value: '' },
      { name: K.TIME.key, value: '' },
    ]);
  }, [form]);

  useEffect(() => () => { handleResetValue(); fetchDealerCenterReset(); }, [handleResetValue, fetchDealerCenterReset]);

  useEffect(() => {
    if (dealerCenterIsLoaded && dealerCenter && !isNew) {
      handleSetValue();
    }
  }, [dealerCenterIsLoaded, dealerCenter, handleSetValue, isNew]);

  useEffect(() => {
    if (!isValidated && !isLoading && Array.isArray(errors) && errors.length > 0) {
      const errorsFlat = errors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.NAME.apiKey]: name,
        [K.INN.apiKey]: inn,
        [K.KRIF_ID.apiKey]: krifId,
        [K.ACQUIRING_ID.apiKey]: acquiringId,
        [K.ONLINE_ID.apiKey]: onlineId,
        [K.ADDRESS.apiKey]: address,
        [K.EMAIL.apiKey]: email,
        [K.PHONE.apiKey]: phone,
        [K.TIME.apiKey]: time,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(name ? [{ name: K.NAME.key, errors: [name] }] : []),
        ...(inn ? [{ name: K.INN.key, errors: [inn] }] : []),
        ...(krifId ? [{ name: K.KRIF_ID.key, errors: [krifId] }] : []),
        ...(acquiringId ? [{ name: K.ACQUIRING_ID.key, errors: [acquiringId] }] : []),
        ...(onlineId ? [{ name: K.ONLINE_ID.key, errors: [onlineId] }] : []),
        ...(address ? [{ name: K.ADDRESS.key, errors: [address] }] : []),
        ...(email ? [{ name: K.EMAIL.key, errors: [email] }] : []),
        ...(phone ? [{ name: K.PHONE.key, errors: [phone] }] : []),
        ...(time ? [{ name: K.TIME.key, errors: [time] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [errors, isLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if (
      !isValidated
      && !editIsLoading
      && Array.isArray(editErrors)
      && editErrors.length > 0
    ) {
      const errorsFlat = editErrors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.NAME.apiKey]: name,
        [K.INN.apiKey]: inn,
        [K.KRIF_ID.apiKey]: krifId,
        [K.ACQUIRING_ID.apiKey]: acquiringId,
        [K.ONLINE_ID.apiKey]: onlineId,
        [K.ADDRESS.apiKey]: address,
        [K.EMAIL.apiKey]: email,
        [K.PHONE.apiKey]: phone,
        [K.TIME.apiKey]: time,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(name ? [{ name: K.NAME.key, errors: [name] }] : []),
        ...(inn ? [{ name: K.INN.key, errors: [inn] }] : []),
        ...(krifId ? [{ name: K.KRIF_ID.key, errors: [krifId] }] : []),
        ...(acquiringId ? [{ name: K.ACQUIRING_ID.key, errors: [acquiringId] }] : []),
        ...(onlineId ? [{ name: K.ONLINE_ID.key, errors: [onlineId] }] : []),
        ...(address ? [{ name: K.ADDRESS.key, errors: [address] }] : []),
        ...(email ? [{ name: K.EMAIL.key, errors: [email] }] : []),
        ...(phone ? [{ name: K.PHONE.key, errors: [phone] }] : []),
        ...(time ? [{ name: K.TIME.key, errors: [time] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [editErrors, editIsLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if (isLoaded) {
      form.setFields([{ name: K.INN.key, value: '' }]);
      history.push(`/app/management/dealerCenters/${data.id}`);
    }
  }, [isLoaded, history, data.id, form]);

  function handleChange({ name, value }) {
    form.setFields([{ name, value }]);
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleFinish(values) {
    const {
      [K.NAME.key]: name,
      [K.INN.key]: inn,
      [K.KRIF_ID.key]: krifId,
      [K.ACQUIRING_ID.key]: acquiringId,
      [K.ONLINE_ID.key]: onlineId,
      [K.ADDRESS.key]: address,
      [K.PHONE.key]: phone,
      [K.TIME.key]: time,
      [K.SECTORS.key]: bindSectors,
      [K.EMAIL.key]: email,
    } = values;

    if (isNew) {
      create({
        [K.NAME.apiKey]: name,
        [K.INN.apiKey]: inn,
        [K.KRIF_ID.apiKey]: krifId,
        [K.ACQUIRING_ID.apiKey]: acquiringId,
        [K.ONLINE_ID.apiKey]: onlineId,
        [K.ADDRESS.apiKey]: address,
        [K.EMAIL.apiKey]: email,
        [K.PHONE.apiKey]: phone,
        [K.TIME.apiKey]: time,
      });
    }

    if (isEdit) {
      const {
        bindSector,
      } = dealerCenter;
      const unbindSectors = bindSector.filter((item) => !bindSectors.includes(item));

      edit({
        id: Number(dealerCenterId),
        [K.NAME.apiKey]: name || salePointTitle,
        [K.INN.apiKey]: inn,
        [K.KRIF_ID.apiKey]: krifId,
        [K.ACQUIRING_ID.apiKey]: acquiringId,
        [K.ONLINE_ID.apiKey]: onlineId,
        [K.ADDRESS.apiKey]: address,
        [K.EMAIL.apiKey]: email,
        [K.PHONE.apiKey]: phone,
        [K.TIME.apiKey]: time,
        [K.SECTORS.apiKey]: formatToInt(bindSectors),
        unbindSector: formatToInt(unbindSectors),
      });
    }

    setIsValidated(false);
  }

  return (
    <Root>
      {(dealerCenterIsLoaded || isNew) && (
        <Header>
          <Title>{salePointTitle}</Title>
          {dealerCenterIsLoaded && !isEdit && checkPrivilege(role.ORGANISATIONS.EDIT_POINTS_OF_SALES) && (
            <Button size="small" type="link" shape="square">
              <Link to={`/app/management/dealerCenters/${dealerCenterId}/edit`}>
                Редактировать
              </Link>
            </Button>
          )}
        </Header>
      )}

      {dealerCenterIsLoading && (
        <LoadingContainer>
          <Loader text="Загрузка данных дилерского центра" />
        </LoadingContainer>
      )}

      {dealerCenterError && <ErrorMessage>{dealerCenterError}</ErrorMessage>}

      {(dealerCenterIsLoaded || isNew) && (
        <Main
          name="pointOfSales"
          layout="vertical"
          hideRequiredMark
          onFinish={handleFinish}
          form={form}
          initialValues={initialValues}
          autoComplete="off"
        >
          <Rows>
            <RowContainer>
              <RowTop>
                <Form.Item
                  name={K.NAME.key}
                  label={K.NAME.title}
                  rules={K.NAME.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.NAME.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                {(isNew || (!canChangeOrganization && dealerCenterId)) && (
                  <Form.Item
                    name={K.INN.key}
                    label={K.INN.title}
                    rules={K.INN.rules}
                    validateFirst
                    validateTrigger="onBlur"
                  >
                    <OrganisationField
                      name={K.INN.key}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={dealerCenterId}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name={K.KRIF_ID.key}
                  label={K.KRIF_ID.title}
                  rules={isNew ? K.KRIF_ID.rules : []}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.KRIF_ID.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.ACQUIRING_ID.key}
                  label={K.ACQUIRING_ID.title}
                  rules={isNew ? K.ACQUIRING_ID.rules : []}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.ACQUIRING_ID.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.ONLINE_ID.key}
                  label={K.ONLINE_ID.title}
                  rules={isNew ? K.ONLINE_ID.rules : []}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.ONLINE_ID.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.ADDRESS.key}
                  label={K.ADDRESS.title}
                  rules={K.ADDRESS.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <AddressField
                    name={K.ADDRESS.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.EMAIL.key}
                  label={K.EMAIL.title}
                  rules={K.EMAIL.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.EMAIL.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>

                <Form.Item
                  name={K.PHONE.key}
                  label={K.PHONE.title}
                  rules={K.PHONE.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Rifm
                    accept={/[\d]/g}
                    mask
                    replace={formatPhone.replace}
                    format={formatPhone.format}
                    onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                  >
                    {({ value, onChange }) => (
                      <Input
                        name={K.PHONE.key}
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                        addonBefore={formatPhone.prefix}
                        disabled={isReadOnly}
                        ref={phoneField}
                      />
                    )}
                  </Rifm>
                </Form.Item>
                {!isNew && (
                  <Form.Item
                    name={K.SECTORS.key}
                    label={K.SECTORS.title}
                    rules={K.SECTORS.rules}
                    validateFirst
                    validateTrigger="onBlur"
                  >
                    <SelectMultiple
                      name={K.SECTORS.key}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      disabled={isReadOnly}
                      options={sectors}
                    />
                  </Form.Item>
                )}
              </RowTop>

              {canChangeOrganization && (
                <RowTop>
                  <OrganizationRow>
                    <Form.Item
                      name={K.INN.key}
                      label={K.INN.title}
                      rules={K.INN.rules}
                      validateFirst
                      validateTrigger="onBlur"
                    >
                      <OrganisationField
                        name={K.INN.key}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={organisationIsEditable}
                      />
                    </Form.Item>
                    {isEdit && hasInn && organisationIsEditable && (
                      <ButtonContainer>
                        <Button
                          type="primary"
                          size="mini"
                          shape="square"
                          onClick={() =>
                            setOrganisationIsEditable(!organisationIsEditable)}
                        >
                          Сменить организацию
                        </Button>
                      </ButtonContainer>
                    )}
                  </OrganizationRow>
                </RowTop>
              )}
            </RowContainer>

            <Row>
              <Form.Item
                name={K.TIME.key}
                label={K.TIME.title}
                rules={K.TIME.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input.TextArea
                  name={K.TIME.key}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  rows={5}
                  disabled={isReadOnly}
                />
              </Form.Item>
              <TimeHelper>
                Пример заполнения - Пн.-Пт. с 7-20 , Сб-Вск с 7-22
              </TimeHelper>

              {isNew && !isLoaded && (
                <FooterAction>
                  <Button
                    size="mini"
                    shape="square"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Сохранить
                  </Button>
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </FooterAction>
              )}

              {isEdit && (
                <FooterAction>
                  <Button
                    size="mini"
                    type="ghost"
                    shape="square"
                    href={`/app/management/dealerCenters/${dealerCenterId}`}
                  >
                    Отменить
                  </Button>
                  <Button
                    size="mini"
                    type="primary"
                    shape="square"
                    htmlType="submit"
                    loading={editIsLoading}
                  >
                    Сохранить
                  </Button>
                  {editError && <ErrorMessage>{editError}</ErrorMessage>}
                  {editIsLoaded && (
                    <SuccessMessage>Данные были изменены</SuccessMessage>
                  )}
                </FooterAction>
              )}
            </Row>
          </Rows>
        </Main>
      )}
    </Root>
  );
}

export default withRouter(
  connect(
    (state) => ({
      role: state.auth.role,
      data: state.dealerCenter.create.data,
      isLoading: state.dealerCenter.create.isLoading,
      isLoaded: state.dealerCenter.create.isLoaded,
      error: state.dealerCenter.create.error,
      errors: state.dealerCenter.create.errors,

      dataEdit: state.dealerCenter.edit.data,
      editIsLoading: state.dealerCenter.edit.isLoading,
      editIsLoaded: state.dealerCenter.edit.isLoaded,
      editError: state.dealerCenter.edit.error,
      editErrors: state.dealerCenter.edit.errors,

      dealerCenter: state.dealerCenter.item.data,
      dealerCenterIsLoading: state.dealerCenter.item.isLoading,
      dealerCenterIsLoaded: state.dealerCenter.item.isLoaded,
      dealerCenterError: state.dealerCenter.item.error,
    }),
    {
      create: createAction,
      resetForm: resetFormAction,
      edit: editAction,
      fetchDealerCenter: fetchDealerCenterAction,
      setBreadcrumbs: setBreadcrumbsAction,
      fetchDealerCenterReset: fetchDealerCenterResetAction,
    },
  )(Item),
);
