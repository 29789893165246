import React from 'react';

export const SuccessfulIcon = (props) => (
  <svg {...props} width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M61 31C61 14.5149 47.6337 1 31.0001 1C14.515 1 1 14.3663 1 31C1 47.4851 14.3664 61 31.0001 61C47.6337 61 61 47.6337 61 31Z" stroke="#787A7A" strokeWidth="2" strokeLinecap="round" />
    <path d="M61 31C61 14.5149 47.6337 1 31.0001 1C14.515 1 1 14.3663 1 31C1 47.4851 14.3664 61 31.0001 61C47.6337 61 61 47.6337 61 31Z" stroke="#787A7A" strokeWidth="2" strokeLinecap="round" />
    <path d="M20.1445 29.8803L28.5151 38.3541L44.658 22.0118" stroke="#E50000" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export const ErrorIcon = (props) => (
  <svg {...props} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 30C60 13.5149 46.6337 0 30.0001 0C13.515 0 0 13.3663 0 30C0 46.4851 13.3664 60 30.0001 60C46.6337 60 60 46.6337 60 30Z" fill="#E50000" />
    <path d="M30.0002 37.0001L29.9998 14.0001" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M30.0145 45L30.015 45.0295" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
