import React, { useRef } from 'react';
import { Rifm } from 'rifm';
import { Form, Input } from 'antd';

import { cursorToLastPhoneValuePosition, formatPhone } from 'utils';

import {
  FormItemsContainer,
  FormItemsRow,
} from '../style';

import { KEYS as K } from '../data';

function BillItems({
    // passed
  handleFocus,
  handleBlur,
}) {
  const phoneField = useRef();
  return (
    <>
      <FormItemsContainer>
        <FormItemsRow items={2} width="536px">
          <Form.Item
            name={K.BILL_SENDER.key}
            label={K.BILL_SENDER.title}
            rules={K.BILL_SENDER.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.BILL_SENDER.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.BILL_TRADE_NAME.key}
            label={K.BILL_TRADE_NAME.title}
            rules={K.BILL_TRADE_NAME.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.BILL_TRADE_NAME.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.BILL_WEB_ADDRESS.key}
            label={K.BILL_WEB_ADDRESS.title}
            rules={K.BILL_WEB_ADDRESS.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.BILL_WEB_ADDRESS.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.BILL_EMAIL.key}
            label={K.BILL_EMAIL.title}
            rules={K.BILL_EMAIL.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.BILL_EMAIL.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.BILL_PHONE.key}
            label={K.BILL_PHONE.title}
            rules={K.BILL_PHONE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Rifm
              accept={/[\d]/g}
              mask
              replace={formatPhone.replace}
              format={formatPhone.format}
              onClick={() => cursorToLastPhoneValuePosition(phoneField)}
            >
              {({ value, onChange }) => (
                <Input
                  name={K.BILL_PHONE.key}
                  value={value}
                  onChange={onChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                  addonBefore={formatPhone.prefix}
                  ref={phoneField}
                  disabled
                />
              )}
            </Rifm>
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
    </>
  );
}

export default BillItems;
