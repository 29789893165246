/* List */
export const FILES_FETCH_LIST = 'FILES_FETCH_LIST';
export const FILES_FETCH_LIST__SUCCESS = 'FILES_FETCH_LIST__SUCCESS';
export const FILES_FETCH_LIST__FAILURE = 'FILES_FETCH_LIST__FAILURE';
export const fetchList = ({ legalEntityId } = {}) => ({
  type: FILES_FETCH_LIST,
  legalEntityId,
});

export const FILES_FETCH_LIST__RESET = 'FILES_FETCH_LIST__RESET';
export const reset = () => ({ type: FILES_FETCH_LIST__RESET });

export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD__SUCCESS = 'FILE_UPLOAD__SUCCESS';
export const FILE_UPLOAD__FAILURE = 'FILE_UPLOAD__FAILURE';
export const fileUpload = ({
  legalEntityId,
  subsidiaryId,
  comment,
  fileName,
} = {}) => ({
  type: FILE_UPLOAD,
  legalEntityId,
  subsidiaryId,
  comment,
  fileName,
});

export const FILE_UPLOAD__RESET = 'FILE_UPLOAD__RESET';
export const resetUpload = () => ({ type: FILE_UPLOAD__RESET });

export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
export const FILE_DOWNLOAD__SUCCESS = 'FILE_DOWNLOAD__SUCCESS';
export const FILE_DOWNLOAD__FAILURE = 'FILE_DOWNLOAD__FAILURE';
export const fileDownload = ({ id, fileName } = {}) => ({ type: FILE_DOWNLOAD, id, fileName });

export const FILE_DOWNLOAD__RESET = 'FILE_DOWNLOAD__RESET';
export const resetDownload = () => ({ type: FILE_DOWNLOAD__RESET });

export const FILE_DELETE = 'FILE_DELETE';
export const FILE_DELETE__SUCCESS = 'FILE_DELETE__SUCCESS';
export const FILE_DELETE__FAILURE = 'FILE_DELETE__FAILURE';
export const fileDelete = ({ id } = {}) => ({ type: FILE_DELETE, id });
