import styled, { css } from 'styled-components';
import React from 'react';
import { Select as AntSelect } from 'antd';
import { ArrowIcon, TagCloseIcon } from 'icons';
import checkBoxIcon from 'assets/icons/checkBox.svg';
import withTheme from 'components/tcm/withTheme';

export const TagWrapper = styled.div`
  margin-right: 10px;
  padding: 4px 10px 4px 12px;
  border: 1px solid #595E68;
  border-radius: 20px;
  box-sizing: border-box;

  &:not(:first-child)&:last-child {
    margin-right: 0;
  }
`;

export const TagContent = withTheme(styled.div`
  display: grid;
  grid-template-columns: max-content 10px;
  column-gap: 10px;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};;
`);

export const CloseBtn = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border: none;
  cursor: pointer;

  &:focus {
    border: none;
    outline: none;
  }
`;

export const OptWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px max-content;
  column-gap: 10px;
  align-items: center;
`;

export const FilterTag = ({ label, onClose }) => (
  <TagWrapper>
    <TagContent>
      {label}
      <CloseBtn onClick={onClose}>
        <TagCloseIcon />
      </CloseBtn>
    </TagContent>
  </TagWrapper>
);

export const CheckBox = styled.div`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #CECECE;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const CheckBoxInput = styled.input.attrs(() => ({ type: 'checkbox' }))`
  visibility: hidden;
  align-self: flex-start;

  & + div {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
  }

  :checked + div {
    background: url(${checkBoxIcon}) no-repeat center;
  }
`;

export const MultipleSelectStyled = withTheme(styled(AntSelect).attrs(() => ({
  mode: 'multiple',
  suffixIcon: <ArrowIcon down />,
  dropdownClassName: 'tcm-multiple-option-list',
  dropdownAlign: {
    points: ['tl', 'bl'],
    offset: [0, -1],
  },
  getPopupContainer: (trigger) => trigger.parentElement,
  showArrow: true,
  tagRender: (p) => <FilterTag {...p} />,
  menuItemSelectedIcon: null,
}))`
  ${({ theme }) => css`
    
    width: 100%;
    color: ${theme.select.textColor};
    box-sizing: border-box;

    &.ant-select {

      &.ant-select-open > .ant-select-selector {
        border-radius: ${theme.select.borderOpenRadius};
      }

      & .ant-select-arrow svg {
        transition: transform 150ms ease-in-out;
      }

      &.ant-select-open .ant-select-arrow svg {
        transform: rotate(180deg);
      }
    }

    &.ant-select > .ant-select-selector {
      padding: ${theme.multipleSelect.padding};
      border-color: ${theme.select.borderColor};
      border-radius: ${theme.select.borderRadius}px;

      & > .ant-select-selection-search {
        padding: 0;
        font-weight: ${theme.select.fontWeight};
        font-size: ${theme.select.fontSize};
        line-height: 30px;
        
        input {
          margin-left: 0;
        }
      }

      & > .ant-select-selection-item {
        padding-bottom: 0;
        font-weight: ${theme.select.fontWeight};
        font-size: ${theme.select.fontSize};
        line-height: ${theme.select.lineHeight};
      }

      & > .ant-select-selection-placeholder {
        left: 20px;
        padding: 0;
        font-weight: ${theme.select.fontWeightPlaceholder};
        font-size: ${theme.select.fontSize};
        line-height: ${theme.select.lineHeight};
        color: ${theme.select.textPlaceholderColor};
        opacity: 1;
      }
    }
    
    &.ant-select-multiple {
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        height: 30px;
        margin: 0 10px 0 0;
        padding: 0 13px 0 11px;
        border: 1px solid #595E68;
        border-radius: 20px;
        background-color: #fff;
        font-family: ${theme.fontFamily};
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        color: #000000;
        box-sizing: border-box;
      }
    }

    & .ant-select-arrow {
      right: 20px;
    }
  `}
`);

const MultipleOption = withTheme(styled(AntSelect.Option)`
`);

MultipleOption.isSelectOption = true;

export { MultipleOption };
