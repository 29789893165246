import {
  FETCH_LEXUS_MODELS,
  FETCH_LEXUS_MODELS__FAILURE,
  FETCH_LEXUS_MODELS__SUCCESS,

  FETCH_TOYOTA_MODELS,
  FETCH_TOYOTA_MODELS__FAILURE,
  FETCH_TOYOTA_MODELS__SUCCESS,

  FETCH_BRANDS,
  FETCH_BRANDS__FAILURE,
  FETCH_BRANDS__SUCCESS,

  FETCH_MODELS,
  FETCH_MODELS__FAILURE,
  FETCH_MODELS__SUCCESS,

} from 'redux/common/actions';

const initState = {
  toyotaModels: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  lexusModels: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  brands: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  models: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },

};

export default function offerReducer(state = initState, action) {
  switch (action.type) {
    case FETCH_TOYOTA_MODELS:
      return {
        ...state,
        toyotaModels: {
          ...state.toyotaModels,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
        filters: initState.filters,
      };
    case FETCH_TOYOTA_MODELS__SUCCESS:
      return {
        ...state,
        toyotaModels: {
          ...state.toyotaModels,
          list: action.list,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
        filters: action.filters,
      };
    case FETCH_TOYOTA_MODELS__FAILURE:
      return {
        ...state,
        toyotaModels: {
          ...state.toyotaModels,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case FETCH_LEXUS_MODELS:
      return {
        ...state,
        lexusModels: {
          ...state.lexusModels,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
        filters: initState.filters,
      };
    case FETCH_LEXUS_MODELS__SUCCESS:
      return {
        ...state,
        lexusModels: {
          ...state.lexusModels,
          list: action.list,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
        filters: action.filters,
      };
    case FETCH_LEXUS_MODELS__FAILURE:
      return {
        ...state,
        lexusModels: {
          ...state.lexusModels,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case FETCH_MODELS:
      return {
        ...state,
        models: {
          ...state.models,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    case FETCH_MODELS__SUCCESS:
      return {
        ...state,
        models: {
          ...state.models,
          list: action.list,
          isLoading: false,
          isLoaded: true,
          error: false,
        },
        filters: action.filters,
      };
    case FETCH_MODELS__FAILURE:
      return {
        ...state,
        models: {
          ...state.models,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case FETCH_BRANDS:
      return {
        ...state,
        brands: {
          ...state.brands,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    case FETCH_BRANDS__SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          list: action.list,
          isLoading: false,
          isLoaded: true,
          error: false,
        },
        filters: action.filters,
      };
    case FETCH_BRANDS__FAILURE:
      return {
        ...state,
        brands: {
          ...state.brands,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}
