/* List */
export const REPORT_FETCH_LIST = 'REPORT_FETCH_LIST';
export const REPORT_FETCH_LIST__SUCCESS = 'REPORT_FETCH_LIST__SUCCESS';
export const REPORT_FETCH_LIST__FAILURE = 'REPORT_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting,
} = {}) => ({
  type: REPORT_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
});

export const REPORT_FETCH_LIST__CANCEL_REQUEST = 'REPORT_FETCH_LIST__CANCEL_REQUEST';
export const REPORT_FETCH_LIST__SET_LOADING = 'REPORT_FETCH_LIST__SET_LOADING';
export const REPORT_FETCH_LIST__SET_PAGINATION = 'REPORT_FETCH_LIST__SET_PAGINATION';

export const REPORT_FETCH_LIST__SET_SEARCH = 'REPORT_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({ type: REPORT_FETCH_LIST__SET_SEARCH, search });

export const REPORT_FETCH_LIST__SET_PAGE = 'REPORT_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: REPORT_FETCH_LIST__SET_PAGE, page });

export const REPORT_FETCH_LIST__SET_SORTING = 'REPORT_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: REPORT_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const REPORT_FETCH_LIST__SET_FILTER = 'REPORT_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: REPORT_FETCH_LIST__SET_FILTER,
  filters,
});

export const REPORT_FETCH_LIST__RESET = 'REPORT_FETCH_LIST__RESET';
export const reset = () => ({ type: REPORT_FETCH_LIST__RESET });

export const REPORT_EXPORT_FILE = 'REPORT_EXPORT_FILE';
export const REPORT_EXPORT_FILE__SUCCESS = 'REPORT_EXPORT_FILE__SUCCESS';
export const REPORT_EXPORT_FILE__FAILURE = 'REPORT_EXPORT_FILE__FAILURE';
export const exportFile = ({ filter = '' } = {}) => ({
  type: REPORT_EXPORT_FILE,
  filter,
});

export const REPORTS_SYNC = 'REPORTS_SYNC';
export const REPORTS_SYNC__SUCCESS = 'REPORTS_SYNC__SUCCESS';
export const REPORTS_SYNC__FAILURE = 'REPORTS_SYNC__FAILURE';
export const reportsSync = ({ sectors = [], date = '' } = {}) => ({
  type: REPORTS_SYNC,
  sectors,
  date,
});

export const REPORT_MONTHLY_SUMMARY_OPERATIONS = 'REPORT_MONTHLY_SUMMARY_OPERATIONS';
export const REPORT_MONTHLY_SUMMARY_OPERATIONS__SUCCESS = 'REPORT_MONTHLY_SUMMARY_OPERATIONS__SUCCESS';
export const REPORT_MONTHLY_SUMMARY_OPERATIONS__FAILURE = 'REPORT_MONTHLY_SUMMARY_OPERATIONS__FAILURE';
export const reportMonthlySummaryOperations = ({
  sectors = [],
  year = 0,
  month = 0,
} = {}) => ({
  type: REPORT_MONTHLY_SUMMARY_OPERATIONS,
  sectors,
  year,
  month,
});

export const REPORT_PERIOD_OPERATIONS = 'REPORT_PERIOD_OPERATIONS';
export const REPORT_PERIOD_OPERATIONS__SUCCESS = 'REPORT_PERIOD_OPERATIONS__SUCCESS';
export const REPORT_PERIOD_OPERATIONS__FAILURE = 'REPORT_PERIOD_OPERATIONS__FAILURE';
export const reportPeriodOperations = ({
  sectors = [],
  dateFrom = '',
  dateTo = '',
} = {}) => ({
  type: REPORT_PERIOD_OPERATIONS,
  sectors,
  dateFrom,
  dateTo,
});

export const REPORT_RECONCILIATION = 'REPORT_RECONCILIATION';
export const REPORT_RECONCILIATION__SUCCESS = 'REPORT_RECONCILIATION__SUCCESS';
export const REPORT_RECONCILIATION__FAILURE = 'REPORT_RECONCILIATION__FAILURE';
export const reportReconciliation = ({
  sectors = [],
  dateRevise,
} = {}) => ({
  type: REPORT_RECONCILIATION,
  sectors,
  dateRevise,
});

export const REPORT_GET_FILE_URL = 'REPORT_GET_FILE_URL';
export const REPORT_GET_FILE_URL__SUCCESS = 'REPORT_GET_FILE_URL__SUCCESS';
export const REPORT_GET_FILE_URL__FAILURE = 'REPORT_GET_FILE_URL__FAILURE';
export const REPORT_GET_FILE_URL__RESET = 'REPORT_GET_FILE_URL__RESET';
export const getFileUrl = ({
  id = '',
} = {}) => ({
  type: REPORT_GET_FILE_URL,
  id,
});
export const getFileUrlReset = () => ({
  type: REPORT_GET_FILE_URL__RESET,
});

export const REPORT_RESET = 'REPORT_RESET';
export const reportReset = () => ({
  type: REPORT_RESET,
});
