import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from 'components/Loader';

import { useLocation, useParams } from 'react-router-dom';

import { fetchItem as fetchItemAction } from 'redux/user/actions';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import Form from './Form';
import Roles from './Roles';

import { Root, ErrorMessage } from './style';

function User({
  // connect
  userData,
  userIsLoading,
  userIsLoaded,
  userError,

  createData,
  createIsLoaded,

  // actions
  fetchItem,
  setBreadcrumbs,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const { userId } = useParams();
  const location = useLocation();
  const isNew = location.pathname.endsWith('/new');
  const isEdit = location.pathname.endsWith('/edit');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // проверка на id или в url параметрах или при возвращении данных от сервера о новом пользователе.
  const rolesUserId = userId || createData.id;

  useEffect(() => {
    if (userId) {
      fetchItem({ userId });
    }
  }, [fetchItem, userId]);

  useEffect(() => {
    if (isMounted) {
      const mixin = isNew
        ? [{ level: 2, name: 'Новый пользователь', link: '/app/management/users/new' }]
        : [
          {
            level: 2,
            name: userData.displayName || `Пользователь ${userId}`,
            link: `/app/management/users/${userId}`,
          },
          ...(isEdit
            ? [
              {
                level: 3,
                name: 'редактирование',
                link: `/app/management/users/${userId}/edit`,
              },
            ]
            : []),
        ];

      setBreadcrumbs([
        { level: 0, name: 'Управление', link: '/app/management' },
        { level: 1, name: 'Пользователи', link: '/app/management/users/list' },
        ...mixin,
      ]);
    }
  }, [isMounted, setBreadcrumbs, userData.displayName, isEdit, isNew, userId]);

  return (
    <Root>
      {userId && userError && <ErrorMessage>{userError}</ErrorMessage>}

      {userId && userIsLoading && (
        <Loader text="Загружаются данные пользователя..." height={400} />
      )}

      {(isNew || (userId && userIsLoaded)) && <Form />}

      {((userId && userIsLoaded) || (createIsLoaded && createData.id)) && (
        <Roles userId={rolesUserId} />
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    userData: state.user.item.data,
    userIsLoading: state.user.item.isLoading,
    userIsLoaded: state.user.item.isLoaded,
    userError: state.user.item.error,
    createData: state.user.create.data,
    createIsLoaded: state.user.create.isLoaded,
  }),
  {
    fetchItem: fetchItemAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(User);
