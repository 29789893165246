import styled, { css } from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const Wrapper = styled.div`
  margin-bottom: 90px;
`;

export const Title = withTheme(styled.p`
${({ theme }) => css`
  margin-top: 18px;
  font-family: ${theme.toyotaFontFamily};
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -1.3px;
  color: ${theme.palette.black};
`}`);

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin-bottom: ${({ last }) => (last ? 0 : '28px')};
  
  ${({ separate }) => separate && `
    position: relative;
    &:before{
      content: '';
      display: block;
      width: 17px;
      height: 1px;
      background: #000000;
      position: absolute;
      bottom: calc(50% - 14px);
      left: calc(50% - 8px);
    }
  `}
`;

export const FormFooter = styled.div`
  margin-top: 80px;
`;
