export const KEYS = {
  COMPANY: {
    key: 'COMPANY',
    apiKey: 'merchantId',
    title: 'Организация',
    rules: [
      {
        required: true,
        message: 'Выберите существующую организацию',
      },
    ],
  },
  DEALER_CENTER: {
    key: 'DEALER_CENTER',
    apiKey: 'dealerCenterId',
    title: 'Точка продаж',
    rules: [],
  },
  FILES: {
    key: 'FILES',
    apiKey: 'fileName',
    title: 'Добавить новый файл',
    rules: [
      {
        required: true,
        message: 'Выберите файл',
      },
    ],
  },
  COMMENT: {
    key: 'COMMENT',
    apiKey: 'comment',
    title: 'Комментарий',
    rules: [],
  },
};
