import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, history } from 'redux/store';
import Boot from 'redux/boot';
import PublicRoutes from 'router';
import UserActivity from 'UserActivity.jsx';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider, message } from 'antd';
import { TSM_THEME } from 'settings';
import themes from 'settings/themes';

import 'react-reflex/styles.css';

// locale
import ruRU from 'antd/es/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import TsmThemeContext from './context/TsmThemeContext';

moment.locale('ru');

message.config({
  top: 40,
  duration: 3,
  maxCount: 3,
});

window.build = '2.8.1';

const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL || ''}>
    <ThemeProvider theme={themes.basic}>
      <TsmThemeContext.Provider value={TSM_THEME}>
        <ConfigProvider locale={ruRU}>
          <Provider store={store}>
            <UserActivity>
              <PublicRoutes history={history} />
            </UserActivity>
          </Provider>
        </ConfigProvider>
      </TsmThemeContext.Provider>
    </ThemeProvider>
  </BrowserRouter>
);

Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
