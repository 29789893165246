import moment from 'moment';

export const formatTcmReports = (reportList = []) => {
  if (Array.isArray(reportList)) {
    return reportList
      .filter((report) =>
        report.params
          && Array.isArray(report.params.campaignIds)
          && report.params.campaignIds.length > 0
          && report.params.startAt
          && report.params.finishAt
          && report.params.query
          && moment(report.params.startAt).isValid()
          && moment(report.params.finishAt).isValid()).map((item) => (
        {
          id: item.id,
          name: item.name,
          campaigns: item.params.campaignIds,
          dateFrom: moment(item.params.startAt),
          dateTo: moment(item.params.finishAt),
          type: item.type,
          search: item.params.query,
        }));
  }
  return [];
};
