export const KEYS = {
  PRICE_TRADE_IN: {
    key: 'PRICE_TRADE_IN',
    apiKey: 'tradeInPrice',
    rules: [
      () => ({
        validator(_, value) {
          if (!value) {
            return Promise.resolve();
          }
          const number = Number(value.toString().replace(/\s/g, ''));
          if (number < 100000 || number > 9999999) {
            return Promise.reject('Введите сумму от 100 000 до 9 999 999');
          }
          return Promise.resolve();
        },
      }),
    ],
  },

  PRICE_NEW_CAR: {
    key: 'PRICE_NEW_CAR',
    apiKey: 'newCarPrice',
    rules: [],
  },

  PRICE_EXTRA_SERVICES: {
    key: 'PRICE_EXTRA_SERVICES',
    apiKey: 'servicesPrice',
    rules: [],
  },

  EXTERNAL_ID: {
    key: 'EXTERNAL_ID',
    apiKey: 'externalId',
    rules: [],
  },

};

/*eslint-disable*/
export const COLOR_MAP = {
  Бежевый: '#f5f5dc',
  Белый:	'#ffffff',
  Бирюзовый:	'#40e0d0',
  Бордовый:	'#b00000',
  Бронзовый:	'#a87900',
  Голубой:	'#0000FF',
  Желтый:	'#ffff00',
  Зеленый:	'#008000',
  Золотистый:	'#FFD700',
  Коричневый:	'#a52a2a',
  'Красно-коричневый':	'#b7410e',
  Красный:	'#FF0000',
  Оливковый:	'#808000',
  Оранжевый:	'#ffa500',
  'Светло-голубой':	'#add8e6',
  'Светло-зеленый':	'#9cee90',
  'Cветло-коричневый': '#a52a2a',
  'Светло-красный':	'#cd5c5c',
  'Светло-серый':	'#d3d3d3',
  'Светло-синий':	'#00bfff',
  Серебристый:	'#c0c0c0',
  'Серо-голубой':	'#5f9ea0',
  'Серо-золотистый':	'#eee8aa',
  'Серо-синий':	'#6a5acd',
  Серый:	'#808080',
  Синий:	'#0000ff',
  'Темно-голубой':	'#00008b',
  'Темно-зеленый':	'#006400',
  'Темно-коричневый':	'#a52a2a',
  'Темно-красный':	'#8b0000',
  'Темно-серый':	'#a9a9a9',
  'Темно-синий':	'#000080',
  Фиолетовый:	'#ee82ee',
  Черный:	'#000000',
  'Ярко-красный':	'#cd5c5c',
  'Светло-бежевый':	'#fffeb6',
  'Светло-коричневый':	'#a52a2a',
  'Слоновой кости':	'#fffff0',
  'Темно-бордовый':	'#680021',
};
