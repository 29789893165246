import styled from 'styled-components';
import { Button } from 'antd';

export const Root = styled.div`
  padding: 0 4rem 0;

  a {
    color: #7b7b7b;
    font-size: 16px;
  }
  
  .nullable {
    background: #EEE;
    cursor: not-allowed;
    
    &:hover td {
      background: #EEE !important;
    }
  }
  
`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightHeaderSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: Open Sans,sans-serif;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const CompanyContainer = styled.div`
  display: grid;
  grid-row-gap: 2px;
`;

export const Inn = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #7E7E7E;
`;

export const Company = styled.div`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;

export const ButtonDownload = styled(Button)`
  background: #FFFFFF;
  border: 1px solid #7B7B7B;
  box-sizing: border-box;
  border-radius: 5px;
  color: #7B7B7B;
  
  &:hover{
    color: #7B7B7B;
    border: 1px solid #7B7B7B;
  }
`;

export const ButtonDeleteContainer = styled.div`
  button {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
`;
