import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

import ToyotaLogo from 'assets/icons/ToyotaLogo.svg';

import { Nav as SecondaryNav } from 'components/Navigation';

const ActiveLinkClass = 'App_Nav_Active';

export const SecontaryHeader = styled(SecondaryNav)`
  align-items: center;
  justify-content: end;

  height: 30px;
  padding-left: 2rem;
  padding-right: 2rem;

  border: none;
`;

export const Root = styled.div`
  overflow: hidden;
  font-family: "Toyota Type", sans-serif;
`;

export const Header = styled.header`
  height: 70px;
  display: flex;
  align-items: center;

  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-width: -webkit-min-content;
  & svg {
    height: 43px;
  }
`;

export const Nav = styled.nav`
  align-items: center;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const LinkStyled = styled(NavLink).attrs({
  activeClassName: ActiveLinkClass,
})`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 70px;
  width: 100%;
  min-width: max-content;

  padding: 0 1rem;

  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  font-family: ToyotaDisplay, sans-serif;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #282830;

  transition: border-top 0.2s ease-in, color 0.2s ease-in;

  &:hover, &.${ActiveLinkClass} {
    color: #282830;
    border-top: 3px solid #FF0022;
  }
`;

export const LinkName = styled.span`
  text-transform: capitalize;
  color: inherit;
`;

export const ProfileContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: flex-start;
  flex: 1;
  min-width: min-content;

  svg, a {
    margin-top: 2px;
  }
  
`;

export const ProfileInfo = styled.div`
  display: inline-block
`;

export const ProfileName = styled.div`
    text-decoration: none;

    margin-bottom: 6px;
    text-transform: capitalize;

    font-family: Toyota Display;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: #282830;
`;

export const ProfileRole = styled.div`
  overflow: hidden;
  cursor: pointer;

  max-width: 215px;
  margin-bottom: 0;

  font-family: Toyota Display;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;

  text-align: right;

  color: #A59C9C;
`;

export const UserIcon = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  & .anticon.anticon-user {
    font-size: 16px;
    margin: 0 12px;
  }
`;

export const Logout = styled(Link)`
  background-color: transparent;

  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #B5B5B5;

  &:hover {
    color: #999999;
  }
`;

export const Logo = styled.img.attrs({
  src: ToyotaLogo,
})`
  width: 180px;
`;
