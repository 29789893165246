import API from 'services';

import { extractErrorAndCode } from 'hacks';

import {
  all, fork, put, takeLatest, call, select,
} from 'redux-saga/effects';

import {
  RESTORE_CONFIRM_PASSWORD,
  RESTORE_CONFIRM_PASSWORD__SUCCESS,
  RESTORE_CONFIRM_PASSWORD__FAILURE,
  RESTORE_SEND_OTP,
  RESTORE_SEND_OTP__SUCCESS,
  RESTORE_SEND_OTP__FAILURE,
  RESTORE_CHECK_OTP,
  RESTORE_CHECK_OTP__SUCCESS,
  RESTORE_CHECK_OTP__FAILURE,

  RESTORE_SEND_OTP_PHONE,
  RESTORE_SEND_OTP_PHONE__SUCCESS,
  RESTORE_SEND_OTP_PHONE__FAILURE,

  RESTORE_FINISH,
  RESTORE_FINISH__SUCCESS,
  RESTORE_FINISH__FAILURE,
} from './actions';

const getState = (state) => state.restore;

export function* confirmPassword() {
  yield takeLatest(RESTORE_CONFIRM_PASSWORD, function* ({ token }) {
    try {
      const response = yield call(API.password.confirm, { token });

      if (
        response
        && response.data
        && Array.isArray(response.data)
        && response.data.length === 0
      ) {
        yield put({ type: RESTORE_CONFIRM_PASSWORD__SUCCESS });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: RESTORE_CONFIRM_PASSWORD__FAILURE, error: error.message });
    }
  });
}

export function* sendOtp() {
  yield takeLatest(RESTORE_SEND_OTP, function* ({ login }) {
    try {
      const {
        otp: { token },
      } = yield select(getState);
      let response;

      if (token) {
        response = yield call(API.otp.resend, { token });
      } else {
        response = yield call(API.otp.send, { login });
      }

      if (
        response
        && response.data
        && Array.isArray(response.data.otpList)
        && response.data.otpList.length > 0
      ) {
        const { recipient, token } = response.data.otpList[0];
        const maskedRecipient = typeof recipient === 'string'
          ? recipient.replace(/\d/g, (v, i) => (i > 4 && i < 10 ? '*' : v))
          : '';
        yield put({ type: RESTORE_SEND_OTP__SUCCESS, recipient: maskedRecipient, token });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: RESTORE_SEND_OTP__FAILURE, error: error.message });
    }
  });
}

export function* sendOtpByPhone() {
  yield takeLatest(RESTORE_SEND_OTP_PHONE, function* ({ phone }) {
    try {
      const response = yield call(API.otp.sendByPhone, { phone });

      if (
        response
        && response.data
        && Array.isArray(response.data.otpList)
        && response.data.otpList.length > 0
      ) {
        const { recipient, token } = response.data.otpList[0];
        const maskedRecipient = typeof recipient === 'string'
          ? recipient.replace(/\d/g, (v, i) => (i > 4 && i < 10 ? '*' : v))
          : '';
        yield put({ type: RESTORE_SEND_OTP_PHONE__SUCCESS, recipient: maskedRecipient, token });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: RESTORE_SEND_OTP_PHONE__FAILURE, error: error.message });
    }
  });
}

export function* checkOtp() {
  yield takeLatest(RESTORE_CHECK_OTP, function* ({ code, password }) {
    try {
      const {
        otp: { token },
      } = yield select(getState);
      let response;

      if (password) {
        response = yield call(API.otp.restorePassword, { code, token });
      } else {
        response = yield call(API.otp.check, { code, token });
      }

      if (response && response.data && Array.isArray(response.data.otpList)) {
        yield put({ type: RESTORE_CHECK_OTP__SUCCESS });
      } else {
        const { error } = extractErrorAndCode(response);

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: RESTORE_CHECK_OTP__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: RESTORE_CHECK_OTP__FAILURE, error: error.message });
    }
  });
}

export function* restore() {
  yield takeLatest(RESTORE_FINISH, function* ({ password }) {
    try {
      const {
        otp: { token },
      } = yield select(getState);
      const response = yield call(API.auth.restorePassword, { password, token });

      if (response.data && Array.isArray(response.data.sessionList)) {
        yield put({ type: RESTORE_FINISH__SUCCESS });
      } else {
        const { error } = extractErrorAndCode(response);

        if (
          response
            && response.data
            && response.data.validationErrorList
            && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: RESTORE_FINISH__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: RESTORE_FINISH__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(confirmPassword),
    fork(sendOtp),
    fork(checkOtp),
    fork(restore),
    fork(sendOtpByPhone),
  ]);
}
