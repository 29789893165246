import styled from 'styled-components';

export const Root = styled.div`
  padding: 0rem 4rem 0;
`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const Footer = styled.footer`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 1rem;
`;
