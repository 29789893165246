import styled, { css } from 'styled-components';
import { key } from 'styled-theme';
import { Button } from 'antd';

const HEIGHT_DEFAULT = 'middle';
const HEIGHT = new Map([
  [HEIGHT_DEFAULT, 50],
  ['large', 60],
  ['small', 40],
  ['mini', 34],
]);
const getHeight = (height) => HEIGHT.get(height) || HEIGHT.get(HEIGHT_DEFAULT);

const SIZE_DEFAULT = 'medium';
const SIZE = new Map([
  [
    SIZE_DEFAULT,
    ({ image }) => css`
      ${image
    ? css`
            height: ${getHeight(HEIGHT_DEFAULT)}px;
            width: ${getHeight(HEIGHT_DEFAULT)}px;
            padding: 0;
          `
    : css`
            height: ${getHeight(HEIGHT_DEFAULT)}px;
            min-width: 160px;
            padding: 12px 25px;
          `}

      font-size: 14px;
      line-height: 25px;
    `,
  ],
  [
    'large',
    ({ image }) => css`
      ${image
    ? css`
            height: ${getHeight('large')}px;
            width: ${getHeight('large')}px;
            padding: 0;
          `
    : css`
            height: ${getHeight('large')}px;
            min-width: 200px;
            padding: 17px 25px;
          `}

      font-size: 14px;
      line-height: 25px;
    `,
  ],
  [
    'small',
    ({ image }) => css`
      ${image
    ? css`
            height: ${getHeight('small')}px;
            width: ${getHeight('small')}px;
            padding: 0;
          `
    : css`
            height: ${getHeight('small')}px;
            font-size: 12px;
            min-width: 140px;
          `}
    `,
  ],
  [
    'mini',
    ({ image }) => css`
      ${image
    ? css`
            height: ${getHeight('mini')}px;
            width: ${getHeight('mini')}px;
            padding: 0;
            width: max-content;
          `
    : css`
            height: ${getHeight('mini')}px;
            min-width: 120px;
            width: max-content;
          `}
    `,
  ],
]);
const getSize = ({ size, image }) => (SIZE.get(size) || SIZE.get(SIZE_DEFAULT))({ image });

const SHAPE_DEFAULT = 'round';
const SHAPE = new Map([
  [
    SHAPE_DEFAULT,
    ({ size }) => css`
      border-radius: ${getHeight(size)}px;
    `,
  ],
  [
    'square',
    ({ size }) => css`
      border-radius: ${getHeight(size)}px;
    `,
  ],
]);
const getShape = ({ shape, size }) =>
  (SHAPE.get(shape) || SHAPE.get(SHAPE_DEFAULT))({ size });

const TYPE_DEFAULT = 'default';
const TYPE = new Map([
  [
    TYPE_DEFAULT,
    css`
      color: ${key(['palette', 'grayscale', 7])};
      border-color: ${key(['palette', 'grayscale', 0])};
      border-width: 1px;
      border-style: solid;
      background: ${key(['palette', 'grayscale', 0])};

      transition: color 0.2s ease-in, background 0.2s ease-in, border 0.2s ease-in;

      &:hover,
      &:focus,
      &:active {
        color: ${key(['palette', 'white', 0])};
        background: ${key(['palette', 'red', 1])};
      }

      &[disabled] {
        color: ${key(['palette', 'grayscale', 7])};
        border-color: ${key(['palette', 'grayscale', 0])};
        background: ${key(['palette', 'grayscale', 0])};
      }
    `,
  ],
  [
    'primary',
    css`
      color: ${key(['palette', 'white', 0])};
      border-color: ${key(['palette', 'red', 0])};
      border-width: 1px;
      border-style: solid;
      background: ${key(['palette', 'red', 0])};

      transition: color 0.2s ease-in, background 0.2s ease-in, border 0.2s ease-in;

      &:hover,
      &:focus,
      &:active {
        background: ${key(['palette', 'red', 1])};
      }

      &[disabled] {
        color: ${key(['palette', 'grayscale', 7])};
        border-color: ${key(['palette', 'grayscale', 0])};
        background: ${key(['palette', 'grayscale', 0])};
      }
    `,
  ],
  [
    'ghost',
    css`
      color: ${key(['palette', 'red', 0])};
      border-color: transparent;
      border-width: 1px;
      border-style: solid;
      background: transparent;
      box-shadow: inset 0px 0px 0px 1px ${key(['palette', 'red', 0])};

      transition: color 0.2s ease-in, box-shadow 0.2s ease-in;

      &:hover,
      &:focus,
      &:active {
        box-shadow: inset 0px 0px 0px 2px ${key(['palette', 'red', 1])};
      }

      &[disabled] {
        color: ${key(['palette', 'grayscale', 7])};
        box-shadow: inset 0px 0px 0px 1px ${key(['palette', 'grayscale', 7])};
      }
    `,
  ],
  [
    'dark',
    css`
      color: ${key(['palette', 'white', 0])};
      border-color: ${key(['palette', 'grayscale', 8])};
      border-width: 1px;
      border-style: solid;
      background: ${key(['palette', 'grayscale', 8])};

      transition: color 0.2s ease-in, background 0.2s ease-in, border 0.2s ease-in;

      &:hover,
      &:focus,
      &:active {
        background: ${key(['palette', 'black', 0])};
      }

      &[disabled] {
        color: ${key(['palette', 'grayscale', 7])};
        border-color: ${key(['palette', 'grayscale', 0])};
        background: ${key(['palette', 'grayscale', 0])};
      }
    `,
  ],
  [
    'link',
    css`
      color: ${key(['palette', 'white', 0])};
      border-color: ${key(['palette', 'red', 0])};
      border-width: 1px;
      border-style: solid;
      background: ${key(['palette', 'red', 0])};
      display: flex;
      justify-content: center;
      align-items: center;

      transition: color 0.2s ease-in, background 0.2s ease-in, border 0.2s ease-in;

      &:hover,
      &:focus,
      &:active {
        background: ${key(['palette', 'red', 1])};
      }

      &[disabled] {
        color: ${key(['palette', 'grayscale', 7])};
        border-color: ${key(['palette', 'grayscale', 0])};
        background: ${key(['palette', 'grayscale', 0])};
      }
    `,
  ],
]);
const getType = (type) => TYPE.get(type) || TYPE.get(TYPE_DEFAULT);

const ButtonStyled = styled(Button)`
  &&.ant-btn {
    font-family: "Toyota Type", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;

    ${({ id }) => getType(id)}
    ${({ type }) => getType(type)}
    ${({ size, image }) => getSize({ size, image })}
    ${({ shape, size }) => getShape({ shape, size })}
  }
`;

export { ButtonStyled };
