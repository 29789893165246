/* List */
export const CREDIT_REQUEST_FETCH_LIST = 'CREDIT_REQUEST_FETCH_LIST';
export const CREDIT_REQUEST_FETCH_LIST__SUCCESS = 'CREDIT_REQUEST_FETCH_LIST__SUCCESS';
export const CREDIT_REQUEST_FETCH_LIST__FAILURE = 'CREDIT_REQUEST_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting, withPagination,
} = {}) => ({
  type: CREDIT_REQUEST_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
  withPagination,
});

export const CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST = 'CREDIT_REQUEST_FETCH_LIST__CANCEL_REQUEST';
export const CREDIT_REQUEST_FETCH_LIST__SET_LOADING = 'CREDIT_REQUEST_FETCH_LIST__SET_LOADING';
export const CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION = 'CREDIT_REQUEST_FETCH_LIST__SET_PAGINATION';

export const CREDIT_REQUEST_FETCH_LIST__SET_SEARCH = 'CREDIT_REQUEST_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({
  type: CREDIT_REQUEST_FETCH_LIST__SET_SEARCH,
  search,
});

export const CREDIT_REQUEST_FETCH_LIST__SET_PAGE = 'CREDIT_REQUEST_FETCH_LIST__SET_PAGE';
export const setPage = ({ pagination } = {}) => ({
  type: CREDIT_REQUEST_FETCH_LIST__SET_PAGE,
  pagination,
});

export const CREDIT_REQUEST_FETCH_LIST__SET_SORTING = 'CREDIT_REQUEST_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: CREDIT_REQUEST_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const CREDIT_REQUEST_FETCH_LIST__SET_FILTER = 'CREDIT_REQUEST_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters, withPagination } = {}) => ({
  type: CREDIT_REQUEST_FETCH_LIST__SET_FILTER,
  filters,
  withPagination,
});

export const CREDIT_REQUEST_FETCH_LIST__RESET = 'CREDIT_REQUEST_FETCH_LIST__RESET';
export const reset = () => ({ type: CREDIT_REQUEST_FETCH_LIST__RESET });

/* Item */
export const CREDIT_REQUEST__FETCH_ITEM = 'CREDIT_REQUEST__FETCH_ITEM';
export const CREDIT_REQUEST__FETCH_ITEM_SUCCESS = 'CREDIT_REQUEST__FETCH_ITEM_SUCCESS';
export const CREDIT_REQUEST__FETCH_ITEM_FAILURE = 'CREDIT_REQUEST__FETCH_ITEM_FAILURE';
export const CREDIT_REQUEST__FETCH_ITEM_RESET = 'CREDIT_REQUEST__FETCH_ITEM_RESET';
export const fetchCreditRequest = ({ id = '' } = {}) => ({
  type: CREDIT_REQUEST__FETCH_ITEM,
  id,
});
export const fetchCreditRequestReset = () => ({
  type: CREDIT_REQUEST__FETCH_ITEM_RESET,
});

/* load document */
export const CREDIT_REQUEST__SAVE_DOCUMENT = 'CREDIT_REQUEST__SAVE_DOCUMENT';
export const CREDIT_REQUEST__SAVE_DOCUMENT_SUCCESS = 'CREDIT_REQUEST__SAVE_DOCUMENT_SUCCESS';
export const CREDIT_REQUEST__SAVE_DOCUMENT_FAILURE = 'CREDIT_REQUEST__SAVE_DOCUMENT_FAILURE';
export const saveDocument = ({ document } = {}) => ({ type: CREDIT_REQUEST__SAVE_DOCUMENT, document });
/* remove document */
export const CREDIT_REQUEST__REMOVE_DOCUMENT = 'CREDIT_REQUEST__REMOVE_DOCUMENT';
export const CREDIT_REQUEST__REMOVE_DOCUMENT_SUCCESS = 'CREDIT_REQUEST__REMOVE_DOCUMENT_SUCCESS';
export const CREDIT_REQUEST__REMOVE_DOCUMENT_FAILURE = 'CREDIT_REQUEST__REMOVE_DOCUMENT_FAILURE';
export const removeDocument = ({ document } = {}) => ({ type: CREDIT_REQUEST__REMOVE_DOCUMENT, document });

/* send SMS */
export const CREDIT_REQUEST__SEND_SMS = 'CREDIT_REQUEST__SEND_SMS';
export const CREDIT_REQUEST__SEND_SMS_SUCCESS = 'CREDIT_REQUEST__SEND_SMS_SUCCESS';
export const CREDIT_REQUEST__SEND_SMS_FAILURE = 'CREDIT_REQUEST__SEND_SMS_FAILURE';
export const sendSMS = ({ creditToken, resolve } = {}) => ({
  type: CREDIT_REQUEST__SEND_SMS,
  payload: { creditToken, resolve },
});

export const CREDIT_REQUEST__CREATE = 'CREDIT_REQUEST__CREATE';
export const CREDIT_REQUEST__CREATE_SUCCESS = 'CREDIT_REQUEST__CREATE_SUCCESS';
export const CREDIT_REQUEST__CREATE_FAILURE = 'CREDIT_REQUEST__CREATE_FAILURE';
export const create = () => ({
  type: CREDIT_REQUEST__CREATE,
});
export const CREDIT_REQUEST__RESET = 'CREDIT_REQUEST__RESET';
export const resetForm = (page) => ({ type: CREDIT_REQUEST__RESET, page });

export const CREDIT_REQUEST__EDIT = 'CREDIT_REQUEST__EDIT';
export const CREDIT_REQUEST__EDIT_SUCCESS = 'CREDIT_REQUEST__EDIT_SUCCESS';
export const CREDIT_REQUEST__EDIT_FAILURE = 'CREDIT_REQUEST__EDIT_FAILURE';
export const edit = () => ({
  type: CREDIT_REQUEST__EDIT,
});
