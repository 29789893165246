import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Form, message } from 'antd';
import { debounce, isEqual } from 'underscore';

import { TCM } from 'components/tcm';
import { BigRublerIcon, RublerIcon } from 'icons';
import { findCreditPrograms } from 'services/tcm/creditProgram';
import { Select } from 'components/tcm/Select';

import {
  fetchToyotaModels as fetchToyotaModelsAction,
  fetchLexusModels as fetchLexusModelsAction,
} from 'redux/common/actions';
import {
  reCalc as reCalcAction,
  reCalcReset as reCalcResetAction,
  saveResult as saveResultAction,
  resetResult as resetResultAction,
} from 'redux/tcmCreditCalculator/actions';

import {
  Container,
  Suffix,
  FieldValue,
  FieldTitle,
  Row,
  RowSliderBlocks,
  CurrentCar,
  Brand,
  ChangeCar, ModelSuffix, CarTitleWrapper,
} from 'containers/App/TCM/Credit/style';
import { ErrorContainer } from 'containers/App/TCM/ClientsTable/style';
import { Rifm } from 'rifm';
import { connectStringNumber } from 'helpers/numbers';
import { formFields } from './data';

// Это временное решение  для демо
// TODO: Yes of course
const renderMonthText = (value) => {
  if ((value === 0) || (value >= 5)) {
    return 'месяцев';
  }

  if (value === 1) {
    return 'месяц';
  }

  return 'месяца';
};

const formatInteger = (value) => {
  if (typeof value === 'number') {
    return value.toLocaleString('ru');
  }

  const number = Number.parseFloat(connectStringNumber(value));

  if (Number.isNaN(number)) {
    return '';
  }
  return number.toLocaleString('ru');
};

const onInputKeyDown = (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
  }
};

const brands = [
  { label: 'Toyota', value: 'Toyota' },
  { label: 'Lexus', value: 'Lexus' },
];

function reducer(state, action) {
  switch (action.type) {
    case 'changeCreditProgramId':
      if (state.creditProgramId === action.payload) {
        return state;
      }
      return {
        ...state,
        creditProgramId: action.payload,
      };
    case 'changeCreditAmount':
      if (state.creditAmount === action.payload) {
        return state;
      }
      return {
        ...state,
        creditAmount: action.payload,
      };
    case 'changeBrand':
      if (state.brand === action.payload) {
        return state;
      }
      return {
        ...state,
        brand: action.payload,
      };
    case 'changeModel':
      if (state.model === action.payload) {
        return state;
      }
      return {
        ...state,
        model: action.payload,
      };
    case 'changeFirstPaymentAmount':
      if (state.firstPaymentAmount === action.payload) {
        return state;
      }
      return {
        ...state,
        firstPaymentAmount: action.payload,
      };
    case 'changeMonthlyPaymentAmount':
      if (state.monthlyPaymentAmount === action.payload) {
        return state;
      }
      return {
        ...state,
        monthlyPaymentAmount: action.payload,
      };
    case 'changeTermOfCredit':
      if (state.termOfCredit === action.payload) {
        return state;
      }
      return {
        ...state,
        termOfCredit: action.payload,
      };
    case 'changeDeferredPaymentAmount':
      if (state.deferredPaymentAmount === action.payload) {
        return state;
      }
      return {
        ...state,
        deferredPaymentAmount: action.payload,
      };
    case 'changeAdditionalServicesAmount':
      if (state.additionalServicesAmount === action.payload) {
        return state;
      }
      return {
        ...state,
        additionalServicesAmount: action.payload,
      };
    case 'changeVehiclePrice':
      if (state.vehiclePrice === action.payload) {
        return state;
      }
      return {
        ...state,
        vehiclePrice: action.payload,
      };

    case 'changeRanges':
      return {
        ...state,
        ranges: {
          ...action.payload,
        },
      };
    case 'changeData':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

function CreditForm({
  offer,

  // store
  calculatorData,
  calculatorIsLoading,
  isSaveLoading,
  isSaveLoaded,
  saveError,

  toyotaModels,
  isToyotaModelsLoading,
  isToyotaModelsLoaded,
  // toyotaModelsError,

  lexusModels,
  isLexusModelsLoading,
  isLexusModelsLoaded,
  // lexusModelsError,

  // actions
  fetchToyotaModels,
  fetchLexusModels,
  reCalc,
  reCalcReset,
  saveResult,
  resetResult,
}) {
  const {
    newCarPrice,
    servicesPrice,
    tradeInPrice,
  } = offer.evaluation;

  const {
    campaign: {
      subtopic: {
        name: subtopicName,
        topic: {
          name: topicName,
        },
        dealerCenter,
      },
    },
  } = offer;

  const initialBrand = offer.calculation?.brand || offer.car?.brand || null;
  const initialModel = offer.calculation?.model || offer.car?.model || null;

  const {
    creditAmount: initCreditAmount,
    creditProgramId: initCreditProgramId,
    deferredPaymentAmount: initDeferredPaymentAmount,
    firstPaymentAmount: initFirstPaymentAmount,
    monthlyPaymentAmount: initMonthlyPaymentAmount,
    termOfCredit: initTermOfCredit,
  } = offer.calculation ?? offer.calculations[0] ?? {};

  const {
    vehiclePrice: initVehiclePrice,
    additionalServicesAmount: initAdditionalServicesAmount,
  } = offer.calculation ?? {};

  const [isProgramLoading, setIsProgramLoading] = useState(false);
  const [isInitCalc, setIsInitCalc] = useState(false);
  const [showAdditionalParams, setShowAdditionalParams] = useState(false);
  const [creditProgramOptions, setCreditProgramOptions] = useState([]);

  const [state, dispatch] = useReducer(reducer, {
    creditProgramId: initCreditProgramId ?? null,
    creditAmount: initCreditAmount ?? 1,
    brand: initialBrand ?? topicName ?? '-',
    model: initialModel ?? subtopicName ?? '-',
    firstPaymentAmount: initFirstPaymentAmount ?? 1,
    monthlyPaymentAmount: initMonthlyPaymentAmount ?? 1,
    termOfCredit: initTermOfCredit ?? 1,
    deferredPaymentAmount: initDeferredPaymentAmount ?? 1,
    additionalServicesAmount: initAdditionalServicesAmount ?? servicesPrice ?? 0,
    vehiclePrice: initVehiclePrice ?? newCarPrice ?? 0,
    ranges: {
      termOfCreditRange: [
        0,
        0,
      ],
      firstPaymentAmountRange: [
        0,
        0,
      ],
      monthlyPaymentAmountRange: [
        0,
        0,
      ],
      deferredPaymentAmountRange: [
        0,
        0,
      ],
    },
  });

  const [form] = Form.useForm();

  const {
    creditProgramId,
    creditAmount,
    firstPaymentAmount,
    monthlyPaymentAmount,
    termOfCredit,
    deferredPaymentAmount,
    additionalServicesAmount,
    vehiclePrice,
    brand,
    model,
    ranges: {
      termOfCreditRange,
      firstPaymentAmountRange,
      monthlyPaymentAmountRange,
      deferredPaymentAmountRange,
    },
  } = state;

  const isResidualPayment = deferredPaymentAmountRange !== null;

  const isModelLoading = isLexusModelsLoading || isToyotaModelsLoading;

  const debounceReCalc = useMemo(() => debounce(reCalc, 500),
    [reCalc]);

  const onFieldChange = useCallback((value) => {
    const currentProgramId = value?.creditProgramId ?? creditProgramId;

    const oldData = {
      creditProgramId: creditProgramId ?? 0,
      creditAmount,
      brand,
      model,
      firstPaymentAmount,
      monthlyPaymentAmount,
      termOfCredit,
      deferredPaymentAmount,
      dateOfCurrentCreditEnd: offer.client.dateOfCurrentCreditEnd,
      additionalServicesAmount,
      vehiclePrice,
    };

    const formData = form.getFieldsValue();

    const newData = {
      creditProgramId: formData.creditProgram ?? currentProgramId,
      creditAmount,
      brand: formData.brand ?? brand,
      model: formData.model ?? model,
      firstPaymentAmount: formData.firstPaymentAmount ?? firstPaymentAmount,
      monthlyPaymentAmount: formData.monthlyPaymentAmount ?? monthlyPaymentAmount,
      termOfCredit: formData.termOfCredit ?? termOfCredit,
      deferredPaymentAmount: formData.deferredPaymentAmount ?? deferredPaymentAmount,
      dateOfCurrentCreditEnd: offer.client.dateOfCurrentCreditEnd,
      additionalServicesAmount: value && value?.additionalServicesAmount
        ? Number(connectStringNumber(value.additionalServicesAmount))
        : value?.additionalServicesAmount === '' // При обнуление доп. сервисов посылалось неверное значение TB-6038
          ? 0
          : additionalServicesAmount ?? 0,
      vehiclePrice: value && value?.vehiclePrice
        ? Number(connectStringNumber(value.vehiclePrice))
        : vehiclePrice ?? 0,
    };

    const doReCalc = newData.vehiclePrice
        && typeof newData.additionalServicesAmount === 'number'
        && currentProgramId
        && typeof creditAmount === 'number'
        && creditAmount > 0
        && (!isEqual(oldData, newData) || !calculatorData);

    if (doReCalc) {
      debounceReCalc({ oldData, newData, dealerCenter });
      return;
    }

    if (!creditProgramId && value.creditProgramId) {
      dispatch({
        type: 'changeCreditProgramId',
        payload: value.creditProgramId,
      });
    }
  }, [
    additionalServicesAmount,
    brand,
    calculatorData,
    creditAmount,
    creditProgramId,
    dealerCenter,
    debounceReCalc,
    deferredPaymentAmount,
    firstPaymentAmount,
    form,
    model,
    monthlyPaymentAmount,
    termOfCredit,
    vehiclePrice,
    offer.client,
  ]);

  const handleSliderChange = (v, slider) => {
    dispatch({
      type: `change${slider.slice(0, 1).toUpperCase()}${slider.slice(1)}`,
      payload: v,
    });

    if (state[slider] === v) {
      return;
    }

    onFieldChange();
  };

  const onBrandChange = (value) => {
    dispatch({
      type: 'changeBrand',
      payload: value,
    });

    dispatch({
      type: 'changeModel',
      payload: undefined,
    });

    dispatch({
      type: 'changeCreditProgramId',
      payload: undefined,
    });

    dispatch({
      type: 'changeVehiclePrice',
      payload: 0,
    });
    dispatch({
      type: 'changeAdditionalServicesAmount',
      payload: 0,
    });
  };

  const onModelChange = (value) => {
    dispatch({
      type: 'changeModel',
      payload: value,
    });

    dispatch({
      type: 'changeVehiclePrice',
      payload: 0,
    });
    dispatch({
      type: 'changeAdditionalServicesAmount',
      payload: 0,
    });
  };

  const onCreditProgramChange = (value) => {
    if (creditProgramId) {
      onFieldChange();
    } else if (value) {
      onFieldChange({ creditProgramId: value });
    }
  };

  const onSubmit = () => {
    if (calculatorIsLoading || isSaveLoading) {
      return;
    }

    const data = {
      creditProgramId,
      creditAmount,
      brand,
      model,
      firstPaymentAmount,
      monthlyPaymentAmount,
      termOfCredit,
      deferredPaymentAmount: deferredPaymentAmountRange ? deferredPaymentAmount : undefined,
      vehiclePrice: Number(connectStringNumber(vehiclePrice)),
      additionalServicesAmount: Number(connectStringNumber(additionalServicesAmount)),
      creditProgram: creditProgramOptions.find((opt) => opt.id === creditProgramId)?.fullInternalName ?? '',
      programRate: calculatorData.programRate,
      externalId: offer.externalId,
    };

    saveResult(data);
  };

  const selectedModels = (() => {
    switch (brand) {
      case 'Toyota':
        return toyotaModels;
      case 'Lexus':
        return lexusModels;
      default:
        return [];
    }
  })();

  const isSubmitDisable = !(
    form.getFieldValue(formFields.creditProgram.key)
    && creditAmount
    && vehiclePrice
    && model
  );

  useEffect(() => {
    if (brand === 'Toyota' && !isToyotaModelsLoaded) {
      fetchToyotaModels();
    }
    if (brand === 'Lexus' && !isLexusModelsLoaded) {
      fetchLexusModels();
    }
  }, [
    brand,
    fetchLexusModels,
    fetchToyotaModels,
    isLexusModelsLoaded,
    isToyotaModelsLoaded,
  ]);

  useEffect(() => {
    if (calculatorData) {
      dispatch({
        type: 'changeRanges',
        payload: calculatorData.ranges,
      });

      const {
        vehiclePrice, additionalServicesAmount, ...newData
      } = calculatorData.creditData;

      dispatch({
        type: 'changeData',
        payload: newData,
      });

      form.setFieldsValue({
        [formFields.termOfCredit.key]: calculatorData.creditData?.termOfCredit,
        [formFields.firstPaymentAmount.key]: calculatorData.creditData?.firstPaymentAmount,
        [formFields.deferredPaymentAmount.key]: calculatorData.creditData?.deferredPaymentAmount,
        [formFields.monthlyPaymentAmount.key]: calculatorData.creditData?.monthlyPaymentAmount,
      });
    }
  }, [calculatorData, form]);

  useEffect(() => {
    form.setFieldsValue({
      [formFields.vehiclePrice.key]: vehiclePrice,
      [formFields.additionalServicesAmount.key]: additionalServicesAmount,
      [formFields.creditProgram.key]: creditProgramId,
      [formFields.brand.key]: brand,
      [formFields.model.key]: model,
      [formFields.firstPaymentAmount.key]: firstPaymentAmount,
      [formFields.deferredPaymentAmount.key]: deferredPaymentAmount,
      [formFields.termOfCredit.key]: termOfCredit,
      [formFields.monthlyPaymentAmount.key]: monthlyPaymentAmount,
    });
  }, [
    additionalServicesAmount,
    brand,
    creditProgramId,
    deferredPaymentAmount,
    firstPaymentAmount,
    form,
    model,
    monthlyPaymentAmount,
    termOfCredit,
    vehiclePrice,
  ]);

  useEffect(() => {
    const offerBrand = offer.calculation?.brand || offer.car?.brand || null;
    const offerModel = offer.calculation?.model || offer.car?.model || null;
    if (offerBrand && offerModel) {
      dispatch({
        type: 'changeBrand',
        payload: offerBrand,
      });

      dispatch({
        type: 'changeModel',
        payload: offerModel,
      });

      form.setFieldsValue({
        [formFields.brand.key]: offerBrand,
        [formFields.model.key]: offerModel,
      });
    }
  }, [
    form,
    offer.calculation,
    offer.car,
  ]);

  // TODO: Добавить обработку ошибок (вынести в сагу)
  useEffect(() => {
    if (dealerCenter && brand && model) {
      setIsProgramLoading(true);
      findCreditPrograms({ dealerCenter, brand, model })
        .then((response) => {
          if (response.data.productResponseList) {
            setCreditProgramOptions(
              response.data.productResponseList
                .map((product) => ({
                  ...product,
                  value: product.id,
                  label: product?.fullInternalName || product?.internalName,
                  info: product?.actionDescription,
                })),
            );

            setIsProgramLoading(false);
          }
        });
    }
  }, [brand, creditProgramOptions.length, dealerCenter, model]);

  useEffect(() => {
    if (isSaveLoaded) {
      message.success({
        content: 'Вы успешно сохранили новый расчет',
      });
    }
  }, [isSaveLoaded]);

  useEffect(() => {
    if (initCreditProgramId && isInitCalc === false) {
      setIsInitCalc(true);
      onFieldChange({ creditProgramId: initCreditProgramId });
    }
  }, [initCreditProgramId, isInitCalc, onFieldChange]);

  useEffect(() => () => {
    resetResult();
    reCalcReset();
  }, [reCalcReset, resetResult]);

  return (
    <Container countButton={4}>
      <Form
        form={form}
        name="credit"
        layout="vertical"
        onFinish={onSubmit}
      >
        {!showAdditionalParams
            && (
              <CurrentCar>
                <CarTitleWrapper>
                  <Brand>{brand}</Brand>
                  {' '}
                  <ModelSuffix>{model}</ModelSuffix>
                </CarTitleWrapper>

                <TCM.ButtonOutline
                  widthSize="fixed"
                  onClick={() => setShowAdditionalParams(true)}
                >
                  Изменить
                </TCM.ButtonOutline>

              </CurrentCar>
            )}

        {showAdditionalParams && (
          <ChangeCar>

            <TCM.Label
              name={formFields.brand.key}
              label={formFields.brand.label}
              validateTrigger="onBlur"
            >
              <TCM.Select
                options={brands}
                name={formFields.brand.key}
                onChange={onBrandChange}
              />
            </TCM.Label>

            <TCM.Label
              name={formFields.model.key}
              label={formFields.model.label}
              validateTrigger="onBlur"
            >
              <TCM.Select
                options={selectedModels}
                name={formFields.model.key}
                loading={isModelLoading}
                onChange={onModelChange}
                placeholder="Введите модель"
              />
            </TCM.Label>

            <TCM.Label
              name={formFields.vehiclePrice.key}
              label={formFields.vehiclePrice.label}
              validateTrigger="onBlur"
            >
              <Rifm accept={/[\d]/g} format={formatInteger}>
                {({ value, onChange }) => (
                  <TCM.Input
                    name={formFields.vehiclePrice.key}
                    suffix={<Suffix><RublerIcon /></Suffix>}
                    onKeyDown={onInputKeyDown}
                    onChange={(event) => {
                      onChange(event);
                      onFieldChange({ vehiclePrice: event.currentTarget.value });
                      if (event.currentTarget.value !== vehiclePrice) {
                        dispatch({
                          type: 'changeVehiclePrice',
                          payload: Number(connectStringNumber(event.currentTarget.value)),
                        });
                      }
                    }}
                    value={value ?? ''}
                  />
                )}
              </Rifm>
            </TCM.Label>

            <TCM.Label
              name={formFields.additionalServicesAmount.key}
              label={formFields.additionalServicesAmount.label}
              validateTrigger="onBlur"
            >
              <Rifm accept={/[\d]/g} format={formatInteger}>
                {({ value, onChange }) => (
                  <TCM.Input
                    name={formFields.additionalServicesAmount.key}
                    suffix={<Suffix><RublerIcon /></Suffix>}
                    onKeyDown={onInputKeyDown}
                    onChange={(event) => {
                      onChange(event);
                      onFieldChange({ additionalServicesAmount: event.currentTarget.value });
                      if (event.currentTarget.value !== additionalServicesAmount) {
                        dispatch({
                          type: 'changeAdditionalServicesAmount',
                          payload: Number(connectStringNumber(event.currentTarget.value)),
                        });
                      }
                    }}
                    value={value ?? ''}
                  />
                )}
              </Rifm>
            </TCM.Label>
          </ChangeCar>
        )}

        <Row>
          <TCM.Label
            name={formFields.creditProgram.key}
            label={formFields.creditProgram.label}
            validateTrigger="onBlur"
            gridColumn="1/3"
          >
            <Select
              name={formFields.creditProgram.key}
              placeholder={formFields.creditProgram.placeholder}
              options={creditProgramOptions}
              onChange={onCreditProgramChange}
              loading={isProgramLoading}
              info
            />
          </TCM.Label>

          <div>
            <FieldTitle>Цена Trade-In</FieldTitle>
            {tradeInPrice ? (
              <FieldValue>
                {formatInteger(tradeInPrice)}
                <Suffix>
                  {' '}
                  <RublerIcon />
                </Suffix>
              </FieldValue>
            ) : (
              <FieldValue>Нет данных</FieldValue>
            )}
          </div>

          <div>
            <FieldTitle>Сумма кредита</FieldTitle>
            {typeof Number(creditAmount) === 'number' && creditAmount >= 0
              ? (
                <FieldValue>
                  {formatInteger(creditAmount) || creditAmount}
                  <Suffix>
                    {' '}
                    <RublerIcon />
                  </Suffix>
                </FieldValue>
              ) : (
                <FieldValue>Нет данных</FieldValue>
              )}
          </div>
        </Row>

        <RowSliderBlocks>
          <Form.Item
            name={formFields.termOfCredit.key}
            validateTrigger="onBlur"
            noStyle
          >
            <TCM.SliderBlock
              title={formFields.termOfCredit.label}
              onChange={(v) => {
                handleSliderChange(v, 'termOfCredit');
              }}
              valueText={renderMonthText(termOfCredit)}
              min={termOfCreditRange[0]}
              max={termOfCreditRange[1]}
              name="termOfCredit"
            />
          </Form.Item>

          <Form.Item
            name={formFields.firstPaymentAmount.key}
            validateTrigger="onBlur"
            noStyle
          >
            <TCM.SliderBlock
              title={formFields.firstPaymentAmount.label}
              suffix={<BigRublerIcon />}
              onChange={(v) => {
                handleSliderChange(v, 'firstPaymentAmount');
              }}
              min={firstPaymentAmountRange[0]}
              max={firstPaymentAmountRange[1]}
              name="firstPaymentAmount"
              format={formatInteger}
              step={1000}
              // secondaryValue="65%"
              // secondaryText="от стоимости автомобиля"
            />
          </Form.Item>

          {isResidualPayment
              && (
                <Form.Item
                  name={formFields.deferredPaymentAmount.key}
                  validateTrigger="onBlur"
                  noStyle
                >
                  <TCM.SliderBlock
                    title={formFields.deferredPaymentAmount.label}
                    suffix={<BigRublerIcon />}
                    onChange={(v) => {
                      handleSliderChange(v, 'deferredPaymentAmount');
                    }}
                    min={deferredPaymentAmountRange[0]}
                    max={deferredPaymentAmountRange[1]}
                    name="deferredPaymentAmount"
                    format={formatInteger}
                    step={1000}
                  />
                </Form.Item>
              )}

          <Form.Item
            name={formFields.monthlyPaymentAmount.key}
            validateTrigger="onBlur"
            noStyle
          >
            <TCM.SliderBlock
              gridColumn={isResidualPayment ? '4/5' : '3/5'}
              title={formFields.monthlyPaymentAmount.label}
              suffix={<BigRublerIcon />}
              onChange={(v) => {
                handleSliderChange(v, 'monthlyPaymentAmount');
              }}
              min={monthlyPaymentAmountRange[0]}
              max={monthlyPaymentAmountRange[1]}
              name="monthlyPaymentAmount"
              format={formatInteger}
              step={1000}
            />
          </Form.Item>
        </RowSliderBlocks>
        <TCM.Button
          loading={isSaveLoading}
          htmlType="submit"
          disabled={isSubmitDisable}
        >
          Сохранить расчет
        </TCM.Button>
        <ErrorContainer>
          {Boolean(saveError) && saveError}
        </ErrorContainer>
      </Form>
    </Container>
  );
}

const ConnectFormWithRedux = connect(
  (state) => ({
    calculatorData: state.tcmCreditCalculator.reCalc.data,
    calculatorIsLoading: state.tcmCreditCalculator.reCalc.isLoading,

    isSaveLoading: state.tcmCreditCalculator.saveResult.isLoading,
    isSaveLoaded: state.tcmCreditCalculator.saveResult.isLoaded,
    saveError: state.tcmCreditCalculator.saveResult.error,

    toyotaModels: state.common.toyotaModels.list,
    isToyotaModelsLoading: state.common.toyotaModels.isLoading,
    isToyotaModelsLoaded: state.common.toyotaModels.isLoaded,
    toyotaModelsError: state.common.toyotaModels.error,

    lexusModels: state.common.lexusModels.list,
    isLexusModelsLoading: state.common.lexusModels.isLoading,
    isLexusModelsLoaded: state.common.lexusModels.isLoaded,
    lexusModelsError: state.common.lexusModels.error,
  }),
  {
    fetchToyotaModels: fetchToyotaModelsAction,
    fetchLexusModels: fetchLexusModelsAction,
    reCalc: reCalcAction,
    reCalcReset: reCalcResetAction,
    saveResult: saveResultAction,
    resetResult: resetResultAction,
  },
)(CreditForm);

export { ConnectFormWithRedux as CreditForm };
