import React, { useEffect, useReducer, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import AutoCompleteCustom, { Option } from 'components/AutoComplete';
import API from 'services';
import { useDebounce } from 'hooks';
import { formatFiasCompany } from 'hacks';
import { DEBOUNCE_SEARCH_TIME } from 'settings';

import {
  Active, Address, Content, Description, Inn, NonActive, Root,
} from './style';

const initialState = {
  search: null,
  isFocused: false,
  isFetching: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

const STATUS = {
  ACTIVE: 'ACTIVE',
  LIQUIDATING: 'LIQUIDATING',
  LIQUIDATED: 'LIQUIDATED',
  REORGANIZING: 'REORGANIZING',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FOCUS: {
      return {
        ...state,
        isFocused: true,
      };
    }
    case ACTIONS.BLUR: {
      return {
        ...state,
        isFocused: false,
      };
    }
    case ACTIONS.FETCH: {
      return {
        ...state,
        isFetching: true,
        search: action.search,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function OrganisationField({
  // passed
  name,
  value: initialValue,
  placeholder = 'Выберите организацию из списка',
  onChange,
  disabled,
  allowDisabledOptions,
  isUnique = true,
}) {
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState(
    typeof initialValue === 'string' ? initialValue : undefined,
  );
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_SEARCH_TIME);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (initialValue && typeof initialValue === 'string' && state.list.length > 0) {
      const organization = state.list.find((item) => item.inn === initialValue);
      if (organization) {
        setDescription(organization.inn);
        setSearchTerm(organization.name);
      }
    }
  }, [setSearchTerm, setDescription, initialValue, state]);

  useEffect(() => {
    if (debouncedSearchTerm !== state.search) {
      dispatch({ type: ACTIONS.FETCH, search: debouncedSearchTerm });
      API.fias
        .fetchFIAS({
          alias: 'organization',
          value: debouncedSearchTerm,
          dependencies: {
            unique: isUnique,
          },
        })
        .then((results) => {
          if (!results.errorCode) {
            dispatch({ type: ACTIONS.FETCH_SUCCESS, list: formatFiasCompany(results, true) });
          } else if (
            typeof debouncedSearchTerm !== 'string'
            || (typeof debouncedSearchTerm === 'string' && debouncedSearchTerm.length < 3)
          ) {
            throw new Error('');
          } else {
            throw new Error('Сервис автоматического заведенияне доступен. Попробуйте еще раз или введите название вручную');
          }
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
        });
    }
  }, [isUnique, state.search, debouncedSearchTerm]);

  function handleChange(v) {
    setSearchTerm(v);
    const item = state.list.find((e) => e.value === v) || {};
    if (item && item.additionalValues?.inn) {
      setDescription(item.additionalValues.inn);
    } else {
      setDescription('');
      onChange({ name, value: '', additionalValues: {} });
    }
  }

  function handleSelect(v, props) {
    let item;
    const { key } = props || {}; // если есть GUID искать нужно по нему
    item = state.list.find((e) => e.GUID === key && e.value === v) || {};
    if (!item) {
      item = state.list.find((e) => e.value === v) || {};
    }

    setSearchTerm(v);
    setDescription(item.additionalValues?.inn || '');
    onChange({ name, value: v, additionalValues: item.additionalValues });
  }

  function handleFocus() {
    dispatch({ type: ACTIONS.FOCUS });
  }

  function handleOnBlur() {
    if (initialValue !== searchTerm) {
      const item = state.list.find((e) => {
        const { value, additionalValues } = e;
        if (additionalValues?.inn && description) {
          return value === searchTerm && additionalValues?.inn === description;
        }
        return value === searchTerm;
      });

      if (item && item.additionalValues?.inn) {
        setDescription(item.additionalValues.inn);
        onChange({ name, value: searchTerm, additionalValues: item.additionalValues });
      } else {
        setDescription('');
        onChange({ name, value: '', additionalValues: {} });
      }
    }
  }

  return (
    <Root>
      <AutoCompleteCustom
        name={name}
        value={searchTerm}
        placeholder={placeholder}
        onChange={handleChange}
        onSelect={handleSelect}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
        disabled={disabled}
        loading={state.isFetching}
        notFoundContent={
          state.error ? (
            <span>{state.error}</span>
          ) : state.isFetching ? (
            <LoadingOutlined />
          ) : (typeof searchTerm !== 'string' || (searchTerm && searchTerm.length < 3)) ? (
            <sup>[запрос должен содержать от 3-ех символов]</sup>
          ) : (
            <span>Неизвестная организация. Попробуйте еще раз или введите название вручную</span>
          )
        }
      >
        {state.list.map((v, index) => (
          <Option
            key={v.GUID || v.value + index}
            value={v.value}
            disabled={allowDisabledOptions ? false : v.additionalValues?.status !== STATUS.ACTIVE}
          >
            <Content>
              {v.additionalValues?.status === STATUS.ACTIVE ? (
                <Active>{v.value}</Active>
              ) : (
                <NonActive>{v.value}</NonActive>
              )}
              {v.additionalValues?.text && (
                <Inn>
                  Адрес:
                  {'\u00a0'}
                  <strong>{v.additionalValues?.text}</strong>
                </Inn>
              )}
              {v.additionalValues?.inn && (
                <Address>
                  ИНН:
                  {'\u00a0'}
                  <strong>{v.additionalValues.inn}</strong>
                </Address>
              )}
            </Content>
          </Option>
        ))}
      </AutoCompleteCustom>
      {description && (
        <Description>
          Найдена компания с ИНН:
          {'\u00a0'}
          <strong>{description}</strong>
        </Description>
      )}
    </Root>
  );
}

export default OrganisationField;
