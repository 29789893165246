import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { connect } from 'react-redux';

import {
  exportFile as exportFileAction,
  exportFileReset as exportFileResetAction,
  sendFileToEmail as sendFileToEmailAction,
  sendFileToEmailReset as sendFileToEmailResetAction,
} from 'redux/transaction/actions';

import { TCM } from 'components/tcm';
import Button from 'components/Button';

import {
  ButtonsContainer,
  EmailTag,
  ErrorMessage,
  Footer,
  InputContainer,
  InputLabel,
  LoadingLoader,
  LoadingMessage,
  RadioGroup,
  Root,
  SubTitle,
  SuccessMessage,
  Title,
  WrongEmail,
} from './style';

const { Radio } = TCM;

function isEmailsValid(emailString) {
  const emailObject = {
    valid: [],
    notValid: [],
  };
  const emailArr = emailString.replace(/\s+/g, '').split(';').filter((i) => i);
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailArr.forEach((email) => {
    if (re.test(email)) { emailObject.valid.push(email); } else { emailObject.notValid.push(email); }
  });
  return (emailObject);
}

const SuccessMessageGenerator = (current, max, message) => (
  <SuccessMessage>
    {message}
    Выгружено
    {' '}
    {current > max ? (
      ' первые 1000 записей из найденных, при необходимости ограничьте поиск'
    ) : (
      `${current} элементов`)}
  </SuccessMessage>
);

const downloadURL = (fileUrl) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();
};

function DownloadModal({
  // passed
  close,
  visible,

  // store
  exportFileData,
  exportFileIsLoading,
  exportFileIsLoaded,
  exportFileError,

  sendFileData,
  sendFileIsLoading,
  sendFileIsLoaded,
  sendFileError,

  // actions
  sendFileToEmail,
  sendFileToEmailReset,
  exportFile,
  exportFileReset,
}) {
  const [fileFormat, setFileFormat] = useState('xls');
  const [validEmails, setValidEmails] = useState(new Set());
  const [invalidEmails, setInvalidEmails] = useState(new Set());
  const [emailInput, setEmailInput] = useState('');

  const { currentCountExport: expCurCnt, maxCountExport: expMaxCnt } = exportFileData;
  const { currentCountExport: sendCurCnt, maxCountExport: sendMaxCnt } = sendFileData;

  const isLoading = exportFileIsLoading || sendFileIsLoading;
  const error = exportFileError || sendFileError;
  const downloadSuccessMessage = 'Список заказов успешно выгружен. ';
  const emailSuccessMessage = 'Список заказов успешно отправлен на e-mail. ';

  const checkEmails = (e) => {
    if (!e.target.value) { return; }

    const parsedEmails = isEmailsValid(e.target.value);
    const newValidEmails = parsedEmails.valid;
    const newInvalidEmails = parsedEmails.notValid;

    setValidEmails(
      new Set([...validEmails, ...newValidEmails]),
    );

    setInvalidEmails(
      new Set(newInvalidEmails),
    );

    setEmailInput(
      newInvalidEmails.join(';'),
    );
  };

  const handleBlur = (e) => {
    checkEmails(e);
  };

  const handleChange = ({ target: { value } }) => {
    if (!value) {
      setInvalidEmails(new Set());
    }
    setEmailInput(value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      checkEmails(e);
    }
  };

  const removeTag = (email) => {
    const newMails = new Set(validEmails);
    newMails.delete(email);
    setValidEmails(newMails);
  };

  const setToDefault = () => {
    close();
    setEmailInput('');
    setValidEmails(new Set());
    setInvalidEmails(new Set());
    sendFileToEmailReset();
    exportFileReset();
  };

  const handleSendToEmails = () => {
    if (validEmails.size > 0) {
      sendFileToEmail({
        fileFormat,
        recipients: [...validEmails],
      });
    }
  };

  const handleDownloadFile = () => {
    exportFile({
      fileFormat,
    });
  };

  useEffect(() => {
    if (exportFileIsLoaded) {
      const { url } = exportFileData;
      downloadURL(url);
    }
  }, [exportFileIsLoaded, exportFileData]);

  return (
    <Root
      visible={visible}
      onCancel={setToDefault}
      footer={null}
      width={768}
    >
      <Title> Выгрузка операций</Title>
      {isLoading ? (
        <LoadingMessage>
          <LoadingLoader
            height={100}
            text="Формирование списка платежей может занять до 1-2 минут, пожалуйста ожидайте"
          />
        </LoadingMessage>
      ) : (
        <>
          <SubTitle>Формат выгрузки</SubTitle>
          <RadioGroup
            onChange={(e) => setFileFormat(e.target.value)}
            value={fileFormat}
          >
            <Radio value="xls">XLS</Radio>
            <Radio value="csv">CSV</Radio>
            <Radio value="pdf">PDF</Radio>
          </RadioGroup>
          <InputContainer>
            <InputLabel>
              Email  для отправки
            </InputLabel>
            {validEmails.size > 0 && [...validEmails].map((email) => (
              <EmailTag closable onClose={() => removeTag(email)} key={email}>
                {email}
              </EmailTag>
            ))}
            <Input
              name="email"
              onBlur={handleBlur}
              onKeyDown={handleEnterKeyPress}
              onChange={handleChange}
              placeholder="Введите адреса эл.почты ( перечислите через ; )"
              value={emailInput}
            />
            {emailInput && invalidEmails.size > 0
            && (
              <WrongEmail>
                {' '}
                Невалидный E-mail:
                {[...invalidEmails].map((invalidEmail) => (
                  ` ${invalidEmail}`
                ))}
              </WrongEmail>
            )}
          </InputContainer>

          <Footer>
            <ButtonsContainer>
              <Button
                type="ghost"
                onClick={handleDownloadFile}
                loading={exportFileIsLoading}
                disabled={sendFileIsLoading}
              >
                Выгрузить
              </Button>
              <Button
                type="primary"
                onClick={handleSendToEmails}
                loading={sendFileIsLoading}
                disabled={invalidEmails.size > 0 || !validEmails.size > 0 || exportFileIsLoading}
              >
                Отправить
              </Button>
            </ButtonsContainer>

            <ErrorMessage>
              {error}
            </ErrorMessage>
            {exportFileIsLoaded && SuccessMessageGenerator(expCurCnt, expMaxCnt, downloadSuccessMessage)}
            {sendFileIsLoaded && SuccessMessageGenerator(sendCurCnt, sendMaxCnt, emailSuccessMessage)}
          </Footer>
        </>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    exportFileData: state.transaction.exportFile.data,
    exportFileIsLoading: state.transaction.exportFile.isLoading,
    exportFileIsLoaded: state.transaction.exportFile.isLoaded,
    exportFileError: state.transaction.exportFile.error,

    sendFileData: state.transaction.sendFile.data,
    sendFileIsLoading: state.transaction.sendFile.isLoading,
    sendFileIsLoaded: state.transaction.sendFile.isLoaded,
    sendFileError: state.transaction.sendFile.error,

  }),
  {
    sendFileToEmail: sendFileToEmailAction,
    sendFileToEmailReset: sendFileToEmailResetAction,
    exportFile: exportFileAction,
    exportFileReset: exportFileResetAction,
  },
)(DownloadModal);
