import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Search } from 'components/Input';
import tableActions from 'redux/table/actions';
import QS from 'query-string';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';
import { Header } from './style';

import Table from './Table';

const { setSearch: setSearchAction } = tableActions;

function List({
  search, setSearch, history, location, setBreadcrumbs,
}) {
  const [value, setValue] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Онлайн заявка', link: '/app/leads' },
        { level: 1, name: 'Все заявки', link: '/app/leads/request/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    const s = QS.parse(location.search);
    const searchQuery = s.search || '';
    if (searchQuery !== search) {
      setValue(searchQuery);
      setSearch({ search: searchQuery });
    }
  }, [setSearch, search, location.search]);

  function handleOnSearch(v) {
    setSearch({ search: v });
    history.push({ search: QS.stringify({ search: v }) });
  }

  function handleOnBlur() {
    if (search !== value) {
      setSearch({ search: value });
      history.push({ search: QS.stringify({ search: value }) });
    }
  }

  return (
    <>
      <Header>
        <Search
          allowClear
          placeholder="Введите номер заявки, ФИО или номер телефона клиента"
          value={value}
          onChange={({ target: { value } }) => setValue(value)}
          onSearch={handleOnSearch}
          onBlur={handleOnBlur}
        />
      </Header>
      <Table />
    </>
  );
}

export default connect(
  (state) => ({
    search: state.table.search,
  }),
  {
    setSearch: setSearchAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(List);
