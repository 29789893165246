import {
  COMPANY_FETCH_LIST,
  COMPANY_FETCH_LIST__SUCCESS,
  COMPANY_FETCH_LIST__FAILURE,
  COMPANY_FETCH_LIST__SET_LOADING,
  COMPANY_FETCH_LIST__SET_PAGINATION,
  COMPANY_FETCH_LIST__SET_SEARCH,
  COMPANY_FETCH_LIST__SET_SORTING,
  COMPANY_FETCH_LIST__SET_FILTER,
  COMPANY_FETCH_LIST__RESET,

  COMPANY_FETCH_ITEM,
  COMPANY_FETCH_ITEM__SUCCESS,
  COMPANY_FETCH_ITEM__FAILURE,
  COMPANY_FETCH_ITEM__RESET,

  COMPANY_CREATE,
  COMPANY_CREATE__SUCCESS,
  COMPANY_CREATE__FAILURE,
  COMPANY_CREATE__RESET,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {};

const initState = {
  collection: {
    data: {},
    list: [],
    dealerCenterList: [],
    pagination: {},
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  item: {
    id: null,
    data: {},
    subsidiaries: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  create: {
    data: {},
    form: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
};

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case COMPANY_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case COMPANY_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case COMPANY_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case COMPANY_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case COMPANY_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case COMPANY_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case COMPANY_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case COMPANY_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          pagination: action.pagination || {},
          page: (action.pagination && action.pagination.page) || 1,
          list: action.list,
          dealerCenterList: action.dealerCenterList,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case COMPANY_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case COMPANY_FETCH_ITEM: {
      return {
        ...state,

        item: {
          ...initState.item,
          id: action.id,
          isLoading: true,
        },
      };
    }
    case COMPANY_FETCH_ITEM__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          data: action.data,
          subsidiaries: action.subsidiaries,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case COMPANY_FETCH_ITEM__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case COMPANY_FETCH_ITEM__RESET: {
      return {
        ...state,

        item: {
          ...initState.item,
        },
      };
    }

    case COMPANY_CREATE: {
      return {
        ...state,

        create: {
          data: {},
          form: {
            name: action.name,
            fullName: action.fullName,
            inn: action.inn,
            ogrn: action.ogrn,
            kpp: action.kpp,
            dateRegistration: action.dateRegistration,
            okved: action.okved,
            okpo: action.okpo,
            oktmo: action.oktmo,
            individualSpruceOrgan: action.individualSpruceOrgan,
            address: action.address,
            postAddress: action.postAddress,
            phone: action.phone,
            fax: action.fax,
            additionalInfo: action.additionalInfo,
          },
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case COMPANY_CREATE__SUCCESS: {
      return {
        ...state,

        // cache updated/created data for company
        item: {
          ...state.item,
          data: action.data,
          subsidiaries: action.subsidiaries,
        },

        create: {
          ...state.create,
          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case COMPANY_CREATE__FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,
          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }
    case COMPANY_CREATE__RESET: {
      return {
        ...state,

        create: {
          ...initState.create,
        },
      };
    }

    default: {
      return state;
    }
  }
}
