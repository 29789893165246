import moment from 'moment';
import {
  CAMPAIGN_TIMESTAMPS, DATE_FORMAT,
} from 'tcm/campaigns/data';
import { convertResponseDateToMoment, getStringFromDate } from 'helpers/date';
import { CHAR } from 'settings/namedUnicode';
import { OfferStatus } from 'tcm/offer/data';

export const UserRole = {
  Employee: 'EMPLOYEE',
  Manager: 'MANAGER',
};
const StatusTitle = {
  EVALUATION: {
    manager: `Передан${CHAR.NBSP}в${CHAR.NBSP}ДЦ`,
    employee: `Передан${CHAR.NBSP}в${CHAR.NBSP}ДЦ`,
  },
  CALLING: {
    manager: `Обзвон${CHAR.NBSP}клиентов`,
    employee: `В${CHAR.NBSP}работе`,
  },
  WAIT_CONFIRM: {
    manager: `Ожидает${CHAR.NBSP}подтверждения`,
    employee: `Ожидает${CHAR.NBSP}подтверждения`,
  },
  CONFIRMED: {
    manager: 'Подтвержден',
    employee: 'Подтвержден',
  },
  REVISION: {
    manager: 'Доработка',
    employee: 'Доработка',
  },
  DISTRIBUTION: {
    manager: 'Распределение',
    employee: 'Распределение',
  },
};

const getTitleFromStatus = (status, role) => {
  switch (role) {
    case UserRole.Employee:
      return StatusTitle[status].employee;
    default:
      return StatusTitle[status].manager;
  }
};

const getOfferMessageFromCode = (statusCode, deadline, role) => {
  const date = convertResponseDateToMoment(deadline)?.format('DD.MM.YYYY');
  switch (role) {
    case UserRole.Manager:
      switch (statusCode) {
        case OfferStatus.Evaluation:
          return `Назначте${CHAR.NBSP}сотрудника${CHAR.NBSP}до:${CHAR.NBSP}${date}`;
        case OfferStatus.Calling:
          return `Ожидаемая${CHAR.NBSP}дата${CHAR.NBSP}получения${CHAR.NBSP}результата обзвона${CHAR.NBSP}от${CHAR.NBSP}сотрудника:${CHAR.NBSP}${date}`;
        case OfferStatus.WaitConfirm:
          return `Подвердите${CHAR.NBSP}результат${CHAR.NBSP}обзвона${CHAR.NBSP}до:${CHAR.NBSP}${date}`;
        case OfferStatus.Rejected:
          return `Ожидаемая${CHAR.NBSP}дата${CHAR.NBSP}получения${CHAR.NBSP}результата доработки${CHAR.NBSP}от${CHAR.NBSP}сотрудника:${CHAR.NBSP}${date}`;
        case OfferStatus.Revision:
          return 'Направлен на доработку';
        case OfferStatus.Confirmed:
          return;
        default:
          console.error('Wrong code of message status!');
          return '';
      }
    case UserRole.Employee:
      switch (statusCode) {
        case OfferStatus.Evaluation:
          return '';
        case OfferStatus.Calling:
          return `Отправьте${CHAR.NBSP}результат обзвона${CHAR.NBSP}до:${CHAR.NBSP}${date}`;
        case OfferStatus.WaitConfirm:
          return `Ожидаемая${CHAR.NBSP}дата${CHAR.NBSP}получения${CHAR.NBSP}подтверждения:${CHAR.NBSP}${date}`;
        case OfferStatus.Rejected:
          return `Отправьте${CHAR.NBSP}результат обзвона${CHAR.NBSP}до:${CHAR.NBSP}${date}`;
        case OfferStatus.Confirmed:
          return '';
        default:
          console.error('Wrong code of message status!');
          return '';
      }
    default:
      console.error('Unknown user role value!');
      return '';
  }
};

export const formatOffersList = (list) => list
  .filter((item) => item.car !== null)
  .map((item) => ({ ...item }));

const getFormattedStatus = (status, deadline, role) => ({
  code: status.status,
  title: getTitleFromStatus(status.status, role),
  createdAt: status.createdAt,
  deadline: deadline && moment(deadline).isValid()
    ? moment(deadline).format('DD.MM.YYYY')
    : moment().add(3, 'days').format('DD.MM.YYYY'),
  timestamp: status.isReal ? CAMPAIGN_TIMESTAMPS.DONE : CAMPAIGN_TIMESTAMPS.PENDING,
  popUpText: getOfferMessageFromCode(status.status, deadline, role),
});

const modifiedStatus = (status, offer, role) => {
  const modifiedCurrent = getFormattedStatus(status, offer.deadline, role);
  modifiedCurrent.timestamp = modifiedCurrent.code === offer.status
    ? CAMPAIGN_TIMESTAMPS.CURRENT
    : modifiedCurrent.timestamp;

  modifiedCurrent.createdAt = [getStringFromDate(new Date(modifiedCurrent.createdAt), DATE_FORMAT.DD_MM)];

  return modifiedCurrent;
};

export const formatOffer = (rawData, role = UserRole.Manager) => {
  if (typeof rawData === 'object') {
    try {
      return {
        ...rawData,
        statusName: getTitleFromStatus(rawData.status, role),
        statusHistory: rawData.statusHistory.reduce((prev, curr) => {
          const modifiedCurrent = modifiedStatus(curr, rawData, role);

          const repeatedIndex = prev.findIndex((status) => status.code === modifiedCurrent.code);
          if (repeatedIndex !== -1) {
            const repeated = prev[repeatedIndex];
            const leftOther = prev.slice(0, repeatedIndex);

            const rightOther = prev.slice(repeatedIndex + 1);
            const modifiedRepeated = [repeated, modifiedCurrent].reduce((prev, curr) => ({
              ...prev,
              createdAt: prev.createdAt.concat([curr.createdAt]),
            }));
            return leftOther.concat(modifiedRepeated).concat(rightOther);
          }

          if (prev[prev.length - 1]?.code === OfferStatus.WaitConfirm
            && prev[prev.length - 1]?.timestamp === CAMPAIGN_TIMESTAMPS.CURRENT
            && modifiedCurrent.code === OfferStatus.Revision
          ) {
            return [...prev.slice(0, -1), modifiedCurrent, ...prev.slice(-1)];
          }

          return [...prev, modifiedCurrent];
        }, []),

      };
    } catch (e) {
      console.error(`formatOffer: invalid data. ${e.name}: ${e.message}`);
      return {};
    }
  }
  console.error('formatOffer: type of "data" parameter must be "Object"!');
  return {};
};
