export const PROPERTY_TYPE = {
  no_name: {
    responseKey: 'no_name',
    key: 'no_name',
  },
  payment_status_url: {
    responseKey: 'payment_status_url',
    key: 'payment_status_url',
  },
  sector: {
    responseKey: 'sector',
    key: 'sector',
  },
  shop_email: {
    responseKey: 'shop_email',
    key: 'shop_email',
  },
  FULL_NAME: {
    responseKey: 'FULL_NAME',
    key: 'fullName',
  },
  OGRN: {
    responseKey: 'OGRN',
    key: 'ogrn',
  },
  KPP: {
    responseKey: 'KPP',
    key: 'kpp',
  },
  DATE_REGISTRATION: {
    responseKey: 'DATE_REGISTRATION',
    key: 'date_registration',
  },
  OKVED: {
    responseKey: 'OKVED',
    key: 'okved',
  },
  OKPO: {
    responseKey: 'OKPO',
    key: 'okpo',
  },
  OKTMO: {
    responseKey: 'OKTMO',
    key: 'oktmo',
  },
  INDIVIDUAL_SPRUCE_ORGAN: {
    responseKey: 'INDIVIDUAL_SPRUCE_ORGAN',
    key: 'individualSpruceOrgan',
  },
  POST_ADDRESS: {
    responseKey: 'POST_ADDRESS',
    key: 'postAddress',
  },
  PHONE: {
    responseKey: 'PHONE',
    key: 'phone',
  },
  FAX: {
    responseKey: 'FAX',
    key: 'fax',
  },
  ADDITIONAL_INFO: {
    responseKey: 'ADDITIONAL_INFO',
    key: 'additionalInfo',
  },
  OPF: {
    responseKey: 'OPF',
    key: 'opf',
  },
};

const flattenProperties = (properties) => {
  const data = Object.create(null);

  Object.values(PROPERTY_TYPE).forEach(({ responseKey, key }) => {
    const property = properties.find((e) => e.propertyName === responseKey);
    if (property && property.propertyValue) {
      data[key] = property.propertyValue;
    }
  });

  return data;
};

export const formatCompany = (data) => {
  // I literally have no idea why we have to send an array with one object in it
  // instead of just sending one object, but whatever, deal with it
  if (
    Array.isArray(data.legalEntityResponseList)
    && data.legalEntityResponseList.length === 1
  ) {
    const item = data.legalEntityResponseList[0];

    return {
      address: item.addressactual,
      createdAt: item.createdAt,
      email: item.email,
      id: item.id,
      inn: item.inn,
      name: item.name,
      updatedAt: item.updatedAt,
      ...(data.propertyResponseList ? flattenProperties(data.propertyResponseList) : {}),
    };
  }

  return {};
};
