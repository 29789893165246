import styled from 'styled-components';
import { PageTitle } from '../style';

export const Wrapper = styled.div`
 margin-top: 22px;
 margin-bottom: 60px;
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 12px;
`;

export const FormButtonsStroke = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`;

export const Title = styled(PageTitle)`
  margin-top: 45px;
  margin-bottom: 35px;
`;

export const EmptyTableContent = styled.div`
  position: relative;
  margin-top: 52px;
  font-family: 'Toyota Type', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  color: #000000;
  p {
    margin-bottom: 1em;
  }
  
  svg {
    position: absolute;
    top: -40px;
    right: 100px;
  }
`;

export const StatusHistoryContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 35px;
`;

export const ReadOnlyFieldContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  align-items: center;
  flex-direction: row;
  margin-bottom: 25px;
`;

export const ReadOnlyField = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`;

export const ReadOnlyFieldTitle = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #A8AAAC;
`;

export const CommentsContainer = styled.div`
     display: grid;
      grid-gap: 20px;
      align-items: center;
      padding-bottom: 45px;
      padding-top: 20px;
`;

export const CommentContainer = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: center;
`;

export const CommentText = styled.div`
     display: grid;
     align-items: center;
     grid-gap: 20px;
     grid-template-columns: 1fr 1fr;
`;
