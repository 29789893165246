import styled, { css } from 'styled-components';
import React from 'react';
import { Select as AntSelect, Popover, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ArrowIcon, InfoIconSecond } from 'icons';
import { Label } from './Label';
import withTheme from './withTheme';

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 30px;
  grid-column-gap: 10px;
`;

const InfoLabel = styled.div`
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
`;

const InfoIconContainer = styled.div.attrs({ className: 'info-icon-container' })`
  display: none;
`;

const Option = withTheme(styled(AntSelect.Option)`
    font-family: ${({ theme }) => theme.fontFamily};
`);

const PopupContainer = styled.div.attrs({ className: 'popup__container' })`
        position: relative;
        padding: 10px;
        max-width: 350px;
    `;

const PopupContent = styled.div.attrs({ className: 'popup__content' })`
        font-family: ToyotaDisplay, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #969595;
  `;

Option.isSelectOption = true;
const Select = withTheme(
  styled(({ theme, options, ...p }) => (
    <AntSelect {...p}>
      {options ? options.map((option) => (
        p.info ? (
          <Option
            key={option.value}
            value={option.value}
          >
            <InfoContainer>
              <InfoLabel>
                <Tooltip text={option.label}>
                  {option.label}
                </Tooltip>
              </InfoLabel>
              <InfoIconContainer>
                <Popover
                  content={(
                    <PopupContainer>
                      <PopupContent>{option.info}</PopupContent>
                    </PopupContainer>
                  )}
                >
                  {InfoIconSecond()}
                </Popover>
              </InfoIconContainer>
            </InfoContainer>
          </Option>
        ) : (
          <Option
            key={option.value}
            value={option.value}
          >
            {option.label || option.value}
          </Option>
        )
      )) : p.children}
    </AntSelect>
  )).attrs(({ loading }) => ({
    suffixIcon: loading ? <LoadingOutlined /> : <ArrowIcon down />,
    dropdownClassName: 'tcm-option-list',
    dropdownAlign: {
      points: ['tl', 'bl'],
      offset: [0, -1],
    },
    getPopupContainer: (trigger) => trigger.parentElement,
  }))`
  ${({ theme }) => css`
    
    width: 100%;
    color: ${theme.select.textColor};
    box-sizing: border-box;
    
    .ant-select-selection-item{
      .info-icon-container{
        display: block;
      }
    }
    
    &.ant-select {
    font-family: ${theme.fontFamily};
      &.ant-select-open > .ant-select-selector{
        border-radius: ${theme.select.borderOpenRadius};
      }

      & .ant-select-arrow svg {
        transition: transform 150ms ease-in-out;
      }

      &.ant-select-open .ant-select-arrow svg {
        transform: rotate(180deg);
      }
      & .ant-select-clear{
        right: 45px;
      }
    }

    &.ant-select > .ant-select-selector {
   
      padding: ${theme.select.padding};
      height: 50px;
      border-color: ${theme.select.borderColor};
      border-radius: ${theme.select.borderRadius}px;
      line-height: unset;

      & > .ant-select-selection-search {
        padding: 0;
        font-weight: ${theme.select.fontWeight};
        font-size: ${theme.select.fontSize};
        line-height: ${theme.select.lineHeight};
      }

      & > .ant-select-selection-item{
        padding-bottom: 0;
        font-weight: ${theme.select.fontWeight};
        font-size: ${theme.select.fontSize};
        line-height: ${theme.select.lineHeight};
        text-overflow: ellipsis;
      }

      & > .ant-select-selection-placeholder {
        padding: 0;
        font-weight: ${theme.select.fontWeightPlaceholder};
        font-size: ${theme.select.fontSize};
        line-height: ${theme.select.lineHeight};
        color: ${theme.select.textPlaceholderColor};
        opacity: 1;
      }
    }

    & .ant-select-arrow {
      right: 20px;
    `}
`,
);

const LabeledSelect = ({ label, ...p }) => (
  <Label label={label}>
    <Select {...p} />
  </Label>
);

export { Select, LabeledSelect, Option };
