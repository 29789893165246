/* List */
export const USER_FETCH_LIST = 'USER_FETCH_LIST';
export const USER_FETCH_LIST__SUCCESS = 'USER_FETCH_LIST__SUCCESS';
export const USER_FETCH_LIST__FAILURE = 'USER_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting,
} = {}) => ({
  type: USER_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
});

export const USER_FETCH_LIST__CANCEL_REQUEST = 'USER_FETCH_LIST__CANCEL_REQUEST';
export const USER_FETCH_LIST__SET_LOADING = 'USER_FETCH_LIST__SET_LOADING';
export const USER_FETCH_LIST__SET_PAGINATION = 'USER_FETCH_LIST__SET_PAGINATION';

export const USER_FETCH_LIST__SET_SEARCH = 'USER_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({ type: USER_FETCH_LIST__SET_SEARCH, search });

export const USER_FETCH_LIST__SET_PAGE = 'USER_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: USER_FETCH_LIST__SET_PAGE, page });

export const USER_FETCH_LIST__SET_SORTING = 'USER_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: USER_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const USER_FETCH_LIST__SET_FILTER = 'USER_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: USER_FETCH_LIST__SET_FILTER,
  filters,
});

export const USER_FETCH_LIST__RESET = 'USER_FETCH_LIST__RESET';
export const reset = () => ({ type: USER_FETCH_LIST__RESET });

/* Item */
export const USER_FETCH_ITEM = 'USER_FETCH_ITEM';
export const USER_FETCH_ITEM__SUCCESS = 'USER_FETCH_ITEM__SUCCESS';
export const USER_FETCH_ITEM__FAILURE = 'USER_FETCH_ITEM__FAILURE';
export const fetchItem = ({ userId } = {}) => ({ type: USER_FETCH_ITEM, userId });

export const USER_CREATE_USER = 'USER_CREATE_USER';
export const USER_CREATE_USER__SUCCESS = 'USER_CREATE_USER__SUCCESS';
export const USER_CREATE_USER__FAILURE = 'USER_CREATE_USER__FAILURE';
export const createUser = ({
  email,
  login,
  phone,
  fullName,
  isInternal,
  roleId = null,
  merchantId = null,
  dealerCenterId = null,
  userType,
} = {}) => ({
  type: USER_CREATE_USER,
  email,
  login,
  phone,
  fullName,
  isInternal,
  roleId,
  merchantId,
  dealerCenterId,
  userType,
});

export const USER_PATCH = 'USER_PATCH';
export const USER_PATCH__SUCCESS = 'USER_PATCH__SUCCESS';
export const USER_PATCH__FAILURE = 'USER_PATCH__FAILURE';
export const USER_PATCH__RESET = 'USER_PATCH__RESET';
export const updateUser = ({
  id, fullName, email, phone,
} = {}) => ({
  type: USER_PATCH,
  id,
  fullName,
  email,
  phone,
});
export const updateUserReset = () => ({
  type: USER_PATCH__RESET,
});

export const USER_CREATE_USER__RESET = 'USER_CREATE_USER__RESET';
export const createUserReset = () => ({ type: USER_CREATE_USER__RESET });

export const USER_BLOCK_USER = 'USER_BLOCK_USER';
export const USER_BLOCK_USER__SUCCESS = 'USER_BLOCK_USER__SUCCESS';
export const USER_BLOCK_USER__FAILURE = 'USER_BLOCK_USER__FAILURE';
export const blockUser = ({ userId } = {}) => ({ type: USER_BLOCK_USER, userId });
export const USER_BLOCK_USER__RESET = 'USER_BLOCK_USER__RESET';
export const blockUserReset = () => ({ type: USER_BLOCK_USER__RESET });

export const USER_UNBLOCK_USER = 'USER_UNBLOCK_USER';
export const USER_UNBLOCK_USER__SUCCESS = 'USER_UNBLOCK_USER__SUCCESS';
export const USER_UNBLOCK_USER__FAILURE = 'USER_UNBLOCK_USER__FAILURE';
export const unblockUser = ({ userId } = {}) => ({ type: USER_UNBLOCK_USER, userId });
export const USER_UNBLOCK_USER__RESET = 'USER_UNBLOCK_USER__RESET';
export const unblockUserReset = () => ({ type: USER_UNBLOCK_USER__RESET });

export const USER_RESTORE_PASSWORD_USER = 'USER_RESTORE_PASSWORD_USER';
export const USER_RESTORE_PASSWORD__SUCCESS = 'USER_RESTORE_PASSWORD__SUCCESS';
export const USER_RESTORE_PASSWORD__FAILURE = 'USER_RESTORE_PASSWORD__FAILURE';
export const restoreUserPassword = ({ userId } = {}) => ({
  type: USER_RESTORE_PASSWORD_USER,
  userId,
});
export const USER_RESTORE_PASSWORD_USER__RESET = 'USER_RESTORE_PASSWORD_USER__RESET';
export const restoreUserPasswordReset = () => ({ type: USER_RESTORE_PASSWORD_USER__RESET });

/* Roles */
export const USER_FETCH_ROLES = 'USER_FETCH_ROLES';
export const USER_FETCH_ROLES__SUCCESS = 'USER_FETCH_ROLES__SUCCESS';
export const USER_FETCH_ROLES__FAILURE = 'USER_FETCH_ROLES__FAILURE';
export const fetchRoles = ({ userId } = {}) => ({ type: USER_FETCH_ROLES, userId });

export const USER_FETCH_AVAILABLE_ROLES = 'USER_FETCH_AVAILABLE_ROLES';
export const USER_FETCH_AVAILABLE_ROLES__SUCCESS = 'USER_FETCH_AVAILABLE_ROLES__SUCCESS';
export const USER_FETCH_AVAILABLE_ROLES__FAILURE = 'USER_FETCH_AVAILABLE_ROLES__FAILURE';
export const fetchAvailableRoles = ({ userId } = {}) => ({
  type: USER_FETCH_AVAILABLE_ROLES,
  userId,
});

export const USER_APPROVE_ROLE = 'USER_APPROVE_ROLE';
export const USER_APPROVE_ROLE__SUCCESS = 'USER_APPROVE_ROLE__SUCCESS';
export const USER_APPROVE_ROLE__FAILURE = 'USER_APPROVE_ROLE__FAILURE';
export const approveRole = ({
  userId,
  merchantId,
  dealerCenterId,
  roleId,
  isInternal,
} = {}) => ({
  type: USER_APPROVE_ROLE,
  userId,
  merchantId,
  dealerCenterId,
  roleId,
  isInternal,
});
export const USER_APPROVE_ROLE__RESET = 'USER_APPROVE_ROLE__RESET';
export const approveRoleReset = () => ({ type: USER_APPROVE_ROLE__RESET });

export const USER_DISAPPROVE_ROLE = 'USER_DISAPPROVE_ROLE';
export const USER_DISAPPROVE_ROLE__SUCCESS = 'USER_DISAPPROVE_ROLE__SUCCESS';
export const USER_DISAPPROVE_ROLE__FAILURE = 'USER_DISAPPROVE_ROLE__FAILURE';
export const disapproveRole = ({ id } = {}) => ({ type: USER_DISAPPROVE_ROLE, id });

export const USER_ACTIVITY = 'USER_ACTIVITY';
export const USER_ACTIVITY__SUCCESS = 'USER_ACTIVITY__SUCCESS';
export const USER_ACTIVITY__FAILURE = 'USER_ACTIVITY__FAILURE';
export const sendUserActivity = () => ({ type: USER_ACTIVITY });

export const USER_ACTIVITY_SET_CREDIT_TOKEN = 'USER_ACTIVITY_SET_CREDIT_TOKEN';
export const USER_ACTIVITY_REMOVE_CREDIT_TOKEN = 'USER_ACTIVITY_REMOVE_CREDIT_TOKEN';
export const setCreditToken = ({ creditRequestAccessToken } = {}) => ({
  type: USER_ACTIVITY_SET_CREDIT_TOKEN,
  creditRequestAccessToken,
});
export const removeCreditToken = () => ({ type: USER_ACTIVITY_REMOVE_CREDIT_TOKEN });

export const USER_RESET_STORE = 'USER_RESET_STORE';
export const resetUserStore = () => ({ type: USER_RESET_STORE });

export const USER_CHECK_EMAIL = 'USER_CHECK_EMAIL';
export const USER_CHECK_EMAIL__SUCCESS = 'USER_CHECK_EMAIL__SUCCESS';
export const USER_CHECK_EMAIL__FAILURE = 'USER_CHECK_EMAIL__FAILURE';
export const USER_CHECK_EMAIL__RESET = 'USER_CHECK_EMAIL__RESET';

export const checkUserEmail = ({ email, merchantId } = {}) => ({
  type: USER_CHECK_EMAIL,
  email,
  merchantId,
});

export const checkUserEmailReset = () => ({
  type: USER_CHECK_EMAIL__RESET,
});

export const USER_DOWNLOAD_LIST_FILE = 'USER_DOWNLOAD_LIST_FILE';
export const USER_DOWNLOAD_LIST_FILE__SUCCESS = 'USER_DOWNLOAD_LIST_FILE__SUCCESS';
export const USER_DOWNLOAD_LIST_FILE__FAILURE = 'USER_DOWNLOAD_LIST_FILE__FAILURE';
export const USER_DOWNLOAD_LIST_FILE__RESET = 'USER_DOWNLOAD_LIST_FILE__RESET';

export const downloadListFile = ({ email, merchantId } = {}) => ({
  type: USER_DOWNLOAD_LIST_FILE,
  email,
  merchantId,
});

export const downloadListFileReset = () => ({ type: USER_DOWNLOAD_LIST_FILE__RESET });
