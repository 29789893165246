import React from 'react';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import { CloseIcon } from 'icons';

export const Modal = styled(AntModal).attrs(({ withoutClose }) => ({
  footer: null,
  closeIcon: <CloseIcon />,
  width: 980,
  closable: !withoutClose,
}))`
 & .ant-modal-close {
  top: 54px;
  right: 54px;
  ${({ selfClosing }) => selfClosing && 'display: none'};
  
  & > .ant-modal-close-x {
    width: 20px;
    height: 20px;
    line-height: 1;
    ${({ selfClosing }) => selfClosing && 'display: none'};
  }
 }
 
 & .ant-modal-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 74px 0;
 }
`;
