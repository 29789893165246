import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { formatFilters } from 'hacks';
import { defaultSorting, defaultPageSize } from 'redux/company/reducer';
import { v1 as getId } from 'uuid';

export const fetchList = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
  withPagination = true,
}) => {
  const URI = `${API}/admin/legal-entity/list`;

  const data = {
    ...(search ? { string: search } : {}),
    ...formatFilters(filters),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: withPagination ? defaultPageSize : 100000000,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchItem = ({ id }) => {
  const URI = `${API}/admin/legal-entity/single`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: Number(id),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const create = ({
  name,
  fullName,
  inn,
  ogrn,
  kpp,
  dateRegistration,
  okved,
  okpo,
  oktmo,
  individualSpruceOrgan,
  address,
  postAddress,
  phone,
  fax,
  additionalInfo,
}) => {
  const URI = `${API}/admin/legal-entity/create`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        name,
        fullName,
        inn,
        ogrn,
        kpp,
        dateRegistration,
        okved,
        okpo,
        oktmo,
        individualSpruceOrgan,
        address,
        postAddress,
        phone,
        fax,
        additionalInfo,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const update = ({
  id,
  name,
  fullName,
  inn,
  ogrn,
  kpp,
  dateRegistration,
  okved,
  okpo,
  oktmo,
  individualSpruceOrgan,
  address,
  postAddress,
  phone,
  fax,
  additionalInfo,
}) => {
  const URI = `${API}/admin/legal-entity/update`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: Number(id),
        name,
        fullName,
        inn,
        ogrn,
        kpp,
        dateRegistration,
        okved,
        okpo,
        oktmo,
        individualSpruceOrgan,
        address,
        postAddress,
        phone,
        fax,
        additionalInfo,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  fetchItem,
  create,
  update,
};
