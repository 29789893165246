import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.nav`
  margin-top: 58px;
`;

export const List = styled.dl`
  margin-bottom: ${({ clients }) => (clients ? '30px' : '19px')};
  
  :last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.dt`
  margin-bottom: 4px;
  font-family: 'Toyota Display';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #000000; 
  a {
     color: inherit;
    }
`;

export const SubTitle = styled.dt`
  margin-bottom: 4px;
  font-family: 'Toyota Display';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
`;

export const Item = styled(({ active, ...p }) => <Link {...p} />)`
  margin-bottom: 1px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active }) => (active ? '#000' : '#A8AAAC')};

  &:hover {
    color: #E50000;
  }
`;

export const Count = styled.span`
  margin-bottom: 1px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #A8AAAC;
  padding-left: 5px;
`;

export const Separator = styled.div`
    height: 1px;
    background: #CECECE;
    margin: 25px 0;
`;
