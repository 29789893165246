import React from 'react';
import { RestrictedRoute } from 'router';
import { Route, Redirect, Switch } from 'react-router-dom';
import { checkPrivilege } from 'helpers/roles';

import List from './List';
import Item from './Item';

const checkRights = (role) => checkPrivilege([
  role.PAYMENTS.VIEW,
  role.PAYMENTS.CREATE_BILLS,
]);

function Index() {
  return (
    <Switch>
      <Route exact path="/app/payments/bills">
        <Redirect to="/app/payments/bills/list" />
      </Route>

      <RestrictedRoute
        path="/app/payments/bills/list"
        component={List}
        checkRights={checkRights}
      />
      <RestrictedRoute
        path="/app/payments/bills/new"
        component={Item}
        checkRights={checkRights}
      />
      <RestrictedRoute
        path="/app/payments/bills/:billId"
        component={Item}
        checkRights={checkRights}
      />
      <RestrictedRoute
        path="/app/payments/bills/:billId/repeat"
        component={Item}
        checkRights={checkRights}
      />
    </Switch>
  );
}

export default Index;
