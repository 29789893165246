export const STATUS_OPTIONS = [
  {
    value: 'new',
    label: 'Новый',
  },
  {
    value: 'active',
    label: 'Активный',
  },
  {
    value: 'blocked_by_client',
    label: 'Заблокирован клиентом',
  },
  {
    value: 'blocked_by_bank',
    label: 'Заблокирован банком',
  },
  {
    value: 'blocked_change_password',
    label: 'Заблокирован смена пароля',
  },
];

export const STATUS_USER = [
  {
    value: 'active_directory',
    label: 'Внешний пользователь',
  },
  {
    value: 'ib',
    label: 'Внутренний пользователь',
  },

];

export const formatToTable = (array = []) => array.map((item) => ({ value: item.id, label: item.name }));

export const formatToApi = ({ array = [], options }) => (array
  ? options.filter((e) => array.includes(e.label)).map((item) => item.value)
  : []);
