import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import tus from 'tus-js-client';
import Dropzone from 'react-dropzone';

import { Popconfirm, message } from 'antd';
import {
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { API_URL as API } from 'settings/api';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';
import {
  fetchCreditRequest,
  fetchCreditRequestReset,
  saveDocument as saveDocumentAction,
  removeDocument as removeDocumentAction,
  sendSMS as sendSMSAction,
} from 'redux/creditRequest/actions';

import Modal from 'components/Modal';
import Button from 'components/Button';

import {
  DocumentsIcon, DownloadIcon, DeleteIcon, PDFIcon,
} from 'icons';

import {
  Container,
  InfoContainer,
  InfoProp,
  InfoValue,
  DocumentsContainer,
  DocumentContainerTitle,
  ControlsContainer,
  DocumentFormImageContainer,
  DocumentFileDownloadIcon,
  DocumentFileDownloadText,
  DocumentFileDocumentIcon,
  DownloadedDocumentsContainer,
  DownloadedDocumentContainer,
  DownloadedDocument,
  Delete,
  DocumentName,
  StyledDeadlineText,
  LoadingWrapper,
  SMSLoadingWrapper,
  Error,
  ErrorMessage,
  DropZoneContainers,
  Download,
} from './style';

const fileMaxSizeMb = 15;

function escapeHtml(string) {
  return String(string).replace(/[^\dA-Za-zА-Яа-я.-]/g, '_');
}

const RequestPage = ({
  match,
  history,

  data,
  error,
  isLoading,
  isSMSLoading,

  setBreadcrumbs,
  getDetails,
  getDetailsReset,
  saveDocument,
  removeDocument,
  sendSMS,
}) => {
  const { params: { id } } = match;
  const [deleteId, setDeleteId] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [tusAction, setTusAction] = useState(false);
  const [errorModalShowing, setErrorModalWhowing] = useState(false);
  const [sendSMSDisabled, setSendSMSDisabled] = useState(false);

  const signedDocuments = useMemo(() => (data.documents || [])
    .filter((item) => !!item.signingStatus), [data.documents]);
  const unsignedDocuments = useMemo(() => (data.documents || [])
    .filter((item) => !item.signingStatus), [data.documents]);

  const isDisabledButton = useMemo(() => {
    const filteredDocuments = (data.documents || []).filter((item) => !item.signingStatus);
    const hasQuestionnaireFile = Boolean(filteredDocuments.find((item) => item.fileType === 'questionnaire'));
    const hasDocumentFile = Boolean(filteredDocuments.find((item) => item.fileType === 'document'));
    return !hasQuestionnaireFile || !hasDocumentFile;
  }, [data.documents]);

  const isDeadlineDateArrived = useMemo(() => {
    const mDate = moment(data.deadlineDate);
    return !!mDate && mDate.isValid() && !moment(data.deadlineDate).isAfter(moment());
  }, [data.deadlineDate]);

  useEffect(() => {
    getDetails({ id });
    return () => getDetailsReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!isLoading) setDeleteId(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    setErrorModalWhowing(!!error || !!uploadError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, uploadError]);

  useEffect(() => {
    setBreadcrumbs([
      { level: 0, name: 'Онлайн заявка', link: '/app/leads/request/list' },
      { level: 1, name: 'Обмен документами', link: '/app/leads/credit-request/list' },
      ...[id ? { level: 2, name: `Заявка № ${data.crifId || id}`, link: `/app/leads/credit-request/${id}` } : {}],
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data.crifId]);

  const handleAdd = (files, type) => {
    const file = files[0];
    const fileSizeMb = file.size * 0.000001;

    setUploadError('');
    setTusAction(true);

    if (fileSizeMb <= fileMaxSizeMb) {
      const upload = new tus.Upload(file, {
        endpoint: `${API}/credit-client-cabinet/file/signing/load`,
        chunkSize: 1048576,
        retryDelays: [0, 3000],
        resume: true,
        removeFingerprintOnSuccess: true,

        metadata: {
          filename: escapeHtml(file.name),
          filetype: file.type,
          creditToken: id,
        },

        onError(err) {
          setUploadError(err.message);
          setTusAction(false);
        },
        onSuccess() {
          setSendSMSDisabled(false);
          setTusAction(false);
          saveDocument({
            document: {
              creditToken: id,
              fileName: escapeHtml(file.name),
              fileType: type,
              fieldName: 'frontend',
              content: '',
            },
          });
        },
      });

      upload.start();
    } else {
      setUploadError('Размер загружаемого файла не может превышать 25МБ');
    }
  };

  const handleRemove = (item) => {
    setDeleteId(item.id);
    removeDocument({
      document: {
        id: item.id,
        creditToken: id,
      },
    });
  };

  const handleSendSMS = useCallback(() => {
    sendSMS({
      creditToken: id,
      resolve: () => {
        setSendSMSDisabled(true);
        message.success('SMS клиенту отправлено', 3);
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return !data.crifId && isLoading ? (<LoadingOutlined />) : (
    <Container>
      <InfoContainer>
        <InfoProp>ID заявки CRIF</InfoProp>
        <InfoValue>{data.crifId || '-'}</InfoValue>
        <InfoProp>ФИО заемщика</InfoProp>
        <InfoValue>{data.clientFullname || '-'}</InfoValue>
        <InfoProp>Дедлайн подписания</InfoProp>
        <InfoValue>
          {data.deadlineDate ? moment(data.deadlineDate).format('DD.MM.YYYY HH:mm') : '-'}
        </InfoValue>
        <InfoProp>Статус подписания</InfoProp>
        <InfoValue>
          {({ 'value-true': 'Подписан', 'value-false': 'Не подписан' })[`value-${data.signingStatus}`] || '-'}
        </InfoValue>
        <InfoProp>Дата время подписания</InfoProp>
        <InfoValue>{data.signingDateTime || '-'}</InfoValue>
      </InfoContainer>
      <DocumentsContainer>
        <DocumentContainerTitle>
          Неподписанные файлы
        </DocumentContainerTitle>
        <ControlsContainer>
          <DropZoneContainers>
            <Dropzone
              accept={['application/pdf']}
              acceptedFiles=".pdf"
              multiple={false}
              onDrop={(files) => handleAdd(files, 'questionnaire')}
            >
              {({ getRootProps, getInputProps }) => (
                <DocumentFormImageContainer {...getRootProps()}>
                  <input {...getInputProps()} accept="application/pdf" />
                  <DocumentFileDocumentIcon>
                    {(deleteId === null && isLoading) || tusAction
                      ? (<LoadingWrapper><LoadingOutlined /></LoadingWrapper>)
                      : (<DocumentsIcon />)}
                  </DocumentFileDocumentIcon>
                  <DocumentFileDownloadText>
                    <DocumentFileDownloadIcon>
                      <DownloadIcon />
                      <span>
                        Переместите
                        {' '}
                        <br />
                        {' '}
                        сюда
                        {' '}
                        <b>Анкету</b>
                        {' '}
                        <br />
                        или
                        {' '}
                        <br />
                        {' '}
                        <u>загрузите</u>
                      </span>
                    </DocumentFileDownloadIcon>
                  </DocumentFileDownloadText>
                </DocumentFormImageContainer>
              )}
            </Dropzone>
            <Dropzone
              accept={['application/pdf']}
              acceptedFiles=".pdf"
              multiple={false}
              onDrop={(files) => handleAdd(files, 'document')}
            >
              {({ getRootProps, getInputProps }) => (
                <DocumentFormImageContainer {...getRootProps()}>
                  <input {...getInputProps()} accept="application/pdf" />
                  <DocumentFileDocumentIcon>
                    {(deleteId === null && isLoading) || tusAction
                      ? (<LoadingWrapper><LoadingOutlined /></LoadingWrapper>)
                      : (<DocumentsIcon />)}
                  </DocumentFileDocumentIcon>
                  <DocumentFileDownloadText>
                    <DocumentFileDownloadIcon>
                      <DownloadIcon />
                      <span>
                        Переместите
                        {' '}
                        <br />
                        {' '}
                        сюда
                        {' '}
                        <b>Документы</b>
                        <br />
                        или
                        <br />
                        {' '}
                        <u>загрузите</u>
                      </span>
                    </DocumentFileDownloadIcon>
                  </DocumentFileDownloadText>
                </DocumentFormImageContainer>
              )}
            </Dropzone>
          </DropZoneContainers>
          <div>
            <Button
              type="primary"
              shape="square"
              disabled={isDisabledButton || sendSMSDisabled}
              onClick={handleSendSMS}
            >
              Отправить СМС клиенту
            </Button>
            {isDeadlineDateArrived && (
              <StyledDeadlineText>
                Дедлайн подписания уже наступил
              </StyledDeadlineText>
            )}
            {isSMSLoading && (<SMSLoadingWrapper><LoadingOutlined /></SMSLoadingWrapper>)}
          </div>
        </ControlsContainer>
      </DocumentsContainer>
      <DownloadedDocumentsContainer>
        {unsignedDocuments.map((item) => (
          <DownloadedDocumentContainer key={item.name}>
            <DownloadedDocument>
              <DocumentFileDocumentIcon href={item.fileUrl} download={item.fileName}>
                {(/\.pdf$/i).test(item.fileName)
                  ? (<PDFIcon />)
                  : (<DocumentsIcon />)}
              </DocumentFileDocumentIcon>
              <Popconfirm
                title="Вы уверены, что хотите удалить  документ?"
                icon={<WarningOutlined />}
                onConfirm={() => handleRemove(item)}
                onCancel={null}
                okButtonProps={{ danger: true }}
                okText="Подтвердить"
                cancelText="Отмена"
              >
                <Delete disable={deleteId !== item.id && isLoading}>
                  Удалить
                  {deleteId === item.id && isLoading
                    ? (<LoadingOutlined />)
                    : (<DeleteIcon />)}
                </Delete>
              </Popconfirm>
            </DownloadedDocument>
            <DocumentName href={item.fileUrl} download={item.fileName}>{item.fileName}</DocumentName>
          </DownloadedDocumentContainer>
        ))}
      </DownloadedDocumentsContainer>
      {signedDocuments.length > 0 && (
        <>
          <DocumentContainerTitle>
            Подписанные файлы
          </DocumentContainerTitle>
          <DownloadedDocumentsContainer>
            {signedDocuments.map((item) => (
              <DownloadedDocumentContainer key={item.name}>
                <DownloadedDocument>
                  <DocumentFileDocumentIcon href={item.fileUrl} download={item.fileName}>
                    {(/\.pdf$/i).test(item.fileName)
                      ? (<PDFIcon />)
                      : (<DocumentsIcon />)}
                  </DocumentFileDocumentIcon>
                  <Popconfirm
                    title="Вы уверены, что хотите удалить  документ?"
                    icon={<WarningOutlined />}
                    onConfirm={() => handleRemove(item)}
                    onCancel={null}
                    okButtonProps={{ danger: true }}
                    okText="Подтвердить"
                    cancelText="Отмена"
                  >
                    <Delete disable={deleteId !== item.id && isLoading}>
                      Удалить
                      {deleteId === item.id && isLoading
                        ? (<LoadingOutlined />)
                        : (<DeleteIcon />)}
                    </Delete>
                  </Popconfirm>
                  <Download href={item.fileUrl} download={item.fileName}>
                    Cкачать
                    <DownloadIcon />
                  </Download>
                </DownloadedDocument>
                <DocumentName href={item.fileUrl} download={item.fileName}>{item.fileName}</DocumentName>
              </DownloadedDocumentContainer>
            ))}
          </DownloadedDocumentsContainer>
        </>
      )}
      <Button
        size="middle"
        type="ghost"
        shape="square"
        onClick={() => history.goBack()}
      >
        Вернуться назад
      </Button>
      <Modal
        visible={errorModalShowing}
        closable
        onCancel={() => setErrorModalWhowing(false)}
        title="Ошибка"
      >
        <Error>
          При выполнении запроса произошла ошибка:
          <br />
          <ErrorMessage>{uploadError || error}</ErrorMessage>
        </Error>
      </Modal>
      {}
    </Container>
  );
};

export default connect(
  (state) => ({
    data: state.creditRequest.item.data,
    error: state.creditRequest.item.error,
    isLoading: state.creditRequest.item.isLoading,
    isSMSLoading: state.creditRequest.item.isSMSLoading,
  }),
  {
    setBreadcrumbs: setBreadcrumbsAction,
    saveDocument: saveDocumentAction,
    removeDocument: removeDocumentAction,
    getDetails: fetchCreditRequest,
    getDetailsReset: fetchCreditRequestReset,
    sendSMS: sendSMSAction,
  },
)(withRouter(RequestPage));
