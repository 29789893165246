export const parseDigits = (string) =>
  (String(string || '').match(/\d+/g) || []).join('');

export const parseDigitsWithSeparator = (string) => {
  const [s] = String(string || '').split('.');
  return (s.match(/\d+/g) || []).join('');
};

export const parseSumWithSeparator = (string) => {
  const s = String(string || '').split(/[.,]/gm);
  const integer = s[0];

  if (s.length > 1) {
    const fractionalSum = s[1];
    const isOnlyNulls = /^[0]+/.test(fractionalSum);
    if (isOnlyNulls) {
      return integer;
    }
    return s.join('');
  }

  return integer;
};
