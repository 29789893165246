import moment from 'moment';
import React from 'react';
import { CallIcon, VisitIcon } from 'icons/CallResultsIcons';
import { CarIcon, NoCallIcon } from 'icons';

export const OfferStatus = {
  Evaluation: 'EVALUATION',
  Revision: 'REVISION',
  Rejected: 'REJECTED',
  Pending: 'KP_CALCULATION',
  Distribution: 'DISTRIBUTION',
  NotAssigned: 'NOT_ASSIGNED',
  Calling: 'CALLING',
  WaitConfirm: 'WAIT_CONFIRM',
  Confirmed: 'CONFIRMED',
  Agreeing: 'AGREEING',
  Refused: 'REFUSED',
};

export const getTextFromOfferStatus = (offerStatus) => {
  switch (offerStatus) {
    case OfferStatus.WaitConfirm:
      return 'Ожидает подтверждения';
    case OfferStatus.Confirmed:
      return 'Подтвержден';
    case OfferStatus.Revision:
      return 'Доработка';
    default:
      return 'В работе';
  }
};

export const CallStatusesCode = {
  Recall: 'CALL_BACK_LATER',
  VisitToDc: 'CLIENT_WILL_COME_TO_DC',
  NotOwner: 'CALL_ANOTHER_PERSON',
  NotClient: 'NOT_CLIENT_ANSWERED',
  NotInterested: 'PROPOSAL_IS_NOT_INTERESTING',
  NotCall: 'NEGATIVE_CLIENT_NOT_CALL',

  NegativeRecall: 'CALL_BACK',
  NotRecall: 'DO_NOT_CALL_BACK',
};

/**
 * @enum
 * @readonly
 * @type {{TradeIn: string, TestDrive: string, Registration: string, ContractConcluded: string}}
 */
export const VisitStatusCode = {
  TradeIn: 'EVALUATION_TRADE_IN',
  TestDrive: 'TEST_DRIVE',
  Registration: 'FILING_AN_APPLICATION',
  ContractConcluded: 'LOAN_AGREEMENT_EXECUTION',
};

/*
 * Проверка на пересечение ключей или значений
 * CallStatusesCode и VisitStatusCode
 */
(() => {
  if (Object.entries(CallStatusesCode).some((status) =>
    Object.keys(VisitStatusCode).includes(status[0])
    || Object.values(VisitStatusCode).includes(status[1]))
  ) {
    throw new Error('CallStatusesCode и VisitStatusCode должны иметь уникальные ключи и значения');
  }
})();

/**
 *
 * @type {{
 *  TradeIn: string,
 *  NotOwner: string,
 *  NotCall: string,
 *  NotInterested: string,
 *  TestDrive: string,
 *  Registration: string,
 *  VisitToDc: string,
 *  NotClient: string,
 *  Recall: string,
 *  ContractConcluded: string,
 *  NegativeRecall: string,
 *  NotRecall: string
 * }}
 */
export const EventStatusesCode = { ...CallStatusesCode, ...VisitStatusCode };

export const CallStatusDescriptionsCode = {
  CallbackDate: 'DATE_TIME_OF_CALL_BACK',
  AnotherName: 'ANOTHER_PERSON_NAME',
  AnotherPhone: 'ANOTHER_PERSON_PHONE',
  Reason: 'REASON',
  Why: 'WHY',
  Model: 'CAR_MODEL',
  Date: 'DATE',
};

export const VisitDescriptionCode = {
  Sum: 'EVALUATION_TRADE_IN_SUM',
  TestDriveBrand: 'TEST_DRIVE_BRAND',
  TestDriveModel: 'TEST_DRIVE_MODEL',
  RegistrationBank: 'FILING_AN_APPLICATION_BANK',
  RegistrationBrand: 'FILING_AN_APPLICATION_BRAND',
  RegistrationModel: 'FILING_AN_APPLICATION_MODEL',
  ContractConcludedBank: 'LOAN_AGREEMENT_EXECUTION_BANK',
  ContractConcludedReason: 'LOAN_AGREEMENT_EXECUTION_REJECTION_REASON',
  LoanAgreementExecutionOtherDC: 'LOAN_AGREEMENT_EXECUTION_OTHER_DEALER_CANTER',
};

/*
 * Проверка на пересечение ключей или значений
 * CallStatusDescriptionsCode и VisitDescriptionCode
 */
(() => {
  if (Object.entries(CallStatusDescriptionsCode).some((status) =>
    Object.keys(VisitDescriptionCode).includes(status[0])
    || Object.values(VisitDescriptionCode).includes(status[1]))
  ) {
    throw new Error('CallStatusDescriptionsCode и VisitDescriptionCode должны иметь уникальные ключи и значения');
  }
})();

/**
 *
 * @type {{
 *  AnotherPhone: string,
 *  TestDriveBrand: string,
 *  RegistrationModel: string,
 *  Why: string,
 *  Sum: string,
 *  Reason: string,
 *  Date: string,
 *  AnotherName: string,
 *  ContractConcludedReason: string,
 *  ContractConcludedBank: string,
 *  CallbackDate: string,
 *  TestDriveModel: string,
 *  RegistrationBank: string,
 *  RegistrationBrand: string,
 *  Model: string
 * }}
 */
export const EventStatusDescriptionsCode = { ...CallStatusDescriptionsCode, ...VisitDescriptionCode };

export const ListType = {
  Underway: 0,
  WaitConfirm: 1,
  Confirmed: 2,
  Revision: 3,
  Agreeing: 4,
  Refused: 5,
};

export const FILTER_LIST_TYPE = {
  CALLING: 0,
  WAIT_CONFIRM: 1,
  CONFIRMED: 2,
  REVISION: 3,
  AGREEING: 4,
  REFUSED: 5,
};

export const MANAGER_SHOW_CHECK_BOXES = {
  [FILTER_LIST_TYPE.WAIT_CONFIRM]: true,
  [FILTER_LIST_TYPE.CALLING]: false,
  [FILTER_LIST_TYPE.CONFIRMED]: false,
  [FILTER_LIST_TYPE.REVISION]: false,
  [FILTER_LIST_TYPE.AGREEING]: false,
  [FILTER_LIST_TYPE.REFUSED]: false,
};

export const EMPLOYEE_SHOW_CHECK_BOXES = {
  [FILTER_LIST_TYPE.WAIT_CONFIRM]: false,
  [FILTER_LIST_TYPE.CALLING]: true,
  [FILTER_LIST_TYPE.CONFIRMED]: false,
  [FILTER_LIST_TYPE.REVISION]: false,
  [FILTER_LIST_TYPE.AGREEING]: false,
  [FILTER_LIST_TYPE.REFUSED]: false,
};

/**
 *
 * @param values
 * @param {{Date: string. Time: string}} dateFormat
 * @returns {[]}
 */
export const getCallStatusDescriptions = (values, dateFormat) => {
  const descriptions = [];
  const date = [];
  const codes = Object.values(EventStatusDescriptionsCode);

  const filteredCodes = new Set(
    codes.filter((code) => code !== EventStatusDescriptionsCode.CallbackDate),
  );

  Object.keys(values).forEach((key) => {
    if (filteredCodes.has(key)) {
      descriptions.push({
        key,
        value: key === EventStatusDescriptionsCode.Date
          ? moment(values[key]).format(dateFormat)
          : values[key],
      });
    }
    if (key === 'RECALL_DATE') {
      date.push(values[key].format(dateFormat));
    }
    if (key === 'RECALL_TIME') {
      date.push(values[key]);
    }
  });

  if (date.length) {
    descriptions.push({
      key: EventStatusDescriptionsCode.CallbackDate,
      value: date.join(' '),
    });
  }

  return descriptions;
};

/**
 *
 * @param offer
 * @return {JSX.Element}
 */
export const getIconFromOffer = (offer) => {
  if ((offer.status && offer.status !== OfferStatus.Rejected && !offer.lastEvent)) {
    return <CallIcon />;
  }
  if (offer.status && offer.status !== OfferStatus.Rejected && offer.lastEvent) {
    switch (offer.lastEvent.status) {
      case EventStatusesCode.ContractConcluded:
      case EventStatusesCode.Registration:
      case EventStatusesCode.TestDrive:
      case EventStatusesCode.TradeIn:
        return <VisitIcon />;
      case EventStatusesCode.VisitToDc:
        return <CarIcon />;
      case EventStatusesCode.NotCall:
      case EventStatusesCode.NotRecall:
      case EventStatusesCode.NotInterested:
        return <NoCallIcon />;
      case EventStatusesCode.Recall:
      case EventStatusesCode.NegativeRecall:
      case EventStatusesCode.NotOwner:
      case EventStatusesCode.NotClient:
        return <CallIcon />;
      default:
        console.error(`getIconFromOfferStatus. Unsupported status: ${offer.lastEvent.status}.
        Status must be in EventStatusesCode.`);
    }
  }
};
