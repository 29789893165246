import API from 'services';

import { extractErrorAndCode, getUser } from 'hacks';

import {
  all, fork, put, takeLatest, call,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  ADMIN_CREATE_MERCHANT,
  ADMIN_CREATE_MERCHANT__SUCCESS,
  ADMIN_CREATE_MERCHANT__FAILURE,
} from './actions';

export function* createMerchant() {
  yield takeLatest(ADMIN_CREATE_MERCHANT, function* (action) {
    try {
      const response = yield call(API.admin.createMerchant, {
        name: action.name,
        inn: action.inn,
        admin: action.admin,
        users: action.users,
      });

      if (response && response.data && response.data.legalEntityResponseList) {
        yield put({ type: ADMIN_CREATE_MERCHANT__SUCCESS, user: getUser(response) });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: ADMIN_CREATE_MERCHANT__FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: ADMIN_CREATE_MERCHANT__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(createMerchant)]);
}
