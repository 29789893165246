import {
  // list
  REPORT_FETCH_LIST,
  REPORT_FETCH_LIST__SUCCESS,
  REPORT_FETCH_LIST__FAILURE,
  REPORT_FETCH_LIST__SET_LOADING,
  REPORT_FETCH_LIST__SET_PAGINATION,
  REPORT_FETCH_LIST__SET_SEARCH,
  REPORT_FETCH_LIST__SET_SORTING,
  REPORT_FETCH_LIST__SET_FILTER,
  REPORT_FETCH_LIST__RESET,

  REPORT_RESET,

  REPORT_EXPORT_FILE,
  REPORT_EXPORT_FILE__SUCCESS,
  REPORT_EXPORT_FILE__FAILURE,

  REPORTS_SYNC,
  REPORTS_SYNC__SUCCESS,
  REPORTS_SYNC__FAILURE,

  REPORT_MONTHLY_SUMMARY_OPERATIONS,
  REPORT_MONTHLY_SUMMARY_OPERATIONS__SUCCESS,
  REPORT_MONTHLY_SUMMARY_OPERATIONS__FAILURE,

  REPORT_PERIOD_OPERATIONS,
  REPORT_PERIOD_OPERATIONS__SUCCESS,
  REPORT_PERIOD_OPERATIONS__FAILURE,

  REPORT_RECONCILIATION,
  REPORT_RECONCILIATION__SUCCESS,
  REPORT_RECONCILIATION__FAILURE,

  REPORT_GET_FILE_URL,
  REPORT_GET_FILE_URL__SUCCESS,
  REPORT_GET_FILE_URL__FAILURE,
  REPORT_GET_FILE_URL__RESET,
} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {};

const initialState = {
  collection: {
    data: {},
    list: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
    pagination: {
      page: 1,
      pageSize: defaultPageSize,
      rowsCount: 0,
    },
  },

  exportFile: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  syncReports: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  monthlySummaryOperations: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  periodOperations: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  reportReconciliation: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  getFileUrl: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initialState.collection,
        },
      };
    }

    case REPORT_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case REPORT_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case REPORT_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case REPORT_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case REPORT_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case REPORT_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORT_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,
          pagination: action.pagination,
          data: action.data,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case REPORT_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REPORT_RESET: {
      return {
        ...state,

        exportFile: {
          ...initialState.exportFile,
        },

        syncReports: {
          ...initialState.syncReports,
        },

        monthlySummaryOperations: {
          ...initialState.monthlySummaryOperations,
        },

        periodOperations: {
          ...initialState.periodOperations,
        },

        reportReconciliation: {
          ...initialState.reportReconciliation,
        },
      };
    }

    case REPORT_EXPORT_FILE: {
      return {
        ...state,

        exportFile: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORT_EXPORT_FILE__SUCCESS: {
      return {
        ...state,
        exportFile: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case REPORT_EXPORT_FILE__FAILURE: {
      return {
        ...state,
        exportFile: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REPORTS_SYNC: {
      return {
        ...state,

        syncReports: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORTS_SYNC__SUCCESS: {
      return {
        ...state,
        syncReports: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case REPORTS_SYNC__FAILURE: {
      return {
        ...state,
        syncReports: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REPORT_MONTHLY_SUMMARY_OPERATIONS: {
      return {
        ...state,

        monthlySummaryOperations: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORT_MONTHLY_SUMMARY_OPERATIONS__SUCCESS: {
      return {
        ...state,
        monthlySummaryOperations: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case REPORT_MONTHLY_SUMMARY_OPERATIONS__FAILURE: {
      return {
        ...state,
        monthlySummaryOperations: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REPORT_PERIOD_OPERATIONS: {
      return {
        ...state,

        periodOperations: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORT_PERIOD_OPERATIONS__SUCCESS: {
      return {
        ...state,
        periodOperations: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case REPORT_PERIOD_OPERATIONS__FAILURE: {
      return {
        ...state,
        periodOperations: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REPORT_RECONCILIATION: {
      return {
        ...state,

        reportReconciliation: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORT_RECONCILIATION__SUCCESS: {
      return {
        ...state,
        reportReconciliation: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case REPORT_RECONCILIATION__FAILURE: {
      return {
        ...state,
        reportReconciliation: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case REPORT_GET_FILE_URL__RESET: {
      return {
        ...state,

        getFileUrl: {
          ...initialState.getFileUrl,
        },
      };
    }
    case REPORT_GET_FILE_URL: {
      return {
        ...state,

        getFileUrl: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case REPORT_GET_FILE_URL__SUCCESS: {
      return {
        ...state,
        getFileUrl: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case REPORT_GET_FILE_URL__FAILURE: {
      return {
        ...state,
        getFileUrl: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default:
      return state;
  }
}
