import React from 'react';
import {
  FormStaticDate,
  RecallDate,
  RecallDateLabel,
  SecondaryField,
} from 'containers/App/TCM/ClientCard/CallForm/style';
import { TCM } from 'components/tcm';
import { KEYS } from 'containers/App/TCM/ClientCard/CallForm/data';

export const RecallSubField = () => (
  <SecondaryField>
    <RecallDateLabel>Дата и время повторного звонка</RecallDateLabel>
    <RecallDate>
      <TCM.Label
        name={KEYS.STATUS.options.recall.children.recallDate.key}
        rules={KEYS.STATUS.options.recall.children.recallDate.rules}
        validateTrigger="onBlur"
      >
        <FormStaticDate
          name={KEYS.STATUS.options.recall.children.recallDate.key}
          allowClear={false}
        />
      </TCM.Label>
      <TCM.Label
        name={KEYS.STATUS.options.recall.children.recallTime.key}
        rules={KEYS.STATUS.options.recall.children.recallTime.rules}
        validateTrigger="onBlur"
      >
        <TCM.TimePicker
          name={KEYS.STATUS.options.recall.children.recallTime.key}
        />
      </TCM.Label>
    </RecallDate>
  </SecondaryField>
);
