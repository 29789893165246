import React, { useEffect, useState, useReducer } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import AutoComplete, { Option } from 'components/AutoComplete';
import { useDebounce } from 'hooks';
import { formatFias } from 'hacks';
import API from 'services';

const initialState = {
  search: null,
  isFocused: false,
  isFetching: false,
  isLoaded: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
  FETCH: 'FETCH',
  SELECT: 'SELECT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FOCUS: {
      return {
        ...state,
        isFocused: true,
        isLoaded: false,
      };
    }
    case ACTIONS.BLUR: {
      return {
        ...state,
        isFocused: false,
        isLoaded: false,
      };
    }
    case ACTIONS.SELECT: {
      return {
        ...state,
        isFocused: false,
        isLoaded: false,
      };
    }
    case ACTIONS.FETCH: {
      return {
        ...state,
        isFetching: true,
        isLoaded: false,
        search: action.search,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isLoaded: true,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isLoaded: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function AddressField({
  value: initialValue,
  name,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  disabled,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isCount, setIsCount] = useState(false);
  const [searchGuid, setSearchGuid] = useState('');

  useEffect(() => {
    if (initialValue && typeof initialValue === 'string') {
      setSearchTerm(initialValue);
    }
  }, [setSearchTerm, initialValue]);

  useEffect(() => {
    if (state.isFocused || isCount) {
      if (debouncedSearchTerm !== state.search) {
        dispatch({ type: ACTIONS.FETCH, search: debouncedSearchTerm });
        API.fias
          .fetchFIAS({
            alias: 'address',
            value: debouncedSearchTerm,
            dependencies: { ...(isCount ? { count: 1 } : {}) },
          })
          .then((results) => {
            setIsCount(false);
            dispatch({ type: ACTIONS.FETCH_SUCCESS, list: formatFias(results) });
          })
          .catch((error) => {
            dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
          });
      }
    }
  }, [state.isFocused, state.search, debouncedSearchTerm, isCount]);

  useEffect(() => {
        // if user selected option and server response data
    if (searchGuid && state.isLoaded) {
      if (state.list.length > 0) {
        const option = state.list[0];

        if (option) {
          const { value } = option;
          onChange({
            name,
            value,
            data: option,
          });
        }
      }
      setSearchGuid('');
    }
  }, [
    state,
    searchGuid,
    onChange,
    name,
  ]);

  function handleSelect(v) {
    setIsCount(true);
    const option = state.list.find((e) => e.value === v);
    if (option.GUID) {
      setSearchGuid(option.GUID);
    }

    dispatch({ type: ACTIONS.SELECT });
    onChange({
      name,
      value: v,
      data: state.list.find((e) => e.value === v),
    });
  }

  function handleOnFocus() {
    dispatch({ type: ACTIONS.FOCUS });
    onFocus({ target: { name } });
  }

  function handleOnBlur() {
    if (searchTerm !== initialValue) {
      onChange({
        name,
        value: searchTerm,
        data: null,
      });
    }
    dispatch({ type: ACTIONS.BLUR });
    onBlur({ target: { name } });
  }

  return (
    <AutoComplete
      name={name}
      value={searchTerm}
      placeholder={placeholder}
      onChange={setSearchTerm}
      onSelect={handleSelect}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      disabled={disabled}
      notFoundContent={
        state.error ? (
          <span>{state.error}</span>
        ) : state.isFetching ? (
          <LoadingOutlined />
        ) : searchTerm && searchTerm.length < 3 ? (
          <sup>[запрос должен содержать от 3-ех символов]</sup>
        ) : (
          <span>нет данных</span>
        )
      }
    >
      {state.list.map((option) => (
        <Option key={option.value}>{option.value}</Option>
      ))}
    </AutoComplete>
  );
}

export default AddressField;
