const palette = {
  red: ['#e50000', '#ce0000'],
  white: ['#fff', 'rgba(255,255,255,0.5)', 'rgba(255,255,255,0.1)'],
  grayscale: [
    '#f0f0f0',
    'rgba(0,0,0,0.05)',
    'rgba(240, 240, 240, 0.96)',
    '#e4e4e4',
    '#cecece',
    '#a8aaac',
    '#6c7073',
    '#595E68',
    '#202020',
  ],
  black: ['#000', 'rgba(0,0,0,0.9)'],

  primary: [
    '#EB0A1E', // 0: Toyota Red
    '#FFFFFF', // 1: White
    '#000000', // 2: Black
    '#58595B', // 3: Gray
    '#DDDEE0', // 4: Gray
  ],
  form: [
    '#F5F5F5', //   0: Background
    '#FFFFFF', //   1: Background:focus
    '#B7B7B7', //   2: Border
    '#0095FF', //   3: Border:focus
    '#0095ff85', // 4: Shadow:focus
    '#202020', //   5: Color
  ],
};

const input = {
  height: '2.2rem',
  fontSize: '.9rem',
  background: palette.form[0],
  borderColor: palette.form[2],
  color: palette.form[5],
  hover: {
    background: palette.form[1],
    borderColor: palette.form[3],
  },
  focus: {
    background: palette.form[1],
    borderColor: palette.form[3],
    boxShadow: `0 0 0 2px ${palette.form[4]}`,
  },
};

const style = {
  palette,

  fonts: {
    primary: 'Roboto, sans-serif',
  },

  sizes: {
    inputHeight: '36px',
    filterInputHeight: '32px',
  },

  form: {
    marginBottom: '1rem',
  },

  input,

  radio: {
    fontSize: '.8rem',
  },

  checkbox: {
    height: '1rem',
    fontSize: '.8rem',
  },
};

export default {
  ...style,

  defaultInput: {
    height: input.height,
    fontSize: input.fontSize,
    background: input.background,
    borderColor: input.borderColor,
    color: input.color,
    '&:hover': input.hover,
    '&:focus': input.focus,
  },

  defaultSelect: {
    height: input.height,
    fontSize: input.fontSize,
    background: input.background,
    borderColor: input.borderColor,
    color: input.color,
    '&:hover': input.hover,
    '&:focus': input.focus,
  },
};
