export const formatDealerCenters = (data) => {
  const { subsidiariesResponseList, legalEntityResponseList } = data;

  if (
    Array.isArray(subsidiariesResponseList)
  ) {
    return subsidiariesResponseList.map((e) => ({
      id: e.id,
      name: e.salePoint || e.name, // сервер менял salePoint и name постоянно
      salePoint: e.salePoint,
      inn: e.inn,
      krifOrganizationId: e.krifOrganizationId,
      krifOrganizationName: e.krifOrganizationName,
      dealershipId: e.dealershipId,
      address: e.address,
      phone: e.phone,
      organisation: (
        Array.isArray(e.legalEntityResponseList) && Array.isArray(legalEntityResponseList)
          ? legalEntityResponseList.find((org) => org.id === e.legalEntityResponseList[0].id)
          : {}
      ),
    }));
  }
  return [];
};
