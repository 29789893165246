import styled from 'styled-components';

export const Root = styled.div`
  position: absolute;
  z-index: -1;
`;

export const ErrorMessage = styled.p`
  // color: red;
`;
