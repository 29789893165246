import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { TCM } from '.';

const Page404Wrapper = styled.div`
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>*{
    margin-bottom: 30px;
  }
`;

const Page404 = ({ text = 'Cтраница не найдена' }) => {
  const history = useHistory();
  return (
    <Page404Wrapper>
      <h1>{text}</h1>
      <TCM.ButtonOutline onClick={() => history.goBack()}>
        Вернуться назад
      </TCM.ButtonOutline>
    </Page404Wrapper>
  );
};

export default Page404;
