import React from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonStyled } from './style';

/*
  Specs: https://www.figma.com/file/WakJcAIpkNth1ukKGbAa1Mth/Toyota-UI-System?node-id=1%3A1599
*/

function ButtonOverride({
  id,
  block,
  children,
  disabled,
  imageSrc,
  imageAlt,
  ghost,
  href,
  htmlType,
  loading,
  onClick,
  size,
  shape,
  target,
  type,
  history,
  style,
}) {
  const hijackOnClick = (...a) => {
    if (typeof href === 'string') {
      a[0].preventDefault(); // event
      history.push(href);
    } else if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <ButtonStyled
      id={id}
      block={block}
      disabled={disabled}
      ghost={ghost}
      image={imageSrc}
      href={href}
      htmlType={htmlType}
      loading={loading}
      onClick={hijackOnClick}
      size={size}
      shape={shape}
      target={target}
      type={type}
      {...style ? {
        style,
      } : {}}
    >
      {imageSrc ? <img src={imageSrc} alt={imageAlt} /> : children}
    </ButtonStyled>
  );
}

export default withRouter(ButtonOverride);
