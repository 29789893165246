export const moveCursorToEnd = (node) => {
  const { length } = node.value;
  node.setSelectionRange(length, length);
};

export const moveCursorTo = ({ node, start, end }) => {
  node.input.setSelectionRange(start, end);
};

export const cursorToLastPhoneValuePosition = (phoneField) => {
  const { value } = phoneField.current.input;
  const before = value.match(/[\d()-_\s]*\d/gm);
  const position = before ? before[0].length : 1;

  moveCursorTo({
    node: phoneField.current,
    start: position,
    end: position,
  });
};
