import React from 'react';

export const CalendarIcon = () => (
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4V16.7C1 17.4 1.5 18 2.2 18H11.8C12.5 18 13 17.4 13 16.7V4H1Z" stroke="#7A7A7B" strokeWidth="2" />
    <path d="M0.5 8H13.5" stroke="#7A7A7B" strokeWidth="2" />
    <path d="M4 12.3C4.55228 12.3 5 11.8523 5 11.3C5 10.7478 4.55228 10.3 4 10.3C3.44772 10.3 3 10.7478 3 11.3C3 11.8523 3.44772 12.3 4 12.3Z" fill="#7A7A7B" />
    <path d="M7 12.3C7.55228 12.3 8 11.8523 8 11.3C8 10.7478 7.55228 10.3 7 10.3C6.44772 10.3 6 10.7478 6 11.3C6 11.8523 6.44772 12.3 7 12.3Z" fill="#7A7A7B" />
    <path d="M10 12.3C10.5523 12.3 11 11.8523 11 11.3C11 10.7478 10.5523 10.3 10 10.3C9.44772 10.3 9 10.7478 9 11.3C9 11.8523 9.44772 12.3 10 12.3Z" fill="#7A7A7B" />
    <path d="M4 15.3C4.55228 15.3 5 14.8523 5 14.3C5 13.7478 4.55228 13.3 4 13.3C3.44772 13.3 3 13.7478 3 14.3C3 14.8523 3.44772 15.3 4 15.3Z" fill="#7A7A7B" />
    <path d="M7 15.3C7.55228 15.3 8 14.8523 8 14.3C8 13.7478 7.55228 13.3 7 13.3C6.44772 13.3 6 13.7478 6 14.3C6 14.8523 6.44772 15.3 7 15.3Z" fill="#7A7A7B" />
    <path d="M10 15.3C10.5523 15.3 11 14.8523 11 14.3C11 13.7478 10.5523 13.3 10 13.3C9.44772 13.3 9 13.7478 9 14.3C9 14.8523 9.44772 15.3 10 15.3Z" fill="#7A7A7B" />
    <path d="M4 3.20002V1.90002" stroke="#7A7A7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 3.20002V1.90002" stroke="#7A7A7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
