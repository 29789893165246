import API from 'services';

import {
  extractErrorAndCode,
  formatDealerCenters,
} from 'hacks';

import {
  all, takeLatest, put, fork, call, select, cancel, take,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  // list
  DEALER_CENTER_FETCH_LIST,
  DEALER_CENTER_FETCH_LIST__SUCCESS,
  DEALER_CENTER_FETCH_LIST__FAILURE,
  DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST,
  DEALER_CENTER_FETCH_LIST__SET_LOADING,
  DEALER_CENTER_FETCH_LIST__SET_PAGINATION,
  DEALER_CENTER_FETCH_LIST__SET_SEARCH,
  DEALER_CENTER_FETCH_LIST__SET_PAGE,
  DEALER_CENTER_FETCH_LIST__SET_SORTING,
  DEALER_CENTER_FETCH_LIST__SET_FILTER,

  // item
  DEALER_CENTER__FETCH_ITEM,
  DEALER_CENTER__FETCH_ITEM_SUCCESS,
  DEALER_CENTER__FETCH_ITEM_FAILURE,
  DEALER_CENTER__CREATE,
  DEALER_CENTER__CREATE_SUCCESS,
  DEALER_CENTER__CREATE_FAILURE,
  DEALER_CENTER__EDIT,
  DEALER_CENTER__EDIT_SUCCESS,
  DEALER_CENTER__EDIT_FAILURE,

  // ARM
  DEALER_CENTER__FETCH_DEALERS,
  DEALER_CENTER__FETCH_DEALERS_SUCCESS,
  DEALER_CENTER__FETCH_DEALERS_FAILURE,
} from './actions';

const getState = (state) => state.dealerCenter;

function* _fetchList({ legalEntityIds, withPagination }) {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);

  try {
    yield put({ type: DEALER_CENTER_FETCH_LIST__SET_LOADING });
    const response = yield call(API.dealerCenter.fetchList, {
      search,
      page,
      filters,
      sorting,
      legalEntityIds,
      withPagination,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.subsidiariesResponseList))
    ) {
      yield put({
        type: DEALER_CENTER_FETCH_LIST__SUCCESS,
        pagination: response.pagination,
        list: formatDealerCenters(response.data),
        companyList: (
          Array.isArray(response.data.legalEntityResponseList)
            ? response.data.legalEntityResponseList
            : []
        ),
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: DEALER_CENTER_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync({ legalEntityIds, withPagination }) {
  const fetchSyncTask = yield fork(_fetchList, { legalEntityIds, withPagination });
  yield take(DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(DEALER_CENTER_FETCH_LIST, function* ({ legalEntityIds, withPagination }) {
    yield call(fetchSync, { legalEntityIds, withPagination });
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(DEALER_CENTER_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: DEALER_CENTER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: DEALER_CENTER_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(DEALER_CENTER_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: DEALER_CENTER_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: DEALER_CENTER_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(DEALER_CENTER_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: DEALER_CENTER_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(DEALER_CENTER_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: DEALER_CENTER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: DEALER_CENTER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: DEALER_CENTER_FETCH_LIST });
  });
}

/* Item */
export function* fetchDealerItemRequest() {
  yield takeLatest(DEALER_CENTER__FETCH_ITEM, function* (action) {
    try {
      const { id } = action;
      const response = yield call(API.dealerCenter.fetchDealerItem, { id });

      if (response.data) {
        yield put({ type: DEALER_CENTER__FETCH_ITEM_SUCCESS, data: response.data });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: DEALER_CENTER__FETCH_ITEM_FAILURE, error: error.message });
    }
  });
}

function* create() {
  yield takeLatest(DEALER_CENTER__CREATE, function* (action) {
    const {
      salePoint,
      inn,
      krifId,
      acquiringID,
      onlineId,
      addressactual,
      email,
      phone,
      time,
    } = action;

    try {
      const response = yield call(API.dealerCenter.create, {
        salePoint,
        inn,
        krifId,
        acquiringID,
        onlineId,
        addressactual,
        email,
        phone,
        time,
      });

      if (response
        && response.data
        && response.data.id) {
        yield put({ type: DEALER_CENTER__CREATE_SUCCESS, data: response.data });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: DEALER_CENTER__CREATE_FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: DEALER_CENTER__CREATE_FAILURE, error: error.message });
    }
  });
}

function* edit() {
  yield takeLatest(DEALER_CENTER__EDIT, function* (action) {
    const {
      id,
      salePoint,
      inn,
      krifId,
      acquiringID,
      onlineId,
      addressactual,
      email,
      phone,
      time,
      bindSector,
      unbindSector,
    } = action;

    try {
      const response = yield call(API.dealerCenter.edit, {
        id,
        salePoint,
        inn,
        krifId,
        acquiringID,
        onlineId,
        addressactual,
        email,
        phone,
        time,
        bindSector,
        unbindSector,
      });

      if (response
        && response.data
        && response.data.id) {
        yield put({
          type: DEALER_CENTER__EDIT_SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: DEALER_CENTER__EDIT_FAILURE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: DEALER_CENTER__EDIT_FAILURE, error: error.message });
    }
  });
}

/* ARM */
export function* fetchDealers() {
  yield takeLatest(DEALER_CENTER__FETCH_DEALERS, function* () {
    try {
      const response = yield call(API.dealerCenter.fetchDealers);

      if (
        response.data
        && response.data.dealerCenterResponseItemList
        && response.data.dealerCenterResponseItemList.length > 0
      ) {
        yield put({
          type: DEALER_CENTER__FETCH_DEALERS_SUCCESS,
          list: response.data.dealerCenterResponseItemList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: DEALER_CENTER__FETCH_DEALERS_FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(fetchDealerItemRequest),
    fork(create),
    fork(edit),

    // arm
    fork(fetchDealers),
  ]);
}
