import {
  // list
  CONTRACT_FETCH_LIST,
  CONTRACT_FETCH_LIST__SUCCESS,
  CONTRACT_FETCH_LIST__FAILURE,
  CONTRACT_FETCH_LIST__SET_LOADING,
  CONTRACT_FETCH_LIST__SET_PAGINATION,
  CONTRACT_FETCH_LIST__SET_SEARCH,
  CONTRACT_FETCH_LIST__SET_SORTING,
  CONTRACT_FETCH_LIST__SET_FILTER,
  CONTRACT_FETCH_LIST__RESET,

  CONTRACT_UPDATE,
  CONTRACT_UPDATE__SUCCESS,
  CONTRACT_UPDATE__FAILURE,
  CONTRACT_UPDATE__RESET,

  CONTRACT_CREATE,
  CONTRACT_CREATE__SUCCESS,
  CONTRACT_CREATE__FAILURE,
  CONTRACT_CREATE__RESET,

  CONTRACT_FETCH,
  CONTRACT_FETCH__SUCCESS,
  CONTRACT_FETCH__FAILURE,
  CONTRACT_ITEM_RESET,

  CONTRACT_DELETE,
  CONTRACT_DELETE__SUCCESS,
  CONTRACT_DELETE__FAILURE,

  CONTRACT_REDIRECT,

  CONTRACT_REDIRECT_TO_NEW_COMPANY,

  CONTRACT_FETCH_POINT_OF_SALES,
  CONTRACT_FETCH_POINT_OF_SALES__SUCCESS,
  CONTRACT_FETCH_POINT_OF_SALES__FAILURE,
  CONTRACT_FETCH_POINT_OF_SALES__RESET,

  CONTRACT_SAVE,
  CONTRACT_SAVE__SUCCESS,
  CONTRACT_SAVE__FAILURE,
  CONTRACT_SAVE__RESET,

  CONTRACT_UNBIND_POINTS_OF_SALE,
  CONTRACT_UNBIND_POINTS_OF_SALE__SUCCESS,
  CONTRACT_UNBIND_POINTS_OF_SALE__FAILURE,
  CONTRACT_UNBIND_POINTS_OF_SALE__RESET,

  CONTRACT_BIND_POINTS_OF_SALE,
  CONTRACT_BIND_POINTS_OF_SALE__SUCCESS,
  CONTRACT_BIND_POINTS_OF_SALE__FAILURE,
  CONTRACT_BIND_POINTS_OF_SALE__RESET,

} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {};

const initState = {
  collection: {
    data: {},
    list: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
    pagination: {
      page: 1,
      pageSize: defaultPageSize,
      rowsCount: 0,
    },
  },

  item: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  save: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  unbindPointsOfSale: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  bindPointsOfSale: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  update: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  create: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  delete: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  redirect: {
    id: null,
    error: '',
  },

  newCompany: {
    inn: null,
    error: '',
  },

  pointOfSales: {
    bindList: [],
    unbindList: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function contractReducer(state = initState, action) {
  switch (action.type) {
    case CONTRACT_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case CONTRACT_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case CONTRACT_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case CONTRACT_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case CONTRACT_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
                state.collection.sorting.sortBy === action.sortBy
                  ? state.collection.sorting.order === ORDER.ASC
                    ? ORDER.DESC
                    : ORDER.ASC
                  : ORDER.ASC,
          },
        },
      };
    }

    case CONTRACT_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case CONTRACT_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,
          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,
          pagination: action.pagination,
          data: action.data,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CONTRACT_UPDATE__RESET: {
      return {
        ...state,

        update: {
          ...initState.update,
        },

        redirect: {
          ...initState.redirect,
        },

        newCompany: {
          ...initState.newCompany,
        },
      };
    }
    case CONTRACT_UPDATE: {
      return {
        ...state,

        update: {
          ...state.update,

          isLoading: true,
          isLoaded: false,
          error: null,
        },

        item: {
          isLoaded: false,
          isLoading: true,
        },
      };
    }
    case CONTRACT_UPDATE__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,
          data: action.data,
          isLoaded: true,
          isLoading: false,
        },

        update: {
          ...state.update,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_UPDATE__FAILURE: {
      return {
        ...state,

        update: {
          ...state.update,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },

        item: {
          ...state.item,

          isLoaded: false,
          isLoading: false,
        },

      };
    }

    case CONTRACT_CREATE__RESET: {
      return {
        ...state,

        create: {
          ...initState.create,
        },

        redirect: {
          ...initState.redirect,
        },

        newCompany: {
          ...initState.newCompany,
        },
      };
    }
    case CONTRACT_CREATE: {
      return {
        ...state,

        create: {
          ...state.create,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_CREATE__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,
          data: action.data,
          isLoaded: true,
        },

        create: {
          ...state.create,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_CREATE__FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CONTRACT_FETCH: {
      return {
        ...state,

        item: {
          ...state.item,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_FETCH__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_FETCH__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CONTRACT_DELETE: {
      return {
        ...state,

        delete: {
          ...state.delete,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_DELETE__SUCCESS: {
      return {
        ...state,

        delete: {
          ...state.delete,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_DELETE__FAILURE: {
      return {
        ...state,

        delete: {
          ...state.delete,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case CONTRACT_SAVE: {
      return {
        ...state,

        save: {
          ...state.save,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_SAVE__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,
          data: action.data,
          isLoaded: true,
        },

        save: {
          ...state.save,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_SAVE__FAILURE: {
      return {
        ...state,

        save: {
          ...state.save,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },

        item: {
          ...state.item,
          isLoaded: false,
          isLoading: false,
        },
      };
    }
    case CONTRACT_SAVE__RESET: {
      return {
        ...state,

        save: {
          ...initState.save,
        },
      };
    }

    case CONTRACT_UNBIND_POINTS_OF_SALE: {
      return {
        ...state,

        unbindPointsOfSale: {
          ...state.unbindPointsOfSale,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_UNBIND_POINTS_OF_SALE__SUCCESS: {
      return {
        ...state,

        unbindPointsOfSale: {
          ...state.unbindPointsOfSale,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_UNBIND_POINTS_OF_SALE__FAILURE: {
      return {
        ...state,

        unbindPointsOfSale: {
          ...state.unbindPointsOfSale,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CONTRACT_UNBIND_POINTS_OF_SALE__RESET: {
      return {
        ...state,

        unbindPointsOfSale: {
          ...initState.unbindPointsOfSale,
        },
      };
    }

    case CONTRACT_BIND_POINTS_OF_SALE: {
      return {
        ...state,

        bindPointsOfSale: {
          ...state.bindPointsOfSale,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case CONTRACT_BIND_POINTS_OF_SALE__SUCCESS: {
      return {
        ...state,

        bindPointsOfSale: {
          ...state.bindPointsOfSale,

          data: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CONTRACT_BIND_POINTS_OF_SALE__FAILURE: {
      return {
        ...state,

        bindPointsOfSale: {
          ...state.bindPointsOfSale,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CONTRACT_BIND_POINTS_OF_SALE__RESET: {
      return {
        ...state,

        bindPointsOfSale: {
          ...initState.bindPointsOfSale,
        },
      };
    }

    case CONTRACT_REDIRECT: {
      return {
        ...state,

        redirect: { ...action.data },
      };
    }

    case CONTRACT_REDIRECT_TO_NEW_COMPANY: {
      return {
        ...state,

        newCompany: { ...action.data },
      };
    }

    case CONTRACT_ITEM_RESET: {
      return {
        ...state,

        item: {
          ...initState.item,
        },
      };
    }

    case CONTRACT_FETCH_POINT_OF_SALES: {
      return {
        ...state,

        pointOfSales: {
          ...state.pointOfSales,
          isLoading: true,
          isLoaded: false,
          error: false,
        },
      };
    }
    case CONTRACT_FETCH_POINT_OF_SALES__SUCCESS: {
      return {
        ...state,

        pointOfSales: {
          ...state.pointOfSales,

          bindList: action.data.bindList,
          unbindList: action.data.unbindList,
          isLoading: false,
          isLoaded: true,
          error: false,
        },
      };
    }
    case CONTRACT_FETCH_POINT_OF_SALES__FAILURE: {
      return {
        ...state,

        pointOfSales: {
          ...state.pointOfSales,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }
    case CONTRACT_FETCH_POINT_OF_SALES__RESET: {
      return {
        ...state,

        pointOfSales: {
          ...initState.pointOfSales,
        },
      };
    }

    default: {
      return state;
    }
  }
}
