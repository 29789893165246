import { Checkbox, CheckBoxGroup } from 'components/tcm/Checkbox';
import {
  Radio, RadioGroup, RadioTextGroup, RadioBtnText,
} from 'components/tcm/Radio';
import {
  Button, ButtonOutline, ButtonText, ButtonGroup, ButtonFilter,
} from './Button';
import {
  Input, LabeledInput, Search, InputSearch,
} from './Input';
import { InputNumber } from './InputNumber';
import { Select, LabeledSelect, Option } from './Select';
import { MultipleSelect, MultipleOption } from './MultipleSelect';
import { Textarea, LabeledTextarea } from './Textarea';
import { Label } from './Label';
import { DateRange, StaticDateRange } from './DateRange';
import { InputRange } from './InputRange';
import { Modal } from './Modal';
import { DatePicker, TimePicker, StaticDatePicker } from './DatePicker';
import Loader from './Loader';
import { SliderBlock } from './Slider';
import { ErrorMessage } from './ErrorMessage';

const TCM = {
  Button,
  ButtonOutline,
  ButtonText,
  ButtonGroup,
  ButtonFilter,
  Input,
  InputSearch,
  Search,
  InputNumber,
  LabeledInput,
  Select,
  LabeledSelect,
  MultipleOption,
  MultipleSelect,
  Option,
  Checkbox,
  CheckBoxGroup,
  Radio,
  RadioGroup,
  RadioBtnText,
  RadioTextGroup,
  Textarea,
  LabeledTextarea,
  Label,
  DateRange,
  StaticDateRange,
  InputRange,
  DatePicker,
  TimePicker,
  StaticDatePicker,
  Modal,
  Loader,
  SliderBlock,
  ErrorMessage,
};

export { TCM };
