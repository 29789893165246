import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import tus from 'tus-js-client';

import {
  API_URL as API,
} from 'settings/api';

import {
  Form,
  Upload,
  Input,
} from 'antd';

import Select from 'components/Select';
import Button from 'components/Button';

import {
  fileUpload as fileUploadAction,
  resetUpload as resetUploadAction,
  resetDownload as resetDownloadAction,
} from 'redux/files/actions';

import { KEYS } from './data';

import {
  Root,
  FormStyled,
  Footer,
  ErrorMessage,
  HiddenContainer,
  HiddenItem,
  DraggerContainer,
} from './style';

const {
  COMPANY,
  DEALER_CENTER,
  COMMENT,
  FILES,
} = KEYS;

const { Dragger } = Upload;

const fileMaxSizeMb = 25;

function escapeHtml(string) {
  return String(string).replace(/[^\dA-Za-zА-Яа-я.-]/g, '_');
}

function AddFile({
  // passed
  close,

  companyList,
  companyListIsLoading,
  companyListIsLoaded,
  companyListError,

  visible,

  // connect
  isLoading,
  isLoaded,
  error,
  errors,

  // actions
  fileUpload,
  resetUpload,
  resetDownload,
}) {
  const [form] = Form.useForm();
  const { companyId } = useParams();
  const [isMounted, setIsMounted] = useState(false);
  const [dealerCentersByCompany, setDealerCentersByCompany] = useState([]);
  const [isValidated, setIsValidated] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [sizeError, setSizeError] = useState('');
  const [tusAction, setTusAction] = useState(false);

  const handleUpload = (file) => {
    const fileSizeMb = file.size * 0.000001;

    setSizeError('');
    setTusAction(true);

    if (fileSizeMb <= fileMaxSizeMb) {
      const upload = new tus.Upload(file, {
        endpoint: `${API}/admin/document/load/file`,
        chunkSize: 1048576,
        retryDelays: [0, 3000],
        resume: true,
        removeFingerprintOnSuccess: true,

        metadata: {
          filename: escapeHtml(file.name),
          filetype: file.type,
          legalEntityId: Number(companyId),
        },

        onError(error) {
          setSizeError(error);
        },
        onSuccess() {
          setFileList([...fileList.filter((item) => escapeHtml(item.name) !== escapeHtml(file.name)),
                       { ...file, name: escapeHtml(file.name) }]);
          setTusAction(false);
        },
      });
      upload.start();
    } else {
      setSizeError('Размер загружаемого файла не может превышать 25МБ');
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    beforeUpload: (file) => {
      handleUpload(file);
      return false;
    },
    onRemove: () => {
      setFileList([]);
    },
  };

  const handleCompanyChange = useCallback((companyId) => {
    form.setFieldsValue({
      [DEALER_CENTER.key]: '',
    });

    const selectedCompany = companyList.find((e) => e.id === companyId);

    if (selectedCompany) {
      if (Array.isArray(selectedCompany.subsidiariesList)) {
        setDealerCentersByCompany(selectedCompany.subsidiariesList);
      }
    }
  }, [form, companyList]);

  function handleSearchCompany() {
    form.setFieldsValue({
      [DEALER_CENTER.key]: '',
    });
  }

  function handleSearchDealerCenter(value) {
    const companyId = form.getFieldValue(COMPANY.key);
    const selectedCompany = companyList.find((e) => e.id === companyId);
    const { subsidiariesList } = selectedCompany;

    if (Array.isArray(subsidiariesList) && subsidiariesList.length > 0) {
      setDealerCentersByCompany(
        subsidiariesList
          .map((item) => ({ ...item, search: item.name ? item.name.toLowerCase() : '' }))
          .filter((item) => item.search.includes(value.toLowerCase())),
      );
    } else {
      setDealerCentersByCompany([]);
    }
  }

  function handleBlur(_e, name) {
    form.validateFields([name]);
  }

  function handleFocus(_e, name) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleFinish(values) {
    const {
      [COMPANY.key]: legalEntityId,
      [DEALER_CENTER.key]: subsidiaryId,
      [COMMENT.key]: comment,
    } = values;

    fileUpload({
      legalEntityId,
      subsidiaryId: subsidiaryId || null,
      comment,
      fileName: fileList[0].name,
    });

    setIsValidated(false);
  }

  function reset() {
    setIsValidated(true);
    form.resetFields();
  }

  function cancel() {
    if (isLoaded) {
      reset();
    }
    setSizeError('');
    close();
  }

  useEffect(() => {
    // we do not have access to `form` hook till the <form> component is rendered
    if (visible) {
      setIsMounted(true);
    } else {
      resetUpload();
      resetDownload();
      setFileList([]);
      setSizeError('');
      setIsValidated(true);
      form.resetFields();
      setIsMounted(false);
    }
  }, [visible, resetUpload, form, setIsMounted, resetDownload]);
  /* start: write fetch errors
  if we failed to fetch one of the lists - get an error message and put it under the input
 */
  useEffect(() => {
    if (isMounted && companyListError) {
      form.setFields([{ name: COMPANY.key, errors: [companyListError] }]);
    }
  }, [isMounted, companyListError, form]);

  useEffect(() => {
    if (isMounted && isLoaded) {
      resetUpload();
      setFileList([]);
      close();
      setIsValidated(true);
      form.resetFields();
    }
  }, [isMounted, isLoaded, form, close, resetUpload]);

  useEffect(() => {
    if (!isValidated && !isLoading && Array.isArray(errors) && errors.length > 0) {
      const errorsFlat = errors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));
      const {
        [COMPANY.apiKey]: company,
        [DEALER_CENTER.apiKey]: point,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(company ? [{ name: COMPANY.key, errors: [company] }] : []),
        ...(point ? [{ name: DEALER_CENTER.key, errors: [point] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [errors, isLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if (companyListIsLoaded && companyId && isMounted) {
      form.setFields([
        ...([{ name: COMPANY.key, value: Number(companyId) }]),
      ]);
      handleCompanyChange(Number(companyId));
    }
  }, [companyListIsLoaded, companyId, form, handleCompanyChange, isMounted]);

  return (
    <Root
      visible={visible}
      onCancel={cancel}
      footer={null}
      width={768}
    >
      <FormStyled
        name="AddRole"
        layout="vertical"
        hideRequiredMark
        form={form}
        onFinish={handleFinish}
      >
        <HiddenContainer>
          <HiddenItem>
            <Form.Item
              name={COMPANY.key}
              label={COMPANY.title}
              rules={COMPANY.rules}
              validateFirst
              validateTrigger="onBlur"
            >
              <Select
                name={COMPANY.key}
                showSearch
                onSearch={handleSearchCompany}
                onFocus={(e) => handleFocus(e, COMPANY.key)}
                onBlur={(e) => handleBlur(e, COMPANY.key)}
                onChange={handleCompanyChange}
                loading={companyListIsLoading}
                disabled
                filterOption={false}
                list={companyList}
              />
            </Form.Item>
          </HiddenItem>
        </HiddenContainer>
        <DraggerContainer hide={fileList.length > 0}>
          <Form.Item
            name={FILES.key}
            label={FILES.title}
            rules={FILES.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Dragger {...props}>
              <p>Переместить сюда</p>
              <p>или</p>
              <Button>
                + Выберите файл
              </Button>
            </Dragger>
          </Form.Item>
        </DraggerContainer>
        {sizeError && <ErrorMessage>{sizeError}</ErrorMessage>}
        <Form.Item
          name={DEALER_CENTER.key}
          label={DEALER_CENTER.title}
          rules={DEALER_CENTER.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Select
            showSearch
            onSearch={handleSearchDealerCenter}
            name={DEALER_CENTER.key}
            onFocus={(e) => handleFocus(e, DEALER_CENTER.key)}
            onBlur={(e) => handleBlur(e, DEALER_CENTER.key)}
            loading={companyListIsLoading}
            disabled={companyListIsLoading}
            filterOption={false}
            list={dealerCentersByCompany}
            hasDefault={false}
          />
        </Form.Item>

        <Form.Item
          name={COMMENT.key}
          label={COMMENT.title}
          rules={COMMENT.rules}
          validateFirst
          validateTrigger="onBlur"
        >
          <Input.TextArea
            name={COMMENT.key}
            onFocus={handleFocus}
            onBlur={handleBlur}
            rows={5}
          />
        </Form.Item>

        {error && !isLoading && <ErrorMessage>{error}</ErrorMessage>}

        <Footer>
          <Button
            type="primary"
            shape="square"
            size="small"
            htmlType="submit"
            loading={tusAction || isLoading}
            disabled={!(fileList.length > 0)}
          >
            Прикрепить
          </Button>
        </Footer>
      </FormStyled>
    </Root>
  );
}

export default connect(
  (state) => ({
    isLoading: state.files.upload.isLoading,
    isLoaded: state.files.upload.isLoaded,
    error: state.files.upload.error,
  }),
  {
    fileUpload: fileUploadAction,
    resetUpload: resetUploadAction,
    resetDownload: resetDownloadAction,
  },
)(AddFile);
