/* List */
export const CAMPAIGN_OFFER_FETCH_LIST = 'CAMPAIGN_OFFER_FETCH_LIST';
export const CAMPAIGN_OFFER_FETCH_LIST__SUCCESS = 'CAMPAIGN_OFFER_FETCH_LIST__SUCCESS';
export const CAMPAIGN_OFFER_FETCH_LIST__FAILURE = 'CAMPAIGN_OFFER_FETCH_LIST__FAILURE';
export const CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST = 'CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST';
export const CAMPAIGN_OFFER_FETCH_LIST__SET_LOADING = 'CAMPAIGN_OFFER_FETCH_LIST__SET_LOADING';
export const CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION = 'CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION';

export const fetchList = ({
  query,
  filters,
  sorting,
} = {}) => ({
  type: CAMPAIGN_OFFER_FETCH_LIST,
  query,
  filters,
  sorting,
});

export const CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH = 'CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({ type: CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH, search });

export const CAMPAIGN_OFFER_FETCH_LIST__SET_PAGE = 'CAMPAIGN_OFFER_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: CAMPAIGN_OFFER_FETCH_LIST__SET_PAGE, page });

export const CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING = 'CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER = 'CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER,
  filters,
});

export const CAMPAIGN_OFFER_FETCH_LIST__RESET = 'CAMPAIGN_OFFER_FETCH_LIST__RESET';
export const reset = () => ({ type: CAMPAIGN_OFFER_FETCH_LIST__RESET });

export const CAMPAIGN__OFFER_FETCH_LIST__RESET_FILTER = 'CAMPAIGN__OFFER_FETCH_LIST__RESET_FILTER';
export const resetFilters = () => ({
  type: CAMPAIGN__OFFER_FETCH_LIST__RESET_FILTER,
});

/* Filter Options */
export const CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS = 'CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS';
export const CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__SUCCESS = 'CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__SUCCESS';
export const CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__FAILURE = 'CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__FAILURE';

export const CAMPAIGN_OFFER_FETCH_STATUSES = 'CAMPAIGN_OFFER_FETCH_STATUSES';
export const CAMPAIGN_OFFER_FETCH_STATUSES__SUCCESS = 'CAMPAIGN_OFFER_FETCH_STATUSES__SUCCESS';
export const CAMPAIGN_OFFER_FETCH_STATUSES__FAILURE = 'CAMPAIGN_OFFER_FETCH_STATUSES__FAILURE';
export const fetchOfferStatuses = () => ({ type: CAMPAIGN_OFFER_FETCH_STATUSES });
