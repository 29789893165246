import React from 'react';

import { InputStyled } from './style';

const defaultProps = {};

export const InputNumber = React.forwardRef((props, ref) => (
  <InputStyled {...defaultProps} {...props} ref={ref} />
));
export default InputNumber;
