import React, { useState, useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { connect } from 'react-redux';
import { throttle } from 'underscore';
import styled from 'styled-components';
import { useInterval } from './hooks';

import { sendUserActivity as sendUserActivityAction } from './redux/user/actions';
import { logout as logoutActon } from './redux/auth/actions';

const Root = styled.div`
  height: 100%;
`;

// 5 second
const SEND_ACTIVITY_INTERVAL = 5 * 1000;

// 10 second
const DEFAULT_SESSION_TIME = 10 * 1000;

function UserActivity({
  // passed
  children,

  // store,
  token,
  isLogout,
  // actions
  sendUserActivity,
  logout,
}) {
  // логика на сброс сессии:
  // 1 когда у Пользователя открыта вкладка в браузере с АРМ и пользователь
  //  - не двигает курсором
  //  - и не осуществляет ввод с клавиатуры в течении 5 и более минут
  // 2 когда у Пользователя открыта вкладка в браузере с АРМ, но
  //  - Пользователь перешел на другую вкладку на 5 и более минут
  const [isUserActive, setIsUserActive] = useState(true);
  const [sessionTimer, setSessionTimer] = useState(DEFAULT_SESSION_TIME);
  const [isSessionActive, setIsSessionActive] = useState(true);

  function setUserActivity() {
    setIsUserActive(true);
    setSessionTimer(DEFAULT_SESSION_TIME);
    setIsSessionActive(true);
  }

  const setUserActivityThrottle = throttle(setUserActivity, 1000);

  useInterval(
    () => {
      sendUserActivity();
    },
    isUserActive && isSessionActive && token ? SEND_ACTIVITY_INTERVAL : null,
  );

  useInterval(
    () => {
      const time = sessionTimer - 1000;
      setSessionTimer(time);
    },
    isUserActive && isSessionActive && token ? 1000 : null,
  );

  useInterval(
    () => {
      const userInfo = localStorage.getItem('USER');
      const { accessToken } = userInfo ? JSON.parse(userInfo) : {};

      if (accessToken && token) {
        if ((accessToken !== token) && !isLogout) {
          logout();
        }
      }
    },
    3000,
  );

  const isVisible = usePageVisibility();

  useEffect(() => {
    if (!isVisible) {
      setIsUserActive(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (sessionTimer === 0) {
      setIsSessionActive(false);
    }
  }, [sessionTimer]);

  return (
    <Root
      onMouseMove={setUserActivityThrottle}
      onTouchMove={setUserActivityThrottle}
      onKeyUp={setUserActivityThrottle}
    >
      {children}
    </Root>
  );
}

export default connect(
  (state) => ({
    token: state.auth.idToken,
    isLogout: state.auth.isLogout,
  }),
  {
    sendUserActivity: sendUserActivityAction,
    logout: logoutActon,
  },
)(UserActivity);
