import { CreateErrorModal } from 'components/tcm/Modals';
import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message,
    };
  }

  render() {
    const { hasError, errorMessage } = this.state;
    const {
      children,
      title = 'Ошибка в системе. Обратитесь в техподдержку',
    } = this.props;

    if (hasError) {
      return (
        <CreateErrorModal
          title={title}
          description={errorMessage}
          visible
          withoutClose
        />
      );
    }

    return children;
  }
}
