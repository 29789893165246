export const defineDependencies = (dependencies, values) => {
  if (typeof dependencies === 'object' && dependencies !== null) {
    return Object.entries(dependencies).reduce(
      (p, [k, v]) => ({ ...p, [k]: values[v] }),
      {},
    );
  }
  return {};
};

export const flattenDependencies = (dependencies) => {
  if (typeof dependencies === 'object' && dependencies !== null) {
    return Object.entries(dependencies).reduce(
      (p, [k, v]) => ({
        ...p,
        ...(typeof v === 'object' ? { [k]: Object.values(v)[0] } : { [k]: v }),
      }),
      {},
    );
  }
  return {};
};
