import styled from 'styled-components';

const Root = styled.div`
  height: 100%;
  position: relative;
  padding-bottom: 30px;
`;

const Title = styled.h3`
  margin: 20px 0;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 30px;
  align-items: center;
  border: 1px solid black;
  padding: 20px;
`;
const GridRow = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  align-items: flex-end;
  border-bottom: ${({ borderNone }) => (borderNone ? 'none' : '1px solid #ddd;')}
`;

const GridItem = styled.div`
  font-family: "Toyota Type", sans-serif;
  color: ${({ label }) => (label ? '#777676' : '#000')};
`;

export {
  Root,
  Grid,
  GridItem,
  GridRow,
  Title,
};
