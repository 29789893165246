import React from 'react';
import { RestrictedRoute, checkRedirectRoute } from 'router';
import { Switch } from 'react-router-dom';

import CreateUser from './CreateUser';
import { Root } from './style';

const checkRights = (role) => checkRedirectRoute(role.SETTINGS.EDIT);

function Index() {
  return (
    <Root>
      <Switch>
        <RestrictedRoute
          exact
          path="/app/settings"
          component={CreateUser}
          checkRights={checkRights}
        />
      </Switch>
    </Root>
  );
}

export default Index;
