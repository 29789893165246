import API from 'services';
import { ON_ERROR } from 'redux/auth/actions';

import {
  extractErrorAndCode,
  formatOfferList,
} from 'hacks';

import {
  all,
  takeLatest,
  put,
  fork,
  call,
  select,
  cancel,
  take,
} from 'redux-saga/effects';

import { CAMPAIGN_STATUSES } from '../../tcm/campaigns/data';

import {
  // list
  CAMPAIGN_OFFER_FETCH_LIST,
  CAMPAIGN_OFFER_FETCH_LIST__SUCCESS,
  CAMPAIGN_OFFER_FETCH_LIST__FAILURE,
  CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST,
  CAMPAIGN_OFFER_FETCH_LIST__SET_LOADING,
  CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION,
  CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH,
  CAMPAIGN_OFFER_FETCH_LIST__SET_PAGE,
  CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING,
  CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER,
  CAMPAIGN_OFFER_FETCH_LIST__RESET,

  // filter options
  CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS,
  CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__SUCCESS,
  CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__FAILURE,

  CAMPAIGN_OFFER_FETCH_STATUSES,
  CAMPAIGN_OFFER_FETCH_STATUSES__SUCCESS,
  CAMPAIGN_OFFER_FETCH_STATUSES__FAILURE,
} from './actions';

const getState = (state) => state.tcmCampaignOffer;
const getStateCampaign = (state) => state.tcmCampaign;

const formatToFilter = (data = []) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((item) => ({ value: item.id || item.name, label: item.name }));
};

function* _fetchList() {
  const {
    collection: {
      search, filters, sorting,
    },
  } = yield select(getState);

  const {
    campaign: {
      data,
    },
  } = yield select(getStateCampaign);
  const { id, statusCode } = data;

  try {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__SET_LOADING, id });
    const response = yield call(API.TCM.campaign.fetchCampaignOfferList, {
      search,
      filters: {
        ...filters.data,
        brands: filters.data.brands ? filters.data.brands.map((item) => item.label) : [],
        models: filters.data.models ? filters.data.models.map((item) => item.label) : [],
        suffixTMS: filters.data.suffixTMS ? filters.data.suffixTMS.map((item) => item.label) : [],
        employees: filters.data.employees ? filters.data.employees.map((item) => item.value) : [],
      },
      sorting: statusCode === CAMPAIGN_STATUSES.CALLING ? {
        ...sorting,
        sortBy: 'status',
      } : { ...sorting },
      id,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.offerItemResponseList))
    ) {
      const {
        topicItemResponseList,
        subtopicItemResponseList,
        suffixTMSList,
      } = response.data;

      yield put({
        type: CAMPAIGN_OFFER_FETCH_LIST__SUCCESS,
        list: formatOfferList(response.data),
        filters: {
          brands: topicItemResponseList ? formatToFilter(topicItemResponseList) : [],
          models: subtopicItemResponseList ? formatToFilter(subtopicItemResponseList) : [],
          suffixTMS: suffixTMSList ? formatToFilter(suffixTMSList) : [],
        },
      });

      yield put({ type: CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS, campaignId: data.id });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST });
  });
}

// Filter Options
export function* fetchFilterOptions() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS, function* ({ campaignId }) {
    try {
      const response = yield call(API.TCM.campaign.fetchFilterOptions, { campaignId });

      if (
        response
        && response.data
        && Array.isArray(response.data.brandList)
        && Array.isArray(response.data.suffixList)
      ) {
        yield put({
          type: CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__SUCCESS,
          filters: {
            brands: response.data.brandList ? formatToFilter(response.data.brandList) : [],
            brandList: response.data.brandList,
            suffixTMS: response.data.suffixList ? formatToFilter(response.data.suffixList) : [],
          },
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_OFFER_FETCH_FILTER_OPTIONS__FAILURE, error: error.message });
    }
  });
}

function* fetchStatuses() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_STATUSES, function* () {
    try {
      const response = yield call(API.TCM.campaign.fetchCampaignOfferStatuses);
      if (
        !response.errorCode && Array.isArray(response?.data?.offersStatusCountResponseList)
      ) {
        yield put({
          type: CAMPAIGN_OFFER_FETCH_STATUSES__SUCCESS,
          data: response.data.offersStatusCountResponseList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CAMPAIGN_OFFER_FETCH_STATUSES__FAILURE, error: error.message });
    }
  });
}

export function* fetchListReset() {
  yield takeLatest(CAMPAIGN_OFFER_FETCH_LIST__RESET, function* () {
    yield put({ type: CAMPAIGN_OFFER_FETCH_LIST__CANCEL_REQUEST });
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(fetchFilterOptions),
    fork(fetchStatuses),
    fork(fetchListReset),
  ]);
}
