import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Input as AntInput } from 'antd';
import MaskedInput from 'antd-mask-input';

import { cursorToLastPhoneValuePosition } from 'utils';

import { TCM } from 'components/tcm';
import { TagCloseIcon } from 'icons';
import { Label } from './Label';
import withTheme from './withTheme';
import { ErrorMessage } from './ErrorMessage';

export const Input = withTheme(styled(({ theme, ...p }) => <AntInput {...p} />)`
  ${({ theme }) => css`
    &, & .ant-input {
      padding: ${theme.input.padding};
      border-color: ${theme.input.borderColor};
      border-radius: ${theme.input.borderRadius}px;
      font-family: ${theme.fontFamily};
      font-weight: ${theme.input.fontWeight};
      font-size: ${theme.input.fontSize}px;
      line-height: ${theme.input.lineHeight}px;
      color: ${theme.input.textColor};
      box-sizing: border-box;
    }


    &::placeholder {
      font-weight: ${theme.input.fontWeightPlaceholder};
      font-size: ${theme.input.fontSize}px;
      line-height: ${theme.input.lineHeight}px;
      color: ${theme.input.textPlaceholderColor};
      opacity: 1;
    }

    &.invalid {
      border-color ${theme.input.borderInvalidColor};
    }
  `}
`);

export const InputSearch = withTheme(styled(({ theme, ...p }) => <AntInput.Search {...p} />)`
  ${({ theme }) => css`
    &, & .ant-input {
      border-color: ${theme.input.borderColor};
      border-radius: ${theme.input.borderRadius}px;
      font-family: ${theme.fontFamily};
      font-weight: ${theme.input.fontWeight};
      font-size: ${theme.input.fontSize}px;
      line-height: ${theme.input.lineHeight}px;
      color: ${theme.input.textColor};
      box-sizing: border-box;
    }
    .ant-input {
      padding: ${theme.input.padding};
    }

    & .ant-input-search-button {
        height: 50px;
    }

    ${({ enterButton }) => !enterButton && css`
      & .anticon:before {
      border-left: none;
      }

      & .anticon-search {
          display: none;
        }
    `}

    &::placeholder {
      font-weight: ${theme.input.fontWeightPlaceholder};
      font-size: ${theme.input.fontSize}px;
      line-height: ${theme.input.lineHeight}px;
      color: ${theme.input.textPlaceholderColor};
      opacity: 1;
    }

    &.invalid {
      border-color ${theme.input.borderInvalidColor};
    }

    .ant-input-group-addon .ant-input-search-button {
      border-left: none;
    }
  `}
`);

const SearchWrapper = withTheme(styled.form`
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 1em;
  align-items: center;
  padding: 13px 20px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.input.borderColor};
  border-radius: ${({ theme }) => theme.input.borderRadius}px;
  font-weight: ${({ theme }) => theme.input.fontWeight};
  font-size: ${({ theme }) => theme.input.fontSize}px;
  line-height: ${({ theme }) => theme.input.lineHeight}px;
  color: ${({ theme }) => theme.input.textColor};
  box-sizing: border-box;

  input, input:focus {
    border: none;
    outline: none;
  }

  input::placeholder {
    font-weight: ${({ theme }) => theme.input.fontWeightPlaceholder};
    font-size: ${({ theme }) => theme.input.fontSize}px;
    line-height: ${({ theme }) => theme.input.lineHeight}px;
    color: ${({ theme }) => theme.input.textPlaceholderColor};
    opacity: 1;
  }
`);

const ClearBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Search = ({
  onSearch, onBlur, value = '', notEmpty, ...p
}) => {
  const [query, setQuery] = useState(value);
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [showSearchBtn, setShowSearchBtn] = useState(false);

  const submitRef = useRef(null);

  const onPressEnter = (event) => {
    if (event.keyCode === 13) {
      onSearch(query);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    onSearch(query);
  };

  const onChange = (event) => {
    setQuery(event.target.value);
    if (event.target.value.length > 0) {
      setShowSearchBtn(true);
    }
  };

  const onClear = (event) => {
    onSearch('');
    setQuery('');
    event.target.blur();
  };

  return (
    <SearchWrapper
      onSubmit={onSubmit}
    >
      <input
        {...p}
        value={query}
        placeholder="Поиск"
        type="search"
        onKeyPress={onPressEnter}
        onChange={onChange}
        onBlur={(event) => {
          if (submitRef.current && event.relatedTarget !== submitRef.current) {
            if (notEmpty) {
              setShowClearBtn(true);
            }
            setShowSearchBtn(false);
          }
        }}
        onFocus={() => {
          if (query) {
            setShowSearchBtn(true);
          }
          setShowClearBtn(false);
        }}
      />
      {showSearchBtn && (
        <TCM.ButtonText htmlType="submit" ref={submitRef}>
          Поиск
        </TCM.ButtonText>
      )}
      {showClearBtn && (
        <ClearBtn onClick={onClear} type="button"><TagCloseIcon /></ClearBtn>
      )}
    </SearchWrapper>
  );
};

export const LabeledInput = ({ label, ...p }) => (
  <Label label={label}>
    <Input {...p} />
  </Label>
);

const PhoneInputWrapper = withTheme(styled.form`
  position: relative;
  width: 370px;
  border: 1px solid ${({ theme }) => theme.input.borderColor};
  border-radius: ${({ theme }) => theme.input.borderRadius}px;
  font-weight: ${({ theme }) => theme.input.fontWeight};
  font-size: ${({ theme }) => theme.input.fontSize}px;
  line-height: ${({ theme }) => theme.input.lineHeight}px;
  color: ${({ theme }) => theme.input.textColor};
  box-sizing: border-box;
  background: #ffffff;
  padding: 0;

  .ant-btn {
    position: absolute;
    padding: 15px;
    top: 0;
    right: 0;
  }
  .ant-input, .ant-input:focus{
    padding: 15px;
    font-size: 16px;
    border: none;
    outline: none;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.input.fontWeight};
    font-size: ${({ theme }) => theme.input.fontSize}px;
    line-height: ${({ theme }) => theme.input.lineHeight}px;
    color: ${({ theme }) => theme.input.textColor};
  }
`);

export const PhoneInput = ({
  onSubmit, value = '', loading, ...p
}) => {
  const [touched, setTouched] = useState(false);
  const isValid = Boolean(value.match(/\+\d \(\d\d\d\) \d\d\d-\d\d-\d\d/));
  const phoneField = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      setTouched(false);
      onSubmit(e);
    } else {
      setTouched(true);
    }
  };
  return (
    <>
      <PhoneInputWrapper onSubmit={handleSubmit}>
        <MaskedInput
          {...p}
          value={value}
          onClick={() => cursorToLastPhoneValuePosition(phoneField)}
          ref={phoneField}
        />
        {isValid
        && (
          <TCM.ButtonText htmlType="submit" loading={loading}>
            {loading ? 'Сохранение...' : 'Сохранить'}
          </TCM.ButtonText>
        )}

      </PhoneInputWrapper>
      {!isValid && touched && <ErrorMessage>Некорректно введен номер</ErrorMessage>}
    </>
  );
};
