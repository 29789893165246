import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Popconfirm, Table } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import Button from 'components/Button';

import {
  fetchListPointOfSales as fetchListPointOfSalesAction,
  fetchListPointOfSalesReset as fetchListPointOfSalesResetAction,
  contractBindPointsOfSale as contractBindPointsOfSaleAction,
  contractUnbindPointsOfSale as contractUnbindPointsOfSaleAction,
  contractBindPointsOfSaleReset as contractBindPointsOfSaleResetAction,
  contractUnbindPointsOfSaleReset as contractUnbindPointsOfSaleResetAction,
} from 'redux/contracts/actions';

import Select from 'components/Select';
import {
  Root,
  DeleteButton,
  AddPointButtonContainer,
  AddPointContainer,
  EmptyMessage,
  ErrorMessage,
  LoadingMessage,
} from './style';

const scroll = { y: 'calc(100vh - 460px)' };

function PointOfSalesTable({
  // passed
  canDeletePointOfSales,
  isEdit,
  isNew,
  isSave,

  unbindPointsOfSale,
  bindPointsOfSale,
  setUnbindPointsOfSale,
  setBindPointsOfSale,

  // store
  item,
  itemIsLoaded,

  pointOfSalesIsLoading,
  pointOfSalesError,

  // actions
  fetchListPointOfSales,
  fetchListPointOfSalesReset,
  contractBindPointsOfSale,
  contractUnbindPointsOfSale,
  contractBindPointsOfSaleReset,
  contractUnbindPointsOfSaleReset,

}) {
  const [isAddPoint, setIsAddPoint] = useState(false);
  const [form] = Form.useForm();

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleDelete(itemId) {
    setBindPointsOfSale(bindPointsOfSale.filter((item) => item.id !== itemId));
    setUnbindPointsOfSale([
      ...unbindPointsOfSale,
      bindPointsOfSale.find((item) => item.id === itemId),
    ]);
  }

  function handleFinish(values) {
    const { salePoint: salePointId } = values;
    setUnbindPointsOfSale(unbindPointsOfSale.filter((item) => item.id !== salePointId));
    setBindPointsOfSale([
      ...bindPointsOfSale,
      unbindPointsOfSale.find((item) => item.id === salePointId),
    ]);
    form.setFields([{ name: 'salePoint', value: '' }]);
    setIsAddPoint(false);
  }

  const locale = {
    emptyText: pointOfSalesIsLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : pointOfSalesError ? (
      <ErrorMessage>{pointOfSalesError}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const COLUMNS = [
    {
      key: 'salePoint',
      title: () => 'Точка продаж',
      render: ({ salePoint }) => (
        <span>{salePoint}</span>
      ),
      width: 180,
    },
    {
      key: 'dealershipId',
      title: () => 'Эквайринг ID',
      render: ({ dealershipId }) => <span>{dealershipId}</span>,
      width: 220,
      align: 'center',
    },
    {
      key: 'id',
      render: ({ id }) => (isNew || isEdit) && canDeletePointOfSales && (
        <Popconfirm
          title="Вы уверены, что хотите выполнить удаление?"
          icon={<WarningOutlined />}
          onConfirm={() => handleDelete(id)}
          okButtonProps={{ danger: true }}
          okText="Да, удалить"
          cancelText="Нет"
        >
          <DeleteButton
            type="ghost"
          >
            Удалить
          </DeleteButton>
        </Popconfirm>

      ),
      width: 150,
      align: 'left',
    },
  ];

  useEffect(() => {
    if (itemIsLoaded) {
      const { sector, inn } = item;

      if (sector && inn) {
        fetchListPointOfSales({ inn, sector: String(sector) });
      }

      form.setFields([{ name: 'salePoint', value: '' }]);
    }
  }, [
    form,
    itemIsLoaded,
    item,
    fetchListPointOfSales,
  ]);

  useEffect(() => {
    if (isSave) {
      const { sector } = item;
      const bindSubsidiariesIds = bindPointsOfSale.filter((item) => item.id).map((item) => item.id);
      const unbindSubsidiariesIds = unbindPointsOfSale.filter((item) => item.id).map((item) => item.id);

      if (bindSubsidiariesIds.length > 0) {
        contractBindPointsOfSale({ sector, subsidiariesIds: bindSubsidiariesIds });
      }

      if (unbindSubsidiariesIds.length > 0) {
        contractUnbindPointsOfSale({ sector, subsidiariesIds: unbindSubsidiariesIds });
      }
    }
    // eslint-disable-next-line
  }, [isSave]);

  useEffect(() => () => {
    contractBindPointsOfSaleReset();
    contractUnbindPointsOfSaleReset();
    fetchListPointOfSalesReset();
  }, [
    contractBindPointsOfSaleReset,
    contractUnbindPointsOfSaleReset,
    fetchListPointOfSalesReset,
  ]);

  return (
    <Root>
      <Table
        rowKey={({ id }) => id}
        columns={COLUMNS}
        dataSource={bindPointsOfSale}
        scroll={scroll}
        pagination={false}
        locale={locale}
        loading={pointOfSalesIsLoading}
      />
      { unbindPointsOfSale.length > 0
        && (isEdit || isNew) && (
        !isAddPoint ? (
          <AddPointButtonContainer>
            <Button
              type="ghost"
              onClick={() => setIsAddPoint(true)}
              disabled={pointOfSalesIsLoading}
            >
              Добавить точку продаж
            </Button>
          </AddPointButtonContainer>
        ) : (
          <AddPointContainer
            name="contract"
            layout="vertical"
            hideRequiredMark
            form={form}
            onFinish={handleFinish}
            autoComplete="off"
          >
            <Form.Item
              name="salePoint"
              label=""
              rules={[]}
              validateFirst
              validateTrigger="onBlur"
            >
              <Select
                name="salePoint"
                onFocus={(e) => handleFocus(e, 'salePoint')}
                onBlur={(e) => handleBlur(e, 'salePoint')}
                filterOption={false}
                list={unbindPointsOfSale}
                hasDefault={false}
                loading={pointOfSalesIsLoading}
                disable={pointOfSalesIsLoading}
              />
            </Form.Item>
            <Button
              block
              type="primary"
              shape="square"
              htmlType="submit"
            >
              Сохранить
            </Button>
          </AddPointContainer>
        )
      )}

    </Root>
  );
}

export default connect(
  (state) => ({
    item: state.contracts.item.data,
    itemIsLoaded: state.contracts.item.isLoaded,

    pointOfSalesBindList: state.contracts.pointOfSales.bindList,
    pointOfSalesUnbindList: state.contracts.pointOfSales.unbindList,
    pointOfSalesIsLoading: state.contracts.pointOfSales.isLoading,
    pointOfSalesIsLoaded: state.contracts.pointOfSales.isLoaded,
    pointOfSalesError: state.contracts.pointOfSales.error,

  }),
  {
    fetchListPointOfSales: fetchListPointOfSalesAction,
    fetchListPointOfSalesReset: fetchListPointOfSalesResetAction,
    contractBindPointsOfSale: contractBindPointsOfSaleAction,
    contractUnbindPointsOfSale: contractUnbindPointsOfSaleAction,
    contractBindPointsOfSaleReset: contractBindPointsOfSaleResetAction,
    contractUnbindPointsOfSaleReset: contractUnbindPointsOfSaleResetAction,
  },
)(PointOfSalesTable);
