import admin from './admin';
import auth from './auth';
import car from './car';
import codebook from './codebook';
import company from './company';
import countries from './countries';
import credit from './credit';
import crif from './crif';
import creditRequest from './creditRequest';
import dealerCenter from './dealerCenter';
import email from './email';
import password from './password';
import fias from './fias';
import file from './file';
import history from './history';
import otp from './otp';
import payment from './payment';
import ping from './ping';
import product from './product';
import request from './request';
import role from './role';
import table from './table';
import transaction from './transaction';
import userStatus from './userStatus';
import user from './user';
import TCM from './tcm';
import files from './files';
import contracts from './contracts';
import report from './report';

export default {
  admin,
  auth,
  car,
  codebook,
  company,
  countries,
  credit,
  crif,
  creditRequest,
  dealerCenter,
  email,
  password,
  fias,
  file,
  history,
  otp,
  payment,
  ping,
  product,
  request,
  role,
  table,
  transaction,
  userStatus,
  user,
  TCM,
  files,
  contracts,
  report,
};
