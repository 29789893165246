import styled from 'styled-components';
import { GRID_AREAS } from 'settings';
import { Tabs } from 'antd';

const TabsStyled = styled(Tabs)`
  &.ant-tabs {
    display: grid;
    grid-auto-flow: row;

    &:before,
    &:after {
      display: none;
    }
  }

  &.ant-tabs {
      overflow: auto;
  }

  &.ant-tabs .ant-tabs-top-content,
  &.ant-tabs .ant-tabs-bottom-content {
    overflow: auto;
    height: 100%;
  }

  &.ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: transparent;

    &.ant-tabs-tabpane-active {
      height: 100%;
    }
  }

  &.ant-tabs-card > .ant-tabs-bar {
    border-color: #d9d9d9;
    margin: 0;
  }

  &.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }

  &.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #d9d9d9;
    background: transparent;
  }
`;

const Block = styled.div.attrs({ className: 'block' })`
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 1rem;

  &, & > div, & .ant-tabs {
    overflow: unset;
    
    //min-height: 200px;
  }

  .block > &,
  .column > & {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border: none;
    border-radius: 0;
    margin-top: 0;
  }
`;

const Column = styled.div.attrs({ className: 'column' })`
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 1rem;

  .block > &,
  .column > & {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border: none;
    border-radius: 0;
    margin-top: 0;
  }
`;

const Row = styled.div`
 display: grid;
  grid-gap: 0 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: '${GRID_AREAS.LEFT.LABEL} ${GRID_AREAS.RIGHT.LABEL}'
                       '${GRID_AREAS.LEFT.INPUT} ${GRID_AREAS.RIGHT.INPUT}'
                       '${GRID_AREAS.LEFT.DESCRIPTION} ${GRID_AREAS.RIGHT.DESCRIPTION}'
                       '${GRID_AREAS.LEFT.WARNING} ${GRID_AREAS.RIGHT.WARNING}'
                       '${GRID_AREAS.LEFT.ERROR} ${GRID_AREAS.RIGHT.ERROR}';

  @media (min-width: 300px) and (max-width: 1439px) {
    grid-template-columns: 1fr;
    grid-template-areas: '${GRID_AREAS.LEFT.LABEL}'
                         '${GRID_AREAS.LEFT.INPUT}'
                         '${GRID_AREAS.LEFT.DESCRIPTION}'
                         '${GRID_AREAS.LEFT.WARNING}'
                         '${GRID_AREAS.LEFT.ERROR}'
                         '${GRID_AREAS.RIGHT.LABEL}'
                         '${GRID_AREAS.RIGHT.INPUT}'
                         '${GRID_AREAS.RIGHT.DESCRIPTION}'
                         '${GRID_AREAS.RIGHT.WARNING}'
                         '${GRID_AREAS.RIGHT.ERROR}';
    & > input + input {
      margin-top: 1rem;
    }
  }
  @media (min-width: 1440px) {}
`;

const RowPayments = styled.div`
  display: block;
`;

const RowAsymmetric = styled.div`
  display: grid;
  grid-gap: 0 10px;
  grid-template-columns: 100px 1fr;
  grid-template-areas: '${GRID_AREAS.LEFT.LABEL} ${GRID_AREAS.RIGHT.LABEL}'
                       '${GRID_AREAS.LEFT.INPUT} ${GRID_AREAS.RIGHT.INPUT}'
                       '${GRID_AREAS.LEFT.DESCRIPTION} ${GRID_AREAS.RIGHT.DESCRIPTION}'
                       '${GRID_AREAS.LEFT.WARNING} ${GRID_AREAS.RIGHT.WARNING}';
                       '${GRID_AREAS.LEFT.ERROR} ${GRID_AREAS.RIGHT.ERROR}';
`;

const ErrorMessage = styled.div`
  color: red;
`;

const NestedRoot = styled.div`
  margin: 15px 0;
  border-top: 1px solid #cdcdcd;
`;

const NestedContainer = styled.div`
  margin-top: 10px;
`;

const KaskoRoot = styled.div`
  padding: 0 0 0 1rem;
`;

const KaskoContainer = styled.div`
  margin-top: 1rem;
  border: 1px solid #cdcdcd;
  padding: 0 1rem;
  border-radius: 3px;
  position: relative;
`;

const KaskoRemove = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #ececec;
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  color: #f00;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

export {
  TabsStyled,
  Block,
  Column,
  Row,
  RowAsymmetric,
  RowPayments,
  ErrorMessage,
  NestedRoot,
  NestedContainer,
  KaskoRoot,
  KaskoContainer,
  KaskoRemove,
  ButtonContainer,
};
