import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { Rifm } from 'rifm';
import moment from 'moment';
import Loader from 'components/Loader';
import { checkPrivilege } from 'helpers/roles';
import DatePicker from 'components/DatePicker';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME_SECONDS } from 'settings';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { fetchList as fetchDealerCenterListAction } from 'redux/dealerCenter/actions';
import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  Form, Input, InputNumber, Table,
} from 'antd';

import Select from 'components/Select';

import {
  parseDigitsWithSeparator as parseDigits,
  formatPhone,
  parseSumWithSeparator,
  cursorToLastPhoneValuePosition,
  formatCurrency,
} from 'utils';

import {
  fetchItem as fetchItemAction,
  create as createPaymentAction,
  resetCreateForm as resetPaymentCreateFormAction,
  annul as annulPaymentAction,
  fetchSectors as fetchSectorsAction,
} from 'redux/payment/actions';

import {
  ErrorMessage,
  SuccessMessage,
} from 'components/Messages';
import { KEYS as K, CHANNEL, COLUMNS } from './data';

import {
  Root,
  Header,
  HeaderTitle,
  Title,
  Actions,
  ActionGroup,
  Main,
  Rows,
  Row,
  RowFooter,
  FooterReadOnly,
  FooterAction,
  FormRow,
  SmallFormRow,
  FormActionGroup,
  FormGroup,
  FormSubmit,
  Label,
} from './style';

const refsCopy = (reference) => navigator.clipboard.writeText(reference);
const disabledDate = (current) => current && current < moment().startOf('day');

const SEPARATORS_TIME = {
  2: ':',
};

const formatTime = (string) => {
  const digits = parseDigits(string);

  return Array.from(digits)
    .reduce((p, c, i) => `${p}${SEPARATORS_TIME[i] || ''}${c}`, '')
    .substring(0, 5);
};

const replaceTime = (string) => {
  const digits = parseDigits(string);

  const [h, m] = [
    digits.slice(0, 2).padEnd(2, '\u2013'),
    digits.slice(2, 4).padEnd(2, '\u2013'),
  ];

  return `${h}${SEPARATORS_TIME[2]}${m}`;
};

const renderLabel = (field, readOnly) => (
  <Label>
    {field.title}
    {field.isOptional && !readOnly && (
      <>
        {'\u00a0'}
        <small>[опционально]</small>
      </>
    )}
  </Label>
);

const renderTitle = ({
  isRepeated, isRepeat, paymentId, billId,
}) => (
  <Title>
    {isRepeated
      ? `Счет #${billId} [продублировано в #${paymentId}]`
      : !billId
        ? 'Новый счет'
        : isRepeat
          ? `Дублировать счет #${billId}`
          : `Счет #${billId}`}
  </Title>
);

const hasAnnulButton = (role) => checkPrivilege([
  role.PAYMENTS.CREATE_BILLS,
  role.PAYMENTS.DECLINE_MY_BILLS,
  role.PAYMENTS.DECLINE_ORGANISATION_BILLS,
]);

const hasRepeatButton = (role) => checkPrivilege([
  role.PAYMENTS.CREATE_BILLS,
]);

const ANNUL_STATUS = 'void';
const REGISTER_STATUS = 'Зарегистрирован';
const initialValues = {
  [K.USER_POINT.key]: '',
};

function CreatePayment({
  // connect
  history,
  role,
  itemData,
  itemOperations,
  itemIsLoading,
  itemIsLoaded,
  itemError,

  // create
  values,
  paymentId,
  formUrl,
  isLoading,
  isLoaded,
  error,
  errors,

  // annul
  annulIsLoading,
  annulIsLoaded,
  annulError,

  // sectors
  sectors,
  sectorsIsLoading,
  sectorsIsLoaded,
  sectorsError,

  // actions
  fetchItem,
  createPayment,
  resetPaymentCreateForm,
  annulPayment,
  fetchSectors,
  setBreadcrumbs,
}) {
  const [form] = Form.useForm();
  const phoneField = useRef();
  const [isValidated, setIsValidated] = useState(true);
  const [isAnnulEnabled, setIsAnnulEnabled] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const location = useLocation();
  const { billId } = useParams();
  const url = itemIsLoaded ? itemData.url : formUrl;
  const isNew = location.pathname.endsWith('/new');
  const isRepeat = location.pathname.endsWith('/repeat');
  const isRepeated = isLoaded && isRepeat;
  const isReadOnly = !checkPrivilege(role.PAYMENTS.CREATE_BILLS)
    || isLoaded
    || (billId && !isRepeat);
  const [isMounted, setIsMounted] = useState(false);
  const { orderId = '' } = itemData;

  useEffect(() => {
    resetPaymentCreateForm();
    setIsMounted(true);
  }, [resetPaymentCreateForm]);

  useEffect(() => {
    if (isMounted) {
      const mixin = isNew
        ? [{ level: 2, name: 'Новый Cчет', link: '/app/payments/bills/new' }]
        : [
          { level: 2, name: `Счет #${billId}`, link: `/app/payments/bills/${billId}` },
          ...(isRepeat
            ? [{ level: 3, name: 'повтор', link: `/app/payments/bills/${billId}/repeat` }]
            : []),
        ];

      setBreadcrumbs([
        { level: 0, name: 'Эквайринг', link: '/app/payments' },
        { level: 1, name: 'Cчета', link: '/app/payments/bills/list' },
        ...mixin,
      ]);
    }
  }, [isMounted, setBreadcrumbs, isNew, isRepeat, billId]);

  useEffect(() => {
    if (billId) {
      fetchItem({ id: billId, isRepeat });
    }
  }, [fetchItem, billId, isRepeat]);

  useEffect(() => {
    if (itemIsLoaded && itemOperations.length > 0) {
      const lastOperation = itemOperations[itemOperations.length - 1];

      if (
        lastOperation.type === REGISTER_STATUS
        && itemData.entityStatus !== ANNUL_STATUS
      ) {
        setIsAnnulEnabled(true);
      }
    }
  }, [itemIsLoaded, itemOperations, itemData]);

  useEffect(() => {
    if (isLoaded) {
      resetPaymentCreateForm();
      history.push(`/app/payments/bills/${paymentId}`);
    }
  }, [isLoaded, paymentId, history, resetPaymentCreateForm]);

  useEffect(() => {
    fetchSectors();
  }, [fetchSectors]);

  useEffect(() => {
    if (sectorsError) {
      form.setFields([{ name: K.USER_POINT.key, errors: [sectorsError] }]);
    }
  }, [sectorsError, form]);

  useEffect(() => {
    if (!isNew && itemIsLoaded) {
      const {
        expiredAt = '',
        description,
        email,
        name,
        phone,
        sectorName,
        additionalInformation,
        number,
        registerSum,
      } = itemData;

      form.setFields([
        // date
        ...(expiredAt ? [{ name: K.USER_DATE.key, value: moment(expiredAt) }] : []),
        ...(expiredAt
          ? [
            {
              name: K.USER_TIME.key,
              value: moment.utc(expiredAt.split('+')[0]).format('HH:mm'),
            },
          ]
          : []),

        ...(description ? [{ name: K.USER_DESCRIPTION.key, value: description }] : []),
        ...(email ? [{ name: K.USER_EMAIL.key, value: email }] : []),
        ...(additionalInformation
          ? [{ name: K.USER_INFO.key, value: additionalInformation }]
          : []),
        ...(name ? [{ name: K.USER_NAME.key, value: name }] : []),
        ...(registerSum ? [{ name: K.USER_ORDER_SUM.key, value: parseSumWithSeparator(registerSum) }] : []),
        ...(phone ? [{ name: K.USER_PHONE.key, value: phone.replace('+7', '') }] : []),
        ...(sectorName ? [{ name: K.USER_POINT.key, value: sectorName }] : []),

        // we explicitely remove K.USER_ORDER.key value if  we repeat the form
        { name: K.USER_ORDER.key, value: !isRepeat && number ? number : null },
      ]);
    }
  }, [isNew, itemIsLoaded, itemData, form, isRepeat]);

  useEffect(() => {
    if (!isValidated && !isLoading && Array.isArray(errors) && errors.length > 0) {
      const errorsFlat = errors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.USER_DATE.apiKey]: date,
        [K.USER_DESCRIPTION.apiKey]: description,
        [K.USER_EMAIL.apiKey]: email,
        [K.USER_INFO.apiKey]: info,
        [K.USER_NAME.apiKey]: name,
        [K.USER_ORDER.apiKey]: order,
        [K.USER_ORDER_SUM.apiKey]: sum,
        [K.USER_PHONE.apiKey]: phone,
        [K.USER_POINT.apiKey]: point,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(date ? [{ name: K.USER_DATE.key, errors: [date] }] : []),
        ...(description ? [{ name: K.USER_DESCRIPTION.key, errors: [description] }] : []),
        ...(email ? [{ name: K.USER_EMAIL.key, errors: [email] }] : []),
        ...(info ? [{ name: K.USER_INFO.key, errors: [info] }] : []),
        ...(name ? [{ name: K.USER_NAME.key, errors: [name] }] : []),
        ...(order ? [{ name: K.USER_ORDER.key, errors: [order] }] : []),
        ...(sum ? [{ name: K.USER_ORDER_SUM.key, errors: [sum] }] : []),
        ...(phone ? [{ name: K.USER_PHONE.key, errors: [phone] }] : []),
        ...(point ? [{ name: K.USER_POINT.key, errors: [point] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [errors, isLoading, form, isValidated, setIsValidated]);

  const blockDataTimeField = useCallback((accountLifeTime) => {
    setDisableDate(accountLifeTime);

    if (accountLifeTime) {
      const date = moment(accountLifeTime).format('YYYY-MM-DD');
      const time = moment(accountLifeTime).format('HH:mm:ss');

      form.setFields([
        { name: K.USER_DATE.key, value: date ? moment(date) : '' },
        { name: K.USER_TIME.key, value: time || '' },
      ]);
    } else {
      form.setFields([
        { name: K.USER_DATE.key, value: '' },
        { name: K.USER_TIME.key, value: '' },
      ]);
    }
  }, [form]);

  useEffect(() => {
    if (sectorsIsLoaded && sectors.length === 1 && isNew && sectors[0].id) {
      form.setFields([
        ...([{ name: K.USER_POINT.key, value: sectors[0].id }]),
      ]);

      const { accountLifeTime } = sectors[0];
      blockDataTimeField(accountLifeTime);
    }
  }, [
    sectorsIsLoaded,
    sectors,
    form,
    isNew,
    blockDataTimeField,
  ]);

  function handleChange(value) {
    const { USER_POINT } = value;

    if (USER_POINT) {
      const { accountLifeTime } = sectors.find((item) => item.id === USER_POINT) || {};
      blockDataTimeField(accountLifeTime);
    }
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function submit(values) {
    const {
      [K.USER_TIME.key]: time,
      [K.USER_DATE.key]: date,
      [K.USER_DESCRIPTION.key]: description,
      [K.USER_EMAIL.key]: email,
      [K.USER_INFO.key]: info,
      [K.USER_NAME.key]: name,
      [K.USER_ORDER.key]: order,
      [K.USER_ORDER_SUM.key]: sum,
      [K.USER_PHONE.key]: phone,
      [K.USER_POINT.key]: point,
      channel,
    } = values;

    // Поколению, при дублировании счета сервер нам возвращает строку,
    // хотя должен был вернуть объект с параметрами
    // по этому тут костыль с проверкой или на id или на name
    const {
      legalEntityId = null,
      sector = null,
      subsidiaryId = null,
    } = point ? sectors.find((item) =>
      (item.id === point) || (item.name === point)) : {};

    createPayment({
      [K.USER_DATE.apiKey]: date
        ? moment
          .utc(`${moment(date).format().split('T').shift()} ${time}`)
          .format(DATE_FORMAT_WITH_TIME_SECONDS)
        : '',
      [K.USER_DESCRIPTION.apiKey]: description || '',
      [K.USER_INFO.apiKey]: info || '',
      [K.USER_NAME.apiKey]: name,
      [K.USER_ORDER.apiKey]: order && Number(parseDigits(order)),
      [K.USER_ORDER_SUM.apiKey]: sum && Number(parseDigits(sum)),
      [K.USER_POINT.apiKey]: subsidiaryId && Number(subsidiaryId),
      [K.USER_EMAIL.apiKey]: email,
      legalEntityId: legalEntityId && Number(legalEntityId),
      sector: sector && Number(sector),

      [K.USER_PHONE.apiKey]:
        channel !== CHANNEL.PHONE
          ? phone && parseDigits(phone).length === 10
            ? phone
            : ''
          : phone,

      channel,
    });
    setIsValidated(false);
  }

  function submitWithEmail() {
    form.setFieldsValue({
      channel: CHANNEL.EMAIL,
    });
    form
      .validateFields()
      .then((values) => {
        const { [K.USER_EMAIL.key]: email } = values;
        if (email) {
          submit({
            ...values,
            channel: CHANNEL.EMAIL,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function submitWithPhone() {
    form.setFieldsValue({
      channel: CHANNEL.PHONE,
    });

    form
      .validateFields()
      .then((values) => {
        const { [K.USER_PHONE.key]: phone } = values;

        if (phone) {
          submit({
            ...values,
            channel: CHANNEL.PHONE,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function submitWithLink() {
    form.setFieldsValue({
      channel: CHANNEL.LINK,
    });

    form
      .validateFields()
      .then((values) => {
        submit({
          ...values,
          channel: CHANNEL.LINK,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleFinish = (values) => {
    const { email, phone } = values;

    if (email) {
      submitWithEmail();
    } else if (phone) {
      submitWithPhone();
    }
  };

  return (
    <Root>
      <Header>
        {renderTitle({
          isRepeated,
          isRepeat,
          paymentId,
          billId,
        })}
        {billId && !isRepeat && itemIsLoaded && (
          <Actions>
            {hasAnnulButton(role) && isAnnulEnabled && (
              <ActionGroup>
                <Button
                  size="small"
                  type="ghost"
                  shape="square"
                  onClick={() => annulPayment({ id: billId })}
                  loading={annulIsLoading}
                  disabled={annulIsLoaded}
                >
                  Аннулировать
                </Button>
                {annulError && <ErrorMessage>{annulError}</ErrorMessage>}
                {annulIsLoaded && (
                  <SuccessMessage>Счет аннулирован успешно</SuccessMessage>
                )}
              </ActionGroup>
            )}
            {hasRepeatButton(role) && (
              <ActionGroup>
                <Button
                  size="small"
                  type="link"
                  href={`/app/payments/bills/${billId}/repeat`}
                  shape="square"
                >
                  Повторить
                </Button>
              </ActionGroup>
            )}
          </Actions>
        )}
      </Header>

      {billId && itemError && <ErrorMessage>{itemError}</ErrorMessage>}

      {billId && itemIsLoading && (
        <Loader text="Загружаются данные платежа..." height={400} />
      )}

      {((billId && itemIsLoaded) || !billId) && (
        <Main
          name="payment"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleFinish}
          onValuesChange={handleChange}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Rows>
            <Row>
              <FormRow>
                <Form.Item
                  name={K.USER_ORDER.key}
                  label={renderLabel(K.USER_ORDER, isReadOnly)}
                  rules={!isReadOnly && K.USER_ORDER.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Input
                    name={K.USER_ORDER.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>
                <Form.Item
                  name={K.USER_ORDER_SUM.key}
                  label={renderLabel(K.USER_ORDER_SUM, isReadOnly)}
                  rules={!isReadOnly && K.USER_ORDER_SUM.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <InputNumber
                    name={K.USER_ORDER_SUM.key}
                    precision={2}
                    decimalSeparator=","
                    formatter={formatCurrency}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={isReadOnly}
                  />
                </Form.Item>
              </FormRow>

              <Form.Item
                name={K.USER_NAME.key}
                label={renderLabel(K.USER_NAME, isReadOnly)}
                rules={!isReadOnly && K.USER_NAME.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input
                  name={K.USER_NAME.key}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={isReadOnly}
                />
              </Form.Item>

              <Form.Item
                name={K.USER_POINT.key}
                label={renderLabel(K.USER_POINT, isReadOnly)}
                rules={!isReadOnly && K.USER_POINT.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Select
                  name={K.USER_POINT.key}
                  onChange={handleChange}
                  onFocus={() => handleFocus({ target: { name: K.USER_POINT.key } })}
                  onBlur={() => handleBlur({ target: { name: K.USER_POINT.key } })}
                  loading={sectorsIsLoading}
                  disabled={isReadOnly || sectors.length === 1}
                  list={sectors}
                  hasDefault={false}
                />
              </Form.Item>

              <Form.Item
                name={K.USER_DESCRIPTION.key}
                label={renderLabel(K.USER_DESCRIPTION, isReadOnly)}
                rules={!isReadOnly && K.USER_DESCRIPTION.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input.TextArea
                  name={K.USER_DESCRIPTION.key}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  rows={5}
                  disabled={isReadOnly}
                />
              </Form.Item>
            </Row>

            <Row>
              <SmallFormRow>
                <Form.Item
                  name={K.USER_DATE.key}
                  label={renderLabel(K.USER_DATE, isReadOnly)}
                  rules={!isReadOnly && K.USER_DATE.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <DatePicker
                    name={K.USER_DATE.key}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabledDate={disabledDate}
                    disabled={disableDate || isReadOnly}
                  />
                </Form.Item>
                <Form.Item
                  name={K.USER_TIME.key}
                  label={renderLabel(K.USER_TIME, isReadOnly)}
                  rules={!isReadOnly && K.USER_TIME.rules}
                  validateFirst
                  validateTrigger="onBlur"
                >
                  <Rifm accept={/[\d]/g} mask replace={replaceTime} format={formatTime}>
                    {({ value, onChange }) => (
                      <Input
                        name={K.USER_TIME.key}
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={disableDate || isReadOnly}
                      />
                    )}
                  </Rifm>
                </Form.Item>
              </SmallFormRow>

              <Form.Item
                name={K.USER_INFO.key}
                label={renderLabel(K.USER_INFO, isReadOnly)}
                rules={!isReadOnly && K.USER_INFO.rules}
                validateFirst
                validateTrigger="onBlur"
              >
                <Input.TextArea
                  name={K.USER_DESCRIPTION.key}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  rows={5}
                  disabled={isReadOnly}
                />
              </Form.Item>

              <RowFooter>
                {((isReadOnly && itemData[K.USER_EMAIL.apiKey]) || !isReadOnly) && (
                  <FormActionGroup>
                    <FormGroup>
                      <Form.Item
                        name={K.USER_EMAIL.key}
                        label={renderLabel(K.USER_EMAIL, isReadOnly)}
                        rules={!isReadOnly && K.USER_EMAIL.rules}
                        validateFirst
                        validateTrigger="onBlur"
                      >
                        <Input
                          name={K.USER_EMAIL.key}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          disabled={isReadOnly}
                        />
                      </Form.Item>
                      {!isReadOnly && (
                        <FormSubmit>
                          <Button
                            id="email"
                            size="mini"
                            type="ghost"
                            shape="square"
                            onClick={submitWithEmail}
                            loading={values.channel === CHANNEL.EMAIL && isLoading}
                            disabled={isLoading}
                          >
                            Отправить на почту
                          </Button>
                        </FormSubmit>
                      )}
                    </FormGroup>
                    {values.channel === CHANNEL.EMAIL && error && (
                      <ErrorMessage>{error}</ErrorMessage>
                    )}
                  </FormActionGroup>
                )}
                {((isReadOnly && itemData[K.USER_PHONE.apiKey]) || !isReadOnly) && (
                  <FormActionGroup>
                    <FormGroup>
                      <Form.Item
                        name={K.USER_PHONE.key}
                        label={renderLabel(K.USER_PHONE, isReadOnly)}
                        rules={!isReadOnly && K.USER_PHONE.rules}
                        validateFirst
                        validateTrigger="onBlur"
                      >
                        <Rifm
                          accept={/[\d]/g}
                          mask
                          replace={formatPhone.replace}
                          format={formatPhone.format}
                          onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                        >
                          {({ value, onChange }) => (
                            <Input
                              name={K.USER_PHONE.key}
                              value={value}
                              onChange={onChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              addonBefore={formatPhone.prefix}
                              disabled={isReadOnly}
                              onClick={() => cursorToLastPhoneValuePosition(phoneField)}
                              ref={phoneField}
                            />
                          )}
                        </Rifm>
                      </Form.Item>
                      {!isReadOnly && (
                        <FormSubmit>
                          <Button
                            id="sms"
                            size="mini"
                            type="ghost"
                            shape="square"
                            onClick={submitWithPhone}
                            loading={values.channel === CHANNEL.PHONE && isLoading}
                            disabled={isLoading}
                          >
                            Отправить по SMS
                          </Button>
                        </FormSubmit>
                      )}
                    </FormGroup>
                    {values.channel === CHANNEL.PHONE && error && (
                      <ErrorMessage>{error}</ErrorMessage>
                    )}
                  </FormActionGroup>
                )}
              </RowFooter>
            </Row>
          </Rows>

          {(!billId || isRepeat) && !formUrl ? (
            <FooterAction>
              {!isReadOnly && (
                <Button
                  size="mini"
                  type="ghost"
                  shape="square"
                  onClick={submitWithLink}
                  loading={values.channel === CHANNEL.LINK && isLoading}
                  disabled={isLoading}
                >
                  Получить ссылку
                </Button>
              )}
              {values.channel === CHANNEL.LINK && error && (
                <ErrorMessage>{error}</ErrorMessage>
              )}
            </FooterAction>
          ) : (
            <FooterReadOnly>
              <Input name={K.USER_REF.key} value={url} readOnly />
              <Button
                size="mini"
                type="primary"
                shape="square"
                htmlType="button"
                onClick={() => refsCopy(url)}
              >
                Скопировать ссылку
              </Button>
              {itemData.entityStatus === 'expired' && (
                <ErrorMessage>
                  Срок действия истек
                  {' '}
                  {moment(itemData.expiredAt).format(DATE_FORMAT)}
                </ErrorMessage>
              )}
            </FooterReadOnly>
          )}
        </Main>
      )}

      {itemIsLoaded && !isRepeat && !isNew && (
        <>
          <Header>
            <HeaderTitle>
              {' '}
              Операции для id заказа
              {' '}
              {orderId}
            </HeaderTitle>
          </Header>
          <Table columns={COLUMNS} dataSource={itemOperations} bordered />
        </>
      )}

    </Root>
  );
}

export default withRouter(
  connect(
    (state) => ({
      role: state.auth.role,
      itemData: state.payment.item.data,
      itemOperations: state.payment.item.operations,
      itemIsLoading: state.payment.item.isLoading,
      itemIsLoaded: state.payment.item.isLoaded,
      itemError: state.payment.item.error,

      values: state.payment.create.form,
      paymentId: state.payment.create.paymentId,
      formUrl: state.payment.create.formUrl,
      isLoading: state.payment.create.isLoading,
      isLoaded: state.payment.create.isLoaded,
      error: state.payment.create.error,
      errors: state.payment.create.errors,

      annulIsLoading: state.payment.annul.isLoading,
      annulIsLoaded: state.payment.annul.isLoaded,
      annulError: state.payment.annul.error,

      sectors: state.payment.sectors.list,
      sectorsIsLoading: state.payment.sectors.isLoading,
      sectorsIsLoaded: state.payment.sectors.isLoaded,
      sectorsError: state.payment.sectors.error,

      dealerCenterList: state.dealerCenter.collection.list,
      dealerCenterIsLoading: state.dealerCenter.collection.isLoading,
      dealerCenterIsLoaded: state.dealerCenter.collection.isLoaded,
      dealerCenterError: state.dealerCenter.collection.error,
    }),
    {
      fetchItem: fetchItemAction,
      createPayment: createPaymentAction,
      resetPaymentCreateForm: resetPaymentCreateFormAction,
      annulPayment: annulPaymentAction,
      fetchSectors: fetchSectorsAction,

      // dealerCenters
      fetchDealerCenterList: fetchDealerCenterListAction,
      // breadcrumb
      setBreadcrumbs: setBreadcrumbsAction,
    },
  )(CreatePayment),
);
