import React, { useCallback, useEffect, useRef } from 'react';
import Select from 'components/Select';
import Input from 'components/Input';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { Rifm } from 'rifm';
import { SCROLL_DURATION } from 'settings';
import { parseDigits } from 'utils';
import { mergeArrays } from 'hacks';

import { CheckOutlined } from '@ant-design/icons';

const style = { width: 40 };

const prefixSelector = (
  <Select defaultValue="+7" style={style} showArrow={false} disabled list={[{ value: 7, label: '+7' }]} />
);

const SEPARATORS = {
  0: '(',
  3: ') ',
  6: '-',
  8: '-',
};

const numberFormat = (string) => {
  const digits = parseDigits(string);

  return Array.from(digits)
    .reduce((p, c, i) => `${p}${SEPARATORS[i] || ''}${c}`, '')
    .substring(0, 15);
};

const addMask = (string) => {
  const digits = parseDigits(string);

  const [area, prefix, linear1, linear2] = [
    digits.slice(0, 3).padEnd(3, '_'),
    digits.slice(3, 6).padEnd(3, '_'),
    digits.slice(6, 8).padEnd(2, '_'),
    digits.slice(8, 10).padEnd(2, '_'),
  ];

  return `${SEPARATORS[0]}${area}${SEPARATORS[3]}${prefix}${SEPARATORS[6]}${linear1}${SEPARATORS[8]}${linear2}`;
};

function PhoneField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  phoneIsVerified = false,
  postfix,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const AddonAfter = phoneIsVerified ? (
    <span>
      <CheckOutlined />
      {postfix && <div>{postfix}</div>}
    </span>
  ) : null;

  function handleOnBlur() {
    const value = getFieldValue(name);
    if ((initialValue || '') !== value) {
      const onlyDigits = parseDigits(value);
      if (onlyDigits.length === 0) {
        onChangeField({}, [...mergeArrays(dependents, ifChangeClearFields), name]);
      }
      if (onlyDigits.length > 0) {
        onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
      }
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: initialValue || '',
      })(
        <Rifm accept={/[\d]/g} mask replace={addMask} format={numberFormat}>
          {({ value, onChange }) => (
            <Input
              name={name}
              value={value}
              onBlur={handleOnBlur}
              onChange={onChange}
              placeholder={placeholder}
              addonBefore={prefixSelector}
              addonAfter={AddonAfter}
              disabled={disabled}
              ref={focusableNode}
              area={area}
              size={size}
            />
          )}
        </Rifm>,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default PhoneField;
