import React, { useEffect, useState } from 'react';
import { Rifm } from 'rifm';
import { parseDigits } from 'utils';

import { Root, InputStyled } from './style';

const SEPARATORS = {
  2: '.',
  4: '.',
};

const format = (string) => {
  const digits = parseDigits(string);

  return Array.from(digits)
    .reduce((p, c, i) => `${p}${SEPARATORS[i] || ''}${c}`, '')
    .substring(0, 11);
};

const replace = (string) => {
  const digits = parseDigits(string);

  const [d, m, y] = [
    digits.slice(0, 2).padEnd(2, '_'),
    digits.slice(2, 4).padEnd(2, '_'),
    digits.slice(4, 8).padEnd(4, '_'),
  ];

  return `${d}${SEPARATORS[2]}${m}${SEPARATORS[4]}${y}`;
};

function DateFilter({
  name: [startName, endName],
  onChange: onChangeField,
  value: [startValue, endValue],
}) {
  const [start, setStart] = useState(startValue || '');
  const [end, setEnd] = useState(endValue || '');

  useEffect(() => {
    setStart(startValue);
  }, [startValue]);

  useEffect(() => {
    setEnd(endValue);
  }, [endValue]);

  function handleOnBlur() {
    onChangeField({
      [startName]: parseDigits(start).length === 8 ? start : '',
      [endName]: parseDigits(end).length === 8 ? end : '',
    });
  }

  return (
    <Root>
      <Rifm
        accept={/[\d]/g}
        mask
        replace={replace}
        format={format}
        value={start}
        onChange={setStart}
      >
        {({ value, onChange }) => (
          <InputStyled
            name={startName}
            value={value}
            onChange={onChange}
            onBlur={handleOnBlur}
          />
        )}
      </Rifm>
      <Rifm
        accept={/[\d]/g}
        mask
        replace={replace}
        format={format}
        value={end}
        onChange={setEnd}
      >
        {({ value, onChange }) => (
          <InputStyled
            name={endName}
            value={value}
            onChange={onChange}
            onBlur={handleOnBlur}
          />
        )}
      </Rifm>
    </Root>
  );
}

export default DateFilter;
