import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const Root = styled.div`
  & {
    .ant-table-thead > tr > th {
      padding: 8px !important;
    }
    .ant-table-tbody > tr > td {
      padding: 0px !important;
    }
    .ant-table-thead > tr > th .ant-table-header-column {
      width: 100%;
    }
    .ant-table-thead > tr > th {
      vertical-align: bottom;
    }
  }
`;

const LoadingMessage = styled.div``;

const ErrorMessage = styled.div`
  color: red;
`;

const ErrorMessageStatus = styled.span`
  color: red;
`;

const EmptyMessage = styled.div``;

const LinkStyled = styled(Link)`
  color: inherit;
  padding: 8px;
  display: block;
`;

const ButtonStyled = styled(Button)`
  margin: 0 8px;
`;

const CommentsContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;

const CommentsBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommentsBtn = styled.button`
  outline: none;
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  width: 166px;
  height: 100%;
`;

const CommentContainer = styled.div`
  padding: .4rem;
`;
const CommentNameDate = styled.span`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 15px 20px;
`;

const CommentText = styled.p`
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 350px;
`;

export {
  Root, LoadingMessage, ErrorMessage, EmptyMessage, LinkStyled, ButtonStyled, CommentsContainer,
  CommentContainer, CommentNameDate, CommentText, ErrorMessageStatus, CommentsBtnContainer, CommentsBtn,
};
