import styled from 'styled-components';

export const Root = styled.div`
  background: #fff;
  padding: 0;
`;

export const ErrorMessage = styled.div`
  color: red;
`;
