import styled from 'styled-components';
import { key, palette } from 'styled-theme';
import { Checkbox } from 'antd';

const sizes = {
  small: {
    height: key('checkbox.height'),
    fontSize: '0.8rem',
  },
  default: {
    height: key('checkbox.height'),
    fontSize: key('checkbox.fontSize'),
  },
  large: {
    height: key('checkbox.height'),
    fontSize: key('checkbox.fontSize'),
  },
};

const CheckboxStyled = styled(Checkbox)`
  && {
    &.ant-checkbox-wrapper {
      display: block;
      margin-top: 8px;
    }
    &.ant-checkbox-wrapper + &.ant-checkbox-wrapper {
      margin-left: 0;
    }

    & .ant-checkbox-inner {
      display:  inline-block;
      height: ${({ size }) => sizes[size || 'default'].height};
      width: ${({ size }) => sizes[size || 'default'].height};
      border-color: ${palette('form', 2)};
      background: ${palette('form', 0)};
    }
    & .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: ${palette('form', 3)};
      // left: 5px;
    }

    &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
    & .ant-checkbox:hover .ant-checkbox-inner,
    & .ant-checkbox-checked::after,
    & .ant-checkbox-inner:hover {
      border-color: ${palette('form', 3)};
    }

    & .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${palette('form', 3)};
      box-shadow: 0 0 0 2px ${palette('form', 4)};
    }

    & .ant-checkbox + span {
      height: ${({ size }) => sizes[size || 'default'].height};
      font-size: ${({ size }) => sizes[size || 'default'].fontSize};
    }
  }
`;

export { CheckboxStyled };
