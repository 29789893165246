import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import Input, { Password } from 'components/Input';
import Button from 'components/Button';

import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import { signInWithEmail as signInWithEmailAction } from 'redux/auth/actions';

import {
  Root, Title, FormBlock, FormError, Footer, LinkButton,
} from './style';

const emailValidators = [
  {
    required: true,
    message: 'Логин не может быть пустым',
  },
];

const passwordValidators = [
  {
    required: true,
    message: 'Пароль не может быть пустым',
  },
];

const iconStyle = {
  color: 'rgba(0,0,0,.25)',
};

function SignIn({
  // passed
  location,
  history,

  // connect
  idToken,
  error,
  isLoading,

  // actions
  signInWithEmail,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (idToken) {
      const path = (
        (
          location.state
          && location.state.from
          && location.state.from.pathname
        ) || '/'
      );

      history.push(path);
    }
  }, [history, idToken, location]);

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleFinish(values) {
    const { email, password } = values;
    signInWithEmail({ username: email, password, rememberMe: true });
  }

  const iconRender = (visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />);

  return (
    <Root>
      <Title>Войти в личный кабинет</Title>
      <Form
        form={form}
        layout="vertical"
        name="SignIn"
        size="large"
        hideRequiredMark
        onFinish={handleFinish}
      >
        <FormBlock>
          <Form.Item
            name="email"
            label=""
            rules={emailValidators}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name="email"
              onFocus={handleFocus}
              onBlur={handleBlur}
              prefix={<UserOutlined style={iconStyle} />}
              placeholder="Логин"
            />
          </Form.Item>
        </FormBlock>
        <FormBlock>
          <Form.Item
            name="password"
            label=""
            rules={passwordValidators}
            validateFirst
            validateTrigger="onBlur"
          >
            <Password
              name="password"
              onFocus={handleFocus}
              onBlur={handleBlur}
              prefix={<LockOutlined style={iconStyle} />}
              type="password"
              iconRender={iconRender}
              placeholder="Пароль"
            />
          </Form.Item>
        </FormBlock>
        {error && <FormError>{error}</FormError>}
        <Footer>
          <Button type="primary" block htmlType="submit" loading={isLoading}>
            Вход
          </Button>
          <LinkButton to="/auth/restore">Восстановить пароль</LinkButton>
        </Footer>
      </Form>
    </Root>
  );
}

export default connect(
  (state) => ({
    idToken: state.auth.idToken,
    error: state.auth.signInWithEmail.error,
    isLoading: state.auth.signInWithEmail.isLoading,
  }),
  {
    signInWithEmail: signInWithEmailAction,
  },
)(SignIn);
