import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { checkPrivilege } from 'helpers/roles';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import Offers from 'components/tcm/Sidebars/Offers';
import EmployeeClients from 'components/tcm/Sidebars/EmployeeClients';
import Users from 'components/tcm/Sidebars/Users';
import { TCM_REPORTS_EMPLOYEES, TCM_REPORTS_PERIOD } from 'settings';

import {
  fetchCampaignStatusList as fetchCampaignStatusListAction,
} from 'redux/tcmCampaign/actions';

import {
  Title,
  Count,
  Item,
  List,
  Wrapper,
} from 'components/tcm/Sidebars/style';

const statusList = [
  {
    label: 'Все',
    count: '',
    link: '/tcm',
    status: 'ALL',
  },
  {
    label: 'Черновик',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.DRAFT}`,
    status: CAMPAIGN_STATUSES.DRAFT,
  },
  {
    label: 'Подбор VIN',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.VIN_REQUEST}`,
    status: CAMPAIGN_STATUSES.VIN_REQUEST,
  },
  {
    label: 'Доработка',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.REVISION}`,
    status: CAMPAIGN_STATUSES.REVISION,
  },
  {
    label: 'Оценка',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.EVALUATION}`,
    status: CAMPAIGN_STATUSES.EVALUATION,
  },
  {
    label: 'Расчет КП',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.KP_CALCULATION}`,
    status: CAMPAIGN_STATUSES.KP_CALCULATION,
  },
  {
    label: 'Распределение клиентов',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.DISTRIBUTION}`,
    status: CAMPAIGN_STATUSES.DISTRIBUTION,
  },
  {
    label: 'Начало обзвона',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.CALLING}`,
    status: CAMPAIGN_STATUSES.CALLING,
  },
  {
    label: 'Обзвон завершен',
    count: '',
    link: `/tcm/campaign/list?filter=${CAMPAIGN_STATUSES.COMPLETE}`,
    status: CAMPAIGN_STATUSES.COMPLETE,
  },
];

// const users = [
//   { label: 'Все', count: 5, link: '/tcm/users/list' },
//   { label: 'Без клиентов', count: 5, link: '/tcm/users/noClients' },
// ];

const reports = [
  { label: 'По кампаниям', link: '/tcm/reports/campaigns' },
  ...(TCM_REPORTS_EMPLOYEES ? [{ label: 'По сотрудникам', link: '/tcm/reports/employees' }] : []),
  ...(TCM_REPORTS_PERIOD ? [{ label: 'По периоду', link: '/tcm/reports/period' }] : []),
];

const NavItem = ({ title, itemList, defaultLocation }) => {
  const location = useLocation();
  const { filter = '' } = queryString.parse(location.search);
  const activeFilter = statusList.findIndex((e) => filter === e.status);
  const { pathname } = location;

  const isDefault = location.pathname === defaultLocation;

  const activeFilterId = activeFilter > -1 ? activeFilter : isDefault ? 0 : null;

  return (
    <List>
      <Title>
        {title}
      </Title>
      {itemList.map((item, i) => (
        <dd key={item.label}>
          {/* Поменять вычисление active */}
          <Item to={item.link} active={activeFilterId ? i === activeFilterId : pathname === item.link}>
            {item.label}
            {' '}
            <Count>{item.count}</Count>
          </Item>
        </dd>
      ))}
    </List>
  );
};

const Campaigns = ({
    // store
  role,
  statuses,
  isLoaded,

  setStatusIsLoaded,
  createIsLoaded,
  campaignRejectIsLoaded,

    // actions
  fetchCampaignStatusList,
}) => {
  const [companyStatuses, setCompanyStatuses] = useState(statusList);
  const canShowOffers = checkPrivilege(role.TCM.VIEW_OFFERS_COUNT);
  const canShowManagerOffers = checkPrivilege(role.TCM.VIEW_MANAGER_OFFERS_COUNT);
  const canShowCampaigns = checkPrivilege(role.TCM.VIEW_CAMPAIGNS_COUNT);
  const canShowUsers = checkPrivilege(role.TCM.VIEW_USERS);

  useEffect(() => {
    if (setStatusIsLoaded) {
      fetchCampaignStatusList();
    }
  }, [
    fetchCampaignStatusList,
    setStatusIsLoaded,
  ]);

  useEffect(() => {
    if (createIsLoaded) {
      fetchCampaignStatusList();
    }
  }, [
    fetchCampaignStatusList,
    createIsLoaded,
  ]);

  useEffect(() => {
    if (campaignRejectIsLoaded) {
      fetchCampaignStatusList();
    }
  }, [
    fetchCampaignStatusList,
    campaignRejectIsLoaded,
  ]);

  useEffect(() => {
    fetchCampaignStatusList();
  }, [fetchCampaignStatusList]);

  useEffect(() => {
    if (isLoaded) {
      const statusCount = statuses.reduce((p, curr) => ({ ...p, [curr.name]: curr.count }), {});
      setCompanyStatuses(statusList.map((item) => ({ ...item, count: statusCount[item.status] ?? '' })));
    }
  }, [isLoaded, statuses]);

  return (
    <Wrapper>

      {canShowCampaigns && (
        <NavItem
          title="Кампании"
          itemList={companyStatuses}
          defaultLocation="/tcm/campaign/list"
        />
      )}

      {canShowManagerOffers && (
        <Offers subTitle={canShowOffers ? '(Менеджер TCM)' : ''} />
      )}

      {canShowOffers && (
        <EmployeeClients subTitle={canShowManagerOffers ? '(Сотрудник)' : ''} />
      )}

      {canShowUsers && <Users />}

      {/* <NavItem */}
      {/*  title="Пользователи" */}
      {/*  itemList={users} */}
      {/*  defaultLocation="/tcm/campaign/users/list" */}
      {/* /> */}
      <NavItem
        title="Отчеты"
        itemList={reports}
        defaultLocation="/tcm/campaign/reports/list"
      />
    </Wrapper>
  );
};

export default connect(
  (state) => ({
    role: state.auth.role,

    // campaign
    statuses: state.tcmCampaign.statuses.list,
    isLoading: state.tcmCampaign.statuses.isLoading,
    isLoaded: state.tcmCampaign.statuses.isLoaded,
    error: state.tcmCampaign.statuses.error,

    collectionIsLoaded: state.tcmCampaign.collection.isLoaded,

    setStatusIsLoaded: state.tcmCampaign.campaignsSet.isLoaded,

    createIsLoaded: state.tcmCampaign.create.isLoaded,

    campaignRejectIsLoaded: state.tcmCampaign.campaignReject.isLoaded,
  }),
  {
    fetchCampaignStatusList: fetchCampaignStatusListAction,
  },
)(Campaigns);
