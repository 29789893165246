import styled from 'styled-components';

export const Root = styled.div`
  .ant-table-thead > tr > th {
    background: transparent;
    padding-bottom: 35px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #000;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 60px 0 30px;
`;

export const Title = styled.h3`
  font-size: 2rem;
  margin: 0;
`;

export const Action = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`;

export const ButtonDelete = styled.button`
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #e50000;
  background: transparent;
  color: #e50000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid #e50000;
    box-shadow: 0 0 0px 1px #e50000;
  }

  & .anticon {
    padding-right: 4px;
  }
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 0.4rem;
`;

export const EmptyMessage = styled.div``;
