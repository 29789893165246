import React, {
  useEffect,
  useState,
} from 'react';
import {
  Select as SelectAntD,
  Tooltip as ToolTipAntD,
  Tag,
} from 'antd';

import { WithStyle } from './style';

const defaultProps = {
  allowClear: true,
  hasDefault: true,
};

const tagRender = (value, label, onClose, withTooltip) =>
  (
    withTooltip ? (
      <ToolTipAntD title={value}>
        {}
        <Tag closable onClose={onClose}>
          {label}
        </Tag>
      </ToolTipAntD>
    ) : (
      <Tag closable onClose={onClose}>
        {label}
      </Tag>
    )
  );

const Select = WithStyle((props) => <SelectAntD {...defaultProps} {...props} />);

function SelectMultiple({
  name,
  value: valueFromProps,
  onChange,
  options = [],
  isLoading,
  hasDefault,
  disabled,
  withTooltip,
  placeholder,
}) {
  const [value, setValue] = useState(valueFromProps || []);
  const [list, setList] = useState(options);

  useEffect(() => {
    if (value === [] && list.length !== options.length) {
      setList(options);
    }
  }, [options, list, value]);

  useEffect(() => {
    if (valueFromProps) {
      setValue(valueFromProps);
    }
  }, [valueFromProps]);

  function handleOnChange(v) {
    setValue(v);
    if (onChange) {
      onChange(v || []);
    }
  }
  return (
    <Select
      allowClear
      name={name}
      value={value}
      onChange={handleOnChange}
      loading={isLoading}
      getPopupContainer={(trigger) => trigger.parentNode}
      mode="multiple"
      maxTagTextLength={10}
      placeholder={placeholder}
      disabled={disabled}
      tagRender={({ value, label, onClose }) =>
        tagRender(value, label, onClose, withTooltip)}
    >
      { hasDefault && (
        <SelectAntD.Option key="" value="">
          Не выбрано
        </SelectAntD.Option>
      )}
      {options.map((option) => (
        <SelectAntD.Option key={option.value} value={option.value}>
          {option.label}
        </SelectAntD.Option>
      ))}
    </Select>
  );
}

export default SelectMultiple;
