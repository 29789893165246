import styled from 'styled-components';
import { Form, Modal } from 'antd';

export const Root = styled(Modal)`
  & .ant-modal-content {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  & .ant-modal-header {
    padding: 2rem;
  }

  & .ant-modal-body {
    padding: 1rem 2rem 2rem;
  }

  & .ant-form-item {
    margin-bottom: 14px;
  }
`;

export const FormStyled = styled(Form)``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: "Open Sans";
  font-size: 24px;
  line-height: 30px;
  color: #000;
  margin: 0;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ErrorMessage = styled.p`
  color: red;
`;
