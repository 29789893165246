import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import RequestPage from './RequestPage';

function Index() {
  return (
    <Switch>
      <Route exact path="/app/leads/credit-request/:id" component={RequestPage} />
    </Switch>
  );
}

export default connect(null, null)(Index);
