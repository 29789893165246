// https://ant.design/components/select/#Option-props
// Select.option has type - number/string, so we explicitly convert boolean values to string
const BOOLEANS = ['true', 'false'];

const formatString = (string) => {
  if (string.includes('₽')) {
    return Number.parseFloat(String(string).replace(/\W/g, ''));
  }
  if (BOOLEANS.includes(string)) {
    return string === BOOLEANS[0];
  }
  return string;
};

const formatValue = (value) => (typeof value === 'string' ? formatString(value) : value);

export const formatForm = (object) =>
  Object.keys(object).reduce(
    (p, c) => ({
      ...p,
      [c]: formatValue(object[c]),
    }),
    {},
  );
