import admin from './admin/reducer';
import auth from './auth/reducer';
import breadcrumbs from './breadcrumbs/reducer';
import company from './company/reducer';
import countries from './countries/reducer';
import credit from './credit/reducer';
import crif from './crif/reducer';
import dealerCenter from './dealerCenter/reducer';
import creditRequest from './creditRequest/reducer';
import history from './history/reducer';
import invite from './invite/reducer';
import restore from './restore/reducer';
import modals from './modals/reducer';
import payment from './payment/reducer';
import product from './product/reducer';
import request from './request/reducer';
import role from './role/reducer';
import salesPoint from './salesPoint/reducer';
import table from './table/reducer';
import tcmCampaign from './tcmCampaign/reducer';
import tcmCampaignOffer from './tcmCampaignOffer/reducer';
import tcmOffer from './tcmOffer/reducer';
import tcmPeriod from './tcmPeriod/reducer';
import transaction from './transaction/reducer';
import userStatus from './userStatus/reducer';
import user from './user/reducer';
import files from './files/reducer';
import tcmEmployee from './tcmEmployee/reducer';
import tcmTopics from './tcmTopics/reducer';
import tcmCallResults from './tcmCallResult/reducer';
import common from './common/reducer';
import contracts from './contracts/reducer';
import tcmCreditCalculator from './tcmCreditCalculator/reducer';
import report from './report/reducer';
import tcmReport from './tcmReport/reducer';

export default {
  admin,
  auth,
  breadcrumbs,
  company,
  countries,
  credit,
  crif,
  creditRequest,
  dealerCenter,
  history,
  invite,
  restore,
  modals,
  payment,
  product,
  request,
  role,
  salesPoint,
  table,
  tcmCampaign,
  tcmCampaignOffer,
  tcmOffer,
  tcmPeriod,
  transaction,
  userStatus,
  user,
  files,
  tcmEmployee,
  tcmTopics,
  tcmCallResults,
  common,
  contracts,
  tcmCreditCalculator,
  report,
  tcmReport,
};
