import {
  USER_STATUS__RESET,
  USER_STATUS__PATCH,
  USER_STATUS__PATCH_SUCCESS,
  USER_STATUS__PATCH_FAILURE,
} from './actions';

const initState = {
  id: null,
  status: 0,
  isLoading: false,
  isLoaded: false,
  error: null,
  successMessage: null,
};

export default function userStatusReducer(state = initState, action) {
  switch (action.type) {
    case USER_STATUS__RESET: {
      return initState;
    }

    case USER_STATUS__PATCH: {
      return {
        id: action.accessToken,
        status: action.status,
        isLoading: true,
        isLoaded: false,
        error: null,
        successMessage: null,
      };
    }
    case USER_STATUS__PATCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        successMessage: action.successMessage,
      };
    }
    case USER_STATUS__PATCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
        successMessage: null,
      };
    }

    default: {
      return state;
    }
  }
}
