import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import { fetchItem as fetchItemAction } from 'redux/transaction/actions';
import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import Operations from './Operations';
import TransactionForm from './TransactionForm';

import { Root, Header, ErrorMessage } from './style';

function Transaction({
  // connect
  data,
  isLoading,
  isLoaded,
  error,

  confirmIsLoaded,
  cancelIsLoaded,
  refundIsLoaded,
  refundPartialIsLoaded,

  // actions
  fetchItem,
  setBreadcrumbs,
}) {
  const { transactionId } = useParams();
  const location = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const shouldRefetch = confirmIsLoaded || cancelIsLoaded || refundIsLoaded || refundPartialIsLoaded;

  useEffect(() => {
    setIsMounted(true);
  }, [setIsMounted]);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 1, name: 'Платежи', link: '/app/payments/transactions/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs, transactionId, location]);

  useEffect(() => {
    if (data.number) {
      setBreadcrumbs([
        { level: 1, name: 'Платежи', link: '/app/payments/transactions/list' },
        { level: 2, name: `Заказ #${data.number}`, link: location.pathname },
      ]);
    }
  }, [data, setBreadcrumbs, location]);

  useEffect(() => {
    fetchItem({ transactionId });
  }, [fetchItem, transactionId]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchItem({ transactionId });
    }
  }, [fetchItem, transactionId, shouldRefetch]);

  return (
    <Root>
      <Header>Информация по заказу</Header>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {isLoading && <Loader text="Загружаются данные заказа..." height={400} />}

      {isLoaded && (
        <>
          <TransactionForm />
          <Operations transactionId={transactionId} />
        </>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    data: state.transaction.item.data,
    isLoading: state.transaction.item.isLoading,
    isLoaded: state.transaction.item.isLoaded,
    error: state.transaction.item.error,

    confirmIsLoaded: state.transaction.item.confirm.isLoaded,
    cancelIsLoaded: state.transaction.item.cancel.isLoaded,
    refundIsLoaded: state.transaction.item.refund.isLoaded,
    refundPartialIsLoaded: state.transaction.item.refundPartial.isLoaded,
  }),
  {
    fetchItem: fetchItemAction,
    setBreadcrumbs: setBreadcrumbsAction,
  },
)(Transaction);
