import API from 'services';

import { extractErrorAndCode } from 'hacks';

import {
  all, takeLatest, put, fork, call, select, cancel, take,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  TRANSACTION_FETCH_LIST,
  TRANSACTION_FETCH_LIST__SUCCESS,
  TRANSACTION_FETCH_LIST__FAILURE,
  TRANSACTION_FETCH_LIST__CANCEL_REQUEST,
  TRANSACTION_FETCH_LIST__SET_LOADING,
  TRANSACTION_FETCH_LIST__SET_PAGINATION,
  TRANSACTION_FETCH_LIST__SET_SEARCH,
  TRANSACTION_FETCH_LIST__SET_PAGE,
  TRANSACTION_FETCH_LIST__SET_SORTING,
  TRANSACTION_FETCH_LIST__SET_FILTER,

  TRANSACTION_FETCH_ITEM,
  TRANSACTION_FETCH_ITEM__SUCCESS,
  TRANSACTION_FETCH_ITEM__FAILURE,

  TRANSACTION_CONFIRM,
  TRANSACTION_CONFIRM__SUCCESS,
  TRANSACTION_CONFIRM__FAILURE,

  TRANSACTION_CANCEL,
  TRANSACTION_CANCEL__SUCCESS,
  TRANSACTION_CANCEL__FAILURE,

  TRANSACTION_REFUND,
  TRANSACTION_REFUND__SUCCESS,
  TRANSACTION_REFUND__FAILURE,

  TRANSACTION_REFUND_PARTIAL,
  TRANSACTION_REFUND_PARTIAL__SUCCESS,
  TRANSACTION_REFUND_PARTIAL__FAILURE,

  TRANSACTION_EXPORT_FILE,
  TRANSACTION_EXPORT_FILE__SUCCESS,
  TRANSACTION_EXPORT_FILE__FAILURE,

  TRANSACTION_SEND_FILE_TO_EMAILS,
  TRANSACTION_SEND_FILE_TO_EMAILS__SUCCESS,
  TRANSACTION_SEND_FILE_TO_EMAILS__FAILURE,
} from './actions';

const isObjectEmpty = (obj) => Object.keys(obj).length === 0;
const getState = (state) => state.transaction;
const toCoins = (value) => parseFloat(value) * 100;

function* _fetchList() {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);
  try {
    yield put({ type: TRANSACTION_FETCH_LIST__SET_LOADING });
    const response = yield call(API.transaction.fetchList, {
      search,
      page,
      filters: {
        ...filters,
        registerSumFrom: filters.registerSumFrom ? toCoins(filters.registerSumFrom) : '',
        registerSumTo: filters.registerSumTo ? toCoins(filters.registerSumTo) : '',
        actualSumFrom: filters.actualSumFrom ? toCoins(filters.actualSumFrom) : '',
        actualSumTo: filters.actualSumTo ? toCoins(filters.actualSumTo) : '',
      },
      sorting,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.paymentResponseList))
    ) {
      yield put({
        type: TRANSACTION_FETCH_LIST__SUCCESS,
        list: response.data.paymentResponseList || [],
        data: {
          pagination: response.pagination || {},
          sorting: response.sorting || {},
        },
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: TRANSACTION_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(TRANSACTION_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(TRANSACTION_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(TRANSACTION_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: TRANSACTION_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: TRANSACTION_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: TRANSACTION_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(TRANSACTION_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: TRANSACTION_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: TRANSACTION_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: TRANSACTION_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(TRANSACTION_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: TRANSACTION_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: TRANSACTION_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(TRANSACTION_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: TRANSACTION_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: TRANSACTION_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: TRANSACTION_FETCH_LIST });
  });
}

/* Item */
export function* fetchItem() {
  yield takeLatest(TRANSACTION_FETCH_ITEM, function* ({ transactionId }) {
    try {
      const response = yield call(API.transaction.fetchItem, { transactionId });

      if (
        response
        && response.data
        && Array.isArray(response.data.operationsItemList)
        && Array.isArray(response.data.paymentResponseList)
      ) {
        yield put({
          type: TRANSACTION_FETCH_ITEM__SUCCESS,

          // I literally have no idea why we have to send an array with one object in it
          // instead of just sending one object, but whatever, deal with it
          data: response.data.paymentResponseList[0] || {},

          // List of operations
          operations: response.data.operationsItemList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_FETCH_ITEM__FAILURE, error: error.message });
    }
  });
}

export function* confirmTransaction() {
  yield takeLatest(TRANSACTION_CONFIRM, function* ({ amount }) {
    try {
      const {
        item: { id },
      } = yield select(getState);

      const response = yield call(API.transaction.confirm, { transactionId: id, amount });

      if (response && response.data && isObjectEmpty(response.data)) {
        yield put({ type: TRANSACTION_CONFIRM__SUCCESS });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_CONFIRM__FAILURE, error: error.message });
    }
  });
}

export function* cancelTransaction() {
  yield takeLatest(TRANSACTION_CANCEL, function* () {
    try {
      const {
        item: { id },
      } = yield select(getState);

      const response = yield call(API.transaction.cancel, { transactionId: id });

      if (response && response.data && isObjectEmpty(response.data)) {
        yield put({ type: TRANSACTION_CANCEL__SUCCESS });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_CANCEL__FAILURE, error: error.message });
    }
  });
}

export function* refundTransaction() {
  yield takeLatest(TRANSACTION_REFUND, function* () {
    try {
      const {
        item: { id },
      } = yield select(getState);

      const response = yield call(API.transaction.refund, { transactionId: id });

      if (response && response.data && isObjectEmpty(response.data)) {
        yield put({ type: TRANSACTION_REFUND__SUCCESS });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_REFUND__FAILURE, error: error.message });
    }
  });
}

export function* refundPartialTransaction() {
  yield takeLatest(TRANSACTION_REFUND_PARTIAL, function* ({ amount }) {
    try {
      const {
        item: { id },
      } = yield select(getState);

      const response = yield call(API.transaction.refundPartial, {
        transactionId: id,
        amount,
      });

      if (response && response.data && isObjectEmpty(response.data)) {
        yield put({ type: TRANSACTION_REFUND_PARTIAL__SUCCESS });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_REFUND_PARTIAL__FAILURE, error: error.message });
    }
  });
}

export function* exportFile() {
  yield takeLatest(TRANSACTION_EXPORT_FILE, function* ({ fileFormat }) {
    try {
      const {
        collection: { filters, sorting },
      } = yield select(getState);

      const response = yield call(API.transaction.exportFile, {
        filter: {
          ...filters,
          registerSumFrom: filters.registerSumFrom ? toCoins(filters.registerSumFrom) : '',
          registerSumTo: filters.registerSumTo ? toCoins(filters.registerSumTo) : '',
          actualSumFrom: filters.actualSumFrom ? toCoins(filters.actualSumFrom) : '',
          actualSumTo: filters.actualSumTo ? toCoins(filters.actualSumTo) : '',
        },
        fileFormat,
        sorting,
      });

      if (response
          && response.data
          && response.data.paymentListExportResponseList
          && Array.isArray(response.data.paymentListExportResponseList)
          && response.data.paymentListExportResponseList[0]
      ) {
        yield put({
          type: TRANSACTION_EXPORT_FILE__SUCCESS,
          data: response.data.paymentListExportResponseList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_EXPORT_FILE__FAILURE, error: error.message });
    }
  });
}

export function* sendFileToEmails() {
  yield takeLatest(TRANSACTION_SEND_FILE_TO_EMAILS, function* ({ fileFormat, recipients }) {
    try {
      const {
        collection: { filters, sorting },
      } = yield select(getState);

      const response = yield call(API.transaction.sendFileToEmail, {
        filter: {
          ...filters,
          registerSumFrom: filters.registerSumFrom ? toCoins(filters.registerSumFrom) : '',
          registerSumTo: filters.registerSumTo ? toCoins(filters.registerSumTo) : '',
          actualSumFrom: filters.actualSumFrom ? toCoins(filters.actualSumFrom) : '',
          actualSumTo: filters.actualSumTo ? toCoins(filters.actualSumTo) : '',
        },
        fileFormat,
        recipients,
        sorting,
      });

      if (response
          && response.data
          && response.data.paymentListExportEmailResponseList
          && Array.isArray(response.data.paymentListExportEmailResponseList)
          && response.data.paymentListExportEmailResponseList[0]
      ) {
        yield put({
          type: TRANSACTION_SEND_FILE_TO_EMAILS__SUCCESS,
          data: response.data.paymentListExportEmailResponseList[0],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: TRANSACTION_SEND_FILE_TO_EMAILS__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(fetchItem),
    fork(confirmTransaction),
    fork(cancelTransaction),
    fork(refundTransaction),
    fork(refundPartialTransaction),
    fork(exportFile),
    fork(sendFileToEmails),
  ]);
}
