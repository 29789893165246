import styled from 'styled-components';

const COLOR_STATUS = {
  Возврат: '#E71313',
  Подтвержден: '#7B7B7B',
  'Ожидает оплаты': '#2E82FF',
  Зарезервирован: '#2E82FF',
};

export const Root = styled.div`
  padding: 0rem 4rem 0;

  a {
    color: #7b7b7b;
    font-size: 16px;
  }

`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const Status = styled.div`
  color: ${(props) =>
    (COLOR_STATUS[props.status] ? COLOR_STATUS[props.status] : '#7B7B7B')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const RepeatContainer = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s linear all;
  &:hover {
    color: #000;
  }
`;

export const RequisitesItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span {
    margin-right: 5px;
  }
`;
