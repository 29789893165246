import React from 'react';

export const InfoIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="#E50000" strokeWidth="2" />
    <path d="M10 14.8C9.4 14.8 9 14.4 9 13.8V9.79999C9 9.19999 9.4 8.79999 10 8.79999C10.6 8.79999 11 9.19999 11 9.79999V13.8C11 14.4 10.6 14.8 10 14.8Z" fill="#E50000" />
    <path d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z" fill="#E50000" />
  </svg>
);

export const InfoIconSecond = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="#A8AAAC" strokeWidth="2" />
    <path d="M10 14.8C9.4 14.8 9 14.4 9 13.8V9.79999C9 9.19999 9.4 8.79999 10 8.79999C10.6 8.79999 11 9.19999 11 9.79999V13.8C11 14.4 10.6 14.8 10 14.8Z" fill="#A8AAAC" />
    <path d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z" fill="#A8AAAC" />
  </svg>

);
