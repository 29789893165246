export const KEYS = {
  THEME: {
    key: 'THEME',
    apiKey: 'topics',
    label: 'Тема',
    placeholder: 'Выберите тему',
  },

  SUB_THEME: {
    key: 'SUB_THEME',
    label: 'Подтема',
    apiKey: 'subtopics',
    placeholder: 'Выберите подтему',
  },

  COUNT_FROM: {
    key: 'COUNT_FROM',
    apiKey: 'countCarsFrom',
    label: 'Кол-во автомобилей от',
    min: 1,
  },

  COUNT_TO: {
    key: 'COUNT_TO',
    apiKey: 'countCarsTo',
    label: 'Кол-во автомобилей до',
    min: 1,
  },

  EXECUTION_DATE_FROM: {
    key: 'EXECUTION_DATE_FROM',
    apiKey: 'deadlineFrom',
    label: 'Срок исполнения от',
  },

  EXECUTION_DATE_TO: {
    key: 'EXECUTION_DATE_TO',
    apiKey: 'dialingTo',
    label: 'Срок исполнения до',
  },

  DATE_FROM: {
    key: 'DATE_FROM',
    apiKey: 'dialingFrom',
    label: 'Обзвон от даты',
  },

  DATE_TO: {
    key: 'DATE_TO',
    apiKey: 'dialingTo',
    label: 'Обзвон до даты',
  },
};
