import {
  all, call, fork, put, takeLeading,
} from 'redux-saga/effects';
import API from 'services';
import { extractErrorAndCode } from 'hacks';
import {
  FETCH_EMPLOYEES_LIST,
  FETCH_EMPLOYEES_LIST__FAILURE,
  FETCH_EMPLOYEES_LIST__SUCCESS,

  EMPLOYEE_GET_REPORT,
  EMPLOYEE_GET_REPORT__FAILURE,
    // EMPLOYEE_GET_REPORT__RESET,
  EMPLOYEE_GET_REPORT__SUCCESS,
} from './actions';
import { ON_ERROR } from '../auth/actions';

function* fetchEmployeesList() {
  yield takeLeading(FETCH_EMPLOYEES_LIST, function* () {
    try {
      const response = yield call(API.TCM.employee.fetchList, {});
      if (
        response.data
        && Array.isArray(response.data.userList)
      ) {
        yield put({
          type: FETCH_EMPLOYEES_LIST__SUCCESS,
          value: response.data.userList,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: FETCH_EMPLOYEES_LIST__FAILURE, error: error.message });
    }
  });
}

function* getEmployeeReport() {
  yield takeLeading(EMPLOYEE_GET_REPORT, function* ({
    campaignIds, employeeIds, startAt, finishAt,
  }) {
    try {
      const response = yield call(API.TCM.employee.getEmployeeReport, {
        campaignIds,
        employeeIds,
        startAt,
        finishAt,
      });

      if (
        response.data
        && !response.errorCode
      ) {
        yield put({
          type: EMPLOYEE_GET_REPORT__SUCCESS,
          data: response.data,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: EMPLOYEE_GET_REPORT__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchEmployeesList),
    fork(getEmployeeReport),
  ]);
}
