import styled from 'styled-components';

export const Root = styled.div``;

export const Description = styled.div`
  height: 2rem;
  font-size: 0.8rem;
  color: #696969;
  margin-top: 4px;
  margin-bottom: -20px;
`;
