import React, { useMemo, useState } from 'react';
import { StatusLine } from 'components/tcm/StatusLine/StatusLine';
import { TCM } from 'components/tcm';
import { PageTitle } from 'containers/App/TCM/style';
import { SHOWING_COMMENTS } from 'settings';
import { CommentRow } from 'components/tcm/CampaignInfo/style';
import { CHAR } from 'settings/namedUnicode';
import { getDeclension } from 'utils/getDeclension';
import { HistoryModal } from 'components/tcm/CampaignInfo/HistoryModal';
import {
  Comment,
  CommentDate,
  CommentsWrapper,
  CommentText,
  CommentTitle,
  Content,
  CountWrapper,
  EstimatedPeriodWrapper,
  FieldTitle,
  FieldValue,
  IdWrapper,
  Main,
  MainContent,
  PlannedPeriodWrapper,
  StatusHistory,
  StatusLineWrapper,
  StatusWrapper,
  SubTitle,
  Title,
} from './style';

export function CampaignInfoWithStatus({
    // passed
  campaign,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <StatusLineWrapper>
        <StatusLine statuses={campaign.statusHistory} />
        <TCM.ButtonText
          as={StatusHistory}
          onClick={() => {
            setShowModal(true);
          }}
        >
          История действий
        </TCM.ButtonText>
      </StatusLineWrapper>
      <CampaignInfo campaign={campaign} />

      <HistoryModal
        statusHistory={campaign.statusHistory}
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        campaignId={campaign.id}
      />
    </>
  );
}

function CampaignInfo({ campaign }) {
  const [showMoreComments, setShowMoreComments] = useState(false);

  const showMoreText = useMemo(() => {
    if (campaign.comments?.length) {
      const number = campaign.comments.length - SHOWING_COMMENTS;
      return (!showMoreComments && campaign.comments && campaign.comments.length > SHOWING_COMMENTS
        ? `Еще ${number} ${getDeclension(number, 'комментарий')}`
        : 'Скрыть');
    }
  },
  [campaign.comments, showMoreComments]);

  const preparedComments = useMemo(() => {
    if (campaign?.comments?.length) {
      return showMoreComments
        ? campaign.comments
        : campaign.comments.slice(0, 2);
    }
    return [];
  },
  [campaign.comments, showMoreComments]);

  return (
    <Main>
      <SubTitle>Кампания</SubTitle>
      <Title>
        <PageTitle>{campaign.topic}</PageTitle>
        <p>{campaign.subtopic}</p>
      </Title>
      <Content>

        <MainContent>
          <IdWrapper>
            <FieldTitle>ID</FieldTitle>
            <FieldValue>{campaign.id}</FieldValue>
          </IdWrapper>

          <StatusWrapper>
            <FieldTitle>Статус</FieldTitle>
            <FieldValue>{campaign.status}</FieldValue>
          </StatusWrapper>

          <PlannedPeriodWrapper>
            <FieldTitle>Плановый период обзвона</FieldTitle>
            <FieldValue>{`${campaign.dialingFrom} - ${campaign.dialingTo}`}</FieldValue>
          </PlannedPeriodWrapper>

          <EstimatedPeriodWrapper>
            <FieldTitle>Расчетный период обзвона</FieldTitle>
            <FieldValue>
              {(campaign.calculatedFrom && campaign.calculatedTo)
                ? `${campaign.calculatedFrom.format('DD.MM')} - ${campaign.calculatedTo.format('DD.MM.YYYY')}`
                : CHAR.MDASH}
            </FieldValue>
          </EstimatedPeriodWrapper>

          <CountWrapper>
            <FieldTitle>Количество доступных для реализации автомобилей</FieldTitle>
            <FieldValue>{campaign.countCarsInStock}</FieldValue>
          </CountWrapper>
        </MainContent>

        {preparedComments.length > 0 && (
          <CommentsWrapper>
            <p>Комментарии</p>

            {preparedComments.map((comment) => (
              <Comment key={comment.id}>
                <div>
                  <CommentTitle>{(comment.author && comment.author.name) ?? 'Банк'}</CommentTitle>
                  <CommentRow>
                    <CommentText>
                      {comment.text}
                    </CommentText>
                    <CommentDate>{comment.createdAt}</CommentDate>
                  </CommentRow>
                </div>
              </Comment>
            ))}

            {campaign.comments.length > SHOWING_COMMENTS && (
              <TCM.ButtonText onClick={() => setShowMoreComments(!showMoreComments)}>
                {showMoreText}
              </TCM.ButtonText>
            )}
          </CommentsWrapper>
        )}
      </Content>
    </Main>
  );
}

export default CampaignInfo;
