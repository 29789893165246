import moment from 'moment';
import { CAMPAIGN_STATUSES, STATUS_DATA } from 'tcm/campaigns/data';

export const formatCampaignList = (objectRaw) => {
  if (objectRaw && Array.isArray(objectRaw.campaignItemResponseList)
  ) {
    const tableData = objectRaw.campaignItemResponseList.map((v) => {
      const {
        id, status: statusRaw, deadline, countCarsInStock, dialingFrom, dialingTo, canClosed, canSetNextStatus,
      } = v;
      const itemSubNameData = objectRaw.subtopicItemResponseList.find(
        (subtopic) => subtopic.id === v.subtopicItemResponseList[0].id,
      );
      const itemNameData = objectRaw.topicItemResponseList.find(
        (topic) => topic.id === itemSubNameData.topicItemResponseList[0].id,
      );

      if (Object.prototype.hasOwnProperty.call(CAMPAIGN_STATUSES, statusRaw)) {
        const deadlineRaw = deadline && new Date(deadline);
        const dialingFromRaw = moment(dialingFrom).isValid() && moment(dialingFrom);
        const dialingToRaw = moment(dialingTo).isValid() && moment(dialingTo);

        // логика такая если кампания в статусе Revision и у него следующий статус KP_calculation то переводить
        // его следует в REVISION_KP_CALCULATION

        const showStatus = statusRaw === CAMPAIGN_STATUSES.REVISION
        && canSetNextStatus === CAMPAIGN_STATUSES.KP_CALCULATION
          ? CAMPAIGN_STATUSES.REVISION_KP_CALCULATION : statusRaw;

        const {
          positiveAction, negativeAction, title: status, filter,
        } = STATUS_DATA[showStatus];

        return {
          id,
          status,
          statusCode: statusRaw,
          deadline: deadlineRaw,
          countCarsInStock,
          dialingFrom: dialingFromRaw,
          dialingTo: dialingToRaw,
          name: itemNameData.name,
          subName: itemSubNameData.name,
          positiveAction,
          negativeAction,
          filter,
          canClosed,
          canSetNextStatus,
        };
      }

      return [];
    });

    return tableData;
  }
  return [];
};
