import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form as LegacyForm } from '@ant-design/compatible';

import { Result } from 'antd';
import Loader from 'components/Loader';

import { TYPES } from 'settings';

import { fetch as fetchCredit, patch as patchCredit } from 'redux/credit/actions';

import { FixedView, Root, ScrollView } from './style';

import Switch from '../../Switch';

function FormComponent({
  // passed
  id,
  form,

  // connect
  fields,
  values,
  queue,
  isLoading,
  isLoaded,
  error,
  fetchIsLoading,
  fetchIsLoaded,
  formIsDisabled,
  popups,
  patchIsLoading,

  // actions
  fetchCredit,
  patchCredit,
}) {
  useEffect(() => {
    fetchCredit({ accessToken: id });
  }, [fetchCredit, id]);

  function onChangeField(userInput, exclude) {
    patchCredit({
      userInput,
      ...(Array.isArray(exclude) ? { exclude } : {}),
    });
  }

  function onSubmit(e) {
    e.preventDefault();
  }

  const isAccessForbidden = popups.find((e) => e.type === TYPES.ACCESS);

  return (
    <Root>
      {popups.map((field) => Switch({ field }))}
      {(isLoading || fetchIsLoading) && <Loader text="Загрузка данных кредита" />}
      {error && <span>{error}</span>}
      {isLoaded && fetchIsLoaded && (
        <>
          {!isAccessForbidden ? (
            <>
              <ScrollView>
                <LegacyForm onSubmit={onSubmit}>
                  {fields.map((field) =>
                    Switch({
                      field,
                      props: {
                        form,
                        fields,
                        values,
                        formIsDisabled,
                        queue,
                        onChangeField,
                        patchIsLoading,
                      },
                    }))}
                </LegacyForm>
              </ScrollView>
              <FixedView>
                <img
                  src="https://d8a6a33f-3369-444b-9b5f-793c13ff0708.selcdn.net/media/common/car_slider_item/tradeins.space/uploads/photo/2153831/a21c99a4f2f5f6304175319ac52ca292.png?v32"
                  alt="car placeholder"
                />
              </FixedView>
            </>
          ) : (
            <Result status="warning" title={isAccessForbidden.text} />
          )}
        </>
      )}
    </Root>
  );
}

const withForm = LegacyForm.create({ name: 'credit' })(FormComponent);

export default connect(
  (state) => ({
    fields: state.credit.fields,
    values: state.credit.values,
    queue: state.credit.requestQueue,
    isLoading: state.credit.fetch.isLoading,
    isLoaded: state.credit.fetch.isLoaded,
    error: state.credit.fetch.error,
    fetchIsLoading: state.request.fetch.isLoading,
    fetchIsLoaded: state.request.fetch.isLoaded,
    formIsDisabled: state.request.formIsDisabled,
    popups: state.request.popups,
    patchIsLoading: state.credit.patch.isLoading,
  }),
  {
    fetchCredit,
    patchCredit,
  },
)(withForm);
