// ugly way of accessing the data - server returns either an object with array inside or just an empty array
export const format = (object) =>
  (object.data && object.data.merchantList ? object.data.merchantList : []);

export const getUser = (object) => format(object)[0] || {};

export const checkIsIternalUser = (userType) => userType === 'internal';
export const checkIsOutourceUser = (userType) => userType === 'outsource';
export const checkIsExternalUser = (userType) => userType === 'external';

export const formatUserWithRole = (userList) => {
  if (Array.isArray(userList)) {
    return userList.map((item) => ({
      ...item,
      roleNames: item.accessList.map(({ role }) => role.name) || [],
    }));
  }

  return [];
};
