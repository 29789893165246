import React from 'react';
import { FIXBLOCK_TYPES as TYPES } from 'settings';

import Row from '../Row';
import Block from '../Block';
import Label from '../Label';
import Button from '../Button';
import Select from '../Select';
import ButtonGroup from '../ButtonGroup';
import Revision from '../Revision';

function Switch({ field, props }) {
  switch (field.type) {
    case TYPES.ROW: {
      return (
        <Row key={field.name}>{field.data.map((e) => Switch({ field: e, props }))}</Row>
      );
    }
    case TYPES.BLOCK: {
      return (
        <Block key={field.name}>{field.data.map((e) => Switch({ field: e, props }))}</Block>
      );
    }
    case TYPES.LABEL: {
      return <Label key={field.name} title={field.title} label={field.text} />;
    }
    case TYPES.BUTTON: {
      let { disabled } = field;
      if (field.name === 'toStatus6') {
        const statusValue = field.form.getFieldValue('status6CausesForRefusalList');
        disabled = !(statusValue) || disabled;
      }
      return (
        <Button
          key={field.name}
          label={field.label}
          name={field.name}
          value={field.value}
          accessToken={field.accessToken}
          confirm={field.confirm}
          disabled={disabled}
        />
      );
    }
    case TYPES.BUTTON_GROUP: {
      return (
        <ButtonGroup key={field.name}>
          {field.data.map((e) => Switch({ field: e, props }))}
        </ButtonGroup>
      );
    }
    case TYPES.HIDDEN: {
      return <input key={field.name} type="hidden" name={field.name} />;
    }

    case TYPES.REVISION: {
      return (
        <Revision key={field.name} {...field} />
      );
    }

    case 'select': {
      return (
        <Select field={field} props={props} />
      );
    }

    default: {
      return null;
    }
  }
}

export default Switch;
