import { formatPhone } from 'utils';

export const KEYS = {
  NAME: {
    key: 'NAME',
    apiKey: 'salePoint',
    title: 'Наименование',
    rules: [
      {
        required: true,
        message: 'Укажите имя Точки Продаж',
      },
    ],
  },

  INN: {
    key: 'INN',
    apiKey: 'inn',
    title: 'Организация',
    rules: [
      {
        required: true,
        message: 'Выберите организацию',
      },
    ],
  },

  KRIF_ID: {
    key: 'KRIF_ID',
    apiKey: 'krifId',
    title: 'Криф ID',
    rules: [],
  },

  ACQUIRING_ID: {
    key: 'ACQUIRING_ID',
    apiKey: 'acquiringID',
    title: 'Эквайринг ID',
    rules: [],
  },

  ONLINE_ID: {
    key: 'ONLINE_ID',
    apiKey: 'onlineId',
    title: 'Онлайн заявка ID',
    rules: [],
  },

  ADDRESS: {
    key: 'ADDRESS',
    apiKey: 'addressactual',
    title: 'Адрес',
    rules: [
      {
        required: true,
        message: 'Поле не может быть пустым',
      },
    ],
  },

  PHONE: {
    key: 'PHONE',
    apiKey: 'phone',
    title: 'Номер Телефона',
    rules: [
      formatPhone.validators.validateIfNotEmpty,
      formatPhone.validators.validateIfNotFilled,
    ],
  },

  DESCRIPTION: {
    key: 'DESCRIPTION',
    apiKey: 'detail',
    title: 'Дополнительная информация',
    rules: [],
  },

  SECTORS: {
    key: 'SECTORS',
    apiKey: 'bindSector',
    title: 'Сектора',
    rules: [],
  },
};
