import styled from 'styled-components';
import { GRID_AREAS } from 'settings';
import { AutoComplete } from 'antd';

const AutoCompleteStyled = styled(AutoComplete).attrs({
  className: 'styledInput',
})`
    &.ant-select-auto-complete {
      ${({ area }) => typeof area === 'boolean' && `
        grid-area: ${area ? GRID_AREAS.LEFT.INPUT : GRID_AREAS.RIGHT.INPUT};
      `}
    }
    
    &.ant-select-single.ant-select-lg {
      .ant-select-selector {
        height: 50px;
        border-radius: 5px;
        
        .ant-select-selection-search-input {
          height: 50px;
        }
        
        .ant-select-selection-placeholder {
          line-height: 50px;
        }
        
        .ant-select-selection-item {
          line-height: 50px;
        }
      }
    }
`;

export { AutoCompleteStyled };
