/* List */
export const TRANSACTION_FETCH_LIST = 'TRANSACTION_FETCH_LIST';
export const TRANSACTION_FETCH_LIST__SUCCESS = 'TRANSACTION_FETCH_LIST__SUCCESS';
export const TRANSACTION_FETCH_LIST__FAILURE = 'TRANSACTION_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting,
} = {}) => ({
  type: TRANSACTION_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
});

export const TRANSACTION_FETCH_LIST__CANCEL_REQUEST = 'TRANSACTION_FETCH_LIST__CANCEL_REQUEST';
export const TRANSACTION_FETCH_LIST__SET_LOADING = 'TRANSACTION_FETCH_LIST__SET_LOADING';
export const TRANSACTION_FETCH_LIST__SET_PAGINATION = 'TRANSACTION_FETCH_LIST__SET_PAGINATION';

export const TRANSACTION_FETCH_LIST__SET_SEARCH = 'TRANSACTION_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({
  type: TRANSACTION_FETCH_LIST__SET_SEARCH,
  search,
});

export const TRANSACTION_FETCH_LIST__SET_PAGE = 'TRANSACTION_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: TRANSACTION_FETCH_LIST__SET_PAGE, page });

export const TRANSACTION_FETCH_LIST__SET_SORTING = 'TRANSACTION_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: TRANSACTION_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const TRANSACTION_FETCH_LIST__SET_FILTER = 'TRANSACTION_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: TRANSACTION_FETCH_LIST__SET_FILTER,
  filters,
});

export const TRANSACTION_FETCH_LIST__RESET = 'TRANSACTION_FETCH_LIST__RESET';
export const reset = () => ({ type: TRANSACTION_FETCH_LIST__RESET });

/* Item */
export const TRANSACTION_FETCH_ITEM = 'TRANSACTION_FETCH_ITEM';
export const TRANSACTION_FETCH_ITEM__SUCCESS = 'TRANSACTION_FETCH_ITEM__SUCCESS';
export const TRANSACTION_FETCH_ITEM__FAILURE = 'TRANSACTION_FETCH_ITEM__FAILURE';
export const fetchItem = ({ transactionId } = {}) => ({
  type: TRANSACTION_FETCH_ITEM,
  transactionId,
});

export const TRANSACTION_CONFIRM = 'TRANSACTION_CONFIRM';
export const TRANSACTION_CONFIRM__SUCCESS = 'TRANSACTION_CONFIRM__SUCCESS';
export const TRANSACTION_CONFIRM__FAILURE = 'TRANSACTION_CONFIRM__FAILURE';
export const confirm = ({ amount }) => ({ type: TRANSACTION_CONFIRM, amount });

export const TRANSACTION_CANCEL = 'TRANSACTION_CANCEL';
export const TRANSACTION_CANCEL__SUCCESS = 'TRANSACTION_CANCEL__SUCCESS';
export const TRANSACTION_CANCEL__FAILURE = 'TRANSACTION_CANCEL__FAILURE';
export const cancel = () => ({ type: TRANSACTION_CANCEL });

export const TRANSACTION_REFUND = 'TRANSACTION_REFUND';
export const TRANSACTION_REFUND__SUCCESS = 'TRANSACTION_REFUND__SUCCESS';
export const TRANSACTION_REFUND__FAILURE = 'TRANSACTION_REFUND__FAILURE';
export const refund = () => ({ type: TRANSACTION_REFUND });

export const TRANSACTION_REFUND_PARTIAL = 'TRANSACTION_REFUND_PARTIAL';
export const TRANSACTION_REFUND_PARTIAL__SUCCESS = 'TRANSACTION_REFUND_PARTIAL__SUCCESS';
export const TRANSACTION_REFUND_PARTIAL__FAILURE = 'TRANSACTION_REFUND_PARTIAL__FAILURE';
export const refundPartial = ({ amount } = {}) => ({
  type: TRANSACTION_REFUND_PARTIAL,
  amount,
});

export const TRANSACTION_SEND_FILE_TO_EMAILS = 'TRANSACTION_SEND_FILE_TO_EMAILS';
export const TRANSACTION_SEND_FILE_TO_EMAILS__SUCCESS = 'TRANSACTION_SEND_FILE_TO_EMAILS__SUCCESS';
export const TRANSACTION_SEND_FILE_TO_EMAILS__FAILURE = 'TRANSACTION_SEND_FILE_TO_EMAILS__FAILURE';
export const sendFileToEmail = ({ fileFormat = '', recipients = [] } = {}) => ({
  type: TRANSACTION_SEND_FILE_TO_EMAILS,
  fileFormat,
  recipients,
});

export const TRANSACTION_SEND_FILE_TO_EMAILS__RESET = 'TRANSACTION_SEND_FILE_TO_EMAILS__RESET';
export const sendFileToEmailReset = () => ({
  type: TRANSACTION_SEND_FILE_TO_EMAILS__RESET,
});

export const TRANSACTION_EXPORT_FILE = 'TRANSACTION_EXPORT_FILE';
export const TRANSACTION_EXPORT_FILE__SUCCESS = 'TRANSACTION_EXPORT_FILE__SUCCESS';
export const TRANSACTION_EXPORT_FILE__FAILURE = 'TRANSACTION_EXPORT_FILE__FAILURE';
export const exportFile = ({ fileFormat = '' } = {}) => ({
  type: TRANSACTION_EXPORT_FILE,
  fileFormat,
});

export const TRANSACTION_EXPORT_FILE__RESET = 'TRANSACTION_EXPORT_FILE__RESET';
export const exportFileReset = () => ({
  type: TRANSACTION_EXPORT_FILE__RESET,
});
