import { useState } from 'react';

export const useForm = (initialValues) => {
  const [form, setForm] = useState(initialValues);

  const updateForm = (values) => {
    setForm((state) => ({
      ...state,
      ...values,
    }));
  };

  const handleInputChange = (object) => {
    if (object.persist) {
      object.persist();
    }
    const element = object.target ? object.target : object;

    setForm((state) => ({
      ...state,
      [element.name]: element.type === 'checkbox' ? element.checked : element.value,
    }));
  };

  return {
    form,
    setForm,
    updateForm,
    handleInputChange,
  };
};
