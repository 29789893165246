import styled from 'styled-components';
import withTheme from './withTheme';

export const ErrorMessage = withTheme(styled.div`
  color: ${({ theme }) => theme.palette.accent};
  font-size: 14px;
  margin-top: 0.4rem;
`);

export const ErrorMessageAbsolute = withTheme(styled(ErrorMessage)`
  position:absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translate(-50%);
`);
