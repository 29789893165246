import { API_TCM_URL as API } from 'settings/api';
import { getToken } from 'helpers/utility';
import { defaultSorting, defaultPageSize } from 'redux/tcmOffer/reducer';
import { v4 as uuidv4 } from 'uuid';

export const fetchList = ({
  id,
  filters,
  sorting = defaultSorting,
  page = 1,
  search = '',
}) => {
  const URI = `${API}/tcm/offers`;
  const data = {
    campaignId: id,
    query: search,
    ...filters,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        sort: sorting,
      },
      // hasnt been implemented yet
      _pagination: {
        page,
        size: defaultPageSize,
      },
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};

export const setEvaluation = ({
  evaluations,
}) => {
  const URI = `${API}/tcm/offers/evaluation/set`;
  const data = {
    evaluations,
  };
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchItem = ({
  id,
}) => {
  const URI = `${API}/tcm/offer`;
  const data = {
    externalId: id,
  };
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const setEmployee = ({
  employees,
}) => {
  const URI = `${API}/tcm/offers/employee/set`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        employees,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const exportOffer = (
  { id },
) => {
  const URI = `${API}/tcm/offers/export?id=${id}&accessToken=${getToken()}`;
  const query = {
    method: 'GET',
  };

  return fetch(URI, query)
    .then((response) => response.blob())
    .then((blob) => blob);
};

export const importOffer = (
  { formData },
) => {
  const URI = `${API}/tcm/offers/import`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      accept: 'application/json',
    },
    body: formData,

  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const postCallResult = ({
  formData,
}) => {
  const URI = `${API}/tcm/offer/call/add`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { ...formData },
    }),

  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

/**
 * @typedef {
 *  'EVALUATION_TRADE_IN' |
 *  'TEST_DRIVE' |
 *  'FILING_AN_APPLICATION' |
 *  'LOAN_AGREEMENT_EXECUTION'
 *  } VisitStatus
 */

/**
 * @typedef {
 *  'EVALUATION_TRADE_IN_SUM' |
 *  'TEST_DRIVE_BRAND' |
 *  'TEST_DRIVE_MODEL' |
 *  'FILING_AN_APPLICATION_BANK' |
 *  'FILING_AN_APPLICATION_BRAND' |
 *  'FILING_AN_APPLICATION_MODEL' |
 *  'LOAN_AGREEMENT_EXECUTION_BANK' |
 *  'LOAN_AGREEMENT_EXECUTION_REJECTION_REASON'
 *  } VisitDescriptionStatus
 */

/**
 *
 * @param {{
 *  externalId: string,
 *  statuses: VisitStatus[],
 *  statusDescriptions: VisitDescriptionStatus[],
 *  text: string
 * }} formData
 * @return {Promise<*>}
 */
export const postVisitResult = ({
  formData,
}) => {
  const URI = `${API}/tcm/offer/visit/add`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { ...formData },
    }),

  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

/**
 * @param {number[]} offerIds
 * @param {string} status
 * @return {Promise<json>}
 */
export const setOfferStatus = ({
  offerIds,
  status,
}) => {
  const URI = `${API}/tcm/offers/status/update`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        externalIds: offerIds,
        status,
      },
    }),

  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const addComment = ({
  externalId,
  text,
}) => {
  const URI = `${API}/tcm/offer/comment/add`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: { externalId, text },
    }),

  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchOfferStatuses = () => {
  const URI = `${API}/tcm/offers/status/list`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {},
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const exportOldCar = (
  { id },
) => {
  const URI = `${API}/tcm/offer/car/export?externalId=${id}&accessToken=${getToken()}`;
  const query = {
    method: 'GET',
  };

  return fetch(URI, query)
    .then((response) => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error('Ошибка сети');
    })
    .then((blob) => blob);
};

export const postAdditionalPhone = ({
  externalId,
  additionalPhone,
}) => {
  const URI = `${API}/tcm/offer/client/additional-phone/update`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        externalId,
        additionalPhone,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getPreviousCampaign = ({
  externalId,
}) => {
  const URI = `${API}/tcm/client/offers`;
  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        externalId,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};
