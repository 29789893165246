import {
  TRANSACTION_FETCH_LIST,
  TRANSACTION_FETCH_LIST__SUCCESS,
  TRANSACTION_FETCH_LIST__FAILURE,
  TRANSACTION_FETCH_LIST__SET_LOADING,
  TRANSACTION_FETCH_LIST__SET_PAGINATION,
  TRANSACTION_FETCH_LIST__SET_SEARCH,
  TRANSACTION_FETCH_LIST__SET_SORTING,
  TRANSACTION_FETCH_LIST__SET_FILTER,
  TRANSACTION_FETCH_LIST__RESET,

  TRANSACTION_FETCH_ITEM,
  TRANSACTION_FETCH_ITEM__SUCCESS,
  TRANSACTION_FETCH_ITEM__FAILURE,

  TRANSACTION_CONFIRM,
  TRANSACTION_CONFIRM__SUCCESS,
  TRANSACTION_CONFIRM__FAILURE,

  TRANSACTION_CANCEL,
  TRANSACTION_CANCEL__SUCCESS,
  TRANSACTION_CANCEL__FAILURE,

  TRANSACTION_REFUND,
  TRANSACTION_REFUND__SUCCESS,
  TRANSACTION_REFUND__FAILURE,
  TRANSACTION_REFUND_PARTIAL,
  TRANSACTION_REFUND_PARTIAL__SUCCESS,
  TRANSACTION_REFUND_PARTIAL__FAILURE,

  TRANSACTION_EXPORT_FILE,
  TRANSACTION_EXPORT_FILE__SUCCESS,
  TRANSACTION_EXPORT_FILE__FAILURE,
  TRANSACTION_EXPORT_FILE__RESET,

  TRANSACTION_SEND_FILE_TO_EMAILS,
  TRANSACTION_SEND_FILE_TO_EMAILS__SUCCESS,
  TRANSACTION_SEND_FILE_TO_EMAILS__FAILURE,
  TRANSACTION_SEND_FILE_TO_EMAILS__RESET,

} from './actions';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const defaultSorting = {
  sortBy: 'createdAt',
  order: ORDER.DESC,
};

export const defaultPageSize = 25;

export const initialFilters = {
  number: '',
  dataFrom: '',
  dataTo: '',
  registerSumFrom: '',
  registerSumTo: '',
  actualSumFrom: '',
  actualSumTo: '',
  name: '',
  phone: '',
  email: '',
  status: '',
  source: '',
};

const initState = {
  collection: {
    data: {},
    list: [],
    sorting: defaultSorting,
    search: '',
    filters: initialFilters,
    page: 1,
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  item: {
    id: null,
    data: {},
    operations: [],
    isLoading: false,
    isLoaded: false,
    error: null,

    confirm: {
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    cancel: {
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    refund: {
      isLoading: false,
      isLoaded: false,
      error: null,
    },

    refundPartial: {
      isLoading: false,
      isLoaded: false,
      error: null,
    },
  },
  exportFile: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },

  sendFile: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

export default function transactionReducer(state = initState, action) {
  switch (action.type) {
    case TRANSACTION_FETCH_LIST__RESET: {
      return {
        ...state,

        collection: {
          ...initState.collection,
        },
      };
    }

    case TRANSACTION_FETCH_LIST__SET_LOADING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
        },
      };
    }

    case TRANSACTION_FETCH_LIST__SET_SEARCH: {
      return {
        ...state,

        collection: {
          ...state.collection,

          search: action.search,
        },
      };
    }

    case TRANSACTION_FETCH_LIST__SET_PAGINATION: {
      return {
        ...state,
        collection: {
          ...state.collection,

          page: action.page,
        },
      };
    }

    case TRANSACTION_FETCH_LIST__SET_SORTING: {
      return {
        ...state,

        collection: {
          ...state.collection,

          sorting: {
            sortBy: action.sortBy,
            order:
              state.collection.sorting.sortBy === action.sortBy
                ? state.collection.sorting.order === ORDER.ASC
                  ? ORDER.DESC
                  : ORDER.ASC
                : ORDER.ASC,
          },
        },
      };
    }

    case TRANSACTION_FETCH_LIST__SET_FILTER: {
      return {
        ...state,

        collection: {
          ...state.collection,

          filters: action.filters,
        },
      };
    }

    case TRANSACTION_FETCH_LIST: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: true,
          isLoaded: false,
          error: null,
        },
      };
    }
    case TRANSACTION_FETCH_LIST__SUCCESS: {
      return {
        ...state,

        collection: {
          ...state.collection,

          data: action.data,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case TRANSACTION_FETCH_LIST__FAILURE: {
      return {
        ...state,

        collection: {
          ...state.collection,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case TRANSACTION_FETCH_ITEM: {
      return {
        ...state,

        item: {
          ...initState.item,
          id: action.transactionId,
          isLoading: true,
          error: null,
        },
      };
    }
    case TRANSACTION_FETCH_ITEM__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          data: action.data,
          operations: action.operations,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case TRANSACTION_FETCH_ITEM__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case TRANSACTION_CONFIRM: {
      return {
        ...state,

        item: {
          ...state.item,

          confirm: {
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_CONFIRM__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          confirm: {
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_CONFIRM__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          confirm: {
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }

    case TRANSACTION_CANCEL: {
      return {
        ...state,

        item: {
          ...state.item,

          cancel: {
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_CANCEL__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          cancel: {
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_CANCEL__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          cancel: {
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }

    case TRANSACTION_REFUND: {
      return {
        ...state,

        item: {
          ...state.item,

          refund: {
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_REFUND__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          refund: {
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_REFUND__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          refund: {
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }

    case TRANSACTION_REFUND_PARTIAL: {
      return {
        ...state,

        item: {
          ...state.item,

          refundPartial: {
            isLoading: true,
            isLoaded: false,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_REFUND_PARTIAL__SUCCESS: {
      return {
        ...state,

        item: {
          ...state.item,

          refundPartial: {
            isLoading: false,
            isLoaded: true,
            error: null,
          },
        },
      };
    }
    case TRANSACTION_REFUND_PARTIAL__FAILURE: {
      return {
        ...state,

        item: {
          ...state.item,

          refundPartial: {
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    }

    case TRANSACTION_EXPORT_FILE__RESET: {
      return {
        ...state,
        exportFile: {
          ...initState.exportFile,
        },
      };
    }
    case TRANSACTION_EXPORT_FILE: {
      return {
        ...state,

        exportFile: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },

        sendFile: {
          ...initState.sendFile,
        },
      };
    }
    case TRANSACTION_EXPORT_FILE__SUCCESS: {
      return {
        ...state,
        exportFile: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case TRANSACTION_EXPORT_FILE__FAILURE: {
      return {
        ...state,

        exportFile: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    case TRANSACTION_SEND_FILE_TO_EMAILS__RESET: {
      return {
        ...state,
        sendFile: {
          ...initState.sendFile,
        },
      };
    }
    case TRANSACTION_SEND_FILE_TO_EMAILS: {
      return {
        ...state,

        sendFile: {
          data: {},
          isLoading: true,
          isLoaded: false,
          error: null,
        },

        exportFile: {
          ...initState.exportFile,
        },
      };
    }
    case TRANSACTION_SEND_FILE_TO_EMAILS__SUCCESS: {
      return {
        ...state,
        sendFile: {
          data: action.data,
          isLoading: false,
          isLoaded: true,
          error: null,
        },
      };
    }
    case TRANSACTION_SEND_FILE_TO_EMAILS__FAILURE: {
      return {
        ...state,

        sendFile: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    }

    default: {
      return state;
    }
  }
}
