/**
 *
 * @param {string} number
 * @param {(string | RegExp)} [separator = /\s/g]
 * @return {string}
 */
export const connectStringNumber = (number, separator = /\s/g) =>
  (typeof number === 'string' ? number.replace(separator, '') : String(number));

/**
 *
 * @param {(number | string)} number
 * @param { string }[separator = ' ']
 */
export const separateNumberByRank = (number, separator = ' ') => {
  const stringOfNumber = typeof number === 'string' ? number : String(number);
  return connectStringNumber(stringOfNumber).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};
