import React, { useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { TYPES, FIXBLOCK_TYPES, FILE_SLOTS } from 'settings';
import { useForm } from 'hooks';

import { FileAddOutlined } from '@ant-design/icons';

import FileList from './FileList';

import {
  Root, Content, Footer, UploadButton,
} from './style';

const NEW_BLOCK_ID = 'THIS_IT_NOT_THE_DROID_YOU_WERE_LOOKING_FOR';
const REQUIRED_FILE_LISTS = [FILE_SLOTS.first.name, FILE_SLOTS.second.name];

function UploadComponent({
  // connect
  requestTree,
  requestIsLoaded,
  formIsDisabled,
}) {
  const scrollableNode = useRef();
  const fixblock = useMemo(() => requestTree.find((e) => e.type === TYPES.MAIN), [requestTree]);
  const fixBlockBody = requestIsLoaded
    ? fixblock.data.find((e) => e.name === 'zeroFormBlockBody')
    : { data: [] };
  const filesGroups = useMemo(() => (requestIsLoaded && Array.isArray(fixBlockBody.data)
    ? fixBlockBody.data
    : []), [fixBlockBody.data, requestIsLoaded]);

  const fileGroupsNonEmpty = filesGroups.filter(
    (e) =>
      (Array.isArray(e.data)
        && e.data.filter((e) => e.type === FIXBLOCK_TYPES.DOCUMENT && e.value).length > 0)
      || (e.error && typeof e.error === 'string'),
  );

  const fileGroupsNonEmptyNames = fileGroupsNonEmpty.map((e) => e.name);

  const { form, setForm, updateForm } = useForm({
    ...filesGroups.reduce(
      (p, c) => ({
        ...p,
        [c.name]:
          REQUIRED_FILE_LISTS.includes(c.name)
          || fileGroupsNonEmptyNames.includes(c.name),
      }),
      Object.create(null),
    ),
    [NEW_BLOCK_ID]: false,
  });

  useEffect(() => {
    if (
      Object.keys(form).filter((e) => e !== NEW_BLOCK_ID).length !== filesGroups.length
    ) {
      setForm({
        ...filesGroups.reduce(
          (p, c) => ({
            ...p,
            [c.name]: form[c.name] || false,
          }),
          Object.create(null),
        ),
        [NEW_BLOCK_ID]: form[NEW_BLOCK_ID],
      });
    }
  }, [form, filesGroups, setForm]);

  function changeType(name, type) {
    updateForm({
      [name]: false,
      [type]: true,
    });
  }

  function handleAddNewFileList() {
    updateForm({
      [NEW_BLOCK_ID]: true,
    });
  }

  const typeList = filesGroups.map((e) => ({
    label: e.label,
    value: e.name,
    disabled: form[e.name],
  }));

  return (
    <Root>
      <Content ref={scrollableNode}>
        {Object.entries(form)
          .reduce((p, [k, v]) => [...p, ...(v ? [k] : [])], [])
          .map((block) => (
            <FileList
              key={block}
              name={block}
              typeList={typeList}
              changeType={changeType}
              formIsDisabled={formIsDisabled}
            />
          ))}
      </Content>
      <Footer>
        <UploadButton
          onClick={handleAddNewFileList}
          disabled={typeList.every((e) => e.disabled) || formIsDisabled}
        >
          <FileAddOutlined />
          {' '}
          Загрузить документы
        </UploadButton>
      </Footer>
    </Root>
  );
}

export { UploadComponent };

export default connect(
  (state) => ({
    requestTree: state.request.fieldsFlat,
    requestIsLoaded: state.request.fetch.isLoaded,
    formIsDisabled: state.request.formIsDisabled,
  }),
  null,
)(UploadComponent);
