import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Button from 'components/Button';

import { logout as logoutAction } from 'redux/auth/actions';
import { checkPrivilege } from 'helpers/roles';

import {
  Root, RootBlock, Nav, LinkStyled, LinkName,
} from './styles';

const ROUTES = [
  {
    path: '/app/management',
    title: 'управление',
    checkRights: (role) => checkPrivilege([
      role.MANAGEMENT.VIEW_USER,
      role.MANAGEMENT.VIEW_USER_INTERNAL,
      role.ORGANISATIONS.VIEW__ALL,
      role.ORGANISATIONS.VIEW_POINTS_OF_SALES,
    ]),
  },
  {
    path: '/app/payments',
    title: 'эквайринг',
    checkRights: (role) => checkPrivilege([
      role.PAYMENTS.VIEW,
      role.PAYMENTS.VIEW_ORGANISATION_BILLS,
      role.PAYMENTS.VIEW_MY_BILLS,
      role.ACQUIRING_AGREEMENT.VIEW,
    ]),
  },
  {
    path: '/app/leads',
    title: 'онлайн-заявка',
    checkRights: (role) => checkPrivilege([
      role.LEADS.NAVIGATION,
      role.LEADS.READ,
    ]),
  },
  {
    path: '/app/settings',
    title: 'настройки',
    checkRights: (role) => checkPrivilege(role.SETTINGS.NAVIGATION),
  },
  {
    path: '/tcm',
    title: 'ТСМ',
    checkRights: (role) => checkPrivilege([
      role.TCM.VIEW_CAMPAIGN,
      role.TCM.VIEW_OFFER,
    ]),
  },
];

function StartPage({
  // store
  role,

  // actions
  logout,
}) {
  const availableRoutes = ROUTES.filter((e) => e.checkRights(role));

  return (
    <Root>
      {availableRoutes.length === 1 ? (
        <Redirect
          to={{
            pathname: availableRoutes[0].path,
          }}
        />
      ) : (
        <Nav>
          {availableRoutes.map(
            ({ path, title }) => (
              <LinkStyled to={path}>
                <LinkName>{title}</LinkName>
              </LinkStyled>
            ),
          )}
        </Nav>
      )}

      <RootBlock>
        <Button size="small" shape="square" type="link" onClick={logout}>
          Выйти
        </Button>
      </RootBlock>
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    isTCMUser: state.auth.isTCMUser,
  }),
  {
    logout: logoutAction,
  },
)(StartPage);
