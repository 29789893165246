import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import AutoComplete, { Option } from 'components/AutoComplete';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { getOptionsListMono, mergeArrays } from 'hacks';
import { SCROLL_DURATION } from 'settings';

function AutocomplitField({
  name,
  type,
  label,
  options = [],
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const optionsArray = getOptionsListMono(options);
  const [list, setList] = useState(optionsArray);

  function onChange(v) {
    const filteredList = v.length > 0
      ? optionsArray.filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
      : optionsArray;

    setList(filteredList);
  }

  function onSelect(v) {
    const value = getFieldValue(name);
    const filteredList = value.length > 0
      ? optionsArray.filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
      : optionsArray;

    setList(filteredList);
  }

  function handleOnBlur() {
    const value = getFieldValue(name);
    if (
      ((optionsArray.find((e) => e.label === initialValue) || {}).value || '') !== value
    ) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue:
          (optionsArray.find((e) => e.label === initialValue) || {}).value || '',
      })(
        <AutoComplete
          name={name}
          placeholder={placeholder}
          area={area}
          onChange={onChange}
          onSelect={onSelect}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={focusableNode}
          size={size}
        >
          {list.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </AutoComplete>,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default React.memo(AutocomplitField, (prev, next) => prev.initialValue === next.initialValue);
