import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useForm } from 'hooks';
import { checkPrivilege } from 'helpers/roles';
import moment from 'moment';
import API from 'services';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';
import {
  fetchList as fetchListAction,
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
  getFileUrl as getFileUrlAction,
  getFileUrlReset as getFileUrlResetAction,
} from 'redux/report/actions';

import {
  fetchList as fetchCompanyListAction,
} from 'redux/company/actions';

import {
  fetchList as fetchPointOfSalesListActions,
} from 'redux/dealerCenter/actions';
import { initialFilters } from 'redux/report/reducer';

import { DATE_FORMAT } from 'settings';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import TableHeader, { FILTER_TYPES } from 'components/TableHeader';

import {
  Count,
  EmptyMessage,
  ErrorMessage,
  ItemsContainer,
  ItemsContent,
  ItemsContentContainer,
  LoadingMessage,
} from 'containers/App/Management/Users/List/style';

import FormReportModal from '../FormReportModal';
import {
  Root,
  Header,
  HeaderTitle,
  Title,
  Company,
  CompanyContainer,
  Inn,
  RightHeaderSide,
  ButtonDownload,
  ButtonDeleteContainer,
  IconContainer,
} from './style';

import {
  formatToTable,
  ACTIONS,
  reducer,
  initialState,
  REPORTS_TYPES,
  REPORTS_TYPES_TABLE,
} from './data';

const showTotal = () => '';
const scroll = { y: 'calc(100vh - 460px)' };

const renderItems = (array) => (
  <ItemsContainer>
    {Array.isArray(array) && array.length === 1 && array[0]}
    {Array.isArray(array) && array.length > 1 && (
      <ItemsContentContainer content={(
        <ItemsContent>
          {array.map((item, index) => index !== 0 && (<div>{item}</div>))}
        </ItemsContent>
      )}
      >
        {array[0]}
        <Count>
          {' '}
          {array.length > 9 ? '9+' : array.length - 1 }
          {' '}
        </Count>
      </ItemsContentContainer>
    )}
  </ItemsContainer>
);

const downloadURL = (fileUrl) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.target = '_blank';
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();
};

function List({
  // connect
  role,

  list,
  sorting,
  isLoading,
  error,
  pagination,

  companyList,
  companyListIsLoaded,
  companyListIsLoading,

  pointOfSalesList,
  pointOfSalesIsLoaded,
  pointOfSalesIsLoading,

  getFileUrlData,
  getFileUrlIsLoaded,
  getFileUrlIsLoading,
  getFileUrlError,

  syncReportsIsLoaded,

  monthlySummaryOperationsIsLoaded,

  periodOperationsIsLoaded,

  reportReconciliationIsLoaded,

    // actions
  setTablePage,
  setSorting,
  setFilters,
  resetTable,
  setBreadcrumbs,
  fetchCompanyList,
  fetchPointOfSalesList,
  getFileUrl,
  getFileUrlReset,
}) {
  const { form, updateForm } = useForm(initialFilters);
  const [page, setPage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayNullable, setDisplayNullable] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [pointOfSalesOptions, setPointOfSalesOptions] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [downloadId, setDownloadId] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    setIsMounted(true);
    return () => getFileUrlReset();
  }, [getFileUrlReset]);

  useEffect(() => {
    if (isMounted) {
      setBreadcrumbs([
        { level: 0, name: 'Эквайринг', link: '/app/payments' },
        { level: 1, name: 'Отчеты', link: '/app/payments/reports/list' },
      ]);
    }
  }, [isMounted, setBreadcrumbs]);

  useEffect(() => {
    setPage(pagination.page);
  }, [pagination]);

  useEffect(() => {
    setFilters({
      filters: {
        nullableReports: displayNullable,
        ...form,
      },
    });

    return () => resetTable();
    // eslint-disable-next-line
  }, [form, resetTable, setFilters, displayNullable]);

  useEffect(() => {
    if (syncReportsIsLoaded) {
      setFilters({
        filters: {
          nullableReports: displayNullable,
          ...form,
        },
      });
    }
  }, [
    form,
    setFilters,
    displayNullable,
    syncReportsIsLoaded,
  ]);

  useEffect(() => {
    if (monthlySummaryOperationsIsLoaded) {
      setFilters({
        filters: {
          nullableReports: displayNullable,
          ...form,
        },
      });
    }
  }, [
    form,
    setFilters,
    displayNullable,
    monthlySummaryOperationsIsLoaded,
  ]);

  useEffect(() => {
    if (periodOperationsIsLoaded) {
      setFilters({
        filters: {
          nullableReports: displayNullable,
          ...form,
        },
      });
    }
  }, [
    form,
    setFilters,
    displayNullable,
    periodOperationsIsLoaded,
  ]);

  useEffect(() => {
    if (reportReconciliationIsLoaded) {
      setFilters({
        filters: {
          nullableReports: displayNullable,
          ...form,
        },
      });
    }
  }, [
    form,
    setFilters,
    displayNullable,
    reportReconciliationIsLoaded,
  ]);

  useEffect(() => {
    if (checkPrivilege(role.ORGANISATIONS.VIEW__ALL)) {
      fetchCompanyList({
        withPagination: false,
      });
    }
  }, [fetchCompanyList, role]);

  useEffect(() => {
    dispatch({ type: ACTIONS.FETCH });
    API.report
      .getReportSectors()
      .then((results) => {
        const { data: { sectorList } } = results;

        if (Array.isArray(sectorList) && (sectorList.length > 0)) {
          const formatSectorList = sectorList.map((item) => (
            { value: item.sector, label: item.sector }));
          dispatch({ type: ACTIONS.FETCH_SUCCESS, list: formatSectorList });
        } else {
          throw new Error('Возникла ошибка при получении списка секторов');
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
      });
  }, []);

  useEffect(() => {
    if (companyListIsLoaded && (companyList.length > 0)) {
      setCompaniesOptions(formatToTable(companyList));
    }
  }, [companyListIsLoaded, companyList]);

  useEffect(() => {
    if (companyListIsLoaded) {
      fetchPointOfSalesList({
        withPagination: false,
      });
    }
  }, [fetchPointOfSalesList, companyListIsLoaded]);

  useEffect(() => {
    if (pointOfSalesIsLoaded && (pointOfSalesList.length > 0)) {
      setPointOfSalesOptions(formatToTable(pointOfSalesList));
    }
  }, [pointOfSalesIsLoaded, pointOfSalesList]);

  useEffect(() => {
    if (getFileUrlIsLoaded && getFileUrlData.url) {
      downloadURL(getFileUrlData.url);
    }
  }, [getFileUrlData, getFileUrlIsLoaded]);

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  const handleDisplayNullable = () => {
    setDisplayNullable(!displayNullable);
  };

  const handleGetUrl = (id) => {
    getFileUrl({ id });
    setDownloadId(id);
  };

  const COLUMNS = [
    {
      key: 'icon',
      title: () =>
        TableHeader({
          title: '',
        }),

      render: ({ sendDataTime = '' }) => (
        <IconContainer>
          {sendDataTime && <MailOutlined />}
        </IconContainer>
      ),
      width: 50,
    },
    {
      key: 'legalEntityName',
      title: () =>
        TableHeader({
          title: 'Организация',
          // sortBy: 'legalEntityName',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_WITH_FILTER,
          filterName: 'legalEntityName',
          filterValue: form.legalEntityName,
          filterOnChange: updateForm,
          filterOptions: companiesOptions,
          filterIsLoading: companyListIsLoading,
          hasDefault: false,
        }),

      render: ({ legalEntityName = [], legalEntityInn = [] }) => (
        <CompanyContainer>
          <Company>
            {renderItems(legalEntityName)}
          </Company>
          <Inn>
            {renderItems(legalEntityInn)}
          </Inn>
        </CompanyContainer>

      ),
      width: 240,
    },
    {
      key: 'subsidisryName',
      title: () =>
        TableHeader({
          title: 'Точка продаж',
          // sortBy: 'subsidisryName',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_WITH_FILTER,
          filterName: 'subsidisryName',
          filterValue: form.subsidisryName,
          filterOnChange: updateForm,
          filterOptions: pointOfSalesOptions,
          hasDefault: false,
          filterIsLoading: pointOfSalesIsLoading,
          disabled: pointOfSalesIsLoading,
        }),
      render: ({ subsidisryName = [] }) => renderItems(subsidisryName),
      width: 240,
      align: 'left',
    },
    {
      key: 'sectors',
      title: () =>
        TableHeader({
          title: 'Сектор',
          // sortBy: 'sector',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterName: 'sectors',
          filterValue: form.sectors,
          filterIsLoading: state.isLoading,
          disabled: state.isLoading,
          filterOnChange: updateForm,
          filterOptions: state.list,
          filterOptionValue: 'value',
        }),
      render: ({ sectors = [] }) => renderItems(sectors),
      width: 200,
      align: 'left',
    },
    {
      key: 'type',
      title: () =>
        TableHeader({
          title: 'Тип',
          // sortBy: 'reportType',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterOptionValue: 'value',
          filterName: 'reportType',
          filterValue: form.requisites,
          filterOnChange: updateForm,
          filterOptions: REPORTS_TYPES,
        }),
      render: ({ reportType }) => (REPORTS_TYPES_TABLE[reportType]),
      width: 200,
      align: 'left',
    },
    {
      key: 'data',
      title: () =>
        TableHeader({
          title: 'Дата',
          // sortBy: 'createdAt',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.PERIOD,
          filterName: ['periodFrom', 'periodTo'],
          filterValue: [form.periodFrom, form.periodTo],
          filterOnChange: updateForm,
        }),
      render: ({ period }) => (<span>{period.map((item) => moment.utc(item).format(DATE_FORMAT)).join(' - ')}</span>),
      width: 200,
      align: 'left',
    },
    {
      key: 'amountToBeTransferred',
      title: () =>
        TableHeader({
          title: 'Сумма',
          sortBy: 'amountToBeTransferred',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.RANGE,
          filterName: ['amountToBeTransferredFrom', 'amountToBeTransferredTo'],
          filterValue: [form.amountToBeTransferredFrom, form.amountToBeTransferredTo],
          filterOnChange: updateForm,
          filterFormat: 'currency',
        }),
      render: ({ amountToBeTransferred = 0 }) => (
        typeof amountToBeTransferred === 'number'
          ? (
            (amountToBeTransferred / 100).toLocaleString('ru')
          ) : (
            'amountToBeTransferred invalid type '
          )
      ),
      width: 220,
      align: 'left',
    },
    {
      key: 'fileName',
      title: () =>
        TableHeader({
          title: 'Файл',
          // sortBy: 'fileName',
          // sorting,
          // onClick: setSorting,
          filterType: FILTER_TYPES.INPUT,
          filterName: 'fileName',
          filterValue: form.fileName,
          filterOnChange: updateForm,
        }),
      render: ({ fileName }) => (fileName),
      width: 220,
      align: 'left',
    },
    {
      key: 'action',
      title: () =>
        TableHeader({
          title: 'Действие',
        }),
      render: ({ id, availableForDownload }) => availableForDownload && (
        <ButtonDeleteContainer>
          <ButtonDownload
            onClick={() => handleGetUrl(id)}
            loading={downloadId === id && getFileUrlIsLoading}
            disable={getFileUrlIsLoading}
          >
            Скачать
          </ButtonDownload>
          {downloadId === id && getFileUrlError && <ErrorMessage>{getFileUrlError}</ErrorMessage>}
        </ButtonDeleteContainer>

      ),
      width: 140,
      align: 'center',
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <RightHeaderSide>
            <Title>История отчетов</Title>
            <Checkbox
              onChange={handleDisplayNullable}
            >
              отображать нулевые отчеты
            </Checkbox>
          </RightHeaderSide>
          {checkPrivilege(role.REPORTS.GENERATE_REPORTS) && (
            <Button
              size="medium"
              type="primary"
              shape="square"
              onClick={openModal}
            >
              Сформировать отчет
            </Button>
          )}
        </HeaderTitle>
      </Header>
      <FormReportModal
        close={closeModal}
        visible={isModalOpen}
      />
      <Table
        rowKey={({ id }) => id}
        columns={COLUMNS}
        rowClassName={(row) => (
          (row.summaryData && row.summaryData.operationCount === 0)
            ? 'nullable' : ''
        )}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect((state) => ({
  role: state.auth.role,

  list: state.report.collection.list,
  sorting: state.report.collection.sorting,
  filters: state.report.collection.filters,
  search: state.report.collection.search,
  isLoaded: state.report.collection.isLoaded,
  isLoading: state.report.collection.isLoading,
  error: state.report.collection.error,
  pagination: state.report.collection.pagination,

  companyList: state.company.collection.list,
  companyListIsLoaded: state.company.collection.isLoaded,
  companyListIsLoading: state.company.collection.isLoading,

  pointOfSalesList: state.dealerCenter.collection.list,
  pointOfSalesIsLoaded: state.dealerCenter.collection.isLoaded,
  pointOfSalesIsLoading: state.dealerCenter.collection.isLoading,

  getFileUrlData: state.report.getFileUrl.data,
  getFileUrlIsLoaded: state.report.getFileUrl.isLoaded,
  getFileUrlIsLoading: state.report.getFileUrl.isLoading,
  getFileUrlError: state.report.getFileUrl.error,

  syncReportsIsLoaded: state.report.syncReports.isLoaded,

  monthlySummaryOperationsIsLoaded: state.report.monthlySummaryOperations.isLoaded,

  periodOperationsIsLoaded: state.report.periodOperations.isLoaded,

  reportReconciliationIsLoaded: state.report.reportReconciliation.isLoaded,
}),
{
  fetchList: fetchListAction,
  setTablePage: setTablePageAction,
  setSorting: setSortingAction,
  setFilters: setFiltersAction,
  setSearch: setSearchAction,
  resetTable: resetTableAction,

  fetchCompanyList: fetchCompanyListAction,
  fetchPointOfSalesList: fetchPointOfSalesListActions,

  setBreadcrumbs: setBreadcrumbsAction,

  getFileUrl: getFileUrlAction,
  getFileUrlReset: getFileUrlResetAction,
})(List);
