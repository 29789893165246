import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
		margin-bottom: 30px;
`;

export const InfoContainer = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: minmax(150px, 250px) 1fr;
  grid-gap: 25px 20px;
		align-items: center;
  margin-bottom: 75px;
`;

export const InfoProp = styled.span`
  font-family: "Toyota Type", sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #282830;
`;

export const InfoValue = styled.span`
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #282830;
`;

export const DocumentsContainer = styled.div`
		margin-bottom: 81px;
`;

export const DocumentContainerTitle = styled.h2`
  font-family: "Toyota Type", sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
		font-weight: bold;
		margin-bottom: 27px;
`;

export const DocumentFormImageContainer = styled.div`
    border: 1px dashed #282830;
				cursor: pointer;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 6px 15px;
    display: grid;
    grid-template-columns: minmax(85px, 130px) 1fr;
    grid-column-gap: 22px;
    height: 165px;
    align-items: center;

    &:focus{
        border: 1px dashed #282830;
        outline: none;
    }
    &:hover{
        border: 1px dashed #282830;
        outline: none;
    }
    &:active{
        border: 1px dashed #282830;
        outline: none;
    }
`;

export const DocumentFileDownloadIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #858585;
  
    svg{
      color: #D9DCDD;
    }
`;

export const DocumentFileDocumentIcon = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
				svg, img {
						max-width: 105px;
				}
`;

export const DocumentFileDownloadText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
		  text-align: center;
`;

export const DownloadedDocumentsContainer = styled.div`
    display: grid;
   	grid-template-columns: repeat(3,319px);
				grid-gap: 44px 81px;	
		  margin-bottom: 51px;
`;

export const DownloadedDocumentContainer = styled.div`
`;

export const DownloadedDocument = styled.div`
  border: 1px dashed #282830;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 6px 15px;
  display: grid;
  position: relative;
  grid-template-columns: minmax(85px, 200px) 1fr;
  grid-column-gap: 22px;
  height: 165px;
  align-items: center;
		margin-bottom: 15px;
`;

export const Delete = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
  align-items: center;
  color: #858585;
  margin-top: -80px;
		cursor: pointer;
`;

export const DocumentName = styled.a`
  text-decoration: none;
  font-family: "Toyota Type", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #282830;
  word-break: break-word;
`;

export const StyledDeadlineText = styled.div`
  margin: 8px 0;
  min-width: 270px;

  font-family: "Toyota Type", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: #6A7181;
  text-align: center;
`;

export const LoadingWrapper = styled.div`
  margin: 8px 0;
  min-width: 270px;
  text-align: center;

  > div {
    margin: 0 auto;
  }
`;

export const Error = styled.p``;

export const ErrorMessage = styled.span`
  color: red;
`;

export const SMSLoadingWrapper = styled.div`
  margin: 8px 0;
  min-width: 270px;

  text-align: center;

  > div {
    margin: 0 auto;
  }
`;

export const ControlsContainer = styled.div`
		display: grid;
		grid-template-columns: max-content minmax(150px, 270px);
		grid-column-gap: 81px;
		margin-bottom: 81px;

    .ant-btn.ant-btn-square.ant-btn-primary {
      min-width: 270px;
    }
`;

export const DropZoneContainers = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 319px) minmax(200px, 319px);
  gap: 38px
`;

export const Download = styled.a`
  display: block;
  text-align: right;
  position: absolute;
  bottom: 25px;
  right: 25px;
  color: #858585;
  
  &:hover{
    color: #858585;
  }
  
  svg{
    width: 18px;
    height: 12px;
    color: #1ABD00;
    padding-left: 5px;
  }
`;
