import React from 'react';

import { ModalStyled } from './style';

const defaultProps = {
  footer: null,
};

const ModalDefault = (props) => <ModalStyled {...defaultProps} {...props} />;

export default ModalDefault;
