import React from 'react';
import { Collapse as CollapseAntD } from 'antd';
import { WithStyle } from './style';

const { Panel } = CollapseAntD;

const defaultProps = {};

const CollapseStyled = WithStyle((props) => (
  <CollapseAntD {...defaultProps} {...props} />
));

function Collapse({ title, children }) {
  return (
    <CollapseStyled defaultActiveKey="1">
      <Panel header={title} key="1">
        {children}
      </Panel>
    </CollapseStyled>
  );
}

export default Collapse;
