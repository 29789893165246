import { LoadingOutlined } from '@ant-design/icons';
import { ErrorContainer } from 'containers/App/TCM/ClientsTable/style';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { connect } from 'react-redux';

import {
  Title,
  Count,
  Item,
  List,
  Separator,
  SubTitle,
} from 'components/tcm/Sidebars/style';
import { fetchStatuses as fetchOfferStatusesAction } from 'redux/tcmOffer/actions';
import { OfferStatus } from 'tcm/offer/data';

const getMenuDataFromStatuses = (statuses) => {
  const all = statuses.find((status) => status.name === 'ALL');
  const allData = all ? [
    {
      label: 'Все',
      count: all.count,
      link: '/tcm/campaign/users/list',
      code: all.name,
    },
  ] : [];

  const calling = statuses.find((status) => status.name === OfferStatus.Calling);
  const callingData = calling ? [
    {
      label: 'В работе',
      count: calling.count,
      link: `/tcm/campaign/users/list?userFilter=${calling.name}`,
      code: calling.name,
    },
  ] : [];

  const waitConfirm = statuses.find((status) => status.name === OfferStatus.WaitConfirm);
  const waitConfirmData = waitConfirm ? [
    {
      label: 'Ожидают подтверждения',
      count: waitConfirm.count,
      link: `/tcm/campaign/users/list?userFilter=${waitConfirm.name}`,
      code: waitConfirm.name,
    },
  ] : [];

  const revision = statuses.find((status) => status.name === OfferStatus.Revision);
  const revisionData = revision ? [
    {
      label: 'Доработка',
      count: revision.count,
      link: `/tcm/campaign/users/list?userFilter=${revision.name}`,
      code: revision.name,
    },
  ] : [];

  const confirmed = statuses.find((status) => status.name === OfferStatus.Confirmed);
  const confirmedData = confirmed ? [
    {
      label: 'Подтвержденные',
      count: confirmed.count,
      link: `/tcm/campaign/users/list?userFilter=${confirmed.name}`,
      code: confirmed.name,
    },
  ] : [];

  const agreeing = statuses.find((status) => status.name === OfferStatus.Agreeing);
  const agreeingData = agreeing ? [
    {
      label: 'Согласившиеся',
      count: agreeing.count,
      link: `/tcm/campaign/users/list?userFilter=${agreeing.name}`,
      code: agreeing.name,
    },
  ] : [];

  const refused = statuses.find((status) => status.name === OfferStatus.Refused);

  const refusedData = refused ? [
    {
      label: 'Отказавшиеся',
      count: refused.count,
      link: `/tcm/campaign/users/list?userFilter=${refused.name}`,
      code: refused.name,
    },
  ] : [];

  return [
    ...allData,
    ...callingData,
    ...waitConfirmData,
    ...revisionData,
    ...confirmedData,
    ...((agreeing || refused) ? [{ label: 'separator' }] : []),
    ...agreeingData,
    ...refusedData,
  ];
};

const NavItem = ({
  // passed
  title,
  statusList,
  subTitle,
}) => {
  const location = useLocation();
  const { userFilter = '' } = queryString.parse(location.search);
  const statuses = getMenuDataFromStatuses(statusList);
  const activeFilter = statuses.findIndex((status) => userFilter === status.code);
  const isDefault = location.pathname === '/tcm/campaign/users/list';

  const activeFilterId = activeFilter > -1 ? activeFilter : isDefault ? 0 : null;

  return (
    <List>
      <Title>
        {title}
      </Title>
      {subTitle && (
        <SubTitle>
          {subTitle}
        </SubTitle>
      )}
      {statuses?.map((item, i) => ((item.label === 'separator') ? (
        <Separator key={item.label} />
      ) : (
        <dd key={item.label}>
          <Item to={item.link} active={i === activeFilterId}>
            {item.label}
            {' '}
            <Count>{item.count}</Count>
          </Item>
        </dd>
      )))}
    </List>
  );
};

const EmployeeClients = ({
  // passed
  subTitle,

  // store
  statuses,
  isLoading,
  isLoaded,
  error,

  setStatusIsLoaded,
  employeesOfferIsLoaded,

  // actions
  fetchStatuses,
}) => {
  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  useEffect(() => {
    if (setStatusIsLoaded) {
      fetchStatuses();
    }
  }, [fetchStatuses, setStatusIsLoaded]);

  useEffect(() => {
    if (employeesOfferIsLoaded) {
      fetchStatuses();
    }
  }, [fetchStatuses, employeesOfferIsLoaded]);

  return (
    <>
      {isLoaded && (
        <NavItem
          subTitle={subTitle}
          title="Клиенты"
          statusList={statuses}
        />
      )}

      {
        isLoading && <LoadingOutlined />
      }

      {
        Boolean(error) && <ErrorContainer>error</ErrorContainer>
      }
    </>
  );
};

export default connect(
  (state) => ({
    // campaign
    statuses: state.tcmOffer.statuses.list,
    isLoading: state.tcmOffer.statuses.isLoading,
    isLoaded: state.tcmOffer.statuses.isLoaded,
    error: state.tcmOffer.statuses.error,

    setStatusIsLoaded: state.tcmOffer.statusSet.isLoaded,

    employeesOfferIsLoaded: state.tcmOffer.employee.isLoaded,
  }),
  {
    fetchStatuses: fetchOfferStatusesAction,
  },
)(EmployeeClients);
