import styled, { css } from 'styled-components';
import { key } from 'styled-theme';
import { Button, Select, Typography } from 'antd';

import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Root = styled.div`
  border: 1px solid #c9c9c9;
  padding: 1rem;
  border-radius: 5px;

  & + & {
    margin-top: 1rem;
  }

  & .ant-upload-list {
    display: none; // hide file list
  }
`;

const Header = styled.header`
  padding: 0 0 1rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -0.5rem;
`;

const Footer = styled.footer`
  margin-top: 1rem;
`;

const SelectStyled = styled(Select)`
  .ant-select-selection--single {
    background: ${key('input.background')};
    border-color: ${key('input.borderColor')};
    color: ${key('input.color')};
    font-size: 0.8rem;

    &:focus {
      ${key('input.focus')};
    }
  }
  :not(.ant-select-disabled) > .ant-select-focused .ant-select-selection,
  :not(.ant-select-disabled) > .ant-select-open .ant-select-selection,
  :not(.ant-select-disabled) > .ant-select-selection:focus,
  :not(.ant-select-disabled) > .ant-select-selection:active {
    ${key('input.focus')}
  }
  :not(.ant-select-disabled) > .ant-select-selection:hover {
    ${key('input.hover')}
  }
  .ant-select-selection-selected-value {
    color: ${key('input.color')};
  }
`;

const FileItem = styled.div`
  width: 8rem;
  display: grid;
  grid-template-rows: 6.6rem 3rem;
  grid-template-columns: 100%;

  margin: 0.5rem;
  border-radius: 4px;
  border: 1px solid #b7b7b7;

  ${({ error }) =>
    error
    && css`
      border-color: red;
    `}
`;

const FileHeader = styled.button`
  border: none;
  background: #fff;
  text-align: center;
  padding: 10px 10px 0 10px;
  background: rgba(0, 0, 0, 0.1);
  transition: background ease-in 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const FileIcon = styled(FileImageOutlined)`
  font-size: calc(4rem / 1.5);
  opacity: 0.8;
`;

const FileTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.5rem;
  font-size: smaller;
`;

const FileName = styled(Title).attrs(() => ({ level: 4 }))`
  h4&.ant-typography {
    font-weight: bold;
    font-size: 0.8rem;
    color: #565656;
  }
`;

const FileFooter = styled.div`
  padding: 10px;
`;

const FileDelete = styled(Button)`
  width: 100%;
  height: 100%;
`;

const FileError = styled.div`
  width: 8rem;
  color: red;
  font-size: 0.8rem;
  margin: -0.5rem 0.5rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FileEmpty = styled(Button)`
  width: 8rem;
  height: calc(6.6rem + 3rem);
  border: 1px solid #d6d6d6;
  font-size: calc(4rem / 1.5);
  margin: 0.5rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  color: #b7b7b7;
  border-color: currentColor;
  transition: color ease-in 0.2s, border-color ease-in 0.2s, opacity ease-in 0.2s;
  transition: background ease-in 0.2s;

  &:hover,
  &:focus {
    color: #b7b7b7;
    border-color: #b7b7b7;
    background: rgba(0, 0, 0, 0.01);
  }
`;

const FileEmptyText = styled.span`
  font-size: 0.8rem;
`;

const FileLoader = styled(LoadingOutlined)`
  font-size: calc(4rem / 1.5);
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileLoaderText = styled.span`
  padding: 6px;
  font-size: 0.8rem;
  text-align: center;
`;

export {
  Root,
  Header,
  ErrorMessage,
  Content,
  Footer,
  SelectStyled,
  FileItem,
  FileHeader,
  FileIcon,
  FileName,
  FileTitle,
  FileFooter,
  FileDelete,
  FileError,
  FileEmpty,
  FileEmptyText,
  FileLoader,
  FileLoaderText,
};
