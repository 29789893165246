import styled from 'styled-components';
import { Form } from 'antd';

export const Root = styled(Form)`
  && {
    max-width: 80rem;
    min-width: 80vw;
    max-height: 80vh;
    overflow: hidden;
    margin: 10vh 5% 0;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #cecece;

    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "main aside"
      "succes succes"
      "footer footer";
  }

  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
  }

  & .ant-form-item-label > label {
    height: 1.2rem;
  }

  & .ant-checkbox-group {
    display: flex;
    flex-flow: column nowrap;
  }
`;

export const Main = styled.main`
  grid-area: main;
  padding: 2rem 1rem 1rem 2rem;
  overflow: auto;
`;

export const MainTitle = styled.h1`
  font-family: ToyotaDisplay;
  font-size: 24px;
  line-height: 30px;
  color: #000;
`;

export const MainSubtitle = styled.h2`
  font-family: ToyotaDisplay;
  font-size: 18px;
  line-height: 25px;
  color: #000;
`;

export const MainDivider = styled.hr`
  height: 1px;
  margin: 0 0 1rem 0;
  background-color: #cecece;
  border: none;
`;

export const Aside = styled.aside`
  grid-area: aside;
  display: flex;
  flex-flow: column nowrap;

  border-left: 1px solid #cecece;
  padding: 2rem 2rem 1rem 1rem;

  overflow: hidden;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
`;

export const AsideTitle = styled.h3`
  font-family: ToyotaDisplay;
  font-size: 24px;
  line-height: 30px;
  color: #000;
`;

export const UserList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  justify-content: space-between;

  height: 100%;
  margin: 0;
  padding: 1rem;
  border: 1px solid #cecece;
  border-radius: 5px;
  overflow: auto;
  list-style: none;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, calc(50% - 1rem)));
  }
`;

export const User = styled.li`
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
`;

export const UserHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const UserTitle = styled.h4`
  margin: 0;
  font-family: ToyotaDisplay;
  font-size: 20px;
  line-height: 27px;
  color: #595e68;
`;

export const UserDelete = styled.button`
  height: 2rem;
  padding: 0 2rem;
  border-radius: 5px;
  border: 1px solid #e50000;
  background: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in;

  &:hover {
    box-shadow: inset 0px 0px 0px 2px #ce0000;
  }
  &:focus {
    outline: none;
    box-shadow: inset 0px 0px 0px 2px #ce0000;
  }
`;

export const Footer = styled.footer`
  grid-area: footer;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid #cecece;
`;

export const ErrorMessage = styled.div`
  margin-top: 1rem;

  &:empty {
    margin-top: 0;
  }
`;

export const Result = styled.div`
  grid-area: succes;

  text-align: center;
  padding: 2rem;
`;

export const ResultTitle = styled.h1``;
