import React from 'react';

import { Root, LoaderText, LoaderStyled } from './style';

function Loader({ text, height = 400 }) {
  return (
    <Root height={height}>
      <LoaderStyled />
      <LoaderText>{text}</LoaderText>
    </Root>
  );
}

export default Loader;
