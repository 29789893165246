/* exportReport */
export const REPORT_EXPORT = 'REPORT_EXPORT';
export const REPORT_EXPORT__SUCCESS = 'REPORT_EXPORT__SUCCESS';
export const REPORT_EXPORT__FAILURE = 'REPORT_EXPORT__FAILURE';
export const REPORT_EXPORT__RESET = 'REPORT_EXPORT__RESET';
export const tcmReportExport = ({
  startAt,
  finishAt,
  campaignIds,
}) => ({
  type: REPORT_EXPORT,
  startAt,
  finishAt,
  campaignIds,
});
export const tcmReportExportReset = () => ({ type: REPORT_EXPORT__RESET });

export const SAVE_REPORT_TEMPLATE = 'TCM_SAVE_REPORT_TEMPLATE';
export const SAVE_REPORT_TEMPLATE__SUCCESS = 'TCM_SAVE_REPORT_TEMPLATE__SUCCESS';
export const SAVE_REPORT_TEMPLATE__FAILURE = 'TCM_SAVE_REPORT_TEMPLATE__FAILURE';
export const SAVE_REPORT_TEMPLATE__RESET = 'TCM_SAVE_REPORT_TEMPLATE__RESET';
export const saveReportTemplate = ({ reportType, name, params }) => ({
  type: SAVE_REPORT_TEMPLATE,
  reportType,
  name,
  params,
});
export const saveReportTemplateReset = () => ({
  type: SAVE_REPORT_TEMPLATE__RESET,
});

export const GET_TCM_REPORT_SETTINGS = 'GET_TCM_REPORT_SETTINGS';
export const GET_TCM_REPORT_SETTINGS__SUCCESS = 'GET_TCM_REPORT_SETTINGS__SUCCESS';
export const GET_TCM_REPORT_SETTINGS__FAILURE = 'GET_TCM_REPORT_SETTINGS__FAILURE';
export const GET_TCM_REPORT_SETTINGS__RESET = 'GET_TCM_REPORT_SETTINGS__RESET';
export const getTcmReportSettings = () => ({ type: GET_TCM_REPORT_SETTINGS });
export const getTcmReportSettingsReset = () => ({ type: GET_TCM_REPORT_SETTINGS__RESET });

export const GET_TCM_REPORT_LIST = 'GET_TCM_REPORT_LIST';
export const GET_TCM_REPORT_LIST__SUCCESS = 'GET_TCM_REPORT_LIST__SUCCESS';
export const GET_TCM_REPORT_LIST__FAILURE = 'GET_TCM_REPORT_LIST__FAILURE';
export const getTcmReportList = () => ({ type: GET_TCM_REPORT_LIST });
