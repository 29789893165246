import React from 'react';

import {
  Route,
  Redirect,
  Switch,
  useParams,
} from 'react-router-dom';

import List from './List';
import Item from './Item';

function Contracts() {
  const { companyId } = useParams();

  return (
    <Switch>
      <Route exact path="/app/management/companies/:companyId/contracts">
        <Redirect to={`/app/management/companies/${companyId}/contracts/list`} />
      </Route>

      <Route path="/app/management/companies/:companyId/contracts/list" component={List} />
      <Route path="/app/management/companies/:companyId/contracts/:contractId" component={Item} />
      {/* <Route path="/app/management/dealerCenters/:dealerCenterId/edit" component={Item} /> */}
    </Switch>
  );
}

export default Contracts;
