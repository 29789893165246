import API from 'services';
import { extractErrorAndCode } from 'hacks';

import {
  all, takeLatest, put, fork, call, select, cancel, take,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import {
  PAYMENT_FETCH_LIST,
  PAYMENT_FETCH_LIST__SUCCESS,
  PAYMENT_FETCH_LIST__FAILURE,
  PAYMENT_FETCH_LIST__CANCEL_REQUEST,
  PAYMENT_FETCH_LIST__SET_LOADING,
  PAYMENT_FETCH_LIST__SET_PAGINATION,
  PAYMENT_FETCH_LIST__SET_SEARCH,
  PAYMENT_FETCH_LIST__SET_PAGE,
  PAYMENT_FETCH_LIST__SET_SORTING,
  PAYMENT_FETCH_LIST__SET_FILTER,
  PAYMENT_FETCH_ITEM,
  PAYMENT_FETCH_ITEM__SUCCESS,
  PAYMENT_FETCH_ITEM__FAILIRE,
  PAYMENT_CREATE,
  PAYMENT_CREATE__SUCCESS,
  PAYMENT_CREATE__FAILIRE,
  PAYMENT_ANNUL,
  PAYMENT_ANNUL__SUCCESS,
  PAYMENT_ANNUL__FAILIRE,

  PAYMENT_SECTOR_FETCH_LIST,
  PAYMENT_SECTOR_FETCH_LIST__SUCCESS,
  PAYMENT_SECTOR_FETCH_LIST__FAILURE,
} from './actions';

const getState = (state) => state.payment;
const toCoins = (value) => parseFloat(value) * 100;

function* _fetchList() {
  const {
    collection: {
      search, page, filters, sorting,
    },
  } = yield select(getState);

  try {
    yield put({ type: PAYMENT_FETCH_LIST__SET_LOADING });
    const response = yield call(API.payment.fetchList, {
      search,
      page,
      filters: {
        ...filters,
        registerSumFrom: filters.registerSumFrom ? toCoins(filters.registerSumFrom) : '',
        registerSumTo: filters.registerSumTo ? toCoins(filters.registerSumTo) : '',
        actualSumFrom: filters.actualSumFrom ? toCoins(filters.actualSumFrom) : '',
        actualSumTo: filters.actualSumTo ? toCoins(filters.actualSumTo) : '',
      },
      sorting,
    });

    // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
    if (
      Array.isArray(response.data)
      || (response.data && Array.isArray(response.data.paymentResponseList))
    ) {
      yield put({
        type: PAYMENT_FETCH_LIST__SUCCESS,
        pagination: response.pagination,
        list: response.data.paymentResponseList || [],
      });
    } else {
      const { error, code } = extractErrorAndCode(response);
      yield put({ type: ON_ERROR, errorCode: code });
      throw new Error(error);
    }
  } catch (error) {
    yield put({ type: PAYMENT_FETCH_LIST__FAILURE, error: error.message });
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(PAYMENT_FETCH_LIST__CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(PAYMENT_FETCH_LIST, function* () {
    yield call(fetchSync);
  });
}

export function* fetchListOnSearch() {
  yield takeLatest(PAYMENT_FETCH_LIST__SET_SEARCH, function* () {
    yield put({ type: PAYMENT_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: PAYMENT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: PAYMENT_FETCH_LIST });
  });
}

export function* fetchListOnPageChange() {
  yield takeLatest(PAYMENT_FETCH_LIST__SET_PAGE, function* (action) {
    yield put({ type: PAYMENT_FETCH_LIST__SET_PAGINATION, page: action.page });
    yield put({ type: PAYMENT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: PAYMENT_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(PAYMENT_FETCH_LIST__SET_SORTING, function* () {
    yield put({ type: PAYMENT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: PAYMENT_FETCH_LIST });
  });
}

export function* fetchListOnFilters() {
  yield takeLatest(PAYMENT_FETCH_LIST__SET_FILTER, function* () {
    yield put({ type: PAYMENT_FETCH_LIST__SET_PAGINATION, page: 1 });
    yield put({ type: PAYMENT_FETCH_LIST__CANCEL_REQUEST });
    yield put({ type: PAYMENT_FETCH_LIST });
  });
}

function* fetchItem() {
  yield takeLatest(PAYMENT_FETCH_ITEM, function* ({ id, isRepeat }) {
    try {
      const response = isRepeat
        ? yield call(API.payment.cloneItem, { id })
        : yield call(API.payment.fetchItem, { id });

      // server is inconsistent, sometimes response.data is an object, sometimes it is an empty array
      // if the active page is repeated, then the server returns an object without operationsItemList
      if (response
        && response.data
        && (isRepeat || Array.isArray(response.data.operationsItemList))
        && Array.isArray(response.data.paymentResponseList)) {
        yield put({
          type: PAYMENT_FETCH_ITEM__SUCCESS,

          // I literally have no idea why we have to send an array with one object in it
          // instead of just sending one object, but whatever, deal with it
          data: response.data.paymentResponseList[0] || {},

          // List of operations
          operations: response.data.operationsItemList || [],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: PAYMENT_FETCH_ITEM__FAILIRE, error: error.message });
    }
  });
}

function* create() {
  yield takeLatest(PAYMENT_CREATE, function* (action) {
    const {
      additionalInformation,
      amount,
      number,
      channel,
      dealerId,
      description,
      email,
      expiredAt,
      fullName,
      phone,
      legalEntityId,
      sector,
    } = action;

    try {
      const response = yield call(API.payment.create, {
        additionalInformation,
        amount,
        number,
        channel,
        dealerId,
        description,
        email,
        expiredAt,
        fullName,
        phone,
        legalEntityId,
        sector,
      });

      if (response && response.data && response.data.paymentId) {
        yield put({
          type: PAYMENT_CREATE__SUCCESS,
          paymentId: response.data.paymentId,
          formUrl: response.data.formUrl,
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });

        if (
          response
          && response.data
          && response.data.validationErrorList
          && Array.isArray(response.data.validationErrorList)
        ) {
          yield put({
            type: PAYMENT_CREATE__FAILIRE,
            error,
            errors: response.data.validationErrorList,
          });
        } else {
          throw new Error(error);
        }
      }
    } catch (error) {
      yield put({ type: PAYMENT_CREATE__FAILIRE, error: error.message });
    }
  });
}

function* annulPayment() {
  yield takeLatest(PAYMENT_ANNUL, function* ({ id }) {
    try {
      const response = yield call(API.payment.annul, { id });

      if (response && response.data && Object.keys(response.data).length === 0) {
        yield put({ type: PAYMENT_ANNUL__SUCCESS });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: PAYMENT_ANNUL__FAILIRE, error: error.message });
    }
  });
}

function* fetchSectors() {
  yield takeLatest(PAYMENT_SECTOR_FETCH_LIST, function* () {
    try {
      const response = yield call(API.payment.fetchSectors);

      if (response
          && response.data
          && Array.isArray(response.data.sectorList)) {
        yield put({
          type: PAYMENT_SECTOR_FETCH_LIST__SUCCESS,
          list: response.data.sectorList.map((item, i) => ({ ...item, id: i + 1 })) || [],
        });
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: PAYMENT_SECTOR_FETCH_LIST__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchList),
    fork(fetchListOnSearch),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(fetchListOnFilters),
    fork(fetchItem),
    fork(create),
    fork(annulPayment),
    fork(fetchSectors),
  ]);
}
