import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { message, Popconfirm, Skeleton } from 'antd';
import { STATUS_LIST, TYPES } from 'settings';
import Modal from 'components/Modal';
import { useHistory } from 'react-router-dom';

import {
  CHECK,
  REMOVE_STATUS_LOCK,
  SET_REDIRECT_FROM_FINAL_STATUS_BUTTON,
  SET_REDIRECT_FROM_RETURN_STATUS_BUTTON,
  SET_REDIRECT_FROM_SUBMIT_STATUS_BUTTON,
} from 'helpers/feature';

import { patch as patchStatus } from 'redux/userStatus/actions';
import { save as saveRequest, saveReset as saveRequestReset } from 'redux/request/actions';

import {
  Error, ErrorMessage, Loader, ModalBody, ModalContent, ModalLink, ModalTitle, Root,
} from './style';

const RETURN_STATUS_VALUE = STATUS_LIST[3].value;
const SUBMIT_STATUS_VALUE = STATUS_LIST[4].value;
const FINAL_STATUS_VALUE = STATUS_LIST[5].value;

const { REACT_APP_PUBLIC_URL: publicUrl } = process.env;

const DEFAULT_LINK = '/';

const REACT_APP_PUBLIC_URL = publicUrl === DEFAULT_LINK ? '' : publicUrl;

const STATES = new Map([
  [
    RETURN_STATUS_VALUE,
    {
      redirect: CHECK(SET_REDIRECT_FROM_RETURN_STATUS_BUTTON, true),
      loader: true,
      modalTitle: 'Возврат заявки на дозаполнение клиенту',
      Progress: () => (
        <ModalBody>
          <ModalTitle>
            <Loader />
            Выполняется возврат заявки клиенту
          </ModalTitle>
        </ModalBody>
      ),
      Error: (error) => (
        <>
          <Error>
            При выполнении запроса произошла ошибка:
            <br />
            <ErrorMessage>{error}</ErrorMessage>
          </Error>
        </>
      ),
      Success: (message) => (
        <>
          <ModalContent>Заявка успешно возвращена клиенту.</ModalContent>
          <ModalLink href={`${REACT_APP_PUBLIC_URL}/app/leads/request/list`}>Перейти в список заявок</ModalLink>
          <br />
          <small>
            [При закрытии модального окна вы будете автоматически перенаправлены на список
            заявок]
          </small>
          {message && <p>{message}</p>}
        </>
      ),
    },
  ],

  [
    SUBMIT_STATUS_VALUE,
    {
      redirect: CHECK(SET_REDIRECT_FROM_SUBMIT_STATUS_BUTTON, false),
      loader: false,
      modalTitle: 'Отправка заявки',
      Progress: () => (
        <ModalBody>
          <ModalTitle>
            <Loader />
            Выполняется отправка заявки
          </ModalTitle>
          <ModalContent>Запрос может занять до 1-2 минут.</ModalContent>
        </ModalBody>
      ),
      Error: (error) => (
        <>
          <Error>
            При выполнении запроса произошла ошибка:
            <br />
            <ErrorMessage>{error}</ErrorMessage>
          </Error>
        </>
      ),
      Success: (message) => (
        <>
          <ModalContent>Заявка успешно отправлена.</ModalContent>
          <ModalLink href={`${REACT_APP_PUBLIC_URL}/app/leads/request/list`}>Перейти в список заявок</ModalLink>
          <br />
          <small>
            [При закрытии модального окна вы будете автоматически перенаправлены на список
            заявок]
          </small>
          {message && <p>{message}</p>}
        </>
      ),
    },
  ],
  [
    FINAL_STATUS_VALUE,
    {
      redirect: CHECK(SET_REDIRECT_FROM_FINAL_STATUS_BUTTON, true),
      loader: true,
      modalTitle: 'Окончательная блокировка заяка',
      Progress: () => (
        <ModalBody>
          <ModalTitle>
            <Loader />
            Выполняется блокировка заявки
          </ModalTitle>
        </ModalBody>
      ),
      Error: (error) => (
        <>
          <Error>
            При выполнении запроса произошла ошибка:
            <br />
            <ErrorMessage>{error}</ErrorMessage>
          </Error>
        </>
      ),
      Success: (message) => (
        <>
          <ModalContent>Заявка успешно заблокирована.</ModalContent>
          <ModalLink href={`${REACT_APP_PUBLIC_URL}/app/leads/request/list`}>Перейти в список заявок</ModalLink>
          <br />
          <small>
            [При закрытии модального окна вы будете автоматически перенаправлены на список
            заявок]
          </small>
          {message && <p>{message}</p>}
        </>
      ),
    },
  ],
]);

function Button({
                  // passed
  label,
  value,
  accessToken,
  confirm,
  disabled = true,

                  // connect
  fieldsFlat,
  formIsDisabled,
  requestStatusIsLocked,
  fetchIsLoading,
  status,
  statusId,
  isLoading,
  isLoaded,
  error,
  successMessage,
  hasMoratorium,
  crifStatusIsLocked,
  saveIsLoaded,

                  // actions
  patchStatus,
  saveRequest,
  saveRequestReset,
}) {
  const history = useHistory();
  const isCurrent = statusId === accessToken && status === value;
  const [showModal, setShowModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const shouldRefetch = useRef(false);

  useEffect(() => {
    if (shouldRefetch.current && saveIsLoaded && value === 6) {
      saveRequestReset();
      patchStatus({ accessToken, status: value });
    }
    // eslint-disable-next-line
  }, [saveIsLoaded, value, shouldRefetch])

  useEffect(() => {
    if (isCurrent && isLoaded && !isLoading) {
      if (showNotification) {
        message.success('Статус успешно изменен', 3);
        setShowNotification(false);

        setTimeout(() => {
          history.push('/app');
        }, 3000);
      }
    }

    if (isCurrent && error === '' && !isLoading && showModal) {
      setShowModal(false);
    }
  }, [showNotification, showModal, isCurrent, isLoaded, error, isLoading, history]);

  function handleClose() {
    setShowModal(false);

    if (isCurrent && isLoaded) {
      history.push('/app');
    }
  }

  function handleStatusChange() {
    if (value === 6) {
      shouldRefetch.current = true;
      saveRequest();
    } else {
      shouldRefetch.current = false;
      patchStatus({ accessToken, status: value });
    }

    if (!STATES.get(value).redirect) {
      setShowModal(true);
    } else {
      setShowNotification(true);
    }
  }

  function handleOnClick(e) {
    e.stopPropagation();
    handleStatusChange();
  }

  const { error: errorCRM } = fieldsFlat.find((e) => e.name === TYPES.CRM_SELECT) || {};

  const errorMessage = isCurrent && error;

  const isButtonDisabled = CHECK(REMOVE_STATUS_LOCK)
    ? false
    : formIsDisabled
      || (value === SUBMIT_STATUS_VALUE
        ? requestStatusIsLocked || errorCRM || crifStatusIsLocked || hasMoratorium
        : value === FINAL_STATUS_VALUE
          ? false
          : crifStatusIsLocked)
      || disabled
      || errorMessage;
  const title = CHECK(REMOVE_STATUS_LOCK)
    ? 'Status is unlocked due to REMOVE_STATUS_LOCK Feature.'
    : formIsDisabled
      ? 'Заявка отправлена на рассмотрение'
      : value === SUBMIT_STATUS_VALUE
        ? hasMoratorium
          ? 'На пользователя наложен мораторий'
          : crifStatusIsLocked
            ? 'Не выбран результат из поиска крифа'
            : errorCRM
              ? 'Не выбран результат из поиска крифа'
              : requestStatusIsLocked
                ? 'Форма содержит незаполненные обязательные поля или поля с ошибками'
                : ''
        : value === RETURN_STATUS_VALUE
          ? crifStatusIsLocked
            ? 'На пользователя наложен мораторий'
            : ''
          : '';

  return (
    <Skeleton active loading={fetchIsLoading}>
      {isCurrent && !STATES.get(value).redirect && (
        <Modal
          visible={showModal}
          closable
          onCancel={handleClose}
          title={STATES.get(value).modalTitle}
        >
          {isLoading && STATES.get(value).Progress()}
          {error && STATES.get(value).Error(error)}
          {isLoaded && STATES.get(value).Success(successMessage)}
        </Modal>
      )}

      {confirm ? (
        <Popconfirm
          title="Поменять статус? Все несохраненные данные будут потеряны."
          onConfirm={handleStatusChange}
          onCancel={null}
          okText="Да, поменять статус"
          cancelText="Нет"
          disabled={isButtonDisabled}
        >
          <Root
            loading={isCurrent && isLoading}
            disabled={isButtonDisabled}
            title={title}
          >
            {STATES.get(value).loader && errorMessage ? <span>{error}</span> : label}
          </Root>
        </Popconfirm>
      ) : (
        <Root
          onClick={handleOnClick}
          loading={isCurrent && isLoading}
          disabled={isButtonDisabled}
          title={title}
        >
          {STATES.get(value).loader && errorMessage ? <span>{error}</span> : label}
        </Root>
      )}
    </Skeleton>
  );
}

export default connect(
  (state) => ({
    fieldsFlat: state.request.fieldsFlat,
    formIsDisabled: state.request.formIsDisabled,
    requestStatusIsLocked: state.request.statusIsLocked,
    fetchIsLoading: state.request.fetch.isLoading,
    status: state.userStatus.status,
    statusId: state.userStatus.id,
    isLoading: state.userStatus.isLoading,
    isLoaded: state.userStatus.isLoaded,
    error: state.userStatus.error,
    successMessage: state.userStatus.successMessage,
    hasMoratorium: state.crif.hasMoratorium,
    crifStatusIsLocked: state.crif.statusIsLocked,

    saveIsLoaded: state.request.save.isLoaded,
  }),
  {
    patchStatus,
    saveRequest,
    saveRequestReset,
  },
)(Button);
