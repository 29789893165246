// C15 Document Package Type Тип пакета документов
export const packageTypes = {
  1: 'Полный',
  2: 'Полный официальный',
  3: 'Полный неофициальный',
  4: 'Два документа',
  5: 'Минимальный',
};

// C18 Phone Type Тип телефона
export const phoneTypes = {
  M: 'Мобильный телефон (личный)',
  D: 'Дополнительный телефон для связи',
  R: 'Домашний телефон (регистрация)',
  A: 'Домашний телефон (фактический)',
  J: 'Рабочий телефон ',
  W: 'Дополнительный рабочий телефон',
  P: 'Телефон отдела кадров',
  F: 'Факс',
  O: 'Телефон организации',
  B: 'Телефон бухгалтерии',
  X: 'Другой',
};

// C22 Country Страна
export const countries = {
  AU: 'Австралия',
  AT: 'Австрия',
  AZ: 'Азербайджан',
  AX: 'Аландские острова',
  AL: 'Албания',
  DZ: 'Алжир',
  VI: 'Американские Виргинские острова',
  AS: 'Американское Самоа',
  AI: 'Ангилья',
  AO: 'Ангола',
  AD: 'Андорра',
  AQ: 'Антарктида',
  AG: 'Антигуа и Барбуда',
  AR: 'Аргентина',
  AM: 'Армения',
  AW: 'Аруба',
  AF: 'Афганистан',
  BS: 'Багамские острова',
  BD: 'Бангладеш',
  BB: 'Барбадос',
  BH: 'Бахрейн',
  BY: 'Беларусь',
  BZ: 'Белиз',
  BE: 'Бельгия',
  BJ: 'Бенин',
  BM: 'Бермудские острова',
  BG: 'Болгария',
  BO: 'Боливия',
  BQ: 'Бонайре, Синт-Эстатиус и Саба',
  BA: 'Босния и Герцеговина',
  BW: 'Ботсвана',
  BR: 'Бразилия',
  IO: 'Британская территория в Индийском океане',
  VG: 'Британские Виргинские острова',
  BN: 'Бруней-Даруссалам',
  BF: 'Буркина Фасо',
  BI: 'Бурунди',
  BT: 'Бутан',
  VU: 'Вануату',
  VA: 'Ватикан',
  HU: 'Венгрия',
  VE: 'Венесуэла',
  UM: 'Внешние малые острова (США)',
  TL: 'Восточный Тимор',
  VN: 'Вьетнам',
  GA: 'Габон',
  HT: 'Гаити',
  GY: 'Гайана',
  GM: 'Гамбия',
  GH: 'Гана',
  GP: 'Гваделупа',
  GT: 'Гватемала',
  GW: 'Гвинея-Бисау',
  DE: 'Германия',
  GG: 'Гернси',
  GI: 'Гибралтар',
  HN: 'Гондурас',
  HK: 'Гонконг',
  GD: 'Гренада',
  GL: 'Гренландия',
  GR: 'Греция',
  GE: 'Грузия',
  DK: 'Дания',
  JE: 'Джерси',
  DJ: 'Джибути',
  DM: 'Доминика',
  DO: 'Доминиканская Республика',
  EG: 'Египет',
  ZM: 'Замбия',
  EH: 'Западная Сахара',
  ZW: 'Зимбабве',
  IL: 'Израиль',
  IN: 'Индия',
  ID: 'Индонезия',
  JO: 'Иордания',
  IQ: 'Ирак',
  IR: 'Иран (исламская республика)',
  IE: 'Ирландия',
  IS: 'Исландия',
  ES: 'Испания',
  IT: 'Италия',
  YE: 'Йемен',
  CV: 'Кабо-Верде',
  KZ: 'Казахстан',
  KY: 'Каймановы Острова',
  KH: 'Камбоджа',
  CM: 'Камерун',
  CA: 'Канада',
  QA: 'Катар',
  KE: 'Кения',
  CY: 'Кипр',
  KG: 'Киргизия',
  KI: 'Кирибати',
  CN: 'Китайская Народная Республика (КНР)',
  CC: 'Кокосовые (Килинг) острова',
  CO: 'Колумбия',
  KM: 'Коморы',
  CG: 'Конго',
  CD: 'Конго, Демократическая Республика',
  KP: 'Корейская Народно-Демократическая Народная Республика',
  CR: 'Коста Рика',
  CI: "Кот д'ивуар",
  CU: 'Куба',
  KW: 'Кувейт',
  CW: 'Кюрасао',
  LA: 'Лаосская Народно-Демократическая Республика',
  LV: 'Латвия',
  LS: 'Лесото',
  LR: 'Либерия',
  LB: 'Ливан',
  LY: 'Ливия',
  LT: 'Литва',
  LI: 'Лихтенштейн',
  LU: 'Люксембург',
  MU: 'Маврикий',
  MR: 'Мавритания',
  MG: 'Мадагаскар',
  YT: 'Майотта',
  MO: 'Макао (Аомынь)',
  MK: 'Македония',
  MW: 'Малави',
  MY: 'Малайзия',
  ML: 'Мали',
  MV: 'Мальдивы',
  MT: 'Мальта',
  MA: 'Марокко',
  MQ: 'Мартиника',
  MH: 'Маршаловы острова',
  MX: 'Мексика',
  FM: 'Микронезия, Федеративные Штаты',
  MZ: 'Мозамбик',
  MD: 'Молдавия',
  MC: 'Монако',
  MN: 'Монголия',
  MS: 'Монтсеррат',
  MM: 'Мьянма',
  NA: 'Намибия',
  NR: 'Науру',
  NP: 'Непал',
  NE: 'Нигер',
  NG: 'Нигерия',
  NL: 'Нидерланды',
  NI: 'Никарагуа',
  NU: 'Ниуэ',
  NZ: 'Новая Зеландия',
  NC: 'Новая Каледония',
  NO: 'Норвегия',
  AE: 'ОАЭ',
  OM: 'Оман',
  BV: 'Остров Буве',
  GU: 'Остров Гуам',
  IM: 'Остров Мэн',
  NF: 'Остров Норфолк',
  CX: 'Остров Рождества',
  SH: 'Остров Святой Елены',
  TW: 'Остров Тайвань',
  GS: 'Остров Южная Джорджия и Южные Сэндвичевы острова',
  CK: 'Острова Кука',
  PN: 'Острова Питкэрн',
  HM: 'Острова Херд и Макдональд',
  PK: 'Пакистан',
  PW: 'Палау',
  PS: 'Палестина',
  PA: 'Панама',
  PG: 'Папуа Новая Гвинея',
  PY: 'Парагвай',
  PE: 'Перу',
  PL: 'Польша',
  PT: 'Португалия',
  PR: 'Пуэрто-Рико',
  GN: 'Республика Гвинея',
  KR: 'Республика Корея',
  TZ: 'Республика Танзания',
  RE: 'Реюньон',
  RU: 'Российская Федерация',
  RW: 'Руанда',
  RO: 'Румыния',
  SV: 'Сальвадор',
  WS: 'Самоа',
  SM: 'Сан-Марино',
  ST: 'Сан-Томе и Принсипи',
  SA: 'Саудовская Аравия',
  SZ: 'Свазиленд',
  MP: 'Северные Марианские острова',
  SC: 'Сейшельские острова, Сейшелы',
  BL: 'Сен-Бартелеми',
  SN: 'Сенегал',
  MF: 'Сен-Мартен (Франция)',
  PM: 'Сен-Пьер и Микелон',
  VC: 'Сент-Винсент и Гренадины',
  KN: 'Сент-Китс и Невис',
  LC: 'Сент-Люсия',
  RS: 'Сербия',
  SG: 'Сингапур',
  SX: 'Синт-Мартен',
  SY: 'Сирия',
  SK: 'Словакия',
  SI: 'Словения',
  GB: 'Соединённое Королевство (Великобритании и Северной Ирландии)',
  SB: 'Соломоновы Острова',
  SO: 'Сомали',
  SD: 'Судан',
  SR: 'Суринам',
  US: 'США',
  SL: 'Сьерра-Леоне',
  TJ: 'Таджикистан',
  TH: 'Таиланд',
  TC: 'Тёркс и Кайкос',
  TG: 'Того',
  TK: 'Токелау',
  TO: 'Тонга',
  TT: 'Тринидад и Тобаго',
  TV: 'Тувалу',
  TN: 'Тунис',
  TM: 'Туркменистан',
  TR: 'Турция',
  UG: 'Уганда',
  UZ: 'Узбекистан',
  UA: 'Украина',
  WF: 'Уоллис и Футуна',
  UY: 'Уругвай',
  FO: 'Фарерские Острова',
  FJ: 'Фиджи',
  PH: 'Филиппины',
  FI: 'Финландия',
  FK: 'Фолклендские острова',
  FR: 'Франция',
  GF: 'Французская Гвиана',
  PF: 'Французская Полинезия',
  TF: 'Французские Южные и Антарктические Территории',
  HR: 'Хорватия',
  CF: 'Центрально Африканская Республика',
  TD: 'Чад',
  ME: 'Черногория',
  CZ: 'Чехия',
  CL: 'Чили',
  CH: 'Швейцария',
  SE: 'Швеция',
  SJ: 'Шпицберген и Ян-Майен',
  LK: 'Шри Ланка',
  EC: 'Эквадор',
  GQ: 'Экваториальная Гвинея',
  ER: 'Эритрея',
  EE: 'Эстония',
  ET: 'Эфиопия',
  ZA: 'Южная Африка',
  SS: 'Южный Судан',
  JM: 'Ямайка',
  JP: 'Япония',
};

// C40 Region Type Тип региона
export const regionTypes = {
  102: 'Аобл',
  109: 'а.обл.',
  101: 'АО',
  110: 'а.окр.',
  103: 'г',
  111: 'г.',
  112: 'г.ф.з.',
  104: 'край',
  105: 'обл',
  113: 'обл.',
  107: 'округ',
  106: 'Респ',
  114: 'респ.',
  108: 'Чувашия',
};

// C41 Area Type Тип района
export const areaTypes = {
  205: 'АО',
  208: 'вн.тер. г.',
  207: 'г.о.',
  209: 'м.р-н',
  206: 'п',
  210: 'пос.',
  201: 'р-н',
  203: 'тер',
  202: 'у',
  211: 'у.',
};

// C42 City Type Тип города
export const cityTypes = {
  310: 'волость',
  301: 'г',
  318: 'г.',
  319: 'г.п.',
  305: 'дп',
  304: 'кп',
  316: 'массив',
  317: 'п',
  302: 'пгт',
  320: 'пгт.',
  311: 'п/о',
  303: 'рп',
  307: 'с/а',
  315: 'с/мо',
  309: 'с/о',
  314: 'с/п',
  306: 'с/с',
  312: 'тер',
};

// C43 Settlement Type Тип населенного пункта
export const settlementTypes = {
  401: 'аал',
  445: 'автодорога',
  440: 'арбан',
  402: 'аул',
  403: 'волость',
  404: 'высел',
  405: 'г',
  436: 'городок',
  449: 'гп',
  406: 'д',
  407: 'дп',
  408: 'ж/д_будка',
  450: 'ж/д б-ка',
  451: 'ж/д в-ка',
  409: 'ж/д_казарм',
  452: 'ж/д к-ма',
  410: 'ж/д_оп',
  438: 'ж/д_платф',
  453: 'ж/д пл-ма',
  454: 'ж/д пл-ка',
  411: 'ж/д_пост',
  412: 'ж/д_рзд',
  413: 'ж/д_ст',
  447: 'жилзона',
  446: 'жилрайон',
  414: 'заимка',
  415: 'казарма',
  439: 'кв-л',
  444: 'кордон',
  416: 'кп',
  442: 'лпх',
  448: 'массив',
  417: 'м',
  418: 'мкр',
  419: 'нп',
  420: 'остров',
  421: 'п',
  426: 'п/о',
  422: 'п/р',
  423: 'п/ст',
  424: 'пгт',
  455: 'пгт.',
  456: 'п. ж/д ст.',
  443: 'погост',
  425: 'починок',
  427: 'промзона',
  428: 'рзд',
  429: 'рп',
  430: 'с',
  431: 'сл',
  441: 'снт',
  432: 'ст',
  433: 'ст-ца',
  437: 'тер',
  434: 'у',
  435: 'х',
};

// C44 Street Type Тип улицы
export const streetTypes = {
  532: 'аал',
  501: 'аллея',
  560: 'арбан',
  533: 'аул',
  502: 'б-р',
  568: 'вал',
  635: 'взв.',
  503: 'въезд',
  534: 'высел',
  563: 'гск',
  536: 'нас',
  504: 'дор',
  537: 'ж/д_будка',
  538: 'ж/д_казарм',
  539: 'ж/д_оп',
  559: 'ж/д_платф',
  540: 'ж/д_пост',
  541: 'ж/д_рзд',
  542: 'ж/д_ст',
  505: 'жт',
  506: 'заезд',
  636: 'з/у',
  543: 'казарма',
  562: 'канал',
  507: 'кв-л',
  508: 'км',
  509: 'кольцо',
  567: 'коса',
  510: 'линия',
  565: 'лпх',
  544: 'м',
  545: 'мкр',
  570: 'мост',
  511: 'наб',
  546: 'нп',
  512: 'остров',
  548: 'п',
  549: 'п/о',
  550: 'п/р',
  551: 'п/ст',
  513: 'парк',
  514: 'пер',
  515: 'переезд',
  637: 'пер-д',
  516: 'пл',
  517: 'пл-ка',
  547: 'платф',
  552: 'полустанок',
  553: 'починок',
  519: 'пр-кт',
  518: 'проезд',
  520: 'просек',
  521: 'проселок',
  566: 'проток',
  522: 'проулок',
  554: 'рзд',
  555: 'с',
  523: 'сад',
  524: 'сквер',
  556: 'сл',
  564: 'снт',
  638: 'с/т',
  561: 'спуск',
  557: 'ст',
  525: 'стр',
  526: 'тер',
  527: 'тракт',
  528: 'туп',
  529: 'ул',
  530: 'уч-к',
  569: 'ферма',
  558: 'х',
  531: 'ш',
  571: 'ряды',
  572: 'а/я',
  573: 'берег',
  574: 'просека',
  575: 'протока',
  576: 'бугор',
  577: 'зона',
  578: 'днп',
  579: 'н/п',
  580: 'ф/х',
  581: 'местность',
  582: 'балка',
  583: 'бухта',
  584: 'причал',
  585: 'мыс',
  586: 'маяк',
  587: 'горка',
  588: 'тоннель',
  589: 'ж/р',
  590: 'ззд.',
  591: 'м-ко',
  593: 'ряд',
  639: 'ряды',
  594: 'тер.',
  595: 'тер. ГСК',
  596: 'тер. ДНП',
  597: 'тер. ДНТ',
  598: 'тер. ОНО',
  599: 'тер. ОНП',
  600: 'тер. ОНТ',
  601: 'тер. СНО',
  602: 'тер. СНП',
  603: 'тер. СНТ',
  604: 'тер. СПК',
  605: 'тер. ТСН',
  606: 'ал.',
  607: 'г-к',
  608: 'дор.',
  609: 'лн.',
  610: 'мкр.',
  611: 'наб.',
  612: 'пер.',
  613: 'пр-д',
  614: 'пр-к',
  615: 'промзона',
  616: 'рзд.',
  617: 'р-н',
  618: 'с-р',
  619: 'тер. ДПК',
  620: 'тер.ф.х.',
  621: 'ул.',
  622: 'ус.',
  623: 'ш.',
  624: 'взв.',
  625: 'сзд.',
  626: 'мгстр.',
  628: 'к-цо',
  629: 'пер-д',
  630: 'пр-ка',
  631: 'пр-лок',
  632: 'проул.',
  634: 'с-к',
};
