import React, { useCallback, useEffect, useRef } from 'react';
import Checkbox from 'components/Checkbox';
import Error from 'components/Fields/Error';
import Warning from 'components/Fields/Warning';
import { SCROLL_DURATION } from 'settings';
import { mergeArrays } from 'hacks';

function CheckboxField({
  name,
  label,
  error: errorMessage,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
}) {
  const focusableNode = useRef();

  function handleChange({ target: { checked } }) {
    if (name === 'crmResults' && !checked) {
      const exludeInput = [
        ...mergeArrays(dependents, ifChangeClearFields),
        'crmSelect',
        'crmSubjectID5NT',
        'crmGroupID',
      ];
      onChangeField({ [name]: checked }, exludeInput);
    } else if ((initialValue === true) !== checked) {
      onChangeField({ [name]: checked }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const focusNode = focusableNode.current;
    if (focusNode) {
      focusNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        focusNode.focus();
      }, SCROLL_DURATION);
    }
  }, [focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      {getFieldDecorator(name, {
        valuePropName: 'checked',
        rules,
        initialValue: initialValue === true,
      })(
        <Checkbox
          onChange={handleChange}
          area={area}
          disabled={disabled}
          ref={focusableNode}
        >
          {label}
        </Checkbox>,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default CheckboxField;
