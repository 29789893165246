import { VisitDescriptionCode, VisitStatusCode } from 'tcm/offer/data';

export const bank = [
  {
    value: 'Тойота Банк',
    label: 'Тойота Банк',
  },
  {
    value: 'Другой банк',
    label: 'Другой банк',
  },
  {
    value: 'Нет. Покупка за наличные',
    label: 'Нет. Покупка за наличные',
  },
  {
    value: 'Нет. Отказ от замены авто',
    label: 'Нет. Отказ от замены авто',
  },
];

export const brand = [
  {
    value: 'Toyota',
    label: 'Toyota',
  },
  {
    value: 'Lexus',
    label: 'Lexus',
  },
];

export const reason = [
  {
    value: 'Отказ по заявке',
    label: 'Отказ по заявке',
  },
  {
    value: 'Отказ клиента',
    label: 'Отказ клиента',
  },
];

export const statusDescriptionsCodes = Object.values(VisitDescriptionCode);

export const FormItems = {
  statuses: {
    key: 'statuses',
    options: {
      TradeIn: {
        value: VisitStatusCode.TradeIn,
        label: 'Оценка Trade-in',
        children: {
          sum: {
            key: VisitDescriptionCode.Sum,
            label: 'Сумма',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.TradeIn);

                  if (result && !value) {
                    return Promise.reject('Сумма не может быть пустой');
                  }
                  return Promise.resolve();
                },
              }),
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.TradeIn);
                  const regExp = /^\d{6,7}$/;
                  if (result && !regExp.test(value?.replace(/\s/g, ''))) {
                    return Promise.reject('Введите сумму от 100 000 до 9 999 999');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        },
      },
      TestDrive: {
        value: VisitStatusCode.TestDrive,
        label: 'Тест-драйв',
        children: {
          brand: {
            key: VisitDescriptionCode.TestDriveBrand,
            label: 'Марка',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.TestDrive);
                  if (result && !value) {
                    return Promise.reject('Выберите марку');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          model: {
            key: VisitDescriptionCode.TestDriveModel,
            label: 'Модель',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.TestDrive);
                  if (result && !value) {
                    return Promise.reject('Выберите модель');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        },
      },
      Registration: {
        value: VisitStatusCode.Registration,
        label: 'Подача заявки',
        children: {
          bank: {
            key: VisitDescriptionCode.RegistrationBank,
            label: 'Банк',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.Registration);
                  if (result && !value) {
                    return Promise.reject('Выберите банк');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          brand: {
            key: VisitDescriptionCode.RegistrationBrand,
            label: 'Марка',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.Registration);
                  if (result && !value) {
                    return Promise.reject('Выберите марку');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          model: {
            key: VisitDescriptionCode.RegistrationModel,
            label: 'Модель',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.Registration);
                  if (result && !value) {
                    return Promise.reject('Выберите модель');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        },
      },
      ContractConcluded: {
        value: VisitStatusCode.ContractConcluded,
        label: 'Оформление кредитного договора',
        children: {
          bank: {
            key: VisitDescriptionCode.ContractConcludedBank,
            label: 'Банк',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.ContractConcluded);
                  if (result && !value) {
                    return Promise.reject('Выберите банк');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
          reason: {
            key: VisitDescriptionCode.ContractConcludedReason,
            label: 'Причина отказа',
            rules: [
              (formInstance) => ({
                validator(rule, value) {
                  const result = formInstance.getFieldValue('statuses')
                    .includes(VisitStatusCode.ContractConcluded);
                  if (result && !value) {
                    return Promise.reject('Выберите причину');
                  }
                  return Promise.resolve();
                },
              }),
            ],
          },
        },
      },
    },
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Выберите хотя бы один статус');
        },
      }),
    ],
  },
  comment: {
    key: 'comment',
    apiKey: 'text',
    placeholder: 'Введите текст',
    rules: [
      () => ({
        validator(rule, value) {
          if (value?.length > 0) {
            return Promise.resolve();
          }
          return Promise.reject('Комментарий не может быть пустым');
        },
      }),
    ],
  },
};
