import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const ContentHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-right: 70px;
`;

export const CompanySearch = styled.div`
  margin-left: 48px;
  margin-right: 34px;
  width: 100%;
  max-width: 396px;
`;

export const PageTitle = styled.h1`
  margin-bottom: 0;
  font-family: 'Toyota Type', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 45px;
  letter-spacing: -1.3px;
  color: #000000;
`;

export const TableContainer = styled.div`
  position: relative;
  
  &:not(:first-child){
    margin-top: 70px;
  }
  
  .loader {
    position: absolute;
    width: 100%;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 200px;
  }
`;

export const ClientTableWrapper = styled.div`
  margin-bottom: 60px;
`;

export const Table = withTheme(styled.table`
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  opacity: ${({ hide }) => (hide ? 0.2 : 1)};
  transition: .2s linear opacity;
  
  thead tr, tbody tr {
    width: 100%;
     td:first-of-type,  th:first-of-type{
      padding-left:20px;
    }
     td:last-of-type,  th:last-of-type{
      padding-right:20px;
    }
  }

  tbody tr{
   transition: .25s all linear;
    &:hover{
      background: #F0F0F0;
    }
  }
  
  tbody td {
    display: flex;
    align-items: flex-start;
  }

  th, td {
    padding-right: 16px;

    &:last-child{
      padding-right: 0;
    }
  }

  th {
    padding-bottom: 15px;
    border-bottom: 1px solid  ${({ theme }) => theme.palette.gray4};
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.gray3};
    
    &:nth-child(2){
      color: ${({ theme }) => theme.palette.black};
    }
    
  }

  td {
    padding-top: 24px;
    padding-bottom: 23px;
    border-bottom: 1px solid  ${({ theme }) => theme.palette.gray5};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.black};
    
    &:nth-child(3){
      min-width: 150px;
    }
    
    &:nth-child(5), &:nth-child(6), &:nth-child(7){
      width: min-content;
    }
    
    &:nth-child(6), &:nth-child(7), &:nth-child(8){
      font-size: 16px;
    }
  }
`);

export const FilterBtnContainer = styled.div`
  width: 100%;
  
  button {
    position: absolute;
    top: -10px;
    right: 0;
  }
`;

export const TableTitleStroke = styled.div`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isTheme }) => (isTheme ? 16 : 14)}px;
  line-height: 20px;
  color: #000000;
`;

export const Vin = styled(TableTitleStroke)`
   grid-area: vin;
   color: inherit;
`;

export const Brand = styled(TableTitleStroke)`
   grid-area: brand;
   color: inherit;
`;

export const Model = styled(TableTitleStroke)`
   grid-area: model;
   color: inherit;
`;

export const TableTitle = withTheme(styled.p`
  margin-bottom: 55px;
  font-family: ${(theme) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  color: ${({ theme }) => theme.palette.black};
`);

export const TableVinTitleContainer = styled.div`
  display: grid;
  //min-width: 202px;
  grid-template-columns: repeat(2, 75px);
  grid-auto-rows: minmax(auto, 20px);
  grid-template-areas:
   'vin   .'
   'brand model'; 
   color: inherit;
   
   p {
    margin-bottom: 0;
    color: inherit;
   }
`;

export const TableTitleSecondaryStroke = styled.div`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #A8AAAC;
`;

export const TableVinBodyContainer = styled.div`
  position: relative;
  display: grid;
  min-width: 183px;
  grid-gap: 10px 10px;
  grid-template-columns: repeat(1, 62px);
  grid-template-areas:
   'vin vin'
   'brand model'; 
`;

export const Cell = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const InfoIconContainer = styled.span`
  margin-left: 5px;
  margin-top: 0;
`;

export const DeadlineContainer = styled.div`
  position: relative;
  display: grid;
  min-width: 183px;
  grid-gap: 10px 10px;
  grid-template-columns: 1fr 30px;
  grid-template-areas: 'time icon';
  align-items: flex-start;
  color: inherit;
  
  p {
    color: inherit;
    margin-bottom: 0;
  }
`;

export const DeadlineHeader = styled.div`
  display: flex;
  align-items: center;
    color: inherit;
  
  p {
    color: inherit;
    margin-bottom: 0;
  }
`;

export const DeadLineTime = withTheme(styled(TableTitleStroke)`
   grid-area: time;
   color: inherit;
`);

export const DeadlineIcon = styled(TableTitleStroke)`
   grid-area: icon;
   margin-top: -4px;
   align-self: end;
   width: max-content;
`;

export const ContainerBtn = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
`;

export const NoCalls = styled.div`
  color: #A8AAAC;
  font-size: 14px;
  line-height: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 75px;
`;

export const ErrorContainer = withTheme(styled.div`
    color: ${({ theme }) => theme.palette.accent};
    margin: 20px 0;
`);

export const NoResults = withTheme(styled.div`
  margin-top: 45px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.black};
  
    p {
      margin-bottom: 0;
    }
  `);
