import React from 'react';
import styled from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

const WithStyle = (ComponentName) => styled(ComponentName)`
  & .anticon {
    margin: 0 1rem;
  }

  & .anticon svg {
    width: 2rem;
    height: 2rem;
  }
`;

const Root = styled.div`
  min-height: 400px;
  ${({ height }) => `
    min-height: ${height}px;
  `}

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderText = styled.div`
  padding-left: 1rem;
  font-size: 1rem;
`;

const LoaderStyled = WithStyle((props) => <LoadingOutlined {...props} />);

export { Root, LoaderText, LoaderStyled };
