export const getListFromResponse = ({ response, magicKey = 'list' }) => {
  if (Array.isArray(response.data)) {
    return {
      list: response.data,
      hasError: false,
    };
  }

  if (response.data && Array.isArray(response.data[magicKey])) {
    return {
      list: response.data[magicKey],
      hasError: false,
    };
  }

  return {
    list: [],
    hasError: true,
  };
};
