import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const ReportContainer = styled.div`
  display: ${({ show }) => (show ? 'grid' : 'none')};
  grid-template-columns: 170px minmax(0, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding-bottom: 35px;
  margin-bottom: 28px;
  border-bottom: 1px solid #F0F0F0;
`;

export const ReportListContainer = styled.div`
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 8px;

  button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const ReportTitle = withTheme(styled.h2`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

export const Report = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #E50000;
  cursor: pointer;
  padding: 0;
`;

export const ReportError = styled.div`
  padding: 10px 0;
  color: #E50000;
`;
