import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';

export const fetchData = ({ accessToken }) => {
  const URI = `${API}/creditrequest/form/fields`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,
      data: {},
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const patch = ({ form, accessToken }) => {
  const URI = `${API}/creditrequest/form/fields`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,

      data: {
        ...form,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const save = ({ form, accessToken }) => {
  const URI = `${API}/creditrequest/form/save`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      accessTokenCreditRequest: accessToken,

      data: {
        ...form,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchComments = ({ creditRequestId }) => {
  const URI = `${API}/creditrequest/comment/list`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),

      data: {
        creditRequestId,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};
export const sendComment = ({ creditRequestId, text }) => {
  const URI = `${API}/creditrequest/comment/add`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),

      data: {
        creditRequestId,
        text,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getDetails = ({ accessToken }) => {
  const URI = `${API}/creditrequest/status/details`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),

      data: {
        accessToken,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const sendToRevision = ({ message, accessTokenCreditRequest }) => {
  const URI = `${API}/creditrequest/credit-data/send-to-dealership`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),

      data: {
        accessTokenCreditRequest,
        message,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetch: fetchData,
  patch,
  save,
  fetchComments,
  sendComment,
  getDetails,
  sendToRevision,
};
