import {
  BREADCRUMBS_SET,
  BREADCRUMBS_BLOCK_LINKS,
  BREADCRUMBS_SHOW_UNBLOCK_MODAL,
  BREADCRUMBS_HIDE_UNBLOCK_MODAL,
  BREADCRUMBS_RESET_BLOCK_LINKS,
  BREADCRUMBS_REDIRECT,
} from './actions';

const initState = {
  list: [],
  isLinksBlock: false,
  showModal: false,
  isRedirect: false,
};

export default function breadcrumbsReducer(state = initState, action) {
  switch (action.type) {
    case BREADCRUMBS_SET: {
      return {
        ...state,
        list: Array.isArray(action.tree) ? action.tree : state.list,
      };
    }

    case BREADCRUMBS_BLOCK_LINKS: {
      return {
        ...state,
        isLinksBlock: true,
      };
    }

    case BREADCRUMBS_SHOW_UNBLOCK_MODAL: {
      return {
        ...state,
        showModal: true,
      };
    }

    case BREADCRUMBS_HIDE_UNBLOCK_MODAL: {
      return {
        ...state,
        showModal: false,
      };
    }

    case BREADCRUMBS_REDIRECT: {
      return {
        ...state,
        isRedirect: true,
      };
    }

    case BREADCRUMBS_RESET_BLOCK_LINKS: {
      return {
        ...state,
        showModal: false,
        isLinksBlock: false,
        isRedirect: false,
      };
    }

    default: {
      return state;
    }
  }
}
