import {
  ADMIN_CREATE_MERCHANT,
  ADMIN_CREATE_MERCHANT__SUCCESS,
  ADMIN_CREATE_MERCHANT__FAILURE,
  ADMIN_CREATE_MERCHANT__RESET,
} from './actions';

const initState = {
  create: {
    inn: null,
    data: {},
    user: {},
    isLoading: false,
    isLoaded: false,
    error: null,
    errors: [],
  },
};

export default function adminReducer(state = initState, action) {
  switch (action.type) {
    case ADMIN_CREATE_MERCHANT: {
      return {
        ...state,

        create: {
          inn: action.inn,
          data: {
            name: action.name,
            admin: action.admin,
            users: action.users,
          },
          user: {},
          isLoading: true,
          isLoaded: false,
          error: null,
          errors: [],
        },
      };
    }
    case ADMIN_CREATE_MERCHANT__SUCCESS: {
      return {
        ...state,

        create: {
          ...state.create,

          user: action.user,
          isLoading: false,
          isLoaded: true,
          error: null,
          errors: [],
        },
      };
    }
    case ADMIN_CREATE_MERCHANT__FAILURE: {
      return {
        ...state,

        create: {
          ...state.create,

          isLoading: false,
          isLoaded: false,
          error: action.error,
          errors: Array.isArray(action.errors) ? action.errors : [],
        },
      };
    }

    case ADMIN_CREATE_MERCHANT__RESET: {
      return {
        ...state,

        create: {
          ...initState.create,
        },
      };
    }

    default: {
      return state;
    }
  }
}
