import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import {
  create as createAction,
  resetError as resetErrorAction,
  resetForm as resetFormAction,
  fetchDialingDates as fetchDialingDatesAction,
  resetCampaign as resetCampaignAction,
  setCampaignStatus as setCampaignStatusAction,
} from 'redux/tcmCampaign/actions';

import { fetchSubjectList as fetchSubjectListAction } from 'redux/tcmTopics/actions';

import { ArrowIcon } from 'icons';
import { getStringFromDate } from 'helpers/date';

import { TCM } from 'components/tcm';
import {
  CreateDraftSuccessfulModal, CreateErrorModal, CreateSuccessfulModal, SaveFormModal,
} from 'components/tcm/Modals';

import Loader from 'components/tcm/Loader';
import { StatusLine } from 'components/tcm/StatusLine/StatusLine';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import RejectCampaign from '../RejectCampaign';
import { KEYS as K } from './data';
import {
  FormButtonsStroke,
  FormRow,
  Title,
  Wrapper,
  StatusHistoryContainer,
  ReadOnlyFieldContainer,
  ReadOnlyField,
  ReadOnlyFieldTitle,
  CommentContainer,
  CommentsContainer,
  CommentText,
} from './style';
import { BackButtonContainer } from '../style';

const CreateCampaignForm = ({
   // passed
  isStatusDraft,
  isStatusRevision,
  campaign,
  // store
  subjectTopics,
  subjectSubtopics,
  subjectIsLoading,
  subjectIsLoaded,
  subjectError,

  createIsLoading,
  createIsLoaded,
  createData,
  createError,
  createErrors,

  campaignIsLoading,

  dialingDates,
  dialingDatesIsLoading,
  // dialingDatesIsLoaded,
  dialingDatesError,

  setCampaignStatusIsLoading,
  setCampaignStatusIsLoaded,
  setCampaignStatusError,

  // actions
  fetchSubjectList,
  create,
  resetError,
  resetForm,
  resetCampaign,
  fetchDialingDates,
  setCampaignStatus,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isValidated, setIsValidated] = useState(true);
  const [subTopics, setSubTopics] = useState([]);
  const [initialValue, setInitialValue] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [showVINSuccess, setShowVINSuccess] = useState(false);
  const [showDraftSaved, setShowDraftSaved] = useState(false);
  const [showDraftModalConfirmation, setShowDraftConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [readOnlyComments, setReadOnlyComments] = useState([]);

  const [isRequestKP, setIsRequestKP] = useState(false);

  useEffect(() => {
    if (setCampaignStatusIsLoaded) {
      history.goBack();
    }
  }, [history, setCampaignStatusIsLoaded]);

  useEffect(() => {
    if (!(isStatusDraft || isStatusRevision)) {
      resetCampaign();
    }
  }, [resetCampaign, isStatusDraft, isStatusRevision]);

  useEffect(() => () => resetForm(), [resetForm]);

  useEffect(() => {
    fetchSubjectList();
  }, [fetchSubjectList]);

  useEffect(() => {
    fetchDialingDates();
  }, [fetchDialingDates]);

  useEffect(() => {
    if (!isValidated && !createIsLoading && Array.isArray(createErrors) && createErrors.length > 0) {
      const errorsFlat = createErrors.map((f) =>
        f.fieldName
          .replace('data.', '')
          .split('.')
          .reduceRight((p, k) => ({ [k]: p }), f.messages[0]));

      const {
        [K.SUB_THEME.apiKey]: subtopicId,
        [K.COUNT.apiKey]: countCarsInStock,
        [K.DATE_FROM.apiKey]: dialingFrom,
        [K.DATE_TO.apiKey]: dialingTo,
        [K.COMMENT.apiKey]: comment,
      } = errorsFlat.reduce((p, c) => ({ ...p, ...c }), {});

      form.setFields([
        ...(subtopicId ? [{ name: K.SUB_THEME.key, errors: [subtopicId] }] : []),
        ...(countCarsInStock ? [{ name: K.COUNT.key, errors: [countCarsInStock] }] : []),
        ...(dialingFrom ? [{ name: K.DATE_FROM.key, errors: [dialingFrom] }] : []),
        ...(dialingTo ? [{ name: K.DATE_TO.key, errors: [dialingTo] }] : []),
        ...(comment ? [{ name: K.COMMENT.key, errors: [comment] }] : []),
      ]);
      setIsValidated(true);
    }
  }, [createErrors, createIsLoading, form, isValidated, setIsValidated]);

  useEffect(() => {
    if ((isStatusDraft || isStatusRevision) && subjectIsLoaded && campaign?.topicId) {
      const subTopics = subjectSubtopics.filter(
        (subTopic) => subTopic.topicItemResponseList[0].id === campaign.topicId,
      );
      setSubTopics(subTopics);
    }
  }, [isStatusDraft, isStatusRevision, subjectIsLoaded, subjectSubtopics, setSubTopics, campaign]);

  useEffect(() => {
    if (createIsLoaded) {
      if (createData.status === 'VIN_REQUEST') {
        setShowVINSuccess(true);
      }
      if (createData.status === 'KP_CALCULATION') {
        setShowVINSuccess(true);
      }
      if (createData.status === 'DRAFT') {
        setShowDraftSaved(true);
      }
    }
  }, [createIsLoaded, createData, setShowDraftSaved, setShowVINSuccess]);

  useEffect(() => {
    if (campaign && campaign.id && (isStatusDraft || isStatusRevision)) {
      const {
        dialingFromRaw,
        dialingToRaw,
        countCarsInStock,
        comments,
      } = campaign;

      const text = Array.isArray(comments) && comments.length > 0 ? comments[0].text : '';

      form.setFields([
        ...(countCarsInStock ? [{ name: K.COUNT.key, value: countCarsInStock, touched: true }] : []),
        ...(dialingFromRaw ? [{ name: K.DATE_FROM.key, value: moment(dialingFromRaw), touched: true }] : []),
        ...(dialingToRaw ? [{ name: K.DATE_TO.key, value: moment(dialingToRaw), touched: true }] : []),
        ...(!isStatusRevision && text ? [{ name: K.COMMENT.key, value: text, touched: true }] : []),
      ]);

      setReadOnlyComments(comments);
    }
  }, [campaign, isStatusDraft, isStatusRevision, form]);

  useEffect(() => {
    if (subjectIsLoaded && campaign && campaign.id && (isStatusDraft || isStatusRevision)) {
      const {
        topicId,
        subtopicId,
      } = campaign;

      form.setFields([
        ...(subtopicId ? [{ name: K.SUB_THEME.key, value: subtopicId, touched: true }] : []),
        ...(topicId ? [{ name: K.THEME.key, value: topicId, touched: true }] : []),
      ]);
    }
  }, [subjectIsLoaded, campaign, isStatusDraft, isStatusRevision, form]);

  useEffect(() => {
    if (subjectError) {
      form.setFields([{ name: K.SUB_THEME.key, error: subjectError }]);
    }
  }, [subjectError, form]);

  useEffect(() => {
    if (dialingDatesError) {
      form.setFields([{ name: K.DATE_FROM.key, error: dialingDatesError }]);
    }
  }, [dialingDatesError, form]);

  useEffect(() => {
    if (campaignIsLoading) {
      setInitialValue(form.getFieldsValue());
    }
  }, [form, form.getFieldsValue, campaignIsLoading]);

  useEffect(() => {
    if (campaign && campaign.statusHistory) {
      const statusKP = campaign.statusHistory.find((item) => item.code === 'KP_CALCULATION');
      setIsRequestKP(statusKP && statusKP.timestamp === 'DONE');
    }
  }, [campaign]);

  const onFormChange = useCallback(
    (field) => {
      const [key, value] = Object.entries(field)[0];

      if (key === 'DATE_FROM' && moment(value).isValid()) {
        fetchDialingDates({
          dialingFrom: moment(value).format('YYYY-MM-DD'),
        });
        form.setFieldsValue({
          [K.DATE_TO.key]: undefined,
        });
      }
      setIsFormChanged(!isEqual(initialValue, form.getFieldsValue()));
    },
    [form, initialValue, fetchDialingDates],
  );

  const onFinish = useCallback((values) => {
    setIsDraft(false);
    const {
      [K.SUB_THEME.key]: subtopicId,
      [K.COUNT.key]: countCarsInStock,
      [K.DATE_FROM.key]: dialingFrom,
      [K.DATE_TO.key]: dialingTo,
      [K.COMMENT.key]: comment,
    } = values;

    create({
      status: isRequestKP ? 'KP_CALCULATION' : 'VIN_REQUEST',
      subtopicId,
      countCarsInStock: Number(countCarsInStock),
      dialingFrom: dialingFrom.format('YYYY-MM-DD'),
      dialingTo: dialingTo.format('YYYY-MM-DD'),
      comment,
    });
    setIsValidated(false);
  }, [create, isRequestKP]);

  const handleToDraft = useCallback(() => {
    setIsDraft(true);
    const {
      [K.SUB_THEME.key]: subtopicId,
      [K.COUNT.key]: countCarsInStock,
      [K.DATE_FROM.key]: dialingFrom,
      [K.DATE_TO.key]: dialingTo,
      [K.COMMENT.key]: comment,
    } = form.getFieldsValue();

    create({
      status: 'DRAFT',
      subtopicId,
      countCarsInStock: countCarsInStock ? Number(countCarsInStock) : null,
      dialingFrom: dialingFrom?.format('YYYY-MM-DD'),
      dialingTo: dialingTo?.format('YYYY-MM-DD'),
      comment,
    });
    setIsValidated(false);
  }, [create, form]);

  const handleBlur = useCallback(({ target: { name } }) => {
    form.validateFields([name]);
  }, [form]);

  const handleFocus = useCallback(({ target: { name } }) => {
    form.setFields([{ name, errors: [] }]);
  }, [form]);

  const onSuccessfulModalClose = useCallback(() => {
    resetForm();
    history.goBack();
  }, [history, resetForm]);

  const onCreteDraftModalClose = useCallback(() => {
    setShowDraftSaved(false);
  }, []);

  const onSuccessfulModalSubmit = useCallback(() => {
    handleToDraft();
  }, [handleToDraft]);

  const onModalClose = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const onModalDraftConfirmationClose = useCallback(() => {
    setShowDraftConfirmation(false);
  }, []);

  const onModalDraftConfirmationSuccess = useCallback(() => {
    setShowDraftConfirmation(false);
    handleToDraft();
  }, [handleToDraft]);

  const onErrorModalClose = useCallback(() => { resetError(); }, [resetError]);

  const expectedDate = useMemo(
    () => (createData && getStringFromDate(new Date(createData.deadline))) || '',
    [createData],
  );

  const onTopicSelect = useCallback((topic) => {
    const subTopics = subjectSubtopics.filter(
      (subTopic) => subTopic.topicItemResponseList[0].id === topic,
    );
    setSubTopics(subTopics);
    form.setFieldsValue({ [K.SUB_THEME.key]: undefined });
  }, [form, subjectSubtopics]);

  const disabledDatesFrom = (current) => current
    && (current < moment(dialingDates.dialingFrom).startOf('day'));

  const disabledDatesTo = (current) => current
    && current < moment(dialingDates.dialingTo).endOf('day');

  const onBack = () => {
    if (isFormChanged) {
      setShowConfirmation(true);
    } else {
      history.goBack();
    }
  };

  const handleShowModal = () => {
    if (isStatusRevision) {
      setShowRejectModal(true);
    } else {
      setShowConfirmation(true);
    }
  };

  useEffect(() => {
    if (showConfirmation && isDraft && createIsLoaded) {
      resetForm();
      history.goBack();
    }
  }, [createIsLoaded, history, isDraft, resetForm, showConfirmation]);

  useEffect(() => {
    if (showDraftSaved && isDraft && createIsLoaded) {
      setTimeout(() => {
        resetForm();
        history.goBack();
      }, 3000);
    }
  }, [createIsLoaded, history, isDraft, resetForm, showDraftSaved]);

  return (
    <Wrapper>
      <TCM.ButtonText onClick={onBack}>
        <BackButtonContainer>
          <ArrowIcon left />
          Назад
        </BackButtonContainer>
      </TCM.ButtonText>

      {campaignIsLoading ? (
        <Loader text="Загрузка данных" />
      ) : (
        <>

          {(isStatusDraft || isStatusRevision) && (
            <StatusHistoryContainer>
              <StatusLine statuses={campaign.statusHistory} />
            </StatusHistoryContainer>
          )}

          <Title>
            {
              isStatusDraft ? 'Редактирование черновика'
                : isStatusRevision ? 'Доработка кампании' : 'Новая кампания'
            }
          </Title>

          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={onFormChange}
          >
            {isStatusRevision && (
              <ReadOnlyFieldContainer>
                <ReadOnlyFieldTitle>ID</ReadOnlyFieldTitle>
                <ReadOnlyField>{campaign?.id || ''}</ReadOnlyField>
              </ReadOnlyFieldContainer>
            )}
            <FormRow>
              <TCM.Label
                name={K.THEME.key}
                label={K.THEME.label}
                rules={K.THEME.rules}
                validateTrigger="onBlur"
              >
                <TCM.Select
                  name={K.THEME.key}
                  placeholder="Выберите тему"
                  onFocus={(e) => handleFocus(e, K.THEME.key)}
                  onBlur={(e) => handleBlur(e, K.THEME.key)}
                  loading={subjectIsLoading}
                  onSelect={onTopicSelect}
                >
                  {subjectTopics.map((option) => (
                    <TCM.Option key={option.id} value={option.id}>
                      {option.name}
                    </TCM.Option>
                  ))}
                </TCM.Select>
              </TCM.Label>

              <TCM.Label
                name={K.SUB_THEME.key}
                label={K.SUB_THEME.label}
                rules={K.SUB_THEME.rules}
                validateTrigger="onBlur"
              >
                <TCM.Select
                  name={K.SUB_THEME.key}
                  placeholder="Выберите подтему"
                  onFocus={(e) => handleFocus(e, K.SUB_THEME.key)}
                  onBlur={(e) => handleBlur(e, K.SUB_THEME.key)}
                  loading={subjectIsLoading}
                >
                  {subTopics.map((option) => (
                    <TCM.Option key={option.id} value={option.id}>
                      {option.name}
                    </TCM.Option>
                  ))}
                </TCM.Select>
              </TCM.Label>
            </FormRow>

            <FormRow>
              <TCM.Label
                name={K.COUNT.key}
                label={K.COUNT.label}
                rules={K.COUNT.rules}
                validateTrigger="onBlur"
              >
                <TCM.InputNumber
                  placeholder="Укажите число"
                  name={K.COUNT.key}
                  min={1}
                  precision={0}
                />
              </TCM.Label>
              <TCM.StaticDateRange
                propsLabelFrom={{
                  name: K.DATE_FROM.key,
                  label: K.DATE_FROM.label,
                  rules: K.DATE_FROM.rules,
                  validateTrigger: 'onBlur',
                }}
                propsInputFrom={{
                  name: K.DATE_FROM.key,
                  disabledDate: disabledDatesFrom,
                  disabled: dialingDatesIsLoading,
                  defaultPickerValue: moment(dialingDates.dialingFrom),
                }}
                propsLabelTo={{
                  name: K.DATE_TO.key,
                  label: K.DATE_TO.label,
                  rules: K.DATE_TO.rules,
                  validateTrigger: 'onBlur',
                }}
                propsInputTo={{
                  name: K.DATE_TO.key,
                  disabledDate: disabledDatesTo,
                  disabled: dialingDatesIsLoading,
                  defaultPickerValue: moment(dialingDates.dialingTo),
                }}
              />
            </FormRow>

            {readOnlyComments.length > 0 && isStatusRevision && (
              <CommentsContainer>
                <ReadOnlyField>Комментарии</ReadOnlyField>
                {readOnlyComments.map((item) => (
                  <CommentContainer>
                    <ReadOnlyFieldTitle>{(item?.author && item.author.name) ?? 'Банк'}</ReadOnlyFieldTitle>
                    <CommentText>
                      <ReadOnlyField>{item?.text}</ReadOnlyField>
                      <ReadOnlyFieldTitle>
                        {item?.createdAt || ''}
                      </ReadOnlyFieldTitle>
                    </CommentText>
                  </CommentContainer>
                ))}
              </CommentsContainer>
            )}

            <TCM.Label
              name={K.COMMENT.key}
              label={isStatusRevision ? 'Новый комментарий' : K.COMMENT.label}
              rules={K.COMMENT.rules}
            >
              <TCM.Textarea
                placeholder="Введите текст"
                name={K.COMMENT.key}
              />
            </TCM.Label>

            <FormButtonsStroke>
              <TCM.ButtonGroup>
                <Form.Item shouldUpdate noStyle>
                  {() => (
                    <TCM.Button
                      htmlType="submit"
                      disabled={!form.isFieldsTouched(
                        [
                          K.THEME.key,
                          K.SUB_THEME.key,
                          K.COUNT.key,
                          K.DATE_FROM.key,
                          K.DATE_TO.key,
                        ], true,
                      ) || form.getFieldsError().some(({ errors }) => errors.length)}
                      loading={!isDraft && createIsLoading}
                    >
                      {isRequestKP ? 'Отправить в банк' : 'Запросить VIN'}
                    </TCM.Button>
                  )}
                </Form.Item>

                {!isStatusRevision && (
                  <Form.Item shouldUpdate noStyle>
                    {() => (
                      <TCM.ButtonOutline
                        htmlType="button"
                        loading={isDraft && createIsLoading}
                        disabled={!form.getFieldValue(K.SUB_THEME.key)}
                        // если нужно показывать модальное окно при сохранение в черновик
                        // onClick={() => setShowDraftConfirmation(true)}
                        onClick={() => onModalDraftConfirmationSuccess()}
                      >
                        Сохранить черновик
                      </TCM.ButtonOutline>
                    )}
                  </Form.Item>
                )}
              </TCM.ButtonGroup>
              {!isStatusDraft
                  && (
                    <TCM.ButtonText
                      type="button"
                      onClick={() => handleShowModal()}
                    >
                      {isStatusRevision ? 'Отозвать кампанию' : 'Отменить'}
                    </TCM.ButtonText>
                  )}
              {campaign && isStatusDraft && (
                <TCM.ButtonText
                  type="button"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Удалить черновик
                </TCM.ButtonText>
              )}
            </FormButtonsStroke>
          </Form>
        </>
      )}

      <CreateSuccessfulModal
        expectedDate={expectedDate}
        visible={showVINSuccess}
        onCancel={onSuccessfulModalClose}
        title={isRequestKP ? 'Автомобили с оценкой отправлены в банк' : 'Запрос VIN направлен в Банк'}
        description={`Ожидаемая дата получения ${isRequestKP ? 'расчета КП' : 'VIN номеров'}:`}
        btnTextCancel="Закрыть"
      />

      <CreateErrorModal
        visible={Boolean(createError)}
        onCancel={onErrorModalClose}
        title="Ошибка"
        description="Попробуйте позднее или обратитесь в техподдержку по телефону: +7 (800) 200-08-40"
        btnTextCancel="Закрыть"
      />

      <CreateDraftSuccessfulModal
        visible={showDraftSaved}
        onCancel={onCreteDraftModalClose}
        title="Черновик успешно сохранен"
      />

      <SaveFormModal
        visible={showConfirmation}
        onCancel={onSuccessfulModalClose}
        onOk={onSuccessfulModalSubmit}
        onClose={onModalClose}
        title="Сохранить новую кампанию перед закрытием?"
        btnTextOk="Да, сохранить"
        btnTextCancel="Нет, закрыть без сохранения"
      />

      <SaveFormModal
        visible={showDraftModalConfirmation}
        onCancel={onSuccessfulModalClose}
        onOk={onModalDraftConfirmationSuccess}
        onClose={onModalDraftConfirmationClose}
        title="Сохранить кампанию перед закрытием?"
        btnTextOk="Да, сохранить"
        btnTextCancel="Нет, закрыть без сохранения"
      />

      <RejectCampaign
        close={() => setShowRejectModal(false)}
        visible={showRejectModal}
      />

      <SaveFormModal
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onOk={() => setCampaignStatus({ id: campaign.id, status: CAMPAIGN_STATUSES.DELETED })}
        onOkLoading={setCampaignStatusIsLoading}
        onOkError={setCampaignStatusError && 'Ошибка сети'}
        onClose={() => setShowDeleteModal(false)}
        title="Удалить черновик кампании?"
        btnTextOk="Да, удалить"
        btnTextCancel="Нет, не удалять"
      />

    </Wrapper>
  );
};

export default connect(
  (state) => ({
    subjectTopics: state.tcmTopics.subjects.topics,
    subjectSubtopics: state.tcmTopics.subjects.subtopics,
    subjectIsLoading: state.tcmTopics.subjects.isLoading,
    subjectIsLoaded: state.tcmTopics.subjects.isLoaded,
    subjectError: state.tcmTopics.subjects.error,

    createIsLoading: state.tcmCampaign.create.isLoading,
    createIsLoaded: state.tcmCampaign.create.isLoaded,
    createData: state.tcmCampaign.create.data,
    createError: state.tcmCampaign.create.error,
    createErrors: state.tcmCampaign.create.errors,

    campaignIsLoading: state.tcmCampaign.campaign.isLoading,

    dialingDates: state.tcmCampaign.dialingDates.data,
    dialingDatesIsLoading: state.tcmCampaign.dialingDates.isLoading,
    dialingDatesIsLoaded: state.tcmCampaign.dialingDates.isLoaded,
    dialingDatesError: state.tcmCampaign.dialingDates.error,

    setCampaignStatusIsLoading: state.tcmCampaign.campaignsSet.isLoading,
    setCampaignStatusIsLoaded: state.tcmCampaign.campaignsSet.isLoaded,
    setCampaignStatusError: state.tcmCampaign.campaignsSet.error,
  }),
  {
    fetchSubjectList: fetchSubjectListAction,
    create: createAction,
    resetError: resetErrorAction,
    resetForm: resetFormAction,
    resetCampaign: resetCampaignAction,

    fetchDialingDates: fetchDialingDatesAction,

    setCampaignStatus: setCampaignStatusAction,
  },
)(CreateCampaignForm);
