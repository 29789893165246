import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import { Upload } from 'antd';
import { Option } from 'components/Select';
import { getOptionsList } from 'hacks';
import { useForm } from 'hooks';
import { TYPES, FILE_SLOTS, FIXBLOCK_TYPES } from 'settings';

import { FileAddOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  patch as patchRequest,
  uploadFiles,
  deleteFile,
  fetchFile,
  removePreview,
} from 'redux/request/actions';

import {
  Root,
  Header,
  ErrorMessage,
  Content,
  Footer,
  SelectStyled,
  FileItem,
  FileHeader,
  FileIcon,
  FileName,
  FileTitle,
  FileFooter,
  FileDelete,
  FileError,
  FileEmpty,
  FileEmptyText,
  FileLoader,
  FileLoaderText,
} from './style';

import Preview from '../Preview';

const SECOND_DOCUMENT_FIELD = FILE_SLOTS.second.options;

const ellipsis = { rows: 1, expandable: false };

function FileList({
  // passed
  name,
  typeList,
  changeType,
  formIsDisabled,

  // connect
  requestTree,
  requestFiles,
  requestPreviews,

  // actions
  patchRequest,
  uploadFiles,
  deleteFile,
  fetchFile,
  removePreview,
}) {
  const scrollableNode = useRef();
  const [isScrolled, setIsScrolled] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const block = typeList.find((e) => e.value === name);
    if (!isScrolled && ((block && block.disabled) || block === undefined)) {
      setIsScrolled(true);
      const node = scrollableNode.current;
      if (node) {
        node.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }
    }
  }, [typeList, isScrolled, setIsScrolled, name]);

  const [type, setType] = useState(
    (typeList.find((e) => e.value === name) || {}).value || undefined,
  );
  function handleTypeChange(v) {
    setType(v);
    changeType(name, v);
  }

  const { error = '', data = [] } = requestTree.find((e) => e.name === type) || { data: [] };

  const selects = data.filter((e) => e.type === TYPES.SELECT);
  const { form, setForm, handleInputChange } = useForm(
    selects.reduce((p, c) => ({ ...p, [c.name]: c.value }), Object.create(null)),
  );

  function handleOnChange({ name, value }) {
    handleInputChange({ name, value });

    // in order to reset the values that are dependent on the first one, we exclude them from form/fields request
    const exclude = selects.length > 1 && selects[0].name === name
      ? selects.slice(1).map((e) => e.name)
      : [];

    // we need to save this value
    patchRequest({
      userInput: {
        [name]: String(value),
      },
      exclude,
    });

    if (exclude.length > 0) {
      setForm({ [name]: value });
    }
  }

  // Secondary Document's field is not a child of parent FileBlock (every FileBlock has
  // .data (array of fields) property)
  // So we target it directly from the form
  const secondaryTypeField = requestTree.find(
    (e) => e.name === SECOND_DOCUMENT_FIELD,
  ) || { options: [] };
  const [typeSecondary, setTypeSecondary] = useState(
    secondaryTypeField.value || undefined,
  );
  useEffect(() => {
    if (secondaryTypeField.value) {
      setTypeSecondary(secondaryTypeField.value);
    }
  }, [secondaryTypeField.value]);

  const files = Array.isArray(data)
    ? data.filter((e) => e.type === FIXBLOCK_TYPES.DOCUMENT)
    : [];
  const fileNames = files.map((e) => e.name);
  const filesToUpload = fileNames.filter(
    (name) =>
      !(
        requestFiles[name].value
        || requestFiles[name].isLoading
        || requestFiles[name].disabled
      ),
  );
  const renderUploadButton = filesToUpload.length > 0 && !formIsDisabled;

  function handleBeforeUpload(file, fileList) {
    setFileList(fileList);

    if (fileList.slice(-1)[0].uid === file.uid) {
      // just to make sure that we take enough files to upload in empty slots
      const filledSlots = Array.from(
        {
          length: Math.min(filesToUpload.length, fileList.length),
        },
        (_, i) => ({ fieldName: filesToUpload[i], file: fileList[i] }),
      );

      uploadFiles({
        fileList: filledSlots,
        ...(typeSecondary ? { documentType: typeSecondary } : {}),
      });
    }

    return false; // explicitly return `false` to get manual control
  }

  function handleFileDelete(fieldName) {
    deleteFile({ fieldName });
  }

  const disabled = type === FILE_SLOTS.second.name ? !typeSecondary : !selects.every((e) => e.value);
  const previews = fileNames.filter(
    (e) =>
      requestPreviews[e]
      && (requestPreviews[e].isLoading
        || requestPreviews[e].isLoaded
        || requestPreviews[e].error),
  );

  return (
    <Root ref={scrollableNode}>
      <Header>
        <SelectStyled
          name="type"
          placeholder="Выберите тип файла"
          getPopupContainer={(trigger) => trigger.parentNode}
          value={type}
          onChange={handleTypeChange}
          disabled={formIsDisabled}
        >
          {typeList.map(({ label, value, disabled }) => (
            <Option key={value} value={value} disabled={disabled}>
              {label}
            </Option>
          ))}
        </SelectStyled>
        {type === FILE_SLOTS.second.name ? (
          <SelectStyled
            name="type"
            placeholder="Выберите тип документа"
            getPopupContainer={(trigger) => trigger.parentNode}
            value={typeSecondary}
            onChange={setTypeSecondary}
            disabled={formIsDisabled}
          >
            {getOptionsList(secondaryTypeField.options).map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </SelectStyled>
        ) : (
          <>
            {selects.map((select) => (
              <SelectStyled
                name={select.name}
                placeholder={select.placeholder}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={form[select.name] || undefined}
                onChange={(value) => handleOnChange({ name: select.name, value })}
                disabled={formIsDisabled}
              >
                {getOptionsList(select.options).map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </SelectStyled>
            ))}
          </>
        )}
      </Header>
      <div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Content>
          {fileNames
            .filter((e) => requestFiles[e].status)
            .map((file) => (
              <Fragment key={file}>
                {requestFiles[file].isLoading ? (
                  <FileItem>
                    <FileLoader />
                    <FileLoaderText>{requestFiles[file].status}</FileLoaderText>
                  </FileItem>
                ) : (
                  <>
                    {requestFiles[file].value && (
                      <div>
                        <FileItem error={requestFiles[file].error}>
                          <FileHeader
                            title="Загрузить превью документа"
                            onClick={() => fetchFile({ name: file })}
                          >
                            <FileIcon />
                            <FileTitle>
                              <FileName strong ellipsis={ellipsis}>
                                {requestFiles[file].label}
                              </FileName>
                            </FileTitle>
                          </FileHeader>
                          <FileFooter>
                            <FileDelete
                              type="danger"
                              size="small"
                              ghost
                              onClick={() => handleFileDelete(file)}
                              disabled={requestFiles[file].disabled || formIsDisabled}
                            >
                              <DeleteOutlined />
                              {' '}
                              удалить
                            </FileDelete>
                          </FileFooter>
                        </FileItem>
                        <FileError>{requestFiles[file].error}</FileError>
                      </div>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          {renderUploadButton && (
            <Upload
              beforeUpload={handleBeforeUpload}
              fileList={fileList}
              multiple
              disabled={disabled}
            >
              <FileEmpty disabled={disabled}>
                <FileAddOutlined />
                <FileEmptyText>Выберите файл</FileEmptyText>
              </FileEmpty>
            </Upload>
          )}
        </Content>
      </div>
      {previews.length > 0 && (
        <Footer>
          {previews.map((fileName) => (
            <Preview
              key={fileName}
              name={fileName}
              file={requestPreviews[fileName]}
              remove={removePreview}
            />
          ))}
        </Footer>
      )}
    </Root>
  );
}

export default connect(
  (state) => ({
    requestTree: state.request.fieldsFlat,
    requestIsLoading: state.request.fetch.isLoading,
    requestIsLoaded: state.request.fetch.isLoaded,
    requestError: state.request.fetch.error,
    requestUploads: state.request.uploads,
    requestFiles: state.request.files,
    requestPreviews: state.request.previews,
  }),
  {
    patchRequest,
    uploadFiles,
    deleteFile,
    fetchFile,
    removePreview,
  },
)(FileList);
