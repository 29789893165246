import React from 'react';
import { connect } from 'react-redux';
import { remove as closeModal } from 'redux/modals/actions';
import Switch from './Switch';

import { Root } from './style';

function Modals({
  // connect
  modals,

  // actions
  closeModal,
}) {
  return <Root>{modals.map((field) => Switch({ field, closeModal }))}</Root>;
}

export default connect(
  (state) => ({
    modals: state.modals,
  }),
  {
    closeModal,
  },
)(Modals);
