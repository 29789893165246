import styled, { css } from 'styled-components';
import { InputNumber as AntInputNumber } from 'antd';
import withTheme from './withTheme';

export const InputNumber = withTheme(styled(AntInputNumber).attrs(({ min = 0 }) => ({
  min,
}))`
  ${({ theme }) => css`
    padding: ${theme.inputNumber.padding};
    width: 100%;
    border-color: ${theme.input.borderColor};
    border-radius: ${theme.input.borderRadius}px;
    font-family: ${theme.fontFamily};
    font-weight: ${theme.input.fontWeight};
    font-size: ${theme.input.fontSize}px;
    line-height: ${theme.input.lineHeight}px;
    color: ${theme.input.textColor};
    box-sizing: border-box;
    
    input {
      padding-left: 0;
    }

    &::placeholder {
      font-weight: ${theme.input.fontWeightPlaceholder};
      font-size: ${theme.input.fontSize}px;
      line-height: ${theme.input.lineHeight}px;
      color: ${theme.input.textPlaceholderColor};
      opacity: 1;
    }

    &.invalid {
      border-color ${theme.input.borderInvalidColor};
    }
  `}
`);
