import React from 'react';
import moment from 'moment';

import {
  InfoIcon,
  CallIcon,
  CarIcon,
  NewIcon,
  NoCallIcon,
  RepeatIcon,
  VisitIcon,
} from 'icons';
import { getRecallDateText } from 'tcm/offer/format';
import { InfoIconContainer } from './style';

export const CLIENTS_STATUS_MAP = {
  CALL_BACK_LATER: {
    text: (statusDescriptions) => {
      if (!Array.isArray(statusDescriptions)) {
        return 'Нет звонков';
      }
      const statusDefault = 'DATE_TIME_OF_CALL_BACK';
      const { value = moment() } = statusDescriptions.find((item) => item.key === statusDefault) || {};

      const today = moment();
      const date = moment(value);

      return (
        <>
          {`Перезвонить ${getRecallDateText(value)}`}
          { today.isAfter(date) && (
            <InfoIconContainer>
              <InfoIcon />
            </InfoIconContainer>
          )}
        </>
      );
    },
    icon: <CallIcon />,
  },

  CALL_BACK: {
    text: (statusDescriptions) => {
      if (!Array.isArray(statusDescriptions)) {
        return 'Нет звонков';
      }
      const statusDefault = 'DATE_TIME_OF_CALL_BACK';
      const { value = moment() } = statusDescriptions.find((item) => item.key === statusDefault) || {};

      const today = moment();
      const tomorrow = moment().add(1, 'days');
      const date = moment(value);

      switch (date) {
        case date.isSame(today, 'day'):
          return `Перезвонить сегодня в ${date.format('HH:mm')}`;
        case date.isSame(tomorrow, 'day'):
          return `Перезвонить завтра в ${date.format('HH:mm')}`;
        default:
          return (
            <>
              {`Перезвонить ${date.format('DD.MM.YYYY')} в ${date.format('HH:mm')}`}
              { today.isAfter(date) && (
                <InfoIconContainer>
                  <InfoIcon />
                </InfoIconContainer>
              )}
            </>
          );
      }
    },
    icon: <CallIcon />,
  },

  CLIENT_WILL_COME_TO_DC: {
    text: () => 'Клиент приедет в ДЦ',
    icon: <CarIcon />,
  },

  CALL_ANOTHER_PERSON: {
    text: () => 'Перезвонить другому человеку',
    icon: <CallIcon />,
  },

  NOT_CLIENT_ANSWERED: {
    text: () => 'Ответил не клиент',
    icon: <CallIcon />,
  },

  PROPOSAL_IS_NOT_INTERESTING: {
    text: () => 'Предложение неинтересно',
    icon: <NoCallIcon />,
  },

  NEGATIVE_CLIENT_NOT_CALL: {
    text: () => 'Негатив клиента, не звонить',
    icon: <NoCallIcon />,
  },

  DO_NOT_CALL_BACK: {
    text: () => 'Не перезванивать',
    icon: <NoCallIcon />,
  },

  REVISION: {
    text: () => 'Доработка',
    icon: <RepeatIcon />,
  },

  WAITING: {
    text: () => 'Ждет новую модель',
    icon: <NewIcon />,
  },

  EVALUATION_TRADE_IN: {
    text: () => 'Визит: оценка Trade-in',
    icon: <VisitIcon />,
  },

  TEST_DRIVE: {
    text: () => 'Визит: тест драйв',
    icon: <VisitIcon />,
  },

  FILING_AN_APPLICATION: {
    text: () => 'Визит: подача заявки',
    icon: <VisitIcon />,
  },

  LOAN_AGREEMENT_EXECUTION: {
    text: () => 'Визит: оформление кредитного договора',
    icon: <VisitIcon />,
  },
};

const VISITS = {
  LOAN_AGREEMENT_EXECUTION: 'оформление кредитного договора',
  FILING_AN_APPLICATION: 'подача заявки',
  TEST_DRIVE: 'тест драйв',
  EVALUATION_TRADE_IN: 'оценка Trade-in',
};

export const CLIENTS_STATUSES_MAP = (statuses) => ({
  text: () => statuses.reduce((prev, curr) => `${prev} ${VISITS[curr]},`, 'Визит:').replace(/[,]$/gm, ''),
  icon: <VisitIcon />,
});
