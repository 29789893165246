import styled, { css } from 'styled-components';
import { Slider as AntdSlider } from 'antd';
import withTheme from 'components/tcm/withTheme';

export const Slider = withTheme(styled(AntdSlider)`
     margin: 10px 0;
     
     &:hover {
         .ant-slider-track {
          background: ${({ theme }) => theme.palette.accent};
        }
        
        .ant-slider-handle:not(.ant-tooltip-open) {
          border-color: rgba(229, 0, 0, .8);
        }
      }

  .ant-slider-rail {
    height: 5px;
    background: ${({ theme }) => theme.palette.gray4};
  }
  
  .ant-slider-step {
    height: 5px;
  }
  
  .ant-slider-handle{
    width: 25px;
    height: 25px;
    margin-top: -11px;
    background: #E50000;
    border-color: #E50000;
    
    &:focus {
      box-shadow: 0 0 0 5px rgba(229, 0, 0, .12);
    }
    
  }
  
  .ant-slider-track {
    background: ${({ theme }) => theme.palette.accent};
  }
  
`);

export const BlockContainer = withTheme(styled.div`
  border: 1px solid ${({ theme }) => theme.palette.gray4};
  box-sizing: border-box;
  position: relative;
  padding: 16px 12px 16px 20px;
  ${({ gridColumn }) => gridColumn && css`
    grid-column: ${gridColumn};
  `}
`);

export const BlockTitle = withTheme(styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.gray3};
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fontFamily};
`);

export const ValueContainer = styled.div`
  display: flex;
  margin-bottom: ${({ isSecondLine }) => (isSecondLine ? '3px' : '31px')}
`;

export const SecondaryValueContainer = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

export const Value = withTheme(styled.div`
  display: flex;
  font-size: 24px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  color: ${({ theme }) => theme.palette.black};
`);

export const SecondaryValue = withTheme(styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.palette.black};
`);

export const Suffix = withTheme(styled.span`
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.gray3};
  margin-left: 6px;
`);

export const SliderContainer = styled.div`
  position: absolute;
  bottom: -16px;
  width: 100%;
  left: 0;
`;

export const EditableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExtremeValue = withTheme(styled.div`
  font-size: .75em;
  color: ${({ theme }) => theme.palette.gray3};
`);
