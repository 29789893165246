import { TOYOTA_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';
import { getToken } from 'helpers/utility';

const COUNTRIES_CODE = 'C22';

export const fetchData = () => {
  const URI = `${API}/code-book/dictionary`;

  const query = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        name: COUNTRIES_CODE,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetch: fetchData,
};
