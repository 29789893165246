import React, {
  useEffect,
  useReducer,
  useState,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Popconfirm,
  notification,
} from 'antd';

import {
  setBreadcrumbs as setBreadcrumbsAction,
  breadCrumbsRedirect as breadCrumbsRedirectAction,
  breadcrumbsHideUnblockModal as breadcrumbsHideUnblockModalAction,
  breadCrumbsBlockLink as breadCrumbsBlockLinkAction,
  breadcrumbsResetBlockLinks as breadcrumbsResetBlockLinksAction,
} from 'redux/breadcrumbs/actions';

import {
  contractItemReset as contractItemResetAction,
  contactUpdate as contactUpdateAction,
  resetUpdate as resetUpdateAction,
  contractSave as contractSaveAction,
  contractSaveReset as contractSaveResetAction,
  contactFetch as contactFetchAction,
} from 'redux/contracts/actions';

import { checkPrivilege } from 'helpers/roles';

import Button from 'components/Button';
import { ArrowCollapseIcon } from 'icons';
import {
  KEYS as K,
  setDataToFields,
  formatValuesForCreateContract,
} from './data';

import { reducer, initialState, ACTIONS } from './reducer';

import AddFile from '../AddFile';
import StatusModal from '../StatusModal';
import RedirectModal from '../RedirectModal';
import PointOfSalesTable from './PointOfSalesTable';

import {
  CollapseParams,
  CollapseContacts,
  CollapseRequisites,
  CollapseBill,
  CollapseSettings,
} from './CollapseItems';

import {
  Root,
  Header,
  Title,
  ButtonsContainer,
  Main,
  CollapseContainer,
  CollapseItem,
  Footer,
  CollapseAll,
  CollapseHeader,
  TextArea,
  ErrorMessage,
  Loading,
} from './style';

const initialValues = {
};

function Contract({
  // store
  role,

  item,
  itemIsLoaded,
  itemIsLoading,

  updateIsLoaded,
  updateIsLoading,
  updateError,

  saveIsLoaded,
  saveIsLoading,
  saveError,

  unbindIsLoading,
  unbindIsLoaded,
  unbindError,

  bindIsLoading,
  bindIsLoaded,
  bindError,

  pointOfSalesBindList,
  pointOfSalesUnbindList,
  pointOfSalesIsLoaded,

  showRedirectModal,

  // actions
  // setBreadcrumbs,
  breadCrumbsRedirect,
  breadcrumbsHideUnblockModal,
  breadCrumbsBlockLink,
  breadcrumbsResetBlockLinks,

  // contractItemReset,
  contactUpdate,
  resetUpdate,
  contractSave,
  // contractSaveReset,

  contactFetch,
}) {
  // const [isMounted, setIsMounted] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isSave, setIsSave] = useState(false);
  const [itemSector, setItemSector] = useState('');
  const [itemContractNumber, setItemContractNumber] = useState('');
  // const [legalEntityName, setLegalEntityName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [unbindPointsOfSale, setUnbindPointsOfSale] = useState([]);
  const [bindPointsOfSale, setBindPointsOfSale] = useState([]);

  const history = useHistory();

  const [form] = Form.useForm();

  // const location = useLocation();
  const { contractId } = useParams();
  const isNew = false;
  const isEdit = false;

  const makeFormDirty = () => setIsFormDirty(true);
  const makeFormClean = () => setIsFormDirty(false);

  const canEdit = checkPrivilege([
    role.ACQUIRING_AGREEMENT.EDIT,
    role.ACQUIRING_AGREEMENT.CHANGE_ONLY_STATUS,
  ]);

  const canDeletePointOfSales = checkPrivilege(
    [role.ACQUIRING_AGREEMENT.EDIT],
  );

/*  const canEditField = checkPrivilege([
    role.ACQUIRING_AGREEMENT.EDIT,
  ]); */

  const isReadOnly = true;

  const canConfirm = checkPrivilege([
    role.ACQUIRING_AGREEMENT.CREATE,
    role.ACQUIRING_AGREEMENT.EDIT,
    role.ACQUIRING_AGREEMENT.CHANGE_ONLY_STATUS,
  ]);

  const openModal = () => setIsModalOpen(true);

  const closeModal = (statusChangeToDefault = false) => {
    setIsModalOpen(false);
    setIsModalStatusOpen(false);

    if (statusChangeToDefault) {
      const { status } = item || {};

      form.setFieldsValue({
        [K.STATUS.key]: status,
      });
    }
  };

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  function handleCollapse(key) {
    dispatch({ type: ACTIONS.OPEN, key });
  }

  function handleShowAll() {
    dispatch({ type: ACTIONS.SHOW_ALL });
  }

  function handleCloseAll() {
    dispatch({ type: ACTIONS.CLOSE_ALL });
  }

  const handleSetDataForm = useCallback(() => {
    form.resetFields();

    const {
      sector,
     // legalEntityName,
      contractNumber,
    } = item;

    if (sector) {
      setItemSector(sector);
    }

    if (contractNumber) {
      setItemContractNumber(contractNumber);
    }

 /*   if (legalEntityName) {
      setLegalEntityName(legalEntityName);
    } */

    setDataToFields(item, form);
  }, [form, item]);

  const handleSetPointsData = useCallback(() => {
    setUnbindPointsOfSale(pointOfSalesUnbindList);
    setBindPointsOfSale(pointOfSalesBindList);
  }, [
    pointOfSalesUnbindList,
    pointOfSalesBindList,
  ]);

  const handleFinish = useCallback(() => {
    const values = form.getFieldsValue();
    setIsSave(true);
    const data = formatValuesForCreateContract(values, item);
    contractSave({ data });
  }, [contractSave, item, form]);

  const openNotification = () => {
    notification.info({
      message: 'Изменения отменены',
    });
  };

  const pushToContractList = () => {
    history.push('/app/payments/contracts/list');
  };

  const handleRedirect = () => {
    breadCrumbsRedirect();
  };

  const handleHideBreadcrumbModal = () => {
    breadcrumbsHideUnblockModal();
  };

  function handleCheckChangeStatus() {
    const statusFieldValue = form.getFieldValue('STATUS');
    const { status } = item;
    if (status === statusFieldValue) {
      handleFinish();
    } else {
      setIsModalStatusOpen(true);
    }
  }

  function handleSetDefaultDataToForm() {
    breadcrumbsResetBlockLinks();
    handleSetDataForm();
    if (pointOfSalesIsLoaded) {
      handleSetPointsData();
    }
    makeFormClean();
  }

  function handlePopoverConfirm() {
    handleSetDefaultDataToForm();
    makeFormClean();
    openNotification();
  }

  useEffect(() => {
    if (pointOfSalesIsLoaded) {
      handleSetPointsData();
    }
  }, [
    pointOfSalesIsLoaded,
    handleSetPointsData,
  ]);

/*
  useEffect(() => {
    setIsMounted(true);

    return () => {
      contractItemReset();
      contractSaveReset();
      breadcrumbsResetBlockLinks();
    };
  }, [contractItemReset, contractSaveReset, breadcrumbsResetBlockLinks]);
*/

 /* useEffect(() => {
    if (isMounted) {
      const legalBreads = !isNew && legalEntityName ? [
        { level: 2, name: legalEntityName, link: `/app/payments/contracts/${contractId}` },
        (itemContractNumber
          ? { level: 3, name: `#${itemContractNumber}`, link: `/app/payments/contracts/${contractId}` } : {}),
      ] : [];

      const newBreads = isNew
        ? [{ level: 2, name: 'Новый договор', link: '/app/payments/contracts/list' }]
        : [];

      setBreadcrumbs([
        { level: 0, name: 'Эквайринг', link: '/app/payments' },
        { level: 1, name: 'Договоры', link: '/app/payments/contracts/list' },
        ...newBreads,
        ...legalBreads,
      ]);
    }
  }, [
    isMounted,
    setBreadcrumbs,
    isNew,
    contractId,
    itemContractNumber,
    legalEntityName,
  ]); */

  useEffect(() => {
    if (itemIsLoaded) {
      handleSetDataForm();
    }
  }, [itemIsLoaded, handleSetDataForm]);

  useEffect(() => {
    if (isNew && !itemIsLoaded && !itemSector) {
      history.push('/app/payments/contracts/list');
    }
  }, [history, itemIsLoaded, isNew, itemSector]);

  useEffect(() => {
    if (contractId) {
      contactFetch({ id: contractId });
    }
  }, [contactFetch, contractId]);

  useEffect(() => {
    if (isFormDirty) {
      breadCrumbsBlockLink();
    }
  }, [isFormDirty, breadCrumbsBlockLink]);

  useEffect(() => {
    if (unbindIsLoaded || bindIsLoaded) {
      setIsSave(false);
    }
  }, [unbindIsLoaded, bindIsLoaded]);

  useEffect(() => {
    if (saveError) {
      handleShowAll();
    }
  }, [saveError]);

  useEffect(() => {
    if (saveIsLoaded) {
      history.push('/app/payments/contracts/list');
    }
  }, [history, saveIsLoaded]);

  if (itemIsLoading && !updateIsLoading) {
    return (
      <Loading>
        Данные договора загружаются...
      </Loading>
    );
  }

  // TODO сделать проверку на canChangeOnlyStatus

  return (
    <Root>
      <Header>
        <Title>
          Договор
          {' '}
          {itemContractNumber}
          {' '}
          (сектор
          {' '}
          {itemSector}
          )
        </Title>

        {canEdit && (
          <ButtonsContainer isEdit={isEdit || isNew}>

            {/*            {!isEdit && !isNew && (
              <Button
                type="ghost"
                shape="square"
                href={`/app/payments/contracts/${contractId}/edit`}
              >
                Редактировать
              </Button>
            )} */}

            {(isEdit || isNew) && (
              <Button
                type="link"
                shape="square"
                onClick={() => openModal()}
              >
                Загрузить CSV
              </Button>
            )}
          </ButtonsContainer>
        )}

      </Header>
      <PointOfSalesTable
        canDeletePointOfSales={canDeletePointOfSales}
        isEdit={isEdit}
        isNew={isNew}
        isSave={isSave}
        unbindPointsOfSale={unbindPointsOfSale}
        bindPointsOfSale={bindPointsOfSale}
        setUnbindPointsOfSale={setUnbindPointsOfSale}
        setBindPointsOfSale={setBindPointsOfSale}
      />
      <Main
        name="contract"
        layout="vertical"
        hideRequiredMark
        form={form}
        size="large"
        onFinish={handleCheckChangeStatus}
        autoComplete="off"
        initialValues={initialValues}
        onValuesChange={() => !isFormDirty && makeFormDirty()}
      >
        <CollapseAll
          onClick={() => (state.haveOpened.length > 0 ? handleCloseAll() : handleShowAll())}
        >
          {state.haveOpened.length > 0 ? 'Свернуть все' : 'Развернуть все'}
        </CollapseAll>

        <CollapseContainer
          expandIconPosition="right"
          expandIcon={() => <ArrowCollapseIcon />}
          activeKey={state.haveOpened}
        >
          <CollapseItem
            header={<CollapseHeader onClick={() => handleCollapse('1')}>Параметры</CollapseHeader>}
            key="1"
            forceRender
            showArrow
          >
            <CollapseParams
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              isEdit={isEdit}
              isNew={isNew}
              role={role}
            />
          </CollapseItem>
          <CollapseItem
            header={<CollapseHeader onClick={() => handleCollapse('2')}>Контакты</CollapseHeader>}
            key="2"
            forceRender
          >
            <CollapseContacts
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              isEdit={isEdit}
              isNew={isNew}
              role={role}
            />
          </CollapseItem>
          <CollapseItem
            header={<CollapseHeader onClick={() => handleCollapse('3')}>Реквизиты</CollapseHeader>}
            key="3"
            forceRender
          >
            <CollapseRequisites
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              isEdit={isEdit}
              isNew={isNew}
              role={role}
            />
          </CollapseItem>
          <CollapseItem
            header={<CollapseHeader onClick={() => handleCollapse('4')}>Чек</CollapseHeader>}
            key="4"
            forceRender
          >
            <CollapseBill
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              isEdit={isEdit}
              isNew={isNew}
              role={role}
            />
          </CollapseItem>
          <CollapseItem
            header={<CollapseHeader onClick={() => handleCollapse('5')}>Настройки</CollapseHeader>}
            key="5"
            forceRender
          >
            <CollapseSettings
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              isEdit={isEdit}
              isNew={isNew}
              role={role}
            />
          </CollapseItem>
        </CollapseContainer>
        <TextArea>
          <Form.Item
            name={K.COMMENT.key}
            label={K.COMMENT.title}
            rules={K.COMMENT.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input.TextArea
              name={K.COMMENT.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              rows={5}
              disabled={isReadOnly}
            />
          </Form.Item>
        </TextArea>
        {(isNew || isEdit) && (
          <Footer>
            {isFormDirty ? (
              <Popconfirm
                title="Вы подтверждаете свое действие?"
                icon={<WarningOutlined />}
                onConfirm={() => handlePopoverConfirm()}
                okButtonProps={{ danger: true }}
                okText="Да, подтверждаю"
                cancelText="Нет"
              >
                <Button
                  type="ghost"
                  shape="square"
                >
                  Отменить
                </Button>
              </Popconfirm>
            ) : (isNew ? (
              <Popconfirm
                title="Вы действительно хотите отменить загрузку данных?"
                icon={<WarningOutlined />}
                onConfirm={pushToContractList}
                okButtonProps={{ danger: true }}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  type="ghost"
                  shape="square"
                >
                  Отменить
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="ghost"
                shape="square"
                onClick={() => handleSetDefaultDataToForm()}
              >
                Отменить
              </Button>
            ))}

            {canConfirm && (
              <Button
                block
                type="primary"
                shape="square"
                htmlType="submit"
                loading={saveIsLoading || bindIsLoading || unbindIsLoading}
              >
                Сохранить
              </Button>
            )}
          </Footer>
        )}
        {saveError && (<ErrorMessage>{saveError}</ErrorMessage>)}
        {bindError && (<ErrorMessage>{bindError}</ErrorMessage>)}
        {unbindError && (<ErrorMessage>{unbindError}</ErrorMessage>)}
      </Main>
      <AddFile
        close={closeModal}
        visible={isModalOpen}
        isLoaded={updateIsLoaded}
        isLoading={updateIsLoading}
        handleAddFile={contactUpdate}
        sector={itemSector}
        resetAddFile={resetUpdate}
        error={updateError}
      />
      <StatusModal
        visible={isModalStatusOpen}
        handleSave={handleFinish}
        close={closeModal}
      />

      <RedirectModal
        visible={showRedirectModal}
        handleSave={handleRedirect}
        close={handleHideBreadcrumbModal}
      />
    </Root>
  );
}
export default connect(
  (state) => ({
    role: state.auth.role,

    item: state.contracts.item.data,
    itemIsLoaded: state.contracts.item.isLoaded,
    itemIsLoading: state.contracts.item.isLoading,

    pointOfSalesBindList: state.contracts.pointOfSales.bindList,
    pointOfSalesUnbindList: state.contracts.pointOfSales.unbindList,
    pointOfSalesIsLoading: state.contracts.pointOfSales.isLoading,
    pointOfSalesIsLoaded: state.contracts.pointOfSales.isLoaded,
    pointOfSalesError: state.contracts.pointOfSales.error,

    updateIsLoaded: state.contracts.update.isLoaded,
    updateIsLoading: state.contracts.update.isLoading,
    updateError: state.contracts.update.error,

    saveIsLoaded: state.contracts.save.isLoaded,
    saveIsLoading: state.contracts.save.isLoading,
    saveError: state.contracts.save.error,

    unbindIsLoading: state.contracts.unbindPointsOfSale.isLoading,
    unbindIsLoaded: state.contracts.unbindPointsOfSale.isLoaded,
    unbindError: state.contracts.unbindPointsOfSale.error,

    bindIsLoading: state.contracts.bindPointsOfSale.isLoading,
    bindIsLoaded: state.contracts.bindPointsOfSale.isLoaded,
    bindError: state.contracts.bindPointsOfSale.error,

    showRedirectModal: state.breadcrumbs.showModal,
  }),
  {
    setBreadcrumbs: setBreadcrumbsAction,
    breadCrumbsRedirect: breadCrumbsRedirectAction,
    breadcrumbsHideUnblockModal: breadcrumbsHideUnblockModalAction,
    breadCrumbsBlockLink: breadCrumbsBlockLinkAction,
    breadcrumbsResetBlockLinks: breadcrumbsResetBlockLinksAction,

    contractItemReset: contractItemResetAction,
    contactUpdate: contactUpdateAction,
    resetUpdate: resetUpdateAction,

    contractSave: contractSaveAction,
    contractSaveReset: contractSaveResetAction,

    contactFetch: contactFetchAction,
  },
)(Contract);
