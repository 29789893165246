import { TOYOTA_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';
import { getToken } from 'helpers/utility';

export const find = ({ alias, dependencies }) => {
  const URI = `${API}/car/find`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        alias,
        dependencies,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const findTradeIn = ({
  alias,
  dependencies,
}) => {
  const URI = `${API}/car/trade-in/find`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        alias,
        dependencies,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  find,
  findTradeIn,
};
