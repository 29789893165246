import styled from 'styled-components';
import { Button } from 'antd';

const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1rem;
`;

const ContentView = styled.div``;

const AsideCollapse = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
`;

const Confirmation = styled.footer`
  margin: 1rem 0;
`;

const ErrorMessage = styled.span`
  margin-left: 1rem;
  color: red;
`;

export {
  ContentContainer, ContentView, AsideCollapse, Confirmation, ErrorMessage,
};
