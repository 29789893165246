import React, { useCallback, useEffect, useRef } from 'react';
import Input from 'components/Input';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { capitalizeValue, SCROLL_DURATION, TYPES as TYPE } from 'settings';
import { mergeArrays } from 'hacks';

const getNumberFromStringLocale = (value) => {
  const formattedString = value ? String(value)?.replace(/\D/gm, '') : '';
  return Number(formattedString);
};

function InputField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue, setFieldsValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
  dataSource,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();
  const isNumberType = type === TYPE.NUMBER;

  const value = getFieldValue(name) || '';

  useEffect(() => {
    const value = getFieldValue(name);
    if (capitalizeValue(initialValue) !== value) {
      setFieldsValue({
        [name]: capitalizeValue(initialValue),
      });
    }
  }, [initialValue, getFieldValue, setFieldsValue, name]);

  function handleOnBlur() {
    const value = getFieldValue(name);
    const formatted = value && isNumberType ? getNumberFromStringLocale(value) : value;
    if ((initialValue || '') !== formatted) {
      onChangeField({ [name]: formatted }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  function getValueFromEvent(e) {
    return (e && e.target && e.target.value) || '';
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  const suffix = (capitalizeValue(initialValue) === value) && (dataSource === 'digital_profile') ? 'DP' : ' ';

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: initialValue || '',
        normalize: (v) => {
          if (isNumberType && v) {
            const number = getNumberFromStringLocale(v);
            if (number) {
              return number.toLocaleString('ru');
            }
            return v;
          }

          return capitalizeValue(v);
        },
        getValueFromEvent,
      })(
        <Input
          name={name}
          placeholder={placeholder}
          area={area}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={focusableNode}
          size={size}
          suffix={suffix}
        />,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default InputField;
