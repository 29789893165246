/* List */
export const CONTRACT_FETCH_LIST = 'CONTRACT_FETCH_LIST';
export const CONTRACT_FETCH_LIST__SUCCESS = 'CONTRACT_FETCH_LIST__SUCCESS';
export const CONTRACT_FETCH_LIST__FAILURE = 'CONTRACT_FETCH_LIST__FAILURE';
export const fetchList = ({
  search, page, filters, sorting,
} = {}) => ({
  type: CONTRACT_FETCH_LIST,
  search,
  page,
  filters,
  sorting,
});

export const CONTRACT_FETCH_LIST__CANCEL_REQUEST = 'CONTRACT_FETCH_LIST__CANCEL_REQUEST';
export const CONTRACT_FETCH_LIST__SET_LOADING = 'CONTRACT_FETCH_LIST__SET_LOADING';
export const CONTRACT_FETCH_LIST__SET_PAGINATION = 'CONTRACT_FETCH_LIST__SET_PAGINATION';

export const CONTRACT_FETCH_LIST__SET_SEARCH = 'CONTRACT_FETCH_LIST__SET_SEARCH';
export const setSearch = ({ search } = {}) => ({ type: CONTRACT_FETCH_LIST__SET_SEARCH, search });

export const CONTRACT_FETCH_LIST__SET_PAGE = 'CONTRACT_FETCH_LIST__SET_PAGE';
export const setPage = ({ page } = {}) => ({ type: CONTRACT_FETCH_LIST__SET_PAGE, page });

export const CONTRACT_FETCH_LIST__SET_SORTING = 'CONTRACT_FETCH_LIST__SET_SORTING';
export const setSorting = ({ sortBy } = {}) => ({
  type: CONTRACT_FETCH_LIST__SET_SORTING,
  sortBy,
});

export const CONTRACT_FETCH_LIST__SET_FILTER = 'CONTRACT_FETCH_LIST__SET_FILTER';
export const setFilters = ({ filters } = {}) => ({
  type: CONTRACT_FETCH_LIST__SET_FILTER,
  filters,
});

export const CONTRACT_FETCH_LIST__RESET = 'CONTRACT_FETCH_LIST__RESET';
export const reset = () => ({ type: CONTRACT_FETCH_LIST__RESET });

export const CONTRACT_UPDATE = 'CONTRACT_UPDATE';
export const CONTRACT_UPDATE__SUCCESS = 'CONTRACT_UPDATE__SUCCESS';
export const CONTRACT_UPDATE__FAILURE = 'CONTRACT_UPDATE__FAILURE';

export const contactUpdate = ({
  file,
  sector,
} = {}) => ({
  type: CONTRACT_UPDATE,
  file,
  sector,
});

export const CONTRACT_CREATE = 'CONTRACT_CREATE';
export const CONTRACT_CREATE__SUCCESS = 'CONTRACT_CREATE__SUCCESS';
export const CONTRACT_CREATE__FAILURE = 'CONTRACT_CREATE__FAILURE';
export const CONTRACT_CREATE__RESET = 'CONTRACT_CREATE__RESET';

export const contractCreate = ({
  file,
} = {}) => ({
  type: CONTRACT_CREATE,
  file,
});

export const contractCreateReset = () => ({ type: CONTRACT_CREATE__RESET });

export const CONTRACT_FETCH = 'CONTRACT_FETCH';
export const CONTRACT_FETCH__SUCCESS = 'CONTRACT_FETCH__SUCCESS';
export const CONTRACT_FETCH__FAILURE = 'CONTRACT_FETCH__FAILURE';

export const contactFetch = ({
  id,
  sector,
} = {}) => ({
  type: CONTRACT_FETCH,
  id,
  sector,
});

export const CONTRACT_UPDATE__RESET = 'CONTRACT_UPDATE__RESET';
export const resetUpdate = () => ({ type: CONTRACT_UPDATE__RESET });

export const CONTRACT_DELETE = 'CONTRACT_DELETE';
export const CONTRACT_DELETE__SUCCESS = 'CONTRACT_DELETE__SUCCESS';
export const CONTRACT_DELETE__FAILURE = 'CONTRACT_DELETE__FAILURE';
export const contractDelete = ({ id } = {}) => ({ type: CONTRACT_DELETE, id });

export const CONTRACT_REDIRECT = 'CONTRACT_REDIRECT';

export const CONTRACT_ITEM_RESET = 'CONTRACT_ITEM_RESET';
export const contractItemReset = () => ({ type: CONTRACT_ITEM_RESET });

export const CONTRACT_REDIRECT_TO_NEW_COMPANY = 'CONTRACT_REDIRECT_TO_NEW_COMPANY';

export const CONTRACT_REDIRECT_TO_NEW_COMPANY__RESET = 'CONTRACT_REDIRECT_TO_NEW_COMPANY__RESET';
export const contractItemToNewCompanyReset = () => ({ type: CONTRACT_REDIRECT_TO_NEW_COMPANY__RESET });

export const CONTRACT_FETCH_POINT_OF_SALES = 'CONTRACT_FETCH_POINT_OF_SALES';
export const CONTRACT_FETCH_POINT_OF_SALES__SUCCESS = 'CONTRACT_FETCH_POINT_OF_SALES__SUCCESS';
export const CONTRACT_FETCH_POINT_OF_SALES__FAILURE = 'CONTRACT_FETCH_POINT_OF_SALES__FAILURE';
export const CONTRACT_FETCH_POINT_OF_SALES__RESET = 'CONTRACT_FETCH_POINT_OF_SALES__RESET';

export const fetchListPointOfSales = ({
  inn, sector,
} = {}) => ({
  type: CONTRACT_FETCH_POINT_OF_SALES,
  inn,
  sector,
});

export const fetchListPointOfSalesReset = () => ({
  type: CONTRACT_FETCH_POINT_OF_SALES__RESET,
});

export const CONTRACT_SAVE = 'CONTRACT_SAVE';
export const CONTRACT_SAVE__SUCCESS = 'CONTRACT_SAVE__SUCCESS';
export const CONTRACT_SAVE__FAILURE = 'CONTRACT_SAVE__FAILURE';
export const contractSave = (data = {}) => ({ type: CONTRACT_SAVE, data });

export const CONTRACT_SAVE__RESET = 'CONTRACT_SAVE__RESET';
export const contractSaveReset = (data = {}) => ({ type: CONTRACT_SAVE__RESET, data });

export const CONTRACT_UNBIND_POINTS_OF_SALE = 'CONTRACT_UNBIND_POINTS_OF_SALE';
export const CONTRACT_UNBIND_POINTS_OF_SALE__SUCCESS = 'CONTRACT_UNBIND_POINTS_OF_SALE__SUCCESS';
export const CONTRACT_UNBIND_POINTS_OF_SALE__FAILURE = 'CONTRACT_UNBIND_POINTS_OF_SALE__FAILURE';
export const contractUnbindPointsOfSale = ({
  sector,
  subsidiariesIds,
}) => ({ type: CONTRACT_UNBIND_POINTS_OF_SALE, sector, subsidiariesIds });

export const CONTRACT_UNBIND_POINTS_OF_SALE__RESET = 'CONTRACT_UNBIND_POINTS_OF_SALE__RESET';
export const contractUnbindPointsOfSaleReset = () => ({ type: CONTRACT_UNBIND_POINTS_OF_SALE__RESET });

export const CONTRACT_BIND_POINTS_OF_SALE = 'CONTRACT_BIND_POINTS_OF_SALE';
export const CONTRACT_BIND_POINTS_OF_SALE__SUCCESS = 'CONTRACT_BIND_POINTS_OF_SALE__SUCCESS';
export const CONTRACT_BIND_POINTS_OF_SALE__FAILURE = 'CONTRACT_BIND_POINTS_OF_SALE__FAILURE';
export const contractBindPointsOfSale = ({
  sector,
  subsidiariesIds,
}) => ({ type: CONTRACT_BIND_POINTS_OF_SALE, sector, subsidiariesIds });

export const CONTRACT_BIND_POINTS_OF_SALE__RESET = 'CONTRACT_BIND_POINTS_OF_SALE__RESET';
export const contractBindPointsOfSaleReset = () => ({ type: CONTRACT_BIND_POINTS_OF_SALE__RESET });
