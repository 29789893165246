import styled from 'styled-components';
import withTheme from 'components/tcm/withTheme';

export const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 47px;
  
  p {
    margin-bottom: 0;
  }
`;

export const Title = withTheme(styled.p`
  margin-bottom: 47px;
  font-family: ${({ theme }) => theme.toyotaFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.black};
`);

export const SearchContainer = styled.div`
  width: 360px;
`;
