import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import BreadCrumpbs from 'components/Breadcrumb';
import { Nav, Link } from 'components/Navigation';
import RequestItem from './Request/Item';
import RequestList from './Request/List';

import CreditRequestItem from './CreditRequests/Item';
import CreditRequestList from './CreditRequests/List';

import { Root } from './style';

function Index() {
  const requestLinks = [
    {
      path: '/app/leads/credit-request/list',
      component: CreditRequestList,
    },
    {
      path: '/app/leads/credit-request/:id',
      component: CreditRequestItem,
    },
    {
      path: '/app/leads/request/list',
      component: RequestList,
    },
    {
      path: '/app/leads/request/:id',
      component: RequestItem,
    },
  ];
  return (
    <>
      <Nav>
        <Link to="/app/leads/request/list">
          Все заявки
        </Link>
        <Link to="/app/leads/credit-request/list">
          Обмен документами
        </Link>
      </Nav>
      <Root>
        <BreadCrumpbs inModal />
        <Switch>
          <Route exact path="/app/leads">
            <Redirect to="/app/leads/request/list" />
          </Route>
          {requestLinks.map((item) => <Route path={item.path} component={item.component} />)}
        </Switch>
      </Root>
    </>
  );
}

export default Index;
