import styled from 'styled-components';

export const Root = styled.div`
  display: grid;
  grid-template-rows: 2.4rem 1fr;
  overflow: hidden;

  & > * {
    // css grid overflow workaround
    min-width: 0;
  }
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
`;
