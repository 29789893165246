import {
  areaTypes,
  cityTypes,
  countries,
  packageTypes,
  phoneTypes,
  regionTypes,
  settlementTypes,
  streetTypes,
} from './codebook';
import themes from './themes';

export const CODEBOOK = {
  packageTypes,
  phoneTypes,
  countries,
  regionTypes,
  areaTypes,
  cityTypes,
  settlementTypes,
  streetTypes,
};

export const DEFAULT_ERROR_MESSAGE = 'We were unable to retrieve data from the server. Try again later.';

export const DATE_FORMAT_PARSE = 'DD.MM.YYYY';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY, HH:mm';
export const DATE_FORMAT_WITH_TIME_SECONDS = 'YYYY-MM-DD HH:mm:ss';

export const GRID_AREAS = {
  LEFT: {
    LABEL: 'GRID_LABEL_LEFT',
    INPUT: 'GRID_INPUT_LEFT',
    ERROR: 'GRID_ERROR_LEFT',
    DESCRIPTION: 'GRID_DESCRIPTION_LEFT',
    WARNING: 'GRID_WARNING_LEFT',
  },
  RIGHT: {
    LABEL: 'GRID_LABEL_RIGHT',
    INPUT: 'GRID_INPUT_RIGHT',
    ERROR: 'GRID_ERROR_RIGHT',
    DESCRIPTION: 'GRID_DESCRIPTION_RIGHT',
    WARNING: 'GRID_WARNING_RIGHT',
  },
};

export const TYPES = {
  SELECT: 'select',
  RADIO: 'radio',
  EMAIL: 'email',
  ACCEPTED: 'accepted',
  TEXT: 'text',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  TEXTAREA: 'textarea',
  ADDRESS: 'address',
  CAR_AUTOSUGGEST: 'carAutocomplit',
  CAR_AUTOSUGGEST_TRADEIN: 'carAutocomplitTradeIn',
  CODEBOOK: 'codeBook',
  PHONE: 'phone',
  SELECT_WITH_SEARCH: 'autocomplit',
  SELECT_WITH_FILTER: 'localAutocomplit',
  PREFIX: 'prefix',
  MONEY: 'money',
  SMS: 'smscode',
  RANGE: 'range',
  DEALER_CENTER: 'dealerCenters',
  HIDDEN: 'hidden',
  CRM_RESULTS: 'crmResults',
  CRM_SELECT: 'crmSelect',
  SELECT_WITHOUT_SUBMIT: 'selectWithoutSend',
  PRODUCT: 'product',
  KASKO: 'borderBlock',
  BUTTON: 'button',

  TITLE: 'title',
  SMS_CONFIRMATION: 'smsConfirm',
  TEXT_NOTIFICATION: 'alert',

  ROW: 'together',
  HALF: 'togetherHalf',
  COLUMN: 'column',
  ROW__0: 'row',
  ROW__1: 'firstFormRow',
  ROW__2: 'secondFormRow',
  ROW__3: 'thirdFormRow',
  BLOCK__2: 'secondFormBlock',
  BLOCK__3: 'thirdFormBlock',
  COLLAPSE: 'collapse',
  TABS: 'tabs',
  TAB: 'tab',

  // modals
  MODAL_ERROR: 'modal_error',
  ERROR_MODAL: 'error_modal', // will map to NOTIFICATION for now
  NOTIFICATION: 'notification',
  ACCESS: 'access',

  // fixed block with documents, checkboxes and main info, such as phone, name and status
  MAIN: 'fixblock',
  MORATORIUM: 'moratorium',
};

export const FIXBLOCK_TYPES = {
  ROW: 'row',
  BLOCK: 'block',
  LABEL: 'label',
  DOCUMENT: 'file',
  HIDDEN: 'hidden',
  CHECKBOX: 'checkbox',
  BUTTON: 'button',
  BUTTON_GROUP: 'buttonGroup', // syntetic local type
  REVISION: 'revision', // syntetic local type
};

export const SCROLL_DURATION = 600;

export const FILE_SLOTS = {
  first: {
    title: 'Паспорт',
    name: 'firstDocumentBlock',
    fields: ['filePassport1', 'filePassport2', 'filePassport3'],
    parent: 'tabs',
    tab: 'firstDocuemntTitle',
  },
  second: {
    title: 'Второй документ',
    name: 'secondDocumentBlock',
    fields: ['fileSecondDocument1', 'fileSecondDocument2'],
    parent: 'tabs',
    tab: 'secodeDocuemntTitle',
    options: 'typeSecondDocument',
  },
  consent: {
    title: 'Согласие',
    name: 'consentDocumentBlock',
    fields: ['fileConsentFormPersonaldata', 'fileConsentFormPersonaldata2'],
    parent: 'tabs',
    tab: '',
  },
  stspts: {
    title: 'СТС/ПТС',
    name: 'stsptsDocumentBlock',
    fields: ['stsptsDocument1', 'stsptsDocument2'],
    parent: 'tabs',
    tab: '',
  },
  employment1: {
    title: 'Документ о трудоустройстве',
    name: 'employmentDocumentBlock1',
    fields: [
      'employmentDocumentBlock1File1',
      'employmentDocumentBlock1File2',
      'employmentDocumentBlock1File3',
      'employmentDocumentBlock1File4',
      'employmentDocumentBlock1File5',
      'employmentDocumentBlock1File6',
      'employmentDocumentBlock1File7',
      'employmentDocumentBlock1File8',
      'employmentDocumentBlock1File9',
      'employmentDocumentBlock1File10',
    ],
    parent: 'tabs',
    tab: '',
  },
  employment2: {
    title: 'Документ о трудоустройстве',
    name: 'employmentDocumentBlock2',
    fields: [
      'employmentDocumentBlock1File1',
      'employmentDocumentBlock1File2',
      'employmentDocumentBlock1File3',
      'employmentDocumentBlock1File4',
      'employmentDocumentBlock1File5',
      'employmentDocumentBlock1File6',
      'employmentDocumentBlock1File7',
      'employmentDocumentBlock1File8',
      'employmentDocumentBlock1File9',
      'employmentDocumentBlock1File10',
    ],
    parent: 'tabs',
    tab: '',
  },
  militaryIDDocumentBlock: {
    title: 'Военный билет',
    name: 'secondDocumentBlock',
    fields: ['militaryIDDocument1', 'militaryIDDocument2'],
    parent: 'tabs',
    tab: '',
  },
  additional: {
    title: 'Дополнительный документ',
    name: 'additionalDocumentBlock1',
    fields: ['militaryIDDocument1', 'militaryIDDocument2'],
    parent: 'tabs',
    tab: '',
  },
};

export const STATUS_LIST = [
  {
    value: 1,
    label: 'Заполнение данных',
  },
  {
    value: 2,
    label: 'Отправлена в Банк',
  },
  {
    value: 3,
    label: 'Проверятся сотрудником Банка',
  },
  {
    value: 4,
    label: 'Требуется заполнить данные',
  },
  {
    value: 5,
    label: 'Отправлена на рассмотрение',
  },
  {
    value: 6,
    label: 'Отказ ОКО',
  },
  {
    value: 61,
    label: 'Отказ ОКО - Недозвон',
  },
  {
    value: 62,
    label: 'Отказ ОКО - Недействительный паспорт',
  },
  {
    value: 63,
    label: 'Отказ ОКО - Критичная ошибка',
  },
  {
    value: 64,
    label: 'Отказ ОКО - Минимальные требования',
  },
  {
    value: 65,
    label: 'Отказ ОКО - Отказ заявителя',
  },
  {
    value: 66,
    label: 'Отказ ОКО - Мораторий',
  },
  {
    value: 67,
    label: 'Отказ ОКО - Не заявитель',
  },
  {
    value: 68,
    label: 'Отказ ОКО - Прочее',
  },
  {
    value: 7,
    label: 'Одобрено',
  },
  {
    value: 8,
    label: 'Одобрено с дополнительными условиями',
  },
  {
    value: 9,
    label: 'Отказ',
  },
  {
    value: 10,
    label: 'Отказ с рекомендацией повторного обращения',
  },
  {
    value: 11,
    label: 'Не принята к рассмотрению',
  },
  {
    value: 70,
    label: 'В процессе отправки',
  },
];

export const PASSPORT_FIELDS = [
  'surname',
  'name',
  'middleName',
  'noMiddleName',
  'sex',
  'dateOfBirth',
  'passportSeriesAndNumber',
  'issuedBy',
  'dateOfIssue',
  'unitСode',
  'placeOfBirth',
  'countryOfBirth',
  'oldPassportSeriesAndNumber',
];

/* we have some rules for search:
  Критерий 1. Фамилия + Имя + Отчество + Дата рождения + Серия паспорта + Номер паспорта.
  Критерий 2. Фамилия + Имя + Отчество + Дата рождения + Место рождения.
  Критерий 3. Имя + Отчество + Серия прежнего паспорта + Номер прежнего паспорта.
  Критерий 4. Фамилия + Имя + Отчество + Дата рождения.
  Критерий 5. Серия паспорта текущего + Номер паспорта текущего
  Критерий 6. Серия прежнего паспорта + Номер прежнего паспорта.

We need to be sure that our form contains fields values in order to perform the search;
They ordered by priority, but we dont need it.
So we eliminate the cases:
  search can be performed if form contains:
    - 'name' AND ('middleName' OR 'noMiddleName') AND ('dateOfBirth' OR 'oldPassportSeriesAndNumber');
    - `passportSeriesAndNumber``
    - `oldPassportSeriesAndNumber`
*/
export const CRIF_STAGES = [
  // ['surname', 'name', ['middleName', 'noMiddleName'], 'dateOfBirth', 'passportSeriesAndNumber'],
  // ['surname', 'name', ['middleName', 'noMiddleName'], 'dateOfBirth'/*, 'placeOfBirth'*/],
  ['name', ['middleName', 'noMiddleName'], 'oldPassportSeriesAndNumber'],
  ['surname', 'name', ['middleName', 'noMiddleName'], 'dateOfBirth'],
  ['passportSeriesAndNumber'],
  ['oldPassportSeriesAndNumber'],
];

export const CRIF_SEARCH_PARAMS = [
  'surname',
  'name',
  'middleName',
  'noMiddleName',
  'dateOfBirth',
  'placeOfBirth',
  'passportSeriesAndNumber',
  'oldPassportSeriesAndNumber',
];

export const isSearchAvailable = (values) =>
  CRIF_STAGES.some((fields) =>
    fields.every((field) => (typeof field === 'string' ? values[field] : field.some((somefield) => values[somefield]))));

export const capitalizeValue = (s) =>
  (typeof s === 'string' && s.length > 0 ? `${s[0].toUpperCase()}${s.slice(1)}` : typeof s === 'number' ? String(s) : '');

export const TSM_THEME = themes.tsmTheme.toyota;

export const SHOWING_COMMENTS = 2;

export const REPORTS = true; // на препрод true
export const MVP_3 = true; // на препрод true
export const ARM_COMMENTS = true; // на препрод true
export const ARM_ANSWER = true; // на препрод false
export const TCM_REPORTS_EMPLOYEES = true; // на тест false
export const TCM_REPORTS_PERIOD = true; // на тест false

export const DEBOUNCE_SEARCH_TIME = 750;
