import styled from 'styled-components';
import { Popover, Button } from 'antd';

export const Root = styled.div`
  padding: 0 0;
  
  .ant-select-selection-item {
    color: rgba(0,0,0, .8);
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
    font-family: Open Sans;
    font-size: 28px;
    color: #000;
    margin: 0;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const ItemsContainer = styled.div`
  position: relative;
`;

export const ItemsContentContainer = styled(Popover)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Count = styled(Button)`
  background: red;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 10px;
  height: 26px;
  min-width: 26px;
  padding: 0;
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const ActionContainers = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 20px;
`;

export const ButtonDownload = styled(Button)`
  background: #FFFFFF;
  border: 1px solid #7B7B7B;
  box-sizing: border-box;
  border-radius: 5px;
  color: #7B7B7B;
  
  &:hover{
    color: #7B7B7B;
    border: 1px solid #7B7B7B;
  }
`;

export const ButtonDeleteContainer = styled.div`
  button {
    width: 100%;
  }
`;

export const ButtonDelete = styled(Button)`
  background: #FFFFFF;
  border: 1px solid #E71313;
  box-sizing: border-box;
  border-radius: 5px;
  color: #E71313;
  
  &:hover{
    color: #E71313;
    border: 1px solid #E71313;
  }
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 10px;
`;
export const Row = styled.div`
  font-family: 'Toyota Type';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
  overflow-wrap: anywhere;
`;
