import React, { useEffect, useState } from 'react';
import {
  RotateRightOutlined,
  RotateLeftOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import PDFPreview from 'components/PDFPreview';

import {
  Root,
  ImageContainer,
  CollapseStyled,
  PanelStyled,
  PreviewRemoveButton,
  ImageLoaderIcon,
  Image,
  ErrorMessage,
  RotateLeftButton,
  DownloadButton,
  RotateRightButton,
  ImagePreviewContainer,
} from './style';

const PDF_MIME = 'application/pdf';

const downloadURL = (fileUrl, fileName) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.style.display = 'none';
  a.click();
  a.remove();
};

function downloadPDF(pdf, name) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `${name}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
}

function Preview({
  name,
  file: {
    file: image, mime, base64, isLoading, isLoaded, error,
  },
  remove,
}) {
  const [rotate, setRotate] = useState(0);
  const isPDF = mime === PDF_MIME;

  function handlePreviewClose(event) {
    event.stopPropagation();
    remove({ name });
  }

  function handleRotateLeft(event) {
    event.stopPropagation();
    setRotate((prevState) => prevState - 90);
  }

  function handleRotateRight(event) {
    event.stopPropagation();
    setRotate((prevState) => prevState + 90);
  }

  function handleDownloadFile(event) {
    event.stopPropagation();
    if (isPDF) {
      downloadPDF(base64, name);
    } else {
      downloadURL(image, name);
    }
  }

  useEffect(() => () => setRotate(0), []);

  return (
    <Root>
      {isLoaded ? (
        <ImageContainer>
          <ImagePreviewContainer rotate={rotate}>
            {isPDF ? (
              <PDFPreview base64={base64} />
            ) : (
              <Image src={image} alt={`Предпросмотр документа ${name}`} />
            )}
          </ImagePreviewContainer>
          <PreviewRemoveButton onClick={handlePreviewClose} title="Удалить превью">
            x
          </PreviewRemoveButton>
          <RotateLeftButton onClick={handleRotateLeft}>
            <RotateLeftOutlined />
          </RotateLeftButton>
          <RotateRightButton onClick={handleRotateRight}>
            <RotateRightOutlined />
          </RotateRightButton>
          <DownloadButton onClick={handleDownloadFile}>
            <DownloadOutlined />
          </DownloadButton>
        </ImageContainer>
      ) : (
        <CollapseStyled>
          <PanelStyled
            key={name}
            disabled
            showArrow={false}
            header={(
              <span>
                {isLoading && (
                  <>
                    <ImageLoaderIcon />
                    {' '}
                    Загружается документ
                    <strong>{name}</strong>
                    ...
                  </>
                )}
                {isLoaded && (
                  <span>
                    Документ
                    <strong>{name}</strong>
                  </span>
                )}
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </span>
            )}
          />
        </CollapseStyled>
      )}
    </Root>
  );
}

export default Preview;
