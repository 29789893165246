import React from 'react';
import { Form, Input } from 'antd';

import DatePicker from 'components/DatePicker';
import Select from 'components/Select';

import { checkPrivilege } from 'helpers/roles';

import {
  FormItemsContainer,
  FormItemsRow,
  FormItemsTitle,
} from '../style';

import { KEYS as K, STATUS_OPTIONS } from '../data';

function ParamsItems({
    // passed
  handleFocus,
  handleBlur,
  isEdit,
  isNew,
  role,
}) {
  const canChangeStatus = checkPrivilege([
    role.ACQUIRING_AGREEMENT.CHANGE_ONLY_STATUS,
    role.ACQUIRING_AGREEMENT.CHANGE_STATUS,
  ]);

  const canEdit = checkPrivilege([
    role.ACQUIRING_AGREEMENT.EDIT,
  ]);

  const isReadOnly = canEdit && (isEdit || isNew);

  const isReadOnlyStatus = (canEdit || canChangeStatus) && (isEdit || isNew);

  return (
    <>
      <FormItemsContainer>
        <FormItemsRow>
          <Form.Item
            name={K.NUMBER.key}
            label={K.NUMBER.title}
            rules={K.NUMBER.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.NUMBER.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.DATE.key}
            label={K.DATE.title}
            rules={K.DATE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <DatePicker
              name={K.DATE.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.STATUS.key}
            label={K.STATUS.title}
            rules={K.STATUS.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Select
              name={K.STATUS.key}
              onFocus={(e) => handleFocus(e, K.STATUS.key)}
              onBlur={(e) => handleBlur(e, K.STATUS.key)}
              filterOption={false}
              list={STATUS_OPTIONS}
              hasDefault={false}
              disabled={!(isReadOnlyStatus)}
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.SECTOR.key}
            label={K.SECTOR.title}
            rules={K.SECTOR.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.SECTOR.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.PASSWORD.key}
            label={K.PASSWORD.title}
            rules={K.PASSWORD.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.PASSWORD.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.API_KEY.key}
            label={K.API_KEY.title}
            rules={K.API_KEY.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.API_KEY.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
        <FormItemsRow>
          <Form.Item
            name={K.OCCUPATION.key}
            label={K.OCCUPATION.title}
            rules={K.OCCUPATION.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.OCCUPATION.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.SITE.key}
            label={K.SITE.title}
            rules={K.SITE.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.SITE.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
          <Form.Item
            name={K.BRAND.key}
            label={K.BRAND.title}
            rules={K.BRAND.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.BRAND.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
      <FormItemsContainer>
        <FormItemsTitle>
          Тариф
        </FormItemsTitle>
        <FormItemsRow>
          <Form.Item
            name={K.CURRENT_TARIFF.key}
            label={K.CURRENT_TARIFF.title}
            rules={K.CURRENT_TARIFF.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.CURRENT_TARIFF.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.NEW_TARIFF.key}
            label={K.NEW_TARIFF.title}
            rules={K.NEW_TARIFF.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <Input
              name={K.NEW_TARIFF.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled
            />
          </Form.Item>
          <Form.Item
            name={K.DATE_TARIFF.key}
            label={K.DATE_TARIFF.title}
            rules={K.DATE_TARIFF.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <DatePicker
              name={K.DATE_TARIFF.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={!(isReadOnly)}
            />
          </Form.Item>
        </FormItemsRow>
      </FormItemsContainer>
    </>
  );
}

export default ParamsItems;
