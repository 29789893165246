import styled from 'styled-components';
import { Popover, Button } from 'antd';

export const Root = styled.div`
  padding: 0rem 4rem 0;
  
  .ant-select-selection-item {
    color: rgba(0,0,0, .8);
  }
`;

export const Header = styled.header`
  margin: 0 auto;
  padding: 2rem 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderSearch = styled.div`
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 28px;
  color: #000;
  margin: 0;
`;

export const LoadingMessage = styled.div``;

export const ErrorMessage = styled.div`
  color: red;
`;

export const EmptyMessage = styled.div``;

export const ItemsContainer = styled.div`
  position: relative;
`;

export const ItemsContent = styled.div`
  background: #fff;
  margin-top: 5px;
`;

export const ItemsContentContainer = styled(Popover)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Count = styled(Button)`
  background: red;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 10px;
  height: 26px;
  min-width: 26px;
  padding: 0;
`;

export const ButtonsContainer = styled.div`
 display: grid;
 grid-auto-flow: column;
 grid-column-gap: 20px;
 align-items: center;
`;

export const DownloadButtonContainer = styled.div`
 display: grid;
 position: relative;
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
