export const KEYS = {
  FILES: {
    key: 'FILES',
    apiKey: 'fileName',
    title: 'Добавить новый файл',
    rules: [
      {
        required: true,
        message: 'Выберите файл',
      },
    ],
  },
};
