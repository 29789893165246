import { CampaignInfoWithStatus } from 'components/tcm/CampaignInfo';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer, useState,
} from 'react';
import { TCM } from 'components/tcm';
import { connect } from 'react-redux';
import { fetch as fetchAction } from 'redux/tcmCampaign/actions';
import { useHistory, useParams } from 'react-router';
import { CAMPAIGN_STATUSES } from 'tcm/campaigns/data';
import { BackBtnWrapper } from 'components/tcm/CampaignInfo/style';
import { ArrowIcon } from 'icons';
import { SaveFormModal } from 'components/tcm/Modals';
import Page404 from 'components/tcm/Page404';
import { BackButtonContainer } from '../style';
import {
  WithDraw, Wrapper,
} from './style';
import Offer from '../Оffer';
import CreateCampaignForm from '../CreateCampaignForm/CreateCampaignForm';
import { Distribution } from '../Disribution/Distribution';
import RejectCampaign from '../RejectCampaign';

const initialState = {
  draftSaveHandler: () => {},
  isDataChanged: false,
  showConfirm: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'setIsDataChanged':
      return {
        ...state,
        isDataChanged: action.value,
      };
    case 'setDraftSaveHandler':
      return {
        ...state,
        draftSaveHandler: action.value,
      };
    case 'setShowConfirm':
      return {
        ...state,
        showConfirm: action.value,
      };
    default:
      throw new Error();
  }
}

const campaignHasBeenProcessedInStatusCalculation = (statusHistory = {}) => {
  const statusKP = statusHistory.find((item) => item.code === CAMPAIGN_STATUSES.KP_CALCULATION);
  return statusKP && statusKP.timestamp === 'DONE';
};

const ShowCampaign = ({
  campaign,
  isLoading,
  isLoaded,
  loadingError,
  fetch,
}) => {
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [campaignWasInCalculation, setCampaignWasInCalculation] = useState(false);

  const setIsDataChanged = useCallback(
    (value) => {
      dispatch({ type: 'setIsDataChanged', value });
    },
    [],
  );

  const setDraftSaveHandler = useCallback(
    (value) => {
      dispatch({ type: 'setDraftSaveHandler', value });
    },
    [],
  );

  useEffect(() => {
    fetch(id);
  }, [fetch, id]);

  useEffect(() => {
    if (isLoaded && campaign.statusHistory) {
      const isCampaignWasInCalculationStatus = campaignHasBeenProcessedInStatusCalculation(campaign.statusHistory);
      setCampaignWasInCalculation(isCampaignWasInCalculationStatus);

      if (isCampaignWasInCalculationStatus) {
        setShowCompanyInfo(true);
      } else {
        (
          setShowCompanyInfo(
            (campaign.statusCode !== CAMPAIGN_STATUSES.DRAFT)
          && (campaign.statusCode !== CAMPAIGN_STATUSES.REVISION),
          )
        );
      }
    }
  }, [isLoaded, campaign]);

  const specificContent = useMemo(() => {
    if (isLoaded) {
      switch (campaign.statusCode) {
        case CAMPAIGN_STATUSES.REVISION:
          return campaignWasInCalculation ? (
            <Offer
              isStatusRevision
              setIsDataChanged={setIsDataChanged}
              setDraftSaveHandler={setDraftSaveHandler}
            />
          ) : (
            <CreateCampaignForm isStatusRevision campaign={campaign} />
          );
        case CAMPAIGN_STATUSES.DRAFT:
          return <CreateCampaignForm isStatusDraft campaign={campaign} />;
        case CAMPAIGN_STATUSES.VIN_REQUEST:
          return null;
        case CAMPAIGN_STATUSES.EVALUATION:
          return (
            <Offer
              setIsDataChanged={setIsDataChanged}
              setDraftSaveHandler={setDraftSaveHandler}
            />
          );
        case CAMPAIGN_STATUSES.KP_CALCULATION:
          return <Offer />;
        case CAMPAIGN_STATUSES.DISTRIBUTION:
          return (
            <Distribution
              campaignId={campaign.id}
              setIsDataChanged={setIsDataChanged}
              setDraftSaveHandler={setDraftSaveHandler}
            />
          );
        case CAMPAIGN_STATUSES.CALLING:
          return (
            <Offer
              setIsDataChanged={setIsDataChanged}
              setDraftSaveHandler={setDraftSaveHandler}
            />
          );
        case CAMPAIGN_STATUSES.COMPLETE:
          return (
            <Offer />
          );
        default:
          console.error('Invalid status code of campaign');
          return null;
      }
    }
    return null;
  }, [campaign, campaignWasInCalculation, isLoaded, setDraftSaveHandler, setIsDataChanged]);

  const onBackClick = () => {
    if (state.isDataChanged) {
      dispatch({ type: 'setShowConfirm', value: true });
    } else {
      history.push('/tcm');
    }
  };

  const onConfirmCancelClick = () => {
    history.push('/tcm');
  };

  const onConfirmOkClick = () => {
    state.draftSaveHandler();
    history.push('/tcm');
  };

  const onCloseModal = () => {
    dispatch({ type: 'setShowConfirm', value: false });
  };
  if (loadingError) {
    return <Page404 text="Кампания не найдена" />;
  }

  if (isLoading) {
    return <TCM.Loader text="Загрузка данных кампании ..." />;
  }

  return (
    <Wrapper>
      {showCompanyInfo && (
        <>
          <BackBtnWrapper>
            <TCM.ButtonText onClick={onBackClick}>
              <BackButtonContainer>
                <ArrowIcon left />
                Назад
              </BackButtonContainer>
            </TCM.ButtonText>
          </BackBtnWrapper>
          <CampaignInfoWithStatus campaign={campaign} />
        </>
      )}
      {specificContent || (
        <WithDraw>
          <TCM.ButtonText
            onClick={() => setShowRejectModal(true)}
          >
            Отозвать кампанию
          </TCM.ButtonText>
        </WithDraw>
      )}
      <SaveFormModal
        visible={state.showConfirm}
        title="Сохранить изменения?"
        btnTextOk="Да"
        btnTextCancel="Нет"
        onOk={onConfirmOkClick}
        onCancel={onConfirmCancelClick}
        onClose={onCloseModal}
      />

      <RejectCampaign
        close={() => setShowRejectModal(false)}
        visible={showRejectModal}
      />
    </Wrapper>
  );
};

const CampaignWithRedux = connect((state) => ({
  campaign: state.tcmCampaign.campaign.data,
  isLoading: state.tcmCampaign.campaign.isLoading,
  isLoaded: state.tcmCampaign.campaign.isLoaded,
  loadingError: state.tcmCampaign.campaign.error,

  statuses: state.tcmCampaign.campaignStatuses.data,
  statusesIsLoading: state.tcmCampaign.campaignStatuses.isLoading,
  statusesIsLoaded: state.tcmCampaign.campaignStatuses.isLoaded,
  statusesLoadingError: state.tcmCampaign.campaignStatuses.error,
}), {
  fetch: fetchAction,
})(ShowCampaign);

export { CampaignWithRedux as ShowCampaign };
