import React, {
  useCallback, useEffect, useState, useReducer, useRef,
} from 'react';
import Select from 'components/Select';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { formatCodeBook, mergeArrays } from 'hacks';
import API from 'services';
import { SCROLL_DURATION } from 'settings';

const initialState = {
  isLoading: false,
  isLoaded: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function Codebook({
  name,
  type,
  alias,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [list, setList] = useState([]);

  function handleOnFocus() {
    if (!state.isLoaded) {
      dispatch({ type: ACTIONS.FETCH });
      API.codebook
        .find({
          name: alias, // passing alias as a name
        })
        .then((results) => {
          const data = formatCodeBook(results);
          dispatch({ type: ACTIONS.FETCH_SUCCESS, list: data });
          setList(data);
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
        });
    }
  }

  function saveForm() {
    const value = getFieldValue(name);
    if (
      ((list.find((e) => e.label === String(initialValue)) || {}).label || '') !== value
    ) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  function handleOnSelect() {
    saveForm();
  }

  function handleOnBlur() {
    saveForm();
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue:
          (state.list.find((e) => e.value === initialValue) || {}).value
          || String(initialValue || ''),
      })(
        <Select
          name={name}
          placeholder={placeholder}
          area={area}
          onFocus={handleOnFocus}
          onSelect={handleOnSelect}
          onBlur={handleOnBlur}
          disabled={disabled}
          loading={state.isLoading}
          notFoundContent={null}
          ref={focusableNode}
          size={size}
          list={state.list}
        />,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default Codebook;
