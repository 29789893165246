import styled from 'styled-components';

export const Root = styled.div`
  margin: 0 0 0 4rem;
  padding-top: 2rem;
  background: #fff;
  border-radius: 5px;
  max-width: 1024px;
  min-height: 500px;
`;

export const Header = styled.header`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 20rem minmax(min-content, 50rem);

  /* Semi-global styles */
  & .ant-select {
    width: 100%;
  }
`;

export const OrganisationLink = styled.a`
  display: block;
`;
