import React from 'react';

import {
  Title,
  Grid,
  GridRow,
  GridItem,
  Root,
} from '../style';

function NotAccepted() {
  return (
    <Root>
      <Title>Не принято</Title>
      <Grid>
        <GridRow>
          <GridItem label>
            Решение:
          </GridItem>
          <GridItem>
            Не принято
          </GridItem>
        </GridRow>
      </Grid>
    </Root>
  );
}

export default NotAccepted;
