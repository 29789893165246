import API from 'services';
import { formatTcmReports, extractErrorAndCode } from 'hacks';

import {
  all,
  put,
  fork,
  call,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';

import {
  REPORT_EXPORT,
  REPORT_EXPORT__SUCCESS,
  REPORT_EXPORT__FAILURE,

  SAVE_REPORT_TEMPLATE,
  SAVE_REPORT_TEMPLATE__SUCCESS,
  SAVE_REPORT_TEMPLATE__FAILURE,

  GET_TCM_REPORT_SETTINGS,
  GET_TCM_REPORT_SETTINGS__SUCCESS,
  GET_TCM_REPORT_SETTINGS__FAILURE,

  GET_TCM_REPORT_LIST,
  GET_TCM_REPORT_LIST__SUCCESS,
  GET_TCM_REPORT_LIST__FAILURE,
} from 'redux/tcmReport/actions';

function* reportExport() {
  yield takeLeading(REPORT_EXPORT, function* ({ startAt, finishAt, campaignIds }) {
    try {
      const response = yield call(API.TCM.report.exportReport, {
        startAt, finishAt, campaignIds,
      });
      if (response.size > 0) {
        yield put({
          type: REPORT_EXPORT__SUCCESS,
          data: response,
        });
      } else {
        throw new Error('Ошибка при выгрузке файла');
      }
    } catch (error) {
      yield put({ type: REPORT_EXPORT__FAILURE, error: error.message });
    }
  });
}

function* saveReportTemplate() {
  yield takeLatest(SAVE_REPORT_TEMPLATE, function* ({ reportType, name, params }) {
    try {
      const response = yield call(API.TCM.report.saveReportTemplate, {
        reportType, name, params,
      });
      if (!response.errorCode && !response.data.length) {
        yield put({
          type: SAVE_REPORT_TEMPLATE__SUCCESS,
          data: response.data,
        });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        error.message = 'Ошибка сети';
      }
      yield put({ type: SAVE_REPORT_TEMPLATE__FAILURE, error: error.message });
    }
  });
}

function* getTcmReportSettings() {
  yield takeLeading(GET_TCM_REPORT_SETTINGS, function* () {
    try {
      const response = yield call(API.TCM.report.getTcmReportSettings);
      if (!response.errorCode && !response.data.length) {
        yield put({
          type: GET_TCM_REPORT_SETTINGS__SUCCESS,
          data: response.data,
        });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        error.message = 'Ошибка сети';
      }
      yield put({ type: GET_TCM_REPORT_SETTINGS__FAILURE, error: error.message });
    }
  });
}

function* getTcmReportList() {
  yield takeLeading(GET_TCM_REPORT_LIST, function* () {
    try {
      const response = yield call(API.TCM.report.getTcmReportList);
      if (
        (!response.errorCode
        && Array.isArray(response.data.reportTemplateList)
        && response.data.reportTemplateList[0])
        || Array.isArray(response.data)
      ) {
        yield put({
          type: GET_TCM_REPORT_LIST__SUCCESS,
          data: formatTcmReports(response.data.reportTemplateList) || [],
        });
      } else {
        const { error } = extractErrorAndCode(response);
        throw new Error(error);
      }
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        error.message = 'Ошибка сети';
      }
      yield put({ type: GET_TCM_REPORT_LIST__FAILURE, error: error.message });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(reportExport),
    fork(saveReportTemplate),
    fork(getTcmReportSettings),
    fork(getTcmReportList),
  ]);
}
