import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';
import icon from 'assets/icons/checkBox.svg';
import withTheme from './withTheme';

export const Checkbox = withTheme(styled(({ theme, ...p }) => <AntCheckbox {...p} />)`
  ${({ theme }) => css`
    &.ant-checkbox-wrapper .ant-checkbox-inner {
      width: ${theme.checkbox.width};
      height: ${theme.checkbox.height};
      border: ${theme.checkbox.border};
      border-radius: ${theme.checkbox.borderRadius};
      background-color: ${theme.checkbox.backgroundColor};
      box-sizing: border-box;
    }
    
    & .ant-checkbox-checked,
    &:hover,
    &:focus {
      border-radius: ${theme.checkbox.borderRadius};
      border-color: ${theme.checkbox.borderColor};
      background-color: transparent;

      &::after {
        border-radius: ${theme.checkbox.borderRadius};
        border-color: ${theme.checkbox.borderColor};
      }
      
      & .ant-checkbox-inner::after {
        width: 20px;
        height: 20px;
        border-color: ${theme.checkbox.iconCheckedColor};
        background: url(${icon}) no-repeat center;
        border: none;
        transform: none;
        top: -3px;
        left: -2px;
      }
    }
    
    & .ant-checkbox-inner::after {
      border-color: ${theme.checkbox.iconCheckedColor};
    }
    
    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border: 0;
      transform: translate(-50%, -50%) scale(1);
      background-color: ${theme.checkbox.iconCheckedColor};
    }
  `}
`);

export const CheckBoxGroup = withTheme(styled(({
  theme,
  direction,
  ...p
}) => <AntCheckbox.Group {...p} />)`
  display: grid;
  grid-auto-flow: ${({ direction }) => direction ?? 'row'};
  gap: 20px;
  
  ${({ theme }) => css`
  &.ant-checkbox-group {
    & .ant-checkbox-group-item > span {
      font-family: ${({ theme }) => theme.fontFamily};
      font-size: 16px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.black};
    }
  
    .ant-checkbox-wrapper .ant-checkbox-inner {
      width: ${theme.checkbox.width};
      height: ${theme.checkbox.height};
      border: ${theme.checkbox.border};
      border-radius: ${theme.checkbox.borderRadius};
      background-color: ${theme.checkbox.backgroundColor};
      box-sizing: border-box;
    }
      
    & .ant-checkbox-checked,
    &:hover,
    &:focus {
      border-radius: ${theme.checkbox.borderRadius};
      border-color: ${theme.checkbox.borderColor};
      background-color: transparent;
  
      &::after {
        border-radius: ${theme.checkbox.borderRadius};
        border-color: ${theme.checkbox.borderColor};
      }
      
      & .ant-checkbox-inner::after {
        width: 20px;
        height: 20px;
        border-color: ${theme.checkbox.iconCheckedColor};
        background: url(${icon}) no-repeat center;
        border: none;
        transform: none;
        top: -3px;
        left: -2px;
      }
    }
    
    & .ant-checkbox-inner::after {
      border-color: ${theme.checkbox.iconCheckedColor};
    }
    
    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border: 0;
      transform: translate(-50%, -50%) scale(1);
      background-color: ${theme.checkbox.iconCheckedColor};
    }
  }
   
  `}
`);
