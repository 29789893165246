import styled from 'styled-components';
import { Modal } from 'antd';

export const Root = styled(Modal)`
  & .ant-modal-content {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  & .ant-modal-header {
    padding: 2rem;
  }

  & .ant-modal-body {
    padding: 1rem 2rem 2rem;
    min-height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & .ant-form-item {
    margin-bottom: 14px;
  }
  
  button span{
    font-size: 18px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  color: #000;
  margin: 0;
  text-align: center;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;
`;
export const SuccessMessage = styled.div`
  color: green;
  margin-top: 10px;
  text-align: center;
`;
