import React, {
  useCallback, useEffect, useReducer, useRef, useState,
} from 'react';
import AutoComplete, { Option } from 'components/AutoComplete';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { defineDependencies, formatCarList, mergeArrays } from 'hacks';
import API from 'services';
import { SCROLL_DURATION } from 'settings';

import { LoadingOutlined } from '@ant-design/icons';

const initialState = {
  query: null,
  isLoading: false,
  isLoaded: false,
  list: [],
  error: null,
};

const ACTIONS = {
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...initialState,
        query: action.query,
        isLoading: true,
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list: action.list,
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

function CarAutoSuggest({
  name,
  type,
  alias,
  dependencies,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  patchIsLoading,
  ifChangeClearFields,
  warning,

  values,
  form: { getFieldDecorator, getFieldValue, setFieldsValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  size,
  isTradeIn = false,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [list, setList] = useState([]);
  const find = isTradeIn ? API.car.findTradeIn : API.car.find;
  const isModelVehicleField = name === 'vehicleOptionsModel';
  const isDisabled = disabled || (isModelVehicleField && patchIsLoading);

  useEffect(() => {
    if (typeof initialValue === 'string') {
      setFieldsValue({
        [name]: initialValue,
      });
    } else {
      setFieldsValue({
        [name]: '',
      });
      setList([]);
    }
  }, [initialValue, name, setFieldsValue]);

  // Приходится этот метод вызывать и при клике и при фокусе.
  // Это связанно с тем что асинхронно поле не сразу отрабатывает на запрос взаимодействия
  // (скорее всего проблема взаимодействия формы и компонента)
  function handleOnFind() {
    const query = defineDependencies(dependencies, values);
    if (
      !state.isLoading
        && (state.query === null
            || Object.entries(state.query).some(([k, v]) => query[k] !== v))
    ) {
      dispatch({ type: ACTIONS.FETCH, query });
      find({
        alias,
        dependencies: query,
      })
        .then((results) => {
          const data = formatCarList(results);
          dispatch({ type: ACTIONS.FETCH_SUCCESS, list: data });
          setList(data);
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
        });
    }
  }

  function handleOnChange(v) {
    const filteredList = v.length > 0
      ? state.list.filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
      : state.list;

    setList(filteredList);
  }

  function saveForm() {
    const value = getFieldValue(name);
    if (
      ((state.list.find((e) => e.label === initialValue) || {}).value || '') !== value
    ) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  function handleOnSelect(v) {
    const value = getFieldValue(name);
    const filteredList = value.length > 0
      ? state.list.filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
      : state.list;

    setList(filteredList);
    saveForm();
  }

  function handleOnBlur() {
    saveForm();
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: initialValue || undefined,
      })(
        <AutoComplete
          name={name}
          placeholder={placeholder}
          area={area}
          onFocus={handleOnFind}
          onClick={handleOnFind}
          onChange={handleOnChange}
          onSelect={handleOnSelect}
          onBlur={handleOnBlur}
          disabled={isDisabled}
          notFoundContent={
            state.error ? (
              <span>{state.error}</span>
            ) : state.isLoading ? (
              <LoadingOutlined />
            ) : (
              <span>нет данных</span>
            )
          }
          ref={focusableNode}
          size={size}
        >
          {list.map((option) => (
            <Option key={option.value}>{option.value}</Option>
          ))}
        </AutoComplete>,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default CarAutoSuggest;
