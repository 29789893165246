import React, { useCallback, useEffect, useRef } from 'react';
import { TextArea } from 'components/Input';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { SCROLL_DURATION } from 'settings';
import { mergeArrays } from 'hacks';
import { TextAreaWithSuffix, TextAreaSuffix } from './style';

function TextareaField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  dataSource,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();
  const value = getFieldValue(name) || '';

  function handleOnBlur() {
    const value = getFieldValue(name);
    if ((initialValue || '') !== value) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  const suffix = (initialValue === value) && (dataSource === 'digital_profile') ? 'DP' : null;

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      <TextAreaWithSuffix suffix={suffix}>
        {getFieldDecorator(name, {
          rules,
          initialValue: initialValue || '',
        })(
          <TextArea
            name={name}
            placeholder={placeholder}
            area={area}
            onBlur={handleOnBlur}
            disabled={disabled}
            ref={focusableNode}
          />,
        )}
        {suffix && <TextAreaSuffix>{suffix}</TextAreaSuffix> }
      </TextAreaWithSuffix>
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default TextareaField;
