import React from 'react';

import Button from 'components/Button';

import {
  Root,
  RedirectContainer,
  RedirectButtonsContainer,
  RedirectTitle,
  TextItem,
} from './style';

function RedirectModal({
  // passed
  close,
  visible,
  handleSave,

}) {
  const handleFinish = () => {
    handleSave();
    close();
  };

  return (
    <Root
      visible={visible}
      onCancel={close}
      footer={null}
      width={768}
    >
      <RedirectContainer>
        <RedirectTitle>
          <TextItem> Измененные данные не сохранены. Вы действительно хотите перейти без сохранения данных?</TextItem>
        </RedirectTitle>
        <RedirectButtonsContainer>
          <Button
            type="ghost"
            shape="square"
            size="small"
            onClick={() => close()}
          >
            Вернуться к редактированию
          </Button>
          <Button
            type="primary"
            shape="square"
            size="small"
            onClick={handleFinish}
          >
            Не сохранять
          </Button>
        </RedirectButtonsContainer>
      </RedirectContainer>
    </Root>
  );
}

export default RedirectModal;
