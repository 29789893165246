import { API_TCM_URL as API } from 'settings/api';
import { getToken } from 'helpers/utility';
import { defaultSorting, defaultPageSize } from 'redux/tcmCampaign/reducer';
import { v4 as uuidv4 } from 'uuid';

export const fetchTopics = () => {
  const URI = `${API}/tcm/subtopics`;
  const data = {};

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchList = ({
  search = '',
  page = 1,
  filters = {},
  sorting = defaultSorting,
}) => {
  const URI = `${API}/tcm/campaigns`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        ...filters,
        ...(search ? { query: search } : {}),
      },
      meta: {
        sort: sorting,
      },
      // hasnt been implemented yet
      _pagination: {
        page,
        size: defaultPageSize,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const create = ({
  status,
  subtopicId,
  countCarsInStock,
  dialingFrom,
  dialingTo,
  comment,
}) => {
  const URI = `${API}/tcm/campaign/create`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        status,
        subtopicId,
        countCarsInStock,
        dialingFrom,
        dialingTo,
        comment,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const update = ({
  status,
  subtopicId,
  countCarsInStock,
  dialingFrom,
  dialingTo,
  comment,
  id,
}) => {
  const URI = `${API}/tcm/campaign/update`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id,
        status,
        subtopicId,
        countCarsInStock,
        dialingFrom,
        dialingTo,
        comment,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

/**
 *
 * @param {number} id
 * @return {Promise<Object>}
 */
export const fetchCampaign = ({
  id,
}) => {
  const URI = `${API}/tcm/campaign`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: id && +id,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const setCampaignStatus = ({
  id,
  status,
  comment,
}) => {
  const URI = `${API}/tcm/campaign/status/set`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: Number(id),
        status,
        comment,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const campaignReject = ({
  id,
  status,
  reason,
}) => {
  const URI = `${API}/tcm/campaign/status/set`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        id: Number(id),
        status,
        comment: '',
        reason,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchListStatuses = () => {
  const URI = `${API}/tcm/campaigns/status/list`;
  const data = {};

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchDialingDates = ({
  dialingFrom,
}) => {
  const URI = `${API}/tcm/campaign/dialing-dates`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        ...(dialingFrom ? { dialingFrom } : {}),
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchCampaignOfferList = ({
  id,
  filters,
  sorting = defaultSorting,
  page = 1,
  search = '',
}) => {
  const URI = `${API}/tcm/campaign/offers`;

  const data = {
    ...filters,
    query: search,
    campaignId: id,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        sort: sorting,
      },
      // hasnt been implemented yet
      _pagination: {
        page,
        size: defaultPageSize,
      },
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchFilterOptions = ({ campaignId }) => {
  const URI = `${API}/tcm/campaign/offers/filter/list`;

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        campaignId,
      },
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchCampaignOfferStatuses = () => {
  const URI = `${API}/tcm/manager/offers/status/list`;
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {},
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchCampaignOffersList = ({
  id,
  filters,
  sorting = defaultSorting,
  page = 1,
  search = '',
}) => {
  const URI = `${API}/tcm/manager/offers`;
  const data = {
    campaignId: id,
    query: search,
    ...filters,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        sort: sorting,
      },
// hasnt been implemented yet
      _pagination: {
        page,
        size: defaultPageSize,
      },
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};

export const exportStatusHistory = ({
  id,
}) => {
  const URI = `${API}/tcm/campaign/status/history/export?X-Request-ID=${uuidv4()}&id=${id}&accessToken=${getToken()}`;

  const query = {
    method: 'GET',
  };

  return fetch(URI, query)
    .then((response) => response.blob())
    .then((blob) => blob);
};

export const getCalendarDate = ({
  dateFrom,
  dateTo,
}) => {
  const URI = `${API}/tcm/calendar`;
  const data = {
    dateFrom,
    dateTo,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};

export const getCampaignReport = ({
  campaignIds,
  startAt,
  finishAt,
}) => {
  const URI = `${API}/tcm/report/campaign`;
  const data = {
    campaignIds,
    startAt,
    finishAt,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};
