import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import List from './List';
import Item from './Item';

function Index() {
  return (
    <Switch>
      <Route exact path="/app/management/dealerCenters">
        <Redirect to="/app/management/dealerCenters/list" />
      </Route>

      <Route path="/app/management/dealerCenters/list" component={List} />
      <Route path="/app/management/dealerCenters/new" component={Item} />
      <Route path="/app/management/dealerCenters/:dealerCenterId" component={Item} />
      <Route path="/app/management/dealerCenters/:dealerCenterId/edit" component={Item} />
    </Switch>
  );
}

export default Index;
