import React from 'react';
import {
  BlockRow,
  CalculationInfoContent,
  FieldTitle,
  FieldValue,
} from 'containers/App/TCM/ClientCard/style';
import { Suffix } from 'containers/App/TCM/style';
import { RublerIcon } from 'icons';
import { separateNumberByRank } from 'helpers/numbers';

export const Calculation = ({ calculations, carName, evaluation }) => {
  const { newCarPrice, servicesPrice, tradeInPrice } = evaluation;
  const vehiclePrice = calculations[0]?.vehiclePrice ?? newCarPrice;
  const additionalServicesPrice = calculations[0]?.additionalServicesAmount ?? servicesPrice;

  const {
    creditAmount,
    brand,
    model,
  } = calculations[0] ?? {};

  const carFullName = brand && model
    ? `${brand} ${model}`
    : carName ?? '-';

  return (

    <CalculationInfoContent>
      <BlockRow>

        <div>
          <FieldTitle>Новый автомобиль</FieldTitle>
          {carFullName ? (
            <FieldValue bold>
              {carFullName}
            </FieldValue>
          ) : (
            <FieldValue>Нет данных</FieldValue>
          )}
        </div>

        <div>
          <FieldTitle>Цена автомобиля</FieldTitle>
          {vehiclePrice ? (
            <FieldValue>
              {separateNumberByRank(vehiclePrice)}
              {' '}
              <Suffix><RublerIcon /></Suffix>
            </FieldValue>
          ) : (
            <FieldValue>Нет данных</FieldValue>
          )}
        </div>

        <div>
          <FieldTitle>Доп. сервисы</FieldTitle>
          {additionalServicesPrice ? (
            <FieldValue>
              {separateNumberByRank(additionalServicesPrice)}
              {' '}
              <Suffix><RublerIcon /></Suffix>
            </FieldValue>
          ) : (
            <FieldValue>Нет данных</FieldValue>
          )}
        </div>

        <div>
          <FieldTitle>Сумма кредита</FieldTitle>
          {creditAmount ? (
            <FieldValue>
              {separateNumberByRank(creditAmount)}
              {' '}
              <Suffix><RublerIcon /></Suffix>
            </FieldValue>
          ) : (
            <FieldValue>Нет данных</FieldValue>
          )}
        </div>

        <div>
          <FieldTitle>Цена Trade-In</FieldTitle>
          {tradeInPrice ? (
            <FieldValue>
              {separateNumberByRank(tradeInPrice)}
              {' '}
              <Suffix><RublerIcon /></Suffix>
            </FieldValue>
          ) : (
            <FieldValue>Нет данных</FieldValue>
          )}
        </div>
      </BlockRow>

      {calculations.map((calculation, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BlockRow key={index}>
          <div>
            <FieldTitle>Программа</FieldTitle>
            <FieldValue bold>{calculation.creditProgram || 'Нет данных'}</FieldValue>
          </div>

          <div>
            <FieldTitle>Срок кредита</FieldTitle>
            <FieldValue>{calculation.termOfCredit || 'Нет данных'}</FieldValue>
          </div>

          <div>
            <FieldTitle>Ежемесячный платеж</FieldTitle>
            {calculation.monthlyPaymentAmount ? (
              <FieldValue>
                {separateNumberByRank(calculation.monthlyPaymentAmount)}
                {' '}
                <Suffix><RublerIcon /></Suffix>
              </FieldValue>
            ) : (
              <FieldValue>Нет данных</FieldValue>
            )}
          </div>

          <div>
            <FieldTitle>Остаточный платеж</FieldTitle>
            {calculation.deferredPaymentAmount ? (
              <FieldValue>
                {separateNumberByRank(calculation.deferredPaymentAmount)}
                {' '}
                <Suffix><RublerIcon /></Suffix>
              </FieldValue>
            ) : (
              <FieldValue>Нет данных</FieldValue>
            )}
          </div>

          <div>
            <FieldTitle>Первоначальный взнос</FieldTitle>
            {calculation.firstPaymentAmount ? (
              <FieldValue>
                {separateNumberByRank(calculation.firstPaymentAmount)}
                {' '}
                <Suffix><RublerIcon /></Suffix>
              </FieldValue>
            ) : (
              <FieldValue>Нет данных</FieldValue>
            )}
          </div>
        </BlockRow>
      ))}
    </CalculationInfoContent>
  );
};
