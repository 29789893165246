import styled from 'styled-components';
import { Form, Modal } from 'antd';

export const Root = styled(Modal)`
  & .ant-modal-content {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  & .ant-modal-header {
    padding: 2rem;
  }

  & .ant-modal-body {
    padding: 1rem 2rem 2rem;
  }

  & .ant-form-item {
    margin-bottom: 14px;
  }
  
  label{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 24px !important;
  }
`;

export const FormStyled = styled(Form)``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: ToyotaDisplay;
  font-size: 24px;
  line-height: 30px;
  color: #000;
  margin: 0;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const ErrorsContainer = styled.div`
  margin: 65px 0 20px;
`;

export const RedirectContainer = styled.div`
  margin: 65px 0 20px;
`;

export const RedirectButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 32px;
  align-items: center;
  justify-content: center;
  
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RedirectTitle = styled.div`
  text-align: center;
`;

export const TextItem = styled.p`
  margin-bottom: 0;
  font-size: 20px;
  
  &:last-of-type {
    margin-bottom: 10px;
  }
`;

export const DraggerContainer = styled.div`
  margin: 20px 0;
  position: relative;
  transition: .2s linear all;
  
  ${({ hide }) => hide && `
       height: 50px;
  `}
  
  .ant-upload.ant-upload-drag{
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 5px;
      
      ${({ hide }) => hide && `
        display: none
      `}
  }
  
  .ant-upload-list-item.ant-upload-list-item-undefined.ant-upload-list-item-list-type-text {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 38px;
      color: #7B7B7B;
  }
  
  button {
    width: 293px;
    height: 62px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 38px;
    }
  }
`;
