import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import AutoComplete, { Option } from 'components/AutoComplete';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import XRegExp from 'xregexp';
import { SCROLL_DURATION } from 'settings';
import { mergeArrays } from 'hacks';

// RegExp doesnt support naming capture groups in firefox. https://bugzilla.mozilla.org/show_bug.cgi?id=1362154
const regexEmail = new XRegExp('(?<name>[^@]{0,})?(?:@+)?(?<domain>.*)?', '');

function EmailField({
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  options = [],
  disabled,
  dependents,
  ifChangeClearFields,
  warning,

  form: { getFieldDecorator, getFieldValue, setFields },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,
  dataSource,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const [list, setList] = useState(Array.isArray(options) ? options : []);
  const value = getFieldValue(name) || '';

  function onChange() {
    // should be replaced with named capture group, const { groups: { emailName = '',
    // domain = '' } } = regexEmail.exec(stateValue);
    const [, emailName = '', domain = ''] = regexEmail.exec(value);

    const filteredList = emailName.length > 0
      ? domain
        ? options.filter((E) => E.startsWith(domain))
        : options
      : [];

    setList(filteredList);
  }

  function onSelect(v) {
    // should be replaced with named capture group, const { groups: { emailName = '',
    // domain = '' } } = regexEmail.exec(stateValue);
    const [, emailName = '', domain = ''] = regexEmail.exec(value);

    const filteredList = emailName.length > 0
      ? domain
        ? options.filter((E) => E.startsWith(domain))
        : options
      : [];

    setList(filteredList);

    setFields({
      [name]: {
        value: `${emailName}@${v}`,
      },
    });
  }

  function handleOnBlur() {
    const value = getFieldValue(name);
    if ((initialValue || '') !== value) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      {getFieldDecorator(name, {
        rules,
        initialValue: initialValue || '',
      })(
        <AutoComplete
          name={name}
          placeholder={placeholder}
          area={area}
          onChange={onChange}
          onSelect={onSelect}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={focusableNode}
          size={size}
          suffix={(initialValue === value) && (dataSource === 'digital_profile') ? 'DP' : ' '}
        >
          {value.length > 0
            && list.map((src) => (
              <Option key={src}>
                {`${value.replace(regexEmail, (emailName) => emailName)}@${src}`}
              </Option>
            ))}
        </AutoComplete>,
      )}
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default EmailField;
