import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';

import { Root, CheckboxStyled, ErrorMessage } from './style';

function UploadCheckbox({
  name,
  initialValue,
  onChange,
  label,
  isRequired,
  error,
  disabled,
}) {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  function handleOnChange({ target: { name, checked } }) {
    setValue(checked);
    onChange({ [name]: checked });
  }

  return (
    <Root>
      <CheckboxStyled
        key={name}
        name={name}
        checked={value}
        onChange={handleOnChange}
        disabled={disabled}
      >
        {label}
        {isRequired && <Tooltip title="Обязательное поле">*</Tooltip>}
      </CheckboxStyled>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  );
}

export default UploadCheckbox;
