export const formatCompanies = (data) => {
  const { subsidiariesResponseList, legalEntityResponseList } = data;

  if (Array.isArray(legalEntityResponseList)) {
    return legalEntityResponseList.map((e) => ({
      addressactual: e.addressactual,
      createdAt: e.createdAt,
      email: e.email,
      id: e.id,
      inn: e.inn,
      name: e.name,
      updatedAt: e.updatedAt,

      subsidiariesList: (
        Array.isArray(e.subsidiariesResponseList) && Array.isArray(subsidiariesResponseList)
          ? e.subsidiariesResponseList.map(({ id }) => subsidiariesResponseList.find((s) => s.id === id))
          : {}
      ),
    }));
  }
  return [];
};

export const formatCompaniesAndDealerCenters = (data) => {
  const { subsidiariesResponseList, legalEntityResponseList } = data;

  const companies = [];
  const dealerCenters = [];
  const dealerCentersWithParents = {};

  if (Array.isArray(legalEntityResponseList)) {
    legalEntityResponseList.forEach((company) => {
      const formatedCompany = ({
        addressactual: company.addressactual,
        createdAt: company.createdAt,
        email: company.email,
        id: company.id,
        inn: company.inn,
        name: company.name,
        updatedAt: company.updatedAt,
      });

      const subsidiariesList = (
        Array.isArray(company.subsidiariesResponseList) && Array.isArray(subsidiariesResponseList)
          ? company.subsidiariesResponseList.map((dealerCenter) => {
            const element = subsidiariesResponseList.find((s) => s.id === dealerCenter.id);
            if (element) {
              dealerCentersWithParents[dealerCenter.id] = formatedCompany;

              return ({
                id: element.id,
                name: element.salePoint || element.name,
                salePoint: element.salePoint,
                inn: element.inn,
                krifOrganizationId: element.krifOrganizationId,
                krifOrganizationName: element.krifOrganizationName,
                organisation: formatedCompany,
              });
            }
            return null;
          }).filter(Boolean)
          : []
      );

      companies.push({
        ...formatedCompany,
        subsidiariesList,
      });
    });
  }

  if (Array.isArray(subsidiariesResponseList)) {
    subsidiariesResponseList.forEach((dealerCenter) => {
      dealerCenters.push({
        id: dealerCenter.id,
        name: dealerCenter.salePoint || dealerCenter.name,
        salePoint: dealerCenter.salePoint,
        inn: dealerCenter.inn,
        krifOrganizationId: dealerCenter.krifOrganizationId,
        organisation: dealerCentersWithParents[dealerCenter.id] || {},
      });
    });
  }

  return { companies, dealerCenters };
};
