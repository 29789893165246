import { getToken } from 'helpers/utility';
import { API_URL as API } from 'settings/api';
import { v1 as getId } from 'uuid';
import { formatFilters } from 'hacks';

import { defaultSorting, defaultPageSize } from 'redux/contracts/reducer';

export const fetchList = ({
  page = 1,
  filters = {},
  sorting = defaultSorting,
}) => {
  const URI = `${API}/admin/acquiring-agreement/list`;

  const data = {
    ...formatFilters(filters),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
      meta: {
        pagination: {
          page,
          pageSize: defaultPageSize,
        },
        sort: sorting,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const updateContract = ({
  file,
  sector,
}) => {
  const URI = `${API}/admin/acquiring-agreement/for-update`;

  const data = {
    file,
    sector: String(sector),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchItem = ({
  id,
}) => {
  const URI = `${API}/admin/acquiring-agreement/get`;

  const data = {
    id: Number(id),
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const createContract = ({
  file,
}) => {
  const URI = `${API}/admin/acquiring-agreement/for-create`;

  const data = {
    file,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const saveContract = ({
  data,
}) => {
  const URI = `${API}/admin/acquiring-agreement/save`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const deleteContract = ({
  id,
}) => {
  const URI = ''; // пока нет метода удаления;

  const data = {
    id,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const fetchPointOfSalesList = ({
  inn,
  sector,
}) => {
  const URI = `${API}/admin/subsidiaries/list`;

  const data = {
    inn,
    sector,
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const unbindPointsOfSale = ({
  sector,
  subsidiariesIds,
}) => {
  const URI = `${API}/admin/subsidiaries/unbind/sector/from/subsidiaryIds`;

  const data = {
    sectors: [{
      sector: Number(sector),
      subsidiariesIds,
    }],
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const bindPointsOfSale = ({
  sector,
  subsidiariesIds,
}) => {
  const URI = `${API}/admin/subsidiaries/bind/sector/to/subsidiaryIds`;

  const data = {
    sectors: [{
      sector: Number(sector),
      subsidiariesIds,
    }],
  };

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export default {
  fetchList,
  fetchItem,
  createContract,
  saveContract,
  updateContract,
  deleteContract,
  fetchPointOfSalesList,
  bindPointsOfSale,
  unbindPointsOfSale,
};
