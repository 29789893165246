import styled from 'styled-components';
import { Form } from 'antd';

export const Root = styled.div`
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  padding: 2rem 4rem 0;

  & .ant-input-number {
    min-width: 160px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const HeaderTitle = styled.header`
  font-size: 28px;
  line-height: 38px;
  padding-bottom: 2rem;
`;
export const Title = styled.h3`
  font-family: "Open Sans";
  font-size: 24px;
  line-height: 30px;
  color: #000;
`;

export const Actions = styled.div`
  display: flex;
`;

export const ActionGroup = styled.div`
  & + & {
    margin-left: 1rem;
  }
`;

export const Main = styled(Form)`
  padding: 2rem 0;
  border-top: 1px solid #e6e6e6;

  /* Semi-global styles */
  & .ant-form-item-control {
    margin-bottom: 0.4rem;
  }

  & .ant-form-item-label {
    padding: 0 0 4px;
    display: flex;
  }

  & .ant-form-item-label > label {
    height: auto;
  }

  & textarea.ant-input {
    margin-bottom: 0px;
  }

  & .ant-btn {
    min-width: 160px !important;
  }
`;

export const Rows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6rem;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const RowFooter = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`;

export const Footer = styled.footer`
  padding: 1rem;
  background: rgba(0, 0, 0, 0.09);
  text-align: center;
`;

export const FooterReadOnly = styled.footer`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  padding-top: 1rem;
  margin-bottom: 2rem;
`;

export const FooterAction = styled.footer`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  padding-top: 1rem;
`;

export const FooterRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 400px;
  margin: 0 auto;
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const SmallFormRow = styled.div`
  display: grid;
  grid-template-columns: .35fr .35fr;
  grid-gap: 2rem;
  
  .ant-row.ant-form-item{
    min-width: 140px;
  }
  
  .ant-picker{
    width: 100%;
    border-radius: 5px;
  }
`;

export const FormActionGroup = styled.div``;

export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const FormSubmit = styled.div`
  padding-left: 1rem;
  margin-top: calc(1.2rem + 3px); // height of label + padding of label
`;

export const Label = styled.div`
  & > * {
    opacity: 0.8;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SuccessMessage = styled.div`
  color: green;
  padding: 0 0 1rem 0;
`;

export const Status = styled.div`
  color: #096dd9;
`;

const OPERATIONS_TYPE_COLORS = {
  Оплата: '#2E82FF',
  Зарегистрирован: '#2E82FF',
  Завершен: '#2E82FF',
  Авторизация: '#2E82FF',
  Возврат: '#E71313',
};

export const Type = styled.div`
  color: ${(props) =>
    (OPERATIONS_TYPE_COLORS[props.type] ? OPERATIONS_TYPE_COLORS[props.type] : '#E71313')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
