import styled from 'styled-components';

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Description = styled.p`
  font-size: 0.9rem;
  text-decoration: ${(props) => (props.confirmed ? 'none' : 'line-through')};
`;

const Title = styled.h4`
  font-size: 1rem;
  font-weight: bold;
`;

export { Header, Description, Title };
