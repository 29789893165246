import API from 'services';

import {
  extractErrorAndCode,
  formatCrif,
  formatCrifResponse,
  isBooleanInPhP,
} from 'hacks';

import { TYPES, isSearchAvailable, CRIF_SEARCH_PARAMS } from 'settings';

import {
  all, call, fork, put, takeLatest, take, select, cancel,
} from 'redux-saga/effects';

import { ON_ERROR } from 'redux/auth/actions';

import { REQUEST__PATCH } from 'redux/request/actions';

import {
  CRIF_CHECK_MORATORIUM,
  CRIF_SET_MORATORIUM,
  CRIF_SEARCH_UNLOCK,
  CRIF_BUTTON_UNLOCK,
  CRIF_CANCEL_REQUEST,
  CRIF_SET_PAGINATION,
  CRIF_SET_LOADING,
  CRIF_SET_PAGE,
  CRIF_SET_SORTING,
  CRIF_FETCH_LIST,
  CRIF_FETCH_LIST__SUCCESS,
  CRIF_FETCH_LIST__FAILURE,
} from './actions';

const getRequestState = (state) => state.request;
const getCrifState = (state) => state.crif;

export function* checkMoratorium() {
  yield takeLatest(CRIF_CHECK_MORATORIUM, function* () {
    const { values: currentValues } = yield select(getRequestState);
    const { list, form, hasMoratorium } = yield select(getCrifState);
    const currentUser = list.find((e) => String(e.crmId) === currentValues[TYPES.CRM_SELECT]) || {};
    const { moratorium } = currentUser;
    const dataHasChanged = Object.keys(form).some((k) => form[k] !== currentValues[k]);

    yield put({
      type: CRIF_SET_MORATORIUM,
      currentUser,
      moratorium: isBooleanInPhP(moratorium),
      searchAvailable: isSearchAvailable(currentValues),
      dataHasChanged,
    });

    if (dataHasChanged && hasMoratorium) {
      yield put({
        type: REQUEST__PATCH,
        userInput: {},
        exclude: [TYPES.CRM_SELECT],
      });
    }
  });
}

export function* _fetchList() {
  const {
    fetch: { id: accessToken },
    values,
  } = yield select(getRequestState);

  if (isSearchAvailable(values)) {
    try {
      const form = CRIF_SEARCH_PARAMS.reduce((p, k) => ({ ...p, [k]: values[k] }), {});
      yield put({ type: CRIF_SET_LOADING, form });
      const response = yield call(API.crif.fetchList, {
        accessToken,
        ...formatCrif(values),
      });

      if (
        response
        && response.data
        && ((Array.isArray(response.data) && response.data.length === 0)
          || response.data.userCrifDataList)
      ) {
        const list = formatCrifResponse(response.data.userCrifDataList);
        const { values: currentValues } = yield select(getRequestState);
        const currentUser = list.find((e) => String(e.crmId) === currentValues[TYPES.CRM_SELECT]) || {};
        const { moratorium } = currentUser;

        yield put({
          type: CRIF_FETCH_LIST__SUCCESS, list, currentUser, moratorium: isBooleanInPhP(moratorium),
        });

        // we send { [TYPES.CRM_RESULTS]: true } to make crmSelect field required
        // - in case if there is no active moratorium and crif search contains some results
        // we send { [TYPES.CRM_RESULTS]: false } to remove crmSelect field from the form
        // - in case if there is no active moratorium and crif contains no results
        if (!isBooleanInPhP(moratorium)) {
          if (list.length > 0) {
            if (currentValues[TYPES.CRM_RESULTS] !== true) {
              yield put({
                type: REQUEST__PATCH,

                userInput: {
                  [TYPES.CRM_RESULTS]: true,
                },
              });
            }
          } else if (currentValues[TYPES.CRM_RESULTS] === true) {
            yield put({
              type: REQUEST__PATCH,

              userInput: {
                [TYPES.CRM_RESULTS]: false,
              },
            });
          }
        }
      } else {
        const { error, code } = extractErrorAndCode(response);
        yield put({ type: ON_ERROR, errorCode: code });
        throw new Error(error);
      }
    } catch (error) {
      yield put({ type: CRIF_FETCH_LIST__FAILURE, error: error.message });
    }
  }
}

function* fetchSync() {
  const fetchSyncTask = yield fork(_fetchList);
  yield take(CRIF_CANCEL_REQUEST);
  yield cancel(fetchSyncTask);
}

export function* fetchList() {
  yield takeLatest(CRIF_FETCH_LIST, function* () {
    const { buttonIsLocked } = yield select(getCrifState);
    if (buttonIsLocked) {
      yield put({ type: CRIF_BUTTON_UNLOCK });
    }
    yield call(fetchSync);
  });
}
export function* fetchListOnPageChange() {
  yield takeLatest(CRIF_SET_PAGE, function* (action) {
    yield put({ type: CRIF_SET_PAGINATION, page: action.page });
    yield put({ type: CRIF_CANCEL_REQUEST });
    yield put({ type: CRIF_FETCH_LIST });
  });
}

export function* fetchListOnSorting() {
  yield takeLatest(CRIF_SET_SORTING, function* () {
    yield put({ type: CRIF_CANCEL_REQUEST });
    yield put({ type: CRIF_FETCH_LIST });
  });
}

export function* removeMoratorium() {
  yield takeLatest(CRIF_SEARCH_UNLOCK, function* () {
    const { values } = yield select(getRequestState);

    if (values[TYPES.CRM_RESULTS] === true) {
      yield put({
        type: REQUEST__PATCH,

        userInput: {
          [TYPES.CRM_RESULTS]: false,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkMoratorium),
    fork(fetchList),
    fork(fetchListOnPageChange),
    fork(fetchListOnSorting),
    fork(removeMoratorium),
  ]);
}
