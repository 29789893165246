import { TOYOTA_URL as API, API_URL as ARM_API } from 'settings/api';
import { v1 as getId } from 'uuid';
import { getToken } from 'helpers/utility';

export const reCalc = ({
  oldData,
  newData,
  dealerCenter,
}) => {
  const URI = `${API}/credit-data/calc`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data: {
        before: oldData,
        after: newData,
        dealerCenterName: dealerCenter,
      },
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const saveResult = ({
  data,
}) => {
  const URI = `${ARM_API}/tcm/offer/calculation/set`;

  const query = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': getId(),
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};
