import { v4 as uuidv4 } from 'uuid';
import { API_TCM_URL as API } from 'settings/api';
import { getToken } from 'helpers/utility';

export const fetchEmployeesList = ({ search }) => {
  const URI = `${API}/tcm/employees`;
  const data = {
    ...(search ? { query: search } : {}),
  };
  const query = {
    method: 'POST',
    headers: {
      'X-Request-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, query)
    .then((response) => response.json())
    .then((json) => json);
};

export const getEmployeeReport = ({
  campaignIds,
  employeeIds,
  startAt,
  finishAt,
}) => {
  const URI = `${API}/tcm/report/employee`;
  const data = {
    campaignIds,
    employeeIds,
    startAt,
    finishAt,
  };

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: getToken(),
      data,
    }),
  };

  return fetch(URI, request)
    .then((response) => response.json())
    .then((json) => json);
};
