/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { useForm } from 'hooks';

import TableHeader, { FILTER_TYPES } from 'components/TableHeader';

import * as actions from 'redux/creditRequest/actions';
import { initialFilters } from 'redux/creditRequest/reducer';

import {
  Root,
  LoadingMessage,
  ErrorMessage,
  EmptyMessage,
  LinkStyled,
} from './style';

const {
  reset: resetTableAction,
  fetchList: fetchDataAction,
  setPage: setTablePageAction,
  setSorting: setSortingAction,
  setFilters: setFiltersAction,
} = actions;

const scroll = { y: 'calc(100vh - 380px)' };
const showTotal = (total, range) => `${range[0]}-${range[1]} из ${total}`;
const MIN_FILTER_LENGTH = 3;

function TableComponent({
  // connect
  list,
  filters,
  sorting,
  isLoading,
  error,
  pagination,

  // actions
  resetTable,
  setTablePage,
  setSorting,
  setFilters,
}) {
  const { form, updateForm } = useForm(initialFilters);

  const goToPage = (page, pageSize) => setTablePage({ pagination: { page, pageSize } });

  useEffect(() => {
    if (list.length > 0
      && (filters.fullName || '').length < MIN_FILTER_LENGTH && (form.fullName || '').length < MIN_FILTER_LENGTH
      && (filters.crifId || '').length < MIN_FILTER_LENGTH && (form.crifId || '').length < MIN_FILTER_LENGTH) return undefined;

    setFilters({
      filters: {
        fullName: (form.fullName || '').length < MIN_FILTER_LENGTH ? '' : form.fullName,
        crifId: (form.crifId || '').length < MIN_FILTER_LENGTH ? '' : form.crifId,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, setFilters]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => resetTable(), []);

  const columns = [
    {
      key: 'crifId',
      title: () =>
        TableHeader({
          title: 'ID заявки CRIF',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'crifId',
          filterValue: form.crifId,
          filterOnChange: updateForm,
        }),
      render: ({ crifId, creditToken }) => (
        <LinkStyled to={`/app/leads/credit-request/${creditToken}`}>{crifId}</LinkStyled>
      ),
      width: 120,
    },
    {
      key: 'clientFullname',
      title: () =>
        TableHeader({
          title: 'ФИО заемщика',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'fullName',
          filterValue: form.fullName,
          filterOnChange: updateForm,
        }),
      render: ({ clientFullname, creditToken }) => (
        <LinkStyled to={`/app/leads/credit-request/${creditToken}`}>
          {clientFullname}
        </LinkStyled>
      ),
      width: 220,
    },
    {
      key: 'deadlineDate',
      title: () =>
        TableHeader({
          title: 'Дедлайн подписания',
          sortBy: 'dealSigningDate',
          sorting,
          onClick: setSorting,
        }),
      render: ({ deadlineDate, creditToken }) => (
        <LinkStyled to={`/app/leads/credit-request/${creditToken}`}>
          {deadlineDate ? moment(deadlineDate).format('DD.MM.YYYY HH:mm') : '-'}
        </LinkStyled>
      ),
      width: 220,
    },
    {
      key: 'signingStatus',
      title: () =>
        TableHeader({
          title: 'Статус подписания',
          sortBy: 'documentationSigned',
          sorting,
          onClick: setSorting,
        }),
      render: ({ signingStatus, creditToken }) => (
        <LinkStyled to={`/app/leads/credit-request/${creditToken}`}>
          {signingStatus ? 'Подписан' : 'Не подписан'}
        </LinkStyled>
      ),
      width: 220,
    },
    {
      key: 'signingDateTime',
      title: () =>
        TableHeader({
          title: 'Дата время подписания',
          sortBy: 'documentSigningDate',
          sorting,
          onClick: setSorting,
        }),
      render: ({ signingDateTime, creditToken }) => (
        <LinkStyled to={`/app/leads/credit-request/${creditToken}`}>
          {signingDateTime ? moment(signingDateTime).format('DD.MM.YYYY HH:mm') : '-'}
        </LinkStyled>
      ),
      width: 220,
    },
  ];

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };

  const paginationObj = {
    current: pagination.page,
    pageSize: pagination.pageSize,
    total: pagination.rowsCount ? pagination.rowsCount : 0,
    showTotal,
    onChange: goToPage,
    showSizeChanger: true,
    pageSizeOptions: [10, 25, 50, 100],
  };

  return (
    <Root>
      <Table
        rowKey={(record) => record.crifId}
        columns={columns}
        dataSource={list}
        pagination={paginationObj}
        bordered
        loading={isLoading}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    list: state.creditRequest.collection.list,
    sorting: state.creditRequest.collection.sorting,
    filters: state.creditRequest.collection.filters,
    isLoaded: state.creditRequest.collection.isLoaded,
    isLoading: state.creditRequest.collection.isLoading,
    error: state.creditRequest.collection.error,
    pagination: state.creditRequest.collection.pagination || {},
  }),
  {
    resetTable: resetTableAction,
    fetchData: fetchDataAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
  },
)(withRouter(TableComponent));
