import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Table } from 'antd';
import moment from 'moment';

import { setBreadcrumbs as setBreadcrumbsAction } from 'redux/breadcrumbs/actions';

import {
  contractCreate as contractCreateAction,
  contractCreateReset as contractCreateResetAction,

  fetchList as fetchListAction,
  setPage as setTablePageAction,
  setSorting as setSortingAction,
  setFilters as setFiltersAction,
  setSearch as setSearchAction,
  reset as resetTableAction,
} from 'redux/contracts/actions';

import {
  fetchList as fetchPointOfSalesListActions,
} from 'redux/dealerCenter/actions';

import {
  fetchList as fetchCompanyListAction,
} from 'redux/company/actions';

import { useForm } from 'hooks';
import { initialFilters } from 'redux/contracts/reducer';

import TableHeader, { FILTER_TYPES } from 'components/TableHeader';

import {
  STATUS_OPTIONS,
  STATUS_VALUES,
  formatToTable,
  formatToApi,
} from './data';

import {
  Header,
  HeaderTitle,
  Root,
  Title,
  Count,
  ItemsContainer,
  ItemsContent,
  ItemsContentContainer,
  Date,
  Requisites,
  EmptyMessage,
  ErrorMessage,
  LoadingMessage,
} from './style';

const scroll = { y: 'calc(100vh - 460px)' };
const showTotal = () => '';

const renderItems = (array) => (
  <ItemsContainer>
    {Array.isArray(array) && array.length === 1 && array[0]}
    {Array.isArray(array) && array.length > 1 && (
      <ItemsContentContainer content={(
        <ItemsContent>
          {array.map((item, index) => index !== 0 && (<div>{item}</div>))}
        </ItemsContent>
      )}
      >
        {array[0]}
        <Count>
          {' '}
          {array.length > 9 ? '9+' : array.length - 1 }
          {' '}
        </Count>
      </ItemsContentContainer>
    )}
  </ItemsContainer>
);

function List({
  // passed
  role,

  // store
  item,

  createIsLoaded,

  list,
  sorting,
  isLoading,
  isLoaded,
  error,
  pagination,

  pointOfSalesList,
  pointOfSalesIsLoaded,
  pointOfSalesIsLoading,

 // actions
  setTablePage,
  contractCreateReset,
  setSorting,
  setFilters,
  resetTable,

  fetchCompanyList,

  fetchPointOfSalesList,
}) {
  const [pointOfSalesOptions, setPointOfSalesOptions] = useState([]);
  const [page, setPage] = useState(1);
  const { form, updateForm } = useForm(initialFilters);
  const history = useHistory();
  const { companyId } = useParams();

  function goToPage(value) {
    setPage(value);
    setTablePage({ page: value });
  }

  const locale = {
    emptyText: isLoading ? (
      <LoadingMessage>Данные загружаются</LoadingMessage>
    ) : error ? (
      <ErrorMessage>{error}</ErrorMessage>
    ) : (
      <EmptyMessage>Данные не найдены</EmptyMessage>
    ),
  };
  const { pageSize, rowsCount } = pagination;

  const paginationObj = {
    current: page,
    pageSize,
    total: rowsCount,
    showTotal,
    onChange: goToPage,
    showSizeChanger: false,
  };

  const COLUMNS = [
    {
      key: 'subsidiariesIds',
      title: () =>
        TableHeader({
          title: 'Точка продаж',
          filterType: FILTER_TYPES.SELECT_MULTIPLE,
          filterName: 'subsidiariesIds',
          filterValue: form.subsidiariesIds,
          filterOnChange: updateForm,
          filterOptions: pointOfSalesOptions,
          hasDefault: false,
          filterIsLoading: pointOfSalesIsLoading,
          disabled: pointOfSalesIsLoading,
        }),
      render: ({ id, subsidiariesNameArray = [] }) => (
        <Link to={`/app/management/companies/${companyId}/contracts/${id}`}>
          {renderItems(subsidiariesNameArray)}
        </Link>
      ),
      width: 180,
      align: 'left',
    },
    {
      key: 'sectors',
      title: () =>
        TableHeader({
          title: 'Сектор',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'sectors',
          filterValue: form.sectors,
          filterOnChange: updateForm,
        }),
      render: ({ id, sector }) => (
        <Link to={`/app/management/companies/${companyId}/contracts/${id}`}>
          {sector}
        </Link>
      ),
      width: 200,
      align: 'left',
    },
    {
      key: 'requisites',
      title: () =>
        TableHeader({
          title: 'Реквизиты',
          filterType: FILTER_TYPES.INPUT,
          filterName: 'searchStr',
          filterValue: form.requisites,
          filterOnChange: updateForm,
        }),
      render: ({ id, contractNumber, contractDate }) => (
        <Link to={`/app/management/companies/${companyId}/contracts/${id}`}>
          <Requisites>{contractNumber || ''}</Requisites>
          <Date>
            {contractDate
              ? moment(contractDate).isValid()
                ? moment(contractDate).format('DD.MM.YYYY')
                : ''
              : ''}
          </Date>
        </Link>
      ),
      width: 260,
      align: 'left',
    },
    {
      key: 'status',
      title: () =>
        TableHeader({
          title: 'Статус',
          sortBy: 'status',
          sorting,
          onClick: setSorting,
          filterType: FILTER_TYPES.SELECT,
          filterName: 'status',
          filterValue: form.status,
          filterOnChange: updateForm,
          filterOptions: STATUS_OPTIONS,
        }),
      render: ({ id, status = 'статус' }) => (
        <Link to={`/app/management/companies/${companyId}/contracts/${id}`}>
          { STATUS_VALUES[status] || '' }
        </Link>
      ),
      width: 140,
      align: 'left',
    },
  ];

  useEffect(() => {
    const { subsidiariesIds, sectors } = form;

    setFilters({
      filters: {
        ...form,
        legalEntityIds: [Number(companyId)],
        subsidiariesIds: formatToApi({
          array: subsidiariesIds,
          options: pointOfSalesOptions,
        }),
        ...(sectors ? { sectors: [Number(sectors)] } : {}),
      },
    });
    return () => resetTable();
        // eslint-disable-next-line
    }, [companyId, setFilters, resetTable, form]);

  useEffect(() => {
    if (isLoaded) {
      fetchCompanyList({
        withPagination: false,
      });
    }
  }, [isLoaded, fetchCompanyList, role]);

  useEffect(() => {
    if (createIsLoaded) {
      const { sector = null } = item;
      if (sector) {
        history.push(`/app/payments/contracts/${sector}/new`);
      }
    }
  }, [createIsLoaded, history, item]);

  useEffect(() => {
    if (createIsLoaded) {
      contractCreateReset();
    }
  }, [createIsLoaded, contractCreateReset]);

  useEffect(() => {
    fetchPointOfSalesList({
      withPagination: false,
    });
  }, [fetchPointOfSalesList]);

  useEffect(() => {
    if (pointOfSalesIsLoaded && (pointOfSalesList.length > 0)) {
      setPointOfSalesOptions(formatToTable(pointOfSalesList));
    }
  }, [pointOfSalesIsLoaded, pointOfSalesList]);

  return (
    <Root>
      <Header>
        <HeaderTitle>
          <Title>Все договоры</Title>
        </HeaderTitle>
      </Header>
      <Table
        rowKey={({ id }) => id}
        columns={COLUMNS}
        dataSource={list}
        loading={isLoading}
        bordered
        pagination={paginationObj}
        locale={locale}
        scroll={scroll}
      />
    </Root>
  );
}

export default connect(
  (state) => ({
    role: state.auth.role,
    item: state.contracts.item.data,
    createIsLoaded: state.contracts.create.isLoaded,
    createIsLoading: state.contracts.create.isLoading,
    createError: state.contracts.create.error,

    list: state.contracts.collection.list,
    sorting: state.contracts.collection.sorting,
    filters: state.contracts.collection.filters,
    isLoaded: state.contracts.collection.isLoaded,
    isLoading: state.contracts.collection.isLoading,
    error: state.contracts.collection.error,
    pagination: state.contracts.collection.pagination,

    pointOfSalesList: state.dealerCenter.collection.list,
    pointOfSalesIsLoaded: state.dealerCenter.collection.isLoaded,
    pointOfSalesIsLoading: state.dealerCenter.collection.isLoading,

    companyList: state.company.collection.list,
    companyListIsLoaded: state.company.collection.isLoaded,
    companyListIsLoading: state.company.collection.isLoading,
  }),
  {
    setBreadcrumbs: setBreadcrumbsAction,
    contractCreate: contractCreateAction,
    contractCreateReset: contractCreateResetAction,

    fetchList: fetchListAction,
    setTablePage: setTablePageAction,
    setSorting: setSortingAction,
    setFilters: setFiltersAction,
    setSearch: setSearchAction,
    resetTable: resetTableAction,

    fetchPointOfSalesList: fetchPointOfSalesListActions,

    fetchCompanyList: fetchCompanyListAction,
  },
)(List);
