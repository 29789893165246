import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import AutoComplete, { Option } from 'components/AutoComplete';
import Error from 'components/Fields/Error';
import Label from 'components/Fields/Label';
import Warning from 'components/Fields/Warning';
import { SCROLL_DURATION } from 'settings';
import { mergeArrays } from 'hacks';

import { LoadingOutlined } from '@ant-design/icons';

import { fetch as fetchCountries } from 'redux/countries/actions';
import { InputContainer, Suffix, WithSuffix } from './style';

function CountryField({
  // passed
  name,
  type,
  label,
  error: errorMessage,
  placeholder,
  area,
  disabled,
  dependents,
  ifChangeClearFields,
  warning,
  dataSource,

  form: { getFieldDecorator, getFieldValue, setFieldsValue },

  onChangeField,
  rules,
  initialValue,

  fieldToScroll: { name: fieldToScrollName } = {},
  onScrollCallback,

  // connect
  countriesList,
  countriesIsLoaded,
  countriesIsLoading,
  countriesError,

  // actions
  fetchCountries,
  size,
}) {
  const scrollableNode = useRef();
  const focusableNode = useRef();

  const [list, setList] = useState(countriesList);
  const value = getFieldValue(name);

  useEffect(() => {
    const v = (countriesList.find((e) => e.value === initialValue) || {}).value
      || String(initialValue || '');

    if (getFieldValue(name) !== v) {
      setFieldsValue({
        [name]: v,
      });
    }
  }, [initialValue, countriesList, getFieldValue, setFieldsValue, name]);

  useEffect(() => {
    if (!(countriesIsLoaded || countriesIsLoading || countriesError)) {
      fetchCountries();
    }
    if (countriesIsLoaded) {
      setList(countriesList);
    }
  }, [
    countriesIsLoaded,
    countriesIsLoading,
    countriesError,
    countriesList,
    fetchCountries,
  ]);

  function onChange(v) {
    const filteredList = v.length > 0
      ? countriesList.filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
      : countriesList;

    setList(filteredList);
  }

  function onSelect(v) {
    const value = getFieldValue(name);
    const filteredList = value.length > 0
      ? countriesList.filter((E) => E.label.toLowerCase().includes(v.toLowerCase()))
      : countriesList;

    setList(filteredList);
  }

  function handleOnBlur() {
    if (
      ((countriesList.find((e) => e.value === initialValue) || {}).value || '') !== value
    ) {
      onChangeField({ [name]: value }, mergeArrays(dependents, ifChangeClearFields));
    }
  }

  const error = /*! isFieldTouched(name) && */ errorMessage;

  const scrollTo = useCallback(() => {
    const scrollNode = scrollableNode.current;
    if (scrollNode) {
      scrollNode.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof onScrollCallback === 'function') {
        onScrollCallback();
      }

      setTimeout(() => {
        const focusNode = focusableNode.current;
        if (focusNode) {
          focusNode.focus();
        }
      }, SCROLL_DURATION);
    }
  }, [scrollableNode, focusableNode, onScrollCallback]);

  useEffect(() => {
    if (name === fieldToScrollName) {
      scrollTo();
    }
  }, [name, fieldToScrollName, scrollTo]);

  const suffix = (initialValue === value) && (dataSource === 'digital_profile') ? 'DP' : null;

  return (
    <>
      <Label label={label} area={area} ref={scrollableNode} type={type} name={name} />
      <WithSuffix suffix={suffix} area={area}>
        <InputContainer>
          {getFieldDecorator(name, {
            rules,
            initialValue:
              (countriesList.find((e) => e.value === initialValue) || {}).value || '',
          })(
            <AutoComplete
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              onSelect={onSelect}
              onBlur={handleOnBlur}
              disabled={disabled}
              notFoundContent={
                countriesError ? (
                  <span>{countriesError}</span>
                ) : countriesIsLoading ? (
                  <LoadingOutlined />
                ) : (
                  <span>нет данных</span>
                )
              }
              ref={focusableNode}
              size={size}
            >
              {list.map((option) => (
                <Option key={option.value}>{option.value}</Option>
              ))}
            </AutoComplete>,
          )}
        </InputContainer>
        {suffix && <Suffix>{suffix}</Suffix>}
      </WithSuffix>
      <Warning text={warning} area={area} />
      <Error error={error} area={area} />
    </>
  );
}

export default connect(
  (state) => ({
    countriesList: state.countries.list,
    countriesIsLoaded: state.countries.isLoaded,
    countriesIsLoading: state.countries.isLoading,
    countriesError: state.countries.error,
  }),
  {
    fetchCountries,
  },
)(CountryField);
