import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { Comment, Form, Input } from 'antd';
import moment from 'moment';

import API from 'services';

import Loader from 'components/Loader';
import Button from 'components/Button';

import { KEYS as K } from './data';

import {
  Root,
  CommentsContainer,
  Main,
  Container,
  ErrorMessage,
  SuccessMessage,
} from './style';

const { TextArea } = Input;

const initialState = {
  fetch: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: null,
  },
  send: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: null,
  },
};

const ACTIONS = {
  FETCH: 'FETCH',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
  SEND: 'SEND',
  SEND_SUCCESS: 'SEND_SUCCESS',
  SEND_FAILURE: 'SEND_FAILURE',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.FETCH: {
      return {
        ...state,
        fetch: {
          ...initialState.fetch,
          isLoading: true,
        },
      };
    }
    case ACTIONS.FETCH_SUCCESS: {
      return {
        ...state,
        fetch: {
          ...state.fetch,
          list: action.list,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case ACTIONS.FETCH_FAILURE: {
      return {
        ...state,
        fetch: {
          ...state.fetch,
          error: action.error,
          isLoading: false,
        },
      };
    }
    case ACTIONS.SEND: {
      return {
        ...state,
        send: {
          ...initialState.send,
          isLoading: true,
        },
      };
    }
    case ACTIONS.SEND_SUCCESS: {
      return {
        ...state,
        send: {
          ...state.send,
          send: action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case ACTIONS.SEND_FAILURE: {
      return {
        ...state,
        send: {
          ...state.send,
          error: action.error,
          isLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
}

const CommentComponent = ({
  content,
  date = '',
  author = '',
}) => (
  <Comment
    author={author}
    content={(
      <p>
        {content}
      </p>
    )}
    datetime={moment(date).isValid()
      ? (
        <span>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</span>
      ) : 'Нет данных'}
  />
);

function Comments({
  // passed
  id,
}) {
  const [form] = Form.useForm();
  const messagesEndRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    fetch: {
      list,
      isLoading: fetchIsLoading,
      isLoaded: fetchIsLoaded,
      error: fetchError,
    },
    send: {
      isLoading: sendIsLoading,
      isLoaded: sendIsLoaded,
      error: sendError,
    },
  } = state;

  const fetchComments = useCallback(() => {
    if (id) {
      dispatch({ type: ACTIONS.FETCH });
      API.request
        .fetchComments({
          creditRequestId: id,
        })
        .then((results) => {
          if (results.data.commentItemList || Array.isArray(results.data)) {
            dispatch({ type: ACTIONS.FETCH_SUCCESS, list: results.data.commentItemList || [] });
          } else {
            throw new Error('Возникла ошибка при получении списка комметариев');
          }
        })
        .catch((error) => {
          dispatch({ type: ACTIONS.FETCH_FAILURE, error: error.message });
        });
    }
  }, [id]);

  const sendComment = useCallback((text) => {
    dispatch({ type: ACTIONS.SEND });
    API.request
      .sendComment({
        creditRequestId: id,
        text,
      })
      .then((results) => {
        if (results) {
          dispatch({ type: ACTIONS.SEND_SUCCESS, data: {} });
        } else {
          throw new Error('Возникла ошибка при получении списка организаций');
        }
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.SEND_FAILURE, error: error.message });
      });
  }, [id]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  function handleFinish(values) {
    const {
      [K.COMMENT.key]: text,
    } = values;
    if (text) {
      sendComment(text);
    }
  }

  function handleBlur({ target: { name } }) {
    form.validateFields([name]);
  }

  function handleFocus({ target: { name } }) {
    form.setFields([{ name, errors: [] }]);
  }

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  useEffect(() => {
    if (sendIsLoaded) {
      fetchComments();
      form.resetFields();
    }
  }, [fetchComments, sendIsLoaded, form]);

  useEffect(() => {
    if (fetchIsLoaded && messagesEndRef) {
      scrollToBottom();
    }
  }, [fetchIsLoaded, messagesEndRef]);

  return (
    <Root>
      {fetchError && <ErrorMessage>{fetchError}</ErrorMessage>}
      <Container>
        <CommentsContainer full={list.length === 0}>
          {list.map((item) => (
            <CommentComponent
              content={item.comment}
              date={item.createdAt}
              author={item.adminName}
              key={item.adminId}
            />
          ))}
          {fetchIsLoading && <Loader text="Загрузка комментарий" />}
          <div ref={messagesEndRef} />
        </CommentsContainer>
        <Main
          name="comment"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={handleFinish}
          autoComplete="off"
        >
          <Form.Item
            name={K.COMMENT.key}
            label={K.COMMENT.title}
            rules={K.COMMENT.rules}
            validateFirst
            validateTrigger="onBlur"
          >
            <TextArea
              name={K.COMMENT.key}
              onFocus={handleFocus}
              onBlur={handleBlur}
              rows={13}
            />
          </Form.Item>
          <Button
            block
            type="primary"
            shape="square"
            htmlType="submit"
            loading={sendIsLoading}
          >
            Отправить
          </Button>
          {sendError && <ErrorMessage>{sendError}</ErrorMessage>}
          {sendIsLoaded && <SuccessMessage>Комментарий добавлен</SuccessMessage>}
        </Main>
      </Container>
    </Root>
  );
}

export default connect(
  null, null,
)(Comments);
